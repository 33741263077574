import {
  useMyClientsStore,
  selectClientStatusFiltersSlice,
  selectMBCStatusFiltersSlice,
  selectPayerFiltersSlice,
  selectTreatmentTrackFiltersSlice,
  selectCadenceFiltersSlice,
  selectTotalSessionsFiltersSlice,
  selectBaselineSeverityState,
  selectMBCMetricsFilterState,
  MyClientsClient,
} from "../../my-clients-model";

import {
  getFilterOnClientStatus,
  getFilterOnMBCStatus,
  getFilterOnCadence,
  getFilterOnTreatmentTrack,
  getFilterOnTotalSessions,
  getFilterOnPayer,
  getFilterOnBaselineSeverity,
  getFilterOnMBCMetrics,
} from "./get-my-clients-filter.util";

export const useFilterClients = (clients: MyClientsClient[]) => {
  const clientStatusFilterState = useMyClientsStore(
    selectClientStatusFiltersSlice,
  );
  const mbcStatusFilterState = useMyClientsStore(selectMBCStatusFiltersSlice);
  const cadenceFilterState = useMyClientsStore(selectCadenceFiltersSlice);
  const payerFilterState = useMyClientsStore(selectPayerFiltersSlice);
  const totalSessionsState = useMyClientsStore(selectTotalSessionsFiltersSlice);
  const treatmentTrackFilterState = useMyClientsStore(
    selectTreatmentTrackFiltersSlice,
  );
  const baselineSeverityFilterState = useMyClientsStore(
    selectBaselineSeverityState,
  );
  const mbcMetricsFilterState = useMyClientsStore(selectMBCMetricsFilterState);

  return clients.filter((client) => {
    return (
      getFilterOnClientStatus(client, clientStatusFilterState) &&
      getFilterOnMBCStatus(client, mbcStatusFilterState) &&
      getFilterOnCadence(client, cadenceFilterState) &&
      getFilterOnTotalSessions(client, totalSessionsState) &&
      getFilterOnPayer(client, payerFilterState) &&
      getFilterOnBaselineSeverity(client, baselineSeverityFilterState) &&
      getFilterOnTreatmentTrack(client, treatmentTrackFilterState) &&
      getFilterOnMBCMetrics(client, mbcMetricsFilterState)
    );
  });
};
