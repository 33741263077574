import { IconProps } from "../icon.interface";

export const ExclamationIcon = ({
  width = 20,
  height = 20,
  viewBox = "0 0 20 20",
  fill='#6E56CF',
  //@ts-ignore
  exclamationFill='#FFFFFF'
}: IconProps) => {
return (
<svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
  <g id="Group 10122103">
  <circle id="Ellipse 150" cx="10" cy="10" r="10" fill={fill}/>
  <path id="Vector 2485 (Stroke)" fill-rule="evenodd" clip-rule="evenodd" d="M10 4.0625C10.5178 4.0625 10.9375 4.48223 10.9375 5V10.625C10.9375 11.1428 10.5178 11.5625 10 11.5625C9.48223 11.5625 9.0625 11.1428 9.0625 10.625V5C9.0625 4.48223 9.48223 4.0625 10 4.0625Z" fill={exclamationFill}/>
  <path id="Vector 2487 (Stroke)" fill-rule="evenodd" clip-rule="evenodd" d="M10 13.4375C10.5178 13.4375 10.9375 13.8572 10.9375 14.375L10.9375 15C10.9375 15.5178 10.5178 15.9375 10 15.9375C9.48223 15.9375 9.0625 15.5178 9.0625 15L9.0625 14.375C9.0625 13.8572 9.48223 13.4375 10 13.4375Z" fill={exclamationFill}/>
  </g>
</svg>
)
}
