import { CSS_COLORS } from "../..";

import { IconProps } from "../icon.interface";

export const CrossOutCircleIcon = ({
  fill = CSS_COLORS.red9,
  title,
}: IconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden={!title}
    >
      {title ? <title>{title}</title> : null}
      <path
        d="M5.85921 4.27336C5.46868 3.88284 4.83552 3.88284 4.44499 4.27336C4.05447 4.66389 4.05447 5.29705 4.44499 5.68758L5.85921 4.27336ZM18.585 19.8276C18.9755 20.2181 19.6087 20.2181 19.9992 19.8276C20.3897 19.437 20.3897 18.8039 19.9992 18.4134L18.585 19.8276ZM21.2222 12.0504C21.2222 17.021 17.1927 21.0504 12.2222 21.0504V23.0504C18.2973 23.0504 23.2222 18.1255 23.2222 12.0504H21.2222ZM12.2222 21.0504C7.25161 21.0504 3.22217 17.021 3.22217 12.0504H1.22217C1.22217 18.1255 6.14704 23.0504 12.2222 23.0504V21.0504ZM3.22217 12.0504C3.22217 7.07985 7.25161 3.05042 12.2222 3.05042V1.05042C6.14704 1.05042 1.22217 5.97528 1.22217 12.0504H3.22217ZM12.2222 3.05042C17.1927 3.05042 21.2222 7.07985 21.2222 12.0504H23.2222C23.2222 5.97528 18.2973 1.05042 12.2222 1.05042V3.05042ZM4.44499 5.68758L18.585 19.8276L19.9992 18.4134L5.85921 4.27336L4.44499 5.68758Z"
        fill={fill}
      />
    </svg>
  );
};
