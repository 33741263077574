import moment from "moment";
import * as React from "react";
import * as ReactRedux from "react-redux";
import { Clinic, Clinician, CurrentUser } from "../../../api/types";
import { AppState, Dispatcher } from "../../../state/models";
import { getCurrentUser } from "../../../state/models/auth";
import {
  clinicianListSelector,
  clinicianOperations,
} from "../../../state/models/clinicians";
import {
  clinicListSelector,
  clinicOperations,
} from "../../../state/models/clinics";
import { matchOperations } from "../../../state/models/matches";
import {
  getCliniciansManualCapacity,
  getClinicianVolumeData,
} from "../../../state/models/slot-tool/operations";
import { teamOperations } from "../../../state/models/team";
import { UserHasAnyPermissions } from "../../_helpers/permissions";
import { MainNavigationMenu } from "./main-navigation-menu-v2/main-navigation-menu-v2";

interface StateProps {
  clinicians: Clinician[];
  clinics: Clinic[];
  loadingClinicians: boolean;
  isAuthenticated: boolean;
  currentUser: CurrentUser | null;
}

interface DispatchProps {
  getClinicianData: (setLoading?: boolean) => void;
  getActiveClinicians: () => void;
  getClinics: () => void;
  getSubregions: () => void;
  getTeam: () => void;
  getActiveHolds: (setLoading?: boolean) => void;
  getCurrentUser: () => Promise<any>;
  getMyConsultsData: () => void;
  getInclusiveCategories: () => void;
  getVolumeData: (excludeWheel?: boolean) => void;
}

interface OwnProps {
  isCollapsed: boolean;
  setIsCollapsed: (v: boolean) => void;
}

interface Props extends StateProps, DispatchProps, OwnProps {}

class SideBarWrapper extends React.Component<Props> {
  private refreshHandle: number | undefined = undefined;
  private backgroundRefreshIntervalMinutes = 5;

  public componentDidMount() {
    if (this.props.isAuthenticated) {
      this.fetchAppData();
      this.queueBackgroundRefresh(this.backgroundRefreshIntervalMinutes);
    }
  }

  public componentWillReceiveProps(newProps: Props) {
    if (newProps.isAuthenticated && !this.props.isAuthenticated) {
      this.fetchAppData();
      this.queueBackgroundRefresh(this.backgroundRefreshIntervalMinutes);
    }
  }

  public queueBackgroundRefresh = (intervalMinutes: number) => {
    if (this.refreshHandle) {
      window.clearTimeout(this.refreshHandle);
      this.refreshHandle = undefined;
    }

    const timeout = moment
      .duration(intervalMinutes, "minutes")
      .asMilliseconds();

    this.refreshHandle = window.setTimeout(() => {
      if (this.props.isAuthenticated) {
        this.queueBackgroundRefresh(this.backgroundRefreshIntervalMinutes);
        this.refreshAppData();
      }
    }, timeout);
  };

  public refreshAppData = (showLoading = false) => {
    const cuser = this.props.currentUser;
    if (
      UserHasAnyPermissions(cuser, [
        "IsSuperUser",
        "IsMatchingAdmin",
        "IsClinicalLeader",
        "IsConsultClinician",
        "IsATMatchRequestEditor",
      ])
    ) {
      this.props.getClinicianData(showLoading);
    } else {
      this.props.getActiveClinicians();
    }
    if (
      UserHasAnyPermissions(cuser, [
        "IsSuperUser",
        "IsMatchingAdmin",
        "IsClinicalLeader",
        "IsConsultClinician",
      ])
    ) {
      this.props.getActiveHolds(showLoading);
    }
  };

  public fetchAppData = () => {
    this.props.getCurrentUser().then((cuser) => {
      if (
        UserHasAnyPermissions(cuser, [
          "IsSuperUser",
          "IsMatchingAdmin",
          "IsClinicalLeader",
          "IsConsultClinician",
        ])
      ) {
        this.props.getMyConsultsData();
      }
      if (
        UserHasAnyPermissions(cuser, [
          "IsSuperUser",
          "IsMatchingAdmin",
          "IsClinicalLeader",
        ])
      ) {
        this.props.getTeam();
      }
      // if (UserHasAnyPermissions(cuser, ["IsMatchingAdmin"])) {
      //   this.props.getVolumeData();
      // } else {
      //   if (
      //     UserHasAnyPermissions(cuser, [
      //       "IsRequeuer",
      //       "IsSuperUser",
      //       "IsConsultClinician",
      //     ])
      //   ) {
      //     this.props.getVolumeData(true);
      //   }
      // }
      if (
        UserHasAnyPermissions(cuser, [
          "IsSuperUser",
          "IsMatchingAdmin",
          "IsClinicalLeader",
          "IsConsultClinician",
          "IsClinician",
        ])
      ) {
        this.props.getInclusiveCategories();
      }
      this.props.getClinics();
      this.props.getSubregions();
      this.refreshAppData(true);
    });
  };

  public render() {
    if (this.props.isAuthenticated) {
      return (
        <MainNavigationMenu
          setIsCollapsed={this.props.setIsCollapsed}
          isCollapsed={this.props.isCollapsed}
        />
      );
    }

    return null;
  }
}

const mapStateToProps: ReactRedux.MapStateToProps<StateProps, {}, AppState> = (
  state,
) => {
  return {
    clinicians: clinicianListSelector(state.clinicians),
    clinics: clinicListSelector(state.clinics),
    loadingClinicians: state.clinicians.loadingClinicians,
    isAuthenticated: state.auth.isAuthenticated,
    currentUser: state.auth.currentUser,
  };
};

const mapDispatchToProps: ReactRedux.MapDispatchToProps<DispatchProps, {}> = (
  dispatch: Dispatcher,
  ownProps,
) => ({
  getClinicianData: (setLoading?: boolean) => {
    dispatch(clinicianOperations.getClinicianData(setLoading));
  },
  getActiveClinicians: () => {
    dispatch(clinicianOperations.getActiveClinicians());
  },
  getSubregions: () => {
    dispatch(clinicOperations.getSubregions());
  },
  getClinics: () => {
    dispatch(clinicOperations.getClinics());
  },
  getTeam: () => {
    dispatch(teamOperations.getTeam());
  },
  getActiveHolds: (setLoading?: boolean) => {
    dispatch(clinicianOperations.getActiveHolds(setLoading));
  },
  getCurrentUser: () => dispatch(getCurrentUser()),
  getMyConsultsData: () => {
    dispatch(matchOperations.getMyMatches());
  },
  getInclusiveCategories: () => {
    dispatch(matchOperations.getInclusiveCategories());
  },
  getVolumeData: (excludeWheel?: boolean) => {
    dispatch(getClinicianVolumeData([], excludeWheel));
    dispatch(getCliniciansManualCapacity());
  },
});

const ConnectedSideBarWrapper = ReactRedux.connect(
  mapStateToProps,
  mapDispatchToProps,
)(SideBarWrapper);

export default ConnectedSideBarWrapper;
