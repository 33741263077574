import React from 'react';
// import { IconProps } from '../../../design-system/icons/icon.interface';
import { IconProps } from '@/app/design-system/icons/icon.interface';

const QuestionIcon = ({ className }: IconProps) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="8.5" cy="8.5" r="8.5" fill="#F2F2F2" />
      <path
        d="M7.59766 10.4901V10.4091C7.60334 9.88068 7.65589 9.46023 7.75533 9.14773C7.8576 8.83523 8.00249 8.58239 8.18999 8.3892C8.37749 8.19602 8.60334 8.01989 8.86754 7.8608C9.038 7.75284 9.19141 7.6321 9.32777 7.49858C9.46413 7.36506 9.57209 7.21165 9.65163 7.03835C9.73118 6.86506 9.77095 6.6733 9.77095 6.46307C9.77095 6.21023 9.71129 5.99148 9.59197 5.80682C9.47266 5.62216 9.31357 5.48011 9.1147 5.38068C8.91868 5.27841 8.69993 5.22727 8.45845 5.22727C8.2397 5.22727 8.03089 5.27273 7.83203 5.36364C7.63317 5.45455 7.46839 5.59659 7.33771 5.78977C7.20703 5.98011 7.13175 6.22585 7.11186 6.52699H5.81641C5.83629 6.01562 5.96555 5.58381 6.20419 5.23153C6.44283 4.87642 6.75817 4.60795 7.15021 4.42614C7.5451 4.24432 7.98118 4.15341 8.45845 4.15341C8.98118 4.15341 9.43857 4.25142 9.83061 4.44744C10.2227 4.64062 10.5266 4.91193 10.7425 5.26136C10.9613 5.60795 11.0707 6.01278 11.0707 6.47585C11.0707 6.79403 11.021 7.08097 10.9215 7.33665C10.8221 7.58949 10.68 7.81534 10.4954 8.0142C10.3136 8.21307 10.0948 8.3892 9.83913 8.54261C9.59766 8.69318 9.40163 8.84943 9.25107 9.01136C9.10334 9.1733 8.99538 9.36506 8.9272 9.58665C8.85902 9.80824 8.82209 10.0824 8.81641 10.4091V10.4901H7.59766ZM8.24112 13.081C8.00817 13.081 7.80788 12.9986 7.64027 12.8338C7.47266 12.6662 7.38885 12.4645 7.38885 12.2287C7.38885 11.9957 7.47266 11.7969 7.64027 11.6321C7.80788 11.4645 8.00817 11.3807 8.24112 11.3807C8.47124 11.3807 8.6701 11.4645 8.83771 11.6321C9.00817 11.7969 9.0934 11.9957 9.0934 12.2287C9.0934 12.3849 9.05362 12.5284 8.97408 12.6591C8.89737 12.7869 8.7951 12.8892 8.66726 12.9659C8.53942 13.0426 8.39737 13.081 8.24112 13.081Z"
        fill="#616161"
      />
    </svg>
  );
};

export { QuestionIcon };
