import { NoResultsImage } from "@/assets/no-results-image";
import { loadMDBWithClinicianId } from "@/state/models/dashboard/operations";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ClinicianPickerV2 } from "../../../_components/clinician-picker-v2/clinician-picker-v2";
import { DxCodeMap } from "../../../client/types";
import { Flex, H2, Stack, Text } from "../../../design-system";
import useTitle from "../../../hooks/useTitle";
import { useFetchDxCodes } from "../../api/use-fetch-dxcodes/use-fetch-dxcodes";
import { getDiagnosisCodeMap } from "../../utils";
import { ClientPane } from "./components/client-pane";
import { ClientPaneHeader } from "./components/client-pane/client-pane-header";

import { MainContentHeader } from "@/app/_shared/MainContentHeader";
import { store } from "@/index";
import { Spin } from "antd";
import { SearchAndFilter } from "./components/search-and-filter/search-and-filter";
import { useMyClientsPage } from "./hooks/use-my-clients-page/use-my-clients-page";
import { useMyClientsRedirect } from "./hooks/use-my-clients-redirect";
import { MyClientsClient } from "./my-clients-model";
import { useMyClientsSearchStore } from "./my-clients-model/my-clients.model";
import { MyClientsContainer } from "./my-clients.styled";
import { useGetDiagnosisCodes } from "../components/use-get-diagnosis-code";
import { CurrentUserContext, CurrentConfigContext } from "@/app/app.utils";
import { userCanAccessHealthie } from "@/app/_helpers/permissions";
export const MyClientsPage = () => {
  const cuser = store.getState()?.auth?.currentUser;
  useTitle("My Clients", false);

  useMyClientsRedirect();

  const {
    isLoading,
    isSuccess,
    sortedAndFilteredClientsData,
    mappedClientsData,
    isClinicalLeaderOrSuperuser,
    isEocDataAvailable,
  } = useMyClientsPage();

  // Array to store clientIds
  const healthieClientIds = [];

  if (mappedClientsData) {
    // Iterate through the array and extract clientIds
    for (const obj of mappedClientsData) {
      if (obj.hasOwnProperty("healthieId")) {
        //@ts-ignore
        healthieClientIds.push(obj.healthieId);
      }
    }
  }

  const currentUser = React.useContext(CurrentUserContext);

  const config = React.useContext(CurrentConfigContext);

  const hasHealthieAccess = userCanAccessHealthie(currentUser, config);

  const { data: diagnosisCodesByUser, isLoading: isDxCodeLoading } =
    useGetDiagnosisCodes(
      //@ts-ignore
      healthieClientIds,
      hasHealthieAccess && healthieClientIds.length > 0,
    );

  if (!isDxCodeLoading && diagnosisCodesByUser) {
    sortedAndFilteredClientsData.forEach((client) => {
      const healthieIdValue = client.healthieId;
      if (
        healthieIdValue &&
        diagnosisCodesByUser.hasOwnProperty(healthieIdValue)
      ) {
        const associatedArray = diagnosisCodesByUser[healthieIdValue];
        if (associatedArray.length > 0) {
          client.primaryDiagnosis = associatedArray[0];
        }
      }
    });
  }

  const { data: dxCodeData } = useFetchDxCodes();
  const dxCodeMap: DxCodeMap = getDiagnosisCodeMap(dxCodeData);

  const { setCurrentlySelectedClinician, currentlySelectedClinician } =
    useMyClientsSearchStore();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClinicianPickerSelect = (clinicianId: null | number) => {
    setCurrentlySelectedClinician(clinicianId);
    dispatch(loadMDBWithClinicianId(clinicianId));
    navigate(`?clinician_id=${clinicianId}`);
  };

  return (
    <MyClientsContainer flexDirection={"column"}>
      <MainContentHeader
        css={{
          "@breakpoint1200": {
            flexDirection: "column",
            alignItems: "flex-start",
          },
          flexWrap: "wrap",
          gap: 8,
        }}
      >
        <H2 as="h1">My Clients</H2>
        {/* 
          {isLoading ? (
            <Flex css={{ marginLeft: 8 }}>
              <Spinner>
                <QuarterCircle dimension={"1.5em"} />
              </Spinner>
            </Flex>
          ) : null} */}

        <Flex
          css={{
            "@breakpoint640": {
              flexDirection: "column-reverse",
            },
            gap: 8,
          }}
        >
          <Text color={"$neutral11"} fontSize="14">
            Times displayed in&nbsp;
            <strong>
              {/* https://stackoverflow.com/questions/1091372/getting-the-clients-time-zone-and-offset-in-javascript */}
              {cuser?.clinician?.primary_timezone ||
                Intl.DateTimeFormat().resolvedOptions().timeZone}
            </strong>
          </Text>

          {isClinicalLeaderOrSuperuser ? (
            <ClinicianPickerV2 callback={handleClinicianPickerSelect} />
          ) : null}
        </Flex>
      </MainContentHeader>

      <SearchAndFilter
        myClients={(mappedClientsData as MyClientsClient[]) ?? []}
      />

      <Spin spinning={isLoading}>
        <Flex
          flexDirection={"column"}
          css={{ flexGrow: 1, backgroundColor: "$neutral3" }}
          as="section"
        >
          <Stack css={{ padding: 24 }}>
            {isSuccess ? (
              <Stack css={{ pt: 8 }} gap={12}>
                <ClientPaneHeader
                  totalClients={sortedAndFilteredClientsData?.length ?? 0}
                />
                {sortedAndFilteredClientsData.length > 0 ? (
                  <Stack>
                    {sortedAndFilteredClientsData.map(
                      (client: MyClientsClient, index: number) => {
                        return (
                          <ClientPane
                            {...client}
                            isEocDataAvailable={isEocDataAvailable}
                            index={index}
                            key={client.clientId}
                            matchingInfo={client.matchingInfo}
                            dxCodeMap={dxCodeMap}
                            client={client}
                            primaryDiagnosis={
                              isDxCodeLoading ? null : client.primaryDiagnosis
                            }
                            isDxCodeLoading={isDxCodeLoading}
                          />
                        );
                      },
                    )}
                  </Stack>
                ) : (
                  <Stack alignItems={"center"} gap={20} css={{ marginTop: 96 }}>
                    <NoResultsImage />

                    <Stack alignItems={"center"}>
                      <Text color="$neutral11">There are no clients</Text>
                      <Text color="$neutral11">matching your search</Text>
                    </Stack>
                  </Stack>
                )}
              </Stack>
            ) : null}
          </Stack>
        </Flex>
      </Spin>
    </MyClientsContainer>
  );
};
