import { RadixTooltip } from "@/app/design-system";
import React from "react";

interface NavigationToolTipProps {
  content: string | React.ReactNode;
  shouldRenderContent: boolean;
  children: React.ReactNode;
  sideOffset?: number;
}

export const NavigationToolTip = ({
  shouldRenderContent,
  children,
  content,
  sideOffset = 12,
}: NavigationToolTipProps) => {
  return (
    <RadixTooltip
      content={content}
      shouldRenderContent={shouldRenderContent}
      shouldRenderArrow={false}
      side={"right"}
      sideOffset={sideOffset}
      delayDuration={150}
      fontSize={14}
    >
      {children}
    </RadixTooltip>
  );
};
