import { Checkbox, Popover, Select as DeprecatedAntdSelect } from "antd";

import { CheckboxValueType } from "antd/es/checkbox/Group";
import { SelectValue } from "antd/es/select";
import { isEqual } from "lodash-es";
import * as React from "react";
import * as ReactRedux from "react-redux";
import styled from "styled-components";

import { Clinician, CurrentUser } from "../../api/types";
import { Permissions } from "../../app/_helpers/permissions";
import { Button } from "../../app/_shared/antd";
import { $antdBorder, $red } from "../../assets/colors";
import { AppState, Dispatcher } from "../../state/models";
import {
  getInitialFilters,
  panelActions,
} from "../../state/models/panel-management";
import * as panelOperations from "../../state/models/panel-management/operations";
import { Text } from "../design-system";
import { isLabeledValue, isMultiStringValue } from "../_helpers";
import { AnchorButton } from "../_shared/AnchorButton";
import {
  incrementMomentDate,
  DeprecatedTimePicker,
} from "../_shared/TimePickerWithMoment";
import {
  CadenceEnum,
  ClientCadence,
  ClientFilterFn,
  ClientFilters,
  ClientSort,
  ClientSortEnum,
  ClientStatus,
  ClientStatusEnum,
  DateRange,
  StatifiedActiveClientFilters,
} from "./types";

const commonStyles = `
  border-radius: 4px;
`;

interface OwnProps {
  setDateRange: (dateRange: DateRange) => void;
  dateRange: DateRange;
  timezone: string;
}

interface StateProps {
  clientSort: ClientSort;
  clientFilters: StatifiedActiveClientFilters;
  clinicianMap: { [id: number]: Clinician };
  currentUser: CurrentUser | null;
}

interface DispatchProps {
  sortClients: (clientSort: ClientSort) => void;
  setClientFilters: (filters: StatifiedActiveClientFilters) => void;
  setClinicianId: (clinicianId: number) => void;
}

class Navbar extends React.Component<OwnProps & StateProps & DispatchProps> {
  public readonly state = {
    cadenceFilters: this.props.clientFilters.byCadence || [],
    statusFilters: this.props.clientFilters.byStatus || [],
    filterPopoverVisible: false,
  };

  public render() {
    const cadenceFilterOptions: Array<{ label: string; value: string }> = [];
    Object.entries(CadenceEnum).map((cadence) =>
      cadenceFilterOptions.push({ label: cadence[1], value: cadence[0] }),
    );
    const statusFilterOptions: Array<{ label: string; value: string }> = [];
    Object.entries(ClientStatusEnum).map((status) =>
      statusFilterOptions.push({ label: status[1], value: status[0] }),
    );

    const filtersAreActive =
      this.state.cadenceFilters.length > 0 ||
      !isEqual(this.state.statusFilters, getInitialFilters(true).byStatus);

    const filterContent = (
      <FilterCard>
        <div>
          <h4>Cadence</h4>
          <Checkbox.Group
            options={cadenceFilterOptions}
            value={this.state.cadenceFilters}
            defaultValue={this.props.clientFilters.byCadence}
            onChange={this.changeCadenceFilter}
          />
        </div>
        <div>
          <h4>Status</h4>
          <Checkbox.Group
            options={statusFilterOptions}
            value={this.state.statusFilters}
            defaultValue={this.props.clientFilters.byStatus}
            onChange={this.changeStatusFilter}
          />
        </div>
        <FilterCardButtons>
          <AnchorButton
            onClick={this.deselectAllFilters}
            style={{ color: $red, border: "none", background: "none" }}
          >
            Reset Filters
          </AnchorButton>
          <div style={{ marginLeft: "auto" }}>
            <Button
              type="dashed"
              style={{ marginRight: ".5em" }}
              onClick={() => this.handleFilterPopoverVisibility(false)}
            >
              Cancel
            </Button>
            <Button type="primary" onClick={this.applyFilters}>
              Apply
            </Button>
          </div>
        </FilterCardButtons>
      </FilterCard>
    );

    const cliniciansSelect = Object.entries(this.props.clinicianMap)
      .sort((a, b) => a[1].first_name.localeCompare(b[1].first_name))
      .map((clinician) => (
        <DeprecatedAntdSelect.Option
          key={clinician[0]}
          value={`${clinician[0]} ${clinician[1].first_name} ${clinician[1].last_name}`}
        >
          {`${clinician[1].first_name} ${clinician[1].last_name}`}
        </DeprecatedAntdSelect.Option>
      ));

    return (
      <Container>
        <Column>
          <DeprecatedTimePicker
            dateRange={this.props.dateRange}
            setDateRange={this.props.setDateRange}
            incrementDate={incrementMomentDate}
          />
          {Permissions.IsPanManAdmin(this.props.currentUser) && (
            <DeprecatedAntdSelect
              style={{ width: 200 }}
              showSearch={true}
              labelInValue={true}
              placeholder="Select a Clinician"
              onChange={this.clinicianOnChange}
            >
              {cliniciansSelect}
            </DeprecatedAntdSelect>
          )}
          <Text color={"$neutral11"} fontSize="14" notFlex>
            Times displayed in&nbsp;<strong>{this.props.timezone}</strong>
          </Text>
        </Column>
        <Column style={{ marginLeft: "auto" }}>
          <span>Sort:</span>
          <DeprecatedAntdSelect
            style={{ width: 180 }}
            defaultValue={this.props.clientSort}
            onChange={this.sortOnChange}
          >
            {Object.entries(ClientSortEnum).map(([key, str]) => (
              <DeprecatedAntdSelect.Option key={key} value={key}>
                {str}
              </DeprecatedAntdSelect.Option>
            ))}
          </DeprecatedAntdSelect>
          <Popover
            open={this.state.filterPopoverVisible}
            onOpenChange={this.handleFilterPopoverVisibility}
            content={filterContent}
            placement="bottomLeft"
            trigger="click"
          >
            <StyledButton type={filtersAreActive ? "primary" : "default"}>
              <span style={{ fontWeight: filtersAreActive ? 600 : 400 }}>
                Filter
              </span>
            </StyledButton>
          </Popover>
        </Column>
      </Container>
    );
  }

  private clinicianOnChange = (value: SelectValue) => {
    if (isLabeledValue(value)) {
      const clinicianId = parseInt(value.key!.split(" ")[0], 0);
      this.props.setClinicianId(clinicianId);
    } else {
      // todo
      throw new Error("Expected a LabeledValue");
    }
  };

  private handleFilterPopoverVisibility = (visible: boolean) => {
    this.setState({ filterPopoverVisible: visible });
  };

  private sortOnChange = (value: SelectValue) => {
    if (typeof value === "string") {
      this.props.sortClients(value as ClientSort);
    } else {
      throw new Error("Expected string value");
    }
  };

  private changeCadenceFilter = (filters: CheckboxValueType[]) => {
    if (isMultiStringValue(filters)) {
      this.setState({ cadenceFilters: filters as ClientCadence[] });
    } else {
      throw new Error("Expected multiple string values");
    }
  };

  private changeStatusFilter = (filters: CheckboxValueType[]) => {
    if (isMultiStringValue(filters)) {
      this.setState({ statusFilters: filters as ClientStatus[] });
    } else {
      throw new Error("Expected multiple string values");
    }
  };

  private deselectAllFilters = (e?: React.MouseEvent) => {
    if (e) {
      e.preventDefault();
    }
    const defaultFilters = getInitialFilters(true);
    this.setState(
      {
        cadenceFilters: defaultFilters.byCadence || [],
        statusFilters: defaultFilters.byStatus || [],
      },
      this.applyFilters,
    );
  };

  private applyFilters = (e?: React.MouseEvent) => {
    if (e) {
      e.preventDefault();
    }
    const filters: ClientFilterFn[] = [];
    const serializableFilters: StatifiedActiveClientFilters = {};
    if (this.state.cadenceFilters.length > 0) {
      filters.push(ClientFilters.byCadence(this.state.cadenceFilters));
      serializableFilters.byCadence = this.state.cadenceFilters;
    }

    if (this.state.statusFilters.length > 0) {
      filters.push(ClientFilters.byStatus(this.state.statusFilters));
      serializableFilters.byStatus = this.state.statusFilters;
    }
    this.props.setClientFilters(serializableFilters);
    this.handleFilterPopoverVisibility(false);
  };
}
interface ButtonProps {
  type?: string;
}
const StyledButton = styled(Button as React.ComponentType<ButtonProps>)`
  ${commonStyles};
`;

/*
 * Styled Components
 */

const FilterCard = styled.div`
  max-width: 480px;

  > div {
    display: flex;
    align-items: top;
    &:not(:last-of-type) {
      border-bottom: 1px solid ${$antdBorder};
      padding-bottom: 0.5em;
      margin-bottom: 1em;
    }

    h4 {
      margin: 0 1em 0 0;
      min-width: 72px;
    }
  }
`;

const FilterCardButtons = styled.div`
  display: flex;
  align-items: center;
`;

const Container = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 12px;
  width: 100%;
  height: 56px;
  z-index: 999;
  background-color: white;
  border-bottom: 1px solid ${$antdBorder};
  flex: 0 0 56px;
  font-size: 10pt;
  .ant-input {
    border-radius: 4px;
  }
`;

const Column = styled.div`
  > * {
    margin: 0 4px;
  }
  > span {
    margin: 0 8px;
  }
  display: flex;
  align-items: center;
`;

/*
 * Utility Components
 */

const mapStateToProps: ReactRedux.MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = (state) => {
  return {
    clientSort: state.panel.sort,
    clientFilters: state.panel.clientFilters,
    clinicianMap: state.clinicians.clinicianMap,
    currentUser: state.auth.currentUser,
  };
};

const mapDispatchToProps: ReactRedux.MapDispatchToProps<DispatchProps, {}> = (
  dispatch: Dispatcher,
  ownProps,
) => ({
  sortClients: (clientSort) => {
    dispatch(panelOperations.sortClients(clientSort));
  },
  setClientFilters: (filters) => {
    dispatch(panelActions.setClientFilters(filters));
    dispatch(panelOperations.filterClients(filters));
  },
  setClinicianId: (clinicianId) => {
    dispatch(panelActions.setClinicianId(clinicianId));
  },
});

export default ReactRedux.connect(mapStateToProps, mapDispatchToProps)(Navbar);
