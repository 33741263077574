import { styledStitches } from "../design-system";

interface CommonAbbrProps {
  children: React.ReactNode;
  title: string;
}

// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/abbr#default_styling

export const UNSTYLED_ABBR_STYLE = {
  borderBottom: "none !important",
  cursor: "inherit !important",
  textDecoration: "none !important",
  fontVariant: "normal",
};

const StyledCommonAbbr = styledStitches("abbr", {
  ...UNSTYLED_ABBR_STYLE,
});

/**
 * @name CommonAbbr
 * @description Particularly useful for abbreviations so that abbreviated forms can be read properly by screen-readers, but common enough for non-screen reader users so that we remove the typical `abbr` styling that indicates that an element is an abbreviation. A use case is for "appt", which is read by a screenreader as "apt" but can be expanded to "appointment" when using this element.
 * GAD7 or PHQ9 are pronounced the same way when spoken and commonly used in the clinician user base, so I don't think they need to be wrapped by this
 * Visit [Understanding SC 3.1.4](https://www.w3.org/TR/UNDERSTANDING-WCAG20/meaning-located.html) for more information
 * @param {string} title // The expanded form of the abbreviation
 */

export const CommonAbbr = ({ children, title }: CommonAbbrProps) => {
  return <StyledCommonAbbr title={title}>{children}</StyledCommonAbbr>;
};
