import { Select as DeprecatedAntdSelect } from "antd";
import styled from "styled-components";

export const size = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tablet: 768,
  laptop: 1024,
  laptopL: 1440,
  desktop: 2560,
  desktopL: 4096,
  bookingFlowMobile: 1300,
};

export const device = {
  mobileS: `(max-width: ${size.mobileS}px)`,
  mobileM: `(max-width: ${size.mobileM}px)`,
  mobileL: `(max-width: ${size.mobileL}px)`,
  tablet: `(max-width: ${size.tablet}px)`,
  laptop: `(max-width: ${size.laptop}px)`,
  laptopL: `(max-width: ${size.laptopL}px)`,
  desktop: `(max-width: ${size.desktop}px)`,
  desktopL: `(max-width: ${size.desktopL}px)`,
};

export const media = {
  mobileS: `@media only screen and ${device.mobileS}`,
  mobileM: `@media only screen and ${device.mobileM}`,
  mobileL: `@media only screen and ${device.mobileL}`,
  tablet: `@media only screen and ${device.tablet}`,
  laptop: `@media only screen and ${device.laptop}`,
  laptopL: `@media only screen and ${device.laptopL}`,
  desktop: `@media only screen and ${device.desktop}`,
  desktopL: `@media only screen and ${device.desktopL}`,

  desktopOnly: `@media only screen and (min-width: ${size.tablet + 1}px)`,
  laptopOnly: `@media only screen and (min-width: ${size.laptop + 1}px)`,
  tabletOnly: `@media only screen and (min-width: ${size.tablet + 1}px)`,
  mobileOnly: `@media only screen and ${device.tablet}`,
  bookingFlowDesktop: `@media only screen and (min-width: ${
    size.bookingFlowMobile + 1
  }px)`,
  bookingFlowMobile: `@media only screen and (max-width: ${size.bookingFlowMobile}px)`,
};

export const MobileOnly = styled.div`
  ${media.desktopOnly} {
    display: none;
  }
`;

export const DesktopOnly = styled.div`
  ${media.mobileOnly} {
    display: none;
  }
`;

export const BookingFlowMobile = styled.div``;
export const BookingFlowDesktop = styled.div``;

export const usStateOptions = (
  <>
    <DeprecatedAntdSelect.Option value="AL">Alabama</DeprecatedAntdSelect.Option>
    <DeprecatedAntdSelect.Option value="AK">Alaska</DeprecatedAntdSelect.Option>
    <DeprecatedAntdSelect.Option value="AZ">Arizona</DeprecatedAntdSelect.Option>
    <DeprecatedAntdSelect.Option value="AR">Arkansas</DeprecatedAntdSelect.Option>
    <DeprecatedAntdSelect.Option value="CA">California</DeprecatedAntdSelect.Option>
    <DeprecatedAntdSelect.Option value="CO">Colorado</DeprecatedAntdSelect.Option>
    <DeprecatedAntdSelect.Option value="CT">Connecticut</DeprecatedAntdSelect.Option>
    <DeprecatedAntdSelect.Option value="DE">Delaware</DeprecatedAntdSelect.Option>
    <DeprecatedAntdSelect.Option value="DC">District Of Columbia</DeprecatedAntdSelect.Option>
    <DeprecatedAntdSelect.Option value="FL">Florida</DeprecatedAntdSelect.Option>
    <DeprecatedAntdSelect.Option value="GA">Georgia</DeprecatedAntdSelect.Option>
    <DeprecatedAntdSelect.Option value="HI">Hawaii</DeprecatedAntdSelect.Option>
    <DeprecatedAntdSelect.Option value="ID">Idaho</DeprecatedAntdSelect.Option>
    <DeprecatedAntdSelect.Option value="IL">Illinois</DeprecatedAntdSelect.Option>
    <DeprecatedAntdSelect.Option value="IN">Indiana</DeprecatedAntdSelect.Option>
    <DeprecatedAntdSelect.Option value="IA">Iowa</DeprecatedAntdSelect.Option>
    <DeprecatedAntdSelect.Option value="KS">Kansas</DeprecatedAntdSelect.Option>
    <DeprecatedAntdSelect.Option value="KY">Kentucky</DeprecatedAntdSelect.Option>
    <DeprecatedAntdSelect.Option value="LA">Louisiana</DeprecatedAntdSelect.Option>
    <DeprecatedAntdSelect.Option value="ME">Maine</DeprecatedAntdSelect.Option>
    <DeprecatedAntdSelect.Option value="MD">Maryland</DeprecatedAntdSelect.Option>
    <DeprecatedAntdSelect.Option value="MA">Massachusetts</DeprecatedAntdSelect.Option>
    <DeprecatedAntdSelect.Option value="MI">Michigan</DeprecatedAntdSelect.Option>
    <DeprecatedAntdSelect.Option value="MN">Minnesota</DeprecatedAntdSelect.Option>
    <DeprecatedAntdSelect.Option value="MS">Mississippi</DeprecatedAntdSelect.Option>
    <DeprecatedAntdSelect.Option value="MO">Missouri</DeprecatedAntdSelect.Option>
    <DeprecatedAntdSelect.Option value="MT">Montana</DeprecatedAntdSelect.Option>
    <DeprecatedAntdSelect.Option value="NE">Nebraska</DeprecatedAntdSelect.Option>
    <DeprecatedAntdSelect.Option value="NV">Nevada</DeprecatedAntdSelect.Option>
    <DeprecatedAntdSelect.Option value="NH">New Hampshire</DeprecatedAntdSelect.Option>
    <DeprecatedAntdSelect.Option value="NJ">New Jersey</DeprecatedAntdSelect.Option>
    <DeprecatedAntdSelect.Option value="NM">New Mexico</DeprecatedAntdSelect.Option>
    <DeprecatedAntdSelect.Option value="NY">New York</DeprecatedAntdSelect.Option>
    <DeprecatedAntdSelect.Option value="NC">North Carolina</DeprecatedAntdSelect.Option>
    <DeprecatedAntdSelect.Option value="ND">North Dakota</DeprecatedAntdSelect.Option>
    <DeprecatedAntdSelect.Option value="OH">Ohio</DeprecatedAntdSelect.Option>
    <DeprecatedAntdSelect.Option value="OK">Oklahoma</DeprecatedAntdSelect.Option>
    <DeprecatedAntdSelect.Option value="OR">Oregon</DeprecatedAntdSelect.Option>
    <DeprecatedAntdSelect.Option value="PA">Pennsylvania</DeprecatedAntdSelect.Option>
    <DeprecatedAntdSelect.Option value="RI">Rhode Island</DeprecatedAntdSelect.Option>
    <DeprecatedAntdSelect.Option value="SC">South Carolina</DeprecatedAntdSelect.Option>
    <DeprecatedAntdSelect.Option value="SD">South Dakota</DeprecatedAntdSelect.Option>
    <DeprecatedAntdSelect.Option value="TN">Tennessee</DeprecatedAntdSelect.Option>
    <DeprecatedAntdSelect.Option value="TX">Texas</DeprecatedAntdSelect.Option>
    <DeprecatedAntdSelect.Option value="UT">Utah</DeprecatedAntdSelect.Option>
    <DeprecatedAntdSelect.Option value="VT">Vermont</DeprecatedAntdSelect.Option>
    <DeprecatedAntdSelect.Option value="VA">Virginia</DeprecatedAntdSelect.Option>
    <DeprecatedAntdSelect.Option value="WA">Washington</DeprecatedAntdSelect.Option>
    <DeprecatedAntdSelect.Option value="WV">West Virginia</DeprecatedAntdSelect.Option>
    <DeprecatedAntdSelect.Option value="WI">Wisconsin</DeprecatedAntdSelect.Option>
    <DeprecatedAntdSelect.Option value="WY">Wyoming</DeprecatedAntdSelect.Option>
  </>
);
