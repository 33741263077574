import * as React from "react";
import { useDispatch } from "react-redux";
import { consultNoteActions } from "../../../../../state/models/consultnote";
import { preferences_options, intensity_options } from "./enums";
import { Input, Select as DeprecatedAntdSelect } from "antd";
import { Flex, Row } from "../../../../_layout/Flex";
import useDebounce from "../../../../hooks/useDebounce";
import { TherapyPreferences } from "../../../../../api/types";
import { SelectSearchProps } from "../shared/SelectSearchProps";
import { getCurrentConsultNoteInfo } from "@/app/consults-and-matching/_hooks";

interface DemographicPreferencesSubQuestionProps {
  values: TherapyPreferences | never[];
  categoryKey: string;
  additionalDetailsKey: string;
  intensityKey: string;
  handleEdit: (hasBeenEdited: boolean) => void;
}

const DemographicPreferencesSubQuestion = ({
  values,
  categoryKey,
  intensityKey,
  additionalDetailsKey,
  handleEdit,
}: DemographicPreferencesSubQuestionProps) => {
  const { readOnlyConsultNote } = getCurrentConsultNoteInfo();

  const handleChange = (key: string, value: string) => {
    handleEdit(true);
    dispatch(
      consultNoteActions.updateRelatedObjectField({
        relatedObjectName: "therapy_preferences",
        key,
        value,
      }),
    );
  };

  const [additionalDetails, setAdditionalDetails] = React.useState(
    values[additionalDetailsKey],
  );

  const handleSetAdditionalDetails = (value: string) => {
    handleEdit(true);
    setAdditionalDetails(value);
  };

  React.useEffect(() => {
    setAdditionalDetails(values[additionalDetailsKey]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values[additionalDetailsKey]]);

  const dispatch = useDispatch();
  const debouncedAdditionalDetails = useDebounce(additionalDetails, 500);

  React.useEffect(() => {
    dispatch(
      consultNoteActions.updateRelatedObjectField({
        relatedObjectName: "therapy_preferences",
        key: additionalDetailsKey,
        value: additionalDetails,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedAdditionalDetails]);

  return (
    <Row layout="start start" style={{ marginTop: "15px" }}>
      <Flex style={{ paddingTop: "3px", flex: "0 0 9%" }}>Category</Flex>
      <Flex style={{ marginRight: "6%", flex: "0 0 15%" }}>
        <DeprecatedAntdSelect<string>
          disabled={readOnlyConsultNote}
          style={{ display: "block" }}
          value={values[categoryKey]}
          onChange={(v) => {
            handleChange(categoryKey, v);
          }}
          {...SelectSearchProps}
        >
          {preferences_options.map((so: any) => {
            return (
              <DeprecatedAntdSelect.Option key={so.key} value={so.value}>
                {so.title}
              </DeprecatedAntdSelect.Option>
            );
          })}
        </DeprecatedAntdSelect>
      </Flex>
      <Flex style={{ paddingTop: "3px", flex: "0 0 6%" }}>Intensity</Flex>
      <Flex style={{ marginRight: "6%", flex: "0 0 15%" }}>
        <DeprecatedAntdSelect<string>
          disabled={readOnlyConsultNote}
          style={{ display: "block" }}
          value={values[intensityKey]}
          onChange={(v) => {
            handleChange(intensityKey, v);
          }}
          {...SelectSearchProps}
        >
          {intensity_options.map((so: any) => {
            return (
              <DeprecatedAntdSelect.Option key={so.key} value={so.value}>
                {so.title}
              </DeprecatedAntdSelect.Option>
            );
          })}
        </DeprecatedAntdSelect>
      </Flex>
      <Flex style={{ paddingTop: "2px", flex: "0 0 6%" }}>
        Additional details
      </Flex>
      <Flex style={{ flex: "0 0 20%" }}>
        <Input.TextArea
          disabled={readOnlyConsultNote}
          style={{ height: "32px" }}
          value={additionalDetails}
          onChange={(e) => {
            handleSetAdditionalDetails(e.target.value);
          }}
        ></Input.TextArea>
      </Flex>
    </Row>
  );
};

export { DemographicPreferencesSubQuestion };
