import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";

import { keyframes, styledStitches } from "../../styles/stitches.config";
import { FOCUS_STYLES } from "../../styles/config/css-utils";

export const contentShow = keyframes({
  "0%": { opacity: 0, transform: "scale(.98)" },
  "100%": { opacity: 1, transform: "scale(1)" },
});

export const StyledCombobox = styledStitches(Combobox, {
  position: "relative",
  svg: {
    position: "absolute",
    top: 12,
    left: 12,
  },

  ".clear-search-button": {
    top: 7,
    right: 40,
  },
});

export const StyledComboboxInput = styledStitches(ComboboxInput, {
  ...FOCUS_STYLES,
  border: `1px solid $neutral8`,
  py: 14,
  minWidth: 400,
  br: 8,
  pl: 44,
});

export const StyledComboboxList = styledStitches(ComboboxList, {
  backgroundColor: "white",
  border: `1px solid $neutral8`,
  borderTop: "none",
  p: 10,
  pt: 0,
  top: -6,
  bblr: 8,
  bbrr: 8,
  position: "absolute",
  minWidth: 400,

  "[data-reach-combobox-option][data-highlighted]": {
    backgroundColor: "$neutral3",
  },

  boxShadow: "0px 8px 8px 0px #00000014",
  animation: `${contentShow} 0.1s ease-in-out`,
  z: 10000,
});

export const StyledComboboxOption = styledStitches(ComboboxOption, {
  listStyle: "none",
  py: 8,
  px: 34,
  br: 4,

  cursor: "pointer",

  "[data-user-value]": {
    fontWeight: "lighter",
    backgroundColor: "$yellow10",
  },

  // '[data-suggested-value]': {
  //   fontWeight: 'bold',
  // },

  "&:hover": {
    backgroundColor: "$neutral3",
  },
});
