import { styledStitches, Text } from "@/app/design-system";
import { TIMEZONE } from "@/app/my-clients/utils";
import { GroupClientProfileActiveTabs } from "./_components/client-profile-active-tabs";
import { selectGroupTherapyClientData } from "./_api/use-group-therapy-client-overview/use-group-therapy-client.util";
import { useGroupTherapyClientPage } from "./_api/use-group-therapy-client-overview";
import { CurrentUserContext } from "@/app/app.utils";
import React from "react";

export const GroupClientProfile = () => {
  const cuser = React.useContext(CurrentUserContext);

  const { isLoading } = useGroupTherapyClientPage({
    select: (data) => selectGroupTherapyClientData(data, TIMEZONE(cuser)),
  });

  return !isLoading ? (
    <GroupClientProfileContainerDiv>
      <Text fontWeight={700} fontSize={24}>
        Client Profile
      </Text>
      <GroupClientProfileActiveTabs />
    </GroupClientProfileContainerDiv>
  ) : null;
};

const GroupClientProfileContainerDiv = styledStitches("div", {
  backgroundColor: "#fff",
  px: 20,
  pt: 24,
  width: "100%",
  height: "100%",
});
