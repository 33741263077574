import { styledStitches } from "@/app/design-system/styles/stitches.config";
import { CheckIcon } from "@/app/design-system/icons/components/check-icon";
import { useNotification } from "@/app/design-system";
import { TIMEZONE } from "@/app/my-clients/utils";
import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";
import { DateTime } from "luxon";
import { useState, useEffect } from "react";
import { useQueryClient } from "react-query";
import { selectDataForEditEventForm } from "../_api/use-fetch-cancellation-types-and-reasons/select-data-for-cancel-client-group-event";
import { useFetchCancellationTypesAndReasons } from "../_api/use-fetch-cancellation-types-and-reasons/use-fetch-cancellation-types-and-reasons";
import { useUpdateClientGroupEvent } from "../_api/use-update-client-group-event";
import { CancelClientGroupEventAction } from "./cancel-client-group-event-action";
import { ClientGroupEventModalDialog } from "./client-group-event-modal-dialog";
import { CurrentUserContext } from "@/app/app.utils";
import React from "react";

interface YesNoRadioGroupProps {
  clientFirstName: string;
  clientLastName: string;
  clientGroupEventId: string;
  appointmentStatus: string | undefined;
  eventStartTime: string;
  clientUnenrolledDate: string | null;
}

export const YesNoRadioGroup = ({
  clientFirstName,
  clientLastName,
  clientGroupEventId,
  appointmentStatus,
  eventStartTime,
  clientUnenrolledDate,
}: YesNoRadioGroupProps) => {
  const [attendanceValue, setAttendanceValue] = useState("");

  useEffect(() => {
    if (appointmentStatus === "attended") {
      setAttendanceValue("yes");
    } else if (
      appointmentStatus === "noshow" ||
      appointmentStatus === "canceled"
    ) {
      setAttendanceValue("no");
    } else {
      setAttendanceValue("");
    }
  }, [appointmentStatus]);

  const cuser = React.useContext(CurrentUserContext);

  const { renderNotification } = useNotification();

  const queryClient = useQueryClient();

  const updateClientGroupEventMutation = useUpdateClientGroupEvent();

  const isMarkedYes = attendanceValue === "yes";
  const isMarkedNo = attendanceValue === "no";

  const isUnenrolled = !!clientUnenrolledDate;

  const now = DateTime.now();

  const sessionStartTime = DateTime.fromISO(eventStartTime).setZone(TIMEZONE(cuser));
  const fiveAMOnSessionStartDate = sessionStartTime
    .startOf("day")
    .plus({ hours: 5 });
  const twoDaysAfterSessionStartTime = sessionStartTime.plus({
    days: 2,
  });

  const { isSuccess: isCancellationDataSuccess } =
    useFetchCancellationTypesAndReasons({
      select: selectDataForEditEventForm,
    });

  const canEditAttendance =
    now >= fiveAMOnSessionStartDate && now <= twoDaysAfterSessionStartTime;

  const handleMarkAttended = () => {
    updateClientGroupEventMutation.mutate(
      { clientGroupEventId, appointmentStatus: "attended" },
      {
        onError: () => {
          renderNotification({
            message: "There was an error marking this session as attended.",
            notificationType: "error",
          });
          queryClient.invalidateQueries();
        },
        onSuccess: () => {
          setAttendanceValue("yes");
          queryClient.invalidateQueries();
        },
      },
    );
  };

  return (
    <RadioGroup value={attendanceValue}>
      {canEditAttendance && !!appointmentStatus ? (
        <ClientGroupEventModalDialog
          appointmentStatus={appointmentStatus}
          clientGroupEventId={clientGroupEventId}
        >
          <StyledRadioYes
            value="yes"
            disabled={isUnenrolled}
            marked={isMarkedYes ? "markedYes" : "unmarked"}
            css={{ opacity: isUnenrolled ? 0.5 : 1 }}
          >
            Yes
            {isMarkedYes ? <CheckIcon /> : null}
          </StyledRadioYes>
        </ClientGroupEventModalDialog>
      ) : (
        <StyledRadioYes
          onClick={() => {
            if (!canEditAttendance) {
              renderNotification({
                message:
                  "Sessions can only be edited within 48 hours of the session ending.",
                notificationType: "warning",
              });
            } else {
              handleMarkAttended();
            }
          }}
          value="yes"
          disabled={isUnenrolled}
          marked={isMarkedYes ? "markedYes" : "unmarked"}
          css={{ opacity: isUnenrolled ? 0.5 : 1 }}
        >
          Yes
          {isMarkedYes ? <CheckIcon /> : null}
        </StyledRadioYes>
      )}

      {canEditAttendance && !!appointmentStatus ? (
        <ClientGroupEventModalDialog
          appointmentStatus={appointmentStatus}
          clientGroupEventId={clientGroupEventId}
        >
          <StyledRadioNo
            value="no"
            disabled={isUnenrolled || !isCancellationDataSuccess}
            marked={isMarkedNo ? "markedNo" : "unmarked"}
            css={{ opacity: isUnenrolled ? 0.5 : 1 }}
          >
            No
            {isMarkedNo ? <CheckIcon /> : null}
          </StyledRadioNo>
        </ClientGroupEventModalDialog>
      ) : (
        <CancelClientGroupEventAction
          callback={() => setAttendanceValue("no")}
          clientFirstName={clientFirstName}
          clientLastName={clientLastName}
          clientGroupEventId={clientGroupEventId}
          canEditAttendance={canEditAttendance}
        >
          <StyledRadioNo
            onClick={() => {
              if (!canEditAttendance) {
                renderNotification({
                  message:
                    "Sessions can only be edited within 48 hours of the session ending.",
                  notificationType: "warning",
                });
              }
            }}
            value="no"
            disabled={isUnenrolled || !isCancellationDataSuccess}
            marked={isMarkedNo ? "markedNo" : "unmarked"}
            css={{ opacity: isUnenrolled ? 0.5 : 1 }}
          >
            No
            {isMarkedNo ? <CheckIcon /> : null}
          </StyledRadioNo>
        </CancelClientGroupEventAction>
      )}
    </RadioGroup>
  );
};

const StyledRadio = styledStitches(RadioGroupPrimitive.Item, {
  background: "$neutral0",
  py: 10,
  border: "2px solid $neutral12",
  cursor: "pointer",
  gap: 8,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: 90,
  transition: "background-color 150ms",

  variants: {
    marked: {
      markedYes: {
        backgroundColor: "$green11",
        color: "$neutral0",
        "&:hover": {
          backgroundColor: "$green10",
          color: "neutral0",
        },
      },
      markedNo: {
        backgroundColor: "$neutral12",
        color: "$neutral0",
        "&:hover": {
          backgroundColor: "$neutral11",
          color: "neutral0",
        },
      },
      unmarked: {
        backgroundColor: "$neutral0",
        color: "$neutral12",
        "&:hover": {
          backgroundColor: "$neutral3",
          color: "neutral12",
        },
      },
    },
  },

  "&:active": {
    transform: "scale(0.985)",
  },
});

const StyledRadioYes = styledStitches(StyledRadio, {
  borderRight: "1px solid $neutral12",
  btlr: 8,
  bblr: 8,
  "&:disabled": {
    cursor: "not-allowed",
  },
});

const StyledRadioNo = styledStitches(StyledRadio, {
  borderLeft: "1px solid $neutral12",
  btrr: 8,
  bbrr: 8,
  "&:disabled": {
    cursor: "not-allowed",
  },
});

export const RadioGroup = styledStitches(RadioGroupPrimitive.Root, {
  display: "flex",
  flexDirection: "row",
});
