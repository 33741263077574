/**
 * This file is copied over from fulfiller/src/app/dashboard/utilization/discrete-billable-hours-averages-view.tsx
 * TODO(jeffreyqiu): removed this file after launching dashboard2.0 and the old dashboard table is deprecated
 */

import React from "react";

import { BillableHoursWeeksMap } from "../types";

import { DateRange } from "../../panel-management/types";

import { AverageValue, AveragesContainer } from "../_common";

import moment from "moment";
import { extractDateRangeFromDataset } from "../discrete-billable-hours/utils";
import { sum } from "lodash-es";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

export const RollingAveragesView = React.memo(
  (props: { data: BillableHoursWeeksMap; dateRange: DateRange }) => {
    const { data, dateRange } = props;

    const pastFourWeeksData: BillableHoursWeeksMap =
      extractDateRangeFromDataset(data, {
        start: moment().subtract(4, "weeks"),
        end: moment().subtract(1, "week"),
      });

    const pastThreeMonthsData: BillableHoursWeeksMap =
      extractDateRangeFromDataset(data, {
        start: moment().subtract(3, "months"),
        end: moment().subtract(1, "week"),
      });

    const inRangeData: BillableHoursWeeksMap = extractDateRangeFromDataset(
      data,
      dateRange,
    );
    let currentGoal = 0;

    if (pastFourWeeksData) {
      const lastWeek = Object.keys(inRangeData)
        .map((k) => inRangeData[k])
        .filter((k) => !!k?.isoweek)
        .sort((a, b) => (moment(a?.week) < moment(b?.week) ? -1 : 1))
        .pop();

      if (lastWeek) {
        currentGoal = lastWeek.target_client_hours || 0;
      }
    }

    return (
      <AveragesContainer style={{ width: 220 }}>
        <p>
          Weekly Avg. vs. Target{" "}
          <Tooltip title="Weekly average clinical hours include your attended therapy sessions + no show therapy sessions + allocated consult sessions (attended, no shows, unattended)">
            <QuestionCircleOutlined />
          </Tooltip>
        </p>
        <AverageValue
          label="Past 3 Months"
          value={`${transformDataToAverages(
            pastThreeMonthsData,
          )} / ${currentGoal}`}
          key="past3months"
        />
        <AverageValue
          label="Past 4 Weeks"
          value={`${transformDataToAverages(
            pastFourWeeksData,
          )} / ${currentGoal}`}
          key="past4weeks"
        />
        <AverageValue
          label={`Between ${moment(props.dateRange.start)
            .startOf("week")
            .format("MM/DD")} ~ ${moment(props.dateRange.end)
            .endOf("week")
            .format("MM/DD")}`}
          value={`${transformDataToAverages(inRangeData)} / ${currentGoal}`}
          key="between"
        />
      </AveragesContainer>
    );
  },
);

export function transformDataToAverages(data: BillableHoursWeeksMap) {
  const allData = Object.values(data).filter(
    (e) => e !== null,
  );
  const allValues = allData.map((e) => e!.hours_utilization);
  if (allValues.length)
    return Number(sum(allValues) / allValues.length).toFixed(1);
  return 0;
}
