import { graphqlClient } from "@/api/graphql";
import { useMyConsultsPageQuery } from "@/graphql/generated";
import { ONE_HOUR } from "@/app/_helpers/constants";
import { customSort, checkForAndAppendMedicareAndMedical } from "./_utils";
export const myConsultsQueryKey = "myConsultsPage";

export const useGetMyConsultsPageQuery = (clinician_ids: number[] | null) => {
  // Get the current date
  const currentDate = new Date();

  // Calculate 30 days in the past
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(currentDate.getDate() - 30);
  thirtyDaysAgo.setHours(0, 0, 0, 0);

  const isEnabled = (clinician_ids || []).length > 0;

  // Calculate 14 days in the future
  const fourteenDaysFromNow = new Date();
  fourteenDaysFromNow.setDate(currentDate.getDate() + 14);
  fourteenDaysFromNow.setHours(0, 0, 0, 0);
  const { data, isLoading, refetch, isError } = useMyConsultsPageQuery(
    graphqlClient,
    {
      clinician_ids: clinician_ids,
      lowerDate: thirtyDaysAgo,
      upperDate: fourteenDaysFromNow,
    },
    {
      queryKey: [myConsultsQueryKey],
      select: (data) => {
        const cutoffDate = new Date("2023-08-27T00:00:00Z");

        const filteredConsults = data.recent_consults.filter((consult) => {
          const startTime = new Date(consult.start_time);
          return consult.ehr_matches.length === 0 || startTime > cutoffDate;
        });

        data.recent_consults = filteredConsults;
        data.my_matches.sort(customSort);
        data.my_matches = checkForAndAppendMedicareAndMedical(data.my_matches);
        return data;
      },
      enabled: isEnabled,
      staleTime: ONE_HOUR,

    },
  );

  return { data, isLoading, refetch, isError };
};
