import React, { useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../../state/models";
import {
  matchmakerOperations,
  matchmakerActions,
} from "../../../../state/models/matchmaker";
import { Title } from "../../../_layout/display";
import { bucketSort } from "../../../_helpers";
import { Icon as LegacyIcon } from "@ant-design/compatible";
import { Checkbox, Divider } from "antd";
import { BENEFIT_TAG_WEIGHT, TagWeight } from "../../../../api/constants";
import { uniq } from "lodash-es";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import Row from "../../../_layout/Flex/Row";
import styled from "styled-components";
import {
  $whiteHover,
  $secondary,
  $greyBorder,
} from "../../../../assets/colors";
import Column from "../../../_layout/Flex/Column";

const StarButton = styled(Column)`
  min-width: 30px;
  max-width: 30px;
  height: 30px;
  font-size: 20px;
  cursor: pointer;
  border-radius: 50%;
  &:hover {
    background-color: ${$whiteHover};
  }
`;

export default () => {
  const { consultBenefitsTags, matchBenefitsTagIds } = useSelector(
    (state: AppState) => {
      const tagMap = state.matches.tagMap;
      const toTag = (tId: number) => tagMap[tId];

      const consultBenefitsTags =
        state.matchmaker.consultTagMap[BENEFIT_TAG_WEIGHT].map(toTag);

      return {
        consultBenefitsTags,
        matchBenefitsTagIds: state.matchmaker.matchTagMap[BENEFIT_TAG_WEIGHT],
      };
    },
  );

  const [benefitsBuckets] = useMemo(
    () => [uniq(consultBenefitsTags.map((tag) => tag.bucket))],
    [consultBenefitsTags],
  );

  const dispatch = useDispatch();
  const onTagCheckboxChange = useCallback(
    (tagId: number, weight: TagWeight, checked: boolean) => {
      switch (weight) {
        case BENEFIT_TAG_WEIGHT:
          dispatch(
            matchmakerOperations.setMatchTag({
              tagId,
              weight: BENEFIT_TAG_WEIGHT,
              checked,
            }),
          );
          break;
        default:
          throw new Error(`Unexpected tag weight: ${weight}`);
      }
    },
    [dispatch],
  );

  return (
    <>
      <Title size="small">Benefits</Title>
      {benefitsBuckets.sort(bucketSort).map((bucket) => (
        <div key={bucket} style={{ marginBottom: "10px" }}>
          <div>
            <i>{bucket}</i>
          </div>
          {consultBenefitsTags
            .filter((tag) => tag.bucket === bucket)
            .map((t) => (
              <CheckboxItem
                key={t.id}
                tagId={t.id}
                category={t.category}
                name={t.name}
                checked={matchBenefitsTagIds.includes(t.id)}
                onChange={(e) =>
                  onTagCheckboxChange(
                    t.id,
                    BENEFIT_TAG_WEIGHT,
                    e.target.checked,
                  )
                }
              />
            ))}
        </div>
      ))}
      {consultBenefitsTags.length === 0 && <div>None selected.</div>}
    </>
  );
};

interface CheckboxItemProps {
  onChange: (e: CheckboxChangeEvent) => void;
  checked: boolean;
  disabled?: boolean;
  category: string;
  name: string;
  tagId: number;
}

const CheckboxItem = ({
  onChange,
  checked,
  disabled,
  category,
  name,
  tagId,
}: CheckboxItemProps) => (
  <>
    <Row style={{ minHeight: "30px" }}>
      <Checkbox
        {...{
          onChange,
          checked,
          disabled,
          style: { flex: 1 },
        }}
      >
        {category} - <strong>{name}</strong>
      </Checkbox>
      {checked && <TagStar tagId={tagId} />}
    </Row>
    <Divider style={{ margin: "8px 0 5px" }} />
  </>
);

const TagStar = ({ tagId }: { tagId: number }) => {
  const starredTagIds = useSelector(
    (state: AppState) => state.matchmaker.starredTagIds,
  );
  const dispatch = useDispatch();
  const toggleTagStarred = useCallback(
    (tagId: number) => {
      if (starredTagIds.includes(tagId)) {
        dispatch(
          matchmakerActions.setStarredTagIds(
            starredTagIds.filter((id) => id !== tagId),
          ),
        );
      } else {
        dispatch(matchmakerActions.setStarredTagIds([...starredTagIds, tagId]));
      }
    },
    [dispatch, starredTagIds],
  );
  const tagIsStarred = starredTagIds.includes(tagId);
  if (starredTagIds.length > 2 && !tagIsStarred) {
    return null;
  }

  return (
    <StarButton layout="center center" onClick={() => toggleTagStarred(tagId)}>
      <LegacyIcon
        type="star"
        style={{ color: tagIsStarred ? $secondary : $greyBorder }}
        theme={tagIsStarred ? "filled" : "outlined"}
      />
    </StarButton>
  );
};
