import { Spin } from "antd";
import * as React from "react";
import * as ReactRedux from "react-redux";
import styled from "styled-components";

import { $antdBorder } from "../../assets/colors";
import { AppState } from "../../state/models";
import { HintTextDiv } from "./_shared";
import Modal from "./modals/CellModal";
import { ModalProvider } from "./modals/CellModalProvider";
import PanelColumn from "./PanelColumn";
import {
  ClientRowIndices,
  ClinicianWeeksMap,
  DateRange,
  PanelSessionsMap,
  ClientsMap,
} from "./types";

interface StateProps {
  dateRange: DateRange;
  weeksMap: ClinicianWeeksMap;
  sessionsMap: PanelSessionsMap;
  clientsMap: ClientsMap;
  isLoading: boolean;
  clientRowIndices: ClientRowIndices;
  clinicianId?: number;
}

class Panel extends React.Component<StateProps> {
  public render() {
    const weekColumns = this.generateColumns();
    let renderedPanelContent = null;
    if (!this.props.isLoading) {
      if (Object.entries(this.props.clientRowIndices).length !== 0) {
        renderedPanelContent = (
          <PanelContainer>
            <PanelColumn
              type="clients"
              key={0}
              clients={Object.values(this.props.clientsMap)}
            />
            {weekColumns}
          </PanelContainer>
        );
      } else {
        renderedPanelContent = (
          <PanelContainer style={{ width: "100%" }}>
            <HintTextDiv>No clients matching active filters found.</HintTextDiv>
          </PanelContainer>
        );
      }
    }

    return (
      <ModalProvider>
        <Container isLoading={this.props.isLoading}>
          <Spin size="large" spinning={this.props.isLoading}>
            {renderedPanelContent}
            <Modal />
          </Spin>
        </Container>
      </ModalProvider>
    );
  }

  private generateColumns() {
    const columns: React.ReactNode[] = [];
    for (const key in this.props.weeksMap) {
      if (this.props.weeksMap.hasOwnProperty(key)) {
        const week = this.props.weeksMap[key];
        const reactKey = `${week.year}${week.week_number}`;
        columns.push(
          <PanelColumn
            type="week"
            week={week}
            key={reactKey}
            sessionsMap={this.props.sessionsMap}
          />,
        );
      }
    }
    return columns;
  }
}

interface ContainerProps {
  isLoading: boolean;
}

const Container = styled.div<ContainerProps>`
  min-width: 100%;
  min-height: 100%;
  border: 1px solid ${$antdBorder};
  border-radius: 4px;
  background-color: white;
  overflow-x: scroll;
  overscroll-behavior-x: none;

  ${(p) =>
    p.isLoading &&
    `display: flex;
    align-items: center;
    justify-content: center;`}
`;

const PanelContainer = styled.div`
  display: flex;
  width: fit-content;
`;

const mapStateToProps: ReactRedux.MapStateToProps<StateProps, {}, AppState> = (
  state,
) => {
  return {
    dateRange: state.panel.dateRange,
    weeksMap: state.panel.weeksMap,
    clinicianId: state.panel.clinicianId,
    sessionsMap: state.panel.sessionsMap,
    clientsMap: state.panel.clientsMap,
    filteredClientIds: state.panel.filteredClientIds,
    isLoading: state.panel.isLoading,
    clientRowIndices: state.panel.clientRowIndices,
  };
};

export default ReactRedux.connect(mapStateToProps)(Panel);
