import React from "react";
import SlotsByDayCheckboxTable from "./SlotsByDayCheckboxTable";
import { useShallowEqualSelector } from "../../../../_helpers/redux";
import { Spin } from "antd";
import { Pad } from "../../../../_layout/display";
import { Row, Flex } from "../../../../_layout/Flex";
import { Button } from "antd";
import { matchmakerUtils } from "../../../../../state/models/matchmaker";
import { LocationContainer, styles, LocationRowHeader } from "./styled";

export default () => {
  const [loading] = useShallowEqualSelector(state => [
    state.matchmaker.loading.clientPreferences,
  ]);

  const { showOtFits } = useShallowEqualSelector(state =>
    matchmakerUtils.matchOtSelector(state.matchmaker),
  );

  const [showGeneral, updateShowGeneral] = React.useState(true);

  if (loading) {
    return (
      <div
        style={{
          width: "100%",
          height: "250px",
          textAlign: "center",
          padding: "100px 50px",
        }}
      >
        <Spin tip="Getting couple's schedule preferences..." size="large" />
      </div>
    );
  }

  return (
    <Pad>
      <LocationContainer>
        <Row layout="space-between center" style={styles.locationRowStyle}>
          <LocationRowHeader>
            {showOtFits ? "Teletherapy" : "General"} Schedule Preferences
          </LocationRowHeader>
          <Flex />
          <Button
            type={!showGeneral ? "primary" : "default"}
            onClick={() => updateShowGeneral(!showGeneral)}
          >
            {!showGeneral ? "Customize" : "Done"}
          </Button>
        </Row>
        {showGeneral && <SlotsByDayCheckboxTable stateKey={"general"} />}
      </LocationContainer>
    </Pad>
  );
};
