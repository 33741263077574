import { cancellationTypeToFrontendValueMap } from "@/app/my-clients/routes/client-profile-page/client-profile-nav/routes/schedule-page/components/2-session-history-section/session-panel/components/session-cancellation-type";
import { getCancellationReasonOptions } from "./event-cancellation-reasons.utils";

import {
  CANCELED_BY_CLIENT_HIDDEN_OPTIONS,
  CANCELED_BY_CLINICIAN_HIDDEN_OPTIONS,
  NO_SHOW_OPTION,
  CancellationReasonsAndTypesApiData,
} from "./event-cancellation-types.constants";

export interface SelectFieldOption {
  id: string;
  label: string;
  value: string;
}

export interface CancellationReasonOptions {
  cancelByClientReasonOptions: SelectFieldOption[];
  lateCancelByClientReasonOptions: SelectFieldOption[];
  cancelByClinicianReasonOptions: SelectFieldOption[];
  cancelByTwoChairsReasonOptions: SelectFieldOption[];
  noShowReasonOption: SelectFieldOption[];
}

export interface SelectDataForEditEventFormReturn {
  cancellationTypeOptions: SelectFieldOption[];
  cancellationReasonOptions: CancellationReasonOptions;
}

export const selectDataForEditEventForm = (
  data: CancellationReasonsAndTypesApiData,
): SelectDataForEditEventFormReturn => {
  const cancelByClientReasonOptions = getCancellationReasonOptions(
    data?.by_client.filter(
      (cancelReason) =>
        !CANCELED_BY_CLIENT_HIDDEN_OPTIONS.includes(cancelReason),
    ),
  );

  const cancelByClinicianReasonOptions = getCancellationReasonOptions(
    data?.by_clinician.filter(
      (cancelReason) =>
        !CANCELED_BY_CLINICIAN_HIDDEN_OPTIONS.includes(cancelReason),
    ),
  );

  const lateCancelByClientReasonOptions = getCancellationReasonOptions(
    data?.late_by_client,
  );

  const cancelByTwoChairsReasonOptions = getCancellationReasonOptions(
    data?.by_twochairs,
  );

  const cancellationTypeOptions = Object.keys(data).map((cancellationType) => {
    return {
      id: cancellationType,
      value: cancellationTypeToFrontendValueMap[cancellationType],
      label: cancellationTypeToFrontendValueMap[cancellationType],
    };
  });

  cancellationTypeOptions.push(NO_SHOW_OPTION);

  return {
    cancellationTypeOptions,
    cancellationReasonOptions: {
      cancelByClientReasonOptions,
      lateCancelByClientReasonOptions,
      cancelByClinicianReasonOptions,
      cancelByTwoChairsReasonOptions,
      noShowReasonOption: [NO_SHOW_OPTION],
    },
  };
};
