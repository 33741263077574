import {
  Flex,
  Stack,
  styledStitches,
  Text,
} from "@/app/design-system";
import { DownChevronIcon, MBCTrackStatusIcon } from "@/app/design-system/icons";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/app/design-system/popover/popover.styled";

import React, { useState } from "react";

export const StyledPopoverButton = styledStitches("button", {
  backgroundColor: "transparent",
  border: "none",
  pl: 16,
  py: 4,
  pr: 30,
  position: "relative",
  cursor: "pointer",

  "&:focus": {
    outline: "none",
    transform: "none",
  },

  ".caret-icon": {
    transition: "transform ease-in-out 0.25s",
    position: "absolute",
    right: 4,
    top: 4,
  },

  variants: {
    open: {
      true: {
        ".caret-icon": {
          transition: "transform ease-in-out 0.25s",
          transform: "rotate(-180deg)",
        },
      },
    },
  },
});

export function MBCTrendKeyInfoPopover() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <StyledPopoverButton
          open={isOpen}
          aria-label="client card sort dropdown button"
          css={{
            height: 34,
          }}
        >
          <Text fontWeight={700}>Show color key</Text>
          <DownChevronIcon className="caret-icon" />
        </StyledPopoverButton>
      </PopoverTrigger>
      <PopoverContent sideOffset={5} align="start" alignOffset={16}>
        <Stack gap={16} css={{ p: 24, borderRadius: 8 }}>
          <Text color={"$neutral9"} fontSize={12}>
            STATUS KEY
          </Text>
          <Flex css={{ width: 560 }} gap={16}>
            <Stack gap={16}>
              <Flex gap={12}>
                <Stack css={{ pt: 8 }}>
                  <MBCTrackStatusIcon fill="#006ADC" />
                </Stack>

                <Stack>
                  <Text fontWeight={700} color={"$neutral11"} fontSize={13}>
                    Ready to graduate
                  </Text>
                  <Text fontSize={13} color={"$neutral9"}>
                    Review treatment goals, consider graduation.
                  </Text>
                </Stack>
              </Flex>

              <Flex gap={12}>
                <Stack css={{ pt: 8 }}>
                  <MBCTrackStatusIcon fill="#18794E" />
                </Stack>
                <Stack>
                  <Text fontWeight={700} color={"$neutral11"} fontSize={13}>
                    Improvement
                  </Text>
                  <Text fontSize={13} color={"$neutral9"}>
                    No change needed.
                  </Text>
                </Stack>
              </Flex>

              <Flex gap={12}>
                <Stack css={{ pt: 8 }}>
                  <MBCTrackStatusIcon fill="#F7CE00" />
                </Stack>
                <Stack>
                  <Text fontWeight={700} color={"$neutral11"} fontSize={13}>
                    Stagnation
                  </Text>
                  <Text fontSize={13} color={"$neutral9"}>
                    Consider updating treatment plan, consultation, additional
                    resources.
                  </Text>
                </Stack>
              </Flex>
            </Stack>

            <Stack gap={16}>
              <Flex gap={12}>
                <Stack css={{ pt: 8 }}>
                  <MBCTrackStatusIcon fill="#CD2B31" />
                </Stack>
                <Stack>
                  <Text fontWeight={700} color={"$neutral11"} fontSize={13}>
                    Escalation
                  </Text>
                  <Text fontSize={13} color={"$neutral9"}>
                    Consider reviewing case, consultation, updating treatment
                    plan.
                  </Text>
                </Stack>
              </Flex>

              <Flex gap={12}>
                <Stack css={{ pt: 8 }}>
                  <MBCTrackStatusIcon fill="#F76808" />
                </Stack>
                <Stack>
                  <Text fontWeight={700} color={"$neutral11"} fontSize={13}>
                    Suicidality
                  </Text>
                  <Text fontSize={13} color={"$neutral9"}>
                    Client endorsed SI - conduct risk assessment and complete
                    safety plan.
                  </Text>
                </Stack>
              </Flex>
            </Stack>
          </Flex>
        </Stack>
      </PopoverContent>
    </Popover>
  );
}
