import { useShallowEqualSelector } from "@/app/_helpers/redux";
import { Button, message, Modal, notification } from "antd";
import * as React from "react";
import { useDispatch } from "react-redux";
import { $border } from "../../../assets/colors";
import {
  consultNoteActions,
  consultNoteOperations,
} from "../../../state/models/consultnote";
import { matchOperations } from "../../../state/models/matches";
import { Title } from "../../_layout/display";
import { getCurrentConsultNoteInfo, useWindowSize } from "../_hooks";
import Footer from "./Components/ConsultNoteFooter";
import BasicInfo from "./ConsultNoteQuestions/BasicInfo";
import MentalStatusQuestion from "./ConsultNoteQuestions/MentalStatusQuestion";
import {
  group_clinically_appropriate_no_reason_checkboxes,
  referred_out_reason_checkboxes,
} from "./ConsultNoteQuestions/MultiSelect/enums";
import { questions } from "./ConsultNoteQuestions/questionData";
import {
  CriterionTraumaQuestion,
  DementiaQuestion,
  DemographicPreferencesQuestion,
  FunctionalImpairmentQuestion,
  HighRiskClientsQuestion,
  InterpersonalViolenceQuestion,
  MultiSelect,
  OpenResponseQuestion,
  PastPresentFocusQuestion,
  PhysicalLocationQuestion,
  PresentingProblemsQuestion,
  ReadinessToEngageQuestion,
  SelfInjuriousBehaviorQuestion,
  SingleDropdownQuestion,
  SubstanceUseQuestion,
  TeletherapyChecklistQuestion,
  TherapyPreferencesQuestion,
} from "./ConsultNoteQuestions/Questions";
import QuestionTitle from "./ConsultNoteQuestions/shared/QuestionTitle";

const styles: { [key: string]: React.CSSProperties } = {
  steps: {
    padding: "15px",
    minHeight: "60px",
    borderBottom: "1px solid " + $border,
  },
  modalContainer: { top: "25px", margin: "0 auto" },
  modalBody: {
    maxHeight: "calc(100vh - 160px)",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    padding: "25px",
    color: "rgba(0, 0, 0, 0.85)",
  },
  footer: {
    backgroundColor: "white",
  },
};

const ConsultNote = () => {
  const { consultNoteOpen, eventId, readOnlyConsultNote } =
    getCurrentConsultNoteInfo();

  const [isSaving, setIsSaving] = React.useState(false);
  const [hasBeenEdited, setHasBeenEdited] = React.useState<boolean>(false);

  const [
    group_discussed,
    group_discussed_no_reason,
    group_clinically_appropriate,
    group_clinically_appropriate_no_reason,
    group_two_chairs_appropriate,
    group_two_chairs_appropriate_no_reason,
    group_two_chairs_appropriate_yes_intention,
    group_two_chairs_appropriate_yes_intention_no_reason,
    referred_out_text,
    referred_out_reason,
    referred_out_required_care,
    referred_out_continuing_care,
    currentUser,
  ] = useShallowEqualSelector((state) => [
    state.consultnote?.openConsult?.consult_note?.group_discussed,
    state.consultnote?.openConsult?.consult_note?.group_discussed_no_reason,
    state.consultnote?.openConsult?.consult_note?.group_clinically_appropriate,
    state.consultnote?.openConsult?.consult_note
      ?.group_clinically_appropriate_no_reason,
    state.consultnote?.openConsult?.consult_note?.group_two_chairs_appropriate,
    state.consultnote?.openConsult?.consult_note
      ?.group_two_chairs_appropriate_no_reason,
    state.consultnote?.openConsult?.consult_note
      ?.group_two_chairs_appropriate_yes_intention,
    state.consultnote?.openConsult?.consult_note
      ?.group_two_chairs_appropriate_yes_intention_no_reason,
    state.consultnote?.openConsult?.consult_note?.referred_out_text,
    state.consultnote?.openConsult?.consult_note?.referred_out_reason,
    state.consultnote?.openConsult?.consult_note?.referred_out_required_care,
    state.consultnote?.openConsult?.consult_note?.referred_out_continuing_care,
    state.auth.currentUser,
  ]);

  const dispatch = useDispatch();

  const showGroups =
    currentUser?.clinician?.service_states?.includes("WA")

  const closeButton = (
    <>
      <Button
        onClick={async () => {
          setIsSaving(true);
          dispatch(consultNoteOperations.saveConsultNote(eventId, false));
          dispatch(consultNoteActions.closeConsultNote());

          setIsSaving(false);
          handleEdit(false);

          notification.close("saveWithoutEditingNotification");
          message.destroy();
        }}
        style={{ backgroundColor: "#52BD95", color: "white" }}
        loading={isSaving}
      >
        Save and Exit
      </Button>

      <Button
        onClick={() => {
          dispatch(consultNoteActions.closeConsultNote());
          handleEdit(false);
          notification.close("saveWithoutEditingNotification");
        }}
        style={{ backgroundColor: "#D14343", color: "white" }}
      >
        Exit Without Saving
      </Button>
    </>
  );

  const closeConsultNote = () => {
    if (hasBeenEdited && !readOnlyConsultNote) {
      notification.open({
        message: "Exit without saving?",
        description: "You're about to close your notes without saving.",
        btn: closeButton,
        placement: "topRight",
        key: "saveWithoutEditingNotification",
      });
    } else {
      message.destroy();
      dispatch(consultNoteActions.closeConsultNote());
    }
  };

  const handleSave = async (submitting?: boolean) => {
    setIsSaving(true);
    dispatch(consultNoteOperations.saveConsultNote(eventId, submitting));

    handleEdit(false);
    setIsSaving(false);
  };

  const clearFields = (fieldsToClear: string[]) => {
    fieldsToClear.forEach((field: string) => {
      dispatch(
        consultNoteActions.setConsultNoteValue({
          key: field,
          value: "",
        }),
      );
    });
  };

  // Conditional Field cleaning
  // Groups
  React.useEffect(() => {
    if (group_discussed !== "No") {
      clearFields(["group_discussed_no_reason"]);
    }
  }, [group_discussed]);

  React.useEffect(() => {
    if (!group_discussed_no_reason?.includes("Other")) {
      clearFields(["group_discussed_other_description"]);
    }
  }, [group_discussed_no_reason]);

  React.useEffect(() => {
    if (group_clinically_appropriate !== "No") {
      clearFields(["group_clinically_appropriate_no_reason"]);
    }
  }, [group_clinically_appropriate]);

  React.useEffect(() => {
    if (!group_clinically_appropriate_no_reason?.includes("Other")) {
      clearFields(["group_clinically_appropriate_other_description"]);
    }
  }, [group_clinically_appropriate_no_reason]);

  React.useEffect(() => {
    if (group_two_chairs_appropriate !== "No") {
      clearFields(["group_two_chairs_appropriate_no_reason"]);
    }
    if (group_two_chairs_appropriate != "Yes") {
      clearFields(["group_two_chairs_appropriate_yes_intention"]);
    }
  }, [group_two_chairs_appropriate]);

  React.useEffect(() => {
    if (!group_two_chairs_appropriate_no_reason?.includes("Other")) {
      clearFields(["group_two_chairs_appropriate_other_description"]);
    }
  }, [group_two_chairs_appropriate_no_reason]);

  React.useEffect(() => {
    if (group_two_chairs_appropriate_yes_intention !== "No") {
      clearFields(["group_two_chairs_appropriate_yes_intention_no_reason"]);
    }
  }, [group_two_chairs_appropriate_yes_intention]);

  React.useEffect(() => {
    if (
      !group_two_chairs_appropriate_yes_intention_no_reason?.includes("Other")
    ) {
      clearFields([
        "group_two_chairs_appropriate_yes_intention_other_description",
      ]);
    }
  }, [group_two_chairs_appropriate_yes_intention_no_reason]);

  // Referrals
  React.useEffect(() => {
    if (referred_out_text !== "Yes") {
      clearFields([
        "referred_out_reason",
        "referred_out_other_description",
        "referred_out_required_care",
        "referred_out_required_care_other_description",
        "referred_out_continuing_care",
        "referred_out_continuing_care_other_description",
        "referred_out_client_comms",
        "referred_out_urgent",
        "referred_out_specificity",
        "referred_out_gender_preference",
        "referred_out_additional_context",
      ]);
    }
  }, [referred_out_text]);

  React.useEffect(() => {
    if (!referred_out_reason?.includes("Other")) {
      clearFields(["referred_out_other_description"]);
    }
  }, [referred_out_reason]);

  React.useEffect(() => {
    if (!referred_out_required_care?.includes("Other")) {
      clearFields(["referred_out_required_care_other_description"]);
    }
  }, [referred_out_required_care]);

  React.useEffect(() => {
    if (!referred_out_continuing_care?.includes("Other")) {
      clearFields(["referred_out_continuing_care_other_description"]);
    }
  }, [referred_out_continuing_care]);

  const handleEdit = (hasBeenEdited: boolean) => {
    setHasBeenEdited(hasBeenEdited);
  };

  const questionMap = Object.assign(
    {},
    ...questions.map((s) => ({ [s.questionNumber]: s })),
  );

  const windowSize = useWindowSize();

  const modalWidth = windowSize ? "72%" : "90%";

  React.useEffect(() => {
    if (eventId) {
      document.addEventListener("keydown", (e) => {
        if (e.code === "KeyS" && (e.metaKey || e.ctrlKey)) {
          if (hasBeenEdited) {
            dispatch(consultNoteOperations.saveConsultNote(eventId, false));
            handleEdit(false);
          }
          e.preventDefault();
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId, hasBeenEdited]);

  return (
    <Modal
      title="Consult Note"
      open={consultNoteOpen}
      maskClosable={false}
      width={modalWidth}
      style={styles.modalContainer}
      bodyStyle={styles.modalBody}
      onCancel={closeConsultNote}
      footer={
        !readOnlyConsultNote && (
          <Footer
            closeConsultNote={closeConsultNote}
            handleSave={handleSave}
            isSaving={isSaving}
          />
        )
      }
    >
      <BasicInfo />

      <OpenResponseQuestion
        question={questionMap[1]}
        isTextArea={true}
        handleEdit={handleEdit}
      />

      <SingleDropdownQuestion
        question={questionMap[2]}
        handleEdit={handleEdit}
      />

      <SingleDropdownQuestion
        question={questionMap[3]}
        handleEdit={handleEdit}
      />

      <TeletherapyChecklistQuestion
        question={questionMap[4]}
        handleEdit={handleEdit}
      />

      <PhysicalLocationQuestion
        question={questionMap[5]}
        handleEdit={handleEdit}
      />

      <PresentingProblemsQuestion
        question={questionMap[6]}
        handleEdit={handleEdit}
      />

      <SelfInjuriousBehaviorQuestion
        question={questionMap[7]}
        handleEdit={handleEdit}
      />

      <HighRiskClientsQuestion
        question={questionMap[7.5]}
        handleEdit={handleEdit}
      />

      <DementiaQuestion question={questionMap[8]} handleEdit={handleEdit} />

      <InterpersonalViolenceQuestion
        question={questionMap[9]}
        handleEdit={handleEdit}
      />

      <SubstanceUseQuestion
        question={questionMap[10]}
        handleEdit={handleEdit}
      />

      <CriterionTraumaQuestion
        question={questionMap[11]}
        handleEdit={handleEdit}
      />

      <OpenResponseQuestion
        question={questionMap[12]}
        isTextArea={true}
        handleEdit={handleEdit}
      />

      <FunctionalImpairmentQuestion
        question={questionMap[13]}
        handleEdit={handleEdit}
      />

      <SingleDropdownQuestion
        question={questionMap[14]}
        handleEdit={handleEdit}
      />

      <SingleDropdownQuestion
        question={questionMap[15]}
        handleEdit={handleEdit}
      />

      <OpenResponseQuestion
        question={questionMap[16]}
        handleEdit={handleEdit}
      />

      <OpenResponseQuestion
        question={questionMap[17]}
        isTextArea={true}
        handleEdit={handleEdit}
      />

      <OpenResponseQuestion
        question={questionMap[18]}
        isTextArea={true}
        handleEdit={handleEdit}
      />

      <OpenResponseQuestion
        question={questionMap[19]}
        isTextArea={true}
        handleEdit={handleEdit}
      />

      <ReadinessToEngageQuestion
        question={questionMap[20]}
        handleEdit={handleEdit}
      />

      {!showGroups && (
        <QuestionTitle
          questionNumber={210}
          hideQuestionNumber={true}
          titleText="Questions 21 - 23"
          subtitleText=": These questions are only visible to Clinicians who are taking Triage Consults"
        />
      )}
      {showGroups && (
        <>
          {/* Did you discuss group therapy with the client? */}
          <SingleDropdownQuestion
            question={questionMap[21]}
            handleEdit={handleEdit}
          />
          {/* If no, why not? */}
          {group_discussed === "No" && (
            <>
              <SingleDropdownQuestion
                question={questionMap[21.1]}
                handleEdit={handleEdit}
              />
              {/* Other (Please describe): __________________ */}
              {group_discussed_no_reason === "Other" && (
                <OpenResponseQuestion
                  question={questionMap[21.2]}
                  isTextArea={true}
                  handleEdit={handleEdit}
                />
              )}
            </>
          )}

          {/* Was the client clinically appropriate for groups? */}
          <SingleDropdownQuestion
            question={questionMap[22]}
            handleEdit={handleEdit}
          />
          {/* If no, why not? (Select all that apply) */}
          {group_clinically_appropriate === "No" && (
            <>
              <MultiSelect
                question={questionMap[22.1]}
                handleEdit={handleEdit}
                options={group_clinically_appropriate_no_reason_checkboxes}
              />
              {/* Other (Please describe): __________________ */}
              {group_clinically_appropriate_no_reason?.includes("Other") && (
                <OpenResponseQuestion
                  question={questionMap[22.2]}
                  isTextArea={true}
                  handleEdit={handleEdit}
                />
              )}
            </>
          )}

          {/* Was the client appropriate for Two Chairs groups? */}
          <SingleDropdownQuestion
            question={questionMap[23]}
            handleEdit={handleEdit}
          />
          {/* If no, why not? */}
          {group_two_chairs_appropriate === "No" && (
            <>
              <SingleDropdownQuestion
                question={questionMap[23.1]}
                handleEdit={handleEdit}
              />
              {/* Other (Please describe): __________________ */}
              {group_two_chairs_appropriate_no_reason === "Other" && (
                <OpenResponseQuestion
                  question={questionMap[23.2]}
                  isTextArea={true}
                  handleEdit={handleEdit}
                />
              )}
            </>
          )}
          {group_two_chairs_appropriate === "Yes" && (
            <>
              {/* If yes, will the client enroll in Two Chairs groups? */}
              <SingleDropdownQuestion
                question={questionMap[23.3]}
                handleEdit={handleEdit}
              />
              {group_two_chairs_appropriate_yes_intention === "No" && (
                <>
                  {/* If no, why not? */}
                  <SingleDropdownQuestion
                    question={questionMap[23.4]}
                    handleEdit={handleEdit}
                  />
                  {group_two_chairs_appropriate_yes_intention_no_reason ===
                    "Other" && (
                    <>
                      {/* Other (Please describe): __________________*/}
                      <OpenResponseQuestion
                        question={questionMap[23.5]}
                        isTextArea={true}
                        handleEdit={handleEdit}
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}

      {/* Does this client need additional or alternative care outside of Two Chairs? */}
      <SingleDropdownQuestion
        question={questionMap[24]}
        handleEdit={handleEdit}
      />

      {referred_out_text === "Yes" && (
        <>
          {/* What is the reason for referring? */}
          <MultiSelect
            question={questionMap[24.1]}
            handleEdit={handleEdit}
            options={referred_out_reason_checkboxes}
          />
          {/* Please explain */}
          {referred_out_reason?.includes("Other") && (
            <OpenResponseQuestion
              question={questionMap[24.2]}
              isTextArea={true}
              handleEdit={handleEdit}
            />
          )}

          {/* What type of care does this client need? */}
          <SingleDropdownQuestion
            question={questionMap[24.3]}
            handleEdit={handleEdit}
          />
          {/* Please explain */}
          {referred_out_required_care?.includes("Other") && (
            <OpenResponseQuestion
              question={questionMap[24.4]}
              isTextArea={true}
              handleEdit={handleEdit}
            />
          )}

          {/* Is this client continuing care at Two Chairs? */}
          <SingleDropdownQuestion
            question={questionMap[24.5]}
            handleEdit={handleEdit}
          />
          {/* Please explain */}
          {referred_out_continuing_care?.includes("Other") && (
            <OpenResponseQuestion
              question={questionMap[24.6]}
              isTextArea={true}
              handleEdit={handleEdit}
            />
          )}

          {/* Have you communicated this referral recommendation to the client? */}
          <SingleDropdownQuestion
            question={questionMap[24.7]}
            handleEdit={handleEdit}
            info={
              <div style={{ marginBottom: 15 }}>
                <em>
                  It is important that clients are informed of the referral
                  recommendation directly from their clinician. This
                  communication should include a safety plan when appropriate.
                  Care Navigation will reach out to the client within 1 business
                  day of receiving this request, so we ask that you contact the
                  client before submitting this request.
                </em>
              </div>
            }
          />

          {/* Is there a specific facility or clinician this client should be referred to? */}
          <OpenResponseQuestion
            question={questionMap[24.8]}
            isTextArea={true}
            handleEdit={handleEdit}
          />

          {/* Does the client have a gender preference for the provider they are referred to? */}
          <OpenResponseQuestion
            question={questionMap[24.9]}
            isTextArea={true}
            handleEdit={handleEdit}
          />

          {/* Is there any additional context important for getting this client connected to the appropriate care provider? */}
          <OpenResponseQuestion
            question={questionMap[24.11]}
            isTextArea={true}
            handleEdit={handleEdit}
          />
        </>
      )}

      <PastPresentFocusQuestion
        question={questionMap[25]}
        handleEdit={handleEdit}
      />

      <Title>
        <u>Therapy Preferences</u>
        <div>
          <i style={{ fontSize: "12px" }}>
            This is purely for your note taking if helpful - these responses
            will NOT show up in the Consult Note in Welkin. Welkin will pull
            whatever tags you enter from the Matchmaker instead.
          </i>
        </div>
      </Title>

      <TherapyPreferencesQuestion
        question={questionMap[26]}
        fields={["structure_preference", "structure_intensity"]}
        handleEdit={handleEdit}
      />

      <TherapyPreferencesQuestion
        question={questionMap[27]}
        fields={["directiveness_preference", "directiveness_intensity"]}
        handleEdit={handleEdit}
      />

      <TherapyPreferencesQuestion
        question={questionMap[28]}
        fields={["homework_preference", "homework_intensity"]}
        handleEdit={handleEdit}
      />

      <TherapyPreferencesQuestion
        question={questionMap[29]}
        fields={["expressiveness_preference", "expressiveness_intensity"]}
        handleEdit={handleEdit}
      />

      <DemographicPreferencesQuestion
        question={questionMap[30]}
        handleEdit={handleEdit}
      />

      <SingleDropdownQuestion
        question={questionMap[31]}
        handleEdit={handleEdit}
      />

      <MentalStatusQuestion
        question={questionMap[32]}
        handleEdit={handleEdit}
      />

      <MentalStatusQuestion
        question={questionMap[33]}
        handleEdit={handleEdit}
      />

      <MentalStatusQuestion
        question={questionMap[34]}
        handleEdit={handleEdit}
      />

      <OpenResponseQuestion
        question={questionMap[35]}
        isTextArea={true}
        handleEdit={handleEdit}
      />
    </Modal>
  );
};

export { ConsultNote };
