import { DateTime } from "luxon";

export const formattedSessionAppointmentDateTime = (
  appointmentDatetime: string,
  timezone: string,
) => {
  const dt = DateTime.fromISO(appointmentDatetime, { zone: timezone }).setZone(
    timezone,
  );

  const date = dt.toLocaleString({
    month: "short",
    day: "numeric",
  });

  const day = dt.toLocaleString({
    weekday: "long",
  });

  const time = dt.toFormat("ha");

  const year = dt.toLocaleString({
    year: "numeric",
  });

  return {
    date,
    day,
    time,
    year,
  };
};
