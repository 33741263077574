import React from "react";
import {
  initialPayerFilterState,
  PayerFilterState,
  selectPayerFiltersSlice,
  useMyClientsFilterStore,
} from "../../../../../../my-clients-model";

export const usePayerFilterDropdown = () => {
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

  const payerFilterState = useMyClientsFilterStore(selectPayerFiltersSlice);

  const numberOfSelectedFilters = [...Object.values(payerFilterState)].filter(
    (filter) => filter,
  ).length;

  const setMyClientsFilter = useMyClientsFilterStore(
    (state) => state.setMyClientsFilter,
  );

  const clearPayerFilters = () => {
    for (const key in initialPayerFilterState) {
      setMyClientsFilter(key as keyof PayerFilterState, false);
    }

    setIsDropdownOpen(false);
  };

  return {
    isDropdownOpen,
    setIsDropdownOpen,
    setMyClientsFilter,
    clearPayerFilters,
    numberOfSelectedFilters,
    ...payerFilterState,
  };
};
