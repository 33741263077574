import * as React from "react";
import * as ReactRedux from "react-redux";
import styled from "styled-components";

import { $antdBorder } from "../../assets/colors";

import { AppState, Dispatcher } from "../../state/models";
import { authOperations, getCurrentUser } from "../../state/models/auth";
import LoginForm from "./component";

interface StateProps {
  loginError?: string;
}

interface DispatchProps {
  googleLogin: (token: string, error?: string) => void;
  legacyLogin: (username: string, password: string) => void;
  getCurrentUser: () => void;
}

interface LoginProps extends StateProps, DispatchProps {}

class LoginContainer extends React.Component<LoginProps> {
  public componentDidMount() {
    if (localStorage.getItem("isLoggedIn") ?? true) {
      this.props.getCurrentUser();
    }
  }

  public render() {
    return (
      <Wrapper>
        <Container>
          <div data-testid="login">
            <LoginForm
              googleLoginFunction={this.props.googleLogin}
              legacyLoginFunction={this.props.legacyLogin}
              loginError={this.props.loginError}
            />
          </div>
          <HeroImage src="https://storage.googleapis.com/chronicler_static/static/two-chairs__login-hero-image.png" />
        </Container>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  width: 100vw;
  height: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  height: 600px;
  margin: 0 5%;
  min-width: 900px;
  display: flex;
  background-color: #fff;
  overflow: hidden;
  border: 1px solid ${$antdBorder};
  border-radius: 4px;

  > div {
    width: 100%;
    height: 100%;
    flex: 1 1 50%;
    padding: 16px 32px;
    display: flex;
    align-items: center;

    > div {
      flex: 1;
    }
  }
`;

const HeroImage = styled.img`
  height: 110%;
  width: auto;
  margin-left: auto;
  margin-right: -4px;
  margin-top: -2.5%;
`;

const mapStateToProps: ReactRedux.MapStateToProps<StateProps, {}, AppState> = (
  state,
  ownProps,
) => ({
  loginError: state.auth.loginError,
});

const mapDispatchToProps: ReactRedux.MapDispatchToProps<DispatchProps, {}> = (
  dispatch: Dispatcher,
  ownProps,
) => ({
  legacyLogin: (username: string, password: string) => {
    dispatch(authOperations.legacyLogin(username, password));
  },
  googleLogin: (token: string, error?: string) => {
    dispatch(authOperations.googleLogin(token, error));
  },
  getCurrentUser: () => {
    dispatch(getCurrentUser());
  },
});

const ConnectedLoginContainer = ReactRedux.connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginContainer);

export default ConnectedLoginContainer;
