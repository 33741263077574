import { useShallowEqualSelector } from "../_helpers/redux";
import { BasicMatch, ConsultExtended } from "../../api/types";
import { useMemo, useState, useEffect } from "react";
import { isEmpty } from "lodash-es";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useSelector } from "react-redux";
import { AppState } from "@/state/models";

export const useMyConsultsFilterOnMatches = (matches: BasicMatch[]) => {
  const [filter, cuser, clinicianMap] = useShallowEqualSelector((state) => [
    state.matches.myConsultsFilter,
    state.auth.currentUser,
    state.clinicians.clinicianMap,
  ]);

  const filteredMatches = useMemo(() => {
    if (isEmpty(clinicianMap)) {
      return [];
    }
    switch (filter.subset) {
      case "all":
        return matches.filter(Boolean);
      case "clinician":
        return matches.filter((match) => match.assignee === filter.userId);
      case "cuser":
        return matches.filter(
          (match) => match.assignee === cuser?.clinician?.user_id,
        );
      case "my_team":
        return matches.filter((match) => {
          const assigneeClinicianId = Object.values(clinicianMap).find(
            (clinician) => match.assignee === clinician.user_id,
          );

          return (
            assigneeClinicianId &&
            cuser?.clinician?.director_of_id &&
            clinicianMap[assigneeClinicianId.id]?.clinic_ids.includes(
              cuser.clinician.director_of_id,
            )
          );
        });
      default:
        return [];
    }
  }, [filter, cuser, clinicianMap, matches]);

  return filteredMatches;
};

export const useMyConsultsFilterOnConsults = (consults: ConsultExtended[]) => {
  const [filter, cuser, clinicianMap] = useShallowEqualSelector((state) => [
    state.matches.myConsultsFilter,
    state.auth.currentUser,
    state.clinicians.clinicianMap,
  ]);

  const filteredConsults = useMemo(() => {
    if (isEmpty(clinicianMap) || !consults) {
      return [];
    }
    switch (filter.subset) {
      case "all":
        return consults.filter(Boolean);
      case "clinician":
        return consults.filter((consult) => {
          const consultClinicianUserId =
            typeof consult.clinician === "number"
              ? clinicianMap[consult.clinician]?.user_id
              : consult.clinician.user_id;
          // Include the consult if either the clinician owns it or is a shadowing clinician on it
          return (
            (consultClinicianUserId &&
              consultClinicianUserId === filter.userId) ||
            (filter.clinicianId &&
              consult?.shadowing_clinicians.includes(filter.clinicianId))
          );
        });
      case "cuser":
        try {
          return consults.filter((consult) => {
            const consultClinicianUserId =
              typeof consult.clinician === "number"
                ? clinicianMap[consult.clinician]?.user_id
                : consult.clinician.user_id;
            // Include the consult if either the user owns it or is a shadowing clinician on it
            return (
              consultClinicianUserId === cuser?.clinician?.user_id ||
              (cuser?.clinician?.id &&
                consult?.shadowing_clinicians.includes(cuser?.clinician?.id))
            );
          });
        } catch (err) {
          console.trace(err);
          return consults;
        };
      case "my_team":
        return consults.filter((consult) => {
          const consultClinicianId =
            typeof consult.clinician === "number"
              ? consult.clinician
              : consult.clinician.id;
          return (
            cuser?.clinician?.director_of_id &&
            clinicianMap[consultClinicianId]?.clinic_ids.includes(
              cuser.clinician.director_of_id,
            )
          );
        });
      default:
        return [];
    }
  }, [consults, filter, cuser, clinicianMap]);

  return filteredConsults;
};

export const useInput = (initialValue: any) => {
  const [value, setValue] = useState(initialValue);

  return {
    value,
    setValue,
    bind: {
      value,
      onChange: (event: React.FormEvent<HTMLInputElement>) => {
        setValue(event.currentTarget.value);
      },
    },
  };
};

export const useCheckbox = (initialValue: any) => {
  const [value, setValue] = useState(initialValue);

  return {
    value,
    setValue,
    bind: {
      value,
      onChange: (event: CheckboxChangeEvent) => {
        setValue(event.target.checked);
      },
    },
  };
}

export const useInputTextArea = (initialValue: any) => {
  const [value, setValue] = useState(initialValue);

  return {
    value,
    setValue,
    bind: {
      value,
      onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setValue(event.currentTarget.value);
      },
    },
  };
};

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        // best practice to set initial state to undefined when SSR:
        // https://joshwcomeau.com/react/the-perils-of-rehydration/ for future reference
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
};

export const getCurrentConsultNoteInfo = () => {
  const [consultNoteOpen, eventId, readOnlyConsultNote] = useSelector(
    (state: AppState) => [
      state.consultnote.isOpen,
      state?.consultnote?.openConsult?.id,
      state?.consultnote.readOnly,
    ],
  );

  return { consultNoteOpen, eventId, readOnlyConsultNote };
}
