import { IconProps } from "../icon.interface";

export const DownChevronIcon = ({
  className,
  fill = "#171717",
  height = 24,
  width = 24,
  title,
}: IconProps) => {
  return (
    <svg
      width={typeof width === "number" ? `${width}px` : width}
      height={typeof height === "number" ? `${height}px` : height}
      viewBox="0 0 24 24"
      fill="none"
      className={className}
      aria-hidden={!title}
    >
      {title ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.59 8.58984L12 13.1698L7.41 8.58984L6 9.99984L12 15.9998L18 9.99984L16.59 8.58984Z"
        fill={fill}
      />
    </svg>
  );
};
