import { DateTime } from "luxon";
import { ClientMatchingStatus } from "../../components/client-pane/components/matching-status-badge";
import { MyClientsClient } from "../../my-clients-model";

export const sortByNextSession = (
  clientA: MyClientsClient,
  clientB: MyClientsClient,
) => {
  if (clientA.nextSession && clientB.nextSession) {
    return (
      DateTime.fromISO(clientA.nextSession.startTime).toMillis() -
      DateTime.fromISO(clientB.nextSession.startTime).toMillis()
    );
  } else if (!clientA.nextSession && clientB.nextSession) {
    return 1;
  } else if (clientA.nextSession && !clientB.nextSession) {
    return -1;
  } else {
    return 0;
  }
};

export const getMostRelevantClients = (clients: MyClientsClient[]) => {
  const prospectiveClients = clients.filter(
    (client) =>
      client.matchingInfo?.matchingStatus === ClientMatchingStatus.Prospective,
  );

  const sortedProspectiveClients = prospectiveClients.sort(sortByNextSession);

  const pendingClients = clients.filter(
    (client) =>
      client.matchingInfo?.matchingStatus === ClientMatchingStatus.Pending,
  );

  const sortedPendingClients = pendingClients.sort(sortByNextSession);

  const newClients = clients.filter(
    (client) =>
      client.matchingInfo?.matchingStatus === ClientMatchingStatus.New,
  );

  const sortedNewClients = newClients.sort(sortByNextSession);

  const activeClients = clients.filter(
    (client) =>
      client.matchingInfo?.matchingStatus === ClientMatchingStatus.Active,
  );

  const sortedActiveClients = activeClients.sort(sortByNextSession);

  const inactiveClients = clients.filter(
    (client) =>
      client.matchingInfo?.matchingStatus === ClientMatchingStatus.Inactive,
  );

  return [
    ...sortedProspectiveClients,
    ...sortedPendingClients,
    ...sortedNewClients,
    ...sortedActiveClients,
    ...inactiveClients,
  ];
};
