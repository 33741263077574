import * as React from "react";
import { useDispatch } from "react-redux";
import { useShallowEqualSelector } from "../../../../_helpers/redux";
import { consultNoteActions } from "../../../../../state/models/consultnote";
import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import QuestionTitle from "../shared/QuestionTitle";
import { ConsultNoteQuestion } from "../../../../../api/types";
import { getCurrentConsultNoteInfo } from "@/app/consults-and-matching/_hooks";

interface Props {
  question: ConsultNoteQuestion;
  handleEdit: (hasBeenEdited: boolean) => void;
  options: string[];
}

export default ({ question, handleEdit, options }: Props) => {
  const { readOnlyConsultNote } = getCurrentConsultNoteInfo();

  const dispatch = useDispatch();

  const [slug_value] = useShallowEqualSelector((state) => [
    state.consultnote?.openConsult?.consult_note?.[question.slug],
  ]);

  const handleChange = (label: string, checked: boolean) => {
    handleEdit(true);

    let rendered_value: string = slug_value || "";

    if (checked === false) {
      rendered_value = slug_value // 'First Option, Second Option, Third Option'
        .split(", ") // ['First Option', 'Second Option', 'Third Option']
        .filter((l: string) => l !== label) // ['First Option', 'Third Option']
        .join(", "); // 'First Option, Third Option'
    }

    if (checked === true && !rendered_value.includes(label)) {
      // Don't add a delineator if it's the first item
      const prefix = rendered_value === "" ? "" : ", ";
      rendered_value += `${prefix}${label}`;
    }

    dispatch(
      consultNoteActions.setConsultNoteValue({
        key: question.slug,
        value: rendered_value,
      }),
    );
  };

  return (
    <div style={{ paddingTop: "15px", paddingBottom: "15px" }}>
      <QuestionTitle
        questionNumber={question.questionNumber}
        titleText={question.title}
        subtitleText={question.subtitle}
        isRequired={question.isRequired}
        hideQuestionNumber={question.hideNumber}
      />
      <div style={{ columns: 3, margin: "15px 0" }}>
        {options?.map((label, index) => {
          const checked = slug_value?.includes(label);

          return (
            <div key={index}>
              <Checkbox
                disabled={readOnlyConsultNote}
                checked={checked}
                style={{ paddingRight: "10px" }}
                onChange={(e: CheckboxChangeEvent) =>
                  handleChange(label, e.target.checked)
                }
              >
                <label>{label}</label>
              </Checkbox>
            </div>
          );
        })}
      </div>
    </div>
  );
};
