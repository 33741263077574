import React from "react";
import { TagsList } from "../Tags/TagsList";
import {
  FavoriteResourceIcon,
  PersonalResourceIcon,
  PublishStatusIcon,
} from "../Icons";
import { Row } from "../../_layout/Flex";

import {
  ResourceCategory,
  ResourceContainer,
  ResourceContent,
  ResourceContentFooter,
} from "../styles";
import { Resource } from "../types";
import { useUserHasAnyPermissions } from "../../_helpers/permissions";

interface ResourceItemProps {
  resource: Resource;
  onSelect: (id: string) => void;
  handleFavoriteIconClick: (resource: Resource) => void;
}

const ResourceItem = ({
  resource,
  onSelect,
  handleFavoriteIconClick,
}: ResourceItemProps) => {
  const isClinician = useUserHasAnyPermissions(["IsClinician"]);
  return (
    <ResourceContainer
      key={resource.id}
      style={{ minHeight: 80, padding: 0, minWidth: 360 }}
      onClick={() => onSelect(resource.id)}
    >
      <ResourceContent
        style={{
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          padding: "10px 20px",
        }}
      >
        {resource.title}
      </ResourceContent>
      <ResourceContent
        style={{ alignItems: "flex-start", padding: "10px 20px" }}
      >
        <div style={{ marginTop: 4, marginBottom: 8 }}>
          <ResourceCategory
            children={resource.categories.map(c => c.name).join(", ")}
          />
        </div>
        <TagsList tags={resource.tags} max={2} />
      </ResourceContent>
      <ResourceContentFooter>
        <Row layout="flex-end center">
          <PublishStatusIcon is_published={resource.is_published} />
          <PersonalResourceIcon resource={resource} />
          {isClinician && (
            <FavoriteResourceIcon
              resource={resource}
              onClick={e => {
                e.stopPropagation();
                handleFavoriteIconClick(resource);
              }}
            />
          )}
        </Row>
      </ResourceContentFooter>
    </ResourceContainer>
  );
};

export { ResourceItem };
