import { styledStitches } from "../design-system";
import {
  STANDARD_FOCUS_OUTLINE,
  HIGH_PRIORITY_ZINDEX,
} from "../design-system/styles/config/css-constants";

// https://css-tricks.com/how-to-create-a-skip-to-content-link/
const StyledSkipLink = styledStitches("a", {
  position: "absolute",
  transform: "translateY(-100%)",
  left: "0%",
  backgroundColor: "$neutral0",
  zIndex: `${HIGH_PRIORITY_ZINDEX}`,
  minWidth: "min-content",
  height: "min-content",
  padding: "4px 16px",
  borderRadius: "$4",
  color: "$blue11",
  "&:focus": {
    transform: "translateY(10%)",
    textDecoration: "underline",
    outline: STANDARD_FOCUS_OUTLINE,
  },
});

interface SkipLinkProps {
  locationId?: string;
  locationName?: string;
}

/**
 * @name SkipLink
 * @description Used to create bypass blocks, which are useful for keyboard or other non-mouse users
 * Visit [Understanding SC 2.4.1:
Bypass Blocks](https://www.w3.org/WAI/WCAG21/Understanding/bypass-blocks.html) for more information
 * @param {string} locationId // where the user can bypass to. Defaults to "#main-content" since that is the most common destination, but would also be useful within a secondary repeated block
 * @param {string} locationName // name of section that the user can bypass to. Defaults to "main content" since that is the most common destination, but would also be useful within a secondary repeated block
 */
export const SkipLink = ({
  locationId = "main-content",
  locationName = "main content",
}: SkipLinkProps) => {
  return (
    <StyledSkipLink href={"#" + locationId}>
      Skip to {locationName}
    </StyledSkipLink>
  );
};
