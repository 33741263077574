interface ViewBox {
  height: number;
  width: number;
  x: number;
  y: number;
}

interface GraphLabelProps {
  viewBox: ViewBox;
  fill: string;
  displayText?: string;
}

export const GraphLabel = (props: GraphLabelProps) => {
  const { displayText, viewBox, fill } = props;
  const x = viewBox.width - viewBox.x - 10;
  const y = viewBox.y + 20;
  if (displayText) {
    return (
      <text x={x} y={y} fill={fill}>
        {displayText}
      </text>
    );
  }
  return null;
};
