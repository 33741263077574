import api from "@/api";
import { Ehr_Timeoff } from "@/graphql/generated";
import { DateTime } from "luxon";

import { useMutation } from "react-query";

interface AddTimeoffArgs {
  clinicianIds: number[];
  startAt: DateTime;
  endAt: DateTime;
  ptoType: string;
}

export function addTimeoffs({
  clinicianIds,
  startAt,
  endAt,
  ptoType,
}: AddTimeoffArgs) {
  return api.post("/ehr/timeoff/bulkCreate/", {
    clinicians: clinicianIds,
    start_at: startAt.toISO(),
    end_at: endAt.toISO(),
    pto_type: ptoType,
  });
}

export function useAddTimeoffsMutation() {
  return useMutation(addTimeoffs);
}

interface UpdateTimeoffArgs {
  id: number;
  startAt: DateTime;
  endAt: DateTime;
  ptoType: string;
}

export function updateTimeoff({
  id,
  startAt,
  endAt,
  ptoType,
}: UpdateTimeoffArgs) {
  return api.patch(`/ehr/timeoff/${id!}/`, {
    start_at: startAt.toISO(),
    end_at: endAt.toISO(),
    pto_type: ptoType,
  });
}

export function useUpdateTimeoffMutation() {
  return useMutation(updateTimeoff);
}

interface DeleteTimeoffArgs {
  id: number;
}

export function deleteTimeoff({ id }: DeleteTimeoffArgs) {
  return api.delete(`/ehr/timeoff/${id}/`);
}
export function useDeleteTimeoffMutation() {
  return useMutation(deleteTimeoff);
}
