import * as React from "react";
import { Container, ResourceContainer, ResourceContent } from "../styles";

import { ResourceContentFooter } from "../styles";
import { Row } from "antd";

import { fetchAddedByMe, fetchFavorites } from "../utils";
import { useDispatchPromise } from "../../_helpers/redux";

interface MyResourcesProps {
  onSelectMyResources: (queryParam: string) => void;
  isClinician: boolean | null;
}

const MyResources = ({
  onSelectMyResources,
  isClinician,
}: MyResourcesProps) => {
  const dispatch = useDispatchPromise();

  const [addedByMeCount, setaddedByMeCount] = React.useState(0);
  const [favoritesCount, setFavoritesCount] = React.useState(0);

  const addedByMeCountText = `${addedByMeCount} Resource${
    addedByMeCount === 1 ? "" : "s"
  }`;

  const favoritesCountText = `${favoritesCount} Resource${
    addedByMeCount === 1 ? "" : "s"
  }`;

  React.useEffect(() => {
    const fetch = async () => {
      const addedByMeRes = await dispatch(fetchAddedByMe());

      setaddedByMeCount(addedByMeRes.data.results.length);

      const favoritesRes = await dispatch(fetchFavorites());

      setFavoritesCount(favoritesRes.data.results.length);
    };

    fetch();
  });

  return (
    <>
      <h3 style={{ marginLeft: 16, paddingLeft: 8 }}>My Resources</h3>
      <Container>
        <ResourceContainer>
          <ResourceContent onClick={() => onSelectMyResources("added_by_me")}>
            Added By Me
          </ResourceContent>
          <ResourceContentFooter>
            <Row>{addedByMeCountText}</Row>
          </ResourceContentFooter>
        </ResourceContainer>

        {isClinician && (
          <ResourceContainer>
            <ResourceContent
              onClick={() => onSelectMyResources("my_favorites")}
            >
              Favorites
            </ResourceContent>
            <ResourceContentFooter>
              <Row>{favoritesCountText}</Row>
            </ResourceContentFooter>
          </ResourceContainer>
        )}
      </Container>
    </>
  );
};

export { MyResources };
