import { ScheduleItem, ScheduleDraftItem, WorkFromHomeDays } from "@/api/types";

/**
 * From a list of existingSchedules, generate a mapping from day of week
 * to a boolean for whether the clinician is in office / virtual on that
 * day. If any batches are in person (work_from_home=false), we consider
 * the full day to be in person.
 */
export function generateWorkFromHomeObject(
  existingSchedules: ScheduleItem[] | ScheduleDraftItem[],
): WorkFromHomeDays {
  const result = {};
  existingSchedules?.forEach((scheduleObj) => {
    if (scheduleObj.work_from_home) {
      // if ANY are true, set that day as "in person"
      result[scheduleObj.day_of_week] = true;
    } else {
      // if a previous batch was already marked as "in person",
      // a later false value shouldn't overwrite it.
      if (!result[scheduleObj.day_of_week]) {
        result[scheduleObj.day_of_week] = false;
      }
    }
    // for a day to be "virtual", the clinician must have NO in person appts that day.
    // If the day is not present in the schedule, leave that day undefined in this object
    // and the UI will show neither virtual nor in person.
  });
  return result;
}
