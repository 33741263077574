import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
  ComboboxPopover,
} from "@reach/combobox";

import { styledStitches } from "@/app/design-system/styles/stitches.config";

export const StyledCombobox = styledStitches(Combobox, {});

export const StyledComboboxPopover = styledStitches(ComboboxPopover, {
  position: "absolute",
  z: 1,
});

export const StyledComboboxInput = styledStitches(ComboboxInput, {
  py: 8,
  minWidth: 125,
  br: 4,
  pr: 44,
  pl: 18,
  transitionDuration: 100,
  fontSize: 14,

  "&:focus": {
    outline: "none",
    bblr: 0,
    bbrr: 0,
  },

  "data-state=idle": {
    bblr: 4,
    bbrr: 4,
  },

  border: `1px solid $neutral8`,
});

export const StyledComboboxList = styledStitches(ComboboxList, {
  backgroundColor: "white",
  p: 10,
  pr: 12,
  pt: 4,
  top: -1,
  bblr: 4,
  bbrr: 4,
  btlr: 0,
  btrr: 0,
  mb: 0,
  minWidth: 225.5,
  "[data-reach-combobox-option][data-highlighted]": {
    backgroundColor: "$neutral3",
  },
});

export const StyledComboboxOption = styledStitches(ComboboxOption, {
  listStyle: "none",
  py: 8,
  px: 12,
  br: 4,

  cursor: "pointer",

  "&:first-of-type": {
    btlr: 0,
    btrr: 0,
  },

  // Per current ticket (tc-4852) we want to remove the match highlighting
  // in our search results. Sorry @char
  // "[data-user-value]": {
  //   fontWeight: "lighter",
  //   backgroundColor: "$yellow10",
  // },

  "&:focus": {
    backgroundColor: "$neutral3",
  },

  "&:hover": {
    backgroundColor: "$neutral3",
  },
});
