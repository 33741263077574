import * as React from "react";
import styled from "styled-components";

const ListContainer = styled.div`
  margin: 0px auto;
  width: 1192px;

  @media only screen and (max-width: 1366px) {
    width: 1048px;
  }
`;

interface Props {
  margin?: string;
}

export default class ViewContainer extends React.Component<Props> {
  public render() {
    return (
      <ListContainer>
        {this.props.children}
      </ListContainer>
    );
  }
}
