import { Graph } from "@/app/_components/graph/graph"
import { ParsedClientEventMbcScoresAndSeverities } from "../_utils/mbc.util";
import { getGraphDataPointFromParsedMBCScore } from "../_utils/mbc-graph.util";
import { MBCAssessmentCustomGraphDot } from "./mbc-graph/mbc-asssessment-custom-graph-dot";
import { GroupsMBCStatusKey } from "./mbc-graph/groups-mbc-status-key";
import { store } from "@/index";

const yAxisTickIntervalsPHQ9 = [0, 5, 10, 15, 20, 25];

const customTickFormatterPHQ9 = (value: number) => {
  if (value === 0) return "Minimal/(1 - 4)";
  if (value === 5) return "Mild/(5 - 9)";
  if (value === 10) return "Moderate/(10 - 14)";
  if (value === 15) return "Moderately Severe/(15 - 19)";
  if (value === 20) return "Severe/(20 - 27)";
  return '';
}

interface MBCAssessmentPHQ9GraphProps {
  eventScores: ParsedClientEventMbcScoresAndSeverities[] | null;
}

export const MBCAssessmentPHQ9Graph = ({ eventScores }: MBCAssessmentPHQ9GraphProps) => {
  const cuser = store.getState()?.auth?.currentUser;

  const formattedEventScores = eventScores?.map(score => getGraphDataPointFromParsedMBCScore(score, 'PHQ', cuser));
  if (formattedEventScores?.length) {
    const firstPHQ9Score = formattedEventScores[0].value ?? undefined;
    const firstPHQ9ScoreLabel = firstPHQ9Score !== undefined ? `First survey score - ${firstPHQ9Score}` : undefined
    return (
      <Graph
        graphDataPoints={formattedEventScores}
        yAxisTickIntervals={yAxisTickIntervalsPHQ9}
        yAxisTickFormatter={customTickFormatterPHQ9}
        referenceLineValue={firstPHQ9Score}
        referenceLineLabel={firstPHQ9ScoreLabel}
        // After discussion with clinicians, the decision was made to keep the remissionReferenceLine at 5 for GAD/PHQ
        blueBackgroundHighlightValue={5}
        legend={GroupsMBCStatusKey}
        customGraphDot={MBCAssessmentCustomGraphDot}
      />
    );
  }
  return null;
}
