import {
  Flex,
  Spacer,
  Text,
} from "../../../../../../../../../../../design-system/index";
import { SessionHistoryFilterCheckbox } from "../session-history-filter-checkbox";
import { useSessionHistoryAppointmentStatusFilters } from "./use-session-history-appointment-status-filter-checkboxes";

interface SessionHistoryFiltersProps {
  totalCanceledSessions: number;
  totalAttendedSessions: number;
  totalNoShowSessions: number;
  totalLateCancelByClientSessions: number;
}

export const SessionHistoryAppointmentStatusFilters = ({
  totalCanceledSessions,
  totalAttendedSessions,
  totalLateCancelByClientSessions,
  totalNoShowSessions,
}: SessionHistoryFiltersProps) => {
  const {
    isFilteringOnAttended,
    isFilteringOnCanceledByClient,
    isFilteringOnLateCancelByClient,
    isFilteringOnNoShow,
    setSessionHistoryAppointmentStatusFilter,
  } = useSessionHistoryAppointmentStatusFilters();

  return (
    <Flex
      gap={24}
      css={{
        flexWrap: "wrap",
        "@breakpoint1400": {
          width: 700,
        },
      }}
    >
      <SessionHistoryFilterCheckbox
        setFilter={setSessionHistoryAppointmentStatusFilter}
        isChecked={isFilteringOnAttended}
        filterName="isFilteringOnAttended"
      >
        <Text fontSize={18} fontWeight="700">
          {totalAttendedSessions}
        </Text>
        <Spacer x={8} />
        <Text>Attended</Text>
      </SessionHistoryFilterCheckbox>

      <SessionHistoryFilterCheckbox
        setFilter={setSessionHistoryAppointmentStatusFilter}
        isChecked={isFilteringOnCanceledByClient}
        filterName="isFilteringOnCanceledByClient"
      >
        <Text fontSize={18} fontWeight="700">
          {totalCanceledSessions}
        </Text>
        <Spacer x={8} />
        <Text>Canceled</Text>
      </SessionHistoryFilterCheckbox>

      {totalLateCancelByClientSessions > 0 ? (
        <SessionHistoryFilterCheckbox
          setFilter={setSessionHistoryAppointmentStatusFilter}
          isChecked={isFilteringOnLateCancelByClient}
          filterName="isFilteringOnLateCancelByClient"
        >
          <Text fontSize={18} fontWeight="700" color={"$red11"}>
            {totalLateCancelByClientSessions}
          </Text>
          <Spacer x={8} />
          <Text color={"$red11"}> Late cancel by client</Text>
        </SessionHistoryFilterCheckbox>
      ) : null}

      <SessionHistoryFilterCheckbox
        setFilter={setSessionHistoryAppointmentStatusFilter}
        isChecked={isFilteringOnNoShow}
        filterName="isFilteringOnNoShow"
      >
        <Text fontSize={18} fontWeight="700" color={"$red11"}>
          {totalNoShowSessions}
        </Text>
        <Spacer x={8} />
        <Text color={"$red11"}>No show</Text>
      </SessionHistoryFilterCheckbox>
    </Flex>
  );
};
