import {
  MessageIcon,
  PersonIcon,
} from "../../../../../../../../../../design-system/icons";
import {
  Flex,
  Text,
  RadixTooltip,
} from "../../../../../../../../../../design-system/index";

interface SessionClinicianProps {
  serviceType: string;
  clinicianName: string | undefined;
}

export const SessionClinician = ({
  serviceType,
  clinicianName,
}: SessionClinicianProps) => {
  return (
    <Flex
      gap={12}
      css={
        {
          // minWidth: 220,
          // "@breakpoint1600": {
          //   minWidth: "fit-content",
          // },
        }
      }
    >
      {serviceType === "consult" && (
        <RadixTooltip content={`Consult`} sideOffset={4}>
          <Flex css={{ height: 25, width: 25, backgroundColor: "white" }}>
            <MessageIcon />
          </Flex>
        </RadixTooltip>
      )}
      {serviceType === "individual" && (
        <RadixTooltip
          content={`Individual therapy with ${clinicianName}`}
          sideOffset={4}
        >
          <Flex css={{ height: 25, width: 25, backgroundColor: "white" }}>
            <PersonIcon />
          </Flex>
        </RadixTooltip>
      )}
      {clinicianName ? (
        <Text css={{ "@breakpoint1400": { display: "none" } }}>
          {clinicianName}
        </Text>
      ) : (
        <Text>Unknown clinician</Text>
      )}
    </Flex>
  );
};
