// WARNING: Reach-UI is no longer being maintained as of September 2022. Please don't use this anymore. We should be using Radix's select (or listbox) instead as of May 2023.
// https://github.com/reach/reach-ui/issues/972
import { CSS_COLORS, Separator } from "..";
import { DownChevronIcon } from "../icons";
import { Text } from "@/app/design-system";
import {
  SelectArrow,
  SelectButton,
  SelectOption,
  SelectOptionsList,
  SelectPopover,
  SelectRoot,
} from "./select.styled";
export interface Option {
  value: string;
  label: string;
  id: string;
}

/**
 * @interface ComposedSelectReachProps
 */

interface ComposedSelectReachProps {
  options: Option[];
  value: string;
  onChange: (value: string) => void;
  id?: string;
  disabled?: boolean;
  selectRootName?: string;
}

/**
 * @component ComposedSelectReach
 * @prop { ComposedSelectReachProps }
 */

/**
 * @name DeprecatedComposedReachUISelect
 * @warning THIS IS DEPRECATED. Please do not use.
 * @returns a composed (and styled) version of the Reach Listbox
 */
export const DeprecatedComposedReachUISelect = ({
  options,
  value,
  onChange,
  id,
  disabled = false,
  selectRootName,
}: ComposedSelectReachProps) => {
  return (
    <SelectRoot value={value} onChange={onChange} id={id} disabled={disabled}>
      {/* Expand options is not perfect as a label (esp if there are multiple selects, but it's better than using the first value as a label, plus we're deprecating this anyways) */}
      <SelectButton aria-label={`Select ${selectRootName} options`}>
        <Text
          color={
            value === "Select"
              ? "$neutral9"
              : disabled
              ? "$neutral8"
              : "$neutral12"
          }
        >
          {options.find((o) => o.value === value)?.label ?? value}
        </Text>
        <SelectArrow>
          <DownChevronIcon fill={disabled ? CSS_COLORS.neutral8 : "#171717"} />
        </SelectArrow>
      </SelectButton>
      <SelectPopover portal={false}>
        <SelectOptionsList>
          <Separator css={{ width: 200, marginBottom: 8 }} />
          {options.map(({ value, label, id }) => (
            <SelectOption key={id} value={value}>
              {label}
            </SelectOption>
          ))}
        </SelectOptionsList>
      </SelectPopover>
    </SelectRoot>
  );
};
