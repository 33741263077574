import { IconProps } from "../icon.interface";
import { CSS_COLORS } from "../..";

export const RightChevronIcon = ({
  className,
  fill = "#171717",
  height = 24,
  width = 24,
  title,
  stroke = "#171717",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      className={className}
      aria-hidden={!title}
      stroke={stroke}
    >
      {title ? <title>{title}</title> : null}
      <path
        d="M8.29289 17.2929C7.90237 17.6834 7.90237 18.3166 8.29289 18.7071C8.68342 19.0976 9.31658 19.0976 9.70711 18.7071L8.29289 17.2929ZM15 12L15.7071 12.7071C16.0976 12.3166 16.0976 11.6834 15.7071 11.2929L15 12ZM9.70711 5.29289C9.31658 4.90237 8.68342 4.90237 8.29289 5.29289C7.90237 5.68342 7.90237 6.31658 8.29289 6.70711L9.70711 5.29289ZM9.70711 18.7071L15.7071 12.7071L14.2929 11.2929L8.29289 17.2929L9.70711 18.7071ZM15.7071 11.2929L9.70711 5.29289L8.29289 6.70711L14.2929 12.7071L15.7071 11.2929Z"
        fill={fill}
      />
    </svg>
  );
};
