import React from "react";
import { Button } from "antd";
import { Row } from "../../../_layout/Flex";
import { Popconfirm } from "antd";

interface Props {
  closeConsultNote: Function;
  handleSave: Function;
  isSaving: boolean;
}

export default (props: Props) => {
  return (
    <Row style={{ flex: 1 }} layout="space-between">
      <div>
        <Button
          onClick={() => {
            props.closeConsultNote();
          }}
        >
          Cancel
        </Button>
      </div>
      <div>
        <Button
          onClick={() => {
            props.handleSave(false);
          }}
          loading={props.isSaving}
        >
          Save Progress
        </Button>
        <Popconfirm
          title="Are you sure you are ready to submit?"
          onConfirm={() => props.handleSave(true)}
          okText="Yes"
          cancelText="No"
        >
          <Button type="primary">Submit</Button>
        </Popconfirm>
        ,
      </div>
    </Row>
  );
};
