import { Flex } from "@/app/design-system";
import { RightChevronIcon } from "@/app/design-system/icons";
import { useGroupTherapyUser } from "@/app/groups/_hooks/use-group-therapy-user";
import { useMyClientsSearchStore } from "@/app/my-clients/routes/my-clients-page/my-clients-model/my-clients.model";
import { useParams } from "react-router-dom";
import { ParsedClientEventMbcScores } from "../_api/use-group-therapy-mbc-events/use-group-therapy-mbc-events.interface";
import { ClientNameAndSIAlert } from "./client-name-and-si-alert";
import { StyledGroupClientMbcPaneLink } from "./group-client-mbc-pane.styled";
import { GroupClientMbcScores } from "./group-client-mbc-scores";

export interface GroupClientMbcPaneProps extends ParsedClientEventMbcScores {
  index?: number;
  isCohesion?: boolean;
}

export const GroupClientMbcPane = ({
  clientId,
  clientName,
  endorsedSI,
  cohesion,
  PHQ,
  GAD,
  isCohesion,
  isComplete,
  isEnrolled,
}: GroupClientMbcPaneProps) => {
  const { groupId } = useParams<{ groupId: string }>();
  const { clinicianId } = useGroupTherapyUser();
  const { currentlySelectedClinician } = useMyClientsSearchStore();

  const clinicianIdParam = clinicianId
    ? `clinician_id=${clinicianId}`
    : currentlySelectedClinician
    ? `clinician_id=${currentlySelectedClinician}`
    : "";

  return (
    <StyledGroupClientMbcPaneLink
      css={{
        "@breakpoint1200": {
          gap: 12,
        },
      }}
      to={`/groups/${groupId}/clients/${clientId}/mbc?${clinicianIdParam}`}
    >
      <GroupClientMbcPaneContent
        clientId={clientId}
        clientName={clientName}
        PHQ={PHQ}
        GAD={GAD}
        cohesion={cohesion}
        isCohesion={isCohesion}
        isComplete={isComplete}
        isEnrolled={isEnrolled}
        endorsedSI={endorsedSI}
      />
    </StyledGroupClientMbcPaneLink>
  );
};

export const GroupClientMbcPaneContent = ({
  clientName,
  endorsedSI,
  cohesion,
  PHQ,
  GAD,
  isCohesion,
  isComplete,
  isEnrolled,
}: GroupClientMbcPaneProps) => (
  <Flex
    css={{
      "@breakpoint1200": {
        justifyContent: "space-between",
        gap: 30,
        flexDirection: "row",
      },
    }}
  >
    <ClientNameAndSIAlert clientName={clientName} endorsedSI={endorsedSI} />
    <GroupClientMbcScores
      isComplete={isComplete}
      GAD={GAD}
      PHQ={PHQ}
      cohesion={cohesion}
      isCohesion={isCohesion}
      isEnrolled={isEnrolled}
    />
    <Flex
      gap={8}
      css={{
        flexGrow: 1,
      }}
      alignItems={"center"}
      justifyContent={"flex-end"}
    >
      <RightChevronIcon className="chevron-right" />
    </Flex>
  </Flex>
);
