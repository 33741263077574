import { IconProps } from "../icon.interface";

export const TadaCelebrateIcon = ({
  className,
  height = 120,
  width = 218,
  title,
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 139 122"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      aria-hidden={!title}
    >
      {title ? <title>{title}</title> : null}
      <path
        d="M21.0002 92L56.0002 29.5C56.3336 31.6667 59.1002 39.5 67.5002 53.5C75.9002 67.5 88.6669 76.6667 94.0002 79.5C71.0002 84.5 24.6002 94.5 23.0002 94.5C21.4002 94.5 21.0002 92.8333 21.0002 92Z"
        fill="#F9C400"
      />
      <path
        d="M94.4646 79.2644L23.4453 94.3789C21.7669 94.7361 20.4475 92.9525 21.2803 91.452L56.5004 27.999"
        stroke="black"
      />
      <path
        d="M76.2283 83.2309C69.0802 77.4133 53.2518 61.4421 47.1231 44.0975"
        stroke="black"
      />
      <path
        d="M57.3354 87.1523C52.9344 84.0276 42.8923 74.4353 37.932 61.0633"
        stroke="black"
      />
      <path
        d="M39.9009 90.5216C37.2748 88.3871 31.5559 82.6527 29.6885 76.7906"
        stroke="black"
      />
      <path
        d="M56.868 27.8759C57.1594 27.6592 57.5676 27.5491 58.1317 27.5941C58.7002 27.6395 59.3955 27.8409 60.21 28.2109C61.8388 28.9509 63.8489 30.3209 66.1106 32.2324C70.6278 36.0502 76.0628 41.9548 81.3345 49.0429C86.6062 56.131 90.6975 63.0351 93.0543 68.4596C94.2343 71.1757 94.968 73.4949 95.2081 75.2677C95.3281 76.1543 95.321 76.8782 95.2009 77.4356C95.0816 77.9889 94.8588 78.3481 94.5674 78.5648C94.276 78.7816 93.8679 78.8916 93.3037 78.8466C92.7353 78.8013 92.0399 78.5999 91.2254 78.2298C89.5966 77.4898 87.5865 76.1199 85.3248 74.2083C80.8077 70.3905 75.3726 64.4859 70.1009 57.3978C64.8292 50.3097 60.7379 43.4057 58.3811 37.9811C57.2011 35.2651 56.4674 32.9458 56.2273 31.173C56.1073 30.2864 56.1144 29.5626 56.2346 29.0051C56.3538 28.4519 56.5766 28.0926 56.868 27.8759Z"
        stroke="black"
      />
      <path
        d="M128.796 69.97C129.048 70.083 129.344 69.9703 129.456 69.7183C129.569 69.4663 129.457 69.1705 129.205 69.0575L128.796 69.97ZM99.1396 67.9939C104.239 66.5136 117.341 64.8351 128.796 69.97L129.205 69.0575C117.46 63.7924 104.095 65.514 98.8608 67.0336L99.1396 67.9939Z"
        fill="black"
      />
      <path
        d="M98.0321 9.82444C97.9351 10.083 98.0661 10.3712 98.3247 10.4682C98.5832 10.5651 98.8714 10.4341 98.9684 10.1756L98.0321 9.82444ZM96.7229 4.41603C97.5758 4.98461 99.1325 6.89007 98.0321 9.82444L98.9684 10.1756C100.268 6.70993 98.4247 4.34872 97.2776 3.58397L96.7229 4.41603Z"
        fill="black"
      />
      <path
        d="M127.447 79.3327C127.624 79.545 127.939 79.5739 128.151 79.3973C128.364 79.2207 128.393 78.9055 128.216 78.6932L127.447 79.3327ZM122.1 77.7917C123.017 77.3323 125.443 76.9236 127.447 79.3327L128.216 78.6932C125.849 75.8478 122.885 76.2799 121.652 76.8977L122.1 77.7917Z"
        fill="black"
      />
      <path
        d="M99.2239 43.4472C99.4708 43.3237 99.571 43.0234 99.4475 42.7764C99.324 42.5294 99.0236 42.4293 98.7766 42.5528L99.2239 43.4472ZM92.2239 46.9472L99.2239 43.4472L98.7766 42.5528L91.7766 46.0528L92.2239 46.9472Z"
        fill="black"
      />
      <path d="M109 23V29" stroke="black" stroke-linecap="round" />
      <path d="M106 26H107.5" stroke="black" stroke-linecap="round" />
      <path d="M110.5 26H112" stroke="black" stroke-linecap="round" />
      <path
        d="M75.5842 26.2227C75.431 26.4524 75.4931 26.7628 75.7229 26.916C75.9527 27.0692 76.2631 27.0071 76.4163 26.7773L75.5842 26.2227ZM76.4163 26.7773C79.1543 22.6703 82.9571 11.759 76.4304 0.745099L75.5701 1.2549C81.8434 11.841 78.1795 22.3297 75.5842 26.2227L76.4163 26.7773Z"
        fill="black"
      />
      <circle cx="107.5" cy="79.5" r="4" fill="#F9C400" stroke="black" />
      <circle cx="68.5002" cy="13.5" r="4" fill="#50685F" stroke="black" />
      <circle cx="124" cy="36" r="3.5" fill="#C76D61" stroke="black" />
      <path
        d="M120.291 5.87524C120.649 5.58258 121.179 5.89284 121.099 6.34895L120.764 8.26288C120.66 8.85702 120.922 9.45569 121.429 9.78244L123.062 10.835C123.451 11.0858 123.32 11.6853 122.861 11.7503L120.938 12.0231C120.34 12.1077 119.852 12.5419 119.698 13.125L119.202 15.0036C119.083 15.4513 118.473 15.5115 118.269 15.0956L117.415 13.3502C117.15 12.8084 116.586 12.4781 115.984 12.5117L114.044 12.6202C113.582 12.646 113.336 12.0838 113.668 11.7617L115.064 10.4103C115.498 9.99077 115.638 9.35245 115.42 8.79009L114.717 6.97854C114.55 6.54683 115.008 6.13908 115.417 6.35598L117.134 7.26614C117.667 7.54868 118.317 7.48452 118.785 7.1033L120.291 5.87524Z"
        fill="#85A79A"
        stroke="black"
      />
      <path
        d="M130.079 48.8317C130.233 48.3949 130.846 48.3839 131.016 48.8148L131.727 50.6232C131.947 51.1845 132.483 51.5591 133.086 51.5739L135.028 51.6216C135.491 51.6329 135.691 52.2131 135.334 52.5074L133.834 53.7424C133.368 54.1257 133.177 54.7507 133.35 55.3288L133.904 57.1909C134.037 57.6347 133.547 58.0043 133.156 57.7552L131.518 56.7102C131.01 56.3857 130.356 56.3975 129.86 56.7399L128.26 57.8431C127.879 58.106 127.376 57.7542 127.492 57.306L127.98 55.4251C128.132 54.8413 127.919 54.2235 127.439 53.8571L125.896 52.6768C125.528 52.3955 125.707 51.8085 126.17 51.7805L128.109 51.6632C128.711 51.6267 129.233 51.2332 129.433 50.6643L130.079 48.8317Z"
        fill="#E88C51"
        stroke="black"
      />
      <path
        d="M92.2505 24.8491C92.6077 24.5545 93.139 24.8618 93.0617 25.3184L92.7371 27.2341C92.6364 27.8288 92.9016 28.426 93.4105 28.75L95.0495 29.7935C95.4401 30.0422 95.312 30.6424 94.8539 30.7099L92.9316 30.9933C92.3349 31.0812 91.8489 31.5181 91.698 32.1021L91.212 33.9834C91.0962 34.4317 90.4858 34.4953 90.28 34.0805L89.4165 32.3399C89.1485 31.7995 88.5828 31.4723 87.9808 31.5093L86.0414 31.6285C85.5792 31.6569 85.3301 31.096 85.661 30.7721L87.0496 29.413C87.4807 28.9911 87.6171 28.352 87.3959 27.7909L86.6832 25.9832C86.5134 25.5524 86.9698 25.1422 87.3801 25.3568L89.1018 26.2575C89.6363 26.5371 90.2863 26.4693 90.7516 26.0855L92.2505 24.8491Z"
        fill="#687795"
        stroke="black"
      />
      <path
        d="M118.5 50.5C118.776 50.5 119 50.2761 119 50C119 49.7239 118.776 49.5 118.5 49.5V50.5ZM98.4684 57.1756C98.6933 56.5758 99.2262 55.6443 100.075 54.8692C100.916 54.1008 102.051 53.5 103.5 53.5V52.5C101.75 52.5 100.384 53.2325 99.4006 54.1308C98.4243 55.0223 97.8072 56.0909 97.5321 56.8244L98.4684 57.1756ZM103.5 53.5C104.445 53.5 105.134 53.6472 105.68 53.8573C106.229 54.0684 106.656 54.351 107.085 54.6569C107.947 55.2729 108.82 56 110.5 56V55C109.181 55 108.553 54.4771 107.666 53.8431C107.219 53.524 106.709 53.1816 106.039 52.924C105.367 52.6653 104.555 52.5 103.5 52.5V53.5ZM110.5 56C111.444 56 112.074 55.5504 112.559 54.9572C112.794 54.6698 113.001 54.3429 113.197 54.017C113.398 53.6808 113.583 53.3553 113.797 53.0184C114.218 52.3568 114.703 51.7382 115.412 51.2792C116.116 50.8237 117.081 50.5 118.5 50.5V49.5C116.919 49.5 115.759 49.8638 114.869 50.4396C113.985 51.0118 113.407 51.7682 112.953 52.4816C112.73 52.8322 112.524 53.1942 112.339 53.5025C112.148 53.8211 111.972 54.0958 111.785 54.324C111.427 54.7621 111.057 55 110.5 55V56Z"
        fill="black"
      />
    </svg>
  );
};
