import React from "react";
import { Radio } from "antd";
import { useShallowEqualSelector } from "../../../../_helpers/redux";
import { useDispatch } from "react-redux";
import { matchmakerActions } from "../../../../../state/models/matchmaker";
import { RadioGroup, RequiredText } from "./styled";
import { Title } from "../../../../_layout/display";
import { HelperTextBox } from "@/app/consults-and-matching/MatchingV2/_components/helper-text-box";

const deliverySelectionNotes = {
  teletherapy_only: "Client only wants to attend sessions via teletherapy",
  remote: "Client only wants to attend sessions via teletherapy",
  open_to_either:
    "Client prefers the option to come into an in-person location if possible, but is open to OT for a great match or faster access to care",
  in_person_only: "Client is only open to ongoing care in person",
  hybrid: "Client will primarily be seen in person (Teletherapy always an option as a backup)",
};

export default () => {
  const { delivery_preference } = useShallowEqualSelector(
    state => state.matchmaker.draft,
  );
  const dispatch = useDispatch();

  return (
    <>
      <Title size="sm">
        Delivery Preference: <RequiredText>*</RequiredText>
      </Title>
      <HelperTextBox primaryText="Prevent high-acuity delay" secondaryText="Do not select “In-Person” if you believe the client is high acuity" color="#5746AF" background="#F5F2FF"/>
      
      <div style={{marginTop: "10px"}}>
        <RadioGroup
          onChange={e =>
            dispatch(
              matchmakerActions.patchMatchDraft({
                delivery_preference: e.target.value,
                hybrid_preference: "teletherapy_ok",
              }),
            )
          }
          value={delivery_preference}
        >
          <Radio.Button value={"hybrid"}>In Person</Radio.Button>
          <Radio.Button value={"open_to_either"}>
            Open to Ongoing Teletherapy
          </Radio.Button>
          <Radio.Button value={"remote"}>Teletherapy Only</Radio.Button>
        </RadioGroup>
        {delivery_preference !== undefined && (
          <div style={{ fontSize: "10px" }}>
            <i>{deliverySelectionNotes[delivery_preference]}</i>
          </div>
        )}
      </div>
      <br />
    </>
  );
};
