import { CurrentUserContext } from "@/app/app.utils";
import { Flex } from "@/app/design-system";
import { RightChevronIcon } from "@/app/design-system/icons/components/right-chevron-icon";
import { TIMEZONE } from "@/app/my-clients/utils";
import { ScreenReaderOnlyText } from "@/app/_shared/ScreenReaderOnlyText";
import { VisuallyNestedLink } from "@/app/_shared/VisuallyNestedLink";
import { DateTime } from "luxon";
import React from "react";
import { ClientOverviewApiData } from "../../../../../api/use-my-clients-overview/my-clients-data.interface";
import { DxCodeMap } from "../../../../../client/types";
import { MatchingInfoByClientIdApiData } from "../../../../api/use-matching-data-by-clients/select-data-for-matching-data-by-client.util";
import { useMyClientsUser } from "../../hooks/use-my-clients-user";
import { ClientPaneContainer, StyledClientPane } from "./client-pane.styled";
import { ClientNameAndPayerText } from "./components/client-name-and-payer-text";
import { DiagnosesAndAssessmentText } from "./components/diagnoses-and-assessment-text";
import { SessionText } from "./components/session-text";
import { useSearchParams } from "react-router-dom";

export interface ClientCardProps extends ClientOverviewApiData {
  index: number;
  matchingInfo: MatchingInfoByClientIdApiData | null;
  dxCodeMap: DxCodeMap;
  isEocDataAvailable: boolean;
  client: ClientOverviewApiData;
  isDxCodeLoading: boolean;
}

export const ClientPane = ({
  firstName,
  lastName,
  preferredName,
  matchingInfo,
  clientPayerServiceRecord,
  clientId,
  primaryDiagnosis,
  isDxCodeLoading,
  severity,
  primaryAssessment,
  mbcTrackStatus,
  mbcTrackStatusBeta,
  endorsedSi,
  nextSession,
  totalSessionsAttendedWithCurrentClinician,
  suggestedRange,
  manualSlotReservation,
  dxCodeMap,
  welkinLink,
  healthieLink,
  isEocDataAvailable,
  client,
  isHighAcuity
}: ClientCardProps) => {
  const cuser = React.useContext(CurrentUserContext);
  const dateTime = DateTime.fromISO(nextSession?.startTime ?? "", {
    zone: TIMEZONE(cuser),
  });

  const formattedStartTimeShort = `${dateTime.toFormat(
    "M/d EEE",
  )} at ${dateTime.toFormat("ha")}`;

  const { clinicianId } = useMyClientsUser();

  const [searchParams] = useSearchParams();
  let queryParamCID = searchParams.get("clinician_id");
  const activeClinicianId = queryParamCID ?? clinicianId;
  return (
    <ClientPaneContainer>
      <StyledClientPane
        css={{
          "@breakpoint1200": {
            gap: 12,
          },
        }}
      >
        <Flex
          css={{
            flexGrow: 1,
            "@breakpoint1200": {
              gap: 24,
            },
          }}
        >
          <Flex
            css={{
              flexGrow: 1,
              "@breakpoint1200": {
                gap: 24,
                flexDirection: "column",
              },
            }}
          >
            <ClientNameAndPayerText
              firstName={firstName}
              lastName={lastName}
              clientPayerServiceRecord={clientPayerServiceRecord}
              matchingInfo={matchingInfo}
              link={`/my-clients/${clientId}/mbc?clinician_id=${activeClinicianId}`}
              isHighAcuity={isHighAcuity}
            />

            <DiagnosesAndAssessmentText
              primaryDiagnosis={primaryDiagnosis}
              isDxCodeLoading={isDxCodeLoading}
              severity={severity}
              primaryAssessment={primaryAssessment}
              mbcTrackStatus={mbcTrackStatus}
              mbcTrackStatusBeta={mbcTrackStatusBeta}
              dxCodeMap={dxCodeMap}
              endorsedSi={endorsedSi}
              welkinLink={welkinLink}
              healthieLink={healthieLink}
              firstName={firstName}
              lastName={lastName}
              client={client}
            />
          </Flex>

          <SessionText
            nextSession={nextSession}
            totalSessionsAttendedWithCurrentClinician={
              totalSessionsAttendedWithCurrentClinician
            }
            isEocDataAvailable={isEocDataAvailable}
            suggestedRange={suggestedRange}
            formattedStartTimeShort={formattedStartTimeShort}
            manualSlotReservation={manualSlotReservation}
            clientPayerServiceRecord={clientPayerServiceRecord}
          />
        </Flex>

        <Flex
          css={{
            width: "fit-content",
            "@breakpoint1200": {
              justifyContent: "flex-end",
            },
          }}
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          <VisuallyNestedLink
            to={`/my-clients/${clientId}/mbc?clinician_id=${activeClinicianId}`}
          >
            <ScreenReaderOnlyText>
              View {firstName} {lastName}
            </ScreenReaderOnlyText>
          </VisuallyNestedLink>
          <RightChevronIcon className="chevron-right" />
        </Flex>
      </StyledClientPane>
    </ClientPaneContainer>
  );
};
