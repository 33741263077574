import Mangle from "@/app/_shared/Mangle";
import { Flex, Text } from "@/app/design-system";
import { LocationIcon } from "@/app/design-system/icons";

interface ClientPronounAgeLocationProps {
  clientPronouns?: string | null;
  age?: string | null;
  cityAndState?: string | null;
}

export const ClientPronounAgeLocation = ({
  clientPronouns,
  age,
  cityAndState,
}: ClientPronounAgeLocationProps) => {
  const ageText = age ? "Age " + age : "";
  return (
    <Flex css={{ pl: 50, flexGrow: 1, maxWidth: 600, minWidth: 300 }}>
      <Flex
        justifyContent={"flex-start"}
        alignItems={"center"}
        css={{ width: 100 }}
      >
        <Text>{clientPronouns}</Text>
      </Flex>
      <Flex
        gap={8}
        css={{
          width: 250,
        }}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Text> {ageText}</Text>
      </Flex>
      {!!cityAndState ? (
        <Flex
          gap={8}
          css={{
            width: 150,
          }}
          alignItems={"center"}
        >
          <LocationIcon />
          <Text>
            <Mangle>{cityAndState}</Mangle>
          </Text>
        </Flex>
      ) : null}
    </Flex>
  );
};
