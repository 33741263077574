import { GraphDataPointData } from "../graph.types";

export interface GraphDotProps {
  cx: number;
  cy: number;
  payload: GraphDataPointData;
}

export const GraphDot = (props: GraphDotProps) => {
  const { cx, cy, payload } = props;
  const { statusColor, value } = payload;

  if (value !== undefined) {
    return (
      <svg cx={cx} cy={cy} fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle
          cx={cx}
          cy={cy}
          r={10}
          fill={statusColor || '#fff'} stroke="#8F8F8F"
        />
      </svg>
    );
  }
  return null;
};
