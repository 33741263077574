import { graphqlClient } from "../../../api/graphql";
import {
  MyClientsOverviewQuery,
  MyClientsOverviewReliableChangeQuery,
  useMyClientsOverviewQuery,
  useMyClientsOverviewReliableChangeQuery,
} from "../../../graphql/generated";
import { FIVE_MINUTES } from "../../_helpers/constants";

interface UseMyClientsOverviewParams<T extends {}> {
  clinicianId: number;
  select?: (data: MyClientsOverviewQuery) => T;
  enabled: boolean;
}

interface UseMyClientsOverviewReliableChangeParams<T extends {}> {
  clinicianId: number;
  select?: (data: MyClientsOverviewReliableChangeQuery) => T;
  enabled: boolean;
}

export const useMyClientsOverview = <T extends {}>({
  clinicianId,
  select,
  enabled,
}: UseMyClientsOverviewParams<T>) => {
  return useMyClientsOverviewQuery(
    graphqlClient,
    { clinician_id: clinicianId },
    {
      staleTime: FIVE_MINUTES,
      select,
      enabled,
    },
  );
};

export const useMyClientsOverviewReliableChange = <T extends {}>({
  clinicianId,
  select,
  enabled,
}: UseMyClientsOverviewReliableChangeParams<T>) => {
  return useMyClientsOverviewReliableChangeQuery(
    graphqlClient,
    { clinician_id: clinicianId },
    {
      staleTime: FIVE_MINUTES,
      select,
      enabled,
    },
  );
};
