import ExternalLink from "@/app/_shared/ExternalLink";
import React from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

import { $antdBorder, $greyText } from "../../../../src/assets/colors";
import { Input } from "../../_shared/antd";

export const ModalContent = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  white-space: wrap;
`;

export const ModalSection = styled.div`
  border-bottom: 1px solid ${$antdBorder};
  padding: 8px 16px;

  h3 {
    margin: 0.5em 0;
  }

  h4 {
    margin-top: 6px;
  }

  &:last-of-type {
    border-bottom: none;
  }
`;

export const ModalTitle = styled(ModalSection)`
  h3 {
    margin-bottom: 0;
  }
  h4 {
    color: ${$greyText};
    margin-top: 0;
  }
`;

export const TextArea = styled(Input.TextArea)`
  height: calc(100% - 48px) !important;
`;

export const DebugView: React.FC<{ children: JSX.Element }> = ({
  children,
}) => {
  const [searchParams] = useSearchParams();

  if (searchParams.get("debug") === "true") {
    return children;
  }

  return null;
};

export const ModalExternalLink: React.FC<{ href: string }> = ({
  href,
  children,
}) => {
  return (
    <ExternalLink href={href} style={{ color: "#008dff", paddingRight: "8px" }}>
      {children}
    </ExternalLink>
  );
};
