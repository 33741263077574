import React from "react";
import { Flex, Stack, Text } from "../../../../../../design-system";
import { VisuallyNestedElevatedMangle } from "../../../../../../_shared/Mangle";
import { ClientCardProps } from "../client-pane";
import { MatchingStatusBadge } from "./matching-status-badge";
import { FlagIcon } from "@/app/design-system/icons/components/flag-icon";
import { CSS_COLORS } from "../../../../../../design-system";
type ClientNameAndPayerTextProps = Pick<
  ClientCardProps,
  "clientPayerServiceRecord" | "firstName" | "lastName" | "matchingInfo" | "isHighAcuity"
> & {
  link: string;
};

export const ClientNameAndPayerText = ({
  firstName,
  lastName,
  matchingInfo,
  clientPayerServiceRecord,
  link,
  isHighAcuity,
}: ClientNameAndPayerTextProps) => {
  return (
    <Stack
      gap={12}
      css={{
        flex: 7,
        marginRight: 20,
      }}
    >
      <Flex
        gap={8}
        alignItems={"center"}
        css={{
          "@breakpoint640": {
            flexDirection: "column",
            alignItems: "flex-start",
          },
        }}
      >
        <Text css={{ fontWeight: 600 }} className="client-name">
          <VisuallyNestedElevatedMangle link={link}>
            {firstName} {lastName}
          </VisuallyNestedElevatedMangle>
        </Text>
        {matchingInfo ? (
          <MatchingStatusBadge clientMatchingData={matchingInfo} />
        ) : null}
      </Flex>

      <Flex>
        <Text color={"$neutral9"}>
          {clientPayerServiceRecord
            ? clientPayerServiceRecord.payer.displayName
            : "DTC"}
        </Text>
        {isHighAcuity && (
            <FlagIcon stroke={CSS_COLORS.red11} title="High Acuity"/>
        )}
      </Flex>
    </Stack>
  );
};
