import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import {
  Control,
  useForm,
  UseFormReturn,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import * as yup from "yup";

import { CancellationType } from "../use-fetch-cancellation-types-and-reasons/event-cancellation-types.constants";
import { getOptions } from "../use-fetch-cancellation-types-and-reasons/event-cancellation-reasons.utils";
import { getInitialCancellationReasons } from "../use-fetch-cancellation-types-and-reasons/event-cancellation-reasons.utils";
import { useFetchCancellationTypesAndReasons } from "../use-fetch-cancellation-types-and-reasons/use-fetch-cancellation-types-and-reasons";
import { selectDataForEditEventForm } from "../use-fetch-cancellation-types-and-reasons/select-data-for-cancel-client-group-event";
import { cancellationTypeToFrontendValueMap } from "../use-fetch-cancellation-types-and-reasons/event-cancellation-types.constants";
export interface CancelSessionFormFields {
  cancellationType: string;
  cancellationReason: string;
  attendanceNote: string;
}

export enum CancelClientGroupEventFieldNames {
  CANCELLATION_TYPE = "cancellationType",
  CANCELLATION_REASON = "cancellationReason",
  ATTENDANCE_NOTE = "attendanceNote",
}

export const useCancelClientGroupEventForm = () => {
  const initialCancellationFormFields: CancelSessionFormFields = {
    cancellationType: "Select",
    cancellationReason: "Select",
    attendanceNote: "",
  };

  const { data } = useFetchCancellationTypesAndReasons({
    select: selectDataForEditEventForm,
  });

  const {
    cancelByClientReasonOptions,
    cancelByClinicianReasonOptions,
    lateCancelByClientReasonOptions,
    noShowReasonOption,
  } = getOptions(data);

  const schema = yup.object({
    [CancelClientGroupEventFieldNames.CANCELLATION_TYPE]: yup
      .string()
      .oneOf(Object.values(CancellationType))
      .required(),
    [CancelClientGroupEventFieldNames.CANCELLATION_REASON]: yup
      .string()
      .not(["Select"])
      .when(CancelClientGroupEventFieldNames.CANCELLATION_TYPE, {
        is: CancellationType.CANCELED_BY_CLIENT,
        then: () =>
          yup
            .string()
            .oneOf(cancelByClientReasonOptions.map((reason) => reason.value))
            .required(),
      })
      .when(CancelClientGroupEventFieldNames.CANCELLATION_TYPE, {
        is: CancellationType.CANCELED_BY_CLINICIAN,
        then: () =>
          yup
            .string()
            .oneOf(cancelByClinicianReasonOptions.map((reason) => reason.value))
            .required(),
      })
      .when(CancelClientGroupEventFieldNames.CANCELLATION_TYPE, {
        is: CancellationType.LATE_CANCEL_BY_CLIENT,
        then: () =>
          yup
            .string()
            .oneOf(
              lateCancelByClientReasonOptions.map((reason) => reason.value),
            )
            .required(),
      })
      .when(CancelClientGroupEventFieldNames.CANCELLATION_TYPE, {
        is: CancellationType.NO_SHOW,
        then: () =>
          yup
            .string()
            .oneOf(noShowReasonOption.map((reason) => reason.value))
            .required(),
      }),
    [CancelClientGroupEventFieldNames.ATTENDANCE_NOTE]: yup.string(),
  });

  const formMethods = useForm<CancelSessionFormFields>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      ...initialCancellationFormFields,
    },
  });

  const {
    control,
    formState: { isValid },
    getValues,
    setValue,
    handleSubmit,
    trigger,
    reset,
  } = formMethods;

  return {
    formMethods,
    isValid,
    setValue,
    control,
    handleSubmit,
    trigger,
    reset,
    getValues,
  };
};

const mapIdsToOptions = (options: { value: string; label: string }[]) => {
  return options.map(({ label, value }, index) => ({
    id: index.toString(),
    label,
    value,
  }));
};

export const useCancellationReasons = (
  control: Control<CancelSessionFormFields>,
  setValue: UseFormSetValue<CancelSessionFormFields>,
  formMethods: UseFormReturn<CancelSessionFormFields, any>,
) => {
  const selectedCancellationType = useWatch<CancelSessionFormFields>({
    name: CancelClientGroupEventFieldNames.CANCELLATION_TYPE,
    control,
  });

  const { data } = useFetchCancellationTypesAndReasons({
    select: selectDataForEditEventForm,
  });

  const {
    cancellationTypeOptions,
    cancelByClientReasonOptions,
    cancelByClinicianReasonOptions,
    lateCancelByClientReasonOptions,
    noShowReasonOption,
  } = getOptions(data);

  const initialCancellationReasons = getInitialCancellationReasons(
    selectedCancellationType,
    data?.cancellationReasonOptions,
  );

  const [conditionalCancellationReasons, setConditionalCancellationReasons] =
    useState(mapIdsToOptions(initialCancellationReasons));

  const [conditionalCancellationType, setConditionalCancellationType] =
    useState(mapIdsToOptions(cancellationTypeOptions));

  useEffect(() => {
    if (selectedCancellationType === CancellationType.CANCELED_BY_CLIENT) {
      setConditionalCancellationReasons(cancelByClientReasonOptions);
      setValue("cancellationReason", "Select");
    } else if (
      selectedCancellationType === CancellationType.CANCELED_BY_CLINICIAN
    ) {
      setConditionalCancellationReasons(cancelByClinicianReasonOptions);
      setValue("cancellationReason", "Select");
    } else if (
      selectedCancellationType === CancellationType.LATE_CANCEL_BY_CLIENT
    ) {
      setConditionalCancellationReasons(lateCancelByClientReasonOptions);
      setValue("cancellationReason", "Select");
    } else if (selectedCancellationType === CancellationType.NO_SHOW) {
      setConditionalCancellationReasons(noShowReasonOption);
      setValue("cancellationReason", "No show (missed session)", {
        shouldValidate: true,
      });
    }
  }, [selectedCancellationType]);

  useEffect(() => {
    if (
      cancellationTypeToFrontendValueMap[selectedCancellationType] ===
        CancellationType.NO_SHOW ||
      cancellationTypeToFrontendValueMap[selectedCancellationType] ===
        CancellationType.LATE_CANCEL_BY_CLIENT
    ) {
      formMethods.resetField("cancellationType");
      formMethods.resetField("cancellationReason");
    } else {
      setConditionalCancellationType([...cancellationTypeOptions]);
    }
  }, []);

  return [conditionalCancellationReasons, conditionalCancellationType];
};
