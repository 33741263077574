import { Label, Stack } from "@/app/design-system";
import { ComposedRadioGroup } from "@/app/design-system/radio-group/radio-group.composed";
import "react-day-picker/dist/style.css";
import { useController, useFormContext } from "react-hook-form";
import { recurringSessionsOptions } from "../../../session-actions.constants";
import { UpsertSessionFormItemProps } from "./upsert-session-form-item.interface";
interface SessionRecurrenceFormItem
  extends Omit<UpsertSessionFormItemProps, "mode"> {
  mode: "edit" | "cancel";
}

export const SessionsToSelectFormItem = ({
  name,
  id,
  mode,
}: SessionRecurrenceFormItem) => {
  const { control } = useFormContext();

  // register input
  const {
    field: { value, onChange, onBlur, ref },
    fieldState: { invalid, isDirty },
    formState: { errors },
  } = useController({
    name,
    control,
  });

  return (
    <Stack gap={10}>
      <Label>{mode === "edit" ? "Apply changes to:" : "Cancel:"} </Label>
      <ComposedRadioGroup
        options={recurringSessionsOptions}
        onValueChange={onChange}
        value={value}
        defaultValue={value}
      />
    </Stack>
  );
};
