// TODO: We'll need to eventually make sure the click events are UnstyledButtons and not divs
// JIRA - https://twochairs.atlassian.net/browse/TC-7199
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { getCurrentConsultNoteInfo } from "@/app/consults-and-matching/_hooks";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Radio } from "antd";
import * as React from "react";
import { useDispatch } from "react-redux";

import { ConsultNoteQuestion } from "../../../../../api/types";
import { consultNoteActions } from "../../../../../state/models/consultnote";
import { useShallowEqualSelector } from "../../../../_helpers/redux";
import { Title } from "../../../../_layout/display";
import { RequiredText } from "../../../../_shared/RequiredText";
import Copy from "./copy";
import { options } from "./enums";

interface Props {
  question: ConsultNoteQuestion;
  handleEdit: (hasBeenEdited: boolean) => void;
}

export default ({ question, handleEdit }: Props) => {
  const { readOnlyConsultNote } = getCurrentConsultNoteInfo();
  const dispatch = useDispatch();

  const [value] = useShallowEqualSelector((state) => [
    state.consultnote?.openConsult?.consult_note?.[question.slug],
  ]);

  React.useEffect(() => {
    dispatch(
      consultNoteActions.setConsultNoteValue({
        key: question.slug,
        value: value,
      }),
    );
  }, [value]);

  const [showingGuide, setShowingGuide] = React.useState(false);

  const handleChange = (e: any) => {
    handleEdit(true);
    dispatch(
      consultNoteActions.setConsultNoteValue({
        key: question.slug,
        value: e.target.value,
      }),
    );
  };

  return (
    <div style={{ paddingTop: "15px", paddingBottom: "15px" }}>
      <Title margin="3px" size="sm">
        <b>
          {question.questionNumber}. {question.title}
        </b>
        {question.subtitle}
        {question.isRequired && <RequiredText> *</RequiredText>}
        <span
          style={{ cursor: "pointer", paddingLeft: "12px" }}
          onClick={() => setShowingGuide(!showingGuide)}
        >
          <InfoCircleOutlined /> <i>Show legend</i>
        </span>
      </Title>
      {showingGuide && <Copy />}
      <Radio.Group
        onChange={handleChange}
        value={value}
        disabled={readOnlyConsultNote}
      >
        {options.map((o) => {
          return (
            <Radio key={o.key} value={o.value} style={{ marginRight: "15px" }}>
              {o.title}
            </Radio>
          );
        })}
      </Radio.Group>
    </div>
  );
};
