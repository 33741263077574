import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  matchmakerOperations,
  matchmakerActions,
} from "../../../../state/models/matchmaker";
import { Pad } from "../../../_layout/display";
import { Title } from "../../../_layout/display";
import { Select as DeprecatedAntdSelect, Button, Alert } from "antd";
import useDebounce from "../../../hooks/useDebounce";
import { useShallowEqualSelector } from "../../../_helpers/redux";
import { alphabetizeByPass } from "../../../_helpers";
import * as colors from "../../../../assets/colors";
import { ConflictOfInterest } from "../../../../api/types";
import { isEqual } from "lodash-es";
import Mangle from "../../../_shared/Mangle";
import { Row, Flex } from "../../../_layout/Flex";

type DropdownOption =
  | {
      id: number;
      type: "clinician";
      first_name: string;
      last_name: string;
      label: "Clinician";
    }
  | {
      id: number;
      type: "client";
      first_name: string;
      last_name: string;
      label: "Client";
      clinician_ids: number[];
    }
  | {
      id: string;
      type: "hq_member";
      first_name: string;
      full_name: string;
      email: string;
      label: "Hq Member";
    };

export default () => {
  const [
    conflictOfInterestSearchResults,
    clinicianMap,
    conflictsOfInterest,
    hasSubmittedCOI,
  ] = useShallowEqualSelector((state) => [
    state.matchmaker.conflictOfInterestSearchResults,
    state.clinicians.clinicianMap,
    state.matchmaker.conflictsOfInterest,
    state.matchmaker.hasSubmittedCOI,
  ]);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 750);

  const defaultValues = useMemo(
    () => conflictsOfInterest.map((coi) => `${coi.type},${coi.id}`),
    [conflictsOfInterest],
  );

  const dropdownOptions: DropdownOption[] = useMemo(() => {
    let items: DropdownOption[] = [];
    if (conflictOfInterestSearchResults.length > 0) {
      items = conflictOfInterestSearchResults.map((item) => {
        if (item.type === "clinician") {
          const clinician = clinicianMap[item.id];
          return {
            ...item,
            label: "Clinician",
            first_name: clinician.first_name,
            last_name: clinician.last_name,
          };
        }
        if (item.type === "hq_member") {
          return {
            ...item,
            first_name: item.full_name.split(" ")[0],
            label: "Hq Member",
          };
        }
        return {
          ...item,
          label: "Client",
        };
      });
    } else if (debouncedSearchTerm.length === 0) {
      items = Object.values(clinicianMap).map((clinician) => ({
        id: clinician.id,
        type: "clinician",
        label: "Clinician",
        first_name: clinician.first_name,
        last_name: clinician.last_name,
      }));
    } else {
      items = [];
    }

    conflictsOfInterest.forEach((coi) => {
      let addition: DropdownOption | null = null;
      if (coi.type === "client") {
        addition = {
          ...coi,
          label: "Client",
        };
      } else if (coi.type === "clinician") {
        const clinician = clinicianMap[coi.id];
        if (clinician) {
          addition = {
            ...coi,
            label: "Clinician",
            first_name: clinician.first_name,
            last_name: clinician.last_name,
          };
        }
      } else if (coi.type === "hq_member") {
        addition = {
          ...coi,
          first_name: coi.full_name.split(" ")[0],
          label: "Hq Member",
        };
      }
      if (addition && !items.some((item) => isEqual(item, addition))) {
        items.push(addition);
      }
    });

    return items;
  }, [
    conflictOfInterestSearchResults,
    conflictsOfInterest,
    clinicianMap,
    debouncedSearchTerm,
  ]);

  const dispatch = useDispatch();
  const getConflictsOfInterest = useCallback(
    (searchTerm: string) =>
      dispatch(matchmakerOperations.searchConflictsOfInterest(searchTerm)),
    [dispatch],
  );
  const saveConflictsOfInterest = useCallback(
    (selections: string[]) => {
      let additionalClinicianIds: number[] = [];
      const cois: ConflictOfInterest[] = selections.map((selection) => {
        const [type, idStr] = selection.split(",");

        const coi = dropdownOptions.find(
          (option) =>
            option.type === type &&
            (option.id === parseInt(idStr) || option.id === idStr),
        )!;

        if (
          coi.type === "client" &&
          !conflictsOfInterest.some(
            (item) => item.id === coi.id && item.type === "client",
          )
        ) {
          additionalClinicianIds = coi.clinician_ids;
        }

        return coi;
      });

      if (additionalClinicianIds.length > 0) {
        additionalClinicianIds.forEach((clinician_id) => {
          if (
            clinicianMap[clinician_id] &&
            !selections.some(
              (selection) => selection === `clinician,${clinician_id}`,
            )
          ) {
            cois.push({ id: clinician_id, type: "clinician" });
          }
        });
      }

      dispatch(matchmakerActions.saveConflictsOfInterest(cois));
    },
    [dropdownOptions, dispatch, conflictsOfInterest, clinicianMap],
  );

  useEffect(() => {
    getConflictsOfInterest(debouncedSearchTerm);
  }, [getConflictsOfInterest, debouncedSearchTerm]);

  return (
    <Pad>
      {!hasSubmittedCOI ? (
        <Alert
          type="error"
          style={{ marginBottom: "15px" }}
          message={
            <Row layout="start center">
              <Flex>
                Reminder: Search for this client's stated Conflicts of Interest
                below.
              </Flex>
              <Button
                onClick={() =>
                  dispatch(
                    matchmakerActions.saveConflictsOfInterest(
                      conflictsOfInterest,
                    ),
                  )
                }
              >
                Dismiss
              </Button>
            </Row>
          }
        />
      ) : null}
      <Title margin="3px" size="sm">
        COI / Multiple Relationships:
      </Title>
      <DeprecatedAntdSelect
        mode="multiple"
        placeholder="Search for clients or employees of Two Chairs..."
        style={{ marginBottom: "5px", width: "100%" }}
        onSearch={(value: string) => setSearchTerm(value.trim())}
        onBlur={() => setSearchTerm("")}
        filterOption={false}
        value={defaultValues}
        onChange={(selections: string[]) => saveConflictsOfInterest(selections)}
        notFoundContent="Nobody found by this name."
      >
        {dropdownOptions.sort(alphabetizeByPass("first_name")).map((item) => (
          <DeprecatedAntdSelect.Option
            key={`${item.type},${item.id}`}
            value={`${item.type},${item.id}`}
          >
            {item.type === "client" && (
              <>
                <Mangle>
                  {item.first_name} {item.last_name}
                </Mangle>{" "}
                <i style={{ color: colors.$greyText }}>{item.label}</i>
              </>
            )}
            {item.type === "clinician" && (
              <>
                {item.first_name} {item.last_name}{" "}
                <i style={{ color: colors.$greyText }}>{item.label}</i>
              </>
            )}
            {item.type === "hq_member" && (
              <>
                {item.full_name}{" "}
                <i style={{ color: colors.$greyText }}>{item.label}</i>
              </>
            )}
          </DeprecatedAntdSelect.Option>
        ))}
      </DeprecatedAntdSelect>
      <p style={{ fontSize: "10px" }}>
        <i>
          Note: Selecting a client here will ensure we do not match the two
          clients to the same treatment clinician.
        </i>
      </p>
    </Pad>
  );
};
