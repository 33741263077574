import { styledStitches } from "@/app/design-system/styles/stitches.config";
export const DEFAULT_FLEX_STYLE = {
  boxSizing: "border-box",
  display: "flex",
};
export const Flex = styledStitches("div", {
  ...DEFAULT_FLEX_STYLE,
  variants: {
    display: {
      "inline-flex": {
        display: "inline-flex",
      },
    },
    flexDirection: {
      row: {
        flexDirection: "row",
      },
      column: {
        flexDirection: "column",
      },
      rowReverse: {
        flexDirection: "row-reverse",
      },
      columnReverse: {
        flexDirection: "column-reverse",
      },
    },
    alignItems: {
      "flex-start": {
        alignItems: "flex-start",
      },
      center: {
        alignItems: "center",
      },
      "flex-end": {
        alignItems: "flex-end",
      },
      stretch: {
        alignItems: "stretch",
      },
      baseline: {
        alignItems: "baseline",
      },
    },
    justifyContent: {
      "flex-start": {
        justifyContent: "flex-start",
      },
      center: {
        justifyContent: "center",
      },
      "flex-end": {
        justifyContent: "flex-end",
      },
      "space-between": {
        justifyContent: "space-between",
      },
    },

    flexWrap: {
      noWrap: {
        flexWrap: "nowrap",
      },
      wrap: {
        flexWrap: "wrap",
      },
      wrapReverse: {
        flexWrap: "wrap-reverse",
      },
    },
    height: {
      all: {
        height: "100%",
      },
    },
    gap: {
      2: {
        gap: "$space2",
      },
      4: {
        gap: "$space4",
      },
      8: {
        gap: "$space8",
      },
      12: {
        gap: "$space12",
      },
      16: {
        gap: "$space16",
      },
      18: {
        gap: "$space18",
      },
      20: {
        gap: "$space20",
      },
      24: {
        gap: "$space24",
      },
      32: {
        gap: "$space32",
      },
      48: {
        gap: "$space48",
      },
    },
  },

  defaultVariants: {
    flexDirection: "row",
    alignItems: "stretch",
    justifyContent: "start",
    flexWrap: "noWrap",
  },
});
