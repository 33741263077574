import { useGetClinicalUtilizationQuery } from "./../../../graphql/generated";
import { graphqlClient } from "@/api/graphql";
import { DateTime } from "luxon";
import { ONE_HOUR } from "@/app/_helpers/constants";

interface UseGetClinicalUtilizationQueryParams {
  clinicianId: number;
  start: DateTime;
  end: DateTime;
  enabled?: boolean;
}

/** @function useDashboardClinicalUtilization
 * @params {number} clinicianId
 * @params {DateTime} start (function will round to previous Monday)
 * @params {DateTime} end (function will round to previous Monday)
 * @params {boolean} enabled
 * @description returns utilization data by week in ascending order for the Care Platform dashboard. If you do not want the week to start on the previous Monday, use useClinicalUtilization
 */
export const useDashboardClinicalUtilization = ({
  clinicianId,
  start,
  end,
  enabled,
}: UseGetClinicalUtilizationQueryParams) => {
  return useClinicalUtilization({
    clinicianId,
    // We want to match the logic in Clinical/utilization.sql so we are getting the start of the week
    // luxon's start of the week always starts on Monday according to the documentation (https://moment.github.io/luxon/api-docs/index.html#datetimestartof)
    // just like postgresql's date_trunc (https://www.postgresql.org/docs/current/functions-datetime.html#FUNCTIONS-DATETIME-TRUNC)
    // (under 'isodow' -  `The day of the week as Monday (1) to Sunday (7)` )
    start: DateTime.local(start.year, start.month, start.day).startOf("week"),
    end: DateTime.local(end.year, end.month, end.day).startOf("week"),
    enabled,
  });
};

/** @function useClinicalUtilization
 * @params {number} clinicianId
 * @params {DateTime} start
 * @params {DateTime} end
 * @params {boolean} enabled
 * @description returns utilization data by week in ascending order.
 */
export const useClinicalUtilization = ({
  clinicianId,
  start,
  end,
  enabled,
}: UseGetClinicalUtilizationQueryParams) => {
  return useGetClinicalUtilizationQuery(
    graphqlClient,
    {
      clinician_id: clinicianId,
      start: start.toISODate(),
      end: end.toISODate(),
    },
    { staleTime: ONE_HOUR, enabled },
  );
};
