import { startCase } from "lodash-es";

import { useEffect, useState } from "react";

import {
  AQMTuningParams,
  AQMTuningParamsBase,
  IdMap,
  AQMScoreBreakdown,
} from "@/api/types";
import { useShallowEqualSelector } from "@/app/_helpers/redux";

interface UseAQMScoreTableParams {
  matchId: number;
}

export const parseScore = (score: number) => {
  return Number(score.toFixed(1));
};

const getRedundencies = (
  aqmScores: IdMap<AQMScoreBreakdown> | null | undefined,
) => {
  if (!aqmScores) {
    return [];
  }

  const allBreakdownComponents = Object.values(aqmScores).map(
    (breakdown) => breakdown.components,
  );
  const prototype = allBreakdownComponents[0];

  const redundencies = Object.keys(prototype).filter((key) =>
    allBreakdownComponents.every(
      (breakdown) => parseScore(breakdown[key]) === parseScore(prototype[key]),
    ),
  );

  return redundencies;
};

export function useFilterTuningParams(
  matchId: number,
  mergedBreakdownComponents: Partial<AQMTuningParamsBase<number>>,
) {
  const [aqmScores] = useShallowEqualSelector((state) => [
    state.matches.aqmScores[matchId],
  ]);

  const redundencies = getRedundencies(aqmScores);
  const breakdownColumns: string[] = Object.keys(mergedBreakdownComponents);

  const [componentColumns, setComponentColumns] = useState<any>([]);

  useEffect(() => {
    // filter out tuning params with equal values, and format remaining ones
    const columns = AQMTuningParams.filter((param) => {
      return !redundencies.includes(param) && breakdownColumns.includes(param);
    }).map((param) => {
      return {
        title: startCase(param).split(" ").slice(0, 2).join(" "),
        dataIndex: param,
        key: param,
        render: parseScore,
      };
    });

    setComponentColumns(columns);
  }, [aqmScores]);

  return componentColumns;
}
