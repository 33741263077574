
import React from 'react';

interface NoDataMessageProps {
  message?: string;
}

/**
 * @returns span letting clinicians know why they may not be seeing any data. Useful for clinicians who are new, clinicians who have no active clients, team leads
 */
export const NoDataMessage: React.FC<NoDataMessageProps> = ({ message }) => {
  return <span>{message ? message : 'No data available'}</span>;
};
