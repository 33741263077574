import React from "react";
import { useController, useFormContext } from "react-hook-form";
import { Label, Stack } from "../../../../../../../../../../../design-system";
import {
  DeprecatedComposedReachUISelect,
  Option,
} from "../../../../../../../../../../../design-system/select-reachui/composed-select-reachui";

interface CancellationFormItemProps {
  name: string;
  options: Option[];
  id?: string;
  callback: () => void;
}

export const CancellationTypeFormItem = ({
  name,
  options,
  id,
  callback,
}: CancellationFormItemProps) => {
  const { control } = useFormContext();

  // register input
  const {
    field: { value, onChange, onBlur, ref },
    fieldState: { invalid, isDirty },
    formState: { errors },
  } = useController({
    name,
    control,
  });

  return (
    <Stack gap={10}>
      <Label required>Cancellation type</Label>
      <DeprecatedComposedReachUISelect
        options={options}
        value={value}
        onChange={(value) => {
          onChange(value);
          callback();
        }}
        id={id}
        selectRootName={"Cancellation type"}
      />
    </Stack>
  );
};
