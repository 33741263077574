import { Table } from "antd";
import React from "react";

import { UserHasAnyPermissions } from "@/app/_helpers/permissions";
import { useShallowEqualSelector } from "../../../_helpers/redux";
import { useAQMScoreTable } from "./hooks/use-aqm-score-table/use-aqm-score-table";
import { useGetAQMScoreTableColumns } from "./hooks/use-get-aqm-table-columns";

interface Props {
  matchId: number;
}

export const AQMScoreTable = (props: Props) => {
  const { matchId } = props;

  const [aqmScores, currentUser, loadingMatchSuggestions] =
    useShallowEqualSelector((state) => [
      state.matches.aqmScores[matchId],
      state.auth.currentUser,
      state.matches.loadingMatchSuggestions,
    ]);

  if (!UserHasAnyPermissions(currentUser, ["IsMatchingAdmin"])) {
    return null;
  }

  const { tableData, componentColumns } = useAQMScoreTable({ matchId });

  const { columns } = useGetAQMScoreTableColumns({ componentColumns });

  return aqmScores && tableData && componentColumns ? (
    <Table
      columns={columns}
      dataSource={tableData ?? undefined}
      loading={loadingMatchSuggestions}
      // onChange={(_pagination, _x, sorter) => { setSortedInfo(sorter); }}
      pagination={{ hideOnSinglePage: true, pageSize: 7 }}
      size="small"
    />
  ) : (
    <Table />
  );
};
