import * as React from "react";
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Tooltip } from "antd";

import { Resource } from "./types";
import { IfPermitted } from "../_helpers/permissions";
import { $early } from "../../assets/colors";

export const EditIcon = ({
  tooltipTitle,
  onClick,
}: {
  tooltipTitle?: string;
  onClick: (e: React.SyntheticEvent) => void;
}) => {
  return (
    <Tooltip placement="top" title={tooltipTitle}>
      <EditOutlined
        style={{
          fontSize: 20,
          marginLeft: 12,
          marginRight: 12,
          cursor: "pointer",
        }}
        onClick={onClick} />
    </Tooltip>
  );
};

export const FavoriteResourceIcon = ({
  resource,
  onClick,
}: {
  resource: Resource;
  onClick: (event: React.SyntheticEvent) => void;
}) => {
  return (
    <IfPermitted permissions={["IsClinician"]} requireAll={true}>
      <Tooltip
        placement="bottom"
        title={
          resource.is_favorite
            ? "You have favorited this resource"
            : "Favorite this resource"
        }
      >
        <LegacyIcon
          type="star"
          theme={resource.is_favorite ? "filled" : "outlined"}
          style={{
            fontSize: 20,
            marginLeft: 12,
            marginRight: 12,
            cursor: "pointer",
            color: resource.is_favorite ? $early : "initial",
          }}
          onClick={onClick}
        />
      </Tooltip>
    </IfPermitted>
  );
};

export const PersonalResourceIcon = ({ resource }: { resource: Resource }) => {
  return (
    <IfPermitted
      permissions={["IsResourceLibCurator", "IsClinician"]}
      requireAll={false}
    >
      <Tooltip
        placement="top"
        title={resource.is_personal ? "Personal Resource" : "Library Resource"}
      >
        <LegacyIcon
          type={resource.is_personal ? "lock" : "global"}
          style={{
            fontSize: 20,
            marginLeft: 12,
            marginRight: 12,
          }}
        />
      </Tooltip>
    </IfPermitted>
  );
};

export const PublishStatusIcon = ({
  is_published,
}: {
  is_published: boolean;
}) => {
  return (
    <IfPermitted permissions={["IsResourceLibCurator"]} requireAll={true}>
      <Tooltip
        placement="top"
        title={is_published ? "Published" : "Not published"}
      >
        <LegacyIcon
          type={is_published ? "eye" : "eye-invisible"}
          style={{
            fontSize: 20,
            marginLeft: 12,
            marginRight: 12,
          }}
        />
      </Tooltip>
    </IfPermitted>
  );
};

interface TrashIconProps {
  onClick: (e: React.SyntheticEvent) => void;
}

export const TrashIcon = ({ onClick }: TrashIconProps) => {
  return (
    <Tooltip placement="top" title={"Delete resource"}>
      <DeleteOutlined
        style={{
          fontSize: 20,
          marginLeft: 12,
          marginRight: 12,
          cursor: "pointer",
        }}
        onClick={onClick} />
    </Tooltip>
  );
};
