import Mangle from "@/app/_shared/Mangle";
import { Flex, Text } from "@/app/design-system";
import { PersonIcon } from "@/app/design-system/icons";
import { GroupsEndorsedSIAlertIcon } from "@/app/design-system/icons/components/groups-endorsed-si-alert-icon";

interface ClientNameAndSIAlertProps {
  clientName: string;
  endorsedSI?: boolean | null;
}

export const ClientNameAndSIAlert = ({
  clientName,
  endorsedSI,
}: ClientNameAndSIAlertProps) => {
  return (
    <Flex
      gap={16}
      css={{
        minWidth: 400,
      }}
    >
      <PersonIcon />

      <Text css={{ fontWeight: 600 }}>
        <Mangle>{clientName}</Mangle>
      </Text>
      {endorsedSI ? (
        <Flex gap={4}>
          <GroupsEndorsedSIAlertIcon />
        </Flex>
      ) : null}
    </Flex>
  );
};
