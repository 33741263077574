// TODO: We'll need to eventually make sure the click events are UnstyledButtons and not divs
// JIRA - https://twochairs.atlassian.net/browse/TC-7199
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { InfoCircleOutlined } from "@ant-design/icons";
import { Input, Radio } from "antd";
import * as React from "react";
import { useDispatch } from "react-redux";

import { ConsultNoteQuestion } from "../../../../../api/types";
import { consultNoteActions } from "../../../../../state/models/consultnote";
import { useShallowEqualSelector } from "../../../../_helpers/redux";
import { Title } from "../../../../_layout/display";
import { RequiredText } from "../../../../_shared/RequiredText";
import useDebounce from "../../../../hooks/useDebounce";
import { RadioGroup } from "../../../Matchmaker/1-ClientStep/CarePreferences/styled";
import SymptomGuide from "./copy";
import { getCurrentConsultNoteInfo } from "@/app/consults-and-matching/_hooks";

interface Props {
  question: ConsultNoteQuestion;
  handleEdit: (hasBeenEdited: boolean) => void;
}

const { useState } = React;

export default ({ question, handleEdit }: Props) => {
  const { readOnlyConsultNote } = getCurrentConsultNoteInfo();

  const [
    criterion_trauma_endorsed,
    criterion_trauma_endorsed_additional_details,
  ] = useShallowEqualSelector((state) => [
    state.consultnote?.openConsult?.consult_note?.criterion_trauma_endorsed,
    state.consultnote?.openConsult?.consult_note
      ?.criterion_trauma_endorsed_additional_details
      ? state.consultnote?.openConsult?.consult_note
          ?.criterion_trauma_endorsed_additional_details
      : "",
  ]);

  const handleChange = (key: string, value: boolean | string) => {
    handleEdit(true);
    dispatch(
      consultNoteActions.setConsultNoteValue({
        key,
        value,
      }),
    );
  };

  const [showingSymptomGuide, setShowingSymptomGuide] = useState(false);

  const [additionalDetails, setAdditionalDetails] = React.useState(
    criterion_trauma_endorsed_additional_details,
  );

  const handleSetAdditionalDetails = (value: string) => {
    setAdditionalDetails(value);
    handleEdit(true);
  };

  const dispatch = useDispatch();

  const debouncedValue = useDebounce(additionalDetails, 500);

  React.useEffect(() => {
    setAdditionalDetails(criterion_trauma_endorsed_additional_details);
  }, [criterion_trauma_endorsed_additional_details]);

  React.useEffect(() => {
    dispatch(
      consultNoteActions.setConsultNoteValue({
        key: "criterion_trauma_endorsed_additional_details",
        value: debouncedValue,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return (
    <div style={{ paddingTop: "15px", paddingBottom: "15px" }}>
      <Title margin="3px" size="sm">
        <b>
          {question.questionNumber}. {question.title}
        </b>
        {question.subtitle}
        {question.isRequired && <RequiredText> *</RequiredText>}
        <span
          style={{ cursor: "pointer", paddingLeft: "12px" }}
          onClick={() => setShowingSymptomGuide(!showingSymptomGuide)}
        >
          <InfoCircleOutlined /> <i>Show legend</i>
        </span>
        {showingSymptomGuide && <SymptomGuide />}
      </Title>
      <RadioGroup
        disabled={readOnlyConsultNote}
        onChange={(e) => {
          handleChange("criterion_trauma_endorsed", e.target.value);
        }}
        value={criterion_trauma_endorsed}
      >
        <Radio.Button value={false}>Denied</Radio.Button>
        <Radio.Button value={true}>Endorsed</Radio.Button>
      </RadioGroup>
      {criterion_trauma_endorsed && (
        <div>
          <div>Additional details (if needed)</div>
          <Input.TextArea
            disabled={readOnlyConsultNote}
            style={{ height: "32px" }}
            value={additionalDetails}
            onChange={(e) => handleSetAdditionalDetails(e.target.value)}
          />
        </div>
      )}
    </div>
  );
};
