import React from "react";
import {
  useMyClientsStore,
  selectMBCStatusFiltersSlice,
  initialMBCStatusFilterState,
  MBCStatusFilterState,
} from "../../../../../my-clients-model";

export const useMBCStatusFiltersDropdown = () => {
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

  const setMyClientsFilter = useMyClientsStore(
    (state) => state.setMyClientsFilter,
  );

  const mbcStatusFilterState = useMyClientsStore(selectMBCStatusFiltersSlice);

  const clearMBCStatusFilters = () => {
    for (const key in initialMBCStatusFilterState) {
      setMyClientsFilter(key as keyof MBCStatusFilterState, false);
    }

    setIsDropdownOpen(false);
  };

  return {
    isDropdownOpen,
    setIsDropdownOpen,
    setMyClientsFilter,
    clearMBCStatusFilters,
    ...mbcStatusFilterState,
  };
};
