import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Modal, Select as DeprecatedAntdSelect, Divider } from "antd";
import { AppState } from "../../../state/models";
import { matchActions } from "../../../state/models/matches";
import { SelectValue } from "antd/es/select";
import RerunMatchQueue from "./RerunMatchQueue";
import ImportClinicianQuestionnaire from "./ImportClinicianQuestionnaire";
// import SendPendingMatchReminderEmail from "./SendPendingMatchReminderEmail";

const modalWidth = Math.min(window.innerWidth - 80, 800);
const styles: { [key: string]: React.CSSProperties } = {
  modalContainer: { top: "25px", margin: "0 auto" },
  modalBody: {
    maxHeight: "calc(100vh - 160px)",
    backgroundColor: "white",
    padding: "15px",
    display: "flex",
    flexDirection: "column",
  },
};

export default () => {
  const [matchingAdminToolIsOpen] = useSelector((state: AppState) => [
    state.matches.matchAdminToolIsOpen,
  ]);

  const dispatch = useDispatch();
  const closeTool = () => dispatch(matchActions.setMatchAdminToolIsOpen(false));

  const [action, setAction] = useState("");

  const availableActions = [
    "Rerun Match Queue",
    "Import Clinician Questionnaire",
    // "Send Pending Match Reminder Email",
  ];

  return (
    <Modal
      title="Matching Admin Tool"
      open={matchingAdminToolIsOpen}
      maskClosable={false}
      width={modalWidth}
      style={styles.modalContainer}
      bodyStyle={styles.modalBody}
      footer={null}
      onCancel={closeTool}
    >
      <DeprecatedAntdSelect
        onChange={(value: SelectValue) => setAction(value as string)}
        style={{ width: 400 }}
        placeholder="Select an action to take..."
      >
        <DeprecatedAntdSelect.Option value={availableActions[0]}>
          {availableActions[0]}
        </DeprecatedAntdSelect.Option>
        <DeprecatedAntdSelect.Option value={availableActions[1]}>
          {availableActions[1]}
        </DeprecatedAntdSelect.Option>
        {/* <Select.Option value={availableActions[2]}>
          {availableActions[2]}
        </Select.Option> */}
      </DeprecatedAntdSelect>
      <Divider />
      {action === availableActions[0] && <RerunMatchQueue />}
      {action === availableActions[1] && <ImportClinicianQuestionnaire />}
      {/* {action === availableActions[2] && <SendPendingMatchReminderEmail />} */}
    </Modal>
  );
};
