import { IconProps } from "../icon.interface";

export const FlagIcon = ({
  className,
  fill = "hsla(0, 0%, 0%, 0)",
  height = 18,
  width = 22,
  title = "",
  stroke = "hsla(0, 0%, 0%, 0)",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 22"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden={!title}
    >
      <title>{title}</title>
      <path
        d="M1 14C1 14 2 13 5 13C8 13 10 15 13 15C16 15 17 14 17 14V2C17 2 16 3 13 3C10 3 8 1 5 1C2 1 1 2 1 2V14ZM1 14V21"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={fill}
      />
    </svg>
  );
};
