import { Card, Tag as AntdTag, Tooltip, Typography } from "antd";
import moment from "moment";
import * as React from "react";
import styled from "styled-components";

import { ManualCapacity, ResolvedMatch } from "../../../api/types";
import { $greyBorder, $greyText } from "../../../assets/colors";
import { useShallowEqualSelector } from "../../_helpers/redux";

const DATETIME_FORMAT = "dddd, MMMM Do YYYY, h:mm:ss a";
const DATE_FORMAT = "dddd, MMMM Do";

// these are functions because i have no clue when/how imports are evaluated
const TODAY = moment();
export const GET_LAST_THURSDAY = () =>
  moment()
    .day(TODAY.day() > 4 ? 4 : -3)
    .hour(0)
    .minute(0);
export const GET_LAST_MONDAY = () =>
  moment()
    .day(TODAY.day() > 1 ? 1 : -6)
    .hour(0)
    .minute(0);
export const GET_LATEST_MATCHING_SESSION = () =>
  moment.max(GET_LAST_THURSDAY(), GET_LAST_MONDAY());
export const GET_PREVIOUS_MATCHING_SESSION = () =>
  moment.min(GET_LAST_THURSDAY(), GET_LAST_MONDAY());

export default function History(props: { clinicianId: number }) {
  const { clinicianId } = props;
  const { recentCapacityMap, clinicianMatchesMap, recentMatchesMap } =
    useShallowEqualSelector((state) => ({
      recentCapacityMap: state.clinicians.recentCapacityMap,
      clinicianMatchesMap: state.clinicians.clinicianToRecentMatchesMap,
      recentMatchesMap: state.clinicians.recentMatchesMap,
    }));

  let cliniciansRecentCapacity: ManualCapacity | undefined =
    recentCapacityMap[clinicianId] && recentCapacityMap[clinicianId][0];

  // if the recent-most manual capacity is older than the last matching session, do not use it
  if (
    cliniciansRecentCapacity &&
    moment(cliniciansRecentCapacity._date_start).isBefore(
      GET_PREVIOUS_MATCHING_SESSION(),
    )
  ) {
    cliniciansRecentCapacity = undefined;
  }

  const recentMatches = clinicianMatchesMap[clinicianId]
    ?.map((match) => recentMatchesMap[match])
    .sort((a, b) => sortRecentMatchesByClientStatus(a, b));

  return (
    <HistoryContainer>
      <ManualCapacityData
        recentMatches={recentMatches}
        capacity={cliniciansRecentCapacity}
      />
    </HistoryContainer>
  );
}

function sortRecentMatchesByClientStatus(a: ResolvedMatch, b: ResolvedMatch) {
  // sort pending matches above all others
  if (a.client_status === "Active" || a.client_status === "Scheduled") {
    return 1;
  }

  return -1;
}

const ManualCapacityData = (props: {
  recentMatches: ResolvedMatch[];
  capacity: ManualCapacity | undefined;
}) => {
  const { capacity, recentMatches } = props;

  let manualContent: JSX.Element;
  if (!capacity) {
    manualContent = (
      <span>No manual capacity data for this matching session. </span>
    );
  } else {
    const { capacity: capacityNum, note } = capacity;
    const dateSavedString = moment(capacity._date_start).format(DATE_FORMAT);
    manualContent = (
      <Card
        type="inner"
        style={{ marginBottom: "8px" }}
        bodyStyle={{ padding: "8px 16px" }}
      >
        <DataLine>
          <span>Matches requested:</span>
          <Typography.Text type="secondary" style={{ paddingLeft: "8px" }}>
            <Tooltip title={`Requested on ${dateSavedString}`}>
              {capacityNum}
            </Tooltip>
          </Typography.Text>
        </DataLine>
        {note && <NoteContainer>{note}</NoteContainer>}
      </Card>
    );
  }

  let matchContent: JSX.Element;
  if (!recentMatches) {
    matchContent = <span>No matches made for this clinician.</span>;
  } else {
    const pendingMatches = recentMatches.filter((match) =>
      isPendingMatch(match),
    );
    // const convertedMatches = recentMatches.filter(
    //   match => !isPendingMatch(match),
    // );

    matchContent = (
      <Card type="inner" bodyStyle={{ padding: "8px 16px" }}>
        <DataLine>
          <span>Matches made in last week:</span>
          <Typography.Text type="secondary" style={{ paddingLeft: "8px" }}>
            {recentMatches.length}
          </Typography.Text>
        </DataLine>
        <DataLine>
          <span>Matches pending conversion:</span>
          <Typography.Text type="secondary" style={{ paddingLeft: "8px" }}>
            {pendingMatches.length}
          </Typography.Text>
        </DataLine>
        {pendingMatches.length > 0 && (
          <MatchesContainer>
            {pendingMatches.map((m, index) => (
              <MatchRow match={m} index={index} key={index} />
            ))}
            {/* {convertedMatches.length > 0 && (
              <Collapse
                bordered={false}
                style={{
                  margin: "0 -16px",
                  borderTop: `1px solid ${$greyBorder}`,
                }}
              >
                <Collapse.Panel
                  header={`Converted Matches (${convertedMatches.length})`}
                  key={1}
                >
                  {convertedMatches.map((m, index) => (
                    <MatchRow match={m} index={index} />
                  ))}
                </Collapse.Panel>
              </Collapse>
            )} */}
          </MatchesContainer>
        )}
      </Card>
    );
  }

  return (
    <>
      {manualContent}
      {matchContent}
    </>
  );
};

const MatchesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
`;

function isPendingMatch(match: ResolvedMatch) {
  return !["Active", "Scheduled", "Did Not Convert"].includes(
    match.client_status,
  );
}

const MatchRow = (props: { match: ResolvedMatch; index: number }) => {
  const { match, index } = props;
  const { id, conversion_event } = match;

  let matchStatusTag: JSX.Element;
  let timeRemainingStr: string = "";

  const matchIsPending = isPendingMatch(match);
  if (!matchIsPending) {
    matchStatusTag = (
      <AntdTag color="green">
        Converted:{" "}
        {moment(conversion_event!.start_time).format(DATETIME_FORMAT)}
      </AntdTag>
    );
  } else {
    if (match.match_email_sent) {
      const timeRemaining = Math.abs(
        moment().diff(moment(match.match_email_sent).add(7, "days"), "hours"),
      );
      if (timeRemaining > 24) {
        timeRemainingStr = `${Math.round(
          timeRemaining / 24,
        )} days left to convert.`;
      } else {
        timeRemainingStr = `1 day left to convert.`;
      }
    }
    matchStatusTag = (
      <Tooltip
        title={`Email sent on ${moment(match.match_email_sent).format(
          DATE_FORMAT,
        )}`}
      >
        <AntdTag color="grey">Pending: {timeRemainingStr}</AntdTag>
      </Tooltip>
    );
  }

  return (
    <MatchContainer key={id}>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <Typography.Text strong>Match {index + 1}</Typography.Text>
        <div style={{ padding: "0px 8px" }}>{matchStatusTag}</div>
      </div>
    </MatchContainer>
  );
};

const MatchContainer = styled.div`
  padding: 6px 24px;
  margin: 0 -16px;

  border-top: 1px solid ${$greyBorder};

  &:last-of-type {
    padding-bottom: 0px;
  }
`;

const HistoryContainer = styled.div``;
const DataLine = styled.div`
  margin: 0;

  > span {
    &:first-of-type {
      min-width: 50%;
      display: inline-block;
    }
  }
`;

const NoteContainer = styled.div`
  margin: 4px 0;
  padding: 8px 12px;
  background-color: rgba(0, 0, 0, 0.08);
  color: ${$greyText};
  border-radius: 4px;
`;
