import {
  BasicMatch,
  ShoppingCartItem,
  MatchSuggestion,
} from "../../../../api/types";
import {
  BulbOutlined,
  CheckCircleOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { Menu, Dropdown, Badge, Tooltip } from "antd";
import React from "react";
import moment from "moment";
import { getMatchTimestamp } from "../../../_helpers";
import { useShallowEqualSelector } from "../../../_helpers/redux";
import { matchActions } from "../../../../state/models/matches";

import { MenuClickEventHandler } from "rc-menu/lib/interface";

import { useDispatch } from "react-redux";

interface BadgeProps {
  match: BasicMatch;
  matchInCart: ShoppingCartItem;
  suggestedMatch: MatchSuggestion;
}
function MatchBadge(props: BadgeProps) {
  const dispatch = useDispatch();
  const { match, matchInCart, suggestedMatch } = props;

  const [aqmExclusions, matchFits] = useShallowEqualSelector((state) => [
    state.matches.aqmExclusions,
    state.matches.matchFitsMap[match.id] || [],
  ]);

  const matchIsExcluded = aqmExclusions.match_ids.includes(match.id);
  const excludedFit = matchFits.find((fit) =>
    aqmExclusions.fit_ids.includes(fit.id),
  );
  const fitIsExcluded = excludedFit !== undefined;

  const showResetMenu = matchIsExcluded || fitIsExcluded;

  let dropdownMenu: JSX.Element | null = null;

  if (showResetMenu) {
    const resetExclusion: MenuClickEventHandler = (e) => {
      e.domEvent.stopPropagation();
      if (matchIsExcluded) {
        aqmExclusions.match_ids = aqmExclusions.match_ids.filter(
          (id) => id !== match.id,
        );
      }
      if (fitIsExcluded) {
        aqmExclusions.fit_ids = aqmExclusions.fit_ids.filter(
          (id) => id !== excludedFit?.id,
        );
      }
      dispatch(matchActions.setAqmExclusions({ ...aqmExclusions }));
    };
    dropdownMenu = (
      <Menu>
        <Menu.Item key="1" onClick={resetExclusion}>
          Reset Exclusion
        </Menu.Item>
      </Menu>
    );
  } else if (suggestedMatch) {
    const excludeFit: MenuClickEventHandler = (e) => {
      e.domEvent.stopPropagation();
      aqmExclusions.fit_ids.push(suggestedMatch.fit_id);
      dispatch(matchActions.setAqmExclusions({ ...aqmExclusions }));
    };

    const excludeMatch: MenuClickEventHandler = (e) => {
      e.domEvent.stopPropagation();
      aqmExclusions.match_ids.push(suggestedMatch.match_id);
      dispatch(matchActions.setAqmExclusions({ ...aqmExclusions }));
    };

    dropdownMenu = (
      <Menu>
        <Menu.Item key="1" onClick={excludeFit}>
          Exclude Suggested Clinician
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="2" onClick={excludeMatch}>
          Exclude Match
        </Menu.Item>
      </Menu>
    );
  }

  let icon: JSX.Element | number;
  if (matchInCart) {
    icon = <CheckCircleOutlined style={{ fontSize: "16px" }} />;
  } else if (match.exclude_from_aqm) {
    icon = <CloseOutlined style={{ fontSize: "16px", color: "red" }} />;
  } else if (showResetMenu) {
    icon = <CloseOutlined style={{ fontSize: "16px" }} />;
  } else if (suggestedMatch) {
    icon = <BulbOutlined style={{ fontSize: "16px" }} />;
  } else {
    icon = 0;
  }
  moment.relativeTimeThreshold("d", 60 * 24); // show 'days' for 60 days
  if (dropdownMenu === null) {
    return (
      <Badge offset={[10, 8]} count={icon}>
        {moment(getMatchTimestamp(match)).fromNow()}
      </Badge>
    );
  }

  return (
    <Dropdown overlay={dropdownMenu} trigger={["hover"]} placement="bottomLeft">
      <Badge offset={[10, 8]} count={icon}>
        {moment(getMatchTimestamp(match)).fromNow()}
      </Badge>
    </Dropdown>
  );
}

export default MatchBadge;
