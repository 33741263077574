import { DateTime } from "luxon";
import { EventApiData } from "../../../../../../../../../api/use-my-clients-overview";

interface UseSessionHistoryParams {
  sessionHistorySessions: EventApiData[];
  sessionsFilteredByTimeRange: EventApiData[];
}
export const useSessionHistorySection = ({
  sessionHistorySessions,
  sessionsFilteredByTimeRange,
}: UseSessionHistoryParams) => {
  const sessionsAttendedInLast2Days = sessionHistorySessions.filter(
    (session) => {
      const now = DateTime.now();
      const twoDaysAgo = now.minus({ days: 2 });
      const sessionEndDateTime = DateTime.fromISO(session.startTime).plus({
        minutes: 50,
      });

      const didEventEndWithinTheLast2Days =
        sessionEndDateTime < now && sessionEndDateTime > twoDaysAgo;

      const isAttendedIsh =
        session.appointmentStatus === "attended" ||
        session.appointmentStatus === null;

      return isAttendedIsh && didEventEndWithinTheLast2Days;
    },
  );

  const totalCanceledSessions = sessionsFilteredByTimeRange.filter(
    (session) => session.appointmentStatus === "canceled",
  ).length;

  const totalAttendedSessions = sessionsFilteredByTimeRange.filter(
    (session) => session.appointmentStatus === "attended",
  ).length;

  const totalNoShowSessions = sessionsFilteredByTimeRange.filter(
    (session) => session.appointmentStatus === "noshow",
  ).length;

  const totalLateCancelByClientSessions = sessionHistorySessions.filter(
    (session) => session.cancellationType === "late_by_client",
  ).length;

  return {
    sessionsAttendedInLast2Days,
    totalCanceledSessions,
    totalAttendedSessions,
    totalNoShowSessions,
    totalLateCancelByClientSessions,
  };
};
