import moment from "moment";
import { LineSeriesPoint, RectSeriesPoint } from "react-vis";

import { DateRange } from "../../panel-management/types";
import { BillableHoursWeeksMap } from "../types";
import { ISOWeekMap } from "../../../api/types";
import { pick } from "lodash-es";

export const ISO_WEEKDATE_FORMAT = "GGGG-[W]WW";

export type BillableHoursDataLabels =
  | "utilization"
  | "therapy_attended"
  | "therapy_scheduled"
  | "no_show_therapy"
  | "consult_attended"
  | "consult_scheduled"
  | "no_show_consult"
  | "couples_attended"
  | "couples_scheduled"
  | "no_show_couples"
  | "remaining_consult_allocation";

/**
 * Refers to a datapoint in d3, expanded to include some custom fields for billable hours utilization.
 *
 * It is important to note that moment(rawDate).format(ISO_WEEKDATE_FORMAT) does NOT necessarily give the correct isoweek!
 * rawDate may be the .startOf('week'), which returns the Sunday (in ISO8601 terms, the end of the previous week).
 * If you need to match data to an isoWeek label, pass in the explicit isoWeek label from the API level and consume that.
 */
export interface ExpandedRectSeriesPoint extends RectSeriesPoint {
  label?: BillableHoursDataLabels;
  rawDate?: Date;
  rawValue?: number;
  discreteValue?: number;
  isoWeek?: string;
}

// for a startDate of any Sunday, we want the bar graph to be ±2 days for symmetry
function calculateX1(date: Date) {
  return moment(date).add(2, "days").toDate();
}
function calculateX0(date: Date) {
  return moment(date).subtract(2, "days").toDate();
}

/**
 * Transforms billablehoursweekmaps into the format consumed by the billable hours series chart
 * @param billableHours entire
 * @param dateRange
 */
export function transformBillableHoursSeries(
  billableHours: BillableHoursWeeksMap,
  dateRange: DateRange,
) {
  const utilization: ExpandedRectSeriesPoint[] = [];
  const numTherapyAttended: ExpandedRectSeriesPoint[] = [];
  const numTherapyScheduled: ExpandedRectSeriesPoint[] = [];
  const numTherapyNoShow: ExpandedRectSeriesPoint[] = [];
  const numConsultAttended: ExpandedRectSeriesPoint[] = [];
  const numConsultScheduled: ExpandedRectSeriesPoint[] = [];
  const numConsultNoShow: ExpandedRectSeriesPoint[] = [];
  const numConsultAllocationRemaining: ExpandedRectSeriesPoint[] = [];
  const numCouplesTherapyAttended: ExpandedRectSeriesPoint[] = [];
  const numCouplesTherapyNoShow: ExpandedRectSeriesPoint[] = [];
  const numCouplesTherapyScheduled: ExpandedRectSeriesPoint[] = [];
  const numTrainingHours: ExpandedRectSeriesPoint[] = [];
  const numTransitionHours: ExpandedRectSeriesPoint[] = [];

  const goalLine: LineSeriesPoint[] = [];
  const goalBox: ExpandedRectSeriesPoint[] = [];
  const ptoHours: { [isoWeek: string]: number } = {};

  const { start, end } = dateRange;

  const billableHoursCopy = { ...billableHours };

  // sort to ensure dates are in order
  Object.entries(billableHoursCopy)
    .sort((a, b) => new Date(a[0]).getTime() - new Date(b[0]).getTime())
    .forEach(([weekString, data], i) => {
      if (!data) {
        return;
      }

      const thisMoment = moment(weekString).startOf("week");
      const date = thisMoment.toDate();

      if (thisMoment.isBefore(start) || thisMoment.isAfter(end)) {
        return;
      }

      const isoWeek = `${data.isoyear}-W${data.isoweek}`;

      utilization.push({
        label: "utilization",
        x: moment(date).toDate(),
        y: data.billable_time_utilization,
        rawDate: date,
        rawValue: data.billable_time_utilization,
        isoWeek,
      });

      numTherapyAttended.push({
        label: "therapy_attended",
        x: calculateX1(date),
        x0: calculateX0(date),
        y: data.attended_therapy_billable_time,
        rawDate: date,
        rawValue: data.attended_therapy_billable_time,
        discreteValue: data.therapy_attended,
        isoWeek,
      });

      numTherapyScheduled.push({
        label: "therapy_scheduled",
        x: calculateX1(date),
        x0: calculateX0(date),
        y: data.scheduled_therapy_billable_time,
        rawDate: date,
        rawValue: data.scheduled_therapy_billable_time,
        discreteValue: data.therapy_scheduled,
        isoWeek,
      });

      numTherapyNoShow.push({
        label: "no_show_therapy",
        x: calculateX1(date),
        x0: calculateX0(date),
        y: data.no_show_therapy_billable_time,
        rawDate: date,
        rawValue: data.no_show_therapy_billable_time,
        discreteValue: data.therapy_noshows,
        isoWeek,
      });

      numConsultAttended.push({
        label: "consult_attended",
        x: calculateX1(date),
        x0: calculateX0(date),
        y: data.attended_consult_billable_time,
        rawDate: date,
        rawValue: data.attended_consult_billable_time,
        discreteValue: data.consults_attended,
        isoWeek,
      });

      numConsultScheduled.push({
        label: "consult_scheduled",
        x: calculateX1(date),
        x0: calculateX0(date),
        y: data.scheduled_consult_billable_time,
        rawDate: date,
        rawValue: data.scheduled_consult_billable_time,
        discreteValue: data.consults_scheduled,
        isoWeek,
      });
      numConsultNoShow.push({
        label: "no_show_consult",
        x: calculateX1(date),
        x0: calculateX0(date),
        y: data.no_show_consult_billable_time,
        rawDate: date,
        rawValue: data.no_show_consult_billable_time,
        discreteValue: data.consult_noshows,
        isoWeek,
      });

      numCouplesTherapyAttended.push({
        label: "couples_attended",
        x: calculateX1(date),
        x0: calculateX0(date),
        y: data.attended_couples_billable_time,
        rawDate: date,
        rawValue: data.attended_couples_billable_time,
        discreteValue: data.couples_attended,
        isoWeek,
      });

      numCouplesTherapyScheduled.push({
        label: "couples_scheduled",
        x: calculateX1(date),
        x0: calculateX0(date),
        y: data.scheduled_couples_billable_time,
        rawDate: date,
        rawValue: data.scheduled_couples_billable_time,
        discreteValue: data.couples_scheduled,
        isoWeek,
      });

      numCouplesTherapyNoShow.push({
        label: "no_show_couples",
        x: calculateX1(date),
        x0: calculateX0(date),
        y: data.no_show_couples_billable_time,
        rawDate: date,
        rawValue: data.no_show_couples_billable_time,
        discreteValue: data.couples_noshow,
        isoWeek,
      });

      const consult_allocation =
        !data.consult_allocation || Number.isNaN(data.consult_allocation)
          ? 0
          : data.consult_allocation;

      const remaining_allocation_score =
        data.allocated_consult_billable_time - data.consult_billable_time;
      const remaining_allocation_discrete = Math.max(
        consult_allocation -
          data.consults_attended -
          data.consult_noshows -
          data.consults_scheduled,
        0,
      );

      numConsultAllocationRemaining.push({
        label: "remaining_consult_allocation",
        x: calculateX1(date),
        x0: calculateX0(date),
        y: Math.max(0, remaining_allocation_score),
        rawDate: date,
        rawValue: remaining_allocation_score,
        discreteValue: remaining_allocation_discrete,
        isoWeek,
      });

      goalLine.push({
        x: moment(date).subtract(4, "days").add(1, "hour").toDate(),
        y: 0.625,
        rawValue: 0.625,
        isoWeek,
      });

      ptoHours[isoWeek] = data.pto_hours;
    });

  // add goalLine for final x-tick and goalBox
  if (goalLine.length > 0) {
    goalLine.push({ x: dateRange.end.toDate(), y: 0.625, rawValue: 0.625 });
    goalBox.push({
      x0: moment(dateRange.start).subtract(4, "days").toDate(),
      x: dateRange.end.toDate(),
      y: 0.625,
      y0: 0.625,
    });
  }

  return {
    utilization,
    numTherapyNoShow,
    numConsultNoShow,
    numCouplesTherapyNoShow,
    numTherapyAttended,
    numTherapyScheduled,
    numConsultAttended,
    numConsultScheduled,
    numConsultAllocationRemaining,
    numCouplesTherapyAttended,
    numCouplesTherapyScheduled,
    numTrainingHours,
    numTransitionHours,
    goalLine,
    goalBox,
    ptoHours,
  };
}

/**
 * Given an ISOWeekMap and a DateRange, this returns a Partial of the data containing
 * only the entries where week is included in the dateRange, inclusive of both start and end.
 */
export function extractDateRangeFromDataset(
  data: ISOWeekMap<any>,
  dateRange: DateRange,
) {
  const { start, end } = dateRange;
  const labelsInRange = [];

  let week = moment(start).startOf("isoWeek");
  const endWeek = moment(end).add(1, "week"); // to include in while loop

  while (week.isBefore(endWeek, "week")) {
    const weekLabel = week.format(ISO_WEEKDATE_FORMAT);
    labelsInRange.push(weekLabel);

    week.add(1, "week");
  }

  console.debug(
    `Picking labels in range for dates ${start.format()}, ${end.format()}:`,
    labelsInRange,
  ); // eslint-disable-line no-console

  return pick(data, labelsInRange);
}
