import { Graph } from "@/app/_components/graph/graph"
import { store } from "@/index";
import { ParsedClientEventMbcScoresAndSeverities } from "../_utils/mbc.util";
import { getGraphDataPointFromParsedMBCScore } from "../_utils/mbc-graph.util";
import { MBCAssessmentCustomGraphDot } from "./mbc-graph/mbc-asssessment-custom-graph-dot";

const yAxisTickIntervalsCohesion = [0, 1, 3, 5, 7];

interface MBCAssessmentCohesionGraphProps {
  eventScores: ParsedClientEventMbcScoresAndSeverities[] | null;
}

export const MBCAssessmentCohesionGraph = ({ eventScores }: MBCAssessmentCohesionGraphProps) => {
  const cuser = store.getState()?.auth?.currentUser;

  const formattedEventScores = eventScores?.map(score => getGraphDataPointFromParsedMBCScore(score, 'cohesion', cuser));
  if (formattedEventScores?.length) {
    return (
      <Graph
        graphDataPoints={formattedEventScores}
        yAxisTickIntervals={yAxisTickIntervalsCohesion}
        customGraphDot={MBCAssessmentCustomGraphDot}
      />
    );
  }
  return null;
}
