import { TooltipProvider } from "@radix-ui/react-tooltip";
import React from "react";

import {
  TooltipArrow,
  TooltipContent,
  TooltipPortal,
  TooltipRoot,
  TooltipTrigger,
} from "./tooltip.styled";

interface TooltipProps {
  children: React.ReactNode;
  content: React.ReactNode;
  open?: boolean;
  defaultOpen?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
  offset?: number;
  sideOffset?: number;
  width?: number | string;
  delayDuration?: number;
  shouldRenderContent?: boolean;
  shouldRenderArrow?: boolean;
  side?: "top" | "bottom" | "right" | "left";
  align?: "center" | "start" | "end";
  fontSize?: number;
}

/**
 * @name RadixTooltip
 * @notice If this is being used within a table, make sure to add TooltipContentInTableScreenReaderOnlyText so that it matches the content.
 */
export const RadixTooltip = ({
  children,
  content,
  open,
  defaultOpen,
  onOpenChange,
  offset = 5,
  sideOffset = 6,
  width = "fit-content",
  delayDuration = 300,
  shouldRenderContent = true,
  shouldRenderArrow = true,
  side = "top",
  align = "center",
  fontSize = 16,
  ...props
}: TooltipProps) => {
  return (
    <TooltipProvider delayDuration={delayDuration}>
      <TooltipRoot
        open={open}
        defaultOpen={defaultOpen}
        onOpenChange={onOpenChange}
      >
        <TooltipTrigger asChild>{children}</TooltipTrigger>

        <TooltipPortal>
          {shouldRenderContent ? (
            <TooltipContent
              side={side}
              align={align}
              sideOffset={sideOffset}
              css={{ width, fontSize }}
              {...props}
            >
              {content}
              {shouldRenderArrow ? (
                <TooltipArrow
                  offset={offset}
                  width={11}
                  height={5}
                  aria-hidden
                />
              ) : null}
            </TooltipContent>
          ) : null}
        </TooltipPortal>
      </TooltipRoot>
    </TooltipProvider>
  );
};
