interface ReactVisWrapperProps {
  children: React.ReactNode;
}
/**
 * @name ReactVisWrapper
 * @param children
 * @notice We should no longer be using react-vis, so if you are using this to wrap a brand new react-vis component, please consider making using something other than react-vis altogether
 * @description Used to hide react-vis elements as it is a horrendous blight to VO and assistive that it's better to wipe it from the accessibility tree
      Ideally it would have been nice to summarize the datapoints but there's no way to do that.
      On top of this, also react-vis has been deprecated as of 2019.
      You could compare this experience to walking in a room with a lot of objects. However, it is impossible to name the objects. At this point in time it is better to just sweep/clean out the room
 */

export const ReactVisWrapper = ({ children }: ReactVisWrapperProps) => {
  return <div aria-hidden>{children}</div>;
};
