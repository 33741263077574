import React, { useMemo } from "react";
import ClientAppointmentInfo from "./ClientAppointmentInfo";
import RematchDropdown from "./RematchDropdown";
import MatchSlotPreferences from "./MatchSlotPreferences";
import { Divider as AntDivider } from "antd";
import { useShallowEqualSelector } from "../../../_helpers/redux";
import ConflictOfInterestAutocomplete from "./ConflictOfInterestAutocomplete";
import GeneralResponseResponses from "./GeneralResponseResponses";

const Divider = () => (
  <div style={{ padding: "0 15px" }}>
    <AntDivider style={{ margin: "0" }} />
  </div>
);

export default () => {
  const [
    match,
    rematch,
    validEmailMap,
    clientEmail,
  ] = useShallowEqualSelector(state => [
    state.matchmaker.initData.match,
    state.matchmaker.initData.rematch,
    state.matchmaker.validEmailMap,
    state.matchmaker.clientEmail,
  ]);

  const showSlotPreferenceInputs = useMemo(
    () => validEmailMap[clientEmail]?.clientId || match !== null,
    [validEmailMap, clientEmail, match],
  );

  return (
    <>
      <div style={{ overflowY: "auto" }}>
        {(match || rematch) && <ClientAppointmentInfo />}
        {showSlotPreferenceInputs && (
          <>
            <GeneralResponseResponses
              formResponse={match?.couple?.joint_intake_form_response}
            />
            <Divider />
            <ConflictOfInterestAutocomplete />
            <Divider />
            <MatchSlotPreferences />
          </>
        )}
      </div>
      <RematchDropdown />
    </>
  );
};
