import React from "react";
import { isEmpty } from "lodash-es";
import { useShallowEqualSelector } from "../../_helpers/redux";
import { ExtendedMatch, ClinicPreference } from "../../../api/types";
import DisplayMatchSlotPreferences from "./DisplayMatchSlotPreferences";
import ClinicPreferenceList from "../../_shared/ClinicPreferenceList";

export default ({ match }: { match: ExtendedMatch }) => {
  const msps = useShallowEqualSelector(
    (state) => state.matches.matchSlotPreferenceMap[match.id],
  );

  if (!isEmpty(msps)) {
    return <DisplayMatchSlotPreferences match={match} />;
  } else {
    return (
      <ClinicPreferenceList
        highlightedClinicIds={
          match.selected_fit
            ? match.selected_fit.clinician.clinic_ids
            : undefined
        }
        clinicPreferences={match.clinic_preferences.filter(
          (cp: ClinicPreference) => cp.preference_level === "match",
        )}
      />
    );
  }
};
