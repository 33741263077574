import { styledStitches } from "@/app/design-system/styles/stitches.config";
export const DEFAULT_STACK_STYLE = {
  display: "flex",
  flexDirection: "column",
};

export const Stack = styledStitches("div", {
  ...DEFAULT_STACK_STYLE,
  variants: {
    alignItems: {
      "flex-start": {
        alignItems: "flex-start",
      },
      center: {
        alignItems: "center",
      },
      "flex-end": {
        alignItems: "flex-end",
      },
      stretch: {
        alignItems: "stretch",
      },
      baseline: {
        alignItems: "baseline",
      },
    },

    justifyContent: {
      start: {
        justifyContent: "flex-start",
      },
      center: {
        justifyContent: "center",
      },
      end: {
        justifyContent: "flex-end",
      },
      "space-between": {
        justifyContent: "space-between",
      },
    },

    flexWrap: {
      noWrap: {
        flexWrap: "nowrap",
      },
      wrap: {
        flexWrap: "wrap",
      },
      wrapReverse: {
        flexWrap: "wrap-reverse",
      },
    },
    gap: {
      2: {
        gap: "$space2",
      },
      4: {
        gap: "$space4",
      },
      8: {
        gap: "$space8",
      },
      10: {
        gap: "$space10",
      },
      12: {
        gap: "$space12",
      },
      14: {
        gap: "$space14",
      },
      16: {
        gap: "$space16",
      },
      18: {
        gap: "$space18",
      },
      20: {
        gap: "$space20",
      },
      24: {
        gap: "24px",
      },
      32: {
        gap: "$space32",
      },
      48: {
        gap: "$space48",
      },
    },
  },
});
