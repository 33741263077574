import { Flex } from "@/app/design-system";
import {
  CartesianGrid,
  Legend,
  Line,
  ComposedChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Area,
} from "recharts";
import { Chart } from "../../types";
import { MBCGraphAxisTick } from "./mbc-graph-axis-tick";
import { MBCGraphDot } from "./mbc-graph-dot";
import { MBCStatusKey } from "./mbc-graph-status-key";
import { MBCGraphTooltip } from "./mbc-graph-tooltip";
import { MBCGraphLabel } from "./mbc-graph-label";

export const MBCGraph = ({
  chart,
  clientId,
  coupleId,
}: {
  chart: Chart;
  clientId?: number;
  coupleId?: number;
}) => {
  const isGad = chart.track === "gad";
  const isPhq = chart.track === "phq";
  const isAlliance = chart.track === "alliance";
  const isSwls = chart.track === "swls";
  const isMhqol = chart.track === "mhqol";

  return (
    <Flex css={{ padding: 16, pr: 0, width: "100%" }}>
      {/* 100% width would cause it to be fixed width for some reason */}
      <ResponsiveContainer debounce={10} height={500} width="99%">
        <ComposedChart
          margin={{
            top: 20,
            bottom: 20,
            // Need extra space here for swls
            // because the y-axis labels are longer
            // than other tracks
            left: chart.track === "swls" || chart.track === "mhqol" ? 40 : 20,
            right: 20,
          }}
          data={chart.data}
        >
          <CartesianGrid stroke="#F0F0F0" />
          <XAxis
            dataKey="label"
            tickLine={false}
            padding={{ left: 20, right: 20 }}
            axisLine={{
              stroke: "#F0F0F0",
            }}
          />
          <YAxis
            dataKey="value"
            interval={0}
            minTickGap={isGad || isPhq ? 40 : undefined}
            axisLine={{
              stroke: "#F0F0F0",
            }}
            domain={chart.domain}
            ticks={chart.ticks}
            tickMargin={5}
            tick={(props) => (
              <MBCGraphAxisTick {...props} track={chart.track} />
            )}
            tickFormatter={(value) => {
              if (isSwls) {
                if (value === 2) return "Extremely Dissatisfied/(0 - 2)";
                if (value === 3) return "Dissatisfied/(2 - 3)";
                if (value === 4) return "Below Average Satisfaction/(3 - 4)";
                if (value === 5) return "Average Satisfaction/(4 - 5)";
                if (value === 6) return "High Satisfaction/(5 - 6)";
                if (value === 7) return "Very High Satisfaction/(6 - 7)";
                return null;
              }
              if (isMhqol) {
                if (value === 5) return "Low quality of life/(0-9)";
                if (value === 10) return "Moderate quality of life/(10-15)";
                if (value === 15) return "High quality of life/(16-21)";
                return null;
              }
              if (isAlliance) {
                if (value === 3) return "Low/(0 - 3)";
                if (value === 4) return "Moderate/(3 - 4)";
                if (value === 5) return "High/(4 - 5)";
                return null;
              }
              if (isGad) {
                if (value === 0) return "Minimal/(1 - 4)";
                if (value === 5) return "Mild/(5 - 9)";
                if (value === 10) return "Moderate/(10 - 14)";
                if (value === 15) return "Severe/(15 - 21)";
                return null;
              }
              if (isPhq) {
                if (value === 0) return "Minimal/(1 - 4)";
                if (value === 5) return "Mild/(5 - 9)";
                if (value === 10) return "Moderate/(10 - 14)";
                if (value === 15) return "Moderately Severe/(15 - 19)";
                if (value === 20) return "Severe/(20 - 27)";
                return value;
              }
              return value;
            }}
          />
          <Tooltip
            // @ts-ignore
            content={clientId ? <MBCGraphTooltip /> : undefined}
          />
          {(isGad || isPhq) && (
            <>
              {chart.firstScoreReferenceLine ? (
                <ReferenceLine
                  label={
                    <MBCGraphLabel
                      displayText={chart.firstScoreReferenceLine.text}
                      fill="#8C8C8C"
                    />
                  }
                  y={chart.firstScoreReferenceLine.score}
                  stroke="#8C8C8C"
                  strokeWidth={2}
                  strokeDasharray="9 2"
                />
              ) : null}
              <ReferenceLine
                    label={
                      <MBCGraphLabel
                        fill="#edf6ff"
                      />
                    }
                    y={5}
                    stroke="#edf6ff"
                    strokeWidth={1}
                  />

              <Area type="monotone" dataKey="remissionReferenceLine" fill="#edf6ff" stroke="#add8e6"/>

              <Legend
                layout="vertical"
                verticalAlign="top"
                align="right"
                content={() => <MBCStatusKey />}
              />
            </>
          )}
          {clientId && (
            <Line
              isAnimationActive={false}
              connectNulls
              type="monotone"
              dataKey="value"
              stroke="#8C8C8C"
              dot={<MBCGraphDot />}
              // dots were not rendering: https://twochairs.atlassian.net/browse/TC-5071
              // if we want the animation we have to force react to draw a rerender this line
              // otherwise, im fine with just rendering the line instantly
              // key={`data-${dataVersion}-line`}
            />
          )}
          {coupleId && (
            <Line
              connectNulls
              type="monotone"
              stroke="#8884d8"
              dataKey="csi4_a"
              name="Client a"
            />
          )}
          {coupleId && (
            <Line
              connectNulls
              type="monotone"
              stroke="#82ca9d"
              dataKey="csi4_b"
              name="Client b"
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </Flex>
  );
};
