import {
  MyClientsClient,
  selectMyClientsSortSlice,
  useMyClientsSortStore,
} from "../../my-clients-model";
import { getMostRelevantClients } from "./my-clients-sort.util";

export const useSortMyClients = (clients: MyClientsClient[]) => {
  const {
    isSortingByMostRelevant,
    isSortingByFirstNameAscending,
    isSortingByFirstNameDescending,
    isSortingByLastNameAscending,
    isSortingByLastNameDescending,
  } = useMyClientsSortStore(selectMyClientsSortSlice);

  if (isSortingByMostRelevant) {
    return getMostRelevantClients(clients);
  } else if (isSortingByFirstNameAscending) {
    return clients.sort((clientA, clientB) =>
      clientA.firstName.localeCompare(clientB.firstName),
    );
  } else if (isSortingByFirstNameDescending) {
    return clients
      .sort((clientA, clientB) =>
        clientA.firstName.localeCompare(clientB.firstName),
      )
      .reverse();
  } else if (isSortingByLastNameAscending) {
    return clients.sort((clientA, clientB) =>
      clientA.lastName.localeCompare(clientB.lastName),
    );
  } else if (isSortingByLastNameDescending) {
    return clients
      .sort((clientA, clientB) =>
        clientA.lastName.localeCompare(clientB.lastName),
      )
      .reverse();
  }

  return clients;
};
