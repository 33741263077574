import { useHasMyClientsPermissions } from "@/app/my-clients/utils";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const useMyClientsRedirect = () => {
  const hasMyClientsPermissions = useHasMyClientsPermissions();
  const navigate = useNavigate();

  useEffect(() => {
    if (!hasMyClientsPermissions) {
      navigate('/dashboard');
    }
  }, [hasMyClientsPermissions]);

  return;
};
