import { Stack } from "@/app/design-system";

import { useShallowEqualSelector } from "@/app/_helpers/redux";

import { useAppPermissions } from "../../hooks/use-app-permissions";

import { MyDashboardMenuItem } from "./components/1-my-dashboard-menu-item";
import { ResourceLibraryMenuItem } from "./components/2-resource-library-menu-item";
import { MyConsultsMenuItem } from "./components/3-my-consults-menu-item";
import { MyClientsMenuItem } from "./components/4-my-clients-menu-item";
import { MatchQueueMenuItem } from "./components/5-match-queue-menu-item";
import {
  TeamMenuItem,
  TimeOffMenuItem,
} from "./components/6-team-and-time-off-menu-items";
import { AvailabilityToolMenuItem } from "./components/7-availability-tool-menu-item";
import { PanelManagementMenuItem } from "./components/8-panel-management-menu-item";
import { GroupTherapyMenuItem } from "./components/9-group-therapy-menu-item";
import { MyAvailabilityMenuItem } from "./components/10-cogsworth-tool-menu-item";

interface TopMainNavigationMenuItemsProps {
  isCollapsed: boolean;
}

export function TopMainNavigationMenuItems({
  isCollapsed,
}: TopMainNavigationMenuItemsProps) {
  const {
    hasContentLibraryPermissions,
    hasMyConsultsPermissions,
    hasMatchQueuePermissions,
    hasAvailabilityToolPermissions,
    hasMyClientsPermissions,
    hasTeamPermissions,
    hasTimeOffPermissions,
    hasGroupTherapyPermissions,
    hasCogsworthPermissions,
  } = useAppPermissions();

  const [currentUser] = useShallowEqualSelector((state) => [
    state.auth.currentUser,
  ]);

  return (
    <>
      {currentUser ? <MyDashboardMenuItem isCollapsed={isCollapsed} /> : null}

      {hasMyConsultsPermissions ? (
        <MyConsultsMenuItem isCollapsed={isCollapsed} />
      ) : null}

      {hasMyClientsPermissions ? (
        <MyClientsMenuItem isCollapsed={isCollapsed} />
      ) : null}

      {hasGroupTherapyPermissions ? (
        <GroupTherapyMenuItem isCollapsed={isCollapsed} />
      ) : null}

      {hasContentLibraryPermissions ? (
        <ResourceLibraryMenuItem isCollapsed={isCollapsed} />
      ) : null}

      {hasMatchQueuePermissions ? (
        <MatchQueueMenuItem isCollapsed={isCollapsed} />
      ) : null}

      {hasTeamPermissions ? <TeamMenuItem isCollapsed={isCollapsed} /> : null}

      {hasTimeOffPermissions ? (
        <TimeOffMenuItem isCollapsed={isCollapsed} />
      ) : null}

      {hasCogsworthPermissions ? (
        <MyAvailabilityMenuItem isCollapsed={isCollapsed} />
      ) : null}

      {hasAvailabilityToolPermissions &&
      (!hasCogsworthPermissions || currentUser?.isSuperUser) ? (
        <AvailabilityToolMenuItem isCollapsed={isCollapsed} />
      ) : null}

      {currentUser ? (
        <PanelManagementMenuItem isCollapsed={isCollapsed} />
      ) : null}
    </>
  );
}
