import { graphqlClient } from "@/api/graphql";
import {
  GroupTherapyNotesEventQuery,
  useGroupTherapyNotesEventQuery,
} from "@/graphql/generated";
import { FIVE_MINUTES } from "@/app/_helpers/constants";

interface UseGroupTherapyNotesEventParams<T extends object> {
  eventId: string | undefined;
  select?: (data: GroupTherapyNotesEventQuery) => T;
  enabled: boolean;
}

export const useGroupTherapyNotesEvent = <T extends {}>({
  eventId,
  select,
  enabled,
}: UseGroupTherapyNotesEventParams<T>) => {
  return useGroupTherapyNotesEventQuery(
    graphqlClient,
    { eventId },
    {
      staleTime: FIVE_MINUTES,
      select,
      enabled,
    },
  );
};
