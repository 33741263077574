import { Flex, H2 } from "@/app/design-system";
import { ComposedDropdownMenu } from "@/app/design-system/dropdown/dropdown.composed";
import { timeRangeValues } from "../utils";

interface SectionHeaderProps {
  setSelectedDateFilter: (a: any) => void;
  currentDateFilter: any;
}

export function SectionHeader({
  setSelectedDateFilter,
  currentDateFilter,
}: SectionHeaderProps) {
  return (
    <Flex
      css={{ p: 24, backgroundColor: "$neutral0" }}
      justifyContent="space-between"
    >
      <H2>Check-in Results</H2>

      <ComposedDropdownMenu
        values={timeRangeValues}
        triggerStyles={{ position: "relative", right: "-14px" }}
        currentValue={currentDateFilter}
        setValue={setSelectedDateFilter}
      />
    </Flex>
  );
}
