import { IconProps } from "../icon.interface";

export const HeartIcon = ({
  fill = "none",
  width = 25,
  height = 24,
  title,
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill={fill}
      aria-hidden={!title}
    >
      {title ? <title>{title}</title> : null}
      <g clipPath="url(#clip0_3983_2610)">
        <path
          d="M21.3709 4.60987C20.8526 4.09888 20.2372 3.69352 19.5598 3.41696C18.8825 3.14039 18.1565 2.99805 17.4233 2.99805C16.6901 2.99805 15.9641 3.14039 15.2867 3.41696C14.6094 3.69352 13.994 4.09888 13.4757 4.60987L12.3999 5.66987L11.3242 4.60987C10.2773 3.57818 8.85726 2.99858 7.37662 2.99858C5.89597 2.99858 4.47596 3.57818 3.42899 4.60987C2.38201 5.64156 1.79382 7.04084 1.79382 8.49987C1.79382 9.95891 2.38201 11.3582 3.42899 12.3899L12.3999 21.2299L21.3709 12.3899C21.8895 11.8791 22.3008 11.2727 22.5815 10.6052C22.8622 9.93777 23.0066 9.22236 23.0066 8.49987C23.0066 7.77738 22.8622 7.06198 22.5815 6.39452C22.3008 5.72706 21.8895 5.12063 21.3709 4.60987Z"
          stroke="#171717"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3983_2610">
          <rect
            width="24.3556"
            height="24"
            fill="white"
            transform="translate(0.222168)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
