import {
  CalendarOutlined,
  ClockCircleOutlined,
  EditOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { Popover, Row } from "antd";
import { TooltipPlacement } from "antd/es/tooltip";
import { uniqBy } from "lodash";
import { DateTime } from "luxon";
import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { Event } from "../../../api/types";
import { $greyBG, $greyText, $red } from "../../../assets/colors";
import { AppState } from "../../../state/models";
import { eventIsConsult } from "../../_helpers/events";
import { IfPermitted } from "../../_helpers/permissions";
import Mangle from "../../_shared/Mangle";
import { shortenClientName, shortenCoupleName, useCellResizer } from "../Cell";
import {
  CloseTimeslot,
  OpenReservationTimeslot,
  RemoveOverride,
} from "../EditTimeslot";
import EventCellPopoverContent, {
  determineWeekMatchesDisplaySide,
} from "../Popover";
import {
  APIManualSlotReservation,
  Recurrence,
  ReservationType,
  SlotClosedSlot,
  SlotOpenSlot,
  SlotReservationSlot,
} from "../types";
import {
  ActionsContainer,
  CellContainer,
  CellContent,
  CellLabel,
} from "./_common";
import { SpecialTimeSlotCellProps } from "./types";

export type OpenTimeSlotCellProps = SpecialTimeSlotCellProps & {
  slot: SlotOpenSlot;
  manual?: boolean;
};
export type ClosedTimeSlotCellProps = SpecialTimeSlotCellProps & {
  slot: SlotClosedSlot;
};

export type ManualReservationTimeSlotCellProps = SpecialTimeSlotCellProps & {
  slot: SlotReservationSlot;
  slot_type: ReservationType;
};

export const ManualReservationTimeSlotCell = (
  props: ManualReservationTimeSlotCellProps,
) => {
  const {
    day,
    hour,
    typeId,
    rowIndex,
    eventIds,
    displaySide,
    slot,
    slot_type,
  } = props;
  const events = useSelector((state: AppState) =>
    state.slottool.eventBuckets[day][hour]
      .map((e) => e.eventId)
      .map((id) => state.slottool.eventMap[id]),
  );

  const msr = useSelector((state: AppState) =>
    state.slottool.reservedSlots.find((s) => s.id === slot.eventId),
  );

  // exclude gcal blocks and consults
  let underlyingTherapySessions = events.filter(
    (e) => e && e.event_type === "procedure" && !eventIsConsult(e),
  );

  // exclude sessions not on biweek
  if (displaySide !== "both") {
    underlyingTherapySessions = underlyingTherapySessions.filter((e) =>
      determineWeekMatchesDisplaySide(moment(e.start_time), displaySide),
    );
  }

  const eventType = "reservation";
  const thisElement = React.useRef<HTMLDivElement>(null);
  const [cellPosition, windowWidth] = useCellResizer(thisElement);
  let popoverPlacement: TooltipPlacement = "right";
  if (cellPosition) {
    popoverPlacement =
      (cellPosition.right + cellPosition.left) / 2 >= windowWidth / 2
        ? "left"
        : "right";
  }

  const [isHovered, setIsHovered] = useState<boolean>(false);

  let label: React.ReactNode;
  switch (slot_type) {
    case ReservationType.Consult:
      label = "Consult Reservation";
      break;
    case ReservationType.Couples:
      label = "Couples Reservation";
      break;
    case ReservationType.Individual:
      label = (
        <Mangle>
          {slot.label ? `${slot.label}: ` : ""}Individual Reservation
        </Mangle>
      );
  }

  return (
    <Popover
      placement={popoverPlacement}
      content={EventCellPopoverContent({
        eventIds,
        typeId,
        day,
        hour,
        displaySide,
        eventType,
      })}
      open={false}
    >
      <CellContainer
        ref={thisElement}
        displaySide={displaySide}
        eventType={eventType}
        slotReservationType={slot_type}
        rowIndex={rowIndex}
        style={{ gridRow: "1 / -1" }} // forces a Slot to occupy the entire cell
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <CellContent>
          <CellLabel>
            <TeamOutlined /> {label}
          </CellLabel>
          <IfPermitted
            permissions={[
              "IsATSlotEditor",
              "IsConsultTimeslotEditor",
              "IsClinicalLeader",
              "IsSuperUser",
            ]}
            requireAll={false}
          >
            {slot.valid_until_date ? (
              <CellLabel
                style={{ whiteSpace: "break-spaces", fontSize: "10pt" }}
              >
                Ends{" "}
                {DateTime.fromISO(slot.valid_until_date).toFormat("LLL dd")}
              </CellLabel>
            ) : null}
          </IfPermitted>
          {underlyingTherapySessions.length > 0 && (
            <UnderlyingEventsBadge
              reservationClientId={msr?.client?.id}
              events={underlyingTherapySessions}
              hovered={isHovered}
              slotType={slot_type}
            />
          )}
          <MTATStatusBadge msr={msr} hovered={isHovered} />
        </CellContent>
        <ActionsContainer>
          {slot_type !== ReservationType.Individual && (
            <IfPermitted
              permissions={["IsConsultTimeslotEditor"]}
              requireAll={false}
            >
              <RemoveOverride type="reservation" slot={slot} />
            </IfPermitted>
          )}
        </ActionsContainer>
      </CellContainer>
    </Popover>
  );
};

const MTATStatusBadge = (props: {
  msr?: APIManualSlotReservation;
  hovered?: boolean;
}) => {
  const { msr } = props;
  if (msr?.mtat_status !== "pending") {
    return null;
  }

  const popoverContent = (
    <div style={{ maxWidth: "240px" }}>
      <div>Expires: {moment(msr.expires_at).fromNow()}</div>
    </div>
  );

  return (
    <Popover content={popoverContent} trigger={"hover"}>
      <MSRBadgeContainer>Pending</MSRBadgeContainer>
    </Popover>
  );
};

const UnderlyingEventsBadge = (props: {
  reservationClientId?: string | number;
  events: Event[];
  hovered?: boolean;
  slotType: ReservationType;
}) => {
  const { reservationClientId, events, hovered, slotType } = props;
  const uniqueClients = uniqBy(events, "client").map((e) => {
    return { clientId: e.client, coupleId: e.couple };
  });

  const popoverContent = (
    <div style={{ maxWidth: "240px" }}>
      <div>
        {events.length} underlying event{events.length === 1 ? "" : "s"} with{" "}
        {uniqueClients.length} client
        {uniqueClients.length === 1 ? "" : "s"}.
      </div>
      {uniqueClients.map((client, index) => (
        <ClientCard
          clientId={client.clientId as number}
          coupleId={client.coupleId as string}
          events={events.filter(
            (e) => e.client === client.clientId && e.couple === client.coupleId,
          )}
          key={index}
        />
      ))}
    </div>
  );

  let badgeLabel: string = `${events.length} ${
    hovered ? "underlying events" : ""
  }`;
  if (slotType === ReservationType.Couples) {
    badgeLabel = "Filled";
  }

  // if any of the events do not match the typeId, then show a 'warning'
  let warning = false;
  if (slotType === ReservationType.Individual) {
    if (
      uniqueClients.some(
        (ct) =>
          ct.clientId !== reservationClientId &&
          ct.coupleId !== reservationClientId,
      )
    ) {
      warning = true;
    }
  }

  return (
    <Popover content={popoverContent} trigger={"hover"}>
      <MSRBadgeContainer warning={warning}>{badgeLabel}</MSRBadgeContainer>
    </Popover>
  );
};

const ClientCard = (props: {
  clientId?: number;
  coupleId?: string;
  events: Event[];
}) => {
  let shortenedName: string = "";

  const couple = useSelector(
    (state: AppState) => state.slottool.coupleMap[props.coupleId!],
  );


  const client = useSelector(
    (state: AppState) => state.slottool.clientMap[props.clientId!],
  );

  if (couple) {
    shortenedName = shortenCoupleName(couple);
  } else {
    shortenedName = shortenClientName(client);
  }
  const healthie_id = client?.healthie_id;

  return (
    <ClientCardContainer>
      <Row style={{ display: "flex" }}>
        <Mangle>
          <a
            href={`/my-clients/${client?.id}/schedule`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {shortenedName}
          </a>
        </Mangle>
        {healthie_id && (
          <a
            href={`https://twochairs.gethealthie.com/users/${healthie_id}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginLeft: "auto", display: "inline-block" }}
          >
            Healthie
          </a>
        )}
      </Row>
      <span style={{ color: $greyText }}>
        {props.events
          .map((e) => moment(e.start_time))
          .sort((a, b) => a.unix() - b.unix())
          .map((d) => d.format("MM/DD"))
          .join(", ")}
      </span>
    </ClientCardContainer>
  );
};

const ClientCardContainer = styled.div`
  border-radius: 8px;
  width: 100%;
  background-color: ${$greyBG};
  padding: 4px 8px;
  margin: 4px 0;
`;

const MSRBadgeContainer = styled.span<{ warning?: boolean }>`
  background-color: ${(props) => (props.warning ? $red : $greyText)};
  border: none;
  border-radius: 16px;
  padding: 2px 8px;
  margin-top: 2px;
  color: #fff;
  font-size: 12px;
  font-weight: 500;

  transition: all 200ms ease;
  white-space: nowrap;
`;

export const OpenTimeSlotCell = (props: OpenTimeSlotCellProps) => {
  const {
    clinicianId,
    day,
    hour,
    typeId,
    rowIndex,
    eventIds,
    displaySide,
    manual,
    slot,
  } = props;
  const eventType = "open";

  const thisElement = React.useRef<HTMLDivElement>(null);
  const [cellPosition, windowWidth] = useCellResizer(thisElement);

  let popoverPlacement: TooltipPlacement = "right";
  if (cellPosition) {
    popoverPlacement =
      (cellPosition.right + cellPosition.left) / 2 >= windowWidth / 2
        ? "left"
        : "right";
  }

  return (
    <Popover
      placement={popoverPlacement}
      content={EventCellPopoverContent({
        eventIds,
        typeId,
        day,
        hour,
        displaySide,
        eventType,
      })}
      open={false}
    >
      <CellContainer
        ref={thisElement}
        displaySide={displaySide}
        eventType={eventType}
        rowIndex={rowIndex}
        style={{ gridRow: "1 / -1" }} // forces a Slot to occupy the entire cell
      >
        <CellLabel style={{ whiteSpace: "break-spaces", fontSize: "10pt" }}>
          <CalendarOutlined /> Begins{" "}
          {DateTime.fromISO(slot.start_date).toFormat("LLL dd")}
        </CellLabel>
        <CellLabel>
          {manual ? (
            <>
              <EditOutlined /> Manually Opened
            </>
          ) : null}
        </CellLabel>
        <ActionsContainer>
          <IfPermitted
            permissions={["IsATSlotEditor", "IsClinicalLeader"]}
            requireAll={false}
          >
            {manual ? (
              <RemoveOverride type="open" slot={slot} />
            ) : (
              <>
                <IfPermitted
                  permissions={["IsConsultTimeslotEditor"]}
                  requireAll={false}
                >
                  <OpenReservationTimeslot
                    clinicianId={clinicianId}
                    day={day}
                    hour={hour}
                    recurrence={
                      (displaySide === "both"
                        ? "weekly"
                        : "biweekly") as Recurrence
                    }
                    even_odd_week={
                      displaySide === "left"
                        ? "even"
                        : displaySide === "right"
                        ? "odd"
                        : undefined
                    }
                    reservation_type={"consult" as ReservationType}
                  />
                </IfPermitted>
                <CloseTimeslot
                  clinicianId={clinicianId}
                  day={day}
                  hour={hour}
                  recurrence={
                    (displaySide === "both"
                      ? "weekly"
                      : "biweekly") as Recurrence
                  }
                  even_odd_week={
                    displaySide === "left"
                      ? "even"
                      : displaySide === "right"
                      ? "odd"
                      : undefined
                  }
                />
              </>
            )}
          </IfPermitted>
        </ActionsContainer>
      </CellContainer>
    </Popover>
  );
};

export const ClosedTimeSlotCell = (props: ClosedTimeSlotCellProps) => {
  const { day, hour, typeId, rowIndex, eventIds, displaySide, slot } = props;
  const eventType = "closed";

  const thisElement = React.useRef<HTMLDivElement>(null);
  const [cellPosition, windowWidth] = useCellResizer(thisElement);
  let popoverPlacement: TooltipPlacement = "right";
  if (cellPosition) {
    popoverPlacement =
      (cellPosition.right + cellPosition.left) / 2 >= windowWidth / 2
        ? "left"
        : "right";
  }

  return (
    <Popover
      placement={popoverPlacement}
      content={EventCellPopoverContent({
        eventIds,
        typeId,
        day,
        hour,
        displaySide,
        eventType,
      })}
      open={false}
    >
      <CellContainer
        ref={thisElement}
        displaySide={displaySide}
        eventType={eventType}
        rowIndex={rowIndex}
        style={{ gridRow: "1 / -1", flexDirection: "column" }} // forces a Slot to occupy the entire cell
      >
        <CellLabel>
          <EditOutlined /> Manually Closed
        </CellLabel>
        <CellLabel style={{ color: $greyText }}>
          <ClockCircleOutlined /> Exp:{" "}
          {moment(slot.expires_at).format("M/DD ha")}
        </CellLabel>
        <ActionsContainer>
          <IfPermitted
            permissions={["IsATSlotEditor", "IsClinicalLeader"]}
            requireAll={false}
          >
            <RemoveOverride type="closed" slot={slot} />
          </IfPermitted>
        </ActionsContainer>
      </CellContainer>
    </Popover>
  );
};
