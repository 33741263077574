import { Flex } from "@/app/design-system";
import {
  CartesianGrid,
  Legend as LegendWrapper,
  Line,
  ComposedChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Area,
} from "recharts";
import { GraphDataPointData } from "./graph.types";
import { GraphAxisTick } from "./_components/graph-axis-tick";
import { GraphDot as DefaultGraphDot, GraphDotProps } from "./_components/graph-dot";
import { GraphLabel } from "./_components/graph-label";
import { GraphTooltip } from "./_components/graph-tooltip";

interface GraphProps {
  graphDataPoints: GraphDataPointData[];
  yAxisTickIntervals: number[];
  yAxisTickFormatter?: (value: number) => string;
  referenceLineValue?: number;
  referenceLineLabel?: string;
  blueBackgroundHighlightValue?: number;
  legend?: () => JSX.Element;
  customGraphDot?: (props: GraphDotProps) => JSX.Element | null;
}

export const Graph = ({
  graphDataPoints,
  yAxisTickIntervals,
  yAxisTickFormatter,
  referenceLineValue = 0,
  referenceLineLabel,
  blueBackgroundHighlightValue = 0,
  legend: Legend,
  customGraphDot: CustomGraphDot,
}: GraphProps) => {
  const GraphDot = CustomGraphDot || DefaultGraphDot;
  return (
    <Flex css={{ padding: 16, pr: 0, width: "100%" }}>
      {/* 100% width would cause it to be fixed width for some reason */}
      <ResponsiveContainer debounce={10} height={500} width="99%">
        <ComposedChart
          margin={{
            top: 20,
            bottom: 20,
            left: 20,
            right: 20,
          }}
          data={graphDataPoints}
        >
          <CartesianGrid stroke="#F0F0F0" />
          <XAxis
            dataKey="label"
            tickLine={false}
            padding={{ left: 20, right: 20 }}
            axisLine={{ stroke: "#F0F0F0" }}
          />
          <YAxis
            dataKey="value"
            interval={0}
            axisLine={{ stroke: "#F0F0F0" }}
            ticks={yAxisTickIntervals}
            // Making domain an empty array seems to have the chart defer to "ticks" for the y axis
            domain={[]}
            tickMargin={5}
            tick={yAxisTickFormatter ? (props) => <GraphAxisTick {...props} /> : undefined}
            tickFormatter={yAxisTickFormatter}
          />
          <Tooltip
            content={(props) => (
              <GraphTooltip {...props} />
            )}
          />
          {referenceLineValue && (
            <ReferenceLine
              label={(props) => (
                <GraphLabel {...props} displayText={referenceLineLabel} fill="#8C8C8C" />
              )}
              y={referenceLineValue}
              stroke="#8C8C8C"
              strokeWidth={2}
              strokeDasharray="9 2"
            />
          )}
          {blueBackgroundHighlightValue && (
            <Area
              type="monotone"
              dataKey={() => blueBackgroundHighlightValue}
              fill="#edf6ff"
              stroke="#add8e6"
            />
          )}
          {!!Legend && (
            <LegendWrapper
              layout="vertical"
              verticalAlign="top"
              align="right"
              content={() => <Legend />}
            />
          )}
          <Line
            isAnimationActive={false}
            connectNulls
            type="monotone"
            dataKey="value"
            stroke="#8C8C8C"
            dot={(props) => (
              <GraphDot {...props} />
            )}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Flex>
  );
};
