import * as React from "react";
import { useDispatch } from "react-redux";
import { useShallowEqualSelector } from "../../../../_helpers/redux";
import { Radio } from "antd";
import { consultNoteActions } from "../../../../../state/models/consultnote";
import { options } from "./enums";
import QuestionTitle from "../shared/QuestionTitle";
import { RadioGroup } from "../../../Matchmaker/1-ClientStep/CarePreferences/styled";
import { ConsultNoteQuestion } from "../../../../../api/types";
import { getCurrentConsultNoteInfo } from "@/app/consults-and-matching/_hooks";

const texts = [
  "Client's presenting issues are impacting their social functioning",
  "Client's presenting issues are impacting their occupational/academic functioning",
  "Client's presenting issues are impacting their activities of daily living (ADL's)",
];

interface Props {
  question: ConsultNoteQuestion;
  handleEdit: (hasBeenEdited: boolean) => void;
}

export default ({ question, handleEdit }: Props) => {
  const { readOnlyConsultNote } = getCurrentConsultNoteInfo();

  const [values] = useShallowEqualSelector((state) => [
    state.consultnote?.openConsult?.consult_note?.functional_impairment || [],
  ]);

  const dispatch = useDispatch();

  const handleChange = (key: string, value: string) => {
    handleEdit(true);
    dispatch(
      consultNoteActions.updateRelatedObjectField({
        relatedObjectName: "functional_impairment",
        key,
        value,
      }),
    );
  };

  const slugs = [
    "social_functioning",
    "occupation_functioning",
    "dailylife_functioning",
  ];

  return (
    <div style={{ paddingTop: "15px", paddingBottom: "15px" }}>
      <QuestionTitle
        questionNumber={question.questionNumber}
        titleText={question.title}
        subtitleText={question.subtitle}
        isRequired={question.isRequired}
      />
      {slugs.map((slug, index) => {
        const v = values[slug];
        return (
          <div style={{ paddingBottom: "20px" }} key={slug}>
            <div style={{ padding: "8px 0px" }}>
              <b>{texts[index]}</b>
            </div>

            <RadioGroup
              disabled={readOnlyConsultNote}
              onChange={(e) => handleChange(slug, e.target.value)}
              value={v}
            >
              {options.map((o) => {
                return (
                  <span
                    style={{ paddingBottom: "8px", fontSize: "13px" }}
                    key={o.key}
                  >
                    <Radio.Button value={o.value}>{o.title}</Radio.Button>
                  </span>
                );
              })}
            </RadioGroup>
          </div>
        );
      })}
    </div>
  );
};
