import * as React from "react";

interface Props {
  margin?: string;
  size?: string;
  style?: any;
}

export default class ViewContainer extends React.Component<Props> {
  public render() {
    const margin = this.props.margin ? this.props.margin : "10px";
    const size = this.props.size ? this.props.size : "default";
    let sizeStyle = {};
    switch (size) {
      case "xs":
        sizeStyle = {
          fontSize: "14px",
        };
        break;
      case "small":
      case "sm":
        sizeStyle = {
          fontSize: "16px",
        };
        break;
      case "large":
      case "lg":
        sizeStyle = {
          fontSize: "22px",
        };
        break;
      case "md":
      case "medium":
      case "default":
      default:
        sizeStyle = {
          fontSize: "18px",
        };
    }
    return (
      <h3
        style={{
          ...this.props.style,
          ...sizeStyle,
          marginBottom: margin,
          fontWeight: 400,
        }}
      >
        {this.props.children}
      </h3>
    );
  }
}
