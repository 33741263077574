import React, { useEffect } from "react";

import {
  useMyClientsStore,
  selectClientStatusFiltersSlice,
  initialClientStatusFilterState,
  ClientStatusFilterState,
} from "../../../../../my-clients-model";

export const useClientStatusFiltersDropdown = () => {
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

  const setMyClientsFilter = useMyClientsStore(
    (state) => state.setMyClientsFilter,
  );

  const clientStatusFilterState = useMyClientsStore(
    selectClientStatusFiltersSlice,
  );

  const clearClientStatusFilters = () => {
    for (const key in initialClientStatusFilterState) {
      setMyClientsFilter(key as keyof ClientStatusFilterState, false);
    }

    setIsDropdownOpen(false);
  };

  return {
    isDropdownOpen,
    setIsDropdownOpen,
    setMyClientsFilter,
    clearClientStatusFilters,
    ...clientStatusFilterState,
  };
};
