export const options = [
  { key: "OPTION__1", value: 1, title: "1 = Not at all" },
  { key: "OPTION__2", value: 2, title: "2" },
  { key: "OPTION__3", value: 3, title: "3" },
  { key: "OPTION__4", value: 4, title: "4 =  weighing pros/cons" },
  { key: "OPTION__5", value: 5, title: "5" },
  { key: "OPTION__6", value: 6, title: "6" },
  { key: "OPTION__7", value: 7, title: "7 = ready to engage" },
];


