import { NavMenuButton } from "./main-nav.styled";
import { NavigationToolTip } from "./navigation-tooltip";

interface NavigationMenuButtonProps {
  isCollapsed: boolean;
  children: React.ReactNode;
  menuItemTooltipContent: string;
  callback?: () => void;
}

export function NavigationMenuButton({
  isCollapsed,
  children,
  menuItemTooltipContent,
  callback,
}: NavigationMenuButtonProps) {
  return (
    <NavigationToolTip
      content={menuItemTooltipContent}
      shouldRenderContent={isCollapsed}
    >
      <NavMenuButton
        css={{
          justifyContent: isCollapsed ? "center" : "flex-start",
          px: isCollapsed ? 13.5 : 16,
        }}
        onClick={() => {
          if (callback) {
            callback();
          }
        }}
      >
        {children}
      </NavMenuButton>
    </NavigationToolTip>
  );
}

export function NavigationMenuSwitchButton({
  isCollapsed,
  children,
  menuItemTooltipContent,
}: NavigationMenuButtonProps) {
  return (
    <NavigationToolTip
      content={menuItemTooltipContent}
      shouldRenderContent={isCollapsed}
    >
      {children}
    </NavigationToolTip>
  );
}
