import {
  BasicInfoQuestionAndResponseContainer,
  BasicInfoQuestionContainer,
  BasicInfoQuestionText,
  BasicInfoResponseText,
} from "./basic-information.styled";
import { Identity } from "../../_api/use-group-therapy-client-overview/use-group-therapy-client.util";

interface IdentityInfoProps {
  identificationData?: Identity;
}

export const IdentityInfo = ({ identificationData }: IdentityInfoProps) => {
  if (!identificationData) {
    return null;
  }

  const racialData = identificationData.racial
    ? identificationData.racial.join(" / ")
    : null;

  return identificationData ? (
    <>
      <BasicInfoQuestionAndResponseContainer>
        <BasicInfoQuestionContainer>
          <BasicInfoQuestionText>
            Racial and Ethnic Identity
          </BasicInfoQuestionText>
        </BasicInfoQuestionContainer>
        <BasicInfoResponseText>{racialData}</BasicInfoResponseText>
      </BasicInfoQuestionAndResponseContainer>

      <BasicInfoQuestionAndResponseContainer>
        <BasicInfoQuestionContainer>
          <BasicInfoQuestionText>Gender</BasicInfoQuestionText>
        </BasicInfoQuestionContainer>
        <BasicInfoResponseText>
          {identificationData.gender}
        </BasicInfoResponseText>
      </BasicInfoQuestionAndResponseContainer>

      <BasicInfoQuestionAndResponseContainer>
        <BasicInfoQuestionContainer>
          <BasicInfoQuestionText>Pronouns</BasicInfoQuestionText>
        </BasicInfoQuestionContainer>
        <BasicInfoResponseText>
          {identificationData.pronouns}
        </BasicInfoResponseText>
      </BasicInfoQuestionAndResponseContainer>
    </>
  ) : null;
};
