// Note: Should be kept in-sync between ccfe, cfa, and fulfiller
import * as SelectPrimitive from "@radix-ui/react-select";
import React from "react";
import { DownChevronIcon } from "../icons";

import {
  SelectContent,
  SelectIcon,
  SelectItem,
  SelectPortal,
  SelectRoot,
  SelectScrollDownButton,
  SelectScrollUpButton,
  SelectTrigger,
  SelectValue,
  SelectViewport,
} from "./select.styled";

// Reference for using variants with styled stitches: https://github.com/stitchesjs/stitches/issues/999
export interface ExtendedTriggerProps
  extends React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger> {
  // Open to renaming 'isSubtle'; the variant is for when there are already a lot of buttons on the page and it's not really a form
  isSubtle: boolean;
  size: "small" | undefined;
}

export const ComposedRadixUISelectTrigger = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Trigger>,
  ExtendedTriggerProps
>(({ className, children, ...props }, ref) => {
  return (
    <SelectTrigger
      {...props}
      subtlety={props.isSubtle ? "subtle" : undefined}
      size={props.size}
      className={className}
      ref={ref}
    >
      <SelectValue placeholder="Select" />
      <SelectIcon>
        {children}
        <DownChevronIcon />
      </SelectIcon>
    </SelectTrigger>
  );
});

export interface ComposedSelectRadixUIOption {
  value: string;
  label: string;
  id: string;
}

interface ComposedRadixUISelectContentProps
  extends React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content> {
  options: ComposedSelectRadixUIOption[];
  isInModal?: boolean;
}

export const ComposedRadixUISelectContent = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Content>,
  ComposedRadixUISelectContentProps
>(({ className, options, ...props }, ref) => (
  <SelectContent
    {...props}
    ref={ref}
    className={className}
    data-testid="radix-select-content"
    isInModal={props.isInModal}
  >
    <SelectScrollUpButton>
      <DownChevronIcon />
    </SelectScrollUpButton>
    <SelectViewport>
      {options.map((option) => (
        <SelectItem
          value={option.value}
          key={option.id}
          data-testid="radix-select-item"
        >
          {option.label}
        </SelectItem>
      ))}
    </SelectViewport>
    <SelectScrollDownButton>
      <DownChevronIcon />
    </SelectScrollDownButton>
  </SelectContent>
));

interface ComposedSelectRadixUIProps {
  onChange: (value: string) => void;
  options: ComposedSelectRadixUIOption[];
  selectLabel: string;
  value?: string;
  disabled?: boolean;
  isSubtle?: boolean;
  size?: "small" | undefined;
  isInModal?: boolean;
}

//
/**
 * @name ComposedRadixUISelect
 * @prop { string } value
 * @prop { boolean } disabled
 * @prop { (value: string) => void } onChange
 * @prop { ComposedSelectRadixUIOption } options
 * @prop { string } selectLabel
 * @prop { boolean } [isSubtle]
 * @prop { "small" | undefined } [size]
 * @prop { number } zIndex
 * @description Please give this component a descriptive name when you are using it
 * @warning SelectRoot doesn't accept a className prop.  For custom styles, import and compose Trigger/Content above. If you don't need custom styles, use this component.
 */
export const ComposedRadixUISelect = ({
  options,
  value,
  onChange,
  selectLabel,
  size,
  isSubtle = false,
  disabled = false,
  isInModal = false,
}: ComposedSelectRadixUIProps) => (
  <SelectRoot value={value} onValueChange={onChange} disabled={disabled}>
    <ComposedRadixUISelectTrigger
      isSubtle={isSubtle}
      size={size}
      aria-label={selectLabel}
    />
    <SelectPortal>
      <SelectPrimitive.Group>
        <ComposedRadixUISelectContent options={options} isInModal={isInModal} />
      </SelectPrimitive.Group>
    </SelectPortal>
  </SelectRoot>
);
