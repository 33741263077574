import { ClientOverviewApiData } from "@/app/api/use-my-clients-overview/my-clients-data.interface";
import {
  Box,
  Flex,
  Stack,
  styledStitches,
  Text,
  RadixTooltip,
} from "@/app/design-system";
import { ClockIcon } from "@/app/design-system/icons/components/clock-icon";
import { useMyClientsUser } from "@/app/my-clients/routes/my-clients-page/hooks/use-my-clients-user";
import { DateTime } from "luxon";
import React from "react";
import { EventApiData } from "../../../../../../../../api/use-my-clients-overview/my-clients-data.interface";
import { CalendarIcon } from "../../../../../../../../design-system/icons";
import { OPACITY_0_TO_100 } from "../../../../../../../../design-system/styles/config/css-keyframe-constants";
import { SessionCadence } from "../../schedule-page.interface";
import { SessionActionsContainer } from "../session-actions/session-actions";
import { SessionCadenceLabel } from "./session-cadence-label";
import { SessionTime } from "./session-time-details";

const SessionCardContainer = styledStitches(Box, {
  border: "1px solid",
  borderColor: "$neutral6",
  pl: 28,
  height: 75,
  width: 660,
  position: "relative",
  animation: `${OPACITY_0_TO_100} calc(75ms * var(--index)) ease-in-out`,
  z: 0,

  ".session-action-container": {
    display: "none",
  },

  "&:hover": {
    backgroundColor: "$neutral3",

    ".session-action-container": {
      display: "flex",
    },
  },
});

interface SessionCardProps {
  eventType?: string;
  sessionCadence: SessionCadence;
  isEditable: boolean;
  index?: number;
  children?: React.ReactNode;
  event: EventApiData;
  client?: ClientOverviewApiData | null;
  isRecent?: boolean;
}

export const SessionCard = ({
  index,
  isEditable,
  client,
  event,
  isRecent,
}: SessionCardProps) => {
  const milliseconds =
    DateTime.fromISO(event?.startTime)
      .plus({ days: 2, minutes: 50 })
      .toMillis() - DateTime.now().toMillis();

  const hours = Math.trunc(milliseconds / 3600000);

  const mins = Math.trunc(milliseconds / 60000);

  const { isClinicalLeaderOrSuperuser, isClinician } = useMyClientsUser();

  return (
    <SessionCardContainer
      alignItems="center"
      gap={12}
      br={8}
      className="session-action-container"
      css={{
        "--index": index,
      }}
    >
      {isRecent ? (
        <ClockIcon />
      ) : (
        <RadixTooltip content={event.clinician?.fullName}>
          <Flex>
            <CalendarIcon />
          </Flex>
        </RadixTooltip>
      )}
      <Stack>
        <Flex gap={12}>
          <SessionTime
            appointmentDatetime={event.startTime}
            color={"$neutral12"}
          />
          <SessionCadenceLabel
            sessionCadence={event.recurrenceData?.recurrence}
            isException={event.recurrenceData?.isException}
          />
        </Flex>
        {isRecent ? (
          <Text color={"$orange11"} fontSize={13}>
            {hours > 1 ? hours : mins}
            {hours > 1 ? "h" : " min"} to make attendance updates.
          </Text>
        ) : null}
      </Stack>

      {isClinicalLeaderOrSuperuser || isClinician ? (
        <SessionActionsContainer
          isEditable={isEditable}
          event={event}
          client={client}
        />
      ) : null}
    </SessionCardContainer>
  );
};
