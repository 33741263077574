import { CurrentUserContext } from "@/app/app.utils";
import { useNotification } from "@/app/design-system";
import { useCancelSession } from "@/app/my-clients/api/use-upsert-session/use-cancel-session";
import {
  DirtyUpsertSessionFormFields,
  useUpdateSession,
} from "@/app/my-clients/api/use-upsert-session/use-edit-session";
import { TIMEZONE, useHasMyClientsPermissions } from "@/app/my-clients/utils";
import React from "react";
import { useFormState } from "react-hook-form";
import { useQueryClient } from "react-query";

import { CancelSessionFormFields } from "../../session-actions/cancel-session-action/cancel-session-form/use-cancel-session-form";
import { RecurringSessionsOptions } from "../../session-actions/session-actions.constants";
import { UpsertSessionFormFields } from "../../session-actions/upsert-session-action/upsert-session-form/use-upsert-session-form";
import { UpdateActionProps } from "./session-modal-dialog.interface";

interface UseSessionModalDialogActionParams<T extends object>
  extends UpdateActionProps<T> {}

export function useSessionModalDialogAction<T extends object>({
  mode,
  control,
  handleSubmit,
  eventId,
  setIsDrawerOpen,
  selectedSessions,
}: UseSessionModalDialogActionParams<T>) {
  const queryClient = useQueryClient();
  const [isModalDialogOpen, setIsModalDialogOpen] = React.useState(false);

  const hasMyClientsPermissions = useHasMyClientsPermissions();
  const cuser = React.useContext(CurrentUserContext);

  const thisSessionOnly =
    selectedSessions === RecurringSessionsOptions.THIS_SESSION_ONLY;

  const title =
    mode === "cancel"
      ? `Cancel ${thisSessionOnly ? "" : "recurring"} session`
      : `Update ${thisSessionOnly ? "" : "recurring"} session`;

  const primaryButtonText = mode === "cancel" ? "Confirm to cancel" : "Save";

  const { renderNotification } = useNotification();

  const { dirtyFields, isDirty, isValid } = useFormState({ control });

  const updateSessionMutation = useUpdateSession();
  const cancelSessionMutation = useCancelSession();

  const onSubmit = handleSubmit((data) => {
    // EDIT SESSION FLOW
    if (mode === "edit") {
      if (!isDirty) {
        renderNotification({
          message: "No changes",
          notificationType: "warning",
        });
        return;
      }

      if (!hasMyClientsPermissions) {
        // the button disable should prevent this, but using this as a fallback
        renderNotification({
          message: "You don't have permission to edit sessions.",
          notificationType: "warning",
        });
        return;
      }

      updateSessionMutation.mutate(
        {
          id: eventId,
          data: data as UpsertSessionFormFields,
          dirtyFields: dirtyFields as DirtyUpsertSessionFormFields,
          isUpdatingAllFutureSessions:
            selectedSessions === RecurringSessionsOptions.ALL_FUTURE_RECURRING,
          timezone: TIMEZONE(cuser),
        },
        {
          onError: () => {
            renderNotification({
              message: "There was an error trying to update this event.",
              notificationType: "error",
            });
          },
          onSuccess: () => {
            queryClient.invalidateQueries();
            setIsDrawerOpen(false);
            renderNotification({
              message: "Your updates are saved.",
              notificationType: "success",
            });
          },
        },
      );
    } else {
      // CANCEL SESSION FLOW
      if (!hasMyClientsPermissions) {
        // the button disable should prevent this, but using this as a fallback
        renderNotification({
          message: "You don't have permission to cancel sessions.",
          notificationType: "warning",
        });
        return;
      }

      cancelSessionMutation.mutate(
        {
          id: eventId,
          data: data as CancelSessionFormFields,
          isCancellingCallFutureSessions:
            selectedSessions === RecurringSessionsOptions.ALL_FUTURE_RECURRING,
        },
        {
          onError: () => {
            renderNotification({
              message: "There was an error trying to cancel this session.",
              notificationType: "error",
            });
          },
          onSuccess: () => {
            queryClient.invalidateQueries();
            setIsDrawerOpen(false);
            renderNotification({
              message: "Session canceled.",
              notificationType: "success",
            });
          },
        },
      );
    }
  });

  return {
    onSubmit,
    title,
    primaryButtonText,
    setIsModalDialogOpen,
    isModalDialogOpen,
    isLoading: updateSessionMutation.isLoading,
    isValid,
  };
}
