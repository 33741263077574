import { Flex, Spacer, Text } from "../../../../../design-system";
import { XIcon, MagnifyingGlassIcon } from "../../../../../design-system/icons";

import { MyClientsFilters } from "../../my-clients-model/my-clients.interface";
import { useMyClientsSearchStore } from "../../my-clients-model/my-clients.model";
import {
  StyledFilterTagClearButton,
  StyledFilterTag,
} from "./filter-tag.styled";

interface FilterTagProps {
  tag: keyof MyClientsFilters;
  clearTagCallback: () => void;
}

export const FilterTag = ({ tag, clearTagCallback }: FilterTagProps) => {
  return (
    <StyledFilterTag>
      {tag}

      <StyledFilterTagClearButton
        onClick={() => {
          clearTagCallback();
        }}
      >
        <XIcon height={12} width={12} />
      </StyledFilterTagClearButton>
    </StyledFilterTag>
  );
};

interface SearchTagProps {
  searchTerm: string;
}

export const SearchTag = ({ searchTerm }: SearchTagProps) => {
  const { setSearchTermState, setClientIds } = useMyClientsSearchStore();

  return (
    <StyledFilterTag>
      <Flex alignItems={"center"}>
        <MagnifyingGlassIcon height={16} width={16} />
        <Spacer x={8} />
        <Text fontSize={14}>"{searchTerm}"</Text>
      </Flex>
      <StyledFilterTagClearButton
        onClick={() => {
          setSearchTermState("");
          setClientIds([]);
        }}
      >
        <XIcon height={12} width={12} />
      </StyledFilterTagClearButton>
    </StyledFilterTag>
  );
};
