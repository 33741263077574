import * as React from "react";
import { message } from "antd";

import { Resource } from "../types";
import { Container } from "../styles";

import { toggleResourceIsFavorite } from "../utils";
import { useDispatchPromise } from "../../_helpers/redux";
import { ResourceItem } from "./ResourceItem";
import { CreateResourceForm } from "./CreateResourceForm";

interface ResourceProps {
  resources: Resource[];
  onSelect: (id: string) => void;
  updateResourceItem: (resource: Resource) => void;
  canAddResource: boolean;
  setParams: (key: string, value: string) => void;
  handleDeleteResource: () => void;
  handleSetIsFormVisible: (isVisible: boolean) => void;
  isFormVisible: boolean;
}

export const ResourceList = ({
  resources,
  onSelect,
  updateResourceItem,
  canAddResource,
  setParams,
  handleDeleteResource,
  handleSetIsFormVisible,
  isFormVisible,
}: ResourceProps) => {
  const dispatch = useDispatchPromise();
  const handleFavoriteIconClick = async (resource: Resource) => {
    try {
      const nextResource = await dispatch(toggleResourceIsFavorite(resource));
      updateResourceItem(nextResource);
    } catch (e) {
      message.error((e as Error).message);
    }
  };
  return (
    <>
      <h3 style={{ marginLeft: 16, paddingLeft: 8 }}>Resource Items</h3>
      <Container style={{ flexDirection: "row" }}>
        {canAddResource && (
          <CreateResourceForm
            onUpdateResource={updateResourceItem}
            setParams={setParams}
            handleDeleteResource={handleDeleteResource}
            handleSetFormIsVisible={handleSetIsFormVisible}
            onSelectResource={onSelect}
            isFormVisible={isFormVisible}
          />
        )}
        {resources.map(resource => (
          <ResourceItem
            resource={resource}
            onSelect={onSelect}
            handleFavoriteIconClick={handleFavoriteIconClick}
            key={resource.id}
          />
        ))}
        {resources.length === 0 && !canAddResource && (
          <p style={{ marginLeft: 8, paddingLeft: 8 }}>
            There are no resource items
          </p>
        )}
      </Container>
    </>
  );
};
