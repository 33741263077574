import { useGetClinicianAuthGroupsQuery } from '@/graphql/generated'
import { graphqlClient } from '@/api/graphql';

export const useGetClinicianAuthGroups = (
  clinicianId: number | undefined | null,
) => {
  if (!clinicianId)
    return {
      groupData: undefined,
      isLoadingGroupData: clinicianId === undefined ? true : false,
    };
  const { data, isLoading } = useGetClinicianAuthGroupsQuery(graphqlClient, {
    clinician_id: clinicianId,
  });
  return { groupData: data, isLoadingGroupData: isLoading };
};
