import React from "react";
import {
  useMyClientsFilterStore,
  selectBaselineSeverityState,
  BaselineSeverityFilterState,
  initialBaselineSeverityFilterState,
} from "../../../../../../my-clients-model";

export const useBaselineSeverityFilterDropdown = () => {
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

  const baselineSeverityFilterState = useMyClientsFilterStore(
    selectBaselineSeverityState,
  );

  const numberOfSelectedFilters = [
    ...Object.values(baselineSeverityFilterState),
  ].filter((filter) => filter).length;

  const setMyClientsFilter = useMyClientsFilterStore(
    (state) => state.setMyClientsFilter,
  );

  const clearBaselineSeverityFitlers = () => {
    for (const key in initialBaselineSeverityFilterState) {
      setMyClientsFilter(key as keyof BaselineSeverityFilterState, false);
    }

    setIsDropdownOpen(false);
  };

  return {
    isDropdownOpen,
    setIsDropdownOpen,
    clearBaselineSeverityFitlers,
    setMyClientsFilter,
    numberOfSelectedFilters,
    ...baselineSeverityFilterState,
  };
};
