import React, { useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../../state/models";
import {
  matchmakerOperations,
  matchmakerActions,
} from "../../../../state/models/matchmaker";
import { Title } from "../../../_layout/display";
import { bucketSort } from "../../../_helpers";
import { Icon as LegacyIcon } from "@ant-design/compatible";
import { Checkbox, Divider } from "antd";
import {
  NEEDS_TAG_WEIGHT,
  BENEFIT_TAG_WEIGHT,
  TagWeight,
} from "../../../../api/constants";
import { difference, uniq, some } from "lodash-es";
import AgeRangePicker from "../../../_shared/FormControls/AgeRangePicker";

import Row from "../../../_layout/Flex/Row";
import styled from "styled-components";
import {
  $whiteHover,
  $secondary,
  $greyBorder,
} from "../../../../assets/colors";
import Column from "../../../_layout/Flex/Column";

const StarButton = styled(Column)`
  min-width: 30px;
  max-width: 30px;
  height: 30px;
  font-size: 20px;
  cursor: pointer;
  border-radius: 50%;
  &:hover {
    background-color: ${$whiteHover};
  }
`;

export default function TagCheckboxes() {
  const {
    consultNeedsTags,
    consultBenefitsTags,
    matchNeedsTagIds,
    matchBenefitsTagIds,
  } = useSelector((state: AppState) => {
    const tagMap = state.matches.tagMap;
    const toTag = (tId: number) => tagMap[tId];

    const uncheckedNeedsTagIds = difference(
      state.matchmaker.consultTagMap[NEEDS_TAG_WEIGHT],
      state.matchmaker.matchTagMap[NEEDS_TAG_WEIGHT],
    );

    const consultNeedsTags =
      state.matchmaker.consultTagMap[NEEDS_TAG_WEIGHT].map(toTag);
    const consultBenefitsTags =
      state.matchmaker.consultTagMap[BENEFIT_TAG_WEIGHT].concat(
        uncheckedNeedsTagIds,
      ).map(toTag);

    return {
      consultNeedsTags,
      consultBenefitsTags,
      matchNeedsTagIds: state.matchmaker.matchTagMap[NEEDS_TAG_WEIGHT],
      matchBenefitsTagIds: state.matchmaker.matchTagMap[BENEFIT_TAG_WEIGHT],
    };
  });

  const [needsBuckets, benefitsBuckets] = useMemo(
    () => [
      uniq(consultNeedsTags.map((tag) => tag.bucket)),
      uniq(consultBenefitsTags.map((tag) => tag.bucket)),
    ],
    [consultNeedsTags, consultBenefitsTags],
  );

  const dispatch = useDispatch();
  const onTagCheckboxChange = useCallback(
    (tagId: number, weight: TagWeight, checked: boolean) => {
      switch (weight) {
        case NEEDS_TAG_WEIGHT:
          dispatch(
            matchmakerOperations.setMatchTag({
              tagId,
              weight: NEEDS_TAG_WEIGHT,
              checked,
            }),
          );
          dispatch(
            matchmakerOperations.setMatchTag({
              tagId,
              weight: BENEFIT_TAG_WEIGHT,
              checked: !checked,
            }),
          );
          break;
        case BENEFIT_TAG_WEIGHT:
          dispatch(
            matchmakerOperations.setMatchTag({
              tagId,
              weight: BENEFIT_TAG_WEIGHT,
              checked,
            }),
          );
          break;
        default:
          throw new Error(`Unexpected tag weight: ${weight}`);
      }
    },
    [dispatch],
  );

  return (
    <>
      <Title size="small">Needs</Title>
      {needsBuckets.sort(bucketSort).map((bucket) => (
        <div key={bucket} style={{ marginBottom: "10px" }}>
          <div>
            <i>{bucket}</i>
          </div>
          {consultNeedsTags
            .filter((tag) => tag.bucket === bucket)
            .map((t) => (
              <CheckboxItem
                key={t.id}
                tagId={t.id}
                category={t.category}
                name={t.name}
                checked={matchNeedsTagIds.includes(t.id)}
                onChange={(e) =>
                  onTagCheckboxChange(t.id, NEEDS_TAG_WEIGHT, e.target.checked)
                }
              />
            ))}
        </div>
      ))}
      {consultNeedsTags.length === 0 && <div>None selected.</div>}

      <Divider style={{ margin: "15px 0", backgroundColor: "transparent" }} />

      <Title size="small">Benefits</Title>
      {benefitsBuckets.sort(bucketSort).map((bucket) => (
        <div key={bucket} style={{ marginBottom: "10px" }}>
          <div>
            <i>{bucket}</i>
          </div>
          {consultBenefitsTags
            .filter((tag) => tag.bucket === bucket)
            .map((t) => (
              <CheckboxItem
                key={t.id}
                tagId={t.id}
                category={t.category}
                name={t.name}
                checked={matchBenefitsTagIds.includes(t.id)}
                disabled={some(consultNeedsTags, (tag) => tag.id === t.id)}
                onChange={(e) =>
                  onTagCheckboxChange(
                    t.id,
                    BENEFIT_TAG_WEIGHT,
                    e.target.checked,
                  )
                }
              />
            ))}
        </div>
      ))}
      {consultBenefitsTags.length === 0 && <div>None selected.</div>}
      <AgeRangePicker />
    </>
  );
}

interface CheckboxItemProps {
  onChange: (e: any) => void;
  checked: boolean;
  disabled?: boolean;
  category: string;
  name: string;
  tagId: number;
}

const CheckboxItem = ({
  onChange,
  checked,
  disabled,
  category,
  name,
  tagId,
}: CheckboxItemProps) => (
  <>
    <Row style={{ minHeight: "30px" }}>
      <Checkbox
        {...{
          onChange,
          checked,
          disabled,
          style: { flex: 1 },
        }}
      >
        {category} - <strong>{name}</strong>
      </Checkbox>
      {checked && <TagStar tagId={tagId} />}
    </Row>
    <Divider style={{ margin: "8px 0 5px" }} />
  </>
);

const TagStar = ({ tagId }: { tagId: number }) => {
  const starredTagIds = useSelector(
    (state: AppState) => state.matchmaker.starredTagIds,
  );
  const dispatch = useDispatch();
  const toggleTagStarred = useCallback(
    (tagId: number) => {
      if (starredTagIds.includes(tagId)) {
        dispatch(
          matchmakerActions.setStarredTagIds(
            starredTagIds.filter((id) => id !== tagId),
          ),
        );
      } else {
        dispatch(matchmakerActions.setStarredTagIds([...starredTagIds, tagId]));
      }
    },
    [dispatch, starredTagIds],
  );
  const tagIsStarred = starredTagIds.includes(tagId);
  if (starredTagIds.length > 2 && !tagIsStarred) {
    return null;
  }

  return (
    <StarButton layout="center center" onClick={() => toggleTagStarred(tagId)}>
      <LegacyIcon
        type="star"
        style={{ color: tagIsStarred ? $secondary : $greyBorder }}
        theme={tagIsStarred ? "filled" : "outlined"}
      />
    </StarButton>
  );
};
