import * as React from "react";

import { Navigate } from "react-router-dom";

import { UserHasAnyPermissions } from "../_helpers/permissions";
import { useShallowEqualSelector } from "../_helpers/redux";

interface AuthRouteContentProps {
  children: React.ReactElement;
}

export const AuthRouteContent = ({ children }: AuthRouteContentProps) => {
  const [currentUser, isAuthenticated] = useShallowEqualSelector((state) => [
    state.auth.currentUser,
    state.auth.isAuthenticated,
  ]);

  let redirectPath: string = "";

  if (isAuthenticated && currentUser) {
    if (UserHasAnyPermissions(currentUser, ["IsWheelCWAUser"])) {
      redirectPath = "/panel-management";
    } else {
      redirectPath = "/dashboard";
    }
  }

  return (
    <>
      {redirectPath ? (
        <Navigate to={{ pathname: redirectPath }} replace />
      ) : (
        children
      )}
    </>
  );
};
