import { graphqlClient } from "@/api/graphql";
import { FIVE_MINUTES } from "@/app/_helpers/constants";
import {
  useGroupTherapyClientMbcSurveyAnswersQuery,
  GroupTherapyClientMbcSurveyAnswersQuery,
} from "@/graphql/generated";
import { useParams } from "react-router-dom";

interface UseGroupTherapyMBCClientSurveyInfoParams<T> {
  select: (data: GroupTherapyClientMbcSurveyAnswersQuery) => T;
  enabled?: boolean;
}

export const useGroupTherapyClientMBCSurveyInfo = <T extends {}>({
  select,
}: UseGroupTherapyMBCClientSurveyInfoParams<T>) => {
  const { clientId: clientStringId, groupId } =
    useParams<{ clientId: string; groupId: string }>();

  const clientId = clientStringId ? parseInt(clientStringId) : undefined;

  return useGroupTherapyClientMbcSurveyAnswersQuery(
    graphqlClient,
    { groupId, clientId },
    {
      staleTime: FIVE_MINUTES,
      select,
      enabled: !!groupId || !!clientId,
    },
  );
};
