export const checkboxes = [
  {
    slug: "denied",
    label: "Denied current active SI / HI / SIB",
  },
  {
    slug: "endorsed_current_active_si",
    label: "Endorsed current active SI",
  },
  {
    slug: "endorsed_current_active_hi",
    label: "Endorsed current active HI",
  },
  {
    slug: "endorsed_current_sib",
    label: "Endorsed current SIB",
  },
  {
    slug: "endorsed_current_passive_si",
    label: "Endorsed current passive SI",
  },
  {
    slug: "endorsed_current_passive_hi",
    label: "Endorsed current passive HI",
  },
  {
    slug: "endorsed_prior_si_attempt",
    label: "Endorsed prior SI attempt",
  },
  {
    slug: "endorsed_prior_si",
    label: "Endorsed prior SI ",
  },
  {
    slug: "endorsed_prior_hi",
    label: "Endorsed prior HI",
  },
  {
    slug: "endorsed_prior_sib",
    label: "Endorsed prior SIB",
  },
  {
    slug: "endorsed_prior_hospitalizations",
    label: "Endorsed prior hospitalizations",
  },
];
