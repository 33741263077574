import { Flex, H2, Stack } from "../../../../../../../../../design-system";

import { EventApiData } from "../../../../../../../../../api/use-my-clients-overview/my-clients-data.interface";
import { RecentSessions } from "./components/recent-sessions";

import { SessionHistoryFiltersDropdownMenu } from "./components/session-history-filter-time-range-filter-dropdown/session-history-time-range-filter-dropdown";

interface SessionHistorySectionHeaderProps {
  sessionsAttendedInLast2Days: EventApiData[];
}

export const SessionHistorySectionHeader = ({
  sessionsAttendedInLast2Days,
}: SessionHistorySectionHeaderProps) => {
  return (
    <Stack gap={14}>
      <Flex justifyContent={"space-between"} alignItems={"center"}>
        <H2 fontWeight={700}>Session history</H2>
        <SessionHistoryFiltersDropdownMenu />
      </Flex>
      <RecentSessions
        sessionsAttendedInLast2Days={sessionsAttendedInLast2Days}
      />
    </Stack>
  );
};
