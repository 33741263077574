import * as React from "react";
import { Title } from "../../../../_layout/display";
import { RequiredText } from "../../../../_shared/RequiredText";

export default ({
  questionNumber,
  titleText,
  subtitleText,
  isRequired,
  hideQuestionNumber,
}: {
  questionNumber: number;
  titleText: string;
  subtitleText?: string;
  isRequired?: boolean;
  hideQuestionNumber?: boolean;
}) => {
  return (
    <Title margin="3px" size="sm">
      <b>
        {!hideQuestionNumber && `${questionNumber}.`} {titleText}
      </b>
      {subtitleText}
      {isRequired && <RequiredText> *</RequiredText>}
    </Title>
  );
};
