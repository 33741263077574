import {
  DropdownMenuPrimitiveIndicator,
  DropdownMenuRadioItemWithoutIndicator,
} from "../../../../../../design-system/dropdown/dropdown.styled";

import React from "react";
import { Label, styledStitches } from "../../../../../../design-system";
import { ArrowUpIcon } from "../../../../../../design-system/icons";

import {
  selectMyClientsSortSlice,
  useMyClientsSortStore,
} from "../../../my-clients-model";
import { deriveSortState, MOST_RELEVANT } from "./client-card-sort.util";

interface MyClientsSortRadioItemProps {
  value: string;
  currentlySelectedSort: string;
  callback?: () => void;
}

export const ClientCardSortRadioItem = ({
  value,
  currentlySelectedSort,
  callback,
}: MyClientsSortRadioItemProps) => {
  const currentSortState = useMyClientsSortStore(selectMyClientsSortSlice);

  const setClientSortState = useMyClientsSortStore(
    (state) => state.setMyClientsSort,
  );

  const isMostRelevant = value === MOST_RELEVANT;

  return (
    <DropdownMenuRadioItemWithoutIndicator
      onSelect={() => {
        if (callback) {
          callback();
        }

        setClientSortState({
          ...deriveSortState(value, currentlySelectedSort, currentSortState),
        });
      }}
      value={value}
      css={{
        cursor: !isMostRelevant ? "pointer" : "auto",
        "&:active": !isMostRelevant
          ? {
              transform: "scale(0.98)",
            }
          : {},
      }}
    >
      <Label htmlFor={value} cursor="pointer">
        {value}
      </Label>

      {!isMostRelevant ? (
        <StyledSortRadioItemIndicator
          css={{
            transform:
              currentSortState.isSortingByFirstNameAscending ||
              currentSortState.isSortingByLastNameAscending
                ? "rotate(0deg)"
                : "rotate(180deg)",
          }}
        >
          <ArrowUpIcon height={16} width={16} />
        </StyledSortRadioItemIndicator>
      ) : null}
    </DropdownMenuRadioItemWithoutIndicator>
  );
};

export const StyledSortRadioItemIndicator = styledStitches(
  DropdownMenuPrimitiveIndicator,
  {
    position: "absolute",
    right: 8,
    top: 10,
    transition: "transform ease-in-out 0.25s",
    height: 16,
  },
);
