import { useMyClientsUser } from "@/app/my-clients/routes/my-clients-page/hooks/use-my-clients-user";
import {
  Button,
  Flex,
  H2,
  Stack,
  Text,
} from "../../../../../../../../design-system";
import Mangle from "../../../../../../../../_shared/Mangle";
import { serviceLineKey } from "../../../../../../../utils";
import { UpsertSessionAction } from "../session-actions/upsert-session-action/upsert-session-action";

interface UpcomingSessionsSectionHeaderProps {
  clientFirstName?: string;
  clientLastName?: string;
  serviceType?: string;
  firstSessionDate: string | null;
  clientId: number | undefined;
}

export const UpcomingSessionsSectionHeader = ({
  clientFirstName,
  clientLastName,
  serviceType,
  firstSessionDate,
  clientId,
}: UpcomingSessionsSectionHeaderProps) => {
  const headerText =
    serviceType && firstSessionDate
      ? `${clientFirstName} started ${serviceLineKey[serviceType]} on 
  ${firstSessionDate}.`
      : null;

  const { isClinicalLeaderOrSuperuser, isClinician } = useMyClientsUser();

  return (
    <Flex
      justifyContent={"space-between"}
      css={{ minHeight: 70 }}
      alignItems={"flex-start"}
    >
      <Stack gap={4}>
        <H2 fontWeight={700}>Upcoming sessions</H2>
        <Text>
          <Mangle>
            <Text>{headerText}</Text>
          </Mangle>
        </Text>
      </Stack>

      {isClinicalLeaderOrSuperuser || isClinician ? (
        <UpsertSessionAction
          mode="add"
          clientName={`${clientFirstName} ${clientLastName}`}
          clientId={clientId}
        >
          <Button
            css={{
              position: "absolute",
              right: 24,
            }}
          >
            Add new session
          </Button>
        </UpsertSessionAction>
      ) : null}
    </Flex>
  );
};
