import { styledStitches } from "../../styles/stitches.config";

export const FilledBackgroundCircle = styledStitches("div", {
  backgroundColor: "$neutral12",
  borderRadius: "50%",
  width: "24px",
  height: "24px",
  minWidth: "min-content",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  aspectRatio: "1 / 1",
  variants: {
    size: {
      small: {
        width: "16px",
        height: "16px",
      },
      medium: {
        width: "24px",
        height: "24px",
      },
    },

    color: {
      green: {
        backgroundColor: "$green3",
        color: "$green11",
      },
      yellow: {
        backgroundColor: "$yellow3",
        color: "$yellow11",
      },
      orange: {
        backgroundColor: "$orange3",
        color: "$orange11",
      },

      neutral: {
        backgroundColor: "$neutral5",
        color: "$neutral11",
      },

      red: {
        backgroundColor: "$red9",
        color: "$neutral0",
      },
      violet: {
        backgroundColor: "$violet11",
        color: "$violet11",
      },
    },
  },
});
