import React from "react";
import { AQMScoreBreakdown } from "../../../api/types";
import { Popover } from "antd";
import { startCase } from "lodash-es";
import { AQMMultiplicativeTuningParams } from "../../../api/types";

export const AQMScorePopover: React.FC<{
  breakdown: AQMScoreBreakdown | null;
  children?: any;
}> = (props) => {
  const { breakdown, children } = props;

  if (!breakdown) {
    return children;
  }

  const renderScore = (score: number) => {
    return Number(score.toFixed(2));
  };

  const content = (
    <div>
      {Object.entries(breakdown.components)
        .filter(([key, value]) => {
          if (
            (AQMMultiplicativeTuningParams as unknown as string[]).includes(key)
          ) {
            return renderScore(value) !== 1;
          }
          return renderScore(value) !== 0;
        })
        .map(([key, value]) => {
          return (
            <p key={key}>
              {startCase(key)}: {renderScore(value)}
            </p>
          );
        })}
    </div>
  );

  const total = renderScore(breakdown.score);
  const title = total === -1 ? "Weekly/Biweekly Mismatch" : `Total: ${total}`;

  return (
    <Popover content={content} title={title} placement="left">
      {children}
    </Popover>
  );
};
