import { Separator, styledStitches, Text } from "@/app/design-system";
import { QuarterCircle, Spinner } from "@/app/design-system";
import { useMyClientsUser } from "@/app/my-clients/routes/my-clients-page/hooks/use-my-clients-user";
import { DateTime } from "luxon";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { EventApiData } from "../../../../../../../../../../api/use-my-clients-overview";
import { recurringSessionsOptions } from "../../session-actions.constants";
import { UpcomingConflictsSection } from "./upcoming-conflicts/upcoming-conflicts";
import { Permissions } from "@/app/_helpers/permissions";
import {
  FutureSessionConflictEvent,
  FutureSessionConflictRecurrence,
} from "./upcoming-conflicts/use-future-conflicts.api";
import {
  SessionClinicianFormItem,
  SessionDateFormItem,
  SessionFormatFormItem,
  SessionRecurrenceFormItem,
  SessionsToSelectFormItem,
  SessionTimeFormItem,
} from "./upsert-session-form-items";
import {
  getSessionRecurrenceOptions,
  sessionFormatOptions,
  timeOptions,
} from "./upsert-session-form.constants";
import {
  UpsertSessionFieldNames,
  UpsertSessionFormFields,
} from "./use-upsert-session-form";
import { CurrentUserContext } from "@/app/app.utils";
import React from "react";

// placeholder
const StyledDrawerForm = styledStitches("form", {
  display: "flex",
  flexDirection: "column",
  gap: 28,
});

interface UpsertSessionFormProps {
  mode: "edit" | "add";
  formMethods: UseFormReturn<UpsertSessionFormFields, any>;
  event?: EventApiData;
  isLoading: boolean;
  isError: boolean;
  conflictingEvents: FutureSessionConflictEvent[] | undefined;
  conflictingRecurrences: FutureSessionConflictRecurrence[] | undefined;
  isRecurringEvent?: boolean;
}

export const UpsertSessionForm = ({
  mode,
  formMethods,
  isLoading,
  conflictingEvents,
  conflictingRecurrences,
  isError,
  event,
  isRecurringEvent,
}: UpsertSessionFormProps) => {
  const recurrenceText = event?.recurrenceData
    ? `Timeslot - ${DateTime.fromFormat(
        String(event?.recurrenceData?.isoDayOfWeek),
        "c",
      ).toFormat("cccc")}s at ${DateTime.fromISO(
        String(event?.startTime),
      ).toFormat("h a")}`
    : "";

  const currentUser = React.useContext(CurrentUserContext);


  const shouldShowSessionsToSelect =
    isRecurringEvent && mode === "edit" && !event?.recurrenceData?.isException;

  const { isClinicalLeaderOrSuperuser } = useMyClientsUser();

  const isNetworkClinician = Permissions.IsNetworkClinician(currentUser);

  const slicedTimeOptions = isNetworkClinician || isClinicalLeaderOrSuperuser ? timeOptions.slice(7, 21) : timeOptions.slice(7, 20);

  return (
    <FormProvider {...formMethods}>
      <StyledDrawerForm>
        {mode === "add" && isClinicalLeaderOrSuperuser && (
          <SessionClinicianFormItem
            name={UpsertSessionFieldNames.CLINICIAN}
            mode={mode}
          />
        )}
        {shouldShowSessionsToSelect && (
          <>
            <SessionsToSelectFormItem
              name={UpsertSessionFieldNames.SESSIONS_TO_UPDATE}
              options={recurringSessionsOptions}
              mode={"edit"}
            />
            <Separator />
          </>
        )}

        <SessionRecurrenceFormItem
          name={UpsertSessionFieldNames.RECURRENCE}
          options={getSessionRecurrenceOptions(mode)}
          mode={mode}
          recurrenceText={recurrenceText}
        />

        <Separator />

        <SessionDateFormItem
          name={UpsertSessionFieldNames.SESSION_DATE}
          mode={mode}
        />

        <SessionTimeFormItem
          name={UpsertSessionFieldNames.SESSION_TIME}
          options={slicedTimeOptions}
          mode={mode}
        />
      </StyledDrawerForm>

      <UpcomingConflictsSection
        conflictingEvents={conflictingEvents}
        conflictingRecurrences={conflictingRecurrences}
        event={event}
      />

      {isLoading ? (
        <Spinner>
          <QuarterCircle dimension={"2em"} />
        </Spinner>
      ) : null}

      {isError ? (
        <Text>There was an issue finding upcoming conflicts.</Text>
      ) : null}
    </FormProvider>
  );
};
