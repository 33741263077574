export enum RecurringSessionsOptions {
  THIS_SESSION_ONLY = "This session only",
  ALL_FUTURE_RECURRING = "This session and all future recurring sessions",
}

export const recurringSessionsOptions = [
  {
    id: "1",
    label: RecurringSessionsOptions.THIS_SESSION_ONLY,
    value: RecurringSessionsOptions.THIS_SESSION_ONLY,
  },
  {
    id: "2",
    label: RecurringSessionsOptions.ALL_FUTURE_RECURRING,
    value: RecurringSessionsOptions.ALL_FUTURE_RECURRING,
  },
];