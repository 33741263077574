import React, { useMemo, useCallback } from "react";
import { Time, Day } from "../../../api/types";
import {
  BoxColor,
  ClickHeader,
  ClickBox,
  ActiveBoxColor,
  TableWrapper,
} from "./styled";
import { generateDefaultDayTimes, days, times } from "../../_helpers/constants";
import { Button } from "antd";
import { Row } from "../../_layout/Flex";
import moment from "moment";

export interface CheckboxItem {
  time: Time;
  day: Day;
  boxColor: BoxColor;
}
export interface ActiveCheckboxItem extends CheckboxItem {
  boxColor: ActiveBoxColor;
}
interface CheckboxTableProps {
  activeCheckboxes: ActiveCheckboxItem[];
  onChange: (updatedCheckboxes: ActiveCheckboxItem[]) => void;
  greenBoxText?: string;
  yellowBoxText?: string;
}
const filterFnByDay = (day: Day) => (b: CheckboxItem) => b.day === day;
const filterFnByTime = (time: Time) => (b: CheckboxItem) => b.time === time;

/*
    It is up to the parent component to update the box pool using the onChange function prop.
*/

const ClickboxTable = ({
  activeCheckboxes,
  onChange,
  greenBoxText,
  yellowBoxText,
}: CheckboxTableProps) => {
  const checkboxes: CheckboxItem[] = useMemo(
    () =>
      generateDefaultDayTimes().map((dt) => ({
        ...dt,
        boxColor:
          activeCheckboxes.find(
            (box) => box.time === dt.time && box.day === dt.day,
          )?.boxColor || null,
      })),
    [activeCheckboxes],
  );

  const boxClicked = useCallback(
    (day: Day | null, time: Time | null) => {
      const currentBoxes = [...checkboxes];

      let filterFn = (b: CheckboxItem) => b.day === day && b.time === time;
      if (!day && !time) {
        filterFn = (b: CheckboxItem) => true;
      } else if (!day) {
        filterFn = filterFnByTime(time!);
      } else if (!time) {
        filterFn = filterFnByDay(day!);
      }

      const boxesToUpdate = currentBoxes.filter(filterFn);
      // if all are 'green' swap all to null
      // else swap all to 'green' (default newColor value)
      const newColor: BoxColor = boxesToUpdate.every(
        (b) => b.boxColor === "green",
      )
        ? null
        : "green";
      const updatedBoxes = currentBoxes.map((b) =>
        filterFn(b) ? { ...b, boxColor: newColor } : b,
      );
      const updatedActiveBoxes = updatedBoxes.filter(
        (b) => b.boxColor !== null,
      ) as ActiveCheckboxItem[];

      return onChange(updatedActiveBoxes);
    },
    [checkboxes, onChange],
  );
  const getBoxColor = useCallback(
    (day: Day, time: Time): BoxColor =>
      checkboxes.find((b) => b.day === day && b.time === time)!.boxColor,
    [checkboxes],
  );

  const getBoxText = useCallback(
    (day: Day, time: Time): string => {
      const color = getBoxColor(day, time);
      let text = "";
      if (color === "green") {
        text = greenBoxText || "";
      }
      if (color === "yellow") {
        text = yellowBoxText || "";
      }
      return text;
    },
    [getBoxColor, greenBoxText, yellowBoxText],
  );

  const getStatusOfBoxGroup = (
    boxGroup: CheckboxItem[],
  ): "all" | "some" | "none" =>
    boxGroup.every((b) => b.boxColor !== null)
      ? "all"
      : boxGroup.some((b) => b.boxColor !== null)
      ? "some"
      : "none";

  const dayCoverage = useCallback(
    (day: Day): "all" | "some" | "none" =>
      getStatusOfBoxGroup(checkboxes.filter(filterFnByDay(day))),
    [checkboxes],
  );

  const timeCoverage = useCallback(
    (time: Time): "all" | "some" | "none" =>
      getStatusOfBoxGroup(checkboxes.filter(filterFnByTime(time))),
    [checkboxes],
  );

  const allCoverage = useCallback(
    (): "all" | "some" | "none" => getStatusOfBoxGroup(checkboxes),
    [checkboxes],
  );

  const invertSelections = useCallback(() => {
    const currentBoxes = [...checkboxes];
    const updatedBoxes = currentBoxes.map((b) => ({
      ...b,
      // 'green' => null & null => 'green'; 'yellow' => 'yellow'
      boxColor: b.boxColor === "green" ? null : "green",
    }));
    const updatedActiveBoxes = updatedBoxes.filter(
      (b) => b.boxColor !== null,
    ) as ActiveCheckboxItem[];
    return onChange(updatedActiveBoxes);
  }, [checkboxes, onChange]);

  return (
    <TableWrapper>
      <Row layout="start stretch">
        <ClickHeader
          key={"all"}
          onClick={() => boxClicked(null, null)}
          coverage={allCoverage()}
          label={"All"}
        />
        {days.map((day) => (
          <ClickHeader
            key={day + "header"}
            onClick={() => boxClicked(day, null)}
            coverage={dayCoverage(day)}
            label={day}
          />
        ))}
      </Row>
      {times.map((time) => (
        <Row layout="start stretch" key={time + "header"}>
          <ClickHeader
            onClick={() => boxClicked(null, time)}
            coverage={timeCoverage(time)}
            label={moment(time, "HH:mm:ss").format("ha")}
          />
          {days.map((day) => (
            <ClickBox
              key={day + time}
              onClick={() => boxClicked(day, time)}
              boxColor={getBoxColor(day, time)}
              text={getBoxText(day, time)}
            />
          ))}
        </Row>
      ))}
      <Row layout={"end start"} style={{ marginTop: "3px" }}>
        <Button size="small" onClick={() => invertSelections()}>
          Invert Selections
        </Button>
      </Row>
    </TableWrapper>
  );
};

export default ClickboxTable;
