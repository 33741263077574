import { styled } from "@stitches/react";

const StyledModalTable = styled("table", {
  fontFamily: "Inter, arial",
  borderCollapse: "separate",
  borderStyle: "hidden",
  borderSpacing: 0,
  width: "100%",
  variants: {
    borderShape: {
      rounded: {
        borderRadius: "4px",
        "& th:first-of-type": {
          borderTopLeftRadius: "4px",
        },
        "& th:last-of-type": {
          borderTopRightRadius: "4px",
        },
        "& tr:last-of-type>td:first-of-type": {
          borderBottomLeftRadius: "4px",
        },
        "& tr:last-of-type>td:last-of-type": {
          borderBottomRightRadius: "4px",
        },
      },
    },
  },
});
const StyledModalTableHeader = styled("th", {
  backgroundColor: "$neutral4",
  fontWeight: 700,
  padding: "10px",
  border: "1px solid $neutral6",
  textAlign: "start",
  borderTop: "2px solid $neutral6",
  borderLeft: "2px solid $neutral6",
  borderRight: "none",
  "&:last-of-type": {
    borderRight: "2px solid $neutral6",
  },
});

const StyledModalTableRow = styled("tr", {
  "& td:not(:last-of-type)": {
    borderLeft: "2px solid $neutral6",
  },
  "& td:last-of-type": {
    borderLeft: "2px solid $neutral6",
    borderRight: "2px solid $neutral6",
  },
  "&:not(:last-of-type) td": {
    borderBottom: "2px solid $neutral6",
  },
  "&:last-of-type td": {
    borderBottom: "2px solid $neutral6",
  },
  padding: "10px",
});

const StyledModalTableData = styled("td", {
  padding: "10px",
});

export const ModalTable = StyledModalTable;
export const ModalTableRow = StyledModalTableRow;
export const ModalTableHeader = StyledModalTableHeader;
export const ModalTableData = StyledModalTableData;
