export const preferences_options = [
  {
    key: "OPTION__gender",
    value: "gender",
    title: "Gender",
  },
  {
    key: "OPTION__age",
    value: "age",
    title: "Age",
  },
  {
    key: "OPTION__race_ethnicity",
    value: "race_ethnicity",
    title: "Race/Ethnicity",
  },
];

export const intensity_options = [
  {
    key: "OPTION__slight",
    value: "slight",
    title: "Slight",
  },
  {
    key: "OPTION__moderate",
    value: "moderate",
    title: "Moderate",
  },
  {
    key: "OPTION__strong",
    value: "strong",
    title: "Strong",
  },
  {
    key: "OPTION__na",
    value: "na",
    title: "N/A",
  },
];
