import { EventRecurrenceApiData } from "../../../../../../../../../api/use-my-clients-overview/my-clients-data.interface";
import { Flex } from "../../../../../../../../../design-system/index";
import { SessionCadenceLabel } from "../../3-session-card/session-cadence-label";
import { SessionTime } from "../../3-session-card/session-time-details";
import { SessionPaneComposedIcon } from "./components/sesssion-panel-composed-icon";

interface SessionMetaDataProps {
  appointmentStatus: string | null;
  recurrenceData: EventRecurrenceApiData | null;
  startTime: string;
}

export const SessionMetaData = ({
  appointmentStatus,
  startTime,
  recurrenceData,
}: SessionMetaDataProps) => {
  return (
    <Flex
      gap={12}
      css={{
        flexGrow: 1,
        maxWidth: 380,
      }}
      alignItems={"center"}
    >
      <SessionPaneComposedIcon
        appointmentStatus={appointmentStatus ?? "none"}
      />
      <SessionTime appointmentDatetime={startTime} color={"$neutral11"} />
      <SessionCadenceLabel
        sessionCadence={recurrenceData?.recurrence}
        isException={recurrenceData?.isException}
      />
    </Flex>
  );
};
