import { Text } from "../../../../../../../../../../design-system/index";

interface SessionStatus {
  appointmentStatus: string | null;
}

export const SessionStatus = ({ appointmentStatus }: SessionStatus) => {
  return (
    <>
      {appointmentStatus === "noshow" && (
        <Text color={"$red11"} fontWeight={700}>
          No show (missed session)
        </Text>
      )}

      {appointmentStatus === "canceled" && (
        <Text fontWeight={700}>Canceled</Text>
      )}

      {appointmentStatus === "attended" && (
        <Text fontWeight={700}>Attended</Text>
      )}
    </>
  );
};
