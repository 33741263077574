import { styledStitches } from "@/app/design-system";
import { TwoChairsHeaderImageLarge } from "@/app/design-system/assets/two-chairs-header-image-large";
import { TwoChairsHeaderImageSmall } from "@/app/design-system/assets/two-chairs-header-image-small";
import { FOCUS_STYLES } from "@/app/design-system/styles/config/css-utils";

import { Link } from "react-router-dom";

export function TwoChairsHeaderLogo({ isCollapsed }: { isCollapsed: boolean }) {
  const linkText = "To Home";
  return (
    <LogoLink
      to="/dashboard"
      style={{ cursor: "pointer", width: "100%", textAlign: "center" }}
    >
      {isCollapsed ? (
        <TwoChairsHeaderImageSmall title={linkText} />
      ) : (
        <TwoChairsHeaderImageLarge title={linkText} />
      )}
    </LogoLink>
  );
}

const LogoLink = styledStitches(Link, { ...FOCUS_STYLES });
