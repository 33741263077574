import { createSelector } from "reselect";
import { Clinic, Subregion } from "../../../api/types";
import { payloadAction, actionFactory, ActionUnion } from "reductser";
import produce from "immer";
import { assertUnreachable, AsyncOperations } from "../types";
import api, { wrapApiCall } from "../../../api";
import { AxiosResponse } from "axios";
import { filterActions } from "../filters";

const actionMap = {
  loadSubregion: payloadAction<Subregion>(),
  loadClinic: payloadAction<Clinic>(),
};

export const clinicActions = actionFactory(actionMap, "CLINICS");

export type ClinicActions = ActionUnion<typeof clinicActions>;

export interface ClinicState {
  ids: number[];
  clinicMap: { [id: number]: Clinic };
  subregionIds: number[];
  subregionMap: { [id: number]: Subregion };
}

function getInitialState(): ClinicState {
  return {
    ids: [],
    clinicMap: {},
    subregionIds: [],
    subregionMap: {},
  };
}

const reducer = (state = getInitialState(), action: ClinicActions) =>
  produce(state, draft => {
    if (action.reducer === "CLINICS") {
      switch (action.type) {
        case "loadSubregion": {
          const { id } = action.payload;
          if (draft.subregionIds.indexOf(id) === -1) {
            draft.subregionIds.push(id);
          }
          draft.subregionMap[id] = action.payload;
          return;
        }
        case "loadClinic": {
          const { id } = action.payload;
          if (draft.ids.indexOf(id) === -1) {
            draft.ids.push(id);
          }
          draft.clinicMap[id] = action.payload;
          return;
        }
        default:
          assertUnreachable(action);
      }
    }
  });

export default reducer;

export const clinicOperations: AsyncOperations = {
  getClinics: () => async dispatch => {
    const response: AxiosResponse<Clinic[]> = await wrapApiCall(
      api.get("/ehr/clinics/"),
      dispatch,
    );
    const clinicIds: number[] = [];
    response.data.forEach(clinic => {
      dispatch(clinicActions.loadClinic(clinic));
      dispatch(
        filterActions.setClinicFilter({
          clinicId: clinic.id,
          toggle: true,
        }),
      );
      clinicIds.push(clinic.id);
    });
  },
  getSubregions: () => async dispatch => {
    try {
      const response: AxiosResponse<Subregion[]> = await wrapApiCall(
        api.get("/ehr/subregions/"),
        dispatch,
      );
      response.data.forEach((s: Subregion) =>
        dispatch(clinicActions.loadSubregion(s)),
      );
    } catch (e) {
      console.error("Failed to get Subregions", e);
    }
  },
};

const clinicIdSelector = (state: ClinicState) => state.ids;
const clinicMapSelector = (state: ClinicState) => state.clinicMap;

export const clinicListSelector = createSelector(
  clinicIdSelector,
  clinicMapSelector,
  (ids, map) => ids.map(id => map[id]),
);
