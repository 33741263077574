import { Spin } from "antd";
import * as React from "react";
import * as Sentry from "@sentry/react";
import * as ReactRedux from "react-redux";
import { Navigate } from "react-router-dom";

import { $night } from "../../assets/colors";

import { UserHasAnyPermissions, UserPermission } from "../_helpers/permissions";
import { authOperations } from "../../state/models/auth";
import { AnchorButton } from "../_shared/AnchorButton";
import { useShallowEqualSelector } from "../_helpers/redux";
import { LoggingInDiv } from "../login/logging-in-div";
import { MainContent } from "../_shared/MainContent";

interface PrivateRouteContentProps {
  children: React.ReactElement;
  permissions?: UserPermission[];
}

export const PrivateRouteContent = ({
  permissions,
  children,
}: PrivateRouteContentProps) => {
  const [currentUser, isAuthenticated] = useShallowEqualSelector((state) => [
    state.auth.currentUser,
    state.auth.isAuthenticated,
  ]);

  const dispatch = ReactRedux.useDispatch();

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  if (!currentUser) {
    return (
      <LoggingInDiv>
        <Spin size="large" />
        <h2>Logging in...</h2>
      </LoggingInDiv>
    );
  } else {
    Sentry.setUser({
      email: currentUser.email,
      clinicianId: currentUser.clinician?.id,
    });
  }

  if (permissions) {
    if (!UserHasAnyPermissions(currentUser, [...permissions, "IsSuperUser"])) {
      return (
        <LoggingInDiv>
          <h1>404</h1>
          <h3 style={{ textAlign: "center" }}>
            You may not have permissions to view this page.
            <p>
              If you believe this is an error, please try
              <AnchorButton
                onClick={() => dispatch(authOperations.logout())}
                style={{ color: $night }}
              >
                {" "}
                logging in again{" "}
              </AnchorButton>
              or contact{" "}
              <a href="mailto:product@twochairs.com" style={{ color: $night }}>
                the product team.
              </a>
            </p>
          </h3>
        </LoggingInDiv>
      );
    }
  }

  return <MainContent>{children}</MainContent>;
};
