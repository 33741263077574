import { ClientOverviewQuery } from "@/graphql/generated";
import { selectDataFromSearchForMyClients } from "../../../../client-profile-page/api/use-client-overview/select-data-for-client-profile.util";
import { useClientOverview } from "../../../../client-profile-page/api/use-client-overview/use-client-overview";

interface UseClientOverviewInternalParams {
  clinicianId: number;
  currentlySelectedClinician: number | null;
  clientIds: number[];
  isClinicalLeaderOrSuperuser: boolean | null;
}

export const useClientOverviewInternal = ({
  clinicianId,
  clientIds,
  isClinicalLeaderOrSuperuser,
  currentlySelectedClinician,
}: UseClientOverviewInternalParams) => {
  // This query will not run unless  and there's a clinicianId to query by.
  // clientIds.length > 0 means the user is searching for a client with a
  // search term.
  //
  // This query will only run when the logged in when isClinicalLeaderOrSuperuser is true
  // since this query searches for ALL clients
  //
  // If the logged in user is a normal clinician, when they search in the client search bar,
  // filter results return only clients that are in their panel, coming from useMyClientsOverview()
  const enabled =
    !!clinicianId && !!isClinicalLeaderOrSuperuser && clientIds.length > 0;

  const { data: clientsOverviewData, isLoading: isClientOverviewLoading } =
    useClientOverview({
      enabled,
      clientIds,
      select: (data: ClientOverviewQuery) =>
        selectDataFromSearchForMyClients(
          data,
          currentlySelectedClinician ?? undefined,
        ),
    });

  return {
    isClientOverviewLoading,
    clientsOverviewData,
  };
};
