import { IconProps } from "../icon.interface";

export const BarGraphIcon = ({
  fill = "#171717",
  width = 25,
  height = 24,
  title,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 24"
    fill="none"
    aria-hidden={!title}
  >
    {title ? <title>{title}</title> : null}
    <path
      d="M12.2222 20V10"
      stroke={fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.2222 20V4"
      stroke={fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.22223 20V16"
      stroke={fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
