import styled from "styled-components";
import tinycolor from "tinycolor2";
import {
  $atShade1,
  $blue,
  $couplesColor,
  $border,
  $green,
  $pink,
} from "../../../assets/colors";
import { EventCellType, ReservationType, SlotEventType } from "../types";
import { DisplaySide } from "./types";

export const CellLabel = styled.div`
  white-space: nowrap;
  font-size: 10pt;
`;

export const CellContent = styled.div`
  margin: 0;
  padding: 0;
`;
export const ActionsContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 60px;

  transition: all 200ms ease;
  opacity: 0;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 16px;
  padding-right: 8px

  background: linear-gradient(
    to left,
    rgba(0, 0, 0, 0.15) 30%,
    rgba(0, 0, 0, 0)
  );
`;

export const CellContainer = styled.div<{
  displaySide: DisplaySide;
  eventType: EventCellType | SlotEventType;
  slotReservationType?: ReservationType;
  colorRatio?: number;
  rowIndex?: number;
}>`
  border: 0px solid
    ${props =>
      tinycolor(
        getColorForEventType(props.eventType, props.slotReservationType),
      )
        .darken(33)
        .toRgbString()};
  background-color: ${props =>
    tinycolor(
      getColorForEventType(props.eventType, props.slotReservationType),
    ).toRgbString()};

  padding: 4px;
  margin: 1px 2px;
  position: relative;

  ${props => {
    switch (props.displaySide) {
      case "left":
        return "margin-right: 2px;";
      case "right":
        return "margin-left: 1px;";
      case "both":
        return;
    }
  }}

  grid-column: ${props => {
    switch (props.displaySide) {
      case "left":
        return "1";
      case "right":
        return "2";
      case "both":
        return "1 / span 2";
    }
  }};

  ${props =>
    props.rowIndex &&
    `
    grid-row: ${props.rowIndex};
  `}

  overflow: hidden;
  transition: all 200ms ease;
  &:hover,
  &:active {
    > ${ActionsContainer} {
      opacity: 1 !important;
    }
  }
  z-index: 5;
`;

function getColorForEventType(
  eventType: EventCellType | SlotEventType,
  slotReservationType?: ReservationType,
) {
  switch (eventType) {
    case "therapy":
      return $atShade1;
    case "consult":
    case "reservation":
      return slotReservationType === ReservationType.Consult
        ? tinycolor($blue)
        : slotReservationType === ReservationType.Couples
        ? tinycolor($pink)
        : $atShade1;
    case "couples":
      return $couplesColor;
    case "block":
    case "closed":
      return $border;
    case "blank":
      return "rgba(0, 0, 0, 0)";
    default:
      return $green;
  }
}
