import { matchSorter } from "match-sorter";
import { useMemo } from "react";
import { useThrottle } from "../../../hooks/use-throttle";

interface UseMatchParams {
  term: string;
  data: any[];
  keys: ((item: any) => string)[];
  throttle: number;
}

export const useMatch = ({
  term,
  data,
  keys,
  throttle = 0,
}: UseMatchParams) => {
  const throttledTerm = useThrottle(term, throttle);

  return useMemo(
    () =>
      term.trim() === ""
        ? null
        : matchSorter(data, throttledTerm, {
            keys,
          }),
    [term],
  );
};
