import React, { useState } from "react";

import { Modal, Select as DeprecatedAntdSelect, Checkbox, Spin } from "antd";
import { $red } from "../../assets/colors";
import {
  DateTimeRangePicker,
  DateTimeRange,
} from "../_shared/FormControls/DateTimeRangePicker";
import moment from "moment";
import { PtoTypeChoices } from "./_shared";
import { Ehr_Timeoff } from "@/graphql/generated";
import { useUpdateTimeoffMutation } from "./api/use-get-timeoff/use-timeoff-mutations";
import { useQueryClient } from "react-query";
import { DateTime } from "luxon";
import { TimeoffsQueryKey } from "./api/use-get-timeoff/use-get-timeoff";

interface Props {
  editingTimeOff: Ehr_Timeoff;
  onClose: () => void;
}

function EditTimeOffModal(props: Props) {
  const { id, ehr_clinician, start_at, end_at, pto_type } =
    props.editingTimeOff;

  const clinician_name = ehr_clinician.full_name as string;
  const clinician_tz = ehr_clinician.primary_timezone;

  const defaultRange = [
    moment(start_at).tz(clinician_tz),
    moment(end_at).tz(clinician_tz),
  ] as DateTimeRange;
  const queryClient = useQueryClient();
  const updateTimeoff = useUpdateTimeoffMutation();

  const [range, setRange] = useState(defaultRange);
  const [reason, setReason] = useState(pto_type);
  const [error, setError] = useState("");

  const handleOk = () => {
    if (!range || !reason) {
      setError("Please fill out all of the fields");
      return;
    } else {
      setError("");
    }
    const [startAt, endAt] = range;

    updateTimeoff.mutate(
      {
        id,
        // replace the timezone with the clinician's timezone, while preserving the time
        // so if the UI shows 5pm, it's 5pm in the clinician's timezone, regardless of what the user's localtime is
        startAt: DateTime.fromISO(startAt.format()).setZone(clinician_tz),
        endAt: DateTime.fromISO(endAt.format()).setZone(clinician_tz),
        ptoType: reason,
      },
      {
        onSettled: async () => {
          queryClient.invalidateQueries([TimeoffsQueryKey]);
          props.onClose();
        },
      },
    );
  };

  return (
    <Modal
      title={`Update Time Off For ${clinician_name}`}
      onOk={handleOk}
      onCancel={props.onClose}
      open
    >
      <div style={{ display: "flex", marginBottom: "15px" }}>
        <DateTimeRangePicker
          defaultValue={defaultRange}
          timezone={clinician_tz}
          onChange={setRange}
          minuteStep={30}
        />
      </div>
      <div>Note: Times will be inputted in the clinician's timezone.</div>
      <div style={{ display: "flex", alignItems: "center", marginTop: "16px" }}>
        <DeprecatedAntdSelect
          defaultValue={reason}
          onChange={setReason}
          placeholder="Select Reason:"
          style={{ marginLeft: "7.5px", flex: 1 }}
        >
          {Object.entries(PtoTypeChoices).map((type: [string, string]) => (
            <DeprecatedAntdSelect.Option key={type[0]} value={type[0]}>
              {type[1]}
            </DeprecatedAntdSelect.Option>
          ))}
        </DeprecatedAntdSelect>
      </div>
      {error ? (
        <div style={{ paddingTop: "15px", color: $red }}>{error}</div>
      ) : null}
    </Modal>
  );
}

export default EditTimeOffModal;
