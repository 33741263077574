import {
  SessionHistoryTimeRange,
  useMyClientsScheduleStore,
} from "../../../../../my-clients-schedule-model";
import { selectSessionHistoryTimeRangeFiltersSlice } from "../../../../../my-clients-schedule-model/my-clients-schedule.model";

export const useSessionHistoryTimeFiltersDropdown = () => {
  const { timeRangeToFilterOn, setSessionHistoryTimeRangeFilter } =
    useMyClientsScheduleStore(selectSessionHistoryTimeRangeFiltersSlice);

  const setFilter = (value: string) => {
    setSessionHistoryTimeRangeFilter(value as SessionHistoryTimeRange);
  };

  return {
    timeRangeToFilterOn,
    setFilter,
  };
};
