import { SearchClientsQuery } from "../../../../graphql/generated";

export const selectDataForSearchBar = (
  data: SearchClientsQuery,
  clinicianId: number,
) => {
  const clients = data.f_search_clients;

  const clinicianPanelClients = clients.filter(
    (client) =>
      !!client.matches.find(
        (match) => match.matchMetaData?.matchedClinicianId === clinicianId,
      ),
  );

  const notClinicianPanelClients = clients.filter(
    (client) =>
      !!client.matches.find(
        (match) => match.matchMetaData?.matchedClinicianId !== clinicianId,
      ),
  );

  const noMatchingDataClients = clients.filter(
    (client) => client?.matches?.length === 0,
  );

  return [
    ...clinicianPanelClients,
    ...notClinicianPanelClients,
    ...noMatchingDataClients,
  ];
};
