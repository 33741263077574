import {
  GroupTherapyClientMbcSurveyAnswersQuery,
  GroupTherapySurveyResponseDataFragment,
  GroupTherapySurveyResponseEventDataFragment,
} from "@/graphql/generated";
import { DateTime } from "luxon";

interface ParsedSurveyResponses {
  phq?: SurveyDateAndResponses;
  gad?: SurveyDateAndResponses;
  cohesion?: SurveyDateAndResponses;
}

export interface SurveyDateAndResponses {
  dateAnswered?: string;
  sessionNumber?: number;
  responses?: QuestionAndAnswer[];
}

export interface QuestionAndAnswer {
  questionText?: string | null;
  answerText?: string | null;
  questionNumber?: string | null;
}

export const selectGroupTherapyClientMBCSurveyData = (
  groupTherapyClientSurveyAnswerData: GroupTherapyClientMbcSurveyAnswersQuery,
): ParsedSurveyResponses => {
  if (!groupTherapyClientSurveyAnswerData) {
    return {};
  }

  const events = groupTherapyClientSurveyAnswerData.group[0].events;
  const sortedEventsDescending = sortEventsDescending(events).filter(
    (event) => event.appointmentStatus === "attended",
  );

  return {
    phq: getSurveyQuestionAndAnswers(sortedEventsDescending, "phq9"),
    gad: getSurveyQuestionAndAnswers(sortedEventsDescending, "gad7"),
    cohesion: getSurveyQuestionAndAnswers(
      sortedEventsDescending,
      "group_cohesion",
    ),
  };
};

const getSurveyQuestionAndAnswers = (
  sortedEventsDescending: GroupTherapySurveyResponseEventDataFragment[],
  surveyName: string,
): SurveyDateAndResponses => {
  const answeredSurveyEvent = findMostRecentEventWithAnsweredSurvey(
    sortedEventsDescending,
    surveyName,
  );

  const surveySessionNumber = answeredSurveyEvent
    ? sortedEventsDescending.length -
      sortedEventsDescending.indexOf(answeredSurveyEvent)
    : undefined;

  const answeredSurveyDate = answeredSurveyEvent?.eventStartTime;
  const surveyScores = answeredSurveyEvent?.clientGroupEvents[0]?.ems[0]?.score;
  const selectedSurvey = surveyScores?.find(
    (score) => score.scoreName === surveyName,
  );

  let questionAndAnswerArray: QuestionAndAnswer[] = [];
  questionAndAnswerArray = orderedQuestions(
    getQuestionAndAnswers(
      questionAndAnswerArray,
      selectedSurvey?.surveyResponse,
    ),
  );

  if (surveyName === "phq9" && selectedSurvey?.isEndorsedSI) {
    const siSurvey = surveyScores?.find((score) => score.scoreName === "si");
    if (siSurvey) {
      let SIQuestionAndAnswerArray: QuestionAndAnswer[] = [];
      SIQuestionAndAnswerArray = orderedQuestions(
        getQuestionAndAnswers(questionAndAnswerArray, siSurvey?.surveyResponse),
      );
      questionAndAnswerArray = [
        ...questionAndAnswerArray,
        ...SIQuestionAndAnswerArray,
      ];
    }
  }

  return {
    dateAnswered: answeredSurveyDate,
    sessionNumber: surveySessionNumber,
    responses: questionAndAnswerArray,
  };
};

const getQuestionAndAnswers = (
  questionAndAnswerArray: QuestionAndAnswer[],
  surveyData?: GroupTherapySurveyResponseDataFragment | null,
): QuestionAndAnswer[] => {
  surveyData?.generalSurveyQuestionResponses.forEach((response) => {
    questionAndAnswerArray.push({
      questionText: response.generalSurveyQuestion.questionText,
      answerText: response.response,
      questionNumber: response?.generalSurveyQuestion?.slug?.split("_").pop(),
    });
  });
  return questionAndAnswerArray;
};

export const orderedQuestions = (questions: QuestionAndAnswer[]) => {
  return questions.sort(compareSlugs);
};

function compareSlugs(x: QuestionAndAnswer, y: QuestionAndAnswer) {
  if (x.questionNumber && y.questionNumber) {
    const x_index = Number(x);
    const y_index = Number(y);
    return x_index - y_index;
  }
  return 0;
}

const findMostRecentEventWithAnsweredSurvey = (
  sortedEventsDescending: GroupTherapySurveyResponseEventDataFragment[],
  surveyName: string,
): GroupTherapySurveyResponseEventDataFragment | undefined => {
  return sortedEventsDescending.find((event) => {
    const scores = event.clientGroupEvents[0]?.ems[0]?.score;
    return (
      scores &&
      scores.length !== 0 &&
      scores.find((score) => score.scoreName === surveyName)
    );
  });
};

const sortEventsDescending = (
  events: GroupTherapySurveyResponseEventDataFragment[],
) => {
  return events.sort(
    (a, b) =>
      DateTime.fromISO(b.eventStartTime).toMillis() -
      DateTime.fromISO(a.eventStartTime).toMillis(),
  );
};
