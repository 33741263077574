import { ModalTableData, ModalTableRow } from "@/app/design-system";
import { TIME_TAG_FORMAT } from "@/app/_helpers/datetime";
import { ScreenReaderOnlyText } from "@/app/_shared/ScreenReaderOnlyText";
import { DateTime } from "luxon";
import {
  EOCClinicianData,
  EOCClinicianSubEpisodeSummary,
} from "../../../../../types";
import { EOCClinicianModalTableCell } from "./eoc-clinician-modal-table-cell";

// We need both of these because the table that this row is a part of will most likely not have consecutive rows of the same clinician and episode with separate subEpisodes (since the point of these is that they were interrupted by another clinician with a gap of at least 60 days)
export interface EOCClinicianModalTableRowProps {
  eocClinician: EOCClinicianData;
  subEpisodeSummary: EOCClinicianSubEpisodeSummary;
  isLastRow: boolean;
}

export const EOCClinicianModalTableRow = (
  props: EOCClinicianModalTableRowProps,
) => {
  const { subEpisodeSummary, eocClinician, isLastRow } = props;
  const eocClinicianLocalizedStartTime = DateTime.fromISO(
    subEpisodeSummary.startDate,
  );
  const eocClinicianLocalizedEndTime = DateTime.fromISO(
    subEpisodeSummary.endDate,
  );

  const today = DateTime.now();
  const isEndDateOutsideCurrentEOCRange =
    today.diff(DateTime.fromISO(subEpisodeSummary.endDate), "days").days > 60;
  return (
    <ModalTableRow>
      <ModalTableData>{eocClinician.clinicianName}</ModalTableData>
      <ModalTableData>
        <time
          dateTime={eocClinicianLocalizedStartTime.toFormat(TIME_TAG_FORMAT)}
        >
          {eocClinicianLocalizedStartTime.toLocaleString()}
        </time>{" "}
        -{" "}
        {isLastRow && !isEndDateOutsideCurrentEOCRange ? (
          <>present</>
        ) : (
          <time
            dateTime={eocClinicianLocalizedEndTime.toFormat(TIME_TAG_FORMAT)}
          >
            {eocClinicianLocalizedEndTime.toLocaleString()}
          </time>
        )}
      </ModalTableData>
      <ModalTableData>{subEpisodeSummary.totalSessions}</ModalTableData>
      <EOCClinicianModalTableCell subEpisodeSummary={subEpisodeSummary} />
    </ModalTableRow>
  );
};
