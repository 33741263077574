import React, { useMemo, useState } from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import { Title } from "../../../_layout/display";
import {
  matchmakerActions,
  matchmakerUtils,
} from "../../../../state/models/matchmaker";
import { Row } from "../../../../app/_layout/Flex";
import { Pad } from "../../../../app/_layout/display";
import { CheckOutlined } from "@ant-design/icons";
import { Button, Divider, Input, DatePicker, Checkbox } from "antd";
import FitList from "../../FitList";
import { FitSuggestion, IdMap, MatchTag } from "../../../../api/types";
import ClinicianStatus from "../../../_shared/ClinicianStatus";
import { useShallowEqualSelector } from "../../../_helpers/redux";
import { useUserHasAnyPermissions } from "../../../_helpers/permissions";
import {
  NEEDS_TAG_WEIGHT,
  BENEFIT_TAG_WEIGHT,
  TagWeight,
} from "../../../../api/constants";
import { GroupsEnrollment } from "./GroupsEnrollment";

export default function MatchStep() {
  const [
    tagIdsByWeight,
    matchDraft,
    selectedClinicianId,
    tagMap,
    starredTagIds,
    isGroupsAppropriateMatch,
    methodologyVersion
  ] = useShallowEqualSelector((state) => [
    state.matchmaker.matchTagMap,
    state.matchmaker.draft,
    state.matchmaker.selectedClinicianId,
    state.matches.tagMap,
    state.matchmaker.starredTagIds,
    state.matchmaker.groupsMatchData.isGroupsAppropriateMatch,
    state.matchmaker.methodologyVersion,
  ]);

  const matchTagMap: IdMap<MatchTag> = useMemo(() => {
    const map: IdMap<MatchTag> = {};
    ([NEEDS_TAG_WEIGHT, BENEFIT_TAG_WEIGHT] as TagWeight[]).forEach(
      (weight) => {
        tagIdsByWeight[weight].forEach((tagId: number) => {
          map[tagId] = {
            ...tagMap[tagId],
            weight,
            starred: starredTagIds.includes(tagId),
          };
        });
      },
    );
    return map;
  }, [tagIdsByWeight, tagMap, starredTagIds]);

  const {
    fits,
    outOfAgeRangeFits,
    excludedFits,
    conflictOfInterestFits,
    nonKpFits,
  } = useShallowEqualSelector(matchmakerUtils.getFilteredFits);

  const dispatch = useDispatch();
  const updateMatchDraft = (
    value: string,
    matchDraftKey:
      | "queue_note"
      | "referral_note"
      | "conflict_of_interest"
      | "match_note",
  ) => {
    dispatch(matchmakerActions.patchMatchDraft({ [matchDraftKey]: value }));
  };
  const updateMatchAfter = (value: string | null) => {
    dispatch(matchmakerActions.patchMatchDraft({ match_after: value }));
  };
  const selectFitSuggestion = (fit: FitSuggestion) => {
    if (fitIsSelected(fit)) {
      dispatch(matchmakerActions.selectFitSuggestion(null));
    } else {
      dispatch(matchmakerActions.selectFitSuggestion(fit.clinician.id));
    }
  };

  const [queueNote, setQueueNote] = useState<string>(matchDraft.queue_note);
  const [matchNote, setMatchNote] = useState<string>(matchDraft.match_note);
  const [referralNote, setReferralNote] = useState<string>(
    matchDraft.referral_note,
  );

  const fitIsSelected = (fit: FitSuggestion) =>
    selectedClinicianId === fit.clinician.id;

  const isMatchingAdmin = useUserHasAnyPermissions([
    "IsMatchingAdmin",
    "IsSuperUser",
  ]);

  if (matchDraft.is_referral)
    return (
      <>
        <Pad>
          <Title size="small">Referral Note:</Title>
          <Input.TextArea
            value={referralNote}
            onBlur={(e) => updateMatchDraft(referralNote, "referral_note")}
            onChange={(e) => setReferralNote(e.target.value)}
            placeholder="What's the reason for this referral?"
          />
        </Pad>
        {isGroupsAppropriateMatch && <GroupsEnrollment />}
      </>
    );

  return (
    <div style={{ overflow: "auto" }}>
      <Pad>
        <Title size="small" margin="0px">
          Match After Date:
        </Title>
        <p>
          <i>
            If the client would like to delay matching until after a certain
            date, please indicate that here.
          </i>
        </p>
        <DatePicker
          defaultValue={
            matchDraft.match_after ? moment(matchDraft.match_after) : undefined
          }
          onChange={(date: moment.Moment | null) =>
            updateMatchAfter(date?.format() || null)
          }
          format="MM-DD-YYYY"
        />
      </Pad>
      <Divider style={{ margin: "0px" }} />

      {isMatchingAdmin && (
        <>
          <Pad>
            <Title size="small">Match Note:</Title>
            <Input.TextArea
              value={matchNote}
              onChange={(e) => setMatchNote(e.target.value)}
              onBlur={() => updateMatchDraft(matchNote, "match_note")}
              placeholder="Provide context for the consult clinician should they need it when sending the match email."
            />
          </Pad>
          <Divider style={{ margin: "0px" }} />
        </>
      )}
      <Pad>
        <Title size="small" margin="0px">
          Manual Review:
        </Title>
        <p>
          <i>
            Flagging for review will temporarily exclude this match from
            matching and may delay your client’s access to care.
          </i>
        </p>
        <Checkbox
          checked={matchDraft.exclude_from_aqm}
          onChange={(e) =>
            dispatch(
              matchmakerActions.patchMatchDraft({
                exclude_from_aqm: e.target.checked,
              }),
            )
          }
        >
          Flag for C+M Review
        </Checkbox>
        <Input
          value={queueNote}
          onChange={(e) => setQueueNote(e.target.value)}
          onBlur={() => updateMatchDraft(queueNote, "queue_note")}
          placeholder="Please provide all necessary context on why this client requires additional review before matching."
          style={{ marginTop: "1em" }}
          type={matchDraft.exclude_from_aqm ? "text" : "hidden"}
        />
      </Pad>
      <Divider style={{ margin: "0px" }} />
      <Pad>
        {fits.length > 0 && (
          <>
            <Title size="small" margin="5px">
              Fit List:
            </Title>
            <FitList<FitSuggestion>
              maxScore={matchDraft.max_score}
              comparisonTagMap={matchTagMap}
              fits={fits}
              serviceType="individual"
              version={methodologyVersion}
              fitAction={(fit: FitSuggestion) => (
                <Row layout="flex-end center" style={{ width: "200px" }}>
                  <ClinicianStatus clinicianId={fit.clinician.id} />
                </Row>
              )}
            />
          </>
        )}
        {outOfAgeRangeFits.length > 1 && (
          <Title size="small" margin="0px">
            {outOfAgeRangeFits.length} clinicians are out of Age Range
          </Title>
        )}
        {outOfAgeRangeFits.length === 1 && (
          <Title size="small" margin="0px">
            1 clinician is out of Age Range
          </Title>
        )}
        {excludedFits.length > 1 && (
          <Title size="small" margin="0px">
            {excludedFits.length} clinicians were manually removed from the Fit
            List
          </Title>
        )}
        {excludedFits.length === 1 && (
          <Title size="small" margin="0px">
            1 clinician was manually removed from the Fit List
          </Title>
        )}
        {conflictOfInterestFits.length > 1 && (
          <Title size="small" margin="0px">
            {conflictOfInterestFits.length} clinicians are a Conflict of
            Interest
          </Title>
        )}
        {conflictOfInterestFits.length === 1 && (
          <Title size="small" margin="0px">
            1 clinician is a Conflict of Interest
          </Title>
        )}
        {nonKpFits.length > 1 && (
          <Title size="small" margin="0px">
            {nonKpFits.length} clinicians are not Kaiser Permanente enabled
          </Title>
        )}
        {nonKpFits.length === 1 && (
          <Title size="small" margin="0px">
            1 clinician is not Kaiser Permanente enabled
          </Title>
        )}
      </Pad>
    </div>
  );
}
