import { Event } from "../../api/types";

export const eventIsConsult = (event: Event) => {
  const { procedure, procedure_id } = event;
  if (procedure) {
    return procedure === "consult";
  }

  if (procedure_id) {
    return procedure_id === 2;
  }

  return false;
};

export const eventIsCouples = (event: Event) => {
  const { procedure, procedure_id } = event;

  if (procedure) {
    return (
      procedure === "couples_therapy" || procedure === "couples_teletherapy"
    );
  }

  if (procedure_id) {
    return procedure_id === 1 || procedure_id === 7;
  }

  return false;
};
