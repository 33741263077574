import * as React from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Button, Input, message } from "antd";

import { useDispatchPromise } from "../../_helpers/redux";
import { EditTagFormProps } from "../types";
import { createTag, updateTag } from "../utils";
import { Row } from "../../_layout/Flex";

const TagForm = ({
  initialTag,
  onUpdateTag,
  onCancel,
  form,
}: EditTagFormProps) => {
  const dispatch = useDispatchPromise();

  const onSubmit = async (e: React.FormEvent) => {
    try {
      e.preventDefault();
      form.validateFields(async (err, values) => {
        if (err) {
          return;
        }
        let response = null;

        if (initialTag && initialTag.id) {
          // update tag
          response = await dispatch(updateTag(initialTag.id, values));
        } else {
          // create new tag
          response = await dispatch(createTag(values));
        }
        if (response.data) {
          onUpdateTag(response.data);
          message.success(`Tag ${initialTag.id ? "updated" : "created"}!`);
        } else {
          message.error(
            `Uh oh! We're unable to ${
              initialTag.id ? "update" : "add"
            } resource`,
          );
        }
      });
    } catch (e) {
      message.error((e as Error).message);
    }
  };

  return (
    <Form onSubmit={onSubmit} onReset={() => onCancel()}>
      <Form.Item label="Name" colon={false} style={{ margin: 8 }}>
        {form.getFieldDecorator("name", {
          initialValue: initialTag.name,
          rules: [{ required: true, message: "Name is required" }],
        })(<Input placeholder="Add tag name" />)}
      </Form.Item>
      <Row layout="space-between start" style={{ margin: "16px 8px 0" }}>
        <Form.Item style={{ margin: 0 }}>
          <Button htmlType="reset">Cancel</Button>
        </Form.Item>
        <Form.Item style={{ margin: 0 }}>
          <Button type="primary" htmlType="submit">
            {initialTag.id ? "Save" : "Create"}
          </Button>
        </Form.Item>
      </Row>
    </Form>
  );
};

export const EditTagForm = Form.create<EditTagFormProps>({
  name: "edit_tag",
})(TagForm);
