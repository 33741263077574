import React from "react";
import { useController, useFormContext } from "react-hook-form";
import {
  Label,
  Stack,
  TextArea,
} from "../../../../../../../../../../../design-system";

interface CancellationFormItemProps {
  name: string;
  callback: () => void;
}

export const CancellationAttendanceNoteFormItem = ({
  name,
  callback,
}: CancellationFormItemProps) => {
  const { control } = useFormContext();

  const {
    field: { onChange },
  } = useController({
    name,
    control,
  });

  return (
    <Stack gap={10}>
      <Label>Attendance note</Label>
      <TextArea
        onChange={(value) => {
          onChange(value);
          callback();
        }}
        css={{ minHeight: 152, p: 16, maxHeight: 152 }}
        placeholder="Provide more context as needed"
      />
    </Stack>
  );
};
