import { graphqlClient } from "@/api/graphql";

import { useGetMatchingHistoryQuery } from "@/graphql/generated";
import { ONE_HOUR } from "@/app/_helpers/constants";
import { DateTime } from "luxon";

export const useGetMatchingHistory = ({
  clinicianId,
  resolvedSince,
}: {
  clinicianId: number;
  resolvedSince: DateTime;
}) => {
  const { data, isLoading } = useGetMatchingHistoryQuery(
    graphqlClient,
    { clinician_id: clinicianId, resolved_since: resolvedSince.toISO() },
    { staleTime: ONE_HOUR, enabled: true },
  );

  if (!data?.hasura_cwa__match_with_status) {
    return { isLoading, pendingMatches: [], numMatchesInLastWeek: 0}
  }

  return {
    isLoading,
    pendingMatches: data?.hasura_cwa__match_with_status.filter(
      (m) => m.client_status === "Pending Match" || m.client_status === "Prospective Match",
    ),
    numMatchesInLastWeek: data?.hasura_cwa__match_with_status.filter(
      (m) =>
        DateTime.fromISO(m.resolved_at) >=
        DateTime.now().minus({ days: 7 }).startOf("day"),
    ).length,
  };
};

export type MatchHistoryRow = ReturnType<typeof useGetMatchingHistory>["pendingMatches"][0];
