import * as React from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import {
  Button,
  Input,
  message,
  Select as DeprecatedAntdSelect,
  Spin,
  Radio,
  Divider,
} from "antd";

import { useDispatchPromise } from "../../_helpers/redux";
import { Category, EditResourceFormProps, Tag } from "../types";
import {
  createResource,
  fetchCategories,
  fetchTags,
  updateResource,
} from "../utils";

import useDebounce from "../../hooks/useDebounce";
import { useUserHasAnyPermissions } from "../../_helpers/permissions";
import styled from "styled-components";

const StyledSelectWithPrettyLittleRoundedCorners = styled.div`
  .ant-select > div {
    border-radius: 4px;
  }
`;

const ResourceForm = ({
  initialResource,
  onUpdateResource,
  onCancel,
  form,
  setParams,
}: EditResourceFormProps) => {
  const dispatch = useDispatchPromise();
  const isClinician = useUserHasAnyPermissions(["IsClinician"]);
  const isCurator = useUserHasAnyPermissions(["IsResourceLibCurator"]);
  const [tagsSearch, setTagsSearch] = React.useState<string>("");
  const [tags, setTags] = React.useState<Tag[]>([]);
  const [categoriesSearch, setCategoriesSearch] = React.useState<string>("");
  const [fetching, setFetching] = React.useState<boolean>(false);
  const [categories, setCategories] = React.useState<Category[]>([]);

  const sortAndFilterCategories = (categories: Category[]) => {
    const otherCategoryIndex = categories.findIndex(
      (category: Category) => category.name === "Other",
    );

    if (otherCategoryIndex > -1) {
      const otherCategory = categories.splice(otherCategoryIndex, 1);
      categories.push(otherCategory[0]);
    }

    return categories;
  };

  // debounce tags and categories search
  const debouncedTagsSearch = useDebounce(tagsSearch, 500);
  const debouncedCategoriesSearch = useDebounce(categoriesSearch, 500);

  React.useEffect(() => {
    const searchTags = async (search: string) => {
      try {
        setFetching(true);
        const tagsRes = await dispatch(fetchTags(search));
        setTags(tagsRes.data.results);
        setFetching(false);
      } catch (e) {
        message.error((e as Error).message);
      }
    };
    searchTags(debouncedTagsSearch);
  }, [debouncedTagsSearch, dispatch]);

  React.useEffect(() => {
    const searchCategories = async (search: string) => {
      try {
        setFetching(true);
        const categoriesRes = await dispatch(fetchCategories(search, 1, 20));
        const rearrangedCategories = sortAndFilterCategories(
          categoriesRes.data.results,
        );
        setCategories(rearrangedCategories);
        setFetching(false);
      } catch (e) {
        message.error((e as Error).message);
      }
    };
    searchCategories(debouncedCategoriesSearch);
  }, [debouncedCategoriesSearch, dispatch]);

  const handleTagsChange = (nextTags: string[]) => {
    // all tag(s) search results have been selected
    // reset search text to refetch all
    if (tags.length && tags.every((t) => nextTags.includes(t.id))) {
      setTagsSearch("");
    }
  };

  const handleCategoriesChange = (nextCategories: string[]) => {
    // all categories(s) search results have been selected
    // reset search text to refetch all
    if (
      categories.length &&
      categories.every((t) => nextCategories.includes(t.id))
    ) {
      setCategoriesSearch("");
    }
  };

  const handleResourceUpdate = async (e: React.FormEvent) => {
    try {
      e.preventDefault();
      form.validateFields(async (err, values) => {
        if (err) {
          return;
        }
        let response = null;

        let payload = { ...values };

        payload.categories =
          typeof payload.categories === "string"
            ? [payload.categories]
            : payload.categories;

        if (isClinician && !isCurator) {
          if (!payload["is_personal"]) {
            payload.is_personal = true;
          }

          if (!payload["is_published"]) {
            payload.is_published = false;
          }
        }

        if (isCurator) {
          if (payload.is_personal) {
            payload.is_published = false;
          } else {
            payload.is_published = true;
          }
        }

        if (initialResource && initialResource.id) {
          // update resource
          response = await dispatch(
            updateResource(initialResource.id, payload),
          );
        } else {
          // create new resource
          response = await dispatch(createResource(payload));
          setParams("resource", response.data.id);
        }
        if (response.data) {
          onUpdateResource({
            ...response.data,
            is_favorite: initialResource.is_favorite,
          });
        } else {
          message.error(
            `Uh oh! We're unable to ${
              initialResource.id ? "update" : "add"
            } resource`,
          );
        }
      });
    } catch (e) {
      message.error((e as Error).message);
    }
  };

  return (
    <Form
      onSubmit={handleResourceUpdate}
      onReset={() => onCancel()}
      hideRequiredMark // Antd defaults to showing the asterisk on the left side, which throws off text alignment
    >
      <Form.Item
        label={
          <span style={{ fontSize: "12px" }}>
            Title<span style={{ color: "red", fontSize: "12px" }}> *</span>
          </span>
        }
        colon={false}
        style={{
          fontSize: "12px",
          padding: "10px 16px 40px 16px",
        }}
      >
        {form.getFieldDecorator("title", {
          initialValue: initialResource.title,
          rules: [
            { required: true, message: "Title is required." },
            { max: 50, message: "Title is limited to 50 characters" },
          ],
        })(
          <Input
            placeholder="Title will be visible to clients when resource is shared"
            maxLength={50}
            style={{ borderRadius: "4px" }}
          />,
        )}
        <span style={{ position: "absolute", top: 16, right: 0, bottom: 0 }}>
          {form.getFieldValue("title").length || 0}/50
        </span>
      </Form.Item>
      <Divider />
      <span style={{ color: "#8C8C8C", padding: "16px" }}>
        The following resource details are visible to clinicians only.
      </span>

      <Form.Item
        label={
          <span style={{ fontSize: "12px" }}>
            Description <span style={{ color: "red" }}>*</span>
          </span>
        }
        colon={false}
        style={{ margin: "8px 0", paddingRight: "16px", paddingLeft: "16px" }}
      >
        {form.getFieldDecorator("description", {
          initialValue: initialResource.description,
          rules: [{ required: true, message: "Description is required." }],
        })(
          <Input.TextArea
            rows={5}
            placeholder="Add resource description"
            style={{ borderRadius: "4px" }}
          />,
        )}
      </Form.Item>
      <Form.Item
        label={
          <span style={{ fontSize: "12px" }}>
            Link to resource <span style={{ color: "red" }}>*</span>
          </span>
        }
        colon={false}
        style={{
          margin: "8px 0",
          fontSize: "12px",
          paddingRight: "16px",
          paddingLeft: "16px",
        }}
      >
        {form.getFieldDecorator("url", {
          initialValue: initialResource.url,
          rules: [
            { required: true, message: "Link is required." },
            {
              pattern: new RegExp("^((?!drive.google).)*$"),
              message: "Google Drive links are not allowed",
            },
          ],
        })(<Input placeholder="URL link" style={{ borderRadius: "4px" }} />)}
      </Form.Item>

      <Form.Item
        label={
          <span style={{ fontSize: "12px" }}>
            Category
            <span style={{ color: "red", fontSize: "12px" }}> *</span>
          </span>
        }
        colon={false}
        style={{
          margin: "8px 0",
          paddingRight: "16px",
          paddingLeft: "16px",
        }}
      >
        <StyledSelectWithPrettyLittleRoundedCorners>
          {form.getFieldDecorator("categories", {
            rules: [
              {
                required: true,
                message: "Category is required.",
              },
            ],
            initialValue: initialResource.categories.map((t) => t.id),
          })(
            <DeprecatedAntdSelect
              dropdownStyle={{ borderRadius: "4px" }}
              placeholder="Select one"
              notFoundContent={fetching ? <Spin size="small" /> : null}
              filterOption={false}
              onSearch={setCategoriesSearch}
              onChange={handleCategoriesChange}
              style={{ width: "284px" }}
            >
              {categories.map((item) => (
                <DeprecatedAntdSelect.Option key={item.id}>{item.name}</DeprecatedAntdSelect.Option>
              ))}
            </DeprecatedAntdSelect>,
          )}
        </StyledSelectWithPrettyLittleRoundedCorners>
      </Form.Item>

      <Form.Item
        label={<span style={{ fontSize: "12px" }}>Tags</span>}
        colon={false}
        style={{ margin: "8px 0", paddingRight: "16px", paddingLeft: "16px" }}
      >
        <StyledSelectWithPrettyLittleRoundedCorners>
          {form.getFieldDecorator("tags", {
            initialValue: initialResource.tags.map((t) => t.id),
          })(
            <DeprecatedAntdSelect
              showArrow
              mode="multiple"
              dropdownStyle={{ borderRadius: "4px" }}
              placeholder="Select up to ..."
              notFoundContent={fetching ? <Spin size="small" /> : null}
              filterOption={false}
              onSearch={setTagsSearch}
              onChange={handleTagsChange}
              style={{ flexBasis: "284px" }}
            >
              {tags.map((item) => (
                <DeprecatedAntdSelect.Option key={item.id}>{item.name}</DeprecatedAntdSelect.Option>
              ))}
            </DeprecatedAntdSelect>,
          )}
        </StyledSelectWithPrettyLittleRoundedCorners>
      </Form.Item>

      {isClinician && !isCurator && (
        <p style={{ margin: "8px 0", padding: "16px" }}>
          This resource will only be visible to you and the clients you share
          this with.
        </p>
      )}

      {isCurator && (
        <>
          <Form.Item
            colon={false}
            label={
              <span style={{ fontSize: "12px" }}>
                Resource visibility <span style={{ color: "red" }}> *</span>
              </span>
            }
            style={{
              margin: "8px 0",
              borderRadius: "4px",
              paddingRight: "16px",
              paddingLeft: "16px",
            }}
          >
            {form.getFieldDecorator("is_personal", {
              initialValue: initialResource.is_personal,
            })(
              <Radio.Group>
                <Radio value={true} style={{ marginBottom: "12px" }}>
                  Private - only I can view this resource.
                </Radio>
                <Radio value={false}>
                  Public - all clinicians can view this resource in their
                  library.
                </Radio>
              </Radio.Group>,
            )}
          </Form.Item>
        </>
      )}

      <div
        style={{
          height: 100,
          paddingRight: "16px",
          paddingLeft: "16px",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-between",
          display: "flex",
        }}
      >
        <Button
          htmlType="reset"
          style={{
            width: "100px",
            height: "40px",
            borderRadius: "4px",
          }}
        >
          Cancel
        </Button>

        <Button
          type="primary"
          htmlType="submit"
          style={{ width: "100px", height: "40px", borderRadius: "4px" }}
        >
          Save
        </Button>
      </div>
    </Form>
  );
};

export const EditResourceForm = Form.create<EditResourceFormProps>({
  name: "edit_resource",
})(ResourceForm);
