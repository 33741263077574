import React, { useState } from "react";
import { Select as DeprecatedAntdSelect, Divider, Button } from "antd";
import { SelectValue } from "antd/es/select";
import { useSelector } from "react-redux";
import { AppState } from "../../../../state/models";
import { Row } from "../../../_layout/Flex";
import api from "../../../../api";
import { AxiosResponse } from "axios";
import { Title } from "../../../_layout/display";
import ButtonGroup from "antd/es/button/button-group";
import { alphabetizeBy } from "../../../_helpers";

interface RerunQueueResponse {
  command: "add_new_clinicians_to_queued_matches";
  data:
    | string
    | {
        logs: string[];
      };
  result: "Success" | "Failure";
}

export default () => {
  const [clinicians] = useSelector((state: AppState) => [
    Object.values(state.clinicians.clinicianMap),
  ]);

  const [loading, setLoading] = useState<boolean>(false);

  const [selectedClinicianId, selectClinicianId] = useState<number>();
  const [dryrunResult, setDryrunResult] = useState<
    RerunQueueResponse | undefined
  >();
  const [runResult, setRunResult] = useState<RerunQueueResponse | undefined>();

  const [runQueueFor, setRunQueueFor] = useState<"all" | "new" | "single">(
    "all",
  );

  const clinicianParam = ["all", "new"].includes(runQueueFor)
    ? `?${runQueueFor}-clinicians`
    : `?clinician-id=${selectedClinicianId}`;
  const url = `/ehr/manage/rerun_match_queue${clinicianParam}`;

  const doDryrun = () => {
    if (loading) return;
    setLoading(true);
    api
      .get(url)
      .then((response: AxiosResponse<RerunQueueResponse>) => {
        console.log(response.data);
        setDryrunResult(response.data);
        setLoading(false);
      })
      .catch((err: any) => {
        console.error(err);
        setLoading(false);
      });
  };

  const doRun = () => {
    if (loading) return;
    setLoading(true);
    api
      .get(url + "&no-dryrun")
      .then((response: AxiosResponse<RerunQueueResponse>) => {
        console.log(response.data);
        setRunResult(response.data);
        setLoading(false);
      })
      .catch((err: any) => {
        console.error(err);
        setLoading(false);
      });
  };

  const buttonPress = (button: "all" | "new" | "single") => {
    setRunQueueFor(button);
    setDryrunResult(undefined);
    setRunResult(undefined);
  };

  return (
    <>
      <Title>Rerun Match Queue</Title>
      <p>This will add / remove clinicians as fits to matches in the queue.</p>
      <Row layout={"start center"}>
        <div>
          <div style={{ marginBottom: "10px" }}>
            <ButtonGroup>
              <Button
                type={runQueueFor === "all" ? "primary" : "default"}
                onClick={() => buttonPress("all")}
              >
                All Clinicians
              </Button>
              <Button
                type={runQueueFor === "new" ? "primary" : "default"}
                onClick={() => buttonPress("new")}
              >
                New Clinicians
              </Button>
              <Button
                type={runQueueFor === "single" ? "primary" : "default"}
                onClick={() => buttonPress("single")}
              >
                Single Clinician
              </Button>
            </ButtonGroup>
          </div>
          {runQueueFor === "single" && (
            <DeprecatedAntdSelect
              disabled={loading}
              onChange={(value: SelectValue) => {
                selectClinicianId(value as number);
                setDryrunResult(undefined);
                setRunResult(undefined);
              }}
              style={{ width: 400 }}
              placeholder="Select a clinician to rerun the queue for..."
            >
              {clinicians
                .sort((a, b) => alphabetizeBy(a, b, "first_name"))
                .map((clinician) => (
                  <DeprecatedAntdSelect.Option
                    key={clinician.id}
                    value={clinician.id}
                  >
                    {clinician.first_name} {clinician.last_name}
                  </DeprecatedAntdSelect.Option>
                ))}
            </DeprecatedAntdSelect>
          )}
        </div>
        <div style={{ flex: 1 }} />
        <Button
          type="default"
          loading={loading}
          disabled={runQueueFor === "single" && !selectedClinicianId}
          onClick={() => doDryrun()}
        >
          Dryrun
        </Button>
        <Button
          loading={loading}
          disabled={!dryrunResult}
          type="primary"
          onClick={doRun}
        >
          Run
        </Button>
      </Row>
      {runResult === undefined && dryrunResult !== undefined && (
        <Results results={dryrunResult} />
      )}
      {runResult !== undefined && <Results results={runResult} />}
    </>
  );
};

const Results = ({ results }: { results: RerunQueueResponse }) => (
  <>
    <Divider />
    <div>
      <strong>Result: </strong> {results.result}
    </div>
    <p>
      <strong>Logs:</strong>
    </p>
    <div style={{ overflowY: "auto" }}>
      {typeof results.data !== "string" && (
        <ul>
          {results.data.logs.map((log: string, idx) => (
            <li key={"logs" + idx}>{log}</li>
          ))}
        </ul>
      )}
      {typeof results.data === "string" && <div>{results.data}</div>}
    </div>
  </>
);
