import { LeftSquareTwoTone } from "@ant-design/icons";
import { MyClientsClient } from "../../../../my-clients-model";
import { MyClientsFilters } from "../../../../my-clients-model/my-clients.interface";
import {
  selectMBCMetricsFilterState,
  useMyClientsFilterStore,
} from "../../../../my-clients-model/my-clients.model";
import { ClientMatchingStatus } from "../../../client-pane/components/matching-status-badge";
import { CurrentUserContext } from "@/app/app.utils";
import React, { useContext } from "react";
import { Permissions } from "@/app/_helpers/permissions";

export const getIsClientGraduateable = (client: MyClientsClient) =>
  (client.mbcTrackStatus === "Graduate" ||
    client.mbcTrackStatus === "Reliable Change"
  ) &&
  client.clientPayerServiceRecord !== null &&
  client.matchingInfo?.matchingStatus !== ClientMatchingStatus.Inactive;

export const getIsClientSubclinicalAndExceeding10Sessions = (
  client: MyClientsClient,
) =>
  client.combinedSeverity === "subclinical" &&
  client.clientPayerServiceRecord !== null &&
  client.totalSessionsAttendedWithCurrentClinician &&
  client.totalSessionsAttendedWithCurrentClinician > 10 &&
  client.matchingInfo?.matchingStatus !== ClientMatchingStatus.Inactive;

export const getIsClientMildToModerateAndExceeding17Sessions = (
  client: MyClientsClient,
) =>
  client.combinedSeverity === "mild - moderate" &&
  client.clientPayerServiceRecord !== null &&
  client.totalSessionsAttendedWithCurrentClinician &&
  client.totalSessionsAttendedWithCurrentClinician > 17 &&
  client.matchingInfo?.matchingStatus !== ClientMatchingStatus.Inactive;

export const getIsClientModerateToSevereAndExceeding25Sessions = (
  client: MyClientsClient,
) =>
  client.combinedSeverity === "moderate - severe" &&
  client.clientPayerServiceRecord !== null &&
  client.totalSessionsAttendedWithCurrentClinician &&
  client.totalSessionsAttendedWithCurrentClinician > 25 &&
  client.matchingInfo?.matchingStatus !== ClientMatchingStatus.Inactive;

const getTotalClientsByMBCMetrics = (myClients: MyClientsClient[]) => {
  const totalGraduatableClients = myClients.filter(
    getIsClientGraduateable,
  ).length;

  const totalSubclinicalClientsExceeding10Sessions = myClients.filter(
    getIsClientSubclinicalAndExceeding10Sessions,
  ).length;

  const totalMildToModerateClientsExceeding17Sessions = myClients.filter(
    getIsClientMildToModerateAndExceeding17Sessions,
  ).length;

  const totalModerateToSevereClientsExceeding25Sessions = myClients.filter(
    getIsClientModerateToSevereAndExceeding25Sessions,
  ).length;

  return {
    totalGraduatableClients,
    totalSubclinicalClientsExceeding10Sessions,
    totalMildToModerateClientsExceeding17Sessions,
    totalModerateToSevereClientsExceeding25Sessions,
  };
};

export const useMBCMetricFiltersBySeverity = (myClients: MyClientsClient[], isDashboardV2User: boolean | null) => {
  const {
    isFilteringOnMBCGraduationClients,
    isFilteringOnKPWA,
    isFilteringOnKPNorcal,
    setMyClientsFilter,
  } = useMyClientsFilterStore((state) => ({
    isFilteringOnMBCGraduationClients: state.isFilteringOnMBCGraduationClients,
    isFilteringOnKPWA: state.isFilteringOnKPWA,
    isFilteringOnKPNorcal: state.isFilteringOnKPNorcal,
    setMyClientsFilter: state.setMyClientsFilter,
  }));

  const {
    isFilteringOnMildToModerateClientsExceeding17Sessions,
    isFilteringOnModerateToSevereClientsExceeding25Sessions,
    isFilteringOnSubclinicalClientsExceeding10Sessions,
  } = useMyClientsFilterStore(selectMBCMetricsFilterState);

  const {
    totalGraduatableClients,
    totalSubclinicalClientsExceeding10Sessions,
    totalMildToModerateClientsExceeding17Sessions,
    totalModerateToSevereClientsExceeding25Sessions,
  } = getTotalClientsByMBCMetrics(myClients);

  const currentUser = useContext(CurrentUserContext);

  let mbcMetricFiltersBySeverity = [
    {
      numberOfClients: totalGraduatableClients,
      clientLabel: "",
      copy: `${totalGraduatableClients !== 1 ? "are" : "is"} ${(!isDashboardV2User && !Permissions.IsCareAlertBetaUser(currentUser)) ? "ready to graduate" : "in remission"}`,
      isFilterChecked:
        isFilteringOnMBCGraduationClients &&
        isFilteringOnKPWA &&
        isFilteringOnKPNorcal,
      filterName: "isFilteringOnMBCGraduationClients" as keyof MyClientsFilters,
    },
    {
      numberOfClients: totalSubclinicalClientsExceeding10Sessions,
      clientLabel: "subclinical",
      copy: "exceeded 10 sessions",
      isFilterChecked: isFilteringOnSubclinicalClientsExceeding10Sessions,
      filterName:
        "isFilteringOnSubclinicalClientsExceeding10Sessions" as keyof MyClientsFilters,
    },
  ];

  return { mbcMetricFiltersBySeverity, setMyClientsFilter };
};
