import React from "react";
import { useFormContext } from "react-hook-form";

import { CancellationAttendanceNoteFormItem } from "./cancellation-attendance-note-form-item";
import { CancellationReasonFormItem } from "./cancellation-reason-form-item";
import { CancellationTypeFormItem } from "./cancellation-type-form-item";
import {
  CancelClientGroupEventFieldNames,
  CancelSessionFormFields,
  useCancellationReasons,
} from "../../_api/use-cancel-client-group-event-form/use-cancel-client-group-event-form";

export const CancelClientGroupEventFormItems = () => {
  const formMethods = useFormContext<CancelSessionFormFields>();

  const { control, setValue, trigger } = formMethods;

  const [cancellationReasons, cancellationTypeOptions] = useCancellationReasons(
    control,
    setValue,
    formMethods,
  );

  return (
    <>
      <CancellationTypeFormItem
        name={CancelClientGroupEventFieldNames.CANCELLATION_TYPE}
        options={cancellationTypeOptions}
      />

      <CancellationReasonFormItem
        name={CancelClientGroupEventFieldNames.CANCELLATION_REASON}
        options={cancellationReasons}
        id={"cancellation-reason-form-item"}
        callback={() => trigger()}
      />

      <CancellationAttendanceNoteFormItem
        name={CancelClientGroupEventFieldNames.ATTENDANCE_NOTE}
        callback={() => trigger()}
      />
    </>
  );
};
