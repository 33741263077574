import * as Tabs from "@radix-ui/react-tabs";
import { styledStitches } from "@/app/design-system/styles/stitches.config";

export const TabsRoot = styledStitches(Tabs.Root, {
  display: "flex",
  flexDirection: "row",
  flex: 1,
});

export const TabsList = styledStitches(Tabs.List, {
  display: "flex",
  flexDirection: "column",
});

export const TabsTrigger = styledStitches(Tabs.Trigger, {
  backgroundColor: "$neutral3",
  border: "none",
  display: "flex",
  py: 20,
  px: 16,
  cursor: "pointer",
  br: 8,
  boxSizing: "border-box",
  justifyContent: "flex-start",

  "&:active": {
    transform: "scale(0.995)",
  },

  '&[data-state="active"]': {
    transitionDuration: "300ms",
    transitionProperty: "border-right",
    backgroundColor: "white",
    borderRight: "6px solid black",
    fontWeight: 700,
  },
});

export const TabsContent = styledStitches(Tabs.Content, {
  display: "flex",
  flex: 1,
});
