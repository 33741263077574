import moment from "moment";
import * as React from "react";
import {
  Crosshair,
  FlexibleWidthXYPlot,
  GradientDefs,
  Hint,
  HorizontalGridLines,
  LineMarkSeries,
  LineSeries,
  RectSeriesPoint,
  VerticalRectSeries,
  VerticalRectSeriesProps,
  XAxis,
  YAxis,
} from "react-vis";
import styled from "styled-components";
import tinycolor from "tinycolor2";

import { Button as AntdButton } from "antd";
import { $green, $grey, $greyBorder, $red } from "../../../assets/colors";
import { HintTextDiv } from "../../panel-management/_shared";
import { Permissions } from "../../_helpers/permissions";
import { useShallowEqualSelector } from "../../_helpers/redux";
import DiscreteBillableHoursChart from "./discrete-billable-hours";
import { BillableHoursWeeksMap, DateRange } from "../types";
import {
  CommonSeriesProps,
  DashboardComponentTitle,
  ShowUtilizationButton,
  TooltipContainer,
  TooltipDot,
  TooltipValues,
} from "../_common";
import { RollingAveragesView } from "./averages-view";
import {
  BillableHoursDataLabels,
  ExpandedRectSeriesPoint,
  ISO_WEEKDATE_FORMAT,
  transformBillableHoursSeries,
} from "../billable-hours/utils";
import { Flex, styledStitches } from "@/app/design-system";
import { UnstyledButton } from "@/app/design-system/button/button";
import { ReactVisWrapper } from "@/app/_shared/react-vis-wrapper";

const THERAPY_COLOR = $green;
const CONSULT_COLOR = "#DBD8AE";
const COUPLES_THERAPY_COLOR = "#D8AEDB";
const NOSHOW_COLOR = $red;
const CONSULT_ALLOCATION_COLOR = tinycolor(CONSULT_COLOR)
  .lighten(18)
  .toRgbString();
const UTILIZATION_COLOR = "#333";
const TARGET_ZONE_COLOR = tinycolor($grey);
const CURRENT_HEADING_LEVEL = 2;
// Based on current usage, where the chart is typically within the top level section (which would have a root level of 1; thus the root level would need to be 2)

const BillableHoursChart = ({
  defaultClinicianId,
  defaultBillableHoursWeeks,
  defaultDateRange,
}: {
  defaultClinicianId?: number;
  defaultBillableHoursWeeks?: any;
  defaultDateRange?: DateRange;
}) => {
  const {
    dateRange,
    clinicianId,
    teamViewClinician,
    billableHoursWeeks,
    isLoadingBillableHours,
    currentUser,
  } = useShallowEqualSelector((state) => ({
    dateRange: state.dashboard.dateRange,
    clinicianId: state.dashboard.clinicianId,
    billableHoursWeeks: state.dashboard.billableHoursWeeks,
    teamViewClinician: state.dashboard.teamViewClinician,
    teamClinicianIds: state.dashboard.teamClinicianIds,
    isLoadingBillableHours: state.dashboard.isLoadingBillableHours,
    currentUser: state.auth.currentUser,
  }));
  // if in MBC2 and not team view
  // can show discrete graph
  const canViewDiscrete = teamViewClinician === null;

  const [discreteVisible, setDiscreteVisible] = React.useState(false);

  if (canViewDiscrete && discreteVisible) {
    return (
      <DiscreteBillableHoursChart
        onToggleView={() => setDiscreteVisible((p) => !p)}
        defaultBillableHoursWeeks={defaultBillableHoursWeeks}
        defaultDateRange={defaultDateRange}
      />
    );
  }

  return (
    <Container id="my-db-utilization">
      <Flex
        justifyContent={"space-between"}
        alignItems={"center"}
        css={{ mt: 12 }}
      >
        <DashboardComponentTitle as="h2" css={{ m: 0 }}>
          Utilization
        </DashboardComponentTitle>

        {canViewDiscrete && (defaultClinicianId ?? clinicianId) && (
          <ShowUtilizationButton
            onClick={() => setDiscreteVisible(true)}
            css={{ mr: 8 }}
          >
            <span
              style={{
                color: "rgba(0, 0, 0, 0.85)",
                fontSize: 16,
                fontWeight: 400,
                lineHeight: "24px",
              }}
            >
              Show Utilization Hours
            </span>
          </ShowUtilizationButton>
        )}
      </Flex>
      {!!(
        !(defaultClinicianId ?? clinicianId) &&
        Permissions.IsSuperUser(currentUser)
      ) && (
        <HintTextDiv style={{ width: "initial" }}>
          Welcome, superuser! Please pick a Clinician to view their Dashboard.
        </HintTextDiv>
      )}

      <DataContainer>
        <div style={{ width: "calc(100% - 160px)" }}>
          <BillableHoursPlot
            billableHoursWeeks={defaultBillableHoursWeeks ?? billableHoursWeeks}
            dateRange={defaultDateRange ?? dateRange}
            showPto={teamViewClinician === null}
            isLoadingState={isLoadingBillableHours}
          />
        </div>
        <RollingAveragesView
          data={defaultBillableHoursWeeks ?? billableHoursWeeks}
          dateRange={defaultDateRange ?? dateRange}
          rootHeadingLevel={CURRENT_HEADING_LEVEL}
        />
      </DataContainer>

      {/* inject svg patterns to accomplish pattern-fills on <rect>s */}
      <SVGGradientOverlay idLabel="consult" patternBGColor={CONSULT_COLOR} />
      <SVGGradientOverlay idLabel="therapy" patternBGColor={THERAPY_COLOR} />
      <SVGGradientOverlay
        idLabel="couplesTherapy"
        patternBGColor={COUPLES_THERAPY_COLOR}
      />
    </Container>
  );
};

const Y_AXIS_DOMAIN = [0, 1];

const BillableHoursPlot = React.memo(
  (props: {
    billableHoursWeeks: BillableHoursWeeksMap;
    dateRange: DateRange;
    showPto?: boolean;
    isLoadingState: boolean;
  }) => {
    const { billableHoursWeeks, dateRange, showPto } = props;

    const [crosshairIndex, setCrosshairIndex] = React.useState<number | null>(
      null,
    );

    const {
      utilization,
      numConsultNoShow,
      numTherapyNoShow,
      numTherapyAttended,
      numTherapyScheduled,
      numConsultAttended,
      numConsultScheduled,
      numConsultAllocationRemaining,
      numCouplesTherapyAttended,
      numCouplesTherapyNoShow,
      numCouplesTherapyScheduled,
      goalLine,
      goalBox,
      ptoHours,
    }: {
      utilization: ExpandedRectSeriesPoint[];
      numTherapyNoShow: ExpandedRectSeriesPoint[];
      numConsultNoShow: ExpandedRectSeriesPoint[];
      numTherapyAttended: ExpandedRectSeriesPoint[];
      numTherapyScheduled: ExpandedRectSeriesPoint[];
      numConsultAttended: ExpandedRectSeriesPoint[];
      numConsultScheduled: ExpandedRectSeriesPoint[];
      numConsultAllocationRemaining: ExpandedRectSeriesPoint[];
      numCouplesTherapyAttended: ExpandedRectSeriesPoint[];
      numCouplesTherapyNoShow: ExpandedRectSeriesPoint[];
      numCouplesTherapyScheduled: ExpandedRectSeriesPoint[];
      goalLine: any[];
      goalBox: any[];
      ptoHours: { [isoWeek: string]: number };
    } = React.useMemo(
      () => transformBillableHoursSeries(billableHoursWeeks, dateRange),
      [billableHoursWeeks, dateRange],
    );

    const hoverProps: Partial<VerticalRectSeriesProps> = {
      onNearestX: React.useCallback(
        (_, { index }) => {
          if (index !== crosshairIndex) {
            setCrosshairIndex(index);
          }
        },
        [crosshairIndex],
      ),
    };

    const commonGraphProps = CommonSeriesProps;

    // determines what values is passed to the crosshair at each index
    const crosshairValues = React.useMemo(() => {
      if (crosshairIndex === null) {
        return [];
      }

      return [
        utilization,
        numTherapyNoShow,
        numConsultNoShow,
        numTherapyAttended,
        numTherapyScheduled,
        numConsultAttended,
        numConsultScheduled,
        numConsultAllocationRemaining,
        numCouplesTherapyAttended,
        numCouplesTherapyScheduled,
        numCouplesTherapyNoShow,
      ].map((d) => crosshairValueTransformer(d[crosshairIndex]));
    }, [
      utilization,
      numTherapyNoShow,
      numConsultNoShow,
      numTherapyAttended,
      numTherapyScheduled,
      numConsultAttended,
      numConsultScheduled,
      numConsultAllocationRemaining,
      numCouplesTherapyAttended,
      numCouplesTherapyScheduled,
      numCouplesTherapyNoShow,
      crosshairIndex,
    ]);

    const onMouseLeave = React.useCallback(() => setCrosshairIndex(null), []);

    const yAxisTickFormat = React.useCallback(
      (value: number) => `${value * 100}%`,
      [],
    );

    const dateXAxisTickFormat = React.useCallback(
      (tick: Date) => moment(tick).format("MMM DD"),
      [],
    );

    const ptoXAxisTickFormat = React.useCallback(
      (tick: Date) => {
        // add a couple of days to ensure we're getting the right isoweek, since `tick` refers to a Sunday
        const hasPto =
          ptoHours[moment(tick).add(4, "day").format(ISO_WEEKDATE_FORMAT)] > 0;
        if (hasPto) {
          return "PTO";
        }
        return null;
      },
      [ptoHours],
    );

    const numWeeksInRange = dateRange.end.diff(dateRange.start, "weeks");

    if (props.isLoadingState) {
      return null;
    }

    return (
      <ReactVisWrapper>
        <FlexibleWidthXYPlot
          xType="time"
          yDomain={Y_AXIS_DOMAIN}
          height={400}
          margin={50}
          stackBy="y"
          onMouseLeave={onMouseLeave}
        >
          <GradientDefs>
            <linearGradient id="TargetZoneGradient" x1="0" x2="0" y1="0" y2="1">
              <stop
                offset="0%"
                stopColor={TARGET_ZONE_COLOR.toPercentageRgbString()}
                stopOpacity={0.8}
              />
              <stop
                offset="100%"
                stopColor={TARGET_ZONE_COLOR.toPercentageRgbString()}
                stopOpacity={0.35}
              />
            </linearGradient>
          </GradientDefs>

          <HorizontalGridLines />
          <VerticalRectSeries
            {...commonGraphProps}
            data={goalBox}
            stack={false}
            color={"url(#TargetZoneGradient"}
            className="targetZoneSeries"
            stroke={0}
          />

          <Hint
            value={{ x: moment(dateRange.start).toDate(), y: 0.625 }}
            align={{ vertical: "top", horizontal: "right" }}
          >
            <h5 role="presentation" style={{ color: "rgba(0, 0, 0, 0.7)" }}>
              62.5% Utilization
            </h5>
          </Hint>

          <LineSeries
            data={goalLine}
            stroke={TARGET_ZONE_COLOR.setAlpha(0.7).toPercentageRgbString()}
          />

          <VerticalRectSeries
            {...hoverProps}
            {...commonGraphProps}
            stack={true}
            color={NOSHOW_COLOR}
            data={numConsultNoShow}
          />

          <VerticalRectSeries
            {...hoverProps}
            {...commonGraphProps}
            stack={true}
            color={CONSULT_COLOR}
            data={numConsultAttended}
          />

          <VerticalRectSeries
            {...hoverProps}
            {...commonGraphProps}
            stack={true}
            color={CONSULT_COLOR}
            data={numConsultScheduled}
            className="consultsScheduledSeries"
          />

          <VerticalRectSeries
            {...hoverProps}
            {...commonGraphProps}
            stack={true}
            color={CONSULT_ALLOCATION_COLOR}
            data={numConsultAllocationRemaining}
            className="consultsAllocatedSeries"
          />

          <VerticalRectSeries
            {...hoverProps}
            {...commonGraphProps}
            stack={true}
            color={NOSHOW_COLOR}
            data={numCouplesTherapyNoShow}
          />

          <VerticalRectSeries
            {...hoverProps}
            {...commonGraphProps}
            stack={true}
            color={COUPLES_THERAPY_COLOR}
            data={numCouplesTherapyAttended}
          />

          <VerticalRectSeries
            {...hoverProps}
            {...commonGraphProps}
            stack={true}
            color={COUPLES_THERAPY_COLOR}
            data={numCouplesTherapyScheduled}
            className="couplesTherapyScheduledSeries"
          />

          <VerticalRectSeries
            {...hoverProps}
            {...commonGraphProps}
            stack={true}
            color={NOSHOW_COLOR}
            data={numTherapyNoShow}
          />

          <VerticalRectSeries
            {...hoverProps}
            {...commonGraphProps}
            stack={true}
            color={THERAPY_COLOR}
            data={numTherapyAttended}
          />

          <VerticalRectSeries
            {...hoverProps}
            {...commonGraphProps}
            stack={true}
            color={THERAPY_COLOR}
            data={numTherapyScheduled}
            className="therapyScheduledSeries"
          />

          <LineMarkSeries
            data={utilization}
            {...commonGraphProps}
            color={UTILIZATION_COLOR}
            curve="curveMonotoneX"
            size={2}
            getNull={(d: any) => d.y !== 0}
          />
          <XAxis
            position="middle"
            orientation="bottom"
            tickTotal={numWeeksInRange}
            tickFormat={dateXAxisTickFormat}
          />
          {showPto && (
            <XAxis
              position="middle"
              orientation="bottom"
              tickTotal={numWeeksInRange}
              tickFormat={ptoXAxisTickFormat}
              tickPadding={36}
              tickSize={0}
            />
          )}
          <YAxis tickFormat={yAxisTickFormat} />
          <Crosshair values={crosshairValues} {...commonGraphProps}>
            <CrosshairTooltip
              crosshairValues={crosshairValues}
              ptoHours={ptoHours}
            />
          </Crosshair>
        </FlexibleWidthXYPlot>
      </ReactVisWrapper>
    );
  },
);

function CrosshairTooltip(props: {
  crosshairValues?: RectSeriesPoint[];
  ptoHours: { [isoWeek: string]: number };
}) {
  const { crosshairValues, ptoHours } = props;

  if (!crosshairValues) {
    return null;
  }

  const date = moment(crosshairValues[0].rawDate);
  const weekStart = moment(date).startOf("week");
  const weekEnd = moment(date).endOf("week");

  // refactoring possible here -- we should consume isoweekdate from the query and use
  // data from the store instead of passing around a messy object in props

  // create an object with the labels to data we care about
  const numSessionsMap: { [label in BillableHoursDataLabels]: number } = {
    utilization: 0,
    therapy_scheduled: 0,
    therapy_attended: 0,
    no_show_therapy: 0,
    no_show_consult: 0,
    consult_scheduled: 0,
    consult_attended: 0,
    couples_attended: 0,
    couples_scheduled: 0,
    no_show_couples: 0,
    remaining_consult_allocation: 0,
  };

  crosshairValues.forEach((value) => {
    if (!value.label) {
      return;
    }
    numSessionsMap[value.label] =
      value.discreteValue === null || value.discreteValue === undefined
        ? value.rawValue
        : value.discreteValue;
  });

  const {
    utilization,
    therapy_scheduled,
    therapy_attended,
    no_show_therapy,
    no_show_consult,
    consult_scheduled,
    consult_attended,
    remaining_consult_allocation,
    couples_attended,
    couples_scheduled,
    no_show_couples,
  } = numSessionsMap;

  const weekPtoHours =
    ptoHours[moment(date).add(3, "days").format(ISO_WEEKDATE_FORMAT)];

  // rounds the remaining alloc if it is not an integer; with all values below 0 rounding up
  const remainingConsultAllocString = Number.isInteger(
    remaining_consult_allocation,
  )
    ? remaining_consult_allocation
    : remaining_consult_allocation > 0 && remaining_consult_allocation < 1
    ? "~1"
    : `~${Math.round(remaining_consult_allocation)}`;

  let userDoesConsults: boolean = false;
  if (
    [
      remaining_consult_allocation,
      consult_scheduled,
      consult_attended,
      no_show_consult,
    ].some((e) => e !== 0)
  ) {
    userDoesConsults = true;
  }

  let userDoesCouplesTherapy: boolean = false;
  if (
    [couples_attended, couples_scheduled, no_show_couples].some((e) => e !== 0)
  ) {
    userDoesCouplesTherapy = true;
  }

  const allocationLabel = weekEnd.isBefore(moment())
    ? "Unattended"
    : "Remaining Alloc.";

  return (
    <TooltipContainer>
      <h4>
        Week of {weekStart.format("MM/DD")} - {weekEnd.format("MM/DD")}
      </h4>
      <TooltipValues>
        <CrosshairValue
          style={{
            borderBottom: `1px solid ${$greyBorder}`,
            paddingBottom: ".3em",
            marginBottom: ".4em",
            boxSizing: "border-box",
          }}
          color={UTILIZATION_COLOR}
          label="Utilization"
          value={`${(utilization * 100).toFixed(1)}%`}
        />

        <h5>Therapy</h5>

        {therapy_scheduled > 0 && (
          <CrosshairValue
            color={THERAPY_COLOR}
            label="Scheduled"
            value={therapy_scheduled}
          />
        )}
        <CrosshairValue
          color={THERAPY_COLOR}
          label="Attended"
          value={therapy_attended}
        />
        <CrosshairValue
          color={NOSHOW_COLOR}
          label="No Show"
          value={no_show_therapy}
        />

        {userDoesConsults && (
          <>
            <h5>Consults</h5>
            <CrosshairValue
              color={CONSULT_ALLOCATION_COLOR}
              value={remainingConsultAllocString}
              label={allocationLabel}
            />
            {consult_scheduled > 0 && (
              <CrosshairValue
                color={CONSULT_COLOR}
                label="Scheduled"
                value={consult_scheduled}
              />
            )}
            <CrosshairValue
              color={CONSULT_COLOR}
              label="Attended"
              value={consult_attended}
            />
            <CrosshairValue
              color={NOSHOW_COLOR}
              label="No Show"
              value={no_show_consult}
            />
          </>
        )}
        {userDoesCouplesTherapy && (
          <>
            <h5>Couples</h5>
            {couples_scheduled > 0 && (
              <CrosshairValue
                color={COUPLES_THERAPY_COLOR}
                label="Scheduled"
                value={couples_scheduled}
              />
            )}
            <CrosshairValue
              color={COUPLES_THERAPY_COLOR}
              label="Attended"
              value={couples_attended}
            />
            <CrosshairValue
              color={NOSHOW_COLOR}
              label="No Show"
              value={no_show_couples}
            />
          </>
        )}
        {!!(weekPtoHours && weekPtoHours > 0) && (
          <>
            <h5>Time Off</h5>
            <CrosshairValue color={"#FFF"} label="Hours" value={weekPtoHours} />
          </>
        )}
      </TooltipValues>
    </TooltipContainer>
  );
}

function CrosshairValue(props: {
  color?: string;
  value: number | string;
  label: string;
  style?: React.CSSProperties;
}) {
  return (
    <>
      <span style={props.style}>
        <TooltipDot color={props.color} />
        {props.label}
      </span>
      <span style={props.style}>{props.value}</span>
    </>
  );
}

function crosshairValueTransformer(v: RectSeriesPoint) {
  try {
    return {
      x: moment(v.rawDate).toDate(),
      y: v.y,
      rawDate: v.rawDate,
      rawValue: v.rawValue,
      discreteValue: v.discreteValue,
      label: v.label,
      isoWeek: v.isoWeek,
    };
  } catch (error) {
    return { x: 0, y: 0 };
  }
}

export const Container = styled.div`
  grid-column: 1 / -1;
  margin-top: 8px;
  padding: 0px 16px 8px;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid ${$greyBorder};
  min-height: 60px;
`;

export const DataContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;

  .consultsScheduledSeries {
    > rect {
      fill: url(#svgoverlaypattern_consult) ${CONSULT_COLOR} !important;
    }
  }

  .therapyScheduledSeries {
    > rect {
      fill: url(#svgoverlaypattern_therapy) ${THERAPY_COLOR} !important;
    }
  }

  .couplesTherapyScheduledSeries {
    > rect {
      fill: url(#svgoverlaypattern_couplesTherapy) ${COUPLES_THERAPY_COLOR} !important;
    }
  }

  .consultsAllocatedSeries {
    > rect {
      stroke: ${CONSULT_COLOR} !important;
      strokewidth: 1px !important;
    }
  }

  .rv-xy-plot__series,
  .rv-xy-plot__series path {
    pointer-events: stroke !important;
  }
`;

const SVGGradientOverlay = (props: {
  idLabel: string;
  patternBGColor: string;
}) => (
  <svg
    style={{ width: 0, height: 0, position: "absolute" }}
    aria-hidden="true"
    focusable="false"
  >
    <pattern
      id={"svgoverlaypattern_" + props.idLabel}
      width="10"
      height="12"
      patternUnits="userSpaceOnUse"
      patternTransform="rotate(45 50 50)"
    >
      <rect width="10" height="12" fill={props.patternBGColor} />
      <line stroke="rgba(255, 255, 255, 0.35)" strokeWidth="10px" y2="12" />
    </pattern>
  </svg>
);

export default BillableHoursChart;
