import { Flex, styledStitches } from "@/app/design-system";
import { useDispatchPromise } from "@/app/_helpers/redux";
import { Column, Row } from "@/app/_layout/Flex";
import {
  ExclamationOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Button, Spin, Tooltip } from "antd";
import moment from "moment";
import * as React from "react";
import { Chart } from "./types";
import { surveyResponseByEvent } from "./utils";
import {
  ResponseSummaryCards,
  SurveyCardsSubheader,
} from "./response-summary-cards/response-summary-cards";
import { CurrentUserContext, CurrentConfigContext } from "@/app/app.utils";
import { userCanAccessHealthie } from "@/app/_helpers/permissions";

type Answer = {
  answer: string;
  question: string;
  score_value: number;
};

type SurveyResponse = {
  name: "mhqol" | "swls" | "alliance" | "gad7" | "phq9";
  answers: Answer[];
  score: number;
};

const HURTING_YOURSELF_TEXT: string = "hurting yourself";
const NOT_AT_ALL_TEXT: string = "not at all";
const ALLIANCE_CHART_INDEX = 2;
// TODO(jeffreyqiu): rename the swls chart index once we replace the swls survey/chart with the mhqol survey/chart
const SWLS_CHART_INDEX = 3;

const SURVEY_QUESTION_SUMMARY_WORDS = {
  gad7: {
    1: "NERVOUSNESS",
    2: "CONTROL OVER WORRY",
    3: "AMOUNT OF WORRY",
    4: "RELAXATION",
    5: "RESTLESSNESS",
    6: "IRRITABILITY",
    7: "FEAR",
  },
  phq9: {
    1: "INTEREST & ENJOYMENT",
    2: "MOOD",
    3: "SLEEP",
    4: "ENERGY",
    5: "EATING",
    6: "SELF-ESTEEM",
    7: "CONCENTRATION",
    8: "PHYSICAL FEELINGS",
    9: "N/A",
  },
};

const TitleHeader = styledStitches("h3", {
  margin: "12px 16px",
  fontSize: "18px",
  lineHeight: "27px",
  fontWeight: 700,
});

const getMHQOLTotalPointsString = (responses: SurveyResponse) => {
  const mhqolSurveyScore = responses.score
  if (mhqolSurveyScore >= 0 && mhqolSurveyScore <= 9) {
    return `${mhqolSurveyScore} - low quality of life`;
  } else if (mhqolSurveyScore >= 10 && mhqolSurveyScore <= 15) {
    return `${mhqolSurveyScore} - moderate quality of life`;
  } else if (mhqolSurveyScore >= 16 && mhqolSurveyScore <= 21) {
    return `${mhqolSurveyScore} - high quality of life`;
  }
  return "";
};

const getMHQOLAnswerScoreValue = (responses: SurveyResponse, index: number) => {
  const wellBeingScoreIndex = responses.answers.length - 1;
  if (index !== wellBeingScoreIndex) {
    return responses.answers[index].score_value;
  } else {
    return "n/a";
  }
};

export const MBCSurveyResponses = ({
  chart,
  welkinLink,
  healthieLink,
  onSetEndorsedSi,
  selectedChart,
}: {
  chart: Chart;
  welkinLink?: string;
  healthieLink?: string;
  onSetEndorsedSi: (event: string, v: boolean) => void;
  selectedChart?: number;
}) => {
  const filteredData = chart.data.filter((item) => item.value !== null);
  const initial = filteredData[filteredData.length - 1];
  const [event, setEvent] = React.useState(initial?.event_id);
  const [survey, setSurvey] = React.useState<any>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [symptomCardIndex, setSymptomCardIndex] = React.useState<number>(0);
  const dispatch = useDispatchPromise();

  React.useEffect(() => {
    const fetchSurvey = async (eventId: string) => {
      setIsLoading(true);
      const res = await dispatch(surveyResponseByEvent(eventId));

      const phqRes = res.data?.results[0]?.responses.find(
        (item: any) => item.name === "phq9",
      );

      if (phqRes) {
        const siQuestion =
          phqRes.answers &&
          phqRes.answers.find(
            (item: any) =>
              item.question.toLowerCase().indexOf(HURTING_YOURSELF_TEXT) > -1,
          );
        const endorsedSi =
          siQuestion &&
          siQuestion.answer.toLowerCase().indexOf(NOT_AT_ALL_TEXT) === -1;
        onSetEndorsedSi(eventId, endorsedSi);
      }

      if (res.data.results?.length) {
        setSurvey(res.data.results[0].responses);
      }

      setIsLoading(false);
    };

    if (event) {
      fetchSurvey(event);
    }

    // useeffect cleanup function to prevent react error:
    // Can't perform a React state update on an unmounted component.
    // This is a no-op, but it indicates a memory leak in your application.
    return () => {};
  }, [dispatch, event, onSetEndorsedSi]);

  const responses: SurveyResponse = survey
    ? survey.find(
        (item: any) =>
          chart.name.toLowerCase().indexOf(item.name) > -1 ||
          chart?.track.toLowerCase().indexOf(item.name) > -1,
      )
    : [];

  const currentEventIdx = filteredData.findIndex(
    (item) => item.event_id === event,
  );

  const prevAllowed = currentEventIdx > 0;
  const nextAllowed = currentEventIdx !== filteredData.length - 1;

  const selectedEventData = chart.data.find((item) => item.event_id === event);
  const onPrev = () => {
    if (prevAllowed) {
      setEvent(filteredData[currentEventIdx - 1].event_id);
      setSymptomCardIndex(symptomCardIndex + 1);
    }
  };

  const onNext = () => {
    if (nextAllowed) {
      setEvent(filteredData[currentEventIdx + 1].event_id);
      setSymptomCardIndex(symptomCardIndex - 1);
    }
  };

  const cuser = React.useContext(CurrentUserContext);
  const config = React.useContext(CurrentConfigContext);

  const hasHealthieAccess = userCanAccessHealthie(cuser, config);

  return (
    <Spin spinning={isLoading}>
      <Column layout="start start">
        {event && selectedEventData && (
          <TitleHeader>
            Survey responses from{" "}
            {moment(selectedEventData.start_time).format("MM/DD/YY")}
          </TitleHeader>
        )}
        {/* The symptomCardIndex is supposed to tell us the nth most recent event that is currently selected.
         * selectedChart === 2 is the Alliance chart, and selectedChart === 3 is the SWLS/MHQOL chart.
         * Hide the ResponseSummaryCards for these charts because the symptom cards are not relevant.
         * TODO(jeffreyqiu): refactor useClientMBCMetrics to refer to the chart name instead of the chart index.
         */}
        {!(
          selectedChart === ALLIANCE_CHART_INDEX ||
          selectedChart === SWLS_CHART_INDEX
        ) && <ResponseSummaryCards answersIndex={symptomCardIndex} />}
        {selectedChart !== SWLS_CHART_INDEX ? (
          <SurveyCardsSubheader
            flexDirection="row"
            css={{ marginLeft: "16px" }}
          >
            Survey responses
          </SurveyCardsSubheader>
        ) : (
          <>
            <SurveyCardsSubheader
              flexDirection="row"
              css={{
                marginLeft: "16px",
                fontSize: "14px",
                paddingBottom: "0px",
                marginBottom: "0px",
              }}
            >
              Question asked: Please indicate below which statements best
              describe your situation today
            </SurveyCardsSubheader>
            <SurveyCardsSubheader
              flexDirection="row"
              css={{ marginLeft: "16px", fontSize: "14px" }}
            >
              Score key: Answers scored on a scale from 0 to 3. Low quality is 0
              and high quality is 3.
            </SurveyCardsSubheader>
          </>
        )}
        <Flex
          css={{
            display: "none",
            "@breakpoint1200": {
              display: "block",
            },
          }}
        >
          <MBCSurveyResponseKey chart={chart} />
        </Flex>
        <Row layout="start start" style={{ padding: 16 }}>
          <Column layout="start start">
            {(!survey || !responses) &&
              welkinLink &&
              !isLoading &&
              !hasHealthieAccess && (
                <p>
                  We're unable to show this survey response. Please view this in
                  the{" "}
                  <a
                    href={welkinLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    client's Welkin chart
                  </a>
                </p>
              )}
            {(!survey || !responses) &&
              healthieLink &&
              !isLoading &&
              hasHealthieAccess && (
                <p>
                  We're unable to show this survey response. Please view this in
                  the{" "}
                  <a
                    href={healthieLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    client's Healthie chart
                  </a>
                </p>
              )}
            {survey && responses && (
              <table
                style={{
                  display: "block",
                }}
              >
                <tbody style={{ display: "block" }}>
                  {responses.answers.map((answer: any, index: number) => (
                    <tr
                      key={answer.question}
                      style={{ border: "1px solid #F0F0F0" }}
                    >
                      <td
                        style={{
                          borderRight: "1px solid #F0F0F0",
                          padding: 12,
                          fontSize: 14,
                          maxWidth: 600,
                          minWidth: 200,
                        }}
                      >
                        <span style={{ fontWeight: 500 }}>{index + 1}.</span>{" "}
                        {answer.question}
                        {answer.question
                          .toLowerCase()
                          .indexOf(HURTING_YOURSELF_TEXT) > -1 &&
                          answer.answer
                            .toLowerCase()
                            .indexOf(NOT_AT_ALL_TEXT) === -1 && (
                            <Tooltip
                              placement="top"
                              title="Client endorsed SI; conduct risk assessment and complete safety plan"
                            >
                              <ExclamationOutlined
                                style={{
                                  float: "right",
                                  margin: "2px auto",
                                  backgroundColor: "#D17134",
                                  borderRadius: 8,
                                  color: "#fff",
                                  fontSize: 16,
                                }}
                              />
                            </Tooltip>
                          )}
                      </td>
                      <td
                        style={{
                          padding: "2px 6px",
                          minWidth: 100,
                          borderRight: "1px solid #F0F0F0",
                        }}
                      >
                        {answer.answer}
                      </td>
                      <td
                        style={{
                          padding: "2px 6px",
                          minWidth: 100,
                          borderRight: "1px solid #F0F0F0",
                        }}
                      >
                        {responses?.name === "mhqol" && Number.isInteger(answer?.score_value)
                          ? getMHQOLAnswerScoreValue(responses, index)
                          : SURVEY_QUESTION_SUMMARY_WORDS?.[responses.name]?.[
                              index + 1
                            ]}
                      </td>
                    </tr>
                  ))}
                  {responses?.name === "mhqol" && (
                    <tr style={{ border: "1px solid #F0F0F0" }}>
                      <td
                        style={{
                          padding: "12px",
                          fontSize: 14,
                          textAlign: "right",
                        }}
                        colSpan={3}
                      >
                        Total Points: {getMHQOLTotalPointsString(responses)}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
            <div style={{ marginTop: 22 }}>
              <Button
                disabled={!prevAllowed}
                type="default"
                style={{ marginRight: 8 }}
                onClick={onPrev}
              >
                <LeftOutlined />
                Previous
              </Button>
              <Button disabled={!nextAllowed} type="default" onClick={onNext}>
                Next
                <RightOutlined />
              </Button>
            </div>
          </Column>
          <Flex
            css={{
              "@breakpoint1200": {
                display: "none",
              },
            }}
          >
            <MBCSurveyResponseKey chart={chart} />
          </Flex>
        </Row>
      </Column>
    </Spin>
  );
};

interface MBCSurveyResponseKeyProps {
  chart: Chart;
}

function MBCSurveyResponseKey({ chart }: MBCSurveyResponseKeyProps) {
  const questions = {
    gad: "Over the last 2 weeks, how often have you been bothered by any of the following problems?",
    phq: "Over the last 2 weeks, how often have you been bothered by any of the following problems?",
    swls: "To what extent do you agree or disagree with the following statements?",
    alliance: "Please reflect on your experience in your most recent session.",
  };

  if (chart?.track === "mhqol") {
    return null;
  }

  return (
    <Column layout="start start">
      <div
        style={{
          flexDirection: "column",
          minWidth: 230,
          maxWidth: 250,
          marginLeft: 22,
        }}
      >
        <p
          style={{
            color: "#8C8C8C",
            textTransform: "uppercase",
            fontSize: 12,
            fontWeight: 400,
            lineHeight: "24px",
          }}
        >
          Survey Response Key
        </p>
        <div>
          <span
            style={{
              fontSize: 13,
              fontWeight: 500,
              lineHeight: "24px",
              margin: 0,
              color: "#595959",
            }}
          >
            Question asked:{" "}
          </span>
          <span
            style={{
              lineHeight: "24px",
              fontSize: 12,
              margin: 0,
              color: "#595959",
            }}
          >
            {questions[chart.track || chart.name.toLowerCase()]}
          </span>
        </div>
      </div>
    </Column>
  );
}
