import { forwardRef } from "react";
import { colors } from "../../styles/config/colors";
import { IconProps } from "../icon.interface";

export const CheckIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ children, ...props }, forwardedRef) => {
    const { fill = colors.neutral0, width = 18, height = 18, title } = props;

    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 18 18"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
        ref={forwardedRef}
        aria-hidden={!title}
      >
        {title ? <title>{title}</title> : null}
        <path
          d="M15.7618 5.93196C16.1523 5.54144 16.1523 4.90827 15.7618 4.51775C15.3713 4.12722 14.7381 4.12722 14.3476 4.51775L15.7618 5.93196ZM6.80469 13.4749L6.09758 14.182C6.48811 14.5725 7.12127 14.5725 7.51179 14.182L6.80469 13.4749ZM3.76179 9.01775C3.37127 8.62722 2.73811 8.62722 2.34758 9.01775C1.95706 9.40827 1.95706 10.0414 2.34758 10.432L3.76179 9.01775ZM14.3476 4.51775L6.09758 12.7677L7.51179 14.182L15.7618 5.93196L14.3476 4.51775ZM7.51179 12.7677L3.76179 9.01775L2.34758 10.432L6.09758 14.182L7.51179 12.7677Z"
          fill={fill}
        />
      </svg>
    );
  },
);
