import { matchmakerOperations } from "@/state/models/matchmaker";
import React from "react";
import { useDispatch } from "react-redux";

export const useAutoOpenMatchmaker = () => {
  const dispatch = useDispatch()
  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const matchmakerClientId = params.get('matchmakerClientId');
    if (matchmakerClientId) {
      dispatch(matchmakerOperations.openMatchmakerByClientId(matchmakerClientId!))
    }
  }, []);
}