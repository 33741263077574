import * as React from "react";
import { useDispatch } from "react-redux";
import { useShallowEqualSelector } from "../../../../_helpers/redux";
import { Title } from "../../../../_layout/display";
import { consultNoteActions } from "../../../../../state/models/consultnote";
import { ConsultNoteQuestion } from "../../../../../api/types";
import { RequiredText } from "../../../../_shared/RequiredText";
import { labels } from "./copy";
import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { Flex, Row } from "../../../../_layout/Flex";
import { getCurrentConsultNoteInfo } from "@/app/consults-and-matching/_hooks";

const questionNumberToFieldsMap = {
  32: [
    "agitated",
    "animated",
    "ashamed",
    "constricted",
    "determined",
    "euphoric",
    "inspired",
    "irritable",
    "nervous",
    "sad",
    "tearful",
  ],
  33: ["linear_and_goal_directed", "disorganized", "racing"],
  34: [
    "apathetic",
    "defensive",
    "engaged",
    "guarded",
    "hostile",
    "open",
    "suspicious",
  ],
};

interface Props {
  question: ConsultNoteQuestion;
  handleEdit: (hasBeenEdited: boolean) => void;
}

export default ({ question, handleEdit }: Props) => {
  const { readOnlyConsultNote } = getCurrentConsultNoteInfo();

  const mental_status = useShallowEqualSelector(
    (state) => state.consultnote?.openConsult?.consult_note?.mental_status,
  );
  const labelsText = labels[question.questionNumber];

  const dispatch = useDispatch();

  const handleChange = (
    key: string,
    value: boolean | string,
    otherKey: string,
  ) => {
    // If the other box is currently checked, we turn that one "off"
    if (value && mental_status?.[otherKey]) {
      dispatch(
        consultNoteActions.updateRelatedObjectField({
          relatedObjectName: "mental_status",
          key: otherKey,
          value: false,
        }),
      );
    }
    dispatch(
      consultNoteActions.updateRelatedObjectField({
        relatedObjectName: "mental_status",
        key,
        value,
      }),
    );
    handleEdit(true);
  };

  return (
    <div style={{ paddingTop: "15px", paddingBottom: "15px" }}>
      <Title margin="3px" size="sm">
        <b>
          {question.questionNumber}. {question.title}:
        </b>
        {question.subtitle}
        {question.isRequired && <RequiredText> *</RequiredText>}
        <div style={{ fontSize: "12px", margin: "6px 0" }}>
          <i>Note: Only select if condition is present.</i>
        </div>
      </Title>

      {labelsText.map((text: any, index: number) => {
        const itemLittleKey = `${
          questionNumberToFieldsMap[question.questionNumber][index]
        }_little`;
        const itemLotKey = `${
          questionNumberToFieldsMap[question.questionNumber][index]
        }_lot`;
        return (
          <Row
            layout="start start"
            key={index}
            style={{ marginBottom: "10px" }}
          >
            <Flex style={{ paddingRight: "50px", flex: "0 0 55%" }}>
              {text}
            </Flex>
            <Flex style={{ flex: "0 0 20%" }}>
              <Checkbox
                disabled={readOnlyConsultNote}
                checked={mental_status?.[itemLittleKey]}
                onChange={(e: CheckboxChangeEvent) =>
                  handleChange(itemLittleKey, e.target.checked, itemLotKey)
                }
                style={{ paddingRight: "10px" }}
              >
                A little
              </Checkbox>
            </Flex>
            <Flex style={{ flex: "0 0 20%" }}>
              <Checkbox
                disabled={readOnlyConsultNote}
                checked={mental_status?.[itemLotKey]}
                onChange={(e: CheckboxChangeEvent) =>
                  handleChange(itemLotKey, e.target.checked, itemLittleKey)
                }
                style={{ paddingRight: "10px" }}
              >
                A lot
              </Checkbox>
            </Flex>
          </Row>
        );
      })}
    </div>
  );
};
