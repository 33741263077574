import * as React from "react";
import * as ReactRedux from "react-redux";
import { AppState } from "../../state/models";
import * as colors from "../../assets/colors";

import { ShopOutlined } from "@ant-design/icons";

import { Tag as AntdTag } from "antd";
import { Clinic, ClinicPreference } from "../../api/types";
import { Title } from "../_layout/display";

interface OwnProps {
  clinicPreferences: ClinicPreference[];
  highlightedClinicIds?: number[];
}
interface StateProps {
  clinicMap: { [id: number]: Clinic };
}

interface Props extends OwnProps, StateProps {}

class ClinicPreferenceList extends React.Component<Props> {
  public render() {
    const highlightedClinicIds = this.props.highlightedClinicIds || [];
    return (
      <>
        <Title size="xs">Preferred Clinics</Title>
        {this.props.clinicPreferences.map(
          (cp: ClinicPreference, idx: number) => (
            <AntdTag
              key={idx}
              style={{
                cursor: "default",
                marginBottom: "10px",
                color: "inherit",
              }}
              color={
                highlightedClinicIds.includes(cp.clinic_id)
                  ? colors.$secondary
                  : ""
              }
            >
              <ShopOutlined /> {this.props.clinicMap[cp.clinic_id].display_name}
            </AntdTag>
          ),
        )}
      </>
    );
  }
}

const mapStateToProps: ReactRedux.MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = (state) => {
  return {
    clinicMap: state.clinics.clinicMap,
  };
};

export default ReactRedux.connect(mapStateToProps)(ClinicPreferenceList);
