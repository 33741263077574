import * as React from "react";
import { useGetClientAssessments } from "@/app/my-clients/api/use-search-clients/use-get-client-assessments";
import { styledStitches } from "@/app/design-system/styles/stitches.config";
import {
  AssessmentAnswer,
  formatByQuestionSlug,
  getImprovedandWorseningResults,
} from "./utilities";
import { useMatch as useReactRouterMatch } from "react-router-dom";
import { media } from "./_helpers";
import { Flex } from "@/app/design-system";
import SurveyCard from "./SurveyCard";

const CardWrapper = styledStitches("div", {
  padding: "0px",
});

const GridContainer = styledStitches("div", {
  display: "grid",
  gridTemplateColumns: "auto auto auto auto auto auto",
  // gridTemplateColumns: "auto auto auto",
  gridColumnGap: "10px",
  gridRowGap: "10px",
  marginBottom: "24px",

  [media.tablet]: {
    gridTemplateColumns: "auto auto",
    gridColumnGap: "9px",
    gridRowGap: "12px",
  },
});

export const SurveyCardsSubheader = styledStitches(Flex, {
  fontSize: "18px",
  marginBottom: "12px",
  [media.tablet]: {
    padding: "26px 0 16px 0",
    alignSelf: "start",
    fontWeight: 500,
  },
});

// The offset is supposed to tell us the nth most recent event that is currently selected
export const ResponseSummaryCards = ({
  answersIndex,
}: {
  answersIndex: number;
}) => {
  const [activeSurveyCard, setActiveSurveyCard] = React.useState<string>("");

  const match = useReactRouterMatch("/my-clients/:clientId/mbc");
  const clientId = match?.params["clientId"];
  const { data: clientAssessmentData, isLoading: isClientAssessmentLoading } =
    useGetClientAssessments({ clientId: Number(clientId) });

  let scores: Array<AssessmentAnswer>;
  let improvedandWorseningResults;

  // @ts-ignore
  if (clientAssessmentData?.mbc_assessment_question_scores) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    scores = clientAssessmentData.mbc_assessment_question_scores;
    if (scores) {
      const formattedAnswers = formatByQuestionSlug(scores);
      if (formattedAnswers) {
        improvedandWorseningResults = getImprovedandWorseningResults(
          formattedAnswers,
          answersIndex,
        );
      }
    }
  }

  return (
    <Flex
      alignItems="flex-start"
      flexDirection="column"
      style={{ margin: "12px 16px" }}
    >
      {!improvedandWorseningResults ||
      (!improvedandWorseningResults?.improvedResults.length &&
        !improvedandWorseningResults?.worseningResults.length) ? (
        <Flex css={{ [media.tablet]: { marginTop: "20px" } }}>
          Looks like things are about the same as last session.
        </Flex>
      ) : (
        <>
          {improvedandWorseningResults?.improvedResults &&
            improvedandWorseningResults?.improvedResults.length > 0 && (
              <>
                <SurveyCardsSubheader flexDirection="row">
                  Improved symptoms since last survey
                </SurveyCardsSubheader>
                <GridContainer>
                  {improvedandWorseningResults?.improvedResults.map(
                    (improvedResult) => {
                      return (
                        <CardWrapper
                          onClick={() =>
                            setActiveSurveyCard(
                              activeSurveyCard === improvedResult.slug
                                ? ""
                                : improvedResult.slug,
                            )
                          }
                        >
                          <SurveyCard
                            imageUrl={`/images/responses-summary/positive/${improvedResult.slug}.svg`}
                            title={improvedResult.title}
                            id={improvedResult.slug}
                            activeCard={activeSurveyCard}
                            description={improvedResult.question_text}
                            isPositive
                          />
                        </CardWrapper>
                      );
                    },
                  )}
                </GridContainer>
              </>
            )}
          {improvedandWorseningResults?.worseningResults &&
            improvedandWorseningResults?.worseningResults.length > 0 && (
              <>
                <SurveyCardsSubheader flexDirection="row">
                  Declined symptoms since last survey
                </SurveyCardsSubheader>
                <GridContainer>
                  {improvedandWorseningResults?.worseningResults.map(
                    (worseningResult) => {
                      return (
                        <CardWrapper
                          onClick={() =>
                            setActiveSurveyCard(
                              activeSurveyCard === worseningResult.slug
                                ? ""
                                : worseningResult.slug,
                            )
                          }
                        >
                          <SurveyCard
                            activeCard={activeSurveyCard}
                            imageUrl={`/images/responses-summary/negative/${worseningResult.slug}.svg`}
                            title={worseningResult.title}
                            id={worseningResult.slug}
                            description={worseningResult.question_text}
                            isPositive={false}
                          />
                        </CardWrapper>
                      );
                    },
                  )}
                </GridContainer>
              </>
            )}
        </>
      )}
    </Flex>
  );
};
