import React from "react";
import { ClientOverviewApiData } from "@/app/api/use-my-clients-overview/my-clients-data.interface";
import { useGetEpisodesOfCare } from "@/app/client/use-mbc/use-get-mbc-episodes-of-care";
import { ClientMBCMetrics } from "./client-mbc-metrics/client-mbc-metrics";
import { formatCharts } from "./utils";

import { useUserHasAnyPermissions, Permissions } from "@/app/_helpers/permissions";
import { UseGetMBC } from "@/app/client/use-mbc/use-get-mbc";
import { mergeMBCData } from "@/app/eoc-utils";
import { CurrentUserContext } from "@/app/app.utils";
import { CurrentConfigContext } from "@/app/app.utils";

interface ClientProfileProps {
  client: ClientOverviewApiData | null;
}

const useBigQuery = false;

export const MBCPage = ({ client }: ClientProfileProps) => {
  const getPageData = () => {
    let isLoading = true;
    let formattedCharts;
    if (client) {
      const IsCareEpisodesUser = useUserHasAnyPermissions([
        "IsCareEpisodesUser",
      ]);
      const cuser = React.useContext(CurrentUserContext);
      const config = React.useContext(CurrentConfigContext);

      
      const IsMHQOLSurveyUser = Permissions.IsMHQOLSurveyUser(cuser, config);
      const mbcResponse = UseGetMBC({ clientId: client?.clientId });
      let episodesOfCareResponse;

      if (IsCareEpisodesUser && useBigQuery) {
        episodesOfCareResponse = useGetEpisodesOfCare({
          clientIds: client?.clientId ? [client?.clientId] : [],
          enabled: true,
          select: (data) => {
            return data.dw_export_episode_of_care;
          },
        });
        isLoading = episodesOfCareResponse.isLoading || mbcResponse.isLoading;
      } else {
        isLoading = mbcResponse.isLoading;
      }

      if (!isLoading) {
        formattedCharts = formatCharts(
          client,
          mergeMBCData(
            mbcResponse.data,
            episodesOfCareResponse?.data ? episodesOfCareResponse.data : [],
          ),
          IsMHQOLSurveyUser,
        );
      }
    }
    return { charts: formattedCharts, isLoading: isLoading };
  };

  const { charts } = getPageData();
  return (
    <ClientMBCMetrics
      clientId={client?.clientId}
      primaryAssessment={client?.primaryAssessment || ""}
      charts={charts || []}
      welkinLink={client?.welkinLink || ""}
      healthieLink={client?.healthieLink || ""}
    />
  );
};
