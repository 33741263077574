import { CurrentUser } from "@/api/types";
import * as Sentry from "@sentry/react";
import * as React from "react";

import {
  extractTracePrefixFromCookie,
  extractTracePrefixFromQuery,
  setTracePrefixOnCookie,
} from "../api/tracePrefixInterceptor";

import { Config } from "@/api/types";

import { useShallowEqualSelector } from "./_helpers/redux";

export const withRouteSentryWrapper = <C extends {}>(
  Component: React.ComponentType<C>,
  name: string,
) => Sentry.withProfiler(Component, { name });

function applyTraceCookie() {
  const queryParamTracePrefix = extractTracePrefixFromQuery();

  if (!queryParamTracePrefix) {
    return;
  }

  const cookieTracePrefix = extractTracePrefixFromCookie();

  if (queryParamTracePrefix !== cookieTracePrefix) {
    setTracePrefixOnCookie(queryParamTracePrefix);
    window.location.reload();
  }
}

function prefetchQueries() {
  // queryClient.prefetchQuery(["MBCInsights", "all-clinicians"], () =>
  //   getMBCInsights(undefined),
  // );
}

export function useCarePlatform() {
  const { isAuthenticated } = useShallowEqualSelector((state) => ({
    isAuthenticated: state.auth.isAuthenticated,
  }));

  React.useEffect(() => {
    prefetchQueries();
    applyTraceCookie();
  }, []);

  return {
    isAuthenticated,
  };
}

export const CurrentUserContext = React.createContext<CurrentUser>({
  username: "",
  email: "",
  first_name: "",
  last_name: "",
  groups: [],
  permissions: [],
  clinician: undefined,
  isSuperUser: false,
  id: 0,
});

export const CurrentConfigContext = React.createContext<Config | undefined>({
  healthie_care_team_sync: false,
  healthie_client_address_broadcast: false,
  healthie_clinician_incremental_sync_enabled: false,
  healthie_emergency_contact_broadcast: false,
  healthie_patient_broadcast_enabled: false,
  healthie_patient_incremental_sync_enabled: false,
  healthie_ui_enabled: false,
  roofdog_enabled: false,
  mhqol_enabled: false,
});
