import * as React from "react";
import { useDispatch } from "react-redux";
import { Input, Select as DeprecatedAntdSelect } from "antd";
import { consultNoteOperations } from "../../../../../state/models/consultnote";
import { copy } from "./copy";
import { problemOptions, severityOptions, durationOptions } from "./enums";
import { Flex, Row } from "../../../../_layout/Flex";
import { PresentingProblem } from "../../../../../api/types";
import { SelectSearchProps } from "../shared/SelectSearchProps";
import useDebounce from "../../../../hooks/useDebounce";
import { getCurrentConsultNoteInfo } from "@/app/consults-and-matching/_hooks";

const problemToCopyMap = {
  anxiety_generalized: "gad",
  depression: "mdd",
  adjustment: "adj",
};

/**
 * @interface
 * @prop {number} orderNumber represents the order in which the subquestion appears on
 * the form the backend returns the last updated question first, so we want to maintain
 * the original order in which the questions are presented to the clinician
 * @prop {PresentingProblem | undefined} entry represents an entry for PresentingProblem use which
 * includes severity, details, etc. see PresentingProblem interface for more details.
 */

interface PresentingProblemsSubQuestionProps {
  orderNumber: number;
  entry: PresentingProblem | undefined;
  handleEdit: (hasBeenEdited: boolean) => void;
}

const PresentingProblemsSubQuestion = ({
  orderNumber,
  entry,
  handleEdit,
}: PresentingProblemsSubQuestionProps) => {
  const { readOnlyConsultNote } = getCurrentConsultNoteInfo();

  const [additionalDetails, setAdditionalDetails] = React.useState(
    entry?.additional_details ? entry?.additional_details : "",
  );

  const handleSetAdditionalDetails = (value: string) => {
    setAdditionalDetails(value);
    handleEdit(true);
  };

  const dispatch = useDispatch();

  const handleChange = (
    collection: string,
    id: string | undefined,
    key: string,
    value: string,
    order: number,
  ) => {
    handleEdit(true);
    dispatch(
      consultNoteOperations.updateNestedField({
        collection,
        id,
        key,
        value,
        order,
      }),
    );
  };

  const debouncedAdditionalDetails = useDebounce(additionalDetails, 500);

  React.useEffect(() => {
    dispatch(
      consultNoteOperations.updateNestedField({
        collection: "presentingproblems",
        id: entry?.id,
        key: "additional_details",
        value: additionalDetails,
        order: orderNumber,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedAdditionalDetails]);

  return (
    <div style={{ marginBottom: "30px" }}>
      <Row layout="start start">
        <Flex style={{ flex: "1", paddingRight: "20px" }}>
          <div>
            <span style={{ paddingRight: "10px" }}>{orderNumber})</span> Problem
          </div>
          <DeprecatedAntdSelect<string>
            disabled={readOnlyConsultNote}
            value={entry?.problem}
            style={{ width: "100%" }}
            onChange={(v) =>
              handleChange(
                "presentingproblems",
                entry?.id,
                "problem",
                v,
                orderNumber,
              )
            }
            {...SelectSearchProps}
          >
            {problemOptions.map((so) => {
              return (
                <DeprecatedAntdSelect.Option key={so.key} value={so.value}>
                  {so.title}
                </DeprecatedAntdSelect.Option>
              );
            })}
          </DeprecatedAntdSelect>
        </Flex>
        <Flex style={{ flex: "1", paddingRight: "20px" }}>
          <div>Severity</div>
          <DeprecatedAntdSelect<string>
            disabled={readOnlyConsultNote}
            style={{ width: "100%" }}
            value={entry?.severity}
            onChange={(v) =>
              handleChange(
                "presentingproblems",
                entry?.id,
                "severity",
                v,
                orderNumber,
              )
            }
            {...SelectSearchProps}
          >
            {severityOptions.map((so) => {
              return (
                <DeprecatedAntdSelect.Option key={so.key} value={so.value}>
                  {so.title}
                </DeprecatedAntdSelect.Option>
              );
            })}
          </DeprecatedAntdSelect>
        </Flex>

        <Flex style={{ flex: "1" }}>
          <div>Duration</div>
          <DeprecatedAntdSelect<string>
            disabled={readOnlyConsultNote}
            value={entry?.duration}
            style={{ width: "100%" }}
            onChange={(v) =>
              handleChange(
                "presentingproblems",
                entry?.id,
                "duration",
                v,
                orderNumber,
              )
            }
            {...SelectSearchProps}
          >
            {durationOptions.map((so) => {
              return (
                <DeprecatedAntdSelect.Option key={so.key} value={so.value}>
                  {so.title}
                </DeprecatedAntdSelect.Option>
              );
            })}
          </DeprecatedAntdSelect>
        </Flex>
      </Row>
      {entry && entry.problem in problemToCopyMap && (
        <div>
          <div
            dangerouslySetInnerHTML={{
              __html: copy[problemToCopyMap[entry.problem]],
            }}
            style={{
              fontSize: "14px",
              paddingTop: "15px",
              paddingBottom: "15px",
              color: "rgba(0, 0, 0, 0.85)",
            }}
          ></div>
        </div>
      )}
      <div style={{ paddingTop: "8px" }}>Additional details</div>
      <Input.TextArea
        disabled={readOnlyConsultNote}
        value={additionalDetails}
        onChange={(e) => handleSetAdditionalDetails(e.target.value)}
      />
    </div>
  );
};

export { PresentingProblemsSubQuestion };
