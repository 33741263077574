import { Flex, Text } from "@/app/design-system";
import {
  PersonIcon,
  RightChevronIcon,
  AccessibilityBadge,
  NewToTherapyBadge,
} from "@/app/design-system/icons";
import { useGroupTherapyUser } from "@/app/groups/_hooks/use-group-therapy-user";
import { useMyClientsSearchStore } from "@/app/my-clients/routes/my-clients-page/my-clients-model/my-clients.model";
import Mangle from "@/app/_shared/Mangle";
import { useParams } from "react-router-dom";
import { StyledGroupClientMbcPaneLink } from "../../sessions/session-id/mbc/_components/group-client-mbc-pane.styled";
import { ParsedGroupClient } from "../_api/use-group-therapy-clients-overview/use-group-therapy-clients.util";
import { ClientPronounAgeLocation } from "./client-pronoun-age-location";

export interface GroupClientPaneProps extends ParsedGroupClient {
  index?: number;
}

export const GroupClientPane = ({
  clientId,
  clientName,
  clientPronouns,
  age,
  cityAndState,
  isNewToTherapy,
  accessibilityNeeds,
}: GroupClientPaneProps) => {
  const { groupId } = useParams<{ groupId: string }>();
  const { clinicianId } = useGroupTherapyUser();
  const { currentlySelectedClinician } = useMyClientsSearchStore();

  const clinicianIdParam = clinicianId
    ? `clinician_id=${clinicianId}`
    : currentlySelectedClinician
    ? `clinician_id=${currentlySelectedClinician}`
    : "";

  return (
    <StyledGroupClientMbcPaneLink
      css={{
        "@breakpoint1200": {
          gap: 12,
        },
      }}
      to={`/groups/${groupId}/clients/${clientId}/profile?${clinicianIdParam}`}
    >
      <GroupClientPaneContent
        clientId={clientId}
        clientName={clientName}
        clientPronouns={clientPronouns}
        age={age}
        cityAndState={cityAndState}
        isNewToTherapy={isNewToTherapy}
        accessibilityNeeds={accessibilityNeeds}
      />
    </StyledGroupClientMbcPaneLink>
  );
};

export const GroupClientPaneContent = ({
  clientName,
  clientPronouns,
  age,
  cityAndState,
  isNewToTherapy,
  accessibilityNeeds,
}: GroupClientPaneProps) => (
  <Flex
    css={{
      "@breakpoint1200": {
        justifyContent: "space-between",
        gap: 30,
        flexDirection: "row",
      },
    }}
  >
    <Flex
      gap={8}
      css={{
        minWidth: 400,
      }}
    >
      <PersonIcon />

      <Text css={{ fontWeight: 600 }}>
        <Mangle>{clientName}</Mangle>
      </Text>
    </Flex>

    <ClientPronounAgeLocation
      clientPronouns={clientPronouns}
      age={age}
      cityAndState={cityAndState}
    />

    <Flex
      gap={8}
      css={{
        flexGrow: 1,
      }}
      alignItems={"center"}
      justifyContent={"flex-end"}
    >
      {isNewToTherapy ? (
        <Flex gap={4}>
          <NewToTherapyBadge />
        </Flex>
      ) : null}

      {accessibilityNeeds?.length !== 0 ? (
        <Flex gap={4}>
          <AccessibilityBadge />
        </Flex>
      ) : null}

      <RightChevronIcon className="chevron-right" />
    </Flex>
  </Flex>
);
