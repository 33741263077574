import { CSS_COLORS, Flex } from "@/app/design-system";
import {
  CircleCheckMarkIcon,
  CrossOutCircleIcon,
  TrashCanIcon,
} from "../../../../../../../../../../design-system/icons";

interface SessionPaneIconProps {
  appointmentStatus: string;
}

export const SessionPaneComposedIcon = ({
  appointmentStatus,
}: SessionPaneIconProps) => {
  const iconToAppointmentStatusMap = {
    attended: <CircleCheckMarkIcon />,
    canceled: <TrashCanIcon stroke={CSS_COLORS.neutral11} />,
    noshow: <CrossOutCircleIcon />,
    lateCancelByCLiewnt: <TrashCanIcon stroke={CSS_COLORS.red9} />,
  };

  return <Flex>{iconToAppointmentStatusMap[appointmentStatus]}</Flex>;
};
