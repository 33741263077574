import { useMainNavigationUtils } from "../../../hooks/use-main-navigation-utils";

import { ShoppingCartOutlined } from "@ant-design/icons";

import { NavigationMenuButton } from "../../components.utility/navigation-menu-button";

interface ShoppingCartMenuItemProps {
  isCollapsed: boolean;
}
export function ShoppingCartMenuItem({
  isCollapsed,
}: ShoppingCartMenuItemProps) {
  const { setShoppingCartIsOpen } = useMainNavigationUtils();

  return (
    <NavigationMenuButton
      callback={setShoppingCartIsOpen}
      menuItemTooltipContent="Shopping Cart"
      isCollapsed={isCollapsed}
    >
      <ShoppingCartOutlined
        style={{ strokeWidth: 2, fontSize: 20 }}
        aria-hidden={!isCollapsed}
        title={!isCollapsed ? "" : "Shopping Cart"}
      />
      {!isCollapsed ? "Shopping Cart" : null}
    </NavigationMenuButton>
  );
}
