import { useMyClientsSearchStore } from "@/app/my-clients/routes/my-clients-page/my-clients-model/my-clients.model";
import { dashboardActions } from "@/state/models/dashboard";
import { loadMDBWithClinicianId } from "@/state/models/dashboard/operations";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";

import { authOperations } from "../../../../../state/models/auth";
import { clinicianActions } from "../../../../../state/models/clinicians";
import { matchActions } from "../../../../../state/models/matches";
import { optionsOperations } from "../../../../../state/models/options";
import { useMbcCount } from "../../../../client/context";

export function useMainNavigationUtils() {
  const { alertCount } = useMbcCount();
  const dispatch = useDispatch();

  const toggleMangle = () => {
    dispatch(optionsOperations.toggleMangle());
  };

  const setAqmTunerIsOpen = () => {
    dispatch(matchActions.setAqmTunerIsOpen(true));
  };

  const setMatchAdminToolIsOpen = () => {
    dispatch(matchActions.setMatchAdminToolIsOpen(true));
  };

  const setShoppingCartIsOpen = () => {
    dispatch(matchActions.setShoppingCartIsOpen(true));
  };

  const setConsentSurveyModalIsOpen = () => {
    dispatch(clinicianActions.setConsentSurveyModalIsOpen(true));
  };

  return {
    alertCount,
    toggleMangle,
    setAqmTunerIsOpen,
    setMatchAdminToolIsOpen,
    setShoppingCartIsOpen,
    setConsentSurveyModalIsOpen,
  };
}

export function useLogout() {
  const dispatch = useDispatch();

  const queryClient = useQueryClient();
  const { reset } = useMyClientsSearchStore((state) => ({
    reset: state.reset,
  }));

  const logout = () => {
    queryClient.clear();
    reset();
    dispatch(authOperations.logout());
  };

  return { logout };
}
