import { Flex, styledStitches } from "@/app/design-system";
import { useLocation } from "react-router-dom";

import { CalendarIcon } from "@/app/design-system/icons";
import { PanelIcon } from "@/app/design-system/icons";
import { CornerRight } from "@/app/design-system/icons/components/corner-right";
import { STANDARD_FOCUS_OUTLINE } from "@/app/design-system/styles/config/css-constants";
import { QuestionCircleOutlined } from "@ant-design/icons";

import { DEFAULT_FLEX_STYLE } from "@/app/design-system/layout/flex";
import { NavMenuItem } from "../../components.utility/main-nav.styled";
import { NavigationMenuItem } from "../../components.utility/navigation-menu-item";
import { NavigationToolTip } from "../../components.utility/navigation-tooltip";

const PanelManagementUserGuideLink = styledStitches("a", {
  display: "flex",
  gap: 12,
  color: "$blue9",
  alignItems: "center",
  "&:focus": {
    outline: `${STANDARD_FOCUS_OUTLINE}`,
  },
});

const PanelManagementNavigationMenuItem = styledStitches(NavMenuItem, {
  ...DEFAULT_FLEX_STYLE,
  alignItems: "center",
  gap: "$space8",
});

interface PanelManagementMenuItemProps {
  isCollapsed: boolean;
}

export function PanelManagementMenuItem({
  isCollapsed,
}: PanelManagementMenuItemProps) {
  const location = useLocation();
  return (
    <>
      <NavigationMenuItem
        to="/panel-management"
        isCollapsed={isCollapsed}
        menuItemTooltipContent="Panel Management"
      >
        <PanelIcon
          height={20}
          width={20}
          aria-hidden={!isCollapsed}
          title={!isCollapsed ? "" : "Panel Management"}
        />
        {!isCollapsed ? "Panel Management" : null}
      </NavigationMenuItem>

      {location.pathname === "/panel-management" ? (
        <PanelManagementNavigationMenuItem
          css={{ pl: isCollapsed ? 20 : 24, pt: 4 }}
        >
          {!isCollapsed ? <CornerRight /> : null}

          <Flex gap={8} alignItems={"center"}>
            {!isCollapsed ? (
              <PanelManagementUserGuideLink
                href="https://docs.google.com/document/d/1eNH2pbI9Akq1ItBwTZe3o3lhgU-KJaXVW7kaqaM1l_c/edit?pli=1#heading=h.mpphizid5ab9"
                id="panManGuide"
                rel="noopener noreferrer"
                target="_blank"
              >
                User guide
              </PanelManagementUserGuideLink>
            ) : (
              <NavigationToolTip
                shouldRenderContent={true}
                content={"Panel management user guide link"}
                sideOffset={30}
              >
                <PanelManagementUserGuideLink
                  href="https://docs.google.com/document/d/1eNH2pbI9Akq1ItBwTZe3o3lhgU-KJaXVW7kaqaM1l_c/edit?pli=1#heading=h.mpphizid5ab9"
                  id="panManGuide"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <QuestionCircleOutlined
                    aria-hidden={!isCollapsed}
                    title={!isCollapsed ? "" : "Panel Management"}
                  />
                </PanelManagementUserGuideLink>
              </NavigationToolTip>
            )}
          </Flex>
        </PanelManagementNavigationMenuItem>
      ) : null}
    </>
  );
}
