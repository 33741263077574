import { Flex } from "../../../../../design-system";

import {
  useMyClientsStore,
  selectActiveFiltersSlice,
} from "../../my-clients-model";
import { useMyClientsSearchStore } from "../../my-clients-model/my-clients.model";
import { FilterTag, SearchTag } from "./filter-tag";

import { StyledClearButton } from "./filter-tag.styled";

import {
  filterTagStateToTagNameMap,
  filterTagNameToTagStateMap,
} from "./filter-tag.util";

export const FilterTagContainer = () => {
  const { activeMyClientsFilters } = useMyClientsStore(
    selectActiveFiltersSlice,
  );

  const { clearAllMyClientsFilters, clearMyClientsFilter } =
    useMyClientsStore();

  const { searchTermState, clientIds, setClientIds, setSearchTermState } =
    useMyClientsSearchStore();

  return (
    <Flex alignItems={"flex-start"} gap={16} flexWrap={"wrap"}>
      {clientIds.length > 0 ? <SearchTag searchTerm={searchTermState} /> : null}
      {activeMyClientsFilters
        .reverse()
        .map((filter) =>
          filterTagStateToTagNameMap[filter] ? (
            <FilterTag
              key={filter}
              tag={filterTagStateToTagNameMap[filter]}
              clearTagCallback={() =>
                clearMyClientsFilter(
                  filterTagNameToTagStateMap[
                    filterTagStateToTagNameMap[filter]
                  ],
                )
              }
            />
          ) : null,
        )}
      {activeMyClientsFilters.length > 0 || clientIds.length > 0 ? (
        <StyledClearButton
          onClick={() => {
            clearAllMyClientsFilters();
            setClientIds([]);
            setSearchTermState("");
          }}
          css={{ br: 8, cursor: "pointer" }}
        >
          Clear all
        </StyledClearButton>
      ) : null}
    </Flex>
  );
};
