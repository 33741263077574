import React from "react";
import { useShallowEqualSelector } from "@/app/_helpers/redux";
import { holdStatusSelector } from "@/state/models/clinicians";
import { ATSidebarContainer } from "./sidebar.styled";
import { createSelector } from "reselect";
import { AppState } from "@/state/models";
import { WeeklyVolumeData } from "../../WeeklyVolume";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";

import {
  ClinicianManualCapacityV2DataMap,
  ClinicianVolumeDataMap,
  volumeDataIsRamp,
} from "../../types";

import { MatchRequestFactors } from "./match-request-factors"
import { ClinicianMatchRequestWidget } from "./clinician-match-request-widget";

export const Sidebar = () => {
  const {
    clinicianId,
    billableHoursWeeks,
    isLoading,
    holdStatusMap,
    clinicianMap,
  } = useShallowEqualSelector((state) => ({
    clinicianId: state.slottool.clinicianId,
    billableHoursWeeks: state.slottool.billableHoursWeeks,
    isLoading: state.slottool.isLoading,
    holdStatusMap: holdStatusSelector(state.clinicians),
    clinicianMap: state.clinicians.clinicianMap,
  }));

  const volumeData = useSelector(volumeDataMapSelector);

  let content;
  if (clinicianId === undefined || !volumeData[clinicianId]) {
    content = null;
  } else {
    const clinicianVolumeData = volumeData[clinicianId];
    content = (
      <>
        <ClinicianMatchRequestWidget
          ramping={volumeDataIsRamp(clinicianVolumeData.rawData)}
          clinicianId={clinicianId}
          volumeData={volumeData[clinicianId]}
          hasPTONextWeek={false}
        />
        <MatchRequestFactors volumeData={clinicianVolumeData} />
      </>
    );
  }
  return <ATSidebarContainer>{content}</ATSidebarContainer>;
};

export const volumeDataMapSelector = createSelector(
  (state: AppState) => state.slottool.clinicianVolumeDataMap,
  (state: AppState) => state.slottool.clinicianManualCapacityDataMap,
  (state: AppState) => holdStatusSelector(state.clinicians),
  (state: AppState) => state.clinicians.clinicianMap,
  (
    clinicianVolumeDataMap,
    clinicianManualCapacityDataMap,
    holdStatusMap,
    clinicianMap,
  ) => {
    const volumeData: Record<number, WeeklyVolumeData> = {};

    for (const clinicianId of Object.keys(clinicianVolumeDataMap)) {
      const isNetwork =
        clinicianId in clinicianMap
          ? clinicianMap[clinicianId].is_network
          : false;

      const clinicianHold = holdStatusMap[parseInt(clinicianId)];

      const {
        volume,
        maxNewClients,
        matchesRequested,
        numAvailableSlots,
        targetPanelSize,
        manualCapacity,
        manualCapacitySetAt,
        rawData,
        source,
        hasPTONextWeek,
      } = computeVolumeForClinician(
        clinicianVolumeDataMap,
        parseInt(clinicianId),
        clinicianManualCapacityDataMap,
        isNetwork,
      );

      volumeData[clinicianId] = {
        volume,
        maxNewClients,
        matchesRequested,
        numAvailableSlots,
        targetPanelSize,
        manualCapacity,
        manualCapacitySetAt,
        rawData,
        source,
        hasPTONextWeek,
        hasActiveHold: !!(
          clinicianHold &&
          DateTime.fromISO(clinicianHold.start_at) <= DateTime.now()
        ),
      };
    }
    return volumeData;
  },
);

function computeVolumeForClinician(
  clinicianVolumeDataMap: ClinicianVolumeDataMap,
  clinicianId: number,
  clinicianManualCapacityDataMap: ClinicianManualCapacityV2DataMap,
  isNetwork: boolean,
) {
  const thisVolumeData = clinicianVolumeDataMap[clinicianId];
  const thisManualCapacity = clinicianManualCapacityDataMap[clinicianId];

  const volume = thisVolumeData.weekly_volume_score_with_pending_matches;
  const remainingVolume = thisVolumeData.remaining_volume;

  const targetPanelSize = thisVolumeData.target_client_hours;
  const maxNewClients = Math.max(0, remainingVolume);

  const numAvailableSlots =
    thisVolumeData.slots_data?.open_slots?.filter(
      (slot) => slot.recurrence === "weekly",
    ).length || 0;

  // AKA auto capacity:
  let matchesRequested = maxNewClients;

  const manualCapacity = thisManualCapacity?.capacity;
  const manualCapacitySetAt = thisManualCapacity?._date_start;
  return {
    volume,
    maxNewClients,
    matchesRequested,
    numAvailableSlots,
    targetPanelSize,
    manualCapacity,
    manualCapacitySetAt,
    rawData: thisVolumeData,
    source: thisVolumeData.source,
    hasPTONextWeek: volumeDataIsRamp(thisVolumeData)
      ? thisVolumeData.ramp_data?.has_pto_next_week ?? false
      : false,
  };
}
