import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../state/models";
import { matchmakerOperations } from "../../../../state/models/matchmaker";
import { Title } from "../../../_layout/display";
import TagSelect from "../../../_shared/FormControls/TagSelect";
import { BENEFIT_TAG_WEIGHT, TagWeight } from "../../../../api/constants";
import { Tag } from "../../../../api/types";
import { difference, isEmpty } from "lodash-es";

interface TagMap {
  [id: number]: Tag;
}

export default () => {
  const [consultTagIdsByWeight, tagMap] = useSelector((state: AppState) => [
    state.matchmaker.consultTagMap,
    state.matches.tagMap,
  ]);
  const dispatch = useDispatch();
  const setConsultTags = (tagMap: TagMap, weight: TagWeight) => {
    const existingConsultTagIds = consultTagIdsByWeight[weight];
    const newTagIds: number[] = Object.values(tagMap).map((t) => t.id);

    const differences = [
      ...difference(existingConsultTagIds, newTagIds),
      ...difference(newTagIds, existingConsultTagIds),
    ];

    if (!isEmpty(differences)) {
      const cts = {
        ...consultTagIdsByWeight,
        [weight]: newTagIds,
      };
      dispatch(matchmakerOperations.setConsultTags(cts));
    }
  };

  return (
    <>
      <Title>Benefits</Title>
      <TagSelect
        selectTagMap={(tagMap: TagMap) =>
          setConsultTags(tagMap, BENEFIT_TAG_WEIGHT)
        }
        selectedTags={consultTagIdsByWeight[BENEFIT_TAG_WEIGHT].map(
          (tId) => tagMap[tId],
        )}
        isCouplesMatch={true}
        tagsVersion={2}
        variant={"benefits"}
      />
    </>
  );
};
