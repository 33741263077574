export const MBCGraphLabel = (props: any) => {
  const { displayText, viewBox, fill } = props;
  const x = viewBox.width - viewBox.x - 50;
  const y = viewBox.y + 20;
  if (displayText) {
    return (
      <text x={x} y={y} fill={fill}>
        {displayText}
      </text>
    );
  }
  return null;
};
