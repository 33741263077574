import { Button, ModalDialog, Spinner, Text } from "@/app/design-system";
import { useNotification } from "@/app/design-system";
import { QuarterCircle } from "@/app/design-system";
import * as Portal from "@radix-ui/react-portal";
import React from "react";
import { useFormState } from "react-hook-form";
import { SessionModalDialogActionContent } from "./session-modal-dialog-content";
import { UpdateActionProps } from "./session-modal-dialog.interface";
import { useSessionModalDialogAction } from "./use-session-modal-dialog-action";

export const SessionModalDialogAction = <T extends object>({
  setIsDrawerOpen,
  mode,
  handleSubmit,
  eventId,
  control,
  trigger,
  values,
  disabled,
  isRecurringEvent,
  selectedSessions,
  event,
}: UpdateActionProps<T>) => {
  const {
    isModalDialogOpen,
    setIsModalDialogOpen,
    primaryButtonText,
    title,
    onSubmit,
    isLoading,
  } = useSessionModalDialogAction({
    mode,
    values,
    setIsDrawerOpen,
    handleSubmit,
    eventId,
    control,
    trigger,
    isRecurringEvent,
    selectedSessions,
  });

  const { isDirty } = useFormState({ control });

  const { renderNotification } = useNotification();

  const handleSaveClick = () => {
    onSubmit();
  };

  return (
    <ModalDialog.Root
      open={isModalDialogOpen}
      onOpenChange={(open) => {
        if (mode === "edit" && isDirty) {
          setIsModalDialogOpen(open);
        } else if (mode === "edit" && !isDirty) {
          renderNotification({
            message: "Please select a field to update.",
            notificationType: "warning",
          });
        } else if (
          mode === "cancel" &&
          values &&
          values["cancellationType"] === "Select" &&
          values["cancellationReason"] === "Select"
        ) {
          renderNotification({
            message:
              "Please select a cancellation type, then cancellation reason.",
            notificationType: "error",
          });
        } else if (
          mode === "cancel" &&
          values &&
          values["cancellationType"] !== "Select" &&
          values["cancellationReason"] === "Select"
        ) {
          renderNotification({
            message: "Please select a cancellation reason.",
            notificationType: "error",
          });
        } else {
          setIsModalDialogOpen(open);
        }
      }}
    >
      <ModalDialog.Trigger asChild>
        <Button
          size={"small"}
          css={{ minWidth: 100 }}
          disabled={disabled}
          onClick={() => trigger()}
        >
          <Text fontWeight={700} color={"$neutral0"}>
            {primaryButtonText}
          </Text>
        </Button>
      </ModalDialog.Trigger>

      <ModalDialog.Portal>
        <ModalDialog.Overlay />
        <ModalDialog.Content>
          <ModalDialog.Header title={title} />
          <SessionModalDialogActionContent
            mode={mode}
            selectedSessions={selectedSessions ?? ""}
            event={event}
          />
          <ModalDialog.Footer gap={12} justifyContent={"flex-end"}>
            <ModalDialog.Close asChild>
              <Button
                size={"small"}
                variant={"secondary"}
                css={{ border: "1px solid $neutral12" }}
                onClick={() => setIsModalDialogOpen(false)}
                disabled={isLoading}
              >
                <Text>Back to edit</Text>
              </Button>
            </ModalDialog.Close>
            <Button
              size={"small"}
              css={{ minWidth: 100 }}
              onClick={handleSaveClick}
              disabled={isLoading || disabled}
              backgroundColor={mode === "cancel" ? "$red11" : "$neutral12"}
            >
              {isLoading ? (
                <Spinner>
                  <QuarterCircle />
                </Spinner>
              ) : (
                <Text color={"$neutral0"}>Confirm</Text>
              )}
            </Button>
            <Portal.Root></Portal.Root>
          </ModalDialog.Footer>
        </ModalDialog.Content>
      </ModalDialog.Portal>
    </ModalDialog.Root>
  );
};
