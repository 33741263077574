import { useRef } from "react";
import create from "zustand";
import { CheckIconFilled, WarningIcon, XCircleIcon } from "../icons";
import { TadaCelebrateIcon } from "../icons/components/tada-celebrate-icon";
import { Text } from "@/app/design-system";
import {
  Toast,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from "../toast/toast.styled";

type NotificationType =
  | "success"
  | "error"
  | "warning"
  | "celebrate"
  | "default";

interface Notification {
  isNotificationOpen: boolean;
  notificationType?: NotificationType;
  message?: string;
  headerText?: string;
  size?: "large" | "default";
}

interface RenderNotificationParams {
  timerRef: React.MutableRefObject<number>;
  notificationType: NotificationType;
  message?: string;
  headerText?: string;
  size?: "large" | "default";
}
interface MyClientsNotificationState {
  notification: Notification;
  renderNotification: (
    renderNotificationParams: RenderNotificationParams,
  ) => void;
  setIsNotificationOpen: (isNotificationOpen: boolean) => void;
  setNotification: (notifcation: Notification) => void;
}

const initialNotificationState: Notification = {
  notificationType: "success",
  isNotificationOpen: false,
  message: "",
};

const useNotificationStore = create<MyClientsNotificationState>()((set) => ({
  notification: initialNotificationState,
  renderNotification: ({
    timerRef,
    notificationType,
    message = "",
    headerText = "",
    size = "default",
  }) =>
    set((state) => {
      state.setNotification(initialNotificationState);

      window.clearTimeout(timerRef.current);

      timerRef.current = window.setTimeout(() => {
        state.setNotification({
          notificationType,
          isNotificationOpen: true,
          message,
          headerText,
          size,
        });
      }, 100);

      return {
        notification: {
          notificationType,
          isNotificationOpen: true,
          message,
          headerText,
          size,
        },
      };
    }),
  setIsNotificationOpen: (isNotificationOpen) =>
    set(() => ({
      notification: {
        isNotificationOpen,
      },
    })),
  setNotification: ({
    notificationType,
    isNotificationOpen,
    message = "",
    headerText = "",
    size = "default",
  }: Notification) =>
    set(() => ({
      notification: {
        notificationType,
        isNotificationOpen,
        message,
        headerText,
        size,
      },
    })),
}));

export const useNotification = () => {
  const timerRef = useRef(0);
  const {
    renderNotification: renderNotificationInternal,
    setIsNotificationOpen,
  } = useNotificationStore();

  const renderNotification = ({
    message,
    headerText,
    notificationType,
    size,
  }: Omit<RenderNotificationParams, "timerRef">) => {
    renderNotificationInternal({
      timerRef,
      notificationType,
      message,
      headerText,
      size,
    });
  };

  return { renderNotification, setIsNotificationOpen };
};

export const Notification = () => {
  const {
    notification: {
      isNotificationOpen,
      message,
      notificationType,
      headerText,
      size,
    },
    setIsNotificationOpen,
  } = useNotificationStore();

  const notificationIconMap = {
    success: <CheckIconFilled />,
    error: <XCircleIcon />,
    warning: <WarningIcon />,
    celebrate: <TadaCelebrateIcon />,
  };

  return (
    <ToastProvider swipeDirection="right" duration={3000}>
      <Toast open={isNotificationOpen} onOpenChange={setIsNotificationOpen}>
        <ToastTitle hidden>{notificationType}</ToastTitle>
        <ToastDescription asChild>
          <div style={{ display: "flex", alignItems: "center" }}>
            {notificationIconMap[notificationType!]}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingLeft: headerText ? 20 : 12,
              }}
            >
              {headerText && (
                <Text css={{ fontSize: 18, fontWeight: 800, paddingBottom: 8 }}>
                  {headerText}
                </Text>
              )}
              <Text>{message}</Text>
            </div>
          </div>
        </ToastDescription>
      </Toast>
      <ToastViewport size={size} />
    </ToastProvider>
  );
};
