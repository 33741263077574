import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Modal, Steps } from "antd";
import { $border } from "../../../assets/colors";
import { AppState } from "../../../state/models";
import { matchmakerActions } from "../../../state/models/matchmaker";

import Footer from "./Footer";

import CoupleStep from "./1-CoupleStep";
import TagsStep from "./2-TagsStep";
import FitStep from "./3-FitStep";
import MatchStep from "./4-MatchStep";

const modalWidth = Math.min(window.innerWidth - 80, 800);
const styles: { [key: string]: React.CSSProperties } = {
  steps: {
    padding: "15px",
    minHeight: "60px",
    borderBottom: "1px solid " + $border,
  },
  modalContainer: { top: "25px", margin: "0 auto" },
  modalBody: {
    maxHeight: "calc(100vh - 160px)",
    backgroundColor: "white",
    padding: "0px",
    display: "flex",
    flexDirection: "column",
  },
  footer: {
    backgroundColor: "white",
  },
};

export default () => {
  const [stepIdx, matchmakerIsOpen] = useSelector((state: AppState) => [
    state.matchmaker.stepIdx,
    state.matchmaker.isOpenCouplesMatchmaker,
    // state.matchmaker.removedTags,
  ]);
  const dispatch = useDispatch();

  const stepTo = (idx: number) => dispatch(matchmakerActions.stepTo(idx));
  const closeMatchmaker = () => dispatch(matchmakerActions.closeMatchmaker());

  return (
    <Modal
      title="Matchmaker"
      open={matchmakerIsOpen}
      maskClosable={false}
      width={modalWidth}
      style={styles.modalContainer}
      bodyStyle={styles.modalBody}
      footer={<Footer />}
      onCancel={closeMatchmaker}
    >
      <Steps size="small" current={stepIdx} style={styles.steps}>
        <Steps.Step
          title="Couple"
          style={{ cursor: "pointer" }}
          onClick={() => stepTo(0)}
        />
        <Steps.Step
          title="Tags"
          style={{ cursor: "pointer" }}
          onClick={() => stepTo(1)}
        />
        <Steps.Step
          title="Fit"
          style={{ cursor: "pointer" }}
          onClick={() => stepTo(2)}
        />
        <Steps.Step
          title="Match"
          style={{ cursor: "pointer" }}
          onClick={() => stepTo(3)}
        />
      </Steps>
      <>
        {stepIdx === 0 && <CoupleStep />}
        {stepIdx === 1 && <TagsStep />}
        {stepIdx === 2 && <FitStep />}
        {stepIdx === 3 && <MatchStep />}
      </>
    </Modal>
  );
};
