import Spin from "antd/lib/spin";
import { styledStitches } from "../design-system";

export const FriendlySpin = styledStitches(Spin, {
  "@media (prefers-reduced-motion)": {
    transform: "none !important;",
    animation: "none !important;",
    " > *": {
      transform: "none !important;",
      animation: "none !important;",
    },
    " > * > *": {
      transform: "none !important;",
      animation: "none !important;",
    },
  },
});
