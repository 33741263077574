import React from "react";
import {
  TabsList,
  TabsTrigger,
  TabsRoot,
  TabsContent,
  HoverTabHighlight,
  ActiveTabHighlight,
} from "../../_components/navigation-tabs/navigation-tabs.styled";
import { useNavigationTabsStyling } from "../../_components/navigation-tabs/use-navigation-tabs-styling";
import { Flex, Stack } from "@/app/design-system";
import { GroupOverviewApiData } from "../_api/group-therapy.interface";
import { GroupPanesContainer } from "./group-panes-container";
import { Weekdays } from "@/app/_helpers";

const ACTIVE_GROUPS_VALUE = "Active Groups";
const PAST_GROUPS_VALUE = "Past Groups";

const weekdays = [
  Weekdays.Monday,
  Weekdays.Tuesday,
  Weekdays.Wednesday,
  Weekdays.Thursday,
  Weekdays.Friday,
  Weekdays.Saturday,
  Weekdays.Sunday,
];

interface GroupNavigationTabsProps {
  groupTherapyOverviewData: GroupOverviewApiData[];
}

export const GroupNavigationTabs = ({
  groupTherapyOverviewData,
}: GroupNavigationTabsProps) => {
  const pastGroups = groupTherapyOverviewData.filter(
    (group) => group.hasCompletedSessions,
  );

  const activeGroups = groupTherapyOverviewData.filter(
    (group) => !group.hasCompletedSessions,
  );

  const {
    wrapperRef,
    resetHighlight,
    highlightStyles,
    hoverHighlightRef,
    repositionHighlight,
    activeTab,
    repositionActiveHighlight,
    activeStyles,
  } = useNavigationTabsStyling(ACTIVE_GROUPS_VALUE);

  return (
    <TabsRoot
      orientation="horizontal"
      defaultValue={activeTab}
      value={activeTab}
      css={{
        px: 20,
        overflow: "auto",
      }}
    >
      <TabsList
        ref={wrapperRef}
        onMouseLeave={resetHighlight}
        css={{
          borderBottom: "1px solid $neutral10",
        }}
      >
        <HoverTabHighlight ref={hoverHighlightRef} css={highlightStyles} />
        <ActiveTabHighlight css={activeStyles} />

        <TabsTrigger
          value={ACTIVE_GROUPS_VALUE}
          onMouseEnter={(e) => repositionHighlight(e, ACTIVE_GROUPS_VALUE)}
          onClick={(e) => repositionActiveHighlight(e, ACTIVE_GROUPS_VALUE)}
        >
          Active Groups
        </TabsTrigger>

        <TabsTrigger
          value={PAST_GROUPS_VALUE}
          onMouseEnter={(e) => repositionHighlight(e, PAST_GROUPS_VALUE)}
          onClick={(e) => repositionActiveHighlight(e, PAST_GROUPS_VALUE)}
        >
          Past Groups
        </TabsTrigger>
      </TabsList>

      <Flex
        css={{
          flexGrow: 1,
        }}
      >
        <TabsContent value={ACTIVE_GROUPS_VALUE}>
          <Stack>
            {weekdays.map((weekday) => (
              <GroupPanesContainer
                groupTherapyOverviewData={activeGroups ?? []}
                dayOfWeek={weekday}
                key={weekday}
              />
            ))}
          </Stack>
        </TabsContent>

        <TabsContent value={PAST_GROUPS_VALUE}>
          <Stack>
            {weekdays.map((weekday) => (
              <GroupPanesContainer
                groupTherapyOverviewData={pastGroups ?? []}
                dayOfWeek={weekday}
                key={weekday}
              />
            ))}
          </Stack>
        </TabsContent>
      </Flex>
    </TabsRoot>
  );
};
