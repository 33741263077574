import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import React from "react";
import { styledStitches } from "../styles/stitches.config";
import { Text } from "@/app/design-system";

import { CheckboxChecked, CheckboxUnchecked } from "../icons";

import {
  slideDownAndFade,
  slideLeftAndFade,
  slideRightAndFade,
  slideUpAndFade,
} from "./dropdown.utils";

const StyledContent = styledStitches(DropdownMenuPrimitive.Content, {
  minWidth: 220,
  backgroundColor: "white",
  borderRadius: 6,
  p: 10,
  display: "flex",
  flexDirection: "column",
  boxShadow:
    "0px 6px 28px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)",
  "@media (prefers-reduced-motion: no-preference)": {
    animationDuration: "400ms",
    animationTimingFunction: "cubic-bezier(0.16, 1, 0.3, 1)",
    animationFillMode: "forwards",
    willChange: "transform, opacity",
    '&[data-state="open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideUpAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade },
    },
  },
});

const itemStyles = {
  all: "unset",
  fontSize: 14,
  color: "$neutral12",
  br: 3,
  display: "flex",
  alignItems: "center",
  height: 20,
  py: 8,
  px: 12,
  position: "relative",
  userSelect: "none",

  "&[data-disabled]": {
    color: "$neutral8",
    pointerEvents: "none",
  },

  "&:focus": {
    backgroundColor: "$neutral3",
    transitionDuration: "50ms",
    outline: "none",
  },
};

const StyledItem = styledStitches(DropdownMenuPrimitive.Item, {
  ...itemStyles,
});
const StyledCheckboxItem = styledStitches(DropdownMenuPrimitive.CheckboxItem, {
  ...itemStyles,
  paddingLeft: 44,
});

const StyledRadioItem = styledStitches(DropdownMenuPrimitive.RadioItem, {
  all: "unset",
  backgroundColor: "white",
  display: "flex",
  userSelect: "none",
  fontSize: 14,
  position: "relative",
  flex: 1,
  br: 3,
  pl: 44,
  py: 6,

  "&[data-disabled]": {
    color: "$neutral8",
    pointerEvents: "none",
  },

  "&:focus": {
    backgroundColor: "$neutral2",
    transitionDuration: "50ms",
  },

  "&:after": {
    content: "",
    display: "block",
    width: 20,
    height: 20,
    top: 8,
    left: 13,
    borderRadius: "50%",
    backgroundColor: "none",
    border: "2px solid $neutral12",
    position: "absolute",
  },
});

const StyledRadioItemIndicator = styledStitches(
  DropdownMenuPrimitive.ItemIndicator,
  {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    left: 14,
    top: 9,
    padding: 2,

    "&:after": {
      content: "",
      display: "block",
      width: "14px",
      height: "14px",
      borderRadius: "50%",
      backgroundColor: "black",
    },
  },
);

const StyledRadioItemWithoutIndicator = styledStitches(
  DropdownMenuPrimitive.RadioItem,
  {
    all: "unset",
    backgroundColor: "white",
    display: "flex",
    userSelect: "none",
    fontSize: 14,
    position: "relative",
    flex: 1,
    br: 3,
    pl: 10,
    py: 6,

    "&[data-state='checked']": {
      backgroundColor: "$neutral3",
    },

    "&[data-disabled]": {
      color: "$neutral8",
      pointerEvents: "none",
    },

    "&:focus": {
      backgroundColor: "$neutral3",
      transitionDuration: "50ms",
    },
  },
);

const StyledTriggerItem = styledStitches(DropdownMenuPrimitive.TriggerItem, {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  py: 8,
  pl: 16,
  pr: 8,
  br: 6,
  '&[data-state="open"]': {},

  "&:focus": {
    backgroundColor: "$neutral3",
    transitionDuration: "50ms",
    outline: "none",
  },
});

const StyledLabel = styledStitches(DropdownMenuPrimitive.Label, {
  paddingLeft: 16,
  pt: 16,
  fontSize: 14,
  color: "$neutral12",
});

const StyledSeparator = styledStitches(DropdownMenuPrimitive.Separator, {
  height: 1,
  backgroundColor: "$neutral",
  margin: 5,
});

const StyledItemIndicator = styledStitches(
  DropdownMenuPrimitive.ItemIndicator,
  {
    position: "absolute",
    left: 10,
    width: 25,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
  },
);

export const StyledUncheckedPositioner = styledStitches("span", {
  position: "absolute",
  left: 10,
  width: 25,
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
});

const StyledArrow = styledStitches(DropdownMenuPrimitive.Arrow, {
  fill: "white",
});

export const RightSlot = styledStitches("div", {
  marginLeft: "auto",
  paddingLeft: 20,
  color: "$neutral11",
  ":focus > &": { color: "white" },
  "[data-disabled] &": { color: "$neutral8" },
});

export const DropdownButton = styledStitches("button", {
  cursor: "pointer",
  padding: 0,
  backgroundColor: "white",
  border: " 1px solid #d0d0d0",
  textAlign: "left",
  position: "relative",
  br: 8,
  py: 12,
  px: 16,
  pr: 32,
  minWidth: "fit-content",
  borderBottom: "1px solid #e2e2e2",

  ".caret-icon": {
    position: "absolute",
    right: 4,
    bottom: "12px",
  },

  "&:active": {
    transform: "scale(0.995)",
  },
});

const StyledTrigger = styledStitches(DropdownMenuPrimitive.Trigger, {
  ".caret-icon": {
    transition: "transform ease-in-out 0.25s",
  },
  '&[data-state="open"]': {
    ".caret-icon": {
      transition: "transform ease-in-out 0.25s",
      transform: "rotate(-180deg)",
    },
  },
});

export const ComposedDropdownMenuCheckboxItem = React.forwardRef<
  HTMLDivElement,
  DropdownMenuPrimitive.DropdownMenuCheckboxItemProps
>(({ children, ...props }, forwardedRef) => {
  const { checked } = props;
  return (
    <StyledCheckboxItem {...props} ref={forwardedRef}>
      <DropdownMenuItemIndicator>
        <CheckboxChecked height={22} width={22} />
      </DropdownMenuItemIndicator>

      {!checked && (
        <StyledUncheckedPositioner>
          <CheckboxUnchecked height={22} width={22} />
        </StyledUncheckedPositioner>
      )}

      <Text fontSize={14}>{children}</Text>
    </StyledCheckboxItem>
  );
});

// Exports
export const DropdownMenu = styledStitches(DropdownMenuPrimitive.Root, {
  section: {
    overflow: "hidden",
  },
});

const StyledDropdownMenuPrimitiveItem = styledStitches(
  DropdownMenuPrimitive.Item,
);

export const DropdownMenuContent = StyledContent;
export const DropdownMenuItem = StyledItem;
export const DropdownMenuCheckboxItem = StyledCheckboxItem;
export const DropdownMenuPrimitiveItem = StyledDropdownMenuPrimitiveItem;

export const DropdownMenuPrimitiveIndicator =
  DropdownMenuPrimitive.DropdownMenuItemIndicator;
export const DropdownMenuRadioGroup = DropdownMenuPrimitive.RadioGroup;
export const DropdownMenuRadioItem = StyledRadioItem;
export const DropdownMenuRadioItemWithoutIndicator =
  StyledRadioItemWithoutIndicator;
export const DropdownMenuItemIndicator = StyledItemIndicator;
export const DropdownMenuRadioItemIndicator = StyledRadioItemIndicator;
export const DropdownMenuTriggerItem = StyledTriggerItem;
export const DropdownMenuTrigger = StyledTrigger;
export const DropdownMenuLabel = StyledLabel;
export const DropdownMenuSeparator = StyledSeparator;
export const DropdownMenuArrow = StyledArrow;
