import * as Sentry from "@sentry/react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Spin } from "antd";
import { AxiosResponse } from "axios";
import { chunk } from "lodash-es";
import { Moment } from "moment";
import React from "react";
import { useDispatch } from "react-redux";
import api, { wrapApiCall } from "../../../api";
import {
  ClinicianWithSchedule,
  ScheduleDraftItem,
  ScheduleItem,
  WorkFromHomeDays,
} from "../../../api/types";
import { openNotification } from "../../slot-tool/EditTimeslot";
import {
  UserHasAnyPermissions,
  useUserHasAnyPermissions,
} from "../../_helpers/permissions";
import { useShallowEqualSelector } from "../../_helpers/redux";
import ScheduleTableV2, { SchedulesArray } from "./ScheduleTable";
import { generateWorkFromHomeObject } from "./util";

const ScheduleDraft: React.FC<{ clinician: ClinicianWithSchedule }> = ({
  clinician,
}) => {
  const [scheduleDrafts, setScheduleDrafts] = React.useState<
    ScheduleDraftItem[]
  >([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [isDrafting, setIsDrafting] = React.useState<boolean>(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    setLoading(true);
    const fetchDrafts = async () => {
      const res = await wrapApiCall(
        api.get<ScheduleDraftItem[]>(
          `/api/clinicianScheduleDrafts/v1/?clinician=${clinician.id}`,
        ),
        dispatch,
      );

      const scheduleDrafts = res.data;
      setLoading(false);
      setScheduleDrafts(scheduleDrafts);
      return scheduleDrafts;
    };

    try {
      fetchDrafts();
    } catch (error) {
      console.error(error);
      openNotification("Failed to fetch schedule drafts.", "", "error");
    }
  }, [clinician, dispatch]);

  const pendingSchedules = scheduleDrafts.filter((v) => v.state === "pending");
  const pendingWorkFromHomeDays = generateWorkFromHomeObject(pendingSchedules);
  const existingClinic = clinician.schedule[0]?.clinic_id | 0;

  const changeScheduleCallback: ChangeScheduleCallback = (
    schedules,
    startWeek,
    hours,
    workFromHomeDays: WorkFromHomeDays,
    clinic_id,
  ) => {
    Sentry.setContext("scheduleDrafts", {
      schedules,
      startWeek,
      hours,
      workFromHomeDays,
      clinic_id,
    });
    const scheduleDrafts: Partial<ScheduleDraftItem>[] = [];
    Object.entries(schedules).forEach(([dow, booleanArray]) => {
      // (edge case) if a user enters hours for a day but doesn't click the "Update"
      // button under "Select days in office", workFromHomeDays[dow] will be undefined
      // for that day. In that case, default to virtual (work_from_home=true).
      const workFromHome = workFromHomeDays[dow] ?? true;

      const scheduleDraftBase: Partial<ScheduleDraftItem> = {
        day_of_week: dow,
        clinician_id: clinician.id,
        clinic: Number(clinic_id),
        work_from_home: workFromHome,
        intended_clinical_hours: hours,
      };

      // is this an interview problem? find consecutive active hours to make schedules out of
      const activeTimeIndices: number[] = [];
      let isActive: boolean = false;
      /**
       * for each boolean in an array of booleans corresponding to a dow's schedule,
       *    e.g., [true, true, false ...] for [8:00: true, 9:00: true, 10:00: false ...]
       * find the start index and end index of each consecutive block of active schedules.
       * for [true, true, false, true], we expect to return [0, 1, 3, 4]
       */

      booleanArray.forEach((bool, i) => {
        if (bool && !isActive) {
          activeTimeIndices.push(i);
          isActive = true;
        } else if (!bool && isActive) {
          activeTimeIndices.push(i);
          isActive = false;
        }
      });

      // push the final index to terminate if schedule terminates at 8pm
      if (isActive) {
        activeTimeIndices.push(14); // 20:00 or 8p
      }

      // chunk the array into arrays of two -- each chunk represents a start and end time within this dow
      const startEndTimes = chunk(activeTimeIndices, 2);
      startEndTimes.forEach(([start, end]) => {
        scheduleDrafts.push({
          ...scheduleDraftBase,
          start_date: startWeek.format("YYYY-MM-DD"),
          start_time: `${start + 7}:00`,
          end_time: `${end + 7}:00`,
        });
      });
    });

    const postDrafts = async () => {
      const res = await wrapApiCall(
        api.post<ScheduleDraftItem[]>(`/api/clinicianScheduleDrafts/v1/`, {
          clinician: clinician.id,
          schedules: scheduleDrafts,
        }),
        dispatch,
      );
      const createdScheduleDrafts = res.data;
      setScheduleDrafts(createdScheduleDrafts);
      openNotification("Saved schedule drafts successfully.", "", "success");
      setIsDrafting(false);

      return scheduleDrafts;
    };

    try {
      postDrafts();
    } catch (error) {
      Sentry.captureException(error);
      openNotification("Error saving schedule drafts.", "", "error");
    }
  };

  const approveScheduleCallback = () => {
    Sentry.setContext("pendingSchedules", {
      pendingSchedules,
    });

    const postSchedules = async (scheduleIds: number[]) => {
      const res: AxiosResponse<Record<number, ScheduleItem>> =
        await wrapApiCall(
          api.post(`/api/clinicianScheduleDrafts/v1/publish/`, {
            clinician: clinician.id,
            schedule_ids: scheduleIds,
          }),
          dispatch,
        );

      const createdSchedules = res.data;
      openNotification(
        "Successfully created schedules.",
        `Drafts successfully set as schedule starting ${
          Object.values(createdSchedules)[0].start_date
        }.`,
        "success",
      );
    };

    try {
      const scheduleIds = pendingSchedules.map((s) => s.id);
      postSchedules(scheduleIds);
    } catch (error) {
      Sentry.captureException(error);
      openNotification(
        "Error approving schedules.",
        "Please reach out to techsupport@twochairs.com",
        "error",
      );
    }
  };

  return (
    <>
      <Spin spinning={loading} size="small">
        {pendingSchedules.length > 0 && (
          <PendingSchedule
            clinician={clinician}
            approveScheduleCallback={approveScheduleCallback}
            drafts={pendingSchedules}
            pendingWorkFromHomeDays={pendingWorkFromHomeDays}
          />
        )}{" "}
        {!isDrafting && (
          <Button disabled type="link" onClick={() => setIsDrafting(true)}>
            <PlusOutlined /> Draft a new schedule
          </Button>
        )}
        {isDrafting && (
          <DraftingSchedule
            clinician={clinician}
            existingClinic={existingClinic}
            callback={changeScheduleCallback}
            existingSchedules={pendingSchedules}
            pendingWorkFromHomeDays={pendingWorkFromHomeDays}
          />
        )}
      </Spin>
    </>
  );
};

const PendingSchedule: React.FC<{
  clinician: ClinicianWithSchedule;
  drafts: ScheduleDraftItem[];
  approveScheduleCallback: ApproveScheduleCallback;
  pendingWorkFromHomeDays: WorkFromHomeDays;
}> = ({
  clinician,
  drafts,
  approveScheduleCallback,
  pendingWorkFromHomeDays,
}) => {
  const currentUser = useShallowEqualSelector(
    (state) => state.auth.currentUser,
  );
  return (
    <div style={{ marginBottom: "8px" }}>
      <h5>Pending Schedule</h5>
      <ScheduleTableV2
        clinician={clinician}
        schedules={drafts}
        workFromHomeDays={pendingWorkFromHomeDays}
      />
      <Button
        disabled
        onClick={(e) => {
          approveScheduleCallback();
        }}
        style={{ marginTop: "8px" }}
      >
        Approve and Activate Schedule
      </Button>
    </div>
  );
};

const DraftingSchedule: React.FC<{
  clinician: ClinicianWithSchedule;
  existingClinic: number | undefined;
  callback: ChangeScheduleCallback;
  existingSchedules?: ScheduleDraftItem[];
  pendingWorkFromHomeDays: WorkFromHomeDays;
}> = ({
  clinician,
  callback,
  existingClinic,
  existingSchedules,
  pendingWorkFromHomeDays,
}) => {
  return (
    <>
      <h4 style={{ marginTop: "8px" }}>You are drafting a new schedule.</h4>
      <ScheduleTableV2
        clinician={clinician}
        existingClinic={existingClinic}
        isDrafting={true}
        onChange={callback}
        schedules={existingSchedules}
        workFromHomeDays={pendingWorkFromHomeDays}
      />
    </>
  );
};

type ChangeScheduleCallback = (
  schedules: SchedulesArray,
  startWeek: Moment,
  workingHours: number,
  workFromHomeDays: WorkFromHomeDays,
  clinic_id: number | undefined,
) => unknown;

type ApproveScheduleCallback = () => unknown;

export default ScheduleDraft;
