import {
  Checkbox,
  Flex,
  Stack,
  Text,
} from "../../../../../../../design-system";
import {
  CheckboxChecked,
  CheckboxUnchecked,
} from "../../../../../../../design-system/icons";
import { MyClientsFilters } from "../../../../my-clients-model/my-clients.interface";
import { useMyClientsFilterStore } from "../../../../my-clients-model/my-clients.model";
import { StyledFilterCard } from "./mbc-metric-checkbox.styled";

interface FilterCardProps {
  copy: string;
  numberOfClients: number;
  clientLabel: string;
  isChecked: boolean;
  setFilter: (
    filterName: keyof MyClientsFilters,
    isFilterChecked: boolean,
  ) => void;
  filterName: keyof MyClientsFilters;
}

export function MBCMetricCheckbox({
  copy,
  numberOfClients,
  clientLabel,
  isChecked,
  filterName,
  setFilter,
}: FilterCardProps) {
  const { clearAllMyClientsFilters, isFilteringOnActiveClients } =
    useMyClientsFilterStore((state) => ({
      clearAllMyClientsFilters: state.clearAllMyClientsFilters,
      isFilteringOnActiveClients: state.isFilteringOnActiveClients,
    }));

  return (
    <Checkbox.Root
      checked={isChecked}
      onCheckedChange={() => {
        if (!isChecked) {
          clearAllMyClientsFilters();

          setFilter(filterName, true);
        } else {
          setFilter(filterName, !isChecked);
          setFilter("isFilteringOnActiveClients", !isFilteringOnActiveClients);
        }
      }}
      css={{
        "&:active": {
          transform: "scale(0.995)",
        },
      }}
    >
      <StyledFilterCard justifyContent={"space-between"}>
        <Stack gap={8}>
          <Text
            color={"$neutral11"}
            fontFamily={"arial"}
            fontWeight={400}
            fontSize={14}
          >
            In-network
          </Text>
          <Flex gap={4}>
            <Text fontWeight={600}>
              {numberOfClients} {clientLabel}
            </Text>
            <Text> client{`${numberOfClients !== 1 ? "s" : ""}`}</Text>
          </Flex>
          <Text>{copy}</Text>
        </Stack>

        <Flex alignItems={"center"}>
          {isChecked ? <CheckboxChecked /> : <CheckboxUnchecked />}
        </Flex>
      </StyledFilterCard>
    </Checkbox.Root>
  );
}
