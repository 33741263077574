import { Button, Flex, Stack, Text } from "@/app/design-system";

import {
  ComposedDropdownMenuCheckboxItem,
  DropdownButton,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuPrimitiveItem,
  DropdownMenuTrigger,
} from "@/app/design-system/dropdown/dropdown.styled";
import { DownChevronIcon } from "@/app/design-system/icons";
import { ClientFilterBar } from "../clientfilter";

interface PastConsultsTableContainerProps {
  onSearch: (searchText: string) => void;
  onSelectClientId: ((searchText: string) => void) | undefined;
  clients: Array<any>;
  isDropdownOpen: any;
  setIsDropdownOpen: any;
  onFilterClick: any;
  onClearFilters: any;
  filterDropdowns: any;
}

export const PastConsultsTableContainer = ({
  onSearch,
  onSelectClientId,
  clients,
  isDropdownOpen,
  setIsDropdownOpen,
  onFilterClick,
  onClearFilters,
  filterDropdowns,
}: PastConsultsTableContainerProps) => {
  const onSelectValue = (value: string) => {
    setIsDropdownOpen(false);
    if (onSelectClientId) {
      onSelectClientId(value);
    }
  };
  return (
    <Flex
      justifyContent="flex-start"
      alignItems="center"
      style={{ marginBottom: "16px" }}
    >
      <ClientFilterBar
        onSearch={onSearch}
        updateSelectedClientId={onSelectValue}
        clients={clients}
      />
      <Flex style={{ marginLeft: "24px" }}>
        <Text style={{ color: "#6F6F6F", marginRight: "16px" }}>Filter By</Text>
        <Flex style={{ color: "#6F6F6F", marginRight: "16px" }}>
          <DropdownMenu open={isDropdownOpen.client_status && !!isDropdownOpen}>
            <DropdownMenuTrigger
              asChild
              onClick={() =>
                setIsDropdownOpen({
                  ...isDropdownOpen,
                  client_status: !isDropdownOpen.client_status,
                })
              }
            >
              <DropdownButton aria-label="client status filter options">
                <Text>Client Status</Text>

                <DownChevronIcon className="caret-icon" />
              </DropdownButton>
            </DropdownMenuTrigger>

            <DropdownMenuContent
              sideOffset={5}
              align={"start"}
              loop
              onInteractOutside={() =>
                setIsDropdownOpen({
                  ...isDropdownOpen,
                  client_status: !isDropdownOpen.client_status,
                })
              }
              onEscapeKeyDown={() =>
                setIsDropdownOpen({
                  ...isDropdownOpen,
                  client_status: !isDropdownOpen.client_status,
                })
              }
            >
              <Stack css={{ pb: 8 }}>
                {filterDropdowns.client_status?.map(
                  (
                    status: { label: string; value: boolean },
                    index: number,
                  ) => (
                    <ComposedDropdownMenuCheckboxItem
                      checked={status.value}
                      onCheckedChange={(e) => {
                        onFilterClick(index, !status.value, "client_status");
                      }}
                    >
                      {status.label}
                    </ComposedDropdownMenuCheckboxItem>
                  ),
                )}
              </Stack>

              <Flex
                justifyContent={"space-between"}
                css={{
                  borderTop: "1px solid $neutral3",
                  pt: 10,
                  pl: 0,
                }}
              >
                <DropdownMenuPrimitiveItem css={{ fontSize: 14 }} asChild>
                  <Button
                    variant={"tertiary"}
                    size={"medium"}
                    onClick={() => onClearFilters("client_status")}
                  >
                    Clear all
                  </Button>
                </DropdownMenuPrimitiveItem>
              </Flex>
            </DropdownMenuContent>
          </DropdownMenu>
        </Flex>
        <Flex style={{ marginRight: "16px" }}>
          <DropdownMenu open={isDropdownOpen.month}>
            <DropdownMenuTrigger
              asChild
              onClick={() =>
                setIsDropdownOpen({
                  ...isDropdownOpen,
                  month: !isDropdownOpen.month,
                })
              }
            >
              <DropdownButton aria-label="client month filter options">
                <Text>Month</Text>

                <DownChevronIcon className="caret-icon" />
              </DropdownButton>
            </DropdownMenuTrigger>

            <DropdownMenuContent
              sideOffset={5}
              align={"start"}
              loop
              onInteractOutside={() =>
                setIsDropdownOpen({
                  ...isDropdownOpen,
                  month: !isDropdownOpen.month,
                })
              }
              onEscapeKeyDown={() =>
                setIsDropdownOpen({
                  ...isDropdownOpen,
                  month: !isDropdownOpen.month,
                })
              }
            >
              <Stack css={{ pb: 8 }}>
                {filterDropdowns.month.map(
                  (
                    status: { label: string; value: boolean },
                    index: number,
                  ) => (
                    <ComposedDropdownMenuCheckboxItem
                      checked={status.value}
                      onCheckedChange={(e) => {
                        onFilterClick(index, !status.value, "month");
                      }}
                    >
                      {status.label}
                    </ComposedDropdownMenuCheckboxItem>
                  ),
                )}
              </Stack>

              <Flex
                justifyContent={"space-between"}
                css={{
                  borderTop: "1px solid $neutral3",
                  pt: 10,
                  pl: 0,
                }}
              >
                <DropdownMenuPrimitiveItem css={{ fontSize: 14 }} asChild>
                  <Button
                    variant={"tertiary"}
                    size={"medium"}
                    onClick={() => onClearFilters("month")}
                  >
                    Clear all
                  </Button>
                </DropdownMenuPrimitiveItem>
              </Flex>
            </DropdownMenuContent>
          </DropdownMenu>
        </Flex>

        <Flex>
          <DropdownMenu open={isDropdownOpen.year}>
            <DropdownMenuTrigger
              asChild
              onClick={() =>
                setIsDropdownOpen({
                  ...isDropdownOpen,
                  year: !isDropdownOpen.year,
                })
              }
            >
              <DropdownButton aria-label="client status filter year options">
                <Text>Year</Text>

                <DownChevronIcon className="caret-icon" />
              </DropdownButton>
            </DropdownMenuTrigger>

            <DropdownMenuContent
              sideOffset={5}
              align={"start"}
              loop
              onInteractOutside={() =>
                setIsDropdownOpen({
                  ...isDropdownOpen,
                  year: !isDropdownOpen.year,
                })
              }
              onEscapeKeyDown={() =>
                setIsDropdownOpen({
                  ...isDropdownOpen,
                  year: !isDropdownOpen.year,
                })
              }
            >
              <Stack css={{ pb: 8 }}>
                {filterDropdowns.year?.map(
                  (
                    status: { label: string; value: boolean },
                    index: number,
                  ) => (
                    <ComposedDropdownMenuCheckboxItem
                      checked={status.value}
                      onCheckedChange={(e) => {
                        onFilterClick(index, !status.value, "year");
                      }}
                    >
                      {status.label}
                    </ComposedDropdownMenuCheckboxItem>
                  ),
                )}
              </Stack>
            </DropdownMenuContent>
          </DropdownMenu>
        </Flex>
      </Flex>
    </Flex>
  );
};
