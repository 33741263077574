import { styled } from "@stitches/react";
import { Link } from "react-router-dom";
import {
  STANDARD_FOCUS_OUTLINE,
  VN_COMPONENT_OVERLAY_INDEX,
} from "../design-system/styles/config/css-constants";
import { VN_COMPONENT_INDEX } from "@/app/design-system/styles/config/css-constants";
import { styledStitches } from "../design-system";
import { FOCUS_STYLES } from "../design-system/styles/config/css-utils";
import { UnstyledButton } from "../design-system/button/button";

/** @name VisuallyNestedLink
 *  @description A Link component that appears to be nested inside a parent component, but behaves in a way where it (or the target click area) spans the entirety of the parent
 * It is _especially_ useful for visually nesting interactive elements and when we need a clickable row so we can respect HTML specifications (specifically, no nested UI elements and only certain elements as direct children of <table>s)
 * This pattern is based on this Nested Links Without Nesting Links (https://www.sarasoueidan.com/blog/nested-links/) article.
 * We are using #2 because it is perfect for our initial use case (in client-pane)
 * The files this pattern was originally being used within are annotated with "VNL" (visually nested links).
 */

export const VisuallyNestedLink = styled(Link, {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  overflow: "hidden",
  whiteSpace: "nowrap",
  position: "absolute",
  zIndex: `${VN_COMPONENT_INDEX}`,
  "&:focus": {
    outline: `${STANDARD_FOCUS_OUTLINE}`,
  },
});

/** @name ElevatedButton
 *  @description Sometimes there are interactive elements that appear on rows that are clickable and have some sort of effect on the page (without changing context). These buttons/links need to be above the VNL in terms of z-index
 */
export const ElevatedButton = styledStitches(UnstyledButton, {
  zIndex: VN_COMPONENT_OVERLAY_INDEX + 2,
  ...FOCUS_STYLES,
});

/** @name ElevatedLink
 *  @description Sometimes there are elements that appear on rows that are clickable and should change the users's context in some way. These buttons/links need to be above the VNL in terms of z-index
 */
export const ElevatedLink = styledStitches(Link, {
  zIndex: VN_COMPONENT_OVERLAY_INDEX + 2,
  ...FOCUS_STYLES,
});

/** @name ElevatedAnchor
 *  @description Sometimes there are elements that appear on rows that are clickable and should change the users's context in some way. These buttons/links need to be above the VNL in terms of z-index
 */
export const ElevatedAnchor = styledStitches("a", {
  zIndex: VN_COMPONENT_OVERLAY_INDEX + 2,
  ...FOCUS_STYLES,
});

/** @name ElevatedMangledLink
 *  @description Sometime we need to mangle something, but the behavior of the elevated element is the same as the rest of the row.
 * WE DO NOT WANT TO DUPLICATE THE LINK. But we do not want to interrupt the clicking behavior for our mouse/trackpad users. So instead we something visually appear,
 * but we take out anything that indicates duplicate functionality in the DOM.
 */
export const ElevatedMangleLink = styledStitches(Link, {
  display: "block",
  width: "100%",
  height: "100%",
  zIndex: VN_COMPONENT_OVERLAY_INDEX + 2,
});

/** @name ElevatedElement
 *  @description Sometimes there are elements that appear on rows that are hoverable. These elements need to be above the VNL in terms of z-index
 * @warning If it's hoverable, it should probably be interactable as well. We will need to fix this in the future
 * this particularly applies to the antd tooltips
 */
export const ElevatedElement = styledStitches("div", {
  display: "inherit",
  zIndex: VN_COMPONENT_OVERLAY_INDEX + 2,
  ...FOCUS_STYLES,
});
