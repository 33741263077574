const SelectSearchProps = {
  showSearch: true,
  optionFilterProp: "children",
  filterOption: (input: any, option: any) => {
    return (
      (option?.props?.children || "")
        .toString()
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  },
};

export { SelectSearchProps };
