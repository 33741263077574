import { Label, Stack } from "@/app/design-system";
import { ClinicianPickerV2 } from "@/app/_components/clinician-picker-v2/clinician-picker-v2";

import "react-day-picker/dist/style.css";
import { useController, useFormContext } from "react-hook-form";
import { UpsertSessionFormFields } from "../use-upsert-session-form";
import { UpsertSessionFormItemProps } from "./upsert-session-form-item.interface";

interface SessionClinicianFormItem extends UpsertSessionFormItemProps {}

export const SessionClinicianFormItem = ({
  name,
  id,
  mode,
}: SessionClinicianFormItem) => {
  const { control } = useFormContext<UpsertSessionFormFields>();
  // register input
  const {
    field: { onChange, onBlur, ref, value },
    fieldState: { invalid, isDirty },
    formState: { errors },
  } = useController({
    name,
    control,
  });


  return (
    <Stack gap={10}>
      <Label required={mode === "add"}>Clinician</Label>
      <ClinicianPickerV2
        portal={false}
        callback={(clinicianId) => onChange(clinicianId)}
        css={{ width: 250, fontSize: 16 }}
      />
    </Stack>
  );
};
