import { mauve } from "@radix-ui/colors";
import * as AccordionPrimitive from "@radix-ui/react-accordion";
import React from "react";
import { keyframes, styledStitches } from "../styles/stitches.config";

const slideDown = keyframes({
  from: { height: 0 },
  to: { height: "var(--radix-accordion-content-height)" },
});

const slideUp = keyframes({
  from: { height: "var(--radix-accordion-content-height)" },
  to: { height: 0 },
});

const StyledAccordion = styledStitches(AccordionPrimitive.Root, {
  backgroundColor: mauve.mauve6,
});

const StyledAccordionItem = styledStitches(AccordionPrimitive.Item, {
  overflow: "hidden",

  "&:focus-within": {
    position: "relative",
    zIndex: 1,
  },
});

const StyledAccordionHeader = styledStitches(AccordionPrimitive.Header, {
  all: "unset",
  display: "flex",
});

const StyledAccordionTrigger = styledStitches(AccordionPrimitive.Trigger, {
  fontFamily: "inherit",
  border: "none",
  flex: 1,
  display: "flex",
  justifyContent: "space-between",
  cursor: "pointer",
  backgroundColor: "white",
  padding: 0,

  "&:disabled": {
    cursor: "auto",
  },
});

const StyledAccordionContent = styledStitches(AccordionPrimitive.Content, {
  display: "flex",
  overflow: "hidden",
  padding: 24,
  backgroundColor: "white",
  justifyContent: "flex-end",

  '&[data-state="open"]': {
    animation: `${slideDown} 100ms cubic-bezier(0.87, 0, 0.13, 1) forwards`,
  },
  '&[data-state="closed"]': {
    animation: `${slideUp} 200ms cubic-bezier(0.87, 0, 0.13, 1) forwards`,
  },
});

// Exports
export const Accordion = StyledAccordion;
export const AccordionItem = StyledAccordionItem;
export const AccordionHeader = StyledAccordionHeader;
export const AccordionTrigger = StyledAccordionTrigger;

export const ComposedAccordionTrigger = React.forwardRef<
  HTMLButtonElement,
  AccordionPrimitive.AccordionTriggerProps
>(({ children, ...props }, forwardedRef) => (
  <StyledAccordionHeader>
    <StyledAccordionTrigger {...props} ref={forwardedRef}>
      {children}
    </StyledAccordionTrigger>
  </StyledAccordionHeader>
));

export const AccordionContent = React.forwardRef<
  HTMLDivElement,
  AccordionPrimitive.AccordionContentProps
>(({ children, ...props }, forwardedRef) => (
  <StyledAccordionContent {...props} ref={forwardedRef}>
    {children}
  </StyledAccordionContent>
));
