import { Flex, Text, RadixTooltip } from "@/app/design-system";
import { GroupsEndorsedSIAlertIcon } from "@/app/design-system/icons/components/groups-endorsed-si-alert-icon";
import { EndorsedSIApiData } from "../../_api/group-therapy.interface";

interface GroupNameAndSIAlertProps {
  groupTitle: string;
  endorsedSI: EndorsedSIApiData | undefined | null;
}

export const GroupNameAndSIAlert = ({
  groupTitle,
  endorsedSI,
}: GroupNameAndSIAlertProps) => {
  const siText =
    endorsedSI && endorsedSI.clientsEndorsedSI
      ? endorsedSI.clientsEndorsedSI.join(", ") + " endorsed SI"
      : "Endorsed SI";

  return (
    <Flex
      gap={8}
      css={{
        minWidth: 330,
      }}
    >
      <Text css={{ fontWeight: 600 }}>{groupTitle}</Text>

      {endorsedSI && endorsedSI.endorsed ? (
        <RadixTooltip content={siText}>
          <Flex gap={4}>
            <GroupsEndorsedSIAlertIcon />
          </Flex>
        </RadixTooltip>
      ) : null}
    </Flex>
  );
};
