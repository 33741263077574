import {
  GroupTherapyClientsDataFragment,
  GroupTherapyClientsQuery,
} from "@/graphql/generated";
import { DateTime } from "luxon";

enum StateAbbreviations {
  Washington = "WA",
  California = "CA",
  Florida = "FL",
  Ohio = "OH",
}

export interface ParsedGroupClient {
  clientId: number | null;
  clientName: string;
  clientPronouns?: string | null;
  age?: string | null;
  cityAndState?: string | null;
  isNewToTherapy: boolean;
  accessibilityNeeds: [] | null;
}

export const selectGroupTherapyClientsData = (
  groupTherapyMbcData: GroupTherapyClientsQuery,
  timezone: string,
): ParsedGroupClient[] => {
  const clientGroups = groupTherapyMbcData.group[0].clientGroups.filter(
    (clientGroup) =>
      !clientGroup.client.isTestClient && clientGroup.client !== null,
  );
  const parsedClients: ParsedGroupClient[] = clientGroups
    .map((clientGroup) => {
      return parseClient(clientGroup.client, timezone);
    })
    .filter((client): client is ParsedGroupClient => client !== null);

  return parsedClients;
};

const parseClient = (
  client: GroupTherapyClientsDataFragment | null,
  timezone: string,
): ParsedGroupClient | null => {
  if (!client) {
    return null;
  }

  if (
    !client.bookingProfileData[0] ||
    !client.bookingProfileData[0]["profileData"]
  ) {
    return {
      clientId: client.clientId,
      clientName: client.firstName + " " + client.lastName,
      accessibilityNeeds: [],
      isNewToTherapy: false,
    };
  }

  const metadata = client.bookingProfileData[0].profileData;
  return {
    clientId: client.clientId,
    clientName: client.firstName + " " + client.lastName,
    clientPronouns: metadata.self_identify["pronouns"],
    age: getAge(metadata.profile["dob"], timezone),
    cityAndState: metadata.profile["city"]
      ? metadata.profile["city"].trim() +
        ", " +
        StateAbbreviations[metadata.profile["state"]]
      : "",
    isNewToTherapy: metadata.more_about_you[
      "have_you_ever_sought_out_therapy_in_the_past"
    ]
      ? getIsNewToTherapy(
          metadata.more_about_you[
            "have_you_ever_sought_out_therapy_in_the_past"
          ],
        )
      : false,
    accessibilityNeeds:
      metadata.more_about_you[
        "do_you_have_any_accessibility_needs_we_should_know_about"
      ],
  };
};

export const getAge = (
  dob: string | undefined,
  timezone: string,
): string | null => {
  if (!dob) {
    return null;
  }
  const birthday = DateTime.fromFormat(dob, "MMddy", {
    zone: timezone,
  });
  const now = DateTime.now();
  return now.diff(birthday, ["years"]).years.toFixed(0);
};

const getIsNewToTherapy = (therapyInPastString: string): boolean => {
  if (therapyInPastString.toLowerCase() === "yes") {
    return true;
  } else {
    return false;
  }
};
