import { useShallowEqualSelector } from "@/app/_helpers/redux";
import { IfPermitted } from "@/app/_helpers/permissions";
import { Button, Text, TextArea, Title6 } from "@/app/design-system";
import {
  Close,
  Content,
  Footer,
  Header,
  Overlay,
  Root,
  Trigger,
} from "@/app/design-system/modal-dialog/modal-dialog.styled";
import {
  ComposedRadixUISelect,
  ComposedSelectRadixUIOption,
} from "@/app/design-system/select-radixui/composed-select-radixui";
import { AppState } from "@/state/models";
import * as slotToolOperations from "@/state/models/slot-tool/operations";
import MinusOutlined from "@ant-design/icons/lib/icons/MinusOutlined";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import * as Dialog from "@radix-ui/react-dialog";
import * as antd from "antd";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { useNotification, Notification } from "@/app/design-system";
import * as Collapsible from "@radix-ui/react-collapsible";

import {
  MatchRequestHeaderRow,
  MatchRequestWidgetContainer,
} from "./sidebar.styled";
import HoldButton from "@/app/team/HoldButton";
import { WeeklyVolumeData } from "../../WeeklyVolume";
import { DownChevronIcon, RightChevronIcon } from "@/app/design-system/icons";

const OTHER = "Other (requires explanation in note)";

const reasonsTenured = [
  "Pending matches about to expire",
  "Not enough availability",
  "Upcoming client termination or rematch",
  "Increasing to help achieve utilization goal",
  "Decreasing because already at or exceeding utilization goal",
  "Caseload benefit",
  OTHER,
];

const reasonsRamping = [
  "Needs slower pace to onboard",
  "Adjusting to technology",
  "Pending matches about to expire",
  "Not enough availability",
  "Incoming previous clients (manager approved)",
  "Upcoming client termination or rematch",
  "Increasing to help achieve utilization goal",
  "Decreasing because already at or exceeding utilization goal",
  OTHER,
];

export const ClinicianMatchRequestWidget = ({
  ramping,
  clinicianId,
  volumeData,
  hasPTONextWeek,
}: {
  ramping: boolean;
  clinicianId: number;
  volumeData: WeeklyVolumeData;
  hasPTONextWeek: boolean;
}) => {
  const [moreDetailIsOpen, setMoreDetailIsOpen] =
    React.useState<boolean>(false);

  const { manualCapacity, matchesRequested, hasActiveHold } = volumeData;

  const nextMatchRequest =
    manualCapacity !== undefined && manualCapacity !== null
      ? manualCapacity
      : matchesRequested;

  return (
    <MatchRequestWidgetContainer>
      <MatchRequestHeaderRow>
        <Text fontSize={16} fontWeight={600}>
          Next Match Request
        </Text>
        <Text fontSize={20} fontWeight={600} style={{ textAlign: "right" }}>
          {" "}
          {hasActiveHold ? "HOLD" : nextMatchRequest}
        </Text>
      </MatchRequestHeaderRow>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <div style={{ display: "flex" }}>
          <IfPermitted
            permissions={["IsATMatchRequestEditor", "IsClinicalLeader"]}
            requireAll={false}
          >
            <MatchesRequested
              clinicianId={clinicianId}
              defaultMatchesRequested={matchesRequested}
              ramping={ramping}
              disabled={hasActiveHold}
            />
          </IfPermitted>
          <IfPermitted permissions={["IsClinicalLeader"]} requireAll={true}>
            &nbsp;
            <HoldButton clinicianId={clinicianId} />
          </IfPermitted>
        </div>

        <section>
          <Collapsible.Root
            open={moreDetailIsOpen}
            onOpenChange={setMoreDetailIsOpen}
          >
            <Collapsible.Trigger asChild>
              <Text color="$neutral10">
                View more details&nbsp;
                {moreDetailIsOpen ? (
                  <DownChevronIcon />
                ) : (
                  <RightChevronIcon height={16} width={16} />
                )}
              </Text>
            </Collapsible.Trigger>
            <Collapsible.Content
              style={{ textAlign: "left", marginTop: "8px" }}
            >
              <Text fontSize={14} color="$neutral10">
                Match requests are collected every Monday and Wednesday
                afternoon. New clients’ start dates will likely spread across
                the next 1-3 weeks. You should not receive more than 3 new
                clients starting therapy on the same day.
              </Text>
            </Collapsible.Content>
          </Collapsible.Root>
        </section>

        {hasPTONextWeek && (
          <Text color={"$red11"} notFlex>
            PTO next week
          </Text>
        )}
      </div>
    </MatchRequestWidgetContainer>
  );
};

const MatchesRequested = ({
  clinicianId,
  defaultMatchesRequested,
  ramping,
  disabled,
}: {
  clinicianId: number;
  defaultMatchesRequested: number;
  ramping: boolean;
  disabled: boolean;
}) => {
  const [newMatchesRequested, setNewMatchesRequested] = React.useState<number>(
    defaultMatchesRequested,
  );

  let reasons = reasonsTenured;
  if (ramping) {
    reasons = reasonsRamping;
  }

  const [reason, setReason] = React.useState<string>("");
  const [additionalReason, setAdditionalReason] = React.useState<string>("");
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const { renderNotification } = useNotification();

  const dispatch: ThunkDispatch<AppState, any, Action> = useDispatch();

  const { cliniciansManualCapacityDataMap } = useShallowEqualSelector(
    (state) => ({
      cliniciansManualCapacityDataMap:
        state.slottool.clinicianManualCapacityDataMap,
    }),
  );

  const thisCliniciansManualCapacity =
    cliniciansManualCapacityDataMap[clinicianId];

  useEffect(() => {
    if (!thisCliniciansManualCapacity) {
      return;
    }
    if (
      thisCliniciansManualCapacity.note &&
      reasons.indexOf(thisCliniciansManualCapacity.note)
    ) {
      setReason(thisCliniciansManualCapacity.note);
    }

    if (
      thisCliniciansManualCapacity.capacity ||
      thisCliniciansManualCapacity.capacity === 0
    ) {
      setNewMatchesRequested(thisCliniciansManualCapacity.capacity);
    }
  }, [clinicianId, thisCliniciansManualCapacity]);

  const upsertCapacity = async () => {
    if (!reason || reason === "") {
      throw Error("missing_reason");
    } else if (reason === OTHER && !additionalReason) {
      throw Error("missing_additional_reason");
    }

    return dispatch(
      slotToolOperations.upsertClinicianManualCapacity(
        clinicianId,
        newMatchesRequested,
        defaultMatchesRequested,
        false, // block biweekly -- not saved here but kept in for backwards compatibility
        false, // unused 'block kp
        reason === OTHER ? `Other: ${additionalReason}` : reason,
      ),
    );
  };

  const radixReasons: ComposedSelectRadixUIOption[] = reasons.map((r) => ({
    id: r,
    value: r,
    label: r,
  }));

  return (
    <>
      <div>
        <Root open={modalOpen} onOpenChange={setModalOpen}>
          <Trigger asChild>
            <Button
              size="small"
              variant="secondary"
              backgroundColor="transparent"
              disabled={disabled}
            >
              Change Request
            </Button>
          </Trigger>

          <Dialog.Portal>
            <Overlay />
            <Content style={{ padding: "8px 16px", minWidth: "500px" }}>
              <Header title="Change Match Request" />
              <section>
                <Title6 style={{ margin: "16px 0 8px" }}>
                  Next Match Request
                </Title6>
                <p>
                  The default match request suggested by the system is{" "}
                  {defaultMatchesRequested}.
                </p>
                <MatchesRequestedStepper
                  matchesRequested={newMatchesRequested}
                  setMatchesRequested={setNewMatchesRequested}
                />
              </section>
              <section style={{ margin: "16px 0" }}>
                <Title6 style={{ margin: "16px 0 8px" }}>
                  Reason for change
                  <Text color="$red11" notFlex>
                    *
                  </Text>
                </Title6>
                <ComposedRadixUISelect
                  options={radixReasons}
                  value={reason}
                  selectLabel="Reason for change"
                  onChange={(v) => setReason(v)}
                  isInModal
                />
              </section>
              {reason === OTHER ? (
                <section>
                  <Text>
                    Please elaborate on the reason for the change.
                    <Text color="$red11" notFlex>
                      *
                    </Text>
                  </Text>
                  <TextArea
                    style={{
                      margin: "8px 0",
                      padding: "0 4px",
                      minWidth: "80%",
                    }}
                    defaultValue={additionalReason}
                    onChange={(t) => setAdditionalReason(t.target.value)}
                  ></TextArea>
                </section>
              ) : null}

              <Footer
                gap={12}
                justifyContent={"flex-end"}
                style={{ padding: "8px 0" }}
              >
                <Close asChild>
                  <Button
                    size={"small"}
                    variant={"secondary"}
                    css={{ border: "1px solid $neutral12" }}
                  >
                    <Text>Cancel</Text>
                  </Button>
                </Close>
                <Button
                  size={"small"}
                  onClick={(e) => {
                    e.preventDefault();
                    upsertCapacity()
                      .then(() => {
                        renderNotification({
                          message:
                            "Successfully updated this clinician's match request.",
                          notificationType: "success",
                        });
                        setModalOpen(false);
                      })
                      .catch((error) => {
                        let errorMessage;
                        if (error.message === "missing_reason") {
                          errorMessage = "Please pick a reason for the change.";
                        } else if (
                          error.message === "missing_additional_reason"
                        ) {
                          errorMessage =
                            "Please provide additional context for the change.";
                        }
                        renderNotification({
                          message: `There was an error setting this clinician's match request. ${errorMessage}`,
                          notificationType: "error",
                        });
                      });
                  }}
                >
                  <Text color={"$neutral0"}>Submit</Text>
                </Button>
              </Footer>
            </Content>
          </Dialog.Portal>
        </Root>

        <Notification />
      </div>
    </>
  );
};

/** Sorry, looks like we don't have a mature input component, so this is still antd */
const MatchesRequestedStepper: React.FC<{
  matchesRequested: number;
  setMatchesRequested: (n: number) => void;
}> = ({ matchesRequested, setMatchesRequested }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          maxWidth: "240px",
          marginRight: "16px",
        }}
      >
        <antd.Button
          shape="circle"
          icon={<MinusOutlined />}
          onClick={() => setMatchesRequested(matchesRequested - 0.5)}
        />
        <antd.Input
          value={matchesRequested}
          onFocus={(e) => e.target.select()}
          onChange={(e) =>
            Number.isNaN(parseInt(e.target.value))
              ? setMatchesRequested(0)
              : setMatchesRequested(parseInt(e.target.value))
          }
          style={{
            fontSize: "24px",
            textAlign: "center",
            margin: "0 8px",
            padding: "4px",
          }}
        />
        <antd.Button
          shape="circle"
          icon={<PlusOutlined />}
          onClick={() => setMatchesRequested(matchesRequested + 0.5)}
        />
      </div>
      <span>new clients</span>
    </div>
  );
};
