import { Link } from "react-router-dom";
import styled from "styled-components";
import { Flex } from "../../../../../design-system/layout/flex";
import { styledStitches } from "@/app/design-system/styles/stitches.config";

// The ClientPane may have links that are visually nested but not nested in terms of the DOM (nested interactive elements is illegal).
// CSS properties that support that will be annotated with "VNL" (visually nested links)
// Based on the second (out of 3) example in this Nested Links Without Nesting Links (https://www.sarasoueidan.com/blog/nested-links/) article.
// We are using #2 because it is perfect for our use case
// For this behave as expected, we'll need to use the VisuallyNestedLink component as well

// VNL (1/2) The link that needs to float above the card needs to have a z-index higher than the z-index of the StyledLink.
export const StyledClientPane = styledStitches(Flex, {
  width: "100%",
  background: "white",
  p: 20,
  pr: 10,
  minHeight: 110,
  gap: 24,

  // VNL (2/2) Making this containing component have a position of relative makes it so that the floating link does not cover the span of the entire page
  position: "relative",

  "&:hover": {
    transition: "background-color ease-in-out",
    transitionDuration: "150ms",
    backgroundColor: "$neutral2",

    ".client-name": {
      color: "$blue11",
    },

    ".chevron-right": {
      display: "inline",
      transition: "transform ease-in-out",
      transform: "translateX(5px)",
    },
  },
});

export const ClientPaneContainer = styledStitches("div", {
  "&:first-of-type > div": {
    btrr: 12,
    btlr: 12,
  },

  "&:last-of-type > div": {
    bbrr: 12,
    bblr: 12,
  },

  "&:not(&:last-of-type) > div": {
    borderBottom: "0.5px solid $neutral8",
  },
});
