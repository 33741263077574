import { useCallback, useState } from "react";

export const useToggle = (initialState = false) => {
  const [state, setState] = useState(initialState);

  const toggle = useCallback(() => setState((state) => !state), []);

  // https://pauledenburg.com/typescript-not-all-constituents-of-type-boolean-void-are-callable/
  return [state, toggle] as const;
};
