// WARNING: Reach-UI is no longer being maintained as of September 2022. Please don't use this anymore. We should be using Radix's select (or listbox) instead as of May 2023.
// https://github.com/reach/reach-ui/issues/972
import {
  ListboxArrow,
  ListboxButton,
  ListboxInput,
  ListboxList,
  ListboxOption,
  ListboxPopover,
} from "@reach/listbox";
import { keyframes, styledStitches } from "../styles/stitches.config";

export const contentShow = keyframes({
  "0%": { opacity: 0, transform: "scale(.96)" },
  "100%": { opacity: 1, transform: "scale(1)" },
});

export const StyledSelectButton = styledStitches(ListboxButton, {
  display: "flex",
  width: 250,
  border: "1px solid",
  borderColor: "$neutral7",
  padding: "8px 4px 8px 18px",
  br: 4,
  color: "$neutral11",
  alignItems: "center",
  justifyContent: "space-between",
  cursor: "pointer",
  boxSizing: "border-box",
  height: 42,

  "&:active": {
    transform: "scale(0.995)",
  },

  '&[aria-disabled="true"]': {
    cursor: "not-allowed",
    borderColor: "$neutral5",
    color: "$neutral8",
  },

  '&[data-reach-listbox-button][aria-expanded="true"]': {
    borderBottom: "none",
    boxShadow: "0px 6px 16px 0px #00000014",
    bblr: 0,
    bbrr: 0,
  },
});

const StyledSelectOptionsList = styledStitches(ListboxList, {
  backgroundColor: "white",
  position: "absolute",
  width: 250,
  p: 8,
  animation: `${contentShow} 0.1s ease-in-out`,
  border: "1px solid",
  borderColor: "$neutral7",
  br: 4,
  gap: 5,
  boxShadow: "0px 8px 8px 0px #00000014",
  borderTop: "none",
  btlr: 0,
  btrr: 0,
  maxHeight: "50vh",
  overflowY: "scroll",

  "[data-current-nav]": {
    backgroundColor: "$neutral2",
  },

  "[data-current-selected]": {
    backgroundColor: "$neutral3",
  },

  "&:focus": {
    outlineStyle: "none",
  },
});

export const StyledSelectOption = styledStitches(ListboxOption, {
  listStyleType: "none",
  py: 6,
  px: 10,
  br: 4,
  cursor: "pointer",
  fontSize: 16,

  "&:hover": {
    backgroundColor: "$neutral2",
  },

  /**
   * @hint
   * when selecting classes or data attributes that belong to the immediate element, use &
   * as a chain selector, otherwise it will select children with those values.
   *
   * @hint
   * styles for the option matching the current value of the input
   */

  "&[data-reach-listbox-option][data-current-selected]": {},

  /**
   * @hint
   * when selecting classes or data attributes that belong to the immediate element, use &
   * as a chain selector, otherwise it will select children with those values.
   *
   * @hint
   * styles for the option matching the user's navigation selection
   */
  "&[data-reach-listbox-option][data-current-nav]": {},
});

export const StyledSelectArrow = styledStitches(ListboxArrow, {
  display: "flex",
  alignItems: "center",

  transition: "transform ease-in-out 0.25s",

  "&[data-expanded]": {
    transition: "transform ease-in-out 0.25s",
    transform: "rotate(-180deg)",
  },
});

export const StyledSelectPopover = styledStitches(ListboxPopover, {
  position: "relative",
  z: 10000,
});

export const SelectRoot = styledStitches(ListboxInput, {});

export const SelectButton = StyledSelectButton;
export const SelectArrow = StyledSelectArrow;
export const SelectOption = StyledSelectOption;
export const SelectOptionsList = StyledSelectOptionsList;
export const SelectPopover = StyledSelectPopover;
