import React from "react";
import moment from "moment";
import { AxiosResponse } from "axios";
import api, { wrapApiCall } from "../../../api";
import { AsyncActionCreator, AsyncAction } from "../types";
import {
  matchmakerActions,
  MatchmakerState,
  createDefaultTagMap,
  StateMatchSlotPreference,
  WeightTagMap,
  UpdateRematchInfoAction,
  matchmakerUtils,
  GroupsMatchData,
} from "./";
import {
  FitSuggestionResponse,
  MatchData,
  TagWeightMap,
  BasicMatch,
  ExtendedMatch,
  Consult,
  MatchTag,
  Day,
  Time,
  MatchSlotPreference,
  DayTime,
  ClinicPreference,
  ConflictOfInterest,
  APIConflictOfInterest,
  SlotPref,
  MatchAgeRange,
} from "../../../api/types";
import { isEqual, flatten, values, isEmpty } from "lodash-es";
import { message } from "antd";
import { stripHtmlFromString, slotArraysMatch } from "../../../app/_helpers";
import { matchOperations } from "../matches";
import { generateDefaultDayTimes } from "../../../app/_helpers/constants";
import { Dispatcher } from "..";
import { QueryClient } from "react-query";
import { myConsultsQueryKey } from "@/app/consults-and-matching/MatchingV2/use-get-my-consults";
import { Permissions } from "@/app/_helpers/permissions";

const checkIfEnrollingInGroup = (groupsMatchData: GroupsMatchData) => {
  const { clientIdToEnroll, groupIdToEnroll, dateEnrolledForGroup } =
    groupsMatchData;
  return !!clientIdToEnroll && !!groupIdToEnroll && !!dateEnrolledForGroup;
};

export const saveMatch: AsyncActionCreator =
  (queryClient: QueryClient) => async (dispatch, getState) => {
    const state = getState().matchmaker;
    const groupsMatchState = getState().matchmaker.groupsMatchData;
    if (state.loading.matchSave) return;
    if (state.removedTags.length > 0) {
      message.warn(
        "Please take action on the Consult Step before saving this match.",
      );
      return;
    }
    dispatch(matchmakerActions.setLoading({ matchSave: true }));

    const consultTagWeightMap: TagWeightMap = {};
    Object.keys(state.consultTagMap).forEach((weight) => {
      state.consultTagMap[weight].forEach((tagId: number) => {
        consultTagWeightMap[tagId] = parseInt(weight);
      });
    });

    const matchTagWeightMap: TagWeightMap = {};
    Object.keys(state.matchTagMap).forEach((weight) => {
      state.matchTagMap[weight].forEach((tagId: number) => {
        matchTagWeightMap[tagId] = parseInt(weight);
      });
    });

    const { showOtFits, showInPersonFits } =
      matchmakerUtils.matchOtSelector(state);
    const msps = state.matchSlotPreferences;
    const matchSlotPreferences: MatchSlotPreference[] = [];
    const generalSlots = msps.general!.slots;

    // Create General Slots
    generalSlots.forEach((slot) => {
      matchSlotPreferences.push({
        subregion: null,
        location_preference_level: null,
        day_of_week: slot.day,
        start_time: slot.time,
        end_time: moment(slot.time, "HH:mm:ss")
          .add(1, "hour")
          .format("HH:mm:ss"),
        preference_origin: "matchmaker",
        is_maybe_slot: slot.pref_level === "maybe",
        is_general: true,
      });
    });

    if (showOtFits) {
      // For OT matches, we just copy what's saved in the general_slots
      generalSlots.forEach((slot) => {
        matchSlotPreferences.push({
          subregion: null,
          location_preference_level: null,
          day_of_week: slot.day,
          start_time: slot.time,
          end_time: moment(slot.time, "HH:mm:ss")
            .add(1, "hour")
            .format("HH:mm:ss"),
          preference_origin: "matchmaker",
          is_maybe_slot: slot.pref_level === "maybe",
          is_general: false,
        });
      });
    }

    if (showInPersonFits) {
      // for non-OT matches, we save all msps by Subregion ID
      Object.keys(msps).forEach((key) => {
        if (key === "general") {
          return;
        } else {
          const pref: StateMatchSlotPreference = msps[key];
          if (pref.use_general_slots) {
            generalSlots.forEach((slot) =>
              matchSlotPreferences.push({
                subregion: pref.subregion,
                location_preference_level: pref.location_preference_level,
                day_of_week: slot.day,
                start_time: slot.time,
                end_time: moment(slot.time, "HH:mm:ss")
                  .add(1, "hour")
                  .format("HH:mm:ss"),
                preference_origin: "matchmaker",
                is_maybe_slot: slot.pref_level === "maybe",
                is_general: false,
              }),
            );
          } else {
            pref.slots.forEach((slot) =>
              matchSlotPreferences.push({
                subregion: pref.subregion,
                location_preference_level: pref.location_preference_level,
                day_of_week: slot.day,
                start_time: slot.time,
                end_time: moment(slot.time, "HH:mm:ss")
                  .add(1, "hour")
                  .format("HH:mm:ss"),
                preference_origin: "matchmaker",
                is_maybe_slot: slot.pref_level === "maybe",
                is_general: false,
              }),
            );
          }
        }
      });
    }

    let profileSlotPreferences: MatchSlotPreference[] | undefined;
    if (state.clientProfileData && state.clientProfileData.fetched) {
      profileSlotPreferences = [];
      const { locationPreferences, schedulePreferences } =
        state.clientProfileData;
      Object.keys(locationPreferences).forEach((subregionId) => {
        const locationPref = locationPreferences[subregionId];
        schedulePreferences.forEach((dayTime) => {
          profileSlotPreferences!.push({
            subregion: parseInt(subregionId),
            location_preference_level: locationPref,
            day_of_week: dayTime.day,
            start_time: moment(dayTime.time, "HH:mm:ss").format("HH:mm:ss"),
            end_time: moment(dayTime.time, "HH:mm:ss")
              .add(1, "hour")
              .format("HH:mm:ss"),
            preference_origin: "client-profile",
            is_maybe_slot: false,
            is_general: false,
          });
        });
      });
    }

    const conflictsOfInterest = state.conflictsOfInterest.map((item) => ({
      id: item.id,
      type: item.type,
    }));

    const initData = state.initData;

    const matchData: MatchData = {
      id: initData.match ? initData.match.id : undefined,
      consultId: initData.consult ? initData.consult.id : null,
      conflictOfInterest: state.draft.conflict_of_interest,
      clientEmail: initData.consult
        ? initData.consult.client.email
        : state.clientEmail,
      queueNote: state.draft.queue_note,
      matchNote: state.draft.match_note,
      selectedClinicianId: state.selectedClinicianId,
      matchAfter: state.draft.match_after,

      isReferral: state.draft.is_referral,
      referralNote: state.draft.is_referral ? state.draft.referral_note : "",

      isRematch: state.draft.is_rematch,
      rematchId: initData.rematch ? initData.rematch.id : null,
      rematchNote: state.draft.is_rematch ? state.draft.rematch_note : "",

      isBiweeklyFit: state.draft.is_biweekly_fit!,
      cadencePrefStrength: state.draft.cadence_pref_strength!,

      consultTagWeightMap,
      matchTagWeightMap,
      starredTagIds: state.starredTagIds,
      matchSlotPreferences,
      profileSlotPreferences,
      excludedClinicianIds: state.excludedClinicianIds,
      conflictsOfInterest,
      matchAgeRange: state.matchAgeRange,

      deliveryPreference: state.draft.delivery_preference!,
      clinicianApprovesOt: Boolean(state.draft.clinician_approves_ot),
      priority: state.draft.priority,

      excludeFromAQM: state.draft.exclude_from_aqm,
      hybridPreference: state.draft.hybrid_preference,
      serviceType: state.isOpenCouplesMatchmaker ? "couples" : "individual",
      methodologyVersion: state.methodologyVersion,
    };

    const shouldEnrollInGroup = checkIfEnrollingInGroup(groupsMatchState);

    if (shouldEnrollInGroup) {
      handleSaveMatchAndEnrollInGroup(
        matchData,
        initData.consult,
        groupsMatchState,
        dispatch,
        queryClient,
      );
    } else {
      handleSaveMatch(matchData, initData.consult, dispatch, queryClient);
    }
  };

const getSaveMatchUrlAndAction = (matchData: MatchData) => {
  const saveMatchAction = matchData.id ? "update" : "create";
  const saveMatchUrl = `ehr/matchtool/${saveMatchAction}_match/`;
  return {
    saveMatchAction,
    saveMatchUrl,
  };
};

const getEnrollClientUrlAndData = (groupsMatchState: GroupsMatchData) => {
  const { clientIdToEnroll, groupIdToEnroll, dateEnrolledForGroup } =
    groupsMatchState;
  const enrollClientUrl = `/api/clientgroups/v1/enroll_client_in_group/`;
  const enrollClientData = {
    client: clientIdToEnroll,
    group: groupIdToEnroll,
    date_joined: dateEnrolledForGroup,
  };
  return {
    enrollClientUrl,
    enrollClientData,
  };
};

const dispatchSaveMatchSuccessActions = (
  id: number,
  consult: Consult | null,
  dispatch: Dispatcher,
) => {
  dispatch(matchOperations.getMyMatches());
  dispatch(matchOperations.getQueuedMatches(false, false));
  dispatch(matchOperations.getExtendedMatch(id));
  dispatch(matchOperations.getRelatedModelsForMatches([id]));
};

const dispatchEnrollClientSuccessActions = (dispatch: Dispatcher) => {
  dispatch(matchmakerActions.clearGroupsEnrollData);
  dispatch(matchmakerActions.setIsGroupsAppropriateMatch(false));
};

const handleSaveMatch = async (
  matchData: MatchData,
  consult: Consult | null,
  dispatch: Dispatcher,
  queryClient: QueryClient,
) => {
  const { saveMatchAction, saveMatchUrl } = getSaveMatchUrlAndAction(matchData);

  try {
    const response: AxiosResponse<{ id: number }> = await wrapApiCall(
      api.post(saveMatchUrl, matchData),
      dispatch,
    );
    message.success(`Successfully ${saveMatchAction}d the Match!`);
    dispatchSaveMatchSuccessActions(response.data.id, consult, dispatch);
    dispatch(matchmakerActions.closeMatchmaker());
  } catch (e) {
    message.error(
      `There was an error saving your match. Please contact techsupport@twochairs.com.`,
    );
  } finally {
    dispatch(matchmakerActions.setLoading({ matchSave: false }));
    queryClient.invalidateQueries([myConsultsQueryKey]);
  }
};

// We're making two updates when this function is called: saving a match and enrolling a client in a group.
// We need to handle each of the four possible outcomes (match success/fail * client enroll success/fail)
const handleSaveMatchAndEnrollInGroup = async (
  matchData: MatchData,
  consult: Consult | null,
  groupsMatchState: GroupsMatchData,
  dispatch: Dispatcher,
  queryClient: QueryClient,
) => {
  const { saveMatchAction, saveMatchUrl } = getSaveMatchUrlAndAction(matchData);
  const { enrollClientUrl, enrollClientData } =
    getEnrollClientUrlAndData(groupsMatchState);
  const { client: clientIdToEnroll } = enrollClientData;

  const saveMatchPromise: Promise<AxiosResponse<{ id: number }>> = wrapApiCall(
    api.post(saveMatchUrl, matchData),
    dispatch,
  );

  const enrollClientPromise = wrapApiCall(
    api.post(enrollClientUrl, enrollClientData),
    dispatch,
  );

  const [saveMatchPromiseResult, enrollClientPromiseResult] =
    await Promise.allSettled([saveMatchPromise, enrollClientPromise]);

  if (
    saveMatchPromiseResult.status === "fulfilled" &&
    enrollClientPromiseResult.status === "fulfilled"
  ) {
    // saveMatch success
    message.success(`Successfully ${saveMatchAction}d the Match!`);
    const { value: saveMatchResponse } = saveMatchPromiseResult;
    dispatchSaveMatchSuccessActions(
      saveMatchResponse.data.id,
      consult,
      dispatch,
    );

    // enrollClient success
    message.success(
      `Successfully enrolled client ${clientIdToEnroll} in groups!`,
    );
    dispatchEnrollClientSuccessActions(dispatch);

    // close matchmaker when save match succeeds
    dispatch(matchmakerActions.closeMatchmaker());
  } else if (
    saveMatchPromiseResult.status === "fulfilled" &&
    enrollClientPromiseResult.status === "rejected"
  ) {
    // saveMatch success
    message.success(`Successfully ${saveMatchAction}d the Match!`);
    const { value: saveMatchResponse } = saveMatchPromiseResult;
    dispatchSaveMatchSuccessActions(
      saveMatchResponse.data.id,
      consult,
      dispatch,
    );

    // enrollClient error (since we close matchmaker when saveMatch succeeds we'll clear groups data even if error)
    message.error(
      `There was an error enrolling client ${clientIdToEnroll} in groups. Please contact support@twochairs.com.`,
    );
    dispatch(matchmakerActions.clearGroupsEnrollData);
    dispatch(matchmakerActions.setIsGroupsAppropriateMatch(false));

    // close matchmaker when save match succeeds
    dispatch(matchmakerActions.closeMatchmaker());
  } else if (
    saveMatchPromiseResult.status === "rejected" &&
    enrollClientPromiseResult.status === "fulfilled"
  ) {
    // saveMatch error
    message.error(
      `There was an error saving your match. Please contact techsupport@twochairs.com.`,
    );

    // enrollClient success
    message.success(
      `Successfully enrolled client ${clientIdToEnroll} in groups!`,
    );
    dispatchEnrollClientSuccessActions(dispatch);
  } else if (
    saveMatchPromiseResult.status === "rejected" &&
    enrollClientPromiseResult.status === "rejected"
  ) {
    // saveMatch error
    message.error(
      `There was an error saving your match. Please contact techsupport@twochairs.com.`,
    );

    // enrollClient error
    message.error(
      `There was an error enrolling client ${clientIdToEnroll} in groups. Please contact support@twochairs.com.`,
    );
  }

  dispatch(matchmakerActions.setLoading({ matchSave: false }));
  queryClient.invalidateQueries([myConsultsQueryKey]);
};

export const setClientEmail: AsyncActionCreator =
  (email: string) => async (dispatch) => {
    dispatch(
      matchmakerActions.setClientEmailIsValid({
        email,
        clientId: null,
        is_kp_referral: false,
      }),
    );
    try {
      const response = await wrapApiCall(
        api.get("/ehr/client_exists_by_email/" + email),
        dispatch,
      );
      dispatch(
        matchmakerActions.setClientEmailIsValid({
          email,
          clientId: response.data.id,
          is_kp_referral: response.data.is_kp_referral,
        }),
      );
      dispatch(getClientProfileFields(response.data.id));
    } catch (e) {
      dispatch(
        matchmakerActions.setClientEmailIsValid({
          email,
          clientId: null,
          is_kp_referral: false,
        }),
      );
    }
  };

export const getPotentialFits: AsyncActionCreator =
  () => async (dispatch, getState) => {
    dispatch(matchmakerActions.setLoading({ potentialFits: true }));

    const state = getState();
    const initData = state.matchmaker.initData;

    const matchClientEmail =
      initData.consult?.client?.email || // present when match from consult carousel
      initData.match?.client?.email || // present when match from match row
      initData.rematch?.client?.email || // present when match from rematch
      state.matchmaker.clientEmail; // present when match by email
    const matchTagMap = getState().matchmaker.matchTagMap;
    const tagWeightMap = {};

    Object.keys(matchTagMap).forEach((k) => {
      matchTagMap[k].forEach((id: number) => {
        tagWeightMap[id] = k;
      });
    });

    const { showOtFits, showInPersonFits } = matchmakerUtils.matchOtSelector(
      state.matchmaker,
    );
    const msps = state.matchmaker.matchSlotPreferences;
    const generalSlots = msps.general!.slots;

    let includeTeletherapySubregion = false;
    if (showOtFits && generalSlots.length > 0) {
      // This tells the backend to add a subregion_id of null
      // which is interpreted as the match being open to (or only) remote
      includeTeletherapySubregion = true;
    }
    const subregionIds = new Set();

    if (showInPersonFits) {
      // for non-OT matches, we save all msps by Subregion ID
      Object.keys(msps)
        .filter((key) => key !== "general")
        .forEach((key) => {
          const pref: StateMatchSlotPreference = msps[key];
          subregionIds.add(pref.subregion);
        });
    }

    // When getting potential fits we only need to know the clinician COIs
    const conflictsOfInterest = state.matchmaker.conflictsOfInterest
      .filter((item) => item.type === "clinician")
      .map((item) => item.id);

    const matchData = {
      id: initData.match ? initData.match.id : undefined,
      clientEmail: initData.consult
        ? initData.consult.client.email
        : state.matchmaker.clientEmail,
      subregionIds: Array.from(subregionIds),
      excludedClinicianIds: state.matchmaker.excludedClinicianIds,
      conflictsOfInterest: conflictsOfInterest,
      minimum_age: state.matchmaker.matchAgeRange?.minimum_age ?? null,
      maximum_age: state.matchmaker.matchAgeRange?.maximum_age ?? null,
      filter_clinical_fits:
        state.matchmaker.matchAgeRange?.filter_clinical_fits ?? null,
      deliveryPreference: state.matchmaker.draft.delivery_preference!,
      clinicianApprovesOt: Boolean(
        state.matchmaker.draft.clinician_approves_ot,
      ),
      includeTeletherapySubregion: includeTeletherapySubregion,
      is_rematch: Boolean(state.matchmaker.draft.is_rematch),
    };

    // Prepend "tag_" to each tag key so we can identify them
    // on the backend
    const tags = {};
    for (const [tagId, weight] of Object.entries(tagWeightMap)) {
      tags[`tag_${tagId}`] = weight;
    }

    const params = {
      matchClientEmail,
      ...tags,
      ...matchData,
      // excludedClinicianIds can sometimes be too long to encode normally:
      // (eg. excludedClinicianIds[]=1&excludedClinicianIds[]=43&...)
      // To fix we encode it as one string with the ids separated by commas:
      // (eg. excludedClinicianIds=1,43,...).
      // If the array is empty the param will be an empty string.
      excludedClinicianIds: matchData.excludedClinicianIds.join(","),
      methodologyVersion: state.matchmaker.methodologyVersion,
    };

    const response: AxiosResponse<FitSuggestionResponse> = await wrapApiCall(
      api.get("/ehr/matchtool/clinician_suggestions/", { params }),
      dispatch,
    );

    dispatch(matchmakerActions.setPotentialFits(response.data));
    dispatch(
      matchmakerActions.patchMatchDraft({
        max_score: response.data.maxScore,
      }),
    );
  };

export const setConsultTags: AsyncActionCreator =
  (cts: WeightTagMap) => (dispatch) => {
    dispatch(matchmakerActions.setConsultTags(cts));
    dispatch(setMatchTags(cts));
  };

export const setMatchTags: AsyncActionCreator =
  (cts: WeightTagMap) => (dispatch, getState) => {
    dispatch(matchmakerActions.setMatchTags(cts));
    const matchTagIds = flatten(values(cts));
    const starredTagIds = getState().matchmaker.starredTagIds;
    dispatch(
      matchmakerActions.setStarredTagIds(
        starredTagIds.filter((id) => matchTagIds.includes(id)),
      ),
    );
    dispatch(delayGettingPotentialFits());
  };

export const canStep = (state: MatchmakerState): boolean => {
  const hasProperInitialData = Boolean(
    state.initData.consult ||
      state.initData.match ||
      (state.clientEmail &&
        (!state.draft.is_rematch || state.draft.rematch_note)),
  );
  const hasSlotPrefs = Object.keys(state.matchSlotPreferences).every(
    (key) =>
      state.matchSlotPreferences[key].slots.length > 0 ||
      (key !== "general" && state.matchSlotPreferences[key].use_general_slots),
  );

  const { showInPersonFits, showOtFits } =
    matchmakerUtils.matchOtSelector(state);
  const hasLocationPrefs = Object.keys(state.matchSlotPreferences).length > 1;

  const { hasSubmittedCOI } = state;

  const hasSubmittedCadencePrefs: boolean =
    state.draft.is_biweekly_fit !== undefined &&
    state.draft.cadence_pref_strength !== undefined;

  const isGroupsMatch = state.groupsMatchData.isGroupsAppropriateMatch;

  return (
    hasProperInitialData &&
    // if enrolling in a group, we don't worry about slot prefs
    (hasSlotPrefs || isGroupsMatch) &&
    state.draft.clinician_approves_ot !== undefined &&
    (showInPersonFits ? hasLocationPrefs : showOtFits) &&
    hasSubmittedCOI &&
    hasSubmittedCadencePrefs
  );
};
export const setMatchTag: AsyncActionCreator =
  ({ tagId, weight, checked }) =>
  (dispatch) => {
    dispatch(
      matchmakerActions.setMatchTag({
        tagId,
        weight,
        checked,
      }),
    );
    dispatch(delayGettingPotentialFits());
  };

export const searchConflictsOfInterest: AsyncActionCreator =
  (searchTerm: string) => async (dispatch) => {
    try {
      const response: AxiosResponse<ConflictOfInterest[]> = await wrapApiCall(
        api.get(
          `/ehr/matches/v2/get_conflict_of_interest_results/?search_term=${searchTerm}`,
        ),
        dispatch,
      );

      dispatch(matchmakerActions.loadConflictOfInterestResults(response.data));
    } catch (e) {
      message.error(
        "Error getting conflict of interest results, please refresh!",
      );
    }
  };

const delayGettingPotentialFits: AsyncActionCreator =
  () => (dispatch, getState) => {
    const tagsThen = getState().matchmaker.matchTagMap;
    setTimeout(() => {
      const tagsNow = getState().matchmaker.matchTagMap;
      if (isEqual(tagsThen, tagsNow)) {
        dispatch(getPotentialFits());
      }
    }, 1200);
  };

export const getClinicianExclusions: AsyncActionCreator =
  (matchId: number) => async (dispatch) => {
    try {
      const response: AxiosResponse<number[]> = await wrapApiCall(
        api.get(`/ehr/matches/v2/${matchId}/exclusions/`),
        dispatch,
      );
      response.data.forEach((clinicianId) =>
        dispatch(
          matchmakerActions.setClinicianExclusion({
            clinicianId,
            excluded: true,
          }),
        ),
      );
    } catch (e) {
      console.error("getClinicianExclusions Catch");
    }
  };

interface SingleAnswerProfileField {
  answer_type: "text";
  answer: string | null;
}
interface MultiAnswerProfileField {
  answer_type: "text[]";
  answer: string[] | null;
}
interface ClientProfileFieldsResponse {
  conflict_of_interest: SingleAnswerProfileField;
  location_preferences: {
    [subregion: string]: SingleAnswerProfileField;
  };
  schedule_preferences: {
    friday: MultiAnswerProfileField;
    monday: MultiAnswerProfileField;
    tuesday: MultiAnswerProfileField;
    saturday: MultiAnswerProfileField;
    thursday: MultiAnswerProfileField;
    wednesday: MultiAnswerProfileField;
  };
  hybrid_model_preference: MultiAnswerProfileField;
  vendor_id?: number;
}

const convertLocationPreferenceString = (
  pref: string | null,
): "strong" | "open" | null => {
  if (!pref) return null;
  if (stripHtmlFromString(pref).toLowerCase().includes("strong")) {
    return "strong";
  }
  if (pref.toLowerCase().includes("open")) {
    return "open";
  }
  return null;
};

export const convertToTime = (time: string): Time | null => {
  switch (time) {
    case "7-8 AM":
      return "07:00:00";
    case "8-9 AM":
      return "08:00:00";
    case "9-10 AM":
      return "09:00:00";
    case "10-11 AM":
      return "10:00:00";
    case "11 AM - 12 PM": // LMAO
    case "11 AM-12 PM":
      return "11:00:00";
    case "12-1 PM":
      return "12:00:00";
    case "1-2 PM":
      return "13:00:00";
    case "2-3 PM":
      return "14:00:00";
    case "3-4 PM":
      return "15:00:00";
    case "4-5 PM":
      return "16:00:00";
    case "5-6 PM":
      return "17:00:00";
    case "6-7 PM":
      return "18:00:00";
    case "7-8 PM":
      return "19:00:00";
    case "8-9 PM":
      return "20:00:00";
    default:
      return null;
  }
};
export const convertToDay = (day: string): Day | null => {
  const str = day.toLowerCase();
  switch (str) {
    case "monday":
      return "Mo";
    case "tuesday":
      return "Tu";
    case "wednesday":
      return "We";
    case "thursday":
      return "Th";
    case "friday":
      return "Fr";
    case "saturday":
      return "Sa";
    default:
      return null;
  }
};

export const getClientProfileFields: AsyncActionCreator =
  (clientId: number) => async (dispatch, getState) => {
    dispatch(matchmakerActions.setLoading({ clientPreferences: true }));
    const subregions = Object.values(getState().clinics.subregionMap);
    try {
      const response: AxiosResponse<ClientProfileFieldsResponse> =
        await wrapApiCall(
          api.get(`/ehr/clients/${clientId}/profile`),
          dispatch,
        );

      const cpConflictOfInterest = response.data.conflict_of_interest;
      const cpLocationPrefs = response.data.location_preferences;
      const cpSchedulePrefs = response.data.schedule_preferences;

      const conflictOfInterest: string | null = cpConflictOfInterest.answer;
      const locationPreferences = {};
      Object.keys(cpLocationPrefs).forEach((key) => {
        const subregion = subregions.find(
          (s) => key === s.display_name.toLowerCase().replace(" ", "_"),
        );
        if (!subregion) {
          console.error("Couldn't find subregion: " + key);
        } else {
          const preference = convertLocationPreferenceString(
            cpLocationPrefs[key] ? cpLocationPrefs[key].answer : null,
          );
          if (preference) locationPreferences[subregion.id] = preference;
        }
      });

      // We get data back from profile for times that __do_not__ work for the client.
      const scheduleConstraints: DayTime[] = [];
      Object.keys(cpSchedulePrefs).forEach((d) => {
        if (cpSchedulePrefs[d]) {
          cpSchedulePrefs[d].answer.forEach((t: string) => {
            const day = convertToDay(d);
            const time = convertToTime(t);
            if (day && time) {
              scheduleConstraints.push({
                day,
                time,
              });
            }
          });
        }
      });
      let schedulePreferences = generateDefaultDayTimes().filter(
        (dt) =>
          !scheduleConstraints.some(
            (constraint) =>
              dt.day === constraint.day && dt.time === constraint.time,
          ),
      );

      if (response.data.vendor_id) {
        // EXPANDED HOURS -- if we're using the old client profile, do not include these times
        schedulePreferences = schedulePreferences.filter(
          (s) => !["07:00:00", "19:00:00", "20:00:00"].includes(s.time),
        );
      }

      dispatch(
        matchmakerActions.setClientProfileData({
          fetched: true,
          conflictOfInterest,
          locationPreferences,
          schedulePreferences,
          hybridModelPreference:
            response.data.hybrid_model_preference?.answer || [],
        }),
      );
      dispatch(matchmakerActions.setLoading({ clientPreferences: false }));
    } catch (e) {
      console.error("Client did not fill out profile", e);
      dispatch(matchmakerActions.setClientProfileData(false));
      dispatch(matchmakerActions.setLoading({ clientPreferences: false }));
    }
  };

export const addSubregion: AsyncActionCreator =
  (subregionId) => (dispatch, getState) => {
    const generalSlotPrefs = getState().matchmaker.matchSlotPreferences.general;
    if (!generalSlotPrefs) return;
    dispatch(
      matchmakerActions.setMatchSlotPreferences({
        [subregionId]: {
          subregion: subregionId,
          location_preference_level: "strong",
          use_general_slots: true,
          slots: generalSlotPrefs.slots,
        },
      }),
    );
  };

export const toggleUseGeneral: AsyncActionCreator =
  (subregionId: number, useGeneral: boolean) => (dispatch, getState) => {
    const msps = getState().matchmaker.matchSlotPreferences;
    const subregionMSP = msps[subregionId];
    if (!subregionMSP) return;
    dispatch(
      matchmakerActions.setMatchSlotPreferences({
        [subregionId]: {
          ...subregionMSP,
          use_general_slots: useGeneral,
          slots: msps.general!.slots,
        },
      }),
    );
  };

export const changeSubregionPreference: AsyncActionCreator =
  (subregionId: number, pref: "strong" | "open" | null) =>
  (dispatch, getState) => {
    const subregionMSP =
      getState().matchmaker.matchSlotPreferences[subregionId];
    if (!subregionMSP) return;

    if (pref === null)
      dispatch(
        matchmakerActions.removeSubregionFromSlotPreferences(subregionId),
      );
    else
      dispatch(
        matchmakerActions.setMatchSlotPreferences({
          [subregionId]: {
            ...subregionMSP,
            location_preference_level: pref,
          },
        }),
      );
  };

export const updateSlotPreferences: AsyncActionCreator =
  (stateKey: "general" | number, slots: SlotPref[]) => (dispatch, getState) => {
    if (stateKey === "general") {
      dispatch(
        matchmakerActions.setMatchSlotPreferences({
          general: {
            slots,
          },
        }),
      );
    } else {
      const msp = getState().matchmaker.matchSlotPreferences[stateKey];
      dispatch(
        matchmakerActions.setMatchSlotPreferences({
          [stateKey]: {
            ...msp,
            slots,
          },
        }),
      );
    }
  };

export const setBackwardsCompatibleLocations: AsyncActionCreator =
  (matchId: number) => async (dispatch, getState) => {
    try {
      const url = `ehr/matches/v2/${matchId}/clinic-preferences`;
      const request: AxiosResponse<ClinicPreference[]> = await wrapApiCall(
        api.get(url),
        dispatch,
      );
      const cps = request.data;
      const clinicMap = getState().clinics.clinicMap;
      const map = {};
      cps.forEach((cp) => {
        const subregionId = clinicMap[cp.clinic_id].subregion;
        const strength = cp.preference_level === "consult" ? "strong" : "open";
        if (!(subregionId in map) || strength === "strong") {
          map[subregionId] = strength;
        }
      });
      Object.keys(map).forEach((subregionId) => {
        dispatch(addSubregion(subregionId));
        dispatch(changeSubregionPreference(subregionId, map[subregionId]));
      });
    } catch (e) {
      console.log(e);
    }
  };

export const getMatchSlotPreferences: AsyncActionCreator =
  (matchId: number) => async (dispatch) => {
    dispatch(matchmakerActions.setLoading({ clientPreferences: true }));
    try {
      const url = `ehr/matches/v2/${matchId}/slot-preferences`;
      const request: AxiosResponse<MatchSlotPreference[]> = await wrapApiCall(
        api.get(url),
        dispatch,
      );

      const matchMsps = request.data;

      if (isEmpty(matchMsps)) {
        dispatch(matchmakerActions.setLoading({ clientPreferences: false }));
        return dispatch(setBackwardsCompatibleLocations(matchId));
      }

      const general: { slots: SlotPref[] } = {
        slots: matchMsps
          .filter((msp) => msp.is_general)
          .map((msp) => ({
            day: msp.day_of_week,
            time: msp.start_time as Time,
            pref_level: msp.is_maybe_slot ? "maybe" : "preferred",
          })),
      };
      const stateMsps = {};
      matchMsps
        .filter((msp) => !msp.is_general)
        .filter((msp) => msp.subregion !== null)
        .forEach((msp) => {
          const subregion: string = msp.subregion!.toString();
          if (!(subregion in stateMsps)) {
            stateMsps[subregion] = {
              subregion: subregion,
              location_preference_level: msp.location_preference_level,
              slots: [],
            };
          }
          stateMsps[subregion].slots.push({
            day: msp.day_of_week,
            time: msp.start_time as Time,
            pref_level: msp.is_maybe_slot ? "maybe" : "preferred",
          });
        });

      Object.keys(stateMsps).forEach((subregionId) => {
        stateMsps[subregionId].use_general_slots = slotArraysMatch(
          general.slots,
          stateMsps[subregionId].slots,
        );
      });

      dispatch(
        matchmakerActions.setMatchSlotPreferences({
          general,
          ...stateMsps,
        }),
      );

      dispatch(matchmakerActions.setLoading({ clientPreferences: false }));
    } catch (e) {
      console.error("Unable to get Match Slot Preferences");
      dispatch(matchmakerActions.setLoading({ clientPreferences: false }));
    }
  };

export const openMatchmakerByClientId: AsyncActionCreator =
  (clientId: number) => async (dispatch, getState) => {
    const response: AxiosResponse<ExtendedMatch> = await wrapApiCall(
      api.get(`ehr/matches/v2/${clientId}/most_recent_match_by_client_id/`),
      dispatch,
    );
    dispatch(
      openMatchmaker({
        match: null,
        rematch: response.data,
        consult: null,
      }),
    );
  };

export const openMatchmaker: AsyncActionCreator =
  (args: {
    match: BasicMatch | null;
    rematch: ExtendedMatch | null;
    consult: Consult | null;
  }) =>
  (dispatch, getState) => {
    const subregionIds = getState().clinics.subregionIds;
    if (!subregionIds.length) return;

    if (args.match) {
      return dispatch(editMatchWithMatchmaker(args.match));
    }
    if (args.rematch) {
      return dispatch(rematchWithMatchmaker(args.rematch));
    }
    return dispatch(createNewMatchWithMatchmaker(args.consult));
  };

export const createNewMatchWithMatchmaker: AsyncActionCreator =
  (consult: Consult | null) => (dispatch, getState) => {
    dispatch(
      matchmakerActions.initMatchData({
        consult,
        methodologyVersion: 3,
      }),
    );
    if (consult) {
      dispatch(getClientProfileFields(consult.client.id));
    }
    dispatch(matchmakerActions.openMatchmaker());
  };

export const editMatchWithMatchmaker: AsyncActionCreator =
  (match: BasicMatch, isCouplesMatch: boolean = false) =>
  (dispatch, getState) => {
    dispatch(matchmakerActions.initMatchData({ match }));
    const removedTags: MatchTag[] = [];
    const matchState = getState().matches;
    const matchTagMap = createDefaultTagMap();
    const matchTags = matchState.matchTagsMap[match.id] || [];
    matchTags.forEach((tag: MatchTag) => {
      if (match.methodology_version === tag.version) {
        matchTagMap[tag.weight].push(tag.id);
      }
    });
    dispatch(matchmakerActions.setConsultTags(matchTagMap));
    dispatch(matchmakerActions.setMatchTags(matchTagMap));
    dispatch(matchmakerActions.setRemovedTags(removedTags));
    dispatch(
      matchmakerActions.setStarredTagIds(
        matchTags.filter((mt) => mt.starred).map((mt) => mt.id),
      ),
    );

    dispatch(getMatchSlotPreferences(match.id));
    const cois = getState().matches.matchConflictsOfInterestMap[match.id] || [];
    dispatch(
      matchmakerActions.saveConflictsOfInterest(
        cois.map((coi) => translateCoi(coi)),
      ),
    );

    dispatch(
      matchmakerActions.patchMatchDraft({
        max_score: match.max_score,
        match_after: match.match_after,
        priority: match.priority,
        queue_note: match.queue_note,
        match_note: match.match_note,
        is_rematch: match.is_rematch,
        rematch_id: match.rematch,
        rematch_note: match.rematch_note,
        is_referral: match.status === "referred",
        referral_note: match.referral_note,
        conflict_of_interest: match.conflict_of_interest,
        delivery_preference: match.delivery_preference,
        clinician_approves_ot: match.clinician_approves_ot,
        hybrid_preference: match.hybrid_preference,
        exclude_from_aqm: match.exclude_from_aqm,
        is_biweekly_fit: match.is_biweekly_fit,
        cadence_pref_strength: match.cadence_pref_strength,
      }),
    );
    dispatch(matchmakerActions.stepTo(3));
    dispatch(getClinicianExclusions(match.id));
    dispatch(getPotentialFits());
    dispatch(getMatchAgeRange(match.id));
    if (isCouplesMatch) {
      dispatch(matchmakerActions.openCouplesMatchmaker());
    } else {
      dispatch(matchmakerActions.openMatchmaker());
    }
  };

const getMatchAgeRange: AsyncActionCreator =
  (match_id: Number) => async (dispatch) => {
    const url = `/ehr/matches/v2/${match_id}/get_match_age_range/`;
    const request: AxiosResponse<MatchAgeRange> = await wrapApiCall(
      api.get(url),
      dispatch,
    );
    dispatch(matchmakerActions.setMatchAgeRange(request.data));
  };

export const rematchWithMatchmaker: AsyncActionCreator =
  (rematch: ExtendedMatch, isCouples = false) =>
  (dispatch, getState) => {
    dispatch(matchmakerActions.initMatchData({ rematch }));
    const removedTags: MatchTag[] = [];
    const matchTagMap = createDefaultTagMap();
    rematch.tags.forEach((tag: MatchTag) => {
      if (matchTagMap[tag.weight] !== undefined) {
        matchTagMap[tag.weight].push(tag.id);
      }
    });
    dispatch(matchmakerActions.setConsultTags(matchTagMap));
    dispatch(matchmakerActions.setMatchTags(matchTagMap));
    dispatch(matchmakerActions.setRemovedTags(removedTags));

    dispatch(getMatchSlotPreferences(rematch.id));
    const cois =
      getState().matches.matchConflictsOfInterestMap[rematch.id] || [];
    dispatch(
      matchmakerActions.saveConflictsOfInterest(
        cois.map((coi) => translateCoi(coi)),
      ),
    );

    dispatch(
      matchmakerActions.patchMatchDraft({
        priority: rematch.priority,
        max_score: rematch.max_score,
        queue_note: rematch.queue_note,
        is_rematch: true,
        rematch_id: rematch.id,
        delivery_preference: rematch.delivery_preference,
        clinician_approves_ot: rematch.clinician_approves_ot,
        hybrid_preference: rematch.hybrid_preference,
        conflict_of_interest: rematch.conflict_of_interest,
        is_biweekly_fit: false,
        cadence_pref_strength: "open",
      }),
    );
    dispatch(
      setClientEmail(rematch.couple?.clientA.email ?? rematch.client.email),
    );
    dispatch(getClinicianExclusions(rematch.id));
    dispatch(getPotentialFits());
    dispatch(getMatchAgeRange(rematch.id));
    if (isCouples) {
      dispatch(matchmakerActions.openCouplesMatchmaker());
    } else {
      dispatch(matchmakerActions.openMatchmaker());
    }
  };

const translateCoi = (apiCoi: APIConflictOfInterest): ConflictOfInterest => {
  if (apiCoi.client !== null) {
    return {
      id: apiCoi.client.id,
      first_name: apiCoi.client.first_name,
      last_name: apiCoi.client.last_name,
      type: "client",
      clinician_ids: [],
    };
  } else if (apiCoi.hq_member !== null) {
    return {
      id: apiCoi.hq_member.id,
      type: "hq_member",
      email: apiCoi.hq_member.email,
      full_name: apiCoi.hq_member.full_name,
    };
  } else {
    return {
      id: apiCoi.clinician_id,
      type: "clinician",
    };
  }
};

export const updateRematchInfo =
  (isRematch: boolean, rematchNote: string | null): AsyncAction =>
  (dispatch, getState) => {
    const action: UpdateRematchInfoAction = {
      isRematch,
      rematchNote,
      excludedClinicianIds: [],
      rematchToClinicianId: null,
      returningToSameClinician: false,
    };
    const state = getState().matchmaker;
    const clinicianState = getState().clinicians;
    if (isRematch && rematchNote) {
      action.returningToSameClinician =
        rematchNote === "Client Re-Engaging in Care (Same Clinician)";
      const originalClinicianId =
        state.initData.rematch?.selected_fit?.clinician.id;
      const originalClinician =
        (originalClinicianId &&
          clinicianState.clinicianMap[originalClinicianId]) ||
        null;

      // If we can't resolve the previous clinician, we should notify the user.
      if (!originalClinicianId || !originalClinician) {
        message.warn({
          content: (
            <div>
              <div>
                <strong>
                  We can't figure out who the originally selected clinician was
                </strong>
              </div>
              <div>
                This is because they've left Two Chairs or this Rematch is being
                made from a Consult or "Match By Email"
              </div>
            </div>
          ),
          icon: <div></div>,
          duration: 15,
        });
      } else if (action.returningToSameClinician) {
        // Notify the user that we're excluding all clinicians except the previously matched clinician
        message.success(
          `Rematching to ${originalClinician.first_name} ${originalClinician.last_name}. Removing other fits.`,
        );
        action.excludedClinicianIds = clinicianState.ids.filter(
          (id) => id !== originalClinicianId,
        );
        action.rematchToClinicianId = originalClinicianId;
      } else {
        // Notify the user that we're excluding the previously matched clinician
        message.success(
          `Removing ${originalClinician.first_name} ${originalClinician.last_name} as a Fit due to the selected rematch reason.`,
        );
        action.excludedClinicianIds = [originalClinicianId];
      }
    }

    dispatch(matchmakerActions.updateRematchInfo(action));
  };

export const openCouplesMatchmaker: AsyncActionCreator =
  (args: { match: BasicMatch | null; rematch: ExtendedMatch | null }) =>
  (dispatch, getState) => {
    const subregionIds = getState().clinics.subregionIds;
    if (!subregionIds.length) return;

    if (args.match) {
      return dispatch(editMatchWithMatchmaker(args.match, true));
    }
    if (args.rematch) {
      return dispatch(rematchWithMatchmaker(args.rematch, true));
    }
  };
