import { forwardRef } from "react";
import { IconProps } from "../icon.interface";

export const PersonIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ children, ...props }, forwardedRef) => {
    const {
      fill = "none",
      stroke = "#171717",
      width = 24,
      height = 25,
      title,
    } = props;

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill={fill}
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        ref={forwardedRef}
        aria-hidden={!title}
      >
        {title ? <title>{title}</title> : null}
        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" stroke={stroke} />
        <circle cx="12" cy="7" r="4" stroke={stroke} />
      </svg>
    );
  },
);
