import { GroupTherapyNotesEventQuery } from "@/graphql/generated";
import { useParams } from "react-router-dom";
import { useGroupTherapyNotesEvent } from "./use-group-therapy-notes-event/use-group-therapy-notes-event.api";

interface UseGroupTherapyNotesEventAttendanceParams<T> {
  select: (data: GroupTherapyNotesEventQuery, timezone: string) => T;
  timezone: string;
}

export const useGroupTherapyNotesEventAttendance = <T extends object>({
  select,
  timezone,
}: UseGroupTherapyNotesEventAttendanceParams<T>) => {
  const { sessionId: eventId } = useParams<{ sessionId: string }>();

  const enabled = !!eventId;

  const { data, isLoading } = useGroupTherapyNotesEvent({
    enabled,
    eventId,
    select: (data) => select(data, timezone),
  });

  return { data, isLoading };
};
