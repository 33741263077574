import moment, { Moment } from "moment";
import React from "react";
import { ClientExtended, CoupleExtended, Time } from "../../api/types";
import { timeIsOnOddWeek } from "../_helpers/datetime";
import { useShallowEqualSelector } from "../_helpers/redux";
import { BlankTimeSlotCell } from "./cells/BlankTimeSlotCell";
import { CellWithEvent, EventCellBaseProps } from "./cells/CellWithEvent";
import {
  ClosedTimeSlotCell,
  ManualReservationTimeSlotCell,
  OpenTimeSlotCell,
} from "./cells/ManualTimeSlotCells";
import {
  DayOfWeek,
  EventCellType,
  EventMap,
  SlotBuckets,
  SlotClosedSlot,
  SlotEvent,
  SlotOpenSlot,
  SlotReservationSlot,
} from "./types";
import { CellContainer } from "./_grid";

export const Cell = (props: {
  day: DayOfWeek;
  hour: Time;
  x: number;
  y: number;
  events: Array<SlotEvent | SlotOpenSlot>;
}) => {
  const { day, hour, events } = props;
  const { clinicianId, eventMap, dateRange, clinicianSchedule } =
    useShallowEqualSelector((state) => ({
      clinicianId: state.slottool.clinicianId,
      eventMap: state.slottool.eventMap,
      dateRange: state.slottool.dateRange,
      clinicianSchedule: state.slottool.clinicianSchedule,
    }));

  const thisDaySchedule = clinicianSchedule[day];
  const isDuringWorkingHours =
    !!thisDaySchedule &&
    thisDaySchedule.hoursIncluded.includes(
      parseInt(moment(hour, "hA").format("H")),
    );

  const numWeeksInRange = Math.abs(
    dateRange.start.diff(dateRange.end, "weeks"),
  );

  // first, bucket events again by client_id. when there are other event types, typeId should be expanded
  const buckets: SlotBuckets = {};

  // 'special slot' cells have their own first class handling, so pull those out here
  const openSlots: SlotOpenSlot[] = events
    .filter((event) => event.type === "open" || event.type === "manual_open")
    .sort((e) => (e.type === "open" ? -1 : 1)) as SlotOpenSlot[]; // ensure manual slots show up preferentially

  const closedSlots: SlotClosedSlot[] = events.filter(
    (event) => event.type === "closed",
  ) as SlotClosedSlot[];

  const reservedSlots: SlotReservationSlot[] = events.filter(
    (event) => event.type === "reservation",
  ) as SlotReservationSlot[];

  let weeklyOpenSlot = openSlots.find((o) => o.recurrence === "weekly");
  const biweeklyOpenSlots = openSlots.filter(
    (o) => o.recurrence === "biweekly",
  );
  let evenWeekOpenSlot: SlotOpenSlot | undefined;
  let oddWeekOpenSlot: SlotOpenSlot | undefined;

  biweeklyOpenSlots.forEach((slot) => {
    if (timeIsOnOddWeek(slot.start_date)) {
      oddWeekOpenSlot = slot;
    } else {
      evenWeekOpenSlot = slot;
    }
  });

  if (
    openSlots.find((e) => e.type === "manual_open") &&
    openSlots.find((e) => e.type === "open") &&
    weeklyOpenSlot?.type === "open"
  ) {
    // if automatically open and manually open slots overlap, hide the automatic slot
    weeklyOpenSlot = openSlots.find((e) => e.type === "manual_open");
  }

  const weeklyClosedSlot = closedSlots.find((o) => o.recurrence === "weekly");
  const biweeklyClosedSlots = closedSlots.filter(
    (o) => o.recurrence === "biweekly",
  );
  let evenWeekClosedSlot: SlotClosedSlot | undefined;
  let oddWeekClosedSlot: SlotClosedSlot | undefined;
  biweeklyClosedSlots.forEach((slot) => {
    if (timeIsOnOddWeek(slot.start_date)) {
      oddWeekClosedSlot = slot;
    } else {
      evenWeekClosedSlot = slot;
    }
  });

  const weeklyReservedSlot = reservedSlots.find(
    (s) => s.recurrence === "weekly",
  );
  const biweeklyReservedSlots = reservedSlots.filter(
    (s) => s.recurrence === "biweekly",
  );
  let evenWeekReservedSlot: SlotReservationSlot | undefined;
  let oddWeekReservedSlot: SlotReservationSlot | undefined;

  biweeklyReservedSlots.forEach((slot) => {
    if (timeIsOnOddWeek(slot.start_date)) {
      oddWeekReservedSlot = slot;
    } else {
      evenWeekReservedSlot = slot;
    }
  });

  events.forEach((event) => {
    const { eventId } = event;
    const rawEvent = eventMap[eventId];
    if (!rawEvent) {
      return;
    }

    const typeId =
      (rawEvent.couple as string) || (rawEvent.client as number) || "gcal";

    if (typeof typeId === undefined || !typeId) {
      return;
    }

    const listOfEvents = buckets[typeId];
    if (listOfEvents) {
      listOfEvents.push(eventId);
    } else {
      buckets[typeId] = [eventId];
    }
  });

  for (const [typeId, events] of Object.entries(buckets)) {
    if (events.length <= 1) {
      delete buckets[typeId];
    }
  }
  if (!isDuringWorkingHours) {
    delete buckets["gcal"];
  }

  const left: EventCellBaseProps[] = [];
  const right: EventCellBaseProps[] = [];
  const both: EventCellBaseProps[] = [];
  Object.entries(buckets).forEach(([typeId, eventIds]) => {
    // logic here: we need to further determine whether these buckets span left, right, or both.
    // buckets that span both will be sorted first
    const events = eventIds.map((id) => eventMap[id]);

    let eventType: EventCellType;
    switch (events[0].procedure) {
      case "consult":
        eventType = "consult";
        break;
      case "therapy":
      case "teletherapy":
        eventType = "therapy";
        break;
      case "couples_therapy":
      case "couples_teletherapy":
        eventType = "couples";
        break;
      default:
        eventType = "block";
    }

    const baseProps: EventCellBaseProps = {
      clinicianId: clinicianId!,
      day,
      hour,
      typeId,
      eventIds,
      numWeeksInRange,
      eventType,
      key: typeId,
    };

    if (events.some((e) => timeIsOnOddWeek(e.start_time))) {
      if (events.some((e) => !timeIsOnOddWeek(e.start_time))) {
        both.push(baseProps);
      } else {
        right.push(baseProps);
      }
    } else {
      left.push(baseProps);
    }
  });

  // order cells by the date so earliest dates show up at the top
  [left, right, both].forEach((list) => {
    if (list.length > 1) {
      list.sort((a, b) =>
        getEarliestDateOfBucket(a.eventIds, eventMap).diff(
          getEarliestDateOfBucket(b.eventIds, eventMap),
        ),
      );
    }
  });

  const numRows = Math.max(
    both.length + left.length,
    both.length + right.length,
  );

  const bothElements = both.map((props) => (
    <CellWithEvent {...props} eventMap={eventMap} displaySide="both" />
  ));
  const leftElements =
    (evenWeekOpenSlot && [
      <OpenTimeSlotCell
        clinicianId={clinicianId!}
        day={day}
        hour={hour as Time}
        slot={evenWeekOpenSlot}
        typeId={evenWeekOpenSlot.type}
        eventIds={[]}
        eventMap={eventMap}
        displaySide="left"
        key={`${evenWeekOpenSlot.type}_even`}
        manual={evenWeekOpenSlot.type === "manual_open"}
      />,
    ]) ||
    (evenWeekReservedSlot !== undefined && [
      <ManualReservationTimeSlotCell
        clinicianId={clinicianId!}
        day={day}
        hour={hour as Time}
        slot={evenWeekReservedSlot}
        slot_type={evenWeekReservedSlot.slot_type}
        typeId={evenWeekReservedSlot.type}
        eventIds={events.map((e) => e.eventId)}
        eventMap={eventMap}
        key={`${evenWeekReservedSlot.type}_even`}
        displaySide="left"
      />,
    ]) ||
    (evenWeekClosedSlot && [
      <ClosedTimeSlotCell
        clinicianId={clinicianId!}
        day={day}
        hour={hour as Time}
        slot={evenWeekClosedSlot}
        typeId={evenWeekClosedSlot.type}
        eventIds={[]}
        eventMap={eventMap}
        displaySide="left"
        key={`${evenWeekClosedSlot.type}_even`}
      />,
    ]) ||
    left.map((props) => (
      <CellWithEvent {...props} eventMap={eventMap} displaySide="left" />
    ));

  // right elements are the bottommost in the dom, so to place them at
  // the top of the 2nd column they need explicit row numbers.
  // they are placed below 'both' elements, plus one because css grid is 1-index
  const rightElements =
    (oddWeekOpenSlot && [
      <OpenTimeSlotCell
        clinicianId={clinicianId!}
        day={day}
        hour={hour as Time}
        slot={oddWeekOpenSlot}
        typeId={oddWeekOpenSlot.type}
        eventIds={[]}
        eventMap={eventMap}
        displaySide="right"
        key={`${oddWeekOpenSlot!.type}_odd`}
        manual={oddWeekOpenSlot.type === "manual_open"}
      />,
    ]) ||
    (oddWeekReservedSlot && [
      <ManualReservationTimeSlotCell
        clinicianId={clinicianId!}
        day={day}
        hour={hour as Time}
        slot={oddWeekReservedSlot}
        slot_type={oddWeekReservedSlot.slot_type}
        typeId={oddWeekReservedSlot.type}
        eventIds={events.map((e) => e.eventId)}
        eventMap={eventMap}
        key={`${oddWeekReservedSlot.type}_odd`}
        displaySide="right"
      />,
    ]) ||
    (oddWeekClosedSlot && [
      <ClosedTimeSlotCell
        clinicianId={clinicianId!}
        day={day}
        hour={hour as Time}
        slot={oddWeekClosedSlot}
        typeId={oddWeekClosedSlot.type}
        eventIds={[]}
        eventMap={eventMap}
        displaySide="right"
        key={`${oddWeekClosedSlot.type}_odd`}
      />,
    ]) ||
    right.map((props, index) => (
      <CellWithEvent
        {...props}
        eventMap={eventMap}
        displaySide="right"
        rowIndex={both.length + index + 1}
      />
    ));

  // fill in blank timeslots
  if (isDuringWorkingHours && bothElements.length === 0) {
    if (leftElements.length === 0 && rightElements.length > 0) {
      leftElements.push(
        <BlankTimeSlotCell
          clinicianId={clinicianId!}
          day={day}
          hour={hour as Time}
          displaySide="left"
          key={`cell_${day}_${hour}`}
        />,
      );
    } else if (rightElements.length === 0 && leftElements.length > 0) {
      rightElements.push(
        <BlankTimeSlotCell
          clinicianId={clinicianId!}
          day={day}
          hour={hour as Time}
          displaySide="right"
          key={`cell_${day}_${hour}`}
        />,
      );
    } else if (rightElements.length === 0 && leftElements.length === 0) {
      bothElements.push(
        <BlankTimeSlotCell
          clinicianId={clinicianId!}
          day={day}
          hour={hour as Time}
          displaySide="both"
          key={`cell_${day}_${hour}`}
        />,
      );
    }
  }

  const content = (weeklyOpenSlot && (
    <OpenTimeSlotCell
      clinicianId={clinicianId!}
      day={day}
      hour={hour as Time}
      slot={weeklyOpenSlot}
      typeId={weeklyOpenSlot.type}
      eventIds={events.map((e) => e.eventId)}
      eventMap={eventMap}
      displaySide="both"
      manual={weeklyOpenSlot!.type === "manual_open"}
    />
  )) ||
    (weeklyReservedSlot && (
      <ManualReservationTimeSlotCell
        clinicianId={clinicianId!}
        day={day}
        hour={hour as Time}
        slot={weeklyReservedSlot}
        slot_type={weeklyReservedSlot.slot_type}
        typeId={weeklyReservedSlot.type}
        eventIds={events.map((e) => e.eventId)}
        eventMap={eventMap}
        displaySide="both"
      />
    )) ||
    (weeklyClosedSlot && (
      <ClosedTimeSlotCell
        clinicianId={clinicianId!}
        day={day}
        hour={hour as Time}
        slot={weeklyClosedSlot}
        typeId={weeklyClosedSlot.type}
        eventIds={events.map((e) => e.eventId)}
        eventMap={eventMap}
        displaySide="both"
      />
    )) || [...bothElements, ...leftElements, ...rightElements];

  return (
    <CellContainer
      x={props.x}
      y={props.y}
      rows={numRows}
      disabled={!isDuringWorkingHours}
    >
      {content}
    </CellContainer>
  );
};

export function useCellResizer(
  ref: React.RefObject<any>,
): [DOMRect | undefined, number] {
  const [windowWidth, setWindowWidth] = React.useState<number>(
    window.innerWidth,
  );
  const [cellPosition, setCellPosition] = React.useState<DOMRect>();
  React.useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    setCellPosition(ref.current?.getBoundingClientRect());
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [ref, windowWidth]);

  return [cellPosition, windowWidth];
}

function getEarliestDateOfBucket(eventIds: string[], eventMap: EventMap) {
  const events = eventIds.map((id) => eventMap[id]);
  let earliestMoment: Moment = moment().add(1, "year");

  events.forEach((e) => {
    if (moment(e.start_time).isBefore(earliestMoment)) {
      earliestMoment = moment(e.start_time);
    }
  });

  return earliestMoment;
}

export function shortenClientName(client: ClientExtended) {
  return `${client.first_name.slice(0, 2)}${client.last_name.slice(0, 2)}`;
}

export function shortenCoupleName(couple: CoupleExtended) {
  if (!couple) return "";
  return `${couple.client_a_first_name.slice(
    0,
    2,
  )}${couple.client_a_last_name.slice(
    0,
    2,
  )} & ${couple.client_b_first_name.slice(
    0,
    2,
  )}${couple.client_b_last_name.slice(0, 2)}`;
}

// used to disambiguate gcal event ids
// function getCommonPartOfGCalUniqueId(gcalId?: string) {
//   if (!gcalId) {
//     return;
//   }
//   return gcalId
//     .split("_")
//     .slice(0, -1)
//     .join("");
// }
