import { Flex } from "@/app/design-system";
import { ParsedClientGroupEvent } from "../_api/use-group-therapy-notes-event/use-group-therapy-notes-event.util";
import { GroupDetailsNotesClientPane } from "./group-details-notes-client-pane";

interface GroupDetailsNotesClientPanesProps {
  groupTherapyNotesEventData: ParsedClientGroupEvent[];
  eventStartTime: string;
}

export const GroupDetailsNotesClientPanes = ({
  groupTherapyNotesEventData,
  eventStartTime,
}: GroupDetailsNotesClientPanesProps) => (
  <Flex flexDirection="column" css={{ pt: 12 }}>
    <Flex as="ul" css={{ flexDirection: "column", paddingLeft: 0 }}>
      {groupTherapyNotesEventData.map((clientGroupEvent) => (
        <GroupDetailsNotesClientPane
          clientId={clientGroupEvent?.clientId}
          attendanceNote={clientGroupEvent?.attendanceNote}
          appointmentStatus={clientGroupEvent?.appointmentStatus ?? undefined}
          cancellationType={clientGroupEvent?.cancellationType}
          clientFirstName={clientGroupEvent?.firstName ?? ""}
          clientGroupEventId={clientGroupEvent.clientGroupEventId}
          clientLastName={clientGroupEvent?.lastName ?? ""}
          eventStartTime={eventStartTime}
          key={clientGroupEvent?.clientGroupEventId}
          healthieId={clientGroupEvent?.healthieId ?? null}
          clientUnenrolledDate={clientGroupEvent?.clientUnenrolledDate ?? null}
        />
      ))}
    </Flex>
  </Flex>
);
