import { EventApiData } from "@/app/api/use-my-clients-overview";
import { CurrentUserContext } from "@/app/app.utils";
import {
  Button,
  Drawer,
  ScrollArea,
  Stack,
  Text,
  RadixTooltip,
} from "@/app/design-system";
import { useNotification } from "@/app/design-system";
import { QuarterCircle, Spinner } from "@/app/design-system";
import { useMyClientsUser } from "@/app/my-clients/routes/my-clients-page/hooks/use-my-clients-user";
import { TIMEZONE, useHasMyClientsPermissions } from "@/app/my-clients/utils";
import Mangle from "@/app/_shared/Mangle";
import React from "react";
import { useWatch } from "react-hook-form";
import { SessionModalDialogAction } from "../../3-session-card/session-modal-dialog-action/session-modal-dialog-action";
import { RecurringSessionsOptions } from "../session-actions.constants";
import {
  selectDataForUpsertSessionForm,
  SelectDataForUpsertSessionFormReturn,
  useFutureSessionConflicts,
} from "./upsert-session-form/upcoming-conflicts/use-future-conflicts.api";
import { UpsertSessionForm } from "./upsert-session-form/upsert-session-form";
import {
  getDefaultFormValues,
  useUpsertSessionForm,
} from "./upsert-session-form/use-upsert-session-form";
import { useUpsertSessionAction } from "./use-upsert-session-action";

export interface UpsertSessionActionProps {
  children: React.ReactNode;
  mode: "edit" | "add";
  event?: EventApiData;
  clientName: string;
  clientId: number | undefined;
}

export const UpsertSessionAction = ({
  children,
  mode,
  event,
  clientName,
  clientId,
}: UpsertSessionActionProps) => {
  const { clinicianId, isSuperUser } = useMyClientsUser();
  const cuser = React.useContext(CurrentUserContext);
  const hasMyClientsPermissions = useHasMyClientsPermissions();
  const { methods, handleSubmit, control, getValues } = useUpsertSessionForm(
    event,
    mode,
    TIMEZONE(cuser),
  );

  const initialEventValues = getDefaultFormValues(mode, event, TIMEZONE(cuser));

  const formValues = getValues();

  /**
   * @hint DEBUGGING TIP
   * an <UpsertSessionAction is rendered for every upcoming session,
   * so run:
   *
   * isDrawerOpen && console.log(<value>) if you only want to see values for the event
   * that's open
   */

  const {
    setIsDrawerOpen,
    isDrawerOpen,
    title,
    submitForm,
    isRecurringEvent,
    isAddSessionLoading,
    isRecurringFormValueSelected,
  } = useUpsertSessionAction({
    formValues,
    mode,
    handleSubmit,
    event,
    hasMyClientsPermissions,
    clientId,
  });

  const { renderNotification } = useNotification();

  const eventClinicianId = event?.clinician?.id;

  const {
    isLoading: isLoadingFutureSessionConflicts,
    data,
    isError,
  } = useFutureSessionConflicts(
    eventClinicianId || clinicianId,
    control,
    isDrawerOpen,
    TIMEZONE(cuser),
    (data) => selectDataForUpsertSessionForm(event, data),
  );

  const {
    recurrence: recurrenceFormFieldValue,
    sessionsToUpdate: sessionsToUpdateFormFieldValue,
  } = useWatch({ control });

  const isUpdatingFutureSessions =
    sessionsToUpdateFormFieldValue ===
    RecurringSessionsOptions.ALL_FUTURE_RECURRING;

  const isAddOrEditSessionisabled = getIsAddOrEditSessionDisabled({
    data,
    recurrenceFormFieldValue,
    hasMyClientsPermissions,
    isLoadingFutureSessionConflicts,
    isUpdatingFutureSessions,
    mode,
  });

  const numberOfRequiredFields = isSuperUser ? 2 : 1;

  const allRequiredFieldsSelected =
    Object.keys(methods.formState.dirtyFields).length >= numberOfRequiredFields;

  const isEditMode = mode === "edit";

  return (
    <Drawer.Root
      onOpenChange={(open) => {
        setIsDrawerOpen(open);

        if (mode === "add") {
          methods.reset();
        } else {
          methods.reset({
            ...initialEventValues,
          });
        }
        // reset the form fields to be the original value.
        // if add session:
        //   set back to empty
        // if edit session:
        //   set back to original event values
      }}
      open={isDrawerOpen}
    >
      {isEditMode ? (
        <RadixTooltip content={"Edit session"}>
          <Drawer.Trigger asChild>{children}</Drawer.Trigger>
        </RadixTooltip>
      ) : (
        <Drawer.Trigger asChild>{children}</Drawer.Trigger>
      )}

      <Drawer.Content>
        <Drawer.Header title={title} />
        <ScrollArea.Root>
          <ScrollArea.Viewport>
            <Stack css={{ px: 20, py: 24, flexGrow: 1 }} gap={24}>
              <Text
                color={"$neutral11"}
                css={{
                  display: "initial",
                  "&:first-letter": { textTransform: "capitalize" },
                }}
              >
                {event?.serviceType || "Individual"} Therapy for{" "}
                <Mangle>{`${clientName}`}</Mangle>
                {isEditMode ? ` with ${event?.clinician?.fullName}` : null}
              </Text>

              <UpsertSessionForm
                mode={mode}
                event={event}
                formMethods={methods}
                isRecurringEvent={isRecurringEvent}
                conflictingEvents={data?.conflictingEvents}
                conflictingRecurrences={data?.conflictingRecurrences}
                isLoading={isLoadingFutureSessionConflicts}
                isError={isError}
              />
            </Stack>
          </ScrollArea.Viewport>
          <ScrollArea.Scrollbar orientation="vertical">
            <ScrollArea.Thumb />
          </ScrollArea.Scrollbar>
        </ScrollArea.Root>

        <Drawer.Footer>
          <Drawer.Close asChild>
            <Button variant={"secondary"} size={"small"}>
              <Text fontWeight={700}>Exit without changes</Text>
            </Button>
          </Drawer.Close>

          {mode === "add" ? (
            <Button
              size={"small"}
              css={{ minWidth: 100 }}
              disabled={isAddOrEditSessionisabled}
              onClick={() => {
                methods.trigger();

                if (allRequiredFieldsSelected) {
                  submitForm();
                } else {
                  renderNotification({
                    message:
                      "Please fill out required fields to add a new session.",
                    notificationType: "warning",
                  });
                }
              }}
            >
              {isAddSessionLoading ? (
                <Spinner>
                  <QuarterCircle />
                </Spinner>
              ) : (
                <Text fontWeight={700} color={"$neutral0"}>
                  Save
                </Text>
              )}
            </Button>
          ) : (
            <SessionModalDialogAction
              setIsDrawerOpen={setIsDrawerOpen}
              mode="edit"
              handleSubmit={handleSubmit}
              eventId={event!.eventId}
              control={control}
              trigger={methods.trigger}
              disabled={isAddOrEditSessionisabled}
              isRecurringEvent={isRecurringEvent}
              isRecurringFormValueSelected={isRecurringFormValueSelected}
              event={event}
              selectedSessions={sessionsToUpdateFormFieldValue}
            />
          )}
        </Drawer.Footer>
      </Drawer.Content>
    </Drawer.Root>
  );
};

interface IsUserAllowedToAddOrEditSession {
  recurrenceFormFieldValue: string | undefined;
  hasMyClientsPermissions: boolean | null;
  isLoadingFutureSessionConflicts: boolean;
  data: SelectDataForUpsertSessionFormReturn | undefined;
  isUpdatingFutureSessions: boolean;
  mode: "edit" | "add";
}

function getIsAddOrEditSessionDisabled({
  recurrenceFormFieldValue,
  hasMyClientsPermissions,
  isLoadingFutureSessionConflicts,
  data,
  isUpdatingFutureSessions,
  mode,
}: IsUserAllowedToAddOrEditSession) {
  const doesNotHaveWritePermission = !hasMyClientsPermissions;

  const isRecurringEvent =
    recurrenceFormFieldValue === "weekly" ||
    recurrenceFormFieldValue === "biweekly";

  const hasConflictingRecurrencesWithSelectedDateTime =
    data?.conflictingRecurrences && data.conflictingRecurrences?.length > 0;

  const isConflictingWithExistingRecurrence =
    hasConflictingRecurrencesWithSelectedDateTime &&
    isRecurringEvent &&
    // in the Edit Session flow:
    // if we select "This session only", isUpdatingFutureSessions will be false,
    // as we want to allow editing a recurring session to be a one off session:
    // <recurring-event>.is_exception = false => <recurring-event>.is_exception = true
    (isUpdatingFutureSessions || mode === "add");

  const isAddOrEditSessionisabled =
    doesNotHaveWritePermission ||
    isLoadingFutureSessionConflicts ||
    isConflictingWithExistingRecurrence;

  return isAddOrEditSessionisabled;
}
