import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/app/design-system/popover/popover.styled";
import { EditOutlined } from "@ant-design/icons";
import { Button as AntdButton, AutoComplete } from "antd";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BasicMatch, BasicUser } from "../../../api/types";
import { matchOperations } from "../../../state/models/matches";
import { useShallowEqualSelector } from "../../_helpers/redux";

import { Button, styledStitches } from "@/app/design-system";
import { SelectValue } from "antd/es/select";
import { alphabetizeBy } from "../../_helpers";

const SearchForAssignmentPopoverContent = styledStitches("div", {
  border: "1px solid $neutral11",
  minWidth: "fitContent",
  padding: "8px",
  marginBottom: "50px",
  width: "200px",
  display: "block",
  position: "relative",
  zIndex: 250,
});

const SearchForAssignmentSubmitButton = styledStitches(Button, {
  margin: "4px",
  width: "100px",
  maxWidth: "80%",
  position: "relative",
});
const MatchAssignment = ({ basicMatch, readOnly }: { basicMatch: BasicMatch, readOnly?: boolean }) => {
  const assignableUsers = useShallowEqualSelector((state) => [
    ...state.matches.assignableUsers,
  ]);

  const [searchTerm, updateSearchTerm] = React.useState<string>("");
  const [newAssignee, updateNewAssignee] = React.useState<number | null>(null);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const dispatch = useDispatch();
  useEffect(() => {
    if (assignableUsers.length < 1) {
      dispatch(matchOperations.getAssignableUsers());
    }
    // eslint-disable-next-line
  }, []);

  const currentAssigneeName = React.useMemo(() => {
    const matchingAssignableUser = assignableUsers.find(
      (assignableUser) =>
        (basicMatch.assignee ?? basicMatch.assignee_id) === assignableUser.id,
    );
    if (matchingAssignableUser !== undefined) {
      return `${matchingAssignableUser.first_name} ${matchingAssignableUser.last_name}`;
    }

    return "Not Assigned";
  }, [assignableUsers, basicMatch]);

  const filteredAssignableUsers = assignableUsers
    .sort((a, b) => alphabetizeBy(a, b, "first_name"))
    .filter((user) =>
      `${user.first_name} ${user.last_name}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase()),
    );
  // create map from assignnable users to their names
  const assignableUserNameMap: Map<string, BasicUser> = new Map<
    string,
    BasicUser
  >();
  assignableUsers.forEach((user) => {
    assignableUserNameMap.set(`${user.first_name} ${user.last_name}`, user);
  });
  return (
    <div>
      {currentAssigneeName}{" "}
      <Popover open={isOpen} onOpenChange={setIsOpen}>
        <PopoverTrigger asChild>
          <AntdButton type="link" disabled={!!readOnly}>
            <EditOutlined />
          </AntdButton>
        </PopoverTrigger>
        <PopoverContent sideOffset={5} align="start" alignOffset={16}>
          <SearchForAssignmentPopoverContent>
            <div>
              <AutoComplete
                style={{
                  width: "100%",
                  minWidth: "160px",
                  marginBottom: "5px",
                  zIndex: 500,
                  height: "fitContent",
                }}
                tabIndex={0}
                dropdownStyle={{
                  zIndex: 500,
                  pointerEvents: "auto",
                }}
                onSelect={(value: SelectValue) => {
                  const clinician = assignableUserNameMap.get(
                    value?.toString() ?? "",
                  );
                  updateNewAssignee(clinician ? clinician.id : null);
                }}
                onSearch={updateSearchTerm}
                placeholder="Start typing..."
              >
                {filteredAssignableUsers.map((user) => (
                  <AutoComplete.Option
                    key={user.id.toString()}
                    title={`${user.first_name} ${user.last_name}`}
                    value={`${user.first_name} ${user.last_name}`}
                    style={{ zIndex: 9999 }}
                  >
                    {`${user.first_name} ${user.last_name}`}
                  </AutoComplete.Option>
                ))}
              </AutoComplete>
            </div>

            <SearchForAssignmentSubmitButton
              variant="primary"
              size="small"
              style={{ width: "100%", zIndex: 300 }}
              disabled={newAssignee === null}
              onClick={() =>
                dispatch(
                  matchOperations.updateAssignee([basicMatch.id], newAssignee),
                )
              }
            >
              Submit
            </SearchForAssignmentSubmitButton>
          </SearchForAssignmentPopoverContent>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default MatchAssignment;
