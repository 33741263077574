import React from "react";
import {
  initialTotalSessionsFilterState,
  selectTotalSessionsFiltersSlice,
  TotalSessionsFilterState,
  useMyClientsFilterStore,
} from "../../../../../../my-clients-model";

export const useTotalSessionFilterDropdown = () => {
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

  const totalSessionsFilterState = useMyClientsFilterStore(
    selectTotalSessionsFiltersSlice,
  );

  const { setMyClientsFilter } = useMyClientsFilterStore((state) => ({
    setMyClientsFilter: state.setMyClientsFilter,
  }));

  const clearTotalSessionsFilters = () => {
    for (const key in initialTotalSessionsFilterState) {
      setMyClientsFilter(key as keyof TotalSessionsFilterState, false);
    }

    setIsDropdownOpen(false);
  };

  const numberOfSelectedFilters = [
    ...Object.values(totalSessionsFilterState),
  ].filter((filter) => filter).length;

  return {
    isDropdownOpen,
    setIsDropdownOpen,
    clearTotalSessionsFilters,
    setMyClientsFilter,
    numberOfSelectedFilters,
    ...totalSessionsFilterState,
  };
};
