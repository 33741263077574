import { graphqlClient } from "@/api/graphql";
import { useMyPastConsultsSearchResultsQuery } from "@/graphql/generated";
import {
  checkForAndAppendMedicareAndMedical,
  customSort,
  getRelevantConsultClinicianMatchEvents,
} from "./_utils";

export const MyPastConsultsSearchResultsQueryKey =
  "myConsultsV2PastTabSearchResults";

export const useGetMyPastConsultsSearchResults = (
  clientId?: number,
  clinicianId?: number,
) => {
  const { data, isLoading, refetch, isError } =
    useMyPastConsultsSearchResultsQuery(
      graphqlClient,
      {
        client_id: clientId ?? 0,
      },
      {
        queryKey: [MyPastConsultsSearchResultsQueryKey],
        enabled: !!clientId,
        select: (data) => {
          const result = clinicianId
            ? getRelevantConsultClinicianMatchEvents(
                data.my_matches,
                clinicianId,
              )
            : [];

          data.my_matches = result;
          data.my_matches.sort(customSort);
          data.my_matches = checkForAndAppendMedicareAndMedical(
            data.my_matches,
          );

          return data;
        },
      },
    );

  return { data, isLoading, refetch, isError };
};
