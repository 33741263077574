import api from "@/api";
import { ONE_HOUR } from "@/app/_helpers/constants";
import { useQuery } from "react-query";
import { DxCode } from "../../../client/types";

const fetchDxCodes = async (): Promise<DxCode[]> => {
  try {
    const { data } = await api.get(`/api/dxcodes/v1/`);
    return data;
  } catch (e) {
    throw new Error(`[fetchDxCodes] Unable to fetch all DxCodes: ${e}`);
  }
};

export const useFetchDxCodes = () => {
  return useQuery<DxCode[], Error>(["DxCodes"], fetchDxCodes, {
    staleTime: ONE_HOUR,
  });
};
