import { useQuery } from "react-query";
import api from "@/api";
import { FIVE_MINUTES } from "@/app/_helpers/constants";

export type ClinicianSchedulePreference = {
  clinician_id: number;
  target_scheduled_sessions: number;
  default_target: number;
  change_reason: string;
  change_reason_other: string;
  change_source: string;
  modified_at: string;
};

const getClinicianSchedulePreferences = async (): Promise<ClinicianSchedulePreference[]> => {
  try {
    const { data } = await api.get(`/api/clinician_schedule_preferences/`,);
    return data;
  } catch (e) {
    throw new Error(
      `[getClinicianSchedulePreferences] Unable to fetch clinician schedule preference.`,
      { cause: e as Error },
    );
  }
};

export const useGetClinicianSchedulePreferences = () => {
  return useQuery(
    ["ClinicianSchedulePreferences"],
    () => getClinicianSchedulePreferences(),
    {
      staleTime: FIVE_MINUTES,
    },
  );
};
