import teamOperations from "./operations";
import {
  payloadAction,
  actionFactory,
  ActionUnion,
  simpleAction,
} from "reductser";
import {
  ClinicianWithConfiguration,
} from "../../../api/types";
import produce from "immer";
import { assertUnreachable } from "../types";

const actionMap = {
  fetchTeam: simpleAction(),
  saveTeam: payloadAction<ClinicianWithConfiguration[]>(),
};

export const teamActions = actionFactory(actionMap, "TEAM");

export type TeamActions = ActionUnion<typeof teamActions>;

export interface TeamState {
  clinicians: ClinicianWithConfiguration[];
  loading: boolean;
}

function getInitialState(): TeamState {
  return {
    clinicians: [],
    loading: true,
  };
}

const reducer = (state = getInitialState(), action: TeamActions) =>
  produce(state, draft => {
    if (action.reducer === "TEAM") {
      switch (action.type) {
        case "fetchTeam":
          draft.loading = true;
          return;
        case "saveTeam":
          draft.clinicians = action.payload;
          draft.loading = false;
          return;
        default:
          assertUnreachable(action);
      }
    }
  });

export default reducer;

export { teamOperations };
