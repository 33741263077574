import {
  GroupDataFragment,
  EventDataFragment,
  GroupTherapyOverviewQuery,
  ClientGroupDataFragment,
} from "@/graphql/generated";
import _ from "lodash";
import { DateTime } from "luxon";
import {
  EndorsedSIApiData,
  GroupEventApiData,
  GroupOverviewApiData,
  GroupsSIEnabled,
  GroupsSIEnabledByGroupData,
  SessionSelectorDropdownData,
} from "../group-therapy.interface";

const SESSIONS_TO_AVERAGE_MBC = [1, 6, 12];

const getMatchingGroup = (
  data: GroupTherapyOverviewQuery,
  groupId?: string,
): GroupDataFragment | undefined => {
  const matchingGroup = data.groupsByClinician.find(
    (group) => group.groupId === groupId,
  );
  return matchingGroup;
};

export const selectGroupMBCAverage = (
  data: GroupTherapyOverviewQuery,
  groupId?: string,
): number => {
  const groupByClinician = getMatchingGroup(data, groupId);

  if (!groupByClinician) {
    return 0;
  }

  const unenrolledClientGroups = groupByClinician.clientGroups.filter(
    ({ clientUnenrolledDate }) => clientUnenrolledDate !== null,
  );

  return getMBCCompletedPercentage(
    groupByClinician?.events,
    unenrolledClientGroups,
  );
};

export const selectSessionNumberOfTotal = (
  data: GroupTherapyOverviewQuery,
  groupId?: string,
  eventId?: string,
): number => {
  const matchingGroup = getMatchingGroup(data, groupId);

  if (!matchingGroup) {
    return 0;
  }

  return (
    matchingGroup.events.findIndex((event) => event.eventId === eventId) + 1
  );
};

export const selectSessionSelectorDropdownData = (
  data: GroupTherapyOverviewQuery,
  timezone: string,
  groupId?: string,
): SessionSelectorDropdownData => {
  const matchingGroup = getMatchingGroup(data, groupId);

  if (!matchingGroup) {
    return {
      groupId,
      idOfFirstEventInGroup: undefined,
      idOfLastEventInGroup: undefined,
      eventsInGroup: [],
    };
  }

  const { events } = matchingGroup;

  const idOfFirstEventInGroup = events[0].eventId;
  const idOfLastEventInGroup = events[events.length - 1].eventId;

  const eventsInGroup = events.map((event, index) => ({
    eventId: event.eventId,
    eventNumberAndDate: getEventNumberAndDate(event, index, timezone),
  }));

  return {
    groupId,
    idOfFirstEventInGroup,
    idOfLastEventInGroup,
    eventsInGroup,
  };
};

const getEventNumberAndDate = (
  event: EventDataFragment,
  index: number,
  timezone: string,
) => {
  const formattedDate = DateTime.fromISO(event.eventStartTime)
    .setZone(timezone)
    .toLocaleString({
      month: "short",
      day: "numeric",
    });
  return `Session ${index + 1}, ${formattedDate}`;
};

/**
 * @function selectGroupTherapyOverviewData
 * @description takes in data of GroupDataFragment, GroupsRateData,
 * and GroupsSIEnabledByGroupData types, parses them and
 * returns a Promise<GroupOverviewApiData[]>
 * @param { GroupDataFragment[] GroupsRateData GroupsSIEnabledByGroupData }
 * @returns { GroupOverviewApiData[]}
 */
export const selectGroupTherapyOverviewData = (
  groupsByClinician: GroupDataFragment[],
  endorsedMap: GroupsSIEnabledByGroupData,
  timezone: string,
): GroupOverviewApiData[] => {
  const parsedGroupOverviews = groupsByClinician.map((groupByClinician) => {
    const endorsed =
      endorsedMap && endorsedMap[groupByClinician.groupId]
        ? parseEndorsedData(endorsedMap[groupByClinician.groupId])
        : null;

    return parseGroup(
      groupByClinician as GroupDataFragment,
      endorsed as EndorsedSIApiData | null,
      timezone,
    );
  });

  return parsedGroupOverviews;
};

const parseGroup = (
  groupByClinician: GroupDataFragment,
  endorsed: EndorsedSIApiData | null,
  timezone: string,
): GroupOverviewApiData => {
  const nonCanceledEvents = groupByClinician.events.filter(
    ({ appointmentStatus }) => appointmentStatus !== "canceled",
  );
  const nextSession = getNextEvent(nonCanceledEvents);
  const prevSession = getPreviousEvent(nonCanceledEvents);
  const [hasCompletedSessions, lastSession] =
    getHasCompletedSessionsAndLastSession(
      nonCanceledEvents,
      groupByClinician.groupEndTime,
      timezone,
    );

  const activeClientGroups = groupByClinician.clientGroups.filter(
    ({ client, clientUnenrolledDate }) =>
      client?.is_test_client === false && clientUnenrolledDate === null,
  );

  const unenrolledClientGroups = groupByClinician.clientGroups.filter(
    ({ clientUnenrolledDate }) => clientUnenrolledDate !== null,
  );

  const canceled = groupByClinician.canceledAt ? true : false;

  const mbcCompletedPercentage = getMBCCompletedPercentage(
    groupByClinician?.events,
    unenrolledClientGroups,
  );

  return {
    groupId: groupByClinician.groupId,
    clinicianId: groupByClinician.clinicianId,
    groupTitle: groupByClinician.groupType.groupName,
    totalSessions: groupByClinician.groupType.groupSessions,
    mbcCompleted: mbcCompletedPercentage,
    endorsedSI: endorsed,
    nextSession,
    prevSession,
    numClients: activeClientGroups.length,
    groupStartTime: groupByClinician.groupStartTime,
    dayOfWeek: parseDayOfWeek(groupByClinician.groupStartTime, timezone),
    hasCompletedSessions: hasCompletedSessions,
    lastSession: lastSession,
    isCanceled: canceled,
  };
};

const getMBCCompletedPercentage = (
  events: EventDataFragment[],
  unenrolledClients: ClientGroupDataFragment[],
): number => {
  let sessionNumber = 0;
  let totalAnswered = 0;
  let totalSent = 0;

  const now = DateTime.now();
  const eventsAnswered = events.filter(
    (event) =>
      event.appointmentStatus === "attended" ||
      (event.appointmentStatus === null &&
        DateTime.fromISO(event.eventStartTime).plus({ days: 1 }) <= now),
  );
  for (const event of eventsAnswered) {
    sessionNumber++;

    const eventStartTime = DateTime.fromISO(event.eventStartTime).startOf(
      "day",
    );
    if (
      SESSIONS_TO_AVERAGE_MBC.includes(sessionNumber) &&
      eventStartTime <= now
    ) {
      const clientGroupEvents = event.clientGroupEvents.filter(
        (cge) => cge.client && !cge?.client?.isTestClient,
      );
      for (const cge of clientGroupEvents) {
        if (cge.client && !cge.client.isTestClient && cge.ems) {
          // check if client unenrolled same day we sent out email
          const unenrolledClientGroup = unenrolledClients.find(
            (client) => client?.clientId === cge?.clientId,
          );

          if (!unenrolledClientGroup) {
            totalSent++;
            if (cge.ems?.length > 0 && cge.ems[0].respondedAt) {
              totalAnswered++;
            }
          }
          // if client unenrolled, count only if before unenrolled
          else {
            const clientUnenrolledDate = DateTime.fromISO(
              unenrolledClientGroup?.clientUnenrolledDate,
            ).startOf("day");
            if (eventStartTime < clientUnenrolledDate) {
              totalSent++;
              if (cge.ems?.length > 0 && cge.ems[0].respondedAt) {
                totalAnswered++;
              }
            }
          }
        }
      }
    }
  }
  return totalSent ? totalAnswered / totalSent : 0;
};

const parseDayOfWeek = (startTime: string, timezone: string): string => {
  return DateTime.fromISO(startTime ?? "", {
    zone: timezone,
  }).toFormat("EEEE");
};

const parseEndorsedData = (endorsed: GroupsSIEnabled): EndorsedSIApiData => {
  return {
    endorsed: endorsed[0],
    clientsEndorsedSI: endorsed[1],
  };
};

const getNextEvent = (
  events: EventDataFragment[],
): GroupEventApiData | null => {
  const now = DateTime.now();
  let sessionNum = 0;

  for (const event of events) {
    sessionNum++;
    let eventStartTime = DateTime.fromISO(event.eventStartTime);

    if (eventStartTime > now) {
      sessionNum = sessionNum;
      return {
        eventId: event.eventId,
        eventStartTime: event.eventStartTime,
        eventEndTime: event.eventEndTime,
        sessionNumber: sessionNum,
        appointmentStatus: event.appointmentStatus,
      };
    }
  }

  // make nextEvent the last event if all events have passed
  if (events.length) {
    return {
      eventId: events[events.length - 1].eventId,
      eventStartTime: events[events.length - 1].eventStartTime,
      eventEndTime: events[events.length - 1].eventEndTime,
      sessionNumber: sessionNum,
      appointmentStatus: events[events.length - 1].appointmentStatus,
    };
  } else {
    return null;
  }
};

const getPreviousEvent = (
  events: EventDataFragment[],
): GroupEventApiData | null => {
  const now = DateTime.now();

  if (!events.length || now < DateTime.fromISO(events[0].eventStartTime)) {
    return null;
  }

  const nextEvent = events.find(
    (event) => DateTime.fromISO(event.eventEndTime) > now,
  );

  const previousEventIdx = nextEvent
    ? events.map((event) => event.eventId).indexOf(nextEvent.eventId) - 1
    : events.length - 1;

  return {
    eventId: events[previousEventIdx].eventId,
    eventStartTime: events[previousEventIdx].eventStartTime,
    eventEndTime: events[previousEventIdx].eventEndTime,
    sessionNumber: previousEventIdx + 1,
    appointmentStatus: events[previousEventIdx].appointmentStatus,
  };
};

const getHasCompletedSessionsAndLastSession = (
  events: EventDataFragment[],
  groupEndTime: string,
  timezone: string,
) => {
  const now = DateTime.now().setZone(timezone);

  if (events.length === 0) {
    if (now > DateTime.fromISO(groupEndTime)) {
      return [true, groupEndTime] as const;
    } else {
      return [false, groupEndTime] as const;
    }
  }

  const sortedEvents = events.sort((a, b) => {
    if (
      DateTime.fromISO(a.eventStartTime) < DateTime.fromISO(b.eventStartTime)
    ) {
      return -1;
    } else if (
      DateTime.fromISO(a.eventStartTime) > DateTime.fromISO(b.eventStartTime)
    ) {
      return 1;
    } else {
      return 0;
    }
  });

  const lastSession = sortedEvents[sortedEvents.length - 1];
  const lastSessionEndTime = DateTime.fromISO(lastSession.eventEndTime);

  const hasCompletedSessions = lastSessionEndTime < now;
  return [hasCompletedSessions, lastSession.eventEndTime] as const;
};

export const getSortedSessions = (groups: GroupOverviewApiData[]) => {
  return groups.sort((a, b) => {
    if (
      DateTime.fromISO(a.groupStartTime) < DateTime.fromISO(b.groupStartTime)
    ) {
      return -1;
    } else if (
      DateTime.fromISO(a.groupStartTime) > DateTime.fromISO(b.groupStartTime)
    ) {
      return 1;
    } else {
      return 0;
    }
  });
};
