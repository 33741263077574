import React from "react";
import { Alert } from "antd";
import Mangle from "../../_shared/Mangle";

export default ({ queueNote }: { queueNote: string }) =>
  queueNote ? (
    <Alert
      banner={true}
      type="warning"
      message={<Mangle>{queueNote}</Mangle>}
      showIcon={false}
    />
  ) : null;
