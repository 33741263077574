import { CurrentUserContext } from "@/app/app.utils";
import { Flex, Text } from "@/app/design-system";
import { Tag } from "@/app/design-system";
import { TIMEZONE } from "@/app/my-clients/utils";
import { DateTime } from "luxon";
import React from "react";
import { GroupEventApiData } from "../../_api/group-therapy.interface";

interface NextSessionFormatedProps {
  nextSession: GroupEventApiData;
  isStarting: boolean;
  hasCompletedSessions: boolean;
  lastSession: string | null;
}

export const NextSessionFormatted = ({
  nextSession,
  hasCompletedSessions,
  lastSession,
}: NextSessionFormatedProps) => {
  const preface = hasCompletedSessions ? "last session: " : "next: ";
  const cuser = React.useContext(CurrentUserContext);

  const nextSessionText = hasCompletedSessions
    ? DateTime.fromISO(lastSession ?? "", {
        zone: TIMEZONE(cuser),
      })
        // last session is the *end time* of the last session, so we move the displayed time up by 1 hour
        .minus({ hours: 1 })
        .toFormat("MMM d' at ' ha")
    : DateTime.fromISO(nextSession.eventStartTime ?? "", {
        zone: TIMEZONE(cuser),
      }).toFormat("MMM d' at ' ha");

  return (
    <Flex justifyContent={"flex-end"} gap={4} css={{ flexGrow: 20, pl: 20 }}>
      <Text>{preface}</Text>
      <Text css={{ fontWeight: 600 }}>{nextSessionText}</Text>
    </Flex>
  );
};

export const StartingSession = ({
  nextSession,
  groupStartTime,
}: {
  nextSession: GroupEventApiData | null | undefined;
  groupStartTime: string;
}) => {
  const cuser = React.useContext(CurrentUserContext);
  const nextSessionDate = nextSession
    ? nextSession.eventStartTime
    : groupStartTime;
  const nextSessionDateFormatted = DateTime.fromISO(nextSessionDate, {
    zone: TIMEZONE(cuser),
  }).toFormat("MMM d");

  return (
    <Flex justifyContent={"flex-end"} gap={12} css={{ flexGrow: 20, pl: 20 }}>
      <Tag color={"green"} css={{ fontWeight: 600, fontSize: 16 }}>
        Starting {nextSessionDateFormatted}
      </Tag>
    </Flex>
  );
};
