import React, { useContext } from "react";
import {
  Button,
  CSS_COLORS,
  Flex,
  Stack,
  Text,
} from "../../../../../../../../design-system";
import {
  ComposedDropdownMenuCheckboxItem,
  DropdownButton,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuPrimitiveItem,
  DropdownMenuTrigger,
} from "../../../../../../../../design-system/dropdown/dropdown.styled";
import {
  DownChevronIcon,
  MBCTrackStatusIcon,
} from "../../../../../../../../design-system/icons";
import { useMBCStatusFiltersDropdown } from "./use-mbc-status-filter-dropdown";
import { CurrentUserContext } from "@/app/app.utils";

import { Permissions } from "@/app/_helpers/permissions";

export const MBCTrendFilterDropdown = () => {
  const currentUser = useContext(CurrentUserContext);

  const {
    isDropdownOpen,
    setIsDropdownOpen,
    clearMBCStatusFilters,
    setMyClientsFilter,
    isFilteringOnMBCGraduationClients,
    isFilteringOnMBCStagnationClients,
    isFilteringOnMBCEscalateClients,
    isFilteringOnMBCImprovementClients,
  } = useMBCStatusFiltersDropdown();

  return (
    <DropdownMenu open={isDropdownOpen}>
      <DropdownMenuTrigger asChild onClick={() => setIsDropdownOpen(true)}>
        <DropdownButton aria-label="client status filter options">
          <Text>Trends</Text>
          <DownChevronIcon className="caret-icon" />
        </DropdownButton>
      </DropdownMenuTrigger>

      <DropdownMenuContent
        sideOffset={5}
        loop
        align={"start"}
        onInteractOutside={() => setIsDropdownOpen(false)}
      >
        <Stack css={{ pb: 8 }}>
          <ComposedDropdownMenuCheckboxItem
            checked={isFilteringOnMBCGraduationClients}
            onCheckedChange={() =>
              setMyClientsFilter(
                "isFilteringOnMBCGraduationClients",
                !isFilteringOnMBCGraduationClients,
              )
            }
          >
            <Flex gap={8} alignItems={"center"}>
              <MBCTrackStatusIcon fill={CSS_COLORS.blue9} />
              <Text fontSize={14}>
                "Remission"
              </Text>
            </Flex>
          </ComposedDropdownMenuCheckboxItem>

          <ComposedDropdownMenuCheckboxItem
            checked={isFilteringOnMBCStagnationClients}
            onCheckedChange={() =>
              setMyClientsFilter(
                "isFilteringOnMBCStagnationClients",
                !isFilteringOnMBCStagnationClients,
              )
            }
          >
            <Flex gap={8} alignItems={"center"}>
              <MBCTrackStatusIcon fill={CSS_COLORS.yellow10} />
              <Text fontSize={14}>Stagnation</Text>
            </Flex>
          </ComposedDropdownMenuCheckboxItem>

          <ComposedDropdownMenuCheckboxItem
            checked={isFilteringOnMBCEscalateClients}
            onCheckedChange={() =>
              setMyClientsFilter(
                "isFilteringOnMBCEscalateClients",
                !isFilteringOnMBCEscalateClients,
              )
            }
          >
            <Flex gap={8} alignItems={"center"}>
              <MBCTrackStatusIcon fill={CSS_COLORS.red9} />
              <Text fontSize={14}>Escalate</Text>
            </Flex>
          </ComposedDropdownMenuCheckboxItem>

          <ComposedDropdownMenuCheckboxItem
            checked={isFilteringOnMBCImprovementClients}
            onCheckedChange={() =>
              setMyClientsFilter(
                "isFilteringOnMBCImprovementClients",
                !isFilteringOnMBCImprovementClients,
              )
            }
          >
            <Flex gap={8} alignItems={"center"}>
              <MBCTrackStatusIcon fill={CSS_COLORS.green9} />
              <Text fontSize={14}>Improvement</Text>
            </Flex>
          </ComposedDropdownMenuCheckboxItem>
        </Stack>

        <Flex
          justifyContent={"space-between"}
          css={{ borderTop: "1px solid $neutral3", pt: 10, pl: 0 }}
        >
          <DropdownMenuPrimitiveItem css={{ fontSize: 14 }} asChild>
            <Button
              variant={"tertiary"}
              size={"medium"}
              onClick={clearMBCStatusFilters}
            >
              Clear all
            </Button>
          </DropdownMenuPrimitiveItem>
        </Flex>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
