import styled from "styled-components";

import {
  $antdBorder,
  $green,
  $greyBorder,
  $greyText,
  $red,
} from "../../assets/colors";
import { styledStitches } from "../design-system";

export const rowHeight = "80px";
export const headerRowHeight = "120px";

export interface CellProps {
  active?: boolean;
  hasContent?: boolean;
}

export const HintTextDiv = styledStitches("div", {
  width: "100%",
  height: "64px",
  display: "flex",
  textAlign: "center",
  alignItems: "center",
  justifyContent: "center",
  color: "$neutral11",
  variants: {
    card: {
      white: {
        backgroundColor: "$neutral0",
        borderRadius: 8,
      },
    },
  },
});

export const Triangle = `
  &::after {
    border-left: 12px solid transparent;
    border-right: 12px solid black;
    border-bottom: 12px solid transparent;
    height: 0;
    width: 0;
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    z-index: 3;
  }
`;

export const CellContainer = styled.div<CellProps>`
  position: relative;
  user-select: none;
  border-bottom: 1px solid ${$antdBorder};

  height: 80px;
  padding: 8px;
  background-color: #fff;

  transition: all 200ms ease;
  ${(p) =>
    p.active &&
    `

    border: 1px solid ${$antdBorder};
    z-index: 20 !important;
    box-shadow: 4px 4px 16px 2px rgba(0, 0, 0, 0.35);
    `}
`;

export const sessionBgColor = (state: string | null, size?: number) => {
  switch (state) {
    case "attended":
      return $green;
    case "canceled":
      return $greyBorder;
    case "noshow":
      return $red;
    case null:
      return scheduledBg(size);
    default:
      return "orange";
  }
};

const scheduledBg = (size?: number) => `repeating-linear-gradient(
  45deg,
  transparent,
  transparent ${(size && size) || 10}px,
  rgba(255, 255, 255, 0.35) ${(size && size) || 10}px,
  rgba(255, 255, 255, 0.35) ${(size && size * 2) || 20}px
), ${$green};
`;
