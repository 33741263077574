export const cancellationReasonToBackendValueMap = {
  Illness: "illness",
  "Vacation or holiday": "vacation_or_holiday",
  "Scheduling conflict": "scheduling_conflict",
  "Pausing care": "pausing_care",
  "Technical issues": "technical_issues",
  "Scheduled in error": "scheduled_in_error",
  "Termination of care": "termination_of_care",
  Unknown: "unknown",
};

export const cancellationReasonToFrontendValueMap = Object.fromEntries(
  Object.entries({
    ...cancellationReasonToBackendValueMap,
    // tacking on auto schedule here since the original object is for
    // the dropdown menu to select cancellation reasons
    // (where autoschedule is not one a clinician should be able to select)
    // we can filter at a higher level in the future and remove this completely
    autoschedule: "autoschedule",
    "conflict of interest": "conflict_of_interest",
    "offboarded clinician": "offboarded_clinician",
    "double booking": "double_booking",
    "out of state": "out_of_state",
    "incorrect service": "incorrect_service",
    "returning client": "returning_client",
    "incomplete client profile": "incomplete_client_profile",
    // supporting pseudo-legacy conflict cancel reasons
    // in case they show up for some reason
    conflict: "conflict",
  }).map((a) => a.reverse()),
);
