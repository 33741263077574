import { ScreenReaderOnlyText } from "@/app/_shared/ScreenReaderOnlyText";
import {
  LoadingPlaceholder,
  PlaceholderText,
} from "@/app/_shared/loading-placeholder";
import { Tag } from "@/app/design-system";
import { ManualSlotReservationApiData } from "../../../../../../api/use-my-clients-overview/my-clients-data.interface";
import {
  Flex,
  Separator,
  Spacer,
  Stack,
  Text,
} from "../../../../../../design-system";
import { ClientCardProps } from "../client-pane";

type PickedSessionTextProps = Pick<
  ClientCardProps,
  | "nextSession"
  | "totalSessionsAttendedWithCurrentClinician"
  | "suggestedRange"
  | "clientPayerServiceRecord"
>;

interface SessionTextProps extends PickedSessionTextProps {
  formattedStartTimeShort: string;
  manualSlotReservation: ManualSlotReservationApiData[] | null;
  isEocDataAvailable: boolean;
}

export const SessionText = ({
  nextSession,
  totalSessionsAttendedWithCurrentClinician,
  formattedStartTimeShort,
  suggestedRange,
  manualSlotReservation,
  clientPayerServiceRecord,
  isEocDataAvailable,
}: SessionTextProps) => {
  const recurrenceTextKey = {
    weekly: "Every week",
    biweekly: "Every 2 weeks",
  };

  const isPayerClient = clientPayerServiceRecord !== null;

  return (
    <Flex
      justifyContent={"flex-end"}
      gap={12}
      css={{
        minWidth: 310,
        "@breakpoint1200": {
          justifyContent: "space-between",
          minWidth: 200,
        },
      }}
    >
      <Stack
        gap={12}
        alignItems={"flex-end"}
        css={{
          minWidth: 180,
          "@breakpoint1200": {
            justifyContent: "center",
            flexGrow: 1,
          },
        }}
      >
        <Flex
          alignItems={"center"}
          css={{
            "@breakpoint1400": {
              flexDirection: "column",
              alignItems: "flex-end",
            },
          }}
        >
          {manualSlotReservation &&
          manualSlotReservation.length > 0 &&
          manualSlotReservation[0].recurrence ? (
            <>
              <Text
                css={{
                  marginRight: 6,
                  "@breakpoint1400": {
                    marginRight: 0,
                  },
                }}
                fontWeight={600}
              >
                {recurrenceTextKey[manualSlotReservation[0].recurrence]}
              </Text>
              <Separator
                orientation="vertical"
                backgroundColor={"$neutral12"}
                height={"70%"}
                css={{
                  marginRight: 6,
                  "@breakpoint1200": {
                    display: "none",
                  },
                }}
              />
            </>
          ) : null}
          <Text>
            {nextSession ? `Next: ${formattedStartTimeShort}` : `Next: none`}
          </Text>
        </Flex>
          <Flex
            gap={8}
            alignItems={"center"}
            css={{
              "@breakpoint1400": {
                flexDirection: "column",
                alignItems: "flex-end",
              },
            }}
          >
              <>
                <Text>
                  {totalSessionsAttendedWithCurrentClinician}{" "}
                  {totalSessionsAttendedWithCurrentClinician === 1
                    ? "session"
                    : "sessions"}
                </Text>
                {suggestedRange && isPayerClient && (
                  <Tag>Goal: {suggestedRange}</Tag>
                )}
              </>
          
          </Flex>
      </Stack>
    </Flex>
  );
};
