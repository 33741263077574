import api from "@/api";
import { cancellationReasonToBackendValueMap } from "./use-fetch-cancellation-types-and-reasons/event-cancellation-types.constants";
import { cancellationTypeMap } from "@/app/my-clients/api/use-upsert-session/use-cancel-session";
import { useMutation } from "react-query";

interface PatchEventArgs {
  clientGroupEventId: string;
  appointmentStatus: string | null;
  cancellationReason?: string | null;
  cancellationType?: string | null;
  attendanceNote?: string | null;
}

function editClientGroupEvent({
  clientGroupEventId,
  appointmentStatus,
  cancellationReason,
  cancellationType,
  attendanceNote,
}: PatchEventArgs) {
  try {
    const editEventPatchBody = {
      cge_id: clientGroupEventId,
      appointment_status: appointmentStatus,
      ...(cancellationReason &&
        cancellationReasonToBackendValueMap[cancellationReason] && {
          cancellation_reason:
            cancellationReasonToBackendValueMap[cancellationReason],
        }),
      ...(cancellationType &&
        cancellationTypeMap[cancellationType] && {
          cancellation_type: cancellationTypeMap[cancellationType],
        }),

      ...(attendanceNote && {
        attendance_note: attendanceNote,
      }),
    };

    console.log(
      `[editEvent] PATCH eventId: ${clientGroupEventId}\n[editEvent] Request body:`,
      editEventPatchBody,
    );

    return api.patch(
      `/api/groups/v1/group_client_attendance/`,
      editEventPatchBody,
    );
  } catch (e) {
    console.error(`[editEvent] Unable to edit event: ${e}`);
    throw new Error(`[editEvent] Unable to edit event: ${e}`);
  }
}

export function useUpdateClientGroupEvent() {
  return useMutation(editClientGroupEvent);
}
