import { useTimezoneProvider } from "@/app/_shared/TimezoneContext";
import { Button, Tag, Typography } from "antd";
import Text from "antd/es/typography/Text";
import { AxiosResponse } from "axios";
import { DateTime } from "luxon";
import * as React from "react";
import * as ReactRedux from "react-redux";
import styled from "styled-components";

import api from "../../../api";
import { EventMbcStatus, PanelAppointment } from "../../../api/types";
import Mangle from "../../../app/_shared/Mangle";
import { AppState, Dispatcher } from "../../../state/models";
import * as panelOperations from "../../../state/models/panel-management/operations";
import { openNotification } from "../../slot-tool/EditTimeslot";
import { copyToClipboard } from "../../_helpers";
import { useShallowEqualSelector } from "../../_helpers/redux";
import { Row } from "../../_layout/Flex";
import { PanelSessionsMap } from "../types";
import { CurrentUserContext, CurrentConfigContext } from "@/app/app.utils";
import { userCanAccessHealthie } from "@/app/_helpers/permissions";

import { NoteTextArea } from "./ModalTextArea";
import {
  ModalContent,
  ModalSection,
  ModalTitle,
  ModalExternalLink,
} from "./_shared";

interface SessionModalDispatchProps {
  updateSessionMeta: (
    id: string | number,
    noteContent: string,
  ) => Promise<AxiosResponse>;
}

interface SessionModalStateProps {
  sessionsMap: PanelSessionsMap;
}

interface SessionModalOwnProps {
  dataId: string | string[];
}

function _SessionModal(
  props: SessionModalOwnProps &
    SessionModalStateProps &
    SessionModalDispatchProps,
) {
  const mbcDataMap = useShallowEqualSelector(
    (state) => state.panel.eventMBCStatusesMap,
  );

  const clinician = useShallowEqualSelector((state) =>
    state.panel.clinicianId
      ? state.clinicians.clinicianMap[state.panel.clinicianId]
      : undefined,
  );

  const sessions =
    typeof props.dataId === "string"
      ? [props.sessionsMap[props.dataId]]
      : props.dataId.map((id) => props.sessionsMap[id]);

  if (!sessions) {
    return (
      <ModalContent>
        Unable to load week data. Please refresh the page.
      </ModalContent>
    );
  }

  const timezone = useTimezoneProvider();
  const cuser = React.useContext(CurrentUserContext);
  const config = React.useContext(CurrentConfigContext);

  const hasHealthieAccess = userCanAccessHealthie(cuser, config);

  const modalSections = [];
  for (const session of sessions) {
    const sessionStartTime = DateTime.fromISO(session.start_time).setZone(
      timezone,
    );
    const appointment = session.appointment[0];
    const client = appointment.client;
    const meta = session.meta;
    const noteContent = (meta && meta.note_content) || "";
    modalSections.push(
      <ModalTitle>
        <h3
          style={{
            verticalAlign: "middle",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span>
            Session with{" "}
            <Mangle>
              {client.first_name} {client.last_name}
            </Mangle>
          </span>
          <SessionStatusTag appointment={appointment} />
        </h3>
        <h4>
          {sessionStartTime.toLocaleString({ ...DateTime.DATETIME_FULL })}
        </h4>
        {Boolean(clinician?.is_wheel) && (
          <>
            <Text type="secondary">
              Please only begin the session after{" "}
              {sessionStartTime.toLocaleString(DateTime.TIME_SIMPLE)} after
              accepting the consult from the Wheel dashboard..
            </Text>
            <Row layout="start center" style={{ margin: "8px 0" }}>
              <Button
                onClick={() =>
                  api
                    .patch(`/api/wheel/consults/v1/${session.event_id}/start/`)
                    .then(() =>
                      openNotification("Session was begun.", "", "success"),
                    )
                    .catch(() =>
                      openNotification(
                        "Error beginning session.",
                        "Encountered an error. Please proceed with the session as usual.",
                        "error",
                      ),
                    )
                }
              >
                Begin Session
              </Button>
              <Button
                onClick={() =>
                  api
                    .patch(
                      `/api/wheel/consults/v1/${session.event_id}/complete/`,
                    )
                    .then(() =>
                      openNotification("Session was completed.", "", "success"),
                    )
                    .catch(() =>
                      openNotification(
                        "Error completing session.",
                        "Encountered an error. Please proceed with the post-session workflow as usual.",
                        "error",
                      ),
                    )
                }
              >
                Complete Session
              </Button>
            </Row>
          </>
        )}
        {Boolean(session.event_id in mbcDataMap) && (
          <MbcLinks
            panelClientId={client.id}
            mbcStatuses={mbcDataMap[session.event_id]}
          />
        )}
      </ModalTitle>,
    );

    modalSections.push(
      <>
        <NoteTextArea
          initialText={noteContent}
          updateFn={(note) => props.updateSessionMeta(session.id, note)}
        />
        <ModalSection>
          <h4>External Links</h4>
          {client.welkin8_id && !hasHealthieAccess && (
            <ModalExternalLink
              href={`https://care.live.welkincloud.io/twochairs/production/patient/${client.welkin8_id}`}
            >
              Welkin Chart
            </ModalExternalLink>
          )}
          {client.healthie_id && hasHealthieAccess && (
            <ModalExternalLink
              href={`https://twochairs.gethealthie.com/users/${client.healthie_id}`}
            >
              Healthie Chart
            </ModalExternalLink>
          )}
        </ModalSection>
      </>,
    );
  }

  return <ModalContent key={sessions[0].id}>{modalSections}</ModalContent>;
}

const mbcButton = (title: string, ems: EventMbcStatus | undefined) => (
  <Button
    style={{ width: "50%" }}
    disabled={!ems?.assessment_url}
    onClick={() => copyToClipboard(ems?.assessment_url as string)}
  >
    {title}
  </Button>
);

const MbcLinks = ({
  mbcStatuses,
  panelClientId,
}: {
  panelClientId: number;
  mbcStatuses: EventMbcStatus[];
}) => {
  const isCouples = React.useMemo(
    () => mbcStatuses.some((ems) => ems.client !== panelClientId),
    [mbcStatuses, panelClientId],
  );
  const buttons: React.ReactElement[] = [];

  if (isCouples) {
    const preSessionClientA = mbcStatuses.find(
      (ems) =>
        ems.client === panelClientId && ems.assessment_type === "pre-session",
    );
    buttons.push(
      mbcButton("Copy Pre-Session Link (Client A)", preSessionClientA),
    );
    const preSessionClientB = mbcStatuses.find(
      (ems) =>
        ems.client !== panelClientId && ems.assessment_type === "pre-session",
    );
    buttons.push(
      mbcButton("Copy Pre-Session Link (Client B)", preSessionClientB),
    );
  } else {
    const preSession = mbcStatuses.find(
      (ems) =>
        ems.client === panelClientId && ems.assessment_type === "pre-session",
    );
    buttons.push(mbcButton("Copy Pre-Session Link", preSession));
  }

  return (
    <>
      <h4>MBC Assessment Links:</h4>
      <Row layout="start center" style={{ flexWrap: "wrap" }}>
        {buttons}
      </Row>
      <div style={{ marginTop: "4px" }}>
        <Typography.Text type={"secondary"}>
          Refresh the page if a link should recently have become available.
        </Typography.Text>
      </div>
    </>
  );
};

function SessionStatusTag(props: { appointment: PanelAppointment }) {
  switch (props.appointment.appointment_status) {
    case "attended":
      return <StyledTag color="green">Attended</StyledTag>;
    case "canceled":
      return <StyledTag>Canceled</StyledTag>;
    case "noshow":
      return <StyledTag color="red">No Show</StyledTag>;
    default:
      return <StyledTag color="cyan">Scheduled</StyledTag>;
  }
}

const StyledTag = styled(Tag)`
  border-radius: 4px !important;
  margin-left: 0.5em !important;
`;

const mapStateToProps: ReactRedux.MapStateToProps<
  SessionModalStateProps,
  SessionModalOwnProps,
  AppState
> = (state) => {
  return {
    sessionsMap: state.panel.sessionsMap,
  };
};

const mapDispatchToProps: ReactRedux.MapDispatchToProps<
  SessionModalDispatchProps,
  SessionModalOwnProps
> = (dispatch: Dispatcher, ownProps) => ({
  updateSessionMeta: (id: string | number, noteContent: string) =>
    dispatch(panelOperations.setSessionOrWeekNote("session", id, noteContent)),
});

const SessionModal = ReactRedux.connect(
  mapStateToProps,
  mapDispatchToProps,
)(_SessionModal);

export default SessionModal;
