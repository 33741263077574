import { Spinner, Stack, Text } from "@/app/design-system";
import { TIMEZONE } from "@/app/my-clients/utils";
import { selectGroupTherapyClientData } from "../../_api/use-group-therapy-client-overview/use-group-therapy-client.util";
import { useGroupTherapyClientPage } from "../../_api/use-group-therapy-client-overview";
import { GroupClientEmergencyContactAndPayer } from "./emergency-contact-and-payer";
import { IdentityInfo } from "./identity-information";
import { NameInformation } from "./name-info";
import { CurrentUserContext } from "@/app/app.utils";
import React from "react";

export const BasicInformation = () => {
  const cuser = React.useContext(CurrentUserContext);

  const {
    data: clientData,
    isLoading,
    isError,
  } = useGroupTherapyClientPage({
    select: (data) => selectGroupTherapyClientData(data, TIMEZONE(cuser)),
  });

  const payer = clientData?.payer || null;

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    return (
      <Text color={"$red11"}> Error getting Client's Basic Information</Text>
    );
  }

  if (!isLoading && clientData && Object.keys(clientData).length) {
    return (
      <Stack css={{ pt: 53 }}>
        <NameInformation clientProfile={clientData.clientProfile} />
        <GroupClientEmergencyContactAndPayer
          emergencyContactData={clientData.emergencyContact}
          payer={payer}
        />
        <IdentityInfo identificationData={clientData.identify} />
      </Stack>
    );
  }

  return (
    <Stack css={{ pt: 60 }}>
      <div> This client did not fill out Basic Information. </div>
    </Stack>
  );
};
