import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import React from "react";

import { MatchCountRowItem } from "../../../api/types";
import { alphabetizeBy } from "../../_helpers";
import { useShallowEqualSelector } from "../../_helpers/redux";
import ClinicianItem from "../../_shared/ClinicianItem";

const MatchCountTable = ({
  matchCountTableData,
}: {
  matchCountTableData: MatchCountRowItem[];
}) => {
  const [clinicianMap] = useShallowEqualSelector((state) => [
    state.clinicians.clinicianMap,
  ]);
  const matchCountTableColumns: ColumnsType<MatchCountRowItem> = [
    {
      title: "Matched Clinician",
      dataIndex: "matchedClinician",
      key: "matchedClinician",
      render: (matchedClinician: number) => (
        <ClinicianItem clinicianId={matchedClinician} hideClinic={true} />
      ),
      sorter: (a: MatchCountRowItem, b: MatchCountRowItem) => {
        const firstClinician = clinicianMap[a.matchedClinician];
        const secondClinician = clinicianMap[b.matchedClinician];
        if (!firstClinician) return -1;
        if (!secondClinician) return 1;
        return alphabetizeBy(firstClinician, secondClinician, "first_name");
      },
    },
    {
      title: "Matches Suggested",
      dataIndex: "matchesSuggested",
      key: "matchesSuggested",
      sorter: (a: MatchCountRowItem, b: MatchCountRowItem) =>
        a.matchesSuggested - b.matchesSuggested,
    },
    {
      title: "Matches Requested",
      dataIndex: "matchesRequested",
      key: "matchesRequested",
      sorter: (a: MatchCountRowItem, b: MatchCountRowItem) =>
        a.matchesRequested - b.matchesRequested,
    },
  ];

  return (
    <Table
      columns={matchCountTableColumns}
      dataSource={matchCountTableData}
      locale={{ emptyText: "Shopping cart is empty." }}
      pagination={false}
      rowKey={(item: MatchCountRowItem) =>
        "row-" + item.matchedClinician.toString()
      }
      size="small"
    />
  );
};
export default MatchCountTable;
