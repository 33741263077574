import { Label, Stack, Text } from "@/app/design-system";
import { DeprecatedComposedReachUISelect } from "@/app/design-system/select-reachui/composed-select-reachui";
import "react-day-picker/dist/style.css";
import { useController, useFormContext, useWatch } from "react-hook-form";
import { RecurringSessionsOptions } from "../../../session-actions.constants";
import { UpsertSessionFormFields } from "../use-upsert-session-form";
import { UpsertSessionFormItemProps } from "./upsert-session-form-item.interface";
interface SessionRecurrenceFormItem extends UpsertSessionFormItemProps {
  recurrenceText: string;
}

export const SessionRecurrenceFormItem = ({
  name,
  options,
  id,
  mode,
  recurrenceText,
}: SessionRecurrenceFormItem) => {
  const { control } = useFormContext<UpsertSessionFormFields>();
  // register input
  const {
    field: { value, onChange, onBlur, ref },
    fieldState: { invalid, isDirty },
    formState: { errors },
  } = useController({
    name,
    control,
  });

  const { sessionsToUpdate } = useWatch<UpsertSessionFormFields>({ control });

  const thisSessionOnly =
    sessionsToUpdate === RecurringSessionsOptions.THIS_SESSION_ONLY;

  return (
    <Stack gap={10}>
      <Label required={mode === "add"}>Cadence</Label>
      <DeprecatedComposedReachUISelect
        options={options ? options : []}
        value={thisSessionOnly ? "Does not repeat" : value ? value : ""}
        onChange={onChange}
        disabled={thisSessionOnly}
        selectRootName={"Session Cadence"}
      />
      {mode === "edit" && recurrenceText && !thisSessionOnly && (
        <Text fontSize={12} color={"$neutral11"}>
          {recurrenceText}
        </Text>
      )}
    </Stack>
  );
};
