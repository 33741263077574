import React from "react";
import { useSessionHistoryTimeFiltersDropdown } from "./use-session-history-time-range-filter-dropdown";

import { ComposedDropdownMenu } from "@/app/design-system/dropdown/dropdown.composed";
import { SessionHistoryTimeRange } from "../../../../../my-clients-schedule-model";

export const SessionHistoryFiltersDropdownMenu = () => {
  const { timeRangeToFilterOn, setFilter } =
    useSessionHistoryTimeFiltersDropdown();

  return (
    <ComposedDropdownMenu
      setValue={setFilter}
      currentValue={timeRangeToFilterOn as string}
      values={Object.values(SessionHistoryTimeRange)}
    />
  );
};
