import { Flex, Text } from "../../../../../../../../design-system";
import { WashingMachineCycleIcon } from "../../../../../../../../design-system/icons";

interface SessionCadenceLabelProps {
  sessionCadence?: string;
  isException?: boolean;
}

const sessionCadenceLabelMap = {
  biweekly: "2w",
  weekly: "1w",
};

export const SessionCadenceLabel = ({
  sessionCadence,
  isException,
}: SessionCadenceLabelProps) => {
  return (
    <>
      {sessionCadence && !isException ? (
        <Flex gap={8} alignItems={"center"}>
          <WashingMachineCycleIcon />
          <Text color={"$neutral9"} fontWeight="500">
            {sessionCadenceLabelMap[sessionCadence]}
          </Text>
        </Flex>
      ) : null}
    </>
  );
};
