import { CurrentUserContext } from "@/app/app.utils";
import { DateTime } from "luxon";
import React from "react";
import { Text } from "../../../../../../../../../../../../design-system";
import { stringifyDateTimeInPrimaryTimezone } from "../../../../../../../../../../../utils";
import { TIMEZONE } from "../../../../../../../../../../../utils";
import { FutureSessionConflictRecurrence } from "../use-future-conflicts.api";
import { StyledUpcomingConflictsCard } from "./upcoming-conflicts-card.styled";

interface UpcomingRecurrencesConflictCardProps {
  recurrence: FutureSessionConflictRecurrence;
}

export const UpcomingRecurrencesConflictCard = ({
  recurrence,
}: UpcomingRecurrencesConflictCardProps) => {
  const cuser = React.useContext(CurrentUserContext);
  const recurrenceDateTime = DateTime.fromFormat(
    `${recurrence.start_date} ${recurrence.slot_time_of_day}`,
    "yyyy-MM-dd HH:mm:ss",
  ).setZone(TIMEZONE(cuser), { keepLocalTime: true });

  return (
    <StyledUpcomingConflictsCard flexDirection="column">
      <Text
        fontSize={"14"}
        capitalize={"firstLetter"}
        css={{ display: "initial" }}
      >
        {recurrence.slot_recurrence} {"recurrence starting "}
        {stringifyDateTimeInPrimaryTimezone(recurrenceDateTime, TIMEZONE(cuser), {
          ...DateTime.DATETIME_FULL,
        })}
      </Text>
      <Text
        fontSize={"16"}
        capitalize={"firstLetter"}
        css={{ display: "initial" }}
      >
        <>
          <Text
            fontWeight={600}
            capitalize={true}
            css={{ display: "initial", verticalAlign: "initial" }}
          >
            {recurrence.slot_type} Reservation
          </Text>
          {recurrence.client
            ? `: ${recurrence.client?.first_name} ${recurrence.client?.last_name}`
            : ""}
        </>
      </Text>
    </StyledUpcomingConflictsCard>
  );
};
