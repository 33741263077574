import {
  filterOutClinicianAndSystemCancellations,
  mergeAndFilterWithEocData,
} from "@/app/eoc-utils";
import {
  ClientOverviewApiData,
  EventApiData,
} from "../../../../../../api/use-my-clients-overview/my-clients-data.interface";
import { Stack } from "../../../../../../design-system";
import { UpcomingSessionsSection } from "./components/1-upcoming-sessions-section/upcoming-sessions-section";
import { SessionHistorySection } from "./components/2-session-history-section/session-history-section";
import { useMyClientsSchedule } from "./hooks/use-my-clients-schedule";

interface SchedulePageProps {
  serviceType?: string;
  client: ClientOverviewApiData | null;
  latestClinicianEocData: any;
}

export const SchedulePage = ({
  client,
  serviceType,
  latestClinicianEocData,
}: SchedulePageProps) => {
  let {
    firstSessionDateTime,
    sessionsFilteredByTimeRange,
    sessionsFilteredByTimeRangeAndAppointmentStatus,
  } = useMyClientsSchedule({
    sessionHistorySessions: client?.sessionHistorySessions,
  });
  let sessionsFilteredByTimeRangeWithEocData: Array<EventApiData> =
    sessionsFilteredByTimeRange;
  let sessionsFilteredByTimeRangeAndAppointmentStatusWithEocData: Array<EventApiData> =
    sessionsFilteredByTimeRangeAndAppointmentStatus;
  if (
    sessionsFilteredByTimeRange.length &&
    sessionsFilteredByTimeRangeAndAppointmentStatus.length &&
    latestClinicianEocData
  ) {
    sessionsFilteredByTimeRange = mergeAndFilterWithEocData(
      sessionsFilteredByTimeRange as [],
      latestClinicianEocData.dw_export_episode_of_care,
    );
    sessionsFilteredByTimeRangeWithEocData =
      filterOutClinicianAndSystemCancellations(sessionsFilteredByTimeRange);

    sessionsFilteredByTimeRangeAndAppointmentStatus = mergeAndFilterWithEocData(
      sessionsFilteredByTimeRangeAndAppointmentStatus as [],
      latestClinicianEocData.dw_export_episode_of_care,
    );
    sessionsFilteredByTimeRangeAndAppointmentStatusWithEocData =
      filterOutClinicianAndSystemCancellations(
        sessionsFilteredByTimeRangeAndAppointmentStatus,
      );
  }

  return (
    <Stack
      css={{
        backgroundColor: "white",
        flex: 1,
        minWidth: 800,
      }}
    >
      <UpcomingSessionsSection
        firstSessionDateTime={firstSessionDateTime}
        client={client}
        serviceType={serviceType}
      />
      <SessionHistorySection
        sessionHistorySessions={client?.sessionHistorySessions ?? []}
        sessionsFilteredByTimeRange={sessionsFilteredByTimeRangeWithEocData}
        sessionsFilteredByTimeRangeAndAppointmentStatus={
          sessionsFilteredByTimeRangeAndAppointmentStatusWithEocData
        }
      />
    </Stack>
  );
};
