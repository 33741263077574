import { Box, Flex, Stack, Text, styledStitches } from "@/app/design-system";

import { STANDARD_FOCUS_OUTLINE } from "@/app/design-system/styles/config/css-constants";

import { useGetLatestClinicianEpisodesOfCare } from "@/app/client/use-mbc/use-get-mbc-episodes-of-care";
import { useEffect, useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import {
  TabsContent,
  TabsList,
  TabsRoot,
  TabsTrigger,
} from "../../../../_components/secondary-nav/secondary-nav.styled";
import { ClientOverviewApiData } from "../../../../api/use-my-clients-overview/my-clients-data.interface";
import { DxCodeMap } from "../../../../client/types";
import { BarGraphIcon, CalendarIcon } from "../../../../design-system/icons";
import { MatchingInfoByClientIdApiData } from "../../../api/use-matching-data-by-clients/select-data-for-matching-data-by-client.util";
import { useMyClientsUser } from "../../my-clients-page/hooks/use-my-clients-user";
import { ClientProfileNavInfo } from "./client-profile-nav-info/client-profile-nav-info";
import { MBCPage } from "./routes/mbc-page/mbc-page";
import { SchedulePage } from "./routes/schedule-page/schedule-page";
import { Spin } from "antd";

interface ClientProfileNavigationProps {
  client: ClientOverviewApiData | null;
  clientMatchingData: MatchingInfoByClientIdApiData | null;
  dxCodeMap: DxCodeMap;
}

const StyledLink = styledStitches(Link, {
  display: "flex",
  alignItems: "stretch",
  "&:focus": { outline: STANDARD_FOCUS_OUTLINE },
});

const StyledNavList = styledStitches(Stack, {
  p: 24,
  gap: 48,
  borderRight: "1px solid $neutral5",
  backgroundColor: "$neutral3",
  "@breakpoint1024": {
    display: "none",
  },
});

export const ClientProfileNavigation = ({
  client,
  clientMatchingData,
  dxCodeMap,
}: ClientProfileNavigationProps) => {
  const [activeTabName, setActiveTabName] = useState("MBC");
  const { pathname } = useLocation();

  const { clinicianId } = useMyClientsUser();

  useEffect(() => {
    const defaultTab = pathname.includes("schedule") ? "Schedule" : "MBC";

    setActiveTabName(defaultTab);
  }, [pathname]);

  const [searchParams] = useSearchParams();
  let queryParamCID = searchParams.get("clinician_id");
  const currentClinicianId: number = queryParamCID
    ? parseInt(queryParamCID)
    : clinicianId;

  const clinicianIdQueryParam = currentClinicianId
    ? `?clinician_id=${currentClinicianId}`
    : "";

  const isEOCEnabled = false;

  const { data: latestClinicianEocData, isLoading } =
    useGetLatestClinicianEpisodesOfCare({
      clinicianId: currentClinicianId ?? 0,
      clientIds: [client?.clientId ?? 0],
      enabled: !!currentClinicianId,
    });

  return (
    <TabsRoot
      orientation="vertical"
      defaultValue={activeTabName}
      value={activeTabName}
    >
      <StyledNavList>
        <ClientProfileNavInfo
          client={client}
          clientMatchingData={clientMatchingData}
          dxCodeMap={dxCodeMap}
        />

        <TabsList>
          <TabsTrigger asChild value="MBC" css={{ flexGrow: 1 }}>
            <StyledLink
              to={`/my-clients/${client?.clientId}/mbc${clinicianIdQueryParam}`}
            >
              <Flex gap={16}>
                <BarGraphIcon /> <Text fontSize={18}>Check-in Results</Text>
              </Flex>
            </StyledLink>
          </TabsTrigger>
          <TabsTrigger asChild value="Schedule" css={{ flexGrow: 1 }}>
            <StyledLink
              to={`/my-clients/${client?.clientId}/schedule${clinicianIdQueryParam}`}
            >
              <Flex gap={16}>
                <CalendarIcon /> <Text fontSize={18}>Schedule</Text>
              </Flex>
            </StyledLink>
          </TabsTrigger>
        </TabsList>
      </StyledNavList>

      <Flex
        css={{
          flex: 1,
        }}
      >
        <TabsContent value="MBC">
          <MBCPage client={client} />
        </TabsContent>

        <TabsContent value="Schedule" aria-busy={isLoading && isEOCEnabled}>
          <Spin spinning={isLoading && isEOCEnabled}>
            {!isLoading ? (
              <SchedulePage
                client={client}
                serviceType={clientMatchingData?.serviceType}
                latestClinicianEocData={
                  isEOCEnabled ? latestClinicianEocData : null
                }
              />
            ) : (
              // We need this so the spinner isn't in a weird place
              <Stack
                aria-hidden
                css={{
                  backgroundColor: "white",
                  flex: 1,
                  minWidth: 800,
                }}
              ></Stack>
            )}
          </Spin>
        </TabsContent>
      </Flex>
    </TabsRoot>
  );
};
