export enum SessionFormatOption {
  VIRTUAL = "Virtual",
  IN_PERSON = "In person",
}

export const sessionFormatOptions = [
  {
    id: "2",
    label: SessionFormatOption.VIRTUAL,
    value: SessionFormatOption.VIRTUAL,
  },
  {
    id: "3",
    label: SessionFormatOption.IN_PERSON,
    value: SessionFormatOption.IN_PERSON,
  },
];

export enum SessionRecurrenceOption {
  EVERY_WEEK = "weekly",
  EVERY_OTHER_WEEK = "biweekly",
  DOES_NOT_REPEAT = "none",
}

const sessionRecurrenceOptions = [
  {
    id: "1",
    label: "Every week",
    value: SessionRecurrenceOption.EVERY_WEEK,
  },
  {
    id: "2",
    label: "Every other week",
    value: SessionRecurrenceOption.EVERY_OTHER_WEEK,
  },
];

export function getSessionRecurrenceOptions(mode: "edit" | "add") {
  const addSessionOption = {
    id: "3",
    label: "Does not repeat",
    value: SessionRecurrenceOption.DOES_NOT_REPEAT,
  };

  return mode === "edit"
    ? sessionRecurrenceOptions
    : [...sessionRecurrenceOptions, addSessionOption];
}

export const timeOptions = [
  { id: "0", label: "12:00 AM", value: "00:00" },
  { id: "1", label: "01:00 AM", value: "01:00" },
  { id: "2", label: "02:00 AM", value: "02:00" },
  { id: "3", label: "03:00 AM", value: "03:00" },
  { id: "4", label: "04:00 AM", value: "04:00" },
  { id: "5", label: "05:00 AM", value: "05:00" },
  { id: "6", label: "06:00 AM", value: "06:00" },
  { id: "7", label: "07:00 AM", value: "07:00" },
  { id: "8", label: "08:00 AM", value: "08:00" },
  { id: "9", label: "09:00 AM", value: "09:00" },
  { id: "10", label: "10:00 AM", value: "10:00" },
  { id: "11", label: "11:00 AM", value: "11:00" },
  { id: "12", label: "12:00 PM", value: "12:00" },
  { id: "13", label: "01:00 PM", value: "13:00" },
  { id: "14", label: "02:00 PM", value: "14:00" },
  { id: "15", label: "03:00 PM", value: "15:00" },
  { id: "16", label: "04:00 PM", value: "16:00" },
  { id: "17", label: "05:00 PM", value: "17:00" },
  { id: "18", label: "06:00 PM", value: "18:00" },
  { id: "19", label: "07:00 PM", value: "19:00" },
  { id: "20", label: "08:00 PM", value: "20:00" },
  { id: "21", label: "09:00 PM", value: "21:00" },
  { id: "22", label: "10:00 PM", value: "22:00" },
  { id: "23", label: "11:00 PM", value: "23:00" },
];
