import { RightChevronIcon } from "@/app/design-system/icons/components/right-chevron-icon";
import { Flex } from "@/app/design-system";
import {
  GroupEventApiData,
  GroupOverviewApiData,
} from "../../_api/group-therapy.interface";
import { StyledGroupPaneDiv, StyledGroupPaneLink } from "./group-pane.styled";
import {
  NextSessionFormatted,
  StartingSession,
} from "./next-session-formatted";
import { ClientsSessionsMBC } from "./clients-sessions-mbc";
import { GroupNameAndSIAlert } from "./group-name-and-si-alert";
import { useGroupTherapyUser } from "../../_hooks/use-group-therapy-user";
import { DateTime } from "luxon";
import { TIMEZONE } from "@/app/my-clients/utils";
import { useMyClientsSearchStore } from "@/app/my-clients/routes/my-clients-page/my-clients-model/my-clients.model";
import React from "react";
import { CurrentUserContext } from "@/app/app.utils";

export interface GroupCardProps extends GroupOverviewApiData {
  index: number;
}

export const GroupPaneContent = ({
  groupTitle,
  mbcCompleted,
  endorsedSI,
  nextSession,
  totalSessions,
  numClients,
  groupStartTime,
  hasCompletedSessions,
  lastSession,
}: GroupCardProps) => {
  const cuser = React.useContext(CurrentUserContext);
  const isStarting =
    groupStartTime === nextSession?.eventStartTime ||
    DateTime.fromISO(groupStartTime, {
      zone: TIMEZONE(cuser),
    }) > DateTime.now();

  return (
    <Flex
      css={{
        "@breakpoint1200": {
          justifyContent: "space-between",
          gap: 30,
          flexDirection: "row",
        },
      }}
    >
      <GroupNameAndSIAlert groupTitle={groupTitle} endorsedSI={endorsedSI} />

      <ClientsSessionsMBC
        mbcCompleted={mbcCompleted}
        nextSession={nextSession}
        totalSessions={totalSessions}
        numClients={numClients}
        groupStartTime={groupStartTime}
      />

      <Flex
        gap={8}
        css={{
          flexGrow: 1,
        }}
        alignItems={"center"}
        justifyContent={"flex-end"}
      >
        {isStarting ? (
          <StartingSession
            nextSession={nextSession}
            groupStartTime={groupStartTime}
          />
        ) : nextSession ? (
          <NextSessionFormatted
            nextSession={nextSession}
            isStarting={isStarting}
            hasCompletedSessions={hasCompletedSessions}
            lastSession={lastSession}
          />
        ) : null}
        <RightChevronIcon className="chevron-right" />
      </Flex>
    </Flex>
  );
};

export const getSessionToLinkTo = (
  prevSession?: GroupEventApiData | null,
  nextSession?: GroupEventApiData | null,
): GroupEventApiData | null => {
  if (prevSession && nextSession) {
    const now = DateTime.now();
    const nextSessionStartTime = DateTime.fromISO(nextSession.eventStartTime);
    return now < nextSessionStartTime ? prevSession : nextSession;
  }
  if (prevSession && !nextSession) {
    return prevSession;
  }
  if (!prevSession && nextSession) {
    return nextSession;
  }
  return null;
};

export const GroupPane = (props: GroupCardProps) => {
  const { groupId, nextSession, prevSession, numClients, isCanceled } = props;
  const { clinicianId } = useGroupTherapyUser();
  const { currentlySelectedClinician } = useMyClientsSearchStore();

  const clinicianIdParam = clinicianId
    ? `clinician_id=${clinicianId}`
    : currentlySelectedClinician
    ? `clinician_id=${currentlySelectedClinician}`
    : "";

  const sessionToLinkTo = getSessionToLinkTo(prevSession, nextSession);

  if (sessionToLinkTo) {
    return (
      <StyledGroupPaneLink
        css={{
          "@breakpoint1200": {
            gap: 12,
          },
        }}
        to={`/groups/${groupId}/sessions/${sessionToLinkTo.eventId}/notes?${clinicianIdParam}`}
      >
        <GroupPaneContent {...props} />
      </StyledGroupPaneLink>
    );
  }

  return (
    <StyledGroupPaneDiv
      css={{
        "@breakpoint1200": {
          gap: 12,
        },
      }}
    >
      <GroupPaneContent {...props} />
    </StyledGroupPaneDiv>
  );
};
