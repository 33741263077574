import {
  ClientProfile,
  formatBirthDate,
  formatPhoneNumber,
} from "../../_api/use-group-therapy-client-overview/use-group-therapy-client.util";
import {
  BasicInfoQuestionAndResponseContainer,
  BasicInfoQuestionContainer,
  BasicInfoQuestionText,
  BasicInfoResponseText,
  SectionDivider,
} from "./basic-information.styled";
import { getAge } from "@/app/groups/group-id/clients/_api/use-group-therapy-clients-overview/use-group-therapy-clients.util";
import { TIMEZONE } from "@/app/my-clients/utils";
import { CurrentUserContext } from "@/app/app.utils";
import React from "react";

interface NameInformationProps {
  clientProfile?: ClientProfile;
}

export const NameInformation = ({ clientProfile }: NameInformationProps) => {
  if (!clientProfile || !Object.keys(clientProfile).length) {
    return null;
  }
  const cuser = React.useContext(CurrentUserContext);

  const fullName = `${clientProfile.first_name} ${clientProfile.last_name}`;
  const address = `${clientProfile.address_line_1}
          ${clientProfile.address_line_2}
          ${clientProfile.city},
          ${clientProfile.state}
          ${clientProfile.zip}`;
  const birthdayAndAge = `${formatBirthDate(
    clientProfile.dob,
    TIMEZONE(cuser),
  )} (age
          ${getAge(clientProfile.dob, TIMEZONE(cuser))})`;

  return (
    <>
      <BasicInfoQuestionAndResponseContainer>
        <BasicInfoQuestionContainer>
          <BasicInfoQuestionText> Preferred Name </BasicInfoQuestionText>
        </BasicInfoQuestionContainer>
        <BasicInfoResponseText>
          {clientProfile.preferred_name}
        </BasicInfoResponseText>
      </BasicInfoQuestionAndResponseContainer>

      <BasicInfoQuestionAndResponseContainer>
        <BasicInfoQuestionContainer>
          <BasicInfoQuestionText> Legal Name </BasicInfoQuestionText>
        </BasicInfoQuestionContainer>
        <BasicInfoResponseText>{fullName}</BasicInfoResponseText>
      </BasicInfoQuestionAndResponseContainer>

      <BasicInfoQuestionAndResponseContainer>
        <BasicInfoQuestionContainer>
          <BasicInfoQuestionText> Email </BasicInfoQuestionText>
        </BasicInfoQuestionContainer>
        <BasicInfoResponseText>{clientProfile.email}</BasicInfoResponseText>
      </BasicInfoQuestionAndResponseContainer>

      <BasicInfoQuestionAndResponseContainer>
        <BasicInfoQuestionContainer>
          <BasicInfoQuestionText> Address </BasicInfoQuestionText>
        </BasicInfoQuestionContainer>
        <BasicInfoResponseText>{address}</BasicInfoResponseText>
      </BasicInfoQuestionAndResponseContainer>

      <BasicInfoQuestionAndResponseContainer>
        <BasicInfoQuestionContainer>
          <BasicInfoQuestionText> Phone </BasicInfoQuestionText>
        </BasicInfoQuestionContainer>
        <BasicInfoResponseText>
          {formatPhoneNumber(clientProfile.phone_number)}
        </BasicInfoResponseText>
      </BasicInfoQuestionAndResponseContainer>

      <BasicInfoQuestionAndResponseContainer>
        <BasicInfoQuestionContainer>
          <BasicInfoQuestionText> Date of Birth </BasicInfoQuestionText>
        </BasicInfoQuestionContainer>
        <BasicInfoResponseText>{birthdayAndAge}</BasicInfoResponseText>
      </BasicInfoQuestionAndResponseContainer>
      <SectionDivider />
    </>
  );
};
