import { DateTime } from "luxon";

export const fuzzyMatch = (
  searchTerm: string,
  fullName: string | null,
  clientId: string | undefined,
) => {
  if (!fullName) return false;
  const normalizedSearchTerm = searchTerm.toLowerCase();
  const normalizedFullName = fullName.toLowerCase();

  return (
    normalizedFullName.includes(normalizedSearchTerm) ||
    clientId?.includes(normalizedSearchTerm)
  );
};

export const isMatchDelayed = (timestamp: string | null) => {
  if (timestamp !== null) {
    const matchAfter = DateTime.fromISO(timestamp);
    const currentDateTime = DateTime.now();
    return matchAfter > currentDateTime;
  } else {
    return false;
  }
};

export const formatPastData = (data: Array<any>) => {
  return data.map((datum) => {
    return {
      match: datum,
      consult: {
        consultId: datum?.consult_id,
        healthieNoteId: datum?.consult_event?.healthie_note_id,
        healthieId: datum?.ehr_client?.healthie_id,
        client: datum?.ehr_client,
        clinician: datum?.clinician_id,
        match: {
          ...datum,
          client: {
            ...datum?.ehr_client,
            payers: datum?.ehr_client?.client_payer_service_records,
          },
        },
      },
      client: {
        fullName: `${datum?.ehr_client?.first_name} ${datum?.ehr_client?.last_name}`,
        clientId: datum?.ehr_client?.id,
        payerAcronym:
          datum?.ehr_client?.client_payer_service_records?.[0]?.payer?.acronym,
        payerDisplayName:
          datum?.ehr_client?.client_payer_service_records?.[0]?.payer
            ?.display_name,
        isFormerClient: datum?.former_client,
        isHighPriority: datum?.priority === "high_acuity",
        isRematch: datum?.is_rematch,
        email: datum?.ehr_client?.email,
      },
      startTime: datum?.consult_event?.start_time,
      statusOrStartTime: {
        status: isMatchDelayed(datum?.match_after)
          ? "Delayed"
          : datum?.client_status,
        startTime: datum?.consult_event?.start_time || datum?.created_at,
        hasConsult: datum?.consult_event ? true : false,
      },
    };
  });
};

export const formatUpcomingAndIncompleteData = (data: Array<any>) => {
  return data.map((datum) => {
    return {
      consult: {
        id: datum?.id,
        consultId: datum.id,
        healthieNoteId: datum?.healthie_note_id,
        ehrMatches: datum?.ehr_matches,
        healthieId: datum?.client?.healthie_id,
        clinician: datum?.clinician_id,
        startTime: datum?.start_time,
        client: {
          ...datum?.client,
          payers: datum?.client?.client_payer_service_records,
        },
      },
      client: {
        fullName: `${datum?.client?.first_name} ${datum?.client?.last_name}`,
        id: datum?.client?.id,
      },
      startTime: datum.start_time,
      statusOrStartTime: {
        status: null,
        startTime: datum?.start_time,
      },
    };
  });
};

export const customSort = (a: any, b: any) => {
  const startTimeA = a.consult_event
    ? new Date(a.consult_event?.start_time).getTime()
    : new Date(a.created_at).getTime();
  const startTimeB = b.consult_event
    ? new Date(b.consult_event?.start_time).getTime()
    : new Date(b.created_at).getTime();

  return startTimeB - startTimeA;
};

export const checkForAndAppendMedicareAndMedical = (matchData: Array<any>) => {
  matchData.forEach((match) => {
    if (
      match.ehr_client.ehr_insurancerecords &&
      match.ehr_client.ehr_insurancerecords.length
    ) {
      const orderedRecords = match.ehr_client.ehr_insurancerecords
        ?.slice()
        .sort((a: any, b: any) => {
          if (
            !a?.effective_termination_date &&
            !b?.effective_termination_date
          ) {
            return 0;
          } else if (!a?.effective_termination_date) {
            return -1;
          } else if (!b?.effective_termination_date) {
            return 1;
          }

          return (
            DateTime.fromISO(b.effective_termination_date).toMillis() -
            DateTime.fromISO(a.effective_termination_date).toMillis()
          );
        });
      match.is_medicare = orderedRecords[0].is_medicare;
      match.is_medical = orderedRecords[0].is_medical;
    }
  });
  return matchData;
};

/**
 * @name getRelevantConsultClinicianMatchEvents
 * @description This function is used to get the relevant match events for a clinician. When someone is the originating consult clinician,
 * they should have the ability to see all of the events for a client.
 * @param allClientMatchEvents
 */
export const getRelevantConsultClinicianMatchEvents = (
  allClientMatchEvents: Array<any>,
  clinicianId: number,
): Array<any> => {
  const sortedEvents = allClientMatchEvents.sort(customSort);
  const isOriginatingConsultClinician =
    sortedEvents.length &&
    sortedEvents[sortedEvents.length - 1].assignee_auth_user.ehr_clinician
      .id === clinicianId;
  return isOriginatingConsultClinician
    ? sortedEvents
    : sortedEvents.filter(
        (event) => event.assignee_auth_user.ehr_clinician.id === clinicianId,
      );
};

/**
 * @name getClientsMapFromPastConsultsData
 * @description Gets the clients from the past consults data as a map of client id to client
 */
export const getClientsMapFromPastConsultsData = (
  allClientMatchEvents: Array<any>,
): Map<string, any> => {
  let clientMap = new Map<string, any>();

  allClientMatchEvents.forEach((event) => {
    clientMap.set(event.client.clientId, event.client);
  });
  return clientMap;
};
