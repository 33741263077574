import { ClientOverviewApiData } from "@/app/api/use-my-clients-overview/my-clients-data.interface";
import { QuarterCircle } from "@/app/design-system";
import { useIsFetching } from "react-query";
import { DownChevronIcon } from "../../../../../../../../design-system/icons";
import {
  Box,
  Button,
  CSS_COLORS,
  Spinner,
  Stack,
  Text,
} from "../../../../../../../../design-system/index";
import { useToggle } from "../../../../../../../../hooks/use-toggle";
import { SessionCadence } from "../../schedule-page.interface";
import { SessionCard } from "../3-session-card/session-card";
import { EmptySessions } from "../empty-sessions";
import { ExpandedUpcomingSessionsSection } from "./expanded-upcoming-sessions-section";
import { UpcomingSessionsSectionHeader } from "./upcoming-sessions-header";

interface UpcomingSessionsSectionProps {
  client: ClientOverviewApiData | null;
  firstSessionDateTime: string | null;
  serviceType?: string;
}

export const UpcomingSessionsSection = ({
  client,
  serviceType,
  firstSessionDateTime,
}: UpcomingSessionsSectionProps) => {
  const [isExpanded, setIsExpanded] = useToggle();

  const upcomingSessions = client?.upcomingSessions;
  const remainingSessions = client?.upcomingSessions?.slice(3);

  const totalRemainingSessions = remainingSessions && remainingSessions?.length;

  const isFetchingClientOverview = useIsFetching(["MyClientsOverview"]);

  return (
    <Box p={24} css={{ borderBottom: "1px solid $neutral6" }}>
      <Stack gap={32} css={{ flex: 1, minWidth: 800 }}>
        <UpcomingSessionsSectionHeader
          clientFirstName={client?.firstName}
          clientLastName={client?.lastName}
          firstSessionDate={firstSessionDateTime}
          serviceType={serviceType}
          clientId={client?.clientId}
        />

        {upcomingSessions?.length === 0 ? (
          <EmptySessions>No upcoming sessions</EmptySessions>
        ) : null}

        {isFetchingClientOverview ? (
          <Spinner>
            <QuarterCircle dimension={"2em"} />
          </Spinner>
        ) : (
          <Stack gap={"16"} alignItems="flex-start">
            {upcomingSessions?.slice(0, 3).map((event, index) => (
              <SessionCard
                key={event.eventId}
                sessionCadence={SessionCadence.Biweekly}
                isEditable={true}
                index={index}
                client={client}
                event={event}
              />
            ))}

            <ExpandedUpcomingSessionsSection
              remainingSessions={remainingSessions ?? []}
              isExpanded={isExpanded}
              client={client}
            />

            {totalRemainingSessions && totalRemainingSessions > 0 ? (
              <ViewMoreSessionsButton
                setIsExpanded={setIsExpanded}
                isExpanded={isExpanded}
                totalRemainingSessions={totalRemainingSessions}
              />
            ) : null}
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

interface ViewMoreSessionsButtonProps {
  setIsExpanded: () => void;
  isExpanded: boolean;
  totalRemainingSessions?: number;
}

export const ViewMoreSessionsButton = ({
  setIsExpanded,
  isExpanded,
  totalRemainingSessions,
}: ViewMoreSessionsButtonProps) => {
  return (
    <Button
      onClick={() => setIsExpanded()}
      variant={"tertiary"}
      color="$neutral11"
      size={"small"}
      // normally, we should try to avoid as many runtime styling props as possible,
      // this is easier to read than the variant version.
      css={{
        svg: {
          transition: "transform ease-in-out 0.25s",
          transform: isExpanded ? "rotate(-180deg)" : "rotate(0deg)",
          "&:hover": {
            fill: `${CSS_COLORS.neutral9}`,
          },
        },
      }}
    >
      <Text color="$neutral11">
        {isExpanded ? `View less` : `View ${totalRemainingSessions} more`}
      </Text>
      <DownChevronIcon fill={CSS_COLORS.neutral11} />
    </Button>
  );
};
