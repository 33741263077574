import { useAQMScoreTableData } from "./use-aqm-score-util-hooks/use-aqm-score-table-data";
import { useBestScoreAndSlot } from "./use-aqm-score-util-hooks/use-best-score-and-slot";
import { useFilterTuningParams } from "./use-aqm-score-util-hooks/use-filter-tuning-params";

export interface UseAQMScoreTableParams {
  matchId: number;
}

export const parseScore = (score: number) => {
  return Number(score.toFixed(1));
};

export function useAQMScoreTable({ matchId }: UseAQMScoreTableParams) {
  const { clinicianBestSlots } = useBestScoreAndSlot({ matchId });

  const { tableData, mergedBreakdownComponents } = useAQMScoreTableData({
    matchId,
    clinicianBestSlots,
  });

  const componentColumns = useFilterTuningParams(
    matchId,
    mergedBreakdownComponents,
  );

  return { componentColumns, tableData };
}
