// Reach UI isn't being used anymore so we'd need to use Radix UI instead whenever we move this to the NextJS App

import {
  CSS_COLORS,
  Flex,
  QuarterCircle,
  Spinner,
  styledStitches,
  Text,
} from "@/app/design-system";
import { UnstyledButton } from "@/app/design-system/button/button";
import {
  StyledCombobox,
  StyledComboboxInput,
  StyledComboboxList,
  StyledComboboxOption,
} from "@/app/design-system/combobox/search-combobox/search-combobox.styled";
import { ComboboxOptionText, ComboboxPopover } from "@reach/combobox";
import { useState } from "react";
import { Link } from "react-router-dom";
import { ClientOverviewApiData } from "../../../../../api/use-my-clients-overview/my-clients-data.interface";
import { useMatch } from "../../../../../design-system/combobox/search-combobox/search-combobox.util";
import { MagnifyingGlassIcon, XIcon } from "../../../../../design-system/icons";
import { selectDataForSearchBar } from "../../../../api/use-search-clients/select-data-for-searchbar";
import { useSearchClients } from "../../../../api/use-search-clients/use-search-clients.api";
import { useMyClientsUser } from "../../hooks/use-my-clients-user";
import { useMyClientsSearchStore } from "../../my-clients-model/my-clients.model";

interface SearchInputProps {
  myClients: ClientOverviewApiData[];
}

interface GlobalSearchInputProps {
  myClients: ClientOverviewApiData[];
  onOptionSelect: (e: any) => void;
}

export const useClientSearchBar = (myClients: ClientOverviewApiData[]) => {
  const [searchTerm, setSearchTerm] = useState("");
  const {
    isClinicalLeaderOrSuperuser,
    clinicianId,
    isSuperUser,
    isCareCoordinator,
  } = useMyClientsUser();

  const {
    data: allClientsSearchResults,
    refetch,
    isLoading,
  } = useSearchClients({
    searchTerm,
    clinicianId,
    select: selectDataForSearchBar,
  });

  const clinicianPanelSearchResults = useMatch({
    term: searchTerm,
    data: myClients,
    keys: [
      (item: ClientOverviewApiData) => `${item.firstName}`,
      (item: ClientOverviewApiData) => `${item.lastName}`,
    ],
    throttle: 0,
  });

  const resultsToRender =
    isClinicalLeaderOrSuperuser || isCareCoordinator
      ? allClientsSearchResults
      : clinicianPanelSearchResults;

  return {
    searchTerm,
    setSearchTerm,
    resultsToRender,
    refetch,
    isSuperUser,
    isLoading,
    clinicianId,
  };
};

export const ClientSearchBar = ({ myClients }: SearchInputProps) => {
  const { setClientIds, setSearchTermState } = useMyClientsSearchStore();

  const {
    searchTerm,
    setSearchTerm,
    resultsToRender,
    refetch,
    isSuperUser,
    isLoading,
    clinicianId,
  } = useClientSearchBar(myClients);

  const clinicianIdQueryParam = clinicianId
    ? `?clinician_id=${clinicianId}`
    : "";

  return (
    <StyledCombobox
      aria-label="clients"
      openOnFocus
      onSelect={(val) => {
        refetch();
        setSearchTermState(val);
        setSearchTerm("");
        setClientIds(resultsToRender?.map((client) => client.clientId) ?? []);
      }}
    >
      <StyledComboboxInput
        className="clients-search-input"
        onChange={(event) => setSearchTerm(event.target.value)}
        placeholder="Search clients"
        value={searchTerm}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            refetch();
            setSearchTermState(searchTerm);
            setSearchTerm("");
            setClientIds(
              resultsToRender?.map((client) => client.clientId) ?? [],
            );
          }
        }}
      />

      <MagnifyingGlassIcon className="magnifying-glass" />

      {searchTerm.length > 0 ? (
        <StyledClearSearchButton
          className="clear-search-button"
          onClick={() => setSearchTerm("")}
          aria-label="Clear Search"
        >
          <XIcon fill={CSS_COLORS.neutral10} />
        </StyledClearSearchButton>
      ) : null}

      <ComboboxPopover className="shadow-popup">
        {resultsToRender && resultsToRender.length > 0 ? (
          <StyledComboboxList>
            {resultsToRender.slice(0, isSuperUser ? 7 : 10).map((client) => (
              <Link
                to={`/my-clients/${client.clientId}/mbc${clinicianIdQueryParam}`}
                key={client.clientId}
              >
                <StyledComboboxOption
                  value={`${client.firstName} ${client.lastName}`}
                >
                  <ComboboxOptionText />

                  <>
                    {isSuperUser && (
                      <Text fontSize={12} color={"$neutral11"}>
                        {client.clientId}
                      </Text>
                    )}

                    <Text fontSize={12} color={"$neutral11"}>
                      {client.email}
                    </Text>
                  </>
                </StyledComboboxOption>
              </Link>
            ))}
          </StyledComboboxList>
        ) : (
          <>
            {/* this prevents "No results found flicker before typing in second letter" */}
            {searchTerm.length > 1 ? (
              <StyledComboboxList>
                <Flex
                  css={{
                    px: 34,
                    py: 8,
                    color: "$neutral11",
                    justifyContent: isLoading ? "center" : "start",
                  }}
                >
                  {isLoading ? (
                    <Spinner>
                      <QuarterCircle dimension={"3em"} />
                    </Spinner>
                  ) : (
                    "No results found"
                  )}
                </Flex>
              </StyledComboboxList>
            ) : null}
          </>
        )}
      </ComboboxPopover>
    </StyledCombobox>
  );
};

export const GlobalClientSearchBar = ({
  myClients,
  onOptionSelect,
}: GlobalSearchInputProps) => {
  const { setClientIds, setSearchTermState } = useMyClientsSearchStore();

  const {
    searchTerm,
    setSearchTerm,
    resultsToRender,
    refetch,
    isSuperUser,
    isLoading,
    clinicianId,
  } = useClientSearchBar(myClients);

  const clinicianIdQueryParam = clinicianId
    ? `?clinician_id=${clinicianId}`
    : "";

  return (
    <StyledCombobox
      aria-label="clients"
      openOnFocus
      onSelect={(val) => {
        refetch();
        setSearchTermState(val);
        setSearchTerm("");
        setClientIds(resultsToRender?.map((client) => client.clientId) ?? []);
      }}
      css={{
        width: "400px",
      }}
    >
      <StyledComboboxInput
        className="clients-search-input"
        onChange={(event) => setSearchTerm(event.target.value)}
        placeholder="Search clients"
        value={searchTerm}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            refetch();
            setSearchTermState(searchTerm);
            setSearchTerm("");
            setClientIds(
              resultsToRender?.map((client) => client.clientId) ?? [],
            );
          }
        }}
      />

      <MagnifyingGlassIcon className="magnifying-glass" />

      {searchTerm.length > 0 ? (
        <StyledClearSearchButton
          className="clear-search-button"
          onClick={() => setSearchTerm("")}
          aria-label="Clear Search"
        >
          <XIcon fill={CSS_COLORS.neutral10} />
        </StyledClearSearchButton>
      ) : null}

      <ComboboxPopover className="shadow-popup">
        {resultsToRender && resultsToRender.length > 0 ? (
          <StyledComboboxList>
            {resultsToRender.slice(0, isSuperUser ? 7 : 10).map((client) => (
              <GlobalSearchStyledComboboxOption
                key={client.clientId}
                value={`${client.firstName} ${client.lastName}`}
              >
                <GlobalComboBoxPopover
                  onClick={() => {
                    onOptionSelect(client.clientId);
                  }}
                  aria-label="Select {client.firstName} {client.lastName}}"
                >
                  <div>
                    <ComboboxOptionText />
                  </div>

                  <>
                    {isSuperUser && (
                      <Text fontSize={12} color={"$neutral11"}>
                        {client.clientId}
                      </Text>
                    )}

                    <Text fontSize={12} color={"$neutral11"}>
                      {client.email}
                    </Text>
                  </>
                </GlobalComboBoxPopover>
              </GlobalSearchStyledComboboxOption>
            ))}
          </StyledComboboxList>
        ) : (
          <>
            {/* this prevents "No results found flicker before typing in second letter" */}
            {searchTerm.length > 1 ? (
              <StyledComboboxList>
                <Flex
                  css={{
                    px: 34,
                    py: 8,
                    color: "$neutral11",
                    justifyContent: isLoading ? "center" : "start",
                  }}
                >
                  {isLoading ? (
                    <Spinner>
                      <QuarterCircle dimension={"3em"} />
                    </Spinner>
                  ) : (
                    "No results found"
                  )}
                </Flex>
              </StyledComboboxList>
            ) : null}
          </>
        )}
      </ComboboxPopover>
    </StyledCombobox>
  );
};

const StyledClearSearchButton = styledStitches("button", {
  all: "unset",
  position: "absolute",
  cursor: "pointer",
});

const GlobalSearchStyledComboboxOption = styledStitches(StyledComboboxOption, {
  py: 0,
  px: 0,
});

const GlobalComboBoxPopover = styledStitches("button", UnstyledButton, {
  minWidth: "100%",
  py: 8,
  display: "flex",
  flexDirection: "column",
  justifyItems: "start",
  alignItems: "left",
});
