import { useMyClientsOverview } from "../../../../../../api/use-my-clients-overview/use-my-clients-overview.api";
import { selectDataForMyClients } from "../../../../../api/utils/select-data-for-my-clients.util";
import { MyClientsOverviewQuery } from "@/graphql/generated";
import { UseGetMBCsWithEpisodesOfCare } from "@/app/client/use-mbc/use-get-mbcs-episodes-of-care";
import { useGetEpisodesOfCare } from "@/app/client/use-mbc/use-get-mbc-episodes-of-care";

import { useUserHasAnyPermissions } from "@/app/_helpers/permissions";
import { attachTotalSessionsAttendedWithCurrentClinicianToClients } from "@/app/eoc-utils";
import { DataWithSessionCount } from "@/app/library/types";
import { ClientOverviewApiData } from "@/app/api/use-my-clients-overview/my-clients-data.interface";

interface UseMyClientsOverviewInternalParams {
  clinicianIdToQueryBy: number;
  clientIds: number[];
  isQualityBetaUser: boolean | null;
}

export const useMyClientsOverviewInternal = ({
  clinicianIdToQueryBy,
  clientIds,
}: UseMyClientsOverviewInternalParams) => {
  // This query will not run unless  and there's a clinicianId to query by.
  // clientIds.length === 0 means there's nothing come back from search results
  // in search results returned from useMyClientsSearchStore.

  const enabled = !!clinicianIdToQueryBy && clientIds.length === 0;

  const { data: myClientsOverviewData, isLoading: isMyClientsOverviewLoading } =
    useMyClientsOverview({
      enabled,
      clinicianId: clinicianIdToQueryBy,
      select: (data) => selectDataForMyClients(data as MyClientsOverviewQuery),
    });


  // const clientIdsAsNumbers: Array<number> = myClientsOverviewData
  //   ? myClientsOverviewData.flatMap((datum: ClientOverviewApiData) => {
  //       if (!datum.clientId) {
  //         return [];
  //       }
  //       return datum.clientId;
  //     })
  //   : [];

  // const { data: clientEOCData, isLoading: isEOCDataLoading } =
  //   useGetEpisodesOfCare({
  //     clientIds: clientIdsAsNumbers ?? [],
  //     enabled: true,
  //     select: (data) => {
  //       return data.dw_export_episode_of_care;
  //     },
  //   });
  // let myEocClientsData

  // if (
  //   !isEOCDataLoading &&
  //   !isMyClientsOverviewLoading &&
  //   IsCareEpisodesUser &&
  //   clientEOCData &&
  //   clinicianIdToQueryBy &&
  //   myClientsOverviewData
  // ) {
  //   myEocClientsData = attachTotalSessionsAttendedWithCurrentClinicianToClients(
  //     myClientsOverviewData,
  //     clientEOCData,
  //     clinicianIdToQueryBy ?? 0,
  //   ) as DataWithSessionCount[];
  // }

  return {
    isMyClientsOverviewLoading,
    isEocDataAvailable: false,
    myClientsOverviewData: myClientsOverviewData,
  };
};
