import {
  AllParsedMBCScoreData,
  ParsedClientEventMbcScores,
  ParsedEventGroupAndClientMbcScores,
} from "@/app/groups/group-id/sessions/session-id/mbc/_api/use-group-therapy-mbc-events/use-group-therapy-mbc-events.interface";
import { DateTime } from "luxon";

export interface ParsedClientMBCData {
  clientId: number;
  totalResponded?: number;
  totalSent?: number;
  eventScores: ParsedClientEventMbcScoresAndSeverities[] | null;
}
export interface ParsedClientEventMbcScoresAndSeverities {
  eventId: string;
  eventDate: string;
  sessionNumber: number;
  isComplete?: boolean;
  isEnrolled: boolean;
  PHQ: ScoresAndSeverities;
  GAD: ScoresAndSeverities;
  cohesion?: number | null;
  endorsedSI?: boolean | null;
}

export interface ScoresAndSeverities {
  score?: number | null;
  severity?: string;
  status?: string;
}

export const getParsedClientMBCData = (
  allData?: AllParsedMBCScoreData,
  selectedClientId?: string,
): ParsedClientMBCData | null => {
  const clientId = selectedClientId ? parseInt(selectedClientId) : undefined;
  if (
    !clientId ||
    !allData ||
    !allData.eventScores ||
    allData.eventScores?.length === 0
  ) {
    return null;
  }

  const clientEventData = selectClientEventScores(
    allData.eventScores,
    clientId,
  );

  const clientResponseData = allData?.clientResponseTotals?.find(
    (client) => client.clientId === clientId,
  );

  return {
    clientId: clientId,
    totalResponded: clientResponseData?.totalResponded,
    totalSent: clientResponseData?.totalSent,
    eventScores: clientEventData,
  };
};

// get selected client's scores from each Event
const selectClientEventScores = (
  allEventScores: ParsedEventGroupAndClientMbcScores[],
  clientId: number,
) => {
  let clientEventData: ParsedClientEventMbcScoresAndSeverities[] = [];

  allEventScores.forEach(
    (eventScoreSummaries: ParsedEventGroupAndClientMbcScores) => {
      const eventId = eventScoreSummaries.eventId;
      const eventDate = eventScoreSummaries.eventDate;
      const sessionNumber = eventScoreSummaries.sessionNumber;

      // find client's score data (if any) in array
      const selectedClientEventScoreSummary = eventScoreSummaries.clientScores
        ? eventScoreSummaries.clientScores.find(
            (eventClientSummary: ParsedClientEventMbcScores) => {
              return eventClientSummary.clientId === clientId;
            },
          )
        : null;

      // if client score data exists and is complete, create and parse score data
      if (
        selectedClientEventScoreSummary &&
        selectedClientEventScoreSummary.isComplete
      ) {
        const GADSeverity = getGADSeverity(selectedClientEventScoreSummary.GAD);
        const PHQSeverity = getPHQSeverity(selectedClientEventScoreSummary.PHQ);

        const parsedClientEvent: ParsedClientEventMbcScoresAndSeverities = {
          eventId: eventId,
          eventDate: eventDate,
          sessionNumber: sessionNumber,
          isComplete: selectedClientEventScoreSummary.isComplete,
          isEnrolled: selectedClientEventScoreSummary.isEnrolled,
          PHQ: {
            score: selectedClientEventScoreSummary.PHQ,
            severity: PHQSeverity,
            status: getStatus(
              clientEventData,
              selectedClientEventScoreSummary.PHQ,
              PHQSeverity,
              "PHQ",
            ),
          },
          GAD: {
            score: selectedClientEventScoreSummary.GAD,
            severity: GADSeverity,
            status: getStatus(
              clientEventData,
              selectedClientEventScoreSummary.GAD,
              GADSeverity,
              "GAD",
            ),
          },
          cohesion: selectedClientEventScoreSummary.cohesion,
          endorsedSI: selectedClientEventScoreSummary.endorsedSI,
        };
        clientEventData.push(parsedClientEvent);
      }
    },
  );
  return clientEventData;
};

const getPHQSeverity = (
  score: number | null | undefined,
): string | undefined => {
  if (score === undefined || score === null) {
    return undefined;
  } else if (score <= 4) {
    return "Minimal";
  } else if (score >= 5 && score <= 9) {
    return "Mild";
  } else if (score >= 10 && score <= 14) {
    return "Moderate";
  } else if (score >= 15 && score <= 19) {
    return "Moderately Severe";
  } else if (score >= 20 && score <= 27) {
    return "Severe";
  }
  return "Score not in range";
};

const getGADSeverity = (
  score: number | null | undefined,
): string | undefined => {
  if (score === undefined || score === null) {
    return undefined;
  } else if (score <= 4) {
    return "Minimal";
  } else if (score >= 5 && score <= 9) {
    return "Mild";
  } else if (score >= 10 && score <= 14) {
    return "Moderate";
  } else if (score >= 15 && score <= 21) {
    return "Severe";
  }
  return "Score not in range";
};

const getStatus = (
  clientEventScores: ParsedClientEventMbcScoresAndSeverities[],
  score: number | null | undefined,
  severity: string | undefined,
  scoreType: string,
): string | undefined => {
  if (clientEventScores.length === 0 || score === undefined || score === null) {
    return undefined;
  }

  const lastElementIndex = clientEventScores.length - 1;

  if (
    severity === "Minimal" &&
    clientEventScores.length >= 2 &&
    clientEventScores[lastElementIndex][scoreType].severity === "Minimal" &&
    clientEventScores[lastElementIndex - 1][scoreType].severity === "Minimal"
  ) {
    return "Remission";
  } else if (
    severity !== "Minimal" &&
    clientEventScores.length >= 4 &&
    severity === clientEventScores[lastElementIndex][scoreType].severity &&
    severity === clientEventScores[lastElementIndex - 1][scoreType].severity &&
    severity === clientEventScores[lastElementIndex - 2][scoreType].severity &&
    severity === clientEventScores[lastElementIndex - 3][scoreType].severity
  ) {
    return "Stagnation";
  } else if (
    score > clientEventScores[lastElementIndex][scoreType].score &&
    severity !== clientEventScores[lastElementIndex][scoreType].severity
  ) {
    return "Escalate";
  } else if (
    score < clientEventScores[lastElementIndex][scoreType].score &&
    severity !== clientEventScores[lastElementIndex][scoreType].severity
  ) {
    return "Improvement";
  }
  return undefined;
};

export const getLastCohesionScoreAndDate = (
  clientEventScores:
    | ParsedClientEventMbcScoresAndSeverities[]
    | null
    | undefined,
  timezone: string,
): string | undefined => {
  if (!clientEventScores?.length) {
    return undefined;
  }
  const lastScoreWithCohesion = clientEventScores
    .slice()
    .reverse()
    .find((score) => score.cohesion !== undefined && score.cohesion !== null);
  if (lastScoreWithCohesion) {
    const formattedDate = DateTime.fromISO(lastScoreWithCohesion.eventDate)
      .setZone(timezone)
      .toLocaleString({
        month: "short",
        day: "numeric",
      });
    return (
      "Scored " +
      lastScoreWithCohesion.cohesion?.toFixed(0) +
      "/7 on " +
      formattedDate
    );
  }
  return undefined;
};

export const getLatestPHQOrGADStatus = (
  eventScores: ParsedClientEventMbcScoresAndSeverities[] | null,
  PHQOrGad: string,
): string | undefined => {
  return eventScores?.length &&
    eventScores[eventScores?.length - 1]
    ? eventScores[eventScores?.length - 1]?.[
        PHQOrGad
      ]?.status
    : undefined;
};
