import { useQuery } from "react-query";
import api from "@/api";
import { FIVE_MINUTES } from "@/app/_helpers/constants";

export type HighAcuityClinicianFitList = {
  full_name: string;
  id: number;
  high_acuity_clients: number;
  is_network: boolean;
  manager_name: string;
  tenure: string;
  ha_eligibility_dict: {
    capacity: boolean;
    tenure: boolean;
    n_high_acuity: boolean;
  };
};

export type HighAcuityFitListParams = {
  matchId: number;
  clinicianIds: number[];
  enabled: boolean;
};

const getHighAcuityFitList = async (
  matchId: number,
  clinicianIds: number[],
): Promise<HighAcuityClinicianFitList[]> => {
  try {
    const { data } = await api.get(
      `/ehr/matches/v2/${matchId}/high_acuity_fit_list/?clinician_ids=${clinicianIds.join(
        ",",
      )}`,
    );

    return data;
  } catch (e) {
    throw new Error(
      `[getHighAcuityFitList] Unable to fetch high acuity fit list.`,
      { cause: e as Error },
    );
  }
};

export type ExpandedHighAcuityClinicianFitList = HighAcuityClinicianFitList & {
  is_ha_eligible: boolean;
};

const selectGetHighAcuityFitList = (data: HighAcuityClinicianFitList[]) => {
  const expandedFitList: ExpandedHighAcuityClinicianFitList[] = [];
  for (let d of data) {
    const clinicianIsHAEligible =
      d.ha_eligibility_dict.capacity &&
      d.ha_eligibility_dict.n_high_acuity &&
      d.ha_eligibility_dict.tenure;
    (d as ExpandedHighAcuityClinicianFitList).is_ha_eligible = clinicianIsHAEligible;
    expandedFitList.push(d as ExpandedHighAcuityClinicianFitList);
  }

  return expandedFitList;
};

export const useGetHighAcuityFitList = ({
  matchId,
  clinicianIds,
  enabled,
}: HighAcuityFitListParams) => {
  return useQuery(
    ["HighAcuityFitList", matchId, clinicianIds],
    () => getHighAcuityFitList(matchId, clinicianIds),
    {
      select: (data) => selectGetHighAcuityFitList(data),
      staleTime: FIVE_MINUTES,
      enabled,
    },
  );
};
