import { EventApiData } from "../../../../../../../../../../api/use-my-clients-overview/my-clients-data.interface";
import { Stack } from "../../../../../../../../../../design-system";
import { SessionCadence } from "../../../../schedule-page.interface";
import { SessionCard } from "../../../3-session-card/session-card";

interface RecentSessionsProps {
  sessionsAttendedInLast2Days: EventApiData[];
}

export const RecentSessions = ({
  sessionsAttendedInLast2Days,
}: RecentSessionsProps) => {
  return (
    <Stack alignItems="flex-start" gap={16}>
      {sessionsAttendedInLast2Days.map((event) => (
        <SessionCard
          key={event.eventId}
          event={event}
          sessionCadence={SessionCadence.Biweekly}
          isEditable={false}
          isRecent
        />
      ))}
    </Stack>
  );
};
