import { HomeOutlined, PhoneOutlined } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Tooltip } from "antd";
import moment from "moment";
import * as React from "react";
import * as ReactRedux from "react-redux";
import styled from "styled-components";
import {
  EventDelivery,
  EventMbcStatus,
  PanelClient,
  PanelClinicianWeek,
  PanelSession,
} from "../../api/types";
import {
  $antdBorder,
  $blue,
  $green,
  $grey,
  $greyText,
} from "../../assets/colors";
import { AppState } from "../../state/models";
import Mangle from "../_shared/Mangle";
import { ModalContext, ModalProviderStore } from "./modals/CellModalProvider";
import {
  CadenceEnum,
  ClientMetaMap,
  ClientStatus,
  ClientStatusEnum,
  ClientToSessionsMap,
  ModalType,
  PanelSessionsMap,
} from "./types";
import {
  CellContainer,
  headerRowHeight,
  sessionBgColor,
  Triangle,
} from "./_shared";
import { DateTime } from 'luxon';
import { useTimezoneProvider } from '../_shared/TimezoneContext';

function getWeekFromWeekNumber(
  week: number,
  year: number,
): [moment.Moment, moment.Moment] {
  return [
    moment()
      .set({ isoWeekYear: year, isoWeek: week, hour: 0, minute: 0 })
      .startOf("week"),
    moment()
      .set({ isoWeekYear: year, isoWeek: week, hour: 0, minute: 0 })
      .endOf("week"),
  ];
}

function useOutsideClick(
  refs: Array<React.RefObject<HTMLElement>>,
  runHook: boolean,
  handler: (event: MouseEvent) => void,
) {
  const handlerRef = React.useRef(handler);
  const modalContext = React.useContext(ModalContext);
  React.useEffect(() => {
    handlerRef.current = handler;
  });
  React.useEffect(() => {
    if (!runHook) {
      return;
    }
    const eventListener = (event: MouseEvent) => {
      const target = event.target as Element;

      // Do nothing if the click is inside the cell or any associated modals and not a close button
      if (
        refs.some(ref => !!(ref.current && ref.current.contains(target))) &&
        !target.closest(".modal-close-button")
      ) {
        return;
      }

      if (!target.closest(".clickable-cell")) {
        modalContext.hideModal();
      }

      handlerRef.current(event);
    };
    document.addEventListener("mousedown", eventListener);
    return () => {
      document.removeEventListener("mousedown", eventListener);
    };
  }, [refs, runHook, modalContext]);
}

const handleClickFn = (
  modalContext: ModalProviderStore,
  modalType: ModalType,
  modalDataId: string,
  callback: () => void,
) => {
  return (e: React.MouseEvent) => {
    let target = e.target as Element;
    target = target.closest(".clickable-cell") as Element;
    modalContext.showModal(
      target.getBoundingClientRect() as DOMRect,
      modalType,
      modalDataId,
    );
    callback();
  };
};

interface StyledCellProps {
  active?: boolean;
}

interface WeekHeaderProps extends StyledCellProps {
  week: PanelClinicianWeek;
}

export function _WeekHeaderCell(props: WeekHeaderProps & WeekHeaderStateProps) {
  const { week } = props;
  const dates = getWeekFromWeekNumber(week.week_number, week.year);
  const [isActive, setActive] = React.useState(false);
  const ref = React.useRef(null);
  const modalContext = React.useContext(ModalContext);

  useOutsideClick([ref, modalContext.state.modalRef], isActive, () => {
    setActive(false);
  });

  const handleClick = handleClickFn(
    modalContext as ModalProviderStore,
    "week",
    week.id,
    () => setActive(true),
  );

  const sessionsNotCanceled = week.sessions.filter(
    id =>
      props.sessionsMap[id].appointment[0].appointment_status !== "canceled",
  );

  return (
    <HorizontalHeaderCell
      ref={ref}
      active={isActive}
      onClick={handleClick}
      hasContent={!!week.note_content}
      key={`${week.week_number}${week.year}`}
      className="clickable-cell"
    >
      <h5>
        Week {week.week_number}
        <span style={{ float: "right" }}>{week.year}</span>
      </h5>
      <h4 style={{ whiteSpace: "nowrap" }}>
        {dates[0].format("MMM D")} – {dates[1].format("MMM D")}
      </h4>
      <hr />
      <HeaderCellContent>
        <ContentRow>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <i>Sessions:</i>
            <span style={{ marginLeft: "0.5em" }}>
              {sessionsNotCanceled.length}
            </span>
          </div>
        </ContentRow>
        {week.consults && week.consults.length !== 0 && (
          <ContentRow>
            <span
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <i>Consults:</i> {week.consults.length}
            </span>
          </ContentRow>
        )}
      </HeaderCellContent>
    </HorizontalHeaderCell>
  );
}

interface WeekHeaderStateProps {
  clinicianId: number;
  clientMetaMap: ClientMetaMap;
  clientToSessionMap: ClientToSessionsMap;
  sessionsMap: PanelSessionsMap;
}

const weekHeaderMapStateToProps: ReactRedux.MapStateToProps<
  WeekHeaderStateProps,
  WeekHeaderProps,
  AppState
> = state => {
  return {
    clinicianId: state.panel.clinicianId!,
    clientMetaMap: state.panel.clientMetaMap,
    clientToSessionMap: state.panel.clientToSessionMap,
    sessionsMap: state.panel.sessionsMap,
  };
};

export const WeekHeaderCell = ReactRedux.connect(weekHeaderMapStateToProps)(
  _WeekHeaderCell,
);

const HeaderCellContent = styled.div`
  i {
    color: ${$greyText};
    text-decoration: none;
    font-style: normal;
  }
`;
const ContentRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface ClientCellProps extends StyledCellProps {
  client: PanelClient;
}

function _ClientHeaderCell(props: ClientCellProps & ClientHeaderStateProps) {
  const { client, clientMetaMap } = props;
  const thisMeta = clientMetaMap[client.id];
  const [isActive, setActive] = React.useState(false);
  const ref = React.useRef(null);
  const modalContext = React.useContext(ModalContext);
  useOutsideClick([ref, modalContext.state.modalRef], isActive, () => {
    setActive(false);
  });

  const handleClick = handleClickFn(
    modalContext as ModalProviderStore,
    "client",
    client.id.toString(),
    () => setActive(true),
  );

  const deliveryPreferenceIcon =
    client.hybrid_preference === "remote" ? (
      <Tooltip title="Prefers teletherapy">
        <PhoneOutlined />
      </Tooltip>
    ) : client.hybrid_preference === "hybrid" ? (
      <Tooltip title="Prefers hybrid">
        <HomeOutlined />
      </Tooltip>
    ) : (
      <Tooltip title="Open to either remote or hybrid">
        <HomeOutlined />
        <PhoneOutlined />?
      </Tooltip>
    );

  let metaElements = null;
  const clientStatus = thisMeta && thisMeta.status;
  if (clientStatus) {
    metaElements = (
      <ClientMetaContainer>
        <ClientStatusDiv clientStatus={clientStatus.state}>
          {ClientStatusEnum[clientStatus.state]} {deliveryPreferenceIcon}
        </ClientStatusDiv>
        <ClientCadence>{CadenceEnum[thisMeta.cadence_override]}</ClientCadence>
      </ClientMetaContainer>
    );
  }

  return (
    <ClientCellContainer
      ref={ref}
      active={isActive}
      hasContent={thisMeta && !!thisMeta.note_content}
      onClick={handleClick}
      key={`${client.id}`}
      className="clickable-cell"
    >
      <ClientCellName>
        <Mangle>
          <span style={{ textTransform: "capitalize" }}>
            {client.first_name} {client.last_name}
          </span>
        </Mangle>
        {metaElements}
      </ClientCellName>
    </ClientCellContainer>
  );
}

interface ClientHeaderStateProps {
  clientMetaMap: ClientMetaMap;
  clientToSessionMap: ClientToSessionsMap;
  sessionsMap: PanelSessionsMap;
}

const clientHeaderMapStateToProps: ReactRedux.MapStateToProps<
  ClientHeaderStateProps,
  ClientCellProps,
  AppState
> = state => {
  return {
    clientMetaMap: state.panel.clientMetaMap,
    clientToSessionMap: state.panel.clientToSessionMap,
    sessionsMap: state.panel.sessionsMap,
  };
};

export const ClientHeaderCell = ReactRedux.connect(clientHeaderMapStateToProps)(
  React.memo(_ClientHeaderCell),
);

interface CollapsedSessionCellProps {
  sessions: PanelSession[];
  doCollapse?: boolean;
}

function sessionHasContent(session: PanelSession) {
  if (session.meta && !!session.meta.note_content) {
    return true;
  }

  return false;
}

export function CollapsedSessionsCell(props: CollapsedSessionCellProps) {
  const { sessions } = props;
  const [isActive, setActive] = React.useState<boolean>(false);
  const ref = React.useRef(null);
  const modalContext = React.useContext(ModalContext);
  useOutsideClick([ref, modalContext.state.modalRef], isActive, () => {
    setActive(false);
  });

  const handleClick = (e: React.MouseEvent) => {
    let target = e.target as Element;
    target = target.closest(".clickable-cell") as Element;
    modalContext.showModal(
      target.getBoundingClientRect() as DOMRect,
      "session",
      sessions.map(s => s.id.toString()),
    );
    setActive(true);
  };

  return (
    <CollapsedSessionCellContainer
      ref={ref}
      active={isActive}
      onClick={handleClick}
      state={null}
      className="clickable-cell"
      hasContent={sessions.map(s => sessionHasContent(s)).some(e => e)}
    >
      <h5>+{sessions.length}</h5>
    </CollapsedSessionCellContainer>
  );
}

interface StyledSessionCellProps extends StyledCellProps {
  session: PanelSession;
}

export function SessionCell(props: StyledSessionCellProps) {
  const { session } = props;

  const timezone = useTimezoneProvider();
  const dateTime = DateTime.fromISO(session.start_time).setZone(timezone)
  const dateString = dateTime.toFormat("EEE M/dd");
  const timeString = dateTime.toFormat("h:mma");
  const [isActive, setActive] = React.useState<boolean>(false);
  const ref = React.useRef(null);
  const modalContext = React.useContext(ModalContext);
  useOutsideClick([ref, modalContext.state.modalRef], isActive, () => {
    setActive(false);
  });

  const sessionMBCData = ReactRedux.useSelector(
    (state: AppState) => state.panel.eventMBCStatusesMap[session.event_id],
  );
  const activeEventUrl = ReactRedux.useSelector(
    (state: AppState) => state.panel.urlEventId,
  );
  const handleClick = handleClickFn(
    modalContext as ModalProviderStore,
    "session",
    session.id.toString(),
    () => setActive(true),
  );

  React.useEffect(() => {
    if (ref?.current && activeEventUrl && activeEventUrl === session.event_id) {
      // @ts-ignore
      // eslint-disable-next-line no-unused-expressions
      ref.current?.click();
      console.log(ref, activeEventUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  const event = session.event;
  const delivery = event?.delivery;

  return (
    <SessionCellContainer
      state={session.appointment[0].appointment_status}
      ref={ref}
      active={isActive}
      onClick={handleClick}
      hasContent={sessionHasContent(session)}
      className="clickable-cell"
    >
      <div style={{ display: "flex", width: '100%', marginRight: '16px' }}>
        <h5>{dateString}</h5>
        <div style={{ marginLeft: "auto" }}>
          <DeliveryIcon delivery={delivery} />
        </div>
      </div>
      <span style={{ fontSize: "11px", textTransform: "uppercase" }}>
        {timeString}
      </span>
      <MBCStatusTag
        statuses={sessionMBCData}
        panelClientId={session.appointment[0]?.client.id || 0}
      />
    </SessionCellContainer>
  );
}

const DeliveryIconSpan = styled.span`
  i {
    vertical-align: 0;
  }
`;

const DeliveryIcon: React.FC<{ delivery: EventDelivery }> = ({ delivery }) => (
  <DeliveryIconSpan style={{ opacity: 0.75, }}>
    {delivery === "in_person" ? (
      <Tooltip title="Session conducted in-person.">
        <LegacyIcon type="house" />
      </Tooltip>
    ) : delivery === "teletherapy" ? (
      <Tooltip title="Session conducted virtually.">
        <PhoneOutlined />
      </Tooltip>
    ) : null}
  </DeliveryIconSpan>
);

type MBCState = undefined | "incomplete" | "completed";
const MBCStatusIndicator = (props: { state: MBCState }) => {
  return <Circle state={props.state} />;
};
const emsToState = (ems: EventMbcStatus | undefined): MBCState =>
  ems ? (ems.assessment_responded_at !== null ? "completed" : "incomplete") : undefined;

const MBCStatusTag = (props: {
  statuses: EventMbcStatus[];
  panelClientId: number;
}) => {
  const [hover, setHover] = React.useState<boolean>(false);
  const isCouples = React.useMemo(
    () => props.statuses?.some(ems => ems.client !== props.panelClientId),
    [props.statuses, props.panelClientId],
  );
  if (!props.statuses) {
    return null;
  }

  const statusIndicators: React.ReactElement[] = [];

  if (isCouples) {
    // Pre Session Client A
    statusIndicators.push(
      <MBCStatusIndicator
        key="presession_a"
        state={emsToState(
          props.statuses.find(
            ems =>
              ems.client === props.panelClientId &&
              ems.assessment_type === "pre-session",
          ),
        )}
      />,
    );
    // Pre Session Client B
    statusIndicators.push(
      <MBCStatusIndicator
        key="presession_b"
        state={emsToState(
          props.statuses.find(
            ems =>
              ems.client !== props.panelClientId &&
              ems.assessment_type === "pre-session",
          ),
        )}
      />,
    );
    // Spacer
    statusIndicators.push(<MBCStatusIndicator state={undefined} />);
  } else {
    // Pre Session
    statusIndicators.push(
      <MBCStatusIndicator
        key="presession"
        state={emsToState(
          props.statuses.find(
            ems =>
              ems.client === props.panelClientId &&
              ems.assessment_type === "pre-session",
          ),
        )}
      />,
    );
  }
  return (
    <MBCTagContainer
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      isHover={hover}
    >
      <span>MBC</span>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "0 2px",
        }}
      >
        {statusIndicators}
      </div>
    </MBCTagContainer>
  );
};

const Circle = styled.div<{ state: MBCState }>`
  box-sizing: content-box;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  border: 1px solid black;

  background-color: rgba(0, 0, 0, 0);
  margin: 0 1px;

  flex: 50%;

  ${p => {
    switch (p.state) {
      case "completed":
        return `border: 1px solid rgba(0, 0, 0, 1); background-color: rgba(0, 0, 0, 0.8)`;
      case "incomplete":
        return ``;
      default:
        return `opacity: 0;`;
    }
  }}
`;

const MBCTagContainer = styled.div<{ isHover?: boolean }>`
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 4px;
  font-size: 10px;
  line-height: 20px;
  transition: all 200ms ease;

  margin-top: auto;
  padding: 0px;

  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  > span {
    width: 0;
    overflow: hidden;
    transition: all 200ms ease;
  }

  ${p =>
    p.isHover &&
    `
      border: 1px solid rgba(0, 0, 0, 0.35);
      padding: 0 4px;
      > span {
        width: initial;
      }
  `}
`;

export const HorizontalHeaderCell = styled(CellContainer)`
  height: ${headerRowHeight};
  position: sticky;
  top: 0;
  z-index: 10;

  h5 {
    color: ${$greyText};
    margin-bottom: 0;
  }

  h4 {
    display: flex;
  }

  hr {
    border-top: 1px solid ${$antdBorder};
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
  }
  ${p => (p.hasContent ? Triangle : "")};
`;

const ClientCellContainer = styled(CellContainer)`
  max-width: 144px;
  overflow: hidden;
  ${p => (p.hasContent ? Triangle : "")};
`;

const ClientCellName = styled.div`
  font-size: 11pt;
  font-weight: 600;
  white-space: nowrap;
`;

const ClientMetaContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ClientCadence = styled.div`
  font-size: 9pt;
  text-transform: capitalize;
  color: ${$greyText};
`;

interface ClientStatusProps {
  clientStatus: ClientStatus;
}
const ClientStatusDiv = styled.div<ClientStatusProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 10pt;
  font-weight: 600;
  margin: 2px 0;
  color: ${p => {
    switch (p.clientStatus) {
      case "active":
        return $green;
      case "inactive":
        return $greyText;
      case "pending":
        return $blue;
      case "terminated":
        return $grey;
    }
  }};

  > i {
    color: initial;
    line-height: initial;
  }
`;

interface StyledSessionCellContainerProps {
  active: boolean;
  state: "attended" | "canceled" | "noshow" | null | string;
  hasContent?: boolean;
}

const SessionCellContainer = styled.div<StyledSessionCellContainerProps>`
  position: relative;
  z-index: 2;
  overflow: hidden;
  padding: 4px;
  flex: 1;
  white-space: nowrap;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: ${props => sessionBgColor(props.state)};

  h5 {
    margin-bottom: 0;
  }

  > span {
    font-size: 11px;
    color: ${$greyText};
  }

  transition: all 200ms ease;

  ${p =>
    p.active &&
    `
      z-index: 20 !important;
      box-shadow: 4px 4px 16px 2px rgba(0, 0, 0, 0.35);
      flex: 3;
      &:hover {
        flex: 3 !important;
      }
  `}

  ${p => (p.hasContent ? Triangle : "")};

  &:hover {
    flex: 2;
  }

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  place-items: flex-start;
`;

const CollapsedSessionCellContainer = styled(SessionCellContainer)`
  background: ${sessionBgColor("canceled")};
  border: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 2;
  padding: 4px 2px;
  flex: 0.15;

  ${p => (p.hasContent ? Triangle : "")};

  span {
    font-size: 11px;
    color: ${$greyText};
  }

  transition: all 200ms ease;

  ${p =>
    p.active &&
    `
      z-index: 20 !important;
      box-shadow: 4px 4px 16px 2px rgba(0, 0, 0, 0.35);
  `}

  &:hover {
    flex: 0.15 !important;
  }
`;
