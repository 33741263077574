import { useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Flex, Spinner, styledStitches, Text } from "@/app/design-system";
import { QuarterCircle } from "@/app/design-system";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/app/design-system/dropdown/dropdown.styled";
import {
  DownChevronIcon,
  LeftChevronIcon,
  RightChevronIcon,
} from "@/app/design-system/icons";
import { StyledSortDropdownButton } from "@/app/my-clients/routes/my-clients-page/components/client-pane/client-pane-sort-dropdown/client-pane-sort-dropdown";
import { useGroupTherapyOverview } from "../../../../../_api/use-group-therapy-overview/use-group-therapy-overview.api";
import { useGroupTherapyUser } from "../../../../../_hooks/use-group-therapy-user";
import { selectSessionSelectorDropdownData } from "../../../../../_api/use-group-therapy-overview/use-group-therapy-overview.util";
import {
  SessionSelectorDropdownData,
  SessionSelectorDropdownEvent,
} from "../../../../../_api/group-therapy.interface";
import { TIMEZONE } from "@/app/my-clients/utils";
import { CurrentUserContext } from "@/app/app.utils";
import React from "react";

const getLinkPath = (
  eventId: string,
  clinicianId: number,
  tabName: string,
  groupId?: string,
) =>
  eventId
    ? `/groups/${groupId}/sessions/${eventId}/${tabName}?clinician_id=${clinicianId}`
    : "";

interface SessionSelectorDropdownOptionProps {
  clinicianId: number;
  eventId: string;
  eventNumberAndDate: string;
  isSelected: boolean;
  tabName: string;
  groupId?: string;
  onClick: () => void;
}

const SessionSelectorDropdownOption = ({
  clinicianId,
  eventId,
  eventNumberAndDate,
  isSelected,
  tabName,
  groupId,
  onClick,
}: SessionSelectorDropdownOptionProps) => {
  return (
    <DropdownMenuItem
      onClick={onClick}
      css={{ backgroundColor: isSelected ? "$neutral6" : undefined }}
      asChild
    >
      <Link to={getLinkPath(eventId, clinicianId, tabName, groupId)}>
        {eventNumberAndDate}
      </Link>
    </DropdownMenuItem>
  );
};

interface SessionSelectorDropdownProps {
  clinicianId: number;
  currentTab: string;
  groupId?: string;
  selectedEventNumberAndDate?: string;
  sessionSelectorDropdownData?: SessionSelectorDropdownData;
}

const SessionSelectorDropdown = ({
  clinicianId,
  currentTab,
  groupId,
  sessionSelectorDropdownData,
  selectedEventNumberAndDate,
}: SessionSelectorDropdownProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const tabName = currentTab ? currentTab : "notes";

  return (
    <SessionSelectorDropdownWrapper>
      <DropdownMenu open={isDropdownOpen}>
        <DropdownMenuTrigger
          asChild
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          <StyledSortDropdownButton css={{ display: "flex", height: 34 }}>
            <Text>{selectedEventNumberAndDate}</Text>
            <DownChevronIcon className="caret-icon" />
          </StyledSortDropdownButton>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          css={{ minWidth: 200 }}
          onInteractOutside={() => setIsDropdownOpen(false)}
          onEscapeKeyDown={() => setIsDropdownOpen(false)}
        >
          {sessionSelectorDropdownData?.eventsInGroup.map(
            ({ eventId, eventNumberAndDate }) => (
              <SessionSelectorDropdownOption
                clinicianId={clinicianId}
                eventId={eventId}
                eventNumberAndDate={eventNumberAndDate}
                groupId={groupId}
                tabName={tabName}
                isSelected={eventNumberAndDate === selectedEventNumberAndDate}
                key={eventId}
                onClick={() => setIsDropdownOpen(false)}
              />
            ),
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    </SessionSelectorDropdownWrapper>
  );
};

const SessionSelectorLeftArrow = ({ link }: { link: string }) => {
  const isDisabled = !link;
  return isDisabled ? (
    <DisabledIconWrapper>
      <LeftChevronIcon />
    </DisabledIconWrapper>
  ) : (
    <StyledLink to={link}>
      <LeftChevronIcon />
    </StyledLink>
  );
};

const SessionSelectorRightArrow = ({ link }: { link: string }) => {
  const isDisabled = !link;
  return isDisabled ? (
    <DisabledIconWrapper>
      <RightChevronIcon />
    </DisabledIconWrapper>
  ) : (
    <StyledLink to={link}>
      <RightChevronIcon />
    </StyledLink>
  );
};

const getMatchingEventIdx = (
  events?: SessionSelectorDropdownEvent[],
  selectedEventId?: string,
): number => {
  if (!events || !selectedEventId || !events.length) {
    return -1;
  }
  return events.findIndex((event) => event.eventId === selectedEventId);
};
const getPrevEventId = (
  events?: SessionSelectorDropdownEvent[],
  selectedEventId?: string,
): string => {
  const matchingIdx = getMatchingEventIdx(events, selectedEventId);
  if (!events || matchingIdx === -1 || matchingIdx === 0) {
    return "";
  }
  return events[matchingIdx - 1].eventId ?? "";
};

const getNextEventId = (
  events?: SessionSelectorDropdownEvent[],
  selectedEventId?: string,
): string => {
  const matchingIdx = getMatchingEventIdx(events, selectedEventId);
  if (!events || matchingIdx === -1 || matchingIdx === events.length - 1) {
    return "";
  }
  return events[matchingIdx + 1].eventId ?? "";
};

export const GroupTherapySessionSelector = () => {
  const cuser = React.useContext(CurrentUserContext);

  const { clinicianId } = useGroupTherapyUser();
  const { groupId, sessionId: eventId } =
    useParams<{ groupId: string; sessionId: string }>();

  const { pathname } = useLocation();
  const parts = pathname.split("/");
  const currentTabUrl = parts.pop() || parts.pop();
  const currentTab = currentTabUrl ? currentTabUrl : "notes";

  const {
    data: sessionSelectorDropdownData,
    isLoading,
    isError,
  } = useGroupTherapyOverview({
    clinicianId,
    select: (data) => selectSessionSelectorDropdownData(data, TIMEZONE(cuser), groupId),
    enabled: !!(clinicianId && groupId && eventId),
  });

  const selectedEvent = sessionSelectorDropdownData?.eventsInGroup.find(
    (event) => event.eventId === eventId,
  );
  const selectedEventNumberAndDate = selectedEvent?.eventNumberAndDate;

  const prevEventId = getPrevEventId(
    sessionSelectorDropdownData?.eventsInGroup,
    selectedEvent?.eventId,
  );
  const nextEventId = getNextEventId(
    sessionSelectorDropdownData?.eventsInGroup,
    selectedEvent?.eventId,
  );
  const leftArrowLink = getLinkPath(
    prevEventId,
    clinicianId,
    currentTab,
    groupId,
  );
  const rightArrowLink = getLinkPath(
    nextEventId,
    clinicianId,
    currentTab,
    groupId,
  );

  if (isLoading && !isError) {
    return (
      <Spinner>
        <QuarterCircle dimension={"2em"}></QuarterCircle>
      </Spinner>
    );
  }

  if (!isLoading && !isError && !!selectedEvent) {
    return (
      <GroupTherapySessionSelectorWrapper>
        <SessionSelectorLeftArrow link={leftArrowLink} />
        <SessionSelectorDropdown
          clinicianId={clinicianId}
          groupId={groupId}
          sessionSelectorDropdownData={sessionSelectorDropdownData}
          selectedEventNumberAndDate={selectedEventNumberAndDate}
          currentTab={currentTab}
        />
        <SessionSelectorRightArrow link={rightArrowLink} />
      </GroupTherapySessionSelectorWrapper>
    );
  }

  return null;
};

const SessionSelectorDropdownWrapper = styledStitches(Flex, {
  justifyContent: "center",
  py: 4,
  width: 208,
});

const iconWrapperStyles = {
  border: "1px solid $neutral9",
  borderRadius: "4px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  px: 16,
  py: 8,
};

const StyledLink = styledStitches(Link, {
  ...iconWrapperStyles,
  "&:active": {
    transform: "scale(0.975)",
  },
});

const DisabledIconWrapper = styledStitches(Flex, {
  ...iconWrapperStyles,
  cursor: "not-allowed",
  opacity: "30%",
});

const GroupTherapySessionSelectorWrapper = styledStitches(Flex);
