import { useEffect } from "react";
import { useMyClientsSearchStore } from "../../my-clients-model/my-clients.model";
import { useFilterClients } from "../use-my-clients-filter/use-my-clients-filter";
import { useSortMyClients } from "../use-my-clients-sort/use-my-clients-sort";
import { useMyClientsUser } from "../use-my-clients-user";
import { useClientOverviewInternal } from "./utils/use-client-overview.util";
import { useMatchingInfoByClientIdsInternal } from "./utils/use-matching-info-by-client-ids.util";
import { useMyClientsOverviewInternal } from "./utils/use-my-clients-overview.util";
import { useUserHasAnyPermissions } from "@/app/_helpers/permissions";
import { MyClientsClient } from "../../my-clients-model";

export const useMyClientsPage = () => {
  const { isClinicalLeaderOrSuperuser, clinicianId } = useMyClientsUser();

  const { clientIds, currentlySelectedClinician } = useMyClientsSearchStore();

  // 1. DETERMINE WHAT CLINICIAN TO QUERY BY FOR CLIENTS OVERVIEW
  // - currentlySelectedClinician is the clinician selected from <ClinicianPickerV2 />
  //     - since currentlySelectedClinician is only present when the logged in user SuperUser or a
  //       clinical leader (users that have administrative access), we default querying for the panel of
  //       the logged in clinician with clinicianId from useMyClientsUser();
  const clinicianIdToQueryBy = currentlySelectedClinician
    ? currentlySelectedClinician
    : clinicianId;

  // 2. QUERY FOR CLINICIAN'S PANEN OR CLIENTS RETURNED FROM SEARCH

  const isQualityBetaUser = useUserHasAnyPermissions(["IsQualityBetaUser"]);
  const IsCareEpisodesUser = useUserHasAnyPermissions(["IsCareEpisodesUser"]);
  const {
    isMyClientsOverviewLoading,
    isEocDataAvailable,
    myClientsOverviewData,
  } = useMyClientsOverviewInternal({
    clinicianIdToQueryBy,
    clientIds,
    isQualityBetaUser,
  });

  const { clientsOverviewData, isClientOverviewLoading } =
    useClientOverviewInternal({
      clientIds,
      clinicianId,
      currentlySelectedClinician,
      isClinicalLeaderOrSuperuser,
    });

  // 3. DETERMINE MATCHING DATA TO QUERY FOR - EITHER WHOLE PANEL OR ONE CLIENT
  // - if there are search results, we pass clientsOverviewData to useMatchingInfoByClientIdsInternal.
  // - otherwise, we default to myClientsOverviewData, which is the panel of the logged in clinician,
  //   or the panel of the currentlySelectedClinician
  const overviewData = clientsOverviewData ? null : myClientsOverviewData;

  const clientIdsToQueryMatchingDataBy = overviewData
    ?.filter((clientOverview) => clientOverview.clientId !== undefined)
    .map((clientOverview) => clientOverview.clientId);

  // 4. MAP MATCHING DATA TO THE CLIENT(S) WE QUERY FOR
  const { clientsMatchingData, isSuccess, isMatchingInfoLoading } =
    useMatchingInfoByClientIdsInternal({
      clinicianId,
      clientIdsToQueryMatchingDataBy:
        clientIdsToQueryMatchingDataBy as number[],
      currentlySelectedClinician,
    });

  const mappedClientsData = overviewData?.map((myClientsData) => ({
    ...myClientsData,
    matchingInfo:
      clientsMatchingData && myClientsData.clientId
        ? clientsMatchingData[myClientsData?.clientId]
        : null,
  }));

  // 5. FILTER CLIENTS BASED ON MY CLIENTS PAGE (/my-clients) FILTER STATE
  const filteredClientsData = useFilterClients(
    (mappedClientsData as MyClientsClient[]) ?? [],
  );

  // 5. FILTER CLIENTS BASED ON MY CLIENTS PAGE (/my-clients) SEARCH STATE
  const searchedClientsData =
    clientIds.length > 0 && !isClinicalLeaderOrSuperuser
      ? filteredClientsData.filter((client) =>
          clientIds.includes(client.clientId as number),
        )
      : filteredClientsData;

  // 6. SORT CLIENTS BASED ON MY CLIENTS PAGE (/my-clients) SORT STATE
  const sortedAndFilteredClientsData = useSortMyClients(
    searchedClientsData ?? [],
  );

  // 7. DETERMINE UI STATE
  const isLoading =
    isMyClientsOverviewLoading ||
    isClientOverviewLoading ||
    isMatchingInfoLoading;

  // 8. RUN SIDE EFFECTS
  useMyClientsSideEffects();

  return {
    isLoading,
    sortedAndFilteredClientsData,
    mappedClientsData,
    isClinicalLeaderOrSuperuser,
    isSuccess,
    isEocDataAvailable,
  };
};

function useMyClientsSideEffects() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
}
