import React from "react";
import styled from "styled-components";

import { $greyBorder } from "../../assets/colors";

interface GridHeaderProps {
  dayOfWeek: string;
  index: number;
}

/** /start common styled components */
const DottedBorder = `1px dotted ${$greyBorder}`;
const SolidBorder = `1px solid ${$greyBorder}`;

const HeaderSuper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 500;
`;

/** /end common styled components */

export const GridHeader = (props: GridHeaderProps) => {
  return (
    <GridHeaderContainer
      style={{ gridColumnStart: 2 + props.index * 2, gridColumnEnd: "span 2" }}
    >
      <div>{props.dayOfWeek}</div>
      <EvenOddContainer>
        <div>Even</div>
        <div>Odd</div>
      </EvenOddContainer>
    </GridHeaderContainer>
  );
};

const GridHeaderContainer = styled(HeaderSuper)`
  background-color: white;
  flex-direction: column;
  text-align: center;
  grid-row: 1;
  border-bottom: ${SolidBorder};
  position: sticky;
  top: 0;
  z-index: 10;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50%;
    width: 100%;
  }
`;

const EvenOddContainer = styled.div`
  display: flex;
  margin: 0;
  padding: 0;
  border-top: ${SolidBorder};

  > div {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &:first-of-type {
      border-right: ${DottedBorder};
    }
  }
`;

export const GridRowHeader = (props: { time: string; index: number }) => {
  return (
    <GridRowHeaderContainer
      style={{ gridRowStart: props.index, gridRowEnd: "span 1" }}
    >
      {props.time}
    </GridRowHeaderContainer>
  );
};

const GridRowHeaderContainer = styled(HeaderSuper)`
  grid-column: 1;
  border-right: ${SolidBorder};
`;

export const CellContainer = styled.div<{
  x: number;
  y: number;
  rows: number;
  disabled: boolean;
}>`
  position: relative;
  background-color: #aaa;

  grid-column-start: ${props => 2 * props.x + 2};
  grid-column-end: span 2;
  grid-row-start: ${props => props.y + 2};
  grid-row-end: span 1;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(${props => props.rows}, 1fr);

  &:after {
    content: '';
    position: absolute;
    right: 50%;
    border-right: ${DottedBorder};
    height: 99%; // prevents overlapping borders lol
    z-index: 1;
  }

  ${props =>
    props.disabled ? `
    background-color: ${$greyBorder} !important;
    opacity: 0.35;

    ` : ""}
`;
