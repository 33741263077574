import { DateTime } from "luxon";
import {
  EOCClinicianData,
  EOCClinicianSubEpisodeSummary,
  EOCEpisodeData,
} from "../../../../../types";
import { EOCClinicianModalTableRow } from "./eoc-clinician-modal-table-row";

export interface EOCClinicianModalTableRowsProps {
  eocs: Array<EOCEpisodeData>;
}

interface EOCTableRowClinicianSummary {
  clinician: EOCClinicianData;
  episodeClinicianSubEpisodesSummary: EOCClinicianSubEpisodeSummary;
}

export const EOCClinicianModalTableRows = (
  props: EOCClinicianModalTableRowsProps,
) => {
  const tableRowClinicianSummaries: Array<EOCTableRowClinicianSummary> = [];
  props.eocs.forEach((eoc: EOCEpisodeData) => {
    return [...eoc.clinicianDataMap.keys()].forEach(
      (eocClinicianKey: number) => {
        const eocClinician = eoc.clinicianDataMap.get(eocClinicianKey);
        if (eocClinician !== undefined) {
          [...eocClinician.subEpisodeSummaryMap.keys()].forEach((key) => {
            const subEpisodeSummary = eocClinician.subEpisodeSummaryMap.get(
              key as unknown as number,
            );
            if (subEpisodeSummary)
              tableRowClinicianSummaries.push({
                clinician: eocClinician,
                episodeClinicianSubEpisodesSummary: subEpisodeSummary,
              });
          });
        }
      },
    );
  });

  const sortedTableRowClinicianSummaries = [...tableRowClinicianSummaries].sort(
    (
      data1: EOCTableRowClinicianSummary,
      data2: EOCTableRowClinicianSummary,
    ) => {
      return DateTime.fromISO(
        data1.episodeClinicianSubEpisodesSummary.startDate,
      )
        .diff(
          DateTime.fromISO(data2.episodeClinicianSubEpisodesSummary.startDate),
          "days",
        )
        .toMillis();
    },
  );

  return (
    <>
      {sortedTableRowClinicianSummaries.map((clinicianSummary, index) => {
        return (
          <EOCClinicianModalTableRow
            subEpisodeSummary={
              clinicianSummary.episodeClinicianSubEpisodesSummary
            }
            eocClinician={clinicianSummary.clinician}
            isLastRow={index === sortedTableRowClinicianSummaries.length - 1}
          />
        );
      })}
    </>
  );
};
