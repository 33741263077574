import { styledStitches } from "@/app/design-system/styles/stitches.config";
import styled from "styled-components";
import * as Tabs from "@radix-ui/react-tabs";

export const TabsRoot = styledStitches(Tabs.Root, {
  flex: 1,
  flexGrow: 1,
  backgroundColor: "$neutral3",
  fontFamily: "Inter, arial",
  minWidth: 500,
  height: "100%",
});

export const TabsList = styledStitches(Tabs.List, {
  display: "flex",
  position: "relative",
});

export const TabsTrigger = styledStitches(Tabs.Trigger, {
  cursor: "pointer",
  width: 200,
  border: "none",
  position: "relative",
  backgroundColor: "none",
  background: "none",
  fontSize: 18,
  py: 20,

  fontWeight: 400,

  "&:hover": {
    color: "$neutral12",
  },

  '&[data-state="active"]': {
    transition: "font-weight 200ms",
    fontWeight: 700,
  },

  "&:disabled": {
    cursor: "not-allowed",
  },
});

export const TabsContent = styledStitches(Tabs.Content, {
  flexGrow: 1,
});

export const NavTabs = styledStitches("div", {
  position: "relative",
});

export const HoverTabHighlight = styledStitches("div", {
  backgroundColor: "$neutral5",
  position: "absolute",
  top: 10,
  left: 0,
  br: 4,
  height: "calc(100% - 18px)",
});

export const ActiveTabHighlight = styledStitches("div", {
  height: 8,
  width: 200,
  br: 8,
  top: "calc(100% - 8px)",
  backgroundColor: "black",
  position: "absolute",
});
