import { payloadAction, actionFactory, ActionUnion } from "reductser";
import produce from "immer";
import { assertUnreachable } from "../types";

const actionMap = {
  setClinicFilter: payloadAction<{ clinicId: number; toggle: boolean }>(),
  setStatusFilter: payloadAction<{ statusName: string; toggle: boolean }>(),
  setWeekdayFilter: payloadAction<{ day: string; toggle: boolean }>(),
};

export const filterActions = actionFactory(actionMap, "FILTERS");

export type FilterActions = ActionUnion<typeof filterActions>;

export interface FilterState {
  byClinic: { [id: number]: boolean };
  byStatus: { [statusName: string]: boolean };
  byWeekday: { [day: string]: boolean };
}

function getInitialState(): FilterState {
  return {
    byClinic: {},
    byStatus: {},
    byWeekday: {},
  };
}

const reducer = (state = getInitialState(), action: FilterActions) =>
  produce(state, (draft) => {
    if (action.reducer === "FILTERS") {
      switch (action.type) {
        case "setClinicFilter":
          draft.byClinic[action.payload.clinicId] = action.payload.toggle;
          return;
        case "setStatusFilter":
          draft.byStatus[action.payload.statusName] = action.payload.toggle;
          return;
        case "setWeekdayFilter":
          draft.byWeekday[action.payload.day] = action.payload.toggle;
          return;
        default:
          assertUnreachable(action);
      }
    }
  });

export default reducer;
