// We are intentionally ignoring these for this file
// For this particular case, we don't necessarily want users to know that we are attaching freshpaint
// whereas for actual UI elements we do
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FunctionComponent } from "react";
import { logFreshpaintEvent } from "@/app/utils/freshpaint.util";

export const FreshPaint: FunctionComponent<{
  eventName: string;
  properties?: object;
}> = ({ children, eventName, properties }) => {
  return (
    <div onClick={(e) => logFreshpaintEvent(eventName, properties)}>
      {children}
    </div>
  );
};

export default FreshPaint;
