import { useController, useFormContext } from "react-hook-form";
import {
  Flex,
  Label,
  Stack,
  Text,
} from "../../../../../../../../../../../design-system";
import { DeprecatedComposedReachUISelect } from "../../../../../../../../../../../design-system/select-reachui/composed-select-reachui";
import { BOX_SHADOW_PULSE_20PX } from "../../../../../../../../../../../design-system/styles/config/css-keyframe-constants";
import { UpsertSessionFormItemProps } from "./upsert-session-form-item.interface";

export const SessionTimeFormItem = ({
  name,
  options,
  id,
  mode,
}: UpsertSessionFormItemProps) => {
  const { control } = useFormContext();
  // register input
  const {
    field: { value, onChange, onBlur, ref },
    fieldState: { invalid, isDirty },
    formState: { errors },
  } = useController({
    name,
    control,
  });

  const sessionEndTime =
    value === "Select"
      ? null
      : `to ${
          options?.find((o) => o.value === value)?.label.slice(0, 3) +
          "50" +
          options?.find((o) => o.value === value)?.label.slice(5)
        }`;

  return (
    <Stack gap={10}>
      <Label required={mode === "add"}>Start time</Label>
      <Flex gap={16}>
        <DeprecatedComposedReachUISelect
          options={options ?? []}
          value={value}
          onChange={onChange}
          selectRootName={"Session start time"}
        />
        <Text
          css={{
            animation: `${BOX_SHADOW_PULSE_20PX} 1.75s infinite cubic-bezier(0.66, 0, 0, 1)`,
          }}
        >
          {sessionEndTime}
        </Text>
      </Flex>
    </Stack>
  );
};
