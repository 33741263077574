import styled from "styled-components";
import { screenReaderOnly } from "../design-system/styles/config/css-utils";

export const ScreenReaderOnlyText = styled.span`
  ${screenReaderOnly}
`;
/**
 * @name TooltipContentInTableScreenReaderOnlyText
 * @description Radix Tooltips don't work inside of tables, so we have to use SROText.
 * Differentiating this in case Radix-UI figures this out one day. This should match the ScreenReaderOnlyText component
 */
export const TooltipContentInTableScreenReaderOnlyText = styled.span`
  ${screenReaderOnly}
`;
