import { Text } from "recharts";

export const MBCGraphAxisTick = (props: any) => {
  const { x, y, payload, track } = props;
  let label = props.tickFormatter(payload.value);
  let range = "";
  let dy = 0;
  let dx = -10;
  let transform = "rotate(0)";

  if (isNaN(label)) {
    // transform = "rotate(-90)";
    dy = -50;
    dx = -30;
    const [_val, _range] = label.split("/");
    range = _range;
    label = _val;
  }

  if (track === "alliance") {
    dy = +40;
  }
  if (track === "swls") {
    // for swls, the labels are longer
    // so need adjust their offsets
    const value = payload.value;
    dx = -40;
    switch (value) {
      case 2:
        dy = +72;
        break;
      case 3:
        dy = +30;
        break;
      default:
        dy = +34;
        break;
    }
  }

  return (
    <g transform={`translate(${x + dx},${y + dy})`}>
      <Text
        x={0}
        y={0}
        width={100}
        textAnchor="middle"
        fill="#000"
        fontSize={14}
        transform={transform}
      >
        {label}
      </Text>
      {range && (
        <Text
          x={0}
          y={18}
          width={100}
          fontSize={12}
          textAnchor="middle"
          fill="#666"
          transform={transform}
        >
          {range}
        </Text>
      )}
    </g>
  );
};
