import { styledStitches } from "@/app/design-system";
import { QuestionAnswer } from "../../_api/use-group-therapy-client-overview/use-group-therapy-client.util";
import { Text } from "@/app/design-system";

const Answer = ({ answer }: { answer: string | null }) => {
  if (answer) {
    return <Text fontSize={16}>{answer}</Text>;
  }
  return (
    <NoResponseGivenText
      fontSize={16}
      color="$neutral9"
    >
      No response given
    </NoResponseGivenText>
  );
};

interface QuestionAndAnswerProps extends React.HTMLProps<HTMLLIElement> {
  questionAnswer: QuestionAnswer;
}

const QuestionAndAnswer = ({ questionAnswer }: QuestionAndAnswerProps) => {
  return (
    <StyledListItem css={{ maxWidth: 475 }}>
      <Text
        fontSize={16}
        color="$neutral11"
        css={{ marginBottom: 8 }}
      >
        {questionAnswer.question}
      </Text>
      <Answer answer={questionAnswer.answer} />
    </StyledListItem>
  );
};

interface CareBackgroundContentProps {
  careBackground: QuestionAnswer[];
}

export const CareBackgroundContent = ({ careBackground }: CareBackgroundContentProps) => {
  return (
    <StyledUnorderedList>
      {careBackground.map(questionAnswer => (
        <QuestionAndAnswer
          questionAnswer={questionAnswer}
          key={questionAnswer.question}
        />
      ))}
    </StyledUnorderedList>
  );
};

const NoResponseGivenText = styledStitches(Text, {
  backgroundColor: '$neutral2',
  padding: '12px 0',
  display: 'flex',
  justifyContent: 'center',
});

const StyledListItem = styledStitches('li', {
  marginBottom: 32,
});

const StyledUnorderedList = styledStitches('ul', {
  'list-style-type': 'none',
  paddingLeft: 20,
});
