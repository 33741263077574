import { DateTime } from "luxon";
import { Day, Time } from "../../api/types";

import { flatMap } from "lodash-es";

export const days: Day[] = ["Mo", "Tu", "We", "Th", "Fr"];
export const times: Time[] = [
  "07:00:00",
  "08:00:00",
  "09:00:00",
  "10:00:00",
  "11:00:00",
  "12:00:00",
  "13:00:00",
  "14:00:00",
  "15:00:00",
  "16:00:00",
  "17:00:00",
  "18:00:00",
  "19:00:00",
  "20:00:00",
];
export const generateDefaultDayTimes = () =>
  flatMap(times, (time) =>
    days.map((day) => {
      return {
        time,
        day,
      };
    }),
  );

export const fullDayMap = {
  Su: "Sunday",
  Mo: "Monday",
  Tu: "Tuesday",
  We: "Wednesday",
  Th: "Thursday",
  Fr: "Friday",
  Sa: "Saturday",
};

export const shortDayMap = {
  Su: "Sun",
  Mo: "Mon",
  Tu: "Tue",
  We: "Wed",
  Th: "Thu",
  Fr: "Fri",
  Sa: "Sat",
};

export const FIVE_MINUTES = 1000 * 60 * 5;
export const ONE_HOUR = 1000 * 60 * 60;
export const ONE_DAY = ONE_HOUR * 24;
export const ONE_WEEK = ONE_DAY * 7;

export enum SessionHistoryTimeRange {
  ALL_TIME = "All time",
  LAST_THIRTY_DAYS = "Last 30 days",
  LAST_THREE_MONTHS = "Last 3 months",
  LAST_SIX_MONTHS = "Last 6 months",
}

export const SessionHistoryTimeRangeStartDates = {
  "All time": null,
  "Last 30 days": DateTime.now().minus({ days: 30 }),
  "Last 3 months": DateTime.now().minus({ months: 3 }),
  "Last 6 months": DateTime.now().minus({ months: 6 })
}

export const PrivacyKey = "__IS_PRIVACY_ON"
