import React, { useState } from "react";
import { Button, Drawer, ScrollArea, Stack, Text } from "@/app/design-system";
import Mangle from "@/app/_shared/Mangle";
import { CancelClientGroupEventFormItems } from "./cancel-client-group-event-action/cancel-client-group-event-form";
import { useCancelClientGroupEventForm } from "../_api/use-cancel-client-group-event-form/use-cancel-client-group-event-form";
import { useNotification } from "@/app/design-system";
import { FormProvider } from "react-hook-form";
import { Form } from "@/app/design-system";
import { useUpdateClientGroupEvent } from "../_api/use-update-client-group-event";
import { useQueryClient } from "react-query";
import { CancellationType } from "../_api/use-fetch-cancellation-types-and-reasons/event-cancellation-types.constants";
import { useGroupTherapyOverview } from "@/app/groups/_api/use-group-therapy-overview/use-group-therapy-overview.api";
import { selectSessionNumberOfTotal } from "@/app/groups/_api/use-group-therapy-overview/use-group-therapy-overview.util";
import { useGroupTherapyUser } from "@/app/groups/_hooks/use-group-therapy-user";
import { useParams } from "react-router-dom";

interface CancelClientGroupEventActionProps {
  children: React.ReactNode;
  clientFirstName?: string;
  clientLastName?: string;
  clientGroupEventId: string;
  callback: () => void;
  canEditAttendance: boolean;
}

export const CancelClientGroupEventAction = ({
  children,
  clientFirstName,
  clientLastName,
  callback,
  clientGroupEventId,
  canEditAttendance,
}: CancelClientGroupEventActionProps) => {
  const queryClient = useQueryClient();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { clinicianId } = useGroupTherapyUser();
  const { groupId, sessionId: eventId } =
    useParams<{ groupId: string; sessionId: string }>();

  const { reset, isValid, handleSubmit, formMethods } =
    useCancelClientGroupEventForm();

  const { renderNotification } = useNotification();

  const updateClientGroupEventMutation = useUpdateClientGroupEvent();

  const { data: sessionNumber } = useGroupTherapyOverview({
    clinicianId,
    select: (data) => selectSessionNumberOfTotal(data, groupId, eventId),
    enabled: !!(clinicianId && groupId && eventId),
  });

  const submitForm = handleSubmit((data) => {
    updateClientGroupEventMutation.mutate(
      {
        clientGroupEventId,
        appointmentStatus:
          data.cancellationType === CancellationType.NO_SHOW
            ? "noshow"
            : "canceled",
        cancellationType: data.cancellationType,
        cancellationReason: data.cancellationReason,
        attendanceNote: data.attendanceNote,
      },
      {
        onError: () => {
          renderNotification({
            message: "There was an error cancelling this session.",
            notificationType: "error",
          });
        },
        onSuccess: () => {
          callback();
          queryClient.invalidateQueries();
          setIsDrawerOpen(false);
        },
      },
    );
  });

  return (
    <Drawer.Root
      onOpenChange={(open) => {
        if (!open) {
          reset();
        }

        setIsDrawerOpen(canEditAttendance && open);
      }}
      open={isDrawerOpen}
    >
      <Drawer.Trigger asChild>{children}</Drawer.Trigger>

      <Drawer.Content>
        <Drawer.Header title={"Edit client attendance"} />
        <ScrollArea.Root>
          <ScrollArea.Viewport>
            <Stack
              css={{ px: 20, py: 24, flex: 1 }}
              justifyContent="space-between"
            >
              <Stack gap={24}>
                <Text color={"$neutral11"}>
                  Session {sessionNumber || "attendance"} for{" "}
                  <Mangle>
                    {clientFirstName} {clientLastName}
                  </Mangle>
                </Text>

                <FormProvider {...formMethods}>
                  <Form>
                    <CancelClientGroupEventFormItems />
                  </Form>
                </FormProvider>
              </Stack>
            </Stack>
          </ScrollArea.Viewport>

          <ScrollArea.Scrollbar orientation="vertical">
            <ScrollArea.Thumb />
          </ScrollArea.Scrollbar>
        </ScrollArea.Root>

        <Drawer.Footer>
          <Drawer.Close asChild>
            <Button variant={"secondary"} size={"small"}>
              <Text fontWeight={700}>Exit without changes</Text>
            </Button>
          </Drawer.Close>

          <Button
            variant={"primary"}
            size={"small"}
            onClick={() => {
              if (!isValid) {
                renderNotification({
                  message:
                    "Please select an attendance status and cancellation reason.",
                  notificationType: "warning",
                });
              } else {
                submitForm();
              }
            }}
          >
            <Text fontWeight={700} color={"$neutral0"}>
              Save changes
            </Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Content>
    </Drawer.Root>
  );
};
