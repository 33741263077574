import { useMainNavigationUtils } from "../../../hooks/use-main-navigation-utils";

import { ReconciliationOutlined } from "@ant-design/icons";

import { NavigationMenuButton } from "../../components.utility/navigation-menu-button";

interface MatchAdminToolMenuItemProps {
  isCollapsed: boolean;
}
export function MatchAdminToolMenuItem({
  isCollapsed,
}: MatchAdminToolMenuItemProps) {
  const { setMatchAdminToolIsOpen } = useMainNavigationUtils();

  return (
    <NavigationMenuButton
      callback={setMatchAdminToolIsOpen}
      isCollapsed={isCollapsed}
      menuItemTooltipContent={"Match Admin Tool"}
    >
      <ReconciliationOutlined
        style={{ strokeWidth: 2, fontSize: 20 }}
        aria-hidden={!isCollapsed}
        title={!isCollapsed ? "" : "Match Admin Tool"}
      />
      {!isCollapsed ? "Match Admin Tool" : null}
    </NavigationMenuButton>
  );
}
