import React from "react";
import styled from "styled-components";
import {
  $border,
  $whiteHover,
  $green,
  $lightYellow,
  $secondary,
} from "../../../assets/colors";

export const BOX_WIDTH = "75px";
export const TABLE_WIDTH = "525px";

export const TableWrapper = styled.div`
  max-width: ${TABLE_WIDTH};
`;

export const AutoCenter = styled.div`
  margin: 0 auto;
  max-width: ${TABLE_WIDTH};
`;

export const BoxWrapper = styled.div`
  width: ${BOX_WIDTH};
  text-align: center;
  padding: 2.5px;
  border: 0.5px solid ${$border};
`;

export const ClickBoxWrapper = styled(BoxWrapper)`
  cursor: pointer;
  &:hover {
    background-color: ${$whiteHover};
  }
`;

export const BoxContent = styled.div`
  height: 100%;
  border-radius: 1px;
  padding: 3px 2px;
  text-align: center;
  margin: 0 auto;
`;

export type ActiveBoxColor = "green" | "yellow";
export type BoxColor = ActiveBoxColor | null;
export const ClickBox = ({
  onClick,
  boxColor,
  text,
}: {
  onClick: () => void;
  boxColor: BoxColor;
  text: string;
}) => (
  <ClickBoxWrapper onClick={onClick}>
    <BoxContent
      style={
        boxColor === "green"
          ? { backgroundColor: $green }
          : boxColor === "yellow"
          ? { backgroundColor: $lightYellow }
          : undefined
      }
    >
      {text}
    </BoxContent>
  </ClickBoxWrapper>
);

export const ClickHeader = ({
  onClick,
  coverage,
  label,
}: {
  onClick: () => void;
  coverage: "none" | "some" | "all";
  label: string;
}) => {
  const style: React.CSSProperties = {
    cursor: "pointer",
  };
  switch (coverage) {
    case "none":
      break;
    case "some":
      style.backgroundColor = $border;
      break;
    case "all":
      style.backgroundColor = $secondary;
      break;
  }
  return (
    <ClickBoxWrapper onClick={onClick}>
      <BoxContent style={style}>{label}</BoxContent>
    </ClickBoxWrapper>
  );
};
