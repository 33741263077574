import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { ClinicianHold, NewClinicianHold } from "../../api/types";
import {
  clinicianOperations,
  holdStatusSelector,
} from "../../state/models/clinicians";
import HoldModal, { HoldModalProps } from "../team/HoldModal";
import { useShallowEqualSelector } from "../_helpers/redux";
import { Button } from "@/app/design-system";

interface HoldButtonProps {
  clinicianId: number;
}

const HoldButton = (props: HoldButtonProps) => {
  const { clinicianId } = props;
  const dispatch = useDispatch();

  const { clinicianMap, scheduleMap, holdStatusMap } = useShallowEqualSelector(
    state => ({
      clinicianMap: state.clinicians.clinicianMap,
      scheduleMap: state.clinicians.scheduleMap,
      holdStatusMap: holdStatusSelector(state.clinicians),
    }),
  );

  const [showHoldModal, setShowHoldModal] = useState<boolean>(false);

  const clinician = clinicianMap[clinicianId];
  const clinicianHold = holdStatusMap[clinicianId];

  const holdModalProps: HoldModalProps = {
    clinician: { ...clinician, schedule: scheduleMap[clinicianId] },
    existingHold: clinicianHold,
    onClose: () => setShowHoldModal(false),
    addHold: (hold: NewClinicianHold) =>
      dispatch(clinicianOperations.addHold(hold)),
    updateHold: (hold: ClinicianHold) =>
      dispatch(clinicianOperations.updateHold(hold)),
    deleteHold: (hold: ClinicianHold) =>
      dispatch(clinicianOperations.deleteHold(hold)),
  };

  return (
    <>
      <Button
        variant="secondary"
        size="small"
        backgroundColor="transparent"
        onClick={e => {
          setShowHoldModal(true);
          e.stopPropagation();
        }}
      >
        {clinicianHold ? "Modify Hold" : "Add Hold"}
      </Button>
      {showHoldModal && <HoldModal {...holdModalProps} />}
    </>
  );
};

export default HoldButton;
