import React from "react";
import {
  TabsList,
  TabsTrigger,
  TabsRoot,
  TabsContent,
  HoverTabHighlight,
  ActiveTabHighlight,
} from "@/app/_components/navigation-tabs/navigation-tabs.styled";
import { useNavigationTabsStyling } from "@/app/_components/navigation-tabs/use-navigation-tabs-styling";
import { Flex, Stack, Text } from "@/app/design-system";
import { BasicInformation } from "./basic-information/basic-information";
import { CareBackground } from "./care-background/care-background";

const BASIC_INFO = "Basic Information";
const CARE_BACKGROUND = "Care Background";

export const GroupClientProfileActiveTabs = () => {
  const {
    wrapperRef,
    resetHighlight,
    highlightStyles,
    hoverHighlightRef,
    repositionHighlight,
    activeTab,
    repositionActiveHighlight,
    activeStyles,
  } = useNavigationTabsStyling(BASIC_INFO);

  const isBasicInfoHighlighted = activeTab === BASIC_INFO;

  return (
    <TabsRoot
      orientation="horizontal"
      defaultValue={activeTab}
      value={activeTab}
      css={{
        overflow: "auto",
        backgroundColor: "#fff",
        pt: 8,
      }}
    >
      <TabsList
        ref={wrapperRef}
        onMouseLeave={resetHighlight}
        css={{ borderBottom: "1px solid $neutral7" }}
      >
        <HoverTabHighlight ref={hoverHighlightRef} css={highlightStyles} />
        <ActiveTabHighlight css={{ width: 170, ...activeStyles }} />

        <Flex css={{ pl: 5 }}>
          <TabsTrigger
            value={BASIC_INFO}
            onMouseEnter={(e) => repositionHighlight(e, BASIC_INFO)}
            onClick={(e) => repositionActiveHighlight(e, BASIC_INFO)}
            css={{ width: 170 }}
          >
            <Text
              css={{
                fontWeight: isBasicInfoHighlighted ? 600 : 400,
                color: isBasicInfoHighlighted ? "$neutral12" : "$neutral11",
                fontSize: 18,
              }}
            >
              {BASIC_INFO}
            </Text>
          </TabsTrigger>
        </Flex>

        <Flex css={{ pl: 70 }}>
          <TabsTrigger
            value={CARE_BACKGROUND}
            onMouseEnter={(e) => repositionHighlight(e, CARE_BACKGROUND)}
            onClick={(e) => repositionActiveHighlight(e, CARE_BACKGROUND)}
            css={{ width: 170 }}
          >
            <Text
              css={{
                fontWeight: !isBasicInfoHighlighted ? 600 : 400,
                color: !isBasicInfoHighlighted ? "$neutral12" : "$neutral11",
                fontSize: 18,
              }}
            >
              {CARE_BACKGROUND}
            </Text>
          </TabsTrigger>
        </Flex>
      </TabsList>

      <Flex
        css={{
          flexGrow: 1,
        }}
      >
        <TabsContent value={BASIC_INFO}>
          <Stack>
            <BasicInformation />
          </Stack>
        </TabsContent>

        <TabsContent value={CARE_BACKGROUND}>
          <Stack>
            <CareBackground />
          </Stack>
        </TabsContent>
      </Flex>
    </TabsRoot>
  );
};
