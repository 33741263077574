import React from "react";

import {
  JointIntakeFormResponse,
} from "../../../../api/types";
import { useShallowEqualSelector } from "../../../_helpers/redux";
import { renderQuestionAndAnswer } from "./coupleIntakeResponseMapping";

interface Props {
  formResponse: JointIntakeFormResponse | undefined;
}

export default ({ formResponse }: Props) => {
  if (formResponse === undefined) {
    return null;
  }
  const { tagMap } = useShallowEqualSelector(state => state.matches);

  return (
    <div style={{ padding: "15px" }}>
      <h3>Couples Intake Form Response</h3>
      {renderQuestionAndAnswer(formResponse, tagMap)}
    </div>
  );
};
