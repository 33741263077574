import {
  useLogout,
  useMainNavigationUtils,
} from "../../../hooks/use-main-navigation-utils";

import { LockOpenIcon } from "@/app/design-system/icons";
import { LockClosedIcon } from "@/app/design-system/icons/components/lock-closed-icon";
import { LogoutIcon } from "@/app/design-system/icons/components/logout-icon";
import { useShallowEqualSelector } from "@/app/_helpers/redux";

import {
  NavigationMenuButton,
  NavigationMenuSwitchButton,
} from "../../components.utility/navigation-menu-button";
import { NavMenuButton } from "../../components.utility/main-nav.styled";
import { useCookies } from "react-cookie"
import { PrivacyKey } from "@/app/_helpers/constants";

interface PrivacyAndLogoutMenuItemsProps {
  isCollapsed: boolean;
}

export function PrivacyAndLogoutMenuItems({
  isCollapsed,
}: PrivacyAndLogoutMenuItemsProps) {
  const [doMangle] = useShallowEqualSelector((state) => [
    state.options.doMangle,
  ]);
  const { toggleMangle } = useMainNavigationUtils();
  const [cookies, setCookies] = useCookies([PrivacyKey])
  const isPrivacyOn = !!cookies[PrivacyKey]

  const { logout } = useLogout();

  return (
    <>
      <li>
        <NavigationMenuSwitchButton
          menuItemTooltipContent={`Privacy ${doMangle ? "on" : "off"}`}
          isCollapsed={isCollapsed}
          callback={toggleMangle}
        >
          <NavMenuButton
            value="privacy"
            css={{
              justifyContent: isCollapsed ? "center" : "flex-start",
              px: isCollapsed ? 13.5 : 16,
            }}
            onClick={() => {
              setCookies(PrivacyKey, !isPrivacyOn, { path: '/', domain: '.twochairs.com' })
              toggleMangle();
            }}
            role="switch"
            aria-checked={doMangle}
            aria-label="Toggle Privacy"
          >
            {doMangle ? <LockClosedIcon /> : <LockOpenIcon />}
            {!isCollapsed ? `Privacy ${doMangle ? "on" : "off"}` : null}
          </NavMenuButton>
        </NavigationMenuSwitchButton>
      </li>

      <li>
        <NavigationMenuButton
          menuItemTooltipContent="Logout"
          isCollapsed={isCollapsed}
          callback={() => logout()}
        >
          <LogoutIcon
            aria-hidden={!isCollapsed}
            title={!isCollapsed ? "" : "Logout"}
          />
          {!isCollapsed ? "Logout" : null}
        </NavigationMenuButton>
      </li>
    </>
  );
}
