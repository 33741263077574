import { Tabs } from "antd";
import moment from "moment";
import React from "react";
import { ClinicianWithSchedule, ScheduleItem } from "../../../api/types";
import { DATE_FORMAT } from "../../../state/models/slot-tool";
import ScheduleDraft from "./ScheduleDraft";
import ScheduleTableV2 from "./ScheduleTable";
import { generateWorkFromHomeObject } from "./util";

const Scheduler: React.FC<{ clinician: ClinicianWithSchedule }> = ({
  clinician,
}) => {
  const uniqueScheduleStartDates = Array.from(
    new Set(
      clinician.schedule
        .map((s) => moment(s.start_date))
        .sort()
        .reverse()
        .map((s) => s.format()),
    ),
  );

  // get only latest past schedule plus all future schedules
  const scheduleStartDates = uniqueScheduleStartDates
    .filter((d) => moment(d).isAfter(moment()))
    .map((d) => moment(d))
    .concat(
      moment.max(
        uniqueScheduleStartDates
          .filter((d) => moment(d).isSameOrBefore(moment()))
          .map((d) => moment(d)),
      ),
    );

  const scheduleBatches: Record<string, ScheduleItem[]> = {};

  scheduleStartDates.forEach((date) => {
    const schedulesOnThisDate: ScheduleItem[] = [];
    clinician.schedule.forEach((s) => {
      if (
        date.isBetween(
          s.start_date,
          s.end_date === null ? moment("3000-01-01") : s.end_date,
          undefined,
          "[)",
        )
      ) {
        schedulesOnThisDate.push(s);
      }
    });

    scheduleBatches[date.format()] = schedulesOnThisDate;
  });

  return (
    <>
      <h4>Active Schedule</h4>
      <Tabs>
        {Object.entries(scheduleBatches).map((value, index) => {
          const workingFromHome = generateWorkFromHomeObject(value[1]);
          return (
            <Tabs.TabPane
              tab={moment(value[0]).format(DATE_FORMAT)}
              key={index.toString()}
            >
              <ScheduleTableV2
                clinician={clinician}
                schedules={value[1]}
                workFromHomeDays={workingFromHome}
              />
            </Tabs.TabPane>
          );
        })}
      </Tabs>
      <h4 style={{ paddingTop: "32px" }}>Draft Schedules</h4>
      <ScheduleDraft clinician={clinician} />
    </>
  );
};

export default Scheduler;
