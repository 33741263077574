import { VN_COMPONENT_OVERLAY_INDEX } from "@/app/design-system/styles/config/css-constants";
import ExternalLink from "@/app/_shared/ExternalLink";
import { ScreenReaderOnlyText } from "@/app/_shared/ScreenReaderOnlyText";
import { DxCodeMap } from "../../../client/types";
import { CSS_COLORS, Flex, Text, RadixTooltip } from "../../../design-system";
import { ClipboardIcon } from "../../../design-system/icons/components/clipboard-icon";
import { CurrentUserContext, CurrentConfigContext } from "@/app/app.utils";
import React, { useContext } from "react";
import { userCanAccessHealthie } from "@/app/_helpers/permissions";
import { ClientOverviewApiData } from "@/app/api/use-my-clients-overview/my-clients-data.interface";

type DiagnosisCodeProps = {
  primaryDiagnosis: string | null | undefined;
  dxCodeMap: DxCodeMap;
  fontSize: 14 | 16;
  isFullDx?: boolean;
  emrLink?: string | null | undefined;
  emrLinkLabel?: string | null | undefined;
  client: ClientOverviewApiData | null;
};

/**
 * @component DiagnosisCode
 * @summary Used to display the diagnosis a clinician sets within Welkin
 * @prop {string} primaryDiagnosis
 * @prop {DxCodeMap} dxCodeMap
 * @prop {14|16} fontSize
 * @prop {boolean=} isFullDx
 * @prop {string=} emrLink
 * @prop {string=} emrLinkLabel If it's possible that we will have multiple diagnosis codes or welkinLinks on a page, we'll need to label these links.
 * For a user who is able to see or if we are viewing a specific client page,
 * it is clear where this link leads (based on visual groupings/context). However, for a screen-reader user that may be less obvious,
 * especially when they are viewing a list of links on the screen reader rotor.
 * */
export const DiagnosisCode = ({
  primaryDiagnosis,
  dxCodeMap,
  isFullDx,
  fontSize,
  emrLink,
  emrLinkLabel,
  client,
}: DiagnosisCodeProps) => {
  const cuser = React.useContext(CurrentUserContext);
  const config = React.useContext(CurrentConfigContext);

  const currentEmr = "Healthie"

  const getEmrJsxDx = (primaryDiagnosis: string) => {
    return (
      <RadixTooltip content={dxCodeMap[primaryDiagnosis]} delayDuration={100}>
        <Flex gap={4} alignItems="flex-start">
          {isFullDx && <ClipboardIcon fill={CSS_COLORS.neutral12} />}
          <Text fontSize={fontSize}>{primaryDiagnosis} </Text>

          {dxCodeMap[primaryDiagnosis] && isFullDx ? (
            <Text
              fontSize={fontSize}
              css={{
                "@breakpoint850": {
                  display: "none",
                },
              }}
            >
              -{" "}
            </Text>
          ) : null}

          {isFullDx && (
            <Text
              fontSize={fontSize}
              css={{
                "@breakpoint850": {
                  display: "none",
                },
              }}
            >
              <Text fontSize={fontSize}>{dxCodeMap[primaryDiagnosis]}</Text>
            </Text>
          )}
        </Flex>
      </RadixTooltip>
    );
  };

  const getEmrLink = (
    emrLink?: string | null,
    emrLinkLabel?: string | null,
  ) => {
    return (
      <Text fontSize={fontSize}>
        {emrLink ? (
          <ExternalLink
            href={emrLink}
            style={{
              color: "#2061DC",
              // DiagnosisCode is visually nested within another link (visually nested link - VNL) in the ClientPane row, which is why we need this z-index
              zIndex: VN_COMPONENT_OVERLAY_INDEX,
            }}
            textStyle={{
              textDecoration: "underline",
            }}
          >
            Assign Primary Diagnosis in {currentEmr}
            {emrLinkLabel ? (
              <ScreenReaderOnlyText>(for {emrLinkLabel})</ScreenReaderOnlyText>
            ) : null}
          </ExternalLink>
        ) : (
          <>Assign Primary Diagnosis in {currentEmr}</>
        )}
      </Text>
    );
  };

  return (
    <>
      {primaryDiagnosis
        ? getEmrJsxDx(primaryDiagnosis)
        : getEmrLink(emrLink, emrLinkLabel)}
    </>
  );
};
