import { AxiosResponse } from "axios";
import moment from "moment";

import { DATE_FORMAT, generateEmptyEventBuckets, slotToolActions } from ".";
import api, { wrapApiCall } from "../../../api";
import {
  BillableHoursWeek,
  ClientExtended,
  Clinician,
  CoupleExtended,
  Event,
  Recurrence,
  ScheduleItem,
  Time,
} from "../../../api/types";
import { fullDayMap } from "../../../app/_helpers/constants";
import { DateRange } from "../../../app/panel-management/types";
import {
  APIExcludedSlot,
  APIManualSlotReservation,
  ClientMap,
  ClinicianManualCapacityV2Data,
  ClinicianManualCapacityV2DataMap,
  ClinicianSchedule,
  ClinicianVolumeData,
  ClinicianVolumeDataMap,
  CoupleMap,
  CreateProcedureLimitPayload,
  DayOfWeek,
  daysArray,
  EventBuckets,
  EventMap,
  ExcludedSlot,
  ManualSlotReservation,
  OpenSlot,
  ProcedureLimit,
  SlotClosedSlot,
  SlotEvent,
  SlotOpenSlot,
  SlotReservationSlot,
} from "../../../app/slot-tool/types";
import { generateEmptyISOWeekMap } from "../dashboard";
import { getAmendedDateRange } from "../dashboard/operations";
import { convertToDay } from "../matchmaker/operations";
import { AsyncActionCreator, AsyncActionWithResultCreator } from "../types";
import { matchActions } from "../matches";
import { clinicianActions } from "../clinicians";
import { cloneDeep } from "lodash-es";

export const getClinicianVolumeData: AsyncActionWithResultCreator<
  ClinicianVolumeDataMap
> =
  (clinicianIds?: number[], excludeWheel?: boolean, excludeSlots?: boolean) =>
  async (dispatch, getState) => {
    const params: {
      clinician_ids?: string;
      open_slots_version?: number;
      exclude_wheel?: string;
      exclude_slots?: string;
    } = {};

    const searchParams = new URLSearchParams(window.location.search);

    if (clinicianIds) {
      params.clinician_ids = clinicianIds.join(",");
    }

    if (excludeWheel) {
      params.exclude_wheel = "true";
    }

    if (excludeSlots) {
      params.exclude_slots = "true";
    }

    if (searchParams.has("open_slots_version")) {
      params.open_slots_version = parseInt(
        searchParams.get("open_slots_version")!,
        10,
      );
    }

    const route = `/api/query/clinician_weekly_volumes/`;

    const existingMap: ClinicianVolumeDataMap = {
      ...getState().slottool.clinicianVolumeDataMap,
    };

    const obj: ClinicianVolumeDataMap = existingMap;

    const res: AxiosResponse<ClinicianVolumeData[]> = await wrapApiCall(
      api.get(route, { params }),
      dispatch,
    );
    const { data } = res;

    data.forEach((d) => (obj[d.clinician_id] = d));
    dispatch(slotToolActions.setClinicianVolumeDataMap(obj));

    return obj;
  };

export const getCliniciansManualCapacity: AsyncActionCreator =
  () => async (dispatch) => {
    const route = `/ehr/manualCapacitiesV2/mostRecent/`;
    const obj: ClinicianManualCapacityV2DataMap = {};

    const res: AxiosResponse<ClinicianManualCapacityV2Data[]> =
      await wrapApiCall(api.get(route, { params: { active: true } }), dispatch);
    const { data } = res;
    data.forEach((d) => (obj[d.clinician] = d));
    dispatch(slotToolActions.setClinicianManualCapacityDataMap(obj));
  };

export const upsertClinicianManualCapacity: AsyncActionWithResultCreator<
  AxiosResponse<ClinicianManualCapacityV2Data>
> =
  (
    clinicianId: number,
    capacity: number,
    autoCapacity: number,
    blockBiweekly: boolean,
    blockKpClients: boolean,
    note: string,
    couplesCapacity?: number,
    couplesAutoCapacity?: number,
  ) =>
  async (dispatch) => {
    const route = `/ehr/manualCapacitiesV2/upsertByClinician/`;
    const payload: Partial<ClinicianManualCapacityV2Data> = {
      clinician: clinicianId,
      capacity,
      auto_capacity: autoCapacity,
      block_biweekly: blockBiweekly,
      block_kp_clients: blockKpClients,
      note,
      set_at: moment().format(),
      couples_capacity: couplesCapacity,
      couples_auto_capacity: couplesAutoCapacity,
    };

    const res: AxiosResponse<ClinicianManualCapacityV2Data> = await wrapApiCall(
      api.post(route, { ...payload }),
      dispatch,
    );

    dispatch(getCliniciansManualCapacity());
    dispatch(matchActions.setShowAqmSuggestions(false));
    dispatch(matchActions.setShowHighAcuityAqmSuggestions(false));
    return res;
  };

type ConsultCapBody = Pick<Clinician, "consult_cap">;
export const upsertClinicianConsultCap: AsyncActionCreator =
  (clinicianId: number, cap: number) => async (dispatch) => {
    const route = `/api/clinicians/v1/${clinicianId}/caps/`;

    const payload: ConsultCapBody = { consult_cap: cap };
    const res: AxiosResponse<ConsultCapBody> = await wrapApiCall(
      api.patch(route, payload),
      dispatch,
    );

    dispatch(
      clinicianActions.patchClinician({ id: clinicianId, patch: res.data }),
    );
  };

export const getClinicianSchedule: AsyncActionCreator =
  (clinicianId: number) => async (dispatch) => {
    const days: Promise<AxiosResponse>[] = [];
    const returnSchedule: Partial<ClinicianSchedule> = {};
    const today = moment().add(1, "week");

    daysArray.forEach((day) => {
      const thisDay = today.day(day);
      const route = `/api/clinicianSchedules/v1/?clinician=${clinicianId}&at=${thisDay.format(
        DATE_FORMAT,
      )}`;
      days.push(wrapApiCall(api.get(route), dispatch));
    });

    const responses: AxiosResponse<ScheduleItem[]>[] = await Promise.all(days);

    responses.forEach((res, index) => {
      const { data } = res;
      const day = daysArray[index];

      if (data.length === 0) {
        returnSchedule[day] = null;
      } else {
        if (!returnSchedule[day]) {
          returnSchedule[day] = {
            dayOfWeek: day,
            hoursIncluded: [],
          };
        }

        data.forEach((schedule) => {
          const hoursInSchedule = [];
          const startTime = parseInt(schedule.start_time.split(":")[0]);
          const endTime = parseInt(schedule.end_time.split(":")[0]);
          for (let hr = startTime; hr < endTime; hr++) {
            hoursInSchedule.push(hr);
          }

          returnSchedule[day]!.hoursIncluded = [
            ...returnSchedule[day]!.hoursIncluded,
            ...hoursInSchedule,
          ];
        });
      }
    });

    dispatch(
      slotToolActions.setClinicianSchedule({
        clinicianId,
        schedule: returnSchedule as ClinicianSchedule,
      }),
    );
  };

export const addManualSlot: AsyncActionWithResultCreator<number> =
  (clinicianId: number, slot: OpenSlot) => async (dispatch, getState) => {
    const route = `/ehr/manualSlotsV2/add_slot/`;
    const modifiedSlot = {
      ...slot,
      day_of_week: convertToDay(slot.day_of_week),
    };
    const res = await api.post(route, { clinicianId, slot: modifiedSlot });

    if (res.status === 200) {
      const volumeDataPromise = dispatch(getClinicianVolumeData([clinicianId]));
      const newSlot = res.data;
      const day: DayOfWeek = fullDayMap[newSlot.day_of_week];
      const hour: Time = newSlot.start_time as Time;

      dispatch(
        slotToolActions.insertEventBucket({
          clinicianId,
          insert: {
            day,
            time: hour,
            slot: {
              time: hour,
              day,
              eventId: newSlot.id,
              recurrence: newSlot.recurrence,
              note: newSlot.note,
              start_date: newSlot.start_date,
              type: "manual_open",
            },
          },
        }),
      );

      const volumeData = await volumeDataPromise;
      const openSlots = volumeData[clinicianId!]?.slots_data?.open_slots;
      dispatch(
        slotToolActions.setEventBuckets({
          clinicianId,
          buckets: addOpenSlotsToBuckets(openSlots, {
            ...getState().slottool.eventBuckets,
          }),
        }),
      );
      dispatch(matchActions.setShowAqmSuggestions(false));
      dispatch(matchActions.setShowHighAcuityAqmSuggestions(false));
    }

    return res.status;
  };

export const expireManualSlot: AsyncActionCreator =
  (slotId: string) => async (dispatch, getState) => {
    const route = `/ehr/manualSlotsV2/${slotId}/expire/`;
    const { clinicianId } = getState().slottool;
    const res: AxiosResponse<APIExcludedSlot> = await api.post(route);

    if (res.status === 200) {
      const slot = res.data;
      const day = fullDayMap[slot.day_of_week];
      const hour = slot.start_time as Time;

      dispatch(
        slotToolActions.removeEventBucket({
          clinicianId,
          remove: { day, time: hour, id: slot.id },
        }),
      );
      dispatch(getClinicianVolumeData([clinicianId]));
      dispatch(matchActions.setShowAqmSuggestions(false));
      dispatch(matchActions.setShowHighAcuityAqmSuggestions(false));
    }
  };

export const addExclusionSlot: AsyncActionWithResultCreator<boolean> =
  (clinicianId: number, slot: ExcludedSlot) => async (dispatch, getState) => {
    const route = `/ehr/manualExclusions/add_slot/`;
    const modifiedSlot = {
      ...slot,
      day_of_week: convertToDay(slot.day_of_week),
    };

    const res = await api.post(route, { clinicianId, slot: modifiedSlot });

    dispatch(getExclusionSlots(clinicianId));

    if (res.status === 200) {
      // remove any existing open slots from the state if they exist
      try {
        const slotEvents: SlotEvent[] =
          getState().slottool.eventBuckets[slot.day_of_week][slot.start_time];
        for (const slotEvent of slotEvents) {
          if (slotEvent.type === "manual_open" || slotEvent.type === "open") {
            dispatch(
              slotToolActions.removeEventBucket({
                clinicianId,
                remove: {
                  day: slot.day_of_week,
                  time: slot.start_time,
                  id: slotEvent.eventId,
                },
              }),
            );
          }
        }
      } catch (e) {
        console.error(e);
      }

      dispatch(getClinicianVolumeData([clinicianId]));
      dispatch(matchActions.setShowAqmSuggestions(false));
      dispatch(matchActions.setShowHighAcuityAqmSuggestions(false));
    }
    return res.status === 200;
  };

export const getExclusionSlots: AsyncActionCreator =
  (clinicianId: number) => async (dispatch) => {
    const route = `/ehr/manualExclusions/get_by_clinician_id/`;
    const res: AxiosResponse<APIExcludedSlot[]> = await api.post(route, {
      clinicianId,
    });

    const { data: excludedSlots } = res;

    if (res.status === 200) {
      excludedSlots.forEach((slot) => {
        const day = fullDayMap[slot.day_of_week];
        const hour = slot.start_time as Time;
        dispatch(
          slotToolActions.insertEventBucket({
            clinicianId,
            insert: {
              day,
              time: hour,
              slot: {
                time: hour,
                day,
                eventId: slot.id,
                recurrence: slot.recurrence as Recurrence,
                note: slot.note,
                start_date: slot.start_date,
                type: "closed",
                expires_at: slot.expires_at,
              } as SlotClosedSlot,
            },
          }),
        );
      });
    }

    dispatch(slotToolActions.setExcludedSlots({ clinicianId, excludedSlots }));
  };

export const addReservationSlot: AsyncActionWithResultCreator<boolean> =
  (clinicianId: number, slot: ManualSlotReservation, timezone: string) =>
  async (dispatch, getState) => {
    const route = `/api/manualSlotReservations/v1/`;
    const modifiedSlot = {
      ...slot,
      slot_day_of_week: convertToDay(slot.slot_day_of_week),
    };

    const res = await api.post(route, { ...modifiedSlot });

    dispatch(getReservationSlots(clinicianId));

    // biweekly reservations have consequences for availability that has to be recomputed in the backend
    // therefore, we'll have to repull all of the data...
    if (slot.slot_recurrence === "biweekly") {
      const dateRange: DateRange = getState().slottool.dateRange;
      dispatch(getAndBucketEvents(clinicianId, dateRange, timezone));
    }

    return res.status === 200 || res.status === 201;
  };

export const expireReservationSlot: AsyncActionWithResultCreator<boolean> =
  (slotId: string, endDate: string) => async (dispatch, getState) => {
    const route = `/api/manualSlotReservations/v1/${slotId}/`;
    const { clinicianId } = getState().slottool;

    const slot = getState().slottool.reservedSlots.find((s) => s.id === slotId);
    if (!slot) {
      return false;
    }

    const res: AxiosResponse<APIManualSlotReservation> = await api.patch(
      route,
      {
        end_date: endDate === "null" ? null : endDate,
      },
    );

    if (res.status === 200) {
      const slot = res.data;
      const day = fullDayMap[slot.slot_day_of_week];
      const hour = slot.slot_time_of_day as Time;

      dispatch(
        slotToolActions.removeEventBucket({
          clinicianId,
          remove: { day, time: hour, id: slot.id },
        }),
      );
      dispatch(getReservationSlots(clinicianId));
    }

    return res.status === 200;
  };

export const getReservationSlots: AsyncActionCreator =
  (clinicianId: number) => async (dispatch) => {
    const route = `/api/manualSlotReservations/v1/?clinician_id=${clinicianId}`;
    const res: AxiosResponse<APIManualSlotReservation[]> = await api.get(route);

    const { data: slotReservations } = res;

    if (res.status === 200) {
      slotReservations.forEach((slot) => {
        if (slot.clinician !== clinicianId) {
          return;
        }
        const day = fullDayMap[slot.slot_day_of_week];
        const hour = slot.slot_time_of_day as Time;

        dispatch(
          slotToolActions.insertEventBucket({
            clinicianId,
            insert: {
              day,
              time: hour,
              slot: {
                id: slot.id,
                time: hour,
                day,
                eventId: slot.id,
                recurrence: slot.slot_recurrence as Recurrence,
                note: null,
                start_date: slot.start_date,
                end_date: slot.end_date,
                valid_until_date: slot.valid_until_date,
                type: "reservation",
                slot_type: slot.slot_type,
                label: slot.client ? `${slot.client.initials}` : undefined,
              } as SlotReservationSlot,
            },
          }),
        );
      });

      dispatch(
        slotToolActions.setReservedSlots({
          clinicianId,
          reservedSlots: slotReservations.filter(
            (s) => s.clinician === clinicianId,
          ),
        }),
      );
    }
  };

export const expireExcludedSlot: AsyncActionCreator =
  (slotId: string) => async (dispatch, getState) => {
    const route = `/ehr/manualExclusions/${slotId}/expire/`;
    const { clinicianId } = getState().slottool;
    const res: AxiosResponse<APIExcludedSlot> = await api.post(route);

    if (res.status !== 200) {
      return;
    }

    if (res.status === 200) {
      const volumeDataPromise = dispatch(getClinicianVolumeData([clinicianId]));
      const slot = res.data;
      let excludedSlots = [...getState().slottool.excludedSlots];
      const day = fullDayMap[slot.day_of_week];
      const hour = slot.start_time as Time;

      excludedSlots = excludedSlots
        ? excludedSlots.filter((s) => s.id !== slotId)
        : [];

      dispatch(
        slotToolActions.removeEventBucket({
          clinicianId,
          remove: { day, time: hour, id: slot.id },
        }),
      );
      dispatch(
        slotToolActions.setExcludedSlots({ clinicianId, excludedSlots }),
      );

      const volumeData = await volumeDataPromise;
      const openSlots = volumeData[clinicianId!]?.slots_data?.open_slots;
      dispatch(
        slotToolActions.setEventBuckets({
          clinicianId,
          buckets: addOpenSlotsToBuckets(openSlots, {
            ...getState().slottool.eventBuckets,
          }),
        }),
      );
      dispatch(matchActions.setShowAqmSuggestions(false));
      dispatch(matchActions.setShowHighAcuityAqmSuggestions(false));
    }
  };

export const getAndBucketEvents: AsyncActionCreator =
  (clinicianId: number, dateRange: DateRange, timezone: string) => async (dispatch, getState) => {
    dispatch(slotToolActions.startLoadingSlotTool());
    dispatch(getClinicianSchedule(clinicianId));

    const volumeDataPromise = dispatch(getClinicianVolumeData([clinicianId]));

    const startDate = dateRange.start.format("YYYY-MM-DD");
    const endDate = dateRange.end.format("YYYY-MM-DD");

    const route = `/api/events/v1/getBetweenDates/?deleted=false&canceled=false&clinician_id=${clinicianId}&start_date=${startDate}&end_date=${endDate}`;

    const res: AxiosResponse<Event[]> = await wrapApiCall(
      api.get(route),
      dispatch,
    );

    const { data } = res;

    let buckets: EventBuckets = generateEmptyEventBuckets();
    const eventMap: EventMap = {};
    const clientMap: ClientMap = {};
    const coupleMap: CoupleMap = {};

    data.forEach((event) => {
      if (event.appointment_status === "canceled") {
        return;
      }

      if (event.gcal_unique_id) {
        // ignore gcal events shorter than 20 mins
        if (
          Math.abs(moment(event.start_time).diff(event.end_time, "minutes")) <
          20
        ) {
          return;
        }
      }

      const startTime = moment(event.start_time).tz(timezone);
      const hour = startTime.format("HH:00:00") as Time; // ISO-8601 time ignoring minute and second
      const day = startTime.format("dddd") as DayOfWeek;
      try {
        buckets[day][hour].push({
          time: hour,
          day,
          eventId: event.id,
          type: "event",
        });
      } catch (error) {
        return;
      }

      eventMap[event.id] = event;
    });

    const volumeData = await volumeDataPromise;
    const openSlots = volumeData[clinicianId]?.slots_data?.open_slots ?? [];
    buckets = addOpenSlotsToBuckets(openSlots, buckets);

    const clientIds = Array.from(
      new Set(
        Object.values(eventMap)
          .filter((e) => e.client)
          .map((e) => e.client),
      ),
    );

    const coupleIds = Array.from(
      new Set(
        Object.values(eventMap)
          .filter((e) => e.couple)
          .map((e) => e.couple),
      ),
    );

    if (clientIds.length) {
      const clientsRoute = `/api/clients/v1/?ids=${clientIds.join(",")}`;

      const clientRes: AxiosResponse<ClientExtended[]> = await wrapApiCall(
        api.get(clientsRoute),
        dispatch,
      );
      const clients = clientRes.data;
      clients.forEach((client) => (clientMap[client.id] = client));
    }

    if (coupleIds.length) {
      const couplesRoute = `/api/couples/v1/?ids=${coupleIds.join(",")}`;

      const couplesRes: AxiosResponse<CoupleExtended[]> = await wrapApiCall(
        api.get(couplesRoute),
        dispatch,
      );
      const couples = couplesRes.data;
      couples.forEach((couple) => (coupleMap[couple.id] = couple));
    }

    const { utilizationDateRange } = getState().slottool;
    dispatch(slotToolActions.setEventMap({ clinicianId, eventMap }));
    dispatch(slotToolActions.setClientMap({ clinicianId, clientMap }));
    dispatch(slotToolActions.setCoupleMap({ clinicianId, coupleMap }));
    dispatch(slotToolActions.setEventBuckets({ clinicianId, buckets }));
    dispatch(getExclusionSlots(clinicianId));
    dispatch(getReservationSlots(clinicianId));
    dispatch(getBillableHours(clinicianId, utilizationDateRange));

    dispatch(slotToolActions.finishLoadingSlotTool({ clinicianId }));
  };

function addOpenSlotsToBuckets(slots: OpenSlot[], buckets: EventBuckets) {
  const clonedBuckets = cloneDeep(buckets);

  slots.forEach((slot) => {
    const day = fullDayMap[slot.day_of_week];
    const hour = slot.start_time as Time;
    try {
      const eventId = slot.type === "manual" ? slot.id : `open_${day}_${hour}`;
      if (
        clonedBuckets[day][hour].find((s: SlotEvent) => s.eventId === eventId)
      ) {
        return;
      }

      clonedBuckets[day][hour].push({
        time: hour,
        day,
        eventId,
        recurrence: slot.recurrence,
        note: slot.note,
        start_date: slot.start_date,
        type: slot.type === "manual" ? "manual_open" : "open",
      } as SlotOpenSlot);
    } catch (error) {
      return;
    }
  });

  return clonedBuckets;
}

const getBillableHours: AsyncActionCreator =
  (clinicianId: number | string, dateRange: DateRange) => async (dispatch) => {
    dispatch(slotToolActions.resetBillableHours());

    // at minimum, get a span from 3 months ago to current week for average-calculating purposes
    const { amendedDateRange, startDate, endDate } =
      getAmendedDateRange(dateRange);
    const route = `/api/query/utilization/billable_hours/?clinician=${clinicianId}&start=${startDate}&end=${endDate}`;
    const map = generateEmptyISOWeekMap(amendedDateRange);

    await wrapApiCall(api.get(route), dispatch).then(
      (res: AxiosResponse<BillableHoursWeek[]>) => {
        const { data } = res;
        data.forEach((week) => {
          const { isoweek, isoyear } = week;
          map[`${isoyear}-W${isoweek}`] = week;
        });

        dispatch(slotToolActions.setBillableHoursWeeks(map));
        return res;
      },
    );
  };

export const getProcedureLimits: AsyncActionCreator =
  () => async (dispatch) => {
    const route = `/ehr/procedureLimits/`;
    const cliniciansToIdsMap = {};

    await wrapApiCall(api.get(route), dispatch).then(
      (res: AxiosResponse<ProcedureLimit[]>) => {
        const { data } = res;

        data.forEach((obj) => {
          const existingArray = cliniciansToIdsMap[obj.clinician] || [];
          cliniciansToIdsMap[obj.clinician] = [...existingArray, obj.id];
        });

        dispatch(slotToolActions.setProcedureLimits(data));
        dispatch(
          slotToolActions.setClinicianProcedureLimitsMap(cliniciansToIdsMap),
        );
      },
    );
  };

export const createProcedureLimit: AsyncActionWithResultCreator<
  ProcedureLimit
> = (procedureLimit: CreateProcedureLimitPayload) => async (dispatch) => {
  const route = `/ehr/procedureLimits/`;
  const { clinician } = procedureLimit;

  const res: AxiosResponse<ProcedureLimit> = await wrapApiCall(
    api.post(route, { ...procedureLimit }),
    dispatch,
  );
  const { data } = res;

  dispatch(slotToolActions.setProcedureLimits([data]));
  dispatch(
    slotToolActions.setClinicianProcedureLimitsMap({ [clinician]: [data.id] }),
  );

  return data;
};

export const putProcedureLimit: AsyncActionWithResultCreator<ProcedureLimit> =
  (procedureLimit: ProcedureLimit) => async (dispatch) => {
    const route = `/ehr/procedureLimits/${procedureLimit.id}/`;
    const { clinician } = procedureLimit;

    const res: AxiosResponse<ProcedureLimit> = await wrapApiCall(
      api.put(route, { ...procedureLimit }),
      dispatch,
    );
    const { data } = res;

    dispatch(slotToolActions.setProcedureLimits([data]));
    dispatch(
      slotToolActions.setClinicianProcedureLimitsMap({
        [clinician]: [data.id],
      }),
    );

    return data;
  };
