// WE ARE TRYING TO MOVE AWAY FROM MOMENT
// Use the TimePicker.tsx instead
// TODO: https://twochairs.atlassian.net/browse/TC-6762
import {
  CalendarOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { DatePicker, Popover } from "antd";

import moment from "moment";
import * as React from "react";

import styled from "styled-components";
import { Button } from "../../app/_shared/antd";
import { $greyBorder } from "../../assets/colors";
import { DateRange } from "../panel-management/types";
import { StyledTimePickerButton } from "./TimePicker";

const PopoverContentContainer = styled.div`
  > .date-pickers {
    display: flex;
    > div {
      flex: 1 1;
      &:first-of-type {
        margin-right: 0.5em;
      }
    }
  }
`;

const WeekDelta = styled.div`
  margin-top: 0.5em;
  .weekdelta-arrow {
    padding: 1px 6px;
  }
  .weekdelta-text {
    flex: 1;
  }
`;

const { WeekPicker } = DatePicker;

export interface TimePickerProps {
  setDateRange: (dateRange: DateRange) => void;
  dateRange: DateRange;
  incrementDate: (
    date: "start" | "end",
    delta: number,
    dateRange: DateRange,
  ) => void;
}

export const incrementMomentDate = (
  date: "start" | "end",
  delta: number,
  dateRange: DateRange,
) => {
  const [ownDate, setOwnDate] = React.useState<DateRange>(dateRange);
  const { start, end } = ownDate;

  if (date === "start") {
    setOwnDate({
      ...ownDate,
      start: moment(start).add(delta, "weeks").startOf("week"),
    });
  } else {
    setOwnDate({
      ...ownDate,
      end: moment(end).add(delta, "weeks").endOf("week"),
    });
  }
};

export const DeprecatedTimePicker = (props: TimePickerProps) => {
  const [popoverVisible, setPopoverVisible] = React.useState<boolean>(false);
  const [ownDate, setOwnDate] = React.useState<DateRange>(props.dateRange);
  const { start, end } = ownDate;

  const apply = () => {
    props.setDateRange(ownDate);
    setPopoverVisible(false);
  };

  const incrementDate = (date: "start" | "end", delta: number) => {
    const { start, end } = ownDate;

    if (date === "start") {
      setOwnDate({
        ...ownDate,
        start: moment(start).add(delta, "weeks").startOf("week"),
      });
    } else {
      setOwnDate({
        ...ownDate,
        end: moment(end).add(delta, "weeks").endOf("week"),
      });
    }
  };
  const popoverContent = (
    <PopoverContentContainer>
      <div className="date-pickers">
        <div>
          <h5>Start Week</h5>
          <WeekPicker
            placeholder="Start Week"
            defaultValue={start}
            value={start}
            format={"ll"}
            onChange={(date: any) => setOwnDate({ ...ownDate, start: date! })}
            disabledDate={(date: any) => !!date && date.isAfter(end)}
          />
          <WeekDelta>
            <Button.Group style={{ display: "flex" }}>
              <Button
                className="weekdelta-arrow"
                onClick={() => incrementDate("start", -1)}
              >
                <LeftOutlined />
              </Button>
              <Button className="weekdelta-text">
                <span>{moment().diff(start, "week") * -1}&nbsp;weeks</span>
              </Button>
              <Button
                className="weekdelta-arrow"
                onClick={() => incrementDate("start", 1)}
                disabled={moment(start).add(1, "weeks").isAfter(end)}
              >
                <RightOutlined />
              </Button>
            </Button.Group>
          </WeekDelta>
        </div>
        <div>
          <h5>End Week</h5>
          <WeekPicker
            placeholder="End Week"
            defaultValue={end}
            value={end}
            format={"ll"}
            onChange={(date) => setOwnDate({ ...ownDate, end: date! })}
            disabledDate={(date) => !!date && date.isBefore(start)}
          />
          <WeekDelta>
            <Button.Group style={{ display: "flex" }}>
              <Button
                className="weekdelta-arrow"
                onClick={() => incrementDate("end", -1)}
                disabled={moment(end).subtract(1, "weeks").isBefore(start)}
              >
                <LeftOutlined />
              </Button>
              <Button className="weekdelta-text">
                <span>
                  {moment().endOf("week").diff(end, "week") * -1}
                  &nbsp;weeks
                </span>
              </Button>
              <Button
                className="weekdelta-arrow"
                onClick={() => incrementDate("end", 1)}
              >
                <RightOutlined />
              </Button>
            </Button.Group>
          </WeekDelta>
        </div>
      </div>
      <div style={{ marginTop: "1em", textAlign: "right" }}>
        <Button type="primary" onClick={apply}>
          Apply
        </Button>
      </div>
    </PopoverContentContainer>
  );

  return (
    <Popover
      trigger="click"
      open={popoverVisible}
      onOpenChange={setPopoverVisible}
      title="Change Date Range"
      content={popoverContent}
      placement="bottomLeft"
    >
      <StyledTimePickerButton>
        <CalendarOutlined
          style={{ color: $greyBorder, margin: "4px .5em 0 0" }}
        />
        <span>{props.dateRange.start.format("ll")}</span>~
        <span>{props.dateRange.end.format("ll")}</span>
      </StyledTimePickerButton>
    </Popover>
  );
};
