import { useEffect, useRef } from "react";
import { useNotification } from "@/app/design-system";
import {
  ParsedClientGroupEvent,
  ParsedGroupTherapyNotesEventAttendance,
} from "../_api/use-group-therapy-notes-event/use-group-therapy-notes-event.util";

const attendanceToastShownPrefix = "ATTENDANCE_TOAST_SHOWN_EVENT_ID_";

const getIsAllAttendanceFilledOut = (
  clientGroupEvents?: ParsedClientGroupEvent[],
) => {
  if (clientGroupEvents) {
    const eligibleClientGroupEvents = clientGroupEvents.filter(
      (clientGroupEvent) => !clientGroupEvent.clientUnenrolledDate,
    );
    return eligibleClientGroupEvents.every(
      (clientGroupEvent) => !!clientGroupEvent.appointmentStatus,
    );
  }
  return false;
};

export const useAllEventAttendanceToast = (
  eventAttendanceData?: ParsedGroupTherapyNotesEventAttendance,
) => {
  const { renderNotification } = useNotification();
  const isInitialDataFetchForSession = useRef(true);
  useEffect(() => {
    if (!eventAttendanceData) {
      isInitialDataFetchForSession.current = true;
    }
    if (!!eventAttendanceData) {
      const isAllAttendanceFilledOut = getIsAllAttendanceFilledOut(
        eventAttendanceData?.clientGroupEvents,
      );
      if (isAllAttendanceFilledOut && isInitialDataFetchForSession.current) {
        sessionStorage.setItem(
          `${attendanceToastShownPrefix}${eventAttendanceData?.eventId}`,
          "true",
        );
      }
      const wasAttendanceToastAlreadyShown = sessionStorage.getItem(
        `${attendanceToastShownPrefix}${eventAttendanceData?.eventId}`,
      );
      if (!wasAttendanceToastAlreadyShown) {
        // show the toast when all attendance is filled, but not if it is on initial data load (before the user makes changes)
        if (isAllAttendanceFilledOut && !isInitialDataFetchForSession.current) {
          renderNotification({
            headerText: "High five! Great work",
            message: "Attendance saved.  Another one in the books.",
            notificationType: "celebrate",
            size: "large",
          });
          sessionStorage.setItem(
            `${attendanceToastShownPrefix}${eventAttendanceData?.eventId}`,
            "true",
          );
        }
      }
      isInitialDataFetchForSession.current = false;
    }
  }, [eventAttendanceData]);
};
