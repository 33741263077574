import api from "@/api";
import { useQuery } from "react-query";
import { CurrentUser, UserGroup } from "@/api/types";

export const getCurrentUser = async (): Promise<any> => {
  const url = `/ehr/auth/logged_in_user`;

  try {
    const { data } = await api.get(url);
    const currentUser: CurrentUser = {
      username: data.username,
      email: data.email,
      groups: data.groups.map(
        (group: { name: string }) => group.name as UserGroup,
      ),
      permissions: data.permissions,
      first_name: data.first_name,
      last_name: data.last_name,
      clinician: data.clinician,
      isSuperUser: data.is_superuser,
      id: data.id,
    };
    return currentUser;
  } catch (e) {
    //given the way we are using this currently, an error (someone not being logged in) is an allowable outcome
    //we just return to avoid retries and thus rerenders
    return undefined;
  }
};

/**
 * @function useGetCurrentUser
 * @description
 */
export const useGetCurrentUser = () => {
  return useQuery({
    // like in useEffect, we pass in query keys as an array to react-query
    // and treat query keys as a dependency array. if the keys change, react-query
    // will automatically call the query function we pass to it.
    queryKey: ["currentUser"],
    // a query function can be any function that returns a promise.
    // the promise that is returned should either resolve the data or throw an error.
    queryFn: () => getCurrentUser(),
    staleTime: Infinity,
  });
};
