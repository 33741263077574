import { BaseSyntheticEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { Clinician } from "../../../api/types";
import { useMatch } from "../../design-system/combobox/search-combobox/search-combobox.util";
import { alphabetizeBy } from "../../_helpers";
import { useShallowEqualSelector } from "../../_helpers/redux";
import { useMyClientsUser } from "../../my-clients/routes/my-clients-page/hooks/use-my-clients-user";

const clinicianNameSortFn = (a: Clinician, b: Clinician) => {
  const sortResult = alphabetizeBy(a, b, "firstName");

  if (sortResult !== 0) {
    return sortResult;
  } else {
    // if first names are equal, sort by last name
    return alphabetizeBy(a, b, "lastName");
  }
};

const useClinicianList = () => {
  const [clinicianMap] = useShallowEqualSelector((state) => [
    state.clinicians.clinicianMap,
  ]);

  const clinicianList = Object.values(clinicianMap)
    .map((clinician) => ({
      ...clinician,
      firstName: clinician.first_name,
      lastName: clinician.last_name,
    }))
    .sort(clinicianNameSortFn);

  return { clinicianList };
};

const useClinicianSearch = (searchTerm: string, clinicianList: Clinician[]) => {
  const results = useMatch({
    term: searchTerm,
    data: clinicianList ?? [],
    keys: [
      (item: any) => `${item.firstName}`,
      (item: any) => `${item.lastName}`,
      (item: any) => `${item.firstName} ${item.lastName}`,
      (item: any) => `${item.lastName} ${item.firstName}`,
    ],
    throttle: 0,
  });

  return results;
};

export const getClinician = (fullName: string, clinicianList: Clinician[]) => {
  const clinician = clinicianList.find((c) => {
    const lookupValue =
      c.first_name.toLowerCase() + " " + c.last_name.toLowerCase();

    return lookupValue === fullName.toLowerCase();
  });

  return clinician;
};

interface UseClinicianPickerV2Params {
  callback: (clinicianId: number | null) => void;
}

export const useClinicianPickerV2 = ({
  callback,
}: UseClinicianPickerV2Params) => {
  const [searchTerm, setSearchTerm] = useState("");

  const { isClinicalLeaderOrSuperuser, currentUser } = useMyClientsUser();

  const { clinicianList } = useClinicianList();

  const results = useClinicianSearch(searchTerm, clinicianList);

  const currentClinicianTeamList = isClinicalLeaderOrSuperuser
    ? clinicianList.filter(
        (clinician) =>
          clinician.people_manager_id === currentUser?.clinician?.id,
      )
    : [];

  const handleChange = (event: BaseSyntheticEvent) => {
    setSearchTerm(event.target.value);

    if (event.target.value.length === 0) {
      callback(null);
    }
  };

  const showResults = searchTerm !== "";

  const setClinicianByName = (fullName: string) => {
    // The user can use up or down arrows to move through the list.
    // When they click enter, Combobox's onSelect method fires and sends
    // the value of the option, which is the clinician's full name.
    // Use that to find the clinician's id and set it to state.
    const clinician = getClinician(fullName, clinicianList);

    if (clinician) {
      callback(clinician.id);
    }
  };

  return {
    clinicianList,
    currentClinicianTeamList,
    results,
    handleChange,
    searchTerm,
    showResults,
    setClinicianByName,
  };
};
