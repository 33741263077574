import * as React from "react";

import { Checkbox } from "antd";
import { Title } from "../../app/_layout/display";
import { Row } from "../../app/_layout/Flex";
import { alphabetize } from "../_helpers";
import { values, mapValues, uniq } from "lodash-es";

const CheckboxList = <T extends unknown>(props: {
  data: T[];
  title: string;
  renderKey: (item: T) => string | number;
  renderItemName: (item: T) => string;
  checkedMap: { [key: string]: boolean };
  updateCheckedMap: (checkedMap: { [key: string]: boolean }) => void;
}) => {
  const {
    data,
    title,
    renderKey,
    renderItemName,
    checkedMap,
    updateCheckedMap,
  } = props;

  const { allIsChecked, allIsIndeterminate } = React.useMemo(() => {
    const uniqueValues = uniq(values(checkedMap));

    return {
      allIsChecked: uniqueValues.every((b) => b),
      allIsIndeterminate: uniqueValues.length === 2,
    };
  }, [checkedMap]);

  const allClicked = React.useCallback(
    (e: unknown) =>
      updateCheckedMap(
        allIsChecked
          ? mapValues(checkedMap, (b: boolean) => false)
          : mapValues(checkedMap, (b: boolean) => true),
      ),
    [updateCheckedMap, checkedMap, allIsChecked],
  );

  const sortedItems = React.useMemo(() => {
    return data
      .sort((a: T, b: T) => alphabetize(renderItemName(a), renderItemName(b)))
      .map((item: T) => (
        <CheckListItem
          key={renderItemName(item)}
          {...{
            checkedMap,
            renderItemName,
            renderKey,
            updateCheckedMap,
            item,
          }}
        />
      ));
  }, [data, checkedMap, renderItemName, renderKey, updateCheckedMap]);

  return (
    <>
      <Title size="sm">{title}</Title>
      <Row layout={"start center"}>
        <Checkbox
          onChange={allClicked}
          checked={allIsChecked}
          indeterminate={allIsIndeterminate}
        >
          All
        </Checkbox>
      </Row>
      {sortedItems}
    </>
  );
};

const CheckListItem = <T extends unknown>({
  checkedMap,
  renderItemName,
  renderKey,
  updateCheckedMap,
  item,
}: {
  renderKey: (item: T) => string | number;
  renderItemName: (item: T) => string;
  checkedMap: { [key: string]: boolean };
  updateCheckedMap: (checkedMap: { [key: string]: boolean }) => void;
  item: T;
}) => {
  const isChecked = React.useMemo(
    () => checkedMap[renderKey(item)],
    [item, checkedMap, renderKey],
  );

  const itemClicked = React.useCallback(
    (e: unknown) => {
      return updateCheckedMap({
        ...checkedMap,
        [renderKey(item)]: !checkedMap[renderKey(item)],
      });
    },
    [item, checkedMap, renderKey, updateCheckedMap],
  );

  return (
    <Row layout={"start center"}>
      <Checkbox onChange={itemClicked} checked={isChecked}>
        {renderItemName(item)}
      </Checkbox>
    </Row>
  );
};

export default CheckboxList;
