import * as React from "react";
import { useDispatch } from "react-redux";
import { useShallowEqualSelector } from "../../../_helpers/redux";
import { consultNoteActions } from "../../../../state/models/consultnote";
import { Checkbox, Input } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import QuestionTitle from "./shared/QuestionTitle";
import { ConsultNoteQuestion } from "../../../../api/types";
import { getCurrentConsultNoteInfo } from "../../_hooks";

interface Props {
  question: ConsultNoteQuestion;
  handleEdit: (hasBeenEdited: boolean) => void;
}

export default ({ question, handleEdit }: Props) => {
  const { readOnlyConsultNote } = getCurrentConsultNoteInfo();

  const [
    physical_location_from_client_chart,
    physical_location_other,
    consult_modality,
  ] = useShallowEqualSelector((state) => [
    state.consultnote?.openConsult?.consult_note
      ?.physical_location_from_client_chart,
    state.consultnote?.openConsult?.consult_note?.physical_location_other,
    state.consultnote?.openConsult?.consult_note?.consult_modality,
  ]);

  const dispatch = useDispatch();

  const handleChange = (key: string, value: boolean | string) => {
    handleEdit(true);
    dispatch(
      consultNoteActions.setConsultNoteValue({
        key,
        value,
      }),
    );
  };

  const checked =
    physical_location_from_client_chart === undefined
      ? true
      : physical_location_from_client_chart;

  return (
    <div style={{ paddingTop: "15px", paddingBottom: "15px" }}>
      <QuestionTitle
        questionNumber={question.questionNumber}
        titleText={question.title}
        subtitleText={question.subtitle}
        isRequired={question.isRequired}
      />
      {consult_modality === "teletherapy" ? (
        <div>
          <Checkbox
            disabled={readOnlyConsultNote}
            checked={checked}
            style={{ paddingRight: "10px" }}
            onChange={(e: CheckboxChangeEvent) =>
              handleChange(
                "physical_location_from_client_chart",
                e.target.checked,
              )
            }
          >
            Address listed in client chart
          </Checkbox>

          {!checked && (
            <div>
              If not, record other location here:
              <Input
                disabled={readOnlyConsultNote}
                onChange={(e) =>
                  handleChange("physical_location_other", e.target.value)
                }
                value={physical_location_other}
              ></Input>
            </div>
          )}
        </div>
      ) : (
        <div>
          <i>Not applicable.</i>
        </div>
      )}
    </div>
  );
};
