import * as React from "react";
import { useDispatch } from "react-redux";
import { useShallowEqualSelector } from "../../../../_helpers/redux";
import { consultNoteActions } from "../../../../../state/models/consultnote";
import { ConsultNoteQuestion } from "../../../../../api/types";
import { preferences_options, intensity_options } from "./enums";
import { Select as DeprecatedAntdSelect } from "antd";
import QuestionTitle from "../shared/QuestionTitle";
import { Flex, Row } from "../../../../_layout/Flex";
import { SelectSearchProps } from "../shared/SelectSearchProps";
import { getCurrentConsultNoteInfo } from "@/app/consults-and-matching/_hooks";

interface Props {
  question: ConsultNoteQuestion;
  fields: string[];
  handleEdit: (hasBeenEdited: boolean) => void;
}

export default ({ question, fields, handleEdit }: Props) => {
  const { readOnlyConsultNote } = getCurrentConsultNoteInfo();

  const [values] = useShallowEqualSelector((state) => [
    state.consultnote?.openConsult?.consult_note?.therapy_preferences || [],
  ]);

  const dispatch = useDispatch();

  const handleChange = (key: string, value: string) => {
    handleEdit(true);
    dispatch(
      consultNoteActions.updateRelatedObjectField({
        relatedObjectName: "therapy_preferences",
        key,
        value,
      }),
    );
  };

  return (
    <div style={{ paddingTop: "15px", paddingBottom: "15px" }}>
      <QuestionTitle
        questionNumber={question.questionNumber}
        titleText={question.title}
        subtitleText={question.subtitle}
      />
      {question.instructionText && (
        <div style={{ padding: "6px 0" }}>
          <i style={{ fontSize: "12px" }}>{question.instructionText}</i>
        </div>
      )}
      <Row layout="start start">
        <Flex style={{ paddingTop: "6px", flex: "0 0 9%" }}>Preference</Flex>
        <Flex style={{ marginRight: "6%", flex: "0 0 30%" }}>
          <DeprecatedAntdSelect<string>
            disabled={readOnlyConsultNote}
            style={{ display: "block" }}
            value={values[fields[0]]}
            onChange={(v) => {
              handleChange(fields[0], v);
            }}
            {...SelectSearchProps}
          >
            {preferences_options.map((so: any) => {
              return (
                <DeprecatedAntdSelect.Option key={so.key} value={so.value}>
                  {so.title}
                </DeprecatedAntdSelect.Option>
              );
            })}
          </DeprecatedAntdSelect>
        </Flex>
        <Flex style={{ paddingTop: "6px", flex: "0 0 6%" }}>Intensity</Flex>
        <Flex style={{ flex: "0 0 30%" }}>
          <DeprecatedAntdSelect<string>
            disabled={readOnlyConsultNote}
            style={{ display: "block" }}
            value={values[fields[1]]}
            onChange={(v) => {
              handleChange(fields[1], v);
            }}
            {...SelectSearchProps}
          >
            {intensity_options.map((so: any) => {
              return (
                <DeprecatedAntdSelect.Option key={so.key} value={so.value}>
                  {so.title}
                </DeprecatedAntdSelect.Option>
              );
            })}
          </DeprecatedAntdSelect>
        </Flex>
      </Row>
    </div>
  );
};
