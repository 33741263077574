import { DateTime } from "luxon";
import { selectDataForMatchingInfoByClient } from "../../../../../api/use-matching-data-by-clients/select-data-for-matching-data-by-client.util";
import { useMatchingInfoByClientIds } from "../../../../../api/use-matching-data-by-clients/use-matching-data-by-clients";

interface UseMatchingInfoByClientIdsInternal {
  clientIdsToQueryMatchingDataBy: number[];
  currentlySelectedClinician: number | null;
  clinicianId: number;
}

export const useMatchingInfoByClientIdsInternal = ({
  clientIdsToQueryMatchingDataBy,
  currentlySelectedClinician,
  clinicianId,
}: UseMatchingInfoByClientIdsInternal) => {
  const fromStartTime = DateTime.now().minus({ weeks: 4 }).toISO();

  const enabled =
    !!clientIdsToQueryMatchingDataBy &&
    !!clinicianId &&
    clientIdsToQueryMatchingDataBy.length > 0;

  const {
    data: clientsMatchingData,
    isLoading: isMatchingInfoLoading,
    isSuccess,
  } = useMatchingInfoByClientIds({
    clientIds: clientIdsToQueryMatchingDataBy as number[],
    fromStartTime,
    enabled,
    select: (data) =>
      selectDataForMatchingInfoByClient(
        data,
        currentlySelectedClinician ? currentlySelectedClinician : clinicianId,
      ),
  });

  return {
    clientsMatchingData,
    isMatchingInfoLoading,
    isSuccess,
  };
};
