import { createStitches, globalCss } from "@stitches/react";
import { colors } from "./config/colors";
import { cssReset } from "./config/css-reset";
import { cssUtils } from "./config/css-utils";

const { css, keyframes, getCssText, theme, createTheme, config, styled } =
  createStitches({
    theme: {
      space: {
        space4: "4px",
        space8: "8px",
        space10: "10px",
        space12: "12px",
        space14: "14px",
        space16: "16px",
        space18: "18px",
        space20: "20px",
        space22: "22px",
        space24: "24px",
        space28: "28px",
        space32: "32px",
        space48: "48px",
      },
      radii: {
        4: "4px",
        8: "8px",
        12: "12px",
        16: "16px",
      },
      colors,
    },
    media: {
      breakpoint640: "(max-width: 640px)",
      breakpoint768: "(max-width: 768px)",
      breakpoint850: "(max-width: 850px)",
      breakpoint1024: "(max-width: 1024px)",
      breakpoint1200: "(max-width: 1200px)",
      breakpoint1400: "(max-width: 1400px)",
      breakpoint1600: "(max-width: 1600px)",
    },
    utils: cssUtils,
  });

export const globalStyles = globalCss(cssReset);

export {
  styled as styledStitches,
  styled,
  css,
  keyframes,
  getCssText,
  theme,
  createTheme,
  config,
};
