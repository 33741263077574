import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuPrimitiveItem,
  ComposedDropdownMenuCheckboxItem,
} from "../../../../../../../../../design-system/dropdown/dropdown.styled";
import { Flex, Button } from "../../../../../../../../../design-system";

import React from "react";

import { Stack } from "../../../../../../../../../design-system/layout/stack";

import { DropdownSubMenuTriggerItem } from "../dropdown-sub-menu-trigger-item";
import { useBaselineSeverityFilterDropdown } from "./use-baseline-severity-filter-dropdown";

export const BaselineSeverityFilterDropdownMenu = () => {
  const {
    numberOfSelectedFilters,
    isFilteringOnSubclinical,
    isFilteringOnMildToModerate,
    isFilteringOnModerateToSevere,
    clearBaselineSeverityFitlers,
    setMyClientsFilter,
  } = useBaselineSeverityFilterDropdown();

  return (
    <DropdownMenu>
      <DropdownSubMenuTriggerItem
        numberOfSelectedFilters={numberOfSelectedFilters}
      >
        Baseline severity
      </DropdownSubMenuTriggerItem>

      <DropdownMenuContent sideOffset={15}>
        <Stack css={{ pb: 8 }}>
          <ComposedDropdownMenuCheckboxItem
            checked={isFilteringOnSubclinical}
            onCheckedChange={() =>
              setMyClientsFilter(
                "isFilteringOnSubclinical",
                !isFilteringOnSubclinical,
              )
            }
          >
            Subclinical
          </ComposedDropdownMenuCheckboxItem>

          <ComposedDropdownMenuCheckboxItem
            checked={isFilteringOnMildToModerate}
            onCheckedChange={() =>
              setMyClientsFilter(
                "isFilteringOnMildToModerate",
                !isFilteringOnMildToModerate,
              )
            }
          >
            Mild - moderate
          </ComposedDropdownMenuCheckboxItem>

          <ComposedDropdownMenuCheckboxItem
            checked={isFilteringOnModerateToSevere}
            onCheckedChange={() =>
              setMyClientsFilter(
                "isFilteringOnModerateToSevere",
                !isFilteringOnModerateToSevere,
              )
            }
          >
            Moderate - severe
          </ComposedDropdownMenuCheckboxItem>
        </Stack>

        <Flex
          justifyContent={"space-between"}
          css={{ borderTop: "1px solid $neutral3", pt: 10, pl: 0 }}
        >
          <DropdownMenuPrimitiveItem css={{ fontSize: 14 }} asChild>
            <Button
              variant={"tertiary"}
              size={"medium"}
              onClick={clearBaselineSeverityFitlers}
            >
              Clear
            </Button>
          </DropdownMenuPrimitiveItem>
        </Flex>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
