import { IconProps } from "../icon.interface";

export const LeftCaretIcon = ({
  fill = "#6F6F6F",
  width = 9,
  height = 15,
  title,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 9 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden={!title}
  >
    {title ? <title>{title}</title> : null}
    <path
      d="M6.96843 14.4691C7.35895 14.8596 7.99212 14.8596 8.38264 14.4691C8.77317 14.0785 8.77317 13.4454 8.38264 13.0549L6.96843 14.4691ZM1.67554 7.76196L0.96843 7.05486C0.577906 7.44538 0.577906 8.07855 0.96843 8.46907L1.67554 7.76196ZM8.38264 2.46907C8.77317 2.07855 8.77317 1.44538 8.38264 1.05486C7.99212 0.664332 7.35895 0.664332 6.96843 1.05486L8.38264 2.46907ZM8.38264 13.0549L2.38264 7.05486L0.96843 8.46907L6.96843 14.4691L8.38264 13.0549ZM2.38264 8.46907L8.38264 2.46907L6.96843 1.05486L0.96843 7.05486L2.38264 8.46907Z"
      fill={fill}
    />
  </svg>
);
