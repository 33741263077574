import * as React from "react";
import { Row } from "../../../../app/_layout/Flex";
import { Pad } from "../../../../app/_layout/display";

import TagCheckboxes from "./TagCheckboxes";
import ClinicianFits from "./ClinicianFits";
import { Alert } from "antd";
import { useShallowEqualSelector } from "../../../_helpers/redux";
import { matchmakerUtils } from "../../../../state/models/matchmaker";

const columnStyle = { flex: 1, overflow: "auto", maxHeight: "100%" };
export default function FitStep() {
  const isKpMatch = useShallowEqualSelector(matchmakerUtils.isKpReferral);
  const { showOtFits } = useShallowEqualSelector((state) =>
    matchmakerUtils.matchOtSelector(state.matchmaker),
  );

  return (
    <>
      {isKpMatch && (
        <Alert
          banner={true}
          type="info"
          message="This is a match for a Kaiser Permanente referral, showing all Kaiser Permanente enabled fits."
          showIcon={false}
        />
      )}
      {showOtFits && (
        <Alert
          banner={true}
          type="warning"
          message="Client preferences include ongoing teletherapy, showing ongoing teletherapy fits."
          showIcon={false}
        />
      )}
      <Row
        layout={"start stretch"}
        style={{ maxHeight: "calc(100vh - 275px)" }}
      >
        <Pad style={columnStyle}>
          <TagCheckboxes />
        </Pad>
        <Pad
          style={{
            ...columnStyle,
            borderLeft: "1px solid #e8e8e8",
          }}
        >
          <ClinicianFits />
        </Pad>
      </Row>
    </>
  );
}
