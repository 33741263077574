import * as React from "react";
import { useShallowEqualSelector } from "../../../_helpers/redux";
import { Input } from "antd";
import { consultNoteActions } from "../../../../state/models/consultnote";
import { ConsultNoteQuestion } from "../../../../api/types";
import { useDispatch } from "react-redux";
import QuestionTitle from "./shared/QuestionTitle";
import useDebounce from "../../../hooks/useDebounce";
import { getCurrentConsultNoteInfo } from "../../_hooks";

interface OpenResponseQuestionProps {
  question: ConsultNoteQuestion;
  handleEdit: (hasBeenEdited: boolean) => void;
  isTextArea?: boolean;
}

export default ({
  question,
  isTextArea,
  handleEdit,
}: OpenResponseQuestionProps) => {
  const { readOnlyConsultNote } = getCurrentConsultNoteInfo();

  const [savedValue] = useShallowEqualSelector((state) => [
    state.consultnote?.openConsult?.consult_note?.[question.slug],
    state,
  ]);

  const [value, setValue] = React.useState(savedValue);

  const dispatch = useDispatch();

  const handleChange = (value: string) => {
    handleEdit(true);
    setValue(value);
  };

  const debouncedValue = useDebounce(value, 500);

  React.useEffect(() => {
    setValue(savedValue);
  }, [savedValue]);

  React.useEffect(() => {
    dispatch(
      consultNoteActions.setConsultNoteValue({
        key: question.slug,
        value: debouncedValue,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return (
    <div style={{ paddingTop: "15px", paddingBottom: "15px" }}>
      <QuestionTitle
        questionNumber={question.questionNumber}
        titleText={question.title}
        subtitleText={question.subtitle}
        isRequired={question.isRequired}
        hideQuestionNumber={question.hideNumber}
      />
      {isTextArea ? (
        <Input.TextArea
          style={{ height: "32px" }}
          onChange={(e) => handleChange(e.target.value)}
          value={value}
          disabled={readOnlyConsultNote}
        />
      ) : (
        <Input disabled={readOnlyConsultNote} onChange={(e) => handleChange(e.target.value)} value={value} />
      )}
    </div>
  );
};
