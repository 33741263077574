// Note: Should be kept in-sync between ccfe, cfa, and fulfiller
import * as Select from "@radix-ui/react-select";
import { forwardRef } from "react";
import {
  MODAL_OVERLAY_ZINDEX,
  OVERLAY_ZINDEX,
} from "../styles/config/css-constants";
import { styledStitches } from "../styles/stitches.config";

const sharedStyles = {
  width: 250,
  border: "1px solid",
  borderColor: "$neutral7",
  br: 4,
  backgroundColor: "$neutral0",
};

const StyledSelectTrigger = styledStitches(Select.Trigger, {
  ...sharedStyles,
  display: "flex",
  p: "8px 4px 8px 18px",
  justifyContent: "space-between",
  cursor: "pointer",
  boxSizing: "border-box",
  height: 42,
  alignItems: "center",

  "&:active": {
    transform: "scale(0.995)",
  },

  "&:focus": {
    outlineColor: "$brandGreen",
  },

  "&[data-disabled]": {
    cursor: "not-allowed",
    backgroundColor: "$neutral2",
  },

  "&[data-placeholder]": {
    color: "$neutral11",
  },

  // Styles to change trigger when it is open can go here if needed
  '&[aria-expanded="true"]': {},

  variants: {
    subtlety: {
      subtle: { border: "none" },
    },
    size: {
      small: {
        width: 160,
      },
    },
  },
});

const StyledSelectPortal = styledStitches(Select.Portal, {
  zIndex: OVERLAY_ZINDEX,
});

const StyledSelectContent = styledStitches(Select.Content, {
  ...sharedStyles,
  p: "2px 8px 8px 8px",
  gap: 5,
  boxShadow: "0px 8px 8px 0px #00000014",
  zIndex: OVERLAY_ZINDEX,
  variants: {
    isInModal: {
      true: {
        zIndex: MODAL_OVERLAY_ZINDEX,
      },
    },
  },
});

const selectScrollButtonStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: 20,
  backgroundColor: "$neutral0",
  cursor: "default",
};

const StyledSelectScrollUpButton = styledStitches(Select.ScrollUpButton, {
  ...selectScrollButtonStyles,
});

const StyledSelectScrollDownButton = styledStitches(Select.ScrollDownButton, {
  ...selectScrollButtonStyles,
});

const StyledSelectViewport = styledStitches(Select.Viewport, {
  py: 5,
});

const StyledSelectItem = styledStitches(Select.Item, {
  py: 6,
  px: 10,
  br: 4,
  cursor: "pointer",
  fontSize: 14,
  userSelect: "none",

  "&:hover": {
    backgroundColor: "$neutral2",
  },

  // items that are focused via keyboard
  "&:focus": {
    backgroundColor: "$neutral2",
  },

  // the selected item
  '&[data-state="checked"]': {
    backgroundColor: "$neutral3",
  },

  "&[data-disabled]": {
    cursor: "not-allowed",
  },
});

const WrappedSelectItem = forwardRef<HTMLDivElement, Select.SelectItemProps>(
  ({ children, ...props }, forwardedRef) => {
    return (
      <StyledSelectItem {...props} ref={forwardedRef}>
        <Select.ItemText>{children}</Select.ItemText>
      </StyledSelectItem>
    );
  },
);

const StyledSelectIcon = styledStitches(Select.Icon, {
  display: "flex",
  alignItems: "center",
});

export const SelectRoot = Select.Root;
export const SelectTrigger = StyledSelectTrigger;
export const SelectValue = Select.Value;
export const SelectIcon = StyledSelectIcon;
export const SelectPortal = StyledSelectPortal;
export const SelectContent = StyledSelectContent;
export const SelectScrollUpButton = StyledSelectScrollUpButton;
export const SelectScrollDownButton = StyledSelectScrollDownButton;
export const SelectViewport = StyledSelectViewport;
export const SelectItem = WrappedSelectItem;
