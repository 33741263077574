import styled from "styled-components";

export const LoggingInDiv = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > h1 {
    font-size: 2em;
  }
  > h2 {
    margin-top: 1em;
  }
`;
