import * as Redux from "redux";

import { ThunkDispatch } from "redux-thunk";
import { AuthAction, AuthState, default as auth } from "./auth";
import {
  ClinicianAction,
  ClinicianState,
  default as clinicians,
} from "./clinicians";
import { ClinicActions, ClinicState, default as clinics } from "./clinics";
import {
  ConsultNoteActions,
  ConsultNoteState,
  default as consultnote,
} from "./consultnote";
import {
  CouplesIntakeResponseAction,
  CouplesIntakeResponseState,
  default as couplesintakeresponse,
} from "./couples-intake-response";
import {
  DashboardAction,
  DashboardState,
  default as dashboard,
} from "./dashboard";
import { default as filters, FilterActions, FilterState } from "./filters";
import { default as matches, MatchActions, MatchState } from "./matches";
import {
  default as matchmaker,
  MatchmakerActions,
  MatchmakerState,
} from "./matchmaker";
import { default as options, OptionsAction, OptionsState } from "./options";
import { default as panel, PanelAction, PanelState } from "./panel-management";
import {
  default as slottool,
  SlotToolAction,
  SlotToolState,
} from "./slot-tool";
import { default as team, TeamActions, TeamState } from "./team";

export type Action =
  | AuthAction
  | ClinicianAction
  | ClinicActions
  | FilterActions
  | MatchActions
  | MatchmakerActions
  | TeamActions
  | OptionsAction
  | PanelAction
  | DashboardAction
  | SlotToolAction
  | ConsultNoteActions
  | CouplesIntakeResponseAction;

export type Dispatcher = ThunkDispatch<AppState, any, Action>;
export type GetState = () => AppState;

// Create and export App State
export interface AppState {
  auth: AuthState;
  clinicians: ClinicianState;
  clinics: ClinicState;
  filters: FilterState;
  matches: MatchState;
  matchmaker: MatchmakerState;
  team: TeamState;
  options: OptionsState;
  panel: PanelState;
  dashboard: DashboardState;
  slottool: SlotToolState;
  consultnote: ConsultNoteState;
  couplesintakeresponse: CouplesIntakeResponseState;
}

// Export Reducers
export const rootReducer = Redux.combineReducers({
  auth,
  clinicians,
  clinics,
  filters,
  matches,
  matchmaker,
  team,
  options,
  panel,
  dashboard,
  slottool,
  consultnote,
  couplesintakeresponse,
});
