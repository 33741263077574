import React from "react";
import { useDispatch } from "react-redux";
import { matchmakerOperations } from "../../../../state/models/matchmaker";
import { Checkbox, Select as DeprecatedAntdSelect } from "antd";
import { Pad } from "../../../_layout/display";
import { $border } from "../../../../assets/colors";
import { Row } from "../../../_layout/Flex";
import { useShallowEqualSelector } from "../../../_helpers/redux";

export default () => {
  const [isRematch, rematchReason] = useShallowEqualSelector((state) => [
    state.matchmaker.draft.is_rematch,
    state.matchmaker.draft.rematch_note
      ? state.matchmaker.draft.rematch_note
      : undefined,
  ]);
  const dispatch = useDispatch();

  return (
    <Pad style={{ borderTop: `1px solid ${$border}` }}>
      <Row layout="start center" style={{ minHeight: "35px" }}>
        <Checkbox
          checked={isRematch}
          onChange={(e) =>
            dispatch(
              matchmakerOperations.updateRematchInfo(
                e.target.checked,
                (e.target.checked && rematchReason) || null,
              ),
            )
          }
        >
          This is a rematch
        </Checkbox>
        {isRematch && (
          <DeprecatedAntdSelect<string>
            onChange={(value) =>
              dispatch(matchmakerOperations.updateRematchInfo(isRematch, value))
            }
            style={{ width: 400 }}
            placeholder="Select a reason for rematch..."
            defaultValue={rematchReason}
          >
            <DeprecatedAntdSelect.Option value="Client Re-Engaging in Care (Same Clinician)">
              Client Re-Engaging in Care (Same Clinician)
            </DeprecatedAntdSelect.Option>
            <DeprecatedAntdSelect.Option value="Client Re-Engaging in Care">
              Client Re-Engaging in Care
            </DeprecatedAntdSelect.Option>
            <DeprecatedAntdSelect.Option value="Poor Match / Alliance - Client Initiated">
              Poor Match / Alliance - Client Initiated
            </DeprecatedAntdSelect.Option>
            <DeprecatedAntdSelect.Option value="Poor Match / Alliance - Clinician Initiated">
              Poor Match / Alliance - Clinician Initiated
            </DeprecatedAntdSelect.Option>
            <DeprecatedAntdSelect.Option value="Change in Client Presentation">
              Change in Client Presentation
            </DeprecatedAntdSelect.Option>
            <DeprecatedAntdSelect.Option value="Scheduling Issue">
              Scheduling Issue
            </DeprecatedAntdSelect.Option>
            <DeprecatedAntdSelect.Option value="Clinician No Longer Available">
              Clinician No Longer Available
            </DeprecatedAntdSelect.Option>
            <DeprecatedAntdSelect.Option value="Clinician Transferring Clinic">
              Clinician Transferring Clinic
            </DeprecatedAntdSelect.Option>
            <DeprecatedAntdSelect.Option value="Clinician Leaving Two Chairs">
              Clinician Leaving Two Chairs
            </DeprecatedAntdSelect.Option>
            <DeprecatedAntdSelect.Option value="Other">
              Other
            </DeprecatedAntdSelect.Option>
          </DeprecatedAntdSelect>
        )}
      </Row>
    </Pad>
  );
};
