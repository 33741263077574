import * as React from "react";
import { useDispatch } from "react-redux";
import { useShallowEqualSelector } from "../../../../_helpers/redux";
import { consultNoteActions } from "../../../../../state/models/consultnote";
import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { checkboxes } from "./enums";
import QuestionTitle from "../shared/QuestionTitle";
import { ConsultNoteQuestion } from "../../../../../api/types";
import { getCurrentConsultNoteInfo } from "@/app/consults-and-matching/_hooks";

interface Props {
  question: ConsultNoteQuestion;
  handleEdit: (hasBeenEdited: boolean) => void;
}

export default ({ question, handleEdit }: Props) => {
  const { readOnlyConsultNote } = getCurrentConsultNoteInfo();

  const [self_injurious_behavior] = useShallowEqualSelector((state) => [
    state.consultnote?.openConsult?.consult_note?.self_injurious_behavior,
  ]);

  const dispatch = useDispatch();

  const handleChange = (key: string, value: boolean | string) => {
    handleEdit(true);
    dispatch(
      consultNoteActions.updateRelatedObjectField({
        relatedObjectName: "self_injurious_behavior",
        key,
        value,
      }),
    );
  };

  const makeLabel = (label: string, index: number) => {
    if (index === 0) {
      return <b>{label}</b>;
    }
    if ([1, 2].includes(index)) {
      return <u>{label}</u>;
    }
    return label;
  };

  return (
    <div style={{ paddingTop: "15px", paddingBottom: "15px" }}>
      <QuestionTitle
        questionNumber={question.questionNumber}
        titleText={question.title}
        subtitleText={question.subtitle}
        isRequired={question.isRequired}
      />
      <div style={{ columns: 3, margin: "15px 0" }}>
        {checkboxes.map((c, index) => {
          const v = self_injurious_behavior?.[c.slug];
          return (
            <div key={index}>
              <Checkbox
                disabled={readOnlyConsultNote}
                checked={v}
                style={{ paddingRight: "10px" }}
                onChange={(e: CheckboxChangeEvent) =>
                  handleChange(c.slug, e.target.checked)
                }
              >
                {makeLabel(c.label, index)}
              </Checkbox>
            </div>
          );
        })}
      </div>
      {(self_injurious_behavior?.endorsed_current_active_hi === true ||
        self_injurious_behavior?.endorsed_current_active_si === true) && (
        <div>
          <u>
            <a
              href="https://twochairs.atlassian.net/wiki/spaces/CP/pages/740622363/Safety+Crisis+Management"
              target="_blank"
              rel="noopener noreferrer"
            >
              Link to Crisis Resources
            </a>
          </u>
        </div>
      )}
    </div>
  );
};
