import { Flex } from "../../../design-system/layout/flex";
import { styledStitches } from "@/app/design-system/styles/stitches.config";

export const MyClientsContainer = styledStitches(Flex, {
  flexGrow: 1,
  backgroundColor: "$neutral3",
  fontFamily: "Inter, arial",
  minWidth: 500,
  height: "100%",
});
