import { CurrentUserContext } from "@/app/app.utils";
import { Stack } from "@/app/design-system";
import { TIMEZONE } from "@/app/my-clients/utils";
import React from "react";
import { useGroupTherapyClientsPage } from "./_api/use-group-therapy-clients-overview";
import { selectGroupTherapyClientsData } from "./_api/use-group-therapy-clients-overview/use-group-therapy-clients.util";
import { ParsedGroupClient } from "./_api/use-group-therapy-clients-overview/use-group-therapy-clients.util";
import { GroupClientPane } from "./_components/group-client-pane";

export const GroupClientsDetails = () => {
  const cuser = React.useContext(CurrentUserContext);

  const { data: clientsData, isLoading: isAttendanceLoading } =
    useGroupTherapyClientsPage({
      select: (data) => selectGroupTherapyClientsData(data, TIMEZONE(cuser)),
    });

  return !isAttendanceLoading ? (
    <Stack css={{ pt: 24, pl: 20, pr: 30 }}>
      {clientsData?.map((client: ParsedGroupClient, index: number) => {
        return (
          <GroupClientPane
            index={index}
            clientId={client.clientId}
            clientName={client.clientName}
            clientPronouns={client.clientPronouns}
            age={client.age}
            cityAndState={client.cityAndState}
            isNewToTherapy={client.isNewToTherapy}
            accessibilityNeeds={client.accessibilityNeeds}
          />
        );
      })}
    </Stack>
  ) : null;
};
