import { store } from "../../index";

export const logFreshpaintEvent = (
  eventType: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  eventProperties = {},
  filterAcceptableProperties = true,
  callback?: (responseCode: number, responseBody: string) => void,
) => {
  // If we're not on production, we're going to mark the event as a test event
  if (window.ENVIRONMENT !== "PROD") {
    eventType += " - TEST";
  }
  // Check if it's clientside and successfully has the library loaded
  if ("freshpaint" in window) {
    const cuser = store.getState()?.auth?.currentUser;
    const filteredEventProperties = {};

    Object.keys({
      pathname: window.location.pathname,
      // @ts-ignore
      ...Object.fromEntries(new URLSearchParams(window.location.search)),
      ...eventProperties,
    }).forEach((key) => {
      if (keyIsAcceptable(key) && filterAcceptableProperties) {
        filteredEventProperties[key] = eventProperties[key];
      } else {
        console.log("Freshpaint parameter not acceptable:", key);
      }
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const freshpaint = (window as any).freshpaint;

    const params = Object.fromEntries(
      // @ts-ignore
      new URLSearchParams(window.location.search),
    );
    // set the event property for every event sent
    freshpaint.addEventProperties({
      referral_type: params.referral_type,
      clinician_id: cuser?.clinician?.id,
      clinician_timezone: cuser?.clinician?.primary_timezone,
      browser_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });

    console.log(
      `Sending Freshpaint event: ${eventType} | with properties: ${JSON.stringify(
        filteredEventProperties,
      )}`,
    );

    freshpaint.track(eventType, filteredEventProperties, callback);
  }
};

export const identifyUser = (
  email: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  identifyObject: Record<string, any> = {},
  callback?: (responseCode: number, responseBody: string) => void,
) => {
  const identifyObjectFiltered = Object.fromEntries(
    Object.entries(identifyObject).filter(([_, v]) => v !== undefined),
  );

  if ("freshpaint" in window) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const freshpaint = (window as any).freshpaint;

    // check if the user has been previously identified before
    if (freshpaint.user) {
      // use traits that were previously set on the user
      const traits = freshpaint.user().traits();

      const identifyObjectWithPreviouslySetUserTraits = {
        ...traits,
        // overwrite any existing traits if there are new traits
        ...identifyObjectFiltered,
        mx_Freshpaint_identify_origin: window.location.href,
      };

      freshpaint.identify(
        email,
        identifyObjectWithPreviouslySetUserTraits,
        callback,
      );
    } else {
      freshpaint.identify(email, identifyObjectFiltered, callback);
    }
  }
};

// We should NOT UNDER ANY CIRCUMSTANCES UPLOAD PHI TO FRESH PAINT
const acceptableFreshPaintEventKeys: string[] = [
  "pathname",
  "tc_utm",
  "qloc",
  "marketing_lead_id",
  "selectedTime",
  "availableTimes",
  "pageTitle",
  "featureName",
  "action",
  "resourceTitle",
  "resourceLink",
  "care_preference",
  "referral_type",
  "clinician_names",
  "clinician_id",
  "event_source",
  "event_type",
  "clinician_timezone",
  "browser_timezone",
  "id",
  "sortDirection",
];

export const acceptableFreshPaintEventKeyRules: ((key: string) => boolean)[] = [
  (key: string) => /^q\d+$/.test(key), // looks like "q###" with any number of numbers after the q
  (key: string) => /^test-\d+$/.test(key), // looks like "test-####" with any number of numbers after the "test-"
];

export const keyIsAcceptable = (key: string): boolean =>
  acceptableFreshPaintEventKeys.includes(key) ||
  acceptableFreshPaintEventKeyRules.every((rule) => rule(key));
