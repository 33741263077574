import { Stack } from "@/app/design-system";
import { TIMEZONE } from "@/app/my-clients/utils";
import { selectGroupTherapyClientData } from "../../_api/use-group-therapy-client-overview/use-group-therapy-client.util";
import { useGroupTherapyClientPage } from "../../_api/use-group-therapy-client-overview";
import { CareBackgroundContent } from "./care-background-content";
import { CurrentUserContext } from "@/app/app.utils";
import React from "react";

export const CareBackground = () => {
  const cuser = React.useContext(CurrentUserContext);

  const { data: clientData, isLoading } = useGroupTherapyClientPage({
    select: (data) => selectGroupTherapyClientData(data, TIMEZONE(cuser)),
  });

  if (!isLoading && clientData?.careBackground) {
    return (
      <Stack css={{ pt: 50 }}>
        <CareBackgroundContent careBackground={clientData.careBackground} />
      </Stack>
    );
  }

  if (!isLoading && !clientData?.careBackground) {
    return (
      <Stack css={{ pt: 60 }}>
        <div> This client did not fill out Care Background information. </div>
      </Stack>
    );
  }

  return null;
};
