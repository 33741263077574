import { useQuery } from "react-query";
import api from "../../../api";
import { FIVE_MINUTES } from "../../_helpers/constants";

interface UseGetMBCParams<T> {
  clientId: number | undefined;
}

export const getMBC= async (
  clientId: number | undefined,
): Promise<any> => {
  if (!clientId) return
  const url = `/api/query/mbc/scores_by_client/${clientId}/`;

  try {
    const { data } = await api.get(url);
    return data;
  } catch (e) {
    throw new Error(
      `[getMBC] Unable to fetch MBC data: ${e}`,
    );
  }
};

/**
 * @function useGetMBC
 * @description
 * @param { number | undefined } clientId to use as a query cache key. if none is provided,
 * useGetMBC will default to fetching all MBC data and useGetMBC will cache
 * the response using the client id key.
 */
export const UseGetMBC = <T extends object>({
  clientId,
}: UseGetMBCParams<T>) => {
  return useQuery({
    // like in useEffect, we pass in query keys as an array to react-query
    // and treat query keys as a dependency array. if the keys change, react-query
    // will automatically call the query function we pass to it.
    queryKey: ["MBC", clientId],
    // a query function can be any function that returns a promise.
    // the promise that is returned should either resolve the data or throw an error.
    queryFn: () => getMBC(clientId),
    // by default, react-query will mark the response from getMBC as stale (not fresh)
    // after the request has completed. here, we set a staleTime of five minutes
    // to prevent overfetching. after a request, we treat this data as fresh for five minutes,
    // and we will only query from the cache instead of making another request to our backend.
    // once five minutes have passed, react-query will refetchthe next time the component that uses this query renders,
    // or the window is refocused where the component is already rendered.
    staleTime: FIVE_MINUTES,
  });
};
