import React from "react";
import { useDispatchPromise } from "../_helpers/redux";
import { Button as AntDButton } from "antd";
import { Button, Text, TextArea, Spacer } from "@/app/design-system";
import * as Dialog from "@radix-ui/react-dialog";
import { IfPermitted } from "@/app/_helpers/permissions";
import {
  Close,
  Root,
  Content,
  Overlay,
  Title,
  Trigger,
  Footer,
  CloseButton,
  ModalIconButton,
} from "@/app/design-system/modal-dialog/modal-dialog.styled";
import { styledStitches } from "@/app/design-system/styles/stitches.config";
import { Flex } from "@/app/design-system/";
import { H2 } from "@/app/design-system/headings/heading";
import { XIcon } from "@/app/design-system/icons";
import {
  ComposedRadixUISelect,
  ComposedSelectRadixUIOption,
} from "@/app/design-system/select-radixui/composed-select-radixui";
import { createRadixSelectOptionValue } from "@/app/design-system/select-radixui/utils";
import styled from "styled-components";
import { useNotification, Notification } from "@/app/design-system";
import api from "@/api";
import { useQueryClient } from 'react-query'
import { clinicianOperations } from "@/state/models/clinicians";
import { ClinicianWithConfiguration } from "@/api/types";

// l33t way of writing [1, 2, 3, ... 32]
const TARGET_OPTIONS = Array.from(Array(32).keys()).map(
  (key: number) => key + 1,
);

const OTHER = "Other";

export const editTargetReasons = [
  {
    id: "1",
    value: "offboarding",
    label: "Offboarding",
  },
  {
    id: "2",
    value: "leave",
    label: "Leave",
  },
  { id: "3", value: "performance issues", label: "Performance Issues" },
  {
    id: "4",
    value: "role change",
    label: "Role change - decrease working hours",
  },
  { id: "5", value: OTHER, label: OTHER },
];

export const EditScheduledSessionsTargetModal = ({
  clinician,
  default_target,
  target_scheduled_sessions
}: { 
  clinician: ClinicianWithConfiguration,
  default_target: number,
  target_scheduled_sessions: number
}) => {
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const { renderNotification } = useNotification();

  const radixSelectTargetOptions: ComposedSelectRadixUIOption[] =
    TARGET_OPTIONS.map((r) => createRadixSelectOptionValue(String(r)));

  const [target, setTarget] = React.useState<string>(String(target_scheduled_sessions));
  const [editReason, setEditReason] = React.useState<string>("");
  const [additionalReason, setAdditionalReason] = React.useState<string>("");

  const queryClient = useQueryClient()

  const dispatch = useDispatchPromise();

  const onSave = () => {
    if (!editReason) {
      renderNotification({
        message: "Please pick a reason for the change.",
        notificationType: "error",
      });
      return;
    } else if (editReason === OTHER && !additionalReason) {
      renderNotification({
        message: "Please provide additional context for the change.",
        notificationType: "error",
      });
      return;
    }

    return api
      .patch(`/api/clinicians/${clinician.id}/target_sessions/`, {
        target_scheduled_sessions: target,
        change_reason: editReason,
        change_reason_other: additionalReason,
        change_source: "teams_tab",
      })
      .then(() => {
        renderNotification({
          message: "Successfully updated autoschedule target.",
          notificationType: "success",
        });
        queryClient.invalidateQueries({ queryKey: ['ClinicianSchedulePreferences'] })
        setEditReason("");
        setAdditionalReason("");
        setModalOpen(false);
        dispatch(clinicianOperations.getActiveHolds(false));
      })
      .catch((error) => {
        renderNotification({
          message:
            "There was an error updating this clinician's autoschedule target.",
          notificationType: "error",
        });
        throw new Error(error.message);
      });
  };

  let roleDisplay = "No role"
  if (clinician.configuration) {
    roleDisplay = clinician.configuration?.classification.employment_type === "network"
    ? "Network"
    : clinician.configuration.classification.schedule_type.toUpperCase()
  }

  return (
    <div>
      <Root open={modalOpen} onOpenChange={setModalOpen}>
        <IfPermitted permissions={["IsSuperUser"]} requireAll={false}>
          <Trigger asChild>
            <AntDButton
              block={true}
              size="small"
              type="default"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
              }}
            >
              Edit
            </AntDButton>
          </Trigger>
        </IfPermitted>
        <Dialog.Portal>
          <Overlay className="DialogOverlay" />
          <Title>Edit scheduled session target</Title>
          <StyledModalDialogContent
            className="DialogContent"
            onClick={(e) => e.stopPropagation()}
          >
            <Flex
              justifyContent={"space-between"}
              css={{
                flexGrow: 1,
                p: "16px 24px",
                borderBottom: "1px solid $neutral4",
              }}
            >
              <H2 fontWeight={700} css={{ fontSize: 20 }} color={"$neutral12"}>
                Edit scheduled session target
              </H2>
              <Dialog.DialogClose asChild>
                <ModalIconButton>
                  <CloseButton>
                    {" "}
                    <XIcon></XIcon>
                  </CloseButton>
                </ModalIconButton>
              </Dialog.DialogClose>
            </Flex>
            <div style={{ padding: "24px" }}>
              <Text>
                Editing scheduled session target will restart Client Scheduler if there
                is a pause in place.
              </Text>
              <Spacer y={24} />
              <Text fontSize={14}>
                New scheduled session target
                <Text color="$red11" notFlex>
                  *
                </Text>
              </Text>
              <SelectWrapper>
                <FlexWrapper>
                  <ComposedRadixUISelect
                    options={radixSelectTargetOptions}
                    value={target}
                    onChange={(value) => {
                      setTarget(value);
                    }}
                    selectLabel="Select"
                    size="small"
                    isInModal
                  />
                  <Text fontSize={14}>Default: {default_target}</Text>
                  <Text fontSize={14}>({roleDisplay})</Text>
                </FlexWrapper>
              </SelectWrapper>
              <Spacer y={24} />
              <Text fontSize={14}>
                Edit reason&nbsp;
                <Text color="$red11" notFlex>
                  *
                </Text>
              </Text>
              <SelectWrapper>
                <ComposedRadixUISelect
                  options={editTargetReasons}
                  value={editReason}
                  onChange={(value) => {
                    setEditReason(value);
                  }}
                  selectLabel="Select"
                  isInModal
                />
              </SelectWrapper>
              {editReason === OTHER ? (
                <section>
                  <Spacer y={24} />
                  <Text fontSize={14}>
                    Other reason description&nbsp;
                    <Text color="$red11" notFlex>
                      *
                    </Text>
                  </Text>
                  <TextArea
                    style={{
                      margin: "8px 0",
                      padding: "8px 12px",
                      minWidth: "80%",
                    }}
                    defaultValue={additionalReason}
                    onChange={(t) => setAdditionalReason(t.target.value)}
                  ></TextArea>
                </section>
              ) : null}
            </div>

            <Footer
              gap={12}
              justifyContent={"flex-end"}
              style={{ padding: "12px 24px" }}
            >
              <Close asChild>
                <Button
                  size={"small"}
                  variant={"secondary"}
                  css={{ border: "1px solid $neutral12" }}
                >
                  <Text>Cancel</Text>
                </Button>
              </Close>
              <Button
                size={"small"}
                onClick={(e) => {
                  e.preventDefault();
                  onSave();
                }}
              >
                <Text color={"$neutral0"}>Confirm</Text>
              </Button>
            </Footer>
          </StyledModalDialogContent>
        </Dialog.Portal>
      </Root>

      <Notification />
    </div>
  );
};

const SelectWrapper = styled.div`
  margin-top: 8px;
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const StyledModalDialogContent = styledStitches(Content, {
  maxWidth: 572,
});
