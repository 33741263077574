import { TIME_TAG_FORMAT } from "@/app/_helpers/datetime";
import { MangledStyledExternalLink } from "@/app/_shared/ExternalLink";
import {
  Flex,
  Spacer,
  Tag,
  Text,
  Title6,
  styledStitches,
} from "@/app/design-system";
import { Chart } from "@/app/design-system/assets/chart";
import { TABLE_LINK_STYLE } from "@/app/design-system/styles/config/css-utils";
import { Ehr_Event } from "@/graphql/generated";
import { DateTime } from "luxon";
import { getClientIncompleteNotesForEhrEventsMap } from "./_utils";

interface IncompleteNotesProps {
  incompleteNotes: Array<Ehr_Event>;
}

export const IncompleteNotes = ({ incompleteNotes }: IncompleteNotesProps) => {
  const clientAggregates =
    getClientIncompleteNotesForEhrEventsMap(incompleteNotes);

  const notesMapKeys = Array.from(clientAggregates.keys());
  return (
    <>
      <Spacer y={24} />
      <section>
        <Title6
          style={{
            color: "#000",
            fontSize: 16,
            fontWeight: 600,
            lineHeight: "24px",
            margin: 0,
          }}
          as="h2"
        >
          {" "}
          Incomplete Notes
        </Title6>
        <Spacer y={16} />
        {incompleteNotes.length === 0 ? (
          <>
            <Flex flexDirection={"column"} alignItems={"center"}>
              <Chart />
              <Spacer y={24} />
              <Text color="$neutral11">
                Keep up the good work! No documentation reminders
              </Text>
            </Flex>
          </>
        ) : (
          <IncompleteNotesList title="Incomplete Notes">
            {notesMapKeys.map((clientId: number) => {
              const aggregateData = clientAggregates.get(clientId);
              if (!aggregateData) return null;
              return (
                <IncompleteNotesListItem key={clientId}>
                  <Flex flexDirection={"row"} gap={8} alignItems={"center"}>
                    <MangledStyledExternalLink
                      href={aggregateData.healthieLink ?? "/"}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                        outline: "2px solid red",
                      }}
                    >
                      <ClientName>{aggregateData.full_name}</ClientName>
                    </MangledStyledExternalLink>
                    {aggregateData.isOverdue ? (
                      <Tag size="small" color={"red"}>
                        Overdue
                      </Tag>
                    ) : null}
                  </Flex>
                  <AggregateDetails gap="4">
                    {/* eslint-disable-next-line no-trailing-spaces */}
                    <p>
                      <span>{aggregateData.count} incomplete: </span>
                      {aggregateData.sortedDates.map(
                        (date: DateTime, index: number) => {
                          return (
                            <span>
                              <time dateTime={date.toFormat(TIME_TAG_FORMAT)}>
                                {date.toFormat("M/d")}
                              </time>
                              <span>
                                {index < aggregateData.count - 1 ? ", " : ""}
                              </span>
                            </span>
                          );
                        },
                      )}
                    </p>
                  </AggregateDetails>
                </IncompleteNotesListItem>
              );
            })}
          </IncompleteNotesList>
        )}
      </section>
    </>
  );
};

const ClientName = styledStitches(Text, TABLE_LINK_STYLE, {
  minWidth: "fit-content",
  display: "inline",
});

const IncompleteNotesList = styledStitches("ul", {
  backgroundColor: "$neutral0",
  padding: "8px",
  borderRadius: "4px",
});

const IncompleteNotesListItem = styledStitches("li", Flex, {
  justifyContent: "space-between",
  padding: "20px",
  borderBottom: "solid 1px $neutral3",
  "&:last-of-type": {
    borderBottom: "none",
  },
});

const AggregateDetails = styledStitches(Flex, {
  fontSize: "16px",
  maxWidth: "50%",
  wordWrap: "break-word",
});
