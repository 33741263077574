import { Flex, Label, Stack, styledStitches, Text } from "@/app/design-system";
import { CalendarIcon } from "@/app/design-system/icons";
import {
  contentShow,
  SelectButton,
  SelectPopover,
  SelectRoot,
} from "@/app/design-system/select-reachui/select.styled";

import { DateTime } from "luxon";
import React from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { useController, useFormContext } from "react-hook-form";
import "./day-picker.css";
import { UpsertSessionFormItemProps } from "./upsert-session-form-item.interface";

export const SessionDateFormItem = ({
  name,
  options,
  id,
  mode,
}: UpsertSessionFormItemProps) => {
  const { control } = useFormContext();
  // register input
  const {
    field: { value, onChange, onBlur, ref },
    fieldState: { invalid, isDirty },
    formState: { errors },
  } = useController({
    name,
    control,
  });

  // react-day-picker uses javascript Dates internally, but we always want to serialize
  // into state with the string isoDate.

  const [selected, setSelected] = React.useState<string>(
    value === "Select" ? new Date().toISOString() : value,
  );

  const dateTime = DateTime.fromISO(selected);

  const onClick = (day: Date) => {
    setSelected(day.toISOString());
    onChange(day.toISOString());
  };

  return (
    <Stack gap={10}>
      <Label required={mode === "add"}>Session date</Label>
      <Flex
        gap={16}
        css={{
          position: "relative",
          svg: { position: "absolute", top: 8, left: 12 },
        }}
      >
        <SelectRoot>
          <SelectButton
            css={{
              pl: 44,
              '&[data-reach-listbox-button][aria-expanded="true"]': {
                borderBottom: "1px solid $neutral7",
                boxShadow: "none",
                bblr: 4,
                bbrr: 4,
              },
              "&:active": {
                transform: "none",
              },
            }}
          >
            <CalendarIcon />
            <Text fontSize={16}>
              {DateTime.fromISO(selected).toLocaleString()}
            </Text>
          </SelectButton>
          <SelectPopover portal={false}>
            <DayPicker
              style={{
                animation: `${contentShow} 0.1s ease-in-out`,
              }}
              selected={DateTime.fromISO(selected).toJSDate()} // deserialize from str isodate to js date for datepicker
              onDayClick={onClick}
              defaultMonth={DateTime.fromISO(selected).toJSDate()}
              // exclude past dates, and Saturday / Sundays
              disabled={[
                { before: new Date() },
                (day: Date) => [0, 6].includes(day.getUTCDay()),
              ]}
            />
          </SelectPopover>
        </SelectRoot>
        <Text>{dateTime.toFormat("cccc")}</Text>
      </Flex>
    </Stack>
  );
};

const StyledDayPicker = styledStitches(DayPicker, {});
