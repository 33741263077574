import { useGetCurrentUser } from "@/app/client/use-get-current-user/use-get-current-user.api";
import { TIMEZONE } from "@/app/my-clients/utils";
import { Stack, Text, styledStitches, Spinner } from "@/app/design-system";
import { useGroupTherapyClientNotesPage } from "./_api/use-group-therapy-client-notes";
import { selectGroupTherapyClientAttendanceNotes } from "./_api/use-group-therapy-client-attendance-notes/use-group-therapy-client-attendance-notes.util";
import { ClientAttendancePane } from "./_components/client-attendance-pane";

export const ClientAttendanceNotes = () => {
  const { data: cuser } = useGetCurrentUser();
  const { data, isLoading, isError } = useGroupTherapyClientNotesPage({
    select: selectGroupTherapyClientAttendanceNotes,
    timezone: TIMEZONE(cuser),
  });

  return !isLoading && !isError ? (
    <Stack
      css={{
        width: "100%",
        background: "white",
        padding: 10,
        paddingLeft: 20,
      }}
    >
      <StyledHeaderText>Notes & Attendance</StyledHeaderText>
      <Stack>
        {data?.map((session) => (
          <ClientAttendancePane session={session} key={session.sessionNumber} />
        ))}
      </Stack>
    </Stack>
  ) : (
    <Spinner />
  );
};

const StyledHeaderText = styledStitches(Text, {
  fontSize: 24,
  fontWeight: 700,
  paddingBottom: 50,
  paddingTop: 30,
});
