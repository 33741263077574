import { useGetTags } from "@/app/api/use-get-tags/use-get-tags";
import { Spinner } from "@/app/design-system";
import { QuarterCircle } from "@/app/design-system";
import { CheckOutlined } from "@ant-design/icons";
import { AutoComplete, Button, Divider, message } from "antd";
import DeprecatedAntdSelect from "antd/es/select";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Tag } from "../../../api/types";
import { matchOperations } from "../../../state/models/matches";
import { alphabetizeByPass } from "../../_helpers";
import { useUserHasAnyPermissions } from "../../_helpers/permissions";
import { useShallowEqualSelector } from "../../_helpers/redux";
import { Title } from "../../_layout/display";
import { Flex, Row } from "../../_layout/Flex";

type TagWeight = -1 | 1 | 2 | 4;

const AddTagForm = ({ clinicianId }: { clinicianId: number }) => {
  const { data: allTags, isLoading: isTagsLoading } = useGetTags();
  const [clinicianTags, inclusiveCategories] = useShallowEqualSelector(
    (state) => [
      state.matches.clinicianTags.filter(
        (ct) => ct.clinician_id === clinicianId,
      ),
      state.matches.inclusiveCategories,
    ],
  );
  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [formTag, setFormTag] = useState<Tag | null | undefined>(null);
  const [formWeight, setFormWeight] = useState<TagWeight>(1);
  const dispatch = useDispatch();

  const saveTagToClinician = () => {
    if (formTag) {
      dispatch(
        matchOperations.createClinicianTag(clinicianId, formTag.id, formWeight),
      );
      clearForm();
    } else {
      message.error("Tag not selected.");
    }
  };

  const searchableTags = allTags?.ehr_tag
    .filter((t) => !clinicianTags.some((ct) => ct.tag_id === t.id))
    .filter(t => t.version === 2) // TAG ROLLUP
    .sort(alphabetizeByPass("name"));
  const searchFilteredTags = searchableTags?.filter((t) =>
    `[V${t.version}] ${t.name} - ${t.category} - ${t.bucket}`
      .toLowerCase()
      .includes(searchValue.toLowerCase()),
  );

  if (isTagsLoading) return null;

  const clearForm = () => {
    setShowCreate(false);
    setSearchValue("");
    setFormTag(null);
    setFormWeight(1);
  };

  if (!allTags)
    return (
      <div>
        <Spinner>
          <QuarterCircle dimension={"3em"} />
        </Spinner>
      </div>
    );
  return (
    <div style={{ paddingBottom: "15px" }}>
      {showCreate && (
        <Row layout="start center" style={{ marginBottom: "15px" }}>
          <div style={{ width: "75%" }}>
            <Row layout="start center">
              <Title margin="0" size="sm">
                Tag To Attach
              </Title>
              <Flex />
              <Button onClick={clearForm}>Cancel</Button>
              <Button onClick={saveTagToClinician} type="primary">
                Save
              </Button>
            </Row>
            <AutoComplete
              value={
                formTag
                  ? `[V${formTag.version}] ${formTag.name} - ${formTag.category} - ${formTag.bucket}`
                  : undefined
              }
              style={{ width: "100%" }}
              dataSource={searchFilteredTags?.map((t) => (
                <AutoComplete.Option
                  value={`${t.id}`}
                  key={t.id.toString()}
                  title={`[V${t.version}] ${t.name} - ${t.category} - ${t.bucket}`}
                >
                  {`[V${t.version}] ${t.name} - ${t.category} - ${t.bucket}`}
                </AutoComplete.Option>
              ))}
              onChange={(value) => {
                if (typeof value === "string") {
                  let selectedFormTag =
                    allTags?.ehr_tag?.find((t) => t.id === Number(value)) ||
                    null;

                    if (selectedFormTag) {
                    const status = !selectedFormTag.status
                      ? "deprecated"
                      : null;
                      // @ts-ignore - narrowing number type to 1|2|3, trust me
                    setFormTag({
                      ...selectedFormTag,
                      status,
                    });
                  }
                }
              }}
              onSearch={setSearchValue}
              placeholder="Search for a tag to attach..."
            />
          </div>
          <Flex />
          <div>
            <Title margin="0px" size="sm">
              Tag Weight
            </Title>
            <div style={{ textAlign: "center" }}>
              {formTag && !inclusiveCategories.includes(formTag.category) ? (
                <DeprecatedAntdSelect<TagWeight>
                  defaultValue={1}
                  style={{ width: 170 }}
                  onChange={(value) => setFormWeight(value)}
                >
                  <DeprecatedAntdSelect.Option value={4}>
                    Expert
                  </DeprecatedAntdSelect.Option>
                  <DeprecatedAntdSelect.Option value={2}>
                    Good
                  </DeprecatedAntdSelect.Option>
                  <DeprecatedAntdSelect.Option value={1}>
                    Treats
                  </DeprecatedAntdSelect.Option>
                  <DeprecatedAntdSelect.Option value={-1}>
                    Does Not Treat
                  </DeprecatedAntdSelect.Option>
                </DeprecatedAntdSelect>
              ) : (
                <CheckOutlined style={{ marginTop: "10px" }} />
              )}
            </div>
          </div>
        </Row>
      )}

      {!showCreate && (
        <Button type="primary" onClick={() => setShowCreate(true)}>
          Add New Tag
        </Button>
      )}
      {showCreate && <Divider />}
    </div>
  );
};

export default AddTagForm;
