import { useQuery } from "react-query";
import api from "@/api";

import { CancellationReasonsAndTypesApiData } from "./event-cancellation-types.constants";

export const getCancellationTypesAndReasons =
  async (): Promise<CancellationReasonsAndTypesApiData> => {
    try {
      const { data } = await api.get(
        "/api/events/v1/valid_cancellation_reasons/",
      );

      return data;
    } catch (e) {
      throw new Error(
        `[getCancellationTypesAndReasons] Unable to fetch event cancellation reasons and types: ${e}`,
      );
    }
  };

interface UseFetchCancellationTypesAndReasonsParams<T> {
  select: (data: CancellationReasonsAndTypesApiData) => T | null;
  enabled?: boolean;
}

export const useFetchCancellationTypesAndReasons = <T extends {}>({
  select,
  enabled = true,
}: UseFetchCancellationTypesAndReasonsParams<T>) => {
  return useQuery(
    ["cancellation-types-and-reasons"],
    () => getCancellationTypesAndReasons(),
    {
      select,
      staleTime: Infinity,
      enabled,
    },
  );
};
