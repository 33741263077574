import { Box, styledStitches } from "../../../../../../../../../design-system";

export const SessionPaneContainer = styledStitches(Box, {
  borderTop: "1px solid",
  borderColor: "$neutral7",
  background: "white",
  flexGrow: 1,
  p: 24,
  minHeight: 78,
  gap: 48,
  minWidth: "100%",
  alignItems: "stretch",
  "[data-state=closed], [data-state=open] &:hover": {
    transition: "background-color ease-in-out",
    transitionDuration: "150ms",
  },

  "@breakpoint1024": {
    flexDirection: "column",
  },
});
