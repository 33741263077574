import * as React from "react";
import { message, Spin } from "antd";

import { useDispatchPromise } from "../_helpers/redux";

import { useUserHasAnyPermissions } from "../_helpers/permissions";
import useTitle from "../hooks/useTitle";
import { ResourceDrawer } from "./Resources/ResourceDrawer";
import { Category, Resource, SearchResults, Tag } from "./types";

import {
  fetchCategories,
  fetchTags,
  searchResults,
  FAVORITES_CATEGORY_ID,
  fetchResourceById,
  deleteResource,
} from "./utils";
import { CategoriesList } from "./Categories/CategoriesList";
import { ResourceList } from "./Resources/ResourceList";
import { TagsList } from "./Tags/TagsList";
import { LibraryNavBar } from "./LibraryNavBar";
import { Row } from "../_layout/Flex";
import { MyResources } from "./MyResources/MyResources";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function LibraryContainer() {
  const navigate = useNavigate();

  const pageTitle = "Content Library";
  useTitle(pageTitle);
  const dispatch = useDispatchPromise();
  const isCurator = useUserHasAnyPermissions(["IsResourceLibCurator"]);
  const isClinician = useUserHasAnyPermissions(["IsClinician"]);

  const [searchParams] = useSearchParams();
  // search will be derived from url params
  const categoriesPage = searchParams.get("categories_page") || "1";
  // search will be derived from url params
  const searchText = searchParams.get("search") || "";
  // selectedCategoryId will be derived from url params
  const selectedCategoryId = searchParams.get("category") || "";
  // selectedResourceId will be derived from url params
  const selectedResourceId = searchParams.get("resource");

  // selectedTags will be derived from url params
  const urlTags = searchParams.get("tags");
  const selectedTags: string[] = React.useMemo(
    () => JSON.parse(urlTags || "[]"),
    [urlTags],
  );

  const setParams = (key: string, value?: string) => {
    if (value) {
      searchParams.set(key, value.toString());
    } else {
      searchParams.delete(key);
    }

    navigate({ search: searchParams.toString() });
  };

  const [categories, setCategories] = React.useState<Category[]>([]);
  const [totalCategories, setTotalCategories] = React.useState<number>(0);
  const [resources, setResources] = React.useState<Resource[]>([]);

  const [tags, setTags] = React.useState<Tag[]>([]);
  const [searchRes, setSearchRes] = React.useState<SearchResults>();

  const [isFormVisible, setIsFormVisible] = React.useState(false);

  // this will be called on initial component mount
  // this will fetch a list of all categories, favorites, and tags
  React.useEffect(() => {
    const init = async () => {
      const [categoriesRes, tagsRes] = await Promise.all([
        dispatch(fetchCategories(null, categoriesPage)),
        dispatch(fetchTags()),
      ]);

      // categories
      const nextCategories = categoriesRes.data.results;
      let categoriesCount = categoriesRes.data.count;

      setCategories(nextCategories);
      setTotalCategories(categoriesCount);
      setTags(tagsRes.data.results);
    };
    init();
  }, [dispatch, categoriesPage, isClinician]);

  // this will be called when url = /library?resource=xyz
  // this will open the drawer with just a resource id
  React.useEffect(() => {
    const loadResources = async (resourceId: string) => {
      const res = await dispatch(fetchResourceById(resourceId));
      setResources([res.data]);
    };
    // if url has selected category (selectedCategoryId) don't fetch resource by id
    // if resources exist, don't fetch.
    // this will ensure that this effect is only called when we load
    // the page with just a resource id
    if (resources.length || selectedCategoryId) return;
    if (selectedResourceId) {
      loadResources(selectedResourceId);
    }
  }, [dispatch, selectedResourceId, selectedCategoryId, resources]);

  React.useEffect(() => {
    const _search = async (
      tags?: string,
      searchText?: string,
      categoryId?: string,
    ) => {
      const res = await dispatch(
        searchResults({
          search_term: searchText || undefined,
          tags: tags || undefined,
          categoryId: categoryId || undefined,
        }),
      );

      if (res) {
        setResources(res.resources);
        setSearchRes(res);
      }
    };
    if (searchText?.length || selectedTags.length || selectedCategoryId) {
      _search(selectedTags.join(","), searchText, selectedCategoryId);
    } else {
      setSearchRes(undefined);
      setResources([]);
    }
  }, [searchText, selectedTags, selectedCategoryId, dispatch]);

  // called to update resource item in list
  // used when user updates and/or toggles favorite
  // in both resource drawer and resources list
  const updateResourceItem = (nextResource: Resource) => {
    const idx = resources.findIndex((r) => r.id === nextResource.id);
    if (idx > -1) {
      const nextResources = [...resources];
      // if we're currently viewing favorites category resources
      // then we will remove resource if the user un-favorites the resource
      if (
        selectedCategoryId === FAVORITES_CATEGORY_ID &&
        !nextResource.is_favorite
      ) {
        // first off remove resource item from resources list
        nextResources.splice(idx, 1);
      } else {
        nextResources.splice(idx, 1, nextResource);
      }
      setResources(nextResources);

      // now update the favorites category counts
      // then update the category count
      const favoriteCategoryIdx = categories.findIndex(
        (c) => c.id === FAVORITES_CATEGORY_ID,
      );
      if (favoriteCategoryIdx === -1) return;
      const nextFavoritesCategory = { ...categories[favoriteCategoryIdx] };
      const shouldAddToFav = nextResource.is_favorite;
      const favResourceIdx = nextFavoritesCategory.items.indexOf(
        nextResource.id,
      );
      if (shouldAddToFav) {
        // fav resource so increment/add
        if (favResourceIdx > -1) return;
        nextFavoritesCategory.items.push(nextResource.id);
      } else {
        // not fav so decrement/remove
        if (favResourceIdx === -1) return;
        nextFavoritesCategory.items.splice(favResourceIdx, 1);
      }
      const nextCategories = [...categories];
      nextCategories.splice(favoriteCategoryIdx, 1, nextFavoritesCategory);
      setCategories(nextCategories);
    } else {
      if (nextResource.id) {
        const nextResources = [...resources, nextResource];
        setResources(nextResources);
      }
    }
  };

  const onResetState = () => {
    navigate({ search: "" });
    setSearchRes(undefined);
    setResources([]);
  };

  const onHandleSearch = (nextSearch: string) => {
    // reset url search params on new search
    navigate({
      search: new URLSearchParams(`search=${nextSearch}`).toString(),
    });
  };

  const handleDeleteResource = async () => {
    try {
      if (selectedResourceId) {
        const res = await dispatch(deleteResource(selectedResourceId));

        if (res.status === 204) {
          message.success("Successfully deleted resource.");
          handleSetIsFormVisible(false);
          const deletedResourceIndex = resources.findIndex(
            (resource) => resource.id === selectedResourceId,
          );
          const newResources = [...resources];
          newResources.splice(deletedResourceIndex, 1);
          setResources(newResources);
          setParams("resource", "");
        }
      }
    } catch (e) {
      console.error((e as Error).message);
    }
  };

  let navBarItems = [];
  const currentCategory = categories.find((c) => c.id === selectedCategoryId);
  const currentResource = resources.find((r) => r.id === selectedResourceId);

  if (searchText) {
    navBarItems.push("Search");
    navBarItems.push(searchText);
  }

  if (currentCategory && currentCategory.name) {
    navBarItems.push(currentCategory.name);
  }

  if (selectedCategoryId === "added_by_me") {
    navBarItems.push("Added By Me");
  }

  if (selectedCategoryId === "my_favorites") {
    navBarItems.push("Favorites");
  }

  if (currentResource && currentResource.title) {
    navBarItems.push(currentResource.title);
  }

  const onUpdateCategory = async (next: Category) => {
    try {
      const prev = [...categories];
      const nextIdx = prev.findIndex((item) => item.id === next.id);
      if (nextIdx === -1) {
        setCategories([...prev, next]);
        onSelectCategory(next.id);
        return;
      }
      prev.splice(nextIdx, 1, next);
      setCategories(prev);
    } catch (e) {
      message.error((e as Error).message);
    }
  };

  const onSelectResource = (id: string) => {
    setParams("resource", id);
    setIsFormVisible(true);
  };

  const handleSetIsFormVisible = (isVisible: boolean) => {
    setIsFormVisible(isVisible);
  };

  const onSelectCategory = (categoryId: string) => {
    navigate({
      search: new URLSearchParams(`category=${categoryId}`).toString(),
    });
  };

  const onSelectMyResources = (route: string) => {
    navigate({
      search: new URLSearchParams(`category=${route}`).toString(),
    });
  };

  const onUpdateTag = (nextTag: Tag) => {
    const prevTags = [...tags];
    const nextTagIdx = prevTags.findIndex((t) => t.id === nextTag.id);
    if (nextTagIdx === -1) {
      setTags([...prevTags, nextTag]);
      return;
    }
    prevTags.splice(nextTagIdx, 1, nextTag);
    setTags(prevTags);
  };

  return (
    <Spin size="large" spinning={!categories.length}>
      <LibraryNavBar
        title={pageTitle}
        items={navBarItems}
        onReset={onResetState}
        onSearch={onHandleSearch}
      />
      <Row style={{ padding: 24 }}>
        <TagsList
          tags={tags}
          allowAddTags={!!isCurator}
          label="Content related to:"
          selectedTags={selectedTags}
          onUpdateTag={onUpdateTag}
          onChangeSelectedTags={(nextTags) => {
            setParams("tags", nextTags.length ? JSON.stringify(nextTags) : "");
            if (!nextTags.length) {
              setSearchRes(undefined);
            }
          }}
        />
      </Row>
      {!selectedCategoryId && (
        <MyResources
          onSelectMyResources={onSelectMyResources}
          isClinician={isClinician}
        />
      )}

      {!selectedCategoryId && (
        <CategoriesList
          allowAddCategory={!!isCurator}
          onUpdateCategory={onUpdateCategory}
          categories={searchRes?.categories || categories}
          onSelect={onSelectCategory}
          currentPage={parseInt(categoriesPage, 10)}
          onChangePage={(next) => setParams("categories_page", String(next))}
          showPagination={!searchRes?.categories}
          totalCount={totalCategories}
        />
      )}
      {(searchRes?.resources || resources.length > 0) && (
        <ResourceList
          handleDeleteResource={handleDeleteResource}
          setParams={setParams}
          resources={resources}
          onSelect={onSelectResource}
          handleSetIsFormVisible={handleSetIsFormVisible}
          updateResourceItem={updateResourceItem}
          canAddResource={
            !!selectedCategoryId &&
            selectedCategoryId !== FAVORITES_CATEGORY_ID &&
            // hiding from super admin
            !!(isClinician || isCurator)
          }
          isFormVisible={isFormVisible}
        />
      )}

      {isFormVisible && (
        <ResourceDrawer
          resourceId={selectedResourceId}
          onUpdateResource={updateResourceItem}
          handleDeleteResource={handleDeleteResource}
          onClose={() => {
            setParams("resource", "");
            handleSetIsFormVisible(false);
          }}
          setParams={setParams}
        />
      )}
    </Spin>
  );
}
