/* eslint-disable no-unreachable */
import { LoadingOutlined } from '@ant-design/icons';
import { Badge, Tooltip } from "antd";
import moment from "moment";
import * as React from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import styled from "styled-components";
import {
  BasicMatch,
  ClinicianHold,
  CouplesMatchAvailability,
  IdMap,
} from "../../api/types";
import { Row } from "../../app/_layout/Flex";
import { $green, $greyBorder, $red, $yellow } from "../../assets/colors";
import { AppState } from "../../state/models";
import { holdStatusSelector } from "../../state/models/clinicians";
import {
  volumeDataMapSelector,
  WeeklyVolumeData,
} from "../slot-tool/WeeklyVolume";
import { UtilizationStatus } from "../_helpers";
import { UserHasAllPermissions, UserHasAnyPermissions } from "../_helpers/permissions";
import { useShallowEqualSelector } from "../_helpers/redux";

const StatusPill = styled.div`
  margin-right: 10px;
  background: ${(props: any) => {
    switch (props.className) {
      case "available":
        return $green;
      case "unavailable":
      case "inactive":
        return $red;
      case "check":
        return $yellow;
      case "hold":
        return $greyBorder;
      default:
        return "transparent";
    }
  }};
  min-width: 90px;
  padding: 1.5px 5px;
  border-radius: 15px;
  text-align: center;
`;

interface Props {
  clinicianId: number;
  showCouplesAvailability?: boolean;
}

export interface Capacity {
  status: UtilizationStatus;
  couplesStatus: UtilizationStatus;
  capacity?: number;
  autoCapacity?: number;
  manualCapacity?: number;
  couplesAvailability?: CouplesMatchAvailability;
  couplesManualCapacity?: number;
  /** @deprecated */
  note?: string;
  isAvailabilityTool?: boolean;
}

function getStatus(hold?: ClinicianHold, capacity?: number) {
  if (hold) {
    const now = moment();

    if (now.isBetween(hold.start_at, hold.end_at)) {
      return UtilizationStatus.Hold;
    }
  }

  if (capacity !== undefined) {
    if (capacity > 0) {
      return UtilizationStatus.Available;
    }
    return UtilizationStatus.Unavailable;
  }

  return UtilizationStatus.Unknown;
}

function computeClinicianCapacity({
  clinicianId,
  volumes,
}: {
  clinicianId: string;
  volumes: IdMap<WeeklyVolumeData>;
}) {
  if (!volumes[clinicianId]) {
    return undefined;
  }

  const volume = volumes[clinicianId];

  return {
    autoCapacity: volume.matchesRequested,
    manualCapacity: volume.manualCapacity,
    couplesAutoCapacity: 0, // hardcoded 0 values for unsupported capacity
    couplesManualCapacity: 0,
    isAvailabilityTool: true,
  };
}

export const getClinicianCapacities = createSelector(
  (state: AppState) => state.clinicians.clinicianMap,
  (state: AppState) => state.clinicians.couplesMatchAvailability,
  (state: AppState) => holdStatusSelector(state.clinicians),
  volumeDataMapSelector,
  (clinicians, couplesMatchAvailability, holdStatusMap, volumes) => {
    const clinicianCapacities: Record<string, Capacity> = {};

    for (const clinicianId of Object.keys(clinicians)) {
      const computedCapacity = computeClinicianCapacity({
        clinicianId,
        volumes,
      });

      if (!computedCapacity) {
        continue;
      }

      const {
        autoCapacity,
        manualCapacity,
        isAvailabilityTool,
        couplesAutoCapacity,
        couplesManualCapacity,
      } = computedCapacity;

      const capacity = manualCapacity ?? autoCapacity;
      const status = getStatus(holdStatusMap[clinicianId], capacity);
      const couplesAvailability =
        couplesMatchAvailability[parseInt(clinicianId)];
      const couplesStatus = getStatus(
        holdStatusMap[clinicianId],
        couplesManualCapacity ?? couplesAutoCapacity ?? undefined,
      );

      clinicianCapacities[clinicianId] = {
        status,
        capacity,
        autoCapacity,
        manualCapacity,
        isAvailabilityTool,
        couplesAvailability,
        couplesManualCapacity,
        couplesStatus,
      };
    }

    return (clinicianId: number) =>
      clinicianCapacities[clinicianId] ?? {
        status: UtilizationStatus.Inactive,
      };
  },
);

export const useClinicianCapacity = (clinicianId: number) => {
  return useSelector(getClinicianCapacities)(clinicianId);
};

const ClinicianStatus = (props: Props) => {
  const { clinicianId, showCouplesAvailability } = props;
  const [clinicianCartMap, cuser, numMatchesInQueue] = useShallowEqualSelector(
    state => [
      state.matches.clinicianCartMap,
      state.auth.currentUser,
      state.matches.queuedMatches.filter((match: BasicMatch) => {
        const matchFits = state.matches.matchFitsMap[match.id] || [];
        return matchFits.some(fit => fit.clinician === clinicianId);
      }).length,
    ],
  );
  const clientsInCart = clinicianCartMap[clinicianId] || [];

  let {
    status,
    autoCapacity,
    manualCapacity,
    isAvailabilityTool,
    couplesAvailability,
    couplesManualCapacity,
    couplesStatus,
  } = useClinicianCapacity(clinicianId);

  if (showCouplesAvailability && couplesStatus) {
    status = couplesStatus;
  }

  const statusClass = status.toLowerCase();


  // 2024-01: currently not working since we don't pull all clinician capacities anymore
  if (!UserHasAllPermissions(cuser, ["IsMatchingAdmin"])) {
    return null;
  }

  const availability = showCouplesAvailability ? (
    UserHasAnyPermissions(cuser, [
      "IsSuperUser",
      "IsMatchingAdmin",
      "IsClinicalLeader",
    ]) && couplesManualCapacity ? (
      <>
        <Tooltip title={clientsInCart.join("\n")}>
          <div>{couplesManualCapacity}</div>
        </Tooltip>
        <div style={{ color: $greyBorder }}>
          <s>{couplesAvailability?.availability}</s>
        </div>
      </>
    ) : (
      <Tooltip title={clientsInCart.join(" ")}>{couplesAvailability?.availability}</Tooltip>
    )
  ) : UserHasAnyPermissions(cuser, [
      "IsSuperUser",
      "IsMatchingAdmin",
      "IsClinicalLeader",
    ]) && manualCapacity ? (
    <>
      <Tooltip title={clientsInCart.join("\n")}>
        <div>{manualCapacity}</div>
      </Tooltip>
      <div style={{ color: $greyBorder }}>
        <s>{autoCapacity}</s>
      </div>
    </>
  ) : (
    <Tooltip title={clientsInCart.join(" ")}>{autoCapacity}</Tooltip>
  );

  return (

    <Row layout={"flex-end center"}>
      <span
        style={{
          flex: 0,
          paddingRight: "4px",
          minWidth: isAvailabilityTool ? "38px" : "76px",
          textAlign: "right",
        }}
      >
        <Badge count={clientsInCart.length} offset={[7, -5]}>
          <b>{availability}</b>
        </Badge>
      </span>
      <StatusPill className={statusClass}>
        {statusClass === "loading" && (
          <span style={{ marginLeft: "-15px" }}>
            <LoadingOutlined />
            &nbsp;&nbsp;
          </span>
        )}
        {numMatchesInQueue && ["Available", "Check"].find(s => s === status)
          ? `Queue (${numMatchesInQueue})`
          : status}
      </StatusPill>
    </Row>
  );
};

export default ClinicianStatus;
