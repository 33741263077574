import React, { useEffect } from "react";
import {
  useMyClientsFilterStore,
  selectTreatmentTrackFiltersSlice,
  TreatmentTrackFiltersState,
  initialTreatmentTrackFilterState,
} from "../../../../../../my-clients-model";

export const useTreatmentTrackFilterDropdown = () => {
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

  const treatmentTrackFilterState = useMyClientsFilterStore(
    selectTreatmentTrackFiltersSlice,
  );

  const setMyClientsFilter = useMyClientsFilterStore(
    (state) => state.setMyClientsFilter,
  );

  const numberOfSelectedFilters = [
    ...Object.values(treatmentTrackFilterState),
  ].filter((filter) => filter).length;

  const clearTreatmentTrackFilters = () => {
    for (const key in initialTreatmentTrackFilterState) {
      setMyClientsFilter(key as keyof TreatmentTrackFiltersState, false);
    }

    setIsDropdownOpen(false);
  };

  return {
    isDropdownOpen,
    setIsDropdownOpen,
    clearTreatmentTrackFilters,

    numberOfSelectedFilters,
    setMyClientsFilter,
    ...treatmentTrackFilterState,
  };
};
