export enum CancellationType {
  CANCELED_BY_CLIENT = "Canceled by client",
  CANCELED_BY_CLINICIAN = "Canceled by clinician",
  NO_SHOW = "No show (missed session)",
  LATE_CANCEL_BY_CLIENT = "Late cancel by client",
}

export const sessionsToSelect = [
  {
    id: "1",
    label: CancellationType.CANCELED_BY_CLIENT,
    value: CancellationType.CANCELED_BY_CLIENT,
  },
  {
    id: "2",
    label: CancellationType.CANCELED_BY_CLINICIAN,
    value: CancellationType.CANCELED_BY_CLINICIAN,
  },
];

export const nonBillableCancellationTypeOptions = [
  {
    id: "1",
    label: CancellationType.CANCELED_BY_CLIENT,
    value: CancellationType.CANCELED_BY_CLIENT,
  },
  {
    id: "2",
    label: CancellationType.CANCELED_BY_CLINICIAN,
    value: CancellationType.CANCELED_BY_CLINICIAN,
  },
];

export const billableCancellationTypeOptions = [
  {
    id: "3",
    label: CancellationType.NO_SHOW,
    value: CancellationType.NO_SHOW,
  },
  {
    id: "4",
    label: CancellationType.LATE_CANCEL_BY_CLIENT,
    value: CancellationType.LATE_CANCEL_BY_CLIENT,
  },
];

export const lateCancelByClientReasons = [
  {
    id: "late-cancel-by-client-reason-illness",
    label: "Illness",
    value: "Illness",
  },
  {
    id: "late-cancel-by-client-reason-holiday",
    label: "Vacation or holiday",
    value: "Vacation or holiday",
  },
  {
    id: "late-cancel-by-client-reason-schedling-conflict",
    label: "Scheduling conflict",
    value: "Scheduling conflict",
  },
  {
    id: "late-cancel-by-client-reason-technical-issues",
    label: "Technical issues",
    value: "Technical issues",
  },
  {
    id: "late-cancel-by-client-reason-unknown",
    label: "Unknown",
    value: "Unknown",
  },
];

export const clinicianCancellationReasons = [
  {
    id: "clinician-cancellation-reason-illness",
    label: "Illness",
    value: "Illness",
  },
  {
    id: "clinician-cancellation-reason-holiday",
    label: "Vacation or holiday",
    value: "Vacation or holiday",
  },
  {
    id: "clinician-cancellation-reason-conflict",
    label: "Scheduling conflict",
    value: "Scheduling conflict",
  },
  {
    id: "clinician-cancellation-reason-technical-issues",
    label: "Technical issues",
    value: "Technical issues",
  },
  {
    id: "clinician-cancellation-reason-scheduled-error",
    label: "Scheduled in error",
    value: "Scheduled in error",
  },
  {
    id: "clinician-cancellation-reason-termination-of-care",
    label: "Termination of care",
    value: "Termination of care",
  },
  {
    id: "clinician-cancellation-unknown",
    label: "Unknown",
    value: "Unknown",
  },
];

export const clientCancellationReasons = [
  {
    id: "client-cancellation-reason-illness",
    label: "Illness",
    value: "Illness",
  },
  {
    id: "client-cancellation-reason-c",
    label: "Vacation or holiday",
    value: "Vacation or holiday",
  },
  {
    id: "client-cancellation-reason-scheduling-conflict",
    label: "Scheduling conflict",
    value: "Scheduling conflict",
  },
  {
    id: "client-cancellation-reason-pausing-care",
    label: "Pausing care",
    value: "Pausing care",
  },
  {
    id: "client-cancellation-reason-technical-issues",
    label: "Technical issues",
    value: "Technical issues",
  },
  {
    id: "client-cancellation-reason-unknown",
    label: "Unknown",
    value: "Unknown",
  },
];

export const noShowCancellationReasons = [
  {
    id: "no-show-cancellation-reason",
    label: "No show (missed session)",
    value: "No show (missed session)",
  },
];
