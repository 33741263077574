import { Flex, styledStitches, Stack, Text } from "@/app/design-system";

export const StyledScoreCard = styledStitches(Flex, {
  width: "213px",
  flexDirection: "column",
  height: 118,
  border: "0.5px solid $neutral6",
  br: 8,
  backgroundColor: "$neutral1",
});

export const StyledScoreAndBaselineCard = styledStitches(Stack, {
  width: "220px",
  flexGrow: 1,
  height: 118,
  border: "0.5px solid $neutral6",
  btrr: 8,
  btlr: 8,
  bbrr: 8,
  bblr: 8,
  backgroundColor: "$neutral1",
  alignItems: "stretch",
});

export const StyledScore = styledStitches(Text, {
  paddingTop: 5,
  fontWeight: "700",
  fontSize: "24px",
  textAlign: "center",
  alignContent: "center",
  justifyContent: "center",
});

export const StyledScoreSubText = styledStitches(Text, {
  fontSize: "13px",
  fontWeight: 500,
  paddingTop: 4,
  paddingBottom: 10,
  justifyContent: "center",
});

export const IconContainer = styledStitches(Flex, {
  pt: 10,
});

export const ScoreBoxContainer = styledStitches(Stack, {
  flexGrow: 1,
  alignItems: "stretch",
  height: "100%",
});
