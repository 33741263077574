import { MyClientsSort } from "../../../my-clients-model";

export const MOST_RELEVANT = "Most relevant";
export const FIRST_NAME = "First name";
export const LAST_NAME = "Last name";

export const sorts = [MOST_RELEVANT, FIRST_NAME, LAST_NAME];

export const deriveSortState = (
  value: string,
  currentlySelectedSort: string,
  currentSortState: MyClientsSort,
) => {
  // if it's the first click, we want to set sort first name by ascending order
  const isFirstClickOnSortByFirstName =
    value === FIRST_NAME && currentlySelectedSort !== FIRST_NAME;

  // if it's the subsequent click, we want to toggle between ascending and descending order by first name
  const isSubsequentClickOnSortByFirstName =
    value === FIRST_NAME && currentlySelectedSort === FIRST_NAME;

  // if it's the first click, we want to set sort last name by ascending order
  const isFirstClickOnSortByLastName =
    value === LAST_NAME && currentlySelectedSort !== LAST_NAME;

  // if it's the subsequent click, we want to toggle between ascending and descending order by last name
  const isSubsequentClickOnSortByLastName =
    value === LAST_NAME && currentlySelectedSort === LAST_NAME;

  if (isFirstClickOnSortByFirstName) {
    return {
      isSortingByFirstNameAscending: true,
      isSortingByFirstNameDescending: false,
      isSortingByLastNameAscending: false,
      isSortingByLastNameDescending: false,
      isSortingByMostRelevant: false,
      currentlySelectedSort: FIRST_NAME,
    };
  } else if (isSubsequentClickOnSortByFirstName) {
    return {
      ...currentSortState,
      isSortingByFirstNameAscending:
        !currentSortState.isSortingByFirstNameAscending,
      isSortingByFirstNameDescending:
        !currentSortState.isSortingByFirstNameDescending,
    };
  } else if (isFirstClickOnSortByLastName) {
    return {
      isSortingByLastNameAscending: true,
      isSortingByFirstNameAscending: false,
      isSortingByFirstNameDescending: false,
      isSortingByLastNameDescending: false,
      isSortingByMostRelevant: false,
      currentlySelectedSort: LAST_NAME,
    };
  } else if (isSubsequentClickOnSortByLastName) {
    return {
      ...currentSortState,
      isSortingByLastNameAscending:
        !currentSortState.isSortingByLastNameAscending,
      isSortingByLastNameDescending:
        !currentSortState.isSortingByLastNameDescending,
    };
  } else {
    return {
      isSortingByMostRelevant: true,
      isSortingByFirstNameAscending: false,
      isSortingByFirstNameDescending: false,
      isSortingByLastNameAscending: false,
      isSortingByLastNameDescending: false,
      currentlySelectedSort: MOST_RELEVANT,
    };
  }
};
