import { CurrentUserContext } from "@/app/app.utils";
import { Stack } from "@/app/design-system";
import { TIMEZONE } from "@/app/my-clients/utils";
import React from "react";
import { selectDataForEditEventForm } from "./_api/use-fetch-cancellation-types-and-reasons/select-data-for-cancel-client-group-event";
import { useFetchCancellationTypesAndReasons } from "./_api/use-fetch-cancellation-types-and-reasons/use-fetch-cancellation-types-and-reasons";
import { selectGroupTherapyNotesEventAttendance } from "./_api/use-group-therapy-notes-event/use-group-therapy-notes-event.util";
import { useGroupTherapyNotesEventAttendance } from "./_api/use-group-therapy-notes-page";
import { GroupDetailsNotesClientPanes } from "./_components/group-details-notes-client-panes";
import { GroupDetailsNotesHeader } from "./_components/group-details-notes-header";
import { useAllEventAttendanceToast } from "./_hooks/use-all-event-attendance-toast";

export const GroupDetailsNotes = () => {
  const cuser = React.useContext(CurrentUserContext);
  const { data, isLoading: isAttendanceLoading } =
    useGroupTherapyNotesEventAttendance({
      select: selectGroupTherapyNotesEventAttendance,
      timezone: TIMEZONE(cuser),
    });

  const { isLoading: isLoadingCancellationData } =
    useFetchCancellationTypesAndReasons({
      select: selectDataForEditEventForm,
    });

  useAllEventAttendanceToast(data);

  const isLoading = isLoadingCancellationData || isAttendanceLoading;

  return (
    <Stack
      css={{
        backgroundColor: "$neutral3",
        overflow: "hidden",
        flex: 1,
        p: 24,
      }}
    >
      <GroupDetailsNotesHeader isLoading={isLoading} />
      <GroupDetailsNotesClientPanes
        groupTherapyNotesEventData={data?.clientGroupEvents ?? []}
        eventStartTime={data?.eventStartTime ?? ""}
      />
    </Stack>
  );
};
