import { styledStitches } from "@/app/design-system/styles/stitches.config";
// Not to be confused with the Title fonts here: https://www.figma.com/file/TtoP7cvJnzUzz16jZY97oQ/Two-Chairs-Design-Library?node-id=513%3A1259&t=B1pfS84ceYezlpsD-0
// If you want those, go to design-system/titles
// These seem to be a bit 'more correct' since that is what the designers use

export const H1 = styledStitches("h1", {
  display: "block",
  fontSize: "2rem",
  color: "$neutral12",
  margin: 0,
});

export const H2 = styledStitches("h2", {
  fontSize: "1.5rem",
  display: "block",
  color: "$neutral12",
  margin: 0,

  variants: {
    fontWeight: {
      400: { fontWeight: 400 },
      500: { fontWeight: 500 },
      600: { fontWeight: 600 },
      700: { fontWeight: 700 },
    },
  },
});

export const H3 = styledStitches("h3", {
  display: "block",
  fontSize: "1.17rem",
  color: "$neutral12",
  margin: 0,
});

export const H4 = styledStitches("h4", {
  display: "block",
  fontSize: "1rem",
  color: "$neutral12",
  margin: 0,
});

export const H5 = styledStitches("h5", {
  display: "block",
  fontSize: "0.83rem",
  color: "$neutral12",
  margin: 0,
});

export const H6 = styledStitches("h6", {
  display: "block",
  fontSize: "0.67rem",
  color: "$neutral12",
  margin: 0,
});
