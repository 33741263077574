import * as React from "react";

import { Resource } from "../types";
import { Button } from "antd";
import { ResourceCategory, ResourceTitle } from "../styles";
import { Row, Column } from "../../_layout/Flex";
import { SendToClientForm } from "./SendToClient";

import { TagsList } from "../Tags/TagsList";

interface ResourceDetailsProps {
  resource: Resource;
  isDetailsView: boolean;
  handleSetDetailsView: (isDetailsView: boolean) => void;
  isClinician: boolean | null;
}

const ResourceDetails = ({
  resource,
  isDetailsView,
  handleSetDetailsView,
  isClinician,
}: ResourceDetailsProps) => {
  const resourceHeaderDetailsStyling = { fontSize: "12px", color: "#8C8C8C" };

  return (
    <Column layout="start start" style={{ padding: "16px" }}>
      {isDetailsView ? (
        <>
          <Row layout="space-between start" style={{ width: "100%" }}>
            <Column layout="flex-start flex-start">
              <ResourceTitle>{resource.title}</ResourceTitle>
            </Column>
          </Row>
          <Column
            layout="flex-start flex-start"
            style={{ marginTop: 20, marginBottom: 8 }}
          >
            <h3 style={resourceHeaderDetailsStyling}>Description</h3>
            <span style={{ whiteSpace: "pre-wrap", fontSize: "16px" }}>
              {resource.description}
            </span>
          </Column>

          <Column layout="flex-start flex-start" style={{ marginTop: 20 }}>
            <h3 style={resourceHeaderDetailsStyling}>Link To Resource</h3>
            <a
              href={resource.url}
              rel="noopener noreferrer"
              target="_blank"
              style={{
                wordBreak: "break-word",
                fontSize: "16px",
                color: "#323232",
              }}
            >
              {resource.url}
            </a>
          </Column>

          <div style={{ marginTop: "20px", marginBottom: 8 }}>
            <h3 style={resourceHeaderDetailsStyling}>Category</h3>
            <ResourceCategory
              children={resource.categories.map(c => c.name).join(", ")}
            />
          </div>

          <div style={{ marginTop: "20px", marginBottom: 8 }}>
            <h3 style={resourceHeaderDetailsStyling}>Tags</h3>
            <TagsList tags={resource.tags} />
          </div>

          {isClinician && (
            <Row
              layout="flex-end"
              style={{
                width: "100%",
                paddingRight: "16px",
                paddingLeft: "16px",
              }}
            >
              <Button
                type="primary"
                style={{
                  height: "40px",
                  borderRadius: "4px",
                }}
                onClick={() => handleSetDetailsView(false)}
              >
                Share with Client
              </Button>
            </Row>
          )}
        </>
      ) : (
        <SendToClientForm
          resource={resource}
          handleSetDetailsView={handleSetDetailsView}
        />
      )}
    </Column>
  );
};

export { ResourceDetails };
