import { IconProps } from "../icon.interface";

export const UtilizationFlagIcon = ({
  className,
  height = 24,
  width = 24,
  title,
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      fill="#6E56CF"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden={!title}
      className={className}
    >
      {title ? <title>{title}</title> : null}
      <circle cx="12" cy="12" r="12" fill="#6E56CF"/>
      <g clipPath="url(#clip0_265_4423)">
        <path d="M8.25 13.6875C8.25 13.6875 8.8125 13.125 10.5 13.125C12.1875 13.125 13.3125 14.25 15 14.25C16.6875 14.25 17.25 13.6875 17.25 13.6875V6.9375C17.25 6.9375 16.6875 7.5 15 7.5C13.3125 7.5 12.1875 6.375 10.5 6.375C8.8125 6.375 8.25 6.9375 8.25 6.9375V13.6875ZM8.25 13.6875V17.625" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
      <clipPath id="clip0_265_4423">
        <rect x="6" y="5.25" width="13.5" height="13.5" rx="6.75" fill="#6E56CF"/>
      </clipPath>
      </defs>
    </svg>
  );
};
