import { CurrentUserContext } from "@/app/app.utils";
import { useGetEpisodesOfCare } from "@/app/client/use-mbc/use-get-mbc-episodes-of-care";
import { UnsortableDashboardTable } from "@/app/dashboard2.0/_components/dashboard-table";
import { styledStitches } from "@/app/design-system";
import { attachTotalSessionsAttendedWithCurrentClinicianToClients } from "@/app/eoc-utils";
import { useMyClientsSearchStore } from "@/app/my-clients/routes/my-clients-page/my-clients-model/my-clients.model";
import { flexRender } from "@tanstack/react-table";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ClientTodayApiData } from "../_models/clients-today.interface";
import { logAnalytics } from "../clients-today/utils";
import { table } from "./clients-today-table-columns";
import { ScreenReaderOnlyText } from "@/app/_shared/ScreenReaderOnlyText";

interface ClientsTodayTableProps {
  isLoading: boolean;
  isEocDataAvailable: boolean;
  clients: ClientTodayApiData[];
  clinicianId?: number;
}

interface EOCClientsTodayTableContainerProps {
  isLoading: boolean;
  clients: ClientTodayApiData[];
  clinicianId?: number;
}

interface EocSessionData {
  is_latest: boolean;
  appointment_status: string;
  clinician_session_number: number;
  client_id: number;
  clinician_id: number;
  start_time: string;
}

export interface ClientEocSessionData extends EocSessionData {
  [propName: string]: any;
}

const SESSION_NUMBER = "Session #";
const APPT_TIME = "startTime";

export const EOCClientsTodayTableContainer = ({
  isLoading,
  clients,
  clinicianId: selectedClinicianId,
}: EOCClientsTodayTableContainerProps) => {
  let clientIds = [];
  clientIds = clients.map((client) => {
    return client.clientId;
  });

  const clientIdsAsNumbers: Array<number> = clientIds.filter(
    (clientId): clientId is number => {
      return !!clientId;
    },
  );
  const { data: clientEOCData, isLoading: isEOCDataLoading } =
    useGetEpisodesOfCare({
      clientIds: clientIdsAsNumbers ?? [],
      enabled: true,
      select: (data) => {
        return data.dw_export_episode_of_care;
      },
    });
  let updatedClients = undefined;
  if (!isEOCDataLoading && clientEOCData) {
    updatedClients = attachTotalSessionsAttendedWithCurrentClinicianToClients(
      clients,
      clientEOCData,
      selectedClinicianId,
    ) as ClientTodayApiData[];
  }

  return (
    <ClientsTodayTable
      isLoading={isLoading}
      isEocDataAvailable={!!updatedClients}
      clients={updatedClients ?? clients}
      clinicianId={selectedClinicianId}
    />
  );
};

export const ClientsTodayTable = ({
  isLoading,
  isEocDataAvailable,
  clients,
  clinicianId: selectedClinicianId,
}: ClientsTodayTableProps) => {
  const cuser = React.useContext(CurrentUserContext);

  const { setCurrentlySelectedClinician } = useMyClientsSearchStore();
  const navigate = useNavigate();
  const navToClient = (clientId: number) => {
    if (selectedClinicianId) {
      setCurrentlySelectedClinician(selectedClinicianId);
    }
    navigate(`/my-clients/${clientId}/mbc?clinician_id=${selectedClinicianId}`);
  };

  const [data, setData] = React.useState(() => [...clients]);
  useEffect(() => {
    setData(clients);
  }, [clients]);

  return (
    <ClientsTodayTableDashboardTable>
      <thead>
        {table({
          data,
          cuser,
          isLoading,
          isEocDataAvailable,
          selectedClinicianId,
        })
          .getHeaderGroups()
          .map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <>
                  {header.column.id === SESSION_NUMBER ||
                  header.column.id === APPT_TIME ? (
                    <ClientsTableSectionNumberColumnTh
                      key={header.id}
                      className={header.column.id}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                    </ClientsTableSectionNumberColumnTh>
                  ) : (
                    <th key={header.id} className={header.column.id}>
                      {header.isPlaceholder ? null : header.column.id ===
                        "clientId" ? (
                        <ScreenReaderOnlyText>Client Link</ScreenReaderOnlyText>
                      ) : (
                        flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )
                      )}
                    </th>
                  )}
                </>
              ))}
            </tr>
          ))}
      </thead>
      <tbody>
        {table({
          data,
          cuser,
          isLoading,
          isEocDataAvailable,
          selectedClinicianId,
        })
          .getRowModel()
          .rows.map((row) => (
            <tr
              key={row.id}
              onClick={() => {
                logAnalytics("client_row_clicked");
                // @ts-ignore
                navToClient(row.original.clientId);
              }}
            >
              {row.getVisibleCells().map((cell) => {
                return (
                  <>
                    {cell.column.id === SESSION_NUMBER ? (
                      <ClientsTableSectionNumberColumnTd key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </ClientsTableSectionNumberColumnTd>
                    ) : (
                      <td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </td>
                    )}
                  </>
                );
              })}
            </tr>
          ))}
      </tbody>
    </ClientsTodayTableDashboardTable>
  );
};

const ClientsTodayTableDashboardTable = styledStitches(
  UnsortableDashboardTable,
  {
    "thead > tr > th:last-of-type": {
      width: "56px",
    },
    "tbody > tr > td:last-of-type": {
      width: "56px",
    },
    "tbody > tr > td:not(first-of-type)": {
      padding: "10px 8px",
    },
  },
);

const ClientsTableSectionNumberColumnTd = styledStitches("td", {
  width: "11.5%",
});

const ClientsTableSectionNumberColumnTh = styledStitches("th", {
  width: "11.5%",
});
