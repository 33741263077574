import * as React from "react";
import { useShallowEqualSelector } from "../../../../_helpers/redux";
import { ConsultNoteQuestion } from "../../../../../api/types";
import QuestionTitle from "../shared/QuestionTitle";
import { PresentingProblemsSubQuestion } from "./PresentingProblemsSubQuestion";
import { range } from "lodash";

const ENTRY_COUNT = 5;

interface Props {
  question: ConsultNoteQuestion;
  handleEdit: (hasBeenEdited: boolean) => void;
}

export default ({ question, handleEdit }: Props) => {
  const [presentingproblems] = useShallowEqualSelector((state) => [
    state.consultnote?.openConsult?.consult_note?.presentingproblems || [],
  ]);

  return (
    <div style={{ paddingTop: "15px", paddingBottom: "15px" }}>
      <QuestionTitle
        questionNumber={question.questionNumber}
        titleText={question.title}
        subtitleText={question.subtitle}
        isRequired={question.isRequired}
      />

      {range(ENTRY_COUNT).map((num) => {
        const orderNumber = num + 1;

        const entry = presentingproblems.find((su) => su.order === orderNumber);

        return (
          <PresentingProblemsSubQuestion
            key={entry?.id}
            entry={entry}
            orderNumber={orderNumber}
            handleEdit={handleEdit}
          />
        );
      })}
    </div>
  );
};
