import { Variables, gql, GraphQLClient } from "graphql-request";
import { API_URL } from "./constants";
import { setLogger } from "react-query";
import * as Sentry from "@sentry/react";

import Cookies from "js-cookie";

export const graphqlClient = new GraphQLClient(`${API_URL}/gql/v1/graphql/`, {
  credentials: "include",
  headers: {
    "X-CSRFTOKEN": Cookies.get("csrftoken") as string,
  },
});

setLogger({
  log: message => {
    Sentry.captureMessage(message);
  },
  warn: message => {
    Sentry.captureMessage(message, "warning")
  },
  error: error => {
    Sentry.captureException(error);
  },
})
