import { Tag } from "@/app/design-system";

import { MatchingInfoByClientIdApiData } from "../../../../../api/use-matching-data-by-clients/select-data-for-matching-data-by-client.util";

export enum ClientMatchingStatus {
  Prospective = "Prospective",
  Pending = "Pending",
  New = "New",
  Active = "Active",
  Inactive = "Inactive",
}

interface MatchingStatusBadgeProps {
  clientMatchingData: MatchingInfoByClientIdApiData | null;
}

export const MatchingStatusBadge = ({
  clientMatchingData,
}: MatchingStatusBadgeProps) => {
  if (clientMatchingData) {
    const matchingStatusBadgeMap = {
      [ClientMatchingStatus.Pending]: (
        <Tag color={"orange"}>Pending {clientMatchingData.expiresAt}</Tag>
      ),
      [ClientMatchingStatus.Prospective]: (
        <Tag color={"yellow"}>Prospective</Tag>
      ),
      [ClientMatchingStatus.New]: <Tag color={"green"}>New</Tag>,
      [ClientMatchingStatus.Inactive]: <Tag>Inactive</Tag>,
      [ClientMatchingStatus.Active]: null,
    };

    return <>{matchingStatusBadgeMap[clientMatchingData.matchingStatus]}</>;
  }

  return null;
};
