export const UPCOMING_MY_CONSULTS_VALUE = "Upcoming Consults";
export const SEARCH_ALL = "Search All";
export const PAST_MY_CONSULTS_VALUE = "Past Consults";
export const INCOMPLETE_MY_CONSULTS_VALUE = "Incomplete Consults";
export const IN_QUEUE_STATUS = "In Queue";

export const INIT_FILTERS = {
  client_status: [
    { label: "In Queue", value: false },
    { label: "Rematched", value: false },
    { label: "Did Not Convert", value: false },
    { label: "Active", value: false },
    { label: "Scheduled", value: false },
    { label: "Prospective Match", value: false },
    { label: "Pending Match", value: false },
    { label: "Referred Out", value: false },
    { label: "Delayed", value: false },
  ],
  month: [
    { label: "January", value: false },
    { label: "February", value: false },
    { label: "March", value: false },
    { label: "April", value: false },
    { label: "May", value: false },
    { label: "June", value: false },
    { label: "July", value: false },
    { label: "August", value: false },
    { label: "September", value: false },
    { label: "October", value: false },
    { label: "November", value: false },
    { label: "December", value: false },
  ],
  year: [
    { label: "2023", value: false },
    { label: "2022", value: false },
  ],
};
