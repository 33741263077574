import { GroupTherapyMbcEventsQuery } from "@/graphql/generated";
import { useParams } from "react-router-dom";
import { useGroupTherapyMbcEvent } from "./use-group-therapy-mbc-events/use-group-therapy-mbc-events.api";
import { selectGroupTherapyMbcEventScores } from "./use-group-therapy-mbc-events/use-group-therapy-mbc-events.util";

interface UseGroupTherapyMbcEventScoresParams<T> {
  select: (data: GroupTherapyMbcEventsQuery) => T;
}

export const useGroupTherapyMbcEventScores = <T extends object>({
  select,
}: UseGroupTherapyMbcEventScoresParams<T>) => {
  const { groupId } = useParams<{ groupId: string }>();

  const enabled = !!groupId;

  const { data, isLoading } = useGroupTherapyMbcEvent({
    enabled,
    groupId,
    select: (data) => select(data),
  });

  return { data, isLoading };
};
