export enum SessionCadence {
  Biweekly = "biweekly",
  Weekly = "weekly",
}

export enum AppointmentStatus {
  Attended = "attended",
  LateCancel = "lateCancel",
  Cancel = "cancel",
}
