import { IconProps } from "../icon.interface";

export const CollapseIcon = ({ title }: IconProps) => {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden={!title}
    >
      {title ? <title>{title}</title> : null}

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.70711 0.292893C7.09763 0.683417 7.09763 1.31658 6.70711 1.70711L2.41421 6L6.70711 10.2929C7.09763 10.6834 7.09763 11.3166 6.70711 11.7071C6.31658 12.0976 5.68342 12.0976 5.29289 11.7071L0.292893 6.70711C-0.0976311 6.31658 -0.0976311 5.68342 0.292893 5.29289L5.29289 0.292893C5.68342 -0.0976311 6.31658 -0.0976311 6.70711 0.292893ZM13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L9.41421 6L13.7071 10.2929C14.0976 10.6834 14.0976 11.3166 13.7071 11.7071C13.3166 12.0976 12.6834 12.0976 12.2929 11.7071L7.29289 6.70711C6.90237 6.31658 6.90237 5.68342 7.29289 5.29289L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893Z"
        fill="#171717"
      />
    </svg>
  );
};
