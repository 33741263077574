import { Spinner, Stack, Text } from "@/app/design-system";
import { QuarterCircle } from "@/app/design-system";
import { NavInfoContainer } from "@/app/_components/secondary-nav/nav-info.styled";
interface GroupClientDetailsNavInfoProps {
  sessionsAttended: number;
  totalSessions: number | undefined;
  groupTitle: string | undefined;
  payer: string | undefined;
  phoneNumber: string | null | undefined;
  email: string | undefined;
  address: string | null | undefined;
  groupId: string | undefined;
  isError: boolean;
  isLoading: boolean;
}
export const GroupClientDetailsNavInfo = ({
  sessionsAttended,
  totalSessions,
  groupTitle,
  payer,
  phoneNumber,
  email,
  address,
  isError,
  isLoading,
}: GroupClientDetailsNavInfoProps) => {
  return (
    <Stack css={{ p: 5 }}>
      {isError ? (
        <Stack
          css={{
            backgroundColor: "$red3",
            flexGrow: 1,
            br: 6,
            border: "1px solid $red11",
          }}
          gap={8}
          alignItems="center"
          justifyContent={"center"}
        >
          <Text color={"$red11"} fontWeight={600}>
            Error loading group info.
          </Text>
        </Stack>
      ) : null}
      {isLoading ? (
        <Spinner>
          <QuarterCircle dimension={"2em"}></QuarterCircle>
        </Spinner>
      ) : null}
      {!isLoading && !isError ? (
        <>
          <Text fontSize={14} css={{ pb: 12, textDecoration: "underline" }}>
            {email}
          </Text>
          {address ? (
            <Text fontSize={14} css={{ pb: 12 }}>
              {address}
            </Text>
          ) : null}
          <Text fontSize={14} css={{ pb: 12 }}>
            {phoneNumber}
          </Text>
          <NavInfoContainer>
            <Text color={"$neutral9"} fontSize={14}>
              Sessions
            </Text>
            <Text fontSize={14}>
              {sessionsAttended} out of {totalSessions}
            </Text>
          </NavInfoContainer>
          <NavInfoContainer>
            <Text color={"$neutral9"} fontSize={14}>
              Payer
            </Text>
            <Text fontSize={14}>{payer}</Text>
          </NavInfoContainer>
          <NavInfoContainer>
            <Text color={"$neutral9"} fontSize={14}>
              Group
            </Text>
            <Text fontSize={14} css={{ textAlign: "end" }}>
              {groupTitle}
            </Text>
          </NavInfoContainer>
        </>
      ) : null}
    </Stack>
  );
};
