import { useGroupTherapyUser } from "../_hooks/use-group-therapy-user";
import { useGroupTherapyOverview } from "@/app/groups/_api/use-group-therapy-overview/use-group-therapy-overview.api";
import {
  GroupOverviewApiData,
  GroupsSIEnabledByGroupData,
} from "@/app/groups/_api/group-therapy.interface";
import { selectGroupTherapyOverviewData } from "@/app/groups/_api/use-group-therapy-overview/use-group-therapy-overview.util";
import { useMyClientsSearchStore } from "@/app/my-clients/routes/my-clients-page/my-clients-model/my-clients.model";
import { useGroupSIDataByClinician } from "@/app/groups/_api/use-group-si-data-by-clinician.api";
import { TIMEZONE } from "@/app/my-clients/utils";
import React from "react";
import { CurrentUserContext } from "@/app/app.utils";

export const useGroupTherapyPage = () => {
  const { clinicianId } = useGroupTherapyUser();
  const cuser = React.useContext(CurrentUserContext);
  const { currentlySelectedClinician } = useMyClientsSearchStore();

  const clinicianIdToQueryBy = currentlySelectedClinician
    ? currentlySelectedClinician
    : clinicianId;

  const enabled = !!clinicianIdToQueryBy;

  const { data: endorsedSIData, isLoading: isSIDataLoading } =
    useGroupSIDataByClinician({ clinicianId: clinicianIdToQueryBy, enabled });

  const {
    data: groupTherapyOverviewData,
    isLoading: isGroupTherapyOverviewLoading,
  } = useGroupTherapyOverview({
    enabled: !isSIDataLoading,
    clinicianId: clinicianIdToQueryBy,
    select: (data) => {
      return selectGroupTherapyOverviewData(
        data.groupsByClinician,
        endorsedSIData as GroupsSIEnabledByGroupData,
        TIMEZONE(cuser),
      );
    },
  });

  const isLoading = isGroupTherapyOverviewLoading || isSIDataLoading;

  return { groupTherapyOverviewData, isLoading };
};

export const getGroupFromGroupTherapyPage = (
  groupTherapyOverviewData: GroupOverviewApiData[] | undefined,
  groupId: string | undefined,
): GroupOverviewApiData | undefined => {
  if (!groupTherapyOverviewData || !groupId) {
    return undefined;
  }
  return groupTherapyOverviewData.find((group) => {
    return group.groupId === groupId;
  });
};
