import { Flex } from "@/app/_layout/Flex";
import { styledStitches } from "@/app/design-system/styles/stitches.config";
import { Link } from "react-router-dom";

const groupPaneStyles = {
  width: "100%",
  background: "white",
  p: 20,
  pr: 10,
  pt: 25,
  cursor: "pointer",
  minHeight: 75,
  align: "center",
  gap: 14,
  borderBottom: "0.5px solid $neutral8",

  "&:first-child": {
    btrr: 8,
    btlr: 8,
  },

  "&:last-child": {
    bbrr: 8,
    bblr: 8,
    borderBottom: "none",
  },
};

const groupPaneHoverStyles = {
  "&:hover": {
    transition: "background-color ease-in-out",
    transitionDuration: "150ms",
    backgroundColor: "$neutral2",

    ".group-name": {
      color: "$blue11",
    },

    ".chevron-right": {
      display: "inline",
      transition: "transform ease-in-out",
      transform: "translateX(5px)",
    },
  },
};

export const StyledGroupPaneLink = styledStitches(Link, {
  ...groupPaneStyles,
  ...groupPaneHoverStyles,
});

export const StyledGroupPaneDiv = styledStitches("div", {
  ...groupPaneStyles,

  cursor: "auto",

  ".chevron-right": {
    display: "none",
  },
});

export const GroupPaneContainer = styledStitches(Flex, {
  flexGrow: 1,
  backgroundColor: "$neutral3",
  fontFamily: "Inter, arial",
  minWidth: 500,
  height: "100%",
});
