import api from "@/api";
import { useMutation } from "react-query";
import { transformDateAndTimeString } from "../../routes/client-profile-page/client-profile-nav/routes/schedule-page/components/session-actions/upsert-session-action/upsert-session-form/upcoming-conflicts/use-future-conflicts.api";
import { UpsertSessionFormFields } from "../../routes/client-profile-page/client-profile-nav/routes/schedule-page/components/session-actions/upsert-session-action/upsert-session-form/use-upsert-session-form";

export interface DirtyUpsertSessionFormFields {
  recurrence: boolean;
  sessionTime: boolean;
  sessionDate: boolean;
  sessionFormat: boolean;
}
interface PatchEventArgs {
  id: string;
  data: UpsertSessionFormFields;
  dirtyFields: DirtyUpsertSessionFormFields;
  isUpdatingAllFutureSessions: boolean;
  timezone: string;
}

export function editEvent({
  id,
  data,
  dirtyFields,
  isUpdatingAllFutureSessions,
  timezone
}: PatchEventArgs) {
  try {
    const { sessionDate, sessionTime, sessionFormat, recurrence } = data;

    const {
      sessionDate: isSessionDateFieldDirty,
      sessionTime: isSessionTimeFieldDirty,
      sessionFormat: isSessionFormatFieldDirty,
      recurrence: isRecurrenceFieldDirty,
    } = dirtyFields;

    const isSessionTimeFieldOrSessionDateFieldDirty =
      isSessionTimeFieldDirty || isSessionDateFieldDirty;

    const combinedDateTime = transformDateAndTimeString(
      sessionDate,
      sessionTime,
      timezone
    );

    const delivery = sessionFormat === "Virtual" ? "teletherapy" : "in_person";

    const editEventPatchBody = {
      ...(isSessionTimeFieldOrSessionDateFieldDirty && {
        start_time: combinedDateTime?.toISO(),
        end_time: combinedDateTime?.plus({ minutes: 50 }).toISO(),
      }),

      ...(isSessionFormatFieldDirty && {
        delivery,
      }),
    };

    const isUpdatingRecurrence =
      isRecurrenceFieldDirty || isUpdatingAllFutureSessions;

    const updateRecurrenceQueryParam = isUpdatingRecurrence
      ? `?update_recurrence=${recurrence}`
      : "";

    console.log(
      `[editEvent] PATCH eventId: ${id}\n[editEvent] Request body:`,
      editEventPatchBody,
      `\n[editEvent] Params:`,
      updateRecurrenceQueryParam ? updateRecurrenceQueryParam : "none",
    );

    return api.patch(
      `/api/care_platform/events/v1/${id}/${updateRecurrenceQueryParam}`,
      editEventPatchBody,
    );
  } catch (e) {
    console.error(`[editEvent] Unable to edit event: ${e}`);
    throw new Error(`[editEvent] Unable to edit event: ${e}`);
  }
}

export function useUpdateSession() {
  return useMutation(editEvent);
}
