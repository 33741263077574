import { CalendarOutlined } from "@ant-design/icons";
import { AxiosResponse } from "axios";
import moment from "moment";
import * as React from "react";
import * as ReactRedux from "react-redux";
import styled from "styled-components";

import { ClinicianUtilization } from "../../../api/types";
import { $greyText } from "../../../assets/colors";
import { AppState, Dispatcher } from "../../../state/models";
import * as panelOperations from "../../../state/models/panel-management/operations";
import { ClinicianWeeksMap, PanelSessionsMap } from "../types";
import { ModalContent, ModalSection, ModalTitle } from "./_shared";
import { NoteTextArea } from "./ModalTextArea";

interface WeekModalStateProps {
  sessionsMap: PanelSessionsMap;
  weeksMap: ClinicianWeeksMap;
  clinicianUtilization: { [id: number]: ClinicianUtilization };
  clinicianId: number;
}

interface WeekModalOwnProps {
  dataId: string;
}

interface WeekModalDispatchProps {
  updateWeekMeta: (id: string, noteContent: string) => Promise<AxiosResponse>;
}

function _WeekModal(
  props: WeekModalOwnProps & WeekModalStateProps & WeekModalDispatchProps,
) {
  const week = props.weeksMap[props.dataId];
  const weekMoment = moment()
    .set({ year: week.year, week: week.week_number, hour: 12 })
    .day("Sunday");

  const [weekNumber, setWeekNumber] = React.useState(week.week_number);
  if (week.week_number !== weekNumber) {
    setWeekNumber(week.week_number);
  }

  const sessionCounts = {
    attended: 0,
    canceled: 0,
    noshow: 0,
    scheduled: 0,
  };

  week.sessions
    .map((id) => props.sessionsMap[id])
    .forEach((session) => {
      const status = session.appointment[0].appointment_status;
      if (status === null) {
        sessionCounts.scheduled++;
      } else {
        sessionCounts[status]++;
      }
    });

  return (
    <ModalContent key={props.dataId}>
      <ModalTitle>
        <h3>
          {weekMoment.format("MMMM DD")} -{" "}
          {weekMoment.day("Saturday").format("MMMM DD, YYYY")}
        </h3>
        <h4>Week {week.week_number}</h4>
      </ModalTitle>
      <ModalSection>
        <Row>
          <div
            style={{ fontSize: "16pt", verticalAlign: 0, marginRight: "0.5em" }}
          >
            <CalendarOutlined />
          </div>
          <div style={{ fontSize: "12pt" }}>
            <span>{sessionCounts.attended} attended therapy sessions</span>
            {sessionCounts.scheduled !== 0 && (
              <span>, {sessionCounts.scheduled} scheduled</span>
            )}
            <div style={{ color: $greyText }}>
              {sessionCounts.noshow} no-show
              {sessionCounts.noshow === 1 ? "" : "s"}
            </div>
          </div>
        </Row>
      </ModalSection>
      <NoteTextArea
        initialText={week.note_content}
        updateFn={(note: string) => props.updateWeekMeta(week.id, note)}
      />
    </ModalContent>
  );
}

const Row = styled.div`
  display: flex;
`;

// const UtilizationContainer = styled.div`
//   display: flex;
//   justify-content: space-between;
//   > div {
//     flex: 1;
//     text-align: center;
//   }

//   h4 {
//     color: ${$greyText};
//   }
// `;

// const UtilizationNumber = styled.span`
//   font-size: 24pt;
//   margin: 0 0.2em 0 0;
// `;

const mapDispatchToProps: ReactRedux.MapDispatchToProps<
  WeekModalDispatchProps,
  WeekModalOwnProps
> = (dispatch: Dispatcher, ownProps) => ({
  updateWeekMeta: (id: string, noteContent: string) =>
    dispatch(panelOperations.setSessionOrWeekNote("week", id, noteContent)),
});

const mapStateToProps: ReactRedux.MapStateToProps<
  WeekModalStateProps,
  WeekModalOwnProps,
  AppState
> = (state) => {
  return {
    clinicianId: state.panel.clinicianId!,
    clinicianUtilization: state.clinicians.utilizationMap,
    sessionsMap: state.panel.sessionsMap,
    weeksMap: state.panel.weeksMap,
  };
};

const WeekModal = ReactRedux.connect(
  mapStateToProps,
  mapDispatchToProps,
)(_WeekModal);

export default WeekModal;
