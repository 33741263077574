import { ColumnsType } from "antd/es/table";
import { SortOrder } from "antd/lib/table/interface";

import { alphabetizeBy } from "../../../../_helpers";
import { useUserHasAnyPermissions } from "@/app/_helpers/permissions";

import { AQMTuningParamsBase } from "../../../../../api/types";

interface UseGetAQMScoreTableColumnsParams {
  componentColumns: ComponentColumnType[];
}
interface RowItem extends AQMTuningParamsBase<number> {
  clinician: string;
  timeSlot: string;
  bestSlot: string;
  score: number;
  modality: string;
  // Used to QA reopening_beta, may want to remove after
  work_from_home: string;
}

interface ComponentColumnType {
  title: string;
  dataIndex: string;
  render: (score: number) => number;
}

export function useGetAQMScoreTableColumns({
  componentColumns,
}: UseGetAQMScoreTableColumnsParams) {

  const columns: ColumnsType<RowItem> = [
    {
      title: "Clinician",
      dataIndex: "clinician",
      key: "clinician",
      sorter: (a: RowItem, b: RowItem) => {
        return alphabetizeBy(a, b, "clinician");
      },
    },
    {
      title: "Time Slot",
      dataIndex: "timeSlot",
      key: "timeSlot",
      sorter: (a: RowItem, b: RowItem) => {
        return alphabetizeBy(a, b, "timeSlot");
      },
    },
    {
      title: "Modality",
      dataIndex: "modality",
      key: "modality",
      filters: [
        { text: "Hybrid", value: "Hybrid" },
        { text: "Remote", value: "Remote" },
      ],
      onFilter: (value: any, rowItem: RowItem) =>
        rowItem.modality === value,
    },
    {
      title: "WFH",
      dataIndex: "work_from_home",
      key: "work_from_home",
      filters: [
        { text: "Yes", value: "Yes" },
        { text: "No", value: "No" },
      ],
      onFilter: (value: any, rowItem: RowItem) =>
        rowItem.work_from_home === value,
    },
    {
      title: "Best Slot",
      dataIndex: "bestSlot",
      key: "bestSlot",
      filters: [{ text: "Yes", value: "Yes" }],
      onFilter: (value: any, rowItem: RowItem) => rowItem.bestSlot === value,
    },
    {
      title: "Score",
      dataIndex: "score",
      defaultSortOrder: "descend" as SortOrder,
      key: "score",
      sorter: (a: RowItem, b: RowItem) => {
        return a.score - b.score;
      },
    },
    ...componentColumns,
  ];

  return { columns };
}
