import { CSS_COLORS, styledStitches } from "@/app/design-system";
import { STANDARD_FOCUS_OUTLINE } from "@/app/design-system/styles/config/css-constants";
import React, { forwardRef } from "react";
import { NavLink, NavLinkProps } from "react-router-dom";

const navLinkActiveStyle = {
  transform: "scale(0.99)",
  transitionDuration: "100ms",
  transitionProperty: "border-right",
  backgroundColor: CSS_COLORS.brandYellow,
  borderRight: "6px solid black",
  fontWeight: 500,
};

const StyledNavLink = styledStitches(NavLink, {
  display: "flex",
  alignItems: "stretch",
  gap: 12,
  backgroundColor: "$neutral0",
  border: "none",
  py: 10,
  px: 16,
  cursor: "pointer",
  br: 6,
  boxSizing: "border-box",
  justifyContent: "flex-start",

  "&:hover": {
    backgroundColor: "$neutral3",
    transitionDuration: "100ms",
    transitionProperty: "background-color",
  },
  "&:focus": {
    outline: `${STANDARD_FOCUS_OUTLINE}`,
  },
  variants: {
    isCollapsed: {
      true: {
        justifyContent: "center",
        px: 8,
      },
    },
  },
});

interface NavigationLinkProps extends NavLinkProps {
  isCollapsed: boolean;
}

export const NavigationLink = forwardRef<
  HTMLAnchorElement,
  NavigationLinkProps
>(({ children, ...props }, forwardedRef) => {
  const { isCollapsed } = props;
  return (
    <StyledNavLink
      ref={forwardedRef}
      {...props}
      isCollapsed={isCollapsed}
      style={({ isActive }) => (isActive ? navLinkActiveStyle : undefined)}
    >
      {children}
    </StyledNavLink>
  );
});
