import { DateTime } from "luxon";
import moment from "moment";
import "moment-timezone";

/**
 * @function getSundayStartOfWeek
 * @param dateTime
 * @returns The Sunday before the start of the week. We need this when converting Luxon to Moment because luxon sets Monday to be the start of the week.
 * Visit the following issue for more details: https://github.com/moment/luxon/issues/373
 */
export const getSundayStartOfWeek = (dateTime: DateTime) => {
  return dateTime
    .minus({ days: dateTime.weekday === 7 ? 0 : dateTime.weekday })
    .startOf("day");
};

/**
 * @function getSundayStartOfNextWeek
 * @param dateTime
 * @returns The Sunday before the start of the next week. We need this when converting Luxon to Moment because luxon sets Monday to be the start of the week.
 * Visit the following issue for more details: https://github.com/moment/luxon/issues/373
 */
export const getSundayStartOfNextWeek = (dateTime: DateTime) => {
  return dateTime
    .plus({ weeks: 1 })
    .minus({ days: dateTime.weekday === 7 ? 0 : dateTime.weekday })
    .startOf("day");
};

/**
 * @function getSaturdayEndOfWeek
 * @param dateTime
 * @returns The Saturday at the end of the week. We need this because luxon sets Monday to be the start of the week, which means luxon will consider Sunday the end of the week (as opposed to Saturday)
 * Visit the following issue for more details: https://github.com/moment/luxon/issues/373
 */
export const getSaturdayEndOfWeek = (dateTime: DateTime) => {
  return dateTime.endOf("week").minus({ days: 1 }).startOf("day");
};

const EPOCH_WEEK = moment.utc(0).startOf("isoWeek");

export const STANDARD_DATETIME_PICKER_START = getSundayStartOfWeek(
  DateTime.now().minus({ weeks: 12 }).startOf("week"),
);
export const STANDARD_DATETIME_PICKER_END = getSaturdayEndOfWeek(
  DateTime.now().minus({ weeks: 0 }).endOf("week"),
);
export const TIME_TAG_FORMAT = "yyyy-MM-dd";

// If a nullish value is received, we default to the current date
export const convertLuxonToMoment = (
  luxonDateTime: DateTime,
): moment.Moment => {
  if (!luxonDateTime) return moment();
  return moment(luxonDateTime.toISO());
};

// If a nullish value is received, we default to the current date
export const convertMomentToLuxon = (
  momentDateTime: moment.Moment,
): DateTime => {
  if (!momentDateTime) return DateTime.now();
  return DateTime.fromISO(momentDateTime.toISOString());
};

export function weeksSinceEpoch(timeValue: moment.Moment | string | DateTime) {
  if (timeValue instanceof DateTime) {
    return Math.floor(
      timeValue
        .startOf("week")
        .setZone("utc")
        .diff(DateTime.fromMillis(0, { zone: "UTC" }).startOf("week"), "weeks")
        .toObject()["weeks"]!,
    );
  }

  const time =
    typeof timeValue === "string" ? moment(timeValue) : timeValue.clone();

  return time
    .startOf("isoWeek")
    .utc() // get a date without any offset
    .diff(EPOCH_WEEK, "week");
}

export function timeIsOnOddWeek(timeValue: moment.Moment | string | DateTime) {
  return weeksSinceEpoch(timeValue) % 2 !== 0;
}

export function getFirstPrecedingMondayFromDateThreeMonthsAgoOrDateTime(
  // @ts-ignore
  dateTime,
) {
  const threeMonthsAgo = new Date();
  threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
  const firstPrecedingMonday = new Date(threeMonthsAgo.getTime());

  // Find the first preceding Monday from three months ago
  while (firstPrecedingMonday.getDay() !== 1) {
    firstPrecedingMonday.setDate(firstPrecedingMonday.getDate() - 1);
  }

  if (new Date(dateTime) > firstPrecedingMonday) {
    return DateTime.fromJSDate(firstPrecedingMonday);
  } else {
    return dateTime;
  }
}
