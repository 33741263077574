import { getRoofDogBaseUrl } from "./_helpers";

const RedirectComponent = () => {
  const { pathname, search } = window.location;
  const baseUrl = getRoofDogBaseUrl();
  const newPath = pathname;
  const newSearch = search && search.length ? `${search}` : "";

  window.location.replace(`${baseUrl}${newPath}${newSearch}`);
  return null;
};

export default RedirectComponent;
