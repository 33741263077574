import { EventApiData } from '@/app/api/use-my-clients-overview';
import { DataWithSessionCount } from "@/app/library/types";
import { Dw_Export_Episode_Of_Care } from "@/graphql/generated";
import { DateTime } from "luxon";
import {
  EOCClientEvent,
  EOCClinicianData,
  EOCClinicianSubEpisodeSummary,
  EOCEpisodeData,
} from "./types";

/**
 * @name getEpisodeNumberToClinicianDataMap
 * @param sortedMergedEventData The merged EOC BigQuery and mbcScoresByClient response, sorted by date
 * @summary Returns a map to easily get EOCs, clinicians within each EOC, and summarized data related to the clinicians' session history with a client
 *  */
export const getEpisodeNumberToClinicianDataMap = (
  sortedMergedEventData: any[],
): Map<number, EOCEpisodeData> => {
  let episodeToClinicianDataMap: Map<number, EOCEpisodeData> = new Map<
    number,
    EOCEpisodeData
  >();

  for (
    let mergedDataIndex = 0;
    mergedDataIndex < sortedMergedEventData.length;
    mergedDataIndex++
  ) {
    const mergedEocDatum = sortedMergedEventData[mergedDataIndex];

    if (
      mergedEocDatum.service_type !== "individual" ||
      mergedEocDatum.appointment_status !== "attended"
    ) {
      continue;
    }

    const existingEpisodeData: EOCEpisodeData | undefined =
      episodeToClinicianDataMap.get(mergedEocDatum.episode);

    let episodeData: EOCEpisodeData;
    let existingClinicianData: EOCClinicianData | undefined,
      newClinicianData: EOCClinicianData;

    if (!existingEpisodeData) {
      episodeData = {
        episode: mergedEocDatum.episode,
        clinicianDataMap: new Map<number, EOCClinicianData>(),
      };
      newClinicianData = generateClinicianData(mergedEocDatum);
      episodeData.clinicianDataMap.set(
        mergedEocDatum.clinician_id,
        newClinicianData,
      );
    } else {
      existingClinicianData = existingEpisodeData.clinicianDataMap.get(
        mergedEocDatum.clinician_id,
      );
      newClinicianData = !!existingClinicianData
        ? getUpdatedClinicianData(mergedEocDatum, existingClinicianData)
        : generateClinicianData(mergedEocDatum);
      existingEpisodeData.clinicianDataMap.set(
        mergedEocDatum.clinician_id,
        newClinicianData,
      );
      episodeData = existingEpisodeData;
    }
    episodeToClinicianDataMap.set(mergedEocDatum.episode, episodeData);
  }
  return episodeToClinicianDataMap;
};
/**
 * @name getUpdatedClinicianData
 * @summary For use whenever updating an EOC episode/clinician mapping
 *  */
const getUpdatedClinicianData = (
  mergedEocDatum: EOCClientEvent,
  existingSubEpisodeSummary: EOCClinicianData,
): EOCClinicianData => {
  const latestSubEpisodeNumber =
    existingSubEpisodeSummary.subEpisodeSummaryMap.size;
  let latestSubEpisodeSummary =
    existingSubEpisodeSummary.subEpisodeSummaryMap.get(latestSubEpisodeNumber);

  if (latestSubEpisodeSummary) {
    const latestSubEpisodeSummaryLatestDate = DateTime.fromISO(
      latestSubEpisodeSummary.endDate,
    );
    const shouldUpdateCurrentSubEpisode =
      DateTime.fromISO(mergedEocDatum.start_time).diff(
        latestSubEpisodeSummaryLatestDate,
        "days",
      ).days < 60;
    if (shouldUpdateCurrentSubEpisode) {
      latestSubEpisodeSummary = {
        ...latestSubEpisodeSummary,
        // sometimes it is possible that the scores were not filled out for the first EOC, so we want to default to the next one that exists
        startDate:
          latestSubEpisodeSummary.startDate ?? mergedEocDatum.start_time,
        startGad7Score:
          latestSubEpisodeSummary.startGad7Score ?? mergedEocDatum.gad7_value,
        startPhq9Score:
          latestSubEpisodeSummary.startPhq9Score ?? mergedEocDatum.phq9_value,
        totalSessions: mergedEocDatum.session_number,
        // If the scores haven't updated, we want to use the currently existing score as a fallback
        endDate: mergedEocDatum.start_time ?? latestSubEpisodeSummary.endDate,
        endGad7Score:
          mergedEocDatum.gad7_value ?? latestSubEpisodeSummary.endGad7Score,
        endPhq9Score:
          mergedEocDatum.phq9_value ?? latestSubEpisodeSummary.endPhq9Score,
      };
      existingSubEpisodeSummary.subEpisodeSummaryMap.set(
        latestSubEpisodeNumber,
        latestSubEpisodeSummary,
      );
    } else {
      existingSubEpisodeSummary.subEpisodeSummaryMap.set(
        latestSubEpisodeNumber + 1,
        generateSubEpisodeSummary(mergedEocDatum),
      );
    }
  } else {
    existingSubEpisodeSummary.subEpisodeSummaryMap.set(
      1,
      generateSubEpisodeSummary(mergedEocDatum),
    );
  }
  return existingSubEpisodeSummary;
};

const generateClinicianData = (
  mergedEocDatum: EOCClientEvent,
): EOCClinicianData => {
  let subEpisodeSummaryMap = new Map<number, EOCClinicianSubEpisodeSummary>();

  // mergedEOCDatum be sorted, so it should be fine to set the first data as 1 (1-indexed, just like the EOCs)
  subEpisodeSummaryMap.set(1, generateSubEpisodeSummary(mergedEocDatum));
  return {
    clinicianName: mergedEocDatum.full_name,
    clinicianId: mergedEocDatum.clinician_id,
    subEpisodeSummaryMap,
  };
};

const generateSubEpisodeSummary = (
  mergedEocDatum: EOCClientEvent,
): EOCClinicianSubEpisodeSummary => {
  return {
    startDate: mergedEocDatum.start_time,
    endDate: mergedEocDatum.start_time,
    startGad7Score: mergedEocDatum.gad7_value,
    endGad7Score: mergedEocDatum.gad7_value,
    startPhq9Score: mergedEocDatum.phq9_value,
    endPhq9Score: mergedEocDatum.phq9_value,
    totalSessions: mergedEocDatum.session_number,
  };
};

/**
 * @name getCliniciansMostRecentSubEpisode
 * @summary Returns the most recent sub-episode summary for a particular clinician within an episode/clinician combination
 *  */
export const getCliniciansMostRecentSubEpisodeSummary = (
  clinicianData: EOCClinicianData,
): EOCClinicianSubEpisodeSummary | undefined => {
  return undefined;
};

export const attachTotalSessionsAttendedWithCurrentClinicianToClients = (
  clients: DataWithSessionCount[],
  clientEOCData: any,
  clinicianId: number | undefined,
): DataWithSessionCount[] => {
  const clientEventWithEocMap = new Map<number, any[]>();

  clientEOCData.forEach((client: any) => {
    const clientId = Math.abs(client.client_id);
    if (clientId) {
      const currentClientData = clientEventWithEocMap.get(Math.abs(clientId));

      clientEventWithEocMap.set(
        clientId,
        currentClientData ? [...currentClientData, client] : [client],
      );
    }
  });
  // sets the values for the clients
  clients = clients.map((client: DataWithSessionCount) => {
    const clientId = client.clientId;
    const eocDataFromLatestEoc = getTotalSessionCountFromLatestEoc(
      clientEventWithEocMap.get(Math.abs(clientId ?? 0)) ?? [],
    );
    client.totalSessionsAttendedWithCurrentClinician = eocDataFromLatestEoc;
    return client;
  });
  return clients;
};

export const getBaselineSession = (
  currentSession: any,
  latestSession: any,
  baselineSession: any,
) => {
  //compare the first event with the latest event with the same clinician id to get "baseline"
  // OR handle the case where none of the events have clinician ids
  const doClinicianIdsExist =
    currentSession.clinician_id || latestSession?.clinician_id;
  if (
    currentSession.clinician_id === latestSession?.clinician_id ||
    !doClinicianIdsExist
  ) {
    const dateOne = DateTime.fromISO(baselineSession.start_time);
    const dateTwo = DateTime.fromISO(currentSession.start_time);
    const diff = dateOne.diff(dateTwo, ["years", "months", "days", "hours"]);
    const daysDiff = diff.as("days");

    if (daysDiff < 60) {
      baselineSession = currentSession;
    }
  }
  return baselineSession;
};

/**
 * @name getTotalLatestSessionCount
 * @returns {number} the displayed total number of sessions for a client (based on latest episode of care)
 * @description The logic for this originates from this [EOC Session Count Update document](https://twochairs.atlassian.net/wiki/spaces/ENGINEERIN/pages/1742667790/Care+Platform+Session+Count+Overview+March+2023)
 */
export const getTotalSessionCountFromLatestEoc = (
  mergedEocData: any[],
): number => {
  const filteredLatestEoc = mergedEocData?.filter(
    (eocDatum) => parseInt(eocDatum.is_latest_clinician_in_episode) && parseInt(eocDatum.is_latest),
  );

  // sometimes sessions are cancelled, so we can't just do something like getting the length of the filtered eoc data
  let maxObj = null;
  let maxValue = -Infinity;

  for (const obj of filteredLatestEoc) {
    const value = parseInt(obj.same_clinician_over_60_days_last_session, 10);
    if (value > maxValue) {
      maxValue = value;
      maxObj = obj;
    }
  }

  return maxObj ? maxObj.same_clinician_over_60_days_last_session : 0;
};


export const mergeMBCData = (
  payloadOne: [],
  eocPayload: Dw_Export_Episode_Of_Care[],
) => {
  if (!payloadOne) payloadOne = [];
  if (!eocPayload) eocPayload = [];
  const merged: any[] = [];
  payloadOne.forEach((payloadOneItem: any) => {
    let combined = { ...payloadOneItem };
    eocPayload.forEach((eocPayloadItem: any) => {
      if (payloadOneItem.event_id === eocPayloadItem.event_id) {
        combined = { ...combined, ...eocPayloadItem };
      }
    });
    merged.push(combined);
  });
  return merged;
};

/**
 * @name mergeAndFilterWithEocData
 * @description Returns only the data that has an EOC equivalent. This is different from mergeMbcData because it acts more as SQL's LEFT JOIN rather than an OUTER JOIN
 */
export const mergeAndFilterWithEocData = (
  payloadOne: Array<EventApiData>,
  eocPayload: Dw_Export_Episode_Of_Care[],
) => {
  if (!payloadOne) payloadOne = new Array<EventApiData>();
  if (!eocPayload) eocPayload = []; 

  const eoc_event_session_ids = new Set<string>(eocPayload.flatMap(payLoadItem=>{
    return payLoadItem.event_id ?? []
  }))
  
  return payloadOne.filter(item => eoc_event_session_ids.has(item.eventId));

};

export const filterOutClinicianAndSystemCancellations = (sessions: Array<EventApiData>): Array<EventApiData> =>{
  return sessions.filter((session) => {
    return (
      session.appointmentStatus !== "canceled" ||
      session.cancellationType === "late_by_client" ||
      session.cancellationType === "by_client"
    );
  });
}