import { CurrentUser } from "@/api/types";
import { DateTime } from "luxon";
import { DxCode, DxCodeMap } from "../client/types";
import { useUserHasAnyPermissions } from "../_helpers/permissions";
import { Timezone } from "../_shared/TimezoneContext";

export const serviceLineKey = {
  individual: "Individual Therapy",
};

export const getDiagnosisCodeMap = (
  dxCodes: DxCode[] | undefined,
): DxCodeMap => {
  const diagnosisCodeMap = {};

  if (dxCodes) {
    dxCodes.forEach((dxCode) => {
      const indexOfDxCode = dxCode.label.indexOf(dxCode.value);

      // subtract 3 to account for the " - "
      const strippedDxLabel = dxCode.label.slice(0, indexOfDxCode - 3);

      diagnosisCodeMap[dxCode.value] = strippedDxLabel;
    });
  }

  return diagnosisCodeMap;
};

export const TIMEZONE = (cuser: CurrentUser | null) => (cuser?.clinician?.primary_timezone
  || Intl.DateTimeFormat()?.resolvedOptions()?.timeZone
  || "America/Los_Angeles") as Timezone;

/**
 *
 * Transforms a Luxon DateTime into the DATETIME_SHORT format with timezone.
 * @param dt Luxon DateTime
 * @returns string "5/31/2022, 10:00 AM PDT"
 */
export const stringifyDateTimeInPrimaryTimezone = (
  dt: DateTime,
  timezone: string,
  customFormat?: Intl.DateTimeFormatOptions,
) =>
  dt.setZone(timezone).toLocaleString(
    customFormat
      ? customFormat
      : {
          ...DateTime.DATETIME_SHORT,
          timeZoneName: "short",
        },
  );

export const useHasMyClientsPermissions = () => {
  return useUserHasAnyPermissions([
    "IsMyClientsWriteAllowed",
    "IsClinician",
    "IsClinicalLeader",
    "IsSuperUser",
  ]);
};
