import { DateTime } from "luxon";
import { EventApiData } from "../../../../../../../../api/use-my-clients-overview/my-clients-data.interface";
import {
  MyClientsSessionsAppointmentStatusFilterState,
  SessionHistoryTimeRange
} from "../../my-clients-schedule-model";
import { SessionHistoryTimeRangeStartDates } from "@/app/_helpers/constants";

interface IsBetweenNowAndPastDateParams {
  pastDateTime: DateTime;
  dateTimeToCompare: DateTime;
}

const isBetweenNowAndPastDate = ({
  dateTimeToCompare,
  pastDateTime,
}: IsBetweenNowAndPastDateParams) => {
  const now = DateTime.now();
  return dateTimeToCompare > pastDateTime && dateTimeToCompare < now;
};

export const getFilterOnSessionTime = (
  sessionHistorysession: EventApiData,
  timeRangeToFilterOn: SessionHistoryTimeRange,
) => {
  if (!sessionHistorysession.startTime) {
    return false;
  }
  const startTime = DateTime.fromISO(sessionHistorysession.startTime);
  if (timeRangeToFilterOn === SessionHistoryTimeRange.ALL_TIME) {
    return sessionHistorysession;
  } else {
    const pastStartDateTime = SessionHistoryTimeRangeStartDates[timeRangeToFilterOn];
    return isBetweenNowAndPastDate({
      dateTimeToCompare: startTime,
      pastDateTime: pastStartDateTime,
    });
  } 
};

export const getFilterOnSessionAppointmentStatus = (
  sessionHistorysession: EventApiData,
  myClientsAppointmentStatusFilterState: MyClientsSessionsAppointmentStatusFilterState,
) => {
  const isAnyMyClientsAppointmentStatusFilterChecked = Object.values(
    myClientsAppointmentStatusFilterState,
  ).includes(true);

  const filterKeys = {
    attended: "isFilteringOnAttended",
    canceled: "isFilteringOnCanceledByClient",
    noshow: "isFilteringOnNoShow",
    late_by_client: "isFilteringOnLateCancelByClient",
  };

  if (isAnyMyClientsAppointmentStatusFilterChecked) {
    if (
      !sessionHistorysession.startTime ||
      !sessionHistorysession.appointmentStatus
    ) {
      return false;
    }
    const filter = filterKeys[sessionHistorysession.appointmentStatus];

    const isLateByClient =
      sessionHistorysession.cancellationType === "late_by_client";

    //special case which keys off the "cancellationType" field
    let filterTwo;
    if (myClientsAppointmentStatusFilterState.isFilteringOnLateCancelByClient) {
      filterTwo = filterKeys[sessionHistorysession.cancellationType];
    }
    return (
      (myClientsAppointmentStatusFilterState[filter] && !isLateByClient) ||
      myClientsAppointmentStatusFilterState[filterTwo]
    );
  }

  return true;
};
