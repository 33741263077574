import moment from "moment";

import {
  AppointmentStatus,
  PanelClient,
  PanelClientMeta,
  PanelClinicianWeek,
  PanelSession,
  MBCClientAdherence,
  EventMbcStatus,
  IdMap,
} from "../../api/types";

export interface Sort {
  description: string;
  fn: any;
}

export interface WeekDate {
  year: number;
  week: number;
}

export interface DateRange {
  start: moment.Moment;
  end: moment.Moment;
}

export interface ClientRowIndices {
  [id: number]: number;
}

export type ModalType = "client" | "session" | "week" | undefined;

export interface ClientMetaMap {
  [id: string]: PanelClientMeta;
}

export type PartialPanelClientMeta = Partial<PanelClientMeta>;

export interface ClientToSessionsMap {
  [clientId: number]: number[];
}

export interface ClinicianWeeksMap {
  [id: string]: PanelClinicianWeek;
}

export interface PanelSessionsMap {
  [id: string]: PanelSession;
}

export interface ClientsMap {
  [id: number]: PanelClient;
}

export interface MBCClientAdherenceMap {
  [id: number]: MBCClientAdherence;
}

export type EventMBCStatusesMap = IdMap<EventMbcStatus[]>;

export type ClientFilterFn = (client: PanelClient) => boolean;
export type ClientFilter<U> = (arg: U) => ClientFilterFn;

export type ClientStatus = keyof typeof ClientStatusEnum;
export const ClientStatusEnum = {
  active: "Active",
  pending: "Pending",
  inactive: "Inactive",
  terminated: "Care Terminated",
};

export type ClientCadence = keyof typeof CadenceEnum;
export const CadenceEnum = {
  "2x a week": "2x/week",
  weekly: "Weekly",
  biweekly: "Biweekly",
  triweekly: "Triweekly",
  monthly: "Monthly",
  other: "Other",
  unknown: "Unknown",
};

const byStatus: ClientFilter<ClientStatus | ClientStatus[]> = status => c =>
  Array.isArray(status)
    ? [...status].includes(c.meta!.status.state)
    : c.meta!.status.state === status;
const byCadence: ClientFilter<ClientCadence | ClientCadence[]> = cadence => c =>
  Array.isArray(cadence)
    ? cadence.includes(c.meta!.cadence_override)
    : c.meta!.cadence_override === cadence;
export const ClientFilters = {
  byStatus,
  byCadence,
};
export type AvailableClientFilters = keyof typeof ClientFilters;
export interface StatifiedActiveClientFilters {
  byStatus?: ClientStatus[];
  byCadence?: ClientCadence[];
}

export type ClientSort = keyof typeof ClientSortEnum;
export const ClientSortEnum = {
  byLastName: "By Last Name",
  byFirstName: "By First Name",
  byNumSessions: "By # of Sessions",
  byStatus: "By Status",
  byCadence: "By Cadence",
  byMBCPreSessionAdherence: "By MBC Pre-Session Adherence",
};

export type ClientAppointmentStatusCountMap = {
  [Key in Exclude<AppointmentStatus, null> | "scheduled"]: number;
};
