import { ListIcon } from "@/app/design-system/icons";

import { NavigationMenuItem } from "../../components.utility/navigation-menu-item";

interface MatchQueueMenuItemProps {
  isCollapsed: boolean;
}

export function MatchQueueMenuItem({ isCollapsed }: MatchQueueMenuItemProps) {
  return (
    <NavigationMenuItem
      to="/match-queue"
      menuItemTooltipContent="Match Queue"
      isCollapsed={isCollapsed}
    >
      <ListIcon title={!isCollapsed ? "" : "Match Queue"} />
      {!isCollapsed ? `Match Queue` : null}
    </NavigationMenuItem>
  );
}
