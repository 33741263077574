import api, { wrapApiCall } from "../../../api";

import { teamActions as actions } from "./";

import { AsyncActionCreator } from "../types";

const getTeam: AsyncActionCreator = () => async dispatch => {
  dispatch(actions.fetchTeam());
  const response = await wrapApiCall(
    api.get("/ehr/clinicians/team/"),
    dispatch,
  );
  dispatch(actions.saveTeam(response.data));
};

export default {
  getTeam,
};
