import { produce } from 'immer';
import moment from 'moment';
import { actionFactory, ActionUnion, payloadAction, simpleAction } from 'reductser';

import { AppointmentClient, PanelClinicianWeek, PanelMeta, PanelSession } from '../../../api/types';
import {
  ClientMetaMap,
  ClientRowIndices,
  ClientsMap,
  ClientSort,
  ClientToSessionsMap,
  ClinicianWeeksMap,
  DateRange,
  EventMBCStatusesMap,
  PanelSessionsMap,
  PartialPanelClientMeta,
  StatifiedActiveClientFilters,
} from '../../../app/panel-management/types';
import { assertUnreachable } from '../types';

const actionMap = {
  setClinicianId: payloadAction<number>(),
  setDateRange: payloadAction<DateRange>(),
  setSort: payloadAction<ClientSort>(),
  startLoadingPanel: simpleAction(),
  processClinicianWeeks: payloadAction<{
    weeks: PanelClinicianWeek[];
    sessions: PanelSession[];
  }>(),
  processClients: payloadAction<{
    clients: AppointmentClient[];
    filteredClientIds: number[];
    clientRowIndices: ClientRowIndices;
    clientsMap: ClientsMap;
  }>(),
  setFilteredClients: payloadAction<{
    filteredClientIds: number[];
    clientRowIndices: ClientRowIndices;
  }>(),
  processClientMeta: payloadAction<{
    [clientId: string]: PartialPanelClientMeta;
  }>(),
  updateWeekMeta: payloadAction<PanelClinicianWeek>(),
  updateSessionMeta: payloadAction<{ id: string; meta: PanelMeta }>(),
  setClientToSessionsMap: payloadAction<ClientToSessionsMap>(),
  setClientFilters: payloadAction<StatifiedActiveClientFilters>(),
  finishLoadingPanel: simpleAction(),
  setMBCEventStatuses: payloadAction<EventMBCStatusesMap>(),
};

export const panelActions = actionFactory(actionMap, "PANEL");
export type PanelAction = ActionUnion<typeof panelActions>;

export interface PanelState {
  clinicianId?: number;
  dateRange: DateRange;
  sort: ClientSort;
  isLoading: boolean;
  filteredClientIds: number[];
  clientRowIndices: ClientRowIndices;
  clientMetaMap: ClientMetaMap;
  clientToSessionMap: ClientToSessionsMap;
  weeksMap: ClinicianWeeksMap;
  sessionsMap: PanelSessionsMap;
  clientsMap: ClientsMap;
  clientFilters: StatifiedActiveClientFilters;
  urlEventId: string | null;
  eventMBCStatusesMap: EventMBCStatusesMap;
}

function getInitialState(): PanelState {
  const today = moment();
  const momentRange: DateRange = {
    start: moment(today)
      .subtract(8, "weeks")
      .startOf("week"),
    end: moment(today)
      .add(4, "weeks")
      .endOf("week"),
  };
  const queryParams = new URLSearchParams(window.location.search);

  return {
    clinicianId: queryParams.get('clinician') !== null ? Number.parseInt(queryParams.get('clinician')!) : undefined,
    sort:
      (localStorage.getItem("defaultSort") as ClientSort) || "byNumSessions",
    dateRange: momentRange,
    isLoading: true,
    weeksMap: {},
    sessionsMap: {},
    clientMetaMap: {},
    clientToSessionMap: {},
    filteredClientIds: [],
    clientRowIndices: {},
    clientsMap: {},
    clientFilters: getInitialFilters(),
    urlEventId: queryParams.get('eventId'),
    eventMBCStatusesMap: {},
  };
}

export const getInitialFilters = (
  ignoreLocal: boolean = false,
): StatifiedActiveClientFilters => {
  if (!ignoreLocal) {
    const cachedFilters = localStorage.getItem("defaultFilter");
    if (cachedFilters) {
      try {
        const filters = JSON.parse(
          cachedFilters,
        ) as StatifiedActiveClientFilters;
        if ([filters.byCadence, filters.byStatus].some(e => !!e)) {
          return filters;
        }
      } catch (e) {
        console.error("Invalid localStorage value for defaultFilters");
        localStorage.removeItem("defaultFilter");
      }
    }
  }
  return { byStatus: ["active", "inactive", "pending"] };
};

const panelReducer = (state = getInitialState(), action: PanelAction) =>
  produce(state, draft => {
    if (action.reducer === "PANEL") {
      switch (action.type) {
        case "setClinicianId":
          draft.clinicianId = action.payload;
          return;
        case "setDateRange":
          draft.dateRange = action.payload;
          return;
        case "setSort":
          draft.sort = action.payload;
          return;
        case "startLoadingPanel":
          draft.isLoading = true;
          return;
        case "processClinicianWeeks":
          draft.weeksMap = {};
          draft.sessionsMap = {};
          action.payload.weeks.forEach(obj => (draft.weeksMap[obj.id] = obj));
          action.payload.sessions.forEach(
            obj => (draft.sessionsMap[obj.id] = obj),
          );
          return;
        case "updateWeekMeta":
          draft.weeksMap[action.payload.id] = {
            ...draft.weeksMap[action.payload.id],
            ...{ note_content: action.payload.note_content },
          };
          return;
        case "updateSessionMeta":
          draft.sessionsMap[action.payload.id].meta = action.payload.meta;
          return;
        case "processClients":
          draft.clientsMap = action.payload.clientsMap;
          draft.filteredClientIds = action.payload.filteredClientIds;
          draft.clientRowIndices = action.payload.clientRowIndices;
          return;
        case "finishLoadingPanel":
          draft.isLoading = false;
          return;
        case "setFilteredClients":
          draft.filteredClientIds = action.payload.filteredClientIds;
          draft.clientRowIndices = action.payload.clientRowIndices;
          return;
        case "processClientMeta":
          Object.entries(action.payload).map(
            ([clientId, clientMeta]) =>
              (draft.clientMetaMap[clientId] = {
                ...draft.clientMetaMap[clientId],
                ...clientMeta,
              }),
          );
          return;
        case "setClientToSessionsMap":
          draft.clientToSessionMap = action.payload;
          return;
        case "setClientFilters":
          draft.clientFilters = action.payload;
          return;
        case "setMBCEventStatuses":
          draft.eventMBCStatusesMap = action.payload;
          return;
        default:
          assertUnreachable(action);
          return;
      }
    }
  });

export default panelReducer;
