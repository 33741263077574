export interface CancellationReasonsAndTypesApiData {
  by_client: string[];
  by_clinician: string[];
  late_by_client: string[];
  by_twochairs: string[];
  cancel_by_system: string[];
}

export const cancellationTypeToFrontendValueMap = {
  late_by_client: "Late cancel by client",
  cancel_by_system: "Canceled by system",
  by_clinician: "Canceled by clinician",
  by_client: "Canceled by client",
  by_twochairs: "Canceled by Two Chairs",
  noshow: "No show (missed session)",
};

export enum CancellationType {
  CANCELED_BY_CLIENT = "Canceled by client",
  CANCELED_BY_CLINICIAN = "Canceled by clinician",
  NO_SHOW = "No show (missed session)",
  LATE_CANCEL_BY_CLIENT = "Late cancel by client",
}

export const cancellationReasonToBackendValueMap = {
  Illness: "illness",
  "Vacation or holiday": "vacation_or_holiday",
  "Scheduling conflict": "scheduling_conflict",
  "Pausing care": "pausing_care",
  "Technical issues": "technical_issues",
  "Scheduled in error": "scheduled_in_error",
  "Termination of care": "termination_of_care",
  Unknown: "unknown",
  "Out of state": "out_of_state",
  "Incorrect service": "incorrect_service",
  "Returning client": "returning_client",
  "Conflict of interest": "conflict_of_interest",
  "Incomplete client profile": "incomplete_client_profile",
};

export const cancellationReasonToFrontendValueMap = {
  illness: "Illness",
  vacation_or_holiday: "Vacation or holiday",
  scheduling_conflict: "Scheduling conflict",
  pausing_care: "Pausing care",
  technical_issues: "Technical issues",
  scheduled_in_error: "Scheduled in error",
  termination_of_care: "Termination of care",
  unknown: "Unknown",
  conflict_of_interest: "Conflict of interest",
  offboarded_clinician: "Offboarded clinician",
  double_booking: "Double booking",
  out_of_state: "Out of state",
  incorrect_service: "Incorrect service",
  returning_client: "Returning client",
  incomplete_client_profile: "Incomplete client profile",
  autoschedule: "Autoschedule",
};

export const CANCELLATION_TYPE_HIDDEN_OPTIONS = [
  "Canceled by system",
  "Canceled by Two Chairs",
];

// we hide these options because they don't make sense
// in the context of groups session
export const CANCELED_BY_CLINICIAN_HIDDEN_OPTIONS = [
  "scheduled_in_error",
  "termination_of_care",
];
// we hide these options because they don't make sense
// in the context of a consult session
export const CANCELED_BY_CLIENT_HIDDEN_OPTIONS = ["pausing_care"];

export const NO_SHOW_OPTION = {
  id: "no_show_missed_session",
  value: "No show (missed session)",
  label: "No show (missed session)",
};
