import { useUserHasAnyPermissions } from "@/app/_helpers/permissions";
import React from "react";
import { Flex } from "../../../../../../../design-system/layout/flex";
import { MyClientsClient } from "../../../../my-clients-model";
import { MyClientsFilters } from "../../../../my-clients-model/my-clients.interface";
import { useMyClientsFilterStore } from "../../../../my-clients-model/my-clients.model";
import { MBCMetricCheckbox } from "./mbc-metric-checkbox";
import { useMBCMetricFiltersBySeverity } from "./mbc-metrics.util";

interface SearchAndFilterProps {
  myClients: MyClientsClient[];
}

export const MBCMetricCheckboxes = ({ myClients }: SearchAndFilterProps) => {
  const isDashboardV2User = useUserHasAnyPermissions(["IsDashboardV2User"]);
  const { mbcMetricFiltersBySeverity, setMyClientsFilter } =
    useMBCMetricFiltersBySeverity(myClients, isDashboardV2User);

  const clearAllMyClientsFilters = useMyClientsFilterStore(
    (state) => state.clearAllMyClientsFilters,
  );

  const setFilter = (
    filter: keyof MyClientsFilters,
    isFilterChecked: boolean,
  ) => {
    if (isFilterChecked) {
      setMyClientsFilter(filter, isFilterChecked);
      setMyClientsFilter("isFilteringOnKPNorcal", true);
      setMyClientsFilter("isFilteringOnKPWA", true);
      setMyClientsFilter("isFilteringOnActiveClients", true);
    } else {
      clearAllMyClientsFilters();
    }
  };

  return (
    <Flex gap={12} flexWrap={"wrap"}>
      {mbcMetricFiltersBySeverity.map(
        ({
          copy,
          numberOfClients,
          clientLabel,
          isFilterChecked,
          filterName,
        }) => {
          return numberOfClients > 0 ? (
            <MBCMetricCheckbox
              setFilter={setFilter}
              isChecked={isFilterChecked}
              copy={copy}
              numberOfClients={numberOfClients}
              filterName={filterName}
              clientLabel={clientLabel}
              key={clientLabel}
            />
          ) : null;
        },
      )}
    </Flex>
  );
};
