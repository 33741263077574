import { styledStitches } from "@/app/design-system";
import { UnstyledButton } from "@/app/design-system/button/button";
import { TABLE_LINK_STYLE } from "@/app/design-system/styles/config/css-utils";
import { Link } from "react-router-dom";

import { TabsTrigger } from "../../_components/navigation-tabs/navigation-tabs.styled";
/**
 * @name TableLink
 * @description Annoying but antd overrides link styles and makes them black, so we have to override them back to our default link styles
 */
export const TableLink = styledStitches(Link, {
  ...TABLE_LINK_STYLE,
  display: "block",
  "&:hover ": {
    color: "#2061DC",
    textDecoration: "none",
  },
  "&:visited": {
    color: "purple",
  },
});

export const PaginationInput = styledStitches("input", {
  borderRadius: "4px",
  padding: "4px",
});

export const TabsLabel = styledStitches("div", UnstyledButton, {
  borderRadius: "12px",
  display: "flex",
  alignContent: "center",
  justifyContent: "center",
  flexDirection: "column",
  height: "48px",
  padding: "4px",
  // replaces HoverTabHighlight
  "&:hover": {
    backgroundColor: "$neutral5",
  },
  // we don't want to have an outline in this particular case because the tab trigger (TabsLabel's parent) is a button, which means it has the focus styles
  outline: "none",
});

export const MatchingNavTrigger = styledStitches(TabsTrigger, {
  width: "fit-content",
  minWidth: "160px",
  justifyContent: "center",
  height: "80px",
  // replaces ActiveTabHighlight
  "&[data-state='active']": {
    borderBottom: "4px solid $neutral12",
    height: "76px",
    marginBottom: "4",
  },
  "&[data-state='active']::after": {
    content: " ",
    width: "100px",
    height: "2px",
    borderBottom: "2px solid $neutral12",
  },
});
