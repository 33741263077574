import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Checkbox } from "antd";
import { AppState } from "../../../state/models";
import { Row } from "../../_layout/Flex";
import {
  matchmakerActions,
  matchmakerOperations,
} from "../../../state/models/matchmaker";
import { CheckboxChangeEvent } from "antd/es/checkbox";

export default () => {
  const [
    stepIdx,
    isReferral,
    loading,
    canStep,
  ] = useSelector((state: AppState) => [
    state.matchmaker.stepIdx,
    state.matchmaker.draft.is_referral,
    state.matchmaker.loading,
    matchmakerOperations.canStep(state.matchmaker),
  ]);
  const dispatch = useDispatch();

  const stepTo = (idx: number) => dispatch(matchmakerActions.stepTo(idx)),
    closeMatchmaker = () => dispatch(matchmakerActions.closeMatchmaker()),
    setIsReferral = (newIsReferral: boolean) =>
      dispatch(
        matchmakerActions.patchMatchDraft({ is_referral: newIsReferral }),
      ),
    saveMatch = () => dispatch(matchmakerOperations.saveMatch());

  const actions = [];
  // Left Side Actions
  if (!stepIdx) {
    actions.push(
      <Button key="cancel" onClick={closeMatchmaker}>
        Cancel
      </Button>,
    );
  }
  if (stepIdx) {
    actions.push(
      <Button key="back" onClick={() => stepTo(stepIdx - 1)}>
        <LeftOutlined /> Back
      </Button>,
    );
  }

  // Action Splitter
  actions.push(<div key="spacer" style={{ flex: 1 }} />);
  // Right Side Actions

  if (stepIdx === 3) {
    actions.push(
      <Checkbox
        key="refer"
        checked={isReferral}
        style={{ marginLeft: "10px" }}
        onChange={(e: CheckboxChangeEvent) => setIsReferral(e.target.checked)}
      >
        Refer client out of Two Chairs{" "}
      </Checkbox>,
    );
  }
  if (stepIdx === 0 || stepIdx === 2) {
    actions.push(
      <Button
        key="next"
        type="primary"
        onClick={() => stepTo(stepIdx + 1)}
        disabled={!canStep}
      >
        Next <RightOutlined />
      </Button>,
    );
  }

  if (stepIdx === 1) {
    actions.push(
      <Button
        key="save and continue"
        type="primary"
        onClick={() => stepTo(stepIdx + 1)}
      >
        Next <RightOutlined />
      </Button>,
    );
  }
  if (stepIdx === 3) {
    actions.push(
      <Button
        key="submit"
        type="primary"
        onClick={saveMatch}
        loading={loading.matchSave}
      >
        Submit
      </Button>,
    );
  }
  return (
    <Row layout={"start center"} style={{ backgroundColor: "white" }}>
      {actions}
    </Row>
  );
};
