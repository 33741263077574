export const preferences_options = [
  {
    key: "OPTION__unsure",
    value: "unsure",
    title: "Unsure / no preference",
  },
  {
    key: "OPTION__low",
    value: "low",
    title: "Low",
  },
  {
    key: "OPTION__medium",
    value: "medium",
    title: "Medium",
  },
  {
    key: "OPTION__high",
    value: "high",
    title: "High",
  },
];

export const intensity_options = [
  {
    key: "OPTION__slight",
    value: "slight",
    title: "Slight",
  },
  {
    key: "OPTION__moderate",
    value: "moderate",
    title: "Moderate",
  },
  {
    key: "OPTION__strong",
    value: "strong",
    title: "Strong",
  },
  {
    key: "OPTION__na",
    value: "na",
    title: "N/A",
  },
];
