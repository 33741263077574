import { EventApiData } from "../../../../../../../../api/use-my-clients-overview/my-clients-data.interface";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  ComposedAccordionTrigger,
} from "../../../../../../../../design-system/accordion/accordion.styled";
import { Box, Stack, Text } from "../../../../../../../../design-system/index";
import { EmptySessions } from "../empty-sessions";
import { useSessionHistorySection } from "./hooks/use-session-history-section";
import { SessionHistoryAppointmentStatusFilters } from "./session-history-section-header/components/session-history-appointment-status-filter-checkboxes/session-history-appointment-status-filter-checkboxes";
import { SessionHistorySectionHeader } from "./session-history-section-header/sessions-history-section-header";
import { SessionPane } from "./session-panel/session-pane";

interface SessionHistorySectionProps {
  sessionHistorySessions: EventApiData[];
  sessionsFilteredByTimeRange: EventApiData[];
  sessionsFilteredByTimeRangeAndAppointmentStatus: EventApiData[];
}

export const SessionHistorySection = ({
  sessionHistorySessions,
  sessionsFilteredByTimeRange,
  sessionsFilteredByTimeRangeAndAppointmentStatus,
}: SessionHistorySectionProps) => {
  const {
    sessionsAttendedInLast2Days,
    totalCanceledSessions,
    totalAttendedSessions,
    totalNoShowSessions,
    totalLateCancelByClientSessions,
  } = useSessionHistorySection({
    sessionHistorySessions,
    sessionsFilteredByTimeRange,
  });

  return (
    <Box
      p={24}
      css={{
        borderBottom: "1px solid",
        borderColor: "$neutral6",
        height: "100%",
      }}
    >
      <Stack gap={24} css={{ flex: 1 }}>
        <SessionHistorySectionHeader
          sessionsAttendedInLast2Days={sessionsAttendedInLast2Days}
        />

        <SessionHistoryAppointmentStatusFilters
          totalAttendedSessions={totalAttendedSessions}
          totalCanceledSessions={totalCanceledSessions}
          totalNoShowSessions={totalNoShowSessions}
          totalLateCancelByClientSessions={totalLateCancelByClientSessions}
        />
        {sessionsFilteredByTimeRangeAndAppointmentStatus.length > 0 ? (
          <Stack>
            <Accordion type="multiple">
              {sessionsFilteredByTimeRangeAndAppointmentStatus.map(
                ({
                  startTime,
                  eventId,
                  recurrenceData,
                  appointmentStatus,
                  clinicianId,
                  cancellationType,
                  serviceType,
                  cancellationReason,
                  clinician,
                  meta,
                }) => {
                  // we check if it's "new world" cancellations by checking for cancellationType
                  // since noshows don't have a cancellation type, we just check that it's a noshow

                  const isSessionAppintmentStatusNoShowOrCanceled =
                    appointmentStatus === "noshow" ||
                    (appointmentStatus === "canceled" && !!cancellationType);

                  const hasNote = !!meta?.attendanceNote;

                  return isSessionAppintmentStatusNoShowOrCanceled ? (
                    <AccordionItem
                      value={eventId}
                      key={eventId}
                      disabled={!hasNote}
                    >
                      <ComposedAccordionTrigger>
                        <SessionPane
                          serviceType={serviceType}
                          cancellationType={cancellationType}
                          cancellationReason={cancellationReason}
                          isSessionAppintmentStatusNoShowOrCanceled={
                            isSessionAppintmentStatusNoShowOrCanceled
                          }
                          key={eventId}
                          startTime={startTime}
                          recurrenceData={recurrenceData}
                          appointmentStatus={appointmentStatus}
                          clinicianId={clinicianId}
                          clinician={clinician}
                          shouldShowViewNoteButton={hasNote}
                        />
                      </ComposedAccordionTrigger>
                      <AccordionContent>
                        <Text>{meta?.attendanceNote}</Text>
                      </AccordionContent>
                    </AccordionItem>
                  ) : (
                    <SessionPane
                      serviceType={serviceType}
                      cancellationType={cancellationType}
                      cancellationReason={cancellationReason}
                      isSessionAppintmentStatusNoShowOrCanceled={
                        isSessionAppintmentStatusNoShowOrCanceled
                      }
                      key={eventId}
                      startTime={startTime}
                      recurrenceData={recurrenceData}
                      appointmentStatus={appointmentStatus}
                      clinicianId={clinicianId}
                      clinician={clinician}
                    />
                  );
                },
              )}
            </Accordion>
          </Stack>
        ) : (
          <EmptySessions>No sessions</EmptySessions>
        )}
      </Stack>
    </Box>
  );
};
