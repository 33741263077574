import { ClinicianWithSchedule } from "../../../api/types";
import { Select as DeprecatedAntdSelect } from "antd";
import React, { useState, useEffect } from "react";

interface Props {
  clinicians: ClinicianWithSchedule[];
  onChange: (clinicianIds: number[]) => void;
}

function ClinicianSelect(props: Props) {
  const { clinicians, onChange } = props;
  const [clinicianIds, setClinicianIds] = useState([] as number[]);

  useEffect(() => {
    onChange(clinicianIds.filter((s) => clinicians.find((c) => c.id === s)));
  }, [
    clinicianIds,
    clinicians,
    onChange,
  ]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginBottom: "15px",
      }}
    >
      <DeprecatedAntdSelect
        mode="multiple"
        style={{ width: "100%" }}
        placeholder="Type a clinician's name"
        onChange={setClinicianIds}
        optionFilterProp="title"
      >
        {clinicians.map((c) => {
          const name = `${c.first_name} ${c.last_name}`;
          return (
            <DeprecatedAntdSelect.Option key={c.id} value={c.id} title={name}>
              {name}
            </DeprecatedAntdSelect.Option>
          );
        })}
      </DeprecatedAntdSelect>
    </div>
  );
}

export default ClinicianSelect;
