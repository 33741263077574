import { consultNoteActions } from "./";
import { AsyncActionCreator } from "../types";
import { ConsultExtended } from "../../../api/types";
import api from "../../../api";
import { message } from "antd";
import { checkMissingRequiredFields } from "./util";
import { questions } from "../../../app/consults-and-matching/ConsultNote/ConsultNoteQuestions/questionData";

const SAVE_CONSULT_NOTE_URL = "/ehr/save_consult_note/";

export const openConsultNote: AsyncActionCreator =
  (args: { consult: ConsultExtended; readOnly?: boolean }) =>
  async (dispatch) => {
    let consultNoteData = null;
    const r = await getConsultNote(args.consult.id);
    if (r?.consult_note_exists) {
      consultNoteData = r?.data;
    }
    return dispatch(
      consultNoteActions.openConsultNote({
        consult: args.consult,
        consultNoteData,
        readOnly: args.readOnly,
      }),
    );
  };

export const updateNestedField: AsyncActionCreator =
  (args: {
    collection: string;
    id: string;
    key: string;
    value: string;
    order: number;
  }) =>
  (dispatch) => {
    return dispatch(consultNoteActions.updateNestedField(args));
  };

export const saveConsultNote: AsyncActionCreator =
  (eventId, submitting = false) =>
  async (dispatch, getState) => {
    const state = getState();

    const data = {
      eventId,
      submitting,
      data: state.consultnote.openConsult?.consult_note,
    };

    if (submitting) {
      const missingRequiredFields = checkMissingRequiredFields(data.data);
      if (missingRequiredFields.length > 0) {
        return alert(
          `Please enter all required fields. Missing: ${missingRequiredFields.map(
            (rf) => ` ${formatErrorString(rf)}`,
          )}`,
        );
      }
    }

    try {
      const r = await api.post(SAVE_CONSULT_NOTE_URL, data);

      dispatch(consultNoteActions.setConsultNoteData(r.data));
      message.success({
        content: "Note Saved!",
        duration: 2,
        style: {
          fontSize: "16px",
        },
        key: "saveNoteSuccess",
      });
    } catch (e) {
      message.error(
        "There was an error saving your consult note, please reach out to techsupport@twochairs.com",
      );
    }

    if (submitting) {
      return dispatch(consultNoteActions.closeConsultNote());
    } else {
      return;
    }
  };

const formatErrorString = (slug: string) => {
  const number = questions.find((q) => q.slug === slug)?.questionNumber || "";
  const slugToReadable = {
    consult_modality: "Consult Modality",
    self_injurious_behavior: "Self Injurious Behavior",
    endorsed_current_ipv: "Endorsed/Denied IPV",
    criterion_trauma_endorsed: "Criterion A Trauma",
    prelim_dx_code_1: "Prelim Diagnosis 1",
    goals_text: "Goals",
    readiness_to_engage: "Readiness to engage",
    client_cadence: "Client cadence",
    functional_impairment: "Functional impairment section",
    initial_treatment_plan: "Initial Treatment Plan",
  };
  return `${number}. ${slugToReadable[slug]}`;
};

export const getConsultNote = async (eventId: string) => {
  try {
    const response = await api.get(
      `/ehr/get_consult_note_data_by_event_id/${eventId}`,
    );
    if (response.data?.consult_note_does_not_exist) {
      return { consult_note_exists: false };
    }
    return { consult_note_exists: true, data: response.data };
  } catch (e) {
    console.log(`Error with fetching consultNote data: ${e}`);
    return null;
  }
};
