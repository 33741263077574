import React, { useCallback, useMemo } from "react";
import { Row } from "../../../../_layout/Flex";
import { SlotPref } from "../../../../../api/types";
import { useShallowEqualSelector } from "../../../../_helpers/redux";
import { useDispatch } from "react-redux";
import { matchmakerOperations } from "../../../../../state/models/matchmaker";
import { SlotPrefsContainer } from "./styled";
import CheckboxTable, {
  ActiveCheckboxItem,
} from "../../../../_shared/ClickboxTable";
import { ActiveBoxColor } from "../../../../_shared/ClickboxTable/styled";
import { Text } from "@/app/design-system";

type PrefLevel = "preferred" | "maybe";

const prefLevelToBoxColor = (prefLevel: PrefLevel): ActiveBoxColor => {
  switch (prefLevel) {
    case "preferred":
      return "green";
    case "maybe":
      return "yellow";
  }
};

const boxColorToPrefLevel = (boxColor: ActiveBoxColor): PrefLevel => {
  switch (boxColor) {
    case "green":
      return "preferred";
    case "yellow":
      return "maybe";
  }
};

interface Props {
  stateKey: "general" | number;
}
const SlotsByDayCheckboxTable = ({ stateKey }: Props) => {
  const [msp] = useShallowEqualSelector((state) => [
    state.matchmaker.matchSlotPreferences[stateKey],
  ]);

  const activeCheckboxes: ActiveCheckboxItem[] = useMemo(() => {
    const slots = msp ? msp.slots : [];
    return slots.map((slot) => ({
      time: slot.time,
      day: slot.day,
      boxColor: prefLevelToBoxColor(slot.pref_level),
    }));
  }, [msp]);

  const dispatch = useDispatch();

  const onBoxesChanged = useCallback(
    (updatedCheckboxes: ActiveCheckboxItem[]) => {
      const slotPrefs: SlotPref[] = updatedCheckboxes
        .filter((b) => b.boxColor !== null)
        .map((b) => ({
          day: b.day,
          time: b.time,
          pref_level: boxColorToPrefLevel(b.boxColor),
        }));

      dispatch(matchmakerOperations.updateSlotPreferences(stateKey, slotPrefs));
    },
    [dispatch, stateKey],
  );

  return (
    <SlotPrefsContainer>
      <CheckboxTable
        activeCheckboxes={activeCheckboxes}
        onChange={onBoxesChanged}
      />
      <Row layout={"start start"} style={{ marginTop: "3px" }}>
        <div style={{ fontSize: "10px", flex: 1, paddingRight: "3px" }}>
          <div>
            <i>
              * Green boxes represent times that the client{" "}
              <strong>prefers</strong>.
            </i>
          </div>
        </div>
      </Row>
    </SlotPrefsContainer>
  );
};

export default SlotsByDayCheckboxTable;
