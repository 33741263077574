import * as React from "react";
import * as ReactRedux from "react-redux";
import { AppState } from "../../state/models";

import { ShopOutlined } from "@ant-design/icons";

import { Badge, Tooltip } from "antd";
import { Clinician, Clinic } from "../../api/types";
import * as colors from "../../assets/colors";
import {
  PayerTag,
  ServiceStateIcon,
} from "../consults-and-matching/_components/MatchIcons";
import { uniq } from "lodash-es";

interface OwnProps {
  clinicianId: number;
  hideClinic?: boolean;
}
interface StateProps {
  clinicMap: { [id: number]: Clinic };
  clinicianMap: { [id: number]: Clinician };
}

interface Props extends OwnProps, StateProps {}

class ClinicianItem extends React.Component<Props> {
  public render() {
    const { clinicianId, clinicMap, hideClinic } = this.props;
    const clinician = this.props.clinicianMap[clinicianId];
    const clinicianNotActive = clinician === undefined;
    if (clinicianNotActive) {
      return <div>Clinician No Longer Active</div>;
    }
    const payers = uniq(
      clinician.current_payer_service_records.map(
        // @ts-ignore
        (payer) => payer.payer.display_name,
      ),
    );
    return (
      <div>
        <div style={{ display: "flex", alignItems: "center", whiteSpace: 'nowrap' }}>
          {clinician.first_name}&nbsp;
          {clinician.last_name}
          {clinician.degree ? ", " + clinician.degree : ""}&nbsp;
          <span>
            {clinician.service_states &&
              clinician.service_states.map((state) => (
                <ServiceStateIcon state={state} key={state} />
              ))}
          </span>
          <div style={{ display: "flex", flexWrap: "wrap"}}>
            {payers && payers.map((payer) => <PayerTag payer={payer} key={payer} />)}
          </div>
        </div>
        {!hideClinic && (
          <div style={{ color: colors.$greyText, fontSize: "10px" }}>
            {clinician.clinic_ids.map((c_id, idx) =>
              clinicMap[c_id] ? (
                <span
                  style={{ marginRight: "3px" }}
                  key={c_id.toString() + idx.toString()}
                >
                  <ShopOutlined />{" "}
                  {clinician.is_remote
                    ? "Remote Only"
                    : clinicMap[c_id].display_name}{" "}
                </span>
              ) : null,
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps: ReactRedux.MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = (state) => {
  return {
    clinicMap: state.clinics.clinicMap,
    clinicianMap: state.clinicians.clinicianMap,
  };
};

export default ReactRedux.connect(mapStateToProps)(ClinicianItem);
