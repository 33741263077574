import { ComposedSelectRadixUIOption } from "./composed-select-radixui";

export const createRadixSelectOptionValue = (
  value: string,
): ComposedSelectRadixUIOption => {
  return { value, label: value, id: value };
};

interface ComposedSelectRadixUIOptionProps {
  value: string;
  label: string;
  id: string;
}

export const createRadixSelectOption = ({
  value,
  label,
  id,
}: ComposedSelectRadixUIOptionProps): ComposedSelectRadixUIOption => {
  return { value, label, id };
};

/**
 * @name createRadixSelectOptions
 * @description Usually there are some actions we want to take as a result of choosing something from a listbox/select. e.g. Choosing a date filter, and then using the option key to get start/end dates
 */
export const createRadixSelectOptions = (objectList: Object) =>
  Object.keys(objectList).map((obj) => {
    return createRadixSelectOptionValue(obj);
  });
