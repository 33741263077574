import { Modal } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { matchActions } from "../../../state/models/matches";
import { useShallowEqualSelector } from "../../_helpers/redux";

const DataRow = styled.div`
  margin-left: 10px;
`;

const ArrayRow = styled.div`
  margin-left: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export default () => {
  const [aqmScorecard, aqmScorecardModalIsOpen, loadingAqmScorecard] =
    useShallowEqualSelector((state) => [
      state.matches.aqmScorecard,
      state.matches.aqmScorecardModalIsOpen,
      state.matches.loadingAqmScorecard,
    ]);

  const dispatch = useDispatch();

  return (
    <Modal
      {...{
        title: `AQM Scorecard`,
        open: aqmScorecardModalIsOpen,
        width: "900px",
        onCancel: () =>
          dispatch(matchActions.setAqmScorecardModalIsOpen(false)),
        footer: null,
        style: { top: "45px" },
        bodyStyle: { paddingBottom: "30px" },
      }}
    >
      {loadingAqmScorecard ? "Loading..." : null}
      {Object.keys(aqmScorecard).length ? mapDataToDiv(aqmScorecard) : null}
    </Modal>
  );
};

function mapDataToDiv(data: object | string | number, parent?: string): any {
  if (parent === "solution" || parent === "clinician_breakdowns") {
    return;
  }
  if (parent) {
    return (
      <DataRow key={JSON.stringify(data)}>
        {parent}
        {": "}
        {mapDataToDiv(data)}
      </DataRow>
    );
  }
  if (Array.isArray(data)) {
    return data.map((value) => {
      return (
        <ArrayRow key={JSON.stringify(value)}>{mapDataToDiv(value)}</ArrayRow>
      );
    });
  }
  if (typeof data === "object") {
    return Object.entries(data).map(([key, value]) => {
      return <DataRow key={key}>{mapDataToDiv(value, key)}</DataRow>;
    });
  }

  if (typeof data === "string") {
    return data;
  }

  return parseFloat(data.toFixed(2));
}
