export const options = [
  {
    key: "OPTION__none",
    value: "none",
    title: "None (no impairment, very good functioning)",
  },
  {
    key: "OPTION__mild",
    value: "mild",
    title: "Mild (some impairment, good functioning)",
  },
  {
    key: "OPTION__moderate",
    value: "moderate",
    title: "Moderate (some impairment, limited functioning)",
  },
  {
    key: "OPTION_severe",
    value: "severe",
    title: "Severe (severe impairment, low functioning)",
  },

];
