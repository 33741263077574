import React from "react";
import { Flex, Text, styledStitches, CSS_COLORS } from "@/app/design-system";
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
} from "@/app/design-system/accordion/accordion.styled";
import { RightChevronIcon } from "@/app/design-system/icons";
import Mangle from "@/app/_shared/Mangle";
import { YesNoRadioGroup } from "./yes-no-radio-group";
import { PersonIcon } from "@/app/design-system/icons";
import { Link, useParams } from "react-router-dom";
import { useGroupTherapyUser } from "@/app/groups/_hooks/use-group-therapy-user";
import { useMyClientsSearchStore } from "@/app/my-clients/routes/my-clients-page/my-clients-model/my-clients.model";
import { StyledGroupPaneNoLink } from "../../mbc/_components/group-client-mbc-pane.styled";
import { useHasGroupTherapyV2Permissions } from "@/app/groups/group-id/_components/group-details-nav/group-details-nav";
import {
  StyledAccordionContent,
  StyledAccordionTrigger,
  StyledChevron,
} from "@/app/groups/group-id/_components/group-id.styled";
import { userCanAccessHealthie } from "@/app/_helpers/permissions";
import { CurrentUserContext, CurrentConfigContext } from "@/app/app.utils";

const cancellationTypeToFrontendValueMap = {
  late_by_client: "Late cancel by client",
  cancel_by_system: "Canceled by system",
  by_clinician: "Canceled by clinician",
  by_client: "Canceled by client",
  by_twochairs: "Canceled by Two Chairs",
};

const healthieLink = "https://twochairs.gethealthie.com/users/";

const healthieHomeLink = "https://twochairs.gethealthie.com/";

interface GroupDetailsNotesClientPaneProps {
  clientId?: number;
  cancellationType?: string | null;
  attendanceNote?: string | null;
  clientFirstName: string;
  clientGroupEventId: string;
  clientLastName: string;
  appointmentStatus?: string;
  eventStartTime: string;
  healthieId: number | null | string;
  clientUnenrolledDate: string | null;
  clientProfileLink?: string;
}

export const GroupDetailsNotesClientPane = (
  props: GroupDetailsNotesClientPaneProps,
) => {
  const { groupId, sessionId } =
    useParams<{ groupId: string; sessionId: string }>();
  const { clinicianId } = useGroupTherapyUser();
  const { currentlySelectedClinician } = useMyClientsSearchStore();
  const cuser = React.useContext(CurrentUserContext);
  const config = React.useContext(CurrentConfigContext);

  const hasHealthieAccess = userCanAccessHealthie(cuser, config);

  const clinicianIdParam = clinicianId
    ? `clinician_id=${clinicianId}`
    : currentlySelectedClinician
    ? `clinician_id=${currentlySelectedClinician}`
    : "";

  const isV2Enabled = useHasGroupTherapyV2Permissions() ? true : false;

  // first link is to an individual client profile.
  // second link is the current page - for when we don't want user to redirect
  const clientProfileLink = isV2Enabled
    ? `/groups/${groupId}/clients/${props.clientId}/notes?${clinicianIdParam}`
    : `/groups/${groupId}/sessions/${sessionId}/notes?${clinicianIdParam}`;

  const hasNote = !!props.attendanceNote;
  return (
    <StyledGroupPaneNoLink css={{ cursor: "auto" }}>
      {hasNote ? (
        <Accordion
          type="multiple"
          css={{ width: "100%", background: "transparent" }}
        >
          <AccordionItem value={props.clientGroupEventId}>
            <AccordionHeader>
              <Flex css={{ width: "70%" }}>
                <GroupDetailsNotesClientPaneContents
                  {...props}
                  clientProfileLink={clientProfileLink}
                />
              </Flex>
              <StyledAccordionTrigger
                css={{
                  width: "10%",
                  maxWidth: 150,
                }}
              >
                <Flex>
                  <Text css={{ color: "$neutral11" }}>View note</Text>
                  <StyledChevron
                    fill={CSS_COLORS.neutral11}
                    className="caret-icon"
                  />
                </Flex>
              </StyledAccordionTrigger>
              <RowItemContainerLink
                to={clientProfileLink}
                css={{ alignContent: "flex-end", flexGrow: 3 }}
              >
                <Flex justifyContent={"flex-end"}>
                  <RightChevronIcon className="chevron-right" />
                </Flex>
              </RowItemContainerLink>
            </AccordionHeader>
            <StyledAccordionContent>
              {props.attendanceNote}
            </StyledAccordionContent>
          </AccordionItem>
        </Accordion>
      ) : (
        <>
          <>
            <Flex css={{ width: "80%", flexGrow: 1 }}>
              <GroupDetailsNotesClientPaneContents
                {...props}
                clientProfileLink={clientProfileLink}
              />
            </Flex>
            <Link to={clientProfileLink}>
              <Flex
                alignItems={"center"}
                justifyContent={"flex-end"}
                css={{ width: 20, minWidth: "fit-content" }}
              >
                <RightChevronIcon className="chevron-right" />
              </Flex>
            </Link>
          </>
        </>
      )}
    </StyledGroupPaneNoLink>
  );
};

const GroupDetailsNotesClientPaneContents = ({
  cancellationType,
  clientFirstName,
  clientLastName,
  clientGroupEventId,
  appointmentStatus,
  eventStartTime,
  healthieId,
  clientUnenrolledDate,
  clientProfileLink,
}: GroupDetailsNotesClientPaneProps) => {
  const linkToClientProfile = clientProfileLink ? clientProfileLink : "/";

  const viewNotesLink = healthieId
    ? `${healthieLink}${healthieId}/private_notes`
    : healthieHomeLink;

  return (
    <>
      <RowItemContainerLink
        to={linkToClientProfile}
        css={{ width: 25, alignContent: "flex-start" }}
      >
        <PersonIcon width={25} />
      </RowItemContainerLink>
      <RowItemContainerLink
        to={linkToClientProfile}
        css={{ width: 200, pl: 12, minWidth: "fit-content" }}
      >
        <Text
          css={{
            fontSize: 18,
            fontWeight: 700,
            opacity: !!clientUnenrolledDate ? 0.5 : 1,
          }}
        >
          <Mangle>
            {clientFirstName} {clientLastName}
          </Mangle>
        </Text>
      </RowItemContainerLink>
      <RowItemContainer css={{ width: 250 }}>
        <Flex justifyContent={"center"}>
          <ViewNotesLink
            href={viewNotesLink}
            target="_blank"
            css={{ borderBottom: "1px solid currentColor", height: 30 }}
          >
            View Notes
          </ViewNotesLink>
        </Flex>
      </RowItemContainer>
      <RowItemContainer css={{ width: 200 }}>
        <YesNoRadioGroup
          clientFirstName={clientFirstName}
          clientLastName={clientLastName}
          clientGroupEventId={clientGroupEventId}
          appointmentStatus={appointmentStatus}
          eventStartTime={eventStartTime}
          clientUnenrolledDate={clientUnenrolledDate}
        />
      </RowItemContainer>
      <RowItemContainerLink
        to={linkToClientProfile}
        css={{ width: 200, alignContent: "flex-start", pl: 12 }}
      >
        {clientUnenrolledDate ? (
          <Text fontWeight={700} fontSize={16} color={"$red11"}>
            {`Unenrolled ${clientUnenrolledDate}`}
          </Text>
        ) : cancellationType || appointmentStatus === "noshow" ? (
          <Text
            fontWeight={700}
            fontSize={16}
            color={
              appointmentStatus === "noshow" ||
              cancellationType === "late_by_client"
                ? "$red11"
                : "$neutral12"
            }
          >
            {cancellationType
              ? cancellationTypeToFrontendValueMap[cancellationType]
              : "No show"}
          </Text>
        ) : null}
      </RowItemContainerLink>
    </>
  );
};

const RowItemContainerLink = styledStitches(Link, {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
});

const RowItemContainer = styledStitches("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
});

const ViewNotesLink = styledStitches("a", {
  color: "$blue11",
  fontSize: 16,
  zIndex: 3,
});
