import { ModalDialog, Button, Text, Flex } from "@/app/design-system";
import { useNotification } from "@/app/design-system";
import { useQueryClient } from "react-query";
import { useUpdateClientGroupEvent } from "../_api/use-update-client-group-event";

interface ClientGroupEventModalDialogProps {
  children: React.ReactElement;
  clientGroupEventId: string;
  appointmentStatus: string;
}

export const ClientGroupEventModalDialog = ({
  children,
  clientGroupEventId,
  appointmentStatus,
}: ClientGroupEventModalDialogProps) => {
  const queryClient = useQueryClient();
  const { renderNotification } = useNotification();
  const updateClientGroupEvenMutation = useUpdateClientGroupEvent();

  const handleResetClientGroupEvent = () => {
    if (appointmentStatus === "attended" || appointmentStatus === "noshow") {
      updateClientGroupEvenMutation.mutate(
        {
          clientGroupEventId,
          appointmentStatus: null,
        },
        {
          onSuccess: () => {
            renderNotification({
              message: "Successfully reset session attendance.",
              notificationType: "success",
            });
            queryClient.invalidateQueries();
          },
          onError: () => {
            renderNotification({
              message: "Error resetting session attendance",
              notificationType: "error",
            });
            queryClient.invalidateQueries();
          },
        },
      );
    } else if (appointmentStatus === "canceled") {
      updateClientGroupEvenMutation.mutate(
        {
          clientGroupEventId,
          appointmentStatus: null,
          cancellationReason: null,
          attendanceNote: null,
        },
        {
          onSuccess: () => {
            renderNotification({
              message: "Successfully reset session attendance.",
              notificationType: "success",
            });
            queryClient.invalidateQueries();
          },
          onError: () => {
            renderNotification({
              message: "Error resetting session attendance",
              notificationType: "error",
            });
            queryClient.invalidateQueries();
          },
        },
      );
    }
  };

  return (
    <ModalDialog.Root>
      <ModalDialog.Trigger asChild>{children}</ModalDialog.Trigger>
      <ModalDialog.Portal>
        <ModalDialog.Overlay />
        <ModalDialog.Content>
          <ModalDialog.Header title={"Edit Attendance"} />
          <Flex css={{ p: "12px 24px" }}>
            Attendance for this client has already been recorded. Would you like
            to edit their attendance before the 48hr window closes?
          </Flex>

          <ModalDialog.Footer gap={12} justifyContent={"flex-end"}>
            <ModalDialog.Close asChild>
              <Button
                onClick={() => handleResetClientGroupEvent()}
                size={"small"}
                variant={"secondary"}
                css={{ border: "1px solid $neutral12" }}
              >
                <Text>Reset and edit</Text>
              </Button>
            </ModalDialog.Close>

            <ModalDialog.Close asChild>
              <Button size={"small"} css={{ minWidth: 100 }}>
                <Text color={"$neutral0"}>Make no changes</Text>
              </Button>
            </ModalDialog.Close>
          </ModalDialog.Footer>
        </ModalDialog.Content>
      </ModalDialog.Portal>
    </ModalDialog.Root>
  );
};
