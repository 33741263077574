import React, { useState, useEffect } from "react";

import { teamOperations } from "../../state/models/team";
import {
  ClinicianHold,
  ClinicianWithConfiguration,
  NewClinicianHold,
} from "../../api/types";

import ClinicianListItem from "./ClinicianListItem";
import { Divider, Spin } from "antd";
import ListContainer from "../_shared/ListContainer";
import { Title } from "../../app/_layout/display";
import {
  HoldStatusMap,
  holdStatusSelector,
} from "../../state/models/clinicians";
import { Row } from "../_layout/Flex";
import HoldModal2, { HoldModalProps2 } from "./SchedulePauseModal";
import HoldModal, { HoldModalProps } from "./HoldModal";
import * as clinicianOperations from "../../state/models/clinicians/operations";
import { useDispatch } from "react-redux";
import { useShallowEqualSelector } from "../_helpers/redux";
import useTitle from "../hooks/useTitle";
import { useUserHasAnyPermissions } from "@/app/_helpers/permissions";
import { useGetClinicianSchedulePreferences } from "./api/use-clinician-schedule-preference/use-get-clinician-schedule-preference";
import { ClinicianSchedulePreference } from "./api/use-clinician-schedule-preference/use-get-clinician-schedule-preference";
import { useGetCliniciansByAuthGroup } from "../api/use-get-clinicians-by-auth-group/use-get-clinicians-by-auth-group";
import { GetCliniciansByAuthGroupQuery } from "@/graphql/generated";

const RenderTeam = ({
  sectionName = "Team",
  clinicians = [],
  holdStatus,
  onClickHold,
  clinicianSchedulePreferences,
  alphaGroupClinicianIds,
}: {
  sectionName: string;
  clinicians: ClinicianWithConfiguration[];
  holdStatus: HoldStatusMap;
  onClickHold: (
    clinician: ClinicianWithConfiguration,
  ) => (hold?: ClinicianHold) => void;
  clinicianSchedulePreferences: ClinicianSchedulePreference[];
  alphaGroupClinicianIds: number[];
}) => {
  return (
    <div>
      <div style={{ width: "50%" }}>
        <Row layout={"start center"}>
          <Divider orientation="left">
            <Title margin="0px">{sectionName}</Title>
          </Divider>
          <Divider orientation="right">
            <Title margin="0px"></Title>
          </Divider>
        </Row>
      </div>
      {clinicians
        .sort((a, b) => a.first_name.localeCompare(b.first_name))
        .map((c) => {
          return (
            <ClinicianListItem
              key={`${c.id}-clinician`}
              clinician={c}
              holdStatus={holdStatus}
              onHoldButtonClick={onClickHold(c)}
              clinicianSchedulePreference={clinicianSchedulePreferences.find(obj => obj.clinician_id === c.id)}
              alphaGroupClinicianIds={alphaGroupClinicianIds}
            />
          );
        })}
    </div>
  );
};

function Team() {
  useTitle("Team");
  const [
    clinicians,
    loadingTeam,
    holdStatus,
  ] = useShallowEqualSelector((state) => [
    state.team.clinicians,
    state.team.loading,
    holdStatusSelector(state.clinicians),
  ]);

  const { data } = useGetClinicianSchedulePreferences()

  const { cliniciansInGroup: alphaGroupClinicianIds, isLoadingCliniciansInGroup } = useGetCliniciansByAuthGroup({
    groupName: "Cogsworth Alpha",
    select: (data: GetCliniciansByAuthGroupQuery) => data.ehr_clinician.map(obj => obj.id),
  })

  const dispatch = useDispatch();

  const addHold = (hold: NewClinicianHold) => {
    dispatch(clinicianOperations.addHold(hold));
  };
  const updateHold = (hold: ClinicianHold) => {
    dispatch(clinicianOperations.updateHold(hold));
  };
  const deleteHold = (hold: ClinicianHold) => {
    dispatch(clinicianOperations.deleteHold(hold));
  };

  const [hasFetched, setHasFetched] = useState(false);

  const [holdModalProps, setHoldModalProps] = useState<
    HoldModalProps | undefined
  >(undefined);

  const [holdModalProps2, setHoldModalProps2] = useState<
    HoldModalProps2 | undefined
  >(undefined);

  useEffect(() => {
    if (!hasFetched) {
      dispatch(teamOperations.getTeam());
      setHasFetched(true);
    }
  }, [hasFetched, dispatch]);

  const onHoldButtonClick =
    (clinician: ClinicianWithConfiguration) => (hold?: ClinicianHold) => {
      setHoldModalProps({
        clinician,
        existingHold: hold,
        onClose: () => setHoldModalProps(undefined),
        addHold,
        updateHold,
        deleteHold,
      });
    };

  const onHoldButtonClick2 =
    (clinician: ClinicianWithConfiguration) => (hold?: ClinicianHold) => {
      setHoldModalProps2({
        clinician,
        existingHold: hold,
        onClose: () => setHoldModalProps2(undefined),
        addHold,
        updateHold,
        deleteHold,
      });
    };

  // all managers
  const managers = clinicians
    .filter((c) => c.is_manager)
    .sort((a, b) => a.first_name.localeCompare(b.first_name));

  // clinicians w/o a manager to ensure
  // we're not removing from the list
  const cliniciansWithoutManagers = clinicians.filter(
    (c) => !c.people_manager_id,
  );

  const cliniciansWithoutSchedules = clinicians.filter(
    (c) => c.schedule.length === 0,
  );

  const userCanSeeOpsTeamSection = useUserHasAnyPermissions([
    "IsMatchingAdmin",
    "IsSuperUser",
  ]);

  const userSeesCogsworthVersion = useUserHasAnyPermissions([
    "IsCogsworthBetaUser",
  ]);

  return (
    <ListContainer>
      <Spin spinning={loadingTeam} style={{ minHeight: "500px" }}>
        {userCanSeeOpsTeamSection
          ? 5 > 0 && (
              <RenderTeam
                sectionName="Operations Team No Schedule Section"
                clinicians={cliniciansWithoutSchedules}
                holdStatus={holdStatus}
                onClickHold={
                  userSeesCogsworthVersion
                    ? onHoldButtonClick2
                    : onHoldButtonClick
                }
                clinicianSchedulePreferences={data ?? []}
                alphaGroupClinicianIds={isLoadingCliniciansInGroup ? [] : alphaGroupClinicianIds!}
              />
            )
          : null}
        {managers.map((manager) => (
          <RenderTeam
            key={`${manager.id}-team`}
            sectionName={
              managers.length > 1
                ? `${manager.first_name} ${manager.last_name}'s Team`
                : "Team"
            }
            clinicians={clinicians.filter(
              (c) => c.people_manager_id === manager.id,
            )}
            holdStatus={holdStatus}
            onClickHold={
              userSeesCogsworthVersion ? onHoldButtonClick2 : onHoldButtonClick
            }
            clinicianSchedulePreferences={data ?? []}
            alphaGroupClinicianIds={isLoadingCliniciansInGroup ? [] : alphaGroupClinicianIds!}
          />
        ))}
        {cliniciansWithoutManagers.length > 0 && (
          <RenderTeam
            sectionName="Team"
            clinicians={cliniciansWithoutManagers}
            holdStatus={holdStatus}
            onClickHold={
              userSeesCogsworthVersion ? onHoldButtonClick2 : onHoldButtonClick
            }
            clinicianSchedulePreferences={data ?? []}
            alphaGroupClinicianIds={isLoadingCliniciansInGroup ? [] : alphaGroupClinicianIds!}
          />
        )}
      </Spin>
      {userSeesCogsworthVersion
        ? holdModalProps2 && <HoldModal2 {...holdModalProps2} />
        : holdModalProps && <HoldModal {...holdModalProps} />}

    </ListContainer>
  );
}

export default Team;
