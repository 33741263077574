import { NotesIcon } from "@/app/design-system/icons/components/notes-icon";

import { NavigationMenuItem } from "../../components.utility/navigation-menu-item";

interface GroupTherapyMenuItemProps {
  isCollapsed: boolean;
}

export function GroupTherapyMenuItem({
  isCollapsed,
}: GroupTherapyMenuItemProps) {
  return (
    <NavigationMenuItem
      to="/groups"
      menuItemTooltipContent="Group Therapy"
      isCollapsed={isCollapsed}
    >
      <NotesIcon
        height={20}
        width={20}
        aria-hidden={!isCollapsed}
        title={!isCollapsed ? "" : "Group Therapy"}
      />
      {!isCollapsed ? "Group Therapy" : null}
    </NavigationMenuItem>
  );
}
