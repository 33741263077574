import { Button, Flex, Stack } from "@/app/design-system";
import {
  ComposedDropdownMenuCheckboxItem,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuPrimitiveItem,
} from "@/app/design-system/dropdown/dropdown.styled";

import React from "react";

import { DropdownSubMenuTriggerItem } from "../dropdown-sub-menu-trigger-item";
import { useTotalSessionFilterDropdown } from "./use-total-sessions-filter-dropdown";

export const ZERO_TO_NINE_SESSIONS = "0 to 9 sessions";
export const TEN_TO_SIXTEEN_SESSIONS = "10 to 16 sessions";
export const SEVENTEEN_TO_TWENTY_FOUR_SESSIONS = "17 to 24 sessions";
export const TWENTY_FIVE_OR_MORE_SESSIONS = "25+ sessions";

export const TotalSessionsDropdownMenu = () => {
  const {
    clearTotalSessionsFilters,
    numberOfSelectedFilters,
    setMyClientsFilter,
    isFilteringOn0to9Sessions,
    isFilteringOn10to16Sessions,
    isFilteringOn17to25Sessions,
    isFilteringOn25OrMoreSessions,
  } = useTotalSessionFilterDropdown();

  return (
    <DropdownMenu>
      <DropdownSubMenuTriggerItem
        numberOfSelectedFilters={numberOfSelectedFilters}
      >
        Total sessions
      </DropdownSubMenuTriggerItem>

      <DropdownMenuContent sideOffset={15}>
        <ComposedDropdownMenuCheckboxItem
          checked={isFilteringOn0to9Sessions}
          onCheckedChange={() =>
            setMyClientsFilter(
              "isFilteringOn0to9Sessions",
              !isFilteringOn0to9Sessions,
            )
          }
        >
          {ZERO_TO_NINE_SESSIONS}
        </ComposedDropdownMenuCheckboxItem>

        <ComposedDropdownMenuCheckboxItem
          checked={isFilteringOn10to16Sessions}
          onCheckedChange={() =>
            setMyClientsFilter(
              "isFilteringOn10to16Sessions",
              !isFilteringOn10to16Sessions,
            )
          }
        >
          {TEN_TO_SIXTEEN_SESSIONS}
        </ComposedDropdownMenuCheckboxItem>

        <ComposedDropdownMenuCheckboxItem
          checked={isFilteringOn17to25Sessions}
          onCheckedChange={() =>
            setMyClientsFilter(
              "isFilteringOn17to25Sessions",
              !isFilteringOn17to25Sessions,
            )
          }
        >
          {SEVENTEEN_TO_TWENTY_FOUR_SESSIONS}
        </ComposedDropdownMenuCheckboxItem>

        <ComposedDropdownMenuCheckboxItem
          checked={isFilteringOn25OrMoreSessions}
          onCheckedChange={() =>
            setMyClientsFilter(
              "isFilteringOn25OrMoreSessions",
              !isFilteringOn25OrMoreSessions,
            )
          }
        >
          {TWENTY_FIVE_OR_MORE_SESSIONS}
        </ComposedDropdownMenuCheckboxItem>

        <Flex
          justifyContent={"space-between"}
          css={{ borderTop: "1px solid $neutral3", pt: 10, pl: 0 }}
        >
          <DropdownMenuPrimitiveItem css={{ fontSize: 14 }} asChild>
            <Button
              variant={"tertiary"}
              size={"medium"}
              onClick={clearTotalSessionsFilters}
            >
              Clear
            </Button>
          </DropdownMenuPrimitiveItem>
        </Flex>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
