import { SessionHistoryTimeRangeStartDates } from "@/app/_helpers/constants";
import { Text, styledStitches } from "@/app/design-system";
import { ComposedDropdownMenu } from "@/app/design-system/dropdown/dropdown.composed";
import { useGroupTherapyMbcEventScores } from "@/app/groups/group-id/sessions/session-id/mbc/_api/use-group-therapy-mbc-events";
import { selectGroupTherapyMbcEventScores } from "@/app/groups/group-id/sessions/session-id/mbc/_api/use-group-therapy-mbc-events/use-group-therapy-mbc-events.util";
import { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { MBCAssessmentActiveTabs } from "./_components/mbc-active-tabs";
import {
  ParsedClientEventMbcScoresAndSeverities,
  ParsedClientMBCData,
  getParsedClientMBCData,
} from "./_utils/mbc.util";

const TimeRanges = {
  allTime: "All time",
  last3Months: "Last 3 months",
  last6Months: "Last 6 months",
} as const;

const timeRangesArray = Object.values(TimeRanges);
type TimeRangesKeys = keyof typeof TimeRanges;
type TimeRangesType = typeof TimeRanges[TimeRangesKeys];

const useEventScores = (clientScores: ParsedClientMBCData | null) => {
  const [eventScoresToDisplay, setEventScoresToDisplay] = useState<
    ParsedClientEventMbcScoresAndSeverities[] | null
  >(null);
  const [timeRange, setTimeRange] = useState<TimeRangesType>("All time");

  useEffect(() => {
    if (clientScores?.eventScores?.length) {
      if (timeRange === "All time") {
        setEventScoresToDisplay(clientScores.eventScores);
      } else {
        const newEventScoresToDisplay = clientScores.eventScores.filter(
          (score) => {
            const { eventDate } = score;
            const newTime = SessionHistoryTimeRangeStartDates[timeRange];
            return DateTime.fromISO(eventDate) > newTime;
          },
        );
        setEventScoresToDisplay(newEventScoresToDisplay);
      }
    }
  }, [clientScores, timeRange]);

  return {
    eventScoresToDisplay,
    setTimeRange,
    timeRange,
  };
};

export const MBCAssessmentResults = () => {
  const { clientId } = useParams<{ clientId: string }>();

  const { data: allClientScores } = useGroupTherapyMbcEventScores({
    select: selectGroupTherapyMbcEventScores,
  });

  const clientScores = useMemo(
    () => getParsedClientMBCData(allClientScores, clientId),
    [allClientScores, clientId],
  );

  const { eventScoresToDisplay, setTimeRange, timeRange } =
    useEventScores(clientScores);

  return (
    <MBCAssessmentResultsContainerDiv>
      <Text fontWeight={600} fontSize={24}>
        MBC Assessment Results
      </Text>
      <ComposedDropdownMenu<TimeRangesType>
        setValue={setTimeRange}
        currentValue={timeRange}
        values={timeRangesArray}
        triggerStyles={{
          marginRight: 30,
          minWidth: 160,
          border: "2px solid black",
        }}
      />
      <SurveyCompletedPercentage clientScores={clientScores} />
      <MBCAssessmentActiveTabs eventScores={eventScoresToDisplay} />
    </MBCAssessmentResultsContainerDiv>
  );
};

interface SurveyCompletedPercentageProps {
  clientScores: ParsedClientMBCData | null;
}
export const SurveyCompletedPercentage = ({
  clientScores,
}: SurveyCompletedPercentageProps) => {
  const surveysCompletedPercentage =
    clientScores?.totalResponded && clientScores?.totalSent
      ? (
          (clientScores?.totalResponded / clientScores?.totalSent) *
          100
        ).toFixed(0)
      : undefined;

  return !!surveysCompletedPercentage ? (
    <Text color="$neutral11" css={{ pt: 4 }}>
      {surveysCompletedPercentage}% ({clientScores?.totalResponded}/
      {clientScores?.totalSent}) of surveys completed
    </Text>
  ) : null;
};

const MBCAssessmentResultsContainerDiv = styledStitches("div", {
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#fff",
  px: 20,
  pt: 24,
  width: "100%",
  position: "relative",
});
