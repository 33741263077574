import { Text, styledStitches } from "@/app/design-system";
import { keyframes } from "@stitches/react";
import React from "react";
import { ScreenReaderOnlyText } from "./ScreenReaderOnlyText";

interface LoadingPlaceholderProps {
  contentDescription: string;
  children: React.ReactNode;
}

const placeholderAnimate = keyframes({
  "0%": { backgroundPosition: "-650px 0" },
  "100%": { backgroundPosition: "650px 0" },
});

const StyledPlaceholder = styledStitches("div", {
  overflow: "hidden",
  position: "relative",
  animationDuration: "1.7s",
  animationFillMode: "forwards",
  animationIterationCount: "infinite",
  animationTimingFunction: "linear",
  animationName: `${placeholderAnimate}`,
  background: `linear-gradient(to right, #eee 2%, #ddd 18%, #eee 33%)`,
  backgroundSize: "1300px", // Animation Area
});

/**
 *
 * @param {string} contentDescription // extra details on what is being loaded
 * @param {React.ReactNode} children // a child that takes up around the same amount of size of the content that will be loaded. Feel free to put in actual sample data because the data will not be visually apparent and it will be hidden to assistive technologies. However, it will still be apparent in the html.
 * @returns an element that shimmers/looks like it's being loaded
 * @description We need to pass in a child that approximately takes up the amount of size of the final loaded product
 * originally from [this codepen](https://codepen.io/Kaladan/pen/yjgzEo)
 */
export const LoadingPlaceholder = ({
  contentDescription,
  children,
}: LoadingPlaceholderProps) => {
  return (
    <StyledPlaceholder>
      <ScreenReaderOnlyText>{contentDescription}</ScreenReaderOnlyText>
      <div aria-hidden>{children}</div>
    </StyledPlaceholder>
  );
};

export const PlaceholderText = styledStitches(Text, {
  visibility: "hidden",
});
