import * as React from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { InputNumber, Checkbox, Divider } from "antd";
import { useInput } from "../../consults-and-matching/_hooks";
import { FormItemProps } from "antd/es/form";
import useDebounce from "../../hooks/useDebounce";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { matchmakerActions } from "../../../state/models/matchmaker";
import { useShallowEqualSelector } from "../../_helpers/redux";
import { Title } from "../../_layout/display";
import { matchmakerOperations } from "@/state/models/matchmaker";

export default function AgeRangePicker() {
  const matchAgeRange = useShallowEqualSelector(
    (state) => state.matchmaker.matchAgeRange,
  );
  const dispatch = useDispatch();

  const { value: minimumAge, setValue: setMinimumAge } = useInput(
    matchAgeRange?.minimum_age,
  );
  const { value: maximumAge, setValue: setMaximumAge } = useInput(
    matchAgeRange?.maximum_age,
  );
  const [isChecked, setIsChecked] = useState(
    matchAgeRange?.filter_clinical_fits || false,
  );

  const validateInputs = (
    min?: number,
    max?: number,
  ): {
    validateStatus: FormItemProps["validateStatus"];
    help: string | null;
  } => {
    if (min && max) {
      if (max - min < 5) {
        return {
          validateStatus: "error",
          help: "Age range must be at least 5 years",
        };
      }
    }
    return {
      validateStatus: "success",
      help: null,
    };
  };

  const debouncedMin = useDebounce(minimumAge, 500);
  const debouncedMax = useDebounce(maximumAge, 500);

  useEffect(() => {
    dispatch(
      matchmakerActions.setMatchAgeRange({
        minimum_age: debouncedMin,
        maximum_age: debouncedMax,
        filter_clinical_fits: isChecked,
      }),
    );
    dispatch(matchmakerOperations.getPotentialFits());
  }, [debouncedMin, debouncedMax, dispatch, isChecked]);

  return (
    <Form layout="inline">
      <Divider style={{ margin: "15px 0" }} />
      <Title size="small">Age Range</Title>
      <Form.Item {...validateInputs(minimumAge, maximumAge)}>
        <InputNumber
          key="minimumAge"
          min={1}
          onChange={setMinimumAge}
          placeholder="Min"
          value={minimumAge}
        ></InputNumber>
        {" to "}
        <InputNumber
          key="maximumAge"
          min={1}
          onChange={setMaximumAge}
          placeholder="Max"
          value={maximumAge}
        ></InputNumber>
      </Form.Item>
      <Form.Item>
        <Checkbox checked={isChecked} onChange={() => setIsChecked(!isChecked)}>
          Add as a Need
        </Checkbox>
      </Form.Item>
    </Form>
  );
};
