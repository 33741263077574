import { Ehr_Tag } from './../../../graphql/generated';
import { graphqlClient } from '@/api/graphql';
import { toMap } from '@/app/_helpers';
import { useGetTagsQuery } from '@/graphql/generated'; 
import { FIVE_MINUTES } from "@/app/_helpers/constants";


export const useGetTags = ()=> {
  const { data, isLoading } = useGetTagsQuery(
    graphqlClient, 
    {},
    {
      staleTime: FIVE_MINUTES
    },
  );

  return { data, isLoading };
};
