import { IconProps } from "../icon.interface";

export const ClockIcon = ({ fill = " #BD4B00 ", title }: IconProps) => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden={!title}
    >
      {title ? <title>{title}</title> : null}
      <path
        d="M12.6787 5.38232C12.6787 4.83004 12.231 4.38232 11.6787 4.38232C11.1264 4.38232 10.6787 4.83004 10.6787 5.38232H12.6787ZM11.6787 11.3823H10.6787C10.6787 11.7611 10.8927 12.1074 11.2315 12.2768L11.6787 11.3823ZM15.2315 14.2768C15.7255 14.5237 16.3261 14.3235 16.5731 13.8295C16.8201 13.3356 16.6199 12.7349 16.1259 12.4879L15.2315 14.2768ZM20.6787 11.3823C20.6787 16.3529 16.6493 20.3823 11.6787 20.3823V22.3823C17.7538 22.3823 22.6787 17.4575 22.6787 11.3823H20.6787ZM11.6787 20.3823C6.70815 20.3823 2.67871 16.3529 2.67871 11.3823H0.678711C0.678711 17.4575 5.60358 22.3823 11.6787 22.3823V20.3823ZM2.67871 11.3823C2.67871 6.41176 6.70815 2.38232 11.6787 2.38232V0.382324C5.60358 0.382324 0.678711 5.30719 0.678711 11.3823H2.67871ZM11.6787 2.38232C16.6493 2.38232 20.6787 6.41176 20.6787 11.3823H22.6787C22.6787 5.30719 17.7538 0.382324 11.6787 0.382324V2.38232ZM10.6787 5.38232V11.3823H12.6787V5.38232H10.6787ZM11.2315 12.2768L15.2315 14.2768L16.1259 12.4879L12.1259 10.4879L11.2315 12.2768Z"
        fill={fill}
      />
    </svg>
  );
};
