export const $primary = "#000000";
export const $secondary = "#f9c511";
export const $lightYellow = "#fbde78";

export const $border = "#e8e8e8";
export const $tableBorder = "#e8e8e8";

export const $green = "#bbcc66";
export const $forestGreen = "#228B22";
export const $red = "#d98c8c";
export const $yellow = "#f8ea87";
export const $blue = "#afdce6";
export const $pink = "#f6c3cb";
export const $tan = "#DBD8AE";

export const $early = "#f9c511";
export const $night = "#5c6bc0";
export const $grey = "#546e7a";

export const $text = "rgba(0, 0, 0, 0.65)";
export const $greyText = "rgba(0, 0, 0, 0.45)";
export const $greyBG = "#fafbfc";
export const $greyBorder = "#cccccc";
export const $whiteHover = "rgba(0, 0, 0, 0.03)";

export const $antdBorder = "#d9dbdc";

// slot tool colors
export const $atBG = "#fef9e6";
export const $atShade1 = "#fdedb4";
export const $atShade2 = "#fce183";
export const $couplesColor = "#E7C7CB";

export const $kpBlue = "#0078B3";

export const $brandWarmGray = "#F6F4EC";
