import { Stack } from "@/app/design-system";
import { getStatusColorFromStatus, GroupMBCStatus } from "../../_utils/mbc-graph.util";

export const GroupsMBCStatusKey = () => (
  <Stack
    css={{
      backgroundColor: "$neutral2",
      maxWidth: 260,
      p: 16,
      borderRadius: 8,
      ml: 22,
      "@breakpoint1200": {
        display: "none",
      },
    }}
  >
    <p
      style={{
        color: "#8C8C8C",
        textTransform: "uppercase",
        fontSize: 12,
        fontWeight: 400,
        lineHeight: "24px",
      }}
    >
      Status Key
    </p>
    <Stack gap={8}>
      {Object.values(GroupMBCStatus).map((status) => {
        return (
          <div
            key={status}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              marginBottom: 4,
            }}
          >
            <div
              style={{
                minHeight: 18,
                minWidth: 18,
                marginRight: 12,
                marginLeft: 4,
                backgroundColor: getStatusColorFromStatus(status),
                borderRadius: 4,
                marginTop: 4,
                border: "1px solid #8F8F8F",
              }}
            />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p
                style={{
                  fontSize: 13,
                  fontWeight: 400,
                  margin: 0,
                  color: "#595959",
                }}
              >
                {status}
              </p>
            </div>
          </div>
        );
      })}
    </Stack>
  </Stack>
);
