import { graphqlClient } from "@/api/graphql";
import { FIVE_MINUTES } from "@/app/_helpers/constants";
import { useGetClinicianQuery, GetClinicianQuery, Ehr_Clinician } from "@/graphql/generated";
import { QueryKey, UseQueryOptions } from "react-query";

export const useGetClinicians = <TData = GetClinicianQuery, TError = unknown> (options: UseQueryOptions<GetClinicianQuery, TError, TData> = {}) => {
  const { data, isLoading } = useGetClinicianQuery<TData, TError>(
    graphqlClient,
    {},
    { staleTime: FIVE_MINUTES, ...options },
  );

  return { data, isLoading };
};

export const useGetCliniciansTimezone = (clinicianId?: number) => {
  const { data, isLoading } = useGetClinicians<Ehr_Clinician["primary_timezone"] | undefined>({
    select: (data) =>
      data.ehr_clinician.find((c) => c.id === clinicianId!)?.primary_timezone,
    enabled: !!clinicianId,
  });

  return { data, isLoading };
}
