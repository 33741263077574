import { DateTime } from "luxon";
import React, { useState } from "react";
import { EventApiData } from "../../../../../../../../../api/use-my-clients-overview/my-clients-data.interface";
import {
  Button,
  Drawer,
  ScrollArea,
  Stack,
  Text,
  RadixTooltip,
} from "../../../../../../../../../design-system";
import Mangle from "../../../../../../../../../_shared/Mangle";
import { SessionModalDialogAction } from "../../3-session-card/session-modal-dialog-action/session-modal-dialog-action";
import { CancelSessionForm } from "./cancel-session-form/cancel-session-form";
import { useCancelSessionForm } from "./cancel-session-form/use-cancel-session-form";
interface CancelSessionAction {
  children: React.ReactNode;
  serviceLine: string;
  clientFirstName?: string;
  clientLastName?: string;
  event: EventApiData;
}

export const CancelSessionAction = ({
  children,
  serviceLine,
  clientFirstName,
  clientLastName,
  event,
}: CancelSessionAction) => {
  const {
    isDrawerOpen,
    setIsDrawerOpen,
    formattedDate,
    formattedTime,
    isRecurringEvent,
  } = useCancelSessionAction({ event });

  const { methods, control, handleSubmit, reset, getValues } =
    useCancelSessionForm();

  const values = getValues();

  return (
    <Drawer.Root
      onOpenChange={(open) => {
        if (!open) {
          reset();
        }

        setIsDrawerOpen(open);
      }}
      open={isDrawerOpen}
    >
      <RadixTooltip content={"Cancel session"}>
        <Drawer.Trigger asChild>{children}</Drawer.Trigger>
      </RadixTooltip>

      <Drawer.Content>
        <Drawer.Header title={"Cancel scheduled session"} />
        <ScrollArea.Root>
          <ScrollArea.Viewport>
            <Stack
              css={{ px: 20, py: 24, flex: 1 }}
              justifyContent="space-between"
            >
              <Stack gap={24}>
                <Mangle>
                  <Text color={"$neutral11"}>
                    {serviceLine} for {clientFirstName} {clientLastName} with{" "}
                    {event.clinician?.fullName} on {formattedDate} at{" "}
                    {formattedTime}
                  </Text>
                </Mangle>

                <CancelSessionForm
                  formMethods={methods}
                  isRecurringEvent={isRecurringEvent}
                  isException={!!event?.recurrenceData?.isException}
                />
              </Stack>
            </Stack>
          </ScrollArea.Viewport>
          <ScrollArea.Scrollbar orientation="vertical">
            <ScrollArea.Thumb />
          </ScrollArea.Scrollbar>
        </ScrollArea.Root>

        <Drawer.Footer>
          <Drawer.Close asChild>
            <Button variant={"secondary"} size={"small"}>
              <Text fontWeight={700}>Exit without changes</Text>
            </Button>
          </Drawer.Close>

          <SessionModalDialogAction
            setIsDrawerOpen={setIsDrawerOpen}
            mode="cancel"
            handleSubmit={handleSubmit}
            eventId={event.eventId}
            control={control}
            trigger={methods.trigger}
            values={values}
            isRecurringEvent={isRecurringEvent}
            disabled={Object.keys(methods.getValues()).length < 3}
            selectedSessions={values.sessionsToUpdate}
            event={event}
          />
        </Drawer.Footer>
      </Drawer.Content>
    </Drawer.Root>
  );
};

function useCancelSessionAction({ event }: Pick<CancelSessionAction, "event">) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const formattedDate = DateTime.fromISO(event.startTime).toLocaleString(
    DateTime.DATE_SHORT,
  );

  const formattedTime = DateTime.fromISO(event.startTime).toLocaleString(
    DateTime.TIME_SIMPLE,
  );

  const isRecurringEvent = !!event?.recurrenceData;

  return {
    formattedDate,
    formattedTime,
    setIsDrawerOpen,
    isDrawerOpen,
    isRecurringEvent,
  };
}
