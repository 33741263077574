import styled from "styled-components";
import { $antdBorder } from "../../assets/colors";

export const TagContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  background-color: #c4c4c4;
  border-radius: 18px;
  margin-right: 10px;
  margin-bottom: 10px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 8px;
`;

export const NavbarContainer = styled.div`
  box-sizing: border-box;
  position: sticky;
  top: 0px;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0 24px;
  width: 100%;
  height: 56px;
  min-height: 56px;
  z-index: 100;
  background-color: white;
  border-bottom: 1px solid ${$antdBorder};
`;

export const ResourceContainer = styled.div`
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid ${$antdBorder};
  min-width: 220px;
  min-height: 200px;
  margin: 16px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: min-content;
  &:hover {
    box-shadow: 2px 2px 14px 6px #ddd9d9ad;
  }
`;
export const ResourceContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #565636;
  font-weight: 500;
  padding: 16px;
`;
export const ResourceContentFooter = styled.div`
  font-size: 14px;
  color: #979184;
  margintop: auto;
  height: 40px;
  border-top: 1px solid ${$antdBorder};
  padding: 8px 12px;
`;

export const ResourceCategory = styled.span`
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: #323232;
`;

export const ResourceTitle = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.01em;
  margin-bottom: 8px;
`;
