import { styledStitches } from "@/app/design-system";
import { DownChevronIcon } from "@/app/design-system/icons";
import {
  AccordionContent,
  AccordionTrigger,
} from "@/app/design-system/accordion/accordion.styled";

export const StyledAccordionTrigger = styledStitches(AccordionTrigger, {
  alignItems: "center",
  justifyContent: "flex-end",
});

export const StyledChevron = styledStitches(DownChevronIcon, {
  transition: "transform 300ms cubic-bezier(0.87, 0, 0.13, 1)",
  "[data-state=open] &": { transform: "rotate(180deg)" },
});

export const StyledAccordionContent = styledStitches(AccordionContent, {
  color: "$neutral11",
  fontSize: 16,
});
