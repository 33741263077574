import React from "react";
import { Alert, Divider } from "antd";
import Mangle from "../../_shared/Mangle";
import { Title } from "../../_layout/display";

export default ({ matchNote }: { matchNote: string }) =>
  matchNote ? (
    <>
      <Divider style={{ margin: "0px" }} />
      <Alert
        banner={true}
        type="error"
        message={
          <>
            <Title size="xs">Match Note</Title>
            <Mangle>{matchNote}</Mangle>
          </>
        }
        showIcon={false}
      />
    </>
  ) : null;
