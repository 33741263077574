import {
  ClientExtended,
  Event,
  Time,
  IdMap,
  CoupleExtended,
  CouplesMatchAvailability,
} from "../../api/types";
import { times } from "../_helpers/constants";

export type DayOfWeek =
  | "Monday"
  | "Tuesday"
  | "Wednesday"
  | "Thursday"
  | "Friday"
  | "Saturday";
export type WorkingTimes = Exclude<Time, "7am" | "7pm" | "8pm">;
export type EventBuckets = Record<
  DayOfWeek,
  Record<WorkingTimes, Array<SlotOpenSlot | SlotEvent>>
>;
export const timesArray: Time[] = times;
export const daysArray: DayOfWeek[] = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export type EventCellType = "consult" | "therapy" | "couples" | "block";

export type SlotEventType =
  | "open"
  | "event"
  | "closed"
  | "manual_open"
  | "blank"
  | "reservation";
export interface SlotEvent {
  time: Time;
  day: DayOfWeek;

  eventId: string;
  type: SlotEventType;
}

export interface SpecialSlotEvent extends SlotEvent {
  note: string | null;
  start_date: string;
  end_date?: string;
  valid_until_date?: string;
  recurrence: Recurrence;
  type: SlotEventType;
}

export interface SlotOpenSlot extends SpecialSlotEvent {
  type: "open" | "manual_open";
}

export interface SlotClosedSlot extends SpecialSlotEvent {
  expires_at: string;
  type: "closed";
}

export interface SlotReservationSlot extends SpecialSlotEvent {
  type: "reservation";
  slot_type: ReservationType;
  label?: string;
}

export type ScheduleItem = {
  dayOfWeek: DayOfWeek;
  hoursIncluded: number[];
};

export type ClinicianSchedule = {
  [dayOfWeek in DayOfWeek]: ScheduleItem | null;
};

export interface ClinicianManualCapacityV2Data {
  clinician: number;
  capacity: number;
  auto_capacity: number;
  couples_capacity?: number;
  couples_auto_capacity?: number;
  note?: string;
  block_biweekly: boolean;
  block_kp_clients: boolean;
  is_expired: boolean;
  set_at: string;
  _date_start: string;
}

export interface ClinicianVolumeData {
  clinician_id: number;
  weekly_volume_score: number;
  weekly_volume_score_with_pending_matches: number;
  biweekly_clients: number;
  weekly_clients: number;
  unknown_clients: number;
  biweekly_couples: number;
  weekly_couples: number;
  consult_allocation: number;
  couples_allocation: number;
  target_client_hours: number;
  remaining_volume: number;
  number_pending_matches: number;
  source:
    | "legacy_weekly_volume"
    | "tenured_weekly_volume_2023_10"
    | "ramp_beta";
  slots_data: SlotsData;
}

export interface LegacyVolumeData extends ClinicianVolumeData {
  source: "legacy_weekly_volume";
}
export interface ClinicianTenuredVolumeData extends ClinicianVolumeData {
  source: "tenured_weekly_volume_2023_10";
  mrmr_data: TenuredVolumeMetricData;
}

export interface TenuredVolumeMetricData {
  clinician: number;
  timestamp: string;
  weeks_tenure: number;
  weeks_1_out: number;
  weeks_2_out: number;
  weeks_3_out: number;
  weeks_4_out: number;
  pending_matches: number;
  pto_weeks: PtoWeeks;
  avg_4_weeks: number;
  recommended_matches_requested: number;
  target_scheduled_appointments: number;
}

export interface PtoWeeks {
  clinician_id: number;
  weeks_1_out: number | null;
  weeks_2_out: number | null;
  weeks_3_out: number | null;
  weeks_4_out: number | null;
}

export interface ClinicianRampVolumeData extends ClinicianVolumeData {
  source: "ramp_beta";
  ramp_data: RampMetricData;
}

export interface RampMetricData {
  timestamp: string;
  match_session_week_num: number;
  onboarding_start_date: string;
  clinician_id: number;
  full_name: string;
  weeks_1_out: number;
  weeks_2_out: number;
  weeks_3_out: number;
  weeks_4_out: number;
  weeks_1_out_pto_holiday: number;
  weeks_2_out_pto_holiday: number;
  weeks_3_out_pto_holiday: number;
  weeks_4_out_pto_holiday: number;
  pending_matches: number;
  pto_weeks: PtoWeeks;
  requesting_for_week: number;
  target_scheduled_appointments: number;
  avg_4_weeks: number;
  actual_scheduled: number;
  new_appointments_needed: number;
  has_pto_next_week: boolean;
  recommended_matches_requested: number;
}

export function volumeDataIsRamp(
  d: ClinicianVolumeData,
): d is ClinicianRampVolumeData {
  return d.source === "ramp_beta";
}

export function volumeDataIsTenured(
  d: ClinicianVolumeData,
): d is ClinicianTenuredVolumeData {
  return d.source === "tenured_weekly_volume_2023_10";
}

export function volumeDataIsLegacy(
  d: ClinicianVolumeData,
): d is LegacyVolumeData {
  return d.source === "legacy_weekly_volume";
}

export interface SlotsData {
  clinician_id: number;
  open_slots: OpenSlot[];
  errors: any[];
}

export interface OpenSlot {
  clinician_id: number;
  subregion_id: number;
  recurrence: Recurrence;
  start_time: string;
  day_of_week: string;
  start_date: string;
  note: string | null;
  type?: "manual" | string;
  id?: string;
}

export enum Recurrence {
  Biweekly = "biweekly",
  Weekly = "weekly",
}

export type EventMap = IdMap<Event>;
export type ClientMap = IdMap<ClientExtended>;
export type CoupleMap = IdMap<CoupleExtended>;
export type ClinicianVolumeDataMap = IdMap<ClinicianVolumeData>;
export type ClinicianManualCapacityV2DataMap =
  IdMap<ClinicianManualCapacityV2Data>;
export type CouplesMatchAvailabilityMap = IdMap<CouplesMatchAvailability>;

// SlotBuckets is a map of someKey (clients, for example) to eventIds
export type SlotBuckets = IdMap<string[]>;

export interface APIOpenOverrideSlot extends OpenSlot {
  id: string;
}

export interface ExcludedSlot {
  clinician_id: number;
  day_of_week: DayOfWeek;
  start_date: string;
  start_time: WorkingTimes;
  recurrence: string;
  expires_at: string;
  note: string | null;
}

export interface APIExcludedSlot extends ExcludedSlot {
  id: string;
}

export enum ReservationType {
  Consult = "consult",
  Couples = "couples_therapy",
  Individual = "individual",
}

export interface ManualSlotReservation {
  clinician: number;
  start_date: string;
  end_date?: string;
  slot_day_of_week: string;
  slot_time_of_day: string;
  slot_recurrence: Recurrence;
  slot_type: ReservationType;
  client?: ClientExtended;

  // /chronicler/api/viewsets/manual_slots.py:41
  //    41:     mtat_status = SerializerMethodField()
  mtat_status?: "expired" | "pending" | "active";
  expires_at?: string;
  valid_until_date?: string;
}

export interface APIManualSlotReservation extends ManualSlotReservation {
  id: string;
}

export interface ProcedureLimit {
  id: string;
  clinician: number;
  start_date: string;
  end_date: string | null;

  weekly_limit_consults: number | null;
  weekly_limit_kp_consults: number | null;
  weekly_limit_couples: number | null;

  daily_limit_consults: number | null;
  daily_limit_kp_consults: number | null;
  daily_limit_couples: number | null;

  daily_limit_therapy: number | null;
}

export type CreateProcedureLimitPayload = Omit<ProcedureLimit, "id">;
export type ProcedureLimitMap = IdMap<ProcedureLimit>;
export type ClinicianProcedureLimitsMap = {
  [clinicianId: number]: string[];
};
