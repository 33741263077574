import { styledStitches } from "@/app/design-system/styles/stitches.config";
import { $brandWarmGray } from "@/assets/colors";

export const ATSidebarContainer = styledStitches('div', {
  width: '330px',
  height: '100%',
  overflowY: 'scroll',

  padding: '8px',

  display: 'flex',
  flexDirection: 'column',

  backgroundColor: '#ffffff',
});

export const MatchRequestHeaderRow = styledStitches('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '16px',
})

export const MatchRequestWidgetContainer = styledStitches('div', {
  backgroundColor: $brandWarmGray,
  padding: '16px',
  borderRadius: '8px',

})
