import { DatePicker } from "antd";
import moment from "moment";
import * as React from "react";
import { TIMEZONE } from "@/app/my-clients/utils";
import { CurrentUserContext } from "@/app/app.utils";

const dateFormat = "MM/DD/YYYY";
const hourMinuteFormat = "h:mm a";
const hourFormat = "h a";

export type DateTimeRange = [moment.Moment, moment.Moment];

interface Props {
  onChange: (range: DateTimeRange) => void;
  timezone?: string;
  value?: [moment.Moment, moment.Moment];
  defaultValue?: [moment.Moment, moment.Moment];
  disabled?: boolean;
  timeFormat?: string;
  minuteStep?: number;
}

const asRange = (
  timezone: string,
  minuteStep: number | undefined,
  e: any,
): DateTimeRange => {
  const startAt = e![0]!;
  const endAt = e![1]!;

  let newStart = startAt.tz(timezone);
  let newEnd = endAt.tz(timezone);
  if (minuteStep) {
    newStart = newStart.set({
      minutes: Math.round(newStart.minutes() / minuteStep) * minuteStep,
    });
    newEnd = newEnd.set({
      minutes: Math.round(newEnd.minutes() / minuteStep) * minuteStep,
    });
  }

  return [newStart, newEnd];
};

export const DateTimeRangePicker = ({
  onChange,
  timezone,
  defaultValue,
  disabled,
  minuteStep = 60,
  timeFormat = minuteStep === 60 ? hourFormat : hourMinuteFormat,
}: Props) => {
  const cuser = React.useContext(CurrentUserContext);

  return (
    <DatePicker.RangePicker
      style={{ flex: 1 }}
      showTime={{
        format: timeFormat,
        use12Hours: true,
        minuteStep,
      }}
      placeholder={["Start At", "End At"]}
      defaultValue={
        defaultValue || [
          moment()
            .startOf("day")
            .add({ day: 1 })
            .set({ hour: 9, minute: 0 }),
          moment()
            .startOf("day")
            .add({ day: 1 })
            .set({ hour: 17, minute: 0 }),
        ]
      }
      ranges={{
        Tomorrow: [
          moment()
            .startOf("day")
            .add({ day: 1 })
            .set({ hour: 9, minute: 0 }),
          moment()
            .startOf("day")
            .add({ day: 1 })
            .set({ hour: 17, minute: 0 }),
        ],
        "Next Week": [
          moment()
            .startOf("week")
            .add({ week: 1, day: 1 })
            .set({ hour: 9, minute: 0 }),
          moment()
            .endOf("week")
            .add({ week: 1, day: -1 })
            .set({ hour: 17, minute: 0 }),
        ],
        "The Week After": [
          moment()
            .startOf("week")
            .add({ week: 2, day: 1 })
            .set({ hour: 9, minute: 0 }),
          moment()
            .endOf("week")
            .add({ week: 2, day: -1 })
            .set({ hour: 17, minute: 0 }),
        ],
      }}
      format={dateFormat + " " + timeFormat}
      onChange={e => {
        onChange(asRange(timezone || TIMEZONE(cuser), minuteStep, e));
      }}
      disabled={disabled}
    />
  );
};
