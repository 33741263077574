import { IconProps } from "../icon.interface";

export const WashingMachineCycleIcon = ({ title }: IconProps) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden={!title}
    >
      {title ? <title>{title}</title> : null}
      <g clipPath="url(#clip0_1061_53679)">
        <path
          d="M19.0532 3.10083V8.10083H14.0532"
          stroke="#8F8F8F"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.719727 16.4342V11.4342H5.71973"
          stroke="#8F8F8F"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.81139 7.26756C3.23403 6.07321 3.95234 5.0054 4.89928 4.16374C5.84623 3.32209 6.99095 2.73403 8.22663 2.45444C9.46232 2.17485 10.7487 2.21284 11.9657 2.56487C13.1827 2.9169 14.2908 3.57149 15.1864 4.46756L19.0531 8.10089M0.719727 11.4342L4.58639 15.0676C5.48202 15.9636 6.59004 16.6182 7.80707 16.9702C9.0241 17.3223 10.3105 17.3603 11.5462 17.0807C12.7818 16.8011 13.9266 16.213 14.8735 15.3714C15.8204 14.5297 16.5388 13.4619 16.9614 12.2676"
          stroke="#8F8F8F"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1061_53679">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
