import * as React from "react";

import {
  EditIcon,
  FavoriteResourceIcon,
  PersonalResourceIcon,
  PublishStatusIcon,
  TrashIcon,
} from "../Icons";

import { LeftOutlined } from "@ant-design/icons";

import { Modal, Button } from "antd";

import { Resource } from "../types";

import { Row } from "../../_layout/Flex";

interface ResourceDrawerActionsProps {
  resource: Resource;
  isCurator: boolean | null;
  isClinician: boolean | null;
  canEditResource: boolean | null;
  title: string;
  handleFavoriteIconClick: () => void;
  handleSetDetailsView: (isDetailsView: boolean) => void;
  onSetIsEditMode: () => void;
  isDetailsView: boolean;
  isEditMode: boolean;
  handleDeleteResource: () => void;
}

const ResourceDrawerActions = ({
  resource,
  isCurator,
  isClinician,
  canEditResource,
  title,
  handleFavoriteIconClick,
  onSetIsEditMode,
  isDetailsView,
  handleSetDetailsView,
  isEditMode,
  handleDeleteResource,
}: ResourceDrawerActionsProps) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Row layout="space-between" style={{ width: "95%", marginBottom: 10 }}>
      <Row layout="flex-start">
        {!isDetailsView && (
          <LeftOutlined
            onClick={() => handleSetDetailsView(true)}
            style={{ marginRight: 12, cursor: "pointer" }}
          />
        )}
        {title}
      </Row>

      {isDetailsView && !isEditMode && (
        <Row layout="flex-end">
          {canEditResource && <TrashIcon onClick={() => showModal()} />}

          {isCurator && (
            <PublishStatusIcon is_published={resource.is_published} />
          )}
          <PersonalResourceIcon resource={resource} />
          {canEditResource && (
            <EditIcon
              tooltipTitle="Edit Resource"
              onClick={() => onSetIsEditMode()}
            />
          )}
          {isClinician && (
            <FavoriteResourceIcon
              resource={resource}
              onClick={handleFavoriteIconClick}
            />
          )}
        </Row>
      )}
      <Modal
        title="Delete Resource"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel">Cancel</Button>,
          <Button key="delete" danger onClick={() => handleDeleteResource()}>
            Delete Resource
          </Button>,
        ]}
      >
        <p>Are you sure you want to delete this resource?</p>
      </Modal>
    </Row>
  );
};

export { ResourceDrawerActions };
