import { ContactsOutlined, CopyOutlined } from "@ant-design/icons";
import { Button, Checkbox, Divider, Spin, Typography } from "antd";
import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";

import { CurrentConfigContext, CurrentUserContext } from "@/app/app.utils";
import { styledStitches } from "@/app/design-system";
import {
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
} from "@/app/design-system/popover/popover.styled";
import { TIMEZONE } from "@/app/my-clients/utils";
import { Radio } from "antd";
import { isEmpty } from "lodash-es";
import moment from "moment-timezone";
import {
  APIConflictOfInterest,
  BasicMatch,
  ClinicPreference,
  ExtendedMatch,
  Fit,
  MatchPriority,
  MatchSlotPreference,
} from "../../../../api/types";
import * as colors from "../../../../assets/colors";
import { matchOperations } from "../../../../state/models/matches";
import { matchmakerOperations } from "../../../../state/models/matchmaker";
import { copyToClipboard, toMap } from "../../../_helpers";
import { useUserHasAnyPermissions } from "../../../_helpers/permissions";
import { useShallowEqualSelector } from "../../../_helpers/redux";
import { Flex, Row } from "../../../_layout/Flex";
import { Pad, Title } from "../../../_layout/display";
import ClinicPreferenceList from "../../../_shared/ClinicPreferenceList";
import ClinicianItem from "../../../_shared/ClinicianItem";
import ClinicianStatus from "../../../_shared/ClinicianStatus";
import Mangle from "../../../_shared/Mangle";
import FitList from "../../FitList";
import { MatchNoteInput } from "../../ShoppingCart";
import DisplayMatchSlotPreferences from "../DisplayMatchSlotPreferences";
import MatchAssignment from "../MatchAssignment";
import MatchNote from "../MatchNote";
import QueueNote from "../QueueNote";
import { AQMScoreTable } from "../aqm-score-table/aqm-score-table";

interface Props {
  fitFilter?: { [id: number]: { couples: boolean; individual: boolean } };
  matchId: number;
  matchData: any;
}

const ClientPopoverBody = styledStitches("div", { padding: "15px" });
const ClientPopoverHead = styledStitches("div", ClientPopoverBody, {
  borderBottom: "1px solid $neutral4",
});

function MatchQueueItem({ fitFilter, matchId, matchData }: Props) {
  const [
    clinicianMap,
    clinicPrefs,
    matchFits,
    matchTags,
    msps,
    queuedMatches,
    conflictsOfInterest,
  ] = useShallowEqualSelector((state) => [
    state.clinicians.clinicianMap,
    state.matches.matchClinicPreferencesMap[matchId],
    state.matches.matchFitsMap[matchId] || [],
    state.matches.matchTagsMap[matchId] || [],
    state.matches.matchSlotPreferenceMap[matchId],
    state.matches.queuedMatches,
    state.matches.matchConflictsOfInterestMap[matchId] || [],
  ]);
  const cuser = React.useContext(CurrentUserContext);

  const myMatchesMap = {};

  matchData.forEach((item: any) => {
    const matchId = item.match.id;

    myMatchesMap[matchId] = item.match;
  });

  const loadingRelatedModels =
    matchFits === undefined ||
    matchTags === undefined ||
    (msps === undefined && clinicPrefs === undefined);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(matchOperations.getRelatedModelsForMatches([matchId]));
  }, [matchData, matchId]);
  const setMatchPriority = (matchId: number, priority: MatchPriority) =>
    dispatch(matchOperations.setMatchPriority(matchId, priority));
  const setMatchReturningToSameClinician = (
    matchId: number,
    returningToSameClinician: boolean,
  ) =>
    dispatch(
      matchOperations.setMatchReturningToSameClinician(
        matchId,
        returningToSameClinician,
      ),
    );

  const openMatchModal = (match: BasicMatch) =>
    dispatch(
      matchmakerOperations.openMatchmaker({
        match,
        rematch: null,
        consult: null,
      }),
    );

  const matchClick = (matchId: number) => {
    const match = myMatchesMap[matchId];
    const moddedMatch = {
      ...match,
      client: {
        ...match.ehr_client,
        payers: match.ehr_client.client_payer_service_records,
      },
    };
    if (match) {
      return openMatchModal(moddedMatch);
    }
    return null;
  };

  const isMatchingAdmin = useUserHasAnyPermissions([
    "IsMatchingAdmin",
    "IsSuperUser",
  ]);
  const isClinicalLeader = useUserHasAnyPermissions([
    "IsClinicalLeader",
    "IsSuperUser",
  ]);
  const isSuperUser = useUserHasAnyPermissions(["IsSuperUser"]);
  const displayConflictOfInterest = useCallback(
    (coi: APIConflictOfInterest, idx: number) => {
      if (coi.client !== null) {
        return (
          <div key={idx} style={coiStyle}>
            <Mangle>
              {coi.client.first_name} {coi.client.last_name}
            </Mangle>{" "}
            <i style={{ color: colors.$greyText }}>Client</i>
          </div>
        );
      } else if (coi.hq_member !== null) {
        return (
          <div key={idx} style={coiStyle}>
            {coi.hq_member.full_name}{" "}
            <i style={{ color: colors.$greyText }}>Hq Member</i>
          </div>
        );
      } else {
        const clinician = clinicianMap[coi.clinician_id];
        const clinicianName = clinician
          ? `${clinician.first_name} ${clinician.last_name}`
          : `Inactive`;
        return (
          <div key={idx} style={coiStyle}>
            {clinicianName} <i style={{ color: colors.$greyText }}>Clinician</i>
          </div>
        );
      }
    },
    [clinicianMap],
  );

  const match =
    myMatchesMap[matchId] || queuedMatches.find((m) => m.id === matchId);
  if (!match) {
    console.log("Match not found", matchId, queuedMatches, myMatchesMap);
    return null;
  }

  const isCouplesMatch = false;

  const getTs = (match: BasicMatch) => {
    const consultTime = match.consult
      ? //@ts-ignore
        match.consult_event.start_time
      : match.created_at;

    return match.match_after && moment(match.match_after).isAfter(moment())
      ? match.match_after
      : consultTime;
  };

  const consultDate = moment(getTs(match))
    .tz(TIMEZONE(cuser))
    .format("MMMM Do, YYYY");

  const filteredFits = matchFits.filter(
    (fit) =>
      fitFilter === undefined ||
      (fitFilter[fit.clinician] &&
        fitFilter[fit.clinician][match.service_type]),
  );

  const clinicianIds = filteredFits.map((fit) => fit.clinician);

  const cadenceText = match.is_biweekly_fit
    ? match.cadence_pref_strength === "strong"
      ? "Biweekly only"
      : "Prefer biweekly"
    : match.cadence_pref_strength === "strong"
    ? "Weekly only"
    : "Prefer weekly";

  const [returningToSameClinician, setReturningToSameClinician] = useState(
    JSON.parse(JSON.stringify(match.returning_to_same_clinician)),
  );

  const [matchPrio, setMatchPrio] = useState(
    JSON.parse(JSON.stringify(match.priority)),
  );
  const [popoverVisible, setPopoverVisible] = useState(false);

  return (
    <>
      <QueueNote queueNote={match.queue_note} />
      <Pad>
        <Row layout="start stretch" style={{ marginBottom: "5px" }}>
          <Flex>
            <Typography.Text>Methodology version: {match.methodology_version}</Typography.Text>
            <Typography.Text style={{ color: colors.$greyText }}>
              Match ID: {match.id} | Client ID: {match.ehr_client.id}
            </Typography.Text>

            {match.ehr_client.client_payer_service_records.map(
              (p: any, index: number) => (
                <Title
                  size="xs"
                  key={index}
                  style={{ margin: 0, color: colors.$kpBlue }}
                >
                  {p.payer.display_name} Client
                </Title>
              ),
            )}
            {match.is_medicare && (
              <Title size="xs" style={{ margin: 0, color: colors.$kpBlue }}>
                Medicare Client
              </Title>
            )}
            {match.is_medical && (
              <Title size="xs" style={{ margin: 0, color: colors.$kpBlue }}>
                Medi-Cal Client
              </Title>
            )}
            <Title size="xs" margin="0px">
              Conflict of Interest (Client Profile):&nbsp;
            </Title>
            <div style={coiStyle}>
              {match.conflict_of_interest ? (
                match.conflict_of_interest
              ) : (
                <i>Question not answered.</i>
              )}
            </div>
            {conflictsOfInterest.length > 0 && (
              <>
                <Title size="xs" margin="0px">
                  Conflicts of Interest:
                </Title>
                {conflictsOfInterest.length === 0 && (
                  <div style={coiStyle}>
                    <i>No conflicts.</i>
                  </div>
                )}
                {conflictsOfInterest.map(displayConflictOfInterest)}
              </>
            )}
            {!isCouplesMatch && (
              <div>
                <Title size="xs" margin="0px">
                  Delivery Preferences:&nbsp;
                </Title>
                <div style={coiStyle}>
                  {match.delivery_preference === "hybrid"
                    ? "Client prefers hybrid therapy"
                    : match.delivery_preference === "remote"
                    ? "Client prefers ongoing teletherapy"
                    : "Client has no delivery preference"}
                </div>
                {match.delivery_preference !== "hybrid" && (
                  <div style={coiStyle}>
                    Consult Clinician{" "}
                    {match.clinician_approves_ot
                      ? "approves ongoing teletherapy"
                      : "does not approve ongoing teletherapy"}
                  </div>
                )}
              </div>
            )}
          </Flex>
          {!isCouplesMatch && (
            <Flex
              style={{
                borderLeft: "1px solid rgb(232, 232, 232)",
                paddingLeft: "10px",
              }}
            >
              <Row layout="start center" style={{ marginBottom: "10px" }}>
                <Title size="xs" margin="0px">
                  Consult Clinician:&nbsp;
                </Title>
                <Flex>
                  <ClinicianItem
                    clinicianId={
                      match.consult_event
                        ? match.consult_event.clinician.id
                        : match.created_by_clinician || 0
                    }
                    hideClinic={true}
                  />
                </Flex>
              </Row>
              {isMatchingAdmin && (
                <Row layout="start center" style={{ marginBottom: "10px" }}>
                  <Title size="xs" margin="0px">
                    Assignee:&nbsp;
                  </Title>
                  <Flex>
                    <MatchAssignment basicMatch={match} />
                  </Flex>
                </Row>
              )}
              {match.match_after !== null && (
                <Row layout="start center" style={{ marginBottom: "10px" }}>
                  <Title size="xs" margin="0px">
                    Delay Matching Until:&nbsp;
                  </Title>
                  <Flex>{moment(match.match_after).format("LL")}</Flex>
                </Row>
              )}
              <Row layout="start center" style={{ marginBottom: "10px" }}>
                <Title size="xs" margin="0px">
                  {cadenceText}
                </Title>
              </Row>
            </Flex>
          )}
        </Row>
        {!loadingRelatedModels && (
          <>
            <MatchLocationPreferences
              match={match}
              msps={msps}
              clinicPrefs={clinicPrefs}
              clinicianIds={clinicianIds}
            />
            <Title margin="0px" size="xs">
              Fit List
            </Title>
            <FitList<Fit>
              maxScore={match.max_score}
              comparisonTagMap={toMap(matchTags)}
              serviceType={match.service_type}
              fits={filteredFits}
              additionalContent={(f) => <MatchNoteInput matchId={f.match} />}
              version={match.methodology_version}
              fitAction={(f) => {
                const fit = f as Fit;
                return (
                  <Row layout="flex-end center" style={{ width: "260px" }}>
                    <ClinicianStatus
                      clinicianId={fit.clinician}
                      showCouplesAvailability={match.service_type === "couples"}
                    />
                  </Row>
                );
              }}
            />
          </>
        )}
        {loadingRelatedModels && (
          <Pad style={{ textAlign: "center" }}>
            <Spin />
          </Pad>
        )}
      </Pad>
      <MatchNote matchNote={match.match_note} />
      {!loadingRelatedModels && (
        <>
          <Divider style={{ margin: "0px" }} />
          <Row layout={"start center"} style={{ padding: "15px" }}>
            {(isClinicalLeader || isMatchingAdmin) && (
              <Radio.Group
                onChange={(e) => {
                  setMatchPrio(
                    e.target.value === "null" ? null : e.target.value,
                  );

                  setMatchPriority(
                    match.id,
                    e.target.value === "null" ? null : e.target.value,
                  );
                }}
                style={{ marginRight: "15px", width: "140px", zIndex: 100 }}
                value={matchPrio || "null"}
              >
                <Radio key="OPTION__regular_priority" value={"null"}>
                  Regular Priority
                </Radio>
                <Radio key="OPTION__high_acuity" value={"high_acuity"}>
                  High Acuity
                </Radio>
                <Radio key="OPTION__high_priority" value={"high_priority"}>
                  High Priority
                </Radio>
              </Radio.Group>
            )}
            {(isClinicalLeader || isMatchingAdmin) && (
              <Checkbox
                checked={returningToSameClinician}
                onChange={(e) => {
                  setMatchReturningToSameClinician(match.id, e.target.checked);
                  setReturningToSameClinician(e.target.checked);
                }}
              >
                Returning To Same Clinician
              </Checkbox>
            )}
            <Flex />
            {isSuperUser && !isCouplesMatch && match.ehr_client.healthie_id && (
              <Button
                style={{ marginRight: "15px" }}
                href={
                  "https://twochairs.gethealthie.com/users/" +
                  match.ehr_client.healthie_id
                }
                icon={<ContactsOutlined />}
                target="_blank"
              >
                Healthie Profile
              </Button>
            )}
            <Button
              style={{ marginRight: "15px" }}
              onClick={() => copyToClipboard(match.ehr_client.email)}
            >
              <CopyOutlined /> Client Email
            </Button>
            <Popover open={popoverVisible} onOpenChange={setPopoverVisible}>
              <PopoverTrigger asChild>
                <Button style={{ marginLeft: "5px" }}>
                  Client Information
                </Button>
              </PopoverTrigger>
              <PopoverContent
                sideOffset={10}
                align="start"
                alignOffset={-150}
                side="top"
              >
                <ClientPopoverHead>
                  <Mangle>
                    {isCouplesMatch
                      ? `${match.couple!.clientA.first_name} ${
                          match.couple!.clientA.last_name
                        }`
                      : `${match.ehr_client.first_name} ${match.ehr_client.last_name}`}
                  </Mangle>
                </ClientPopoverHead>
                <ClientPopoverBody>
                  {isSuperUser && (
                    <>
                      <div>
                        <Mangle>
                          <strong>
                            {isCouplesMatch ? "Couple" : "Client"} ID:
                          </strong>{" "}
                          {isCouplesMatch
                            ? match.couple!.chroniclerId
                            : match.ehr_client.id}
                        </Mangle>
                      </div>
                      <div>
                        <Mangle>
                          <strong>Match ID:</strong> {match.id}
                        </Mangle>
                      </div>
                    </>
                  )}
                  <div>
                    <Mangle>
                      <strong>Phone:</strong> {match.ehr_client.phone_number}
                    </Mangle>
                  </div>
                  <div>
                    <Mangle>
                      <strong>Email:</strong> {match.ehr_client.email}
                    </Mangle>
                  </div>
                  <div>
                    <Mangle>
                      <strong>Consult Date:</strong> {consultDate}
                    </Mangle>
                  </div>
                </ClientPopoverBody>
                <PopoverArrow />
              </PopoverContent>
            </Popover>
            <Button onClick={() => matchClick(match.id)} type={"primary"}>
              Edit Match
            </Button>
          </Row>
          <AQMScoreTable matchId={match.id} />
        </>
      )}
    </>
  );
}

export default MatchQueueItem;

const MatchLocationPreferences = ({
  match,
  msps,
  clinicPrefs,
  clinicianIds,
}: {
  match: BasicMatch | any | ExtendedMatch;
  msps: MatchSlotPreference[];
  clinicPrefs: ClinicPreference[];
  clinicianIds: number[];
}) => {
  if (isEmpty(msps) && isEmpty(clinicPrefs)) {
    return null;
  }
  if (!isEmpty(msps)) {
    return (
      <DisplayMatchSlotPreferences match={match} clinicianIds={clinicianIds} />
    );
  } else {
    return (
      <ClinicPreferenceList
        clinicPreferences={clinicPrefs.filter(
          (cp: ClinicPreference) => cp.preference_level === "match",
        )}
      />
    );
  }
};

const coiStyle = { margin: "0 10px 10px" };
