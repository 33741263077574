import { forwardRef } from "react";
import { IconProps } from "../icon.interface";

export const EmptyClipboardIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ children, ...props }, forwardedRef) => {
    const { fill = "#171717", width = 25, height = 24, title } = props;

    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden={!title}
      >
        {title ? <title>{title}</title> : null}
        <path
          d="M16.2222 3C15.6699 3 15.2222 3.44772 15.2222 4C15.2222 4.55228 15.6699 5 16.2222 5V3ZM18.2222 4V3V4ZM4.22217 20H3.22217H4.22217ZM6.22217 4V3V4ZM8.22217 5C8.77445 5 9.22217 4.55228 9.22217 4C9.22217 3.44772 8.77445 3 8.22217 3V5ZM16.2222 5H18.2222V3H16.2222V5ZM18.2222 5C18.4874 5 18.7417 5.10536 18.9293 5.29289L20.3435 3.87868C19.7809 3.31607 19.0178 3 18.2222 3V5ZM18.9293 5.29289C19.1168 5.48043 19.2222 5.73478 19.2222 6H21.2222C21.2222 5.20435 20.9061 4.44129 20.3435 3.87868L18.9293 5.29289ZM19.2222 6V20H21.2222V6H19.2222ZM19.2222 20C19.2222 20.2652 19.1168 20.5196 18.9293 20.7071L20.3435 22.1213C20.9061 21.5587 21.2222 20.7957 21.2222 20H19.2222ZM18.9293 20.7071C18.7417 20.8946 18.4874 21 18.2222 21V23C19.0178 23 19.7809 22.6839 20.3435 22.1213L18.9293 20.7071ZM18.2222 21H6.22217V23H18.2222V21ZM6.22217 21C5.95695 21 5.7026 20.8946 5.51506 20.7071L4.10085 22.1213C4.66346 22.6839 5.42652 23 6.22217 23V21ZM5.51506 20.7071C5.32752 20.5196 5.22217 20.2652 5.22217 20H3.22217C3.22217 20.7957 3.53824 21.5587 4.10085 22.1213L5.51506 20.7071ZM5.22217 20V6H3.22217V20H5.22217ZM5.22217 6C5.22217 5.73478 5.32752 5.48043 5.51506 5.29289L4.10085 3.87868C3.53824 4.44129 3.22217 5.20435 3.22217 6H5.22217ZM5.51506 5.29289C5.7026 5.10536 5.95695 5 6.22217 5V3C5.42652 3 4.66346 3.31607 4.10085 3.87868L5.51506 5.29289ZM6.22217 5H8.22217V3H6.22217V5ZM9.22217 3H15.2222V1H9.22217V3ZM15.2222 3H17.2222C17.2222 1.89543 16.3267 1 15.2222 1V3ZM15.2222 3V5H17.2222V3H15.2222ZM15.2222 5V7C16.3267 7 17.2222 6.10457 17.2222 5H15.2222ZM15.2222 5H9.22217V7H15.2222V5ZM9.22217 5H7.22217C7.22217 6.10457 8.1176 7 9.22217 7V5ZM9.22217 5V3H7.22217V5H9.22217ZM9.22217 3V1C8.1176 1 7.22217 1.89543 7.22217 3H9.22217Z"
          fill={fill}
        />
      </svg>
    );
  },
);
