import { forwardRef } from "react";
import { IconProps } from "../icon.interface";

export const NotesIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ children, ...props }, forwardedRef) => {
    const {
      fill = "none",
      stroke = "#171717",
      width = 27,
      height = 26,
      title,
    } = props;

    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 27 26"
        fill={fill}
        ref={forwardedRef}
        aria-hidden={!title}
      >
        {title ? <title>{title}</title> : null}
        <path
          d="M21.8859 7.16176C23.466 8.64322 24.3928 10.6917 24.4625 12.8565C24.5322 15.0213 23.7391 17.1251 22.2576 18.7052"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.8333 8.33331C20.7662 8.33331 22.3333 6.76631 22.3333 4.83331C22.3333 2.90032 20.7662 1.33331 18.8333 1.33331C16.9003 1.33331 15.3333 2.90032 15.3333 4.83331C15.3333 6.76631 16.9003 8.33331 18.8333 8.33331Z"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.83325 16.5C6.76625 16.5 8.33325 14.933 8.33325 13C8.33325 11.067 6.76625 9.5 4.83325 9.5C2.90026 9.5 1.33325 11.067 1.33325 13C1.33325 14.933 2.90026 16.5 4.83325 16.5Z"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.8333 24.6666C20.7662 24.6666 22.3333 23.0996 22.3333 21.1666C22.3333 19.2336 20.7662 17.6666 18.8333 17.6666C16.9003 17.6666 15.3333 19.2336 15.3333 21.1666C15.3333 23.0996 16.9003 24.6666 18.8333 24.6666Z"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.9616 22.301C12.8695 22.8616 10.6403 22.5681 8.76457 21.4851C6.88881 20.4022 5.52009 18.6184 4.95951 16.5263"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.80744 9.30105C5.32524 7.19792 6.6573 5.38662 8.51058 4.26563C10.3639 3.14463 12.5865 2.80576 14.6897 3.32356"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);
