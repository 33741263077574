// TODO: We'll need to eventually make sure the click events are UnstyledButtons and not divs
// JIRA - https://twochairs.atlassian.net/browse/TC-7199
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import { Badge, Button, DatePicker } from "antd";
import RadioGroup from "antd/es/radio/group";
import RadioButton from "antd/es/radio/radioButton";
import Text from "antd/es/typography/Text";
import { Tooltip } from "antd";
import { range, flatten } from "lodash-es";
import moment, { Moment } from "moment";
import React from "react";
import styled from "styled-components";
import {
  ClinicianWithSchedule,
  ScheduleItem,
  WorkFromHomeDays,
} from "../../../api/types";
import { $green, $greyBorder, $greyText } from "../../../assets/colors";
import { DayOfWeek, daysArray } from "../../slot-tool/types";
import { useState } from "react";
import DropDown from "./DropDown";
import { useShallowEqualSelector } from "../../_helpers/redux";
import { clinicListSelector } from "../../../state/models/clinics";
import Input from "antd/es/input/Input";
import { Label } from "@radix-ui/react-label";

const MINIMUM_SCHEDULE_START_DATE = "2021-08-01";

const HOURS_REQUIRED = {
  18: 10,
  27: 15,
  36: 20,
  45: 25,
};

interface Props {
  clinician: ClinicianWithSchedule;
  schedules?: ScheduleItem[];
  isDrafting?: boolean;
  existingClinic?: number;
  onChange?: (
    schedules: SchedulesArray,
    startWeek: Moment,
    workingHours: number,
    workFromHomeDays: WorkFromHomeDays,
    clinic: number,
  ) => unknown;
  workFromHomeDays: WorkFromHomeDays;
}

function generateEmptySchedule(): SchedulesArray {
  const obj = {};
  for (const dow of daysArray) {
    obj[dow] = Array(14).fill(false);
  }

  return obj as SchedulesArray;
}

const ScheduleTableV2: React.FC<Props> = (props: Props) => {
  const [schedules, setSchedules] = React.useState<SchedulesArray>(
    generateEmptySchedule(),
  );
  const [clinicsList] = useShallowEqualSelector((state) => [
    clinicListSelector(state.clinics),
  ]);

  // Corresponds to the checkboxes that allow users to enter which
  // days they are in the office
  const [daysInOffice, setDaysInOffice] = React.useState([
    { label: "Monday", selected: false },
    { label: "Tuesday", selected: false },
    { label: "Wednesday", selected: false },
    { label: "Thursday", selected: false },
    { label: "Friday", selected: false },
  ]);

  // Tracks which days the clinician is working in person or remotely.
  // Days on which the clinician is not working will be undefined. For example, if
  // the clinican is working from home on Monday, in person on Wednesday, and not
  // working the rest of the week, the result would be:
  // { Monday: true, Wednesday: false }
  const [workFromHomeDays, setWorkFromHomeDays] = React.useState(
    props.workFromHomeDays,
  );

  const existingSchedules = props.schedules;
  const { isDrafting, onChange } = props;

  React.useEffect(() => {
    if (existingSchedules) {
      let newSchedules = generateEmptySchedule();
      for (const schedule of existingSchedules) {
        const dow = schedule.day_of_week;
        const timesArray = newSchedules[dow];
        const startTime = parseInt(schedule.start_time.split(":")[0], 10);
        const endTime = parseInt(schedule.end_time.split(":")[0], 10);
        range(startTime, endTime).forEach(
          (time) => (timesArray[time - 7] = true),
        );
        newSchedules[dow] = timesArray;
      }

      // If the incoming schedule is empty, don't overwrite the current schedule.
      // This fixes an edge case where the user would add in some hours and
      // then the calendar would go blank a few seconds later.
      const newScheduleArrays: boolean[][] = Object.values(newSchedules);
      const newScheduleHasEntries = newScheduleArrays.some((scheduleArray) =>
        scheduleArray.includes(true),
      );
      if (!newScheduleHasEntries) {
        return;
      }

      setSchedules(newSchedules);
    }
  }, [existingSchedules]);

  const cellOnClick: React.MouseEventHandler<HTMLTableCellElement> = (
    event,
  ) => {
    if (!isDrafting) {
      return;
    }

    const schedulesCopy = { ...schedules };
    const target = event.target as HTMLTableCellElement;
    if (target.className.indexOf("disabled") > 0) {
      return;
    }
    const xIndex = target.cellIndex;
    const yIndex = (target.parentElement as HTMLTableRowElement).rowIndex;
    // subtract one from yIndex to account for time column ("8am" etc)
    // subtract two from xIndex to account for two header columns,
    // day of the week and virtual or in person
    const targetDay = daysArray[yIndex - 1];
    const targetTime = xIndex - 2;
    const existingValue = schedules[targetDay][targetTime];
    schedulesCopy[targetDay][targetTime] = !existingValue;

    setSchedules(schedulesCopy);

    // Special case: if the user is removing the last cell on a day,
    // remove that day from workFromHomeDays.
    if (!schedulesCopy[targetDay].includes(true)) {
      const updatedWorkFromHomeDays = { ...workFromHomeDays };
      delete updatedWorkFromHomeDays[targetDay];
      setWorkFromHomeDays(updatedWorkFromHomeDays);

      // also make sure that day is deselected from daysInOffice
      const updatedDaysInOffice = [...daysInOffice];
      const idx = updatedDaysInOffice.findIndex(
        (day) => day.label === targetDay,
      );
      if (idx >= 0) {
        updatedDaysInOffice[idx].selected = false;
        setDaysInOffice(updatedDaysInOffice);
      }
    }
    // If the user is adding hours to a day and the workFromHomeDays object
    // doesn't have that day yet, add it in. This may happen if a user edits
    // a previously existing schedule draft or if they're adding hours to a
    // new day of the week.
    if (
      schedulesCopy[targetDay].includes(true) &&
      workFromHomeDays[targetDay] === undefined
    ) {
      const updatedWorkFromHomeDays = { ...workFromHomeDays };
      // set the initial value to the inverse of the "Select days in person" checkbox
      const checkboxObj = daysInOffice.find(
        (obj: DaysInOfficeItem) => obj.label === targetDay,
      );
      if (checkboxObj) {
        updatedWorkFromHomeDays[targetDay] = !checkboxObj.selected;
        setWorkFromHomeDays(updatedWorkFromHomeDays);
      }
    }
  };

  const selectDay = (dayOfWeek: string) => {
    const updatedDays = [...daysInOffice];
    const idx = updatedDays.findIndex((day) => day.label === dayOfWeek);
    if (idx >= 0) {
      const prevSelected = updatedDays[idx].selected;
      updatedDays[idx].selected = !prevSelected;
    }
    setDaysInOffice(updatedDays);
  };

  const updateWorkFromHomeDays = () => {
    // Update the workFromHomeDays state object with data from the daysInOffice
    // state object
    const daysWorking: string[] = Object.keys(schedules).filter((dayOfWeek) =>
      schedules[dayOfWeek].includes(true),
    );
    const workFromHomeDays: WorkFromHomeDays = {};
    daysWorking.forEach((dayOfWeek: string) => {
      const obj = daysInOffice.find(
        (d: { label: string }) => d.label === dayOfWeek,
      );
      const inOffice = obj?.selected;
      workFromHomeDays[dayOfWeek] = !inOffice;
    });

    setWorkFromHomeDays(workFromHomeDays);
  };

  // Track whether workFromHomeDays and inOfficeDays are in sync. If not we
  // enable the "Update" button so the user can click to sync them.
  // Cases where not equal:
  // 1. day is selected in checkbox and undefined or true in workFromHomeDays[day]
  // 2. day is not selected in checkbox and workFromHomeDays[day] is false
  let hybridStatesAreEqual = true;
  daysInOffice.forEach((day: DaysInOfficeItem) => {
    if (day.selected === true) {
      if (
        workFromHomeDays[day.label] === undefined ||
        workFromHomeDays[day.label] === true
      ) {
        hybridStatesAreEqual = false;
      }
    } else if (day.selected === false) {
      if (workFromHomeDays[day.label] === false) {
        hybridStatesAreEqual = false;
      }
    }
  });

  const totalHours = flatten(Object.values(schedules)).filter((e) => e).length;
  let workingHoursMet: number = 0;
  let workingHoursNext: number = 0;

  const [workingHoursWanted, setWorkingHoursWanted] =
    React.useState<number>(workingHoursNext);
  const [startWeek, setStartWeek] = React.useState<Moment | null>();

  let hoursRequiredToReachWanted: number = workingHoursWanted - totalHours;

  const primeTimesRequired = props.clinician.is_network
    ? 0
    : workingHoursWanted / 3;

  const existingFirstSchedule = existingSchedules && existingSchedules[0];
  const [clinic, setClinicId] = useState(props.existingClinic);

  const [showDropDown, setShowDropDown] = useState<boolean>(false);
  const [selectClinic, setSelectClinic] = useState<string>("");

  let clinicsMap = new Map();
  clinicsList.forEach((item) => {
    clinicsMap.set(item.display_name, item.id);
  });
  const clinicM = clinicsMap;
  const clinics = () => {
    let clinicNames = Array.from(clinicM.keys()).sort();
    return clinicNames;
  };
  let existing_name = "None Selected";
  let existing_id = "None";
  if (props.existingClinic !== undefined) {
    existing_id = "" + props.existingClinic;
  }
  for (let [key, value] of clinicM.entries()) {
    if (value === props.existingClinic) existing_name = key;
  }
  const existingClinicName = props.existingClinic;
  /**
   * Toggle the drop down menu
   */
  const toggleDropDown = () => {
    setShowDropDown(!showDropDown);
  };

  /**
   * Callback function to consume the
   * clinic name from the child component
   *
   * @param clinic  The selected clinic
   */
  const clinicSelection = (clinic_name: string): void => {
    setSelectClinic(clinic_name);
    const clinic_selected = clinicM.get(clinic_name);
    setClinicId(clinic_selected);
  };

  let clinic_id_text = clinicM.get(selectClinic) || existing_id;
  let clinic_name_text = selectClinic || existing_name;

  return (
    <>
      {existingFirstSchedule && !isDrafting && (
        <div style={{ marginBottom: "8px" }}>
          This schedule begins on{" "}
          {moment(existingFirstSchedule.start_date).format("MMM D, YYYY")}.
        </div>
      )}
      {isDrafting && (
        <div style={{ marginBottom: "8px" }}>
          <div style={{ marginBottom: "12px" }}>
            <div style={{ marginRight: "8px", marginBottom: "4px" }}>
              Select the week this schedule will begin:
            </div>
            <DatePicker.WeekPicker
              onChange={(date: any) => setStartWeek(date?.startOf("week"))}
              format={"YYYY-MM-DD"}
              placeholder="Select week"
              // default to today's date
              defaultPickerValue={moment()}
              disabledDate={(current: any) =>
                Boolean(
                  current &&
                    current <
                      moment(MINIMUM_SCHEDULE_START_DATE).startOf("week"),
                )
              }
            />
          </div>
          <div
            style={{
              marginRight: "8px",
              marginTop: "8px",
              marginBottom: "4px",
            }}
          >
            <div>Choose your number of weekly available hours.</div>
            <div>
              Please include one hour reserved for lunch on any day with more
              than 4 hours working.
            </div>
            {props.clinician.is_network && (
              <FlexRow>
                <Label>Working Hours:</Label>
                <Input
                  type="number"
                  onChange={(e) =>
                    setWorkingHoursWanted(parseInt(e.target.value))
                  }
                  defaultValue={workingHoursWanted}
                  style={{ maxWidth: "100px" }}
                />
              </FlexRow>
            )}

            {!props.clinician.is_network && (
              <RadioGroup
                onChange={(e) =>
                  setWorkingHoursWanted(parseInt(e.target.value))
                }
                defaultValue={workingHoursWanted}
              >
                {Object.keys(HOURS_REQUIRED).map((workingHours) => (
                  <RadioButton value={workingHours}>{workingHours}</RadioButton>
                ))}
              </RadioGroup>
            )}
          </div>
        </div>
      )}
      <GridContainer>
        <Table>
          {/* header row */}
          <tbody>
            <tr>
              <HeaderTd />
              <HeaderTd />
              {range(14).map((hour) => (
                <HeaderTd key={hour}>
                  {moment(hour + 7, "k").format("ha")}
                </HeaderTd>
              ))}
            </tr>
            {daysArray.map((day) => {
              const workingOnDay = schedules[day].includes(true);
              // If a clinician is not working on a certain day, the UI should not
              // show "Virtual" or "In Person" for that calendar column
              const workingFromHome = workingOnDay
                ? workFromHomeDays[day]
                : undefined;
              return generateTimeRow(
                day,
                schedules[day],
                cellOnClick,
                workingFromHome,
                props.clinician.is_network,
              );
            })}
          </tbody>
        </Table>
        {isDrafting && (
          <FlexColumn>
            <fieldset style={{ marginBottom: "6px" }}>
              <p>Select days in person</p>
              {daysInOffice.map((day: DaysInOfficeItem) => (
                <div style={{ paddingBottom: "10px" }}>
                  <label key={day.label}>
                    <input
                      type="checkbox"
                      checked={day.selected}
                      onChange={(e) => selectDay(e.target.value)}
                      value={day.label}
                      // disable if unchecked and no working hours on this day of week
                      disabled={
                        !day.selected && !schedules[day.label].includes(true)
                      }
                    />{" "}
                    {day.label}
                  </label>
                </div>
              ))}
            </fieldset>
            <Button
              onClick={updateWorkFromHomeDays}
              disabled={hybridStatesAreEqual}
            >
              Update
            </Button>
          </FlexColumn>
        )}
      </GridContainer>
      {isDrafting && (
        <Validation>
          <b>Total Hours:</b> {totalHours}
          {workingHoursMet !== 0 && (
            <>
              This schedule contains enough hours for {workingHoursMet} hour
              work weeks.
            </>
          )}
          <br />
          <StyledList>
            {hoursRequiredToReachWanted > 0 && (
              <li>
                <Text style={{ color: "red" }}>
                  <HourBadge hours={workingHoursWanted} /> hour schedules
                  require {hoursRequiredToReachWanted} more hours.
                </Text>
              </li>
            )}
          </StyledList>
          <div>
            <b>Total In Person Days:</b>{" "}
            {Object.values(workFromHomeDays).filter((v) => v === false).length}
          </div>
          <div style={{ marginBottom: "1em" }}>
            <b>Total Virtual Days:</b>{" "}
            {Object.values(workFromHomeDays).filter((v) => v === true).length}
          </div>
          <b> Clinic ID: </b>
          {clinic_id_text}
          {/* {clinicM.get(selectClinic)} || "None" */}
          <br></br>
          <b> Clinic Selected: </b>
          {clinic_name_text}
          <br></br>
          {/* {selectClinic} || {existing_name};<br></br> */}
          <>
            <Tooltip
              title="You must add a clinic in order to save a schedule"
              placement="left"
            >
              <DropDown
                clinics={clinics()}
                showDropDown={true}
                toggleDropDown={(): void => toggleDropDown()}
                clinicSelection={clinicSelection}
                existing={existing_name}
              />
            </Tooltip>
          </>
        </Validation>
      )}
      {isDrafting && onChange && (
        <Button
          type="primary"
          onClick={() =>
            onChange(
              schedules,
              startWeek!,
              workingHoursWanted,
              workFromHomeDays,
              clinic!,
            )
          }
          disabled={!startWeek || clinic === undefined}
        >
          Submit Draft
        </Button>
      )}
    </>
  );
};

const HourBadge: React.FC<{ hours: number }> = ({ hours }) => (
  <Badge
    style={{
      color: "#333",
      backgroundColor: "#efefef",
      boxShadow: "0 0 0 1px #d9d9d9 inset",
    }}
    count={hours}
  />
);

function generateTimeRow(
  dow: string,
  schedule: boolean[],
  cellOnClick: React.MouseEventHandler<HTMLTableCellElement>,
  workFromHome?: boolean,
  isNetwork?: boolean,
) {
  if (dow === "Saturday") {
    return null;
  }

  const hybridLabel =
    workFromHome === true
      ? "VIRTUAL"
      : workFromHome === false
      ? "IN PERSON"
      : ""; // if workFromHome is undefined, don't show a label

  return (
    <tr key={dow}>
      <HybridLabel data-disabled>{hybridLabel}</HybridLabel>
      <HeaderTd data-disabled>{dow}</HeaderTd>
      {range(14).map((hourIndex) => (
        <TimeCell
          isActive={schedule[hourIndex]}
          onClick={cellOnClick}
          key={hourIndex}
          isDisabled={!isNetwork && [0, 12, 13].includes(hourIndex)}
        ></TimeCell>
      ))}
    </tr>
  );
}

const TimeCell: React.FC<{
  isActive?: boolean;
  onClick?: React.MouseEventHandler;
  isDisabled?: boolean;
}> = ({ isActive, isDisabled, onClick, children }) => {
  return (
    <td
      onClick={onClick}
      className={(isActive ? "active" : "") + (isDisabled ? " disabled" : "")}
      style={{ color: "#000" }}
    >
      {children}
    </td>
  );
};

const Table = styled.table`
  width: 100%;
  padding: 16px;
  border-collapse: collapse;
  border-spacing: 2px;

  tr {
    display: block;
    float: left;

    td {
      &:first-child {
        height: 26px;
      }
    }
  }

  th,
  td {
    display: block;
  }

  td {
    height: 32px;
    border: 0.2rem solid transparent;
    background-clip: padding-box;
    background-color: ${$greyBorder};
    text-align: center;
    line-height: 24px;

    &.active {
      background-color: ${$green};
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  td:first-child {
    width: 80px;
  }
`;

const HeaderTd = styled.td`
  background-color: transparent !important;
  text-align: center;
`;

const HybridLabel = styled(HeaderTd)`
  font-size: 12px;
  color: #6f6f6f;
`;

const Validation = styled.div`
  margin: 8px 0;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 500px 1fr;
  grid-gap: 100px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const FlexRow = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const StyledList = styled.ol`
  margin-bottom: 0;
`;

export default ScheduleTableV2;

export type SchedulesArray = Record<DayOfWeek, Array<boolean>>;

type DaysInOfficeItem = {
  label: string;
  selected: boolean;
};
