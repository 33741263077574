import * as matchmakerOperations from "./operations";
import {
  simpleAction,
  payloadAction,
  actionFactory,
  ActionUnion,
} from "reductser";
import produce from "immer";
import { assertUnreachable } from "../types";
import {
  BasicMatch,
  Consult,
  FitSuggestion,
  FitSuggestionResponse,
  MatchDraft,
  ExtendedMatch,
  DayTime,
  ConflictOfInterest,
  SlotPref,
  MatchAgeRange,
  MatchTag,
  MatchPriority,
} from "../../../api/types";
import {
  NEEDS_TAG_WEIGHT,
  BENEFIT_TAG_WEIGHT,
  TagWeight,
} from "../../../api/constants";
import { remove, uniq, intersection } from "lodash-es";
import moment from "moment";
import { createSelector } from "reselect";
import { AppState } from "..";

export interface WeightTagMap {
  [NEEDS_TAG_WEIGHT]: number[];
  [BENEFIT_TAG_WEIGHT]: number[];
}
export const createDefaultTagMap = (): WeightTagMap => ({
  [NEEDS_TAG_WEIGHT]: [],
  [BENEFIT_TAG_WEIGHT]: [],
});

type SetMatchTagAction = {
  tagId: number;
  weight: TagWeight;
  checked: boolean;
};

type SetClinicianExclusion = {
  clinicianId: number;
  excluded: boolean;
};

type InitialMatchmakerData = {
  match: BasicMatch | null;
  rematch: ExtendedMatch | null;
  consult: Consult | null;
  methodologyVersion?: 1 | 2 | 3;
};

export interface AgePreference {
  ages: string[];
  preference_strength: string | null;
}
export interface GenderPreference {
  genders: string[];
  preference_strength: string | null;
}
export interface RaceEthnicityPreference {
  race_ethnicity: string;
  preference_strength: string | null;
}
export type StateMatchSlotPreference = {
  subregion: number | null;
  location_preference_level: "strong" | "open";
  use_general_slots: boolean;
  slots: SlotPref[];
};
export interface UpdateRematchInfoAction {
  isRematch: boolean;
  rematchNote: string | null;
  rematchToClinicianId: number | null;
  priority?: MatchPriority;
  excludedClinicianIds: number[];
  returningToSameClinician: boolean;
}
export interface GroupsMatchData {
  isGroupsAppropriateMatch: boolean;
  clientIdToEnroll: number | null;
  groupIdToEnroll: string | null;
  dateEnrolledForGroup: string | null;
}
type StateMspPayload = {
  [key: number]: StateMatchSlotPreference;
  general?: {
    slots: SlotPref[];
  };
};
type StateClientProfileData =
  | {
      fetched: boolean;
      conflictOfInterest: string | null;
      locationPreferences: {
        [id: number]: "open" | "strong";
      };
      schedulePreferences: DayTime[];
      hybridModelPreference: string[];
    }
  | false;

const actionMap = {
  patchMatchDraft: payloadAction<Partial<MatchDraft>>(),
  initMatchData: payloadAction<Partial<InitialMatchmakerData>>(),

  setMatchSlotPreferences: payloadAction<StateMspPayload>(),
  removeSubregionFromSlotPreferences: payloadAction<number>(),
  setClientProfileData: payloadAction<StateClientProfileData>(),

  selectFitSuggestion: payloadAction<number | null>(),
  setClientEmailIsValid: payloadAction<{
    email: string;
    clientId: number | null;
    is_kp_referral: boolean;
  }>(),
  stepTo: payloadAction<number>(),
  closeMatchmaker: simpleAction(),
  openMatchmaker: simpleAction(),
  addConsultTag: payloadAction<{ tagId: number; weight: TagWeight }>(),
  setConsultTags: payloadAction<WeightTagMap>(),
  setMatchTags: payloadAction<WeightTagMap>(),
  setRemovedTags: payloadAction<MatchTag[]>(),
  setStarredTagIds: payloadAction<number[]>(),
  setMatchTag: payloadAction<SetMatchTagAction>(),
  setConsultClinicIds: payloadAction<number[]>(),
  setMatchClinicIds: payloadAction<number[]>(),
  setPotentialFits: payloadAction<FitSuggestionResponse>(),
  setClinicianExclusion: payloadAction<SetClinicianExclusion>(),
  setMatchAgeRange: payloadAction<MatchAgeRange>(),
  setLoading: payloadAction<{ [key: string]: boolean }>(),
  loadConflictOfInterestResults: payloadAction<ConflictOfInterest[]>(),
  saveConflictsOfInterest: payloadAction<ConflictOfInterest[]>(),
  updateRematchInfo: payloadAction<UpdateRematchInfoAction>(),
  setIsGroupsAppropriateMatch: payloadAction<boolean>(),
  setGroupsEnrollData: payloadAction<Partial<GroupsMatchData>>(),
  clearGroupsEnrollData: simpleAction(),
  openCouplesMatchmaker: simpleAction(),
};

export const matchmakerActions = actionFactory(actionMap, "MATCHMAKER");

export type MatchmakerActions = ActionUnion<typeof matchmakerActions>;

export interface MatchmakerState {
  isOpen: boolean;
  loading: {
    potentialFits: boolean;
    matchSave: boolean;
    clientPreferences: boolean;
  };
  matchSlotPreferences: StateMspPayload;
  clientProfileData: StateClientProfileData;
  draft: MatchDraft;
  stepIdx: number;
  selectedClinicianId: number | null;
  validEmailMap: {
    [email: string]: { clientId: number | null; is_kp_referral: boolean };
  };
  clientEmail: string;
  initData: InitialMatchmakerData;
  consultTagMap: WeightTagMap;
  matchTagMap: WeightTagMap;
  removedTags: MatchTag[];
  starredTagIds: number[];
  consultClinicIds: number[]; // deprecated
  matchClinicIds: number[]; // deprecated
  potentialFits: FitSuggestion[];
  invalidMatchFits: InvalidMatchFits;
  excludedClinicianIds: number[];
  conflictOfInterestSearchResults: ConflictOfInterest[];
  conflictsOfInterest: ConflictOfInterest[];
  hasSubmittedCOI: boolean;
  matchAgeRange: MatchAgeRange | null;
  rematchToClinicianId: number | null;
  groupsMatchData: GroupsMatchData;
  isOpenCouplesMatchmaker: boolean;
  methodologyVersion: 1 | 2 | 3;
}

type InvalidMatchFits = {
  SERVICE_STATE_MISMATCH?: number[];
  LOCATION_PREFERENCE_MISMATCH?: number[];
  NO_SUBREGION_MATCH?: number[];
  CLINICIAN_EXCLUDED?: number[];
  CLINICIAN_COI?: number[];
  CLINICIAN_NOT_IN_AGE_RANGE?: number[];
  CLINICIAN_NOT_KP_NORCAL?: number[];
  CLINICIAN_NOT_MEDICARE?: number[];
  CLINICIAN_NOT_MEDICAL?: number[];
  CLINICIAN_NO_MATCHING_SERVICE_RECORD?: number[];
  CLINICIAN_IS_WHEEL_RESTRICTED?: number[];
};

const getInitialState = (): MatchmakerState => ({
  isOpen: false,
  loading: {
    potentialFits: false,
    matchSave: false,
    clientPreferences: false,
  },
  draft: {
    clinician_approves_ot: true,
    conflict_of_interest: "",
    consult_id: null,
    exclude_from_aqm: false,
    is_referral: false,
    is_rematch: false,
    hybrid_preference: "teletherapy_ok",
    match_after: null,
    match_note: "",
    max_score: 0,
    priority: null,
    queue_note: "",
    referral_note: "",
    rematch_id: null,
    rematch_note: "",
    returning_to_same_clinician: false,
    // as of 4/22/24, we no longer support biweekly. so all clients will be weekly
    is_biweekly_fit: false,
    cadence_pref_strength: "open",
  },
  initData: {
    match: null,
    consult: null,
    rematch: null,
  },
  matchSlotPreferences: {
    general: { slots: [] },
  },
  clientProfileData: {
    fetched: false,
    conflictOfInterest: "",
    locationPreferences: {},
    schedulePreferences: [],
    hybridModelPreference: [],
  },
  stepIdx: 0,
  selectedClinicianId: null,
  validEmailMap: {},
  clientEmail: "",
  consultTagMap: createDefaultTagMap(),
  matchTagMap: createDefaultTagMap(),
  removedTags: [],
  starredTagIds: [],
  consultClinicIds: [],
  matchClinicIds: [],
  potentialFits: [],
  invalidMatchFits: {},
  excludedClinicianIds: [],
  conflictOfInterestSearchResults: [],
  conflictsOfInterest: [],
  hasSubmittedCOI: false,
  matchAgeRange: null,
  rematchToClinicianId: null,
  groupsMatchData: {
    isGroupsAppropriateMatch: false,
    clientIdToEnroll: null,
    groupIdToEnroll: null,
    dateEnrolledForGroup: null,
  },
  isOpenCouplesMatchmaker: false,
  methodologyVersion: 2,
});

const reducer = (state = getInitialState(), action: MatchmakerActions) =>
  produce(state, (draft) => {
    if (action.reducer === "MATCHMAKER") {
      switch (action.type) {
        case "patchMatchDraft":
          draft.draft = {
            ...draft.draft,
            ...action.payload,
          };
          break;
        case "initMatchData":
          draft.initData = {
            ...draft.initData,
            ...action.payload,
          };
          draft.methodologyVersion =
            draft.initData.match?.methodology_version ||
            draft.initData.rematch?.methodology_version ||
            action.payload.methodologyVersion ||
            2;
          break;

        case "setMatchSlotPreferences":
          draft.matchSlotPreferences = {
            ...draft.matchSlotPreferences,
            ...action.payload,
          };
          break;
        case "setClientProfileData":
          draft.clientProfileData = action.payload;
          if (action.payload) {
            const { locationPreferences, schedulePreferences } = action.payload;
            const clientProfileMsps = {};
            Object.keys(locationPreferences).forEach((subregionId) => {
              clientProfileMsps[subregionId] = {
                subregion: subregionId,
                location_preference_level: locationPreferences[subregionId],
                use_general_slots: true,
                slots: schedulePreferences,
              };
            });
            draft.matchSlotPreferences = {
              ...clientProfileMsps,
              general: {
                slots: schedulePreferences.map((slot) => ({
                  ...slot,
                  pref_level: "preferred",
                })),
              },
            };
            const profileCoi = action.payload.conflictOfInterest || "";
            draft.draft.conflict_of_interest = profileCoi;

            if (!profileCoi || profileCoi === "No") {
              draft.hasSubmittedCOI = true;
            }

            if (action.payload.hybridModelPreference.length === 1) {
              switch (action.payload.hybridModelPreference[0]) {
                case "I would like all virtual care":
                  draft.draft.delivery_preference = "remote";
                  draft.draft.hybrid_preference = "teletherapy_ok";
                  break;
                case "I would like in-person care":
                case "I would like primarily in-person care (with the option for teletherapy sessions as needed)":
                  draft.draft.delivery_preference = "hybrid";
                  draft.draft.hybrid_preference = "teletherapy_ok";
                  break;
                case "I am open to either":
                  draft.draft.delivery_preference = "open_to_either";
                  draft.draft.hybrid_preference = "teletherapy_ok";
                  break;
              }
            }
          } else {
            draft.hasSubmittedCOI = true;
          }
          break;
        case "removeSubregionFromSlotPreferences": {
          const subregionId = action.payload;
          const msps = { ...draft.matchSlotPreferences };
          if (subregionId in msps) {
            delete msps[subregionId];
          }
          draft.matchSlotPreferences = msps;
          break;
        }
        case "setClientEmailIsValid":
          draft.validEmailMap[action.payload.email] = action.payload;
          draft.clientEmail = action.payload.email;
          break;
        case "stepTo":
          if (matchmakerOperations.canStep(state))
            draft.stepIdx = action.payload;
          break;
        case "addConsultTag":
          draft.consultTagMap[action.payload.weight] = uniq([
            ...draft.consultTagMap[action.payload.weight],
            action.payload.tagId,
          ]);
          break;
        case "setConsultTags":
          draft.consultTagMap = {
            ...draft.consultTagMap,
            ...action.payload,
          };
          break;
        case "setMatchTags":
          draft.matchTagMap = {
            ...draft.matchTagMap,
            ...action.payload,
          };
          break;
        case "setRemovedTags":
          draft.removedTags = action.payload;
          break;
        case "setStarredTagIds":
          draft.starredTagIds = action.payload;
          break;
        case "setMatchTag": {
          const { checked, weight, tagId } = action.payload;
          if (checked) {
            draft.matchTagMap = {
              ...draft.matchTagMap,
              [weight]: [...draft.matchTagMap[weight], tagId],
            };
          } else {
            draft.matchTagMap = {
              ...draft.matchTagMap,
              [weight]: draft.matchTagMap[weight].filter((i) => i !== tagId),
            };
          }
          break;
        }
        case "setConsultClinicIds":
          draft.consultClinicIds = action.payload;
          draft.matchClinicIds = action.payload;
          break;
        case "setMatchClinicIds":
          draft.matchClinicIds = action.payload;
          break;
        case "setPotentialFits":
          draft.potentialFits = action.payload.suggestions;
          draft.invalidMatchFits = action.payload.invalid_fits;
          draft.loading.potentialFits = false;
          break;
        case "selectFitSuggestion":
          draft.selectedClinicianId = action.payload;
          break;
        case "setLoading":
          draft.loading = {
            ...draft.loading,
            ...action.payload,
          };
          break;
        case "openMatchmaker":
          draft.isOpen = true;
          break;
        case "setClinicianExclusion":
          if (action.payload.excluded)
            draft.excludedClinicianIds.push(action.payload.clinicianId);
          else
            draft.excludedClinicianIds = draft.excludedClinicianIds.filter(
              (i) => i !== action.payload.clinicianId,
            );
          break;
        case "closeMatchmaker": {
          const initState = getInitialState();
          Object.keys(initState).forEach((key) => {
            draft[key] = initState[key];
          });
          break;
        }
        case "loadConflictOfInterestResults":
          draft.conflictOfInterestSearchResults = action.payload;
          break;
        case "saveConflictsOfInterest":
          draft.conflictsOfInterest = action.payload;
          draft.hasSubmittedCOI = true;
          break;
        case "setMatchAgeRange":
          draft.matchAgeRange = action.payload;
          break;
        case "updateRematchInfo":
          draft.draft.is_rematch = action.payload.isRematch;
          if (action.payload.priority !== undefined) {
            draft.draft.priority = action.payload.priority;
          }
          draft.draft.rematch_note = action.payload.rematchNote || "";
          draft.draft.returning_to_same_clinician =
            action.payload.returningToSameClinician;
          draft.rematchToClinicianId = action.payload.rematchToClinicianId;
          draft.excludedClinicianIds = action.payload.excludedClinicianIds;
          break;
        case "setIsGroupsAppropriateMatch":
          draft.groupsMatchData = {
            ...draft.groupsMatchData,
            isGroupsAppropriateMatch: action.payload,
          };
          break;
        case "setGroupsEnrollData":
          draft.groupsMatchData = {
            ...draft.groupsMatchData,
            clientIdToEnroll: action.payload.clientIdToEnroll || null,
            groupIdToEnroll: action.payload.groupIdToEnroll || null,
            dateEnrolledForGroup: action.payload.dateEnrolledForGroup || null,
          };
          break;
        case "clearGroupsEnrollData":
          draft.groupsMatchData = {
            ...draft.groupsMatchData,
            clientIdToEnroll: null,
            groupIdToEnroll: null,
            dateEnrolledForGroup: null,
          };
          break;
        case "openCouplesMatchmaker":
          draft.isOpenCouplesMatchmaker = true;
          break;
        default:
          assertUnreachable(action);
      }
    }
  });

const matchmakerSelector = (state: AppState) => state.matchmaker;
const clientSelector = createSelector(
  (state: AppState) => state.matchmaker.initData,
  ({ consult, match, rematch }) =>
    consult?.client || match?.client || rematch?.client || null,
);

const matchSelector = createSelector(
  (state: AppState) => state.matchmaker.initData,
  ({ match, rematch }) => match || rematch || null,
);

const isKpReferralSelector = createSelector(
  clientSelector,
  matchmakerSelector,
  (client, matchmaker) =>
    client
      ? client.is_kp_referral
      : Boolean(
          matchmaker.validEmailMap[matchmaker.clientEmail]?.is_kp_referral,
        ),
);

const matchOtSelector = createSelector(
  (matchmaker: MatchmakerState) => matchmaker.draft,
  (matchDraft) => ({
    showOtFits:
      matchDraft.clinician_approves_ot === true &&
      ["teletherapy_only", "open_to_either", "remote"].includes(
        matchDraft.delivery_preference || "",
      ),
    showInPersonFits:
      matchDraft.clinician_approves_ot === false ||
      ["in_person_only", "open_to_either", "hybrid"].includes(
        matchDraft.delivery_preference || "",
      ),
  }),
);

const matchmakerUtils = {
  clientSelector,
  isKpReferral: isKpReferralSelector,
  matchOtSelector,
  getFilteredFits: createSelector(
    (state: AppState) => state.matchmaker.potentialFits,
    (state: AppState) => state.matchmaker.invalidMatchFits,
    (potentialFits, invalidMatchFits: InvalidMatchFits) => {
      const fits = [...potentialFits];

      const locationMismatchFits = remove(
        fits,
        (fit) =>
          invalidMatchFits.LOCATION_PREFERENCE_MISMATCH?.includes(
            fit.clinician.id,
          ) ||
          invalidMatchFits.NO_SUBREGION_MATCH?.includes(fit.clinician.id) ||
          invalidMatchFits.SERVICE_STATE_MISMATCH?.includes(fit.clinician.id),
      );

      // FILTER BY CONFLICTS OF INTEREST
      const conflictOfInterestFits = remove(fits, (fit) =>
        invalidMatchFits.CLINICIAN_COI?.includes(fit.clinician.id),
      );

      // FILTER BY MANUALLY EXCLUDED FITS
      const excludedFits = remove(fits, (fit) =>
        invalidMatchFits.CLINICIAN_EXCLUDED?.includes(fit.clinician.id),
      );

      // FILTER BY AGE RANGE
      const outOfAgeRangeFits = remove(fits, (fit) =>
        invalidMatchFits.CLINICIAN_NOT_IN_AGE_RANGE?.includes(fit.clinician.id),
      );

      const nonKpFits = remove(fits, (fit) =>
        invalidMatchFits.CLINICIAN_NOT_KP_NORCAL?.includes(fit.clinician.id),
      );

      const nonMedicalFits = remove(fits, (fit) =>
        invalidMatchFits.CLINICIAN_NOT_MEDICAL?.includes(fit.clinician.id),
      );

      const nonMedicareFits = remove(fits, (fit) =>
        invalidMatchFits.CLINICIAN_NOT_MEDICARE?.includes(fit.clinician.id),
      );

      const credentialMismatchFits = remove(fits, (fit) =>
        invalidMatchFits.CLINICIAN_NO_MATCHING_SERVICE_RECORD?.includes(
          fit.clinician.id,
        ),
      );

      const wheelRestrictionsFits = remove(fits, (fit) =>
        invalidMatchFits.CLINICIAN_IS_WHEEL_RESTRICTED?.includes(
          fit.clinician.id,
        ),
      );

      return {
        potentialFits,
        fits,
        excludedFits,
        conflictOfInterestFits,
        outOfAgeRangeFits,
        locationMismatchFits,
        nonKpFits,
        nonMedicalFits,
        nonMedicareFits,
        credentialMismatchFits,
        wheelRestrictionsFits,
      };
    },
  ),
};

export default reducer;
export { matchmakerOperations, matchmakerUtils };
