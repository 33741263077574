import { Text, styledStitches, Flex } from "@/app/design-system";
import { Spin } from "antd";
import { DateTime } from "luxon";
import { useGroupTherapyClientMBCSurveyInfo } from "../_api/use-group-therapy-client-mbc-survey-info/use-group-therapy-client-mbc-survey-info.api";
import {
  SurveyDateAndResponses,
  QuestionAndAnswer,
  selectGroupTherapyClientMBCSurveyData,
} from "../_api/use-group-therapy-client-mbc-survey-info/use-group-therapy-client-mbc-survey-info.util";

interface SurveyResultsProps {
  surveyName: string;
  timezone: string;
}

export const SurveyResults = ({ surveyName, timezone }: SurveyResultsProps) => {
  const { data, isLoading, isError } = useGroupTherapyClientMBCSurveyInfo({
    select: selectGroupTherapyClientMBCSurveyData,
  });

  const surveyAnswerData: SurveyDateAndResponses = data
    ? data[surveyName]
    : null;

  const isShowSurveyData =
    !!surveyAnswerData?.responses?.length && !isLoading && !isError;

  const sessionDate = surveyAnswerData?.dateAnswered
    ? DateTime.fromISO(surveyAnswerData?.dateAnswered, {
        zone: timezone,
      }).toFormat("M/d/yy")
    : null;

  const sessionDateText = !!sessionDate
    ? `Individual survey results prior to session
        ${surveyAnswerData?.sessionNumber} on ${sessionDate}`
    : "Individual survey results";

  return isLoading && !isError ? (
    <Spin />
  ) : (
    <SurveyResultsContent
      isShowSurveyData={isShowSurveyData}
      surveyAnswerData={surveyAnswerData}
      sessionDateText={sessionDateText}
      surveyName={surveyName}
    />
  );
};

interface SurveyResultsContentProps {
  isShowSurveyData: boolean;
  surveyAnswerData: SurveyDateAndResponses;
  sessionDateText: string;
  surveyName: string;
}

const SurveyResultsContent = ({
  isShowSurveyData,
  surveyAnswerData,
  sessionDateText,
  surveyName,
}: SurveyResultsContentProps) =>
  isShowSurveyData ? (
    <>
      <ResponseHeaderText>{sessionDateText}</ResponseHeaderText>
      <ResponseAndKeyContainer>
        <Flex css={{ flexDirection: "column", flexGrow: 2 }}>
          {surveyAnswerData?.responses?.map(
            (surveyAnswer: QuestionAndAnswer, i, arr) => (
              <SurveyQuestionAndAnswer
                key={surveyAnswer.questionText}
                questionAnswerData={surveyAnswer}
                isLast={arr.length - 1 === i ? true : false}
              />
            ),
          )}
        </Flex>
        <Flex css={{ paddingLeft: 35 }}>
          <SurveyResponseKey track={surveyName} />
        </Flex>
      </ResponseAndKeyContainer>
    </>
  ) : null;

interface SurveyQuestionAndAnswerProps {
  questionAnswerData: QuestionAndAnswer;
  isLast: boolean;
}

const SurveyQuestionAndAnswer = ({
  questionAnswerData,
  isLast,
}: SurveyQuestionAndAnswerProps) => {
  const bottomBorder = isLast ? "1px solid $neutral8" : "none";
  return (
    <QuestionAndAnswerBox css={{ borderBottom: bottomBorder }}>
      <Flex css={{ width: "80%" }}>
        <ResponseText>{questionAnswerData?.questionNumber}.</ResponseText>
        <ResponseText>{questionAnswerData?.questionText}</ResponseText>
      </Flex>

      <AnswerBox>
        <Text fontWeight={500}>{questionAnswerData?.answerText}</Text>
      </AnswerBox>
    </QuestionAndAnswerBox>
  );
};

interface SurveyResponseKey {
  track: string;
}

const SurveyResponseKey = ({ track }: SurveyResponseKey) => {
  const questions = {
    gad: "Over the last 2 weeks, how often have you been bothered by any of the following problems?",
    phq: "Over the last 2 weeks, how often have you been bothered by any of the following problems?",
    cohesion: "Please reflect on your experience in your most recent session.",
  };

  return (
    <ResponseKeyContainer>
      <SurveyAnswerScoreKeyText style={{ color: "#8C8C8C" }}>
        SURVEY ANSWER SCORE KEY
      </SurveyAnswerScoreKeyText>
      <div style={{ paddingTop: 16 }}>
        <SurveyAnswerScoreKeyText css={{ fontWeight: 500 }}>
          Question asked:{" "}
        </SurveyAnswerScoreKeyText>
        <SurveyAnswerScoreKeyText css={{ fontSize: 12 }}>
          {questions[track]}
        </SurveyAnswerScoreKeyText>
      </div>
    </ResponseKeyContainer>
  );
};

const ResponseHeaderText = styledStitches(Text, {
  fontSize: 18,
  fontWeight: 600,
  padding: 4,
  paddingLeft: 16,
});

const ResponseAndKeyContainer = styledStitches(Flex, {
  flexDirection: "row",
  padding: 16,
});

const QuestionAndAnswerBox = styledStitches(Flex, {
  border: "1px solid $neutral8",
  minHeight: 60,
  maxHeight: 100,
  flexDirection: "column",
  overflow: "auto",
  flexGrow: 2,
});

const AnswerBox = styledStitches(Flex, {
  justifyContent: "flex-start",
  borderLeft: "1px solid $neutral8",
  paddingLeft: 10,
  width: "20%",
});

const ResponseText = styledStitches(Text, {
  paddingLeft: 5,
});

const ResponseKeyContainer = styledStitches("div", {
  flexDirection: "column",
  backgroundColor: "#F8F8F8",
  width: 250,
  padding: 5,
  paddingLeft: 10,
  minHeight: 150,
  maxHeight: 300,
});

const SurveyAnswerScoreKeyText = styledStitches("span", {
  fontSize: 13,
  fontWeight: 400,
  lineHeight: "24px",
  fontFamily: "Inter",
  textAlign: "left",
});
