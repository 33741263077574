import AqmTuner from "../../../../consults-and-matching/AqmTuner";
import { ConsultNote } from "../../../../consults-and-matching/ConsultNote/ConsultNote";
import CouplesMatchmaker from "../../../../consults-and-matching/CouplesMatchmaker";
import MatchingAdminTool from "../../../../consults-and-matching/MatchingAdminTool";
import Matchmaker from "../../../../consults-and-matching/Matchmaker";
import AQMScorecardModal from "../../../../consults-and-matching/MatchQueue/AQMScorecardModal";
import ShoppingCart from "../../../../consults-and-matching/ShoppingCart";
import ClinicianTagModal from "../../../../team/ClinicianTagModal";
import ConsentSurveyModal from "../../../../_shared/ConsentSurveyModal";

export function ComponentsWithoutRoutes() {
  return (
    <>
      <MatchingAdminTool />
      <Matchmaker />
      <CouplesMatchmaker />
      <ConsultNote />
      <ShoppingCart />
      <AqmTuner />
      <ClinicianTagModal />
      <ConsentSurveyModal />
      <AQMScorecardModal />
    </>
  );
}
