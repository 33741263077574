import React from "react";
import styled from "styled-components";
import {
  $border,
  $whiteHover,
  $greyBorder,
} from "../../../../../assets/colors";

export const LocationContainer = styled.div`
  margin: 0 0 8px;
  border: 1px solid ${$border};
`;

export const LocationRowHeader = styled.h4`
  margin: 0;
`;

const Container = styled.div`
  padding: 15px;
  border-left: 3.5px solid ${$greyBorder};
`;
export const SlotPrefsContainer: React.FunctionComponent<{
  style?: React.CSSProperties;
}> = ({ children, style }) => <Container style={style}>{children}</Container>;

export const styles: { [key: string]: React.CSSProperties } = {
  locationRowStyle: {
    backgroundColor: $whiteHover,
    padding: "3px 5px",
    borderBottom: `1px solid ${$border}`,
  },
};
