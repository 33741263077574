import * as RadixLabel from "@radix-ui/react-label";
import { styledStitches } from "@/app/design-system/styles/stitches.config";

import { Text } from "@/app/design-system";

const StyledLabel = styledStitches(RadixLabel.Root, {
  position: "relative",
  display: "flex",
  gap: 2,
  alignItems: "flex-start",
  /**
   * implicitly, labels should not be separated from the
   * background they sit on. but explicitly setting a
   * z index is also weird. had to do this to adjust for the
   * label showing through a select popover. help me
   */
  zIndex: 0,
});

interface LabelProps {
  required?: boolean;
  cursor?: string;
  children: React.ReactNode;
  htmlFor?: string;
  className?: string;
  requiredAsteriskOffset?: number;
}

/**
 * @component Label
 * @description
 * @params
 */

export const Label = ({
  required,
  children,
  htmlFor,
  ...props
}: LabelProps) => (
  <StyledLabel htmlFor={htmlFor} role="label" {...props}>
    {children}
    {required && (
      <Text color="$red11" aria-hidden>
        *
      </Text>
    )}
  </StyledLabel>
);
