import * as React from "react";
import { useDispatch } from "react-redux";
import { useShallowEqualSelector } from "../../../../_helpers/redux";
import { Select as DeprecatedAntdSelect } from "antd";
import { consultNoteActions } from "../../../../../state/models/consultnote";
import enums from "./enums";
import { ConsultNoteQuestion } from "../../../../../api/types";
import QuestionTitle from "../shared/QuestionTitle";
import { SelectSearchProps } from "../shared/SelectSearchProps";
import { getCurrentConsultNoteInfo } from "@/app/consults-and-matching/_hooks";

interface SingleDropdownProps {
  question: ConsultNoteQuestion;
  handleEdit: (hasBeenEdited: boolean) => void;
  info?: JSX.Element;
}

export default ({ question, handleEdit, info }: SingleDropdownProps) => {
  const { readOnlyConsultNote } = getCurrentConsultNoteInfo();

  let [value] = useShallowEqualSelector((state) => [
    state.consultnote?.openConsult?.consult_note?.[question.slug],
  ]);

  const dispatch = useDispatch();

  const handleChange = (v: any) => {
    handleEdit(true);
    dispatch(
      consultNoteActions.setConsultNoteValue({
        key: question.slug,
        value: v,
      }),
    );
  };

  const optionsSelector = question.slug.includes("prelim_dx_code")
    ? "prelim_dx_code"
    : question.slug;
  const options = enums[`${optionsSelector}_options`];

  return (
    <div style={{ paddingTop: "15px", paddingBottom: "15px" }}>
      <QuestionTitle
        questionNumber={question.questionNumber}
        titleText={question.title}
        subtitleText={question.subtitle}
        isRequired={question.isRequired}
        hideQuestionNumber={question.hideNumber}
      />
      {info}
      <DeprecatedAntdSelect<string>
        disabled={readOnlyConsultNote}
        style={{ width: "100%" }}
        value={value}
        onChange={handleChange}
        {...SelectSearchProps}
      >
        {options.map((so: any) => {
          return (
            <DeprecatedAntdSelect.Option key={so.key} value={so.value}>
              {so.title}
            </DeprecatedAntdSelect.Option>
          );
        })}
      </DeprecatedAntdSelect>
    </div>
  );
};
