import { graphqlClient } from "@/api/graphql"
import { IdMap } from "@/api/types";
import { Ehr_Cliniciantag, GetClinicianTagsQuery, useGetClinicianTagsQuery } from "@/graphql/generated"

export type UseGetClinicianTagTags = GetClinicianTagsQuery["ehr_cliniciantag"]
export type UseGetBulkClinicianTagsData = ReturnType<typeof useGetBulkClinicianTags>["data"];

export const useGetBulkClinicianTags = (clinician_ids: number[]) => {
  const { data, isLoading } = useGetClinicianTagsQuery(
    graphqlClient,
    { clinician_ids: clinician_ids },
    {
      staleTime: 1000 * 60 * 15,
      select: (data) => {
        const tagMap: IdMap<UseGetClinicianTagTags> = {};
        data.ehr_cliniciantag.forEach((tag) => {
          const clinicianId = tag.clinician_id;
          if (!(clinicianId in tagMap)) {
            tagMap[clinicianId] = [];
          }

          tagMap[clinicianId].push(tag);

        })
        return tagMap;
      }
    }
  );

  return { data, isLoading };
}


export const useGetClinicianTags = (clinician_id: number) => {
  const { data, isLoading } = useGetClinicianTagsQuery(
    graphqlClient,
    { clinician_ids: [clinician_id] },
    {
      staleTime: 1000 * 60 * 15,
      enabled: !!clinician_id,
    }
  );

  return { data, isLoading };
}
