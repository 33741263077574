import { Alert } from "antd";
import React from "react";
import { matchmakerUtils } from "../../../../../state/models/matchmaker";
import { useShallowEqualSelector } from "../../../../_helpers/redux";
import { Pad } from "../../../../_layout/display";
import DeliveryInput from "./DeliveryInput";
// import HybridPreferenceInput from "./HybridPreferenceInput";

export default () => {
  const [
    clinicianApprovesOt,
    { showOtFits, showInPersonFits },
  ] = useShallowEqualSelector(state => [
    state.matchmaker.draft.clinician_approves_ot,
    matchmakerUtils.matchOtSelector(state.matchmaker),
  ]);
  return (
    <Pad>
      <DeliveryInput />
      {/* <HybridPreferenceInput /> */}

      {clinicianApprovesOt !== undefined &&
        (showOtFits || showInPersonFits) && (
          <Alert
            message={`This client can be matched to ${
              showOtFits ? "ongoing teletherapy" : ""
            }${showOtFits && showInPersonFits ? " and " : ""}${
              showInPersonFits ? "in-person therapy" : ""
            }`}
            type="success"
            showIcon
          />
        )}
    </Pad>
  );
};
