import React from "react";
import { Fit, FitSuggestion, MatchTag, ServiceType } from "../../api/types";
import { Row } from "../../app/_layout/Flex";
import MatchRating from "../../app/_shared/MatchRating";
import ClinicianItem from "../../app/_shared/ClinicianItem";
import ExpandableRowItem from "../../app/_shared/ExpandableRowItem";
import TagListComparison from "../../app/_shared/TagListComparison";
import { Pad } from "../_layout/display";
import { Badge, Tooltip } from "antd";
import { useShallowEqualSelector } from "../_helpers/redux";
import { UseGetBulkClinicianTagsData, useGetBulkClinicianTags, useGetClinicianTags } from "../api/use-get-clinician-tags/use-get-clinician-tags";
import { GetClinicianTagsQuery } from "@/graphql/generated";

interface GeneralProps<T> {
  maxScore: number;
  flaggedFits?: (Fit | FitSuggestion)[];
  version: 1 | 2 | 3;
  additionalContent?: (fit: T) => React.ReactElement | null;
  comparisonTagMap: { [tagId: number]: MatchTag };
  fitAction?: (fit: T) => React.ReactElement;
  serviceType: ServiceType;
}

interface FitListProps<T> extends GeneralProps<T> {
  fits: T[];
}

const FitList = <T extends Fit | FitSuggestion>(props: FitListProps<T>) => {
  const clinicianIds = props.fits.map(f => typeof f.clinician === "number" ? f.clinician : f.clinician.id);
  const cliniciansTags = useGetBulkClinicianTags(clinicianIds).data;

  return (props.fits.length < 1 ? (
    <Pad style={{ textAlign: "center" }}>No fits found.</Pad>
  ) : (
    <>
      {props.fits
        .sort((a: T, b: T) => b.score - a.score)
        .map((fit: T, idx) => (
          <FitItem<T>
            key={idx}
            cliniciansTagsData={cliniciansTags}
            {...{
              ...props,
              fit,
            }}
          />
        ))}
    </>
  ))
}
;

interface FitItemProps<U> extends GeneralProps<U> {
  fit: U;
  cliniciansTagsData: UseGetBulkClinicianTagsData
}

const FitItem = <U extends Fit | FitSuggestion>(props: FitItemProps<U>) => {
  const clinicianId =
    typeof props.fit.clinician === "number"
      ? props.fit.clinician
      : props.fit.clinician.id;

  const clinicianTagsData = props.cliniciansTagsData?.[clinicianId] ?? [];

  const starredTags = Object.values(props.comparisonTagMap)
    .filter(matchTag => matchTag.starred)
    .map(matchTag => matchTag.id);
  const totalStars = starredTags.length;
  const clinicianStars = clinicianTagsData
      .filter(ct => ct.version === props.version)
      .map(ct => ct.tag_id)
      .filter(id => starredTags.includes(id)) ?? [];

  const flagFit = props.flaggedFits?.includes(props.fit);

  return (
    <ExpandableRowItem
      key={clinicianId}
      header={
        <Row layout={"start center"} style={{ padding: "0 5px" }}>
          <MatchRating
            normalizedScore={props.fit.score / props.maxScore}
            totalStars={totalStars}
            filledStars={clinicianStars.length}
          />
          <div style={{ flex: 1, padding: "10px  0 10px 10px" }}>
            <Tooltip
              title={
                flagFit && "Clinician is not in client's preferred age range."
              }
            >
              <Badge count={flagFit ? 1 : 0} dot={true} offset={[-1, -2]}>
                <ClinicianItem clinicianId={clinicianId} />
              </Badge>
            </Tooltip>
          </div>
          {props.fitAction && (
            <span style={{ paddingLeft: "35px" }}>
              {props.fitAction(props.fit)}
            </span>
          )}
        </Row>
      }
      content={
        <>
          {props.additionalContent ? props.additionalContent(props.fit) : null}
          <Row layout="space-between start">
            <TagListComparison
              comparisonTagMap={props.comparisonTagMap}
              clinicianId={clinicianId}
              clinicianTags={clinicianTagsData}
              version={props.version}
            />
          </Row>
        </>
      }
    />
  );
};

export default FitList;
