import useCareEpisodesFlag from "@/app/hooks/useCareEpisodesFlag";
import { mbcTrackStatusConfig } from "../../utils";
import { DateTime } from "luxon";

/**
 * When isCurrentEpisodeOfCareClinician is undefined or null then the clinician does not
 * have mbc episodes of care data, in which case we do not grey out the dot svg.
 * Dots corresponding to prior episodes of care should be filled with grey (#C4C4C4).
 * 
 * The baseline session is generally the first session of the most recent clinician.
 * However, there is an edge case where if a client had sessions with clinician A,
 * then had sessions with clinician B, but returned to clinician A within 60 days
 * of the last session of the first series of sessions with clinician A, then the
 * baseline session would be the first session of the first series of sessions (episode).
 */
export const MBCGraphDot = (props: any) => {
  const IsCareEpisodesUser = useCareEpisodesFlag();
  const { dataKey, payload, cx, cy, ...rest } = props;
  const baselineDate = DateTime.fromISO(payload.baselineSessionStartTime);
  const sessionDate = DateTime.fromISO(payload.start_time);
  const isPriorEpisodeOfCare = IsCareEpisodesUser && (payload.isCurrentEpisodeOfCareClinician === false || sessionDate < baselineDate);
  if (props.value === null) return null;
  return (
    <svg cx={cx} cy={cy} fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle
        cx={cx}
        cy={cy}
        {...rest}
        r={payload.status ? 10 : 6}
        fill={isPriorEpisodeOfCare ? "#C4C4C4" : mbcTrackStatusConfig(payload.status).color } stroke="#8F8F8F"
      />
      {payload.endorsed_si ? (
      <path transform={`translate(${cx - 10.5}, ${cy - 11})`} d="M10.7188 8.56534C10.6733 8.16193 10.4858 7.84943 10.1562 7.62784C9.8267 7.40341 9.41193 7.29119 8.91193 7.29119C8.55398 7.29119 8.24432 7.34801 7.98295 7.46165C7.72159 7.57244 7.51847 7.72585 7.37358 7.92188C7.23153 8.11506 7.16051 8.33523 7.16051 8.58239C7.16051 8.78977 7.20881 8.96875 7.3054 9.11932C7.40483 9.26989 7.53409 9.39631 7.69318 9.49858C7.85511 9.59801 8.02841 9.68182 8.21307 9.75C8.39773 9.81534 8.57528 9.86932 8.74574 9.91193L9.59801 10.1335C9.87642 10.2017 10.1619 10.294 10.4545 10.4105C10.7472 10.527 11.0185 10.6804 11.2685 10.8707C11.5185 11.0611 11.7202 11.2969 11.8736 11.5781C12.0298 11.8594 12.108 12.196 12.108 12.5881C12.108 13.0824 11.9801 13.5213 11.7244 13.9048C11.4716 14.2884 11.1037 14.5909 10.6207 14.8125C10.1406 15.0341 9.55966 15.1449 8.87784 15.1449C8.22443 15.1449 7.65909 15.0412 7.18182 14.8338C6.70455 14.6264 6.33097 14.3324 6.06108 13.9517C5.79119 13.5682 5.64205 13.1136 5.61364 12.5881H6.93466C6.96023 12.9034 7.0625 13.1662 7.24148 13.3764C7.4233 13.5838 7.65483 13.7386 7.93608 13.8409C8.22017 13.9403 8.53125 13.9901 8.86932 13.9901C9.24148 13.9901 9.57244 13.9318 9.86222 13.8153C10.1548 13.696 10.3849 13.5312 10.5526 13.321C10.7202 13.108 10.804 12.8594 10.804 12.5753C10.804 12.3168 10.7301 12.1051 10.5824 11.9403C10.4375 11.7756 10.2401 11.6392 9.99006 11.5312C9.7429 11.4233 9.46307 11.3281 9.15057 11.2457L8.11932 10.9645C7.42045 10.7741 6.86648 10.4943 6.45739 10.125C6.05114 9.75568 5.84801 9.26705 5.84801 8.65909C5.84801 8.15625 5.98438 7.71733 6.2571 7.34233C6.52983 6.96733 6.89915 6.67614 7.36506 6.46875C7.83097 6.25852 8.35653 6.15341 8.94176 6.15341C9.53267 6.15341 10.054 6.2571 10.5057 6.46449C10.9602 6.67188 11.3182 6.95739 11.5795 7.32102C11.8409 7.68182 11.9773 8.09659 11.9886 8.56534H10.7188ZM14.9982 6.27273V15H13.6815V6.27273H14.9982Z" fill={mbcTrackStatusConfig(payload.status)?.mbcDotTextColor || "black"}/>
      ) : null}

      {payload.status === "Reliable Change" ? (
        <text x={cx - 13} y={cy + 30} fill="#006ADC" fontWeight="bold">
          RCI
        </text>
      ) : null}
    </svg>
  );
};
