import { IconProps } from "../icon.interface";

export const GroupsEndorsedSIAlertIcon = ({
  width = 32,
  height = 32,
  viewBox = "0 0 32 32",
  x = 0,
  y = 0,
  title,
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x={x}
      y={y}
      aria-hidden={!title}
    >
      {title ? <title>{title}</title> : null}
      <circle cx="16" cy="16" r="16" fill="#F76808" />
      <path
        d="M15.6096 13.7102C15.5642 13.2519 15.3691 12.8958 15.0244 12.642C14.6797 12.3883 14.2119 12.2614 13.621 12.2614C13.2195 12.2614 12.8805 12.3182 12.6039 12.4318C12.3274 12.5417 12.1153 12.6951 11.9676 12.892C11.8236 13.089 11.7517 13.3125 11.7517 13.5625C11.7441 13.7708 11.7877 13.9527 11.8824 14.108C11.9808 14.2633 12.1153 14.3977 12.2858 14.5114C12.4562 14.6212 12.6532 14.7178 12.8767 14.8011C13.1002 14.8807 13.3388 14.9489 13.5926 15.0057L14.638 15.2557C15.1456 15.3693 15.6115 15.5208 16.0358 15.7102C16.46 15.8996 16.8274 16.1326 17.138 16.4091C17.4486 16.6856 17.6892 17.0114 17.8596 17.3864C18.0339 17.7614 18.1229 18.1913 18.1267 18.6761C18.1229 19.3883 17.9411 20.0057 17.5812 20.5284C17.2252 21.0473 16.71 21.4508 16.0358 21.7386C15.3653 22.0227 14.5566 22.1648 13.6096 22.1648C12.6702 22.1648 11.8521 22.0208 11.1551 21.733C10.4619 21.4451 9.92024 21.0189 9.53008 20.4545C9.14372 19.8864 8.94107 19.1837 8.92213 18.3466H11.3028C11.3293 18.7367 11.4411 19.0625 11.638 19.3239C11.8388 19.5814 12.1058 19.7765 12.4392 19.9091C12.7763 20.0379 13.157 20.1023 13.5812 20.1023C13.9979 20.1023 14.3596 20.0417 14.6664 19.9205C14.9771 19.7992 15.2176 19.6307 15.388 19.4148C15.5585 19.1989 15.6437 18.9508 15.6437 18.6705C15.6437 18.4091 15.5661 18.1894 15.4108 18.0114C15.2593 17.8333 15.0358 17.6818 14.7403 17.5568C14.4486 17.4318 14.0907 17.3182 13.6664 17.2159L12.3994 16.8977C11.4183 16.6591 10.6437 16.286 10.0755 15.7784C9.50736 15.2708 9.22516 14.5871 9.22895 13.7273C9.22516 13.0227 9.41266 12.4072 9.79145 11.8807C10.174 11.3542 10.6986 10.9432 11.3653 10.6477C12.032 10.3523 12.7896 10.2045 13.638 10.2045C14.5017 10.2045 15.2555 10.3523 15.8994 10.6477C16.5471 10.9432 17.0509 11.3542 17.4108 11.8807C17.7706 12.4072 17.9562 13.017 17.9676 13.7102H15.6096ZM22.2417 10.3636V22H19.7815V10.3636H22.2417Z"
        fill="white"
      />
    </svg>
  );
};
