import { graphqlClient } from "@/api/graphql";
import { FIVE_MINUTES } from "@/app/_helpers/constants";
import {
  GetClientGroupsAttendanceQuery,
  useGetClientGroupsAttendanceQuery
} from "@/graphql/generated";

interface UseGetClientGroupsAttendanceParams<T> {
  clientId: number;
  select: (data: GetClientGroupsAttendanceQuery) => T;
  enabled?: boolean;
}

// used to determine if a client is already enrolled in a group or not
export const useGetClientGroupsAttendance = <T extends {}>({
  clientId,
  select,
  enabled,
}: UseGetClientGroupsAttendanceParams<T>) => {
  return useGetClientGroupsAttendanceQuery(
    graphqlClient,
    { client_id: clientId },
    {
      staleTime: FIVE_MINUTES,
      queryKey: [clientId],
      select,
      enabled,
    },
  );
};
