import { DateTime } from "luxon";
import { useParams } from "react-router-dom";
import { useMyClientsOverview } from "../../../api/use-my-clients-overview/use-my-clients-overview.api";
import { selectDataForMatchingInfoByClient } from "../../api/use-matching-data-by-clients/select-data-for-matching-data-by-client.util";
import { useMatchingInfoByClientIds } from "../../api/use-matching-data-by-clients/use-matching-data-by-clients";
import { selectDataForMyClientsSchedule } from "../../api/utils/select-data-for-my-clients.util";
import { useMyClientsUser } from "../my-clients-page/hooks/use-my-clients-user";
import { useMyClientsSearchStore } from "../my-clients-page/my-clients-model/my-clients.model";
import { selectDataFromSearchForClientProfile } from "./api/use-client-overview/select-data-for-client-profile.util";
import { useClientOverview } from "./api/use-client-overview/use-client-overview";

export const useClientProfilePage = () => {
  const params = useParams<{ clientId: string }>();
  const clientId = Number(params.clientId);

  const { clinicianId } = useMyClientsUser();
  const { currentlySelectedClinician } = useMyClientsSearchStore();
  const { data: myClientsOverview } = useMyClientsOverview({
    clinicianId,
    select: selectDataForMyClientsSchedule,
    enabled: !!clinicianId,
  });

  const client = myClientsOverview?.[clientId] ?? null;

  const { data: clientOverview } = useClientOverview({
    clientIds: [clientId],
    select: (data) => selectDataFromSearchForClientProfile(data, clinicianId),
    enabled: !client,
  });

  const fromStartTime = DateTime.now().minus({ weeks: 4 }).toISO();

  const { data: clientsMatchingData } = useMatchingInfoByClientIds({
    clientIds: [clientId],
    fromStartTime,

    enabled: !!client || !!clientOverview,
    select: (data) =>
      selectDataForMatchingInfoByClient(
        data,
        currentlySelectedClinician ? currentlySelectedClinician : clinicianId,
      ),
  });

  const clientMatchingData = clientsMatchingData
    ? clientsMatchingData[clientId]
    : null;

  return {
    clinicianId,
    client: client ? client : clientOverview,
    clientMatchingData,
  };
};
