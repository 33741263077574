import { IconProps } from "../icon.interface";

export const EndorsedSIAlertIcon = ({
  width = 24,
  height = 24,
  viewBox = "0 0 24 24",
  x = 0,
  y = 0,
  title,
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      x={x}
      y={y}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden={!title}
    >
      {title ? <title>{title}</title> : null}
      <circle cx="12" cy="12" r="11.5" fill="#F76808" stroke="#F76808" />
      <path
        d="M12.1953 10.0291C12.1461 9.59209 11.9429 9.25355 11.5859 9.01349C11.2289 8.77036 10.7796 8.64879 10.2379 8.64879C9.85014 8.64879 9.51468 8.71035 9.23153 8.83345C8.94839 8.95348 8.72834 9.11967 8.57138 9.33203C8.4175 9.54131 8.34055 9.77983 8.34055 10.0476C8.34055 10.2723 8.39287 10.4661 8.49751 10.6293C8.60523 10.7924 8.74527 10.9293 8.91761 11.0401C9.09304 11.1478 9.28078 11.2386 9.48082 11.3125C9.68087 11.3833 9.87322 11.4418 10.0579 11.4879L10.9812 11.728C11.2828 11.8018 11.5921 11.9019 11.9091 12.0281C12.2261 12.1542 12.52 12.3204 12.7908 12.5266C13.0617 12.7328 13.2802 12.9883 13.4464 13.293C13.6156 13.5977 13.7003 13.9624 13.7003 14.3871C13.7003 14.9226 13.5618 15.3981 13.2848 15.8136C13.0109 16.229 12.6123 16.5568 12.0891 16.7969C11.569 17.0369 10.9396 17.157 10.201 17.157C9.49313 17.157 8.88068 17.0446 8.36364 16.82C7.84659 16.5953 7.44188 16.2768 7.1495 15.8643C6.85713 15.4489 6.69555 14.9564 6.66477 14.3871H8.09588C8.12358 14.7287 8.23438 15.0134 8.42827 15.2411C8.62524 15.4658 8.87607 15.6335 9.18075 15.7443C9.48852 15.852 9.82552 15.9059 10.1918 15.9059C10.5949 15.9059 10.9535 15.8428 11.2674 15.7166C11.5844 15.5874 11.8337 15.4089 12.0153 15.1811C12.1969 14.9503 12.2876 14.681 12.2876 14.3732C12.2876 14.0932 12.2076 13.8639 12.0476 13.6854C11.8906 13.5069 11.6767 13.3591 11.4059 13.2422C11.1381 13.1252 10.835 13.0221 10.4964 12.9329L9.37926 12.6282C8.62216 12.422 8.02202 12.1188 7.57884 11.7188C7.13873 11.3187 6.91868 10.7893 6.91868 10.1307C6.91868 9.58594 7.06641 9.11044 7.36186 8.70419C7.65732 8.29794 8.05741 7.98248 8.56215 7.75781C9.06688 7.53007 9.63625 7.41619 10.2702 7.41619C10.9104 7.41619 11.4751 7.52853 11.9645 7.7532C12.4569 7.97786 12.8447 8.28717 13.1278 8.68111C13.411 9.07197 13.5587 9.52131 13.571 10.0291H12.1953ZM16.8314 7.54545V17H15.4049V7.54545H16.8314Z"
        fill="white"
      />
    </svg>
  );
};
