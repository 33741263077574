import { styledStitches } from "@/app/design-system/styles/stitches.config";

export const Table = styledStitches("table", {
  borderCollapse: "collapse",
  // border: '0.5px solid rgba(0, 0, 0, 0.3)',
});

export const Thead = styledStitches("thead", {
  fontSize: 14,
  color: "hsla(0, 0%, 44%, 1)",
  fontStyle: "normal",
  fontWeight: 400,
});

export const Tr = styledStitches("tr", {
  background: "white",
  borderBottom: "0.5px solid rgba(0, 0, 0, 0.3)",
});

export const Tbody = styledStitches("tbody");

export const Td = styledStitches("td", {
  span: {
    marginRight: 10,
  },
});

export const Th = styledStitches("th", {
  textAlign: "start",
  fontWeight: "normal",
});
