import { ExclamationIcon } from "@/app/design-system/icons/components/exclamation-icon";
import React from "react";

interface HelperTextProps {
  primaryText: string;
  secondaryText: string;
  color: string;
  background: string;
  style?: React.CSSProperties;

}

export const HelperTextBox = ({primaryText, secondaryText, background, color, style}:HelperTextProps) => {
  return (
    <div style={{background: `${background}`, padding: '16px', borderRadius: "8px", display:"inline-flex", ...style}}>
     <ExclamationIcon fill={color}/>
     <span style={{color: `${color}`, marginLeft: "5px"}}><b>{primaryText}:{ }</b> {secondaryText}</span>
    </div>
  );
};
