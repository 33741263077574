import { useGetClinicianTags } from "@/app/api/use-get-clinician-tags/use-get-clinician-tags";
import { ContactsOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useState } from "react";
import { BasicMatch, ExtendedMatch } from "../../../../api/types";
import { $kpBlue } from "../../../../assets/colors";
import { copyToClipboard, toMap } from "../../../_helpers";
import {
  IfPermitted,
  useUserHasAnyPermissions,
} from "../../../_helpers/permissions";
import { Row } from "../../../_layout/Flex";
import { Pad, Title } from "../../../_layout/display";
import ClinicianItem from "../../../_shared/ClinicianItem";
import Mangle from "../../../_shared/Mangle";
import MatchRating from "../../../_shared/MatchRating";
import TagListComparison from "../../../_shared/TagListComparison";
import MatchLocationPreferences from "../MatchLocationPreferences";
import MatchNote from "../MatchNote";
import QueueNote from "../QueueNote";
import Footer from "./Footer";

import { userCanAccessHealthie } from "@/app/_helpers/permissions";
import { CurrentConfigContext, CurrentUserContext } from "@/app/app.utils";
import { Flex, styledStitches, Notification } from "@/app/design-system";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/app/design-system/popover/popover.styled";
import MatchAssignment from "../MatchAssignment";

export default function ResolvedMatchItem({
  extendedMatch,
}: {
  extendedMatch: ExtendedMatch;
}) {
  const status = extendedMatch.client_status ?? extendedMatch.couple_status;
  const clientOrCouple = extendedMatch.couple ?? extendedMatch.client;
  const clientOrCoupleClientA =
    extendedMatch.couple?.clientA ?? extendedMatch.client;
  const payers = extendedMatch.client.payers;
  const healthie_id = extendedMatch.client?.healthie_id;

  const actions = [];
  if (
    [
      "Pending Match",
      "Prospective Match",
      "Scheduled",
      "Did Not Convert",
    ].includes(status || "")
  )
    actions.push("requeue");
  if (status === "Active") actions.push("rematch");

  const isMatchingAdmin = useUserHasAnyPermissions([
    "IsMatchingAdmin",
    "IsSuperUser",
    "IsCareCoordinator",
  ]);

  const isSuperUser = useUserHasAnyPermissions(["IsSuperUser"]);

  const clinicianTags = useGetClinicianTags(
    extendedMatch.selected_fit?.clinician.id!,
  );
  const [popoverVisible, setPopoverVisible] = useState(false);

  return (
    <>
      <QueueNote queueNote={extendedMatch.queue_note} />
      <Pad>
        <Row layout="start center" style={{ marginBottom: "10px" }}>
          <div style={{ flex: 1 }} />
          {healthie_id && (
            <IfPermitted permissions={["IsSuperUser"]} requireAll={true}>
              <Button
                href={"https://twochairs.gethealthie.com/users/" + healthie_id}
                icon={<ContactsOutlined />}
                size="small"
                style={{ marginRight: "5px" }}
                target="_blank"
              >
                Healthie Profile
              </Button>
            </IfPermitted>
          )}
          <Button
            size="small"
            style={{ marginRight: "5px" }}
            onClick={() => copyToClipboard(clientOrCoupleClientA.email)}
          >
            Copy Client Email
          </Button>
          <Popover open={popoverVisible} onOpenChange={setPopoverVisible}>
            <PopoverTrigger asChild>
              <Button
                {...{
                  type: "primary",
                  size: "small",
                }}
              >
                See Client Information
              </Button>
            </PopoverTrigger>
            <PopoverContent
              sideOffset={10}
              align="start"
              alignOffset={-150}
              side="bottom"
            >
              <ClientPopoverHead>
                <Mangle>
                  {`${clientOrCoupleClientA.first_name} ${clientOrCoupleClientA.last_name}`}
                </Mangle>
              </ClientPopoverHead>

              <ClientPopoverBody>
                {isSuperUser && (
                  <>
                    <div>
                      <Mangle>
                        <strong>
                          {extendedMatch.couple ? "Couple" : "Client"} ID:
                        </strong>{" "}
                        {extendedMatch?.couple?.chroniclerId ??
                          extendedMatch.client.id}
                      </Mangle>
                    </div>
                    <div>
                      <Mangle>
                        <strong>Match ID:</strong> {extendedMatch.id}
                      </Mangle>
                    </div>
                  </>
                )}
                <>
                  <div>
                    <Mangle>
                      <strong>Phone:</strong>{" "}
                      {clientOrCoupleClientA.phone_number}
                    </Mangle>
                  </div>
                  <div>
                    <Mangle>
                      <strong>Email:</strong> {clientOrCoupleClientA.email}
                    </Mangle>
                  </div>
                </>
              </ClientPopoverBody>
            </PopoverContent>
          </Popover>
        </Row>
        {payers.map((p) => (
          <Title size="xs" key={p.id} style={{ margin: 0, color: $kpBlue }}>
            {p.payer.display_name} Client
          </Title>
        ))}
        {status === "Active" && (
          <Title size="xs">
            Appointments Attended: {extendedMatch.client_appts_attended}
          </Title>
        )}
        <Row layout="start center" style={{ marginBottom: "10px" }}>
          <Title size="xs" margin="0px">
            Consult Clinician:&nbsp;
          </Title>
          <ClinicianItem
            clinicianId={
              extendedMatch.consult && typeof extendedMatch.consult === "object"
                ? typeof extendedMatch.consult.clinician === "number"
                  ? extendedMatch.consult.clinician
                  : extendedMatch.consult.clinician.id
                : extendedMatch.created_by_clinician || 0
            }
            hideClinic={true}
          />
        </Row>
        {isMatchingAdmin && (
          <Row layout="start center" style={{ marginBottom: "10px" }}>
            <Title size="xs" margin="0px">
              Assignee:&nbsp;
            </Title>
            <Flex>
              <MatchAssignment
                basicMatch={extendedMatch as unknown as BasicMatch}
                readOnly
              />
            </Flex>
          </Row>
        )}
        {extendedMatch.selected_fit && extendedMatch.selected_fit.clinician && (
          <Row layout="start center" style={{ marginBottom: "10px" }}>
            <Title size="xs" margin="0px">
              Selected Clinician:&nbsp;
            </Title>
            <ClinicianItem
              clinicianId={extendedMatch.selected_fit.clinician.id}
              hideClinic={true}
            />
            <MatchRating
              style={{ paddingLeft: "10px" }}
              normalizedScore={
                extendedMatch.selected_fit.score / extendedMatch.max_score
              }
            />
          </Row>
        )}
        {extendedMatch.selected_fit && (
          <TagListComparison
            comparisonTagMap={toMap(extendedMatch.tags)}
            clinicianId={extendedMatch.selected_fit.clinician.id}
            clinicianTags={clinicianTags.data?.ehr_cliniciantag ?? []}
            version={extendedMatch.methodology_version}
          />
        )}
        <MatchLocationPreferences match={extendedMatch} />
      </Pad>
      <MatchNote matchNote={extendedMatch.match_note} />
      <Footer extendedMatch={extendedMatch} actions={actions} />
    </>
  );
}

const ClientPopoverBody = styledStitches("div", { padding: "12px" });
const ClientPopoverHead = styledStitches("div", ClientPopoverBody, {
  borderBottom: "1px solid $neutral4",
});
