import { Flex, ScrollArea, Stack } from "@/app/design-system";
import { useState } from "react";

import { useShallowEqualSelector } from "../../../_helpers/redux";
import { ComponentsWithoutRoutes } from "./components/components-without-routes";
import {
  ModalNavMenuRoot,
  NavMenuList,
  NavMenuRoot,
  NavMenuSection,
  NavigationMenuContainer,
} from "./components/components.utility/main-nav.styled";
import { TwoChairsHeaderLogo } from "./components/components.utility/two-chairs-header-logo";

import { TopMainNavigationMenuItems } from "./components/1-top-main-navigation-menu-items/top-main-navigation-menu-items";
import { BottomMainNavigationMenuItems } from "./components/2-bottom-main-navigation-menu-items/bottom-main-navigation-menu-items";
import { CollapseSidebarButton } from "../../../_shared/collapse-sidebar-button";
import { useCollapseSidebar } from "./hooks/use-collapse-sidebar";
import { CollapseIcon } from "@/app/design-system/icons/components/collapse-icon";
import { NavigationMenuItem } from "./components/components.utility/navigation-menu-item";

interface MainNavigationMenuV2Props {
  setIsCollapsed: (v: boolean) => void;
  isCollapsed: boolean;
}

function MainNavigationMenuV2({
  setIsCollapsed,
  isCollapsed,
}: MainNavigationMenuV2Props) {
  const { isCollapsible } = useCollapseSidebar(setIsCollapsed);

  const [currentUser] = useShallowEqualSelector((state) => [
    state.auth.currentUser,
  ]);

  return currentUser ? (
    <Stack
      css={{
        height: "100vh",
        position: "fixed",
        z: 1,
      }}
      as="nav"
    >
      <ScrollArea.Root>
        <ScrollArea.Viewport>
          <NavigationMenuContainer>
            <NavMenuSection
              css={{ height: "100vh", width: isCollapsed ? 64 : 220 }}
            >
              <TwoChairsHeaderLogo isCollapsed={isCollapsed} />
              <Stack
                justifyContent={"space-between"}
                gap={8}
                css={{ flexGrow: 1, px: 8, pb: 8 }}
              >
                <NavMenuRoot orientation="vertical">
                  <NavMenuList title="Navigation">
                    <TopMainNavigationMenuItems isCollapsed={isCollapsed} />
                  </NavMenuList>
                </NavMenuRoot>

                <ModalNavMenuRoot
                  orientation="vertical"
                  aria-label="Modal buttons"
                >
                  <NavMenuList title="Modal">
                    <BottomMainNavigationMenuItems isCollapsed={isCollapsed} />
                  </NavMenuList>
                </ModalNavMenuRoot>
              </Stack>

              <CollapseSidebarButton
                role="switch"
                aria-label="Collapse Menu"
                aria-checked={isCollapsed}
                css={{
                  width: isCollapsed ? 69 : 220,
                  transform: isCollapsed ? "rotate(180deg)" : "rotate(0deg)",
                }}
                onClick={() =>
                  isCollapsible
                    ? setIsCollapsed(isCollapsed ? false : true)
                    : null
                }
              >
                {isCollapsible ? <CollapseIcon /> : null}
              </CollapseSidebarButton>
            </NavMenuSection>
          </NavigationMenuContainer>
        </ScrollArea.Viewport>
        <ScrollArea.Scrollbar orientation="vertical">
          <ScrollArea.Thumb />
        </ScrollArea.Scrollbar>
      </ScrollArea.Root>
      <ComponentsWithoutRoutes />
    </Stack>
  ) : null;
}

const MainNavigationMenu = MainNavigationMenuV2;

export { MainNavigationMenu };
