import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useShallowEqualSelector } from "../../../_helpers/redux";
import { Tabs, Spin, Divider } from "antd";
import { Row } from "../../../_layout/Flex";
import { Pad, Title } from "../../../_layout/display";
import MatchQueueItem from "./MatchQueueItem";
import ResolvedMatchItem from "./ResolvedMatchItem";
import ReferredMatchItem from "./ReferredMatchItem";
import MatchStatus from "../../MatchStatus";
import { BasicMatch, ExtendedMatch } from "../../../../api/types";
import { matchOperations } from "../../../../state/models/matches";

export default function MyMatchesTableItem({
  basicMatch,
}: {
  basicMatch: BasicMatch;
}) {
  const matchId = basicMatch.id;
  const [npsForm] = useShallowEqualSelector((state) => [
    state.matches.consultNPSFormMap[matchId],
  ]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (basicMatch.status === "queued") {
      dispatch(matchOperations.getRelatedModelsForMatches([basicMatch.id]));
    } else {
      dispatch(matchOperations.getExtendedMatch(matchId));
    }
  }, [basicMatch, dispatch, matchId]);

  const MatchItemComponent =
    basicMatch.status === "queued" ? MatchQueueItem : MatchInfo;

  if (!npsForm) {
    return <MatchItemComponent matchId={matchId} />;
  }

  const { form } = npsForm;
  return (
    <Tabs defaultActiveKey="1">
      <Tabs.TabPane tab="Match Information" key="1">
        <MatchItemComponent matchId={matchId} />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Consult Feedback" key="2">
        <div style={{ paddingLeft: "15px" }}>
          {form.blob.map((question) => {
            if (question.answer_type === "none") {
              return null;
            }
            return (
              <div
                style={{ marginBottom: "10px" }}
                key={question.vendor_question_id}
              >
                <strong>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: question.question_text,
                    }}
                  />
                </strong>
                <div>{question.answer_text}</div>
              </div>
            );
          })}
        </div>
      </Tabs.TabPane>
    </Tabs>
  );
}

const MatchInfo = ({ matchId }: { matchId: number }) => {
  const [extendedMatch] = useShallowEqualSelector((state) => [
    state.matches.extendedMatchMap[matchId],
  ]);

  if (!extendedMatch) {
    return (
      <Row layout="center center" style={{ padding: "15px" }}>
        <Spin />
      </Row>
    );
  }

  switch (extendedMatch.client_status ?? extendedMatch.couple_status) {
    case "Active":
    case "Did Not Convert":
    case "Pending Match":
    case "Prospective Match":
    case "Scheduled":
      return <ResolvedMatchItem extendedMatch={extendedMatch} />;
    case "Referred Out":
      return <ReferredMatchItem extendedMatch={extendedMatch} />;
    case "Rematched":
      if (extendedMatch.rematch) {
        return (
          <>
            <RematchHeader rematch={extendedMatch.rematch} />
            <Divider style={{ margin: 0 }} />
            <ResolvedMatchItem extendedMatch={extendedMatch} />
          </>
        );
      }
      return <Pad>Error getting match information</Pad>;
    default:
      return <Pad>Error getting match information</Pad>;
  }
};

export const MatchInfo2 = ({ matchId }: { matchId: number }) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(matchOperations.getExtendedMatch(matchId));
  }, [matchId]);
  const [extendedMatch] = useShallowEqualSelector((state) => [
    state.matches.extendedMatchMap[matchId],
  ]);

  if (!extendedMatch) {
    return (
      <Row layout="center center" style={{ padding: "15px" }}>
        <Spin />
      </Row>
    );
  }

  switch (extendedMatch.client_status ?? extendedMatch.couple_status) {
    case "Active":
    case "Did Not Convert":
    case "Pending Match":
    case "Prospective Match":
    case "Scheduled":
      return <ResolvedMatchItem extendedMatch={extendedMatch} />;
    case "Referred Out":
      return <ReferredMatchItem extendedMatch={extendedMatch} />;
    case "Rematched":
      if (extendedMatch.rematch) {
        return (
          <>
            <RematchHeader rematch={extendedMatch.rematch} />
            <Divider style={{ margin: 0 }} />
            <ResolvedMatchItem extendedMatch={extendedMatch} />
          </>
        );
      }
      return <Pad>Error getting match information</Pad>;
    default:
      return <Pad>Error getting match information</Pad>;
  }
};

const RematchHeader = ({ rematch }: { rematch: ExtendedMatch }) => (
  <Pad>
    <Row layout="space-between center">
      <Title size="medium" margin="0px">
        This Client Has Been Rematched
      </Title>
      <Row layout="start center">
        New Match Status:&nbsp;&nbsp;&nbsp;&nbsp;
        {MatchStatus(rematch)}
      </Row>
    </Row>
  </Pad>
);
