import { graphqlClient } from "@/api/graphql";
import { FIVE_MINUTES } from "@/app/_helpers/constants";
import {
  useGroupTherapyClientNavInfoQuery,
  GroupTherapyClientNavInfoQuery,
} from "@/graphql/generated";

interface UseGroupTherapyNavInfoParams<T> {
  groupId: string | undefined;
  clientStringId: string | undefined;
  select: (data: GroupTherapyClientNavInfoQuery) => T;
  enabled?: boolean;
}

export const useGroupTherapyClientNavInfo = <T extends {}>({
  groupId,
  clientStringId,
  select,
  enabled,
}: UseGroupTherapyNavInfoParams<T>) => {
  const clientId = clientStringId ? parseInt(clientStringId) : undefined;
  return useGroupTherapyClientNavInfoQuery(
    graphqlClient,
    { groupId, clientId },
    {
      staleTime: FIVE_MINUTES,
      select,
      enabled,
    },
  );
};
