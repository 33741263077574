import {
  selectAppointmentStatusFiltersSlice,
  useMyClientsScheduleStore,
} from "../../my-clients-schedule-model";
import {
  getFilterOnSessionAppointmentStatus,
  getFilterOnSessionTime,
} from "./get-my-clients-schedule-filter.util";

import { EventApiData } from "../../../../../../../../api/use-my-clients-overview/my-clients-data.interface";

export const useFilterSessions = (
  sessionHistorySessions: EventApiData[] | undefined,
) => {
  if (!sessionHistorySessions) {
    return {
      sessionsFilteredByTimeRangeAndAppointmentStatus: [],
      sessionsFilteredByTimeRange: [],
    };
  }
  const appointmentStatusFilterState = useMyClientsScheduleStore(
    selectAppointmentStatusFiltersSlice,
  );

  const timeRangeToFilterOn = useMyClientsScheduleStore(
    (state) => state.timeRangeToFilterOn,
  );

  const sessionsFilteredByTimeRangeAndAppointmentStatus =
    sessionHistorySessions.filter((sessionHistorySession) => {
      return (
        getFilterOnSessionAppointmentStatus(
          sessionHistorySession,
          appointmentStatusFilterState,
        ) &&
        getFilterOnSessionTime(sessionHistorySession, timeRangeToFilterOn) &&
        sessionHistorySession.appointmentStatus !== null
      );
    });

  const sessionsFilteredByTimeRange = sessionHistorySessions.filter(
    (sessionHistorySession) => {
      return getFilterOnSessionTime(sessionHistorySession, timeRangeToFilterOn);
    },
  );

  return {
    sessionsFilteredByTimeRangeAndAppointmentStatus,
    sessionsFilteredByTimeRange,
  };
};
