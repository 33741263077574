import { Flex, Notification, styledStitches } from "@/app/design-system";
import { LeftCaretIcon } from "@/app/design-system/icons";
import { MainContentHeader } from "@/app/_shared/MainContentHeader";
import useTitle from "@/app/hooks/useTitle";
import { Link, useParams } from "react-router-dom";
import {
  getGroupFromGroupTherapyPage,
  useGroupTherapyPage,
} from "../_api/use-group-therapy-page";
import { getSessionToLinkTo } from "../_components/group-panes-container/group-pane";
import { GroupTherapyContainer } from "../_components/groups.styled";
import { useGroupTherapyRedirect } from "../_hooks/use-group-therapy-redirect";
import { useGroupTherapyUser } from "../_hooks/use-group-therapy-user";
import { GroupClientDetailsNavigation } from "./_components/group-client-details-nav/group-client-details-nav";
import { GroupDetailsNavigation } from "./_components/group-details-nav/group-details-nav";

const BackLink = styledStitches(Link, {
  color: "$neutral11",
  fontSize: 16,
  display: "flex",
  gap: 8,
  alignItems: "center",
});

export const GroupTherapyDetailsPage = () => {
  useTitle("Group Therapy Details", false);
  const { clinicianId } = useGroupTherapyUser();
  const clinicianIdParam = clinicianId ? `clinician_id=${clinicianId}` : "";
  useGroupTherapyRedirect();

  const { clientId, groupId } =
    useParams<{ clientId: string; groupId: string }>();

  const { groupTherapyOverviewData, isLoading } = useGroupTherapyPage();
  const groupOverviewData = getGroupFromGroupTherapyPage(
    groupTherapyOverviewData,
    groupId,
  );

  const sessionToLinkTo = getSessionToLinkTo(
    groupOverviewData?.prevSession,
    groupOverviewData?.nextSession,
  );

  return !clientId ? (
    <GroupTherapyContainer flexDirection={"column"}>
      <MainContentHeader>
        <Flex gap={8} alignItems={"center"}>
          {/* if we navigate directly to this page without clinician id, we need to derive clinician id
          from the group if we are to navigate back to the group home page and expect it to be all the clinician's groups
          */}
          <BackLink to={`/groups?${clinicianIdParam}`}>
            <LeftCaretIcon />
            Back to Groups
          </BackLink>
        </Flex>
      </MainContentHeader>
      <GroupDetailsNavigation />
      <Notification />
    </GroupTherapyContainer>
  ) : (
    <>
      <GroupTherapyContainer flexDirection={"column"}>
        <MainContentHeader>
          <Flex gap={8} alignItems={"center"}>
            <BackLink
              to={`/groups/${groupOverviewData?.groupId}/sessions/${sessionToLinkTo?.eventId}/notes?${clinicianIdParam}`}
            >
              <LeftCaretIcon />
              Back to {groupOverviewData?.groupTitle}
            </BackLink>
          </Flex>
        </MainContentHeader>

        <GroupClientDetailsNavigation />
      </GroupTherapyContainer>
    </>
  );
};
