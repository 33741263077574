export const clientStatusFilterTagStateToTagNameMap = {
  isFilteringProspectiveClients: "Prospective",
  isFilteringOnPendingClients: "Pending",
  isFilteringOnNewClients: "New",
  isFilteringOnActiveClients: "Active",
  isFilteringOnInactiveClients: "Inactive",
  isFilteringOnGraduatedClients: "Graduated",
};

export const mbcStatusFilterTagStateToTagNameMap = {
  isFilteringOnMBCGraduationClients: "Graduate",
  isFilteringOnMBCStagnationClients: "Stagnation",
  isFilteringOnMBCEscalateClients: "Escalate",
  isFilteringOnMBCImprovementClients: "Improvement",
};

export const cadenceFilterTagStateToTagNameMap = {
  isFilteringOnBiweekly: "Biweekly",
  isFilteringOnWeekly: "Weekly",
  isFilteringOnNoCandence: "No cadence",
};

export const treatmentTrackTagStateToTagNameMap = {
  isFilteringOnGAD7Anxiety: "GAD7 - Anxiety",
  isFilteringOnPHQ9Depression: "PHQ9 - Depression",
  isFilteringOnNoTrack: "No track",
};

export const totalSessionsTagStateToTagNameMap = {
  isFilteringOn0to9Sessions: "0 to 9 sessions",
  isFilteringOn10to16Sessions: "10 to 16 sessions",
  isFilteringOn17to25Sessions: "17 to 24 sessions",
  isFilteringOn25OrMoreSessions: "25+ sessions",
};

export const baselineSeverityTagStateToTagNameMap = {
  isFilteringOnSubclinical: "Subclinical",
  isFilteringOnMildToModerate: "Mild - moderate",
  isFilteringOnModerateToSevere: "Moderate - severe",
};

export const payerTagStateToTagNameMap = {
  isFilteringOnKPNorcal: "Kaiser Permanente Norcal",
  isFilteringOnKPWA: "Kaiser Permanente Washington",
  isFilteringOnDTC: "DTC",
  isFilteringOnAetna: "Aetna",
};

export const mbcMetricTagStateToTagNameMap = {
  isFilteringOnSubclinicalClientsExceeding10Sessions:
    "Subclinical exceeding 10 sessions",
  isFilteringOnMildToModerateClientsExceeding17Sessions:
    "Mild to moderate exceeding 17 sessions",
  isFilteringOnModerateToSevereClientsExceeding25Sessions:
    "Moderate to severe exceeding 25 sessions",
};

export const filterTagStateToTagNameMap = {
  ...clientStatusFilterTagStateToTagNameMap,
  ...mbcStatusFilterTagStateToTagNameMap,
  ...cadenceFilterTagStateToTagNameMap,
  ...totalSessionsTagStateToTagNameMap,
  ...treatmentTrackTagStateToTagNameMap,
  ...baselineSeverityTagStateToTagNameMap,
  ...payerTagStateToTagNameMap,
  ...mbcMetricTagStateToTagNameMap,
};

export const filterTagNameToTagStateMap = Object.fromEntries(
  Object.entries(filterTagStateToTagNameMap).map((a) => a.reverse()),
);
