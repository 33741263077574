import { graphqlClient } from "../../../../../../api/graphql";
import {
  ClientOverviewQuery,
  useClientOverviewQuery,
} from "../../../../../../graphql/generated";
import { FIVE_MINUTES } from "../../../../../_helpers/constants";

interface UseClientOverviewParams<T extends {}> {
  clientIds: number[];
  select?: (data: ClientOverviewQuery) => T | null;
  enabled: boolean;
}

export const useClientOverview = <T extends {}>({
  clientIds,
  select,
  enabled,
}: UseClientOverviewParams<T>) => {
  return useClientOverviewQuery(
    graphqlClient,
    { client_ids: clientIds },
    {
      staleTime: FIVE_MINUTES,
      select,
      enabled,
    },
  );
};
