import { Flex, Stack, Text } from "@/app/design-system";
import { useShallowEqualSelector } from "@/app/_helpers/redux";
import { useMyClientsUser } from "../../hooks/use-my-clients-user";
import { useMyClientsSearchStore } from "../../my-clients-model/my-clients.model";
import { FilterTagContainer } from "../filter-tags/filter-tags";
import { ClientPaneSortDropdown } from "./client-pane-sort-dropdown/client-pane-sort-dropdown";

interface ClientPaneHeaderProps {
  totalClients: number;
}

export const ClientPaneHeader = ({ totalClients }: ClientPaneHeaderProps) => {
  const [clinicianMap] = useShallowEqualSelector((state) => [
    state.clinicians.clinicianMap,
  ]);

  const { currentlySelectedClinician } = useMyClientsSearchStore();

  const { clinicianId, isClinicalLeaderOrSuperuser } = useMyClientsUser();

  const currentClinicianId = currentlySelectedClinician || clinicianId;

  const initialClinicianName = clinicianMap[currentClinicianId]
    ? `${clinicianMap[currentClinicianId]?.first_name} ${clinicianMap[currentClinicianId]?.last_name}`
    : "";

  const { clientIds } = useMyClientsSearchStore();

  const isViewingSearchResults = clientIds.length !== 0;

  return (
    <Stack gap={8}>
      <Flex
        justifyContent={"space-between"}
        css={{
          px: 8,
        }}
      >
        <Flex
          gap={4}
          css={{
            "@breakpoint1200": {
              flexDirection: "column",
            },
          }}
        >
          {initialClinicianName && isClinicalLeaderOrSuperuser ? (
            <Text fontWeight={"600"}>
              {isViewingSearchResults
                ? "Search results: "
                : `${initialClinicianName}'s panel: `}
            </Text>
          ) : null}
          <Text>
            {totalClients} Client{totalClients === 1 ? "" : "s"}
          </Text>
        </Flex>

        <ClientPaneSortDropdown />
      </Flex>
      <FilterTagContainer />
    </Stack>
  );
};
