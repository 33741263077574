import api from "@/api";
import { useMutation } from "react-query";
import { CancellationType } from "../../routes/client-profile-page/client-profile-nav/routes/schedule-page/components/session-actions/cancel-session-action/cancel-session-form/cancel-session-form.constants";
import { CancelSessionFormFields } from "../../routes/client-profile-page/client-profile-nav/routes/schedule-page/components/session-actions/cancel-session-action/cancel-session-form/use-cancel-session-form";
import { cancellationReasonToBackendValueMap } from "./upsert-session.constants";

interface CancelEventArgs {
  id: string;
  data: CancelSessionFormFields;
  isCancellingCallFutureSessions: boolean;
}

export const cancellationTypeMap = {
  [CancellationType.CANCELED_BY_CLIENT]: "by_client",
  [CancellationType.CANCELED_BY_CLINICIAN]: "by_clinician",
  [CancellationType.LATE_CANCEL_BY_CLIENT]: "late_by_client",
};

export function cancelEvent({
  id,
  data,
  isCancellingCallFutureSessions,
}: CancelEventArgs) {
  try {
    const { attendanceNote, cancellationReason, cancellationType } = data;

    const cancelEventBody = {
      appointment_status:
        cancellationType === CancellationType.NO_SHOW ? "noshow" : "canceled",
      ...(cancellationReasonToBackendValueMap[cancellationReason] && {
        cancellation_reason:
          cancellationReasonToBackendValueMap[cancellationReason],
      }),
      ...(cancellationTypeMap[cancellationType] && {
        cancellation_type: cancellationTypeMap[cancellationType],
      }),

      ...(attendanceNote && {
        attendance_note: attendanceNote,
      }),
    };

    const cancelEventQueryString = `?end_recurrence=${isCancellingCallFutureSessions}`;

    console.log(
      `[cancelEvent] PATCH eventId: ${id}\n[cancelEvent] Request body:`,
      cancelEventBody,
      `\n[cancelEvent] Params:`,
      cancelEventQueryString,
    );

    return api.patch(
      `/api/care_platform/events/v1/${id}/${cancelEventQueryString}`,
      cancelEventBody,
    );
  } catch (e) {
    console.error(`[cancelEvent] Unable to cancel event: ${e}`);
    throw new Error(`[cancelEvent] Unable to cancel event: ${e}`);
  }
}

export function useCancelSession() {
  return useMutation(cancelEvent);
}
