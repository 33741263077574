import { graphqlClient } from "../../../../api/graphql";
import { FIVE_MINUTES } from "../../../_helpers/constants";
import {
  useSearchClientsQuery,
  SearchClientsQuery,
} from "../../../../graphql/generated";

interface UseSearchClients<T extends {}> {
  searchTerm: string;
  clinicianId: number;
  select: (data: SearchClientsQuery, clinicianId: number) => T;
}

export const useSearchClients = <T extends {}>({
  searchTerm,
  select,
  clinicianId,
}: UseSearchClients<T>) => {
  return useSearchClientsQuery<T>(
    graphqlClient,
    { search: searchTerm },
    {
      select: (data) => select(data, clinicianId),
      staleTime: FIVE_MINUTES,
    },
  );
};
