import { Stack } from "@/app/design-system";
import { useParams } from "react-router-dom";
import { useGroupTherapyMbcEventScores } from "./_api/use-group-therapy-mbc-events";
import {
  getEventData,
  selectGroupTherapyMbcEventScores,
} from "./_api/use-group-therapy-mbc-events/use-group-therapy-mbc-events.util";
import { WeeklyCheckInOverviewHeader } from "./_components/weekly-check-in-overview-header";

export const GroupDetailsMBC = () => {
  const { sessionId } = useParams<{ sessionId: string }>();

  const { data: allScoreData, isLoading } = useGroupTherapyMbcEventScores({
    select: selectGroupTherapyMbcEventScores,
  });

  const eventScoreData = getEventData(allScoreData, sessionId);

  return (
    <WeeklyCheckInOverviewHeader
      isLoading={isLoading}
      eventData={eventScoreData.eventData}
      isFirstSession={eventScoreData.isFirstSession}
    />
  );
};
