import {
  ClinicianUtilization,
  ConsultNPSForm,
  DayTime,
  BasicMatch,
  ExtendedMatch,
  IdMap,
  AQMScoreBreakdown,
  CouplesMatch,
  CsvDataItem,
} from "../../api/types";
import { message } from "antd";

import moment from "moment";
import { SelectValue, LabeledValue } from "antd/es/select";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { isString, differenceWith, isEmpty, isEqual } from "lodash-es";

export enum UtilizationStatus {
  Unknown = "Loading",
  Available = "Available",
  Unavailable = "Unavailable",
  Hold = "Hold",
  Inactive = "Inactive",
}

export enum Weekdays {
  Sunday = "Sunday",
  Monday = "Monday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
  Thursday = "Thursday",
  Friday = "Friday",
  Saturday = "Saturday",
}

export const holdReasons = [
  {
    key: "ramp down clinical",
    value: "Ramping down clinical time - employment status change",
  },
  {
    key: "ramp down therapy",
    value: "Ramping down therapy time (e.g. to take on more consults)",
  },
  { key: "new clients", value: "Too many new clients this week" },
  { key: "over utilized", value: "Over-utilized / burnout risk" },
  { key: "other", value: "Other" },
];

export const autoSchedulePauseReasons = [
  {
    key: "offboarding",
    value: "Offboarding",
  },
  {
    key: "leave",
    value: "Leave",
  },
  { key: "performance issues", value: "Performance Issues" },
  { key: "role change", value: "Role change - decrease working hours" },
];

export const requeueReasons = [
  {
    key: "client-initiated--didnt-see-email",
    value: "Client-initiated - Didn't see email",
    removeSelectedFit: false,
  },
  {
    key: "client-initiated--wanted-to-confirm-missed-deadline",
    value: "Client-initiated - Wanted to confirm but missed deadline",
    removeSelectedFit: false,
  },
  {
    key: "client-initiated--didnt-like-therapist-match",
    value: "Client-initiated - Didn't like therapist match",
    removeSelectedFit: true,
  },
  {
    key: "client-initiated--first-session-date-didnt-work",
    value:
      "Client-initiated - First session date didn't work, unable to adjust",
    removeSelectedFit: false,
  },
  {
    key: "client-initiated--availability-changed",
    value: "Client-initiated - Client's regular timeslot availability changed",
    removeSelectedFit: false,
  },
  {
    key: "client-initiated--clients-clinical-preferences-needs-changed",
    value: "Client-initiated - Client's clinical preferences/needs changed",
    removeSelectedFit: true,
  },
  {
    key: "client-initiated--referred-out-came-back",
    value: "Client-initiated - Referred out and came back",
    removeSelectedFit: false,
  },
  {
    key: "client-initiated--insurance-change",
    value: "Client-initiated - Insurance change",
    removeSelectedFit: false,
  },
  {
    key: "client-initiated--wasnt-ready-to-start-care",
    value: "Client-initiated - Wasn't ready to start care",
    removeSelectedFit: false,
  },
  {
    key: "client-initiated--other",
    value: "Client-initiated - Other",
    removeSelectedFit: false,
  },
  {
    key: "clinician-initiated--clinical-fit",
    value: "Clinician-initiated - Clinical fit",
    removeSelectedFit: true,
  },
  {
    key: "clinician-initiated--overmatched",
    value: "Clinician-initiated - Overmatched",
    removeSelectedFit: false,
  },
  {
    key: "clinician-initiated--schedule-change-slot-not-available",
    value: "Clinician-initiated - Schedule change; slot no longer available",
    removeSelectedFit: false,
  },
  {
    key: "clinician-initiated--slot-wasnt-supposed-to-be-available",
    value: "Clinician-initiated - Slot was never supposed to be available",
    removeSelectedFit: false,
  },
  {
    key: "clinician-initiated--other",
    value: "Clinician-initiated - Other",
    removeSelectedFit: false,
  },
  {
    key: "clinician-initiated--conflicts-with-company-team-meeting",
    value: "Clinician-initiated - Conflicts with company/team meeting",
    removeSelectedFit: false,
  },
] as const;

export type RequeueReasonKeys = typeof requeueReasons[number]["key"];

export const isCurrentWeek = (date: string) => {
  const currentWeek = moment().isoWeekday(1);
  if (moment(date).isSame(currentWeek, "day")) {
    return true;
  }
  return false;
};

export const getAvailabilityFromUtil = (utilization: ClinicianUtilization) => {
  if (!utilization) {
    return;
  }
  const totalUtil = utilization.utilization;
  const targetUtil = utilization.target;
  return Math.round((targetUtil - totalUtil) * 2) / 2;
};

export const clientStatusIsConverted = (status: string) => {
  return ["Active", "Scheduled"].find((s) => s === status);
};

export const alphabetize = (a: string, b: string) => {
  return a > b ? 1 : b > a ? -1 : 0;
};

export function alphabetizeBy(a: any, b: any, key: string) {
  return alphabetize(
    (a[key] || "").toLowerCase(),
    (b[key] || "").toLowerCase(),
  );
}

export const alphabetizeByPass = (key: string) => (a: any, b: any) =>
  alphabetizeBy(a, b, key);

export const bucketSort = (a: string, b: string) => {
  const order = {
    "Presenting Concern": 0,
    "Clinical Issues": 0,
    "Focus Areas": 1,
    "Treatments": 2,
    "Clinical Style": 3,
    "Therapist Characteristics": 4,
    "Clinician Characteristics": 4,
    "Couples Therapy": 5,
  };
  const alpha = order[a] !== undefined ? order[a] : 5;
  const beta = order[b] !== undefined ? order[b] : 5;
  return alpha - beta;
};

export const toMap = (collection: Array<{ id: string | number }>) => {
  const map = {};
  collection.map((item: any) => (map[item.id] = item));
  return map;
};

export const getMapLength = (subject: Map<any, any>) => {
  return Array.from(subject.keys()).length;
};

export const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
  message.success("Copied!");
};

export const getMatchTimestamp = (match: BasicMatch) => {
  const consultTime = match.consult
    ? match.consult.start_time
    : match.created_at;

  return match.match_after && moment(match.match_after).isAfter(moment())
    ? match.match_after
    : consultTime;
};

export const matchQueueSort = (a: BasicMatch, b: BasicMatch) => {
  const dtA = getMatchTimestamp(a);
  const dtB = getMatchTimestamp(b);
  return moment(dtA).valueOf() - moment(dtB).valueOf();
};

function sumMatchPriority(match: BasicMatch) {
  let sum = 1;
  switch (match.priority) {
    case "high_acuity":
      sum += 6;
      break;
    case "high_priority":
      sum += 4;
      break;
  }
  if (match.returning_to_same_clinician) {
    sum += 1;
  }
  if (match.is_rematch) {
    sum += 2;
  }
  return sum;
}

export const matchPrioritySort = (a: BasicMatch, b: BasicMatch) => {
  return sumMatchPriority(b) - sumMatchPriority(a);
};

export const sortQueuedMatches = (matches: BasicMatch[]) => {
  return matches
    .sort(matchQueueSort)
    .sort(matchPrioritySort)
    .map((match, i) => ({ ...match, queue_rank: i + 1 }));
};

export const resolvedMatchSort = (
  a: BasicMatch | ExtendedMatch,
  b: BasicMatch | ExtendedMatch,
) => {
  const dtA =
    a.consult && !isString(a.consult) ? a.consult.start_time : a.created_at;
  const dtB =
    b.consult && !isString(b.consult) ? b.consult.start_time : b.created_at;
  return moment(dtB).valueOf() - moment(dtA).valueOf();
};

export const median = (values: number[]) => {
  if (values.length === 0) {
    return 0;
  }

  values.sort((a, b) => {
    return a - b;
  });

  const half = Math.floor(values.length / 2);

  if (values.length % 2) {
    return values[half];
  }

  return (values[half - 1] + values[half]) / 2.0;
};

export function isLabeledValue(value: SelectValue): value is LabeledValue {
  if (
    typeof value !== "string" &&
    typeof value !== "number" &&
    !(value instanceof Array)
  ) {
    return value !== undefined;
  }

  return false;
}

export function isMultiStringValue(
  value: SelectValue | CheckboxValueType[],
): value is string[] {
  if (value instanceof Array) {
    if (value.length === 0) {
      return true;
    }

    const firstValue = value[0];
    if (typeof firstValue === "string") {
      const check: string = firstValue;
      return check !== undefined;
    }
  }

  return false;
}

export const stripHtmlFromString = (str: string): string => {
  // https://stackoverflow.com/questions/822452/strip-html-from-text-javascript
  return str.replace(/<[^>]*>?/gm, "");
};

export function isMultiNumberValue(
  value: SelectValue | CheckboxValueType[],
): value is number[] {
  if (value instanceof Array) {
    if (value.length === 0) {
      return true;
    }

    const firstValue = value[0];
    if (typeof firstValue === "number") {
      const check: number = firstValue;
      return check !== undefined;
    }
  }

  return false;
}

export const getNpsScoreFromForm = (form: ConsultNPSForm) => {
  if (!form) return 0;
  try {
    const { blob } = form;
    const npsQuestion = blob[blob.length - 1];
    let answerText = npsQuestion!["answer_text"];
    if (answerText.includes("10")) return 10;
    if (answerText.includes("1")) return 1;
    if (answerText.includes("0")) return 0;
    const score = parseInt(answerText);
    if (isNaN(score)) return null;
    return score;
  } catch (e) {
    return null;
  }
};

export const getFeedbackAvgScoreFromForm = (form: ConsultNPSForm) => {
  if (!form) return 0;
  try {
    const { blob } = form;
    let questionsAnswered = 5;
    const npsQuestions: number[] = [
      blob[3].answer_text,
      blob[4].answer_text,
      blob[5].answer_text,
      blob[6].answer_text,
      blob[7].answer_text,
    ].map((answerText) => {
      switch (answerText.toLowerCase()) {
        case "strongly agree":
          return 5;
        case "agree":
          return 4;
        case "neither agree nor disagree":
          return 3;
        case "disagree":
          return 2;
        case "strongly disagree":
          return 1;
        default:
          questionsAnswered--;
          return 0;
      }
    });
    return npsQuestions.reduce((a, b) => a + b) / questionsAnswered;
  } catch (e) {
    return null;
  }
};

export const slotArraysMatch = (
  slotsA: DayTime[],
  slotsB: DayTime[],
): boolean => {
  const slotsOnlyInA = differenceWith(slotsA, slotsB, isEqual);
  const slotsOnlyInB = differenceWith(slotsB, slotsA, isEqual);
  return isEmpty(slotsOnlyInA) && isEmpty(slotsOnlyInB);
};

export const matchEmailNotSent = (match: BasicMatch | ExtendedMatch) =>
  match.client_status === "Prospective Match" &&
  match.match_email_sent === null;

export const getHighestAqmScore = (
  aqmScores: IdMap<AQMScoreBreakdown>,
  clinicianId: number,
) => {
  return Object.keys(aqmScores)
    .filter((key) => key.split("_")[0] === clinicianId.toString())
    .map((key) => aqmScores[key])
    .reduce((highestScore: AQMScoreBreakdown | null, currentScore) => {
      if (!highestScore) {
        return currentScore;
      }
      return highestScore.score > currentScore.score
        ? highestScore
        : currentScore;
    }, null);
};

export const upsertQueryParam = (
  name: string,
  value: string,
  reloadPage: boolean,
) => {
  const params = new URLSearchParams(window.location.search);
  params.set(name, value);

  if (reloadPage) {
    window.location.search = params.toString();
  } else {
    const newPath = `${window.location.pathname}?${params.toString()}`;
    // eslint-disable-next-line no-restricted-globals
    history.pushState(null, "", newPath);
  }
};

export const showInitialsCouple = (match: CouplesMatch | BasicMatch) => {
  return `${match.couple!.clientA.initials} ${match.couple!.clientB.initials}`;
};

export const getMatchInitials = (match: CouplesMatch | BasicMatch) => {
  if (match.couple) {
    return showInitialsCouple(match);
  } else {
    return match.client.initials;
  }
};

const currentDate = () => {
  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${month}_${day}_${year}`;
};

export const performDownload = function (data: string, filename: string) {
  const blob = new Blob([data], { type: "text/csv" });

  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");

  a.setAttribute("href", url);
  a.setAttribute("download", `${filename}_${currentDate()}.csv`);
  a.click();
};

type CsvInputData = {
  headers: string[];
  rows: CsvDataItem[];
};

export const makeCSV = (data: CsvInputData) => {
  const csvRows: string[] = [];
  csvRows.push(data.headers.join(","));

  data.rows.forEach((row) => {
    csvRows.push(row.join(","));
  });
  return csvRows.join("\n");
};

export const downloadAsCSV = async function (
  data: CsvInputData,
  filename: string,
) {
  const csvData = makeCSV(data);
  performDownload(csvData, filename);
};

export const getRoofDogBaseUrl = () => {
  const { hostname, port, href } = window.location;
  if (hostname === "localhost" && port === "3000") {
    return `http://localhost:3333`;
  }
  if (hostname === "clinicians.staging.2chairs.co") {
    return `https://roofdog.staging.2chairs.co`;
  }
  //note: update this once the old clinicians app gets a new URL
  if (hostname === "clinicians.twochairs.com") {
    return `https://cliniciansbeta.twochairs.com`;
  } else {
    return href;
  }
};
