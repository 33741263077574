import React, { useState } from "react";
import { CSS } from "@stitches/react";

function useHoverHighlight() {
  const [tabBoundingBox, setTabBoundingBox] = React.useState<{
    width: number;
    left: number;
  } | null>(null);

  const [wrapperBoundingBox, setWrapperBoundingBox] = React.useState<{
    left: number;
  } | null>(null);

  const [highlightedTab, setHighlightedTab] = React.useState<null | string>(
    null,
  );
  const [isHoveredFromNull, setIsHoveredFromNull] = React.useState(true);

  const hoverHighlightRef = React.useRef<HTMLDivElement>(null);

  const wrapperRef = React.useRef<HTMLDivElement>(null);

  const repositionHighlight = (e: any, tab: string) => {
    if (e && wrapperRef.current) {
      setTabBoundingBox(e.target.getBoundingClientRect());
      setWrapperBoundingBox(wrapperRef.current.getBoundingClientRect());
      setIsHoveredFromNull(!highlightedTab);
      setHighlightedTab(tab);
    }
  };

  const resetHighlight = () => setHighlightedTab(null);

  const highlightStyles: CSS = {};

  if (tabBoundingBox && wrapperBoundingBox) {
    highlightStyles.transitionDuration = isHoveredFromNull ? "0ms" : "150ms";
    highlightStyles.opacity = highlightedTab ? 1 : 0;
    highlightStyles.width = `${tabBoundingBox.width}px`;
    highlightStyles.transform = `translate(${
      tabBoundingBox.left - wrapperBoundingBox.left
    }px)`;
  }

  return {
    wrapperBoundingBox,
    highlightStyles,
    wrapperRef,
    resetHighlight,
    repositionHighlight,
    hoverHighlightRef,
  };
}

function useActiveHighlight(
  activeTabValue: string,
  wrapperRef: React.RefObject<HTMLDivElement>,
  wrapperBoundingBox: { left: number } | null,
) {
  const [activeTabBoundingBox, setActiveTabBoundingBox] = React.useState<{
    left: number;
  } | null>(null);

  const [activeTab, setActiveTab] = useState(activeTabValue);

  const repositionActiveHighlight = (e: any, tab: string) => {
    if (e && wrapperRef.current) {
      setActiveTabBoundingBox(e.target.getBoundingClientRect());
      setActiveTab(tab);
    }
  };

  const activeStyles: CSS = {};

  if (activeTabBoundingBox && wrapperBoundingBox) {
    activeStyles.transitionDuration = "150ms";
    activeStyles.transform = `translate(${
      activeTabBoundingBox.left - wrapperBoundingBox.left
    }px)`;
  }

  return {
    activeTab,
    setActiveTab,
    repositionActiveHighlight,
    activeStyles,
  };
}

export function useNavigationTabsStyling(activeTabValue: string) {
  const {
    wrapperBoundingBox,
    highlightStyles,
    wrapperRef,
    resetHighlight,
    hoverHighlightRef,
    repositionHighlight,
  } = useHoverHighlight();

  const { activeTab, setActiveTab, repositionActiveHighlight, activeStyles } =
    useActiveHighlight(activeTabValue, wrapperRef, wrapperBoundingBox);

  return {
    hoverHighlightRef,
    repositionHighlight,
    resetHighlight,
    highlightStyles,
    activeTab,
    setActiveTab,
    repositionActiveHighlight,
    activeStyles,
    wrapperRef,
  };
}
