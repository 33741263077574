import * as React from "react";
import { Alert, Button } from "antd";
import TagInputs from "./TagInputs";
import { NEEDS_TAG_WEIGHT } from "../../../../api/constants";
import { useShallowEqualSelector } from "../../../_helpers/redux";
import { Row, Flex } from "../../../_layout/Flex";
import { useDispatch } from "react-redux";
import { matchmakerActions } from "../../../../state/models/matchmaker";

export default () => {
  const [removedTags] = useShallowEqualSelector(state => [
    state.matchmaker.removedTags,
  ]);

  const dispatch = useDispatch();

  return (
    <>
      <div style={{ overflow: "auto", padding: "15px" }}>
        {removedTags.length > 0 && (
          <Alert
            type="error"
            style={{ marginBottom: "15px" }}
            message={
              <div>
                <Row layout="start center">
                  <Flex>Some tags for this match are no longer available:</Flex>
                  <Button
                    onClick={() =>
                      dispatch(matchmakerActions.setRemovedTags([]))
                    }
                  >
                    Dismiss
                  </Button>
                </Row>
                <ul>
                  {removedTags.map(tag => (
                    <li key={tag.id.toString()}>
                      {tag.bucket} - {tag.category} -{" "}
                      <strong>{tag.name}</strong>{" "}
                      <i>
                        ({tag.weight === NEEDS_TAG_WEIGHT ? "Need" : "Benefit"})
                      </i>
                    </li>
                  ))}
                </ul>
              </div>
            }
          />
        )}
        <TagInputs />
      </div>
    </>
  );
};
