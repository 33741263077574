export const STANDARD_FOCUS_OUTLINE = "-webkit-focus-ring-color auto 1px";

/* Default z-index for page elements is 0;
These are meant to make some of our z-indices more predictable.
This way we're not just arbitrarily throwing zIndex values around.
e.g. we don't make a popover components overlap with an important alert 
because we randomly assigned the popover zIndex to 9001
*/

/**
 * Description: Useful for when we have components that are 'visually nested' within a component,
 * but have click target areas that appear over the span of their parent
 * Related components: VisuallyNestedLinks
 */
export const VN_COMPONENT_INDEX = 5;
export const VN_COMPONENT_OVERLAY_INDEX = 6;

/**
 * Useful for timepickers or components that are popover-esque
 */
export const OVERLAY_ZINDEX = 10;

/**
 * Useful for components that are popover-esque that appear within a modal
 */
export const MODAL_OVERLAY_ZINDEX = 15;

/**
 * Useful for things that should never be covered,
 * e.g. skip links and alerts
 */
export const HIGH_PRIORITY_ZINDEX = 20;
