import { UsersIcon } from "@/app/design-system/icons/components/users-icon";
import { AuditOutlined } from "@ant-design/icons";

import { NavigationMenuItem } from "../../components.utility/navigation-menu-item";

interface TeamMenuItem {
  isCollapsed: boolean;
}

export function TeamMenuItem({ isCollapsed }: TeamMenuItem) {
  return (
    <>
      <NavigationMenuItem
        to="/team"
        isCollapsed={isCollapsed}
        menuItemTooltipContent="Team"
      >
        <UsersIcon />
        {!isCollapsed ? "Team" : null}
      </NavigationMenuItem>
    </>
  );
}

export function TimeOffMenuItem({ isCollapsed }: TeamMenuItem) {
  return (
    <NavigationMenuItem
      to="/time-off"
      isCollapsed={isCollapsed}
      menuItemTooltipContent="Time Off"
    >
      <AuditOutlined
        style={{ fontSize: 20 }}
        aria-hidden={!isCollapsed}
        title={!isCollapsed ? "" : "Time Off"}
      />
      {!isCollapsed ? "Time Off" : null}
    </NavigationMenuItem>
  );
}
