export enum Timezones {
  PACIFIC_TIME = 'America/Los_Angeles',
  CENTRAL_TIME = 'America/Chicago',
  EASTERN_TIME = 'America/New_York',
}

export const PT_USER_FRIENDLY_TIMEZONE = 'Pacific Time';
export const CT_USER_FRIENDLY_TIMEZONE = 'Central Time';
export const ET_USER_FRIENDLY_TIMEZONE = 'Eastern Time';

export const PT_TIMEZONE_ABBREVIATION = 'PT';
export const CT_TIMEZONE_ABBREVIATION = 'CT';
export const ET_TIMEZONE_ABBREVIATION = 'ET';

export const PT_IANA_TIMEZONE = 'America/Los_Angeles';
export const CT_IANA_TIMEZONE = 'America/Chicago';
export const ET_IANA_TIMEZONE = 'America/New_York';

export const IANA_TO_TIMEZONE_ABBREVIATION = {
  [PT_IANA_TIMEZONE]: PT_TIMEZONE_ABBREVIATION,
  [CT_IANA_TIMEZONE]: CT_TIMEZONE_ABBREVIATION,
  [ET_IANA_TIMEZONE]: ET_TIMEZONE_ABBREVIATION,
} as const;

export const IANA_TO_USER_FRIENDLY_TIMEZONE = {
  [PT_IANA_TIMEZONE]: PT_USER_FRIENDLY_TIMEZONE,
  [CT_IANA_TIMEZONE]: CT_USER_FRIENDLY_TIMEZONE,
  [ET_IANA_TIMEZONE]: ET_USER_FRIENDLY_TIMEZONE,
} as const;
