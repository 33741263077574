import { Separator, Stack } from "@/app/design-system";

import * as React from "react";
import { MBCSurveyResponses } from "../mbc-survey-responses";
import { Chart } from "../types";
import { ChartsTabs } from "./chart-tabs";
import { MBCGraph } from "./mbc-graph/mbc-graph";
import { MBCTrendKeyInfoPopover } from "./mbc-trend-key-popover";
import { SectionHeader } from "./section-header";
import { useClientMBCMetrics } from "./use-client-mbc-metrics";
interface ClientMBCMetricsProps {
  charts: Chart[];
  clientId?: number;
  coupleId?: number;
  primaryAssessment?: string;
  welkinLink?: string;
  healthieLink?: string;
}

export const ClientMBCMetrics = ({
  primaryAssessment,
  charts,
  clientId,
  coupleId,
  welkinLink,
  healthieLink,
}: ClientMBCMetricsProps) => {
  const [endorsedSi, setEndorsedSi] = React.useState(false);
  const chartCount = charts.length;

  const {
    setSelectedChart,
    selectedChart,
    setSelectedDateFilter,
    selectedDateFilter,
    chartsToRender,
    lastEventId,
    chart,
  } = useClientMBCMetrics(primaryAssessment, charts);

  return (
    <Stack
      css={{
        backgroundColor: "$neutral0",
        overflow: "hidden",
        flex: 1,
        pb: 32,
      }}
    >
      <SectionHeader
        setSelectedDateFilter={setSelectedDateFilter}
        currentDateFilter={selectedDateFilter}
      />

      <ChartsTabs
        endorsedSi={endorsedSi}
        setSelectedChart={setSelectedChart}
        selectedChart={selectedChart}
        chartsToRender={chartsToRender}
        chartCount={chartCount}
      />
      {chart && (
        <MBCGraph chart={chart} clientId={clientId} coupleId={coupleId} />
      )}

      <Stack
        alignItems="flex-start"
        gap={24}
        css={{
          display: "none",
          "@breakpoint1200": {
            display: "flex",
          },
        }}
      >
        <MBCTrendKeyInfoPopover />
        <Separator orientation="horizontal" />
      </Stack>

      {chart && clientId && (
        <MBCSurveyResponses
          selectedChart={selectedChart}
          onSetEndorsedSi={(eventId, isEndorsedSi) => {
            // only want to set endorsed SI for the last/most recent event
            if (eventId && lastEventId && eventId === lastEventId) {
              setEndorsedSi(isEndorsedSi);
            }
          }}
          chart={chart}
          welkinLink={welkinLink}
          healthieLink={healthieLink}
        />
      )}
    </Stack>
  );
};
