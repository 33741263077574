import {
  GroupTherapyClientDataFragment,
  GroupTherapyClientQuery,
  GroupTherapyEmergencyContactFragment,
} from "@/graphql/generated";
import { DateTime } from "luxon";
import { getPayer } from "@/app/groups/group-id/_api/use-group-therapy-client-nav-info/use-group-therapy-client-nav-info.util";

export interface ParsedGroupClient {
  clientProfile?: ClientProfile;
  identify?: Identity;
  priorTherapy?: string;
  emergencyContact?: EmergencyContact;
  payer?: string;
  careBackground?: QuestionAnswer[] | null;
}

export interface QuestionAnswer {
  question: string;
  answer: string | null;
}

export interface EmergencyContact {
  contactName?: string | null;
  email?: string | null;
  phone?: string | null;
  relationship?: string | null;
}

export interface Identity {
  gender?: string;
  racial?: string[];
  pronouns?: string;
  sex_at_birth?: string;
}

export interface ClientProfile {
  address_line_1?: string;
  address_line_2?: string;
  city?: string;
  dob?: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  phone_number?: string;
  preferred_name?: string;
  state?: string;
  twochair_email_opt_out?: boolean;
  zip?: string;
}

export const selectGroupTherapyClientData = (
  groupTherapyMbcData: GroupTherapyClientQuery,
  timezone: string,
): ParsedGroupClient => {
  if (!groupTherapyMbcData) {
    return {};
  }
  const bookingProfileData =
    groupTherapyMbcData.group[0].clientGroups[0].client.bookingProfileData[0];

  if (!bookingProfileData || !Object.keys(bookingProfileData).length) {
    return {};
  }

  return {
    clientProfile: bookingProfileData.profileData.profile,
    emergencyContact: getEmergencyContactInfo(
      groupTherapyMbcData.group[0].clientGroups[0].client,
    ),
    identify: bookingProfileData.profileData.self_identify,
    payer: getPayer(
      groupTherapyMbcData.group[0].clientGroups[0].client.insurance,
    ),
    careBackground: getCareBackground(
      groupTherapyMbcData.group[0].clientGroups[0].client,
    ),
  };
};

const getEmergencyContactInfo = (
  clientData: GroupTherapyClientDataFragment,
): EmergencyContact | undefined => {
  if (!clientData) {
    return undefined;
  }
  const bookingProfileEmergencyContact =
    clientData.bookingProfileData[0].profileData.emergency_contact;

  // get the most recent emergency contact in the database
  const mostRecentEmergencyContact = getSortedContacts(
    clientData.emergencyContacts,
  )[0];

  let contactName, phone, email, relationship;
  if (bookingProfileEmergencyContact) {
    contactName =
      bookingProfileEmergencyContact.first_name +
      " " +
      bookingProfileEmergencyContact.last_name;
    phone = formatPhoneNumber(bookingProfileEmergencyContact.phone_number);
    email = bookingProfileEmergencyContact.email;
    relationship = bookingProfileEmergencyContact.relationship;
  } else {
    contactName = mostRecentEmergencyContact.name;
    phone = formatPhoneNumber(mostRecentEmergencyContact.phone);
    email = mostRecentEmergencyContact.email;
    relationship = mostRecentEmergencyContact.relationship;
  }

  return {
    contactName,
    phone,
    email,
    relationship,
  };
};

const getSortedContacts = (
  contacts: GroupTherapyEmergencyContactFragment[],
) => {
  return contacts.sort((a, b) => {
    if (DateTime.fromISO(a.date) < DateTime.fromISO(b.date)) {
      return -1;
    } else if (DateTime.fromISO(a.date) > DateTime.fromISO(b.date)) {
      return 1;
    } else {
      return 0;
    }
  });
};

export const formatBirthDate = (
  dob: string | undefined,
  timezone: string,
): string | undefined => {
  if (!dob) {
    return undefined;
  }
  return DateTime.fromFormat(dob, "MMddy", {
    zone: timezone,
  }).toLocaleString();
};

export const formatPhoneNumber = (phoneNumber: string | null | undefined) => {
  if (!phoneNumber) {
    return null;
  }
  const cleanedNumber = ("" + phoneNumber).replace(/\D/g, "");
  const match = cleanedNumber.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
};

const getArrayAnswerAsString = (answer: string[]) =>
  answer.length ? answer.join(", ") : null;

const trimString = (answer: string) => (answer ? answer.trim() : null);

const getCareBackground = (
  clientData: GroupTherapyClientDataFragment,
): QuestionAnswer[] | null => {
  const moreAboutYou =
    clientData.bookingProfileData[0].profileData?.more_about_you;
  if (moreAboutYou) {
    return [
      {
        question: "What brings you to Group Therapy?",
        answer: trimString(moreAboutYou.what_brings_you_to_group_therapy),
      },
      {
        question: "Have you sought out therapy in the past?",
        answer: trimString(
          moreAboutYou.have_you_ever_sought_out_therapy_in_the_past,
        ),
      },
      {
        question: "What types of care have you received?",
        answer: getArrayAnswerAsString(
          moreAboutYou.what_type_of_care_have_you_received,
        ),
      },
      {
        question: "How long were you engaged in care?",
        answer: trimString(
          moreAboutYou.about_how_long_were_you_engaged_in_care,
        ),
      },
      {
        question:
          "Helpful or unhelpful about your previous experiences in care?",
        answer: trimString(
          moreAboutYou.what_was_helpful_or_unhelpful_about_previous_experience_in_care,
        ),
      },
      {
        question: "Accessibility needs",
        answer: getArrayAnswerAsString(
          moreAboutYou.do_you_have_any_accessibility_needs_we_should_know_about,
        ),
      },
      {
        question:
          "Any health condition impacting your ability to participate in Group Therapy?",
        answer: trimString(
          moreAboutYou.any_health_condition_impacting_your_ability_to_participate_in_group,
        ),
      },
      {
        question: "Is anyone close to you currently in care with Two Chairs?",
        answer: trimString(
          moreAboutYou.is_anyone_close_to_you_currently_in_care_with_twochairs,
        ),
      },
    ];
  }
  return null;
};
