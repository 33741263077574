import * as React from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Button, Input, message, Radio } from "antd";

import { useDispatchPromise } from "../../_helpers/redux";
import { EditCategoryFormProps } from "../types";
import { createCategory, updateCategory } from "../utils";
import { Row } from "../../_layout/Flex";

const CategoryForm = ({
  initialCategory,
  onUpdateCategory,
  onCancel,
  form,
}: EditCategoryFormProps) => {
  const dispatch = useDispatchPromise();

  const onSubmit = async (e: React.FormEvent) => {
    try {
      e.preventDefault();
      form.validateFields(async (err, values) => {
        if (err) {
          return;
        }
        let response = null;

        if (initialCategory && initialCategory.id) {
          // update tag
          response = await dispatch(updateCategory(initialCategory.id, values));
        } else {
          // create new tag
          response = await dispatch(createCategory(values));
        }
        if (response.data) {
          onUpdateCategory({
            ...response.data,
            items: initialCategory.items,
          });
          message.success(
            `Category ${initialCategory.id ? "updated" : "created"}!`,
          );
        } else {
          message.error(
            `Uh oh! We're unable to ${
              initialCategory.id ? "update" : "add"
            } resource`,
          );
        }
      });
    } catch (e) {
      message.error((e as Error).message);
    }
  };

  return (
    <Form onSubmit={onSubmit} onReset={() => onCancel()}>
      <Form.Item label="Name" colon={false} style={{ margin: 8 }}>
        {form.getFieldDecorator("name", {
          initialValue: initialCategory.name,
          rules: [{ required: true, message: "Name is required" }],
        })(<Input placeholder="Add category name" />)}
      </Form.Item>

      <Form.Item colon={false} label="Publish category" style={{ margin: 8 }}>
        {form.getFieldDecorator("is_published", {
          initialValue: initialCategory.is_published,
        })(
          <Radio.Group>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>,
        )}
      </Form.Item>

      <Row layout="space-between start" style={{ margin: "16px 8px 0" }}>
        <Form.Item style={{ margin: 0 }}>
          <Button htmlType="reset">Cancel</Button>
        </Form.Item>
        <Form.Item style={{ margin: 0 }}>
          <Button type="primary" htmlType="submit">
            {initialCategory.id ? "Save" : "Create"}
          </Button>
        </Form.Item>
      </Row>
    </Form>
  );
};

export const EditCategoryForm = Form.create<EditCategoryFormProps>({
  name: "edit_category",
})(CategoryForm);
