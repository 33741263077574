import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import React from "react";

import {
  DropdownButton,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuRadioItemIndicator,
  DropdownMenuRadioItemWithoutIndicator,
  DropdownMenuTrigger,
} from "./dropdown.styled";

import { Stack, Text } from "../";

import { DownChevronIcon } from "../icons";
import { Label } from "@/app/design-system";

export const ComposedDropdownMenuRadioItem = React.forwardRef<
  HTMLDivElement,
  DropdownMenuPrimitive.DropdownMenuRadioItemProps
>(({ children, ...props }, forwardedRef) => {
  const { value } = props;
  return (
    <DropdownMenuRadioItem {...props} ref={forwardedRef} id={value}>
      <DropdownMenuRadioItemIndicator />
      <Label htmlFor={value} cursor="pointer">
        {children}
      </Label>
    </DropdownMenuRadioItem>
  );
});

export const ComposedDropdownMenuRadioItemWithoutIndicator = React.forwardRef<
  HTMLDivElement,
  DropdownMenuPrimitive.DropdownMenuRadioItemProps
>(({ children, ...props }, forwardedRef) => {
  const { value } = props;
  return (
    <DropdownMenuRadioItemWithoutIndicator
      {...props}
      ref={forwardedRef}
      id={value}
    >
      <Label htmlFor={value} cursor="pointer">
        {children}
      </Label>
    </DropdownMenuRadioItemWithoutIndicator>
  );
});

export const ComposedDropdownMenuTrigger = React.forwardRef<
  HTMLButtonElement,
  DropdownMenuPrimitive.DropdownMenuTriggerProps
>(({ children, ...props }, forwardedRef) => {
  return (
    <DropdownMenuTrigger asChild ref={forwardedRef} {...props}>
      <DropdownButton aria-label="client status filter options">
        <Text>{children}</Text>

        <DownChevronIcon className="caret-icon" />
      </DropdownButton>
    </DropdownMenuTrigger>
  );
});

interface ComposedDropdownMenuProps<T extends string> {
  values: T[];
  currentValue: string;
  setValue: (value: T) => void;
  ariaLabel?: string;
  triggerStyles?: Record<string, string | number>
}

export const ComposedDropdownMenu = <T extends string>({
  currentValue,
  setValue,
  values,
  ariaLabel,
  triggerStyles = {},
}: ComposedDropdownMenuProps<T>) => {
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

  return (
    <DropdownMenu open={isDropdownOpen}>
      <DropdownMenuTrigger asChild onClick={() => setIsDropdownOpen(true)} css={triggerStyles}>
        <DropdownButton
          aria-label={ariaLabel}
          css={{ position: "absolute", right: 24, border: "none" }}
        >
          <Text>{currentValue}</Text>
          <DownChevronIcon className="caret-icon" />
        </DropdownButton>
      </DropdownMenuTrigger>

      <DropdownMenuContent
        sideOffset={5}
        onInteractOutside={() => setIsDropdownOpen(false)}
      >
        <Stack gap={16}>
          <DropdownMenuRadioGroup value={currentValue}>
            {values.map((value) => {
              return (
                <ComposedDropdownMenuRadioItemWithoutIndicator
                  key={value}
                  value={value}
                  onSelect={() => {
                    setIsDropdownOpen(false);
                    setValue(value);
                  }}
                >
                  {value}
                </ComposedDropdownMenuRadioItemWithoutIndicator>
              );
            })}
          </DropdownMenuRadioGroup>
        </Stack>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
