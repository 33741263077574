import React from "react";
import {
  GroupTherapyClientNotesQuery,
  GroupTherapyAttendanceClientGroupEventDataFragment,
} from "@/graphql/generated";
import { DateTime } from "luxon";

export interface ParsedClientAttendanceNotes {
  sessionNumber: number;
  formattedSessionDate?: string | null;
  emrLink: string;
  attendanceStatus?: string | null;
  notes?: string | null;
}

interface AttendanceNotesEventData {
  eventStartTime?: string;
  eventAppointmentStatus?: string | null;
  clientGroupEvents: GroupTherapyAttendanceClientGroupEventDataFragment[];
}

const healthieLink = "https://twochairs.gethealthie.com/clients/";
const healthieHomeLink = "https://twochairs.gethealthie.com/";

export const selectGroupTherapyClientAttendanceNotes = (
  groupTherapyNotesData: GroupTherapyClientNotesQuery,
  timezone: string,
): ParsedClientAttendanceNotes[] => {
  const allEvents = groupTherapyNotesData?.group[0].events;


  const filteredEvents = getSortedSessions(
    allEvents.filter((event) => event?.eventAppointmentStatus === "attended"),
  );

  const allParsedClientNotes: ParsedClientAttendanceNotes[] = [];
  filteredEvents.forEach((event, index) => {
    const sessionNumber = index + 1;
    const formattedSessionDate = getSessionDate(
      timezone,
      event?.eventStartTime,
    );
    const healthieId = event?.clientGroupEvents[0]?.client?.healthieId;

    const emrLink = healthieId
        ? `${healthieLink}${healthieId}/intake_flow`
        : healthieHomeLink


    const attendanceStatus = getSessionStatus(
      event?.clientGroupEvents[0]?.cgeAppointmentStatus,
      event?.clientGroupEvents[0]?.cancellationType,
      event?.clientGroupEvents[0]?.cancellationReason,
    );
    const notes = event?.clientGroupEvents[0]?.meta?.attendanceNote;
    const parsedClientAttendanceAndNotes = {
      sessionNumber,
      formattedSessionDate,
      emrLink,
      attendanceStatus,
      notes,
    };
    allParsedClientNotes.push(parsedClientAttendanceAndNotes);
  });

  return allParsedClientNotes;
};

const getSessionDate = (timezone: string, eventStartTime?: string | null) => {
  if (!eventStartTime) {
    return null;
  }
  return DateTime.fromISO(eventStartTime, {
    zone: timezone,
  }).toFormat("MMM d");
};

const getSessionStatus = (
  attendanceStatus?: string | null,
  attendanceType?: string | null,
  attendanceReason?: string | null,
) => {
  if (!attendanceStatus) {
    return null;
  }
  if (attendanceStatus === "attended") {
    return "Attended";
  } else if (attendanceStatus === "noshow") {
    return "No show, late cancel";
  } else if (
    attendanceStatus === "canceled" &&
    attendanceType === "late_by_client"
  ) {
    return "Canceled, late cancel";
  } else {
    return "Canceled";
  }
};

const getSortedSessions = (groups: AttendanceNotesEventData[]) => {
  return groups.sort((a, b) => {
    if (!a.eventStartTime || !b.eventStartTime) {
      return 0;
    }
    return (
      DateTime.fromISO(a?.eventStartTime).toMillis() -
      DateTime.fromISO(b?.eventStartTime).toMillis()
    );
  });
};
