import { graphqlClient } from "@/api/graphql";
import {
  GroupTherapyOverviewQuery,
  useGroupTherapyOverviewQuery,
} from "@/graphql/generated";
import { FIVE_MINUTES } from "@/app/_helpers/constants";

interface UseGroupsOverviewParams<T extends object> {
  clinicianId: number;
  select?: (data: GroupTherapyOverviewQuery) => T;
  enabled?: boolean;
}

export const useGroupTherapyOverview = <T extends {}>({
  clinicianId,
  select,
  enabled,
}: UseGroupsOverviewParams<T>) => {
  return useGroupTherapyOverviewQuery(
    graphqlClient,
    { clinician_id: clinicianId },
    {
      staleTime: FIVE_MINUTES,
      select,
      enabled,
    },
  );
};
