import {
  CSS_COLORS,
  Flex,
  Separator,
  Stack,
  Text,
  RadixTooltip,
} from "../../../../../../design-system";
import { EndorsedSIAlertIcon } from "../../../../../../design-system/icons/components/endorsed-si-alert-icon";
import { MBCTrackTrendIcon } from "../../../../../../design-system/icons/components/mbc-track-status-icon";
import { DiagnosisCode } from "../../../../components/diagnosis-code";
import { ClientCardProps } from "../client-pane";
import React, { useContext } from "react";
import { Spinner, QuarterCircle } from "../../../../../../design-system";

const textColorKey = {
  Stagnation: "$yellow11",
  Escalate: "$red11",
  Graduate: "$blue11",
  "Reliable Change": "$blue11",
  Improvement: "$green11",
};

export const trackKey = {
  GAD: "GAD7 track",
  PHQ: "PHQ9 track",
};

const statusIconColorsKey = {
  Stagnation: CSS_COLORS.yellow10,
  Escalate: CSS_COLORS.red9,
  Graduate: CSS_COLORS.blue9,
  "Reliable Change": CSS_COLORS.blue9,
  Improvement: CSS_COLORS.green9,
};

const trackStatusKey = {
  Stagnation: "Stagnation",
  Escalate: "Escalate",
  Graduate: "Graduate (Remission)",
  "Reliable Change": "Graduate (Reliable Change)",
  Improvement: "Improvement",
};

const mbcTrackStatusToTooltipCopy = (track: string | null, status: string) => {
  const map = {
    Improvement: "Consider no change to treatment plan",
    Escalate: "Consider reviewing case, consultation, updating treatment plan",
    Stagnation:
      "Consider updating treatment plan, consultation, additional resources",
    Graduate: "Review treatment goals, consider graduation",
    "Reliable Change":
      track === "PHQ"
        ? "Client has reached reliable change for depression when they have sustained a 5+ point drop from their intake score for 3 consecutive sessions and are at or past the lower end of the suggested session range."
        : "Client has reached reliable change for anxiety when they have sustained a 4+ point drop from their intake score for 3 consecutive sessions and are at or past the lower end of the suggested session range.",
  };
  return map[status];
};

type DiagnosesAndAssessmentTextProps = Pick<
  ClientCardProps,
  | "primaryAssessment"
  | "primaryDiagnosis"
  | "severity"
  | "mbcTrackStatus"
  | "mbcTrackStatusBeta"
  | "dxCodeMap"
  | "endorsedSi"
  | "welkinLink"
  | "healthieLink"
  | "firstName"
  | "lastName"
  | "client"
  | "isDxCodeLoading"
>;

export const DiagnosesAndAssessmentText = ({
  primaryDiagnosis,
  primaryAssessment,
  isDxCodeLoading,
  severity,
  mbcTrackStatus,
  mbcTrackStatusBeta,
  dxCodeMap,
  endorsedSi,
  welkinLink,
  healthieLink,
  firstName,
  lastName,
  client,
}: DiagnosesAndAssessmentTextProps) => {
  const mbcTrackStatusValue = mbcTrackStatusBeta;


  return (
    <Stack
      gap={12}
      css={{
        flex: 10,
        minWidth: 450,
        "@breakpoint1400": {
          minWidth: 0,
        },
      }}
    >
      <Flex gap={4} alignItems={"center"}>
        {!isDxCodeLoading ? (
          <DiagnosisCode
            primaryDiagnosis={primaryDiagnosis}
            dxCodeMap={dxCodeMap}
            isFullDx
            fontSize={16}
            emrLink={`${healthieLink}/actions`}
            emrLinkLabel={`${firstName} ${lastName}`}
            client={client}
          />
        ) : (
          <Spinner>
            <QuarterCircle dimension={"1em"}></QuarterCircle>
          </Spinner>
        )}
      </Flex>
      <Flex
        gap={8}
        css={{
          "@breakpoint1200": {
            flexDirection: "column",
          },
        }}
      >
        {primaryAssessment ? (
          <Text>{trackKey[primaryAssessment]}</Text>
        ) : (
          <Text>No track</Text>
        )}

        <Flex
          gap={8}
          css={{
            "@breakpoint1200": {
              flexDirection: "column",
            },
            "@breakpoint850": {
              flexDirection: "row",
            },
          }}
        >
          {severity ? (
            <>
              <Separator
                orientation="vertical"
                width={2}
                backgroundColor={"$neutral12"}
                css={{
                  "@breakpoint1200": {
                    display: "none",
                  },
                }}
              />

              <Text css={{ display: "none" }}>baseline</Text>
              <Text fontWeight={600}>{severity}</Text>
            </>
          ) : null}

          {mbcTrackStatusValue ? (
            <RadixTooltip
              content={mbcTrackStatusToTooltipCopy(
                primaryAssessment,
                mbcTrackStatusValue,
              )}
              width={280}
            >
              <Flex gap={8} alignItems={"center"}>
                <Stack alignItems={"center"}>
                  <MBCTrackTrendIcon
                    fill={statusIconColorsKey[mbcTrackStatusValue]}
                  />
                </Stack>
                <Text
                  color={textColorKey[mbcTrackStatusValue]}
                  fontWeight={500}
                >
                  {trackStatusKey[mbcTrackStatusValue]}
                </Text>
              </Flex>
            </RadixTooltip>
          ) : null}

          {endorsedSi ? (
            <RadixTooltip content={"Endorsed SI"}>
              <Stack
                alignItems={"center"}
                css={{
                  width: "fit-content",
                  "@breakpoint850": {
                    alignItems: "flex-start",
                  },
                }}
              >
                <EndorsedSIAlertIcon height={24} width={24} />
              </Stack>
            </RadixTooltip>
          ) : null}
        </Flex>
      </Flex>
    </Stack>
  );
};
