import React, { useEffect } from "react";
import {
  useMyClientsStore,
  selectCadenceFiltersSlice,
  CadenceFilterState,
  initialCadenceFilterState,
} from "../../../../../my-clients-model";

export const useCadenceFiltersDropdown = () => {
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

  const setMyClientsFilter = useMyClientsStore(
    (state) => state.setMyClientsFilter,
  );

  const cadenceFilterState = useMyClientsStore(selectCadenceFiltersSlice);

  const clearCadenceFilters = () => {
    for (const key in initialCadenceFilterState) {
      setMyClientsFilter(key as keyof CadenceFilterState, false);
    }

    setIsDropdownOpen(false);
  };

  return {
    isDropdownOpen,
    setIsDropdownOpen,
    setMyClientsFilter,
    clearCadenceFilters,
    ...cadenceFilterState,
  };
};
