import produce from 'immer';
import moment from 'moment';
import { actionFactory, ActionUnion, payloadAction, simpleAction } from 'reductser';

import {
  BillableHoursWeeksMap,
  DateRange,
  MBCOverallAdherenceWeekMap,
  TeamBillableHoursWeeksMap,
} from '../../../app/dashboard/types';
import { Clinician } from '../../../api/types';
import { DateTime } from 'luxon';

const actionMap = {
  setClinicianId: payloadAction<number | string>(),
  setClinicianManagers: payloadAction<Clinician[]>(),
  setTeamViewClinician: payloadAction<number | null>(),
  setTeamClinicianIds: payloadAction<number[]>(),
  setTeamCliniciansMap: payloadAction<{[clinicianId: number]: Clinician}>(),
  setDateRange: payloadAction<DateRange>(),
  setBillableHoursWeeks: payloadAction<BillableHoursWeeksMap>(),

  resetBillableHours: simpleAction(),
  startLoadingBillableHours: simpleAction(),
  finishLoadingBillableHours: simpleAction(),

  setTeamBillableHoursWeeks: payloadAction<TeamBillableHoursWeeksMap>(),
  resetTeamBillableHours: simpleAction(),
  startLoadingTeamBillableHours: simpleAction(),
  finishLoadingTeamBillableHours: simpleAction(),

  setMBCOverallAdherenceWeeks: payloadAction<MBCOverallAdherenceWeekMap>(),
  startLoadingMBC: simpleAction(),
  finishLoadingMBC: simpleAction(),
};
export const dashboardActions = actionFactory(actionMap, "DASHBOARD");
export type DashboardAction = ActionUnion<typeof dashboardActions>;


export interface DashboardState {
  clinicianId?: number | string;
  clinicianManagers: Clinician[];
  teamViewClinician: number | null;
  teamClinicianIds?: number[];
  teamCliniciansMap: { [clinicianId: number]: Clinician };
  dateRange: DateRange;

  billableHoursWeeks: BillableHoursWeeksMap;
  isLoadingBillableHours: boolean;

  isLoadingTeamBillableHours: boolean;
  teamBillableHoursWeeks: TeamBillableHoursWeeksMap;

  mbcOverallAdherenceWeeks: MBCOverallAdherenceWeekMap;
  isLoadingMBC: boolean;
}

function getInitialState(): DashboardState {
  const initialDateRange: DateRange = {
    start: moment()
      .subtract(12, "weeks")
      .startOf("week"),
    end: moment().endOf("week"),
  };
  return {
    clinicianId: undefined,
    clinicianManagers: [],
    teamViewClinician: null,
    teamClinicianIds: [],
    teamCliniciansMap: {},
    dateRange: initialDateRange,
    billableHoursWeeks: generateEmptyISOWeekMap(initialDateRange),
    isLoadingBillableHours: false,
    teamBillableHoursWeeks: {},
    isLoadingTeamBillableHours: false,
    mbcOverallAdherenceWeeks: generateEmptyISOWeekMap(initialDateRange),
    isLoadingMBC: false,
  };
}

export function generateEmptyISOWeekMap(dateRange: DateRange) {
  const { start, end } = dateRange;
  const map = {};
  for (let week = moment(start); week.isSameOrBefore(moment(end).add(1, 'week')); week.add(1, 'week')) {
    const isoyear = week.format('GGGG');
    const isoweek = week.format('WW');

    map[`${isoyear}-W${isoweek}`] = null;
  }
  return map;
}

export function generateEmptyISOWeekMapLuxon(start: DateTime, end: DateTime) {
  const map = {};
  for (let week = start; week <= end.plus({ week: 1 }); week = week.plus({ week: 1 })) {
    const isoyear = week.toFormat('yyyy');
    const isoweek = week.toFormat('WW');

    map[`${isoyear}-W${isoweek}`] = null;
  }
  return map;
}

const panelReducer = (
  state = getInitialState(),
  action: DashboardAction,
) => produce(state, draft => {
  if (action.reducer !== "DASHBOARD") return;
  switch (action.type) {
    case "setBillableHoursWeeks":
      draft.billableHoursWeeks = action.payload;
      return;
    case "resetBillableHours":
      draft.billableHoursWeeks = generateEmptyISOWeekMap(state.dateRange);
      return;
    case "setDateRange":
      draft.dateRange = action.payload;
      return;
    case "setClinicianId":
      draft.clinicianId = action.payload;
      return;
    case "setTeamViewClinician":
      draft.teamViewClinician = action.payload;
      return;
    case "startLoadingBillableHours":
      draft.isLoadingBillableHours = true;
      return;
    case "finishLoadingBillableHours":
      draft.isLoadingBillableHours = false;
      return;
    case "setClinicianManagers":
      draft.clinicianManagers = action.payload;
      return;
    case "setTeamClinicianIds":
      draft.teamClinicianIds = action.payload;
      return;
    case "setTeamCliniciansMap":
      draft.teamCliniciansMap = action.payload;
      return;
    case "setTeamBillableHoursWeeks":
      draft.teamBillableHoursWeeks = action.payload;
      return;
    case "startLoadingTeamBillableHours":
      draft.isLoadingTeamBillableHours = true;
      return;
    case "finishLoadingTeamBillableHours":
      draft.isLoadingTeamBillableHours = false;
      return;
    case "resetTeamBillableHours":
      draft.teamBillableHoursWeeks = {};
      return;

    case "startLoadingMBC":
      draft.isLoadingMBC = true;
      return;
    case "finishLoadingMBC":
      draft.isLoadingMBC = false;
      return;
    case "setMBCOverallAdherenceWeeks":
      draft.mbcOverallAdherenceWeeks = action.payload;
      return; 
  }
});

export default panelReducer;
