import * as React from "react";
import { Breadcrumb, Input } from "antd";

import { NavbarContainer } from "./styles";

export const LibraryNavBar = ({
  title,
  items,
  onReset,
  onSearch,
}: {
  title: string;
  items: string[];
  onReset: () => void;
  onSearch: (search: string) => void;
}) => {
  return (
    <NavbarContainer>
      <Breadcrumb separator=" // " style={{ cursor: "pointer" }}>
        <Breadcrumb.Item onClick={onReset}>{title}</Breadcrumb.Item>
        {items.map(item => (
          <Breadcrumb.Item key={item} children={item} />
        ))}
      </Breadcrumb>
      <div style={{ marginLeft: "auto" }}>
        <Input.Search
          placeholder="Search content library"
          enterButton="Search"
          onSearch={onSearch}
        />
      </div>
    </NavbarContainer>
  );
};
