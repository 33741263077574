import { styledStitches } from "@/app/design-system/styles/stitches.config";
import { Flex } from "./flex";

/**
 * @component Box
 * @description a <Box /> acts as a container
 * @prop p padding
 */

export const Box = styledStitches(Flex, {
  variants: {
    backgroundColor: {},

    p: {
      4: {
        p: "$space4",
      },
      8: {
        p: "$space8",
      },
      12: {
        p: "$space12",
      },
      16: {
        p: "$space16",
      },
      24: {
        p: "$space24",
      },
      28: {
        p: "$space28",
      },
    },

    py: {
      4: {
        py: "$space4",
      },
      8: {
        py: "$space8",
      },
      12: {
        py: "$space12",
      },
      16: {
        py: "$space16",
      },
      24: {
        py: "$space24",
      },
      28: {
        py: "$space28",
      },
    },
    px: {
      4: {
        px: "$space4",
      },
      8: {
        px: "$space8",
      },
      12: {
        px: "$space12",
      },
      16: {
        px: "$space16",
      },
      24: {
        px: "$space24",
      },
      28: {
        px: "$space28",
      },
    },

    br: {
      4: {
        borderRadius: 4,
      },
      8: {
        borderRadius: 8,
      },
    },
  },
});
