import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Checkbox } from "antd";
import { AppState } from "../../../state/models";
import { Row } from "../../_layout/Flex";
import {
  matchmakerActions,
  matchmakerOperations,
} from "../../../state/models/matchmaker";
import { copyToClipboard } from "../../_helpers";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { QueryClient, useQueryClient } from "react-query";
import { myConsultsQueryKey } from "../MatchingV2/use-get-my-consults";
import { Tooltip } from "antd";
import { ExclamationIcon } from "@/app/design-system/icons/components/exclamation-icon";
import { useShallowEqualSelector } from "../../_helpers/redux";
import { matchmakerUtils } from "../../../state/models/matchmaker";
const MatchmakerFooter = ({
  handleForceTriageToGroupsClick,
  showForceTriageButton,
}: {
  handleForceTriageToGroupsClick: () => void;
  showForceTriageButton: boolean;
}) => {
  const [stepIdx, isReferral, loading, clientEmail, canStep, currentUser, msp, consultTagIdsByWeight] =
    useSelector((state: AppState) => [
      state.matchmaker.stepIdx,
      state.matchmaker.draft.is_referral,
      state.matchmaker.loading,
      state.matchmaker.initData.consult
        ? state.matchmaker.initData.consult.client.email
        : state.matchmaker.clientEmail,
      matchmakerOperations.canStep(state.matchmaker),
      state.auth.currentUser,
      state.matchmaker.matchSlotPreferences["general"],
      state.matchmaker.consultTagMap,
    ]);
    const {
      excludedFits,
    } = useShallowEqualSelector(matchmakerUtils.getFilteredFits);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const stepTo = (idx: number) => dispatch(matchmakerActions.stepTo(idx)),
    closeMatchmaker = () => dispatch(matchmakerActions.closeMatchmaker());

  const handleSetIsReferralClick = (newIsReferral: boolean) => {
    dispatch(matchmakerActions.clearGroupsEnrollData());
    dispatch(matchmakerActions.patchMatchDraft({ is_referral: newIsReferral }));
  };

  const handleSaveMatchClick = () => {
    dispatch(matchmakerOperations.saveMatch(queryClient));
    queryClient.invalidateQueries([myConsultsQueryKey]);
  };

  const showGroups = currentUser?.clinician?.service_states?.includes("WA");

  const actions = [];
  // Left Side Actions
  if (!stepIdx) {
    actions.push(
      <Button key="cancel" onClick={closeMatchmaker}>
        Cancel
      </Button>,
    );
  }
  if (stepIdx) {
    actions.push(
      <Button key="back" onClick={() => stepTo(stepIdx - 1)}>
        <LeftOutlined /> Back
      </Button>,
    );
  }

  if (stepIdx === 0 && showForceTriageButton && showGroups) {
    actions.push(
      <Button
        key="skip to groups"
        type="ghost"
        onClick={handleForceTriageToGroupsClick}
      >
        Triage to Groups
      </Button>,
    );
  }

  // Action Splitter
  actions.push(<div key="spacer" style={{ flex: 1 }} />);
  // Right Side Actions

  if (stepIdx === 3) {
    actions.push(
      <Checkbox
        key="refer"
        checked={isReferral}
        style={{ marginLeft: "10px" }}
        onChange={(e: CheckboxChangeEvent) =>
          handleSetIsReferralClick(e.target.checked)
        }
      >
        Refer client out of Two Chairs Individual Therapy{" "}
      </Checkbox>,
    );
  }
  if (stepIdx > 0) {
    actions.push(
      <Button
        key="copy client email"
        onClick={() => copyToClipboard(clientEmail)}
      >
        Copy Client Email
      </Button>,
    );
  }
  if (stepIdx === 0 || stepIdx === 2) {
    let showTtip
    if (stepIdx === 0) {
      showTtip = msp && msp.slots.length > 0 && msp.slots.length < 10
    }
    else {
      showTtip = excludedFits && excludedFits.length < 30 && excludedFits.length > 0
    }
    actions.push(
      showTtip? 
      //@ts-ignore
      <Tooltip title={<div style={{display: "flex"}}><span style={{ marginRight: '8px', flexShrink: 0 }}><ExclamationIcon fill="white" exclamationFill="black"/></span> Please review the Time to Care guidance and make appropriate adjustments</div>}>
      <Button
        key="next"
        type="primary"
        onClick={() => stepTo(stepIdx + 1)}
        disabled={!canStep}
      >
        Next<RightOutlined />
      </Button></Tooltip>:<Button
        key="next"
        type="primary"
        onClick={() => stepTo(stepIdx + 1)}
        disabled={!canStep}
      >
        Next<RightOutlined />
      </Button>
    );
  }

  if (stepIdx === 1) {
    const showTtip= consultTagIdsByWeight && (consultTagIdsByWeight[1].length + consultTagIdsByWeight[100].length > 10)
    actions.push(
      showTtip? <Tooltip title={<div style={{display: "flex"}}><span style={{ marginRight: '8px', flexShrink: 0 }}><ExclamationIcon fill="black"/></span> Please review the Time to Care guidance and make appropriate adjustments</div>}>
      <Button
        key="save and continue"
        type="primary"
        onClick={() => stepTo(stepIdx + 1)}
      >
        Next <RightOutlined />
      </Button></Tooltip>:<Button
        key="save and continue"
        type="primary"
        onClick={() => stepTo(stepIdx + 1)}>
        Next <RightOutlined />
      </Button>
    );
  }
  if (stepIdx === 3) {
    actions.push(
      <Button
        key="submit"
        type="primary"
        onClick={handleSaveMatchClick}
        loading={loading.matchSave}
      >
        Submit
      </Button>,
    );
  }
  return (
    <Row layout={"start center"} style={{ backgroundColor: "white" }}>
      {actions}
    </Row>
  );
};

export default MatchmakerFooter;
