import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../../state/models";
import { matchmakerOperations } from "../../../../state/models/matchmaker";
import { Pad } from "../../../_layout/display";
import { Title } from "../../../_layout/display";
import { CheckCircleTwoTone, UserOutlined, WarningTwoTone } from '@ant-design/icons';
import { Input } from "antd";
import { $red, $green, $kpBlue } from "../../../../assets/colors";
import useDebounce from "../../../hooks/useDebounce";

export default () => {
  const [
    savedClientEmail,
    validEmailMapObj,
  ] = useSelector((state: AppState) => [
    state.matchmaker.clientEmail,
    state.matchmaker.validEmailMap[state.matchmaker.clientEmail],
  ]);

  const [clientEmail, setClientEmail] = useState(savedClientEmail);

  const debouncedClientEmail = useDebounce(clientEmail, 500);

  const dispatch = useDispatch();
  const dispatchClientEmail = useCallback(
    (email: string) =>
      savedClientEmail !== email &&
      dispatch(matchmakerOperations.setClientEmail(email)),
    [dispatch, savedClientEmail],
  );

  useEffect(() => {
    dispatchClientEmail(debouncedClientEmail);
  }, [dispatchClientEmail, debouncedClientEmail]);

  const isValidEmail = Boolean(validEmailMapObj?.clientId);
  const hasError = !isValidEmail && clientEmail;
  const isKpClient = Boolean(validEmailMapObj?.is_kp_referral);

  return (
    <Pad>
      <Title margin="5px" size="sm">
        Who are you matching?
      </Title>
      <Input
        placeholder="Client email address..."
        style={{ marginBottom: "5px" }}
        prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
        suffix={
          clientEmail ? (
            isValidEmail ? (
              <CheckCircleTwoTone twoToneColor={$green} />
            ) : (
              <WarningTwoTone twoToneColor={$red} />
            )
          ) : null
        }
        value={clientEmail}
        onChange={e => setClientEmail(e.target.value.trim())}
      />
      {hasError && (
        <div style={{ color: $red }}>This is not a recognized client email</div>
      )}
      {isKpClient && (
        <div style={{ color: $kpBlue }}>
          This is a Kaiser Permanente Referral
        </div>
      )}
      {(!hasError || !isKpClient) && <br />}
    </Pad>
  );
};
