import * as React from "react";

export default () => {
  return (
    <div style={{ fontSize: "14px" }}>
      <p dir="ltr">
        <span>Substance of choice:</span>
      </p>
      <ol>
        <li dir="ltr">
          <span>Impaired Control</span>
          <ul>
            <li dir="ltr">
              Taking the substance in larger amounts or for longer than you're
              meant to
            </li>
            <li dir="ltr">
              Wanting to cut down or stop using the substance but not managing
              to
            </li>
            <li dir="ltr">
              Spending a lot of time getting, using, or recovering from use of
              the substance
            </li>
            <li dir="ltr">Having cravings and urges to use the substance</li>
          </ul>
        </li>
        <li dir="ltr">
          <span>Social Impairment&nbsp;</span>
          <ul>
            <li dir="ltr">
              Not managing to fulfill major role obligations at work, home, or
              school because of recurrent substance use
            </li>
            <li dir="ltr">
              Continuing to use, even when it causes persistent or recurrent
              social or interpersonal problems (like in relationships)
            </li>
            <li dir="ltr">
              Giving up important social, occupational, or recreational
              activities because of substance use. (ie, withdrawing from family
              activities or hobbies to use).
            </li>
          </ul>
        </li>
        <li dir="ltr">
          <span>Risky Use</span>
          <ul>
            <li dir="ltr">
              Using the substance again and again, even when it puts you in
              danger (ie, driving while intoxicated).
            </li>
            <li dir="ltr">
              Continuing to use, even when you know you have a physical or
              psychological problem that could have been caused or made worse by
              the substance (ie, liver damage or anger issues)
            </li>
          </ul>
        </li>
        <li dir="ltr">
          <span>Tolerance and Withdrawal</span>
          <ul>
            <li dir="ltr">
              Needing more of the substance to get the effect you want
              (tolerance).
            </li>
            <li dir="ltr">
              Having a development of withdrawal symptoms, which is relieved by
              taking more of the substance (Withdrawal symptoms vary greatly
              across the classes of substances)
            </li>
          </ul>
        </li>
      </ol>
    </div>
  );
};
