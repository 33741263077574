import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import { keyframes, styledStitches } from "../styles/stitches.config";
import { VN_COMPONENT_OVERLAY_INDEX } from "../styles/config/css-constants";

const slideUpAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(-2px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const slideRightAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(2px)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
});

const slideDownAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(2px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const slideLeftAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(-2px)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
});

export const TooltipRoot = styledStitches(TooltipPrimitive.Root);
export const TooltipPortal = styledStitches(TooltipPrimitive.Portal);

export const TooltipContent = styledStitches(TooltipPrimitive.Content, {
  br: 4,
  p: "6px 8px",
  fontSize: 16,
  background: "rgba(0, 0, 0, 0.85)",
  color: "$neutral0",
  boxShadow:
    "hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px",
  "@media (prefers-reduced-motion: no-preference)": {
    animationDuration: "400ms",
    animationTimingFunction: "cubic-bezier(0.16, 1, 0.3, 1)",
    animationFillMode: "forwards",
    willChange: "transform, opacity",
    '&[data-state="delayed-open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideUpAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade },
    },
  },
  zIndex: VN_COMPONENT_OVERLAY_INDEX + 3,
});

export const TooltipTrigger = styledStitches(
  TooltipPrimitive.TooltipTrigger,
  {},
);

export const TooltipArrow = styledStitches(TooltipPrimitive.Arrow, {
  fill: "rgba(0, 0, 0, 0.75)",
});

export const TooltipProvider = TooltipPrimitive.Provider;
