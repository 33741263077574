import {
  GroupTherapyMbcEventDataFragment,
  GroupTherapyMbcClientGroupEventDataFragment,
  GroupTherapyMbcScoreDataFragment,
  GroupTherapyMbcEmsDataFragment,
  GroupTherapyMbcEventsQuery,
} from "@/graphql/generated";
import { DateTime } from "luxon";
import {
  BaselineScores,
  ParsedClientEventMbcScores,
  ParsedEventGroupAndClientMbcScores,
  ParsedGroupEventMbcScores,
  AllParsedMBCScoreData,
  TotalScores,
  ClientResponseTotals,
} from "./use-group-therapy-mbc-events.interface";

export const selectGroupTherapyMbcEventScores = (
  groupTherapyMbcData: GroupTherapyMbcEventsQuery,
): AllParsedMBCScoreData => {
  const events = getValidEvents(groupTherapyMbcData);
  if (!events || events?.length === 0) {
    return {};
  }

  let sessionNumber = 0;
  const parsedGroupAndClientMbcScores = events.map(
    (event: GroupTherapyMbcEventDataFragment) => {
      sessionNumber++;
      return getParsedClientAndGroupMbcEvent(event, sessionNumber);
    },
  );

  return {
    eventScores: parsedGroupAndClientMbcScores,
    baseline: getCurrentBaselines(parsedGroupAndClientMbcScores),
    clientResponseTotals: getClientResponseTotals(events),
  };
};

const getEvent = (
  events?: ParsedEventGroupAndClientMbcScores[] | null,
  selectedEventId?: string,
) => {
  if (!events || !selectedEventId || !events.length) {
    return { eventScores: null, eventIndex: -1 };
  }

  const eventIndex = events.findIndex(
    (event) => event.eventId === selectedEventId,
  );
  return { eventScores: [events[eventIndex]], eventIndex };
};

/*
Gets all data from one event - returns AllParsedMBCScoreData interface with
eventScores populated as an array with only one event object
*/
export const getEventData = (
  allData?: AllParsedMBCScoreData,
  selectedEventId?: string,
): { eventData: AllParsedMBCScoreData | null; isFirstSession: boolean } => {
  const { eventScores, eventIndex } = getEvent(
    allData?.eventScores,
    selectedEventId,
  );

  const eventData = {
    eventScores: eventScores,
    baseline: allData?.baseline,
    clientResponseTotals: allData?.clientResponseTotals,
  };

  return {
    eventData: eventData ? eventData : null,
    isFirstSession: eventIndex === 0 ? true : false,
  };
};

/**
 * @function getValidEvents
 * @description  Checks for validity of events, only returning non-canceled events
 * in the past that have sent out MBC assessments. Returns null if no events.
 * @param {GroupTherapyMbcData} groupTherapyMbcData initial data returned from gql
 */
export const getValidEvents = (
  groupTherapyMbcData: GroupTherapyMbcEventsQuery,
): GroupTherapyMbcEventDataFragment[] | null => {
  const group = groupTherapyMbcData?.group ? groupTherapyMbcData?.group : null;
  const events = group ? group[0].events : null;
  if (!events) {
    return null;
  }
  const now = DateTime.now();

  const filteredEvents = events.filter(
    (event: GroupTherapyMbcEventDataFragment) =>
      event.appointmentStatus !== "canceled" &&
      DateTime.fromISO(event?.startTime ?? "") <= now,
  );
  return filteredEvents;
};

/**
 * @function getParsedClientAndGroupMbcEvent
 * @description Parses a single event, getting an array of all parsed client MBC scores
 * ParsedClientEventMbcScores, and the group's event average MBC scores ParsedGroupEventMbcScores.
 * @param {GroupTherapyMbcEventDataFragment} event all data for one event from gql
 */
const getParsedClientAndGroupMbcEvent = (
  event: GroupTherapyMbcEventDataFragment,
  sessionNumber: number,
): ParsedEventGroupAndClientMbcScores => {
  const { eventId, startTime: eventStartTime, clientGroupEvents } = event;
  if (!clientGroupEvents) {
    return {
      eventId: eventId,
      eventDate: eventStartTime,
      sessionNumber: sessionNumber,
    };
  }

  const eventStart = DateTime.fromISO(event?.startTime ?? "");

  const { parsedClients, totalsAfterClients } = getParsedClientGroupEvents(
    clientGroupEvents,
    eventStart,
  );

  const gadScore = getAvg(
    totalsAfterClients.totaledGAD,
    totalsAfterClients.totalAnswered,
  );
  const phqScore = getAvg(
    totalsAfterClients.totaledPHQ,
    totalsAfterClients.totalAnswered,
  );

  const cohesionScore =
    totalsAfterClients.totaledCohesion !== 0
      ? getAvg(
          totalsAfterClients.totaledCohesion,
          totalsAfterClients.totalAnswered,
        )
      : null;

  const parsedGroupEvent: ParsedGroupEventMbcScores = {
    totalSent: totalsAfterClients.totalSent,
    totalAnswered: totalsAfterClients.totalAnswered,
    isCohesion: cohesionScore ? true : false,
    gad: gadScore,
    phq: phqScore,
    cohesion: cohesionScore,
  };

  return {
    eventId: eventId,
    eventDate: eventStartTime,
    sessionNumber: sessionNumber,
    clientScores: getSortedClients(parsedClients),
    groupEventScores: parsedGroupEvent,
  };
};

/**
 * @function getCurrentBaselines
 * @description Takes in all ParsedEventGroupAndClientMbcScores
  and returns baseline scores - ie the first session's score for that metric.
  (For PHQ and GAD this should be session one, for group_cohesion session 3).
  Implemented such that even if our cadence changes it will still be the first instance of that score
 * @param {ParsedEventGroupAndClientMbcScores[]} groupMBCScores parsed group MBC scores for all events that have passed
 */
const getCurrentBaselines = (
  groupMBCScores: ParsedEventGroupAndClientMbcScores[],
): BaselineScores | null => {
  let baselineScores: BaselineScores | null = null;

  for (let index in groupMBCScores) {
    const scores = groupMBCScores[index].groupEventScores;

    if (scores) {
      if (!baselineScores) {
        baselineScores = {
          gad: scores.gad,
          phq: scores.phq,
          cohesion: scores.cohesion,
        };
      } else if (
        baselineScores &&
        baselineScores.cohesion === null &&
        scores.cohesion !== null
      ) {
        baselineScores.cohesion = scores.cohesion;
        return baselineScores;
      }
    }
  }
  return baselineScores;
};

/**
 * @function getParsedClientGroupEvents
 * @description This method takes in a list of GroupTherapyMbcClientGroupEvents
  and returns a list of ParsedClientEventMbcScores and the TotalScores,
    which count toward the group's event score averages.
 * @param {GroupTherapyMbcClientGroupEventDataFragment[]} clientGroupEvents all gql client group event data for one event
 * @param {DateTime} eventStart event start time
 */
const getParsedClientGroupEvents = (
  clientGroupEvents: GroupTherapyMbcClientGroupEventDataFragment[],
  eventStart: DateTime,
): {
  parsedClients: ParsedClientEventMbcScores[];
  totalsAfterClients: TotalScores;
} => {
  let totalScores: TotalScores = {
    totalAnswered: 0,
    totalSent: 0,
    totaledGAD: 0,
    totaledPHQ: 0,
    totaledCohesion: 0,
  };
  const parsedClientMbcScores: ParsedClientEventMbcScores[] = [];

  const filteredClientGroupEvents = clientGroupEvents.filter(
    (cge) => !cge?.client?.isTestClient && cge.client,
  );

  filteredClientGroupEvents.forEach((clientGroupEvent) => {
    // initialize a parsedClient object
    const parsedClient: ParsedClientEventMbcScores = {
      clientId: clientGroupEvent.client?.clientId,
      clientName: `${clientGroupEvent?.client?.clientFirstName} ${clientGroupEvent?.client?.clientLastName}`,
      isComplete: true,
      isEnrolled: true,
      endorsedSI: false,
    };

    // if client is unenrolled don't count toward number of surveys sent out
    const unenrolledDate = clientGroupEvent?.client?.clientGroup
      ? clientGroupEvent?.client?.clientGroup[0].clientUnenrolledDate
      : null;
    if (unenrolledDate && DateTime.fromISO(unenrolledDate) <= eventStart) {
      parsedClient.isComplete = false;
      parsedClient.isEnrolled = false;
      parsedClientMbcScores.push(parsedClient);
      return;
    }

    /*
      Not every client has an EMS, or eventMBCStatus, for every event.
      For ex: if they're unenrolled or there was a bug they may not
      have received one. They also could have received multiple.
      This method returns one EMS or null if there are none.
    */
    const ems = getEms(clientGroupEvent.EMSes);
    if (ems) {
      totalScores.totalSent++;

      if (!ems.emsRespondedAt || !ems.scores) {
        parsedClient.isComplete = false;
        parsedClientMbcScores.push(parsedClient);
        return;
      }

      const filteredScores = filterScoresWithBug(ems.scores);

      const parsedClientAfterScore = parseClientScores(
        filteredScores,
        parsedClient,
      );
      parsedClientMbcScores.push(parsedClientAfterScore);

      // add to total tally
      totalScores.totalAnswered += filteredScores.length >= 1 ? 1 : 0;

      filteredScores.forEach((score) => {
        if (score.scoreName === "phq9") {
          totalScores.totaledPHQ += score.value;
        } else if (score.scoreName === "gad7") {
          totalScores.totaledGAD += score.value;
        } else if (score.scoreName === "group_cohesion") {
          totalScores.totaledCohesion += score.value;
        }
      });
      return;
    } else {
      parsedClient.isComplete = false;
      parsedClientMbcScores.push(parsedClient);
      return;
    }
  });

  return {
    parsedClients: parsedClientMbcScores,
    totalsAfterClients: totalScores,
  };
};

/**
 * @function parseClientScores
 * @description Parses raw scores into a ParsedClientEventMbcScores object and
  increments TotalScores to contribute to average
 */
const parseClientScores = (
  scores: GroupTherapyMbcScoreDataFragment[],
  parsedClient: ParsedClientEventMbcScores,
): ParsedClientEventMbcScores => {
  const filteredScores = filterScoresWithBug(scores);

  filteredScores.forEach((score) => {
    if (score.scoreName === "phq9") {
      parsedClient.PHQ = score.value;
    } else if (score.scoreName === "gad7") {
      parsedClient.GAD = score.value;
    } else if (score.scoreName === "group_cohesion") {
      parsedClient.cohesion = score.value;
    } else if (score.scoreName === "si") {
      parsedClient.endorsedSI = score.endorsedSI;
    }
  });
  return parsedClient;
};

/**
 * @function getClientResponseTotals
 * @description Parses all clientGroupEvents in each Event to
 * find sums surveys sent and responded to for each client.
 */
const getClientResponseTotals = (
  events: GroupTherapyMbcEventDataFragment[],
): ClientResponseTotals[] | null => {
  if (events.length === 0) {
    return null;
  }

  const clientResponseTotals: ClientResponseTotals[] = [];

  events.forEach((event: GroupTherapyMbcEventDataFragment) => {
    event?.clientGroupEvents.forEach(
      (clientGroupEvent: GroupTherapyMbcClientGroupEventDataFragment) => {
        const clientId = clientGroupEvent?.client?.clientId;
        const ems = getEms(clientGroupEvent.EMSes); // eventMBCStatus

        // if no ems, no assessments were sent
        if (ems) {
          // if client already exists, find to add to total
          const previousClientTotal = findClientInTotals(
            clientId,
            clientResponseTotals,
          );

          // if not, initialize new empty client
          const newClientTotal = previousClientTotal || {
            clientId: clientId,
            totalResponded: 0,
            totalSent: 0,
          };

          // SI is an optional addendum to PHQ, not a new survey
          const numOfAssessments = ems.assessments
            ? ems.assessments.split("-").filter((survey) => {
                return survey !== "si";
              }).length
            : 2; // there should always be at least 2 assessments

          const scores = ems.scores
            ? ems.scores.filter((score) => {
                return score.scoreName !== "si";
              }).length
            : 0;

          newClientTotal.totalResponded += scores;
          newClientTotal.totalSent += numOfAssessments;

          if (!previousClientTotal) {
            clientResponseTotals.push(newClientTotal);
          } else {
            const indexReplace =
              clientResponseTotals.indexOf(previousClientTotal);
            clientResponseTotals[indexReplace] = newClientTotal;
          }
        }
      },
    );
  });
  return clientResponseTotals ? clientResponseTotals : null;
};

const findClientInTotals = (
  clientId: number,
  totals: ClientResponseTotals[],
): ClientResponseTotals | null => {
  if (!totals) {
    return null;
  }
  const clientTotal = totals.find((total) => {
    return total.clientId === clientId;
  });
  return clientTotal ? clientTotal : null;
};

const getAvg = (total: number, num: number): number | null => {
  if (num === 0) {
    return null;
  }
  return total / num;
};

/**
 * @function filterScoresWithBug
 * @function getEms
 * @description these functions are necessary because when we first released groups there was a bug
 * which produced many duplicate objects, including ehr_assessmentscore and ehr_eventmbcstatus.
 * We only want one of each.
 * May be obselete in future
 */
const filterScoresWithBug = (scores: GroupTherapyMbcScoreDataFragment[]) => {
  return scores.filter((value, index) => {
    const _value = JSON.stringify(value);
    return (
      index ===
      scores.findIndex((obj) => {
        return JSON.stringify(obj) === _value;
      })
    );
  });
};

// This method is because early on a bug was introduced where
// multiple ems, or EventMBCStatuses were created. This way we
// only return one.
const getEms = (
  EMSes: GroupTherapyMbcEmsDataFragment[] | [] | null | undefined,
): GroupTherapyMbcEmsDataFragment | null => {
  if (!EMSes || EMSes.length === 0) {
    return null;
  }
  return EMSes.filter((ems) => ems.notificationEmailId)[0];
};

const getSortedClients = (
  clientScores: ParsedClientEventMbcScores[] | undefined,
) => {
  return clientScores
    ? clientScores.sort((a, b) => {
        return a === b ? 0 : a ? -1 : 1;
      })
    : [];
};
