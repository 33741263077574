import produce from "immer";
import {
  actionFactory,
  ActionUnion,
  payloadAction,
  simpleAction,
} from "reductser";
import { uniqBy } from "lodash-es";
import {
  ClientExtended,
  Clinician,
  ClinicianHold,
  ClinicianSlot,
  ClinicianSlotAvailability,
  ClinicianUtilization,
  CouplesMatchAvailability,
  IdMap,
  ManualCapacity,
  PendingMatch,
  ResolvedMatch,
  ScheduleItem,
} from "../../../api/types";
import { assertUnreachable } from "../types";
import {
  ClinicianManualCapacityV2Data,
  CouplesMatchAvailabilityMap,
} from "../../../app/slot-tool/types";

const actionMap = {
  completedClinicianLoad: simpleAction(),
  fetchActiveHolds: simpleAction(),
  fetchCapacities: simpleAction(),
  fetchClinicians: simpleAction(),
  fetchManualSlots: simpleAction(),
  fetchSlotAvailabilities: simpleAction(),
  loadActiveHolds: payloadAction<ClinicianHold[]>(),
  loadClinician: payloadAction<Clinician>(),
  loadClinicianManualSlots: payloadAction<{
    clinicianId: number;
    manualSlots: ClinicianSlot[];
  }>(),
  loadClinicians: payloadAction<Clinician[]>(),
  loadManualSlots: payloadAction<ClinicianSlot[]>(),
  loadPendingMatches_v2: payloadAction<PendingMatch[]>(),
  loadRecentCapacities: payloadAction<ManualCapacity[]>(),
  loadRecentCapacitiesV2: payloadAction<ClinicianManualCapacityV2Data[]>(),
  loadRecentMatches: payloadAction<ResolvedMatch[]>(),
  loadSchedules: payloadAction<ScheduleItem[]>(),
  loadSlotAvailabilities: payloadAction<ClinicianSlotAvailability[]>(),
  loadUtilization: payloadAction<ClinicianUtilization>(),
  loadUtilizations: payloadAction<ClinicianUtilization[]>(),
  patchClinician: payloadAction<{ id: number; patch: Partial<Clinician> }>(),
  setConsentSurveyModalData: payloadAction<string | ConsentSurveyModalData>(),
  setConsentSurveyModalIsOpen: payloadAction<boolean>(),
  setCouplesMatchAvailability: payloadAction<{
    [id: number]: CouplesMatchAvailability;
  }>(),
  setLastUpdated: simpleAction(),
  setLoadingStartMatching: payloadAction<boolean>(),
  setTagModalClinicianId: payloadAction<number | null>(),
  setUseTeamView: payloadAction<boolean>(),
};

export const clinicianActions = actionFactory(actionMap, "CLINICIANS");

export type ClinicianAction = ActionUnion<typeof clinicianActions>;

export interface ConsentSurveyModalData {
  isKpReferral: boolean;
  clients: { client: ClientExtended; token: string }[];
}

export interface ClinicianState {
  activeHolds: ClinicianHold[];
  clinicianMap: { [id: number]: Clinician };
  clinicianToRecentMatchesMap: { [id: number]: number[] };
  clinicianToSlotMap: { [id: number]: ClinicianSlot[] };
  consentSurveyModalData: string | ConsentSurveyModalData;
  consentSurveyModalIsOpen: boolean;
  couplesMatchAvailability: CouplesMatchAvailabilityMap;
  couplesSlotToClinicianMap: { [dayTime: string]: ClinicianSlot[] };
  ids: number[];
  lastUpdated?: number;
  loadingActiveHolds: boolean;
  loadingCapacities: boolean;
  loadingClinicians: boolean;
  loadingManualSlots: boolean;
  loadingSlotAvailabilities: boolean;
  loadingStartMatching: boolean;
  manualSlotsMap: IdMap<ClinicianSlot[]>;
  pendingMatchesMap: { [id: number]: PendingMatch[] };
  recentCapacityMap: { [id: number]: ManualCapacity[] };
  recentCapacityV2Map: { [id: number]: ClinicianManualCapacityV2Data[] };
  recentMatchesMap: { [matchId: number]: ResolvedMatch };
  scheduleMap: { [id: number]: ScheduleItem[] };
  slotToClinicianMap: { [dayTime: string]: ClinicianSlot[] };
  tagModalClinicianId: number | null;
  useTeamView: boolean;
  utilizationMap: { [id: number]: ClinicianUtilization };
}

function getInitialState(): ClinicianState {
  return {
    activeHolds: [],
    clinicianMap: {},
    clinicianToRecentMatchesMap: {},
    clinicianToSlotMap: {},
    consentSurveyModalData: { isKpReferral: false, clients: [] },
    consentSurveyModalIsOpen: false,
    couplesMatchAvailability: {},
    couplesSlotToClinicianMap: {},
    ids: [],
    lastUpdated: undefined,
    loadingActiveHolds: false,
    loadingCapacities: false,
    loadingClinicians: false,
    loadingManualSlots: false,
    loadingSlotAvailabilities: false,
    loadingStartMatching: false,
    manualSlotsMap: {},
    pendingMatchesMap: {},
    recentCapacityMap: {},
    recentCapacityV2Map: {},
    recentMatchesMap: {},
    scheduleMap: {},
    slotToClinicianMap: {},
    tagModalClinicianId: null,
    useTeamView: true,
    utilizationMap: {},
  };
}

const cliniciansReducer = (
  state = getInitialState(),
  action: ClinicianAction,
) =>
  produce(state, (draft) => {
    if (action.reducer === "CLINICIANS") {
      const loadClinician = (clinician: Clinician) => {
        const { id } = clinician;
        if (draft.ids.indexOf(id) === -1) {
          draft.ids.push(id);
        }

        draft.clinicianMap[id] = { ...draft.clinicianMap[id], ...clinician };

        if (!draft.pendingMatchesMap[id]) {
          draft.pendingMatchesMap[id] = [];
        }
      };

      const loadScheduleItem = (item: ScheduleItem) => {
        const { clinician_id } = item;
        draft.scheduleMap[clinician_id] = draft.scheduleMap[clinician_id] || [];
        draft.scheduleMap[clinician_id].push(item);
      };
      switch (action.type) {
        case "fetchClinicians":
          draft.loadingClinicians = true;
          return;
        case "loadClinicians": {
          action.payload.forEach((clinician) => loadClinician(clinician));
          return;
        }
        case "loadClinician": {
          loadClinician(action.payload);
          return;
        }
        case "patchClinician": {
          const { id, patch } = action.payload;
          const currentClinician = draft.clinicianMap[id];
          loadClinician({
            ...currentClinician,
            ...patch,
          });
          return;
        }
        case "completedClinicianLoad":
          draft.loadingClinicians = false;
          return;
        case "loadSchedules":
          for (const cId in draft.scheduleMap) {
            if (cId) {
              draft.scheduleMap[cId] = [];
            }
          }
          action.payload.forEach((scheduleItem) =>
            loadScheduleItem(scheduleItem),
          );
          return;
        case "loadUtilization": {
          draft.utilizationMap[action.payload.clinician_id] = action.payload;
          return;
        }
        case "loadUtilizations": {
          action.payload.forEach(
            (util: ClinicianUtilization) =>
              (draft.utilizationMap[util.clinician_id] = util),
          );
          return;
        }
        case "loadPendingMatches_v2": {
          action.payload.forEach((pendingMatch: PendingMatch) => {
            if (pendingMatch.selected_fit) {
              draft.pendingMatchesMap[pendingMatch.selected_fit.clinician] =
                uniqBy(
                  [
                    ...draft.pendingMatchesMap[
                      pendingMatch.selected_fit.clinician
                    ],
                    pendingMatch,
                  ],
                  "id",
                );
            }
          });
          return;
        }
        case "loadRecentMatches": {
          const clinicianToMatchesMap = {};
          action.payload.forEach((match: ResolvedMatch) => {
            draft.recentMatchesMap[match.id] = match;
            if (!clinicianToMatchesMap[match.selected_fit.clinician]) {
              clinicianToMatchesMap[match.selected_fit.clinician] = [match.id];
            } else {
              clinicianToMatchesMap[match.selected_fit.clinician].push(
                match.id,
              );
            }
          });
          draft.clinicianToRecentMatchesMap = clinicianToMatchesMap;
          return;
        }
        case "fetchActiveHolds":
          draft.loadingActiveHolds = true;
          return;
        case "loadActiveHolds":
          draft.loadingActiveHolds = false;
          draft.activeHolds = action.payload;
          return;
        case "fetchCapacities":
          draft.loadingCapacities = true;
          return;
        case "loadRecentCapacities":
          draft.recentCapacityMap = {};
          action.payload.forEach((cap: ManualCapacity) => {
            const clinicianId = cap.clinician as number;
            const capList = draft.recentCapacityMap[clinicianId] || [];
            capList.push(cap);
            draft.recentCapacityMap[clinicianId] = capList;
          });
          return;
        case "loadRecentCapacitiesV2":
          draft.recentCapacityV2Map = {};
          action.payload.forEach((cap: ClinicianManualCapacityV2Data) => {
            const clinicianId = cap.clinician as number;
            const capList = draft.recentCapacityV2Map[clinicianId] || [];
            capList.push(cap);
            draft.recentCapacityV2Map[clinicianId] = capList;
          });
          return;
        case "fetchSlotAvailabilities":
          draft.loadingSlotAvailabilities = true;
          return;
        case "loadSlotAvailabilities": {
          draft.loadingSlotAvailabilities = false;
          action.payload.forEach((avail: ClinicianSlotAvailability) => {
            draft.clinicianToSlotMap[avail.clinician_id] = avail.open_slots;
            avail.open_slots.forEach((slot) => {
              let key = slot.day_of_week + slot.start_time;
              if (!draft.slotToClinicianMap[key]) {
                draft.slotToClinicianMap[key] = [];
              }
              draft.slotToClinicianMap[key].push(slot);
            });
          });
          return;
        }
        case "fetchManualSlots":
          draft.loadingManualSlots = true;
          return;
        case "loadManualSlots": {
          draft.manualSlotsMap = {};
          action.payload.forEach((manualSlot) => {
            if (manualSlot.clinician_id in draft.manualSlotsMap) {
              draft.manualSlotsMap[manualSlot.clinician_id].push(manualSlot);
            } else {
              draft.manualSlotsMap[manualSlot.clinician_id] = [manualSlot];
            }
          });
          draft.loadingManualSlots = false;
          return;
        }
        case "loadClinicianManualSlots": {
          draft.manualSlotsMap[action.payload.clinicianId] =
            action.payload.manualSlots;
          return;
        }
        case "setLastUpdated":
          draft.lastUpdated = Date.now();
          return;
        case "setUseTeamView":
          draft.useTeamView = action.payload;
          return;
        case "setLoadingStartMatching":
          draft.loadingStartMatching = action.payload;
          break;
        case "setTagModalClinicianId":
          draft.tagModalClinicianId = action.payload;
          break;
        case "setConsentSurveyModalIsOpen":
          draft.consentSurveyModalIsOpen = action.payload;
          break;
        case "setConsentSurveyModalData":
          draft.consentSurveyModalData = action.payload;
          break;
        case "setCouplesMatchAvailability":
          draft.couplesMatchAvailability = {
            ...draft.couplesMatchAvailability,
            ...action.payload,
          };
          Object.values(action.payload).forEach((avail) => {
            avail.open_slots.forEach((slot) => {
              let key = slot.day_of_week + slot.start_time;
              if (!draft.couplesSlotToClinicianMap[key]) {
                draft.couplesSlotToClinicianMap[key] = [];
              }
              draft.couplesSlotToClinicianMap[key].push(slot);
            });
          });
          break;
        default:
          assertUnreachable(action);
      }
    }
  });

export default cliniciansReducer;
