import { TooltipProps } from "recharts";
import { GraphDataPointData } from "../graph.types";

const getValueToDisplay = (value: number | null | undefined) => {
  if (value) {
    return value % 1 ? (value).toFixed(2) : value;
  }
  return 'n/a';
};

// mimics the types TooltipProps expects
export type ValueType = number | string | Array<number | string>;
export type NameType = number | string;

export const GraphTooltip = (props: TooltipProps<ValueType, NameType>) => {
  const { active, payload } = props;
  if (active && payload?.length) {
    const dataPoint: GraphDataPointData = payload[0].payload;
    const {
      value,
      tooltipLabel,
      status,
      statusColor,
    } = dataPoint;
    const hasStatus = status && statusColor;
    return (
      <div
        style={{
          backgroundColor: "#fff",
          borderRadius: 8,
          padding: '8px 8px 0 8px',
          display: "flex",
          flexDirection: "column",
          boxShadow: "rgba(0, 0, 0, 0.3) 0 2px 10px",
          maxWidth: 400,
        }}
      >
        <p style={{ fontWeight: "bold" }}>{tooltipLabel}</p>
        <p>Score: {getValueToDisplay(value)}</p>
        {hasStatus && (
          <div style={{ display: "flex", flexDirection: "column", paddingBottom: 10 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  height: 18,
                  width: 18,
                  marginRight: 12,
                  marginLeft: 4,
                  borderRadius: 4,
                  backgroundColor: statusColor,
                }}
              />
              {status}
            </div>
          </div>
        )}
      </div>
    );
  }
  return null;
};
