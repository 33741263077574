import produce from "immer";
import { actionFactory, ActionUnion, payloadAction } from "reductser";

import { CoupleForMatch } from "../../../api/types";
import { assertUnreachable } from "../types";

const actionMap = {
  setCouplesFormResponseData: payloadAction<CoupleForMatch>(),
};

export const couplesIntakeResponseAction = actionFactory(
  actionMap,
  "COUPLESINTAKE",
);

export type CouplesIntakeResponseAction = ActionUnion<
  typeof couplesIntakeResponseAction
>;

export interface CouplesIntakeResponseState {
  couplesFormResponseData: CoupleForMatch | null;
}

const getInitialState = (): CouplesIntakeResponseState => ({
  couplesFormResponseData: null,
});

const reducer = (
  state = getInitialState(),
  action: CouplesIntakeResponseAction,
) =>
  produce(state, draft => {
    if (action.reducer === "COUPLESINTAKE") {
      switch (action.type) {
        case "setCouplesFormResponseData":
          draft.couplesFormResponseData = action.payload;
          return;
        default:
          assertUnreachable(action as never);
          return;
      }
    }
  });

export default reducer;
