import { EventApiData } from "@/app/api/use-my-clients-overview";
import { yupResolver } from "@hookform/resolvers/yup";
import { DateTime } from "luxon";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { RecurringSessionsOptions } from "../../session-actions.constants";
import { SessionRecurrenceOption } from "./upsert-session-form.constants";

export enum UpsertSessionFieldNames {
  SESSION_DATE = "sessionDate",
  SESSION_TIME = "sessionTime",
  SESSION_FORMAT = "sessionFormat",
  RECURRENCE = "recurrence",
  SESSIONS_TO_UPDATE = "sessionsToUpdate",
  CLINICIAN = "clinician",
}

export const getDefaultFormValues = (
  mode: "edit" | "add",
  event: EventApiData | undefined,
  timezone: string,
): UpsertSessionFormFields => {
  const isEditMode = mode === "edit";

  const sessionDate =
    isEditMode && event?.startTime ? event.startTime : DateTime.now().toISO();

  const sessionTime =
    isEditMode && event?.startTime
      ? DateTime.fromISO(event.startTime, { zone: timezone }).toLocaleString(
          DateTime.TIME_24_SIMPLE,
        )
      : "Select";

  const deliveryTypeToSessionFormatMap = {
    in_person: "In person",
    teletherapy: "Virtual",
  };

  const sessionFormat =
    isEditMode && event?.deliveryType
      ? deliveryTypeToSessionFormatMap[event?.deliveryType]
      : "Select";

  const recurrence =
    isEditMode && event?.recurrenceData
      ? event.recurrenceData.recurrence
      : SessionRecurrenceOption.DOES_NOT_REPEAT;

  return {
    recurrence,
    sessionTime,
    sessionDate,
    sessionFormat,
    ...(mode === "edit" && {
      sessionsToUpdate: RecurringSessionsOptions.THIS_SESSION_ONLY,
    }),
  };
};

export interface UpsertSessionFormFields {
  recurrence: string;
  sessionTime: string;
  sessionDate: string;
  sessionFormat: string;
  sessionsToUpdate?: string;
  clinician?: string;
}

export const useUpsertSessionForm = (
  event: EventApiData | undefined,
  mode: "edit" | "add",
  timezone: string,
) => {
  const schema =
    mode === "add"
      ? yup.object({
          [UpsertSessionFieldNames.SESSION_DATE]: yup.string().required(),
          [UpsertSessionFieldNames.SESSION_TIME]: yup
            .string()
            .not(["Select"])
            .required(),
          [UpsertSessionFieldNames.RECURRENCE]: yup
            .string()
            .not(["Select"])
            .required(),
        })
      : yup.object({
          [UpsertSessionFieldNames.SESSIONS_TO_UPDATE]: yup.string(),
          [UpsertSessionFieldNames.SESSION_DATE]: yup.string(),
          [UpsertSessionFieldNames.SESSION_TIME]: yup.string(),
          [UpsertSessionFieldNames.RECURRENCE]: yup.string().nullable(),
        });

  const initialEventValues = getDefaultFormValues(mode, event, timezone);

  const methods = useForm<UpsertSessionFormFields>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      ...initialEventValues,
    },
  });

  const { handleSubmit, control, getValues } = methods;

  return {
    methods,
    handleSubmit,
    getValues,
    control,
  };
};
