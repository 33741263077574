import { EventApiData } from "@/app/api/use-my-clients-overview";
import { DateTime } from "luxon";
import { Control, useWatch } from "react-hook-form";
import { useQuery } from "react-query";
import api from "../../../../../../../../../../../../api";
import {
  AppointmentStatus,
  Day,
  Recurrence,
} from "../../../../../../../../../../../../api/types";
import { ReservationType } from "../../../../../../../../../../../slot-tool/types";
import { UpsertSessionFormFields } from "../use-upsert-session-form";

export type FutureSessionConflictEvent = {
  clinician: number;
  id: string;
  event_type: string;
  appointment_status: AppointmentStatus;
  client: {
    id: number;
    email: string;
    first_name: string;
    last_name: string;
  } | null;
  start_time: string;
  end_time: string;
  block_description: string;
  service_type: string;
};

export type FutureSessionConflictRecurrence = {
  id: string;
  slot_recurrence: Recurrence;
  slot_time_of_day: string;
  start_date: string;
  end_date: string;
  slot_day_of_week: Day;
  slot_type: ReservationType;
  client: {
    id: number;
    email: string;
    first_name: string;
    last_name: string;
  } | null;
  valid_until_date: string;
};

export interface GetFutureSessionConflictsResponse {
  events: FutureSessionConflictEvent[];
  recurrences: FutureSessionConflictRecurrence[];
  can_schedule_event: boolean;
  can_schedule_recurrence: boolean;
}

const getFutureSessionConflicts = async (
  clinicianId: number,
  sessionDateTime: DateTime,
  recurrence?: string,
): Promise<GetFutureSessionConflictsResponse> => {
  try {
    const { data } = await api.get(
      "/api/care_platform/events/v1/getConflicts/",
      {
        params: {
          clinician_id: clinicianId,
          start_time: sessionDateTime.toISO(),
          recurrence: recurrence === "none" ? undefined : recurrence,
        },
      },
    );

    return data;
  } catch (e) {
    throw e;
  }
};

const GET_FUTURE_SESSION_CONFLICTS_QUERY_KEY = "GetFutureSessionConflicts";

interface UseFutureSessionConflictsQueryParams<T> {
  clinicianId: number;
  sessionDateTime: DateTime;
  recurrence?: string;
  select: (data: GetFutureSessionConflictsResponse) => T;
  enabled: boolean;
}

const useFutureSessionConflictsQuery = <T extends object>({
  clinicianId,
  sessionDateTime,
  recurrence,
  select,
  enabled,
}: UseFutureSessionConflictsQueryParams<T>) => {
  return useQuery(
    [
      GET_FUTURE_SESSION_CONFLICTS_QUERY_KEY,
      sessionDateTime,
      recurrence,
      clinicianId,
    ],
    {
      queryFn: () =>
        getFutureSessionConflicts(clinicianId, sessionDateTime, recurrence),
      retry: false,
      enabled: !!sessionDateTime && enabled,
      staleTime: 1000 * 30,
      select,
    },
  );
};

export const useFutureSessionConflicts = <T extends object>(
  clinicianId: number,
  control: Control<UpsertSessionFormFields, any>,
  enabled: boolean,
  timezone: string,
  select: (data: GetFutureSessionConflictsResponse) => T,
) => {
  const sessionDate = useWatch({
    control,
    name: "sessionDate",
  });

  const sessionTime = useWatch({
    control,
    name: "sessionTime",
  });

  const recurrence = useWatch({
    control,
    name: "recurrence",
  });

  const selectedClinician = useWatch({
    control,
    name: "clinician",
  });

  const parsedDateTime = transformDateAndTimeString(sessionDate, sessionTime, timezone);

  return useFutureSessionConflictsQuery({
    clinicianId: selectedClinician ? Number(selectedClinician) : clinicianId,
    sessionDateTime: parsedDateTime!,
    recurrence,
    select,
    enabled,
  });
};

export function transformDateAndTimeString(isoDate: string, time: string, timezone: string) {
  try {
    const parsedDate = DateTime.fromISO(isoDate);
    const parsedTime = DateTime.fromISO(time);

    // affix timezone to primary timezone BEFORE changing the hour
    return parsedDate
      .setZone(timezone, { keepLocalTime: true })
      .set({
        hour: parsedTime.hour,
        minute: parsedTime.minute,
        second: 0,
        millisecond: 0,
      });
  } catch (error) {
    return undefined;
  }
}

export interface SelectDataForUpsertSessionFormReturn {
  conflictingEvents: FutureSessionConflictEvent[];
  conflictingRecurrences: FutureSessionConflictRecurrence[];
}

export const selectDataForUpsertSessionForm = (
  event: EventApiData | undefined,
  data: GetFutureSessionConflictsResponse,
): SelectDataForUpsertSessionFormReturn => {
  const conflictingEvents = data.events.filter((e) => e.id !== event?.eventId);

  const conflictingRecurrences = data.recurrences.filter(
    (r) => r.id !== event?.recurrenceData?.slotId,
  );

  return {
    conflictingEvents,
    conflictingRecurrences,
  };
};
