import {
  CircleCheckMarkIcon,
  CrossOutCircleIcon,
  TrashCanIcon,
} from "@/app/design-system/icons";
import { Flex, Text, styledStitches, CSS_COLORS } from "@/app/design-system";
import { ParsedClientAttendanceNotes } from "../_api/use-group-therapy-client-attendance-notes/use-group-therapy-client-attendance-notes.util";
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
} from "@/app/design-system/accordion/accordion.styled";
import {
  StyledAccordionContent,
  StyledAccordionTrigger,
  StyledChevron,
} from "@/app/groups/group-id/_components/group-id.styled";

interface ClientAttendancePaneProps {
  session: ParsedClientAttendanceNotes;
}

export const ClientAttendancePane = ({
  session,
}: ClientAttendancePaneProps) => {
  const hasNote = session.notes?.length;

  return hasNote ? (
    <StyledClientAttendancePane>
      <Accordion
        type="multiple"
        css={{ width: "100%", background: "transparent" }}
      >
        <AccordionItem value={session?.emrLink}>
          <AccordionHeader>
            <SessionsAttended session={session} />
            <WelkinAndStatus session={session} />
            <Flex
              css={{
                width: "40%",
                justifyContent: "flex-end",
                alignContent: "flex-end",
              }}
            >
              <StyledAccordionTrigger>
                <Flex>
                  <Text css={{ color: "$neutral11" }}>View note</Text>
                  <StyledChevron
                    fill={CSS_COLORS.neutral11}
                    className="caret-icon"
                  />
                </Flex>
              </StyledAccordionTrigger>
            </Flex>
          </AccordionHeader>
          <StyledAccordionContent>{session.notes}</StyledAccordionContent>
        </AccordionItem>
      </Accordion>
    </StyledClientAttendancePane>
  ) : (
    <StyledClientAttendancePane>
      <SessionsAttended session={session} />
      <WelkinAndStatus session={session} />
      <Flex css={{ width: "40%" }}></Flex>
    </StyledClientAttendancePane>
  );
};

const SessionsAttended = ({ session }: ClientAttendancePaneProps) => (
  <Flex
    css={{
      width: "20%",
      gap: 14,
      overflow: "auto",
    }}
  >
    <SessionIcon session={session} />
    <Text css={{ fontWeight: 700 }}> Session {session.sessionNumber} </Text>
    <Text> - {session.formattedSessionDate} </Text>
  </Flex>
);

const SessionIcon = ({ session }: ClientAttendancePaneProps) => {
  if (session.attendanceStatus === "Canceled") {
    return <TrashCanIcon />;
  } else if (session.attendanceStatus === "No show, late cancel") {
    return <CrossOutCircleIcon />;
  } else {
    return <CircleCheckMarkIcon />;
  }
};

const WelkinAndStatus = ({ session }: ClientAttendancePaneProps) => {
  const attendanceColor =
    session?.attendanceStatus === "Attended" ? "$neutral12" : "$red11";
  const attendanceWeight = session?.attendanceStatus === "Attended" ? 400 : 700;
  return (
    <Flex
      css={{
        width: "40%",
        gap: 14,
        justifyContent: "flex-end",
        overflow: "auto",
      }}
    >
      <ViewNotesLink
        href={session?.emrLink}
        target="_blank"
        css={{ borderBottom: "1px solid currentColor", height: 30 }}
      >
        View Notes
      </ViewNotesLink>
      <Text
        color={attendanceColor}
        fontWeight={attendanceWeight}
        css={{ paddingLeft: 100 }}
      >
        {session?.attendanceStatus}
      </Text>
    </Flex>
  );
};

const StyledClientAttendancePane = styledStitches(Flex, {
  width: "99%",
  background: "white",
  flexDirection: "row",
  p: 20,
  pr: 10,
  pt: 25,
  minHeight: 70,
  align: "center",
  gap: 14,
  borderBottom: "2px solid $neutral6",

  "&:first-child": {
    borderTop: "2px solid $neutral6",
  },
});

const ViewNotesLink = styledStitches("a", {
  color: "$blue11",
  fontSize: 16,
});
