import { EventApiData } from "@/app/api/use-my-clients-overview";
import { useFormContext, useWatch } from "react-hook-form";
import { Stack, Text } from "../../../../../../../../../../../design-system";
import { SessionRecurrenceOption } from "../upsert-session-form.constants";
import { UpsertSessionFormFields } from "../use-upsert-session-form";
import { UpcomingEventsConflictCard } from "./components/upcoming-events-conflict-card";
import { UpcomingRecurrencesConflictCard } from "./components/upcoming-recurrence-conflict-card";
import {
  FutureSessionConflictEvent,
  FutureSessionConflictRecurrence,
} from "./use-future-conflicts.api";

type UpcomingConflictsProps = {
  conflictingRecurrences: FutureSessionConflictRecurrence[] | undefined;
  conflictingEvents: FutureSessionConflictEvent[] | undefined;
  event: EventApiData | undefined;
};

export const UpcomingConflictsSection = ({
  conflictingRecurrences,
  conflictingEvents,
  event,
}: UpcomingConflictsProps) => {
  const { control } = useFormContext<UpsertSessionFormFields>();

  const { recurrence: selectedRecurrenceFieldValue, sessionsToUpdate } =
    useWatch<UpsertSessionFormFields>({
      control,
    });

  const isOneOffSession =
    selectedRecurrenceFieldValue === SessionRecurrenceOption.DOES_NOT_REPEAT ||
    // SessionRecurrenceOption.DOES_NOT_REPEAT is not part
    // of the select field options when in the edit flow which is why it does not parse into a real value
    // but is be set to be null when editing a one off session
    selectedRecurrenceFieldValue === null ||
    event?.recurrenceData.isException ||
    sessionsToUpdate === "This session only";

  return (
    <Stack gap={"16"}>
      {conflictingEvents && conflictingEvents.length > 0 ? (
        <>
          <Text fontSize={"14"} color="$neutral11">
            There are conflict(s) with the updated time. By saving, you confirm
            to override the following conflicts:
          </Text>
          {conflictingEvents.map((event) => (
            <UpcomingEventsConflictCard event={event} key={event.id} />
          ))}
        </>
      ) : null}

      {conflictingRecurrences && conflictingRecurrences.length > 0 ? (
        <>
          <Text
            fontSize={"14"}
            color={isOneOffSession ? "$neutral11" : "$orange11"}
          >
            There are conflicting recurrence(s) with the updated time.
            {isOneOffSession
              ? " By saving, you confirm to override the following conflicts:"
              : " Overlapping recurrences are not allowed."}
          </Text>

          {conflictingRecurrences.map((recurrence) => (
            <UpcomingRecurrencesConflictCard
              recurrence={recurrence}
              key={recurrence.id}
            />
          ))}
        </>
      ) : null}
    </Stack>
  );
};
