import { CloseCircleOutlined } from '@ant-design/icons';
import * as React from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";

import { $antdBorder } from "../../../assets/colors";
import {
  ModalContext,
  SessionModalProviderStore,
  ModalProviderStore,
  isSessionModal,
} from "./CellModalProvider";
import ClientModal from "./ClientModalContent";
import SessionModal from "./SessionModalContent";
import WeekModal from "./WeekModalContent";

const MODAL_WIDTH = 560;
const MODAL_MARGIN = 16;

interface ModalPosition {
  top: number | null;
  left: number | null;
}

function Modal() {
  const modalContext = React.useContext(ModalContext);
  const [position, setPosition] = React.useState<ModalPosition>({
    top: null,
    left: null,
  });

  const modalDataSource = isSessionModal(modalContext.state.modalType) ? (modalContext as SessionModalProviderStore).state.modalDataIds : (modalContext as ModalProviderStore).state.modalDataId;

  React.useEffect(() => {
    if (modalContext.state.modalRef.current) {
      const MODAL_HEIGHT = modalContext.state.modalRef.current.clientHeight;
      const cellDOMRect = modalContext.state.modalOriginPosition!;
      const modalPosition: ModalPosition = { top: null, left: null };
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;
      if (MODAL_HEIGHT === 0) {
        return;
      }

      // if the modal can fit below (ie, aligned at the top)
      if (windowHeight - cellDOMRect.top > MODAL_HEIGHT) {
        modalPosition.top = cellDOMRect.top;
      } else if (cellDOMRect.bottom - MODAL_HEIGHT < 100) {
        // if the modal can't fit below or above without overflowing
        modalPosition.top =
          cellDOMRect.top - (MODAL_HEIGHT - cellDOMRect.height) / 2;
      } else {
        modalPosition.top = cellDOMRect.bottom - MODAL_HEIGHT;
      }

      if (cellDOMRect.left < windowWidth / 2) {
        // if the modal goes on the right side of the cell
        modalPosition.left = cellDOMRect.right;
      } else {
        // if the modal goes on the left side of the cell
        modalPosition.left = cellDOMRect.left - MODAL_WIDTH - MODAL_MARGIN * 2;
      }
      setPosition(modalPosition);
    } else {
      setPosition({ top: null, left: null });
    }
  }, [modalDataSource, modalContext.state.modalOriginPosition, modalContext.state.modalRef]);

  if (!modalContext.state.modalIsOpen) {
    return null;
  }

  const modalContent = () => {
    switch (modalContext.state.modalType) {
      case "client":
        return <ClientModal dataId={modalContext.state.modalDataId} />;
      case "week":
        return <WeekModal dataId={modalContext.state.modalDataId} />;
      default:
        return (
          <SessionModal
            dataId={
              (modalContext as SessionModalProviderStore).state.modalDataIds
            }
          />
        );
    }
  };

  return createPortal(
    <ModalContainer position={position} ref={modalContext.state.modalRef}>
      <CloseCircleOutlined
        className="modal-close-button"
        onClick={modalContext.hideModal}
        style={{
          position: "absolute",
          top: "16px",
          right: "16px",
          fontSize: "18pt",
        }} />
      {modalContent()}
    </ModalContainer>,
    document.getElementById("panel-mgmt-modal")!,
  );
}

interface ModalContainerProps {
  position: ModalPosition;
}

const ModalContainer = styled.div<ModalContainerProps>`
  will-change: left, top;
  width: ${MODAL_WIDTH}px;
  background-color: white;
  border: 1px solid ${$antdBorder};
  z-index: 9999;
  position: fixed;
  margin: 0 16px;
  transition: top 200ms ease-in-out, left 200ms ease-in-out;
  box-shadow: 4px 4px 16px 2px rgba(0, 0, 0, 0.35);
  border-radius: 4px;
  padding-bottom: 8px;
  ${p => p.position && p.position.top === null && `opacity: 0;`}
  ${p => p.position && generateCSSFromPosition(p.position)}
`;

function generateCSSFromPosition(position: ModalPosition) {
  const cssStrings: string[] = [];
  for (const attr in position) {
    if (position[attr]) {
      cssStrings.push(`${attr}: ${position[attr]}px; `);
    }
  }

  return cssStrings.join(" ");
}

export default Modal;
