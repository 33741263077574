import { AxiosResponse } from "axios";

import { sum } from "lodash-es";
import moment from "moment";
import api, { wrapApiCall } from "../../api";
import { BillableHoursWeek } from "../../api/types";
import { AsyncActionWithResultCreator } from "../../state/models/types";
import { Insights } from "./PerformanceOverview";
import { DateRange, MBCOverallAdherenceWeek } from "./types";

interface loadYearHalfPerformanceArgs {
  clinicianId: number | null;
  clinicianIds: number[] | null;
  start: string;
  end: string;
}
export const loadYearHalfPerformance: AsyncActionWithResultCreator<any> =
  (payload: loadYearHalfPerformanceArgs) =>
  async (dispatch): Promise<Insights> => {
    const { clinicianId, clinicianIds, start, end } = payload;
    const dateRange = {
      start: moment(start),
      end: moment(end),
    };
    // team view
    if (clinicianIds) {
      const [getTeamUtilizationRes, teamMbcAdherenceRes] = await Promise.all([
        dispatch(getTeamUtilization(clinicianIds, dateRange)),
        dispatch(getTeamMBCOverallAdherence(clinicianIds, dateRange)),
      ]);
      let payload = {};
      if (
        getTeamUtilizationRes &&
        getTeamUtilizationRes.data &&
        getTeamUtilizationRes.data.length > 0
      ) {
        const allData = getTeamUtilizationRes.data;
        const avg =
          allData
            .map((e) => e!.hours_utilization)
            .reduce((prev, curr) => (curr += prev), 0) /
          allData
            .map((e) => e!.workweek_hours)
            .reduce((prev, curr) => (curr += prev), 0);
        payload["avgUtilizationPercent"] = (avg * 100).toFixed(1);
      }
      if (
        teamMbcAdherenceRes &&
        teamMbcAdherenceRes.data &&
        teamMbcAdherenceRes.data.length > 0
      ) {
        const allMBCData = teamMbcAdherenceRes.data;
        let pre = allMBCData
          .map((w) => w!.pre_session_adherence)
          .filter((e) => !!e) as number[];

        const preSessionAverage =
          pre.reduce((prev, curr) => (curr += prev), 0) / pre.length || 0;
        payload["adherence"] = (preSessionAverage * 100).toFixed(1);
      }
      return payload;
    } else {
      // individual clinician metrics
      const [clinicianMbcAdherence, clinicianUtilization] = await Promise.all([
        dispatch(getMBCOverallAdherence(clinicianId, dateRange)),
        dispatch(getUtilizationByClinician(clinicianId, dateRange)),
      ]);
      let payload = {};
      if (
        clinicianUtilization &&
        clinicianUtilization.data &&
        clinicianUtilization.data.length > 0
      ) {
        const allData = clinicianUtilization.data.filter(
          (e) => e !== null && e.billable_time_utilization !== 0,
        );
        const utilizations = allData.map((e) => e!.hours_utilization);
        const targets = allData.map((e) => e!.target_client_hours);
        if (utilizations.length && targets.length) {
          const avgUtil = Number(
            sum(utilizations) / utilizations.length,
          ).toFixed(0);
          const avgTarget = Number(sum(targets) / targets.length).toFixed(0);
          payload["avgUtilization"] = avgUtil;
          payload["avgUtilizationTarget"] = avgTarget;
        }
      }
      if (
        clinicianMbcAdherence &&
        clinicianMbcAdherence.data &&
        clinicianMbcAdherence.data.length > 0
      ) {
        const allMBCData = clinicianMbcAdherence.data;
        let pre = allMBCData
          .map((w) => w!.pre_session_adherence)
          .filter((e) => !!e) as number[];

        const preSessionAverage =
          pre.reduce((prev, curr) => (curr += prev), 0) / pre.length || 0;
        payload["adherence"] = (preSessionAverage * 100).toFixed(1);
      }
      return payload;
    }
  };

export const getUtilizationByClinician: AsyncActionWithResultCreator<
  AxiosResponse<BillableHoursWeek[]>
> = (clinicianId: number | string, dateRange: DateRange) => async (dispatch) => {
  const route = `/api/query/utilization/billable_hours/?clinician=${clinicianId}&start=${dateRange.start.format(
    "YYYY-MM-DD",
  )}&end=${dateRange.end.format("YYYY-MM-DD")}`;
  return await wrapApiCall(api.get(route), dispatch);
};

export const getTeamUtilization: AsyncActionWithResultCreator<
  AxiosResponse<BillableHoursWeek[]>
> = (clinicianIds: number[], dateRange: DateRange) => async (dispatch) => {
  const route = `/api/query/utilization/multiple_clinicians_billable_hours/?clinician=${clinicianIds.join(
    ",",
  )}&start=${dateRange.start.format("YYYY-MM-DD")}&end=${dateRange.end.format(
    "YYYY-MM-DD",
  )}`;
  return await wrapApiCall(api.get(route), dispatch);
};

export const getMBCOverallAdherence: AsyncActionWithResultCreator<
  AxiosResponse<MBCOverallAdherenceWeek[]>
> = (clinicianId: number, dateRange: DateRange) => async (dispatch) => {
  const route = `/api/query/mbc/overall_adherence_by_clinician/?clinician=${clinicianId}&start=${dateRange.start.format(
    "YYYY-MM-DD",
  )}&end=${dateRange.end.format("YYYY-MM-DD")}`;
  return await wrapApiCall(api.get(route), dispatch);
};

export const getTeamMBCOverallAdherence: AsyncActionWithResultCreator<
  AxiosResponse<MBCOverallAdherenceWeek[]>
> = (clinicianIds: number[], dateRange: DateRange) => async (dispatch) => {
  let route = `/api/query/mbc/overall_adherence_team_view/?clinician=${clinicianIds.join(
    ",",
  )}&start=${dateRange.start.format("YYYY-MM-DD")}&end=${dateRange.end.format(
    "YYYY-MM-DD",
  )}`;
  return await wrapApiCall(api.get(route), dispatch);
};
 
 export const METRICS_PANEL_DATE_RANGES =  {
    "Last 3 months" : {
      start: moment()
        .subtract("12", "weeks")
        .startOf("week"),
      end: moment().endOf("week"),
    },
    "Last 4 weeks": {
      start: moment()
        .subtract("4", "weeks")
        .startOf("week"),
      end: moment().endOf("week"),
    },
    "Future 4 weeks": {
      start: moment().startOf("week"),
      end: moment().add(4, "weeks").endOf("week"),
    },
  }