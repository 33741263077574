import { styledStitches } from "@/app/design-system/styles/stitches.config";
import { allColorsForStyledStitches, colors, neutrals } from "../styles/config/colors";

export const Text = styledStitches("span", {
  verticalAlign: "middle",
  display: "flex",
  alignItems: "center",
  fontSize: 16,
  fontWeight: "normal",

  variants: {
    notFlex: {
      true: {
        display: "initial",
      },
    },
    capitalize: {
      true: {
        textTransform: "capitalize",
      },
      firstLetter: {
        "&:first-letter": {
          textTransform: "capitalize",
        },
      },
    },
    lowercase: {
      true: {
        textTransform: "lowercase",
      },
    },
    fontFamily: {
      arial: {
        fontFamily: "Arial",
      },
    },
    fontWeight: {
      400: { fontWeight: 400 },
      500: { fontWeight: 500 },
      600: { fontWeight: 600 },
      700: { fontWeight: 700 },
    },
    color: allColorsForStyledStitches,
    fontSize: {
      12: {
        fontSize: 12,
      },
      13: {
        fontSize: 13,
      },
      14: {
        fontSize: 14,
      },
      16: {
        fontSize: 16,
      },
      18: {
        fontSize: 18,
      },
      20: {
        fontSize: 20,
      },
      22: {
        fontSize: 22,
      },
      24: {
        fontSize: 24,
      },
    },
  },

  // even though we can set color on <Text />, we have to use
  // the css override since <Text /> is inheriting color from the
  // global less config from antd. I'm so annoyed

  compoundVariants: [
    {
      color: "$orange11",
      css: {
        color: "$orange11",
      },
    },

    {
      color: "$yellow11",
      css: {
        color: "$yellow11",
      },
    },
    {
      color: "$red11",
      css: {
        color: "$red11",
      },
    },
    {
      color: "$blue11",
      css: {
        color: "$blue11",
      },
    },
    {
      color: "$neutral9",
      css: {
        color: "$neutral9",
      },
    },
    {
      color: "$green11",
      css: {
        color: "$green11",
      },
    },
    {
      color: "$neutral11",
      css: {
        color: "$neutral11",
      },
    },
    {
      color: "$neutral12",
      css: {
        color: "$neutral12",
      },
    },
    {
      color: "$neutral0",
      css: {
        color: "$neutral0",
      },
    },

    {
      color: "$neutral8",
      css: {
        color: "$neutral8",
      },
    },
  ],

  defaultVariants: {
    color: "$neutral12",
  },
});
