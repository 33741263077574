import * as React from "react";
import { useDispatch } from "react-redux";
import { useShallowEqualSelector } from "../../../_helpers/redux";
import { consultNoteActions } from "../../../../state/models/consultnote";
import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import QuestionTitle from "../ConsultNoteQuestions/shared/QuestionTitle";
import { ConsultNoteQuestion } from "../../../../api/types";
import { getCurrentConsultNoteInfo } from "../../_hooks";

interface Props {
  question: ConsultNoteQuestion;
  handleEdit: (hasBeenEdited: boolean) => void;
}

const _ = ({ question, handleEdit }: Props) => {
  const { readOnlyConsultNote } = getCurrentConsultNoteInfo();

  const [
    teletherapy_checklist_client_identity,
    teletherapy_checklist_emergency_contact,
    teletherapy_checklist_confidentiality_consent,
    consult_modality,
  ] = useShallowEqualSelector(state => [
    state.consultnote?.openConsult?.consult_note
      ?.teletherapy_checklist_client_identity,

    state.consultnote?.openConsult?.consult_note
      ?.teletherapy_checklist_emergency_contact,

    state.consultnote?.openConsult?.consult_note
      ?.teletherapy_checklist_confidentiality_consent,
    state.consultnote?.openConsult?.consult_note?.consult_modality,
  ]);

  const dispatch = useDispatch();

  const handleChange = (key: string, value: boolean) => {
    handleEdit(true);
    dispatch(
      consultNoteActions.setConsultNoteValue({
        key,
        value,
      }),
    );
  };

  const checkboxes = [
    {
      slug: "teletherapy_checklist_client_identity",
      label: "Confirmed client’s identity (full name, date of birth, and phone number)",
    },
    {
      slug: "teletherapy_checklist_emergency_contact",
      label: "Confirmed or collected a local emergency contact (in Welkin)",
    },
    {
      slug: "teletherapy_checklist_confidentiality_consent",
      label:
        "Reviewed confidentiality and teletherapy informed consent, and received verbal consent from client",
    },
  ];

  return (
    <div style={{ paddingTop: "15px", paddingBottom: "15px" }}>
      <QuestionTitle
        questionNumber={question.questionNumber}
        titleText={question.title}
        subtitleText={question.subtitle}
        isRequired={question.isRequired}
      />
      {consult_modality === "teletherapy" ? (
        checkboxes.map((c, index) => {
          const v = [
            teletherapy_checklist_client_identity,
            teletherapy_checklist_emergency_contact,
            teletherapy_checklist_confidentiality_consent,
          ][index];
          return (
            <div key={index}>
              <Checkbox
                checked={v}
                style={{ paddingRight: "10px" }}
                onChange={(e: CheckboxChangeEvent) =>
                  handleChange(c.slug, e.target.checked)
                }
                disabled={readOnlyConsultNote}
              >
                {c.label}
              </Checkbox>
            </div>
          );
        })
      ) : (
        <div>
          <i>Not applicable to in-person therapy</i>
        </div>
      )}
    </div>
  );
};

export default _;
