import { produce } from "immer";
import moment from "moment";
import {
  actionFactory,
  ActionUnion,
  payloadAction,
  simpleAction,
} from "reductser";

import { Time } from "../../../api/types";
import { BillableHoursWeeksMap } from "../../../app/dashboard/types";
import { DateRange } from "../../../app/panel-management/types";
import {
  APIExcludedSlot,
  ClientMap,
  ClinicianSchedule,
  ClinicianVolumeDataMap,
  DayOfWeek,
  daysArray,
  EventBuckets,
  EventMap,
  SlotEvent,
  timesArray,
  WorkingTimes,
  ClinicianManualCapacityV2DataMap,
  SlotOpenSlot,
  CoupleMap,
  APIManualSlotReservation,
  ProcedureLimitMap,
  ClinicianProcedureLimitsMap,
  ProcedureLimit,
} from "../../../app/slot-tool/types";
import { generateEmptyISOWeekMap } from "../dashboard";

interface EventBucketInsert {
  day: DayOfWeek;
  time: WorkingTimes;
  slot: SlotOpenSlot | SlotEvent;
}

interface EventBucketRemove {
  day: DayOfWeek;
  time: WorkingTimes;
  id: string;
}

const actionMap = {
  setClinicianId: payloadAction<number>(),
  setDateRange: payloadAction<DateRange>(),
  setEventMap: payloadAction<{ clinicianId?: number; eventMap: EventMap }>(),
  insertEventBucket: payloadAction<{
    clinicianId?: number;
    insert: EventBucketInsert;
  }>(),
  removeEventBucket: payloadAction<{
    clinicianId?: number;
    remove: EventBucketRemove;
  }>(),
  setEventBuckets: payloadAction<{
    clinicianId?: number;
    buckets: EventBuckets;
  }>(),
  setExcludedSlots: payloadAction<{
    clinicianId?: number;
    excludedSlots: APIExcludedSlot[];
  }>(),
  setReservedSlots: payloadAction<{
    clinicianId?: number;
    reservedSlots: APIManualSlotReservation[];
  }>(),
  setClientMap: payloadAction<{ clinicianId?: number; clientMap: ClientMap }>(),
  setCoupleMap: payloadAction<{ clinicianId?: number; coupleMap: CoupleMap }>(),
  setClinicianSchedule: payloadAction<{
    clinicianId?: number;
    schedule: ClinicianSchedule;
  }>(),
  setClinicianVolumeDataMap: payloadAction<ClinicianVolumeDataMap>(),
  setClinicianManualCapacityDataMap: payloadAction<
    ClinicianManualCapacityV2DataMap
  >(),
  resetBillableHours: simpleAction(),
  setBillableHoursWeeks: payloadAction<BillableHoursWeeksMap>(),
  startLoadingSlotTool: simpleAction(),
  finishLoadingSlotTool: payloadAction<{ clinicianId: number }>(),
  setProcedureLimits: payloadAction<ProcedureLimit[]>(),
  setClinicianProcedureLimitsMap: payloadAction<ClinicianProcedureLimitsMap>(),
};

export const slotToolActions = actionFactory(actionMap, "SLOTTOOL");
export type SlotToolAction = ActionUnion<typeof slotToolActions>;

export interface SlotToolState {
  billableHoursWeeks: BillableHoursWeeksMap;
  clinicianId?: number;
  clinicianSchedule: ClinicianSchedule;
  clinicianVolumeDataMap: ClinicianVolumeDataMap;
  clinicianManualCapacityDataMap: ClinicianManualCapacityV2DataMap;
  procedureLimitsMap: ProcedureLimitMap;
  clinicianProcedureLimitsMap: ClinicianProcedureLimitsMap;
  dateRange: DateRange;
  eventBuckets: EventBuckets;
  excludedSlots: APIExcludedSlot[];
  reservedSlots: APIManualSlotReservation[];
  eventMap: EventMap;
  clientMap: ClientMap;
  coupleMap: CoupleMap;
  isLoading: boolean;
  utilizationDateRange: DateRange;
}

export const TIME_FORMAT = "HH:mm:ss";
export const DATE_FORMAT = "YYYY-MM-DD";

const getDefaultSchedule = () => {
  const daysOfWeek: DayOfWeek[] = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
  ];
  const obj: Partial<ClinicianSchedule> = {};
  daysOfWeek.forEach(
    day =>
      (obj[day] = {
        dayOfWeek: day,
        hoursIncluded: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17]
      }),
  );

  return obj as ClinicianSchedule;
};

function getInitialState(): SlotToolState {
  const utilizationDateRange: DateRange = {
    start: moment()
      .subtract(12, "weeks")
      .startOf("week"),
    end: moment()
      .add(4, "weeks")
      .endOf("week"),
  };
  return {
    billableHoursWeeks: generateEmptyISOWeekMap(utilizationDateRange),
    clinicianId: undefined,
    clinicianSchedule: getDefaultSchedule(),
    dateRange: {
      start: moment()
        .add(1, "week")
        .startOf("week"),
      end: moment()
        .add(8, "weeks")
        .endOf("week"),
    },
    procedureLimitsMap: {},
    clinicianProcedureLimitsMap: {},
    utilizationDateRange: utilizationDateRange,
    eventMap: {},
    clientMap: {},
    coupleMap: {},
    clinicianVolumeDataMap: {},
    clinicianManualCapacityDataMap: {},
    eventBuckets: generateEmptyEventBuckets(),
    excludedSlots: [],
    reservedSlots: [],
    isLoading: false,
  };
}

const slotToolReducer = (state = getInitialState(), action: SlotToolAction) =>
  produce(state, draft => {
    switch (action.type) {
      case "resetBillableHours":
        draft.billableHoursWeeks = generateEmptyISOWeekMap(state.dateRange);
        return;
      case "setBillableHoursWeeks":
        draft.billableHoursWeeks = action.payload;
        return;
      case "setClinicianId":
        draft.clinicianId = action.payload;
        return;
      case "setDateRange":
        draft.dateRange = action.payload;
        return;
      case "startLoadingSlotTool":
        draft.isLoading = true;
        return;
      case "finishLoadingSlotTool": {
        if (action.payload.clinicianId !== state.clinicianId) {
          return;
        }
        draft.isLoading = false;
        return;
      }
      case "setEventMap": {
        if (action.payload.clinicianId !== state.clinicianId) {
          return;
        }
        draft.eventMap = action.payload.eventMap;
        return;
      }
      case "setClientMap": {
        if (action.payload.clinicianId !== state.clinicianId) {
          return;
        }
        draft.clientMap = action.payload.clientMap;
        return;
      }
      case "setCoupleMap": {
        if (action.payload.clinicianId !== state.clinicianId) {
          return;
        }
        draft.coupleMap = action.payload.coupleMap;
        return;
      }
      case "setEventBuckets": {
        if (action.payload.clinicianId !== state.clinicianId) {
          return;
        }
        draft.eventBuckets = action.payload.buckets;
        return;
      }
      case "insertEventBucket": {
        if (action.payload.clinicianId !== state.clinicianId) {
          return;
        }
        const { day, time, slot } = action.payload.insert;
        const currentSlots = draft.eventBuckets[day][time] ?? [];
        currentSlots.push(slot);
        return;
      }
      case "removeEventBucket": {
        if (action.payload.clinicianId !== state.clinicianId) {
          return;
        }
        const { day, time, id } = action.payload.remove;
        const currentSlots = draft.eventBuckets[day][time] ?? [];
        draft.eventBuckets[day][time] = currentSlots.filter(
          s => s.eventId !== id,
        );
        return;
      }
      case "setExcludedSlots": {
        if (action.payload.clinicianId !== state.clinicianId) {
          return;
        }
        draft.excludedSlots = action.payload.excludedSlots;
        return;
      }
      case "setReservedSlots": {
        if (action.payload.clinicianId !== state.clinicianId) {
          return;
        }
        draft.reservedSlots = action.payload.reservedSlots;
        return;
      }
      case "setClinicianSchedule": {
        if (action.payload.clinicianId !== state.clinicianId) {
          return;
        }
        draft.clinicianSchedule = action.payload.schedule;
        return;
      }
      case "setClinicianVolumeDataMap": {
        draft.clinicianVolumeDataMap = action.payload;
        return;
      }
      case "setClinicianManualCapacityDataMap": {
        draft.clinicianManualCapacityDataMap = action.payload;
        return;
      }
      case "setProcedureLimits": {
        action.payload.forEach(obj => {
          draft.procedureLimitsMap[obj.id] = obj;
        });
        return;
      }
      case "setClinicianProcedureLimitsMap": {
        Object.entries(action.payload).forEach(([clinicianId, rows]) => {
          const existingArray = draft.clinicianProcedureLimitsMap[clinicianId] || [];
          draft.clinicianProcedureLimitsMap[clinicianId] = Array.from(
            new Set([
              ...existingArray,
              ...rows,
            ]),
          );
        });
      }
    }
  });

export const generateEmptyEventBuckets = (): EventBuckets => {
  const buckets = {};
  daysArray.forEach((day: DayOfWeek) => {
    const dayBucket = {};
    timesArray.forEach((time: Time) => (dayBucket[time] = [] as SlotEvent[]));
    buckets[day] = dayBucket as Record<WorkingTimes, SlotEvent[]>;
  });
  return buckets as EventBuckets;
};

export default slotToolReducer;
