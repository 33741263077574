import { DownChevronIcon } from "@/app/design-system/icons";
import { ComboboxOptionText } from "@reach/combobox";
import { CSS } from "@stitches/react/types/css-util";
import { Clinician } from "../../../api/types";
import { Flex, H5, Separator } from "../../design-system";
import {
  StyledCombobox,
  StyledComboboxInput,
  StyledComboboxList,
  StyledComboboxOption,
  StyledComboboxPopover,
} from "../../design-system/combobox/combox.styled";
import {
  ScrollArea,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport,
} from "./clinician-picker-v2-scroll-area";
import { useClinicianPickerV2 } from "./use-clinician-picker-v2";
import { OVERLAY_ZINDEX } from "@/app/design-system/styles/config/css-constants";

interface ClinicianPickerV2Props {
  callback: (clinicianId: number | null) => void;
  portal?: boolean;
  css?: CSS;
}

export const ClinicianPickerV2 = ({
  callback,
  portal = false,
  css = { width: 225.5 },
}: ClinicianPickerV2Props) => {
  const {
    handleChange,
    clinicianList,
    currentClinicianTeamList,
    results,
    showResults,
    setClinicianByName,
  } = useClinicianPickerV2({ callback });

  return (
    <StyledCombobox
      aria-label="clients"
      openOnFocus
      onSelect={setClinicianByName}
      css={{
        ...css,
        // custom css for the down arrow icon, at the custom component level
        // since not all comboboxes are like a select (search bars, for ex)

        cursor: "pointer",
        position: "relative",
        zIndex: OVERLAY_ZINDEX,
        ".down-chevron-icon": {
          transition: "transform ease-in-out 0.25s",
          position: "absolute",
          right: 6,
          top: 8,
        },

        '&[data-state="suggesting"]': {
          ".down-chevron-icon": {
            transition: "transform ease-in-out 0.25s",
            transform: "rotate(-180deg)",
          },
        },
      }}
    >
      <StyledComboboxInput
        className="clients-search-input"
        onChange={handleChange}
        placeholder="Select clinician"
        css={{ ...css, cursor: "pointer" }}
      />
      <DownChevronIcon className="down-chevron-icon" />

      <StyledComboboxPopover
        className="shadow-popup"
        portal={portal}
        css={{ ...css }}
      >
        <ScrollArea isLoading={clinicianList.length === 0}>
          <ScrollAreaViewport>
            {showResults ? (
              <ResultsComboxList results={results} callback={callback} />
            ) : (
              <InitialComboboxList
                currentClinicianTeamList={currentClinicianTeamList}
                clinicianList={clinicianList}
                callback={callback}
              />
            )}
          </ScrollAreaViewport>
          <ScrollAreaScrollbar orientation="vertical">
            <ScrollAreaThumb />
          </ScrollAreaScrollbar>
        </ScrollArea>
      </StyledComboboxPopover>
    </StyledCombobox>
  );
};

interface ResultsComboxListProps extends ClinicianPickerV2Props {
  results: any;
}

export const ResultsComboxList = ({
  results,
  callback,
}: ResultsComboxListProps) => {
  return (
    <>
      {results.length ? (
        <StyledComboboxList persistSelection>
          {results.map((result: any, index: any) => (
            <StyledComboboxOption
              onClick={() => {
                callback(result.id);
              }}
              key={index}
              value={`${result.firstName} ${result.lastName}`}
            >
              <ComboboxOptionText />
            </StyledComboboxOption>
          ))}
        </StyledComboboxList>
      ) : (
        <StyledComboboxList>
          <Flex css={{ px: 12, py: 8, color: "$neutral11" }}>
            No results found
          </Flex>
        </StyledComboboxList>
      )}
    </>
  );
};

interface InitialComboboxListProps extends ClinicianPickerV2Props {
  currentClinicianTeamList: Clinician[];
  clinicianList: Clinician[];
}

export const InitialComboboxList = ({
  currentClinicianTeamList,
  clinicianList,
  callback,
}: InitialComboboxListProps) => {
  return (
    <>
      <StyledComboboxList persistSelection>
        <CurrentClinicianTeamList
          currentClinicianTeamList={currentClinicianTeamList}
          callback={callback}
        />
        <AllClinicianTeamList
          clinicianList={clinicianList}
          callback={callback}
        />
      </StyledComboboxList>
    </>
  );
};

type CurrentClinicianTeamListProps = Pick<
  InitialComboboxListProps,
  "currentClinicianTeamList" | "callback"
>;

const CurrentClinicianTeamList = ({
  currentClinicianTeamList,
  callback,
}: CurrentClinicianTeamListProps) => {
  return (
    <>
      {currentClinicianTeamList.length > 0 ? (
        <>
          <H5 key={"team"} css={{ pl: 12, pt: 2, pb: 4, fontWeight: 600 }}>
            Team
          </H5>
          <Separator orientation="horizontal" />
        </>
      ) : null}

      {currentClinicianTeamList?.map((result: any) => (
        <StyledComboboxOption
          onClick={() => {
            callback(result.id);
          }}
          value={`${result.firstName} ${result.lastName}`}
        >
          <ComboboxOptionText />
        </StyledComboboxOption>
      ))}
    </>
  );
};

type AllClinicianTeamListProps = Pick<
  InitialComboboxListProps,
  "clinicianList" | "callback" | "css"
>;

const AllClinicianTeamList = ({
  clinicianList,
  callback,
  css,
}: AllClinicianTeamListProps) => {
  return (
    <>
      {clinicianList.length > 0 ? (
        <>
          <H5 key={"team"} css={{ pl: 12, pt: 16, pb: 4, fontWeight: 600 }}>
            All clinicians
          </H5>
          <Separator orientation="horizontal" />
        </>
      ) : null}

      {clinicianList?.map((result: any, index: number) => (
        <StyledComboboxOption
          key={result.id}
          onClick={() => {
            callback(result.id);
          }}
          value={`${result.firstName} ${result.lastName}`}
          css={{ ...css }}
        >
          <ComboboxOptionText />
        </StyledComboboxOption>
      ))}
    </>
  );
};
