import { Stack } from "../../../../../design-system/layout/stack";
import { styledStitches } from "@/app/design-system/styles/stitches.config";
import { MyClientsClient } from "../../my-clients-model";
import { MyClientsSearchAndFiltersSection } from "./filters/filters";
import React from "react";
import { MBCMetricCheckboxes } from "./filters/filter-checkboxes/mbc-metric-checkboxes";

const SearchAndFilterContainer = styledStitches(Stack, {
  backgroundColor: "white",
  padding: 24,
});

interface SearchAndFilterProps {
  myClients: MyClientsClient[];
}

export const SearchAndFilter = ({ myClients }: SearchAndFilterProps) => {
  return (
    <SearchAndFilterContainer gap={24}>
      <MyClientsSearchAndFiltersSection myClients={myClients} />
      <MBCMetricCheckboxes myClients={myClients} />
    </SearchAndFilterContainer>
  );
};
