import { IconProps } from "../icon.interface";

export const MagnifyingGlassIcon = ({
  className,
  height = 24,
  width = 24,
  title,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden={!title}
    >
      {title ? <title>{title}</title> : null}
      <path
        d="M20.3118 21.4996C20.7024 21.8901 21.3355 21.8901 21.7261 21.4996C22.1166 21.1091 22.1166 20.4759 21.7261 20.0854L20.3118 21.4996ZM17.3761 15.7354C16.9855 15.3449 16.3524 15.3449 15.9618 15.7354C15.5713 16.1259 15.5713 16.7591 15.9618 17.1496L17.3761 15.7354ZM18.019 10.7925C18.019 14.6585 14.885 17.7925 11.019 17.7925V19.7925C15.9896 19.7925 20.019 15.763 20.019 10.7925H18.019ZM11.019 17.7925C7.15305 17.7925 4.01904 14.6585 4.01904 10.7925H2.01904C2.01904 15.763 6.04848 19.7925 11.019 19.7925V17.7925ZM4.01904 10.7925C4.01904 6.92649 7.15305 3.79248 11.019 3.79248V1.79248C6.04848 1.79248 2.01904 5.82192 2.01904 10.7925H4.01904ZM11.019 3.79248C14.885 3.79248 18.019 6.92649 18.019 10.7925H20.019C20.019 5.82192 15.9896 1.79248 11.019 1.79248V3.79248ZM21.7261 20.0854L17.3761 15.7354L15.9618 17.1496L20.3118 21.4996L21.7261 20.0854Z"
        fill="#171717"
      />
    </svg>
  );
};
