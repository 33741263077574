// These are the Stitches utils
// https://stitches.dev/docs/utils
import { STANDARD_FOCUS_OUTLINE } from "./css-constants";
import { css } from "styled-components";
import type * as Stitches from "@stitches/react";

export const cssUtils = {
  p: (value: Stitches.PropertyValue<"padding">) => ({
    padding: value,
  }),
  pt: (value: Stitches.PropertyValue<"paddingTop">) => ({
    paddingTop: value,
  }),
  pr: (value: Stitches.PropertyValue<"paddingRight">) => ({
    paddingRight: value,
  }),
  pb: (value: Stitches.PropertyValue<"paddingBottom">) => ({
    paddingBottom: value,
  }),
  pl: (value: Stitches.PropertyValue<"paddingLeft">) => ({
    paddingLeft: value,
  }),
  px: (value: Stitches.PropertyValue<"paddingLeft">) => ({
    paddingLeft: value,
    paddingRight: value,
  }),
  py: (value: Stitches.PropertyValue<"paddingTop">) => ({
    paddingTop: value,
    paddingBottom: value,
  }),

  m: (value: Stitches.PropertyValue<"margin">) => ({
    margin: value,
  }),
  mt: (value: Stitches.PropertyValue<"marginTop">) => ({
    marginTop: value,
  }),
  mr: (value: Stitches.PropertyValue<"marginRight">) => ({
    marginRight: value,
  }),
  mb: (value: Stitches.PropertyValue<"marginBottom">) => ({
    marginBottom: value,
  }),
  ml: (value: Stitches.PropertyValue<"marginLeft">) => ({
    marginLeft: value,
  }),
  mx: (value: Stitches.PropertyValue<"marginLeft">) => ({
    marginLeft: value,
    marginRight: value,
  }),
  my: (value: Stitches.PropertyValue<"marginTop">) => ({
    marginTop: value,
    marginBottom: value,
  }),

  // A property for applying width/height together
  size: (value: number | string) => ({
    width: value,
    height: value,
  }),

  // A property to apply linear gradient
  linearGradient: (value: string) => ({
    backgroundImage: `linear-gradient(${value})`,
  }),

  // An abbreviated property for zndex
  z: (value: number | string) => ({
    zIndex: value,
  }),

  gap: (value: number | string) => ({
    gap: value,
  }),

  ta: (value: Stitches.PropertyValue<"textAlign">) => ({ textAlign: value }),

  fd: (value: Stitches.PropertyValue<"flexDirection">) => ({
    flexDirection: value,
  }),
  fw: (value: Stitches.PropertyValue<"flexWrap">) => ({ flexWrap: value }),

  ai: (value: Stitches.PropertyValue<"alignItems">) => ({ alignItems: value }),
  ac: (value: Stitches.PropertyValue<"alignContent">) => ({
    alignContent: value,
  }),
  jc: (value: Stitches.PropertyValue<"justifyContent">) => ({
    justifyContent: value,
  }),
  as: (value: Stitches.PropertyValue<"alignSelf">) => ({ alignSelf: value }),
  fg: (value: Stitches.PropertyValue<"flexGrow">) => ({ flexGrow: value }),
  fs: (value: Stitches.PropertyValue<"flexShrink">) => ({ flexShrink: value }),
  fb: (value: Stitches.PropertyValue<"flexBasis">) => ({ flexBasis: value }),

  /**
   * @property bc
   * @returns An abbreviated property for background-color
   */
  bc: (value: Stitches.PropertyValue<"backgroundColor">) => ({
    backgroundColor: value,
  }),

  br: (value: Stitches.PropertyValue<"borderRadius">) => ({
    borderRadius: value,
  }),

  btrr: (value: Stitches.PropertyValue<"borderTopRightRadius">) => ({
    borderTopRightRadius: value,
  }),
  bbrr: (value: Stitches.PropertyValue<"borderBottomRightRadius">) => ({
    borderBottomRightRadius: value,
  }),
  bblr: (value: Stitches.PropertyValue<"borderBottomLeftRadius">) => ({
    borderBottomLeftRadius: value,
  }),
  btlr: (value: Stitches.PropertyValue<"borderTopLeftRadius">) => ({
    borderTopLeftRadius: value,
  }),

  bs: (value: Stitches.PropertyValue<"boxShadow">) => ({ boxShadow: value }),
  tp: (value: Stitches.PropertyValue<"transitionProperty">) => ({
    transitionProperty: value,
  }),
  td: (value: Stitches.PropertyValue<"transitionDelay">) => ({
    transitionDelay: value,
  }),
  ttf: (value: Stitches.PropertyValue<"transitionTimingFunction">) => ({
    transitionTimingFunction: value,
  }),
  w: (value: number | string) => ({
    width: value,
  }),
  h: (value: number | string) => ({
    height: value,
  }),
};

// TODO: Move everything following this line to a css-helpers file

export const screenReaderOnly = css`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;

/**
 * if we have interactive components, we must have focus styles so our keyboard users can actually perceive the elements
 */
export const FOCUS_STYLES = {
  // https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible
  // We don't want a _lingering_ focus outline, so we use focus-visible (which is still visible for users who are only able to use keyboards)
  "&:focus-visible": {
    /* Draw the focus when :focus-visible is supported */
    outline: STANDARD_FOCUS_OUTLINE,
  },

  // focus-visible works on most browsers, but there are a few that do not
  // In these cases we add a fallback so there is always SOME sort of focus.
  // It is much better to have an interactive component that is ugly to some users (the lingering focus)
  // rather than something that is completely unusable for others (no focus outline/lack of perceivable navigation for users who cannot use a mouse)
  // For more information, please visit: https://www.w3.org/WAI/WCAG21/Understanding/focus-visible.html
  "@supports not selector(:focus-visible)": {
    "&:focus": {
      outline: STANDARD_FOCUS_OUTLINE,
    },
  },
};

export const TABLE_LINK_STYLE = {
  textDecoration: "underline",
  color: "#2061DC",
  fontSize: 16,
  fontWeight: 400,
  lineHeight: "24px",
  "&:hover": {
    textDecoration: "none",
  },
};

export const UNSTYLED_LIST_STYLE = {
  listStyleType: "none",
  margin: "0",
  padding: "0",
};
