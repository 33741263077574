import { styled } from "@stitches/react";
import { violet, blackA } from "@radix-ui/colors";

import * as CheckboxPrimitive from "@radix-ui/react-checkbox";

const StyledCheckbox = styled(CheckboxPrimitive.Root, {
  all: "unset",
  display: "flex",
  borderRadius: 4,
  cursor: "pointer",
  "&:focus": { boxShadow: `0 0 0 2px $neutral8` },
});

export const Root = StyledCheckbox;
export const Indicator = CheckboxPrimitive.Indicator;
