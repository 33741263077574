import { Ehr_Timeoff } from "@/graphql/generated";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Card, Modal, Table, Tooltip } from "antd";
import moment from "moment";
import "moment-timezone";
import React from "react";
import { useQueryClient } from "react-query";
import { $green, $red } from "../../assets/colors";
import { CurrentUserContext } from "../app.utils";
import { UserHasAnyPermissions } from "../_helpers/permissions";
import { Flex, Row } from "../_layout/Flex";
import { TimeoffsQueryKey } from "./api/use-get-timeoff/use-get-timeoff";
import { useDeleteTimeoffMutation } from "./api/use-get-timeoff/use-timeoff-mutations";
import { PtoTypeChoices } from "./_shared";

interface Props {
  data: Ehr_Timeoff[];
  setEditingTimeOffId: (id: number) => void;
}

function TimeOffTable(props: Props) {
  const cuser = React.useContext(CurrentUserContext);

  const isCareCoordinator = UserHasAnyPermissions(cuser, ["IsCareCoordinator"]);

  const queryClient = useQueryClient();
  const deleteTimeoff = useDeleteTimeoffMutation();

  const showConfirm = (id: number) => {
    Modal.confirm({
      title: "Are you sure you want to delete this record?",
      onOk() {
        deleteTimeoff.mutate(
          { id },
          {
            onSettled: () => queryClient.invalidateQueries([TimeoffsQueryKey]),
          },
        );
      },
    });
  };

  let data = props.data || [];
  data = data.sort((a, b) => b.id - a.id);

  const nameFilters = Array.from(
    new Set(
      data.map((timeOff: Ehr_Timeoff) => timeOff.ehr_clinician.full_name),
    ),
  )
    .sort()
    .map((name) => ({ text: name as string, value: name as string }));

  const timeSorter =
    (startOrEnd: string) => (a: Ehr_Timeoff, b: Ehr_Timeoff) => {
      const dateA = a[startOrEnd];
      const dateB = b[startOrEnd];
      if (moment(dateA).isBefore(moment(dateB))) {
        return -1;
      }
      if (moment(dateB).isBefore(moment(dateA))) {
        return 1;
      }
      return 0;
    };

  return (
    <Card bodyStyle={{ padding: "0px" }} style={{ marginBottom: "40px" }}>
      <Table
        dataSource={data}
        bordered={false}
        size={"middle"}
        pagination={{ style: { paddingRight: "20px" } }}
        rowKey={(timeOff: Ehr_Timeoff) => "row-" + timeOff.id.toString()}
      >
        <Table.Column
          title="Name"
          dataIndex={["ehr_clinician", "full_name"]}
          key="name"
          filters={nameFilters}
          onFilter={(value, timeOff: Ehr_Timeoff) =>
            timeOff.ehr_clinician.full_name === value
          }
        />
        <Table.Column
          title="Type"
          dataIndex="pto_type"
          key="pto_type"
          filters={Object.entries(PtoTypeChoices).map(
            (type: [string, string]) => ({
              text: type[1],
              value: type[0],
            }),
          )}
          onFilter={(value, timeOff: Ehr_Timeoff) => timeOff.pto_type === value}
          render={(ptoType) => PtoTypeChoices[ptoType]}
        />
        <Table.Column
          title="From"
          dataIndex="start_at"
          key="start_at"
          sorter={timeSorter("start_at")}
          render={(date, record) =>
            moment(date)
              .tz(record.ehr_clinician.primary_timezone)
              .format("ddd, MMM Do YYYY, h:mm a z")
          }
        />
        <Table.Column
          title="To"
          dataIndex="end_at"
          key="end_at"
          sorter={timeSorter("end_at")}
          render={(date, record) =>
            moment(date)
              .tz(record.ehr_clinician.primary_timezone)
              .format("ddd, MMM Do YYYY, h:mm a z")
          }
        />
        {isCareCoordinator ? null : (
          <Table.Column
            title=""
            dataIndex="id"
            key="id"
            render={(id) => (
              <Row layout={"center space-between"}>
                <Flex>
                  <Tooltip title="Edit">
                    <Button
                      type="ghost"
                      shape="circle"
                      icon={<EditOutlined />}
                      onClick={() => props.setEditingTimeOffId(id)}
                    />
                  </Tooltip>
                </Flex>
                <Flex>
                  <Tooltip title="Delete">
                    <Button
                      danger
                      shape="circle"
                      icon={<DeleteOutlined />}
                      onClick={() => showConfirm(id)}
                    />
                  </Tooltip>
                </Flex>
              </Row>
            )}
          />
        )}
      </Table>
    </Card>
  );
}

export default TimeOffTable;
