import * as React from "react";
import { PlusOutlined } from '@ant-design/icons';

import { Resource } from "../types";
import { ResourceContainer, ResourceContent } from "../styles";

import { ResourceDrawer } from "./ResourceDrawer";

interface CreateResourceFormProps {
  onUpdateResource: (resource: Resource) => void;
  setParams: (key: string, value: string) => void;
  handleDeleteResource: () => void;
  onSelectResource: (id: string) => void;
  isFormVisible: boolean;
  handleSetFormIsVisible: (isVisible: boolean) => void;
}

export const CreateResourceForm = ({
  onUpdateResource,
  setParams,
  handleDeleteResource,
  onSelectResource,
  isFormVisible,
  handleSetFormIsVisible,
}: CreateResourceFormProps) => {
  return (
    <ResourceContainer style={{ minWidth: 360 }}>
      <ResourceContent onClick={() => onSelectResource("")}>
        <PlusOutlined style={{ fontSize: 24, marginBottom: 16 }} />
        Add new resource
      </ResourceContent>
      {isFormVisible && (
        <ResourceDrawer
          resourceId=""
          handleDeleteResource={handleDeleteResource}
          setParams={setParams}
          onUpdateResource={onUpdateResource}
          onClose={() => handleSetFormIsVisible(false)}
        />
      )}
    </ResourceContainer>
  );
};
