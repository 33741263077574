import { Graph } from "@/app/_components/graph/graph"
import { ParsedClientEventMbcScoresAndSeverities } from "../_utils/mbc.util";
import { getGraphDataPointFromParsedMBCScore } from "../_utils/mbc-graph.util";
import { MBCAssessmentCustomGraphDot } from "./mbc-graph/mbc-asssessment-custom-graph-dot";
import { GroupsMBCStatusKey } from "./mbc-graph/groups-mbc-status-key";
import { store } from "@/index";

const yAxisTickIntervalsGAD7 = [0, 5, 10, 15, 20];

const customTickFormatterGAD7 = (value: number) => {
  if (value === 0) return "Minimal/(1 - 4)";
  if (value === 5) return "Mild/(5 - 9)";
  if (value === 10) return "Moderate/(10 - 14)";
  if (value === 15) return "Severe/(15 - 21)";
  return '';
}

interface MBCAssessmentGAD7GraphProps {
  eventScores: ParsedClientEventMbcScoresAndSeverities[] | null;
}

export const MBCAssessmentGAD7Graph = ({ eventScores }: MBCAssessmentGAD7GraphProps) => {
  const cuser = store.getState()?.auth?.currentUser;

  const formattedEventScores = eventScores?.map(score => getGraphDataPointFromParsedMBCScore(score, 'GAD', cuser));
  if (formattedEventScores?.length) {
    const firstGAD7Score = formattedEventScores[0].value ?? undefined;
    const firstGAD7ScoreLabel = firstGAD7Score !== undefined ? `First survey score - ${firstGAD7Score}` : undefined
    return (
      <Graph
        graphDataPoints={formattedEventScores}
        yAxisTickIntervals={yAxisTickIntervalsGAD7}
        yAxisTickFormatter={customTickFormatterGAD7}
        referenceLineValue={firstGAD7Score}
        referenceLineLabel={firstGAD7ScoreLabel}
        // After discussion with clinicians, the decision was made to keep the remissionReferenceLine at 5 for GAD/PHQ
        blueBackgroundHighlightValue={5}
        legend={GroupsMBCStatusKey}
        customGraphDot={MBCAssessmentCustomGraphDot}
      />
    );
  }
  return null;
}
