import { forwardRef } from "react";
import { IconProps } from "../icon.interface";

export const MessageIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ children, ...props }, forwardedRef) => {
    const {
      fill = "none",
      stroke = "#171717",
      width = 24,
      height = 25,
      title,
    } = props;

    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 25"
        fill={fill}
        ref={forwardedRef}
        aria-hidden={!title}
      >
        {title ? <title>{title}</title> : null}
        <path
          d="M21 12C21.0034 13.3199 20.6951 14.6219 20.1 15.8C19.3944 17.2118 18.3098 18.3992 16.9674 19.2293C15.6251 20.0594 14.0782 20.4994 12.5 20.5C11.1801 20.5035 9.87812 20.1951 8.7 19.6L3 21.5L4.9 15.8C4.30493 14.6219 3.99656 13.3199 4 12C4.00061 10.4218 4.44061 8.87488 5.27072 7.53258C6.10083 6.19028 7.28825 5.1056 8.7 4.40003C9.87812 3.80496 11.1801 3.49659 12.5 3.50003H13C15.0843 3.61502 17.053 4.49479 18.5291 5.97089C20.0052 7.44699 20.885 9.41568 21 11.5V12Z"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);
