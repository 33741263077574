import React from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownButton,
  ComposedDropdownMenuCheckboxItem,
  DropdownMenuPrimitiveItem,
} from "../../../../../../../../design-system/dropdown/dropdown.styled";

import { DownChevronIcon } from "../../../../../../../../design-system/icons";

import {
  Text,
  Stack,
  Flex,
  Button,
} from "../../../../../../../../design-system";

import { useClientStatusFiltersDropdown } from "./use-client-status-filters";

export const ClientStatusFilterDropdown = () => {
  const {
    isDropdownOpen,
    setIsDropdownOpen,
    setMyClientsFilter,
    isFilteringProspectiveClients,
    isFilteringOnActiveClients,
    isFilteringOnInactiveClients,
    isFilteringOnNewClients,
    isFilteringOnPendingClients,
    clearClientStatusFilters,
  } = useClientStatusFiltersDropdown();

  return (
    <DropdownMenu open={isDropdownOpen}>
      <DropdownMenuTrigger asChild onClick={() => setIsDropdownOpen(true)}>
        <DropdownButton aria-label="client status filter options">
          <Text>Client Status</Text>

          <DownChevronIcon className="caret-icon" />
        </DropdownButton>
      </DropdownMenuTrigger>

      <DropdownMenuContent
        sideOffset={5}
        align={"start"}
        loop
        onInteractOutside={() => setIsDropdownOpen(false)}
      >
        <Stack css={{ pb: 8 }}>
          <ComposedDropdownMenuCheckboxItem
            checked={isFilteringProspectiveClients}
            onCheckedChange={() =>
              setMyClientsFilter(
                "isFilteringProspectiveClients",
                !isFilteringProspectiveClients,
              )
            }
          >
            Prospective
          </ComposedDropdownMenuCheckboxItem>

          <ComposedDropdownMenuCheckboxItem
            checked={isFilteringOnPendingClients}
            onCheckedChange={() =>
              setMyClientsFilter(
                "isFilteringOnPendingClients",
                !isFilteringOnPendingClients,
              )
            }
          >
            Pending
          </ComposedDropdownMenuCheckboxItem>

          <ComposedDropdownMenuCheckboxItem
            checked={isFilteringOnNewClients}
            onCheckedChange={() =>
              setMyClientsFilter(
                "isFilteringOnNewClients",
                !isFilteringOnNewClients,
              )
            }
          >
            New
          </ComposedDropdownMenuCheckboxItem>

          <ComposedDropdownMenuCheckboxItem
            checked={isFilteringOnActiveClients}
            onCheckedChange={() =>
              setMyClientsFilter(
                "isFilteringOnActiveClients",
                !isFilteringOnActiveClients,
              )
            }
          >
            Active
          </ComposedDropdownMenuCheckboxItem>

          <ComposedDropdownMenuCheckboxItem
            checked={isFilteringOnInactiveClients}
            onCheckedChange={() =>
              setMyClientsFilter(
                "isFilteringOnInactiveClients",
                !isFilteringOnInactiveClients,
              )
            }
          >
            Inactive
          </ComposedDropdownMenuCheckboxItem>
        </Stack>

        <Flex
          justifyContent={"space-between"}
          css={{ borderTop: "1px solid $neutral3", pt: 10, pl: 0 }}
        >
          <DropdownMenuPrimitiveItem css={{ fontSize: 14 }} asChild>
            <Button
              variant={"tertiary"}
              size={"medium"}
              onClick={clearClientStatusFilters}
            >
              Clear all
            </Button>
          </DropdownMenuPrimitiveItem>
        </Flex>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
