import { Stack } from "@/app/design-system";

import { useShallowEqualSelector } from "@/app/_helpers/redux";

import { useAppPermissions } from "../../hooks/use-app-permissions";
import { ConsentSurveyMenuItem } from "./components/1-consent-survey-main-navigation-menu-item";
import { AQMTuningMenuItem } from "./components/2-aqm-tuning-menu-item";
import { ShoppingCartMenuItem } from "./components/3-shopping-cart-menu-item";
import { MyMatchTagsMenuItem } from "./components/4-my-match-tags-menu-item";
import { MatchAdminToolMenuItem } from "./components/5-match-admin-tool-menu-item";
import { PrivacyAndLogoutMenuItems } from "./components/6-privacy-and-logout-menu-items";

interface BottomMainNavigationMenuItemsProps {
  isCollapsed: boolean;
}

export function BottomMainNavigationMenuItems({
  isCollapsed,
}: BottomMainNavigationMenuItemsProps) {
  const {
    hasAQMTuningPermissions,
    hasConsentSurveyPermissions,
    hasMatchAdminToolPermissions,
    hasShoppingCartPermissions,
    currentUserClinician,
  } = useAppPermissions();

  const [currentUser] = useShallowEqualSelector((state) => [
    state.auth.currentUser,
    state.options.doMangle,
  ]);

  return (
    <>
      {hasConsentSurveyPermissions ? (
        <li>
          <ConsentSurveyMenuItem isCollapsed={isCollapsed} />
        </li>
      ) : null}

      {hasAQMTuningPermissions ? (
        <li>
          <AQMTuningMenuItem isCollapsed={isCollapsed} />
        </li>
      ) : null}

      {hasShoppingCartPermissions ? (
        <li>
          <ShoppingCartMenuItem isCollapsed={isCollapsed} />
        </li>
      ) : null}

      {currentUserClinician ? (
        <li>
          <MyMatchTagsMenuItem isCollapsed={isCollapsed} />
        </li>
      ) : null}

      {hasMatchAdminToolPermissions ? (
        <li>
          <MatchAdminToolMenuItem isCollapsed={isCollapsed} />
        </li>
      ) : null}

      {currentUser ? (
        <PrivacyAndLogoutMenuItems isCollapsed={isCollapsed} />
      ) : null}
    </>
  );
}
