/**
 * @component Spacer
 * @description Provide empty space.
 */

import { styledStitches } from "@/app/design-system/styles/stitches.config";

export const Spacer = styledStitches("span", {
  size: "1px",
  variants: {
    inline: {
      true: {
        display: "inline-block",
      },
      false: {
        display: "block",
      },
    },
    x: {
      8: {
        marginRight: 8,
      },
      16: {
        marginRight: 16,
      },
      24: {
        marginRight: 24,
      },
    },
    y: {
      8: {
        marginBottom: 8,
      },
      16: {
        marginBottom: 16,
      },
      24: {
        marginBottom: 24,
      },
      25: {
        marginBottom: 25,
      },
    },
  },
  defaultVariants: {
    inline: false,
  },
});
