import { AxiosResponse } from "axios";
import { produce } from "immer";
import {
  actionFactory,
  ActionUnion,
  payloadAction,
  simpleAction,
} from "reductser";

import api, { wrapApiCall } from "../../../api";
import {
  APICurrentUser,
  CurrentUser,
  UserGroup,
  Clinician,
} from "../../../api/types";
import { UserHasAnyPermissions } from "../../../app/_helpers/permissions";
import { panelActions } from "../panel-management";
import {
  assertUnreachable,
  AsyncActionWithResultCreator,
  AsyncOperations,
} from "../types";
import TagManager from "react-gtm-module";
import { GOOGLE_TAG_MANAGER_ID } from "@/api/constants";
import { logFreshpaintEvent } from "@/app/utils/freshpaint.util";

export enum LoginError {
  USER_LOGIN_INVALID_CREDENTIALS = "Couldn't log you in, is there a typo?",
  USER_LOGIN_UNKNOWN_ERROR = "Couldn't log you in, is there a typo?",
  USER_CREDENTIALS_EXPIRED = "Your session expired. Please log in again.",
}

const actionMap = {
  loginResult: payloadAction<LoginResult>(),
  logout: simpleAction(),
  setMyTeamClinicianIds: payloadAction<number[]>(),
  getCurrentUser: simpleAction(),
};

export const authActions = actionFactory(actionMap, "AUTH");

export type AuthAction = ActionUnion<typeof authActions>;

export type LoginResult =
  | {
      isSuccess: true;
      currentUser: CurrentUser;
      loginError: null;
    }
  | {
      isSuccess: false;
      loginError: string;
      currentUser: null;
    };

export interface AuthState {
  isAuthenticated: boolean;
  loginError?: string;
  currentUser: CurrentUser | null;
  myTeamClinicianIds: number[];
}

export function getInitialState(): AuthState {
  return {
    currentUser: null,
    isAuthenticated: false,
    myTeamClinicianIds: [],
  };
}

const authReducer = (state = getInitialState(), action: AuthAction) =>
  produce(state, (draft) => {
    if (action.reducer === "AUTH") {
      switch (action.type) {
        case "setMyTeamClinicianIds":
          draft.myTeamClinicianIds = action.payload;
          return;
        case "getCurrentUser":
          return;
        case "loginResult":
          if (action.payload.isSuccess === true) {
            draft.isAuthenticated = true;
            draft.currentUser = action.payload.currentUser;
          } else {
            draft.loginError = action.payload.loginError;
          }
          return;
        case "logout":
          draft.isAuthenticated = false;
          draft.currentUser = null;
          return;
        default:
          assertUnreachable(action);
          return;
      }
    }
  });

export default authReducer;

export const getCurrentUser: AsyncActionWithResultCreator<CurrentUser | void> =
  () => (dispatch) => {
    return api
      .get("/ehr/auth/logged_in_user")
      .then((res: AxiosResponse<APICurrentUser>) => {
        localStorage.setItem("isLoggedIn", "true");
        const currentUser: CurrentUser = {
          username: res.data.username,
          email: res.data.email,
          groups: res.data.groups.map((group) => group.name as UserGroup),
          first_name: res.data.first_name,
          last_name: res.data.last_name,
          clinician: res.data.clinician,
          isSuperUser: res.data.is_superuser,
          permissions: res.data.permissions,
          id: res.data.id,
        };
        dispatch(
          authActions.loginResult({
            isSuccess: true,
            currentUser,
            loginError: null,
          }),
        );

        const tagManagerArgs: {
          gtmId: string;
          dataLayer: {
            authUserId: number;
            email: string;
            userGroups: string[];
            clinicianId?: number;
          };
        } = {
          gtmId: GOOGLE_TAG_MANAGER_ID,
          dataLayer: {
            authUserId: currentUser.id,
            email: currentUser.email,
            userGroups: currentUser.groups,
            clinicianId: undefined,
          },
        };

        if (currentUser.clinician) {
          dispatch(panelActions.setClinicianId(currentUser.clinician.id));

          tagManagerArgs.dataLayer.clinicianId = currentUser.clinician.id;

          logFreshpaintEvent("clinician_authenticated");

          if (UserHasAnyPermissions(currentUser, ["IsClinicalLeader"])) {
            dispatch(
              authOperations.getClinicianReports(currentUser.clinician.id),
            );
          }
        }

        TagManager.dataLayer(tagManagerArgs);

        return currentUser;
      })
      .catch(() => {
        if (localStorage.getItem("isLoggedIn")) {
          dispatch(authOperations.expire());
        }
      });
  };

export const authOperations: AsyncOperations = {
  getClinicianReports: (clinicianId: number) => async (dispatch) => {
    await wrapApiCall(
      api.get(`/api/clinicians/v1/${clinicianId}/reports/`),
      dispatch,
    )
      .then((res: AxiosResponse<Clinician[]>) => {
        const { data } = res;
        const cliniciansMap = {};
        const clinicianIds = data.map((d) => {
          cliniciansMap[d.id] = d;
          return d.id;
        });
        dispatch(authActions.setMyTeamClinicianIds(clinicianIds));
      })
      .catch((e) => {
        console.error(e);
      });
  },
  googleLogin: (token: string, error?: string) => (dispatch) => {
    if (error) {
      return dispatch(
        authActions.loginResult({
          isSuccess: false,
          currentUser: null,
          loginError: `Couldn't log you in: ${error}`,
        }),
      );
    }
    return api
      .post("/auth/google/", { access_token: token })
      .then(() => {
        dispatch(getCurrentUser());
      })
      .catch(() => {
        dispatch(
          authActions.loginResult({
            isSuccess: false,
            loginError: LoginError.USER_LOGIN_INVALID_CREDENTIALS,
            currentUser: null,
          }),
        );
      });
  },
  legacyLogin: (username: string, password: string) => (dispatch) => {
    return api
      .post("/auth/login/", { username, password })
      .then(() => {
        dispatch(getCurrentUser());
      })
      .catch(() =>
        dispatch(
          authActions.loginResult({
            isSuccess: false,
            loginError: LoginError.USER_LOGIN_INVALID_CREDENTIALS,
            currentUser: null,
          }),
        ),
      );
  },
  expire: () => (dispatch) => {
    dispatch(authOperations.logout());
    dispatch(
      authActions.loginResult({
        isSuccess: false,
        loginError: LoginError.USER_CREDENTIALS_EXPIRED,
        currentUser: null,
      }),
    );
  },
  logout: () => (dispatch) => {
    localStorage.removeItem("isLoggedIn");
    api.post("/auth/logout/");
    dispatch(authActions.logout());
  },
};
