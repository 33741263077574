import React, { useCallback } from "react";

import { Subregion } from "../../../../../api/types";
import { useShallowEqualSelector } from "../../../../_helpers/redux";
import { useDispatch } from "react-redux";
import {
  matchmakerOperations,
  matchmakerUtils,
} from "../../../../../state/models/matchmaker";
import { LocationContainer, styles, LocationRowHeader } from "./styled";
import { CloseOutlined } from "@ant-design/icons";
import { Select as DeprecatedAntdSelect, Checkbox, Button, Popconfirm } from "antd";
import { Row, Flex } from "../../../../_layout/Flex";
import SlotsByDayCheckboxTable from "./SlotsByDayCheckboxTable";

export default ({ subregion }: { subregion: Subregion }) => {
  const [subregionMSP] = useShallowEqualSelector((state) => [
    state.matchmaker.matchSlotPreferences[subregion.id],
  ]);
  const { showOtFits } = useShallowEqualSelector((state) =>
    matchmakerUtils.matchOtSelector(state.matchmaker),
  );

  const dispatch = useDispatch();
  const changeSubregionPreference = useCallback(
    (subregionId: number, pref: "strong" | "open" | null) =>
      dispatch(
        matchmakerOperations.changeSubregionPreference(subregionId, pref),
      ),
    [dispatch],
  );

  const toggleUseGeneral = useCallback(
    (useGeneral: boolean) =>
      dispatch(matchmakerOperations.toggleUseGeneral(subregion.id, useGeneral)),
    [dispatch, subregion],
  );

  return (
    <LocationContainer>
      <Row layout="space-between center" style={styles.locationRowStyle}>
        <LocationRowHeader>{subregion.display_name}</LocationRowHeader>
        <Flex />
        <DeprecatedAntdSelect<"strong" | "open">
          onChange={(val: "strong" | "open") =>
            changeSubregionPreference(subregion.id, val)
          }
          style={{ width: 200 }}
          defaultValue={subregionMSP.location_preference_level}
        >
          <DeprecatedAntdSelect.Option value="strong">
            Strong Preference
          </DeprecatedAntdSelect.Option>
          <DeprecatedAntdSelect.Option value="open">
            Open to this Location
          </DeprecatedAntdSelect.Option>
        </DeprecatedAntdSelect>
        <Checkbox
          style={{ marginLeft: "15px" }}
          checked={subregionMSP.use_general_slots}
          onChange={() => toggleUseGeneral(!subregionMSP.use_general_slots)}
        >
          Use {showOtFits ? "Teletherapy" : "General"} Schedule Preferences
        </Checkbox>
        <Popconfirm
          title={`Remove ${subregion.display_name} from this client's location preferences?`}
          placement="topRight"
          onConfirm={() => changeSubregionPreference(subregion.id, null)}
          okText="Yes"
          cancelText="No"
        >
          <Button type="link" icon={<CloseOutlined />} />
        </Popconfirm>
      </Row>
      {!subregionMSP.use_general_slots && (
        <SlotsByDayCheckboxTable stateKey={subregion.id} />
      )}
    </LocationContainer>
  );
};
