import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import { NavigationLink } from "./nav-link.styled";
import { NavigationToolTip } from "./navigation-tooltip";

interface NavigationMenuItemProps {
  isCollapsed: boolean;
  children: React.ReactNode;
  to: string;
  menuItemTooltipContent: string;
}

export function NavigationMenuItem({
  isCollapsed,
  children,
  menuItemTooltipContent,
  to,
}: NavigationMenuItemProps) {
  return (
    <NavigationMenu.Item>
      <NavigationToolTip
        content={menuItemTooltipContent}
        shouldRenderContent={isCollapsed}
      >
        <NavigationLink to={to} isCollapsed={isCollapsed}>
          {children}
        </NavigationLink>
      </NavigationToolTip>
    </NavigationMenu.Item>
  );
}
