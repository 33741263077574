import React, { useState } from "react";

import { Button, Divider, Spin } from "antd";
import { Title } from "../../app/_layout/display";
import { Row } from "../_layout/Flex";
import ListContainer from "../_shared/ListContainer";

import useTitle from "../hooks/useTitle";
import EditTimeOffModal from "./EditTimeOffModal";
import TimeOffModal from "./TimeOffModal";
import TimeOffTable from "./TimeOffTable";
import { useGetTimeoffs } from "./api/use-get-timeoff/use-get-timeoff";

function TimeOffComponent() {
  useTitle("Time Off Records");
  const { data: timeoffs, isLoading } = useGetTimeoffs({ enabled: true });

  const [displayTimeOffModal, setDisplayTimeOffModal] = useState(false);
  const [editingTimeOffId, setEditingTimeOffId] = useState(0);

  return (
    <>
      {editingTimeOffId !== 0 && (
        <EditTimeOffModal
          editingTimeOff={timeoffs?.find((obj) => obj.id === editingTimeOffId)!}
          onClose={() => setEditingTimeOffId(0)}
        />
      )}
      {displayTimeOffModal && (
        <TimeOffModal onClose={() => setDisplayTimeOffModal(false)} />
      )}
      <ListContainer>
        <Spin spinning={isLoading} style={{ minHeight: "500px" }}>
          <Row layout={"start center"} style={{ width: "50%" }}>
            <Divider orientation="left">
              <Title margin="0px">Time Off Records</Title>
            </Divider>
            <Divider orientation="right">
              <Button
                style={{ float: "right" }}
                size="large"
                onClick={() => setDisplayTimeOffModal(true)}
              >
                Add Time Off
              </Button>
            </Divider>
          </Row>
          <TimeOffTable
            data={timeoffs!}
            setEditingTimeOffId={setEditingTimeOffId}
          />
        </Spin>
      </ListContainer>
    </>
  );
}

export default TimeOffComponent;
