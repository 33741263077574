import React, { useMemo, useState } from "react";
import { useShallowEqualSelector } from "../../_helpers/redux";
import { slotArraysMatch } from "../../_helpers";
import { Title } from "../../_layout/display";
import {
  IdMap,
  DayTime,
  Time,
  Day,
  MatchSlotPreference,
  Subregion,
  SlotPref,
  BasicMatch,
  ExtendedMatch,
  ClinicianSlot,
} from "../../../api/types";
import { Row } from "../../_layout/Flex";
import {
  ExclamationCircleFilled,
  QuestionCircleOutlined,
  ShopOutlined,
} from "@ant-design/icons";
import {
  Tag as AntdTag,
  Tooltip,
  Select as DeprecatedAntdSelect,
  Badge,
  Popover,
  Table as AntdTable,
} from "antd";
import {
  $border,
  $forestGreen,
  $green,
  $secondary,
  $lightYellow,
} from "../../../assets/colors";
import { SlotPrefsContainer } from "../Matchmaker/1-ClientStep/MatchSlotPreferences/styled";
import { times, days } from "../../_helpers/constants";
import { isEmpty, uniq } from "lodash-es";
import { useDispatch } from "react-redux";
import { getSlotAvailabilities } from "../../../state/models/clinicians/operations";
import * as colors from "../../../assets/colors";
import { HighAcuityFitList } from "./MyMatchesTableItem/HighAcuityFitList";
import ShoppingCartButton from "./ShoppingCartButton";
import {
  IfPermitted,
  UserHasAllPermissions,
  useUserHasAnyPermissions,
} from "../../_helpers/permissions";
import {
  TableWrapper,
  TABLE_WIDTH,
  BoxWrapper,
  BoxContent,
} from "../../_shared/ClickboxTable/styled";
import moment from "moment";
import { Table, Td, Text, Tr } from "@/app/design-system";
import { Timezone } from "@/app/_shared/TimezoneContext";
import { DateTime } from "luxon";
import {
  ExpandedHighAcuityClinicianFitList,
  useGetHighAcuityFitList,
} from "../api/use-get-high-acuity-fit-list/use-get-high-acuity-fit-list";
import ClinicianStatus from "@/app/_shared/ClinicianStatus";

const DisplayMatchSlotPreferencesWrapper = ({
  match,
  clinicianIds,
}: {
  match: BasicMatch | ExtendedMatch;
  clinicianIds?: number[];
}) => {
  const dispatch = useDispatch();

  const [
    clinicianToSlotMap,
    loadingSlotAvailabilities,
    matchSlotPreferences,
    subregionMap,
  ] = useShallowEqualSelector((state) => [
    state.clinicians.clinicianToSlotMap,
    state.clinicians.loadingSlotAvailabilities,
    state.matches.matchSlotPreferenceMap[match.id],
    state.clinics.subregionMap,
  ]);

  if (isEmpty(matchSlotPreferences) || isEmpty(subregionMap)) {
    return null;
  }

  const clinicianSlotsToLoad = clinicianIds?.filter(
    (clinicianId) => clinicianToSlotMap[clinicianId] === undefined,
  );

  if (
    useUserHasAnyPermissions(["IsMatchingAdmin"]) &&
    !loadingSlotAvailabilities &&
    clinicianSlotsToLoad?.length
  ) {
    dispatch(getSlotAvailabilities(clinicianSlotsToLoad));
  }

  return (
    <DisplayMatchSlotPreferences
      clinicianIds={clinicianIds || []}
      match={match}
      matchSlotPreferences={matchSlotPreferences}
      subregionMap={subregionMap}
    />
  );
};

interface Props {
  clinicianIds: number[];
  match: BasicMatch | ExtendedMatch | any;
  matchSlotPreferences: MatchSlotPreference[];
  subregionMap: IdMap<Subregion>;
}

const DisplayMatchSlotPreferences = ({
  clinicianIds,
  match,
  matchSlotPreferences,
  subregionMap,
}: Props) => {
  const { generalMsps, subregionMsps } = useMemo(() => {
    const subregionMsps: IdMap<{
      subregion: number | null;
      use_general: boolean;
      preference_level: "strong" | "open" | null;
      slots: SlotPref[];
    }> = {};
    const generalMsps: SlotPref[] = [];
    if (!matchSlotPreferences) return { generalMsps, subregionMsps };
    matchSlotPreferences.forEach((msp) => {
      if (msp.is_general) {
        generalMsps.push({
          day: msp.day_of_week,
          time: msp.start_time as Time,
          pref_level: msp.is_maybe_slot ? "maybe" : "preferred",
        });
      } else {
        const subregion: string = msp.subregion
          ? msp.subregion.toString()
          : "teletherapy";
        if (!(subregion in subregionMsps)) {
          subregionMsps[subregion] = {
            subregion: msp.subregion,
            use_general: false,
            preference_level: msp.location_preference_level,
            slots: [],
          };
        }
        subregionMsps[subregion].slots.push({
          day: msp.day_of_week,
          time: msp.start_time as Time,
          pref_level: msp.is_maybe_slot ? "maybe" : "preferred",
        });
      }
    });

    // Set whether it matches 'general schedule prefs'
    Object.keys(subregionMsps).forEach((subregion) => {
      subregionMsps[subregion].use_general =
        !isEmpty(generalMsps) &&
        !isEmpty(subregionMsps[subregion].slots) &&
        slotArraysMatch(generalMsps, subregionMsps[subregion].slots);
    });

    return { generalMsps, subregionMsps };
  }, [matchSlotPreferences]);

  const options: string[] = [];
  const subregionMspsUsingGeneral = Object.values(subregionMsps).filter(
    (msp) => msp.use_general,
  );
  const subregionsUsingGeneral = uniq(
    subregionMspsUsingGeneral.map((msp) => msp.subregion),
  );
  const subregionMspsWithParticularPrefs = Object.values(subregionMsps).filter(
    (msp) => !msp.use_general,
  );
  const generalSchedulePrefsTitle = subregionMspsUsingGeneral
    .map((msp) =>
      msp.subregion === null
        ? "Teletherapy"
        : subregionMap[msp.subregion]?.display_name,
    )
    .join(", ");

  if (!isEmpty(subregionMspsUsingGeneral)) {
    options.push("general");
  }
  subregionMspsWithParticularPrefs.forEach((msp) =>
    options.push(
      msp.subregion === null ? "teletherapy" : msp.subregion.toString(),
    ),
  );

  const showScheduleSelector = options.length > 1;
  const [scheduleKey, updateScheduleKey] = useState(options[0]);

  if (isEmpty(subregionMsps) || isEmpty(subregionMap)) {
    return null;
  }
  const timezone =
    match.client?.primary_timezone ?? match.ehr_client.primary_timezone;

  return (
    <div style={{ marginBottom: "10px" }}>
      <Title size="xs">
        Location Preferences{" "}
        <Tooltip title="Yellow indicates strong preference for this location.">
          <QuestionCircleOutlined />
        </Tooltip>
      </Title>
      <Row layout="start center">
        {Object.keys(subregionMsps).map((subregion) =>
          subregion === "teletherapy" ? (
            <LocationTag
              key={subregion + "_Tag"}
              name={"Teletherapy"}
              preference={"strong"}
            />
          ) : (
            <LocationTag
              key={subregion + "_Tag"}
              name={subregionMap[subregion].display_name}
              preference={subregionMsps[subregion].preference_level}
            />
          ),
        )}
      </Row>
      <Title size="xs">Schedule Preferences</Title>
      <Text color={"$neutral11"} fontSize={14} notFlex>
        Client's timezone is <b>{timezone}</b>.
      </Text>
      <SlotPrefsContainer style={{ paddingTop: "0px", paddingBottom: "0px" }}>
        {showScheduleSelector && (
          <DeprecatedAntdSelect<string>
            onChange={(val) => updateScheduleKey(val)}
            style={{ width: TABLE_WIDTH, marginBottom: "10px" }}
            defaultValue={scheduleKey}
          >
            {generalSchedulePrefsTitle && (
              <DeprecatedAntdSelect.Option value="general">
                {generalSchedulePrefsTitle} Schedule Preferences
              </DeprecatedAntdSelect.Option>
            )}
            {Object.values(subregionMsps)
              .filter((msp) => !msp.use_general)
              .map((msp) => (
                <DeprecatedAntdSelect.Option
                  key={msp.subregion + "_SelectOption"}
                  value={msp.subregion!.toString()}
                >
                  {subregionMap[msp.subregion!].display_name} Schedule
                  Preferences
                </DeprecatedAntdSelect.Option>
              ))}
          </DeprecatedAntdSelect>
        )}
        <SchedulePreferencesTable
          clinicianIds={clinicianIds}
          match={match}
          subregions={
            scheduleKey === "general"
              ? subregionsUsingGeneral
              : [parseInt(scheduleKey)]
          }
          slots={
            scheduleKey === "general"
              ? generalMsps
              : subregionMsps[scheduleKey].slots
          }
        />
      </SlotPrefsContainer>
    </div>
  );
};

const LocationTag = ({
  name,
  preference,
}: {
  name: string;
  preference: "strong" | "open" | null;
}) => (
  <AntdTag
    style={{
      cursor: "default",
      marginBottom: "10px",
      color: "inherit",
    }}
    color={preference === "strong" ? $secondary : ""}
  >
    {preference && <ShopOutlined />} {name}
  </AntdTag>
);

/**
 * Given an array of ClinicianSlots, creates a `slotToClinicianMap` where all slots are localized to targetTimezone.
 */
function createMapOfLocalizedSlots(
  clinicianSlots: ClinicianSlot[],
  targetTimezone: Timezone,
): Map<string, ClinicianSlot[]> {
  const slotToClinicianMap = new Map();

  clinicianSlots.forEach((slot) => {
    let localized_start_time = slot.start_time;
    if (slot.timezone !== targetTimezone) {
      const slotDateTime = DateTime.fromISO(
        `${slot.start_date}T${slot.start_time}`,
        { zone: slot.timezone },
      );
      const localizedSlotDateTime = slotDateTime.setZone(targetTimezone);
      localized_start_time = localizedSlotDateTime.toLocaleString(
        DateTime.TIME_24_WITH_SECONDS,
      ) as Time;
    }

    const key = slot.day_of_week + localized_start_time;

    if (slotToClinicianMap.has(key)) {
      slotToClinicianMap.get(key).push(slot);
    } else {
      slotToClinicianMap.set(key, [slot]);
    }
  });

  return slotToClinicianMap;
}

const SchedulePreferencesTable = ({
  clinicianIds,
  match,
  subregions,
  slots,
}: {
  clinicianIds: number[];
  match: BasicMatch | ExtendedMatch | any;
  subregions: (number | null)[];
  slots: SlotPref[];
}) => {
  const [clinicianMap, matchFits, clinicianToSlotsMap, suggestedMatch] =
    useShallowEqualSelector((state) => [
      state.clinicians.clinicianMap,
      state.matches.matchFitsMap[match.id] || [],
      state.clinicians.clinicianToSlotMap,
      state.matches.matchSuggestions[match.id],
    ]);
  const timezone =
    match.client?.primary_timezone ?? match.ehr_client.primary_timezone;
  // Localize each clinician's slots to this client's timezone.
  // We don't store a full list of slots anywhere, so just flatten one of our maps.
  const slotToClinicianMap = createMapOfLocalizedSlots(
    Object.values(clinicianToSlotsMap).flat(),
    timezone,
  );

  const clinicianFitMap = matchFits.reduce((fitMap, fit) => {
    fitMap[fit.clinician] = fit;
    return fitMap;
  }, {});

  const getSlotColor = (slot: DayTime) => {
    const slotMatch = slots.find(
      (s) => s.day === slot.day && s.time === slot.time,
    );
    if (slotMatch?.pref_level === "preferred") {
      if (
        suggestedMatch?.day_of_week === slot.day &&
        suggestedMatch?.start_time === slot.time
      ) {
        return $forestGreen;
      }
      return $green;
    }
    if (slotMatch?.pref_level === "maybe") {
      if (
        suggestedMatch?.day_of_week === slot.day &&
        suggestedMatch?.start_time === slot.time
      ) {
        return $secondary;
      }
      return $lightYellow;
    }
    return "";
  };



  const getDayHeaderColor = (day: Day) => {
    const daysSelected = slots.filter((s) => s.day === day).length;
    return daysSelected === times.length
      ? $secondary
      : daysSelected > 0
      ? $border
      : "";
  };
  const getTimeHeaderColor = (time: Time) => {
    const timesSelected = slots.filter((s) => s.time === time).length;
    return timesSelected === days.length
      ? $secondary
      : timesSelected > 0
      ? $border
      : "";
  };

  const clinicianIsAFit = (slot: ClinicianSlot) => {
    const clinicianIsAFit = clinicianIds.includes(slot.clinician_id);
    const subregionIsAFit = subregions.includes(slot.subregion_id);
    const matchIsTeletherapy = subregions.includes(null);
    const clinicianIsTeletherapy =
      clinicianMap[slot.clinician_id]?.enable_ot_matches;
    return (
      clinicianIsAFit &&
      (subregionIsAFit || (matchIsTeletherapy && clinicianIsTeletherapy))
    );
  };

  const getSlotHoverData = (
    day: Day,
    time: Time,
    highAcuityFitList?: ExpandedHighAcuityClinicianFitList[],
  ) => {
    const slots = slotToClinicianMap
      .get(day + time)
      ?.filter(clinicianIsAFit)
      .sort((a, b) => a.start_date.localeCompare(b.start_date));
    const highAcuityClinicianSlots = slots?.filter(
      (slot) =>
        highAcuityFitList !== undefined &&
        highAcuityFitList
          .filter((c) => c.is_ha_eligible)
          .map((c) => c.id)
          .includes(slot.clinician_id),
    );

    return {
      numSlots: slots?.length || 0,
      numHighAcuityClinicians: highAcuityClinicianSlots?.length || 0,
      table: (
        <Table>
          {slots?.map((slot) => {
            const clinician = clinicianMap[slot.clinician_id];
            const fit = clinicianFitMap[slot.clinician_id];
            const isHighAcuityEligible =
              highAcuityClinicianSlots?.includes(slot);
            return (
              <Tr key={slot.clinician_id}>
                <Td style={{ paddingRight: "12px" }}>
                  {isHighAcuityEligible ? "🚨 " : ""}
                  {clinician.first_name} {clinician.last_name}
                </Td>
                <Td style={{ textTransform: "capitalize" }}>
                  {slot.recurrence}, {slot.start_date}
                </Td>
                <Td>
                  {slot.timezone !== timezone
                    ? `(${DateTime.fromISO(
                        `${slot.start_date}T${slot.start_time}`,
                        { zone: slot.timezone },
                      ).toLocaleString({
                        ...DateTime.TIME_WITH_SHORT_OFFSET,
                        second: undefined,
                      })})`
                    : null}
                </Td>
                <Td style={{ padding: "0 8px" }} className="status">
                  <ClinicianStatus clinicianId={clinician.id} />
                </Td>
                <Td>
                  <ShoppingCartButton fit={fit} slot={slot} />
                </Td>
              </Tr>
            );
          })}
        </Table>
      ),
    };
  };

  // fill matchingSlots with a mapping from clinician_id to the earliest start date & time that
  // they have in common with the client
  const matchingSlots = {};

  // additionally, keep track of all of the slots for display later
  const matchingSlotsList: { [id: number]: ClinicianSlot[] } = {};
  slots.forEach((slot) => {
    const clinicianSlots = slotToClinicianMap
      .get(slot.day + slot.time)
      ?.filter(clinicianIsAFit);
    clinicianSlots?.forEach((slot) => {
      if (!matchingSlots[slot.clinician_id]) {
        // if there's nothing for this clinician in the object yet, just add it it
        matchingSlots[slot.clinician_id] = {
          start_date: slot.start_date,
          start_time: slot.start_time,
        };
        matchingSlotsList[slot.clinician_id] = [slot];
      } else {
        // otherwise, compare start dates and add the new one if it's earlier.
        // we're not worrying about time here, just the earliest start_date is fine
        // regardless of the time
        const existing_date = matchingSlots[slot.clinician_id].start_date;
        if (
          DateTime.fromISO(slot.start_date) < DateTime.fromISO(existing_date)
        ) {
          matchingSlots[slot.clinician_id] = {
            start_date: slot.start_date,
            start_time: slot.start_time,
          };
        }
        matchingSlotsList[slot.clinician_id].push(slot);
      }
    });
  });

  const fitClinicianIds = Object.keys(matchingSlots).map((i) => parseInt(i));
  const { data: highAcuityFitList } = useGetHighAcuityFitList({
    matchId: match.id,
    clinicianIds: fitClinicianIds,
    enabled: match.priority === "high_acuity" && fitClinicianIds.length > 0,
  });


  return (
    <>
      <Row layout="flex-start flex-start">
        <TableWrapper>
          <Row layout="start stretch">
            <TableBox />
            {days.map((day) => (
              <TableBox
                key={day + "_header"}
                label={day}
                color={getDayHeaderColor(day)}
              />
            ))}
          </Row>
          {times.map((time) => (
            <Row layout="start stretch" key={time + "header"}>
              <TableBox
                key={time + "_header"}
                label={moment(time, "HH:mm:ss").format("ha")}
                color={getTimeHeaderColor(time)}
              />
              {days.map((day) => (
                <TableBox
                  key={day + time + "_box"}
                  color={getSlotColor({
                    time,
                    day,
                  })}
                  openClinicians={getSlotHoverData(
                    day,
                    time,
                    highAcuityFitList,
                  )}
                />
              ))}
            </Row>
          ))}

          <div style={{ fontSize: "10px", marginTop: "3px" }}>
            <i>
              * Green boxes represent times that the client <strong>can</strong>{" "}
              make it to an appointment.
            </i>
            <br />
            <i>
              * Times are shown in the client's timezone,{" "}
              <strong>{timezone}</strong>.
            </i>
          </div>
        </TableWrapper>
        <IfPermitted permissions={["IsMatchingAdmin"]} requireAll={true}>
          <ListOfSlotsTable
            matchingSlotsList={matchingSlotsList}
            highAcuityFitList={highAcuityFitList}
            clinicianFitMap={clinicianFitMap}
          />
        </IfPermitted>
      </Row>
      {match.priority === "high_acuity" &&
        Object.keys(matchingSlots).length > 0 && (
          <HighAcuityFitList
            matchId={match.id}
            clinicianSlots={matchingSlots}
          />
        )}
    </>
  );
};

const ListOfSlotsTable = ({
  matchingSlotsList,
  highAcuityFitList,
  clinicianFitMap,
}: {
  matchingSlotsList: { [id: number]: ClinicianSlot[] };
  highAcuityFitList?: ExpandedHighAcuityClinicianFitList[];
  clinicianFitMap: {};
}) => {
  const flattenedList = Object.values(matchingSlotsList)
    .flat()
    .sort((a, b) => a.start_date.localeCompare(b.start_date));

  const [clinicianMap] = useShallowEqualSelector((state) => [
    state.clinicians.clinicianMap,
  ]);

  const highAcuityClinicianSlots = flattenedList.filter(
    (slot) =>
      highAcuityFitList !== undefined &&
      highAcuityFitList
        .filter((c) => c.is_ha_eligible)
        .map((c) => c.id)
        .includes(slot.clinician_id),
  );

  return (
    <AntdTable dataSource={flattenedList} size="small" scroll={{ y: 400 }}>
      <AntdTable.Column
        title="Clinician"
        key="clinician_id"
        dataIndex="clinician_id"
        sorter={(a: ClinicianSlot, b: ClinicianSlot) =>
          clinicianMap[a.clinician_id].first_name.localeCompare(
            clinicianMap[b.clinician_id].first_name,
          )
        }
        render={(clinicianId: number, row: ClinicianSlot) =>
          `${highAcuityClinicianSlots.includes(row) ? "🚨 " : ""}${
            clinicianMap[clinicianId]?.first_name
          } ${clinicianMap[clinicianId]?.last_name}`
        }
      />
      <AntdTable.Column
        title="Start date"
        key="start_date"
        dataIndex="start_date"
        sorter={(a: ClinicianSlot, b: ClinicianSlot) =>
          a.start_date.localeCompare(b.start_date)
        }
        render={(start_date, row) => `${row.day_of_week} ${start_date}`}
      />
      <AntdTable.Column
        title="Recurrence"
        key="recurrence"
        dataIndex="recurrence"
        width="96px"
      />
      <AntdTable.Column
        key="actions"
        width="200px"
        render={(row: ClinicianSlot) => (
          <div style={{ display: "flex" }}>
            <ClinicianStatus clinicianId={row.clinician_id} />
            <ShoppingCartButton
              fit={clinicianFitMap[row.clinician_id]}
              slot={row}
            />
          </div>
        )}
      />
    </AntdTable>
  );
};

const TableBox = ({
  color,
  label,
  openClinicians,
}: {
  color?: string;
  label?: string;
  openClinicians?: {
    numSlots: number;
    numHighAcuityClinicians: number;
    table: React.ReactNode;
  };
}) => {
  if (openClinicians?.numSlots && color) {
    // emphasize the badge when there's high acuity clinicians in this timeslot
    const shouldBeHighlighted = openClinicians?.numHighAcuityClinicians > 0;
    return (
      <Popover content={openClinicians.table} title={"Open Slots"}>
        <BoxWrapper>
          <BoxContent
            style={{
              backgroundColor: color,
            }}
          >
            <Badge
              style={{
                backgroundColor: shouldBeHighlighted ? colors.$early : "#fff",
                color: "#000",
              }}
              count={
                `${openClinicians.numSlots}` +
                (openClinicians?.numHighAcuityClinicians > 0
                  ? ` (${openClinicians.numHighAcuityClinicians})`
                  : "")
              }
            />
          </BoxContent>
        </BoxWrapper>
      </Popover>
    );
  }
  return (
    <BoxWrapper>
      <BoxContent
        style={{
          backgroundColor: color || "",
        }}
      >
        {label}
      </BoxContent>
    </BoxWrapper>
  );
};

export default DisplayMatchSlotPreferencesWrapper;
