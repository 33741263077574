export interface AssessmentAnswer {
  survey_date_taken?: Date | string;
  answer_value?: number | null | undefined;
  client_id: number;
  slug: string;
  survey_name: keyof FormattedAnswers | string;
  value: string;
  image?: string;
  category?: string;
  title: string;
  question_text: string;
}

export interface AuthGroup {
  id: number;
  name: string;
}

export interface FormattedAnswers {
  [key: string]: Gad7Questions | Phq9Questions;
}

export interface Gad7Questions {
  [key: string]: Array<AssessmentAnswer>;
}

export interface Phq9Questions {
  [key: string]: Array<AssessmentAnswer>;
}

const getCategoryBySlug = (slug: string) => {
  if (slug === "gad7_1") {
    return "Nervousness";
  }
  if (slug === "gad7_2") {
    return "Control Over Worry";
  }
  if (slug === "gad7_3") {
    return "Amount of Worry";
  }
  if (slug === "gad7_4") {
    return "Relaxation";
  }
  if (slug === "gad7_5") {
    return "Restlessness";
  }
  if (slug === "gad7_6") {
    return "Irritability";
  }
  if (slug === "gad7_7") {
    return "Fear";
  }
  if (slug === "phq9_1") {
    return "Interest and Enjoyment";
  }
  if (slug === "phq9_2") {
    return "Mood";
  }
  if (slug === "phq9_3") {
    return "Sleep";
  }
  if (slug === "phq9_4") {
    return "Energy";
  }
  if (slug === "phq9_5") {
    return "Eating";
  }
  if (slug === "phq9_6") {
    return "Self-Esteem";
  }
  if (slug === "phq9_7") {
    return "Concentration";
  }
  if (slug === "phq9_8") {
    return "Physical Feelings";
  }
  if (slug === "phq9_9") {
    return "";
  } else return "";
};

export const formatByQuestionSlug = (
  questions: AssessmentAnswer[] | undefined,
) => {
  if (!questions) return;
  // eslint-disable-next-line
  const slugMap: { [key: string]: any } = {
    gad7: {},
    phq9: {},
  };
  questions.forEach((question: AssessmentAnswer) => {
    question["title"] = getCategoryBySlug(question.slug);
    const surveyName = question.survey_name;
    if (surveyName && surveyName in slugMap) {
      // eslint-disable-next-line
      if (slugMap[surveyName][question.slug]) {
        // eslint-disable-next-line
        slugMap[surveyName][question.slug].push(question);
      } else {
        // eslint-disable-next-line
        slugMap[surveyName][question.slug] = [question];
      }
    }
  });
  return slugMap;
};

// The list/array of answers corresponding to each key within formattedAnswers fields gad7 and phq9 are sorted in
// chronological order, where formattedAnswers.gad7[key][0] indicates the most recent event,
// and formattedAnswers.gad7[key][formattedAnswers.gad7[key].length - 1] indicates the least recent event.
// In contrast, chart.data param of MBCSurveyResponses is sorted in reverse-chronological order.
// The currentEventIdx is based off off chart.data param, which comes from MBCSurveyResponses.
// So, currentEventIdx = 0 indicates the least recent event, and currentEventIdx = chart.data.length - 1 indicates
// the most recent event.
// Each answer in the list of answers corresponding to each key within formattedAnswers fields gad7 and phq9 should
// correspond to a single event in chart.data
// Therefore, we would always expect 0 <= currentEventIdx <= formattedAnswers.gad7[key].length - 1
// An additional consideration is that chart.data may vary in length according to which date range filter is applied.
// This is why answersIndex is initialized to 0
export const getImprovedandWorseningResults = (
  formattedAnswers: FormattedAnswers,
  answersIndex: number,
) => {
  const improvedResults: AssessmentAnswer[] = [];
  const worseningResults: AssessmentAnswer[] = [];

  const gadKeys = Object.keys(formattedAnswers.gad7);
  const phqKeys = Object.keys(formattedAnswers.phq9);

  gadKeys.forEach((key) => {
    const latestAnswer = formattedAnswers.gad7[key]?.[answersIndex];
    const penultimateAnswer = formattedAnswers.gad7[key]?.[answersIndex + 1];

    if (latestAnswer && penultimateAnswer) {
      if (
        latestAnswer.answer_value !== null &&
        latestAnswer.answer_value !== undefined &&
        penultimateAnswer.answer_value !== null &&
        penultimateAnswer.answer_value !== undefined
      ) {
        if (latestAnswer.answer_value < penultimateAnswer.answer_value) {
          //improvement
          improvedResults.push(latestAnswer);
        } else if (latestAnswer.answer_value > penultimateAnswer.answer_value) {
          //worsening
          worseningResults.push(latestAnswer);
        }
      }
    }
  });

  phqKeys.forEach((key) => {
    //omit suicidal question
    if (key !== "phq9_9") {
      const latestAnswer = formattedAnswers.phq9[key]?.[answersIndex];
      const penultimateAnswer = formattedAnswers.phq9[key]?.[answersIndex + 1];
      if (latestAnswer && penultimateAnswer) {
        if (
          latestAnswer.answer_value !== null &&
          latestAnswer.answer_value !== undefined &&
          penultimateAnswer.answer_value !== null &&
          penultimateAnswer.answer_value !== undefined
        ) {
          if (latestAnswer.answer_value < penultimateAnswer.answer_value) {
            //improvement
            improvedResults.push(latestAnswer);
          } else if (
            latestAnswer.answer_value > penultimateAnswer.answer_value
          ) {
            //worsening
            worseningResults.push(latestAnswer);
          }
        }
      }
    }
  });

  return { improvedResults, worseningResults };
};
