import moment from "moment";
import * as React from "react";
import * as ReactRedux from "react-redux";
import styled from "styled-components";

import { PanelClient, PanelClinicianWeek, PanelSession } from "../../api/types";
import { $antdBorder } from "../../assets/colors";
import { AppState } from "../../state/models";
import { CellContainer, headerRowHeight, rowHeight } from "./_shared";
import {
  ClientHeaderCell,
  CollapsedSessionsCell,
  HorizontalHeaderCell,
  SessionCell,
  WeekHeaderCell,
} from "./Cells";
import { ClientRowIndices, ClientsMap, PanelSessionsMap } from "./types";

type PanelColumnProps =
  | {
      type: "clients";
      clients: PanelClient[];
    }
  | {
      type: "week";
      week: PanelClinicianWeek;
      sessionsMap: PanelSessionsMap;
    };

function generateWeek(
  week: PanelClinicianWeek,
  clientRowIndices: ClientRowIndices,
  sessionsMap: PanelSessionsMap,
) {
  const headerCell = <WeekHeaderCell key={0} week={week} />;
  const columnCells = [headerCell];

  const indexToSessions: {
    [index: number]: {
      full: Array<{ props: StyledTableCellProps }>;
      collapsed: PanelSession[];
    };
  } = {};

  // iterate over all sessions and add them to a bucket by client
  for (const sessionId of week.sessions) {
    const session = sessionsMap[sessionId];
    const index = clientRowIndices[session.appointment[0].client.id];
    if (!index) {
      continue;
    }
    if (!indexToSessions[index]) {
      indexToSessions[index] = { full: [], collapsed: [] };
    }

    const thisIndex = indexToSessions[index];

    if (session.appointment[0].appointment_status === "canceled") {
      thisIndex.collapsed.push(session);
    } else {
      thisIndex.full.push(<SessionCell key={session.id} session={session} />);
    }
  }

  // iterate over all client indexes (starting at 1 because 0 is the header),
  // adding session buckets to containers if they exist
  for (let index = 1; index <= Object.keys(clientRowIndices).length; index++) {
    if (indexToSessions[index]) {
      indexToSessions[index].full.sort((a, b) =>
        moment(a.props.session!.start_time).isAfter(b.props.session!.start_time)
          ? 1
          : -1,
      );

      if (
        indexToSessions[index].full.length === 0 &&
        indexToSessions[index].collapsed.length >= 1
      ) {
        columnCells.push(
          <SessionCellContainer key={index} index={index}>
            {indexToSessions[index].collapsed.map(s => (
              <SessionCell key={s.id} session={s} />
            ))}
          </SessionCellContainer>,
        );
      } else {
        columnCells.push(
          <SessionCellContainer key={index} index={index}>
            {indexToSessions[index].full}
            {indexToSessions[index].collapsed.length > 0 && (
              <CollapsedSessionsCell
                doCollapse={indexToSessions[index].full.length > 0}
                sessions={indexToSessions[index].collapsed}
              />
            )}
          </SessionCellContainer>,
        );
      }
    } else {
      columnCells.push(<SessionCellContainer key={index} index={index} />);
    }
  }

  return columnCells;
}

function generateClients(
  clientsMap: ClientsMap,
  clientRowIndices: ClientRowIndices,
) {
  const rows = new Array(Object.keys(clientRowIndices).length);
  rows[0] = <HorizontalHeaderCell style={{ zIndex: 11 }} key={0} />;
  for (const [clientId, rowIndex] of Object.entries(clientRowIndices)) {
    const client = clientsMap[clientId];
    rows[rowIndex] = <ClientHeaderCell key={client.id} client={client} />;
  }

  return rows;
}

interface PanelColumnStateProps {
  clientRowIndices: ClientRowIndices;
  filteredClientIds: number[];
  clientsMap: ClientsMap;
}

class PanelColumn extends React.Component<
  PanelColumnProps & PanelColumnStateProps
> {
  public render() {
    const numRows = Object.keys(this.props.clientRowIndices).length;
    let items: React.ReactChild[];
    let isLastWeek: boolean = false;
    if (this.props.type === "clients") {
      items = generateClients(
        this.props.clientsMap,
        this.props.clientRowIndices,
      );
    } else {
      isLastWeek = this.props.week.year === moment().year() && this.props.week.week_number + 1 === moment().isoWeek();
      items = generateWeek(
        this.props.week as PanelClinicianWeek,
        this.props.clientRowIndices as ClientRowIndices,
        this.props.sessionsMap,
      );
    }
    return (
      <Column
        numRows={numRows}
        width={this.props.type === "clients" ? 144 : 132}
        sticky={this.props.type === "clients"}
        isLastWeek={isLastWeek}
      >
        {items}
      </Column>
    );
  }
}

const mapStateToProps: ReactRedux.MapStateToProps<
  PanelColumnStateProps,
  PanelColumnProps,
  AppState
> = state => {
  return {
    clientRowIndices: state.panel.clientRowIndices,
    filteredClientIds: state.panel.filteredClientIds,
    clientsMap: state.panel.clientsMap,
  };
};

// TODO
// @ts-ignore
export default ReactRedux.connect(mapStateToProps)(PanelColumn);

interface StyledColumnProps {
  numRows: number;
  width?: number;
  sticky?: boolean;
  isLastWeek: boolean;
}

const Column = styled.div<StyledColumnProps>`
  border-right: 1px solid ${$antdBorder};
  ${p => (p.isLastWeek ? `border-right: 2px solid red;` : "")}
  ${p =>
    p.width &&
    `
    min-width: ${p.width}px;
    width: ${p.width}px;
  `}
  display: grid;
  grid-template-columns: ${p => p.width && p.width - 2}px;
  grid-template-rows: ${headerRowHeight} repeat(${p => p.numRows}, ${rowHeight});
  background-size: 100px ${rowHeight};
  background-image: linear-gradient(
    to bottom,
    #fff 0px,
    #fff ${headerRowHeight},
    ${$antdBorder} 255px,
    #fff 1px
  );
  ${p => p.sticky && `position: sticky; left: 0px; z-index: 11;`}
`;

interface StyledTableCellProps {
  index: number;
  session?: PanelSession;
}

const SessionCellContainer = styled(CellContainer)<StyledTableCellProps>`
  max-width: 144px;
  display: flex;
  padding: 4px;
  /* css grid is indexed starting at 1 */
  grid-row-start: ${p => p.index + 1};
`;
