import {
  EmergencyContact,
  formatPhoneNumber,
} from "../../_api/use-group-therapy-client-overview/use-group-therapy-client.util";
import {
  BasicInfoQuestionAndResponseContainer,
  BasicInfoQuestionContainer,
  BasicInfoQuestionText,
  BasicInfoResponseText,
  SectionDivider,
} from "./basic-information.styled";
import { Text } from "@/app/design-system";

interface GroupClientEmergencyContactAndPayerProps {
  emergencyContactData: EmergencyContact | undefined;
  payer: string | null;
}

export const GroupClientEmergencyContactAndPayer = ({
  emergencyContactData,
  payer,
}: GroupClientEmergencyContactAndPayerProps) => {
  if (!emergencyContactData) {
    return null;
  }

  const emergencyContact = `${emergencyContactData.relationship} - ${emergencyContactData.contactName}`;

  if (!emergencyContactData) {
    return null;
  }
  const email = emergencyContactData.email;

  return emergencyContactData ? (
    <>
      <BasicInfoQuestionAndResponseContainer css={{ pb: 0 }}>
        <BasicInfoQuestionContainer>
          <BasicInfoQuestionText> Emergency contact </BasicInfoQuestionText>
        </BasicInfoQuestionContainer>
        <BasicInfoResponseText>{emergencyContact}</BasicInfoResponseText>
      </BasicInfoQuestionAndResponseContainer>
      {!!email ? (
        <Text
          css={{
            paddingLeft: 290,
            fontWeight: 300,
            pt: 24,
            pb: 24,
          }}
        >
          {email}
        </Text>
      ) : null}
      {!!emergencyContactData.phone ? (
        <Text
          css={{
            paddingLeft: 290,
            fontWeight: 300,
            pb: 24,
          }}
        >
          {emergencyContactData.phone}
        </Text>
      ) : null}
      <SectionDivider />

      {!!payer ? (
        <>
          <BasicInfoQuestionAndResponseContainer>
            <BasicInfoQuestionContainer>
              <BasicInfoQuestionText> Payer </BasicInfoQuestionText>
            </BasicInfoQuestionContainer>
            <BasicInfoResponseText>{payer}</BasicInfoResponseText>
          </BasicInfoQuestionAndResponseContainer>
          <SectionDivider />
        </>
      ) : null}
    </>
  ) : null;
};
