import React from "react";

import { H2, H4 } from "@/app/design-system/headings/heading";
import { Stack } from "@/app/design-system/layout/stack";
import { Text } from "@/app/design-system";
import Mangle from "@/app/_shared/Mangle";
import { MatchingInfoByClientIdApiData } from "../../../../api/use-matching-data-by-clients/select-data-for-matching-data-by-client.util";
import { MatchingStatusBadge } from "../../../my-clients-page/components/client-pane/components/matching-status-badge";
import {
  PreferredNameContainer,
  StyledXIcon,
  SuperUnstyledButton,
} from "@/app/_components/secondary-nav/nav-info.styled";
import { Tag } from "@/app/design-system";
import { FlagIcon } from "@/app/design-system/icons/components/flag-icon";
import { CSS_COLORS } from "@/app/design-system";
import { CurrentUserContext } from "@/app/app.utils";

interface ClientProfileNavContactInfoProps {
  firstName: string;
  lastName: string;
  email: string;
  preferredName: string;
  phoneNumber: string;
  clientMatchingData: MatchingInfoByClientIdApiData | null;
  isHighAcuity?: boolean;
}

export const ClientProfileNavContactInfo = ({
  firstName,
  lastName,
  preferredName,
  email,
  phoneNumber,
  clientMatchingData,
  isHighAcuity
}: ClientProfileNavContactInfoProps) => {

  const [isPreferredNameOpen, setIsPreferredNameOpen] = React.useState(true);

  const cuser = React.useContext(CurrentUserContext);





  const handleClosePreferredName = () => {
    localStorage.setItem("preferredNameClosed", "true");
    setIsPreferredNameOpen(false);
  };

  React.useEffect(() => {
    if (localStorage.getItem("preferredNameClosed") === "true") {
      setIsPreferredNameOpen(false);
    }
  }, []);

  return (
    <Stack gap={14}>
      <Mangle>
      <H2
            css={{
              fontWeight: 700,
            }}
          >
            {firstName} {lastName}
          </H2>
      </Mangle>
      {isHighAcuity  && (
        <Tag color="red">
          <div  style={{ display: 'flex', flexDirection: 'row', gap: '0.25rem', alignItems: 'center' }}>
          <FlagIcon stroke={CSS_COLORS.red11} title="High Acuity"/>
            <span>High Acuity</span>
          </div>
        </Tag>
      )}
      {preferredName && isPreferredNameOpen ? (
        <PreferredNameContainer>
          <span>👋 Please note that this client has a preferred name</span>
          <SuperUnstyledButton onClick={handleClosePreferredName}>
            <StyledXIcon fill="white" width={16} />
          </SuperUnstyledButton>
        </PreferredNameContainer>
      ) : null}
      {preferredName  && (
          <Text fontSize={"14"}>
            Preferred Name:{" "}
            <span style={{ fontWeight: 700 }}><Mangle>{preferredName}</Mangle></span>
          </Text>
        ) 
        }
      <Stack gap={14} alignItems="flex-start">
        <MatchingStatusBadge clientMatchingData={clientMatchingData} />
        <Mangle>
          <Text fontSize={"14"}>{email}</Text>
        </Mangle>
        <Mangle>
          <Text fontSize={"14"}>{phoneNumber}</Text>
        </Mangle>
      </Stack>
    </Stack>
  );
};
