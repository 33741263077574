import { ModalTableData } from "@/app/design-system";
import { ScreenReaderOnlyText } from "@/app/_shared/ScreenReaderOnlyText";
import { EOCClinicianSubEpisodeSummary } from "../../../../../types";

interface EOCClinicianModalTableCellProps {
  subEpisodeSummary: EOCClinicianSubEpisodeSummary;
}

export const EOCClinicianModalTableCell = (
  props: EOCClinicianModalTableCellProps,
) => {
  const { subEpisodeSummary } = props;
  {
    const doPhq9ScoresExist =
      subEpisodeSummary.startPhq9Score && subEpisodeSummary.endPhq9Score;
    const doGad7ScoresExist =
      subEpisodeSummary.startGad7Score && subEpisodeSummary.endGad7Score;
    return (
      <ModalTableData>
        {doPhq9ScoresExist ? (
          <>
            <span style={{ whiteSpace: "pre" }}></span>
            PHQ9: {subEpisodeSummary.startPhq9Score} <span aria-hidden>→</span>
            <ScreenReaderOnlyText>to</ScreenReaderOnlyText>{" "}
            {subEpisodeSummary.endPhq9Score}
          </>
        ) : null}
        {doPhq9ScoresExist && doGad7ScoresExist ? <br></br> : null}
        {doGad7ScoresExist ? (
          <>
            GAD7: {subEpisodeSummary.startGad7Score} <span aria-hidden>→</span>
            <ScreenReaderOnlyText>to</ScreenReaderOnlyText>{" "}
            {subEpisodeSummary.endGad7Score}
          </>
        ) : null}
        {!doPhq9ScoresExist && !doGad7ScoresExist ? (
          <>No scores available</>
        ) : null}
      </ModalTableData>
    );
  }
};
