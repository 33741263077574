import { Flex } from "@/app/design-system/layout/flex";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { SCALE_0985_TO_1000 } from "../styles/config/css-keyframe-constants";
import { styledStitches } from "@/app/design-system/styles/stitches.config";

const StyledDialogOverlay = styledStitches(DialogPrimitive.DialogOverlay, {
  backgroundColor: `$neutral12`,
  opacity: 0.4,
  position: "fixed",
  inset: 0,
  z: 2,
});

const StyledDialogTitle = styledStitches(DialogPrimitive.Title, {
  borderBottom: "1px solid #f3f3f3",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: 60,
  textAlign: "center",
  padding: "16px",
  margin: 0,
});

const StyledDialogContent = styledStitches(DialogPrimitive.Content, {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  backgroundColor: "$neutral0",
  btlr: 6,
  bblr: 6,
  boxShadow: `hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
  hsl(206 22% 7% / 20%) 0px 10px 20px -15px`,
  position: "fixed",
  width: 500,
  maxWidth: 700,
  right: -0,
  top: 0,
  height: "100vh",
  maxHeight: "100vh",
  opacity: 1,
  transition: "opacity 200ms cubic-bezier(0.16, 1, 0.3, 1)",
  animation: `${SCALE_0985_TO_1000} 250ms cubic-bezier(0.16, 1, 0.3, 1)`,
  ">": {
    flexGrow: 1,
  },
  z: 3,
});

export const StyledCloseButton = styledStitches("button", {
  all: "unset",
  borderRadius: "50%",
  cursor: "pointer",
});

const StyledDrawerFooter = styledStitches(Flex, {
  p: "12px 24px 12px",
  borderTop: "1px solid $neutral7",
});

export const StyledPortal = styledStitches(DialogPrimitive.Portal, {});

export const Root = DialogPrimitive.Root;
export const Close = DialogPrimitive.Close;
export const CloseButton = StyledCloseButton;
export const Trigger = DialogPrimitive.DialogTrigger;
export const Portal = StyledPortal;
export const Overlay = StyledDialogOverlay;
export const Title = StyledDialogTitle;
export const Content = StyledDialogContent;
export const Footer = StyledDrawerFooter;
