import * as consultNoteOperations from "./operations";
import produce from "immer";
import {
  actionFactory,
  ActionUnion,
  payloadAction,
  simpleAction,
} from "reductser";
import {
  ConsultExtended,
  ConsultAndNoteNoteData,
  ConsultNote,
} from "../../../api/types";
import { assertUnreachable } from "../types";

const defaultConsultNoteState = {
  consult_modality: "teletherapy",
  consult_training: "none",
};

const actionMap = {
  openConsultNote: payloadAction<ConsultAndNoteNoteData>(),
  setConsultNoteData: payloadAction<ConsultNote>(),
  closeConsultNote: simpleAction(),
  setConsultNoteValue: payloadAction<{
    key: string | undefined;
    value: string | boolean | null | undefined;
  }>(),
  updateNestedField: payloadAction<{
    collection: string;
    id: string;
    key: string;
    value: string;
    order: number;
  }>(),
  updateRelatedObjectField: payloadAction<{
    relatedObjectName: string;
    key: string;
    value: string | boolean;
  }>(),
};

export const consultNoteActions = actionFactory(actionMap, "CONSULTNOTE");

export type ConsultNoteActions = ActionUnion<typeof consultNoteActions>;

export interface ConsultNoteState {
  isOpen: boolean;
  openConsult: ConsultExtended | null;
  readOnly?: boolean;
}

const getInitialState = (): ConsultNoteState => ({
  isOpen: false,
  openConsult: null,
  readOnly: false,
});

const reducer = (state = getInitialState(), action: ConsultNoteActions) =>
  produce(state, (draft) => {
    if (action.reducer === "CONSULTNOTE") {
      switch (action.type) {
        case "openConsultNote":
          draft.isOpen = true;
          draft.openConsult = { ...action.payload.consult };
          draft.readOnly = !!action.payload.readOnly;

          if (draft.openConsult === null) return;

          if (
            !("consultNoteData" in action.payload) ||
            !action.payload.consultNoteData
          ) {
            draft.openConsult.consult_note = defaultConsultNoteState;
          } else {
            draft.openConsult.consult_note = action.payload.consultNoteData;
          }
          break;

        case "setConsultNoteData":
          if (draft.openConsult === null) {
            break;
          }
          draft.openConsult.consult_note = action.payload;
          break;

        case "closeConsultNote": {
          const initState = getInitialState();

          Object.keys(initState).forEach((key) => {
            draft[key] = initState[key];
          });
          break;
        }

        case "setConsultNoteValue":
          if (draft.openConsult === null || action.payload.key === undefined)
            break;

          draft.openConsult.consult_note[action.payload.key] =
            action.payload.value;
          break;

        case "updateNestedField": {
          if (draft.openConsult === null || action.payload.key === undefined)
            break;

          const { collection, key, value, order } = { ...action.payload };
          // Initiate the array in state if needed
          if (!(collection in draft.openConsult.consult_note)) {
            draft.openConsult.consult_note[collection] = [];
          }

          let existingEntry: keyof ConsultNote | null = null;
          if (state?.openConsult?.consult_note[collection]) {
            existingEntry = draft?.openConsult?.consult_note[collection].find(
              (c: any) => c.order === order,
            );
          }

          if (existingEntry) {
            // Update
            existingEntry[key] = value;
          } else {
            // Create
            draft.openConsult.consult_note[collection].push({
              [key]: value,
              order,
            });
          }
          break;
        }

        case "updateRelatedObjectField": {
          if (draft.openConsult === null || action.payload.key === undefined)
            break;

          const { relatedObjectName, key, value } = action.payload;

          if (!(relatedObjectName in draft.openConsult.consult_note)) {
            draft.openConsult.consult_note[relatedObjectName] = {};
          }
          draft.openConsult.consult_note[relatedObjectName][key] = value;
          break;
        }

        default:
          assertUnreachable(action);
      }
    }
  });

export default reducer;
export { consultNoteOperations };
