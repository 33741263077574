import * as React from "react";
import * as ReactRedux from "react-redux";
import styled from "styled-components";

import { AppState } from "../../state/models";
import { styledStitches } from "../design-system";
import {
  VN_COMPONENT_INDEX,
  VN_COMPONENT_OVERLAY_INDEX,
} from "../design-system/styles/config/css-constants";
import { ElevatedMangleLink, VisuallyNestedLink } from "./VisuallyNestedLink";

interface StateProps {
  doMangle: boolean;
}

interface PrivacySpanProps {
  doMangle?: boolean;
}

const PrivacySpan = styled.span`
  transition: all 300ms ease;
  ${(props: PrivacySpanProps) =>
    props.doMangle && "filter: blur(8px); &:hover { filter: blur(0); }"};
`;

/** @name Mangle
 * @summary Mangles text for privacy reasons
 */
const Mangle: React.SFC<StateProps> = (props) => {
  return <PrivacySpan doMangle={props.doMangle}>{props.children}</PrivacySpan>;
};

const mapStateToProps: ReactRedux.MapStateToProps<StateProps, {}, AppState> = (
  state,
) => {
  return { doMangle: state.options.doMangle };
};

export default ReactRedux.connect(mapStateToProps)(Mangle);

const ElevatedPrivacySpan = styledStitches(PrivacySpan, {
  zIndex: VN_COMPONENT_OVERLAY_INDEX,
});

/** @name Mangle
 * @summary Mangles text for privacy reasons
 */
const ElevatedMangleBase: React.SFC<StateProps> = (props) => {
  return (
    <ElevatedPrivacySpan doMangle={props.doMangle}>
      {props.children}
    </ElevatedPrivacySpan>
  );
};

export const ElevatedMangle =
  ReactRedux.connect(mapStateToProps)(ElevatedMangleBase);

interface VisuallyNestedElevatedMangleProps {
  link: string;
  children: React.ReactNode;
}

export const VisuallyNestedComponentOverlappingComponent = styledStitches(
  VisuallyNestedLink,
  {
    display: "block",
    zIndex: VN_COMPONENT_INDEX + 2,
  },
);

/**
 * @param VisuallyNestedElevatedMangle
 * @returns A mangle that can work above rows or other components that are interactive/clickable
 * @description Useful for when have personal data that is mangled
 * we elevate this because we want to mangle/unmangle the
 * name without mangling/unmangling the whole entire row.
 * we also set it as link because we want to make sure that the
 * target area is uninterrupted (but we do remove it from the tabIndex so that the keyboard interactions are not doubles)
 */
export const VisuallyNestedElevatedMangle = ({
  link,
  children,
}: VisuallyNestedElevatedMangleProps) => {
  return (
    <ElevatedMangle>
      <VisuallyNestedComponentOverlappingComponent as="span">
        <ElevatedMangleLink
          // We are removing this from the tabIndex and setting as aria-hidden because
          // the row itself has the same functionality
          // If we don't do this, then we basically have duplicate behavior.
          tabIndex={-1}
          aria-hidden
          to={link}
        ></ElevatedMangleLink>
      </VisuallyNestedComponentOverlappingComponent>
      {children}
    </ElevatedMangle>
  );
};
