import { ClientSearchBar } from "@/app/my-clients/routes/my-clients-page/components/search-and-filter/client-search-bar";
import { ClientOverviewApiData } from "../../../../../../api/use-my-clients-overview/my-clients-data.interface";
import { Flex } from "../../../../../../design-system/layout/flex";
import { Text } from "@/app/design-system";
import { ClientStatusFilterDropdown } from "./filter-dropdowns/1-client-status-filter-dropdown/client-status-filter-dropdown";
import { CadenceFilterDropdown } from "./filter-dropdowns/2-cadence-filter-dropdown/cadence-filter-dropdown";
import { MBCTrendFilterDropdown } from "./filter-dropdowns/3-mbc-trend-filter-dropdown/mbc-status-filter-dropdown";
import { MoreFiltersDropdown } from "./filter-dropdowns/4-more-filters-dropdown/more-filters-dropdown";

interface MyClientsPanelFiltersProps {
  myClients: ClientOverviewApiData[];
}

export const MyClientsSearchAndFiltersSection = ({
  myClients,
}: MyClientsPanelFiltersProps) => {
  return (
    <Flex gap={16} flexWrap={"wrap"}>
      <ClientSearchBar myClients={myClients} />
      <Flex
        gap={16}
        css={{
          "@breakpoint1200": {
            flexDirection: "column",
          },
        }}
      >
        <Flex
          alignItems={"center"}
          css={{
            "@breakpoint1200": {
              display: "none",
            },
          }}
        >
          <Text>Filter by</Text>
        </Flex>

        <Flex
          gap={16}
          css={{
            "@breakpoint1200": {
              flexWrap: "wrap",
            },
          }}
        >
          <ClientStatusFilterDropdown />
          <MBCTrendFilterDropdown />
          <CadenceFilterDropdown />
          <MoreFiltersDropdown />
        </Flex>
      </Flex>
    </Flex>
  );
};
