import { CurrentUserContext } from "@/app/app.utils";
import { Flex } from "@/app/design-system";
import { TIMEZONE } from "@/app/my-clients/utils";
import { useShallowEqualSelector } from "@/app/_helpers/redux";
import { matchmakerActions } from "@/state/models/matchmaker";
import { Button, Select as DeprecatedAntdSelect } from "antd";
import { DateTime } from "luxon";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { useGetAllLiveGroupsAfterDate } from "../../api/use-get-all-live-groups-after-date/use-get-all-live-groups-after-date.api";
import {
  getGroupDescriptionsForClientEnroll,
  GroupDescriptionForClientEnroll,
} from "../../api/use-get-all-live-groups-after-date/use-get-all-live-groups-after-date.util";

const AddClientToGroupError = () => {
  return (
    <Flex flexDirection="column">
      <span>There was an error fetching available groups.</span>
      <span>
        Please try again later or email groupops@twochairs.com and ask them to
        enroll this client in a group.
      </span>
    </Flex>
  );
};

export const AddClientToGroup = ({ clientId }: { clientId: number }) => {
  const dispatch = useDispatch();
  const cuser = useContext(CurrentUserContext);
  const userTimezone = TIMEZONE(cuser);

  const today = DateTime.now().toFormat("yyyy-MM-dd");

  const {
    data: allLiveGroupsData,
    isLoading,
    isError,
  } = useGetAllLiveGroupsAfterDate<GroupDescriptionForClientEnroll[]>({
    date: today,
    select: (data) => getGroupDescriptionsForClientEnroll(data, userTimezone),
    enabled: !!clientId,
  });

  const [groupIdToEnroll] = useShallowEqualSelector((state) => [
    state.matchmaker.groupsMatchData.groupIdToEnroll,
  ]);

  const handleGroupSelect = (groupId: string) => {
    dispatch(
      matchmakerActions.setGroupsEnrollData({
        clientIdToEnroll: clientId,
        groupIdToEnroll: groupId,
        dateEnrolledForGroup: DateTime.now().toFormat("yyyy-MM-dd"),
      }),
    );
  };

  const handleGroupClear = () => {
    dispatch(matchmakerActions.clearGroupsEnrollData());
  };

  if (isLoading) {
    return <span> Loading all groups.. </span>;
  }

  if (!isLoading && isError) {
    return <AddClientToGroupError />;
  }

  if (!allLiveGroupsData?.length) {
    return <span> There are no available groups at this time. </span>;
  }

  if (allLiveGroupsData) {
    return (
      <Flex flexDirection="column" css={{ paddingBottom: 16 }}>
        <span> Optional: please choose a group to enroll your client in. </span>
        <Flex css={{ paddingTop: 4 }}>
          <DeprecatedAntdSelect<string>
            style={{ width: "100%", paddingBottom: 8 }}
            value={groupIdToEnroll}
            onChange={handleGroupSelect}
            placeholder="No group selected"
          >
            {allLiveGroupsData.map((group) => {
              return (
                <DeprecatedAntdSelect.Option key={group.id} value={group.id}>
                  {group.description}
                </DeprecatedAntdSelect.Option>
              );
            })}
          </DeprecatedAntdSelect>
          <Button
            key="clear-group-selection"
            type="default"
            onClick={handleGroupClear}
            style={{ marginLeft: 8 }}
          >
            Clear
          </Button>
        </Flex>
        <span>
          {" "}
          If a group is selected, the client will be enrolled when you click
          Submit below.{" "}
        </span>
      </Flex>
    );
  }
  return null;
};
