import { Flex, styledStitches } from "../../../../../design-system";

export const filterTagStyles = {
  all: "unset",
  py: 6,
  px: 12,
  gap: 8,
  display: "flex",
  alignItems: "center",
  backgroundColor: "$neutral0",
  br: 12,
  textAlign: "center",
};

export const StyledFilterTag = styledStitches(Flex, { ...filterTagStyles });

export const StyledClearButton = styledStitches("button", {
  ...filterTagStyles,
  br: 8,
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  color: "$neutral11",
});

export const StyledFilterTagClearButton = styledStitches("button", {
  all: "unset",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
});
