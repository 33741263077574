import { Flex, styledStitches } from "@/app/design-system";
import { UnstyledButton } from "@/app/design-system/button/button";
import {
  ClickableRowTable,
  SORTED_IN_ASCENDING,
  SORTED_IN_DESCENDING,
} from "@/app/design-system/tanstack-table/tanstack-table.styled";
import { VisuallyNestedLink } from "@/app/_shared/VisuallyNestedLink";
import { styled } from "@stitches/react";

import { CSS_COLORS } from "@/app/design-system";
import { ScreenReaderOnlyText } from "@/app/_shared/ScreenReaderOnlyText";

import { ClientAllianceScore } from "@/app/dashboard/alliance-scores";
import { ArrowDownIcon, ArrowUpIcon } from "@/app/design-system/icons";
import { flexRender, Header } from "@tanstack/react-table";
import { ClientAdherenceTableData } from "../../dashboard/client-adherence/_components/client_adherence_table";
import { chartData } from "../attendance/_utils/utils";

export const SortableDashboardTable = styled(ClickableRowTable, {
  tr: {
    position: "relative",
    "&:hover td a svg, &:focus td a svg": {
      transition: "transform ease-in-out",
      transform: "translateX(5px)",
    },
  },
  "thead > tr > th:first-of-type": {
    width: "30%",
  },

  "thead > tr > th": {
    height: "44px",
    padding: "0",
  },
  "thead > tr > th > button": {
    cursor: "pointer",
    width: "100%",
  },
  "tbody > tr > td > div": {
    fontWeight: "400",
    flexWrap: "wrap",
  },
  variants: {
    fontWeight: {
      regularFontWeight: {
        "thead > tr > th": {
          padding: "20px 16px",
        },
        "tbody > tr > td": {
          borderBottom: "1px solid #f0f0f0",
          fontWeight: "400",
          fontSize: "16px",
          padding: "20px 16px",
        },
      },
    },
  },
});

export const UnsortableDashboardTable = styled(SortableDashboardTable, {
  "thead > tr > th": {
    padding: "10px 8px",
  },
  "thead > tr > th:first-of-type": {
    width: "30%",
    minWidth: "max-content",
    padding: "10px 16px",
    paddingInlineEnd: "8px",
  },
  "tbody > tr > td:first-of-type": {
    padding: "10px 16px",
    paddingInlineEnd: "8px",
  },
});

export const DashboardTableColumnSortButton = styled(UnstyledButton, {
  padding: "10px 16px",
  height: "100%",
});

// Visually Nested Link (VNL) Pattern - These properties make it so that the invisible link covers the entirety of its parent TableRow with the DashboardTable
// In this particular situation, it is because <table> should not have <a> as a child [based on the table HTML specifications](https://html.spec.whatwg.org/multipage/tables.html#the-table-element)
// We must abide by the spec lest we mess up the accessibility tree that gets generated from the DOM
export const DashboardTableVisuallyNestedTableRowLink =
  styled(VisuallyNestedLink);

export const DashboardTableFlex = styled(Flex, {
  marginInlineEnd: "24px",
});

interface SortableDashboardTableHeaderProps {
  header:
    | Header<ClientAdherenceTableData, unknown>
    | Header<chartData, unknown>
    | Header<ClientAllianceScore, unknown>;
}
export const SortableDashboardTableHeader = ({
  header,
}: SortableDashboardTableHeaderProps) => {
  return (
    <Flex alignItems={"center"} gap="4">
      {flexRender(header.column.columnDef.header, header.getContext())}
      {{
        asc: (
          <>
            <ArrowUpIcon
              title={SORTED_IN_ASCENDING}
              stroke={CSS_COLORS.neutral9}
              height={18}
              width={18}
            />

            <ScreenReaderOnlyText>
              Sort {header.column.id} in descending order
            </ScreenReaderOnlyText>
          </>
        ),
        desc: (
          <>
            <ArrowDownIcon
              title={SORTED_IN_DESCENDING}
              stroke={CSS_COLORS.neutral9}
              height={18}
              width={18}
            />

            <ScreenReaderOnlyText>
              Remove {header.column.id} sorting
            </ScreenReaderOnlyText>
          </>
        ),
      }[header.column.getIsSorted() as string] ?? (
        <ScreenReaderOnlyText>Sort in ascending order</ScreenReaderOnlyText>
      )}
    </Flex>
  );
};
