import { styledStitches } from "@/app/design-system/styles/stitches.config";
// These come from here:
// https://www.figma.com/file/TtoP7cvJnzUzz16jZY97oQ/Two-Chairs-Design-Library?node-id=513%3A1259&t=B1pfS84ceYezlpsD-0


/** 
 * @name: Title1
 * 
 * Make sure you are setting the element as the correct element in the body.
 * e.g. even though this is styled as an h1, document-wise it might be used as an h2 or h3
 */
export const Title1 = styledStitches("h1", {
  fontSize: "42px",
  lineHeight: "42px",
  variants: {
    fontWeight: {
      medium: { fontWeight: 500 },
      bold: { fontWeight: 700 },
    },
    margin: {
      noMargin: {margin: 0}
    }
  },
});

/**
 * @see {@link Title1}
 */
export const Title2 = styledStitches("h2", {
  fontSize: "32px",
  lineHeight: "32px",
  variants: {
    fontWeight: {
      medium: { fontWeight: 500 },
      bold: { fontWeight: 700 },
    },
    margin: {
      noMargin: {margin: 0}
    }
  },
});


/**
 * @see {@link Title1}
 */
export const Title3 = styledStitches("h3", {
  fontSize: "28px",
  lineHeight: "28px",
  variants: {
    fontWeight: {
      medium: { fontWeight: 500 },
      bold: { fontWeight: 700 },
    },
    margin: {
      noMargin: {margin: 0}
    }
  },
});


/**
 * @see {@link Title1}
 */
export const Title4 = styledStitches("h4", {
  fontSize: "24px",
  lineHeight: "24px",
  variants: {
    fontWeight: {
      medium: { fontWeight: 500 },
      bold: { fontWeight: 700 },
    },
    margin: {
      noMargin: {margin: 0}
    }
  },
});


/**
 * @see {@link Title1}
 */
export const Title5 = styledStitches("h5", {
  fontSize: "20px",
  lineHeight: "30px",
  variants: {
    fontWeight: {
      medium: { fontWeight: 500 },
      bold: { fontWeight: 700 },
    },
    margin: {
      noMargin: {margin: 0}
    }
  },
});


/**
 * @see {@link Title1}
 */
export const Title6 = styledStitches("h6", {
  fontSize: "16px",
  lineHeight: "24px",
  variants: {
    fontWeight: {
      medium: { fontWeight: 500 },
      bold: { fontWeight: 700 },
    },
    margin: {
      noMargin: {margin: 0}
    }
  },
  marginBottom: 0, //removes antd h6 styling
});