import { APIClinicianTag } from "../../../api/types";
import { alphabetize } from "../../_helpers";
import { useShallowEqualSelector } from "../../_helpers/redux";

import React, { useState, useMemo, useEffect } from "react";
import {
  CheckOutlined,
  DeleteOutlined,
  DislikeFilled,
  SearchOutlined,
} from "@ant-design/icons";
import { Icon as LegacyIcon } from "@ant-design/compatible";
import {
  Modal,
  Table,
  Select as DeprecatedAntdSelect,
  Button,
  Input,
} from "antd";
import { matchOperations } from "../../../state/models/matches";
import { useDispatch } from "react-redux";
import { uniqBy } from "lodash-es";
import { useUserHasAnyPermissions } from "../../_helpers/permissions";
import { Row, Flex } from "../../_layout/Flex";
import { $green, $red } from "../../../assets/colors";

type TagWeight = -1 | 1 | 2 | 4;
const tagWeightToExpertise = (weight: TagWeight): string => {
  switch (weight) {
    case 4:
      return "Very Good";
    case 2:
      return "Good";
    case 1:
      return "Fair";
    case -1:
      return "N/A";
  }
};

export default function TagsTable({ clinicianId }: { clinicianId: number }) {
  const [clinicianTags, clinician, inclusiveCategories] =
    useShallowEqualSelector((state) => [
      state.matches.clinicianTags.filter(
        (ct) => ct.clinician_id === clinicianId && ct.version === 2,
      ),
      state.clinicians.clinicianMap[clinicianId],
      state.matches.inclusiveCategories,
    ]);

  const dispatch = useDispatch();

  const [bucketFilters, updateBucketFilters] = useState<string[]>([]);
  const [categoryFilters, updateCategoryFilters] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [serviceFilters, updateServicetFilter] = useState<string[]>([
    "used_for_individual",
    "used_for_couples",
  ]);

  useEffect(() => {
    dispatch(matchOperations.getClinicianTags(clinicianId));
    // eslint-disable-next-line
  }, []);

  const filteredClinicianTags = useMemo(() => {
    let filteredTags = clinicianTags;
    if (bucketFilters.length > 0) {
      filteredTags = filteredTags.filter((ct) =>
        bucketFilters.includes(ct.tag.bucket),
      );
    }
    if (categoryFilters.length > 0) {
      filteredTags = filteredTags.filter((ct) =>
        categoryFilters.includes(ct.tag.category),
      );
    }
    if (searchValue.length > 0) {
      filteredTags = filteredTags.filter((t) =>
        `${t.tag.name} - ${t.tag.category} - ${
          t.tag.bucket
        } - ${tagWeightToExpertise(t.weight)}`
          .toLowerCase()
          .includes(searchValue.toLowerCase()),
      );
    }
    if (serviceFilters.length < 2) {
      filteredTags = filteredTags.filter((t) => {
        if (
          t.tag.used_for_individual &&
          serviceFilters.includes("used_for_individual")
        ) {
          return true;
        }
        if (
          t.tag.used_for_couples &&
          serviceFilters.includes("used_for_couples")
        ) {
          return true;
        }
        return false;
      });
    }
    return filteredTags;
  }, [
    clinicianTags,
    bucketFilters,
    categoryFilters,
    searchValue,
    serviceFilters,
  ]);

  const patchClinicianTag = (
    ct: APIClinicianTag,
    weight: TagWeight,
    prefer_not_to_treat: boolean,
  ) => {
    dispatch(
      matchOperations.patchClinicianTag(
        ct.clinician_id,
        ct.tag_id,
        weight,
        prefer_not_to_treat,
      ),
    );
  };

  const confirmDelete = (ct: APIClinicianTag) => {
    Modal.confirm({
      title:
        "Are you sure you want to remove " +
        ct.tag.name +
        " from " +
        clinician.first_name +
        "?",
      onOk: () =>
        dispatch(
          matchOperations.deleteClinicianTag(ct.clinician_id, ct.tag_id),
        ),
    });
  };

  const userCanCRUD = useUserHasAnyPermissions([
    "IsClinicalLeader",
    "IsMatchingAdmin",
    "IsSuperUser",
  ]);

  return (
    <>
      <Row layout="start center">
        <Flex />
        <Input
          addonBefore={<SearchOutlined />}
          onChange={(e) => setSearchValue(e.target.value)}
          allowClear={true}
          placeholder="Search all tags ..."
          style={{ marginBottom: "15px" }}
        />
      </Row>

      <Table<APIClinicianTag>
        dataSource={filteredClinicianTags}
        size="small"
        rowKey={(record: any) => record.id.toString()}
        onChange={(pagination: any, filters: any, sorter: any) => {
          // filters looks like { bucket: string[]; category: string[]; }
          updateBucketFilters(filters.bucket ? filters.bucket : []);
          updateCategoryFilters(filters.category ? filters.category : []);
          updateServicetFilter(
            filters.service
              ? filters.service
              : ["used_for_individual", "used_for_couples"],
          );
        }}
      >
        <Table.Column<APIClinicianTag>
          title="Version"
          dataIndex={["tag", "version"]}
          key="version"
        />
        <Table.Column<APIClinicianTag>
          title="Name"
          dataIndex={["tag", "name"]}
          key="name"
          sorter={(a, b) => alphabetize(a.tag.name, b.tag.name)}
        />
        <Table.Column<APIClinicianTag>
          title="Category"
          dataIndex={["tag", "category"]}
          key="category"
          sorter={(a, b) => alphabetize(a.tag.category, b.tag.category)}
          filters={uniqBy(
            clinicianTags
              .sort((a, b) => alphabetize(a.tag.category, b.tag.category))
              .map((ct) => ({
                text: ct.tag.category,
                value: ct.tag.category,
              })),
            "text",
          )}
        />
        <Table.Column<APIClinicianTag>
          title="Service"
          key="service"
          filters={[
            { text: "Individual", value: "used_for_individual" },
            { text: "Couples", value: "used_for_couples" },
          ]}
        />
        <Table.Column<APIClinicianTag>
          title="Bucket"
          dataIndex={["tag", "bucket"]}
          key="bucket"
          sorter={(a, b) => alphabetize(a.tag.bucket, b.tag.bucket)}
          filters={uniqBy(
            clinicianTags
              .sort((a, b) => alphabetize(a.tag.bucket, b.tag.bucket))
              .map((ct) => ({
                text: ct.tag.bucket,
                value: ct.tag.bucket,
              })),
            "text",
          )}
        />
        <Table.Column<APIClinicianTag>
          title="Weight"
          key="weight"
          sorter={(a, b) => a.weight - b.weight}
          render={(text, ct) =>
            inclusiveCategories.includes(ct.tag.category) ? (
              <CheckOutlined />
            ) : (
              <>
                {userCanCRUD && (
                  <DeprecatedAntdSelect<TagWeight>
                    defaultValue={ct.weight}
                    style={{ width: 170 }}
                    onChange={(value) =>
                      patchClinicianTag(ct, value, ct.prefer_not_to_treat)
                    }
                  >
                    <DeprecatedAntdSelect.Option value={4}>
                      {tagWeightToExpertise(4)}
                    </DeprecatedAntdSelect.Option>
                    <DeprecatedAntdSelect.Option value={2}>
                      {tagWeightToExpertise(2)}
                    </DeprecatedAntdSelect.Option>
                    <DeprecatedAntdSelect.Option value={1}>
                      {tagWeightToExpertise(1)}
                    </DeprecatedAntdSelect.Option>
                    <DeprecatedAntdSelect.Option value={-1}>
                      {tagWeightToExpertise(-1)}
                    </DeprecatedAntdSelect.Option>
                  </DeprecatedAntdSelect>
                )}
                {!userCanCRUD && tagWeightToExpertise(ct.weight)}
              </>
            )
          }
        />
        <Table.Column<APIClinicianTag>
          title="Preference"
          key="prefer_not_to_treat"
          sorter={(a, b) =>
            a.prefer_not_to_treat ? 1 : 0 - (b.prefer_not_to_treat ? 1 : 0)
          }
          render={(text, ct) => (
            <Row layout="space-around center">
              {userCanCRUD ? (
                <>
                  <LegacyIcon
                    type="like"
                    theme={ct.prefer_not_to_treat ? undefined : "filled"}
                    style={{
                      color: ct.prefer_not_to_treat ? undefined : $green,
                    }}
                    onClick={() => patchClinicianTag(ct, ct.weight, false)}
                  />
                  <LegacyIcon
                    type="dislike"
                    theme={ct.prefer_not_to_treat ? "filled" : undefined}
                    style={{
                      color: ct.prefer_not_to_treat ? $red : undefined,
                    }}
                    onClick={() => patchClinicianTag(ct, ct.weight, true)}
                  />
                </>
              ) : ct.prefer_not_to_treat ? (
                <DislikeFilled style={{ color: $red }} />
              ) : null}
            </Row>
          )}
        />
        {userCanCRUD && (
          <Table.Column<APIClinicianTag>
            title=""
            key="operation"
            render={(text: any, ct) => (
              <Button
                danger
                shape="circle"
                icon={<DeleteOutlined />}
                onClick={() => confirmDelete(ct)}
              />
            )}
          />
        )}
      </Table>
    </>
  );
}
