import {
  ComposedDropdownMenuCheckboxItem,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuPrimitiveItem,
} from "@/app/design-system/dropdown/dropdown.styled";
import { PayerFilterState } from "@/app/my-clients/routes/my-clients-page/my-clients-model";

import React from "react";

import { Button, Flex } from "../../../../../../../../../design-system";
import { DropdownSubMenuTriggerItem } from "../dropdown-sub-menu-trigger-item";
import { usePayerFilterDropdown } from "./use-payer-filter-dropdown";

interface PayerDropDownOptions {
  label: string;
  filter: boolean;
  filterLabel: keyof PayerFilterState;
}

export const PayerFilterDropdownMenu = () => {
  const {
    numberOfSelectedFilters,
    isFilteringOnDTC,
    isFilteringOnKPNorcal,
    isFilteringOnKPWA,
    isFilteringOnAetna,
    setMyClientsFilter,
    clearPayerFilters,
  } = usePayerFilterDropdown();

  const payerMap: PayerDropDownOptions[] = [
    {
      label: "Kaiser Permanente Norcal",
      filter: isFilteringOnKPNorcal,
      filterLabel: "isFilteringOnKPNorcal",
    },
    {
      label: "Kaiser Permanente Washington",
      filter: isFilteringOnKPWA,
      filterLabel: "isFilteringOnKPWA",
    },
    { label: "DTC", filter: isFilteringOnDTC, filterLabel: "isFilteringOnDTC" },
    {
      label: "Aetna",
      filter: isFilteringOnAetna,
      filterLabel: "isFilteringOnAetna",
    },
  ];

  return (
    <DropdownMenu>
      <DropdownSubMenuTriggerItem
        numberOfSelectedFilters={numberOfSelectedFilters}
      >
        Payer
      </DropdownSubMenuTriggerItem>

      <DropdownMenuContent sideOffset={15}>
        {payerMap.map((payer) => (
          <ComposedDropdownMenuCheckboxItem
            checked={payer.filter}
            onCheckedChange={() =>
              setMyClientsFilter(payer.filterLabel, !payer.filter)
            }
          >
            {payer.label}
          </ComposedDropdownMenuCheckboxItem>
        ))}

        <Flex
          justifyContent={"space-between"}
          css={{ borderTop: "1px solid $neutral3", pt: 10, pl: 0 }}
        >
          <DropdownMenuPrimitiveItem css={{ fontSize: 14 }} asChild>
            <Button
              variant={"tertiary"}
              size={"medium"}
              onClick={clearPayerFilters}
            >
              Clear
            </Button>
          </DropdownMenuPrimitiveItem>
        </Flex>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
