import {
  CalendarOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import * as Popover from "@radix-ui/react-popover";
import { DatePicker } from "antd";
import { DateTime } from "luxon";
import { OVERLAY_ZINDEX } from "@/app/design-system/styles/config/css-constants";

import * as React from "react";

import styled from "styled-components";
import { Button } from "../../app/_shared/antd";
import { Button as StyledButton, Flex, H5 } from "@/app/design-system";

import { $greyBorder } from "../../assets/colors";
import { styledStitches } from "../design-system";
import { STANDARD_FOCUS_OUTLINE } from "../design-system/styles/config/css-constants";
import {
  convertLuxonToMoment,
  convertMomentToLuxon,
  getSaturdayEndOfWeek,
  getSundayStartOfWeek,
  TIME_TAG_FORMAT,
} from "../_helpers/datetime";
import { ScreenReaderOnlyText } from "./ScreenReaderOnlyText";
import { UnstyledButton } from "../design-system/button/button";

export const StyledTimePickerButton = styledStitches(UnstyledButton, {
  borderRadius: "4px",
  // These are the antd default button styles (to minimize visual changes)
  lineHeight: "1.5715",
  position: "relative",
  display: "inline-block",
  fontWeight: "400",
  whiteSpace: "nowrap",
  textAlign: "center",
  border: "1px solid transparent",
  boxShadow: "0 2px 0 rgba(0, 0, 0, 0.015)",
  transition: "all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)",
  userSelect: "none",
  touchAction: "manipulation",
  height: "32px",
  padding: "4px 15px",
  fontSize: "14px",
  color: "rgba(0, 0, 0, 0.85)",
  borderColor: "#d9d9d9",
  background: " #fff",
});

const PopoverContentContainer = styled.div`
  > .date-pickers {
    display: flex;
    > div {
      flex: 1 1;
      &:first-of-type {
        margin-right: 0.5em;
      }
    }
  }
`;

const PopoverArrow = styledStitches(Popover.Arrow, {
  fill: "white",
});

const PopoverClose = styledStitches(Popover.Close, {
  all: "unset",
  marginTop: "1em",
  // The following attributes are a copy of the antd style that the original TimePicker "Apply" button used
  lineHeight: "1.5715",
  height: "32px",
  padding: "4px 15px",
  fontSize: "14px",
  borderRadius: 0,
  backgroundColor: "#000000d9",
  color: "#fff",
  "&:hover, &:focus": {
    backgroundColor: "$brandYellow",
  },
  "&:focus": { outline: STANDARD_FOCUS_OUTLINE },
});

const StyledPopoverContent = styledStitches(Popover.Content, {
  width: "100%",
  backgroundColor: "$neutral0",
  zIndex: `${OVERLAY_ZINDEX}`,
  padding: "16px",
  boxShadow:
    "hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px",
});

const WeekDelta = styled.div`
  margin-top: 0.5em;
  .weekdelta-arrow {
    padding: 1px 6px;
  }
  .weekdelta-text {
    flex: 1;
  }
`;

export interface TimePickerDateRange {
  start: DateTime;
  end: DateTime;
}

const { WeekPicker } = DatePicker;

export interface TimePickerProps {
  setDateRange: (dateRange: TimePickerDateRange) => void;
  dateRange: TimePickerDateRange;
  incrementDate: (
    date: "start" | "end",
    delta: number,
    dateRange: TimePickerDateRange,
  ) => void;
}

export const incrementLuxonDate = (
  date: "start" | "end",
  delta: number,
  dateRange: TimePickerDateRange,
) => {
  const [ownDate, setOwnDate] = React.useState<TimePickerDateRange>(dateRange);
  const { start, end } = ownDate;

  if (date === "start") {
    setOwnDate({
      ...ownDate,
      start: getSundayStartOfWeek(start.plus({ weeks: delta }).startOf("week")),
    });
  } else {
    setOwnDate({
      ...ownDate,
      end: getSaturdayEndOfWeek(end.plus({ weeks: delta })),
    });
  }
};

/**
 * @component TimePicker
 * @param props
 * @returns a TimePicker that uses Luxon (but secretly uses moment underneath until we can get rid of it)
 */

export const TimePicker = (props: TimePickerProps) => {
  const [ownDate, setOwnDate] = React.useState<TimePickerDateRange>(
    props.dateRange,
  );

  const { start, end } = ownDate;

  const apply = () => {
    props.setDateRange({
      start: getSundayStartOfWeek(ownDate.start),
      end: getSaturdayEndOfWeek(ownDate.end),
    });
  };

  const incrementDate = (date: "start" | "end", delta: number) => {
    const { start, end } = ownDate;

    if (date === "start") {
      setOwnDate({
        ...ownDate,
        start: getSundayStartOfWeek(
          start.plus({ weeks: delta, days: 1 }).startOf("week"),
        ),
      });
    } else {
      setOwnDate({
        ...ownDate,
        end: getSaturdayEndOfWeek(end.plus({ weeks: delta })),
      });
    }
  };

  const startNowDifference = start
    .diff(getSundayStartOfWeek(DateTime.now()), "weeks")
    .toObject().weeks;

  const startNowDifferenceRounded =
    startNowDifference || startNowDifference === 0
      ? Math.round(startNowDifference)
      : 0;

  const endNowDifference = end
    .endOf("week")
    .diff(getSaturdayEndOfWeek(DateTime.now()), "weeks")
    .toObject().weeks;

  return (
    <>
      <Popover.Root>
        <Popover.Trigger asChild>
          <StyledTimePickerButton>
            <Flex css={{ gap: "0.75em" }}>
              <span>
                <CalendarOutlined
                  style={{ color: $greyBorder, margin: "4px .5em 0 0" }}
                  aria-hidden
                />
                <ScreenReaderOnlyText>
                  Choose date range. Currently:
                </ScreenReaderOnlyText>
                <time
                  dateTime={props.dateRange.start.toFormat(TIME_TAG_FORMAT)}
                >
                  {props.dateRange.start.toFormat("LLL dd, yyyy")}
                </time>
              </span>
              <span aria-hidden>~</span>
              <ScreenReaderOnlyText>to</ScreenReaderOnlyText>
              <time dateTime={props.dateRange.end.toFormat(TIME_TAG_FORMAT)}>
                {props.dateRange.end.toFormat("LLL dd, yyyy")}
              </time>
            </Flex>
          </StyledTimePickerButton>
        </Popover.Trigger>
        <StyledPopoverContent>
          <PopoverContentContainer>
            <div className="date-pickers">
              <div>
                <H5 as="label" id="timePickerStartWeekLabel">
                  Start Week
                </H5>
                <WeekPicker
                  aria-labelledby="timePickerStartWeekLabel"
                  placeholder="Start Week"
                  defaultValue={convertLuxonToMoment(start)}
                  value={convertLuxonToMoment(start)}
                  format={"ll"}
                  onChange={(date: any) => {
                    setOwnDate({
                      ...ownDate,
                      start: convertMomentToLuxon(date)!,
                    });
                  }}
                  disabledDate={(date: any) =>
                    !!date && date.isAfter(convertLuxonToMoment(end))
                  }
                  allowClear={false}
                />
                <WeekDelta>
                  <Button.Group style={{ display: "flex" }}>
                    <Button
                      className="weekdelta-arrow"
                      onClick={() => incrementDate("start", -1)}
                    >
                      <LeftOutlined aria-label="Decrement week" />
                    </Button>
                    <Button className="weekdelta-text">
                      <span>
                        {
                          // This is adjusted so that it returns the same # of
                          // weeks as the TimePickerWithMoment date picker

                          startNowDifferenceRounded < 1
                            ? startNowDifferenceRounded
                            : startNowDifferenceRounded - 1
                        }
                        &nbsp;weeks
                      </span>
                    </Button>

                    <Button
                      className="weekdelta-arrow"
                      onClick={() => incrementDate("start", 1)}
                      disabled={start.plus({ weeks: 1 }) > end}
                    >
                      <RightOutlined aria-label="Increment week" />
                    </Button>
                  </Button.Group>
                </WeekDelta>
              </div>
              <div>
                <H5 as="label" id="timePickerEndWeekLabel">
                  End Week
                </H5>
                <WeekPicker
                  aria-labelledby="timePickerEndWeekLabel"
                  placeholder="End Week"
                  defaultValue={convertLuxonToMoment(end)}
                  value={convertLuxonToMoment(end)}
                  format={"ll"}
                  onChange={(date: any) =>
                    setOwnDate({
                      ...ownDate,
                      end: date
                        ? convertMomentToLuxon(date)
                        : getSaturdayEndOfWeek(ownDate.end),
                    })
                  }
                  disabledDate={(date) => {
                    return !!date && date.isBefore(convertLuxonToMoment(start));
                  }}
                  allowClear={false}
                />
                <WeekDelta>
                  <Button.Group style={{ display: "flex" }}>
                    <Button
                      className="weekdelta-arrow"
                      onClick={() => incrementDate("end", -1)}
                      disabled={end.minus({ week: 1 }) < start}
                    >
                      <LeftOutlined aria-label="Decrement week" />
                    </Button>
                    <Button className="weekdelta-text">
                      <span>
                        {Math.round(endNowDifference ? endNowDifference : 0)}{" "}
                        &nbsp;weeks
                      </span>
                    </Button>
                    <Button
                      className="weekdelta-arrow"
                      onClick={() => incrementDate("end", 1)}
                    >
                      <RightOutlined aria-label="Increment week" />
                    </Button>
                  </Button.Group>
                </WeekDelta>
              </div>
            </div>
            <Flex justifyContent={"flex-end"}>
              <PopoverClose onClick={apply}>Apply</PopoverClose>
            </Flex>
          </PopoverContentContainer>
          <PopoverArrow />
        </StyledPopoverContent>
      </Popover.Root>
    </>
  );
};
