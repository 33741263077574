import { Flex, H2, styledStitches, Text } from "@/app/design-system";
import {
  TabsList,
  TabsTrigger,
  TabsRoot,
  TabsContent,
  HoverTabHighlight,
} from "@/app/_components/navigation-tabs/navigation-tabs.styled";

import { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";

import { GroupDetailsMBC } from "../../sessions/session-id/mbc/mbc";
import { GroupDetailsNotes } from "../../sessions/session-id/notes/notes";
import { GroupDetailsNavInfo } from "./group-details-nav-info";
import { useGroupTherapyNavInfo } from "../../_api/use-group-therapy-nav-info/use-group-therapy-nav-info.api";
import { selectDataForGroupDetailsNav } from "../../_api/use-group-therapy-nav-info/use-group-therapy-nav-info.util";
import { useNavigationTabsStyling } from "@/app/_components/navigation-tabs/use-navigation-tabs-styling";
import { useGroupTherapyUser } from "../../../_hooks/use-group-therapy-user";
import { useMyClientsSearchStore } from "@/app/my-clients/routes/my-clients-page/my-clients-model/my-clients.model";
import { useUserHasAnyPermissions } from "@/app/_helpers/permissions";
import { GroupClientsDetails } from "../../clients/clients";
import { TIMEZONE } from "@/app/my-clients/utils";
import { CurrentUserContext } from "@/app/app.utils";
import React from "react";
import { selectGroupMBCAverage } from "@/app/groups/_api/use-group-therapy-overview/use-group-therapy-overview.util";
import { useGroupTherapyOverview } from "@/app/groups/_api/use-group-therapy-overview/use-group-therapy-overview.api";

const ATTENDANCE_TAB = "notes";
const MBC_TAB = "mbc";
const CLIENTS_TAB = "clients";

const ATTENDANCE_TAB_NAME = "Notes & Attendance";
const MBC_TAB_NAME = "Weekly Check-ins";
const CLIENTS_TAB_NAME = "Client Information";

export const useHasGroupTherapyV2Permissions = () => {
  return useUserHasAnyPermissions(["IsGroupTherapyV2User", "IsSuperUser"]);
};

export const GroupDetailsNavigation = () => {
  const cuser = React.useContext(CurrentUserContext);
  const { pathname } = useLocation();
  const { groupId, sessionId } =
    useParams<{ groupId: string; sessionId: string }>();
  const { clinicianId } = useGroupTherapyUser();
  const { currentlySelectedClinician } = useMyClientsSearchStore();
  const [currentSessionId, setCurrentSessionId] = useState(sessionId);

  const parts = pathname.split("/");
  const lastSegment = parts.pop() || parts.pop();
  const [value, setValue] = useState(lastSegment);

  const clinicianIdToQueryBy = currentlySelectedClinician
    ? currentlySelectedClinician
    : clinicianId;

  const clinicianIdParam = clinicianIdToQueryBy
    ? `clinician_id=${clinicianIdToQueryBy}`
    : "";

  const isV2Disabled = useHasGroupTherapyV2Permissions() ? false : true;

  const { data, isLoading, isError } = useGroupTherapyNavInfo({
    groupId,
    select: (data) => selectDataForGroupDetailsNav(data, TIMEZONE(cuser)),
    enabled: !!groupId,
  });

  const { data: groupAdherenceData } = useGroupTherapyOverview({
    clinicianId,
    select: (data) => selectGroupMBCAverage(data, groupId),
    enabled: !!(clinicianId && groupId),
  });
  const averageMBC = groupAdherenceData
    ? String(Math.round(groupAdherenceData * 100))
    : null;

  const {
    wrapperRef,
    resetHighlight,
    highlightStyles,
    hoverHighlightRef,
    repositionHighlight,
    activeTab: activeTabName,
    repositionActiveHighlight,
    activeStyles,
  } = useNavigationTabsStyling(ATTENDANCE_TAB);

  useEffect(() => {
    setValue(lastSegment);
    if (sessionId) {
      setCurrentSessionId(sessionId);
    }
  }, [pathname]);

  return (
    <Flex flexDirection="column" css={{ backgroundColor: "$neutral0" }}>
      <Flex css={{ pl: 24, pt: 24 }}>
        <H2 css={{ fontSize: 28, fontWeight: 700 }}>{data?.groupTitle}</H2>
        <H2 css={{ fontSize: 16, pt: 10 }}> &nbsp;•&nbsp; </H2>
        <H2 css={{ fontSize: 28, fontWeight: 700 }}>{data?.groupDayAndTime}</H2>
      </Flex>
      <Flex css={{ pl: 27 }} justifyContent="space-between">
        <GroupDetailsNavInfo
          sessionsAttended={data?.sessionsAttended ? data?.sessionsAttended : 0}
          averageAttendance={data?.averageAttendance}
          firstSessionDate={data?.firstSessionDate}
          endSessionDate={data?.endSessionDate}
          totalSessions={data?.totalSessions}
          numClients={data?.numClients}
          averageMBC={averageMBC}
          isError={isError}
          isLoading={isLoading}
        />
      </Flex>
      <TabsRoot
        orientation="horizontal"
        defaultValue={ATTENDANCE_TAB}
        value={value}
        css={{
          borderTop: "1px solid",
          borderColor: "$neutral5",
        }}
      >
        <Flex css={{ backgroundColor: "$neutral0", px: 20 }}>
          <TabsList
            ref={wrapperRef}
            onMouseLeave={resetHighlight}
            css={{ backgroundColor: "$neutral0" }}
          >
            <HoverTabHighlight ref={hoverHighlightRef} css={highlightStyles} />
            <GroupDetailsActiveTabHighlight css={activeStyles} />

            <Flex>
              <Flex css={{ pr: 78 }}>
                <StyledLink
                  to={`/groups/${groupId}/sessions/${currentSessionId}/notes?${clinicianIdParam}`}
                >
                  <TabsTrigger
                    value={ATTENDANCE_TAB}
                    onMouseEnter={(e) => repositionHighlight(e, ATTENDANCE_TAB)}
                    onClick={(e) =>
                      repositionActiveHighlight(e, ATTENDANCE_TAB)
                    }
                  >
                    <Flex gap={16} alignItems={"center"}>
                      <Text fontSize={18}>{ATTENDANCE_TAB_NAME}</Text>
                    </Flex>
                  </TabsTrigger>
                </StyledLink>
              </Flex>

              <Flex css={{ pr: 74 }}>
                <StyledLink
                  to={`/groups/${groupId}/sessions/${currentSessionId}/mbc?${clinicianIdParam}`}
                >
                  <TabsTrigger
                    value={MBC_TAB}
                    onMouseEnter={(e) => repositionHighlight(e, MBC_TAB)}
                    onClick={(e) => repositionActiveHighlight(e, MBC_TAB)}
                    disabled={isV2Disabled}
                    css={{
                      opacity: isV2Disabled ? 0.0 : 1,
                    }}
                  >
                    <Flex>
                      <Text fontSize={18}>{MBC_TAB_NAME}</Text>
                    </Flex>
                  </TabsTrigger>
                </StyledLink>
              </Flex>

              <StyledLink to={`/groups/${groupId}/clients?${clinicianIdParam}`}>
                <TabsTrigger
                  value={CLIENTS_TAB}
                  onMouseEnter={(e) => repositionHighlight(e, CLIENTS_TAB)}
                  onClick={(e) => repositionActiveHighlight(e, CLIENTS_TAB)}
                  disabled={isV2Disabled}
                  css={{
                    opacity: isV2Disabled ? 0.0 : 1,
                  }}
                >
                  <Flex gap={16} alignItems={"center"}>
                    <Text fontSize={18}>{CLIENTS_TAB_NAME}</Text>
                  </Flex>
                </TabsTrigger>
              </StyledLink>
            </Flex>
          </TabsList>
        </Flex>

        <Flex
          css={{
            flex: 1,
          }}
        >
          <TabsContent value={ATTENDANCE_TAB}>
            <GroupDetailsNotes />
          </TabsContent>
          <TabsContent value={MBC_TAB}>
            <GroupDetailsMBC />
          </TabsContent>
          <TabsContent value={CLIENTS_TAB}>
            <GroupClientsDetails />
          </TabsContent>
        </Flex>
      </TabsRoot>
    </Flex>
  );
};

const StyledLink = styledStitches(Link, {
  display: "flex",
  alignItems: "stretch",
});

const GroupDetailsActiveTabHighlight = styledStitches("div", {
  height: 8,
  width: 180,
  br: 8,
  top: 60,
  backgroundColor: "black",
  position: "absolute",
});
