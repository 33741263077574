// TODO: We'll need to eventually make sure the click events are UnstyledButtons and not divs
// JIRA - https://twochairs.atlassian.net/browse/TC-7199
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { InfoCircleOutlined } from "@ant-design/icons";
import { range } from "lodash";
import * as React from "react";

import { ConsultNoteQuestion } from "../../../../../api/types";
import { useShallowEqualSelector } from "../../../../_helpers/redux";
import { Title } from "../../../../_layout/display";
import { RequiredText } from "../../../../_shared/RequiredText";
import SymptomGuide from "./copy";
import { SubstanceUseSubQuestion } from "./SubstanceUseSubQuestion";

const { useState } = React;

const ENTRY_COUNT = 4;

interface Props {
  handleEdit: (hasBeenEdited: boolean) => void;
  question: ConsultNoteQuestion;
}

export default ({ question, handleEdit }: Props) => {
  const [showingSymptomGuide, setShowingSymptomGuide] = useState(false);

  const [substanceuses] = useShallowEqualSelector((state) => [
    state.consultnote?.openConsult?.consult_note?.substanceuses || [],
  ]);

  return (
    <div style={{ paddingTop: "15px", paddingBottom: "15px" }}>
      <Title margin="3px" size="sm">
        <b>
          {question.questionNumber}. {question.title}{" "}
        </b>
        {question.isRequired && <RequiredText> *</RequiredText>}
        <span
          style={{ cursor: "pointer", paddingLeft: "12px" }}
          onClick={() => setShowingSymptomGuide(!showingSymptomGuide)}
        >
          <InfoCircleOutlined /> <i>Show symptom guide</i>
        </span>
        {showingSymptomGuide && <SymptomGuide />}
      </Title>
      {range(ENTRY_COUNT).map((num) => {
        const orderNumber = num + 1;

        const entry = substanceuses.find((su) => su.order === orderNumber);
        return (
          <SubstanceUseSubQuestion
            entry={entry}
            orderNumber={orderNumber}
            key={entry?.id}
            handleEdit={handleEdit}
          />
        );
      })}
    </div>
  );
};
