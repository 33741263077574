import { styledStitches } from "@/app/design-system";
import React from "react";

const MAIN_LANDMARK_ID = "main-content";

interface MainProps {
  children: React.ReactNode;
}

const StyledMainContent = styledStitches("main", {
  minHeight: "100%",
});
export const MainContent = ({ children }: MainProps) => {
  return (
    <StyledMainContent id={MAIN_LANDMARK_ID}>{children}</StyledMainContent>
  );
};
