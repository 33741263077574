import { CalendarOutlined } from '@ant-design/icons';
import { Popover } from "antd";
import { TooltipPlacement } from "antd/es/tooltip";
import React from "react";
import { Time } from "../../../api/types";
import { IfPermitted } from "../../_helpers/permissions";
import { useShallowEqualSelector } from "../../_helpers/redux";
import Mangle from "../../_shared/Mangle";
import { shortenClientName, shortenCoupleName, useCellResizer } from "../Cell";
import { OpenReservationTimeslot, OpenTimeslot } from "../EditTimeslot";
import EventCellPopoverContent from "../Popover";
import {
  DayOfWeek,
  EventCellType,
  EventMap,
  Recurrence,
  ReservationType,
  SlotEventType,
} from "../types";
import { DisplaySide } from "./types";
import { ActionsContainer, CellContainer, CellLabel } from "./_common";

export type EventCellBaseProps = {
  clinicianId: number;
  day: DayOfWeek;
  hour: Time;
  typeId: string;
  eventIds: string[];
  numWeeksInRange: number;
  eventType: EventCellType | SlotEventType;
  key: string;
};

export type EventCellFullProps = EventCellBaseProps & {
  eventMap: EventMap;
  displaySide: DisplaySide;
  rowIndex?: number;
};

export const CellWithEvent = (props: EventCellFullProps) => {
  const {
    clinicianId,
    day,
    hour,
    typeId,
    displaySide,
    eventIds,
    rowIndex,
    eventType,
  } = props;

  const { clientMap, coupleMap } = useShallowEqualSelector(state => ({
    clientMap: state.slottool.clientMap,
    coupleMap: state.slottool.coupleMap,
  }));

  const thisElement = React.useRef<HTMLDivElement>(null);
  const [cellPosition, windowWidth] = useCellResizer(thisElement);
  let popoverPlacement: TooltipPlacement = "right";
  if (cellPosition) {
    popoverPlacement =
      (cellPosition.right + cellPosition.left) / 2 >= windowWidth / 2
        ? "left"
        : "right";
  }

  let eventCellTitle;
  if (eventType === "block") {
    eventCellTitle = (
      <>
        <CalendarOutlined />
        &nbsp; Block
      </>
    );
  } else if (eventType === "therapy") {
    eventCellTitle = shortenClientName(clientMap[typeId]);
  } else {
    eventCellTitle = shortenCoupleName(coupleMap[typeId]);
  }

  return (
    <Popover
      placement={popoverPlacement}
      content={EventCellPopoverContent({
        eventIds,
        typeId,
        day,
        hour,
        displaySide,
        eventType,
      })}
      open={false}
    >
      <CellContainer
        ref={thisElement}
        displaySide={displaySide}
        eventType={eventType}
        rowIndex={rowIndex}
      >
        <CellLabel>
          <Mangle>{eventCellTitle}</Mangle>
        </CellLabel>
        <ActionsContainer>
          <IfPermitted
            permissions={["IsConsultTimeslotEditor"]}
            requireAll={false}
          >
            <OpenReservationTimeslot
              clinicianId={clinicianId}
              day={day}
              hour={hour}
              recurrence={
                (displaySide === "both" ? "weekly" : "biweekly") as Recurrence
              }
              even_odd_week={
                displaySide === "left"
                  ? "even"
                  : displaySide === "right"
                  ? "odd"
                  : undefined
              }
              reservation_type={"consult" as ReservationType}
            />
          </IfPermitted>
          <IfPermitted
            permissions={["IsATSlotEditor", "IsClinicalLeader"]}
            requireAll={false}
          >
            <OpenTimeslot
              clinicianId={clinicianId}
              day={day}
              hour={hour}
              recurrence={
                (displaySide === "both" ? "weekly" : "biweekly") as Recurrence
              }
              even_odd_week={
                displaySide === "left"
                  ? "even"
                  : displaySide === "right"
                  ? "odd"
                  : undefined
              }
            />
          </IfPermitted>
        </ActionsContainer>
      </CellContainer>
    </Popover>
  );
};
