import {
  Flex,
  Spinner,
  Stack,
  Text,
  styledStitches,
} from "@/app/design-system";
import { QuarterCircle } from "@/app/design-system";
import { GroupTherapySessionSelector } from "../../notes/_components/session-selector-dropdown";
import {
  AllParsedMBCScoreData,
  ParsedClientEventMbcScores,
} from "../_api/use-group-therapy-mbc-events/use-group-therapy-mbc-events.interface";
import { GroupClientMbcPane } from "./group-client-mbc-pane";
import { ScoreOverview } from "./score-overview";

interface WeeklyCheckInOverviewHeaderProps {
  isLoading: boolean;
  eventData: AllParsedMBCScoreData | null;
  isFirstSession: boolean;
}

export const WeeklyCheckInOverviewHeader = ({
  isLoading,
  eventData,
  isFirstSession,
}: WeeklyCheckInOverviewHeaderProps) => (
  <Flex
    flexDirection="column"
    css={{
      flex: 1,
      p: 20,
    }}
  >
    <Flex alignItems={"center"}>
      {isLoading ? (
        <Flex css={{ marginLeft: 8 }}>
          <Spinner>
            <QuarterCircle dimension={"1.5em"} />
          </Spinner>
        </Flex>
      ) : null}
    </Flex>
    <WeeklyCheckInOverviewHeaderContent
      isLoading={isLoading}
      eventData={eventData}
      isFirstSession={isFirstSession}
    />
    <StyledHeader>
      <Text fontSize={18}> Individual Check-in Results </Text>
    </StyledHeader>
    <GroupClientMbcPanes
      isLoading={isLoading}
      eventData={eventData}
      isFirstSession={isFirstSession}
    />
  </Flex>
);

const WeeklyCheckInOverviewHeaderContent = ({
  isLoading,
  eventData,
  isFirstSession,
}: WeeklyCheckInOverviewHeaderProps) => (
  <>
    {!isLoading ? (
      <>
        <Flex>
          <StyledTitle>Group Average</StyledTitle>
          <GroupTherapySessionSelector />
        </Flex>
        <SessionScoreSummaries
          allEventData={eventData}
          isFirstSession={isFirstSession}
        />
      </>
    ) : null}
  </>
);

const GroupClientMbcPanes = ({
  isLoading,
  eventData,
}: WeeklyCheckInOverviewHeaderProps) => {
  const eventScores =
    eventData && eventData.eventScores ? eventData.eventScores[0] : null;
  const clientData =
    eventScores && eventScores.clientScores
      ? eventScores.clientScores.filter((client) => client.isEnrolled)
      : null;
  return !isLoading ? (
    <Stack css={{ padding: 4 }}>
      {clientData?.map((client: ParsedClientEventMbcScores, index: number) => {
        return (
          <GroupClientMbcPane
            index={index}
            clientId={client.clientId}
            clientName={client.clientName}
            PHQ={client.PHQ}
            GAD={client.GAD}
            cohesion={client.cohesion}
            isCohesion={
              eventScores ? eventScores.groupEventScores?.isCohesion : false
            }
            endorsedSI={client.endorsedSI}
            isComplete={client.isComplete}
            isEnrolled={client.isEnrolled}
          />
        );
      })}
    </Stack>
  ) : null;
};

interface SessionScoreSummariesProps {
  allEventData: AllParsedMBCScoreData | null;
  isFirstSession: boolean;
}

export const SessionScoreSummaries = ({
  allEventData,
  isFirstSession,
}: SessionScoreSummariesProps) => {
  const eventScores =
    allEventData?.eventScores &&
    allEventData.eventScores[0] &&
    allEventData.eventScores[0].groupEventScores
      ? allEventData.eventScores[0].groupEventScores
      : null;
  const baseline = allEventData?.baseline;

  const isCohesionFirstMBCSession =
    eventScores?.cohesion === baseline?.cohesion ? true : false;

  return (
    <Flex>
      <ScoreOverview
        scoreName={"gad"}
        score={!!eventScores && !!eventScores.gad ? eventScores.gad : null}
        baseline={baseline?.gad}
        isFirstSession={isFirstSession}
        isCheckInCompletion={false}
      />

      <ScoreOverview
        scoreName={"phq"}
        score={!!eventScores && !!eventScores.phq ? eventScores.phq : null}
        baseline={baseline?.phq}
        isFirstSession={isFirstSession ? isFirstSession : false}
        isCheckInCompletion={false}
      />

      {baseline?.cohesion ? (
        <ScoreOverview
          scoreName={"cohesion"}
          score={
            !!eventScores && !!eventScores.cohesion
              ? eventScores.cohesion
              : null
          }
          baseline={baseline?.cohesion}
          isFirstSession={isCohesionFirstMBCSession}
          isCheckInCompletion={false}
        />
      ) : null}

      <ScoreOverview
        scoreName={"Check-in completion"}
        isFirstSession={isFirstSession ? isFirstSession : false}
        isCheckInCompletion={true}
        totalResponded={eventScores?.totalAnswered}
        totalSent={eventScores?.totalSent}
      />
    </Flex>
  );
};

const StyledTitle = styledStitches(Text, {
  flexGrow: 1,
  fontSize: 18,
  pb: 15,
  minWidth: 250,
});

const StyledHeader = styledStitches(Flex, {
  flexGrow: 1,
  paddingTop: 20,
  paddingBottom: 16,
  alignItems: "center",
  justifyContent: "flex-start",
});
