import { Badge, Button, Col, Row, Spin, Tooltip } from "antd";
import * as React from "react";
import { useDispatch } from "react-redux";
import { ClinicianHold, ClinicianWithConfiguration } from "../../api/types";
import * as colors from "../../assets/colors";
import { clinicianActions, HoldStatusMap } from "../../state/models/clinicians";
import {
  IfPermitted,
  useUserHasAnyPermissions,
} from "../_helpers/permissions";
import { useShallowEqualSelector } from "../_helpers/redux";
import { Pad } from "../_layout/display";
import ExpandableRowItem from "../_shared/ExpandableRowItem";
import Scheduler from "./Schedules";
import { EditScheduledSessionsTargetModal } from "./EditScheduledSessionsTargetModal";
import { ClinicianSchedulePreference } from "./api/use-clinician-schedule-preference/use-get-clinician-schedule-preference";
import { ListItemBanner } from "./ListItemBanner";
import { DateTime } from "luxon";
import { RampWeek } from "../../api/types";


interface HoldButtonProps {
  hold: ClinicianHold;
  onHoldButtonClick: (hold?: ClinicianHold) => void;
}

const HoldButton = ({ hold, onHoldButtonClick }: HoldButtonProps) => {
  const userSeesCogsworthVersion = useUserHasAnyPermissions([
    "IsCogsworthBetaUser",
  ]);
  const userSeesCogsworthOpsVersion = useUserHasAnyPermissions([
    "IsCogsworthOpsUser",
  ]);

  return (
    <Button
      block={!userSeesCogsworthVersion && !userSeesCogsworthOpsVersion}
      size="small"
      style={{ width: "88px" }}
      disabled={!!hold && !!userSeesCogsworthOpsVersion}
      // type={!hold || userSeesCogsworthVersion ? "default" : "primary"}
      type={userSeesCogsworthVersion ? "default" : hold ? "primary" : "default"}
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        if (userSeesCogsworthVersion && hold) {
          e.stopPropagation();
          return;
        }
        onHoldButtonClick(hold);
        e.stopPropagation();
      }}
    >
      {userSeesCogsworthVersion
        ? "Pause"
        : hold
        ? "Modify Hold"
        : "Add Hold"}
    </Button>
  );
};

const pauseDisplay = (hold: ClinicianHold) => {
  return (
    <div style={{backgroundColor: "#646464", color:"white", display:"inline", padding:"4px"}}>
    Paused on {DateTime.fromISO(hold.start_at).toFormat('M/d')}
  </div>)
}

const cogsWorthButtonDisplay = (hold : ClinicianHold, onHoldButtonClick: () => void) => {
  const userSeesCogsworthVersion = useUserHasAnyPermissions([
    "IsCogsworthBetaUser",
  ]);
  const userSeesCogsworthOpsVersion = useUserHasAnyPermissions([
    "IsCogsworthOpsUser",
  ]);
  return (
    <div style={{display:"inline"}}>
      {userSeesCogsworthVersion && !userSeesCogsworthOpsVersion && hold
        ? pauseDisplay(hold)
        : <HoldButton hold={hold} onHoldButtonClick={onHoldButtonClick} />
      }
    </div>
  )
}


interface Props {
  clinician: ClinicianWithConfiguration;
  holdStatus: HoldStatusMap;
  onHoldButtonClick: (hold?: ClinicianHold) => void;
  clinicianSchedulePreference: ClinicianSchedulePreference | undefined;
  alphaGroupClinicianIds: number[];
}

const ClinicianListItem = ({
  clinician,
  holdStatus,
  onHoldButtonClick,
  clinicianSchedulePreference,
  alphaGroupClinicianIds,
}: Props) => {
  const loadingActiveHolds = useShallowEqualSelector(
    (state) => state.clinicians.loadingActiveHolds,
  );
  const dispatch = useDispatch();
  const userSeesCogsworthVersion = useUserHasAnyPermissions([
    "IsCogsworthBetaUser",
  ]);
  const userSeesCogsworthOpsVersion = useUserHasAnyPermissions([
    "IsCogsworthOpsUser",
  ]);

  return (
    <ExpandableRowItem
      style={{
        border: `1px solid ${colors.$border}`,
        marginBottom: "10px",
        backgroundColor: "white",
      }}
      hideDivider={true}
      headerStyle={{
        height: "35px",
        borderBottom: `1px solid ${colors.$border}`,
      }}
      header={
        <Row style={{ display: 'grid', gridTemplateColumns: '1fr 470px', alignItems: 'center', height: '100%' }} align="middle">
          <div style={{ paddingLeft: "15px" }}>
            {clinician.first_name} {clinician.last_name}, {clinician.degree}{" "}
            {clinician.is_kaiser_norcal_approved && (
              <Tooltip placement="top" title={"KP Clinician"}>
                <Badge count={1} dot={true} color={colors.$night} />
              </Tooltip>
            )}
          </div>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            gap: '12px',
          }}>
            {(userSeesCogsworthVersion || userSeesCogsworthOpsVersion) && (
              <p style={{ marginBottom: '0px' }}>Client Scheduler:</p>
            )}
            <div>
              <Spin spinning={loadingActiveHolds}>
                {cogsWorthButtonDisplay(holdStatus[clinician.id], onHoldButtonClick)}
              </Spin>
            </div>
            {userSeesCogsworthOpsVersion && clinicianSchedulePreference && (
              <div>
                <EditScheduledSessionsTargetModal
                  clinician={clinician}
                  default_target={clinicianSchedulePreference.default_target}
                  target_scheduled_sessions={
                    clinicianSchedulePreference.target_scheduled_sessions
                  }
                />
              </div>
            )}
            {userSeesCogsworthVersion && !userSeesCogsworthOpsVersion && clinicianSchedulePreference && clinicianSchedulePreference.target_scheduled_sessions !== clinicianSchedulePreference.default_target && (
              <div style={{ marginLeft: '12px' }}>
                <NonDefaultTarget
                  clinicianSchedulePreference={clinicianSchedulePreference}
                  ramp_schedule={clinician.configuration?.ramp_schedule}
                />
              </div>
            )}
            {userSeesCogsworthOpsVersion &&
              <div style={{ marginLeft: '12px' }}>
                {holdStatus[clinician.id] ? pauseDisplay(holdStatus[clinician.id]) : clinicianSchedulePreference && clinicianSchedulePreference.target_scheduled_sessions !== clinicianSchedulePreference.default_target ? (
                  <NonDefaultTarget
                    clinicianSchedulePreference={clinicianSchedulePreference}
                    ramp_schedule={clinician.configuration?.ramp_schedule}
                  />
                ) : null}
              </div>
            }
          </div>
        </Row>
      }
      content={
        <>
          <ListItemBanner
            holdStatus={holdStatus}
            clinician={clinician}
            clinicianSchedulePreference={clinicianSchedulePreference}
            userSeesCogsworthVersion={userSeesCogsworthVersion}
          />
          {!alphaGroupClinicianIds.includes(clinician.id) && (
            <IfPermitted permissions={["IsFlexSchedulingUser"]} requireAll={true}>
              <Row>
                <Pad>
                  <Scheduler clinician={clinician} />
                </Pad>
              </Row>
            </IfPermitted>
          )}
          <Pad>
            <Button
              onClick={() =>
                dispatch(clinicianActions.setTagModalClinicianId(clinician.id))
              }
            >
              View Match Tags
            </Button>
          </Pad>
        </>
      }
    />
  );
};

export default ClinicianListItem;

interface NonDefaultTargetProps {
  clinicianSchedulePreference: ClinicianSchedulePreference;
  ramp_schedule: { [key: string]: RampWeek } | null | undefined;
}

const NonDefaultTarget = ({
  clinicianSchedulePreference,
  ramp_schedule,
}: NonDefaultTargetProps) => {
  let isRamping = false;
  if (ramp_schedule) {
    const weeks = Object.keys(ramp_schedule)
    const currentRampWeekIndex = weeks.findIndex((week) =>
      DateTime.fromISO(week).hasSame(DateTime.now(), "week"),
    )
    if (currentRampWeekIndex !== -1) {
      isRamping = true;
    }
  }

  return (
    <span>
      {`${isRamping ? "Ramp" : "Modified"} Target: ${clinicianSchedulePreference.target_scheduled_sessions}`}
    </span>
  )
}
