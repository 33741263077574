import * as React from "react";

import {
  CheckCircleFilled,
  CheckOutlined,
  CloseSquareTwoTone,
  StarFilled,
} from "@ant-design/icons";

import { Tooltip } from "antd";
import { MatchTag, ClinicianMatchTag } from "../../api/types";
import { values, intersectionBy, differenceBy, uniq } from "lodash-es";
import * as colors from "../../assets/colors";

import {
  NEEDS_TAG_WEIGHT,
  BENEFIT_TAG_WEIGHT,
  EXPERT_TAG_WEIGHT,
} from "../../api/constants";
import { useShallowEqualSelector } from "../_helpers/redux";
import { Row } from "../_layout/Flex";
import { UseGetBulkClinicianTagsData, UseGetClinicianTagTags, useGetClinicianTags } from "../api/use-get-clinician-tags/use-get-clinician-tags";

interface Props {
  comparisonTagMap: { [id: number]: MatchTag };
  clinicianTags: UseGetClinicianTagTags;
  clinicianId: number;
  version: 1 | 2 | 3;
}

const TagListComparison = ({ comparisonTagMap, version, clinicianTags }: Props) => {

  const inclusiveCategories =
    useShallowEqualSelector((state) => (
      state.matches.inclusiveCategories
    ));

  const filteredClinicianTags = clinicianTags?.filter((ct) => ct.version === version) ?? [];

  const totalTags = values(comparisonTagMap);
  const totalNeedsTags = totalTags.filter(
    (tag: MatchTag) => tag.weight === NEEDS_TAG_WEIGHT,
  );
  const totalBenefitTags = totalTags.filter(
    (tag: MatchTag) => tag.weight === BENEFIT_TAG_WEIGHT,
  );

  const matchedNeedsTags = filteredClinicianTags.filter(ct => totalNeedsTags.map(t => t.id).includes(ct.tag_id));
  const matchedBenefitTags = filteredClinicianTags.filter(ct => totalBenefitTags.map(t => t.id).includes(ct.tag_id));

  const missingInclusiveCategories: string[] = [];
  let missingTags = totalTags.filter(t => !filteredClinicianTags.map(ct => ct.tag_id).includes(t.id));
  missingTags = missingTags.filter((tag: MatchTag) => {
    const incCat = inclusiveCategories.find(
      (category) => tag.category === category,
    );
    if (
      incCat &&
      !matchedNeedsTags.find((t) => t.ehr_cliniciantag_ehr_tag.category === incCat) &&
      !matchedBenefitTags.find((t) => t.ehr_cliniciantag_ehr_tag.category === incCat)
    ) {
      missingInclusiveCategories.push(incCat);
    }
    return !incCat;
  });
  return (
    <div
      style={{
        marginBottom: "10px",
        paddingLeft: "10px",
        borderLeft: "solid 3.5px " + colors.$greyBorder,
      }}
    >
      {matchedNeedsTags.length > 0 && (
        <>
          <strong>Needs</strong>
          {matchedNeedsTags
            .sort(
              (a, b) =>
                b.weight - a.weight,
            )
            .map((tag) => {
              const isExpertTag = tag.weight === EXPERT_TAG_WEIGHT;
              const tagName =
                (inclusiveCategories.find((cat) => cat === tag.ehr_cliniciantag_ehr_tag.category)
                  ? tag.ehr_cliniciantag_ehr_tag.category + " - "
                  : "") + tag.ehr_cliniciantag_ehr_tag.name;
              return (
                <TagItem
                  key={tagName}
                  name={tagName}
                  type={isExpertTag ? "expert" : null}
                  starred={Boolean(comparisonTagMap[tag.id]?.starred)}
                />
              );
            })}
        </>
      )}
      <div>
        {matchedBenefitTags.length > 0 && <strong>Benefits</strong>}
        {matchedBenefitTags
          .sort(
            (a, b) => b.weight - a.weight,
          )
          .map((tag) => {
            const isExpertTag = tag.weight === EXPERT_TAG_WEIGHT;
            const tagName =
              (inclusiveCategories.find((cat) => cat === tag.ehr_cliniciantag_ehr_tag.category)
                ? tag.ehr_cliniciantag_ehr_tag.category + " - "
                : "") + tag.ehr_cliniciantag_ehr_tag.name;
            return (
              <TagItem
                key={tagName}
                name={tagName}
                type={isExpertTag ? "expert" : null}
                starred={Boolean(comparisonTagMap[tag.id]?.starred)}
              />
            );
          })}
        {(missingInclusiveCategories.length > 0 || missingTags.length > 0) && (
          <strong>Missing</strong>
        )}
        {missingTags.map((tag: MatchTag) => {
          const tagName =
            (inclusiveCategories.find((cat) => cat === tag.category)
              ? tag.category + " - "
              : "") + tag.name;
          return <TagItem key={tagName} name={tagName} type="missing" />;
        })}
        {uniq(missingInclusiveCategories).map((category: string) => (
          <TagItem key={category} name={category} type="missing" />
        ))}
      </div>
    </div>
  );
};

const TagItem = ({
  name,
  type,
  starred,
}: {
  name: string;
  type: "expert" | "missing" | null;
  starred?: boolean;
}) => {
  const icon = type ? (
    type === "expert" ? (
      <Tooltip title="Expert">
        <CheckCircleFilled twoToneColor={colors.$green} />
      </Tooltip>
    ) : (
      <Tooltip title="Missing">
        <CloseSquareTwoTone twoToneColor={colors.$red} />
      </Tooltip>
    )
  ) : (
    <CheckOutlined />
  );

  const color = type
    ? type === "expert"
      ? colors.$green
      : colors.$red
    : undefined;

  return (
    <Row layout="start center">
      <div style={{ width: "20px" }}>
        {starred && <StarFilled style={{ color: colors.$secondary }} />}
      </div>
      <div
        style={{
          color,
        }}
      >
        {icon} {name}
      </div>
    </Row>
  );
};

export default TagListComparison;
