import { blue, green, yellow, red, orange, gray, violet } from "@radix-ui/colors";

export const neutrals = {
  neutral0: "#ffffff",
  neutral1: gray.gray1,
  neutral2: gray.gray2,
  neutral3: gray.gray3,
  neutral4: gray.gray4,
  neutral5: gray.gray5,
  neutral6: gray.gray6,
  neutral7: gray.gray7,
  neutral8: gray.gray8,
  neutral9: gray.gray9,
  neutral10: gray.gray10,
  neutral11: gray.gray11,
  neutral12: gray.gray12,
};

export const colors = {
  brandYellow: "hsla(47, 100%, 49%, 1)",
  brandGreen: "hsla(60, 26%, 27%, 1)",
  brandWarmGrey: "hsla(48, 36%, 95%, 1)",
  brandBlue: "hsla(221, 25%, 26%, 1)",
  ...neutrals,
  red3: red.red3,
  red9: red.red9,
  red11: red.red11,
  orange3: orange.orange3,
  orange9: orange.orange9,
  orange11: orange.orange11,
  yellow3: yellow.yellow3,
  yellow10: yellow.yellow10,
  yellow11: yellow.yellow11,
  green3: green.green3,
  green9: green.green9,
  green10: green.green10,
  green11: green.green11,
  blue3: blue.blue3,
  blue9: blue.blue9,
  blue11: blue.blue11,
  violet3: violet.violet3,
  violet9: violet.violet9,
  violet11: violet.violet11,
};

type AllColorsForStyledStitches = Record<`$${keyof typeof colors}`, Record<'color', string>>;
export const allColorsForStyledStitches: AllColorsForStyledStitches = (Object.entries({...colors}).reduce(
  (collector, [key, color]) => ({ ...collector, [`$${key}`]: { color: `$${key}` }}), {}
)) as AllColorsForStyledStitches;
