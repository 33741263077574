import * as React from "react";
import { PlusOutlined } from '@ant-design/icons';
import { Modal, Pagination } from "antd";

import { Category } from "../types";
import { Container, ResourceContainer, ResourceContent } from "../styles";
import { EditCategoryForm } from "./EditCategoryForm";
import { Row } from "../../_layout/Flex";
import { ADDED_BY_ME_CATEGORY_ID, FAVORITES_CATEGORY_ID } from "../utils";
import { CategoryItem } from "../Resources/CategoryItem";

export const CategoriesList = ({
  categories,
  onSelect,
  onUpdateCategory,
  allowAddCategory,
  onChangePage,
  showPagination,
  currentPage,
  totalCount,
}: {
  categories: Category[];
  onSelect: (id: string) => void;
  onChangePage: (nextPage: number) => void;
  onUpdateCategory: (next: Category) => Promise<void>;
  allowAddCategory: boolean;
  showPagination: boolean;
  currentPage: number;
  totalCount: number;
}) => {
  const [editCategory, setEditCategory] = React.useState<Category>();

  const onSetEditCategory = (item: Category) => {
    setEditCategory(item);
  };

  const nonPersonalCategories = categories.filter(
    category =>
      category.id !== FAVORITES_CATEGORY_ID &&
      category.id !== ADDED_BY_ME_CATEGORY_ID,
  );

  return (
    <>
      <h3 style={{ marginLeft: 16, paddingLeft: 8 }}>Resource Categories</h3>
      <Container>
        {allowAddCategory && (
          <ResourceContainer>
            <ResourceContent
              onClick={() =>
                setEditCategory({
                  id: "",
                  name: "",
                  items: [],
                  is_published: true,
                })
              }
            >
              <PlusOutlined style={{ fontSize: 24, marginBottom: 16 }} />
              Add category
            </ResourceContent>
          </ResourceContainer>
        )}
        {nonPersonalCategories.map((item) => (
          <CategoryItem
            key={item.id}
            item={item}
            onSelect={onSelect}
            onSetEditCategory={onSetEditCategory}
          />
        ))}
        {categories.length === 0 && !allowAddCategory && (
          <p style={{ marginLeft: 8, paddingLeft: 8 }}>
            There are no categories
          </p>
        )}
      </Container>
      {showPagination && (
        <Row style={{ margin: "0 16px", padding: 8 }}>
          <Pagination
            pageSize={10}
            onChange={onChangePage}
            current={currentPage}
            total={totalCount}
          />
        </Row>
      )}
      <Modal
        title="Category"
        open={!!editCategory}
        footer={null}
        onCancel={() => setEditCategory(undefined)}
        bodyStyle={{ paddingTop: 8 }}
      >
        {editCategory && (
          <EditCategoryForm
            initialCategory={editCategory}
            onCancel={() => setEditCategory(undefined)}
            onUpdateCategory={(next) => {
              onUpdateCategory(next);
              setEditCategory(undefined);
            }}
          />
        )}
      </Modal>
    </>
  );
};
