import * as React from "react";

export default () => {
  return (
    <div style={{ fontSize: "14px" }}>
      <div>Traumatic event:</div>
      <div>
        <b>Client denied current Impact</b> OR{" "}
        <b>Client endorsed current Impact</b>
      </div>
      <div>If endorsed, consider assessing:</div>
      <ol>
        <li>Intrusion:</li>
        <ul>
          <li>Unwanted upsetting memories</li>
          <li>Nightmares</li>
          <li>Flashbacks</li>
          <li>Emotional distress after exposure to traumatic reminders</li>
          <li>Physical reactivity after exposure to traumatic reminders</li>
        </ul>
        <li>Avoidance:</li>
        <ul>
          <li>Trauma-related thoughts or feelings</li>
          <li>Trauma-related external reminders</li>
        </ul>
        <li>Mood/cognition changes:</li>
        <ul>
          <li>Inability to recall key features of the trauma</li>
          <li>
            Overly negative thoughts and assumptions about oneself or the world
          </li>
          <li>Exaggerated blame of self or others for causing the trauma </li>
          <li>Negative affect </li>
          <li>Decreased interest in activities</li>
          <li>Feeling isolated </li>
          <li>Difficulty experiencing positive affect</li>
        </ul>
        <li>Arousal/reactivity changes:</li>
        <ul>
          <li>Irritability or aggression</li>
          <li>Risky or destructive behavior</li>
          <li>Hypervigilance</li>
          <li>Heightened startle reaction</li>
          <li>Difficulty concentrating</li>
          <li>Difficulty sleeping</li>
        </ul>
      </ol>
    </div>
  );
};
