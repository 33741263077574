import { IconProps } from "../icon.interface";

export const LogoutIcon = ({ title }: IconProps) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden={!title}
    >
      {title ? <title>{title}</title> : null}
      <path
        d="M7.5 18.5C8.05228 18.5 8.5 18.0523 8.5 17.5C8.5 16.9477 8.05228 16.5 7.5 16.5V18.5ZM2.5 15.8333H1.5H2.5ZM4.16667 2.5V1.5V2.5ZM7.5 3.5C8.05228 3.5 8.5 3.05228 8.5 2.5C8.5 1.94772 8.05228 1.5 7.5 1.5V3.5ZM12.6262 13.4596C12.2357 13.8501 12.2357 14.4832 12.6262 14.8738C13.0168 15.2643 13.6499 15.2643 14.0404 14.8738L12.6262 13.4596ZM17.5 10L18.2071 10.7071C18.5976 10.3166 18.5976 9.68342 18.2071 9.29289L17.5 10ZM14.0404 5.12623C13.6499 4.7357 13.0168 4.7357 12.6262 5.12623C12.2357 5.51675 12.2357 6.14992 12.6262 6.54044L14.0404 5.12623ZM7.5 9C6.94772 9 6.5 9.44772 6.5 10C6.5 10.5523 6.94772 11 7.5 11V9ZM7.5 16.5H4.16667V18.5H7.5V16.5ZM4.16667 16.5C3.98986 16.5 3.82029 16.4298 3.69526 16.3047L2.28105 17.719C2.78115 18.219 3.45942 18.5 4.16667 18.5V16.5ZM3.69526 16.3047C3.57024 16.1797 3.5 16.0101 3.5 15.8333H1.5C1.5 16.5406 1.78095 17.2189 2.28105 17.719L3.69526 16.3047ZM3.5 15.8333V4.16667H1.5V15.8333H3.5ZM3.5 4.16667C3.5 3.98986 3.57024 3.82029 3.69526 3.69526L2.28105 2.28105C1.78095 2.78115 1.5 3.45942 1.5 4.16667H3.5ZM3.69526 3.69526C3.82029 3.57024 3.98986 3.5 4.16667 3.5V1.5C3.45942 1.5 2.78115 1.78095 2.28105 2.28105L3.69526 3.69526ZM4.16667 3.5H7.5V1.5H4.16667V3.5ZM14.0404 14.8738L18.2071 10.7071L16.7929 9.29289L12.6262 13.4596L14.0404 14.8738ZM18.2071 9.29289L14.0404 5.12623L12.6262 6.54044L16.7929 10.7071L18.2071 9.29289ZM17.5 9H7.5V11H17.5V9Z"
        fill="#171717"
      />
    </svg>
  );
};
