import { useMainNavigationUtils } from "../../../hooks/use-main-navigation-utils";

import { ControlOutlined } from "@ant-design/icons";

import { NavigationMenuButton } from "../../components.utility/navigation-menu-button";

interface AQMTuningMenuItemProps {
  isCollapsed: boolean;
}

export function AQMTuningMenuItem({ isCollapsed }: AQMTuningMenuItemProps) {
  const { setAqmTunerIsOpen } = useMainNavigationUtils();

  return (
    <NavigationMenuButton
      callback={() => setAqmTunerIsOpen()}
      isCollapsed={isCollapsed}
      menuItemTooltipContent="AQM Tuning"
    >
      <ControlOutlined
        style={{ strokeWidth: 2, fontSize: 20 }}
        aria-hidden={!isCollapsed}
        title={!isCollapsed ? "" : "AQM Tuning"}
      />
      {!isCollapsed ? "AQM Tuning" : null}
    </NavigationMenuButton>
  );
}
