import { useGetCliniciansByAuthGroupQuery, GetCliniciansByAuthGroupQuery } from '@/graphql/generated'
import { graphqlClient } from '@/api/graphql';
import { UserGroup } from '@/api/types';

interface UseGetCliniciansByAuthGroupParams<T extends object> {
  groupName: UserGroup;
  select?: (data: GetCliniciansByAuthGroupQuery) => T;
  enabled?: boolean;
}

export const useGetCliniciansByAuthGroup = <T extends {}>({
  groupName,
  select,
}: UseGetCliniciansByAuthGroupParams<T>) => {
  const { data, isLoading } = useGetCliniciansByAuthGroupQuery(
    graphqlClient,
    { group_name: groupName },
    { select },
  );
  return { cliniciansInGroup: data, isLoadingCliniciansInGroup: isLoading };
};
