import { clinicianActions } from "@/state/models/clinicians";
import { ControlOutlined } from "@ant-design/icons";

import { useDispatch } from "react-redux";
import { useAppPermissions } from "../../../hooks/use-app-permissions";
import { NavigationMenuButton } from "../../components.utility/navigation-menu-button";

interface MyMatchTagsMenuItemProps {
  isCollapsed: boolean;
}

export function MyMatchTagsMenuItem({ isCollapsed }: MyMatchTagsMenuItemProps) {
  const dispatch = useDispatch();

  const { currentUserClinician } = useAppPermissions();

  return (
    <NavigationMenuButton
      menuItemTooltipContent="My Match Tags"
      isCollapsed={isCollapsed}
      callback={() =>
        dispatch(
          clinicianActions.setTagModalClinicianId(currentUserClinician!.id),
        )
      }
    >
      <ControlOutlined
        style={{ strokeWidth: 2, fontSize: 20 }}
        aria-hidden={!isCollapsed}
        title={!isCollapsed ? "" : "My Match Tags"}
      />
      {!isCollapsed ? "My Match Tags" : null}
    </NavigationMenuButton>
  );
}
