import { Text, Flex } from "@/app/design-system";
import { styledStitches } from "@/app/design-system/styles/stitches.config";

export const BasicInfoQuestionText = styledStitches(Text, {
  fontWeight: 500,
  fontSize: 16,
});

export const BasicInfoQuestionContainer = styledStitches(Flex, {
  width: 257,
  justifyContent: "end",
});

export const BasicInfoResponseText = styledStitches(Text, {
  paddingLeft: 33,
  fontWeight: 300,
  fontSize: 16,
});

export const BasicInfoQuestionAndResponseContainer = styledStitches(Flex, {
  fontFamily: "Inter, arial",
  minWidth: 500,
  "@breakpoint1200": {
    flexDirection: "row",
  },
  pb: 24,
});

export const SectionDivider = styledStitches(Flex, {
  borderTop: "2px solid $neutral6",
  marginTop: 19,
  marginBottom: 43,
});
