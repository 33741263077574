import { getCurrentClinicianAttendedSessions } from "@/app/library/utils";
import { getMostRecentClientPayerServiceRecord } from "@/app/api/utils/get-most-recent-client-payer-service-record.util";
import {
  ClientOverviewFragment,
  ClientOverviewQuery,
} from "../../../../../../graphql/generated";
import {
  ClientOverviewApiData,
  ClientPayerServiceRecord,
  EventApiData,
  ManualSlotReservationApiData,
} from "../../../../../api/use-my-clients-overview/my-clients-data.interface";
import {
  getCombinedSeverity,
  parseSessions,
  sortAndFilterManualSlotReservations,
} from "../../../../api/utils/select-data-for-my-clients.util";

const parseClientOverviewData = (
  clients: ClientOverviewFragment,
  clinicianId?: number,
): ClientOverviewApiData => {
  const {
    clientId,
    email,
    firstName,
    lastName,
    preferredName,
    events,
    phoneNumber,
    clientOverview,
    diagnosisRecords,
    clientPayerServiceRecords,
    manualSlotReservations,
    careLocation,
    matches
  } = clients ?? {};

  const {
    primaryAssessment,
    mbcTrackStatus,
    mbcTrackStatusBeta,
    suggestedRange,
    severity,
    welkinLink,
    healthieLink,
    healthieId,
    endorsedSi,
  } = clientOverview ?? {};


  const { nextSession, upcomingSessions, sessionHistorySessions } =
    parseSessions(events as EventApiData[], clinicianId);

  const sortedActiveManualSlotReservations =
    sortAndFilterManualSlotReservations(
      manualSlotReservations as ManualSlotReservationApiData[],
    );

  const combinedSeverity = getCombinedSeverity(severity);

  const currentClientPayerServiceRecord = getMostRecentClientPayerServiceRecord(
    clientPayerServiceRecords as ClientPayerServiceRecord[],
  );

  return {
    clientId,
    firstName: firstName ?? "",
    lastName: lastName ?? "",
    preferredName: preferredName ?? null,
    email,
    // parse "most recent cpsr"
    clientPayerServiceRecord:
      clientPayerServiceRecords && clientPayerServiceRecords.length > 0
        ? currentClientPayerServiceRecord
        : null,
    primaryDiagnosis:
      diagnosisRecords && diagnosisRecords.length > 0
        ? diagnosisRecords[0].primaryDiagnosisCode
        : null,
    severity: severity ?? null,
    combinedSeverity,
    totalSessionsAttended: events?.filter(
      (event) =>
        event.appointmentStatus === "attended" &&
        event.serviceType !== "consult",
    ).length,
    totalSessionsAttendedWithCurrentClinician:
      getCurrentClinicianAttendedSessions(
        events as EventApiData[],
        clinicianId,
      ),
    suggestedRange: suggestedRange ?? null,
    primaryAssessment: primaryAssessment ?? null,
    mbcTrackStatus: mbcTrackStatus ?? null,
    mbcTrackStatusBeta: mbcTrackStatusBeta ?? null,
    phoneNumber: phoneNumber ?? null,
    nextSession: nextSession ?? null,
    upcomingSessions: upcomingSessions ?? [],
    sessionHistorySessions: sessionHistorySessions ?? [],
    welkinLink: welkinLink ?? null,
    healthieLink: healthieLink ?? null,
    healthieId: healthieId ?? null,
    manualSlotReservation: sortedActiveManualSlotReservations,
    endorsedSi: endorsedSi ?? null,
    careLocation: careLocation ?? null,
    isHighAcuity: matches?.[0]?.priority === "high_acuity"
  };
};

export const selectDataFromSearchForClientProfile = (
  data: ClientOverviewQuery,
  clinicianId: number,
): ClientOverviewApiData | null => {
  const parsedClientOverview = data.clients[0]
    ? parseClientOverviewData(data.clients[0], clinicianId)
    : null;

  return parsedClientOverview;
};

export const selectDataFromSearchForMyClients = (
  data: ClientOverviewQuery,
  clinicianId?: number,
): ClientOverviewApiData[] => {
  const parsedClientOverviews = data.clients.map((client) =>
    parseClientOverviewData(client, clinicianId),
  );

  return parsedClientOverviews;
};
