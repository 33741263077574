import { Ehr_Clinician } from "@/graphql/generated";
import * as React from "react";
import { useEffect } from "react";
import * as ReactRedux from "react-redux";
import styled from "styled-components";

import { CurrentUser } from "../../api/types";
import { Permissions } from "../../app/_helpers/permissions";
import { AppState, Dispatcher } from "../../state/models";
import { panelActions } from "../../state/models/panel-management";
import * as panelOperations from "../../state/models/panel-management/operations";
import { useGetClinicians, useGetCliniciansTimezone } from "../api/use-get-clinicians/use-get-clinicians";
import { UseTitleProxy } from "../hooks/useTitle";
import { Timezone, TimezoneProvider } from "../_shared/TimezoneContext";
import Navbar from "./Navbar";
import Panel from "./Panel";
import { DateRange } from "./types";
import { HintTextDiv } from "./_shared";

interface StateProps {
  dateRange: DateRange;
  clinicianId?: number;
  currentUser: CurrentUser | null;
}

interface DispatchProps {
  setDateRange: (dateRange: DateRange) => void;
  getClinicianWeekSessions: (id: number, dateRange: DateRange) => void;
  setClinicianId: (id: number) => void;
}

interface Props extends StateProps, DispatchProps {}

const PanelManagementContainer = (props: Props) => {
  const {
    clinicianId,
    getClinicianWeekSessions,
    dateRange,
    currentUser,
    setDateRange,
  } = props;

  const updateCWS = () => {
    if (clinicianId) {
      getClinicianWeekSessions(clinicianId, dateRange);
    } else if (currentUser) {
      if (currentUser.clinician) {
        getClinicianWeekSessions(currentUser.clinician.id, dateRange);
      }
    }
  };

  useEffect(() => {
    updateCWS();
  }, [dateRange, clinicianId]);

  let content;

  const { data: selectedClinicianTimezone, isLoading: getClinicianLoading } = useGetCliniciansTimezone(clinicianId);

  const timezone: Timezone = (selectedClinicianTimezone ||
    currentUser?.clinician?.primary_timezone ||
    Intl.DateTimeFormat().resolvedOptions().timeZone) as Timezone;

  if (clinicianId) {
    content = <Panel />;
  } else {
    if (Permissions.IsPanManAdmin(currentUser)) {
      content = (
        <HintTextDiv>
          Welcome, superuser! Please pick a Clinician to view their panel.{" "}
          {timezone}
        </HintTextDiv>
      );
    } else {
      content = (
        <HintTextDiv>
          Error: Your user account is not associated with a clinician ID. Please
          let techsupport@twochairs.com know!
        </HintTextDiv>
      );
    }
  }
  return (
    <Container debug={false}>
      <TimezoneProvider timezone={timezone}>
        <UseTitleProxy title="Panel Management" />
        <Navbar
          dateRange={dateRange}
          setDateRange={setDateRange}
          timezone={timezone}
        />
        <ModalViewContainer id="panel-mgmt-modal" />
        <ViewContainer>{content}</ViewContainer>
      </TimezoneProvider>
    </Container>
  );
};

const ViewContainer = styled.div`
  margin: 8px 8px;
  display: flex;
  flex: 1;
  position: relative;
`;

const ModalViewContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  mouse-event: none;
`;

const mapStateToProps: ReactRedux.MapStateToProps<StateProps, {}, AppState> = (
  state,
) => {
  return {
    clinicianId: state.panel.clinicianId,
    dateRange: state.panel.dateRange,
    currentUser: state.auth.currentUser,
    isLoading: state.panel.isLoading,
  };
};

const mapDispatchToProps: ReactRedux.MapDispatchToProps<DispatchProps, {}> = (
  dispatch: Dispatcher,
  ownProps,
) => ({
  setDateRange: (dateRange) => {
    dispatch(panelActions.setDateRange(dateRange));
  },
  getClinicianWeekSessions: (id: number, dateRange: DateRange) => {
    dispatch(panelOperations.getClinicianWeekSessions(id, dateRange));
  },
  setClinicianId: (id: number) => dispatch(panelActions.setClinicianId(id)),
});

/*
 * Styled components
 */

interface ContainerProps {
  debug?: boolean;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  ${(props: ContainerProps) =>
    props.debug && "border: 1px solid red; * { border: 1px solid red; }"};
`;

export default ReactRedux.connect(
  mapStateToProps,
  mapDispatchToProps,
)(PanelManagementContainer);
