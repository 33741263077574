import React, { useEffect, useState } from "react";
import { ComposedRadixUISelect } from "@/app/design-system/select-radixui/composed-select-radixui";
import { createRadixSelectOptionValue } from "@/app/design-system/select-radixui/utils";
type DropDownProps = {
  clinics: string[];
  showDropDown: boolean;
  toggleDropDown: Function;
  clinicSelection: Function;
  existing: string;
};

const DropDown: React.FC<DropDownProps> = ({
  clinics,
  clinicSelection,
  existing,
}: DropDownProps): JSX.Element => {
  var radixClinics = clinics.map(function (x) {
    return createRadixSelectOptionValue(x);
  });
  radixClinics.unshift(createRadixSelectOptionValue("Select New Clinic"));

  const storyOptions = radixClinics;
  let defval = storyOptions[0].value;
  if (existing !== "None Selected") {
    defval = existing;
  }

  let [value, setValue] = useState(defval);
  return (
    <ComposedRadixUISelect
      options={storyOptions}
      value={value}
      onChange={(value) => {
        setValue(value);
        clinicSelection(value);
      }}
      selectLabel="Clinic Select"
    ></ComposedRadixUISelect>
  );
};

export default DropDown;
