import { graphqlClient } from "@/api/graphql";
import { FIVE_MINUTES } from "@/app/_helpers/constants";
import {
  useGroupTherapyNavInfoQuery,
  GroupTherapyNavInfoQuery,
} from "@/graphql/generated";

interface UseGroupTherapyNavInfoParams<T> {
  groupId: string | undefined;
  select: (data: GroupTherapyNavInfoQuery) => T;
  enabled?: boolean;
}

export const useGroupTherapyNavInfo = <T extends {}>({
  groupId,
  select,
  enabled,
}: UseGroupTherapyNavInfoParams<T>) => {
  return useGroupTherapyNavInfoQuery(
    graphqlClient,
    { groupId },
    {
      staleTime: FIVE_MINUTES,
      select,
      enabled,
    },
  );
};
