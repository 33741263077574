import { graphqlClient } from "@/api/graphql";
import {
  GroupTherapyClientQuery,
  useGroupTherapyClientQuery,
} from "@/graphql/generated";
import { FIVE_MINUTES } from "@/app/_helpers/constants";

interface UseGroupTherapyClientParams<T extends object> {
  groupId: string | undefined;
  clientId: number | undefined;
  select?: (data: GroupTherapyClientQuery) => T;
  enabled: boolean;
}

export const useGroupTherapyClient = <T extends {}>({
  groupId,
  clientId,
  select,
  enabled,
}: UseGroupTherapyClientParams<T>) => {
  return useGroupTherapyClientQuery(
    graphqlClient,

    { groupId, clientId },
    {
      staleTime: FIVE_MINUTES,
      select,
      enabled,
    },
  );
};
