import {
  Checkbox,
  DatePicker,
  Input,
  Modal,
  Select as DeprecatedAntdSelect,
  Typography,
} from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { SelectValue } from "antd/es/select";
import moment, { Moment } from "moment";
import * as React from "react";
import {
  ClinicianHold,
  ClinicianWithSchedule,
  NewClinicianHold,
} from "../../api/types";
import {
  DateTimeRange,
  DateTimeRangePicker,
} from "../_shared/FormControls/DateTimeRangePicker";
import { $red } from "../../assets/colors";
// import { holdReasons } from "../_helpers";
import { autoSchedulePauseReasons } from "../_helpers";

const TOOLTIP_DATE_FORMAT = "dddd MMMM Do, YYYY";

export interface HoldModalProps2 {
  addHold: (hold: NewClinicianHold) => void;
  updateHold: (hold: ClinicianHold) => void;
  deleteHold: (hold: ClinicianHold) => void;
  onClose: () => void;
  clinician: ClinicianWithSchedule;
  existingHold?: ClinicianHold;
}

interface State {
  range?: DateTimeRange;
  reason: string;
  note: string;
  shouldDelete: boolean;
  error: string;
}

class HoldModal2 extends React.Component<HoldModalProps2, State> {
  public state: State = {
    range: this.props.existingHold && [
      moment(this.props.existingHold.start_at),
      moment(this.props.existingHold.end_at),
    ],
    reason: this.props.existingHold ? this.props.existingHold.reason : "",
    note: this.props.existingHold ? this.props.existingHold.note : "",
    shouldDelete: false,
    error: "",
  };

  public handleOk = () => {
    const { clinician, existingHold } = this.props;
    const { reason, note, range, shouldDelete } = this.state;
    if (shouldDelete) {
      this.props.deleteHold(existingHold!);
      this.props.onClose();
      return;
    }

    if (!reason) {
      this.setState({
        error: "Please fill out Reason.",
      });
      return;
    } else {
      this.setState({ error: "", reason: reason });
    }

    const startAt = moment();
    const endAt = moment().add(1, "year");
    if (existingHold) {
      this.props.updateHold({
        ...existingHold,
        reason: this.state.reason,
      });
    } else {
      this.props.addHold({
        clinician: clinician.id,
        reason,
        note,
        start_at: startAt.toISOString(),
        end_at: endAt.toISOString(),
      });
    }
    this.props.onClose();
  };

  public updateTime = (range: DateTimeRange) => this.setState({ range });
  public updateDelete = (e: CheckboxChangeEvent) =>
    this.setState({ shouldDelete: e.target.checked });

  public render() {
    const { clinician, existingHold } = this.props;
    const { shouldDelete } = this.state;

    const title = this.props.existingHold ? "Modify Autoschedule" : "Pause Client Scheduler";
    const [startDate, endDate] = this.state.range || [undefined, undefined];

    const tooltipText = this.state.range
      ? `This hold ${
          moment(startDate).isBefore(moment()) ? "started" : "will start"
        } on ${moment(startDate).format(TOOLTIP_DATE_FORMAT)}${
          moment(endDate).year() === 2100
            ? "."
            : ` and ends on ${moment(endDate).format(TOOLTIP_DATE_FORMAT)}.`
        }`
      : null;

    return (
      <Modal
        title={title}
        onOk={this.handleOk}
        onCancel={this.props.onClose}
        open
      >
        <div style={{ marginBottom: "15px" }}>
          <p>
            Please select the reason to pause scheduling new clients for this
            clinician.
          </p>
        </div>
        <div style={{ display: "flex", marginBottom: "15px" }}>
          {existingHold ? (
            <DateTimeRangePicker
              onChange={this.updateTime}
              defaultValue={this.state.range}
              disabled={shouldDelete}
            />
          ) : (
            <div></div>
            // <DatePicker
            //   onChange={(date: Moment | null) => {
            //     this.updateTime([date || moment(), moment("2100-01-01")]);
            //   }}
            //   defaultValue={this.state?.range ? this.state.range[0] : undefined}
            //   disabled={shouldDelete}
            //   placeholder={"Select start date"}
            // />
          )}
        </div>
        <div style={{ marginBottom: "15px" }}>
          <p>Pause reason</p>
        </div>
        <Typography.Text
          type="secondary"
          style={{ display: "block", margin: "-10px 0 16px 0" }}
        >
          {tooltipText}
        </Typography.Text>
        <div style={{ display: "flex", marginBottom: "15px" }}>
          <DeprecatedAntdSelect
            defaultValue={this.state.reason || undefined}
            style={{ width: "100%" }}
            onChange={(reason: SelectValue) => {
              // todo
              // @ts-ignore
              this.setState({ reason: reason });
            }}
            placeholder="Select a reason for pause..."
          >
            {autoSchedulePauseReasons.map((reason, idx) => (
              <DeprecatedAntdSelect.Option
                key={idx.toString()}
                value={reason.key}
              >
                {reason.value}
              </DeprecatedAntdSelect.Option>
            ))}
          </DeprecatedAntdSelect>
        </div>
        <div style={{ marginBottom: "15px" }}>
          <p>
            <br></br>
            If you need to make a change, please reach out to
            cliniciansupport@twochairs.com
          </p>
        </div>
        {/* <div style={{ display: "flex", marginBottom: "15px" }}>
          <Input.TextArea
            value={this.state.note}
            style={{ width: "100%" }}
            onChange={(e) => this.setState({ note: e.target.value })}
            placeholder="Please provide some additional context..."
          />
        </div> */}
        {existingHold && (
          <Checkbox
            style={{ marginRight: "7.5px", flex: 1 }}
            checked={this.state.shouldDelete}
            onChange={this.updateDelete}
          >
            Delete this hold.
          </Checkbox>
        )}
        {this.state.error && (
          <div style={{ paddingTop: "15px", color: $red }}>
            {this.state.error}
          </div>
        )}
      </Modal>
    );
  }
}

export default HoldModal2;
