import { graphqlClient } from "@/api/graphql";
import {
  Ehr_Timeoff,
  GetTimeoffQuery,
  useGetTimeoffQuery,
} from "@/graphql/generated";
import { UseQueryOptions } from "react-query";
import { ONE_HOUR } from "@/app/_helpers/constants";

export const TimeoffsQueryKey = "getTimeoff";

export const useGetTimeoffs = <TError = unknown>(
  options: UseQueryOptions<GetTimeoffQuery, TError, Ehr_Timeoff[]>,
) => {
  const { data, isLoading } = useGetTimeoffQuery<Ehr_Timeoff[], TError>(
    graphqlClient,
    undefined,
    {
      staleTime: ONE_HOUR,
      queryKey: [TimeoffsQueryKey],
      select: (data) => data.ehr_timeoff as Ehr_Timeoff[],
      ...options,
    },
  );

  return { data, isLoading };
};
