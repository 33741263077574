import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../state/models";
import { matchmakerOperations } from "../../../../state/models/matchmaker";
import { Title } from "../../../_layout/display";
import TagSelect from "../../../_shared/FormControls/TagSelect";
import {
  NEEDS_TAG_WEIGHT,
  BENEFIT_TAG_WEIGHT,
  TagWeight,
} from "../../../../api/constants";
import { Tag } from "../../../../api/types";
import { Divider } from "antd";
import AgeRangePicker from "../../../_shared/FormControls/AgeRangePicker";
import { difference, isEmpty } from "lodash-es";
import { HelperTextBox } from "@/app/consults-and-matching/MatchingV2/_components/helper-text-box";

interface TagMap {
  [id: number]: Tag;
}

export default function TagInputs() {
  const [consultTagIdsByWeight, tagMap, methodologyVersion] = useSelector(
    (state: AppState) => [
      state.matchmaker.consultTagMap,
      state.matches.tagMap,
      state.matchmaker.methodologyVersion,
    ],
  );
  const dispatch = useDispatch();
  const setConsultTags = (tagMap: TagMap, weight: TagWeight) => {
    const existingConsultTagIds = consultTagIdsByWeight[weight];
    const newTagIds: number[] = Object.values(tagMap).map((t) => t.id);

    const differences = [
      ...difference(existingConsultTagIds, newTagIds),
      ...difference(newTagIds, existingConsultTagIds),
    ];

    if (!isEmpty(differences)) {
      const cts = {
        ...consultTagIdsByWeight,
        [weight]: newTagIds,
      };
      dispatch(matchmakerOperations.setConsultTags(cts));
    }
  };

  return (
    <>
      <Title>Needs</Title>
      {consultTagIdsByWeight &&
      consultTagIdsByWeight[1].length + consultTagIdsByWeight[100].length >
        10 ? (
        <HelperTextBox
          style={{ marginBottom: "10px" }}
          primaryText="High number of needs"
          secondaryText="Aim for 2-3 top needs to prevent long wait times. Maximum 9 needs"
          color="#BD4B00"
          background="#FFF1E7"
        />
      ) : (
        <HelperTextBox
          style={{ marginBottom: "10px" }}
          primaryText="Decrease wait time"
          secondaryText={
            "Aim for 2-3 top needs." +
            (methodologyVersion === 3
              ? ""
              : " Add clinical styles as benefits below.")
          }
          color="#5746AF"
          background="#F5F2FF"
        />
      )}

      <TagSelect
        variant="needs"
        selectTagMap={(tagMap: TagMap) =>
          setConsultTags(tagMap, NEEDS_TAG_WEIGHT)
        }
        selectedTags={consultTagIdsByWeight[NEEDS_TAG_WEIGHT].map(
          (tId) => tagMap[tId],
        )}
        excludedTags={consultTagIdsByWeight[BENEFIT_TAG_WEIGHT].map(
          (tId) => tagMap[tId],
        )}
        isCouplesMatch={false}
        tagsVersion={methodologyVersion as 2 | 3}
      />
      <Divider style={{ margin: "15px 0" }} />
      <Title>Benefits</Title>
      <TagSelect
        variant="benefits"
        selectTagMap={(tagMap: TagMap) =>
          setConsultTags(tagMap, BENEFIT_TAG_WEIGHT)
        }
        selectedTags={consultTagIdsByWeight[BENEFIT_TAG_WEIGHT].map(
          (tId) => tagMap[tId],
        )}
        excludedTags={consultTagIdsByWeight[NEEDS_TAG_WEIGHT].map(
          (tId) => tagMap[tId],
        )}
        isCouplesMatch={false}
        tagsVersion={methodologyVersion as 2 | 3}
      />
      <AgeRangePicker />
    </>
  );
}
