import React from "react";
import { useController, useFormContext, useWatch } from "react-hook-form";
import { Label, Stack } from "../../../../../../../../../../../design-system";
import {
  DeprecatedComposedReachUISelect,
  Option,
} from "../../../../../../../../../../../design-system/select-reachui/composed-select-reachui";
import { CancelSessionFieldNames } from "../use-cancel-session-form";

interface CancellationFormItemProps {
  name: string;
  options: Option[];
  id?: string;
  callback: () => void;
}

export const CancellationReasonFormItem = ({
  name,
  options,
  id,
  callback,
}: CancellationFormItemProps) => {
  const { control } = useFormContext();

  const selectedCancellationReason = useWatch({
    name: CancelSessionFieldNames.CANCELLATION_REASON,
    control,
  });

  const selectedCancellationType = useWatch({
    name: CancelSessionFieldNames.CANCELLATION_TYPE,
    control,
  });

  const {
    field: { value, onChange, onBlur, ref },
    fieldState: { invalid, isDirty },
    formState: { errors },
  } = useController({
    name,
    control,
  });

  return (
    <Stack gap={10}>
      <Label required>Cancellation reason</Label>
      <DeprecatedComposedReachUISelect
        id={id}
        options={options}
        value={selectedCancellationReason}
        onChange={(value) => {
          onChange(value);
          callback();
        }}
        disabled={selectedCancellationType === "Select"}
        selectRootName={"Cancellation reason"}
      />
    </Stack>
  );
};
