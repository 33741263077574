import { useUserHasAnyPermissions } from "@/app/_helpers/permissions";
import { CurrentUserContext } from "@/app/app.utils";
import { Button, Flex, QuarterCircle, Spinner } from "@/app/design-system";

import { Tag } from "@/app/design-system/tag/tag";
import { matchmakerOperations } from "@/state/models/matchmaker";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  TabsList,
  TabsRoot,
} from "../../_components/navigation-tabs/navigation-tabs.styled";
import { Permissions } from "../../_helpers/permissions";
import { MatchingNavTrigger, TabsLabel } from "./_components";
import { useGetMyConsultsPageQuery } from "./use-get-my-consults";

import { GlobalClientSearchBar } from "@/app/my-clients/routes/my-clients-page/components/search-and-filter/client-search-bar";
import { TabsContent } from "@radix-ui/react-tabs";
import {
  formatPastData,
  formatUpcomingAndIncompleteData,
  fuzzyMatch,
  isMatchDelayed,
} from "./_utils";
import { useGetMatchesByClientId } from "./use-get-matches-by-client-id";

import { ConsultsTableContainer } from "./_components/consults-table";
import {
  INCOMPLETE_MY_CONSULTS_VALUE,
  INIT_FILTERS,
  PAST_MY_CONSULTS_VALUE,
  SEARCH_ALL,
  UPCOMING_MY_CONSULTS_VALUE,
} from "./consts";
import { useGetMyPastConsultsSearchResults } from "./use-get-my-past-consults-search";
import { ConsultClinicianClientsContext } from "./consultClinicianClients.context";
interface FilterOption {
  label: string;
  value: boolean;
}

export const MyConsultsV2NavigationTabs = ({
  clinicianId,
}: {
  clinicianId?: number;
}) => {
  const isMatchAdmin: boolean | null = useUserHasAnyPermissions([
    "IsSuperUser",
    "IsMatchingAdmin",
    "IsClinicalLeader",
    "IsCareCoordinator",
  ]);

  const [activeTab, setActiveTab] = useState(
    isMatchAdmin ? SEARCH_ALL : UPCOMING_MY_CONSULTS_VALUE,
  );
  const dispatch = useDispatch();
  const openMatchmaker = () =>
    dispatch(
      matchmakerOperations.openMatchmaker({
        match: null,
        rematch: null,
        consult: null,
      }),
    );
  const cuser = React.useContext(CurrentUserContext);

  const {
    data: myConsultsPageData,
    isLoading,
    refetch: refetchMyConsults,
    isError,
  } = useGetMyConsultsPageQuery(clinicianId ? [clinicianId] : null);

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedPastClientId, setSelectedPastClientId] = useState<
    string | undefined
  >("");
  const [filterDropdowns, setFilterDropdowns] = useState(INIT_FILTERS);
  const [pastClients, setPastClients] = useState<Array<any>>([]);

  const [globalClientResultId, setGlobalClientResultId] = React.useState();
  const [upcomingConsults, setUpcomingConsults] = useState<Array<any>>([]);
  const [incompleteConsults, setIncompleteConsults] = useState<Array<any>>([]);

  const {
    data: myPastConsultsSearchPageData,
    isLoading: isPastConsultSearchDataLoading,
    isError: pastConsultSearchError,
  } = useGetMyPastConsultsSearchResults(
    selectedPastClientId as unknown as number,
    clinicianId,
  );
  const handleGlobalSearchChange = (value: any) => {
    setGlobalClientResultId(value);
  };

  const {
    data: globalSearchData,
    isLoading: isGlobalSearchLoading,
    refetch: refetchMatchesByClientId,
  } = useGetMatchesByClientId(globalClientResultId ? globalClientResultId : 0);

  const [consultClinicianClients, setConsultClinicianClients] = useState<any>(
    myPastConsultsSearchPageData?.my_matches,
  );

  React.useEffect(() => {
    const upcomingConsults = myConsultsPageData?.recent_consults.filter(
      (consult) => {
        const consultTime = DateTime.fromISO(consult.start_time).plus({
          hours: 1,
        });
        return consultTime > DateTime.local();
      },
    );
    setUpcomingConsults(upcomingConsults ?? []);
    const incompleteConsults = myConsultsPageData?.recent_consults.filter(
      (consult) => {
        const consultTime = DateTime.fromISO(consult.start_time).plus({
          hours: 1,
        });
        return consultTime <= DateTime.local();
      },
    );

    setIncompleteConsults(incompleteConsults ?? []);
    setPastClients(myConsultsPageData?.my_matches ?? []);
    setConsultClinicianClients(
      myConsultsPageData?.my_matches
        ? formatPastData(myConsultsPageData?.my_matches)
        : [],
    );
  }, [myConsultsPageData, myPastConsultsSearchPageData]);

  React.useEffect(() => {
    const my_matches_data = selectedPastClientId
      ? !isPastConsultSearchDataLoading
        ? myPastConsultsSearchPageData?.my_matches
        : []
      : myConsultsPageData?.my_matches;
    // @ts-ignore
    const filtered = my_matches_data?.filter((match) => {
      const date = new Date(match.consult_event?.start_time);
      const monthOfDate = date.toLocaleString("default", { month: "long" });
      const yearOfDate = date.getFullYear();

      const anyClientStatusTrue = filterDropdowns.client_status?.some(
        (status) => status.value,
      );

      const anyMonthTrue = filterDropdowns.month.some((month) => month.value);

      const matches = fuzzyMatch(
        searchTerm,
        `${match?.ehr_client?.first_name} ${match.ehr_client?.last_name}`,
        match.ehr_client?.id.toString(),
      );
      const statusMatches =
        !anyClientStatusTrue ||
        filterDropdowns.client_status.some((status: FilterOption) => {
          // Delayed status is not explicitly specified by backend in `client_status` field of a match.
          // Whether a match is "delayed" is based off of the presence of the `match_after` field.
          // Determining whether a match is "delayed" takes precedence over other match `client_status`es.
          // Refer to /monorepo/fulfiller/src/app/consults-and-matching/MatchStatus.tsx:parseClientStatus()
          if (
            (status.label === "Delayed" && isMatchDelayed(match.match_after)) ||
            (status.label === match.client_status &&
              !isMatchDelayed(match.match_after))
          ) {
            return status.value;
          } else {
            return false;
          }
        });

      const monthMatches =
        !anyMonthTrue ||
        filterDropdowns.month.some((month: FilterOption) => {
          if (month.label === monthOfDate) return month.value;
          return false;
        });

      const anyYearTrue = filterDropdowns.year.some((year) => year.value);
      const yearMatches =
        !anyYearTrue ||
        filterDropdowns.year.some((year: FilterOption) => {
          if (year.label === String(yearOfDate)) return year.value;
          return false;
        });

      return matches && statusMatches && monthMatches && yearMatches;
    });

    setPastClients(filtered ?? []);
  }, [selectedPastClientId, myPastConsultsSearchPageData, filterDropdowns]);

  if (isLoading || (isMatchAdmin && isGlobalSearchLoading)) {
    return (
      <Spinner style={{ marginTop: "16px" }}>
        <QuarterCircle dimension={"2em"}></QuarterCircle>
      </Spinner>
    );
  }

  const searchAllData = globalSearchData
    ? formatPastData(globalSearchData.matches)
    : [];

  const isSuperUser =
    (!clinicianId || isNaN(clinicianId)) &&
    (Permissions.IsSuperUser(cuser) || Permissions.IsClinicalLeader(cuser));

  const upcomingChartData = formatUpcomingAndIncompleteData(upcomingConsults);
  const pastChartData = formatPastData(pastClients);
  const incompleteChartData =
    formatUpcomingAndIncompleteData(incompleteConsults);

  return (
    <ConsultClinicianClientsContext.Provider value={consultClinicianClients}>
      <TabsRoot
        orientation="horizontal"
        defaultValue={activeTab}
        css={{
          overflow: "auto",
          backgroundColor: "$neutral0",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0 20px",
          }}
        >
          <TabsList>
            {isMatchAdmin ? (
              <MatchingNavTrigger
                css={{ width: 260 }}
                value={SEARCH_ALL}
                onClick={() => {
                  setActiveTab(SEARCH_ALL);
                }}
              >
                <TabsLabel>
                  <Flex
                    alignItems="center"
                    flexDirection={"row"}
                    justifyContent="center"
                    gap="8"
                  >
                    Search All{" "}
                    <Tag
                      color="violet"
                      size="small"
                      style={{ marginLeft: "8px" }}
                      as="span"
                    >
                      Global
                    </Tag>
                  </Flex>
                </TabsLabel>
              </MatchingNavTrigger>
            ) : null}

            {clinicianId && (
              <>
                <MatchingNavTrigger
                  css={{ width: 160 }}
                  value={UPCOMING_MY_CONSULTS_VALUE}
                  onClick={() => setActiveTab(UPCOMING_MY_CONSULTS_VALUE)}
                >
                  <TabsLabel>Upcoming</TabsLabel>
                </MatchingNavTrigger>

                <MatchingNavTrigger
                  value={PAST_MY_CONSULTS_VALUE}
                  onClick={() => setActiveTab(PAST_MY_CONSULTS_VALUE)}
                >
                  <TabsLabel>Past</TabsLabel>
                </MatchingNavTrigger>

                <MatchingNavTrigger
                  value={INCOMPLETE_MY_CONSULTS_VALUE}
                  onClick={() => setActiveTab(INCOMPLETE_MY_CONSULTS_VALUE)}
                >
                  <TabsLabel>
                    {" "}
                    Incomplete
                    {`${
                      incompleteConsults?.length > 0
                        ? ` (${incompleteConsults?.length})`
                        : ""
                    }`}
                  </TabsLabel>
                </MatchingNavTrigger>
              </>
            )}
          </TabsList>
          <Button
            variant={"secondary"}
            backgroundColor="transparent"
            size={"small"}
            onClick={openMatchmaker}
            style={{ fontWeight: 500 }}
            css={{
              height: "auto",
            }}
          >
            Match by email
          </Button>{" "}
        </div>
        <TabsContent value={SEARCH_ALL}>
          <Flex
            css={{
              flexGrow: 1,
              background: "$neutral2",
              justifyContent: "center",
              padding: "20px",
            }}
            flexDirection={"column"}
          >
            <GlobalClientSearchBar
              myClients={[]}
              onOptionSelect={handleGlobalSearchChange}
            />
            <ConsultsTableContainer
              data={searchAllData}
              activeTab={SEARCH_ALL}
              isLoading={isGlobalSearchLoading}
              refetchMyConsults={refetchMyConsults}
              refetchMatchesByClinicianId={refetchMatchesByClientId}
              onSearch={setSearchTerm}
              filterDropdowns={filterDropdowns}
              onClearFilters={(filter: keyof typeof INIT_FILTERS) => {
                const updatedFilters = INIT_FILTERS[filter].map((item) => ({
                  ...item,
                  value: false,
                }));
                setFilterDropdowns((prevDropdowns) => ({
                  ...prevDropdowns,
                  [filter]: updatedFilters,
                }));
              }}
              onFilterClick={(
                index: number,
                value: boolean,
                filterType: string,
              ) => {
                setFilterDropdowns((prevDropdowns) => {
                  const updatedDropdowns = { ...prevDropdowns };
                  updatedDropdowns[filterType][index] = {
                    ...updatedDropdowns[filterType][index],
                    value: value,
                  };
                  return updatedDropdowns;
                });
              }}
            />
          </Flex>
        </TabsContent>
        <TabsContent value={UPCOMING_MY_CONSULTS_VALUE}>
          <Flex
            css={{
              flexGrow: 1,
              background: "$neutral2",
              justifyContent: "center",
            }}
          >
            <ConsultsTableContainer
              data={upcomingChartData}
              activeTab={UPCOMING_MY_CONSULTS_VALUE}
              isLoading={isLoading}
              onSearch={setSearchTerm}
              filterDropdowns={filterDropdowns}
              refetchMyConsults={refetchMyConsults}
              refetchMatchesByClinicianId={refetchMatchesByClientId}
              onClearFilters={(filter: keyof typeof INIT_FILTERS) => {
                const updatedFilters = INIT_FILTERS[filter].map((item) => ({
                  ...item,
                  value: false,
                }));
                setFilterDropdowns((prevDropdowns) => ({
                  ...prevDropdowns,
                  [filter]: updatedFilters,
                }));
              }}
              onFilterClick={(
                index: number,
                value: boolean,
                filterType: string,
              ) => {
                setFilterDropdowns((prevDropdowns) => {
                  const updatedDropdowns = { ...prevDropdowns };
                  updatedDropdowns[filterType][index] = {
                    ...updatedDropdowns[filterType][index],
                    value: value,
                  };
                  return updatedDropdowns;
                });
              }}
            />
          </Flex>
        </TabsContent>{" "}
        <TabsContent value={PAST_MY_CONSULTS_VALUE}>
          <Flex
            css={{
              flexGrow: 1,
              background: "$neutral2",
              justifyContent: "center",
            }}
          >
            <ConsultsTableContainer
              data={pastChartData}
              activeTab={PAST_MY_CONSULTS_VALUE}
              isError={
                !!(selectedPastClientId && pastConsultSearchError) || isError
              }
              isLoading={
                isLoading ||
                !!(selectedPastClientId && isPastConsultSearchDataLoading)
              }
              onSearch={setSearchTerm}
              onSelectClientId={setSelectedPastClientId}
              selectedClientId={selectedPastClientId}
              searchFilter={searchTerm}
              refetchMyConsults={refetchMyConsults}
              refetchMatchesByClinicianId={refetchMatchesByClientId}
              filterDropdowns={filterDropdowns}
              onClearFilters={(filter: keyof typeof INIT_FILTERS) => {
                const updatedFilters = INIT_FILTERS[filter].map((item) => ({
                  ...item,
                  value: false,
                }));
                setFilterDropdowns((prevDropdowns) => ({
                  ...prevDropdowns,
                  [filter]: updatedFilters,
                }));
              }}
              onFilterClick={(
                index: number,
                value: boolean,
                filterType: string,
              ) => {
                setFilterDropdowns((prevDropdowns) => {
                  const updatedDropdowns = { ...prevDropdowns };
                  updatedDropdowns[filterType][index] = {
                    ...updatedDropdowns[filterType][index],
                    value: value,
                  };
                  return updatedDropdowns;
                });
              }}
            />
          </Flex>
        </TabsContent>
        <TabsContent value={INCOMPLETE_MY_CONSULTS_VALUE}>
          <Flex
            css={{
              flexGrow: 1,
              background: "$neutral2",
              justifyContent: "center",
            }}
          >
            <ConsultsTableContainer
              data={incompleteChartData}
              activeTab={INCOMPLETE_MY_CONSULTS_VALUE}
              isLoading={isLoading}
              onSearch={setSearchTerm}
              refetchMyConsults={refetchMyConsults}
              refetchMatchesByClinicianId={refetchMatchesByClientId}
              filterDropdowns={filterDropdowns}
              onClearFilters={(filter: keyof typeof INIT_FILTERS) => {
                const updatedFilters = INIT_FILTERS[filter].map((item) => ({
                  ...item,
                  value: false,
                }));
                setFilterDropdowns((prevDropdowns) => ({
                  ...prevDropdowns,
                  [filter]: updatedFilters,
                }));
              }}
              onFilterClick={(
                index: number,
                value: boolean,
                filterType: string,
              ) => {
                setFilterDropdowns((prevDropdowns) => {
                  const updatedDropdowns = { ...prevDropdowns };
                  updatedDropdowns[filterType][index] = {
                    ...updatedDropdowns[filterType][index],
                    value: value,
                  };
                  return updatedDropdowns;
                });
              }}
            />
          </Flex>
        </TabsContent>
      </TabsRoot>
    </ConsultClinicianClientsContext.Provider>
  );
};
