/* eslint-disable no-mixed-operators */
/* eslint-disable react/jsx-pascal-case */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { DateTime } from "luxon";
import { $antdBorder } from "../../assets/colors";
import { dashboardActions } from "../../state/models/dashboard";
import * as dashboardOperations from "../../state/models/dashboard/operations";
import {
  Permissions,
  userCanAccessHealthie,
  UserHasAnyPermissions,
  userCanAccessIncompleteNotes,
} from "../_helpers/permissions";
import { useShallowEqualSelector } from "../_helpers/redux";

import { isEmpty } from "lodash-es";

import useTitle from "../hooks/useTitle";

import { convertMomentToLuxon, TIME_TAG_FORMAT } from "@/app/_helpers/datetime";
import { UtilizationTile } from "@/app/dashboard2.0/utilization/utilization-tile";
import { useSearchParams } from "react-router-dom";
import { MainContentHeader } from "../_shared/MainContentHeader";
import { ScreenReaderOnlyText } from "../_shared/ScreenReaderOnlyText";
import {
  Button,
  CSS_COLORS,
  Flex,
  Spacer,
  Stack,
  styledStitches,
  Text,
  Title2,
} from "../design-system";
import { useMyClientsSearchStore } from "../my-clients/routes/my-clients-page/my-clients-model/my-clients.model";
import { ClientsToday } from "./clients-today/clients-today";
import { DateRange } from "./types";

import { CongratulatoryBanners } from "@/app/dashboard2.0/congratulatory-banners";
import { size } from "@/app/my-clients/routes/client-profile-page/client-profile-nav/routes/mbc-page/response-summary-cards/_helpers";
import type { AppState } from "@/state/models";
import * as Collapsible from "@radix-ui/react-collapsible";
import { LoadingPlaceholder } from "../_shared/loading-placeholder";
import { CurrentConfigContext } from "../app.utils";
import { IncompleteNotes } from "../dashboard2.0/_components/incomplete-notes";
import { TimeZoneAndClinicianPicker } from "../dashboard2.0/_components/timezone-and-clinician-picker";
import { CollapseIcon } from "../design-system/icons/components/collapse-icon";
import { ComposedRadixUISelect } from "../design-system/select-radixui/composed-select-radixui";
import { createRadixSelectOptions } from "../design-system/select-radixui/utils";
import { logFreshpaintEvent } from "../utils/freshpaint.util";
import { useGetIncompleteDocumentationReminders } from "./_api/use-get-documentation-reminders";
import { ClientAdherenceTile } from "./_components/client-adherence-tile";
import { MetricSidePanelSection } from "./_components/metric-side-panel-section";
import { METRICS_PANEL_DATE_RANGES } from "./utils";

const CollapseContainer = ({
  metricPanelOpen,
  setMetricPanelOpen,
  screenReaderOnlyText,
  userId,
}: {
  metricPanelOpen: boolean;
  setMetricPanelOpen: (open: boolean) => void;
  screenReaderOnlyText: string;
  userId: number;
}) => {
  return (
    <NotificationsAndMetricsSidePanelCollapseSwitch asChild>
      <CollapseButton
        variant="tertiary"
        sidePanelSize={metricPanelOpen ? "large" : "small"}
        onClick={() => {
          upsertMetricPanelOpen(userId, setMetricPanelOpen, !metricPanelOpen);
          logFreshpaintEvent(
            `Dashboard v2: Clicked ${
              metricPanelOpen ? `close` : `open`
            } dashboard right side nav`,
          );
        }}
      >
        <CollapseButtonFlex
          gap="4"
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          {metricPanelOpen ? (
            <HideSpan>
              Hide
              <ScreenReaderOnlyText>
                {" "}
                {screenReaderOnlyText}
              </ScreenReaderOnlyText>
            </HideSpan>
          ) : null}{" "}
          <CollapseIcon
            data-open={metricPanelOpen}
            title={metricPanelOpen ? "" : `Open ${screenReaderOnlyText}`}
          />
        </CollapseButtonFlex>
      </CollapseButton>
    </NotificationsAndMetricsSidePanelCollapseSwitch>
  );
};

const HideSpan = styledStitches("span", {
  fontSize: 14,
  color: "$neutral12",
});

const updateMetricPanelOpenIfNotPresent = (
  userId: number,
  setMetricPanelOpen: (open: boolean) => void,
  metricPanelOpen: boolean,
) => {
  const metricPanelOpenPreferenceMap = JSON.parse(
    localStorage.getItem("metricPanelOpen") || "null",
  );
  if (
    !metricPanelOpenPreferenceMap ||
    !metricPanelOpenPreferenceMap?.[userId]
  ) {
    upsertMetricPanelOpen(userId, setMetricPanelOpen, metricPanelOpen);
  }
};

const upsertMetricPanelOpen = (
  userId: number,
  setMetricPanelOpen: (open: boolean) => void,
  metricPanelOpen: boolean,
) => {
  const metricPanelOpenPreferenceMap = JSON.parse(
    localStorage.getItem("metricPanelOpen") || "{}",
  );
  setMetricPanelOpen(metricPanelOpen);
  localStorage.setItem(
    "metricPanelOpen",
    JSON.stringify({
      ...metricPanelOpenPreferenceMap,
      [userId]: metricPanelOpen,
    }),
  );
};

export default function DashboardContainer() {
  useTitle("My Dashboard");
  const dispatch = useDispatch();
  const cuser = useSelector((state: AppState) => state.auth.currentUser);
  // Compute the number of weeks between the rampStartDate and the current date.
  // If the number of weeks is less than 5, and metricPanelOpen is not populated in localStorage, then
  // we initialize metricPanelOpen to false. This is done to ensure that the metric panel is closed for
  // clinicians who have been with the company for less than 5 weeks.
  useEffect(() => {
    if (cuser?.clinician?.ramp_start_date) {
      const rampStartDate = DateTime.fromISO(cuser.clinician.ramp_start_date);
      const currentDate = DateTime.now();
      const weeksSinceRampStartDate = Math.floor(
        currentDate.diff(rampStartDate, "weeks").weeks,
      );
      if (weeksSinceRampStartDate < 5) {
        updateMetricPanelOpenIfNotPresent(cuser.id, setMetricPanelOpen, false);
      } else {
        updateMetricPanelOpenIfNotPresent(cuser.id, setMetricPanelOpen, true);
      }
    } else if (
      cuser?.clinician?.ramp_start_date === null ||
      cuser?.isSuperUser
    ) {
      // Older clinicians that existed prior to the introduction of ramp_start_date should initially have
      // the metric panel open.
      updateMetricPanelOpenIfNotPresent(cuser.id, setMetricPanelOpen, true);
    }
  }, [cuser]);
  const [metricPanelOpen, setMetricPanelOpen] = React.useState<boolean>(() => {
    const storedValue = JSON.parse(
      localStorage.getItem("metricPanelOpen") || "{}",
    )?.[cuser?.id || -1];
    return typeof storedValue === "boolean" ? storedValue : false;
  });
  // Save the metricPanelOpen state to local storage which allows us to persist the state
  // of the metric panel when the user refreshes the page.
  useEffect(() => {
    // This if condition is necessary because we only want to start saving preferences to local storage
    // after the clinician/user has already specified a preference.
    // Also need to hide the side panel if the clinician is new and has been with the company
    // for less than 5 weeks.
    const metricPanelOpenPreferenceMap = JSON.parse(
      localStorage.getItem("metricPanelOpen") || "null",
    );
    if (
      metricPanelOpenPreferenceMap &&
      cuser?.id &&
      cuser.id in metricPanelOpenPreferenceMap
    ) {
      localStorage.setItem(
        "metricPanelOpen",
        JSON.stringify({
          ...metricPanelOpenPreferenceMap,
          [cuser.id]: metricPanelOpen,
        }),
      );
    }
  }, [metricPanelOpen]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < size.laptopL) {
        setMetricPanelOpen(false);
      }
    };
    // The following addEventListener allows us to listen for when the user resizes the window.
    window.addEventListener("resize", handleResize);
    // The following removeEventListener is necessary to prevent a memory leak.
    // It does so by removing the event listener when the component unmounts.
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const changeMDBClinician = (id: number | string) =>
    dispatch(dashboardOperations.loadMDBWithClinicianId(id));

  const getClinicianManagers = () => {
    dispatch(dashboardOperations.getClinicianManagers());
  };

  const setDateRange = (dateRange: DateRange) => {
    const correctedRange: DateRange = {
      start: dateRange.start.startOf("week"),
      end: dateRange.end.endOf("week"),
    };
    dispatch(dashboardActions.setDateRange(correctedRange));
  };

  const { setCurrentlySelectedClinician } = useMyClientsSearchStore();

  const handleSelectClinician = (clinicianid: number) => {
    changeMDBClinician(clinicianid);
    setCurrentlySelectedClinician(clinicianid);
  };

  const {
    clinicianId,
    dateRange,
    clinicianMap,
    currentUser,
    clinicianManagers,
    myTeamClinicianIds,
    isLoadingBillableHours,
    isLoadingMBC,
  } = useShallowEqualSelector((state) => ({
    clinicianId: state.dashboard.clinicianId,
    dateRange: state.dashboard.dateRange,
    currentUser: state.auth.currentUser,
    clinicianMap: state.clinicians.clinicianMap,
    clinicianManagers: state.dashboard.clinicianManagers,
    myTeamClinicianIds: state.auth.myTeamClinicianIds,
    isLoadingBillableHours: state.dashboard.isLoadingBillableHours,
    isLoadingMBC: state.dashboard.isLoadingMBC,
  }));

  if (!dateRange) {
    setDateRange({
      start: moment().subtract("12", "weeks").startOf("week"),
      end: moment().endOf("week"),
    });
  }

  const [searchParams] = useSearchParams();
  const loggedInUserClinicianId = currentUser?.clinician?.id;
  let queryParamCID = searchParams.get("clinicianId");
  let suppliedCID: number | undefined;

  const CLINICIAN_SIDE_PANEL = "Clinician Metrics Side Panel";

  useEffect(() => {
    suppliedCID = parseInt(queryParamCID || "");
    if (
      !isNaN(suppliedCID) &&
      UserHasAnyPermissions(currentUser, ["IsClinicalLeader", "IsSuperUser"])
    ) {
      changeMDBClinician(suppliedCID);
    }
  }, [queryParamCID]);

  if (
    UserHasAnyPermissions(currentUser, ["IsSuperUser", "IsMDBTeamViewUser"]) &&
    isEmpty(clinicianManagers)
  ) {
    getClinicianManagers();
  }

  if (!clinicianId && currentUser?.clinician) {
    changeMDBClinician(currentUser.clinician.id);
  }

  const [isReady, setReady] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (!isReady) {
      if (!isLoadingBillableHours && !isLoadingMBC) {
        setReady(true);
      }
    } else if (isLoadingBillableHours || isLoadingMBC) {
      setReady(false);
    }
  }, [setReady, isLoadingMBC, isLoadingBillableHours, isReady]);

  const metricsPanelDateOptions = createRadixSelectOptions(
    METRICS_PANEL_DATE_RANGES,
  );
  let [pickerDateRange, setPickerDateRange] = useState(
    metricsPanelDateOptions[0].value,
  );

  // set date range, and re-load mdbclinician
  const handleDateRangeUpdate = (dateRange: DateRange) => {
    setDateRange(dateRange);
    setLuxonTimePickerDateRange({
      start: convertMomentToLuxon(dateRange.start),
      end: convertMomentToLuxon(dateRange.end),
    });
    if (clinicianId) {
      changeMDBClinician(clinicianId);
    }
  };
  const [luxonTimePickerDateRange, setLuxonTimePickerDateRange] = useState({
    start: convertMomentToLuxon(dateRange.start),
    end: convertMomentToLuxon(dateRange.end),
  });

  const config = React.useContext(CurrentConfigContext);
  const isHealthieBetaClinician = cuser
    ? userCanAccessIncompleteNotes(cuser, config)
    : false;

  const hasHealthieAccess = cuser
    ? userCanAccessHealthie(cuser, config)
    : false;

  // if we don't use startOf, then the query will keep firing off every time a millisecond passes
  const threeMonthsAgo = DateTime.now().minus({ months: 3 }).startOf("day");
  const specificDate = DateTime.fromJSDate(new Date("2023-08-28T00:00:00Z"));

  const lowerDate = isHealthieBetaClinician
    ? threeMonthsAgo
    : threeMonthsAgo > specificDate
    ? threeMonthsAgo
    : specificDate;
  const { data, isLoading: isIncompleNotesLoading } =
    useGetIncompleteDocumentationReminders({
      clinician_id: Number(clinicianId) ?? 0,
      pastEndTime: lowerDate,
    });
  const incompleteNotes: Array<any> = data?.ehr_event ?? [];

  return (
    <>
      <ScreenReaderOnlyText as="h1"> My Dashboard</ScreenReaderOnlyText>
      <Dashboard2Page>
        {/* Global Navbar Placeholder */}
        <Dashboard2MainContentHeader>
          <Text as="section">
            <div>
              {cuser?.first_name ? (
                <>
                  Hi{" "}
                  {cuser?.clinician
                    ? cuser?.clinician?.first_name
                    : cuser?.first_name}
                  , today is{" "}
                </>
              ) : (
                // eslint-disable-next-line no-trailing-spaces
                <>Today is </>
              )}
              <HeaderTime dateTime={DateTime.now().toFormat(TIME_TAG_FORMAT)}>
                {DateTime.now().toLocaleString({
                  weekday: "long",
                  month: "short",
                  day: "numeric",
                })}
              </HeaderTime>
            </div>
          </Text>
          <TimeZoneAndClinicianPicker
            clinicianMap={clinicianMap}
            handleSelectClinician={handleSelectClinician}
            clinicianId={clinicianId}
            myTeamClinicianIds={myTeamClinicianIds}
            permissions={[
              "IsSuperUser",
              "IsClinicalLeader",
              "IsCareCoordinator",
            ]}
            clinicianManagers={clinicianManagers}
            currentUser={currentUser}
            loggedInUserClinicianId={loggedInUserClinicianId}
          />
        </Dashboard2MainContentHeader>
        <Flex justifyContent={"space-between"} height={"all"}>
          <Dashboard2MainContentContainer>
            <ClientsToday
              clinicianId={
                clinicianId ? Number(clinicianId) : loggedInUserClinicianId
              }
            />
            {hasHealthieAccess && isIncompleNotesLoading ? (
              <LoadingPlaceholder contentDescription="Incomplete Notes section loading">
                <Spacer x="24" />
              </LoadingPlaceholder>
            ) : null}
            {hasHealthieAccess && !isIncompleNotesLoading ? (
              <IncompleteNotes incompleteNotes={incompleteNotes} />
            ) : null}
          </Dashboard2MainContentContainer>
          {clinicianId ? (
            <NotificationsAndMetricsSidePanel
              open={metricPanelOpen}
              onOpenChange={setMetricPanelOpen}
            >
              <SideBarContainer
                data-state={metricPanelOpen ? "open" : "closed"}
              >
                <CollapseContainer
                  userId={cuser?.id || -1}
                  metricPanelOpen={metricPanelOpen}
                  setMetricPanelOpen={setMetricPanelOpen}
                  screenReaderOnlyText={CLINICIAN_SIDE_PANEL}
                />
                <Collapsible.Content>
                  <section>
                    <ScreenReaderOnlyText>
                      <Title2>Celebrate</Title2>
                    </ScreenReaderOnlyText>
                    <section>
                      <CongratulatoryBanners />
                    </section>
                  </section>
                  <section>
                    <ScreenReaderOnlyText as="h2">Metrics</ScreenReaderOnlyText>

                    <Flex gap={"20"} flexDirection={"column"}>
                      <section>
                        <ScreenReaderOnlyText>Trends from</ScreenReaderOnlyText>
                        <ComposedRadixUISelect
                          options={metricsPanelDateOptions}
                          value={pickerDateRange}
                          onChange={(value) => {
                            setPickerDateRange(value);
                            handleDateRangeUpdate(
                              METRICS_PANEL_DATE_RANGES[value],
                            );
                          }}
                          selectLabel="Date Range for Metrics"
                          isSubtle
                          size="small"
                        />
                      </section>

                      <ClientAdherenceTile
                        isInFuture={
                          pickerDateRange === metricsPanelDateOptions[2].value
                        }
                        timePickerDateRange={luxonTimePickerDateRange}
                        clinicianId={clinicianId ? Number(clinicianId) : -1}
                      />
                      {!Permissions.IsNetworkClinician(currentUser) && (
                        <UtilizationTile
                          timePickerDateRange={luxonTimePickerDateRange}
                          clinicianId={clinicianId ? Number(clinicianId) : -1}
                        />
                      )}
                      <Stack gap="12">
                        <MetricSidePanelSection
                          clinicianId={clinicianId}
                          sectionDescription="Client Attendance"
                          page="client-attendance"
                        />
                        <MetricSidePanelSection
                          clinicianId={clinicianId}
                          sectionDescription="Therapeutic Alliance"
                          page="alliance-scores"
                        />
                      </Stack>
                    </Flex>
                  </section>
                </Collapsible.Content>
              </SideBarContainer>
            </NotificationsAndMetricsSidePanel>
          ) : null}
        </Flex>
      </Dashboard2Page>
    </>
  );
}

export const Container = styledStitches("section", {
  display: "flex",
  flexDirection: "column",
  padding: "16px 20px",
  width: "100%",
  animation: "all 250ms ease",
});

const Dashboard2Page = styledStitches("div", {
  display: "flex",
  flexDirection: "column",
  height: "100vh",
});
export const Dashboard2MainContentHeader = styledStitches(MainContentHeader, {
  flexShrink: 0,
});

const Dashboard2MainContentContainer = styledStitches(Container, {
  overflowY: "auto",
  width: "100%",
});

export const SideBarContainer = styledStitches(Container, {
  height: "100%",
  padding: "16px 12px",
  '&[data-state="closed"]': {
    display: "grid",
  },
});

const NavbarContainer = styled.div`
  box-sizing: border-box;
  position: sticky;
  top: 0px;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0 12px;
  width: 100%;
  height: 56px;
  min-height: 56px;
  z-index: 100;
  background-color: white;
  border-bottom: 1px solid ${$antdBorder};
`;

export const HeaderTime = styled.time`
  display: inline;
`;

const NotificationsAndMetricsSidePanel = styledStitches(Collapsible.Root, {
  display: "flex",
  justifyContent: "space-between",
  width: "fit-content",
  background: CSS_COLORS.neutral0,
  borderInlineStart: `1px solid ${CSS_COLORS.neutral5}`,
});

const NotificationsAndMetricsSidePanelCollapseSwitch = styledStitches(
  Collapsible.Trigger,
  {
    alignSelf: "start",
  },
);

const CollapseButton = styledStitches(Button, {
  '&[data-state="open"]': {
    svg: {
      transform: "rotate(-180deg)",
    },
  },
  variants: {
    sidePanelSize: {
      small: {
        padding: 18,
        width: "fit-content",
      },
      large: {
        padding: "8px 18px",
        display: "flex",
        justifyContent: "space-between",
      },
    },
  },
});

const CollapseButtonFlex = styledStitches(Flex, {
  flex: "0 0 100%",
});

export const MetricCard = styledStitches("section", {
  padding: "20px",
});

export const PrimaryMetricCard = styledStitches(MetricCard, {
  borderRadius: "$16",
  backgroundColor: CSS_COLORS.neutral2,
  padding: "20px",
  minWidth: "340px",
  minHeight: "fit-content",
});
