import { CurrentUserContext } from "@/app/app.utils";
import { DateTime } from "luxon";
import React from "react";
import { Text } from "../../../../../../../../../../../../design-system";
import { stringifyDateTimeInPrimaryTimezone, TIMEZONE } from "../../../../../../../../../../../utils";
import { FutureSessionConflictEvent } from "../use-future-conflicts.api";
import { StyledUpcomingConflictsCard } from "./upcoming-conflicts-card.styled";

interface UpcomingEventsConflictCardProps {
  event: FutureSessionConflictEvent;
}

export const UpcomingEventsConflictCard = ({
  event,
}: UpcomingEventsConflictCardProps) => {
  const cuser = React.useContext(CurrentUserContext);
  const eventStartTime = DateTime.fromISO(event.start_time);
  const eventEndTime = DateTime.fromISO(event.end_time);

  const startEndSameDay =
    eventStartTime.toISODate() === eventEndTime.toISODate();

  return (
    <StyledUpcomingConflictsCard flexDirection="column">
      <Text
        fontSize={"14"}
        capitalize={"firstLetter"}
        css={{ display: "initial" }}
      >
        <>
          {event.service_type ? "Session on " : "Event on "}
          {/* full datetime */}
          {stringifyDateTimeInPrimaryTimezone(eventStartTime, TIMEZONE(cuser), {
            ...DateTime.DATETIME_SHORT,
            timeZoneName: undefined,
          })}
          {" - "}
          {startEndSameDay
            ? // only time
              stringifyDateTimeInPrimaryTimezone(eventEndTime, TIMEZONE(cuser), {
                ...DateTime.TIME_SIMPLE,
                timeZoneName: undefined,
              })
            : // full datetime
              stringifyDateTimeInPrimaryTimezone(eventEndTime, TIMEZONE(cuser), {
                ...DateTime.DATETIME_SHORT,
                timeZoneName: undefined,
              })}{" "}
          {/* timezone only */}
          {stringifyDateTimeInPrimaryTimezone(eventStartTime, TIMEZONE(cuser), {
            day: "2-digit",
            timeZoneName: "short",
          }).slice(4)}
        </>
      </Text>
      <Text
        fontSize={"16"}
        capitalize={"firstLetter"}
        css={{ display: "initial" }}
      >
        {event.event_type === "block" ? (
          <>
            <b>Google Calendar</b>
            {event.block_description ? `: ${event.block_description}` : ""}
          </>
        ) : (
          <>
            <Text
              fontWeight={600}
              capitalize={true}
              css={{ display: "initial", verticalAlign: "initial" }}
            >
              {event.service_type}
            </Text>
            : {event.client?.first_name} {event.client?.last_name}
          </>
        )}
      </Text>
    </StyledUpcomingConflictsCard>
  );
};
