import { Flex, styledStitches } from "../../../../../../../design-system";

export const StyledFilterCard = styledStitches(Flex, {
  background: "$neutral3",
  minHeight: 100,
  minWidth: 310,
  br: 8,
  py: 12,
  px: 16,
  fontSize: 16,
  cursor: "pointer",
});
