import { Button, Separator, styledStitches } from "@/app/design-system";

import { CloseButton } from "@/app/design-system/drawer/drawer.styled";
import { XIcon } from "@/app/design-system/icons";
import {
  Content,
  Header,
  ModalIconButton,
  Overlay,
  Root,
} from "@/app/design-system/modal-dialog/modal-dialog.styled";

import {
  ModalTable,
  ModalTableHeader,
  ModalTableRow,
} from "@/app/design-system";

import * as Dialog from "@radix-ui/react-dialog";
import { EOCClinicianModalTableRow } from "./eoc-clinician-modal-table-row";
import { EOCEpisodeData } from "../../../../../types";
import { getMapLength } from "@/app/_helpers";
import { EOCClinicianModalTableRows } from "./eoc-clinician-modal-table-rows";

export interface SessionHistoryModalProps {
  sortedEocs: EOCEpisodeData[];
  mbcQueryError: boolean | undefined;
}

const SessionHistoryDialogContent = styledStitches(Content, {
  maxWidth: "640px",
});

const SessionHistoryDialogContentHeader = styledStitches(Header, {
  padding: "20px",
  margin: 0,
  lineHeight: 1,
});

const SessionHistoryDialogContentTableWrapper = styledStitches("div", {
  padding: "20px",
});

const SessionHistoryDialogContentErrorMessage = styledStitches("p", {
  textAlign: "center",
});

export const SessionHistoryModal = (props: SessionHistoryModalProps) => {
  return (
    <Root>
      <Dialog.Trigger asChild>
        <Button variant={"antdLink"}>View session history</Button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Overlay className="DialogOverlay" />
        <SessionHistoryDialogContent className="DialogContent">
          <SessionHistoryDialogContentHeader>
            Session History
          </SessionHistoryDialogContentHeader>
          <Separator />
          <SessionHistoryDialogContentTableWrapper>
            {props.mbcQueryError ? (
              <SessionHistoryDialogContentErrorMessage>
                Something went wrong on our end - please try again in a few
                minutes.
              </SessionHistoryDialogContentErrorMessage>
            ) : (
              <ModalTable borderShape={"rounded"}>
                <tbody>
                  <ModalTableRow>
                    <ModalTableHeader>Clinician Name</ModalTableHeader>
                    <ModalTableHeader>Dates</ModalTableHeader>
                    <ModalTableHeader># Sessions</ModalTableHeader>
                    <ModalTableHeader>Scores (First & Last)</ModalTableHeader>
                  </ModalTableRow>
                  <EOCClinicianModalTableRows eocs={props.sortedEocs} />
                </tbody>
              </ModalTable>
            )}
            <Dialog.DialogClose asChild>
              <ModalIconButton>
                <CloseButton aria-label="Close modal">
                  <XIcon></XIcon>
                </CloseButton>
              </ModalIconButton>
            </Dialog.DialogClose>
          </SessionHistoryDialogContentTableWrapper>
        </SessionHistoryDialogContent>
      </Dialog.Portal>
    </Root>
  );
};
