import { GroupTherapyClientNotesQuery } from "@/graphql/generated";
import { useParams } from "react-router-dom";
import { useGroupTherapyClientAttendanceNotes } from "./use-group-therapy-client-attendance-notes/use-group-therapy-client-attendance-notes.api";

interface UseGroupTherapyClientAttendanceNotesParams<T> {
  select: (data: GroupTherapyClientNotesQuery, timezone: string) => T;
  timezone: string;
}

export const useGroupTherapyClientNotesPage = <T extends object>({
  select,
  timezone,
}: UseGroupTherapyClientAttendanceNotesParams<T>) => {
  const { clientId, groupId } =
    useParams<{ groupId: string; clientId: string }>();

  const enabled = !!groupId && !!clientId;

  const { data, isLoading, isError } = useGroupTherapyClientAttendanceNotes({
    groupId,
    clientStringId: clientId,
    select: (data) => select(data, timezone),
    enabled,
  });

  return { data, isLoading, isError };
};
