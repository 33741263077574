import { EventApiData } from "@/app/api/use-my-clients-overview";
import { CurrentUserContext } from "@/app/app.utils";

import { useNotification } from "@/app/design-system";
import { useAddSession } from "@/app/my-clients/api/use-upsert-session/use-add-session";
import { useMyClientsUser } from "@/app/my-clients/routes/my-clients-page/hooks/use-my-clients-user";
import { TIMEZONE } from "@/app/my-clients/utils";
import React from "react";
import { UseFormHandleSubmit } from "react-hook-form";
import { useQueryClient } from "react-query";
import { SessionRecurrenceOption } from "./upsert-session-form/upsert-session-form.constants";
import { UpsertSessionFormFields } from "./upsert-session-form/use-upsert-session-form";

interface UseUpsertSessionActionParams<T> {
  mode: "edit" | "add";
  event: EventApiData | undefined;
  handleSubmit: UseFormHandleSubmit<UpsertSessionFormFields>;
  hasMyClientsPermissions: boolean | null;
  clientId: number | undefined;
  formValues?: UpsertSessionFormFields;
}

const isFalsy = (val: any) => val === null || val === undefined;

export function useUpsertSessionAction<T>({
  mode,
  event,
  handleSubmit,
  hasMyClientsPermissions,
  clientId,
  formValues,
}: UseUpsertSessionActionParams<T>) {
  const queryClient = useQueryClient();
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const title = mode === "edit" ? "Edit scheduled session" : "Add new session";

  const isRecurringEvent =
    event?.recurrenceData?.recurrence === "biweekly" ||
    event?.recurrenceData?.recurrence === "weekly";

  const isRecurringFormValueSelected = formValues
    ? formValues["recurrence"] === SessionRecurrenceOption.EVERY_WEEK ||
      formValues["recurrence"] === SessionRecurrenceOption.EVERY_OTHER_WEEK
    : false;

  /**
   * @todo https://twochairs.atlassian.net/browse/TC-4872
   * allow superusers to go to a client's profile, and schedule a new session with any clinician
   */

  const { clinicianId } = useMyClientsUser();
  const cuser = React.useContext(CurrentUserContext);

  const { renderNotification } = useNotification();

  const addSessionMutation = useAddSession();

  const submitForm = handleSubmit((data) => {
    if (isFalsy(clientId) || isFalsy(clinicianId) || !hasMyClientsPermissions) {
      renderNotification({
        message: "There was an error creating this session.",
        notificationType: "error",
      });
      return;
    } else {
      addSessionMutation.mutate(
        { clinicianId, clientId: clientId as number, data, timezone: TIMEZONE(cuser) },
        {
          onError: () => {
            renderNotification({
              message: "There was an error creating this session.",
              notificationType: "error",
            });
          },
          onSuccess: () => {
            queryClient.invalidateQueries();
            setIsDrawerOpen(false);
            renderNotification({
              message: "Session successfuly created.",
              notificationType: "success",
            });
          },
        },
      );
    }
  });

  return {
    title,
    isDrawerOpen,
    setIsDrawerOpen,
    submitForm,
    isAddSessionLoading: addSessionMutation.isLoading,
    isRecurringEvent,
    isRecurringFormValueSelected,
  };
}
