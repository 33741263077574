import { graphqlClient } from "@/api/graphql";
import { FIVE_MINUTES } from "@/app/_helpers/constants";
import {
  GroupTherapyClientNotesQuery,
  useGroupTherapyClientNotesQuery,
} from "@/graphql/generated";

interface UseGroupTherapyClientAttendanceNotesParams<T> {
  groupId: string | undefined;
  clientStringId: string | undefined;
  select?: (data: GroupTherapyClientNotesQuery) => T;
  enabled?: boolean;
}

export const useGroupTherapyClientAttendanceNotes = <T extends {}>({
  groupId,
  clientStringId,
  select,
  enabled,
}: UseGroupTherapyClientAttendanceNotesParams<T>) => {
  const clientId = clientStringId ? parseInt(clientStringId) : undefined;
  return useGroupTherapyClientNotesQuery(
    graphqlClient,
    { groupId, clientId },
    {
      staleTime: FIVE_MINUTES,
      select,
      enabled,
    },
  );
};
