import { MyClientsIcon } from "@/app/design-system/icons";

import { NavigationMenuItem } from "../../components.utility/navigation-menu-item";

interface MyClientsMenuItemProps {
  isCollapsed: boolean;
}

export function MyClientsMenuItem({ isCollapsed }: MyClientsMenuItemProps) {
  const menuItemName = "My Clients";
  return (
    <NavigationMenuItem
      to="/my-clients"
      menuItemTooltipContent={menuItemName}
      isCollapsed={isCollapsed}
    >
      <MyClientsIcon
        height={20}
        width={20}
        aria-hidden={!isCollapsed}
        title={!isCollapsed ? "" : menuItemName}
      />
      {!isCollapsed ? menuItemName : null}
    </NavigationMenuItem>
  );
}
