import React from "react";
import {
  TabsList,
  TabsTrigger,
  TabsRoot,
  TabsContent,
  HoverTabHighlight,
  ActiveTabHighlight,
} from "@/app/_components/navigation-tabs/navigation-tabs.styled";
import { useNavigationTabsStyling } from "@/app/_components/navigation-tabs/use-navigation-tabs-styling";
import { Flex, Stack, styledStitches } from "@/app/design-system";
import {
  getLastCohesionScoreAndDate,
  getLatestPHQOrGADStatus,
  ParsedClientEventMbcScoresAndSeverities,
  ParsedClientMBCData,
} from "../_utils/mbc.util";
import { MBCAssessmentPHQ9Graph } from "./mbc-assessment-phq9-graph";
import { MBCAssessmentGAD7Graph } from "./mbc-assessment-gad7-graph";
import { MBCAssessmentCohesionGraph } from "./mbc-assessment-cohesion-graph";
import { TIMEZONE } from "@/app/my-clients/utils";
import { CurrentUserContext } from "@/app/app.utils";
import { SurveyResults } from "./mbc-survey-answers";

const DEPRESSION_PHQ9_VALUE = "Depression (PHQ9)";
const ANXIETY_GAD7_VALUE = "Anxiety (GAD7)";
const GROUP_COHESION_VALUE = "Group Cohesion";

type TagColors = "blue" | "green" | "yellow" | "red" | "grey";

export const StatusTagColorsValueMap = {
  Remission: "blue" as TagColors,
  Improvement: "green" as TagColors,
  Stagnation: "yellow" as TagColors,
  Escalate: "red" as TagColors,
};

interface StatusTagProps {
  label: string;
  color?: TagColors;
}

const StatusTag = ({ label, color = "grey" }: StatusTagProps) => (
  <StatusTagContainerDiv color={color}>{label}</StatusTagContainerDiv>
);

interface HeaderItemContentProps {
  tabHeight: number;
  label: string;
  statusTagLabel?: string;
  statusTagColor?: TagColors;
}

const HeaderItemContent = ({
  label,
  statusTagLabel,
  statusTagColor,
  tabHeight,
}: HeaderItemContentProps) => (
  <HeaderItemContentContainerDiv css={{ height: tabHeight }}>
    <div>{label}</div>
    {statusTagLabel && (
      <StatusTag label={statusTagLabel} color={statusTagColor} />
    )}
  </HeaderItemContentContainerDiv>
);

interface MBCAssessmentActiveTabsProps {
  eventScores: ParsedClientEventMbcScoresAndSeverities[] | null;
}

export const MBCAssessmentActiveTabs = ({
  eventScores,
}: MBCAssessmentActiveTabsProps) => {
  const cuser = React.useContext(CurrentUserContext);
  const {
    wrapperRef,
    resetHighlight,
    highlightStyles,
    hoverHighlightRef,
    repositionHighlight,
    activeTab,
    repositionActiveHighlight,
    activeStyles,
  } = useNavigationTabsStyling(DEPRESSION_PHQ9_VALUE);

  const latestPHQStatus = getLatestPHQOrGADStatus(eventScores, "PHQ");
  const PHQStatusTagColor = latestPHQStatus
    ? StatusTagColorsValueMap[latestPHQStatus]
    : undefined;

  const latestGADStatus = getLatestPHQOrGADStatus(eventScores, "GAD");
  const GADStatusTagColor = latestGADStatus
    ? StatusTagColorsValueMap[latestGADStatus]
    : undefined;

  const cohesionScoreAndDate = getLastCohesionScoreAndDate(
    eventScores,
    TIMEZONE(cuser),
  );

  // if there are status tags (ie, if a client has answered any surveys)
  // the height should be 70 to accomodate, otherwise shorter
  const tabHeight =
    !!latestPHQStatus || !!latestGADStatus || cohesionScoreAndDate ? 70 : 30;

  return (
    <TabsRoot
      orientation="horizontal"
      defaultValue={activeTab}
      value={activeTab}
      css={{
        overflow: "auto",
        backgroundColor: "#fff",
        pt: 8,
      }}
    >
      <TabsList
        ref={wrapperRef}
        onMouseLeave={resetHighlight}
        css={{ borderBottom: "1px solid $neutral10" }}
      >
        <HoverTabHighlight ref={hoverHighlightRef} css={highlightStyles} />
        <ActiveTabHighlight css={{ width: 245, ...activeStyles }} />

        <TabsTrigger
          value={DEPRESSION_PHQ9_VALUE}
          onMouseEnter={(e) => repositionHighlight(e, DEPRESSION_PHQ9_VALUE)}
          onClick={(e) => repositionActiveHighlight(e, DEPRESSION_PHQ9_VALUE)}
          css={{ width: 245 }}
        >
          <HeaderItemContent
            tabHeight={tabHeight}
            label={DEPRESSION_PHQ9_VALUE}
            statusTagLabel={latestPHQStatus}
            statusTagColor={PHQStatusTagColor}
          />
        </TabsTrigger>

        <TabsTrigger
          value={ANXIETY_GAD7_VALUE}
          onMouseEnter={(e) => repositionHighlight(e, ANXIETY_GAD7_VALUE)}
          onClick={(e) => repositionActiveHighlight(e, ANXIETY_GAD7_VALUE)}
          css={{ width: 245 }}
        >
          <HeaderItemContent
            tabHeight={tabHeight}
            label={ANXIETY_GAD7_VALUE}
            statusTagLabel={latestGADStatus}
            statusTagColor={GADStatusTagColor}
          />
        </TabsTrigger>

        <TabsTrigger
          value={GROUP_COHESION_VALUE}
          onMouseEnter={(e) => repositionHighlight(e, GROUP_COHESION_VALUE)}
          onClick={(e) => repositionActiveHighlight(e, GROUP_COHESION_VALUE)}
          css={{ width: 245 }}
        >
          <HeaderItemContent
            tabHeight={tabHeight}
            label={GROUP_COHESION_VALUE}
            statusTagLabel={cohesionScoreAndDate}
            statusTagColor="grey"
          />
        </TabsTrigger>
      </TabsList>

      <Flex css={{ flexGrow: 1 }}>
        <TabsContent value={DEPRESSION_PHQ9_VALUE}>
          <Stack>
            <MBCAssessmentPHQ9Graph eventScores={eventScores} />
            <SurveyResults surveyName="phq" timezone={TIMEZONE(cuser)} />
          </Stack>
        </TabsContent>

        <TabsContent value={ANXIETY_GAD7_VALUE}>
          <Stack>
            <MBCAssessmentGAD7Graph eventScores={eventScores} />
            <SurveyResults surveyName="gad" timezone={TIMEZONE(cuser)} />
          </Stack>
        </TabsContent>

        <TabsContent value={GROUP_COHESION_VALUE}>
          <Stack>
            <MBCAssessmentCohesionGraph eventScores={eventScores} />
            <SurveyResults surveyName="cohesion" timezone={TIMEZONE(cuser)} />
          </Stack>
        </TabsContent>
      </Flex>
    </TabsRoot>
  );
};

const StatusTagContainerDiv = styledStitches("div", {
  padding: "4px 12px",
  width: "fit-content",
  borderRadius: 10,
  fontWeight: 400,
  marginTop: 4,
  variants: {
    color: {
      blue: {
        backgroundColor: "$blue3",
        color: "$blue11",
      },
      green: {
        backgroundColor: "$green3",
        color: "$green11",
      },
      yellow: {
        backgroundColor: "$yellow3",
        color: "$yellow11",
      },
      red: {
        backgroundColor: "$red3",
        color: "$red11",
      },
      white: {
        border: "1px solid $neutral8",
        color: "$neutral11",
      },
      grey: {
        backgroundColor: "$neutral3",
        color: "$neutral11",
      },
    },
  },
});

const HeaderItemContentContainerDiv = styledStitches("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "0 4px",
  height: 70,
});
