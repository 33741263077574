import {
  STANDARD_DATETIME_PICKER_END,
  STANDARD_DATETIME_PICKER_START,
} from "@/app/_helpers/datetime";
import { useShallowEqualSelector } from "@/app/_helpers/redux";
import { BackLink } from "@/app/_shared/BackLink";
import { CommonAbbr } from "@/app/_shared/CommonAbbr";
import { MainContentHeader } from "@/app/_shared/MainContentHeader";
import {
  TimePicker,
  TimePickerDateRange,
  incrementLuxonDate,
} from "@/app/_shared/TimePicker";
import { CurrentUserContext } from "@/app/app.utils";
import { TimeZoneAndClinicianPicker } from "@/app/dashboard2.0/_components/timezone-and-clinician-picker";
import {
  BILLABLE_UTILIZATION_THRESHOLD,
  getAvgBillableUtilization,
} from "@/app/dashboard2.0/utilization/utilization-tile";
import { Flex, Spacer, Title5 } from "@/app/design-system";
import { CheckIconFilled } from "@/app/design-system/icons";
import { UtilizationFlagIcon } from "@/app/design-system/icons/components/utilization-flag";
import useTitle from "@/app/hooks/useTitle";
import { StyledDatePickerWithPrettyLittleRoundedCorners } from "@/app/library/Resources/SendToClient";
import { HintTextDiv } from "@/app/panel-management/_shared";
import { Spin } from "antd";
import moment from "moment";
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { NoDataMessage } from "../_components/no-data-message";
import { Container } from "../dashboard";
import BillableHoursChart from "./billable-hours";
import { DateTime } from "luxon";

export const Utilization = () => {
  const params = useParams<{ clinicianId: string }>();
  const navigate = useNavigate();
  const clinicianId = Number(params.clinicianId);
  useTitle("Utilization Hours", true);
  const searchParams = new URLSearchParams(window.location.search);
  const startDateString = searchParams.get('startDate');
  const endDateString =  searchParams.get('endDate');
  const startDate = startDateString ? DateTime.fromISO(startDateString) : null;
  const endDate = endDateString ? DateTime.fromISO(endDateString) : null;

  // Initializing date range to query param in order to maintain date picker state
  // when toggling between clinicians with the clinician picker.
  let initialDateRange: TimePickerDateRange = {
    start: startDate || STANDARD_DATETIME_PICKER_START,
    end: endDate || STANDARD_DATETIME_PICKER_END,
  };

  const [timePickerDateRange, setTimePickerDateRange] =
    React.useState<TimePickerDateRange>(initialDateRange);

  const { isLoading, avgBillableUtilization, billableHoursMap } =
    getAvgBillableUtilization({ clinicianId, timePickerDateRange });
  const cuser = React.useContext(CurrentUserContext);

  const { clinicianMap, myTeamClinicianIds, clinicianManagers } =
    useShallowEqualSelector((state) => ({
      clinicianMap: state.clinicians.clinicianMap,
      myTeamClinicianIds: state.auth.myTeamClinicianIds,
      clinicianManagers: state.dashboard.clinicianManagers,
    }));
  const loggedInUserClinicianId = cuser?.clinician?.id;

  // TODO: Add loading indicator based off of derivative graphQL call useClinicalUtilization() of getAvgBillableUtilization() above
  return (
    <>
      <MainContentHeader>
        <BackLink>Back to home</BackLink>
        <TimeZoneAndClinicianPicker
          clinicianMap={clinicianMap}
          handleSelectClinician={(clinicianId: number) => {
            const startDate = timePickerDateRange.start.toISODate();
            const endDate = timePickerDateRange.end.toISODate();
            navigate(`/dashboard/${clinicianId}/utilization?startDate=${startDate}&endDate=${endDate}`);
          }}
          clinicianId={clinicianId}
          myTeamClinicianIds={myTeamClinicianIds}
          permissions={["IsSuperUser", "IsClinicalLeader", "IsCareCoordinator"]}
          clinicianManagers={clinicianManagers}
          currentUser={cuser}
          loggedInUserClinicianId={loggedInUserClinicianId}
        />
      </MainContentHeader>
      <Container>
        <Spin size="large" spinning={isLoading}>
          <div style={{ padding: "0 0 24px 4px" }}>
            <Flex flexDirection="row" justifyContent="space-between">
              <Flex flexDirection="row">
                <Flex flexDirection="column">
                  <Flex flexDirection="row" alignItems="center">
                    <Title5 as="h1" style={{ marginBottom: "0px" }}>
                      <CommonAbbr title="Average">Avg</CommonAbbr> Utilization:{" "}
                      {!isNaN(avgBillableUtilization)
                        ? `${avgBillableUtilization}%`
                        : ""}
                    </Title5>
                    <Spacer x="8" />
                    {isLoading ? null : avgBillableUtilization >=
                      BILLABLE_UTILIZATION_THRESHOLD ? (
                      <CheckIconFilled
                        className="circle-check-mark"
                        title="Meets goal"
                      />
                    ) : (
                      <UtilizationFlagIcon
                        className="utilization-flag"
                        title="Below goal"
                      />
                    )}
                  </Flex>
                  <p>Goal is {String(BILLABLE_UTILIZATION_THRESHOLD)}%</p>
                </Flex>
                <Spacer x="24" />
              </Flex>
              <section>
                <StyledDatePickerWithPrettyLittleRoundedCorners>
                  <TimePicker
                    dateRange={timePickerDateRange}
                    setDateRange={setTimePickerDateRange}
                    incrementDate={incrementLuxonDate}
                  />
                </StyledDatePickerWithPrettyLittleRoundedCorners>
              </section>
            </Flex>
          </div>
          {!isLoading && !avgBillableUtilization ? (
            <HintTextDiv card={"white"}>
              <NoDataMessage />
            </HintTextDiv>
          ) : (
            <BillableHoursChart
              defaultClinicianId={clinicianId}
              defaultBillableHoursWeeks={billableHoursMap}
              defaultDateRange={{
                start: moment(timePickerDateRange.start.toJSDate()),
                end: moment(timePickerDateRange.end.toJSDate()),
              }}
            />
          )}
        </Spin>
      </Container>
    </>
  );
};
