import { SessionHistoryTimeRange } from "@/app/_helpers/constants";
import create from "zustand";
import {
  MyClientSessionsState,
  MyClientsSessionsAppointmentStatusFilterState,
  MyClientsSessionsTimeFilterState
} from "./my-clients-schedule.interface";

export const initialTimeRangeFilter: MyClientsSessionsTimeFilterState = {
  timeRangeToFilterOn: SessionHistoryTimeRange.ALL_TIME,
};

export const initialAppointmentStatusFilters: MyClientsSessionsAppointmentStatusFilterState =
  {
    isFilteringOnCanceledByClient: false,
    isFilteringOnAttended: false,
    isFilteringOnLateCancelByClient: false,
    isFilteringOnNoShow: false,
  };

export const initialSessionFilters = {
  ...initialTimeRangeFilter,
  ...initialAppointmentStatusFilters,
};

export const selectAppointmentStatusFiltersSlice = ({
  isFilteringOnCanceledByClient,
  isFilteringOnAttended,
  isFilteringOnLateCancelByClient,
  isFilteringOnNoShow,
}: MyClientsSessionsAppointmentStatusFilterState) => ({
  isFilteringOnCanceledByClient,
  isFilteringOnAttended,
  isFilteringOnLateCancelByClient,
  isFilteringOnNoShow,
});

export const selectSessionHistoryTimeRangeFiltersSlice = ({
  timeRangeToFilterOn,

  setSessionHistoryTimeRangeFilter,
}: MyClientSessionsState) => ({
  timeRangeToFilterOn,

  setSessionHistoryTimeRangeFilter,
});

export const useMyClientsScheduleStore = create<MyClientSessionsState>()(
  (set) => ({
    ...initialSessionFilters,
    setSessionHistoryTimeRangeFilter: (timeRangeToFilterOn) =>
      set(() => ({ timeRangeToFilterOn })),
    clearSessionHistoryAppointmentStatusFilter: (filter) =>
      set(() => ({ [filter]: false })),
    setSessionHistoryAppointmentStatusFilter: (filter, isSelected) =>
      set(() => ({ [filter]: isSelected })),
  }),
);
