import { IconProps } from "../icon.interface";

export const TrashCanIcon = ({
  height = 28,
  width = 28,
  stroke = "#171717",
  title,
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden={!title}
    >
      {title ? <title>{title}</title> : null}
      <path
        d="M3.5 7H5.83333H24.5"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.33325 7.00004V4.66671C9.33325 4.04787 9.57909 3.45438 10.0167 3.01679C10.4543 2.57921 11.0477 2.33337 11.6666 2.33337H16.3333C16.9521 2.33337 17.5456 2.57921 17.9832 3.01679C18.4208 3.45438 18.6666 4.04787 18.6666 4.66671V7.00004M22.1666 7.00004V23.3334C22.1666 23.9522 21.9208 24.5457 21.4832 24.9833C21.0456 25.4209 20.4521 25.6667 19.8333 25.6667H8.16659C7.54775 25.6667 6.95425 25.4209 6.51667 24.9833C6.07908 24.5457 5.83325 23.9522 5.83325 23.3334V7.00004H22.1666Z"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6667 12.8334V19.8334"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3333 12.8334V19.8334"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
