export interface Props {
  title?: string;
}
export const ThreeDotsIcon = ({ title }: Props) => {
  return (

    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      {title ? <title>{title}</title> : null}
      <path d="M10 10.8333C10.4603 10.8333 10.8334 10.4602 10.8334 10C10.8334 9.53977 10.4603 9.16667 10 9.16667C9.53978 9.16667 9.16669 9.53977 9.16669 10C9.16669 10.4602 9.53978 10.8333 10 10.8333Z" fill="#171717" stroke="#171717" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M15.8333 10.8333C16.2936 10.8333 16.6667 10.4602 16.6667 10C16.6667 9.53977 16.2936 9.16667 15.8333 9.16667C15.3731 9.16667 15 9.53977 15 10C15 10.4602 15.3731 10.8333 15.8333 10.8333Z" fill="#171717" stroke="#171717" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M4.16665 10.8333C4.62688 10.8333 4.99998 10.4602 4.99998 10C4.99998 9.53977 4.62688 9.16667 4.16665 9.16667C3.70641 9.16667 3.33331 9.53977 3.33331 10C3.33331 10.4602 3.70641 10.8333 4.16665 10.8333Z" fill="#171717" stroke="#171717" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    
  );
};
