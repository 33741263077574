import { Flex } from "@/app/design-system/layout/flex";
import { styledStitches } from "../design-system/styles/stitches.config";

export const MainContentHeader = styledStitches(Flex, {
  backgroundColor: "$neutral0",
  color: "black",
  borderBottom: "1px solid",
  borderColor: "$neutral5",
  justifyContent: "space-between",
  alignItems: "start",
  padding: 10,
  px: 24,
  minHeight: 65,
  flexWrap: "wrap",
});
