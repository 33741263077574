import { styledStitches } from "@/app/design-system/styles/stitches.config";

export const SORTED_IN_ASCENDING = "(sorted in ascending order)";
export const SORTED_IN_DESCENDING = "(sorted in descending order)";

export const Table = styledStitches("table", {
  borderRadius: "8px",
  backgroundColor: "#fff",
  overflow: "hidden",
  table: {
    marginBottom: "20px",
  },
  "thead > tr > th": {
    backgroundColor: "#fff",
    borderBottom: "2px solid #f0f0f0",
    fontWeight: "400",
    fontSize: "12px",
  },
  "tbody > tr > td": {
    borderBottom: "1px solid #f0f0f0",
    fontWeight: "500",
    fontSize: "16px",
    padding: "20px 16px",
  },
  tableLayout: "fixed",
  width: "100%",
  th: {
    padding: "10px 16px",
  },
  textAlign: "left",
});

export const ClickableRowTable = styledStitches(Table, {
  "tbody > tr:hover": {
    backgroundColor: "$neutral2",
  },
  tr: {
    position: "relative",
  },
});
