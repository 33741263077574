import { DateTime } from "luxon";
import { MatchingInfoByClientIdsQuery } from "../../../../graphql/generated";
import { ClientMatchingStatus } from "../../routes/my-clients-page/components/client-pane/components/matching-status-badge";

export interface MatchingInfoByClientIdApiData {
  matchedClinicianId: number;
  matchedAt: string;
  matchingStatus: ClientMatchingStatus;
  expiresAt: string;
  firstSessionDate: string;
  serviceType: string;
  numScheduledAppointments: number;
  clientStatus: string;
  status: string;
}

export interface MatchingInfoByClientIdEventApiData {
  id: string;
  startTime: string;
  appointmentStatus: string;
  serviceType: string;
  clinicianId: number;
}

interface GetFilteredEventsParams {
  events: MatchingInfoByClientIdEventApiData[];
  clinicianId?: number;
}

function getMatchingStatusFromEvents({
  events,
  clinicianId,
}: GetFilteredEventsParams) {
  const sortedEvents = events.sort(
    (a, b) =>
      DateTime.fromISO(a.startTime).toMillis() -
      DateTime.fromISO(b.startTime).toMillis(),
  );

  const now = DateTime.now().toMillis();

  const futureEvents = sortedEvents.filter((event) => {
    const sessionStartTime = DateTime.fromISO(event.startTime).toMillis();

    return sessionStartTime > now;
  });

  const futureNonConsultNonCanceledEventsWithClinician = futureEvents.filter(
    (event) =>
      event.serviceType !== "consult" &&
      event.appointmentStatus !== "canceled" &&
      event.clinicianId === clinicianId,
  );

  if (futureNonConsultNonCanceledEventsWithClinician.length > 0) {
    return ClientMatchingStatus.Active;
  }

  const pastNonConsultEvents =
    futureNonConsultNonCanceledEventsWithClinician.filter(
      (event) => now > DateTime.fromISO(event.startTime).toMillis(),
    );

  // we specify at query level to query for all events that are either 4 weeks
  // into the future or 4 weeks in the past. if any of those events 4 weeks in the past
  // are attended, we consider this client to be active.
  if (
    pastNonConsultEvents.some((event) => event.appointmentStatus === "attended")
  ) {
    return ClientMatchingStatus.Active;
  }

  return ClientMatchingStatus.Inactive;
}

interface GetMatchingStatusFromMatchMetaDataParams {
  matchMetaData: MatchingInfoByClientIdApiData;
}

function getMatchingStatusFromMatchMetaData({
  matchMetaData,
}: GetMatchingStatusFromMatchMetaDataParams) {
  // PROSPECTIVE
  if (matchMetaData?.clientStatus === "Prospective Match") {
    // this is when a client has been matched, but has not been sent the email to
    // confirm match to timeslot. this value exists for 24hr mon 3pm-tues 3pm and thurs 3pm-fri 3pm
    return ClientMatchingStatus.Prospective;

    // PENDING
  } else if (matchMetaData?.clientStatus === "Pending Match") {
    // when a client is matched and sent a match email, but yet to confirm
    // match to timeslot
    return ClientMatchingStatus.Pending;

    // NEW
  } else if (matchMetaData?.clientStatus === "Scheduled") {
    // when a client is scheduled to have their first therapy session after confirming
    // match to timeslot
    return ClientMatchingStatus.New;
    // INACTIVE
  } else if (matchMetaData?.numScheduledAppointments === 0) {
    // for rematches:
    // if a client is rematched to a clinician that is NOT the "current clinician"
    // numScheduledAppointments will be 0, because numScheduledAppointments is
    // scheduled appointments with the "current clinician"

    // the "current clinician" is dictacted by the clinicianId we pass to our backend
    // to fetch all active clients by clinician, which gives us the list of client ids
    // to query matching data by.
    return ClientMatchingStatus.Inactive;
    // ACTIVE
  } else if (
    (matchMetaData?.clientStatus === "Active" ||
      matchMetaData?.clientStatus === "Rematched") &&
    matchMetaData?.numScheduledAppointments > 0
  ) {
    // this when a client is in active care
    return ClientMatchingStatus.Active;
  }

  return ClientMatchingStatus.Inactive;
}

interface GetFilteredAndSortedMatchesParams {
  clinicianId: number | undefined;
  matches: MatchingInfoApiData[];
}

interface MatchingInfoApiData {
  matchMetaData: MatchingInfoByClientIdApiData;
}

function getFilteredAndSortedMatches({
  clinicianId,
  matches,
}: GetFilteredAndSortedMatchesParams) {
  const filteredMatchesByCurrentClinician = clinicianId
    ? matches.filter(
        (match) => match.matchMetaData?.matchedClinicianId === clinicianId,
      )
    : matches;

  const sortedMatchInfo = filteredMatchesByCurrentClinician.sort(
    (a, b) =>
      DateTime.fromISO(b.matchMetaData?.matchedAt).toMillis() -
      DateTime.fromISO(a.matchMetaData?.matchedAt).toMillis(),
  );

  return sortedMatchInfo;
}

/**
 * @function selectDataForMatchingInfoByClientId
 * @description TODO
 * @param { MatchingDataByClientIdsQuery } data
 * @returns { Record<number, MatchingInfoByClientIdApiData> }
 * returns matching data for a client.
 *
 */

export function selectDataForMatchingInfoByClient(
  data: MatchingInfoByClientIdsQuery,
  clinicianId?: number,
): Record<number, MatchingInfoByClientIdApiData> {
  const matchingDataByClientMap = {};

  data.client.forEach((client) => {
    const matches = client.matches;

    const events = client.events;

    const sortedMatchInfo = getFilteredAndSortedMatches({
      clinicianId,
      matches: matches as MatchingInfoApiData[],
    });

    const doesClientHaveMatchesWithCurrentClinician = !!sortedMatchInfo[0];

    const matchMetaData = sortedMatchInfo[0]?.matchMetaData;

    if (doesClientHaveMatchesWithCurrentClinician) {
      const matchingStatus = getMatchingStatusFromMatchMetaData({
        matchMetaData: matchMetaData as MatchingInfoByClientIdApiData,
      });

      let timeUntilexpirationText;

      if (matchMetaData?.expiresAt) {
        const hours = Math.trunc(
          (DateTime.fromISO(matchMetaData?.expiresAt).toMillis() -
            DateTime.now().toMillis()) /
            3600000,
        );

        if (hours >= 24) {
          const days = Math.trunc(hours / 24);
          timeUntilexpirationText = `${days}d`;
        } else {
          timeUntilexpirationText = `${hours}h`;
        }
      }

      matchingDataByClientMap[client.clientId] = {
        matchingStatus,
        expiresAt: timeUntilexpirationText
          ? timeUntilexpirationText
          : matchMetaData?.expiresAt,
        firstSessionDate: matchMetaData?.firstSessionDate,
        serviceType: matchMetaData?.serviceType,
      };
    } else {
      const matchingStatus = getMatchingStatusFromEvents({
        events: events as MatchingInfoByClientIdEventApiData[],
        clinicianId,
      });

      matchingDataByClientMap[client.clientId] = {
        matchingStatus,
        expiresAt: matchMetaData?.expiresAt,
        firstSessionDate: matchMetaData?.firstSessionDate,
        serviceType: matchMetaData?.serviceType,
      };
    }
  });

  return matchingDataByClientMap;
}
