import * as React from "react";

import { Category } from "../types";
import {
  ResourceContainer,
  ResourceContent,
  ResourceContentFooter,
} from "../styles";

import { Row } from "../../_layout/Flex";
import { EditIcon, PublishStatusIcon } from "../Icons";

import { IfPermitted } from "../../_helpers/permissions";

interface CategoryProps {
  item: Category;
  onSelect: (id: string) => void;
  onSetEditCategory?: (item: Category) => void;
}

const CategoryItem = ({ item, onSelect, onSetEditCategory }: CategoryProps) => {
  const resourceCount = item.items?.length;
  const resourceCountText = `${resourceCount} Resource${
    resourceCount === 1 ? "" : "s"
  }`;

  return (
    <ResourceContainer key={item.id} onClick={() => onSelect(item.id)}>
      <ResourceContent>{item.name}</ResourceContent>
      <ResourceContentFooter>
        <Row layout="space-between center">
          {resourceCountText}
          <Row layout="flex-end center">
            <PublishStatusIcon is_published={item.is_published} />
            <IfPermitted
              permissions={["IsResourceLibCurator"]}
              requireAll={true}
            >
              <EditIcon
                tooltipTitle="Edit Category"
                onClick={e => {
                  e.stopPropagation();
                  if (onSetEditCategory) {
                    onSetEditCategory(item);
                  }
                }}
              />
            </IfPermitted>
          </Row>
        </Row>
      </ResourceContentFooter>
    </ResourceContainer>
  );
};

export { CategoryItem };
