import { AvailabilityToolIcon } from "@/app/design-system/icons";

import { NavigationMenuItem } from "../../components.utility/navigation-menu-item";

interface AvailabilityToolMenuItemProps {
  isCollapsed: boolean;
}

export function AvailabilityToolMenuItem({
  isCollapsed,
}: AvailabilityToolMenuItemProps) {
  const menuItemName = "Availability Tool";
  return (
    <NavigationMenuItem
      menuItemTooltipContent={menuItemName}
      to="/availability-tool"
      isCollapsed={isCollapsed}
    >
      <AvailabilityToolIcon
        aria-hidden={!isCollapsed}
        title={!isCollapsed ? "" : menuItemName}
      />
      {!isCollapsed ? menuItemName : null}
    </NavigationMenuItem>
  );
}
