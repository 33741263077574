import { useEffect, useState } from "react";

import { IdMap } from "../../../../../../../api/types";
import { useShallowEqualSelector } from "../../../../../../_helpers/redux";

export function useBestScoreAndSlot({ matchId }: { matchId: number }) {
  const [clinicianBestScores, setSlinicianBestScores] = useState<IdMap<number>>(
    {},
  );
  const [clinicianBestSlots, setSlinicianBestSlots] = useState<IdMap<string>>(
    {},
  );

  const [aqmScores] = useShallowEqualSelector((state) => [
    state.matches.aqmScores[matchId],
  ]);

  // identify the best score and corresponding slot id for each clinician
  useEffect(() => {
    if (aqmScores) {
      Object.entries(aqmScores).forEach(([slotId, breakdown]) => {
        const clinicianId = slotId.split("_")[0];
        if (!(clinicianId in clinicianBestScores)) {
          setSlinicianBestScores({ [clinicianId]: breakdown.score });
          setSlinicianBestSlots({ [clinicianId]: slotId });
        } else {
          if (breakdown.score > clinicianBestScores[clinicianId]) {
            clinicianBestScores[clinicianId] = breakdown.score;
            clinicianBestSlots[clinicianId] = slotId;
          }
        }
      });
    }
  }, [aqmScores]);

  return { clinicianBestSlots };
}
