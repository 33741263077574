import React from "react";
import { useGetNumPriorWeekConsults } from "@/app/dashboard2.0/queries/use-get-num-prior-week-consults";
import { DateTime } from "luxon";
import { ConfettiChair } from "@/app/design-system/icons/components/confetti-chair";
import { styledStitches } from "@/app/design-system";
import { ConfettiClipboard } from "@/app/design-system/icons/components/confetti-clipboard";
import { getSaturdayEndOfWeek, getSundayStartOfWeek } from "@/app/_helpers/datetime";
import { useGetNumClients } from "@/app/dashboard2.0/queries/use-get-num-clients";
import { useShallowEqualSelector } from "@/app/_helpers/redux";

export const CongratulatoryBanners = () => {
  const {
    clinicianId,
  } = useShallowEqualSelector((state) => ({
    clinicianId: state.dashboard.clinicianId,
  }));
  const { data: numPriorWeekConsults } = useGetNumPriorWeekConsults({
    start: getSundayStartOfWeek(DateTime.now()).minus({ weeks: 1 }),
    end: getSaturdayEndOfWeek(DateTime.now()).minus({ weeks: 1 }),
  });
  const { data: numClientsYesterday } = useGetNumClients({
    clinicianId: Number(clinicianId),
    start: DateTime.now().minus({ days: 1 }).startOf('day'),
    end: DateTime.now().minus({ days: 1 }).endOf('day'),
  });

  const showNumPriorWeekConsults = React.useMemo(() => {
    // If there are no new clients, don't show the banner
    const numNewClients = numPriorWeekConsults?.ehr_event_aggregate.aggregate?.count;
    if (!numNewClients || Number.isInteger(numNewClients) && numNewClients === 0) {
      return false;
    }

    // If it's not a weekday, don't show the banner
    const todayIsWeekday = DateTime.now().weekday <= 5;
    if (!todayIsWeekday) {
      return false;
    }

    // At this point, there are new clients from last week. If the banner has never been displayed yet, show the banner
    const lastDisplayedTime = localStorage.getItem('lastDisplayedNewClientsCount');
    if (!lastDisplayedTime) {
      localStorage.setItem('lastDisplayedNewClientsCount', DateTime.now().toISO());
      return true;
    }

    // If the banner has been displayed today (when there are new clients from last week), show the banner
    const lastDisplayedTimeIsToday = lastDisplayedTime && DateTime.fromISO(lastDisplayedTime).hasSame(DateTime.now(), 'day');
    if (lastDisplayedTimeIsToday) {
      localStorage.setItem('lastDisplayedNewClientsCount', DateTime.now().toISO());
      return true;
    }

    // If the banner has been displayed earlier this week, don't show the banner
    const lastDisplayedTimeIsThisWeek = lastDisplayedTime && DateTime.fromISO(lastDisplayedTime).hasSame(DateTime.now(), 'week');
    if (lastDisplayedTimeIsThisWeek) {
      return false;
    }

    // There are new clients from last week, it's not a weekday, and the banner has been previously displayed in a prior week.
    // Show the banner and update the last displayed time.
    localStorage.setItem('lastDisplayedNewClientsCount', DateTime.now().toISO());
    return true;
  }, [numPriorWeekConsults]);

  const showCongratulatoryClipboard = React.useMemo(() => {
    const clientsWithScores = numClientsYesterday?.withScores?.aggregate?.count || -1;
    const totalClients = numClientsYesterday?.totalClients?.aggregate?.count || -1;
    return clientsWithScores > 0 && totalClients === clientsWithScores;
  }, [numClientsYesterday]);

  // When user logs into the Care Platform for the first time in a day, show max of 1 messages (if applicable)
  // in the following order:
  //     1. "xx new clients joined Two Chairs last week!""
  //     2. All clients filled out their surveys yesterday!
  return (
    <>
      {
        showNumPriorWeekConsults ? (
          <CelebrationContainer>
            <ConfettiChair className="congratulatory-chair" />
            <ConfettiChairText>
              {numPriorWeekConsults?.ehr_event_aggregate.aggregate?.count} new clients joined
              <br/>Two Chairs last week!
            </ConfettiChairText>
          </CelebrationContainer>
        ) : showCongratulatoryClipboard ? (
          <CelebrationContainer>
            <ConfettiClipboard className="congratulatory-clipboard" />
            <ConfettiClipboardText>All clients filled out their surveys yesterday!</ConfettiClipboardText>
          </CelebrationContainer>
        ) : null
      }
    </>
  );
}

const ConfettiClipboardText = styledStitches("div", {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-25%, -55%)',
  textAlign: 'left',
  fontFamily: 'Feijoa Medium',
  fontStyle: 'normal',
  fontHeight: '500',
  fontSize: '18px',
  lineHeight: '27px',
  color: 'white',
  fontWeight: 500,
});

const CelebrationContainer = styledStitches("div", {
  position: 'relative',
  marginBottom: '18px',
});

const ConfettiChairText = styledStitches("div", {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-25%, -55%)',
  textAlign: 'left',
  fontFamily: 'Feijoa Medium',
  fontStyle: 'normal',
  fontHeight: '500',
  fontSize: '18px',
  lineHeight: '27px',
  fontWeight: 500,
});
