import * as React from "react";
import { useShallowEqualSelector } from "../../../../_helpers/redux";
import { ConsultNoteQuestion } from "../../../../../api/types";
import QuestionTitle from "../shared/QuestionTitle";
import { DemographicPreferencesSubQuestion } from "./DemographicPreferencesSubQuestion";

interface Props {
  question: ConsultNoteQuestion;
  handleEdit: (hasBeenEdited: boolean) => void;
}

export default ({ question, handleEdit }: Props) => {
  const [values] = useShallowEqualSelector((state) => [
    state.consultnote?.openConsult?.consult_note?.therapy_preferences || [],
  ]);

  const partialSlugs = [
    "demo_category_",
    "demo_intensity_",
    "additional_details_demo_",
  ];

  return (
    <div style={{ paddingTop: "15px", paddingBottom: "15px" }}>
      <QuestionTitle
        questionNumber={question.questionNumber}
        titleText={question.title}
        subtitleText={question.subtitle}
        isRequired={question.isRequired}
      />

      {["one", "two", "three"].map((str) => {
        const categoryKey = `${partialSlugs[0]}${str}`;
        const intensityKey = `${partialSlugs[1]}${str}`;
        const additionalDetailsKey = `${partialSlugs[2]}${str}`;

        return (
          <DemographicPreferencesSubQuestion
            key={str}
            values={values}
            intensityKey={intensityKey}
            categoryKey={categoryKey}
            additionalDetailsKey={additionalDetailsKey}
            handleEdit={handleEdit}
          />
        );
      })}
    </div>
  );
};
