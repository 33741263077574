import * as React from "react";
import { debounce } from "lodash-es";
import { CheckOutlined, LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import TextArea from "antd/es/input/TextArea";
import { ModalSection } from "./_shared";

interface NoteTextAreaProps {
  initialText: string;
  updateFn: (noteContent: string) => Promise<any>;
}

export function NoteTextArea(props: NoteTextAreaProps) {
  const [isSaved, setSaved] = React.useState<boolean>(true);
  const [note, setNote] = React.useState<string>(props.initialText);

  const debouncedUpdateFn = React.useCallback(
    debounce((content: string) => {
      props.updateFn(content).then((res) => setSaved(true));
    }, 1500),
    [],
  );

  const onChange = (e: React.FormEvent) => {
    const target = e.target as HTMLInputElement;
    setSaved(false);
    setNote(target.value);
    debouncedUpdateFn(target.value);
  };

  const indicator = isSaved ? (
    <CheckOutlined style={{ fontSize: 16 }} />
  ) : (
    <Spin indicator={spinIcon} />
  );

  return (
    <ModalSection style={{ flex: 1 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingRight: "5px",
        }}
      >
        <h4>Notes</h4>
        {indicator}
      </div>
      <TextArea value={note} onInput={onChange} />
    </ModalSection>
  );
}

const spinIcon = <LoadingOutlined style={{ fontSize: 24 }} spin={true} />;
