import { useShallowEqualSelector } from "../../_helpers/redux";
import { Modal } from "antd";
import React from "react";
import TagsTable from "./TagsTable";
import AddTagForm from "./AddTagForm";
import { useDispatch } from "react-redux";
import { clinicianActions } from "../../../state/models/clinicians";
import { useUserHasAnyPermissions } from "@/app/_helpers/permissions";

export default () => {
  const [tagModalClinicianId, clinician] = useShallowEqualSelector((state) => {
    const tagModalClinicianId = state.clinicians.tagModalClinicianId;
    return [
      tagModalClinicianId,
      tagModalClinicianId === null
        ? null
        : state.clinicians.clinicianMap[tagModalClinicianId],
    ];
  });
  const userCanSeeAddTagForm = useUserHasAnyPermissions([
    "IsClinicalLeader",
    "IsMatchingAdmin",
    "IsSuperUser",
  ]);

  const dispatch = useDispatch();

  const clinicianName =
    clinician === null
      ? ""
      : `- ${clinician.first_name} ${clinician.last_name}`;

  return (
    <Modal
      {...{
        title: `Matchmaker Tags ${clinicianName}`,
        open: tagModalClinicianId !== null,
        width: "900px",
        onCancel: () => dispatch(clinicianActions.setTagModalClinicianId(null)),
        footer: null,
        style: { top: "45px" },
        bodyStyle: { paddingBottom: "5px" },
      }}
    >
      {tagModalClinicianId !== null && (
        <>
          {userCanSeeAddTagForm ? (
            <AddTagForm clinicianId={tagModalClinicianId} />
          ) : null}
          <TagsTable clinicianId={tagModalClinicianId} />
        </>
      )}
    </Modal>
  );
};
