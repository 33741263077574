import { useState } from "react";

import { Modal, Select as DeprecatedAntdSelect, Spin } from "antd";
import { DateTime } from "luxon";
import moment from "moment";
import { useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { $red } from "../../assets/colors";
import { AppState } from "../../state/models";
import ClinicianSelect from "../_shared/FormControls/ClinicianSelect";
import {
  DateTimeRange,
  DateTimeRangePicker,
} from "../_shared/FormControls/DateTimeRangePicker";
import { useAddTimeoffsMutation } from "./api/use-get-timeoff/use-timeoff-mutations";
import { TimeoffsQueryKey } from "./api/use-get-timeoff/use-get-timeoff";
import { PtoTypeChoices } from "./_shared";
import { useUserHasAnyPermissions } from "../_helpers/permissions";

interface Props {
  onClose: () => void;
}

function TimeOffModal(props: Props) {
  const [clinicians, loading] = useSelector((state: AppState) => [
    state.team.clinicians,
    state.team.loading,
  ]);

  const defaultRange = [
    moment().startOf("day").add({ day: 1 }).set({ hour: 8, minute: 0 }),
    moment().startOf("day").add({ day: 1 }).set({ hour: 19, minute: 0 }),
  ] as DateTimeRange;

  const queryClient = useQueryClient();
  const addTimeoffs = useAddTimeoffsMutation();

  const [clinicianIds, setClinicianIds] = useState([] as number[]);
  const [range, setRange] = useState(defaultRange);
  const [reason, setReason] = useState("");
  const [error, setError] = useState("");

  const handleOk = () => {
    if (!range || !reason || clinicianIds.length === 0) {
      setError("Please fill out all of the fields");
      return;
    } else {
      setError("");
    }
    const [startAt, endAt] = range;

    addTimeoffs.mutate(
      {
        clinicianIds,
        startAt: DateTime.fromISO(startAt.format()),
        endAt: DateTime.fromISO(endAt.format()),
        ptoType: reason,
      },
      {
        onSettled: async () => {
          queryClient.invalidateQueries([TimeoffsQueryKey]);
          props.onClose();
        },
      },
    );
  };

  const canUseTrainingHours = useUserHasAnyPermissions([
    "IsTrainingHoursTimeOffUser",
  ]);

  return (
    <Modal
      title="Add Time Off For"
      onOk={handleOk}
      onCancel={props.onClose}
      open
    >
      <Spin spinning={loading}>
        <ClinicianSelect clinicians={clinicians} onChange={setClinicianIds} />
        <div style={{ display: "flex", marginBottom: "15px" }}>
          <DateTimeRangePicker
            defaultValue={defaultRange}
            onChange={setRange}
            minuteStep={30}
          />
        </div>
        <div>Note: Times will be inputted in the clinician's timezone.</div>
        <div
          style={{ display: "flex", alignItems: "center", marginTop: "16px" }}
        >
          <DeprecatedAntdSelect
            onChange={setReason}
            placeholder="Select Reason:"
            style={{ marginLeft: "7.5px", flex: 1 }}
          >
            {Object.entries(PtoTypeChoices).map((type: [string, string]) => (
              <DeprecatedAntdSelect.Option
                key={type[0]}
                value={type[0]}
                disabled={type[0] === "training_hours" && !canUseTrainingHours}
              >
                {type[1]}
              </DeprecatedAntdSelect.Option>
            ))}
          </DeprecatedAntdSelect>
        </div>
        {error ? (
          <div style={{ paddingTop: "15px", color: $red }}>{error}</div>
        ) : null}
      </Spin>
    </Modal>
  );
}

export default TimeOffModal;
