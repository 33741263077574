import moment, { Moment } from "moment";
import React from "react";
import styled from "styled-components";

import { Event } from "../../api/types";
import { $greyBG } from "../../assets/colors";
import { useShallowEqualSelector } from "../_helpers/redux";
import Mangle from "../_shared/Mangle";
import { ISO_WEEKDATE_FORMAT } from "../dashboard/billable-hours/utils";
import { DayOfWeek, SlotEvent, EventCellType, SlotEventType } from "./types";
import { shortenClientName } from "./Cell";
import { timeIsOnOddWeek } from "../_helpers/datetime";
import { CalendarOutlined } from "@ant-design/icons";
import { Tag as AntdTag } from "antd";
import { DisplaySide } from "./cells/types";

interface EventCellPopoverProps {
  eventIds: string[];
  typeId: string;
  day: DayOfWeek;
  hour: string;
  displaySide: DisplaySide;
  eventType: EventCellType | SlotEventType;
}

const EventCellPopoverContent = (props: EventCellPopoverProps) => {
  const { clientMap, coupleMap } = useShallowEqualSelector((state) => ({
    clientMap: state.slottool.clientMap,
    coupleMap: state.slottool.coupleMap,
  }));

  const { eventIds, day, hour, typeId, displaySide, eventType } = props;

  let title;
  if (eventType === "block") {
    title = "Calendar Event";
  } else if (eventType === "open") {
    title = "Open Slot";
  } else if (eventType === "closed") {
    title = "Closed Slot";
  } else if (eventType === "reservation") {
    title = "Manual Reservation";
  } else if (eventType === "therapy") {
    const client = clientMap[typeId];
    title = (
      <Mangle>
        {client.first_name} {client.last_name}
      </Mangle>
    );
  } else {
    const couple = coupleMap[typeId];
    const coupleName = `${couple.client_a_first_name} & ${couple.client_b_first_name}`;
    title = <Mangle>{coupleName}</Mangle>;
  }

  return (
    <PopoverContainer>
      <Title>{title}</Title>
      <WeeksDisplay
        eventIds={eventIds}
        hour={hour}
        day={day}
        displaySide={displaySide}
      />
    </PopoverContainer>
  );
};

interface WeeksDisplayProps {
  eventIds: string[];
  hour: string;
  day: DayOfWeek;
  displaySide: DisplaySide;
}

export function determineWeekMatchesDisplaySide(
  date: Moment,
  displaySide: DisplaySide,
) {
  if (displaySide === "both") {
    return false;
  }

  const isOdd = timeIsOnOddWeek(date);

  if (displaySide === "left") {
    return !isOdd;
  }

  return isOdd;
}

const WeeksDisplay = (props: WeeksDisplayProps) => {
  const { hour, day, eventIds, displaySide } = props;
  const { dateRange, eventMap, eventBuckets, clientMap } =
    useShallowEqualSelector((state) => ({
      dateRange: state.slottool.dateRange,
      eventMap: state.slottool.eventMap,
      eventBuckets: state.slottool.eventBuckets,
      clientMap: state.slottool.clientMap,
    }));

  const allEventIds: string[] = eventBuckets[day][hour]?.map(
    (slotEvent: SlotEvent) => slotEvent.eventId,
  );
  const allEvents = allEventIds.map((eventId) => eventMap[eventId]) || [];
  const weeksToEvents: {
    [isoWeekYear: string]: { moment: Moment; isOwn?: boolean; events: Event[] };
  } = {};

  allEvents.forEach((event) => {
    if (!event) {
      return;
    }

    const { start_time } = event;
    const startTime = moment(start_time);
    const isoStartTime = startTime.format(ISO_WEEKDATE_FORMAT);

    if (!weeksToEvents[isoStartTime]) {
      weeksToEvents[isoStartTime] = {
        moment: startTime,
        events: [event],
        isOwn: eventIds.includes(event.id),
      };
    } else {
      weeksToEvents[isoStartTime] = {
        ...weeksToEvents[isoStartTime],
        events: [...weeksToEvents[isoStartTime].events!, event],
      };
    }
  });

  for (
    let week = moment(dateRange.start).day(day);
    week.isBefore(dateRange.end);
    week.add(1, "week")
  ) {
    const isoWeek = week.format(ISO_WEEKDATE_FORMAT);
    if (weeksToEvents[isoWeek]?.events) {
      continue;
    } else {
      weeksToEvents[isoWeek] = { moment: week, events: [] };
    }
  }

  const sortedKeys = Object.keys(weeksToEvents).sort();

  const weekElements = sortedKeys.map((isoWeekDate) => {
    const dateMoment = moment(isoWeekDate).day(day);
    const events = weeksToEvents[isoWeekDate];
    let eventBadges: Array<JSX.Element | null> = [];

    events.events.forEach((event) => {
      let eventBadge: JSX.Element | null = null;
      if (event && event.event_type !== "block") {
        const client = clientMap[event.client as number];
        eventBadge = (
          <AntdTag>
            <Mangle>{shortenClientName(client)}</Mangle>
          </AntdTag>
        );
      } else if (event.event_type === "block") {
        eventBadge = (
          <AntdTag>
            <CalendarOutlined />
            &nbsp; GCal
          </AntdTag>
        );
      }

      eventBadges.push(eventBadge);
    });

    const weekMatchesDisplaySide = determineWeekMatchesDisplaySide(
      dateMoment,
      displaySide,
    );

    return (
      <WeekContainer
        isOwn={events.isOwn}
        indent={weekMatchesDisplaySide}
        key={dateMoment.format()}
      >
        <div>{dateMoment.format("MMM D")}</div>
        {eventBadges!}
      </WeekContainer>
    );
  });

  return <WeekDisplayContainer>{weekElements}</WeekDisplayContainer>;
};

const WeekDisplayContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${$greyBG};
  min-width: 184px;

  border-radius: 4px;
  padding: 8px 8px;
`;

const WeekContainer = styled.div<{ isOwn?: boolean; indent?: boolean }>`
  margin-bottom: 4px;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.05);
  opacity: 0.5;

  display: flex;
  justify-content: space-between;

  ${(props) =>
    props.isOwn &&
    `
    opacity: 1;
  `}

  ${(props) =>
    props.indent &&
    `
    border: 1px solid rgba(0, 0, 0, 0.4);
  `}
`;

const PopoverContainer = styled.div`
  max-width: 400px;
`;

const Title = styled.h4``;
export default EventCellPopoverContent;
