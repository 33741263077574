import { IconProps } from "../icon.interface";

export const LocationIcon = ({
  fill = "none",
  stroke = "#171717",
  width = 21,
  height = 24,
  title,
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden={!title}
    >
      {title ? <title>{title}</title> : null}
      <path
        d="M19.0242 9.995C19.0242 16.995 10.0242 22.995 10.0242 22.995C10.0242 22.995 1.02417 16.995 1.02417 9.995C1.02417 7.60805 1.97238 5.31886 3.66021 3.63103C5.34804 1.94321 7.63722 0.994995 10.0242 0.994995C12.4111 0.994995 14.7003 1.94321 16.3881 3.63103C18.076 5.31886 19.0242 7.60805 19.0242 9.995Z"
        stroke="#171717"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0242 12.995C11.681 12.995 13.0242 11.6518 13.0242 9.995C13.0242 8.33814 11.681 6.995 10.0242 6.995C8.36732 6.995 7.02417 8.33814 7.02417 9.995C7.02417 11.6518 8.36732 12.995 10.0242 12.995Z"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
