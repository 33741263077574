export const problemOptions = [
  {
    key: "OPTION__none",
    value: "none",
    title: "-",
  },
  {
    key: "OPTION__adhd",
    value: "adhd",
    title: "ADHD",
  },
  {
    key: "OPTION__adjustment",
    value: "adjustment",
    title: "Adjustment",
  },
  {
    key: "OPTION__anxiety_(social_phobia)",
    value: "anxiety_(social_phobia)",
    title: "Anxiety (Social Phobia)",
  },
  {
    key: "OPTION__anxiety_(generalized)",
    value: "anxiety_generalized",
    title: "Anxiety (Generalized)",
  },
  {
    key: "OPTION__anxiety_(panic)",
    value: "anxiety_(panic)",
    title: "Anxiety (Panic)",
  },
  {
    key: "OPTION__anger_management",
    value: "anger_management",
    title: "Anger Management",
  },
  {
    key: "OPTION__attachment",
    value: "attachment",
    title: "Attachment",
  },
  {
    key: "OPTION__autism_spectrum",
    value: "autism_spectrum",
    title: "Autism Spectrum",
  },
  {
    key: "OPTION__bipolar_disorder",
    value: "bipolar_disorder",
    title: "Bipolar Disorder",
  },
  {
    key: "OPTION__body_image",
    value: "body_image",
    title: "Body Image",
  },
  {
    key: "OPTION__chronic_pain_/_illness",
    value: "chronic_pain_/_illness",
    title: "Chronic Pain / Illness",
  },
  {
    key: "OPTION__depression",
    value: "depression",
    title: "Depression",
  },
  {
    key: "OPTION__dysthymia",
    value: "dysthymia",
    title: "Dysthymia",
  },
  {
    key: "OPTION__eating_disorder",
    value: "eating_disorder",
    title: "Eating Disorder",
  },
  {
    key: "OPTION__emotion_regulation",
    value: "emotion_regulation",
    title: "Emotion Regulation",
  },
  {
    key: "OPTION__family_of_origin",
    value: "family_of_origin",
    title: "Family of Origin",
  },
  {
    key: "OPTION__gender/sexual_identity",
    value: "gender/sexual_identity",
    title: "Gender/Sexual Identity",
  },
  {
    key: "OPTION__grief",
    value: "grief",
    title: "Grief",
  },
  {
    key: "OPTION__interpersonal_problems_(general)",
    value: "interpersonal_problems_(general)",
    title: "Interpersonal Problems (General)",
  },
  {
    key: "OPTION__life_purpose",
    value: "life_purpose",
    title: "Life Purpose",
  },
  {
    key: "OPTION__minority_status_experience",
    value: "minority_status_experience",
    title: "Minority Status Experience",
  },
  {
    key: "OPTION__ocd",
    value: "ocd",
    title: "OCD",
  },
  {
    key: "OPTION__parenting",
    value: "parenting",
    title: "Parenting",
  },
  {
    key: "OPTION__perfectionism",
    value: "perfectionism",
    title: "Perfectionism",
  },
  {
    key: "OPTION__psychosomatic_disorder",
    value: "psychosomatic_disorder",
    title: "Psychosomatic Disorder",
  },
  {
    key: "OPTION__psychosis",
    value: "psychosis",
    title: "Psychosis",
  },
  {
    key: "OPTION__ptsd",
    value: "ptsd",
    title: "PTSD",
  },
  {
    key: "OPTION__relationship_problems_(specific)",
    value: "relationship_problems_(specific)",
    title: "Relationship Problems (Specific)",
  },
  {
    key: "OPTION__stress",
    value: "stress",
    title: "Stress",
  },
  {
    key: "OPTION__self_esteem",
    value: "self_esteem",
    title: "Self-Esteem",
  },
  {
    key: "OPTION__sleep",
    value: "sleep",
    title: "Sleep",
  },
  {
    key: "OPTION__substance_abuse",
    value: "substance_abuse",
    title: "Substance Abuse",
  },
  {
    key: "OPTION__trauma_(childhood)",
    value: "trauma_(childhood)",
    title: "Trauma (Childhood)",
  },
  {
    key: "OPTION__trauma_(adulthood)",
    value: "trauma_(adulthood)",
    title: "Trauma (Adulthood)",
  },
  {
    key: "OPTION__trauma_(sexual)",
    value: "trauma_(sexual)",
    title: "Trauma (Sexual)",
  },
  {
    key: "OPTION__other",
    value: "other",
    title: "Other",
  },
];

export const severityOptions = [
  {
    key: "OPTION__rule_out",
    value: "rule_out",
    title: "Rule Out",
  },
  {
    key: "OPTION__mild",
    value: "mild",
    title: "Mild",
  },
  {
    key: "OPTION__mild___moderate",
    value: "mild___moderate",
    title: "Mild - Moderate",
  },
  {
    key: "OPTION__moderate",
    value: "moderate",
    title: "Moderate",
  },
  {
    key: "OPTION__moderate___severe",
    value: "moderate___severe",
    title: "Moderate - Severe",
  },
  {
    key: "OPTION__severe",
    value: "severe",
    title: "Severe",
  },
  {
    key: "OPTION__n/a",
    value: "n/a",
    title: "N/A",
  },
];

export const durationOptions = [
  {
    key: "OPTION__recent_onset_(1_2_months)",
    value: "recent_onset_(1_2_months)",
    title: "Recent onset (1-2 months)",
  },
  {
    key: "OPTION__recent_onset_(3_6_months)",
    value: "recent_onset_(3_6_months)",
    title: "Recent onset (3-6 months)",
  },
  {
    key: "OPTION__long_standing,_no_recent_change",
    value: "long_standing,_no_recent_change",
    title: "Long standing, no recent change",
  },
  {
    key: "OPTION__long_standing,_recent_exacerbation",
    value: "long_standing,_recent_exacerbation",
    title: "Long standing, recent exacerbation",
  },
  {
    key: "OPTION__longstanding___recent_improvement",
    value: "longstanding___recent_improvement",
    title: "Longstanding - recent improvement",
  },
  {
    key: "OPTION__in_remission_with_recent_onset",
    value: "in_remission_with_recent_onset",
    title: "In remission with recent onset",
  },
  {
    key: "OPTION__in_remission",
    value: "in_remission",
    title: "In remission",
  },
  {
    key: "OPTION__assess_further",
    value: "assess_further",
    title: "Assess Further",
  },
  {
    key: "OPTION__n/a",
    value: "n/a",
    title: "N/A",
  },
];
