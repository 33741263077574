import { graphqlClient } from "@/api/graphql";
import { useMatchesByClientIdQuery } from "@/graphql/generated";
import { customSort, checkForAndAppendMedicareAndMedical } from "./_utils";

export const matchesByClinicianIdQueryKey = "matchesByClinicianIdQueryKey";


export const useGetMatchesByClientId = (clientId: number) => {
  const { data, isLoading, refetch } = useMatchesByClientIdQuery(
    graphqlClient,
    {
      clientId: clientId,
    },
    {
      enabled: !!clientId,
      queryKey: [matchesByClinicianIdQueryKey],
      select: (data) => {
        data.matches.sort(customSort);
        data.matches = checkForAndAppendMedicareAndMedical(data.matches);
        return data;
      },
    },
  );

  return { data, isLoading, refetch };
};
