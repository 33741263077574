export const past_present_focus_preference_options = [
  {
    key: "OPTION__no_preference",
    value: "no_preference",
    title: "No Preference / Unsure",
  },
  {
    key: "OPTION__past",
    value: "past",
    title: "Past",
  },
  {
    key: "OPTION__mix_of_past_present",
    value: "mix_of_past_present",
    title: "Mix of Past / Present",
  },
  {
    key: "OPTION__present_future",
    value: "present_future",
    title: "Present / Future",
  },
];

export const past_present_focus_intensity_options = [
  {
    key: "OPTION__slight",
    value: "slight",
    title: "Slight",
  },
  {
    key: "OPTION__moderate",
    value: "moderate",
    title: "Moderate",
  },
  {
    key: "OPTION__strong",
    value: "strong",
    title: "Strong",
  },
  {
    key: "OPTION__na",
    value: "na",
    title: "N/A",
  },
];
