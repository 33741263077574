import create from "zustand";
import {
  BaselineSeverityFilterState,
  CadenceFilterState,
  ClientStatusFilterState,
  MBCMetricsFilterState,
  MBCStatusFilterState,
  MoreFiltersState,
  MyClientsFilters,
  MyClientsSearchState,
  MyClientsSort,
  MyClientsSortState,
  MyClientsState,
  PayerFilterState,
  TotalSessionsFilterState,
  TreatmentTrackFiltersState,
} from "./my-clients.interface";

export const initialClientStatusFilterState = {
  isFilteringProspectiveClients: false,
  isFilteringOnPendingClients: false,
  isFilteringOnNewClients: false,
  isFilteringOnActiveClients: false,
  isFilteringOnInactiveClients: false,
  isFilteringOnGraduatedClients: false,
};

export const initialMBCStatusFilterState = {
  isFilteringOnMBCGraduationClients: false,
  isFilteringOnMBCStagnationClients: false,
  isFilteringOnMBCEscalateClients: false,
  isFilteringOnMBCImprovementClients: false,
};

export const initialCadenceFilterState = {
  isFilteringOnBiweekly: false,
  isFilteringOnWeekly: false,
  isFilteringOnNoCandence: false,
};

export const initialTreatmentTrackFilterState = {
  isFilteringOnGAD7Anxiety: false,
  isFilteringOnPHQ9Depression: false,
  isFilteringOnNoTrack: false,
};

export const initialTotalSessionsFilterState: TotalSessionsFilterState = {
  isFilteringOn0to9Sessions: false,
  isFilteringOn10to16Sessions: false,
  isFilteringOn17to25Sessions: false,
  isFilteringOn25OrMoreSessions: false,
};

export const initialBaselineSeverityFilterState = {
  isFilteringOnSubclinical: false,
  isFilteringOnMildToModerate: false,
  isFilteringOnModerateToSevere: false,
};

export const initialPayerFilterState = {
  isFilteringOnKPNorcal: false,
  isFilteringOnKPWA: false,
  isFilteringOnDTC: false,
  isFilteringOnAetna: false,
};

export const initialMoreFiltersState = {
  ...initialTreatmentTrackFilterState,
  ...initialTotalSessionsFilterState,
  ...initialBaselineSeverityFilterState,
  ...initialPayerFilterState,
};

export const initialMBCMetricsFilterState = {
  isFilteringOnSubclinicalClientsExceeding10Sessions: false,
  isFilteringOnMildToModerateClientsExceeding17Sessions: false,
  isFilteringOnModerateToSevereClientsExceeding25Sessions: false,
};

export const initialFilters: MyClientsFilters = {
  ...initialClientStatusFilterState,
  ...initialMBCStatusFilterState,
  ...initialCadenceFilterState,
  ...initialMoreFiltersState,
  ...initialMBCMetricsFilterState,
};

export const initialMyClientsSortState: MyClientsSort = {
  isSortingByFirstNameAscending: false,
  isSortingByFirstNameDescending: false,
  isSortingByLastNameAscending: false,
  isSortingByLastNameDescending: false,
  isSortingByMostRelevant: true,
  currentlySelectedSort: "Most relevant",
};

export const selectClientStatusFiltersSlice = ({
  isFilteringProspectiveClients,
  isFilteringOnPendingClients,
  isFilteringOnNewClients,
  isFilteringOnActiveClients,
  isFilteringOnInactiveClients,
  isFilteringOnGraduatedClients,
}: ClientStatusFilterState) => ({
  isFilteringProspectiveClients,
  isFilteringOnPendingClients,
  isFilteringOnNewClients,
  isFilteringOnActiveClients,
  isFilteringOnInactiveClients,
  isFilteringOnGraduatedClients,
});

export const selectCadenceFiltersSlice = ({
  isFilteringOnWeekly,
  isFilteringOnBiweekly,
  isFilteringOnNoCandence,
}: CadenceFilterState) => ({
  isFilteringOnWeekly,
  isFilteringOnBiweekly,
  isFilteringOnNoCandence,
});

export const selectPayerFiltersSlice = ({
  isFilteringOnDTC,
  isFilteringOnKPNorcal,
  isFilteringOnKPWA,
  isFilteringOnAetna,
}: PayerFilterState) => ({
  isFilteringOnDTC,
  isFilteringOnKPNorcal,
  isFilteringOnKPWA,
  isFilteringOnAetna,
});

export const selectTotalSessionsFiltersSlice = ({
  isFilteringOn0to9Sessions,
  isFilteringOn10to16Sessions,
  isFilteringOn17to25Sessions,
  isFilteringOn25OrMoreSessions,
}: TotalSessionsFilterState) => ({
  isFilteringOn0to9Sessions,
  isFilteringOn10to16Sessions,
  isFilteringOn17to25Sessions,
  isFilteringOn25OrMoreSessions,
});

export const selectTreatmentTrackFiltersSlice = ({
  isFilteringOnGAD7Anxiety,
  isFilteringOnPHQ9Depression,
  isFilteringOnNoTrack,
}: TreatmentTrackFiltersState) => ({
  isFilteringOnGAD7Anxiety,
  isFilteringOnPHQ9Depression,
  isFilteringOnNoTrack,
});

export const selectMBCStatusFiltersSlice = ({
  isFilteringOnMBCImprovementClients,
  isFilteringOnMBCEscalateClients,
  isFilteringOnMBCGraduationClients,
  isFilteringOnMBCStagnationClients,
}: MBCStatusFilterState) => ({
  isFilteringOnMBCImprovementClients,
  isFilteringOnMBCEscalateClients,
  isFilteringOnMBCGraduationClients,
  isFilteringOnMBCStagnationClients,
});

export const selectBaselineSeverityState = ({
  isFilteringOnSubclinical,
  isFilteringOnMildToModerate,
  isFilteringOnModerateToSevere,
}: BaselineSeverityFilterState) => ({
  isFilteringOnSubclinical,
  isFilteringOnMildToModerate,
  isFilteringOnModerateToSevere,
});

export const selectMBCMetricsFilterState = ({
  isFilteringOnMildToModerateClientsExceeding17Sessions,
  isFilteringOnModerateToSevereClientsExceeding25Sessions,
  isFilteringOnSubclinicalClientsExceeding10Sessions,
}: MBCMetricsFilterState) => ({
  isFilteringOnMildToModerateClientsExceeding17Sessions,
  isFilteringOnModerateToSevereClientsExceeding25Sessions,
  isFilteringOnSubclinicalClientsExceeding10Sessions,
});

export const selectMoreFiltersSlice = ({
  isFilteringOnGAD7Anxiety,
  isFilteringOnPHQ9Depression,
  isFilteringOnNoTrack,
  isFilteringOn0to9Sessions,
  isFilteringOn10to16Sessions,
  isFilteringOn17to25Sessions,
  isFilteringOn25OrMoreSessions,
  isFilteringOnSubclinical,
  isFilteringOnMildToModerate,
  isFilteringOnModerateToSevere,
  isFilteringOnDTC,
  isFilteringOnKPNorcal,
  isFilteringOnKPWA,
  isFilteringOnAetna,
}: MoreFiltersState) => ({
  isFilteringOnGAD7Anxiety,
  isFilteringOnPHQ9Depression,
  isFilteringOnNoTrack,
  isFilteringOn0to9Sessions,
  isFilteringOn10to16Sessions,
  isFilteringOn17to25Sessions,
  isFilteringOn25OrMoreSessions,
  isFilteringOnSubclinical,
  isFilteringOnMildToModerate,
  isFilteringOnModerateToSevere,
  isFilteringOnDTC,
  isFilteringOnKPNorcal,
  isFilteringOnKPWA,
  isFilteringOnAetna,
});

export const selectMyClientsSortSlice = (myClientSortState: MyClientsSort) => ({
  ...myClientSortState,
});

export const selectActiveFiltersSlice = (state: MyClientsState) => {
  const activeMyClientsFilters = Object.keys(initialFilters).filter(
    (filter) => state[filter],
  );

  return { activeMyClientsFilters };
};

export const useMyClientsFilterStore = create<MyClientsState>()((set) => ({
  ...initialFilters,
  setMyClientsFilter: (filter, isSelected) =>
    set(() => ({ [filter]: isSelected })),
  clearMyClientsFilter: (filter) =>
    set(() => {
      if (filter in initialTotalSessionsFilterState) {
        return {
          [filter]: initialFilters[filter],
        };
      }

      return { [filter]: initialFilters[filter] };
    }),
  clearAllMyClientsFilters: () => set(() => ({ ...initialFilters })),
}));

export const useMyClientsSortStore = create<MyClientsSortState>()((set) => ({
  ...initialMyClientsSortState,
  setMyClientsSort: (sortState: Partial<MyClientsSort>) =>
    set((state) => ({ ...state, ...sortState })),

  resetMyClientsSort: () => set(() => ({ ...initialMyClientsSortState })),
}));

const initialState = {
  currentlySelectedClinician: null,
  searchTermState: "",
  clientIds: [],
};

export const useMyClientsSearchStore = create<MyClientsSearchState>()(
  (set) => ({
    ...initialState,
    setCurrentlySelectedClinician: (clinicianId: number | null) =>
      set(() => ({ currentlySelectedClinician: clinicianId })),
    setClientIds: (clientIds) => set(() => ({ clientIds })),
    setSearchTermState: (searchTerm) =>
      set(() => ({ searchTermState: searchTerm })),
    reset: () => set({ ...initialState }),
  }),
);
