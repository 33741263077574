import { EmptyCalendar } from "../../../../../../../../assets/empty-calendar";
import { Flex, Text } from "../../../../../../../design-system";

interface EmptySessionsProps {
  children: React.ReactNode;
}

export const EmptySessions = ({ children }: EmptySessionsProps) => {
  return (
    <Flex
      css={{ flexGrow: 1, pt: 18, pb: 26, height: "100%" }}
      alignItems={"center"}
      flexDirection={"column"}
    >
      <EmptyCalendar />
      <Text color={"$neutral9"}>{children}</Text>
    </Flex>
  );
};
