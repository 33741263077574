import * as React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { Button, Popover } from "antd";
import { Text } from "../design-system";
import moment from "moment";
import SlotTool from ".";
import { $antdBorder } from "../../assets/colors";
import { slotToolActions } from "../../state/models/slot-tool";
import useInterval from "../hooks/useInterval";
import useTitle from "../hooks/useTitle";
import { upsertQueryParam } from "../_helpers";
import { timeIsOnOddWeek } from "../_helpers/datetime";
import { IfPermitted, useUserHasAnyPermissions } from "../_helpers/permissions";
import { useShallowEqualSelector } from "../_helpers/redux";
import DeprecatedClinicianPicker from "../_shared/ClinicianPicker";
import TeamViewChecklist from "./TeamViewChecklist";
import { useGetCliniciansTimezone } from "../api/use-get-clinicians/use-get-clinicians";
import { TIMEZONE } from "../my-clients/utils";
import {
  Timezone,
  TimezoneProvider,
  useTimezoneProvider,
} from "../_shared/TimezoneContext";
import { CurrentUserContext } from "../app.utils";
import { Sidebar } from "./_components/sidebar/sidebar";

export default function SlotToolPage() {
  useTitle("Availability Tool");

  const cuser = React.useContext(CurrentUserContext);

  const { clinicianId } = useShallowEqualSelector((state) => ({
    clinicianId: state.slottool.clinicianId,
  }));

  const { data: selectedClinicianTimezone, isLoading: isLoadingTimeZone } =
    useGetCliniciansTimezone(clinicianId);
  const timezone: Timezone = (selectedClinicianTimezone ||
    TIMEZONE(cuser)) as Timezone;

  return (
    <Container>
      <TimezoneProvider timezone={timezone}>
        <Navbar />
        <ContentContainer>
          {/* hacky: avoid rendering the AT until we fully fetch timezone to avoid double-renders.
          this is really janky but the alternative is worse */}
          {(!isLoadingTimeZone && <SlotTool />) || null}
          <Sidebar />
        </ContentContainer>
      </TimezoneProvider>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow: hidden;
`;

const Navbar = () => {
  const dispatch = useDispatch();
  const timezone = useTimezoneProvider();
  const { setClinicianId } = React.useMemo(
    () => ({
      setClinicianId: (clinicianId: number) => {
        dispatch(slotToolActions.setClinicianId(clinicianId));
      },
    }),
    [dispatch],
  );

  const {
    clinicianId,
    clinicianMap,
    myTeamClinicianIds,
    lastUpdated,
    clinicianVolumeDataMap,
  } = useShallowEqualSelector((state) => ({
    clinicianId: state.slottool.clinicianId,
    clinicianMap: state.clinicians.clinicianMap,
    myTeamClinicianIds: state.auth.myTeamClinicianIds,
    lastUpdated: state.clinicians.lastUpdated,
    clinicianVolumeDataMap: state.slottool.clinicianVolumeDataMap,
  }));

  const updateClinicianId = (id: number) => {
    upsertQueryParam("clinicianId", id.toString(), false);
    setClinicianId(id);
  };

  const canSeeTeamCapacities =
    useUserHasAnyPermissions(["IsSuperUser"]) ||
    (myTeamClinicianIds && myTeamClinicianIds.length > 1);

  const [lastUpdatedString, setLastUpdatedString] = React.useState<string>(
    moment(lastUpdated).fromNow(),
  );

  useInterval(() => setLastUpdatedString(moment(lastUpdated).fromNow()), 30000);

  const thisWeek = moment().startOf("isoWeek");
  const thisWeekEvenOdd = timeIsOnOddWeek(thisWeek) ? "odd" : "even";

  const nextWeek = moment().startOf("isoWeek").add({ week: 1 });
  const nextWeekEvenOdd = timeIsOnOddWeek(nextWeek) ? "odd" : "even";

  const weekOddEvenIndicator = `This week ${thisWeek.format(
    "M/D",
  )} is ${thisWeekEvenOdd}, next week ${nextWeek.format(
    "M/D",
  )} is ${nextWeekEvenOdd}.`;

  return (
    <>
      <NavbarContainer>
        <IfPermitted permissions={["IsClinicalLeader"]} requireAll={false}>
          {canSeeTeamCapacities && (
            <Popover content={<TeamViewChecklist />} trigger="click">
              <Button style={{ marginRight: "8px" }}>Team Capacities</Button>
            </Popover>
          )}
        </IfPermitted>

        {lastUpdated && (
          <Text color={"$neutral11"} fontSize="14">
            Last updated: {lastUpdatedString}. {weekOddEvenIndicator}&nbsp;
          </Text>
        )}
        <Text color={"$neutral11"} fontSize="14" notFlex>
          Times displayed in&nbsp;<b>{timezone}</b>
        </Text>

        <IfPermitted permissions={["IsClinicalLeader"]} requireAll={false}>
          <DeprecatedClinicianPicker<number>
            clinicianMap={clinicianMap}
            setClinicianFn={updateClinicianId}
            value={clinicianId}
            sortFirstValues={myTeamClinicianIds}
            style={{ marginLeft: "auto" }}
          />
        </IfPermitted>
      </NavbarContainer>
      {clinicianId && clinicianVolumeDataMap[clinicianId] ? (
        <NavbarContainer>
          <b>Open Timeslots</b>:{" "}
          {
            clinicianVolumeDataMap[clinicianId].slots_data.open_slots.filter(
              (slot) => slot.recurrence === "weekly",
            ).length
          } weekly,&nbsp;
          {
            clinicianVolumeDataMap[clinicianId].slots_data.open_slots.filter(
              (slot) => slot.recurrence === "biweekly",
            ).length
          } biweekly
        </NavbarContainer>
      ) : null}
    </>
  );
};

const NavbarContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0 12px;
  width: 100%;
  height: 56px;
  background-color: white;
  border-bottom: 1px solid ${$antdBorder};
`;
