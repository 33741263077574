import { CSS_COLORS, Flex, Text, styledStitches } from "@/app/design-system";
import {
  StyledComboboxList,
  StyledComboboxOption,
} from "@/app/design-system/combobox/combox.styled";
import {
  StyledCombobox,
  StyledComboboxInput,
} from "@/app/design-system/combobox/search-combobox/search-combobox.styled";
import { MagnifyingGlassIcon, XIcon } from "@/app/design-system/icons";
import { OVERLAY_ZINDEX } from "@/app/design-system/styles/config/css-constants";
import { ComboboxPopover } from "@reach/combobox";
import { useState } from "react";

const StyledClearSearchButton = styledStitches("button", {
  all: "unset",
  position: "absolute",
  cursor: "pointer",
});

export const StyledClientFilterComboboxPopover = styledStitches(
  ComboboxPopover,
  {
    zIndex: OVERLAY_ZINDEX + 2,
    boxShadow: "0px 8px 8px 0px #00000014",
  },
);

interface SearchBarProps {
  onSearch: (searchText: string) => void;
  updateSelectedClientId: (selectedValue: string) => void;
  clients: Array<any>;
}

export const ClientFilterBar: React.FC<SearchBarProps> = ({
  onSearch,
  updateSelectedClientId,
  clients,
}) => {
  const [searchText, setSearchText] = useState("");
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newText = event.target.value;
    if (newText?.length === 0) {
      updateSelectedClientId("");
    }
    setSearchText(newText);
    onSearch(newText);
  };

  const results = clients.slice(0, 10);

  // The value is unfortunately what is shown in the dropdown, which does not necessarily match the client id
  // I added the email as a fallback in case there are multiple clients with the same name
  const valueKeyMap = results.reduce((acc, client) => {
    acc[client.fullName + client.email] = client;
    return acc;
  }, {} as Record<string, any>);
  return (
    <StyledCombobox
      aria-label="clients"
      openOnFocus
      onClick={() => setIsPopoverOpen(true)}
      onSelect={(val) => {
        setSearchText(valueKeyMap[val] ? valueKeyMap[val].fullName : "");
        setIsPopoverOpen(false);
        onSearch(valueKeyMap[val] ? valueKeyMap[val].fullName : "");
        updateSelectedClientId(valueKeyMap[val].clientId);
      }}
    >
      <StyledComboboxInput
        className="clients-search-input"
        onChange={handleInputChange}
        placeholder="Filter clients"
        value={searchText}
      />

      <MagnifyingGlassIcon className="magnifying-glass" />

      {searchText.length > 0 ? (
        <StyledClearSearchButton
          aria-label="Clear Search"
          className="clear-search-button"
          onClick={() => {
            setSearchText("");
            onSearch("");
            updateSelectedClientId("");
          }}
        >
          <XIcon fill={CSS_COLORS.neutral10} />
        </StyledClearSearchButton>
      ) : null}

      <StyledClientFilterComboboxPopover className="shadow-popup">
        {!!isPopoverOpen && results.length > 0 ? (
          <StyledComboboxList>
            {results.map((client) => (
              <StyledComboboxOption
                key={client.clientId}
                value={client.fullName + client.email}
              >
                <div>
                  <Text>{client.fullName}</Text>
                </div>

                <>
                  <Text fontSize={12} color={"$neutral11"}>
                    {client.email}
                  </Text>
                </>
              </StyledComboboxOption>
            ))}
          </StyledComboboxList>
        ) : (
          <StyledComboboxList>
            <Flex css={{ px: 12, py: 8, color: "$neutral11" }}>
              No results found
            </Flex>
          </StyledComboboxList>
        )}
      </StyledClientFilterComboboxPopover>
    </StyledCombobox>
  );
};
