import { Tag as AntdTag, Button } from "antd";
import React from "react";
import styled from "styled-components";

import { $secondary, $border, $whiteHover } from "../../assets/colors";
import { useShallowEqualSelector } from "../_helpers/redux";
import { getClinicianCapacities } from "../_shared/ClinicianStatus";
import { useDispatch } from "react-redux";
import { slotToolActions } from "../../state/models/slot-tool";
import { clinicianListSelector } from "../../state/models/clinicians";
import { useUserHasAnyPermissions } from "../_helpers/permissions";
import { upsertQueryParam, UtilizationStatus } from "../_helpers";

const TeamViewChecklist = () => {
  const { clinicians, clinicianMap, myTeamClinicianIds } =
    useShallowEqualSelector((state) => ({
      clinicians: clinicianListSelector(state.clinicians),
      clinicianMap: state.clinicians.clinicianMap,
      myTeamClinicianIds: state.auth.myTeamClinicianIds,
    }));

  // since we're deriving the values from state, we create copies,
  // then sort them since state is immutable. otherwise we get this error:
  // TypeError: Cannot assign to read only property '0' of object '[object Array]'

  const cliniciansCopy = [...clinicians];
  const myTeamClinicianIdsCopy = [...myTeamClinicianIds];

  const content = useUserHasAnyPermissions(["IsSuperUser", "IsMatchingAdmin"])
    ? cliniciansCopy
        .sort((a, b) => a.first_name.localeCompare(b.first_name))
        .sort((a, b) => {
          try {
            return a.clinic_ids[0] - b.clinic_ids[0];
          } catch {
            return 0;
          }
        })
        .map((c) => <ClinicianRow clinicianId={c.id} key={c.id} />)
    : myTeamClinicianIdsCopy
        .sort((a, b) =>
          clinicianMap[a]?.first_name.localeCompare(
            clinicianMap[b]?.first_name,
          ),
        )
        .map((id) => <ClinicianRow clinicianId={id} key={id} />);

  return (
    <Container>
      <Table>
        <thead>
          <tr>
            <th style={{ paddingBottom: "16px" }}>Clinician</th>
            <th style={{ paddingBottom: "16px" }} colSpan={2}>
              Capacity
            </th>
            <th></th>
            <th style={{ paddingBottom: "16px", textAlign: "center" }}>
              Actions
            </th>
          </tr>
        </thead>
        <tbody>{content}</tbody>
      </Table>
    </Container>
  );
};

const ClinicianRow = (props: { clinicianId: number }) => {
  const dispatch = useDispatch();
  const { clinicianMap, getClinicianCapacity } = useShallowEqualSelector(
    (state) => ({
      clinicianMap: state.clinicians.clinicianMap,
      getClinicianCapacity: getClinicianCapacities(state),
    }),
  );

  const clinician = clinicianMap[props.clinicianId];
  const capacity = getClinicianCapacity(props.clinicianId);

  if (!clinician) {
    return null;
  }

  const tags = [];
  let hasManual = false;
  if (
    !capacity.manualCapacity &&
    capacity.manualCapacity !== capacity.autoCapacity
  ) {
    tags.push(<AntdTag color={$secondary}>Manual</AntdTag>);
    hasManual = true;
  }

  return (
    <RowContainer
      key={clinician.id}
      onClick={() => {
        upsertQueryParam("clinicianId", props.clinicianId.toString(), false);
        dispatch(slotToolActions.setClinicianId(props.clinicianId));
      }}
    >
      <td style={{ paddingRight: "16px", paddingLeft: "8px" }}>
        {clinician.first_name} {clinician.last_name}
      </td>
      {capacity.status === UtilizationStatus.Hold ? (
        <td colSpan={2} style={{ textAlign: "center" }}>
          <b>HOLD</b>
        </td>
      ) : (
        <>
          <td style={{ textAlign: "right" }}>
            {hasManual
              ? capacity.manualCapacity
              : capacity.autoCapacity && Math.floor(capacity.autoCapacity)}
          </td>
          <AutoCapacity overridden={hasManual}>
            {hasManual ? capacity.autoCapacity : ""}
          </AutoCapacity>
        </>
      )}
      <td style={{ padding: "0 16px" }}>{tags}</td>
      <td>
        <Button type="link">Jump To</Button>
      </td>
    </RowContainer>
  );
};

const Container = styled.div`
  max-height: 80vh;
  overflow-y: scroll;
`;

const Table = styled.table`
  border-spacing: 0 0.75em;
`;
const RowContainer = styled.tr`
  margin: 8px 0;
  border-bottom: 1px solid ${$border};
  border-top: 1px solid ${$border};
  border-collapse: collapse;

  &:hover {
    background-color: ${$whiteHover};
  }

  > td {
    padding-top: 8px;
    padding-bottom: 4px;
  }
`;
const AutoCapacity = styled.td<{ overridden?: boolean }>`
  ${(props) =>
    props.overridden &&
    `
    text-decoration: line-through;
    color: rgba(204, 204, 204);
  `}
`;

export default TeamViewChecklist;
