import { graphqlClient } from "../../../../api/graphql";
import {
  useGetClientAssessmentScoresQuery,
  GetClientAssessmentScoresQuery,
} from "../../../../graphql/generated";

interface UseSearchClients<T extends {}> {
  clientId: number;
}

export const useGetClientAssessments = <T extends {}>({
  clientId,
}: UseSearchClients<T>) => {
  const { data, isLoading } = useGetClientAssessmentScoresQuery<T>(
    graphqlClient,
    { client_id: clientId },
  );

  return { data, isLoading };
};
