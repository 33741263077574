import { useGetTags } from "@/app/api/use-get-tags/use-get-tags";
import { useNotification, Notification } from "@/app/design-system";
import { Ehr_Tag } from "@/graphql/generated";
import { Select as DeprecatedAntdSelect } from "antd";
import { SelectValue } from "antd/es/select";
import { differenceBy, forEach, omitBy, uniqBy } from "lodash-es";
import { useEffect, useState } from "react";
import { Tag } from "../../../api/types";
import {
  alphabetizeBy,
  bucketSort,
  isMultiNumberValue,
  toMap,
} from "../../../app/_helpers";

type SelectTag = Omit<Ehr_Tag, "created_at" | "modified_at">;

interface Props {
  selectTagMap: (tagMap: { [id: number]: Tag }) => void;
  selectedTags: Array<Tag | SelectTag>;
  excludedTags?: Array<Tag | SelectTag>;
  isCouplesMatch: boolean;
  omitClinicalStyles?: boolean;
  variant: "benefits" | "needs";
  tagsVersion: 2 | 3;
}

export default function TagSelect(props: Props) {
  const { selectTagMap, selectedTags, excludedTags, isCouplesMatch } = props;
  const [selectedTagMap, setSelectedTagMap] = useState<{ [id: number]: Tag }>(
    toMap(selectedTags),
  );
  const { data, isLoading: isGetTagsLoading } = useGetTags();
  const { renderNotification } = useNotification();

  let tags = data?.ehr_tag
    .filter((t) => t.version === props.tagsVersion)
    .filter(
      (tag) =>
        tag.shown_in_matchtool &&
        (isCouplesMatch ? tag.used_for_couples : tag.used_for_individual),
    );

  if (props.variant === "needs") {
    tags = tags?.filter((t) => t.bucket !== "Clinical Style");
  }

  if (props.tagsVersion === 3) {
    if (props.variant === "benefits") {
      tags = tags?.filter(
        (t) => t.bucket !== "Presenting Concern" && t.bucket !== "Focus Areas",
      );
    }
  }

  // remove tags that don't match the selected version
  useEffect(() => {
    setSelectedTagMap(
      omitBy(
        selectedTagMap,
        (t: Tag | SelectTag) => t.version !== props.tagsVersion,
      ),
    );
  }, [selectTagMap]);

  useEffect(
    () => {
      selectTagMap(selectedTagMap);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedTagMap],
  );

  const setTagIds = (bucket: string, tagIds: number[]) => {
    const newSelectedTagMap = omitBy(
      selectedTagMap,
      (tag: Tag | SelectTag) => tag.bucket === bucket,
    );
    forEach(tagIds, (id: number) => {
      if (tags) {
        const tag = tags.find((t) => t.id === id);
        if (tag) {
          newSelectedTagMap[id] = tag as Tag;
        }
      }
    });
    setSelectedTagMap(newSelectedTagMap);
  };
  const onTagSelect = (bucket: string) => (ids: SelectValue) => {
    if (isMultiNumberValue(ids)) {
      // don't allow adding more than 4 presenting concerns, but allow removing em
      if (
        bucket === "Presenting Concern" &&
        ids.length > 4 &&
        ids.length > // if draft ids is greater than existing selected tags; ie, we're adding a tag
          Object.values(selectedTagMap).filter(
            (t: Tag | SelectTag) => t.bucket === bucket,
          ).length
      ) {
        renderNotification({
          message: "Presenting concerns is limited to 4 tags maximum.",
          notificationType: "error",
        });
        return;
      }
      setTagIds(bucket, ids);
    } else {
      throw new Error("Expected a number array");
    }
  };
  const defaultShownTagsValue = excludedTags
    ? differenceBy(tags, excludedTags, "id")
    : tags;
  const shownTags = defaultShownTagsValue ?? [];
  const buckets = uniqBy(tags, "bucket").map((t) => t.bucket);
  return (
    <>
      {buckets.sort(bucketSort).map((bucket) => (
        <div key={bucket} style={{ marginBottom: "10px" }}>
          <strong>{bucket}</strong>
          <DeprecatedAntdSelect
            mode="multiple"
            style={{ width: "100%" }}
            placeholder="Start typing..."
            onChange={onTagSelect(bucket)}
            optionFilterProp="title"
            value={selectedTags
              .filter((t) => t.bucket === bucket)
              .map((t) => t.id)}
          >
            {shownTags
              .filter((t) => t.bucket === bucket)
              .sort((a: any, b: any) => alphabetizeBy(a, b, "name"))
              .sort((a: any, b: any) => alphabetizeBy(a, b, "category"))
              .map((t) => {
                return (
                  <DeprecatedAntdSelect.Option
                    key={`${t.id}`}
                    value={t.id}
                    title={`${t.category} - ${t.name}`}
                  >
                    {t.category} - <strong>{t.name}</strong>
                  </DeprecatedAntdSelect.Option>
                );
              })}
          </DeprecatedAntdSelect>
        </div>
      ))}
      <Notification />
    </>
  );
}
