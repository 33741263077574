import { styledStitches } from "@/app/design-system/styles/stitches.config";
import * as SeparatorPrimitive from "@radix-ui/react-separator";

const StyledSeparator = styledStitches(SeparatorPrimitive.Root, {
  backgroundColor: "$neutral7",
  "&[data-orientation=horizontal]": { height: 1, width: "100%" },
  "&[data-orientation=vertical]": { height: "100%", width: 1 },

  variants: {
    width: {
      2: {
        "&[data-orientation=vertical]": { height: "100%", width: 2 },
      },
    },
    height: {
      "70%": {
        height: "70%",
      },
    },
    backgroundColor: {
      $neutral12: {
        backgroundColor: "$neutral12",
      },
    },
    marginX: {
      1: {
        marginLeft: 1,
        marginRight: 1,
      },
      2: {
        marginLeft: 2,
        marginRight: 2,
      },
    },
    marginY: {
      1: {
        marginTop: 1,
        marginBottom: 1,
      },
      2: {
        marginTop: 2,
        marginBottom: 2,
      },
    },
  },

  compoundVariants: [
    {
      backgroundColor: "$neutral12",
      height: "70%",
      css: {
        "&[data-orientation=vertical]": { height: "70%", width: 1 },
        backgroundColor: "$neutral12",
      },
    },
  ],
});

export const Separator = StyledSeparator;
