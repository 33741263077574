import { ExportOutlined } from "@ant-design/icons";
import * as React from "react";
import { CSSProperties } from "styled-components";
import { styledStitches } from "../design-system";
import { FOCUS_STYLES } from "../design-system/styles/config/css-utils";
import Mangle from "./Mangle";

interface Props {
  href: string;
  children?: React.ReactNode;
  style?: CSSProperties | undefined;
  textStyle?: CSSProperties | undefined;
}

/**
 * @component ExternalLink
 * @summary We want to inform users whenever a link opens in a new tab
 * @description We want to inform users whenever a link opens in a new tab. New tabs can be disorienting for users, especially those users with vision-related disabilities.
 * This is a best practice. 
 * 
 * Visit the WCAG Techniques ["Opening new windows and tabs from a link only when necessary"
](https://www.w3.org/WAI/WCAG21/Techniques/general/G200) or ["Using the target attribute to open a new window on user request and indicating this in link text"
](https://www.w3.org/WAI/WCAG21/Techniques/html/H83) for more details
 */

const ExternalLink = (props: Props) => {
  return (
    <a
      href={props.href}
      target="_blank"
      rel="noopener noreferrer"
      style={props.style}
    >
      <span style={props.textStyle}>{props.children}</span>{" "}
      <ExportOutlined aria-label="(opens in a new tab)" />
    </a>
  );
};
export default ExternalLink;

export const MangledStyledExternalLink = (props: Props) => {
  return (
    <FocusStyledExternalLinkAnchor
      href={props.href}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Mangle>
        <span style={props.textStyle}>{props.children}</span>{" "}
        <ExportOutlined aria-label="(opens in a new tab)" />
      </Mangle>
    </FocusStyledExternalLinkAnchor>
  );
};

const FocusStyledExternalLinkAnchor = styledStitches("a", {
  ...FOCUS_STYLES,
});
