import { IconProps } from "../icon.interface";

export const LeftChevronIcon = ({
  className,
  fill = "#171717",
  height = 24,
  width = 24,
  title,
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      className={className}
      aria-hidden={!title}
    >
      {title ? <title>{title}</title> : null}
      <path
        d="M13.9684 18.4691C14.359 18.8596 14.9921 18.8596 15.3826 18.4691C15.7732 18.0785 15.7732 17.4454 15.3826 17.0549L13.9684 18.4691ZM8.67554 11.762L7.96843 11.0549C7.57791 11.4454 7.57791 12.0785 7.96843 12.4691L8.67554 11.762ZM15.3826 6.46907C15.7732 6.07855 15.7732 5.44538 15.3826 5.05486C14.9921 4.66433 14.359 4.66433 13.9684 5.05486L15.3826 6.46907ZM15.3826 17.0549L9.38264 11.0549L7.96843 12.4691L13.9684 18.4691L15.3826 17.0549ZM9.38264 12.4691L15.3826 6.46907L13.9684 5.05486L7.96843 11.0549L9.38264 12.4691Z"
        fill={fill}
      />
    </svg>
  );
};
