export const NEEDS_TAG_WEIGHT = 100;
export const BENEFIT_TAG_WEIGHT = 1;
export type TagWeight = 1 | 100;

export const EXPERT_TAG_WEIGHT = 4;
export type ClinicianTagWeight = -1 | 1 | 2 | 4;

(window as any).global = window;

export async function sprigProxy(...args: any): Promise<any> {
  try {
    const sprig = window.Sprig;
    return (sprig as any)(...args)
  } catch {
    console.debug("No Sprig object found.")
  }
}
declare global {
  interface Window {
    ENVIRONMENT?: "DEV" | "REVIEW" | "STAGING" | "PROD";
    RELEASE?: string;
    VITE_API_SERVER?: string;
    VITE_GOOGLE_CLIENT_ID?: string;
    VITE_GOOGLE_TAG_MANAGER_ID: string;
    Sprig?: any;
  }

  interface ImportMeta {
    env: {
      VITE_API_SERVER: string;
      VITE_GOOGLE_CLIENT_ID: string;
      VITE_GOOGLE_TAG_MANAGER_ID: string;
      NODE_ENV: string;
      PUBLIC_URL: string;
    };
  }
}

export const API_URL =
  window.VITE_API_SERVER || import.meta.env.VITE_API_SERVER;

export const GOOGLE_CLIENT_ID =
  window.VITE_GOOGLE_CLIENT_ID || import.meta.env.VITE_GOOGLE_CLIENT_ID;

export const GOOGLE_TAG_MANAGER_ID =
  window.VITE_GOOGLE_TAG_MANAGER_ID ||
  import.meta.env.VITE_GOOGLE_TAG_MANAGER_ID;
