import React from "react";
import { Button, Flex, Stack } from "../../../../../../../../design-system";
import { ComposedDropdownMenuTrigger } from "../../../../../../../../design-system/dropdown/dropdown.composed";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuPrimitiveItem,
} from "../../../../../../../../design-system/dropdown/dropdown.styled";
import { TreatmentTrackDropdownMenu } from "./1-treatment-track-filter-dropdown/treatment-track-dropdown-menu";
import { TotalSessionsDropdownMenu } from "./2-total-sessions-filter-dropdown/total-sessions-dropdown-menu";
import { BaselineSeverityFilterDropdownMenu } from "./3-baseline-severity-filter-dropdown/baseline-severity-dropdown-menu";
import { PayerFilterDropdownMenu } from "./4-payer-filter-dropdown/payer-filter-dropdown-menu";
import { useMoreFiltersDropdown } from "./use-more-filters";

export const MoreFiltersDropdown = () => {
  const { isDropdownOpen, setIsDropdownOpen, clearMoreFilters } =
    useMoreFiltersDropdown();

  return (
    <DropdownMenu open={isDropdownOpen}>
      <ComposedDropdownMenuTrigger
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        More
      </ComposedDropdownMenuTrigger>

      <DropdownMenuContent
        sideOffset={5}
        align={"start"}
        loop
        onInteractOutside={() => setIsDropdownOpen(false)}
      >
        <Stack gap={8}>
          <Stack>
            <TreatmentTrackDropdownMenu />
            <TotalSessionsDropdownMenu />
            <BaselineSeverityFilterDropdownMenu />
            <PayerFilterDropdownMenu />
          </Stack>

          <Flex
            justifyContent={"space-between"}
            css={{ borderTop: "1px solid $neutral3", pt: 10, pl: 0 }}
          >
            <DropdownMenuPrimitiveItem css={{ fontSize: 14 }} asChild>
              <Button
                variant={"tertiary"}
                size={"medium"}
                onClick={() => {
                  setIsDropdownOpen(false);
                  clearMoreFilters();
                }}
              >
                Clear all
              </Button>
            </DropdownMenuPrimitiveItem>
          </Flex>
        </Stack>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
