import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { CloseOutlined } from "@ant-design/icons";

import { Button, Divider, Tooltip } from "antd";
import moment from "moment";
import { ExtendedMatch } from "../../../../api/types";
import { matchOperations } from "../../../../state/models/matches";
import { matchmakerOperations } from "../../../../state/models/matchmaker";
import { RequeueReasonKeys, requeueReasons } from "../../../_helpers";
import { useShallowEqualSelector } from "../../../_helpers/redux";
import { Row } from "../../../_layout/Flex";

import {
  Spacer,
  styledStitches,
  Text,
  TextArea,
  useNotification,
} from "@/app/design-system";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/app/design-system/popover/popover.styled";
import {
  ComposedRadixUISelect,
  ComposedSelectRadixUIOption,
} from "@/app/design-system/select-radixui/composed-select-radixui";
import {
  createRadixSelectOptions,
  createRadixSelectOption,
} from "@/app/design-system/select-radixui/utils";
interface Props {
  extendedMatch: ExtendedMatch;
  actions: string[];
}

const RematchSearch = styledStitches("div", {
  border: "1px solid $neutral11",
  minWidth: "fitContent",
  padding: "16px 24px",
  marginBottom: "50px",
});

const Footer = ({ extendedMatch, actions }: Props) => {
  const { renderNotification } = useNotification();

  const dispatch = useDispatch();
  const openMatchModal = () =>
    extendedMatch.couple
      ? dispatch(
          matchmakerOperations.openCouplesMatchmaker({
            match: null,
            rematch: extendedMatch,
            consult: null,
          }),
        )
      : dispatch(
          matchmakerOperations.openMatchmaker({
            match: null,
            rematch: extendedMatch,
            consult: null,
          }),
        );

  const [requeueReason, setRequeueReason] =
    useState<RequeueReasonKeys | null>();
  const [requeueReasonOther, setRequeueReasonOther] = useState("");
  const showRequeueReasonOther =
    requeueReason === "client-initiated--other" ||
    requeueReason === "clinician-initiated--other";
  const requeueMatch = () => {
    if (!requeueReason) {
      return;
    }

    if (showRequeueReasonOther && !requeueReasonOther) {
      renderNotification({
        message: "Please provide additional context for the requeue.",
        notificationType: "error",
      });
      return;
    }

    dispatch(
      matchOperations.requeueMatch(
        extendedMatch.id,
        requeueReason,
        removeSelectedFit,
        requeueReasonOther,
      ),
    );
    setPopoverVisible(false);
    setRequeueReason(null);
  };

  const Actions: JSX.Element[] = [];

  if (extendedMatch.match_email_sent) {
    const sentTime = moment(extendedMatch.match_email_sent);
    Actions.push(
      <span key={"matchEmailTimestamp"} style={{ marginRight: "auto" }}>
        {sentTime.format("[Match email sent] M/D [at] h:mm a")}
      </span>,
    );
  }

  if (actions.includes("rematch")) {
    Actions.push(
      <Button key={"rematch"} onClick={openMatchModal}>
        Rematch
      </Button>,
    );
  }

  const [popoverVisible, setPopoverVisible] = useState(false);

  const removeSelectedFit: boolean = useMemo(
    () =>
      Boolean(
        requeueReasons.find(
          (reason) => reason.key === requeueReason && reason.removeSelectedFit,
        ),
      ),
    [requeueReason],
  );
  const radixRequeueReasons: Array<ComposedSelectRadixUIOption> =
    requeueReasons.map((reason) => {
      return createRadixSelectOption({
        id: reason.key,
        value: reason.key,
        label: reason.value,
      });
    });

  if (actions.includes("requeue")) {
    Actions.push(
      <div key={"requeue"}>
        <Popover open={popoverVisible} onOpenChange={setPopoverVisible}>
          <PopoverTrigger asChild>
            <Button style={{ marginLeft: "5px" }}>Reject & Requeue</Button>
          </PopoverTrigger>
          <PopoverContent
            sideOffset={50}
            align="start"
            alignOffset={-400}
            side="top"
          >
            <RematchSearch>
              <Row layout="space-between center">
                <Text>Select a Reason</Text>
                <Button onClick={() => setPopoverVisible(false)}>
                  <CloseOutlined />
                </Button>
              </Row>
              <Spacer y={24} />
              <Row layout="start center">
                <ComposedRadixUISelect
                  options={radixRequeueReasons}
                  value={requeueReason as string}
                  onChange={(value) => {
                    setRequeueReason(value as RequeueReasonKeys);
                    setRequeueReasonOther("");
                  }}
                  selectLabel="Requeue Reason"
                ></ComposedRadixUISelect>
              </Row>
              {showRequeueReasonOther && (
                <>
                  <Spacer y={24} />
                  <Text>
                    Please provide a reason for this requeue.
                    <Text color="$red11" notFlex>
                      *
                    </Text>
                  </Text>
                  <Spacer y={8} />
                  <TextArea
                    style={{
                      padding: "8px 12px",
                      minWidth: "80%",
                    }}
                    defaultValue={requeueReasonOther}
                    onChange={(t) => setRequeueReasonOther(t.target.value)}
                  ></TextArea>
                </>
              )}
              <Spacer y={24} />
              <Row layout="end center">
                <Button
                  type="primary"
                  style={{ marginLeft: "5px" }}
                  onClick={requeueMatch}
                >
                  Ok
                </Button>
              </Row>
              {removeSelectedFit && (
                <div style={{ fontSize: "10px" }}>
                  <i>
                    Note: Selecting this Requeue Reason will remove the initial
                    selected clinician from the Fit List.
                  </i>
                </div>
              )}
            </RematchSearch>
          </PopoverContent>
        </Popover>
      </div>,
    );
  }

  return (
    <>
      <Divider style={{ margin: 0 }} />
      <Row layout={"flex-end center"} style={{ padding: "15px" }}>
        {Actions}
      </Row>
    </>
  );
};

export default Footer;
