import { Flex, Text } from "@/app/design-system";
import {
  StyledScoreCard,
  StyledScore,
  StyledScoreSubText,
  StyledScoreAndBaselineCard,
  IconContainer,
  ScoreBoxContainer,
} from "./score-overview.styled";
import { ArrowUpIcon, ArrowDownIcon } from "@/app/design-system/icons";

const GREEN = "#30A46C";
const RED = "#CD2B31";

interface ScoreOverviewProps {
  scoreName: string;
  score?: number | null;
  baseline?: number | null;
  isFirstSession: boolean;
  isCheckInCompletion: boolean;
  totalResponded?: number | null;
  totalSent?: number | null;
}

export enum ScoreNames {
  phq = "PHQ-9",
  gad = "GAD-7",
  cohesion = "Cohesion",
}

export const ScoreOverview = ({
  scoreName,
  score,
  baseline,
  isFirstSession,
  isCheckInCompletion,
  totalResponded,
  totalSent,
}: ScoreOverviewProps) => {
  if (isCheckInCompletion || isFirstSession || (!!baseline && !score)) {
    return (
      <Score
        scoreName={scoreName}
        isCheckInCompletion={isCheckInCompletion}
        baseline={baseline}
        totalResponded={totalResponded}
        totalSent={totalSent}
      />
    );
  } else {
    return (
      <ScoreAndBaseline
        scoreName={scoreName}
        score={score}
        baseline={baseline}
      />
    );
  }
};

interface ScoreProps {
  scoreName: string;
  isCheckInCompletion: boolean;
  baseline?: number | null;
  totalResponded?: number | null;
  totalSent?: number | null;
}

export const Score = ({
  scoreName,
  isCheckInCompletion,
  baseline,
  totalResponded,
  totalSent,
}: ScoreProps) => {
  const title = isCheckInCompletion
    ? scoreName
    : `Avg Group ${ScoreNames[scoreName]}`;
  const score = isCheckInCompletion
    ? `${totalResponded}/${totalSent}`
    : baseline?.toFixed(1);
  const subText = isCheckInCompletion
    ? "Completed this week"
    : "This week - Baseline";

  if (isCheckInCompletion && (!totalResponded || !totalSent)) {
    return null;
  }
  return (
    <div style={{ paddingRight: 15 }}>
      <StyledScoreCard flexDirection={"column"} css={{ alignItems: "stretch" }}>
        <Flex
          css={{
            borderBottom: "0.5px solid $neutral6",
            padding: 8,
            justifyContent: "center",
          }}
        >
          <Text fontSize={14}>{title}</Text>
        </Flex>
        <StyledScore fontWeight={"700"} css={{ fontSize: 24 }}>
          {score}
        </StyledScore>
        <StyledScoreSubText>{subText}</StyledScoreSubText>
      </StyledScoreCard>
    </div>
  );
};

interface ScoreAndBaselineProps {
  scoreName: string;
  score?: number | null;
  baseline?: number | null;
}

export const ScoreAndBaseline = ({
  scoreName,
  score,
  baseline,
}: ScoreAndBaselineProps) => {
  const title = `Avg Group ${ScoreNames[scoreName]}`;
  const weeksScore = score?.toFixed(1);
  const baselineScore = baseline?.toFixed(0);

  return (
    <div style={{ paddingRight: 15 }}>
      <StyledScoreAndBaselineCard>
        <Flex
          justifyContent={"center"}
          css={{
            borderBottom: "0.5px solid $neutral6",
            padding: 8,
          }}
        >
          <Text fontSize={14}>{title}</Text>
        </Flex>
        <Flex
          css={{
            alignItems: "stretch",
          }}
        >
          <ScoreBoxContainer>
            <StyledScore fontWeight={"700"} css={{ fontSize: 24 }}>
              {weeksScore}
            </StyledScore>
            <StyledScoreSubText>This Week</StyledScoreSubText>
          </ScoreBoxContainer>
          <ScoreBoxContainer css={{ borderLeft: "0.5px solid $neutral6" }}>
            <ScoreChange
              score={score}
              baselineScore={baseline}
              scoreName={scoreName}
            />
            <StyledScoreSubText color={"$neutral9"}>
              Baseline: {baselineScore}
            </StyledScoreSubText>
          </ScoreBoxContainer>
        </Flex>
      </StyledScoreAndBaselineCard>
    </div>
  );
};

interface ScoreChangeProps {
  score: number | null | undefined;
  baselineScore: number | null | undefined;
  scoreName: string;
}

const ScoreChange = ({ score, baselineScore, scoreName }: ScoreChangeProps) => {
  if (!baselineScore || !score) {
    return null;
  }
  const scoreDiff = score - baselineScore;
  const scoreToDisplay = Math.abs(scoreDiff).toFixed(1);

  const hasScoreIncreasedSinceLastSession = scoreDiff >= 0 ? true : false;
  let strokeColor;
  if (scoreName === "phq" || scoreName === "gad") {
    // for phq and gad, improvement is negative
    strokeColor = scoreDiff > 0 ? RED : GREEN;
  } else {
    // for cohesion, improvement is positive
    strokeColor = scoreDiff >= 0 ? GREEN : RED;
  }
  return (
    <Flex justifyContent={"center"}>
      <IconContainer>
        {hasScoreIncreasedSinceLastSession ? (
          <ArrowUpIcon stroke={strokeColor} />
        ) : (
          <ArrowDownIcon stroke={strokeColor} />
        )}
      </IconContainer>
      <StyledScore
        fontWeight={"700"}
        css={{ fontSize: 24, color: strokeColor }}
      >
        {scoreToDisplay}
      </StyledScore>
    </Flex>
  );
};
