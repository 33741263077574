import { graphqlClient } from "../../../../api/graphql";
import {
  MatchingInfoByClientIdsQuery,
  useMatchingInfoByClientIdsQuery,
} from "../../../../graphql/generated";
import { FIVE_MINUTES } from "../../../_helpers/constants";

interface UseMatchingInfoByClientsParams<T extends object> {
  clientIds: number[];
  select?: (data: MatchingInfoByClientIdsQuery) => T;
  enabled: boolean;
  fromStartTime: string;
}

export const useMatchingInfoByClientIds = <T extends object>({
  clientIds,
  select,
  enabled,
}: // fromStartTime,
UseMatchingInfoByClientsParams<T>) => {
  return useMatchingInfoByClientIdsQuery<T>(
    graphqlClient,
    { client_ids: clientIds },

    {
      staleTime: FIVE_MINUTES,
      select,
      enabled,
    },
  );
};
