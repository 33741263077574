import { Stack } from "@/app/design-system";
import { MBCStatus, mbcTrackStatusConfig } from "../../utils";
import { CurrentUserContext } from "@/app/app.utils";
import React, { useContext } from "react";
import { Permissions } from "@/app/_helpers/permissions";


export const MBCStatusKey = () => {
  const currentUser = useContext(CurrentUserContext);

  return (
    <Stack
      css={{
        backgroundColor: "$neutral2",
        maxWidth: 260,
        p: 16,
        borderRadius: 8,
        ml: 22,
        "@breakpoint1200": {
          display: "none",
        },
      }}
    >
      <p
        style={{
          color: "#8C8C8C",
          textTransform: "uppercase",
          fontSize: 12,
          fontWeight: 400,
          lineHeight: "24px",
        }}
      >
        Status Key
      </p>
      <Stack gap={8}>
        {Object.keys(MBCStatus).map((status) => {
          if (status === "reliableChange") return null;
          if (status === "graduate" && Permissions.IsCareAlertBetaUser(currentUser)) return null;
          if (status === "remission" && !Permissions.IsCareAlertBetaUser(currentUser
          )) return null;
          const { color, description, border } = mbcTrackStatusConfig(
            MBCStatus[status],
          );
          return (
            <div
              key={status}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                marginBottom: 4,
              }}
            >
              <div
                style={{
                  minHeight: 18,
                  minWidth: 18,
                  marginRight: 12,
                  marginLeft: 4,
                  backgroundColor: color,
                  borderRadius: 4,
                  marginTop: 4,
                  border: border,
                }}
              />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <p
                  style={{
                    fontSize: 13,
                    fontWeight: 400,
                    margin: 0,
                    color: "#595959",
                  }}
                >
                  {MBCStatus[status]}
                </p>
                <p
                  style={{
                    lineHeight: "18px",
                    fontSize: 12,
                    margin: 0,
                    color: "#595959",
                  }}
                >
                  {description}
                </p>
              </div>
            </div>
          );
        })}
      </Stack>
    </Stack>
    )
};
