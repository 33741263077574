import { useState, useEffect } from "react";

const TITLE_SUFFIX = " | Two Chairs Care Platform";

const useTitle = (title: string, useSuffix: boolean = true) => {
  const [documentTitle, setDocumentTitle] = useState(title);

  useEffect(() => {
    document.title = `${documentTitle} ${useSuffix ? TITLE_SUFFIX : ""}`;
  }, [documentTitle, setDocumentTitle, useSuffix]);

  return [documentTitle, setDocumentTitle];
};

export default useTitle;

// useTitle proxy for non-functional component. use in cases where we have not yet converted page containers into functional components
export function UseTitleProxy(props: { title: string }) {
  useTitle(props.title);
  return null;
}
