import {
  Button,
  Flex,
  Stack,
  Text,
} from "../../../../../../../../design-system";
import {
  ComposedDropdownMenuCheckboxItem,
  DropdownButton,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuPrimitiveItem,
  DropdownMenuTrigger,
} from "../../../../../../../../design-system/dropdown/dropdown.styled";
import { DownChevronIcon } from "../../../../../../../../design-system/icons";
import { useCadenceFiltersDropdown } from "./use-cadence-filters";

export const CadenceFilterDropdown = () => {
  const {
    isDropdownOpen,
    setIsDropdownOpen,
    setMyClientsFilter,
    clearCadenceFilters,
    isFilteringOnWeekly,
    isFilteringOnBiweekly,
    isFilteringOnNoCandence,
  } = useCadenceFiltersDropdown();

  return (
    <div>
      <DropdownMenu open={isDropdownOpen}>
        <DropdownMenuTrigger asChild onClick={() => setIsDropdownOpen(true)}>
          <DropdownButton aria-label="client status filter options">
            <Text>Cadence</Text>

            <DownChevronIcon className="caret-icon" />
          </DropdownButton>
        </DropdownMenuTrigger>

        <DropdownMenuContent
          sideOffset={5}
          align={"start"}
          loop
          onInteractOutside={() => setIsDropdownOpen(false)}
        >
          <Stack css={{ pb: 8 }}>
            <ComposedDropdownMenuCheckboxItem
              checked={isFilteringOnWeekly}
              onCheckedChange={() =>
                setMyClientsFilter("isFilteringOnWeekly", !isFilteringOnWeekly)
              }
            >
              Every week
            </ComposedDropdownMenuCheckboxItem>

            <ComposedDropdownMenuCheckboxItem
              checked={isFilteringOnBiweekly}
              onCheckedChange={() =>
                setMyClientsFilter(
                  "isFilteringOnBiweekly",
                  !isFilteringOnBiweekly,
                )
              }
            >
              Every 2 weeks
            </ComposedDropdownMenuCheckboxItem>

            <ComposedDropdownMenuCheckboxItem
              checked={isFilteringOnNoCandence}
              onCheckedChange={() =>
                setMyClientsFilter(
                  "isFilteringOnNoCandence",
                  !isFilteringOnNoCandence,
                )
              }
            >
              No cadence
            </ComposedDropdownMenuCheckboxItem>
          </Stack>

          <Flex
            justifyContent={"space-between"}
            css={{ borderTop: "1px solid $neutral3", pt: 10, pl: 0 }}
          >
            <DropdownMenuPrimitiveItem css={{ fontSize: 14 }} asChild>
              <Button
                variant={"tertiary"}
                size={"medium"}
                onClick={clearCadenceFilters}
              >
                Clear all
              </Button>
            </DropdownMenuPrimitiveItem>
          </Flex>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};
