import React from "react";
import { CSS_COLORS } from "../..";
import { IconProps } from "../icon.interface";

const WarningIcon = ({
  className,
  height = 24,
  width = 24,
  fill = "none",
  stroke = `${CSS_COLORS.orange9}`,
  title,
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={className}
      aria-hidden={!title}
    >
      {title ? <title>{title}</title> : null}
      <circle cx="12" cy="12" r="10"></circle>
      <line x1="12" y1="8" x2="12" y2="12"></line>
      <line x1="12" y1="16" x2="12.01" y2="16"></line>
    </svg>
  );
};

export { WarningIcon };
