import React from "react";

import { BillableHoursWeeksMap } from "../types";

import { DateRange } from "../../panel-management/types";

import { AverageValue, AveragesContainer } from "../_common";

import moment from "moment";
import { extractDateRangeFromDataset } from "../billable-hours/utils";

export const RollingAveragesView = React.memo(
  (props: {
    data: BillableHoursWeeksMap;
    dateRange: DateRange;
    rootHeadingLevel?: number;
  }) => {
    const { data, dateRange } = props;

    const adjustedDateRange = { ...dateRange };
    if (adjustedDateRange.start.day() === 0) {
      // if the start date is on a Sunday, set it to the immediate next Monday to preserve isoWeek logic
      // humans consider Sundays as start of the week, but isoWeeks start on Monday.
      adjustedDateRange.start = moment(adjustedDateRange.start).day(1);
    }

    const pastFourWeeksData: BillableHoursWeeksMap =
      extractDateRangeFromDataset(data, {
        start: moment().startOf("isoWeek").subtract(4, "weeks"),
        end: moment().startOf("isoWeek").subtract(1, "week"),
      });

    const pastThreeMonthsData: BillableHoursWeeksMap =
      extractDateRangeFromDataset(data, {
        start: moment().startOf("isoWeek").subtract(3, "months"),
        end: moment().startOf("isoWeek").subtract(1, "week"),
      });

    const inRangeData: BillableHoursWeeksMap = extractDateRangeFromDataset(
      data,
      adjustedDateRange,
    );

    return (
      <AveragesContainer>
        <AverageValue
          label="Past 3 Months"
          value={transformDataToAverages(pastThreeMonthsData) || 0}
          key="past3months"
        />
        <AverageValue
          label="Past 4 Weeks"
          value={transformDataToAverages(pastFourWeeksData) || 0}
          key="past4weeks"
        />
        <AverageValue
          label={`Between ${moment(props.dateRange.start)
            .startOf("week")
            .format("MM/DD")} ~ ${moment(props.dateRange.end)
            .endOf("week")
            .format("MM/DD")}`}
          value={transformDataToAverages(inRangeData) || 0}
          key="between"
        />
      </AveragesContainer>
    );
  },
);

export function transformDataToAverages(data: BillableHoursWeeksMap) {
  const allData = Object.values(data).filter((e) => e !== null);
  const numerator = allData
    .map((e) => e!.hours_utilization)
    .reduce((prev, curr) => (curr += prev), 0);
  const denominator = allData
    .map((e) => e!.workweek_hours)
    .reduce((prev, curr) => (curr += prev), 0);

  if (denominator !== 0) {
    return numerator / denominator;
  }

  return 0;
}
