import {
  selectAppointmentStatusFiltersSlice,
  useMyClientsScheduleStore,
} from "../../../../../my-clients-schedule-model";

export const useSessionHistoryAppointmentStatusFilters = () => {
  const filters = useMyClientsScheduleStore(
    selectAppointmentStatusFiltersSlice,
  );

  const setSessionHistoryAppointmentStatusFilter = useMyClientsScheduleStore(
    (state) => state.setSessionHistoryAppointmentStatusFilter,
  );

  return {
    setSessionHistoryAppointmentStatusFilter,
    ...filters,
  };
};
