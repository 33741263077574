import { DateTime } from "luxon";
import { ClientPayerServiceRecord } from "../use-my-clients-overview/my-clients-data.interface";

/**
 * @function sortValidCPSRs
 * @description sorts CPSRs by coverage_termination_date date descending order. the order is stable if both CPSRs coverage_termination_date
 *  are null. a coverage_termination_date of null indicates "indefinite coverage" making it greater than an actual date.
 * @param {ClientPayerServiceRecord[]} clientPayerServiceRecords initial form state
 */
const sortValidCPSRs = (
  clientPayerServiceRecords: ClientPayerServiceRecord[],
) => {
  return clientPayerServiceRecords.sort((a, b) => {
    if (
      a.coverageTerminationDate === null &&
      b.coverageTerminationDate === null
    ) {
      // with stable sorting, return the same order
      return 0;
    } else if (
      a.coverageTerminationDate === null &&
      b.coverageTerminationDate !== null
    ) {
      return -1;
    } else if (
      a.coverageTerminationDate !== null &&
      b.coverageTerminationDate === null
    ) {
      return 1;
    }

    return (
      DateTime.fromISO(b.coverageTerminationDate as string).toMillis() -
      DateTime.fromISO(a.coverageTerminationDate as string).toMillis()
    );
  });
};

/**
 * @function getValidCPSRs
 * @description filters for CPSRs  for coverage_termination_date
 * that are either null or have a coverage_termination_date that is after now
 * @param {IClientPayerServiceRecord[]} clientPayerServiceRecords
 */
const getValidCPSRs = (
  clientPayerServiceRecords?: ClientPayerServiceRecord[],
) => {
  const validCPSRs = clientPayerServiceRecords?.filter(
    (clientPayerServiceRecord) => {
      if (clientPayerServiceRecord.coverageTerminationDate === null) {
        return true;
      }

      const coverageTerminationDate = DateTime.fromISO(
        clientPayerServiceRecord.coverageTerminationDate,
      );

      return coverageTerminationDate > DateTime.now() ? true : false;
    },
  );

  return validCPSRs ?? [];
};

export const getMostRecentClientPayerServiceRecord = (
  clientPayerServiceRecords?: ClientPayerServiceRecord[],
) => {
  const validCPSRs = getValidCPSRs(clientPayerServiceRecords);

  // no cpsr - DTC or all terminations are before today
  if (clientPayerServiceRecords?.length === 0 || validCPSRs?.length === 0) {
    return null;
  }

  // if multiple cpsrs payer is furthest out termination date
  const sortedValidCPSRs = sortValidCPSRs(validCPSRs);

  return sortedValidCPSRs.length > 0 ? sortedValidCPSRs[0] : null;
};
