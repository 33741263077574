import moment from "moment";
import * as React from "react";
import { Chart } from "../types";
import { timeRangeValues } from "../utils";

export const useClientMBCMetrics = (
  primaryAssessment: string | undefined,
  charts: Chart[],
) => {
  let primaryTrack = "";
  let primaryChart: Chart | undefined;

  if (primaryAssessment) {
    primaryTrack = primaryAssessment.toLowerCase();
    primaryChart = charts.find((c) => c.track === primaryTrack);
  }

  const chartsToRender = primaryChart
    ? [primaryChart, ...charts.filter((c) => c.track !== primaryTrack)]
    : [...charts];

  const [selectedChart, setSelectedChart] = React.useState(0);
  const [selectedDateFilter, setSelectedDateFilter] = React.useState(
    timeRangeValues[1],
  );

  let chart = chartsToRender[selectedChart];

  const lastEventId = chart && [...chart.data].pop()?.event_id;

  const currentDateFilter = timeRangeValues.find(
    (item) => item === selectedDateFilter,
  );

  const timeRangeValueMap = {
    "All time": -1,
    "Last 3 months": 3,
    "Last 6 months": 6,
  };

  if (chart) {
    if (currentDateFilter && timeRangeValueMap[currentDateFilter] > -1) {
      const prevDate = moment().subtract(
        timeRangeValueMap[selectedDateFilter],
        "months",
      );

      const filteredData = chart.data.filter((row) =>
        moment(row.start_time).isSameOrAfter(prevDate),
      );

      chart = {
        ...chart,
        data: filteredData,
      };
    }

    /**
     * The following includes the `remissionReferenceLine` dataKey to enable the <Area> component
     * in mbc-graph.tsx to render.
     *
     * Note: After discussion with clinicians, the decision was made to keep the remissionReferenceLine
     * at a fixed y = 5.
     */
    chart = {
      ...chart,
      data: chart.data.map((datum) => ({
        ...datum,
        remissionReferenceLine: 5,
      })),
    };
  }

  return {
    lastEventId,
    selectedChart,
    setSelectedChart,
    setSelectedDateFilter,
    selectedDateFilter,
    chartsToRender,
    chart,
  };
};
