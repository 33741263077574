import { Flex, Text } from "@/app/design-system";

interface ClientScoresProps {
  isComplete: boolean;
  isEnrolled: boolean;
  GAD?: number | null | undefined;
  PHQ?: number | null | undefined;
  cohesion?: number | null | undefined;
  isCohesion?: boolean | undefined;
}

export const GroupClientMbcScores = ({
  isComplete,
  GAD,
  PHQ,
  cohesion,
  isCohesion,
}: ClientScoresProps) => {
  return (
    <Flex
      css={{ pl: 50, flexGrow: 5, maxWidth: 1050, minWidth: 640 }}
      justifyContent={"flex-start"}
      alignItems={"stretch"}
    >
      {isComplete ? (
        <>
          <Flex css={{ flexGrow: 3 }}>
            <ScoreAndName
              scoreName={"GAD-7"}
              score={GAD}
              shrink={0}
              orientationChoice={"center"}
              grow={0}
              width={350}
            />
            <ScoreAndName
              scoreName={"PHQ-9"}
              score={PHQ}
              shrink={1}
              orientationChoice={isCohesion ? "center" : "flex-start"}
              grow={isCohesion ? 1 : 2}
              width={isCohesion ? 400 : 300}
            />
          </Flex>
          {isCohesion ? (
            <Flex css={{ flexGrow: 4, alignContent: "stretch" }}>
              <ScoreAndName
                scoreName={"Cohesion"}
                score={cohesion}
                shrink={2}
                orientationChoice={"flex-end"}
                grow={2}
                width={500}
              />
            </Flex>
          ) : null}
        </>
      ) : (
        <Flex
          css={{
            justifyContent: isCohesion ? "flex-start" : "center",
            width: isCohesion ? 350 : 300,
          }}
        >
          <Text fontWeight={400} fontSize={16}>
            Check in not completed
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

interface ScoreAndNameProps {
  scoreName: string;
  score?: number | null;
  shrink: number;
  grow?: number | null;
  width?: number | null;
  orientationChoice?: string | null;
}

const ScoreAndName = ({
  scoreName,
  score,
  shrink,
  grow,
  width,
  orientationChoice,
}: ScoreAndNameProps) => {
  const roundedScore = score ? score.toFixed(0) : null;
  const orientation = orientationChoice ? orientationChoice : "flex-start";
  const flexGrow = grow ? grow : 1;

  const severity = getScoreSeverity({ scoreName, score });
  const currentWidth = width ? width : 250;

  return (
    <Flex
      css={{
        alignItems: "stretch",
        justifyContent: orientation,
        flexShrink: shrink,
        flexGrow: flexGrow,
      }}
    >
      <Flex css={{ width: currentWidth }}>
        <Text fontWeight={"700"} css={{ paddingRight: 5 }}>
          {scoreName}:
        </Text>
        <Text>
          {roundedScore} {severity}
        </Text>
      </Flex>
    </Flex>
  );
};

export const getScoreSeverity = ({
  scoreName,
  score,
}: {
  scoreName: string;
  score?: number | null;
}) => {
  if (scoreName === "GAD-7") {
    if (!score) {
      return "";
    } else if (score > 14) {
      return "- severe";
    } else if (score > 9) {
      return "- moderate";
    } else if (score > 4) {
      return "- mild";
    } else if (score >= 0) {
      return "- subclinical";
    } else {
      return "";
    }
  } else if (scoreName === "PHQ-9") {
    if (!score) {
      return "";
    } else if (score > 19) {
      return "- severe";
    } else if (score > 14) {
      return "- moderately-severe";
    } else if (score > 9) {
      return "- moderate";
    } else if (score > 4) {
      return "- mild";
    } else if (score >= 0) {
      return "- subclinical";
    } else {
      return "";
    }
  } else {
    return "";
  }
};
