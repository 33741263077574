import { graphqlClient } from "@/api/graphql";
import { ONE_HOUR } from "@/app/_helpers/constants";

import {
  GetMatchRequeueLogQuery,
  useGetMatchRequeueLogQuery,
} from "@/graphql/generated";

export const useGetRequeueLog = ({
  matchIds,
  enabled,
}: {
  matchIds: number[];
  enabled: boolean;
}) => {
  const { data, isLoading } = useGetMatchRequeueLogQuery(
    graphqlClient,
    { matchIds: matchIds },
    {
      staleTime: ONE_HOUR,
      enabled,
      select: (data) => {
        return data.ehr_match_requeue_log.reduce(
          (
            a: Record<number, GetMatchRequeueLogQuery["ehr_match_requeue_log"]>,
            v,
          ) => {
            const { match_id } = v;
            (a[match_id] = a[match_id] || []).push(v);
            return a;
          },
          {},
        );
      },
    },
  );

  return {
    data,
    isLoading,
  };
};

export type MatchRequeueLog = NonNullable<ReturnType<typeof useGetRequeueLog>['data']>[number][0];
