import { AxiosInstance } from "axios";
import moment from 'moment-timezone';
import Cookies from "js-cookie";

// Set trace prefix request header
const DEFAULT_TRACE_HEADER_KEY = "x-trace-prefix";
const DEFAULT_TRACE_QUERY_PARAM = "__trace_prefix__";
const DEFAULT_TRACE_COOKIE_NAME = "_tpre";

export function extractTracePrefixFromQuery(): string | undefined {
  const params = new URLSearchParams(window.location.search);

  const value = params.get(DEFAULT_TRACE_QUERY_PARAM);
  if (value && typeof value === "string") {
    console.log(`Received trace prefix from query parameter: ${value}`);
    return value;
  }

  return;
}

export function extractTracePrefixFromCookie(): string | undefined {
  const value = Cookies.get(DEFAULT_TRACE_COOKIE_NAME);

  if (value && typeof value === "string") {
    console.log(`Received trace prefix from query parameter: ${value}`);
    return value;
  }

  return;
}

export function setTracePrefixOnCookie(traceIdPrefix: string) {
  Cookies.set(DEFAULT_TRACE_COOKIE_NAME, traceIdPrefix, {
    expires: moment().add({minutes: 5}).toDate(),
    secure: true,
  });
}

type Extractor = () => string | undefined;
export const DEFAULT_EXTRACTORS: Extractor[] = [
  extractTracePrefixFromQuery,
  extractTracePrefixFromCookie,
];
const traceIdRegexp = new RegExp("^[a-f0-9]{24,32}$");

export function applyTracePrefixInterceptor(api: AxiosInstance) {
  api.interceptors.request.use(config => {
    if (config.method === 'OPTIONS' || config.method === 'options') {
      return config;
    }

    try {
      let traceIdPrefix: string | undefined;
      for (const extractor of DEFAULT_EXTRACTORS) {
        traceIdPrefix = extractor();

        if (traceIdPrefix && traceIdRegexp.test(traceIdPrefix)) {
          console.log(`Validated trace prefix: ${traceIdPrefix}`);
          break;
        }
      }

      if (!traceIdPrefix) {
        // console.log(`Not adding trace ID to request for ${config.url}`);
        return config;
      }

      const headers = config.headers ?? {};
      headers[DEFAULT_TRACE_HEADER_KEY] = traceIdPrefix;
      config.headers = headers;

      setTracePrefixOnCookie(traceIdPrefix);
    } catch (err) {
      // pass
    }
    return config;
  });
}
