import {
  ClientOverviewApiData,
  EventApiData,
} from "../../../../../../../../api/use-my-clients-overview/my-clients-data.interface";
import { Flex, styledStitches } from "../../../../../../../../design-system";
import {
  PenIcon,
  TrashCanIcon,
} from "../../../../../../../../design-system/icons";
import { CancelSessionAction } from "./cancel-session-action/cancel-session-action";
import { UpsertSessionAction } from "./upsert-session-action/upsert-session-action";

export const SessionActionButton = styledStitches("button", {
  all: "unset",
  backgroundColor: "$neutral0",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  p: 12,
  cursor: "pointer",
  svg: {
    display: "flex",
  },
});

interface SessionActionsContainerProps {
  isEditable?: boolean;
  client?: ClientOverviewApiData | null;
  serviceLine?: string;
  event: EventApiData;
}

export const SessionActionsContainer = ({
  isEditable,
  serviceLine = "Individual Therapy",
  event,
  client,
}: SessionActionsContainerProps) => {
  const clientFirstName = client?.firstName;
  const clientLastName = client?.lastName;

  return (
    <Flex
      css={{ position: "absolute", right: 20, gap: 24 }}
      className="session-action-container"
    >
      {isEditable ? (
        <UpsertSessionAction
          mode="edit"
          event={event}
          clientName={`${clientFirstName} ${clientLastName}`}
          clientId={client?.clientId}
        >
          <SessionActionButton>
            <PenIcon />
          </SessionActionButton>
        </UpsertSessionAction>
      ) : null}

      <CancelSessionAction
        clientFirstName={clientFirstName}
        clientLastName={clientLastName}
        serviceLine={serviceLine}
        event={event}
      >
        <SessionActionButton>
          <TrashCanIcon />
        </SessionActionButton>
      </CancelSessionAction>
    </Flex>
  );
};
