import { Radio } from "antd";
import styled from "styled-components";
import { $red, $secondary } from "../../../../../assets/colors";

export const RadioGroup = styled(Radio.Group)`
  margin-bottom: 8px !important;
  .ant-radio-button-wrapper-checked {
    background-color: ${$secondary} !important;
  }
`;

export const RequiredText = styled.span`
  color: ${$red};
`;
