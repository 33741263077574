import {
  GroupTherapyClientNavClientAddressDataFragment,
  GroupTherapyClientNavClientPayerRecordsDataFragment,
  GroupTherapyClientNavEventDataFragment,
  GroupTherapyClientNavInfoQuery,
  GroupTherapyClientPayerRecordsDataFragment,
} from "@/graphql/generated";
import { DateTime } from "luxon";
import { formatPhoneNumber } from "../../clients/client-id/profile/_api/use-group-therapy-client-overview/use-group-therapy-client.util";
import { getScoreSeverity } from "../../sessions/session-id/mbc/_components/group-client-mbc-scores";

export const selectDataForGroupClientDetailsNav = (
  data: GroupTherapyClientNavInfoQuery,
) => {
  const client = data.client[0];
  const groupType = data.client[0].clientGroups[0].group.groupType;
  const events = client.clientGroups[0].group.events;

  const baselineSeverity = getBaselineSeverity(events);

  const groupTitle = `${groupType.curriculum} for ${groupType.affinity}`;

  const sessionsAttended = events.filter((event) => {
    const now = DateTime.now();
    return (
      now > DateTime.fromISO(event.startTime) &&
      event.appointmentStatus === "attended"
    );
  }).length;

  const clientName = client.firstName + " " + client.lastName;

  const payer = getPayer(client.payerServiceRecords);

  const groupId = client.clientGroups[0].group.groupId;

  const selectData = {
    clientName,
    sessionsAttended: sessionsAttended ? sessionsAttended : 0,
    groupTitle,
    totalSessions: groupType.groupSessions,
    baselineSeverity,
    payer,
    phoneNumber: formatPhoneNumber(client.phoneNumber),
    email: client.email,
    address: getClientAddress(client.addresses),
    groupId: groupId,
  };

  return selectData;
};

// this is for PHQ severity -- if we want GAD's baseline change this
const getBaselineSeverity = (
  events: GroupTherapyClientNavEventDataFragment[] | null,
): string | null => {
  if (!events || events.length === 0) {
    return null;
  }

  const event = events.find((event) => {
    return (
      event &&
      event?.appointmentStatus === "attended" &&
      event?.clientGroupEvents[0]?.EMSes[0]?.emsRespondedAt &&
      event?.clientGroupEvents[0]?.EMSes[0]?.notificationEmailId &&
      event?.clientGroupEvents[0]?.EMSes[0]?.scores.length > 0
    );
  });

  if (!event) {
    return null;
  }
  const scores = event.clientGroupEvents[0].EMSes[0].scores;
  const phqScore = scores.find((score) => {
    return score && score.scoreName === "PHQ-9" && score.value;
  });

  if (!phqScore || !phqScore.scoreName || !phqScore.value) {
    return null;
  }
  const scoreName = phqScore.scoreName;
  const score = phqScore.value;

  return getScoreSeverity({ scoreName, score });
};

export const getPayer = (
  payerServiceRecords:
    | GroupTherapyClientNavClientPayerRecordsDataFragment[]
    | GroupTherapyClientPayerRecordsDataFragment[],
): string => {
  const now = DateTime.now();
  const payerServiceRecord = payerServiceRecords.find((payer) => {
    let coverageUnexpired = true;
    if (
      payer.coverageTerminationDate &&
      DateTime.fromISO(payer.coverageTerminationDate) < now
    ) {
      coverageUnexpired = false;
    }
    return (
      DateTime.fromISO(payer.coverageStartDate) <= now && coverageUnexpired
    );
  });

  return payerServiceRecord?.payer?.displayName
    ? payerServiceRecord?.payer?.displayName
    : "DTC";
};

const getClientAddress = (
  clientAddresses: GroupTherapyClientNavClientAddressDataFragment[],
): string | null => {
  const addressesSorted = clientAddresses.sort((address1, address2) => {
    return address2.dateCreated - address1.dateCreated;
  });
  if (addressesSorted.length === 0 || !addressesSorted) {
    return null;
  }
  const address = addressesSorted[0];
  const zipcode = address.zipcode ? address.zipcode.slice(0, 5) : null;
  return `${address.address} ${address.city} ${address.state} ${zipcode}`;
};
