import {
  DropdownMenu,
  DropdownMenuContent,
  ComposedDropdownMenuCheckboxItem,
  DropdownMenuPrimitiveItem,
} from "../../../../../../../../../design-system/dropdown/dropdown.styled";

import React from "react";

import { DropdownSubMenuTriggerItem } from "../dropdown-sub-menu-trigger-item";
import { useTreatmentTrackFilterDropdown } from "./use-treatment-track-filter-dropdown";
import { Flex, Button } from "../../../../../../../../../design-system";

export const TreatmentTrackDropdownMenu = () => {
  const {
    isFilteringOnGAD7Anxiety,
    isFilteringOnPHQ9Depression,
    isFilteringOnNoTrack,
    clearTreatmentTrackFilters,
    numberOfSelectedFilters,
    setMyClientsFilter,
  } = useTreatmentTrackFilterDropdown();

  return (
    <DropdownMenu>
      <DropdownSubMenuTriggerItem
        numberOfSelectedFilters={numberOfSelectedFilters}
      >
        Treatment track
      </DropdownSubMenuTriggerItem>
      <DropdownMenuContent sideOffset={15}>
        <ComposedDropdownMenuCheckboxItem
          checked={isFilteringOnGAD7Anxiety}
          onCheckedChange={() =>
            setMyClientsFilter(
              "isFilteringOnGAD7Anxiety",
              !isFilteringOnGAD7Anxiety,
            )
          }
        >
          GAD7 - Anxiety
        </ComposedDropdownMenuCheckboxItem>

        <ComposedDropdownMenuCheckboxItem
          checked={isFilteringOnPHQ9Depression}
          onCheckedChange={() =>
            setMyClientsFilter(
              "isFilteringOnPHQ9Depression",
              !isFilteringOnPHQ9Depression,
            )
          }
        >
          PHQ9 - Depression
        </ComposedDropdownMenuCheckboxItem>

        <ComposedDropdownMenuCheckboxItem
          checked={isFilteringOnNoTrack}
          onCheckedChange={() =>
            setMyClientsFilter("isFilteringOnNoTrack", !isFilteringOnNoTrack)
          }
        >
          No track
        </ComposedDropdownMenuCheckboxItem>

        <Flex
          justifyContent={"space-between"}
          css={{ borderTop: "1px solid $neutral3", pt: 10, pl: 0 }}
        >
          <DropdownMenuPrimitiveItem css={{ fontSize: 14 }} asChild>
            <Button
              variant={"tertiary"}
              size={"medium"}
              onClick={clearTreatmentTrackFilters}
            >
              Clear
            </Button>
          </DropdownMenuPrimitiveItem>
        </Flex>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
