import { useMainNavigationUtils } from "../../../hooks/use-main-navigation-utils";

import { OrderedListOutlined } from "@ant-design/icons";

import { NavigationMenuButton } from "../../components.utility/navigation-menu-button";

interface ConsentSurveyMenuItemProps {
  isCollapsed: boolean;
}

export function ConsentSurveyMenuItem({
  isCollapsed,
}: ConsentSurveyMenuItemProps) {
  const { setConsentSurveyModalIsOpen } = useMainNavigationUtils();

  return (
    <NavigationMenuButton
      callback={() => setConsentSurveyModalIsOpen()}
      isCollapsed={isCollapsed}
      menuItemTooltipContent="Consent Surveys"
    >
      <OrderedListOutlined
        style={{ strokeWidth: 2, fontSize: 20 }}
        aria-hidden={!isCollapsed}
        title={!isCollapsed ? "" : "Consent Surveys"}
      />
      {!isCollapsed ? "Consent Surveys" : null}
    </NavigationMenuButton>
  );
}
