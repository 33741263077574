import api, { wrapApiCall } from "@/api";
import { Chart, ClientScoreBySession } from "@/app/client/types";
import { AsyncActionWithResultCreator } from "@/state/models/types";
import { AxiosResponse } from "axios";

import { ClientOverviewApiData } from "@/app/api/use-my-clients-overview/my-clients-data.interface";
import moment from "moment";
import { Dw_Export_Episode_Of_Care } from "@/graphql/generated";
import { useUserHasAnyPermissions } from "@/app/_helpers/permissions";

export const MBCStatus = {
  improvement: "Improvement",
  escalate: "Escalate",
  stagnation: "Stagnation",
  graduate: "Graduate",
  remission: "Remission",
  reliableChange: "Reliable Change",
  noChange: "No Change",
  previousEpisodeOfCare: "Historical Data",
};

export const mbcTrackStatusConfig = (status: string, track?: string) => {
  let color = "#fff";
  let fadedColor = "#fff";
  let description = "";
  let tooltipDescription = "";
  let border = undefined;
  let mbcDotTextColor = undefined;
  switch (status) {
    case MBCStatus.improvement:
      color = "#18794E";
      fadedColor = "#E9FDF3";
      description = "No change needed.";
      tooltipDescription = "No change needed.";
      mbcDotTextColor = "white";
      break;
    case MBCStatus.escalate:
      color = "#CD2B31";
      fadedColor = "#FFF1F1";
      description =
        "Consider reviewing case, consultation, updating treatment plan.";
      tooltipDescription =
        "Consider reviewing case, consultation, updating treatment plan.";
      mbcDotTextColor = "white";
      break;
    case MBCStatus.stagnation:
      color = "#F7CE00";
      fadedColor = "#FFF6C7";
      description =
        "Consider updating treatment plan, consultation, additional resources.";
      tooltipDescription =
        "Consider updating treatment plan, consultation, additional resources.";
      mbcDotTextColor = "black";
      break;
    case MBCStatus.graduate:
      color = "#006ADC";
      fadedColor = "#EBF2FF";
      description = "Review treatment goals, consider graduation.";
      tooltipDescription =
        "Client has reached remission when their score is below 5 for 3 consecutive sessions.";
      mbcDotTextColor = "white";
      break;
    case MBCStatus.remission:
      color = "#006ADC";
      fadedColor = "#EBF2FF";
      description = "Review treatment goals, consider graduation.";
      tooltipDescription =
        "Client has reached remission when their score is below 5 for 3 consecutive sessions.";
      mbcDotTextColor = "white";
      break;
    case MBCStatus.reliableChange:
      color = "#006ADC";
      fadedColor = "#EBF2FF";
      tooltipDescription =
        track === "phq"
          ? "Client has reached reliable change for depression when they have sustained a 5+ point drop from their intake score for 3 consecutive sessions and are at or past the lower end of the suggested session range."
          : "Client has reached reliable change for anxiety when they have sustained a 4+ point drop from their intake score for 3 consecutive sessions and are at or past the lower end of the suggested session range.";
      mbcDotTextColor = "white";
      break;
    case MBCStatus.previousEpisodeOfCare:
      color = "rgba(143,143,143,0.5)";
      description = "Sessions with Prior Clinicians.";
      break;
    default:
      color = "#fff";
      border = "1px solid #8F8F8F";
      description = "No Signficant Change.";
      mbcDotTextColor = "black";
      break;
  }
  return {
    color,
    fadedColor,
    border,
    description,
    tooltipDescription,
    mbcDotTextColor,
  };
};

export const clientScores: AsyncActionWithResultCreator<AxiosResponse<any>> =
  (clientId: number) => async (dispatch) => {
    try {
      return await wrapApiCall(
        api.get(`/api/mbc/2/client_assessment_scores/${clientId}/`),
        dispatch,
      );
    } catch (e) {
      throw new Error((e as Error).message);
    }
  };

export const surveyResponseByEvent: AsyncActionWithResultCreator<
  AxiosResponse<any>
> = (eventId: string) => async (dispatch) => {
  try {
    return await wrapApiCall(
      api.get(`/api/mbc/2/get_survey_responses_for_event/${eventId}/`),
      dispatch,
    );
  } catch (e) {
    throw new Error((e as Error).message);
  }
};
export const couplesScoresBySession: AsyncActionWithResultCreator<
  AxiosResponse<any>
> = (coupleId: string) => async (dispatch) => {
  try {
    return await wrapApiCall(
      api.get(`/api/query/mbc/scores_by_couple/${coupleId}/`),
      dispatch,
    );
  } catch (e) {
    throw new Error((e as Error).message);
  }
};
export const fetchClientProfile: AsyncActionWithResultCreator<
  AxiosResponse<any>
> =
  ({ clientId, coupleId }: { clientId?: number; coupleId?: string }) =>
  async (dispatch) => {
    try {
      let url = "/api/query/mbc/client_profile/";
      if (clientId) {
        url += `?client_id=${clientId}`;
      }
      if (coupleId) {
        url += `?couple_id=${coupleId}`;
      }
      return await wrapApiCall(api.get(url), dispatch);
    } catch (e) {
      throw new Error((e as Error).message);
    }
  };

export const clientsToday: AsyncActionWithResultCreator<AxiosResponse<any>> =
  (clinicianId: number, start: string, end: string) => async (dispatch) => {
    try {
      return await wrapApiCall(
        api.get(
          `/api/query/mbc/clients_today/?clinician=${clinicianId}&start=${start}&end=${end}`,
        ),
        dispatch,
      );
    } catch (e) {
      throw new Error((e as Error).message);
    }
  };

export const getFirstScoreReferenceLine = (
  scores: Array<ClientScoreBySession>,
  key: string,
) => {
  const baselineSession = scores.find(
    (obj) => obj.is_baseline && obj.is_latest,
  );

  const scoresFiltered = scores.filter((scoreItem) => {
    return (
      scoreItem.appointment_status !== "canceled" &&
      scoreItem.service_type != "consult"
    );
  });

  if (baselineSession) {
    scoresFiltered.forEach((scoreItem, index) => {
      scoreItem.calculated_session_number =
        scoreItem.same_clinician_over_60_days_last_session
          ? scoreItem.same_clinician_over_60_days_last_session
          : scoresFiltered[index - 1]?.calculated_session_number
          ? // @ts-ignore
            parseInt(scoresFiltered[index - 1].calculated_session_number) + 1
          : undefined;
    });
  }
  return {
    text: `Baseline (Current Clinician) - ${baselineSession?.[key]}`,
    score: baselineSession?.[key],
    baseLineSessionClinicianId: baselineSession?.clinician_id,
    baselineClientSessionNumber: baselineSession?.client_session_number,
    baselineSessionStartTime: baselineSession?.start_time,
  };
};

export const formatCharts = (
  client: ClientOverviewApiData | null,
  data: any,
  isMHQOLSurveyUser: boolean | null,
) => {
  if (!client) return;
  const primaryAssessment = client.primaryAssessment;
  const trackStatus = client.mbcTrackStatusBeta


  let sortedScores: ClientScoreBySession[] = data.sort(
    (a: ClientScoreBySession, b: ClientScoreBySession) =>
      new Date(a.start_time) < new Date(b.start_time) ? -1 : 1,
  );

  const phqTicks = [0, 5, 10, 15, 20];
  const gadTicks = [0, 5, 10, 15, 20];

  const getFirstScoreRCthreshold = (scores: Array<object>, key: string) => {
    let score = undefined;
    scores.every((scoreItem) => {
      if (scoreItem[key]) {
        score = scoreItem[key];
        return false;
      } else {
        return true;
      }
    });
    return score;
  };

  const gadChartBySession: Chart = {
    track: "gad",
    name: "Anxiety (GAD7)",
    type: "line",
    data: [],
    firstScoreReferenceLine: getFirstScoreReferenceLine(data, "gad7_value"),
    reliableChangeReferenceLine: getFirstScoreRCthreshold(
      data,
      "gad7_reliable_change_threshhold",
    ),
    referenceLines: [5, 10, 15],
    ticks: gadTicks,
    primary:
      primaryAssessment && primaryAssessment.toLowerCase() === "gad"
        ? true
        : false,
    status:
      primaryAssessment && primaryAssessment.toLowerCase() === "gad"
        ? trackStatus
          ? trackStatus
          : ""
        : "",
  };

  const phqChartBySession: Chart = {
    track: "phq",
    name: "Depression (PHQ9)",
    type: "line",
    data: [],
    firstScoreReferenceLine: getFirstScoreReferenceLine(data, "phq9_value"),
    reliableChangeReferenceLine: getFirstScoreRCthreshold(
      data,
      "phq9_reliable_change_threshhold",
    ),
    referenceLines: [5, 10, 15, 20],
    ticks: phqTicks,
    primary:
      primaryAssessment && primaryAssessment.toLowerCase() === "phq"
        ? true
        : false,
    status:
      primaryAssessment && primaryAssessment.toLowerCase() === "phq"
        ? trackStatus
          ? trackStatus
          : ""
        : "",
  };

  const phqBaselineSession = getFirstScoreReferenceLine(data, "phq9_value");

  const gadBaselineSession = getFirstScoreReferenceLine(data, "gad7_value");

  const allianceBaselineSession = getFirstScoreReferenceLine(
    data,
    "alliance_value",
  );

  const swlsBaselineSession = getFirstScoreReferenceLine(data, "swls_value");

  const alliance: Chart = {
    track: "alliance",
    name: "Alliance",
    type: "line",
    data: [],
    referenceLines: [3, 4, 5],
    ticks: [0, 1, 2, 3, 4, 5],
    status: "",
    domain: ["0", "5"],
  };

  const swls: Chart = {
    track: "swls",
    name: "SWLS",
    type: "line",
    service_type: "",
    data: [],
    referenceLines: [2, 3, 4, 5, 6, 7],
    ticks: [0, 1, 2, 3, 4, 5, 6, 7],
    status: "",
    domain: ["0", "7"],
  };

  const mhqol: Chart = {
    track: "mhqol",
    name: "Quality of Life",
    type: "line",
    service_type: "",
    data: [],
    referenceLines: [5,10,15],
    ticks: [0, 5, 10, 15, 20],
    status: "",
    domain: ["0", "31"],
  };

  let includesConsult = false;
  sortedScores.forEach((score: ClientScoreBySession) => {
    if (score.service_type === "consult") {
      includesConsult = true;
    }
  });

  sortedScores.forEach((score: ClientScoreBySession) => {
    if (score.appointment_status !== "canceled") {
      phqChartBySession.data.push({
        label: moment(score.start_time).format("MM/DD/YY"),
        value: score.phq9_value,
        status: score.phq9_status,
        service_type: score.service_type,
        start_time: score.start_time,
        event_id: score.event_id,
        episode: score.episode,
        clinician_full_name: score.full_name,
        clinician_last_name: score.last_name,
        track: "phq",
        endorsed_si: score.phq9_endorsed_si,
        client_session_number: score.client_session_number,
        clinician_session_number: score.clinician_session_number,
        includesConsult: includesConsult,
        isLatest: score?.is_latest,

        baselineSessionStartTime: phqBaselineSession?.baselineSessionStartTime,
        calculated_session_number: score.calculated_session_number,
      });

      gadChartBySession.data.push({
        label: moment(score.start_time).format("MM/DD/YY"),
        value: score.gad7_value,
        status: score.gad7_status,
        service_type: score.service_type,
        start_time: score.start_time,
        event_id: score.event_id,
        episode: score.episode,
        clinician_full_name: score.full_name,
        clinician_last_name: score.last_name,
        track: "gad",
        client_session_number: score.client_session_number,
        clinician_session_number: score.clinician_session_number,
        endorsed_si: score.gad7_endorsed_si,
        includesConsult: includesConsult,
        isLatest: score?.is_latest,
        isCurrentEpisodeOfCareClinician: score?.is_latest ?? null,
        baselineSessionStartTime: gadBaselineSession?.baselineSessionStartTime,
        calculated_session_number: score.calculated_session_number,
      });

      alliance.data.push({
        label: moment(score.start_time).format("MM/DD/YY"),
        value: score.alliance_value,
        status: "",
        service_type: score.service_type,
        start_time: score.start_time,
        episode: score.episode,
        clinician_full_name: score.full_name,
        clinician_last_name: score.last_name,
        client_session_number: score.client_session_number,
        clinician_session_number: score.clinician_session_number,
        event_id: score.event_id,
        includesConsult: includesConsult,
        isLatest: score?.is_latest,
        isCurrentEpisodeOfCareClinician: score?.is_latest ?? null,
        baselineSessionStartTime:
          allianceBaselineSession?.baselineSessionStartTime,
        calculated_session_number: score.calculated_session_number,
      });

      swls.data.push({
        label: moment(score.start_time).format("MM/DD/YY"),
        value: score.swls_value,
        status: "",
        service_type: score.service_type,
        start_time: score.start_time,
        event_id: score.event_id,
        episode: score.episode,
        clinician_full_name: score.full_name,
        clinician_last_name: score.last_name,
        client_session_number: score.client_session_number,
        clinician_session_number: score.clinician_session_number,
        includesConsult: includesConsult,
        isLatest: score?.is_latest,
        isCurrentEpisodeOfCareClinician: score?.is_latest ?? null,
        baselineSessionStartTime: swlsBaselineSession?.baselineSessionStartTime,
        calculated_session_number: score.calculated_session_number,
      });

      mhqol.data.push({
        label: moment(score.start_time).format("MM/DD/YY"),
        value: score.mhqol_value,
        status: "",
        service_type: score.service_type,
        start_time: score.start_time,
        event_id: score.event_id,
        episode: score.episode,
        clinician_full_name: score.full_name,
        clinician_last_name: score.last_name,
        client_session_number: score.client_session_number,
        clinician_session_number: score.clinician_session_number,
        includesConsult: includesConsult,
        isLatest: score?.is_latest,
        isCurrentEpisodeOfCareClinician: score?.is_latest ?? null,
      });
    }
  });

  if (isMHQOLSurveyUser) {
    return [phqChartBySession, gadChartBySession, alliance, mhqol];
  } else {
    return [phqChartBySession, gadChartBySession, alliance, swls];
  }
};

export const timeRangeValues = ["All time", "Last 3 months", "Last 6 months"];
