import {
  Flex,
  H4,
  RadixTooltip,
  styledStitches,
  Tag,
} from "@/app/design-system";
import React from "react";
import { WeeklyVolumeData } from "../../WeeklyVolume";
import moment from "moment";
import { Text } from "@/app/design-system";
import { volumeDataIsRamp, volumeDataIsTenured } from "../../types";
import * as Collapsible from "@radix-ui/react-collapsible";
import { DownChevronIcon, RightChevronIcon } from "@/app/design-system/icons";
import { DateTime } from "luxon";
import { useShallowEqualSelector } from "@/app/_helpers/redux";
import { IfPermitted } from "@/app/_helpers/permissions";

import {
  MatchHistoryRow,
  useGetMatchingHistory,
} from "./_api/use-get-matching-history";
import { ErrorBoundary } from "@sentry/react";
import { transformDataToAverages } from "@/app/dashboard/billable-hours/AveragesView";
import { extractDateRangeFromDataset } from "@/app/dashboard/billable-hours/utils";
import { BillableHoursWeeksMap } from "@/app/dashboard/types";
import { QuestionIcon } from "@/app/my-clients/routes/client-profile-page/client-profile-nav/routes/mbc-page/response-summary-cards/QuestionIcon";

export const MatchRequestFactors = ({
  volumeData,
}: {
  volumeData: WeeklyVolumeData;
}) => {
  const { rawData: volumeRawData } = volumeData;
  const data = volumeRawData;
  const {
    target,
    avg_4_weeks,
    weeks_1_out,
    weeks_2_out,
    weeks_3_out,
    weeks_4_out,
    pto_weeks,
    pending_matches,
  } = volumeDataIsRamp(data)
    ? {
        target: data.ramp_data.target_scheduled_appointments,
        avg_4_weeks: data.ramp_data.avg_4_weeks,
        weeks_1_out: data.ramp_data.weeks_1_out,
        weeks_2_out: data.ramp_data.weeks_2_out,
        weeks_3_out: data.ramp_data.weeks_3_out,
        weeks_4_out: data.ramp_data.weeks_4_out,
        pto_weeks: data.ramp_data.pto_weeks,
        pending_matches: data.ramp_data.pending_matches,
      }
    : volumeDataIsTenured(data)
    ? {
        target: data.mrmr_data.target_scheduled_appointments,
        avg_4_weeks: data.mrmr_data.avg_4_weeks,
        weeks_1_out: data.mrmr_data.weeks_1_out,
        weeks_2_out: data.mrmr_data.weeks_2_out,
        weeks_3_out: data.mrmr_data.weeks_3_out,
        weeks_4_out: data.mrmr_data.weeks_4_out,
        pto_weeks: data.mrmr_data.pto_weeks,
        pending_matches: data.mrmr_data.pending_matches,
      }
    : {
        target: data.target_client_hours,
        avg_4_weeks: null,
        weeks_1_out: null,
        weeks_2_out: null,
        weeks_3_out: null,
        weeks_4_out: null,
        pto_weeks: null,
        pending_matches: data.number_pending_matches,
      };

  const nextWeeks = [1, 2, 3, 4].map((i) =>
    DateTime.now().plus({ weeks: i }).startOf("week"),
  );
  const dtFormat = { ...DateTime.DATE_SHORT, year: undefined };

  const [avgScheduledExpandIsOpen, setAvgScheduledExpandIsOpen] =
    React.useState<boolean>(false);

  const [pendingMatchesExpandIsOpen, setPendingMatchesExpandIsOpen] =
    React.useState<boolean>(false);

  const matchingHistory = useGetMatchingHistory({
    clinicianId: volumeData.rawData.clinician_id,
    resolvedSince: DateTime.now().minus({ weeks: 2 }).startOf("day"),
  });
  const SessionUnlessPTO = ({
    sessions,
    pto,
  }: {
    sessions: number | null;
    pto?: number | null;
  }) => <>{pto ? "PTO" : `${sessions ? sessions : "n/a"} sessions`}</>;

  const HelpTooltip =
    volumeRawData.source === "ramp_beta" ? (
      <Text fontSize={12} color="$neutral2" notFlex>
        "Ramping" means the system is requesting new clients each week at a
        stair-step pace to reach your target number of scheduled sessions over
        your first 8 weeks. After that, the system will request new clients as
        needed to maintain your caseload target.
      </Text>
    ) : (
      <Text fontSize={12} color="$neutral2" notFlex>
        The next match request is calculated as{" "}
        <i>
          (avg scheduled sessions target) - (avg scheduled in the next 4 weeks)
          - pending clients
        </i>
        , rounded. <br />
        The average scheduled sessions in the next 4 weeks includes scheduled
        therapy sessions and consult reservations. Weeks with PTO are excluded
        from this average.
      </Text>
    );
  return (
    <MatchRequestFactorsContainer>
      <H4>
        Match Request Factors{" "}
        <IfPermitted permissions={["IsSuperUser", "IsClinicalLeader"]} requireAll={false}>
          {volumeRawData.source === "ramp_beta" ? "- Ramping" : null}
        </IfPermitted>
        <RadixTooltip delayDuration={0} content={HelpTooltip} width="280px">
          <div style={{ display: "inline-block" }}>
            <QuestionIcon width={32} height={32} />
          </div>
        </RadixTooltip>
      </H4>
      {/* hide the target for ramping clinicians whose target is not 28 */}
      {data.source === "ramp_beta" && target !== 28 ? null : (
        <MatchRequestFactorsSectionHeader>
          <Text>Avg scheduled sessions target</Text>
          <Text>{target}</Text>
        </MatchRequestFactorsSectionHeader>
      )}
      <Collapsible.Root
        open={avgScheduledExpandIsOpen}
        onOpenChange={setAvgScheduledExpandIsOpen}
      >
        <MatchRequestFactorsSectionHeader>
          <Collapsible.Trigger asChild>
            <Text>
              Avg scheduled in the next 4 weeks
              {avgScheduledExpandIsOpen ? (
                <DownChevronIcon />
              ) : (
                <RightChevronIcon height={16} width={16} />
              )}
            </Text>
          </Collapsible.Trigger>
          <Text>
            {avg_4_weeks ? Math.round(avg_4_weeks * 10) / 10 : avg_4_weeks}
          </Text>
          <Collapsible.Content>
            <Detail>
              <Text>
                Next week:{" "}
                <SessionUnlessPTO
                  sessions={weeks_1_out}
                  pto={pto_weeks?.weeks_1_out}
                />
              </Text>
              <Text>
                Week of {nextWeeks[1].toLocaleString(dtFormat)}:{" "}
                <SessionUnlessPTO
                  sessions={weeks_2_out}
                  pto={pto_weeks?.weeks_2_out}
                />
              </Text>
              <Text>
                Week of {nextWeeks[2].toLocaleString(dtFormat)}:{" "}
                <SessionUnlessPTO
                  sessions={weeks_3_out}
                  pto={pto_weeks?.weeks_3_out}
                />
              </Text>
              <Text>
                Week of {nextWeeks[3].toLocaleString(dtFormat)}:{" "}
                <SessionUnlessPTO
                  sessions={weeks_4_out}
                  pto={pto_weeks?.weeks_4_out}
                />
              </Text>
            </Detail>
          </Collapsible.Content>
        </MatchRequestFactorsSectionHeader>
      </Collapsible.Root>
      <Collapsible.Root
        open={pendingMatchesExpandIsOpen}
        onOpenChange={setPendingMatchesExpandIsOpen}
      >
        <MatchRequestFactorsSectionHeader>
          <Collapsible.Trigger
            asChild
            disabled={pending_matches === 0 || matchingHistory.isLoading}
          >
            <Text>
              Pending clients{" "}
              {pending_matches > 0 && !matchingHistory.isLoading ? (
                pendingMatchesExpandIsOpen ? (
                  <DownChevronIcon />
                ) : (
                  <RightChevronIcon height={16} width={16} />
                )
              ) : null}
            </Text>
          </Collapsible.Trigger>
          <Text>{pending_matches}</Text>
          <Collapsible.Content>
            <Detail>
              {matchingHistory.pendingMatches.map((m) => (
                <PendingMatchDetail matchRow={m} />
              ))}
            </Detail>
          </Collapsible.Content>
        </MatchRequestFactorsSectionHeader>
      </Collapsible.Root>
      {/* <H4 style={{ marginTop: "16px" }}>Match History</H4>
      <MatchRequestFactorsSectionHeader>
        <Text>
          Matches made in last week: {matchingHistory.numMatchesInLastWeek}
        </Text>
      </MatchRequestFactorsSectionHeader> */}

      <H4 style={{ marginTop: "16px" }}>Utilization Summary</H4>
      <UtilizationSummaryDetail />
    </MatchRequestFactorsContainer>
  );
};

const UtilizationSummaryDetail = () => {
  const { billableHoursWeeks } = useShallowEqualSelector((state) => ({
    billableHoursWeeks: state.slottool.billableHoursWeeks,
  }));

  const billableHoursClinician = Object.values(billableHoursWeeks).map(
    (w) => w !== null && w.clinician_id,
  );

  const futureFourWeeksData: BillableHoursWeeksMap =
    extractDateRangeFromDataset(billableHoursWeeks, {
      start: moment(),
      end: moment().add(3, "weeks"),
    });

  const pastFourWeeksData: BillableHoursWeeksMap = extractDateRangeFromDataset(
    billableHoursWeeks,
    {
      start: moment().subtract(4, "weeks"),
      end: moment().subtract(1, "week"),
    },
  );

  const pastThreeMonthsData: BillableHoursWeeksMap =
    extractDateRangeFromDataset(billableHoursWeeks, {
      start: moment().subtract(3, "months"),
      end: moment().subtract(1, "week"),
    });

  const futureFourWeeksAverage =
    transformDataToAverages(futureFourWeeksData) || 0;
  const pastFourWeeksAverage = transformDataToAverages(pastFourWeeksData) || 0;
  const pastThreeMonthsAverage =
    transformDataToAverages(pastThreeMonthsData) || 0;
  return (
    <UtilizationContainer>
      <UtilizationValue
        label="Last 3 Months"
        value={pastThreeMonthsAverage}
        key="last3months"
      />
      <UtilizationValue
        label="Last 4 Weeks"
        value={pastFourWeeksAverage}
        key="last4weeks"
      />
      <UtilizationValue
        label="Future 4 Weeks"
        value={futureFourWeeksAverage}
        key="future4weeks"
      />
    </UtilizationContainer>
  );
};

const UtilizationValue = (props: {
  label: string;
  value?: number;
  children?: JSX.Element;
}) => (
  <div style={{ textAlign: "center", width: "30%" }}>
    <h5>{props.label}</h5>
    {props.value !== undefined && (
      <span style={{ fontSize: "14pt" }}>{Math.round(props.value * 100)}%</span>
    )}
    {props.children}
  </div>
);

const UtilizationContainer = styledStitches("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  alignItems: "center",
  fontSize: "12pt",
  padding: "10px 8px",
  h5: {
    margin: "1em 0.2em 0.5em",
  },
});

const PendingMatchDetail = ({ matchRow }: { matchRow: MatchHistoryRow }) => {
  const hoursExpiresIn = DateTime.fromISO(matchRow.expires_at)
    .diffNow("hours")
    .mapUnits((x) => Math.ceil(x));
  return (
    <Detail
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <ErrorBoundary>
        <Text>
          {matchRow.ehr_client?.first_name?.slice(0, 2)}
          {matchRow.ehr_client?.last_name?.slice(0, 2)} -{" "}
          {matchRow.resolved_recurrence}
        </Text>
      </ErrorBoundary>
      <Tag size="small">
        Pending -{" "}
        {hoursExpiresIn.hours >= 24
          ? `${hoursExpiresIn.shiftTo("day", "hours").days} day${
              hoursExpiresIn.days > 1 ? "s" : ""
            }`
          : hoursExpiresIn.shiftTo("hours").toHuman()}{" "}
        left
      </Tag>
    </Detail>
  );
};

const Detail = styledStitches("div", {});

const MatchRequestFactorsSectionHeader = styledStitches("section", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  flexFlow: "row wrap",
  padding: "16px 0",
  overflowX: "hidden",
  borderBottom: "1px solid $neutral6",
  [`& ${Text}`]: {
    fontSize: "14px;",
  },

  [`& ${Detail}`]: {
    flexBasis: "100%",
    margin: "8px 0",
    minWidth: "300px",
    [`& ${Text}`]: {
      lineHeight: 2,
    },
  },
});

const MatchRequestFactorsContainer = styledStitches("div", {
  display: "flex",
  flexDirection: "column",
  padding: "8px",
  margin: "8px 0",
});
