import React, { useEffect } from "react";
import {
  useMyClientsFilterStore,
  selectMoreFiltersSlice,
} from "../../../../../my-clients-model/my-clients.model";

import {
  MyClientsFilters,
  MoreFiltersState,
} from "../../../../../my-clients-model/my-clients.interface";

export const useMoreFiltersDropdown = () => {
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

  const moreFilters = useMyClientsFilterStore(selectMoreFiltersSlice);

  const { setMyClientsFilter, clearMyClientsFilter } = useMyClientsFilterStore(
    ({ setMyClientsFilter, clearMyClientsFilter }) => ({
      setMyClientsFilter,
      clearMyClientsFilter,
    }),
  );

  const applyGlobalFilters = () => {
    for (const key in moreFilters) {
      setMyClientsFilter(key as keyof MyClientsFilters, moreFilters[key]);
    }
  };

  const clearMoreFilters = () => {
    for (const key in moreFilters) {
      clearMyClientsFilter(key as keyof MoreFiltersState);
    }
  };

  return {
    isDropdownOpen,
    setIsDropdownOpen,
    applyGlobalFilters,
    clearMoreFilters,
  };
};
