import { DateTime } from "luxon";
import { EventApiData } from "../../../../../../../api/use-my-clients-overview/my-clients-data.interface";
import { useFilterSessions } from "./use-filter-sessions/use-filter-sessions";

interface UseMyClientsScheduleParams {
  sessionHistorySessions: EventApiData[] | undefined;
}

export const useMyClientsSchedule = ({
  sessionHistorySessions,
}: UseMyClientsScheduleParams) => {
  const firstSessionDateTime =
    sessionHistorySessions && sessionHistorySessions.length > 0
      ? DateTime.fromISO(
          sessionHistorySessions[sessionHistorySessions.length - 1].startTime,
        ).toLocaleString(DateTime.DATE_SHORT)
      : null;

  const {
    sessionsFilteredByTimeRange,
    sessionsFilteredByTimeRangeAndAppointmentStatus,
  } = useFilterSessions(sessionHistorySessions);

  return {
    firstSessionDateTime,
    sessionHistorySessions,
    sessionsFilteredByTimeRange,
    sessionsFilteredByTimeRangeAndAppointmentStatus,
  };
};
