import { Flex } from "@/app/design-system";
import { styledStitches } from "@/app/design-system/styles/stitches.config";
import { Link } from "react-router-dom";

const groupClientMbcPaneStyles = {
  width: "100%",
  background: "white",
  p: 20,
  pr: 10,
  pt: 25,
  cursor: "pointer",
  minHeight: 75,
  align: "center",
  gap: 14,
  borderBottom: "2px solid $neutral4",

  "&:first-child": {
    btrr: 8,
    btlr: 8,
  },

  "&:last-child": {
    bbrr: 8,
    bblr: 8,
    borderBottom: "none",
  },
};

const groupClientMbcPaneHoverStyles = {
  "&:hover": {
    transition: "background-color ease-in-out",
    transitionDuration: "150ms",
    backgroundColor: "$neutral2",

    ".client-name": {
      color: "$blue11",
    },

    ".chevron-right": {
      display: "inline",
      transition: "transform ease-in-out",
      transform: "translateX(5px)",
    },
  },
};

export const StyledGroupClientMbcPaneLink = styledStitches(Link, {
  ...groupClientMbcPaneStyles,
  ...groupClientMbcPaneHoverStyles,
});

export const StyledGroupPaneNoLink = styledStitches(Flex, {
  ...groupClientMbcPaneStyles,
  ...groupClientMbcPaneHoverStyles,
});
