import { Clinician, CurrentUser } from "@/api/types";
import { Flex, Text } from "@/app/design-system";
import { alphabetizeBy } from "@/app/_helpers";
import {
  IfPermitted,
  UserHasAllPermissions,
  UserPermission,
} from "@/app/_helpers/permissions";
import DeprecatedClinicianPicker from "@/app/_shared/ClinicianPicker";
import { TEAM_VIEW_META_PREFIX } from "@/state/models/dashboard/operations";

export const TimeZoneAndClinicianPicker = ({
  clinicianMap,
  handleSelectClinician,
  clinicianId,
  myTeamClinicianIds,
  permissions,
  clinicianManagers,
  currentUser,
  loggedInUserClinicianId,
}: {
  clinicianMap: {
    [id: number]: Clinician;
  };
  handleSelectClinician: (clinicianid: number) => void;
  clinicianId: string | number | undefined;
  myTeamClinicianIds: number[];
  permissions: UserPermission[];
  clinicianManagers: Clinician[];
  currentUser: CurrentUser | null;
  loggedInUserClinicianId: number | undefined;
}) => {
  return (
    <Flex alignItems={"center"} gap="8">
      <Text color={"$neutral11"} fontSize="14">
        Times displayed in&nbsp;
        <strong>
          {/* https://stackoverflow.com/questions/1091372/getting-the-clients-time-zone-and-offset-in-javascript */}
          {currentUser?.clinician?.primary_timezone ||
            Intl.DateTimeFormat().resolvedOptions().timeZone}
        </strong>
      </Text>
      <ClinicianPickerContainer
        clinicianMap={clinicianMap}
        handleSelectClinician={handleSelectClinician}
        clinicianId={clinicianId}
        myTeamClinicianIds={myTeamClinicianIds}
        permissions={permissions}
        clinicianManagers={clinicianManagers}
        currentUser={currentUser}
        loggedInUserClinicianId={loggedInUserClinicianId}
      />
    </Flex>
  );
};

export const ClinicianPickerContainer = ({
  clinicianMap,
  handleSelectClinician,
  clinicianId,
  myTeamClinicianIds,
  permissions,
  clinicianManagers,
  currentUser,
  loggedInUserClinicianId,
}: {
  clinicianMap: {
    [id: number]: Clinician;
  };
  handleSelectClinician: (clinicianid: number) => void;
  clinicianId: string | number | undefined;
  myTeamClinicianIds: number[];
  permissions: UserPermission[];
  clinicianManagers: Clinician[];
  currentUser: CurrentUser | null;
  loggedInUserClinicianId: number | undefined;
}) => {
  let teamViewPickerOptions = {};

  // fixes issue where we try to reassign state from the selector
  const clinicianManagersCopy = [...clinicianManagers];

  if (UserHasAllPermissions(currentUser, ["IsSuperUser"])) {
    clinicianManagersCopy
      .sort((a, b) => alphabetizeBy(a, b, "first_name"))
      .forEach((c) => {
        teamViewPickerOptions[`${TEAM_VIEW_META_PREFIX}${c.id}`] = `${
          c.first_name
        } ${c.last_name.charAt(0)}.'s Team`;
      });
  } else if (
    loggedInUserClinicianId &&
    clinicianManagersCopy.find((c) => c.id === loggedInUserClinicianId)
  ) {
    teamViewPickerOptions = {
      [`${TEAM_VIEW_META_PREFIX}${loggedInUserClinicianId}`]: `Team View`,
    };
  }

  return (
    <IfPermitted permissions={permissions} requireAll={false}>
      <div style={{ marginLeft: "auto" }}>
        <DeprecatedClinicianPicker
          clinicianMap={clinicianMap}
          setClinicianFn={handleSelectClinician}
          value={clinicianId}
          additionalOptions={teamViewPickerOptions}
          sortFirstValues={myTeamClinicianIds}
        />
      </div>
    </IfPermitted>
  );
};
