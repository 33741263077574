import * as Sentry from "@sentry/react";
import axios, { AxiosError, AxiosPromise, AxiosResponse } from "axios";
import { Dispatcher } from "../state/models";
import { authOperations } from "../state/models/auth";
import { API_URL } from "./constants";
import { applyTracePrefixInterceptor } from "./tracePrefixInterceptor";

const api = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFTOKEN",
});

// catch API errors in Sentry
api.interceptors.response.use(
  (res) => res,
  (err) => {
    Sentry.withScope((scope) => {
      let requestContext = {};
      if (err.response) {
        const res = err.response as AxiosResponse;
        requestContext = {
          statusCode: res.status,
          url: res.config.url,
          route: `${res.config.method?.toUpperCase()} ${res.config.url}`,
        };

        if (res.status >= 400 && res.status < 500) {
          scope.setLevel("warning");
          if (res.status !== 401) {
            // avoid multiply-logging 401s
            Sentry.captureMessage(err.message, {
              level: "warning",
              tags: {
                ...requestContext,
              },
              contexts: {
                response: res.data,
              },
            });
          }

          // re-throw error instead of global Promise.reject()ing.
          // for some reason, the global Promise.reject will bypass
          // wrapApiCall's .catch
          throw err;
        }
      }

      Sentry.captureException(err, {
        tags: {
          ...requestContext,
        },
      });

      throw err;
    });
  },
);

applyTracePrefixInterceptor(api);

export const wrapApiCall = (promise: AxiosPromise, dispatch: Dispatcher) => {
  return promise.catch((error: AxiosError) => {
    if (error.response && error.response.status === 401) {
      Sentry.captureMessage(
        "Received 401 in wrapped API call. Expiring session...",
        {
          level: "warning",
        },
      );
      dispatch(authOperations.expire());
    }
    return Promise.reject(error);
  });
};

export default api;
