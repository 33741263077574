import { OpenBookIcon } from "@/app/design-system/icons";

import { NavigationMenuItem } from "../../components.utility/navigation-menu-item";

interface ResourceLibraryMenuItem {
  isCollapsed: boolean;
}

export function ResourceLibraryMenuItem({
  isCollapsed,
}: ResourceLibraryMenuItem) {
  return (
    <NavigationMenuItem
      to="/library"
      menuItemTooltipContent="Library"
      isCollapsed={isCollapsed}
    >
      <OpenBookIcon
        aria-hidden={!isCollapsed}
        title={!isCollapsed ? "" : "Library"}
      />
      {!isCollapsed ? "Library" : null}
    </NavigationMenuItem>
  );
}
