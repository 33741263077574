import { keyframes } from "../stitches.config";

export const OPACITY_0_TO_100 = keyframes({
  "0%": { opacity: 0 },
  "100%": { opacity: 1 },
});

export const OPACITY_0_TO_40 = keyframes({
  "0%": { opacity: 0 },
  "100%": { opacity: 0.4 },
});

export const TRANSLATEY_0_TO_100 = keyframes({
  "0%": { translateY: 0 },
  "100%": { translateY: 100 },
});

export const SCALE_0985_TO_1000 = keyframes({
  "0%": { scale: 0.975, opacity: 0 },
  "100%": { scale: 1, opacity: 1 },
});

export const BOX_SHADOW_PULSE_20PX = keyframes({
  to: { boxShadow: "0 0 0 20px rgba(255, 255, 255, 0)" },
});
