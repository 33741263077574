import { GroupTherapyClientQuery } from "@/graphql/generated";
import { useParams } from "react-router-dom";
import { useGroupTherapyClient } from "./use-group-therapy-client-overview/use-group-therapy-client-overview.api";

interface UseGroupTherapyClientPageParams<T> {
  select: (data: GroupTherapyClientQuery) => T;
}

export const useGroupTherapyClientPage = <T extends object>({
  select,
}: UseGroupTherapyClientPageParams<T>) => {
  const { groupId, clientId: clientIdString } =
    useParams<{ groupId: string; clientId: string }>();

  const clientId = clientIdString ? parseInt(clientIdString) : undefined;

  const enabled = !!groupId && !!clientId;

  const { data, isLoading, isError } = useGroupTherapyClient({
    enabled,
    groupId,
    clientId,
    select: (data) => select(data),
  });

  return { data, isLoading, isError };
};
