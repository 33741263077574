import { Fit, ClinicianSlot } from "../../../api/types";

import { IfPermitted } from "../../_helpers/permissions";

import React, { useCallback, useMemo } from "react";
import {
  BulbOutlined,
  ClockCircleOutlined,
  DeleteOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import { Button } from "antd";
import { useShallowEqualSelector } from "../../_helpers/redux";
import { useDispatch } from "react-redux";
import { matchActions } from "../../../state/models/matches";
import { $forestGreen, $green } from "../../../assets/colors";
import { AQMScorePopover } from "./AQMScorePopover";
import { getHighestAqmScore } from "../../_helpers";

const ShoppingCartButton = ({ fit, slot }: { fit: Fit; slot: ClinicianSlot }) => {
  const dispatch = useDispatch();
  const [aqmScores, clinician, currentFit, match, suggestedMatch] =
    useShallowEqualSelector((state) => [
      state.matches.aqmScores[fit.match],
      state.clinicians.clinicianMap[fit.clinician],
      state.matches.matchesInCart[fit.match],
      state.matches.myMatchesMap[fit.match],
      state.matches.matchSuggestions[fit.match],
    ]);

  const isSuggestedFit = useMemo(() => {
    if (suggestedMatch?.fit_id === fit.id) {
      if (
        !slot ||
        (slot.day_of_week === suggestedMatch.day_of_week &&
          slot.start_time === suggestedMatch.start_time)
      ) {
        return suggestedMatch;
      }
    }
    return null;
  }, [fit, slot, suggestedMatch]);

  const isInCart = useMemo(() => {
    return currentFit?.fit.id === fit.id;
  }, [currentFit, fit]);

  const cartButtonClick = () => {
    if (isInCart) {
      return dispatch(matchActions.removeMatchFromCart(fit.match));
    }

    // manual matches are always made to teletherapy
    // the logic to determine in-person fit should not be duplicated here,
    // so until the action is backend-authoritative, always match to remote
    return dispatch(
      matchActions.addMatchesToCart([
        { match, fit, slot: {...slot!, modality: 'remote'}, is_manual_match: true },
      ]),
    );
  };

  const aqmScoreBreakdown = useMemo(() => {
    if (!aqmScores) {
      return null;
    }
    if (!slot) {
      return getHighestAqmScore(aqmScores, fit.clinician);
    }
    return null;
    // disabling the tooltip for individual slots
    // const slotId = `${slot.clinician_id}_${slot.day_of_week}_${slot.start_time}`;
    // return aqmScores[slotId];
  }, [aqmScores, fit.clinician, slot]);

  return (
    <IfPermitted permissions={["IsMatchingAdmin"]} requireAll={true}>
      <AQMScorePopover breakdown={aqmScoreBreakdown}>
        <Button
          style={{
            minWidth: "40px",
            marginLeft: "5px",
            backgroundColor: isSuggestedFit ? $forestGreen : "",
          }}
          size="small"
          disabled={clinician === undefined}
          onClick={(e) => {
            e.stopPropagation();
            cartButtonClick();
          }}
          type={isInCart ? "primary" : "default"}
        >
          {isInCart ? (
            <DeleteOutlined />
          ) : isSuggestedFit ? (
            <BulbOutlined style={{ color: "white" }} />
          ) : (
            <ShoppingCartOutlined />
          )}
        </Button>
      </AQMScorePopover>
    </IfPermitted>
  );
};


export default ShoppingCartButton;
