import React from 'react';

export type Timezone = 'America/Los_Angeles' | 'America/New_York';

interface TimezoneContext {
  timezone: Timezone,
}

const TimezoneContext = React.createContext<Timezone | undefined>(undefined);

export const TimezoneProvider = ({ timezone, children }: { timezone: Timezone, children: React.ReactNode }) => {
  return <TimezoneContext.Provider value={timezone}>
    {children}
  </TimezoneContext.Provider>
}

export const useTimezoneProvider = () => {
  const timezone = React.useContext(TimezoneContext);

  if (!timezone) {
    throw new Error('Missing TimezoneContext.Provider');
  }

  return timezone;
}
