import { IconProps } from "../icon.interface";

export const CircleCheckMarkIcon = ({
  className,
  fill = "#46a758",
  width = 24,
  height = 24,
  title,
}: IconProps) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      aria-hidden={!title}
    >
      {title ? <title>{title}</title> : null}
      <path
        d="M23 11.0799C23 10.5276 22.5523 10.0799 22 10.0799C21.4477 10.0799 21 10.5276 21 11.0799H23ZM22 11.9999L23 12.0004V11.9999H22ZM15.663 3.77329C16.1675 3.99807 16.7587 3.77134 16.9834 3.26686C17.2082 2.76239 16.9815 2.17122 16.477 1.94644L15.663 3.77329ZM22.7075 4.70663C23.0978 4.31591 23.0975 3.68275 22.7068 3.29242C22.316 2.90209 21.6829 2.90241 21.2925 3.29312L22.7075 4.70663ZM12 14.0099L11.2929 14.717C11.4805 14.9046 11.7349 15.0099 12.0002 15.0099C12.2656 15.0098 12.52 14.9043 12.7075 14.7166L12 14.0099ZM9.70711 10.3028C9.31658 9.91225 8.68342 9.91225 8.29289 10.3028C7.90237 10.6933 7.90237 11.3265 8.29289 11.717L9.70711 10.3028ZM21 11.0799V11.9999H23V11.0799H21ZM21 11.9993C20.9989 13.9401 20.3705 15.8285 19.2084 17.3829L20.8103 18.5804C22.2306 16.6806 22.9986 14.3725 23 12.0004L21 11.9993ZM19.2084 17.3829C18.0464 18.9374 16.413 20.0745 14.5518 20.6248L15.1189 22.5427C17.3936 21.8702 19.39 20.4803 20.8103 18.5804L19.2084 17.3829ZM14.5518 20.6248C12.6907 21.1751 10.7015 21.109 8.88102 20.4364L8.18792 22.3125C10.413 23.1345 12.8442 23.2153 15.1189 22.5427L14.5518 20.6248ZM8.88102 20.4364C7.06051 19.7638 5.50619 18.5208 4.44987 16.8927L2.77206 17.9812C4.06312 19.9711 5.96285 21.4904 8.18792 22.3125L8.88102 20.4364ZM4.44987 16.8927C3.39354 15.2645 2.89181 13.3386 3.01951 11.402L1.02384 11.2704C0.86777 13.6373 1.48099 15.9913 2.77206 17.9812L4.44987 16.8927ZM3.01951 11.402C3.14721 9.4654 3.89749 7.62199 5.15845 6.14666L3.63811 4.84722C2.09693 6.65039 1.17992 8.90346 1.02384 11.2704L3.01951 11.402ZM5.15845 6.14666C6.41942 4.67133 8.12351 3.64314 10.0166 3.21542L9.57581 1.2646C7.26206 1.78736 5.17929 3.04404 3.63811 4.84722L5.15845 6.14666ZM10.0166 3.21542C11.9096 2.78771 13.8902 2.9834 15.663 3.77329L16.477 1.94644C14.3103 0.981007 11.8896 0.741837 9.57581 1.2646L10.0166 3.21542ZM21.2925 3.29312L11.2925 13.3031L12.7075 14.7166L22.7075 4.70663L21.2925 3.29312ZM12.7071 13.3028L9.70711 10.3028L8.29289 11.717L11.2929 14.717L12.7071 13.3028Z"
        fill={fill}
      />
    </svg>
  );
};
