export const labels = {
  32: [
    "Agitated (Emotional distress and restlessness)",
    "Animated (Lively, showing a range of gestures and facial expressions)",
    "Ashamed (Sheepish, appears fearful of or sensitive to humiliation)",
    "Constricted (Restriction in the range or intensity of displayed feelings)",
    "Determined (Resolute, purposeful, oriented toward a goal)",
    "Euphoric (Intensely excited, joyful, delighted)",
    "Inspired (Possessing the spirit to do something)",
    "Irritable (Easily frustrated or upset)",
    "Nervous (Experiencing worry, unease, tension, anxiety)",
    "Sad (Pain associated with loss, despair, hopelessness, disappointment)",
    "Tearful (Crying, or inclined to cry)",
  ],
  33: ["Linear and Goal-directed", "Disorganized", "Racing"],
  34: [
    "Apathetic (Showing no interest, enthusiasm, or concern)",
    "Defensive (Anxious in response to being challenged, self-justifying, tries to avoid criticism)",
    "Engaged (Interested, curious, actively listening)",
    "Guarded (Does not disclose a lot, or does so cautiously. reserved)",
    "Hostile (Unfriendly, antagonistic, reactively angry)",
    "Open (Frank, accessible, honest)",
    "Suspicious (Distrustful, skeptical, wary, assumes negative intentions)",
  ],
};
