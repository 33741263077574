import * as React from "react";
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Modal } from "antd";

import { Tag } from "../types";
import { Container, TagContainer } from "../styles";
import { $early } from "../../../assets/colors";
import { Row } from "../../_layout/Flex";
import { EditTagForm } from "./EditTagForm";

export const TagsList = ({
  tags,
  label,
  selectedTags = [],
  onChangeSelectedTags,
  allowAddTags,
  max,
  onUpdateTag,
}: {
  tags: Tag[];
  max?: number;
  label?: string;
  allowAddTags?: boolean;
  selectedTags?: string[];
  onChangeSelectedTags?: (nextTags: string[]) => void;
  onUpdateTag?: (nextTag: Tag) => void;
}) => {
  const [editTag, setEditTag] = React.useState<Tag>();
  const handleTagUpdate = (nextTag: Tag) => {
    if (onUpdateTag) {
      onUpdateTag(nextTag);
      setEditTag(undefined);
    }
  };
  return (
    <Container
      style={{
        alignItems: "center",
        padding: 0,
      }}
    >
      {label && <span style={{ margin: "0 16px 10px 0" }}>{label}</span>}
      {allowAddTags && (
        <TagContainer
          style={{ cursor: "pointer" }}
          onClick={() => setEditTag({ id: "", name: "" })}
        >
          <Row>
            <PlusOutlined style={{ marginRight: 8 }} />
            Add tag
          </Row>
        </TagContainer>
      )}
      {tags
        .filter((t, idx) => {
          if (max) {
            return idx + 1 <= max;
          }
          return true;
        })
        .map(t => (
          <TagContainer
            key={t.id}
            style={{
              flexDirection: "row",
              backgroundColor: selectedTags?.includes(t.id)
                ? $early
                : "#c4c4c4",
              fontSize: 14,
              cursor: onChangeSelectedTags ? "pointer" : "",
            }}
            onClick={() => {
              if (onChangeSelectedTags) {
                // remove from selected tags arr if exists
                if (selectedTags?.includes(t.id)) {
                  selectedTags.splice(selectedTags.indexOf(t.id), 1);
                } else {
                  // add to selected tags arr if it doesn't already exist
                  selectedTags.push(t.id);
                }
                onChangeSelectedTags(selectedTags);
              }
            }}
          >
            {t.name}
            {/* hiding edit icon */}
            {/* {allowAddTags && (
              <Icon
                style={{ marginLeft: 8 }}
                type="edit"
                onClick={e => {
                  e.stopPropagation();
                  setEditTag(t);
                }}
              />
            )} */}
            {selectedTags?.includes(t.id) && (
              <CloseOutlined style={{ marginLeft: 8 }} />
            )}
          </TagContainer>
        ))}
      {max && tags.length > max && (
        <span style={{ marginBottom: 10 }}>+{tags.length - max}</span>
      )}
      <Modal
        title="Tag"
        open={!!editTag}
        footer={null}
        onCancel={() => setEditTag(undefined)}
        bodyStyle={{ paddingTop: 8 }}
      >
        {editTag && (
          <EditTagForm
            initialTag={editTag}
            onCancel={() => setEditTag(undefined)}
            onUpdateTag={next => handleTagUpdate(next)}
          />
        )}
      </Modal>
    </Container>
  );
};
