/* eslint-disable no-mixed-operators */
/* eslint-disable react/jsx-pascal-case */
import { FOCUS_STYLES } from "@/app/design-system/styles/config/css-utils";

import { CSS_COLORS, styledStitches } from "@/app/design-system";
import { RightChevronIcon } from "@/app/design-system/icons";
import { Link } from "react-router-dom";

interface ViewTrendsLinkProps {
  link: string;
  linkDescription?: string;
}

/**
 * @name ViewTrendsLink
 * @description Returns a link a detailed page. If you are using this component multiple times in a page, you must pass in which page this link is taking them to to ensure a positive experience for ALL users.
 * For more context, please visit [Link Purpose (Link Only)](https://www.w3.org/WAI/WCAG21/Understanding/link-purpose-link-only) and [Using aria-label for link purpose](https://www.w3.org/WAI/WCAG21/Techniques/aria/ARIA8)
 * @returns Returns a link
 */
export const ViewTrendsLink = ({
  link,
  linkDescription,
}: ViewTrendsLinkProps) => {
  return (
    <StyledViewTrendsLink
      to={link}
      aria-label={
        linkDescription ? `View ${linkDescription} Trends` : "View Trends"
      }
    >
      View Trends
      <RightChevronIcon
        stroke={CSS_COLORS.neutral9}
        fill={CSS_COLORS.neutral9}
      />
    </StyledViewTrendsLink>
  );
};

const StyledViewTrendsLink = styledStitches(Link, {
  ...FOCUS_STYLES,
  color: CSS_COLORS.neutral11,
  display: "flex",
  alignItems: "center",
  width: "fit-content",
  "&:hover": {
    textDecoration: "underline",
  },
  "&:hover svg": {
    transition: "transform ease-in-out",
    transform: "translateX(5px)",
  },
});
