import { useSearchParams } from "react-router-dom";
import { UserHasAnyPermissions } from "../../_helpers/permissions";
import { useShallowEqualSelector } from "../../_helpers/redux";

export const useGroupTherapyUser = () => {
  const [searchParams] = useSearchParams();

  const currentUser = useShallowEqualSelector(
    (state) => state.auth.currentUser,
  );

  const clinicianId =
    currentUser?.clinician?.id ?? Number(searchParams.get("clinician_id"));

  const isClinicalLeader = UserHasAnyPermissions(currentUser, [
    "IsClinicalLeader",
  ]);

  const isSuperUser = UserHasAnyPermissions(currentUser, ["IsSuperUser"]);

  return {
    clinicianId,
    isClinicalLeaderOrSuperuser: isSuperUser || isClinicalLeader,
    isSuperUser,
    currentUser,
    isClinician: !!currentUser?.clinician?.id,
  };
};
