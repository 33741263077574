import { DEFAULT_STACK_STYLE as DEFAULT_STACK_STYLES } from "@/app/design-system/layout/stack";
import { UNSTYLED_LIST_STYLE } from "@/app/design-system/styles/config/css-utils";
import { styledStitches } from "@/app/design-system/styles/stitches.config";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";

export const NavigationMenuContainer = styledStitches("div", {
  display: "flex",
  flexDirection: "row",
  flex: 1,
});

export const NavMenuRoot = styledStitches(NavigationMenu.Root, {
  display: "flex",
  flexDirection: "column",
  flex: 1,
});

export const ModalNavMenuRoot = styledStitches(NavigationMenu.Root, {
  display: "flex",
  flexDirection: "column",
});

export const NavMenuSection = styledStitches("div", {
  display: "flex",
  flexDirection: "column",
  borderRight: "1px solid $neutral5",
  width: 220,
  backgroundColor: "$neutral0",
});

export const NavMenuList = styledStitches(NavigationMenu.List, {
  ...DEFAULT_STACK_STYLES,
  ...UNSTYLED_LIST_STYLE,
  gap: "$space4",
  margin: 0,
});

export const NavMenuItem = styledStitches(NavigationMenu.Item, {
  all: "unset",
});

export const NavMenuButton = styledStitches("button", {
  backgroundColor: "$neutral0",
  border: "none",
  display: "flex",
  py: 10,
  px: 16,
  cursor: "pointer",
  br: 6,
  boxSizing: "border-box",
  justifyContent: "flex-start",
  gap: 12,
  width: "100%",
  flexGrow: 1,

  "&:active": {
    transform: "scale(0.99)",
    backgroundColor: "$brandYellow",
  },

  "&:hover": {
    backgroundColor: "$neutral3",
    transitionDuration: "100ms",
    transitionProperty: "background-color",
  },
});
