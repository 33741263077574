import React, { useMemo } from "react";
import ClientAppointmentInfo from "./ClientAppointmentInfo";
import EmailMatch from "./EmailMatch";
import RematchDropdown from "./RematchDropdown";
import MatchSlotPreferences from "./MatchSlotPreferences";
import { Divider as AntDivider, Alert } from "antd";
import { useShallowEqualSelector } from "../../../_helpers/redux";
import ConflictOfInterestAutocomplete from "./ConflictOfInterestAutocomplete";
import CarePreferences from "./CarePreferences";

const Divider = () => (
  <div style={{ padding: "0 15px" }}>
    <AntDivider style={{ margin: "0" }} />
  </div>
);

export default () => {
  const [
    consult,
    match,
    rematch,
    validEmailMap,
    clientEmail,
    clientProfileData,
  ] = useShallowEqualSelector(state => [
    state.matchmaker.initData.consult,
    state.matchmaker.initData.match,
    state.matchmaker.initData.rematch,
    state.matchmaker.validEmailMap,
    state.matchmaker.clientEmail,
    state.matchmaker.clientProfileData,
  ]);

  const showSlotPreferenceInputs = useMemo(
    () =>
      validEmailMap[clientEmail]?.clientId ||
      consult !== null ||
      match !== null,
    [validEmailMap, clientEmail, consult, match],
  );

  const [clientProfileFound, clientProfileNotFound] = useMemo(
    () => [clientProfileData && clientProfileData.fetched, !clientProfileData],
    [clientProfileData],
  );

  return (
    <>
      <div style={{ overflowY: "auto" }}>
        {clientProfileFound && showSlotPreferenceInputs && (
          <Alert
            banner={true}
            message="Found Client Profile!"
            type="success"
            style={{ marginBottom: "10px" }}
            showIcon
          />
        )}
        {clientProfileNotFound && showSlotPreferenceInputs && (
          <Alert
            banner={true}
            message="Client Profile Not Found."
            style={{ marginBottom: "10px" }}
            type="warning"
            showIcon
          />
        )}
        {!consult && !match && !rematch && <EmailMatch />}
        {(consult || match || rematch) && <ClientAppointmentInfo />}
        {showSlotPreferenceInputs && (
          <>
            <Divider />
            <ConflictOfInterestAutocomplete />
            <Divider />
            <CarePreferences />
            <Divider />
            <MatchSlotPreferences />
          </>
        )}
      </div>
      <RematchDropdown />
    </>
  );
};
