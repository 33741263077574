import * as React from "react";
import { useDispatch } from "react-redux";
import { useShallowEqualSelector } from "../../../../_helpers/redux";
import { consultNoteActions } from "../../../../../state/models/consultnote";
import { Input } from "antd";
import QuestionTitle from "../shared/QuestionTitle";
import { ConsultNoteQuestion } from "../../../../../api/types";
import useDebounce from "../../../../hooks/useDebounce";
import { getCurrentConsultNoteInfo } from "@/app/consults-and-matching/_hooks";

interface Props {
  question: ConsultNoteQuestion;
  handleEdit: (hasBeenEdited: boolean) => void;
}

export default ({ question, handleEdit }: Props) => {
  const { readOnlyConsultNote } = getCurrentConsultNoteInfo();

  const [
    safety_plan_details,
    endorsed_current_active_hi,
    endorsed_current_active_si,
  ] = useShallowEqualSelector((state) => [
    state.consultnote?.openConsult?.consult_note?.self_injurious_behavior
      ?.safety_plan_details
      ? state.consultnote?.openConsult?.consult_note?.self_injurious_behavior
          ?.safety_plan_details
      : "",

    state.consultnote?.openConsult?.consult_note?.self_injurious_behavior
      ?.endorsed_current_active_hi,
    state.consultnote?.openConsult?.consult_note?.self_injurious_behavior
      ?.endorsed_current_active_si,
  ]);

  const [value, setValue] = React.useState(safety_plan_details);

  const handleChange = (value: string) => {
    handleEdit(true);
    setValue(value);
  };

  React.useEffect(() => {
    setValue(safety_plan_details);
  }, [safety_plan_details]);

  const dispatch = useDispatch();
  const debouncedValue = useDebounce(value, 500);

  React.useEffect(() => {
    dispatch(
      consultNoteActions.updateRelatedObjectField({
        relatedObjectName: "self_injurious_behavior",
        key: "safety_plan_details",
        value: value,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  if (endorsed_current_active_hi || endorsed_current_active_si) {
    return (
      <div style={{ paddingTop: "15px", paddingBottom: "15px" }}>
        <QuestionTitle
          questionNumber={question.questionNumber}
          titleText={question.title}
          subtitleText={question.subtitle}
          isRequired={question.isRequired}
          hideQuestionNumber={true}
        />
        <div>
          <div>
            <Input.TextArea
              disabled={readOnlyConsultNote}
              style={{ height: "32px" }}
              value={value}
              onChange={(e) => handleChange(e.target.value)}
            />
          </div>
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
};
