import { IconProps } from "../icon.interface";

export const CalendarIcon = ({
  fill = "#171717",
  width = 25,
  height = 24,
  title,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 24"
    fill="none"
    aria-hidden={!title}
  >
    {title ? <title>{title}</title> : null}
    <path
      d="M17.2222 2C17.2222 1.44772 16.7745 1 16.2222 1C15.6699 1 15.2222 1.44772 15.2222 2H17.2222ZM15.2222 6C15.2222 6.55228 15.6699 7 16.2222 7C16.7745 7 17.2222 6.55228 17.2222 6H15.2222ZM9.22223 2C9.22223 1.44772 8.77451 1 8.22223 1C7.66994 1 7.22223 1.44772 7.22223 2H9.22223ZM7.22223 6C7.22223 6.55228 7.66994 7 8.22223 7C8.77451 7 9.22223 6.55228 9.22223 6H7.22223ZM3.22223 9C2.66994 9 2.22223 9.44772 2.22223 10C2.22223 10.5523 2.66994 11 3.22223 11V9ZM21.2222 11C21.7745 11 22.2222 10.5523 22.2222 10C22.2222 9.44772 21.7745 9 21.2222 9V11ZM5.22223 5H19.2222V3H5.22223V5ZM19.2222 5C19.7745 5 20.2222 5.44771 20.2222 6H22.2222C22.2222 4.34315 20.8791 3 19.2222 3V5ZM20.2222 6V20H22.2222V6H20.2222ZM20.2222 20C20.2222 20.5523 19.7745 21 19.2222 21V23C20.8791 23 22.2222 21.6569 22.2222 20H20.2222ZM19.2222 21H5.22223V23H19.2222V21ZM5.22223 21C4.66994 21 4.22223 20.5523 4.22223 20H2.22223C2.22223 21.6569 3.56537 23 5.22223 23V21ZM4.22223 20V6H2.22223V20H4.22223ZM4.22223 6C4.22223 5.44772 4.66994 5 5.22223 5V3C3.56537 3 2.22223 4.34315 2.22223 6H4.22223ZM15.2222 2V6H17.2222V2H15.2222ZM7.22223 2V6H9.22223V2H7.22223ZM3.22223 11H21.2222V9H3.22223V11Z"
      fill={fill}
    />
  </svg>
);
