import React, { useCallback } from "react";
import SlotsByDayCheckboxTable from "./SlotsByDayCheckboxTable";
import { useShallowEqualSelector } from "../../../../_helpers/redux";
import { DownOutlined } from '@ant-design/icons';
import { Spin, Menu, Dropdown } from "antd";
import { Pad, Title } from "../../../../_layout/display";
import { Row, Flex } from "../../../../_layout/Flex";
import { Button } from "antd";
import {
  matchmakerOperations,
  matchmakerUtils,
} from "../../../../../state/models/matchmaker";
import { useDispatch } from "react-redux";
import { LocationContainer, styles, LocationRowHeader } from "./styled";
import SubregionDataSelector from "./SubregionDataSelector";
import { HelperTextBox } from "@/app/consults-and-matching/MatchingV2/_components/helper-text-box";

export default () => {
  const [subregionMap, msps, loading] = useShallowEqualSelector(state => [
    state.clinics.subregionMap,
    state.matchmaker.matchSlotPreferences,
    state.matchmaker.loading.clientPreferences,
    state.matchmaker.draft,
  ]);

  const { showOtFits, showInPersonFits } = useShallowEqualSelector(state =>
    matchmakerUtils.matchOtSelector(state.matchmaker),
  );
  const [msp] = useShallowEqualSelector(state => [
    state.matchmaker.matchSlotPreferences["general"],
  ]);


  const [showGeneral, updateShowGeneral] = React.useState(true);

  const dispatch = useDispatch();
  const addSubregionToMSP = useCallback(
    (subregionId: number) => {
      return dispatch(matchmakerOperations.addSubregion(subregionId));
    },
    [dispatch],
  );

  const availableSubregions = Object.values(subregionMap).filter(
    subregion =>
      !Object.keys(msps).includes(subregion.id.toString()) &&
      subregion.shown_in_matchmaker,
  );

  if (loading) {
    return (
      <div
        style={{
          width: "100%",
          height: "250px",
          textAlign: "center",
          padding: "100px 50px",
        }}
      >
        <Spin tip="Getting client's schedule preferences..." size="large" />
      </div>
    );
  }

  return (
    <Pad>
      {showInPersonFits && <Title size="sm">Location Preferences:</Title>}
      {showInPersonFits &&
        Object.keys(msps).map((mspKey: string) => {
          if (["general", "teletherapy"].includes(mspKey)) {
            return null;
          } else {
            const subregion = subregionMap[parseInt(mspKey)];
            return (
              <SubregionDataSelector
                key={mspKey + "__row"}
                subregion={subregion}
              />
            );
          }
        })}
      {showInPersonFits && (
        <Row layout="space-between center" style={{ marginBottom: "10px" }}>
          {availableSubregions.length > 0 && (
            <Dropdown
              overlay={
                <Menu>
                  {availableSubregions.map((subregion, idx) => (
                    <Menu.Item
                      key={idx}
                      onClick={() => addSubregionToMSP(subregion.id)}
                    >
                      {subregion.display_name}
                    </Menu.Item>
                  ))}
                </Menu>
              }
              trigger={["click"]}
            >
              <Button type="primary" icon={<DownOutlined />}>
                Add Location
              </Button>
            </Dropdown>
          )}
        </Row>
      )}
      {msp && msp.slots.length > 0 && msp.slots.length < 10?
            <HelperTextBox primaryText="Low Availability" secondaryText="Make sure clients select at least 10 recurring available times to avoid delay" color="#BD4B00" background="#FFF1E7"/>:
            <HelperTextBox primaryText="Decrease wait time" secondaryText="Make sure clients select at least 10 recurring available times" color="#5746AF" background="#F5F2FF"/>
      }

      

      <LocationContainer style={{marginTop: "10px"}}>
        <Row layout="space-between center" style={styles.locationRowStyle}>
          <LocationRowHeader>
            {showOtFits ? "Teletherapy" : "General"} Schedule Preferences
          </LocationRowHeader>
          <Flex />
          <Button
            type={!showGeneral ? "primary" : "default"}
            onClick={() => updateShowGeneral(!showGeneral)}
          >
            {!showGeneral ? "Customize" : "Done"}
          </Button>
        </Row>
        {showGeneral && <SlotsByDayCheckboxTable stateKey={"general"} />}
      </LocationContainer>
    </Pad>
  );
};
