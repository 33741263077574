import { Button, Modal } from "antd";
import Search from "antd/es/input/Search";
import React from "react";
import { useDispatch } from "react-redux";
import {
  clinicianActions,
  clinicianOperations,
} from "../../state/models/clinicians";
import { copyToClipboard } from "../_helpers";
import { useShallowEqualSelector } from "../_helpers/redux";
import { Title } from "../_layout/display";
import { Row } from "../_layout/Flex";
import Mangle from "./Mangle";

export default () => {
  const [consentSurveyModalData, consentSurveyModalIsOpen] =
    useShallowEqualSelector((state) => [
      state.clinicians.consentSurveyModalData,
      state.clinicians.consentSurveyModalIsOpen,
    ]);

  const dispatch = useDispatch();

  const findSurveyLinks = (inputText: string) =>
    dispatch(clinicianOperations.findSurveyLinks(inputText));

  return (
    <Modal
      {...{
        title: `Consent Survey Links`,
        open: consentSurveyModalIsOpen,
        width: "900px",
        onCancel: () =>
          dispatch(clinicianActions.setConsentSurveyModalIsOpen(false)),
        footer: null,
        style: { top: "45px" },
        bodyStyle: { paddingBottom: "30px" },
      }}
    >
      <Search
        placeholder="Input Welkin URL or patient email."
        onSearch={findSurveyLinks}
        enterButton
      />

      {typeof consentSurveyModalData === "string" ? (
        <Title margin="0px" size="md">
          {consentSurveyModalData}
        </Title>
      ) : (
        consentSurveyModalData.clients.map((client) => {
          const slug = consentSurveyModalData.isKpReferral
            ? "KpCouplesTherapyProfile"
            : "couples-therapy-profile";
          return (
            <Row
              key={client.client.id}
              layout={"space-between center"}
              style={{ marginTop: "5px" }}
            >
              <div style={{ flex: 1 }}>
                <Title margin="0px" size="sm">
                  <Mangle>
                    {client.client.first_name} {client.client.last_name}
                  </Mangle>
                </Title>
              </div>
              <div style={{ flex: 1 }}>
                <Title margin="0px" size="sm">
                  <Mangle>{client.client.email}</Mangle>
                </Title>
              </div>
              <div style={{ flex: 1 }}>
                <Button
                  onClick={() =>
                    copyToClipboard(
                      `https://www.surveymonkey.com/r/${slug}?identity=${client.token}`,
                    )
                  }
                >
                  Copy Survey Link
                </Button>
              </div>
            </Row>
          );
        })
      )}
    </Modal>
  );
};
