import { Flex, Stack, styledStitches } from "@/app/design-system";
import { Column, Row } from "@/app/_layout/Flex";
import { ExclamationOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import moment from "moment";
import { Chart } from "../types";
import { MBCStatus, mbcTrackStatusConfig } from "../utils";
import { CurrentUserContext } from "@/app/app.utils";
import React, { useContext } from "react";
import { Permissions } from "@/app/_helpers/permissions";

interface ChartsTabsProps {
  endorsedSi: boolean;
  setSelectedChart: (a: any) => void;
  chartsToRender: Chart[];
  selectedChart: number;
  chartCount: number;
}

export const ChartsTabs = ({
  endorsedSi,
  setSelectedChart,
  selectedChart,
  chartsToRender,
  chartCount,
}: ChartsTabsProps) => {
  const currentUser = useContext(CurrentUserContext);
  return (
    <Flex css={{ borderBottom: "1px solid rgba(0, 0, 0, 0.15)", px: 16 }}>
    {/* Tab item */}
    {chartsToRender.map((chart, index) => {
      const isSelected = index === selectedChart;
      const isLastItem = index === chartCount - 1;
      let sublabel = "";
      let subLabelBackground = "rgba(50, 50, 50, 0.13)";
      let subLabelColor = "#000";

      if (chart.primary && chart.status) {
        sublabel = chart.status;
        sublabel = sublabel === "Graduate" && Permissions.IsCareAlertBetaUser(currentUser) ? 'Remission' : sublabel;
        const { color, fadedColor } = mbcTrackStatusConfig(chart.status);
        subLabelBackground = fadedColor;
        subLabelColor = color;
        if (chart.status === MBCStatus.stagnation) {
          subLabelColor = "#B28035";
        }
      } else {
        const filtered = chart.data.filter(
          (item) =>
            moment(item.start_time) < moment().add("1", "d") &&
            item.value !== null,
        );
        const lastItem = filtered[filtered.length - 1];
        if (lastItem) {
          if (chart.track.toLowerCase() === "gad") {
            sublabel = `Scored ${lastItem.value}/21 on ${moment(
              lastItem.start_time,
            ).format("MM/DD/YY")}`;
          }
          if (chart.track.toLowerCase() === "phq") {
            sublabel = `Scored ${lastItem.value}/27 on ${moment(
              lastItem.start_time,
            ).format("MM/DD/YY")}`;
          }
          if (chart.name.toLowerCase() === "alliance") {
            sublabel = `Scored ${lastItem.value}/5 on ${moment(
              lastItem.start_time,
            ).format("MM/DD/YY")}`;
          }
          if (chart.name.toLowerCase() === "swls") {
            sublabel = `Scored ${lastItem.value}/7 on ${moment(
              lastItem.start_time,
            ).format("MM/DD/YY")}`;
          }
          if (chart.name.toLowerCase() === "mhqol" || chart?.track?.toLowerCase() === "mhqol") {
            sublabel = `Scored ${lastItem.value}/21 on ${moment(
              lastItem.start_time,
            ).format("MM/DD/YY")}`;
          }
        }
      }
      return (
        <Column
          key={index}
          layout="start stretch"
          onClick={() => setSelectedChart(index)}
          style={{
            flex: 1,
            padding: "12px 12px 0px 12px",
            cursor: "pointer",
          }}
        >
          <Stack
            justifyContent="space-between"
            css={{
              paddingBottom: isSelected ? 0 : 12,
              backgroundColor: isSelected ? "#fff" : "transparent",
              flex: 1,
              width: 200,
            }}
          >
            {/* Tab name and status icon */}
            <Row layout="space-between center">
              <p
                style={{
                  marginBottom: 4,
                  color: "#000",
                  fontWeight: isSelected ? 600 : 400,
                  fontSize: 18,
                  lineHeight: "28px",
                }}
              >
                {chart.name}
              </p>
              {endorsedSi && chart.track === "phq" && (
                <Tooltip
                  placement="top"
                  title="Client endorsed SI; conduct risk assessment and complete safety plan"
                >
                  <ExclamationOutlined
                    style={{
                      float: "right",
                      margin: "2px 4px 2px auto",
                      backgroundColor: "#D17134",
                      borderRadius: 8,
                      color: "#fff",
                      fontSize: 16,
                    }}
                  />
                </Tooltip>
              )}
            </Row>
            {/* Tab status or latest score */}
            <Row>
              {sublabel && (
                <p
                  style={{
                    padding: "4px 12px",
                    borderRadius: 10,
                    backgroundColor: subLabelBackground,
                    color: subLabelColor,
                    fontWeight: 500,
                    marginBottom: 4,
                    fontSize: 13,
                  }}
                >
                  {sublabel}
                </p>
              )}
            </Row>
            {isSelected && <ActiveTabHighlight />}
          </Stack>
        </Column>
      );
    })}
  </Flex>
  )
};

export const ActiveTabHighlight = styledStitches("div", {
  height: 8,
  width: 200,
  br: 8,
  mt: 4,
  backgroundColor: "black",
});
