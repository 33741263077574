import { Flex, Stack, Text } from "@/app/design-system";
import { GroupOverviewApiData } from "../_api/group-therapy.interface";
import { getSortedSessions } from "../_api/use-group-therapy-overview/use-group-therapy-overview.util";
import { GroupPane } from "./group-panes-container/group-pane";
import { GroupPaneContainer } from "./group-panes-container/group-pane.styled";

interface GroupPanesContainerProps {
  groupTherapyOverviewData: GroupOverviewApiData[];
  dayOfWeek: string;
}

export const GroupPanesContainer = ({
  groupTherapyOverviewData,
  dayOfWeek,
}: GroupPanesContainerProps) => {
  const sortedGroupsData = getSortedSessions(groupTherapyOverviewData);

  const daysValidGroups = sortedGroupsData.filter(
    (group) =>
      group.dayOfWeek === dayOfWeek &&
      group.numClients > 0 &&
      !group.isCanceled,
  );

  const isGroup = daysValidGroups.length > 0 ?? false;

  return (
    <GroupPaneContainer>
      {isGroup ? (
        <Stack>
          <Flex
            gap={4}
            css={{
              "@breakpoint1200": {
                flexDirection: "row",
              },
              paddingTop: "20px",
              paddingLeft: "10px",
              flexGrow: 1,
            }}
          >
            <Text fontWeight={"600"}>
              <p
                style={{
                  color: "#A8A8A8",
                  fontWeight: 600,
                  fontSize: 16,
                  lineHeight: "20px",
                  marginBottom: 4,
                }}
              >
                {dayOfWeek.toUpperCase()}S
              </p>
            </Text>
          </Flex>
          <Flex
            flexDirection={"column"}
            css={{
              flexGrow: 1,
              backgroundColor: "$neutral3",
              "@breakpoint1200": {
                flexDirection: "column",
                alignItems: "flex-start",
              },
              flexWrap: "wrap",
            }}
          >
            <Stack css={{ padding: 4 }}>
              {daysValidGroups.map(
                (group: GroupOverviewApiData, index: number) => {
                  return (
                    <GroupPane {...group} index={index} key={group.groupId} />
                  );
                },
              )}
            </Stack>
          </Flex>
        </Stack>
      ) : null}
    </GroupPaneContainer>
  );
};
