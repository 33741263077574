import {
  cancellationReasonToFrontendValueMap,
  CancellationType,
} from "./event-cancellation-types.constants";
import {
  CancellationReasonOptions,
  SelectDataForEditEventFormReturn,
} from "./select-data-for-cancel-client-group-event";
import { CANCELLATION_TYPE_HIDDEN_OPTIONS } from "./event-cancellation-types.constants";

export const getCancellationReasonOptions = (cancellationReasons: string[]) => {
  return cancellationReasons.map((cancellationReason) => {
    return {
      id: cancellationReason,
      value: cancellationReasonToFrontendValueMap[cancellationReason],
      label: cancellationReasonToFrontendValueMap[cancellationReason],
    };
  });
};

export const getOptions = (
  data: SelectDataForEditEventFormReturn | null | undefined,
) => {
  const cancellationTypeOptions =
    data?.cancellationTypeOptions.filter(
      (cancellationType) =>
        !CANCELLATION_TYPE_HIDDEN_OPTIONS.includes(cancellationType.value),
    ) ?? [];

  const cancelByClientReasonOptions =
    data?.cancellationReasonOptions.cancelByClientReasonOptions ?? [];

  const cancelByClinicianReasonOptions =
    data?.cancellationReasonOptions.cancelByClinicianReasonOptions ?? [];

  const lateCancelByClientReasonOptions =
    data?.cancellationReasonOptions.lateCancelByClientReasonOptions ?? [];

  const cancelByTwoChairsReasonOptions =
    data?.cancellationReasonOptions.cancelByTwoChairsReasonOptions ?? [];

  const noShowReasonOption =
    data?.cancellationReasonOptions.noShowReasonOption ?? [];

  return {
    cancellationTypeOptions,
    cancelByClientReasonOptions,
    cancelByClinicianReasonOptions,
    lateCancelByClientReasonOptions,
    cancelByTwoChairsReasonOptions,
    noShowReasonOption,
  };
};

export const getInitialCancellationReasons = (
  selectedCancellationType: CancellationType | string,
  cancellationReasons: CancellationReasonOptions | undefined,
) => {
  if (cancellationReasons) {
    if (selectedCancellationType === CancellationType.CANCELED_BY_CLIENT) {
      return cancellationReasons.cancelByClientReasonOptions;
    } else if (
      selectedCancellationType === CancellationType.CANCELED_BY_CLINICIAN
    ) {
      return cancellationReasons.cancelByClinicianReasonOptions;
    } else if (
      selectedCancellationType === CancellationType.LATE_CANCEL_BY_CLIENT
    ) {
      return cancellationReasons.lateCancelByClientReasonOptions;
    } else if (selectedCancellationType === CancellationType.NO_SHOW) {
      return cancellationReasons.noShowReasonOption;
    }
  }

  return [];
};
