// TODO: We'll need to eventually make sure the click events are UnstyledButtons and not divs
// JIRA - https://twochairs.atlassian.net/browse/TC-7203
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from "react";
import { Divider } from "antd";
import * as colors from "../../assets/colors";

interface OwnProps {
  style?: any;
  wrapperStyle?: any;
  header: JSX.Element;
  headerStyle?: any;
  content: JSX.Element;
  contentStyle?: any;
  hideDivider?: boolean;
  disabled?: boolean;
  onOpen?: () => void;
}

class ExpandableRowItem extends React.Component<OwnProps> {
  public state = {
    isExpanded: false,
    hovering: false,
  };
  public render() {
    const wrapperStyle = {
      ...this.props.wrapperStyle,
      ...this.props.style,
    };
    const headerStyle = {
      ...{
        cursor: this.props.disabled ? "default" : "pointer",
        backgroundColor:
          !this.props.disabled && this.state.hovering ? colors.$whiteHover : "",
      },
      ...this.props.headerStyle,
    };
    const contentStyle = this.props.contentStyle || {};
    return (
      <div style={wrapperStyle}>
        <div
          style={headerStyle}
          onMouseEnter={() => this.setState({ hovering: true })}
          onMouseLeave={() => this.setState({ hovering: false })}
          onClick={() =>
            this.setState(
              {
                isExpanded: this.props.disabled
                  ? false
                  : !this.state.isExpanded,
              },
              () =>
                this.props.onOpen &&
                this.state.isExpanded &&
                this.props.onOpen(),
            )
          }
        >
          {this.props.header}
        </div>
        {this.state.isExpanded && (
          <div style={contentStyle}>
            {this.props.content}
            {!this.props.hideDivider && <Divider style={{ margin: 0 }} />}
          </div>
        )}
      </div>
    );
  }
}

export default ExpandableRowItem;
