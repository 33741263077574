import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Button, Input } from "antd";
import { FormComponentProps } from "@ant-design/compatible/lib/form";
import * as React from "react";
import { GOOGLE_CLIENT_ID } from "@/api/constants";
import { GoogleLogin } from "@react-oauth/google";

const FormItem = Form.Item;

interface LoginProps extends FormComponentProps {
  loginError?: string;
  googleLoginFunction: (token: string, error?: string) => void;
  legacyLoginFunction: (username: string, password: string) => void;
}

class LoginForm extends React.Component<LoginProps> {
  public handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.props.form.validateFields(
      (err, values: { email: string; password: string }) => {
        if (!err) {
          this.props.legacyLoginFunction(values.email, values.password);
        }
      },
    );
  };

  public handleGoogleError = (error: any) => {
    this.props.googleLoginFunction("", error.error);
  };

  public render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        {GOOGLE_CLIENT_ID && GOOGLE_CLIENT_ID !== "undefined" && (
          <GoogleLogin
            onSuccess={(credentialResponse) => {
              if (credentialResponse && credentialResponse.credential) {
                this.props.googleLoginFunction(credentialResponse.credential);
              }
            }}
            onError={() => {
              throw new Error("Google Login failed");
            }}
            useOneTap
          />
        )}
        <Form onSubmit={this.handleSubmit} style={{ marginTop: 20 }}>
          <FormItem>
            {getFieldDecorator("email", {
              rules: [
                { required: true, message: "Please input your username!" },
              ],
            })(
              <Input
                prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                placeholder="Username"
              />,
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator("password", {
              rules: [
                { required: true, message: "Please input your Password!" },
              ],
            })(
              <Input
                prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                type="password"
                placeholder="Password"
              />,
            )}
          </FormItem>
          <div style={{ color: "red", marginBottom: "15px" }}>
            {this.props.loginError}
          </div>
          <FormItem style={{ margin: "0px" }}>
            <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
              Log in
            </Button>
          </FormItem>
        </Form>
      </div>
    );
  }
}

export default Form.create<LoginProps>()(LoginForm);
