import { keyframes, styledStitches } from "../styles/stitches.config";

const SPIN = keyframes({
  "100%": {
    transform: "rotate(360deg)",
  },
});

export const QuarterCircle = ({ dimension = "1em" }: Props) => (
  <svg
    viewBox="0 0 1024 1024"
    focusable="false"
    data-icon="loading"
    width={dimension}
    height={dimension}
    fill="currentColor"
    aria-hidden="true"
  >
    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z" />
  </svg>
);

export interface Props {
  dimension?: string | number | undefined;
}

export const Spinner = styledStitches("div", {
  transition:
    "width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)",
  svg: {
    animation: `${SPIN} 0.75s infinite linear`,
  },
});
