import { AxiosResponse } from "axios";
import moment from "moment";

import { dashboardActions, generateEmptyISOWeekMap } from ".";
import api, { wrapApiCall } from "../../../api";
import { BillableHoursWeek, Clinician } from "../../../api/types";
import {
  DateRange,
  MBCOverallAdherenceWeek,
  TeamBillableHoursWeeksMap,
} from "../../../app/dashboard/types";
import { AsyncActionCreator } from "../types";

export const TEAM_VIEW_META_PREFIX = "META_TEAM_";

export const loadMDBWithClinicianId: AsyncActionCreator =
  (id: string | number) => async (dispatch, getState) => {
    dispatch(dashboardActions.setClinicianId(id));
    dispatch(dashboardActions.startLoadingBillableHours());
    dispatch(dashboardActions.startLoadingMBC());

    // determine if id is a team view or a regular clinician view
    const clinicianIdAsString = String(id);
    if (clinicianIdAsString.indexOf(TEAM_VIEW_META_PREFIX) !== -1) {
      // team view
      const managerId = Number.parseInt(
        clinicianIdAsString.split("_").slice(-1)[0],
      );
      dispatch(dashboardActions.setTeamViewClinician(managerId));
      await dispatch(getClinicianReports(managerId));

      const { teamClinicianIds, dateRange } = getState().dashboard;

      await Promise.all([
        dispatch(getTeamBillableHours(teamClinicianIds, dateRange)),
        dispatch(getTeamMBCOverallAdherence(teamClinicianIds, dateRange)),
      ]);

      dispatch(dashboardActions.finishLoadingBillableHours());
      dispatch(dashboardActions.finishLoadingMBC());
    } else {
      // individual view
      const { clinicianId, dateRange } = getState().dashboard;
      dispatch(dashboardActions.setTeamViewClinician(null));
      await Promise.all([
        dispatch(getBillableHours(clinicianId, dateRange)),
        dispatch(getMBCOverallAdherence(clinicianId, dateRange)),
      ]);

      dispatch(dashboardActions.finishLoadingBillableHours());
      dispatch(dashboardActions.finishLoadingMBC());
    }
  };

export function getAmendedDateRange(dateRange: DateRange) {
  const amendedDateRange = {
    start: moment.min(
      moment().subtract(13, "weeks").startOf("isoWeek"),
      dateRange.start,
    ),
    end: moment.max(moment().endOf("isoWeek"), dateRange.end),
  };
  const startDate = amendedDateRange.start.format("YYYY-MM-DD");
  const endDate = amendedDateRange.end.format("YYYY-MM-DD");

  return { amendedDateRange, startDate, endDate };
}

export const getBillableHours: AsyncActionCreator =
  (clinicianId: number | string, dateRange: DateRange) =>
  async (dispatch, getState) => {
    dispatch(dashboardActions.resetBillableHours());

    // at minimum, get a span from 3 months ago to current week for average-calculating purposes
    const { amendedDateRange, startDate, endDate } =
      getAmendedDateRange(dateRange);
    const route = `/api/query/utilization/billable_hours/?clinician=${clinicianId}&start=${startDate}&end=${endDate}`;
    const map = generateEmptyISOWeekMap(amendedDateRange);

    await wrapApiCall(api.get(route), dispatch).then(
      (res: AxiosResponse<BillableHoursWeek[]>) => {
        const { data } = res;
        data.forEach((week) => {
          const { isoweek, isoyear } = week;
          map[`${isoyear}-W${isoweek}`] = week;
        });

        dispatch(dashboardActions.setBillableHoursWeeks(map));
        return res;
      },
    );
  };

export const getClinicianReports: AsyncActionCreator =
  (clinicianId: number) => async (dispatch) => {
    dispatch(dashboardActions.setTeamClinicianIds([]));
    dispatch(dashboardActions.setTeamCliniciansMap({}));

    await wrapApiCall(
      api.get(`/api/clinicians/v1/${clinicianId}/reports/`),
      dispatch,
    ).then((res: AxiosResponse<Clinician[]>) => {
      const { data } = res;
      const cliniciansMap = {};
      const clinicianIds = data.map((d) => {
        cliniciansMap[d.id] = d;
        return d.id;
      });

      dispatch(dashboardActions.setTeamClinicianIds(clinicianIds));
      dispatch(dashboardActions.setTeamCliniciansMap(cliniciansMap));
    });
  };

export const getClinicianManagers: AsyncActionCreator =
  () => async (dispatch) => {
    await wrapApiCall(api.get(`/api/clinicians/v1/managers/`), dispatch).then(
      (res: AxiosResponse<Clinician[]>) => {
        const { data } = res;
        dispatch(dashboardActions.setClinicianManagers(data));
      },
    );
  };

export const getTeamBillableHours: AsyncActionCreator =
  (clinicianIds: number[], dateRange: DateRange) =>
  async (dispatch, getState) => {
    dispatch(dashboardActions.resetBillableHours());

    const { amendedDateRange, startDate, endDate } =
      getAmendedDateRange(dateRange);
    const data: TeamBillableHoursWeeksMap = {};
    clinicianIds.forEach(
      (n) => (data[n] = generateEmptyISOWeekMap(amendedDateRange)),
    );

    // const requests = clinicianIds.map(async clinicianId => {
    //   const route = `/api/query/utilization/billable_hours/?clinician=${clinicianId}&start=${startDate}&end=${endDate}`;
    //   const thisMap = data[clinicianId];

    //   await wrapApiCall(api.get(route), dispatch).then(
    //     (res: AxiosResponse<BillableHoursWeek[]>) => {
    //       const { data } = res;
    //       data.forEach(week => {
    //         const { isoweek, isoyear } = week;
    //         thisMap[`${isoyear}-W${isoweek}`] = week;
    //       });
    //     },
    //   );

    //   return thisMap;
    // });

    const route = `/api/query/utilization/multiple_clinicians_billable_hours/?clinician=${clinicianIds.join(
      ",",
    )}&start=${startDate}&end=${endDate}`;
    const map = generateEmptyISOWeekMap(amendedDateRange);
    const bhuRequest = wrapApiCall(api.get(route), dispatch).then(
      (res: AxiosResponse<BillableHoursWeek[]>) => {
        const { data } = res;
        data.forEach((week) => {
          const { isoweek, isoyear } = week;
          map[`${isoyear}-W${isoweek}`] = week;
        });

        dispatch(dashboardActions.setBillableHoursWeeks(map));
        return res;
      },
    );

    const allRequests: Array<Promise<any>> = [bhuRequest];

    Promise.all(allRequests).then((reqs) => {
      return reqs;
    });
  };

export const mbcOverallAdherenceQueryRoute = (
  clinicianId: number,
  dateRange: DateRange,
) =>
  `/api/query/mbc/overall_adherence_by_clinician/?clinician=${clinicianId}&start=${dateRange.start.format(
    "YYYY-MM-DD",
  )}&end=${dateRange.end.format("YYYY-MM-DD")}`;

export const getMBCOverallAdherence: AsyncActionCreator =
  (clinicianId: number, dateRange: DateRange) => async (dispatch) => {
    // at minimum, get up to today's date for average-calculating purposes
    const amendedDateRange = {
      ...dateRange,
      start: moment.min(
        moment().subtract(4, "weeks").startOf("isoWeek"),
        dateRange.start,
      ),
      end: moment.max(moment().endOf("isoWeek"), dateRange.end),
    };

    await wrapApiCall(
      api.get(mbcOverallAdherenceQueryRoute(clinicianId, amendedDateRange)),
      dispatch,
    ).then((res: AxiosResponse<MBCOverallAdherenceWeek[]>) => {
      const { data } = res;

      const map = generateEmptyISOWeekMap(amendedDateRange);

      data.forEach((week) => {
        const { isoweek, isoyear } = week;
        map[`${isoyear}-W${isoweek}`] = week;
      });

      dispatch(dashboardActions.setMBCOverallAdherenceWeeks(map));
    });
  };

export const mbcOverallAdherenceTeamViewQueryRoute = (
  clinicianIds: number[],
  dateRange: DateRange,
) =>
  `/api/query/mbc/overall_adherence_team_view/?clinician=${clinicianIds.join(
    ",",
  )}&start=${dateRange.start.format("YYYY-MM-DD")}&end=${dateRange.end.format(
    "YYYY-MM-DD",
  )}`;

export const getTeamMBCOverallAdherence: AsyncActionCreator =
  (clinicianIds: number[], dateRange: DateRange) => async (dispatch) => {
    // at minimum, get up to today's date for average-calculating purposes
    const amendedDateRange = {
      ...dateRange,
      start: moment.min(
        moment().subtract(4, "weeks").startOf("isoWeek"),
        dateRange.start,
      ),
      end: moment.max(moment().endOf("isoWeek"), dateRange.end),
    };

    await wrapApiCall(
      api.get(
        mbcOverallAdherenceTeamViewQueryRoute(clinicianIds, amendedDateRange),
      ),
      dispatch,
    ).then((res: AxiosResponse<MBCOverallAdherenceWeek[]>) => {
      const { data } = res;

      const map = generateEmptyISOWeekMap(amendedDateRange);

      data.forEach((week) => {
        const { isoweek, isoyear } = week;
        map[`${isoyear}-W${isoweek}`] = week;
      });

      dispatch(dashboardActions.setMBCOverallAdherenceWeeks(map));
    });
  };

