import { Flex } from "@/app/design-system";
import { useShallowEqualSelector } from "@/app/_helpers/redux";
import { Pad, Title } from "@/app/_layout/display";
import { matchmakerUtils } from "@/state/models/matchmaker";
import { useGetClientGroupsAttendance } from "../../api/use-get-client-groups-attendance/use-get-client-groups-attendance.api";
import { getGroupDescriptionFromAttendanceData } from "../../api/use-get-client-groups-attendance/use-get-client-groups-attendance.util";
import { AddClientToGroup } from "./AddClientToGroup";

const GroupsEnrollmentError = () => {
  return (
    <Flex flexDirection="column">
      <span>There was an error determining whether this client is already enrolled in a group.</span>
      <span>Please try again later or contact support@twochairs.com.</span>
    </Flex>
  )
};

const GroupsEnrollmentContent = () => {
  const client = useShallowEqualSelector(matchmakerUtils.clientSelector);
  const clientId = client?.id || 0;
  
  const { data: clientCurrentGroupDescription, isLoading, isError } = useGetClientGroupsAttendance<string>({
    clientId,
    select: getGroupDescriptionFromAttendanceData,
    enabled: !!clientId,
  });

  if (isLoading) {
    return <span> Loading groups enrollment data.. </span>;
  }

  if (!clientId || (!isLoading && isError)) {
    return <GroupsEnrollmentError />
  }

  if (clientCurrentGroupDescription !== undefined) {
    return clientCurrentGroupDescription !== '' ? (
      <Flex flexDirection="column">
        <span> This client is already enrolled: {clientCurrentGroupDescription}. </span>
        <span> If the client decides to change their group, please reach out to groupops@twochairs.com. </span>
      </Flex>
    ) : (
      <AddClientToGroup clientId={clientId} />
    );
  }

  return null;
};

export const GroupsEnrollment = () => (
  <Pad>
    <Title size="small">Groups Enrollment:</Title>
    <GroupsEnrollmentContent />
  </Pad>
);
