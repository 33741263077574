import { Flex, H2, Spinner, Text } from "@/app/design-system";
import { QuarterCircle } from "@/app/design-system";
import { GroupTherapySessionSelector } from "./session-selector-dropdown";

interface GroupDetailsNotesHeaderProps {
  isLoading: boolean;
}

export const GroupDetailsNotesHeader = ({
  isLoading,
}: GroupDetailsNotesHeaderProps) => (
  <Flex flexDirection="column">
    <Flex css={{ width: "100%", pb: 12 }}>
      <Flex alignItems={"center"} gap={8}>
        {isLoading ? (
          <Flex css={{ marginLeft: 8 }}>
            <Spinner>
              <QuarterCircle dimension={"1.5em"} />
            </Spinner>
          </Flex>
        ) : null}
      </Flex>
      <Flex
        gap={8}
        css={{
          flexGrow: 1,
        }}
        alignItems={"center"}
        justifyContent={"flex-start"}
      >
        <Text>
          {" "}
          Please complete client notes and attendance 48 hours after end of
          session.{" "}
        </Text>
      </Flex>
      <Flex
        gap={8}
        css={{
          flexGrow: 1,
        }}
        alignItems={"center"}
        justifyContent={"flex-end"}
      >
        <GroupTherapySessionSelector />
      </Flex>
    </Flex>
  </Flex>
);
