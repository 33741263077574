import { EventApiData } from "@/app/api/use-my-clients-overview";
import { Stack, Text } from "@/app/design-system";
import { useMyClientsUser } from "@/app/my-clients/routes/my-clients-page/hooks/use-my-clients-user";
import React from "react";
import { RecurringSessionsOptions } from "../../session-actions/session-actions.constants";

interface SessionModalDialogActionContentProps<T> {
  mode: "cancel" | "edit";
  selectedSessions: string;
  // for when we show the values that were changed in the confirmation modal
  event?: EventApiData;
  values?: T;
}

export const SessionModalDialogActionContent = <T extends object>({
  mode,
  selectedSessions,
  event,
}: SessionModalDialogActionContentProps<T>) => {
  const { clinicianId, isClinician } = useMyClientsUser();

  const isEventClinician = clinicianId === event?.clinicianId;
  const shouldShowWarning = isClinician && !isEventClinician;
  return (
    <Stack
      css={{
        backgroundColor: "$neutral0",
        p: "20px 24px 32px 24px",
      }}
      gap={24}
    >
      {selectedSessions === RecurringSessionsOptions.THIS_SESSION_ONLY ? (
        mode === "cancel" ? (
          <Stack gap={12}>
            {shouldShowWarning ? (
              <Text color={"$orange11"} fontSize={14}>
                Warning: You are about to make changes to a session scheduled
                with {event?.clinician?.fullName}.
              </Text>
            ) : null}
            <Text>Are you sure you want to cancel this session?</Text>
          </Stack>
        ) : (
          <Stack gap={12}>
            {shouldShowWarning ? (
              <Text color={"$orange11"} fontSize={14}>
                Warning: You are about to make changes to a session scheduled
                with {event?.clinician?.fullName}.
              </Text>
            ) : null}
            <Text>Are you sure you want to make changes to this session?</Text>
          </Stack>
        )
      ) : null}

      {selectedSessions === RecurringSessionsOptions.ALL_FUTURE_RECURRING ? (
        mode === "cancel" ? (
          <Stack gap={12}>
            {shouldShowWarning ? (
              <Text color={"$orange11"} fontSize={14}>
                Warning: You are about to make changes to a session scheduled
                with {event?.clinician?.fullName}.
              </Text>
            ) : null}
            <Text>
              Are you sure you want to cancel this session and all future
              recurring sessions?
            </Text>
          </Stack>
        ) : (
          <Stack gap={12}>
            {shouldShowWarning ? (
              <Text color={"$orange11"} fontSize={14}>
                Warning: You are about to make changes to a session scheduled
                with {event?.clinician?.fullName}.
              </Text>
            ) : null}
            <Text>
              Are you sure you want to make changes to this session and all
              future recurring sessions?
            </Text>
          </Stack>
        )
      ) : null}
    </Stack>
  );
};
