import {
  Flex,
  Separator,
  Stack,
  Text,
  Spinner,
  QuarterCircle,
} from "@/app/design-system";

import { NavInfoContainer } from "@/app/_components/secondary-nav/nav-info.styled";
import { CurrentUserContext } from "@/app/app.utils";
import { TIMEZONE } from "@/app/my-clients/utils";
import { LoadingOutlined } from "@ant-design/icons";
import { DateTime } from "luxon";
import React from "react";
import { shortDayMap } from "../../../../../_helpers/constants";
import {
  ClientPayerServiceRecord,
  ManualSlotReservationApiData,
} from "../../../../../api/use-my-clients-overview/my-clients-data.interface";
import { DxCodeMap } from "../../../../../client/types";
import { DiagnosisCode } from "../../../components/diagnosis-code";
import { EmrLink } from "./client-profile-nav-therapy-info.styled";
import { ClientOverviewApiData } from "../../../../../api/use-my-clients-overview/my-clients-data.interface";
import { sprigProxy } from "@/api/constants";

interface ClientTherapyInfo {
  diagnosis?: string | null;
  baselineSeverity: string | null;
  totalSessionsAttendedWithCurrentClinician: number | null;
  totalSessionsAttended: number | null;
  clientPayerServiceRecord: ClientPayerServiceRecord | null;
  goalNumberOfSessionsRange?: string;
  manualSlotReservation?: ManualSlotReservationApiData[] | null;
  welkinLink: string | null;
  healthieLink: string | null;
  dxCodeMap: DxCodeMap;
  suggestedRange: string | null;
  careLocation: string | null;
  isLoading?: boolean;
  client: ClientOverviewApiData | null;
  isDxCodeLoading: boolean;
}

export const ClientProfileNavTherapyInfo = ({
  diagnosis,
  baselineSeverity,
  totalSessionsAttendedWithCurrentClinician,
  totalSessionsAttended,
  clientPayerServiceRecord,
  manualSlotReservation,
  welkinLink,
  healthieLink,
  dxCodeMap,
  suggestedRange,
  careLocation,
  isLoading,
  client,
  isDxCodeLoading,
}: ClientTherapyInfo) => {
  const cuser = React.useContext(CurrentUserContext);


  const formattedCoverageStartDate = clientPayerServiceRecord
    ? DateTime.fromISO(
        clientPayerServiceRecord?.coverageStartDate,
      ).toLocaleString({ timeZone: TIMEZONE(cuser) })
    : null;

  const formattedExpectedCoverageEndDate =
    clientPayerServiceRecord &&
    clientPayerServiceRecord?.expectedCoverageTerminationDate
      ? DateTime.fromISO(
          clientPayerServiceRecord?.expectedCoverageTerminationDate,
        ).toLocaleString({ timeZone: TIMEZONE(cuser) })
      : null;

  const formattedCoverageEndDate =
    clientPayerServiceRecord &&
    clientPayerServiceRecord?.coverageTerminationDate
      ? DateTime.fromISO(
          clientPayerServiceRecord?.coverageTerminationDate,
        ).toLocaleString({ timeZone: TIMEZONE(cuser) })
      : null;

  const coverageEndDate = formattedCoverageEndDate
    ? formattedCoverageEndDate
    : formattedExpectedCoverageEndDate;

  const coverageDateRangeText =
    clientPayerServiceRecord && formattedCoverageStartDate
      ? `Effective ${formattedCoverageStartDate} - ${
          coverageEndDate ? coverageEndDate : "No end date"
        }`
      : null;

  const readablecareLocation =
    careLocation === "hybrid"
      ? "In clinic or via teletherapy"
      : "Via teletherapy";

  React.useEffect(() => {
    const storageKey = `MATCH_SATISFACTION_HAS_COMPLETED_SURVEY__${client?.clientId}`;
    const MATCH_SATISFACTION_SURVEY_ID = 120336;
    const TEST_SURVEY_ID = 119052;
    const hasCompletedSurvey = localStorage.getItem(storageKey);


    // when survey is closed due to completion, set the bit for this client to true
    const onSurveyWillClose = (e: { name: string; initiator?: string }) => {
      if (e.name === "survey.willClose") {
        console.group();
        console.log("Survey will close.");
        console.table(e);
        console.groupEnd();
        if (e.initiator === "survey.completed") {
          localStorage.setItem(storageKey, "true");
          sprigProxy("removeListener", "survey.willClose", onSurveyWillClose);
        }
      }
    };

    // add listener for survey, that adds a listener for when the survey is completed
    const addSprigListener = async () => {
      // clear all sprig listeners here. we can't clear specific ones without memoizing the function
      // which is more effort than i'm willing to put in for this
      await sprigProxy('removeAllListeners');
      const onSurveyWillPresent = (e: any) => {
        if (
          e["survey.id"] === MATCH_SATISFACTION_SURVEY_ID ||
          e["survey.id"] === TEST_SURVEY_ID
        ) {
          sprigProxy("addListener", "survey.willClose", onSurveyWillClose);
        }
      };
      await sprigProxy("addListener", "survey.will.present", onSurveyWillPresent);
    };

    addSprigListener().catch((e) => {
      console.error("Failed to add Sprig listener", e);
    });

    if (!hasCompletedSurvey) {
      // when sprig fires the survey, the initial listener checks if it's the match satisfaction survey
      // if so, it adds a handler on close to set the bit to prevent the survey from being shown again
      sprigProxy("identifyAndTrack", {
        eventName: "ACCESS_CLIENT_PAGE",
        properties: {
          numSessions: totalSessionsAttendedWithCurrentClinician,
        },
      });
    }
  }, [totalSessionsAttendedWithCurrentClinician, client]);

  return (
    <Stack>
      <NavInfoContainer>
        <Text color={"$neutral9"} fontSize={14}>
          Diagnosis
        </Text>
        {!isDxCodeLoading ? (
          <DiagnosisCode
            primaryDiagnosis={diagnosis}
            dxCodeMap={dxCodeMap}
            fontSize={14}
            client={client}
            emrLink={`${healthieLink}/actions`}
          />
        ) : (
          <Spinner>
            <QuarterCircle dimension={"1em"}></QuarterCircle>
          </Spinner>
        )}
      </NavInfoContainer>

      {baselineSeverity ? (
        <NavInfoContainer>
          <Text color={"$neutral9"} fontSize={14}>
            Baseline severity
          </Text>

          <Text fontSize={14} capitalize>
            {baselineSeverity}
          </Text>
        </NavInfoContainer>
      ) : null}

      <NavInfoContainer>
        <Text color={"$neutral9"} fontSize={14}>
          Sessions
        </Text>
        {isLoading ? (
          <LoadingOutlined />
        ) : (
          <Text fontSize={14}>
            {totalSessionsAttendedWithCurrentClinician}
            {suggestedRange ? (
              <>
                <Separator
                  orientation="vertical"
                  backgroundColor={"$neutral12"}
                  height={"70%"}
                  css={{
                    mx: 6,
                  }}
                />
                <Text fontSize={14}>Goal: {suggestedRange}</Text>
              </>
            ) : null}
          </Text>
        )}
      </NavInfoContainer>

      <NavInfoContainer>
        <Text color={"$neutral9"} fontSize={14} css={{ alignItems: "start" }}>
          Payer
        </Text>

        <Stack alignItems={"flex-end"}>
          <Text fontSize={14}>
            {clientPayerServiceRecord?.payer.displayName
              ? clientPayerServiceRecord?.payer.displayName
              : "DTC"}
          </Text>
          {coverageDateRangeText ? (
            <Text fontSize={14} color={"$neutral11"}>
              {coverageDateRangeText}
            </Text>
          ) : null}
        </Stack>
      </NavInfoContainer>

      <NavInfoContainer>
        <Text color={"$neutral9"} fontSize={14} css={{ alignItems: "start" }}>
          Care Location
        </Text>
        <Text fontSize={14}>{readablecareLocation}</Text>
      </NavInfoContainer>

      {manualSlotReservation && manualSlotReservation.length > 0 ? (
        <NavInfoContainer>
          <Text color={"$neutral9"} fontSize={14} css={{ alignItems: "start" }}>
            Timeslot
          </Text>

          <Stack gap={8}>
            {manualSlotReservation.map((msr) => {
              const formattedStartDate = DateTime.fromISO(
                msr.startDate,
              ).toLocaleString();

              const formattedValidUntilDate = DateTime.fromISO(
                msr.validUntilDate,
              ).toLocaleString();

              return (
                <Stack alignItems={"flex-end"} key={formattedStartDate}>
                  <Text key={msr.startDate}>
                    <Text fontSize={14} capitalize>
                      {msr.recurrence}{" "}
                      {msr.evenOrOdd ? `(${msr.evenOrOdd})` : null}
                    </Text>

                    <Text fontSize={14}>
                      {", "}
                      {shortDayMap[msr.slotDayOfWeek]} at{" "}
                      {DateTime.fromFormat(msr.slotTimeOfDay, "TT").toFormat(
                        "ha",
                      )}
                    </Text>
                  </Text>

                  {msr.validUntilDate ? (
                    <Text fontSize={14} color={"$neutral11"}>
                      Ends {formattedValidUntilDate}
                    </Text>
                  ) : null}
                </Stack>
              );
            })}
          </Stack>
        </NavInfoContainer>
      ) : null}

      {healthieLink ? (
        <Flex css={{ py: 16 }}>
          <EmrLink href={healthieLink} target="_blank">
            View Healthie chart
          </EmrLink>
          <Separator
            css={{ mx: 16 }}
            orientation="vertical"
            backgroundColor={"$neutral12"}
          />
          <EmrLink href={`${healthieLink}/private_notes`} target="_blank">
            View session notes
          </EmrLink>
        </Flex>
      ) : null}
    </Stack>
  );
};
