import { useEffect, useState } from "react";

import { useWindowSize } from "../../../../consults-and-matching/_hooks";

export function useCollapseSidebar(callback: (val: boolean) => void) {
  const [isCollapsible, setIsCollapsible] = useState(true);
  const windowSize = useWindowSize();

  useEffect(() => {
    if (windowSize.width < 1200) {
      callback(true);
      setIsCollapsible(false);
    } else {
      setIsCollapsible(true);
    }
  }, [windowSize]);

  return {
    isCollapsible,
  };
}
