import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";
import { styledStitches } from "@/app/design-system/styles/stitches.config";

const StyledRadio = styledStitches(RadioGroupPrimitive.Item, {
  all: "unset",
  backgroundColor: "$neutral0",
  height: "16px",
  width: "16px",
  borderRadius: "50%",
  border: "1px solid grey",
  cursor: "pointer",
});

const StyledIndicator = styledStitches(RadioGroupPrimitive.Indicator, {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",

  "&:after": {
    content: "",
    display: "block",
    width: "12px",
    height: "12px",
    borderRadius: "50%",
    backgroundColor: "$neutral12",
  },
});

const StyledRadioGroupRoot = styledStitches(RadioGroupPrimitive.Root, {
  display: "flex",
  flexDirection: "column",
  gap: 12,
});

// Exports
export const Root = StyledRadioGroupRoot;
export const Radio = StyledRadio;
export const Indicator = StyledIndicator;
