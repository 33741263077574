import { ClientOverviewApiData } from "@/app/api/use-my-clients-overview/my-clients-data.interface";
import { EventApiData } from "../../../../../../../../api/use-my-clients-overview/my-clients-data.interface";
import { Stack } from "../../../../../../../../design-system/index";
import { SessionCadence } from "../../schedule-page.interface";
import { SessionCard } from "../3-session-card/session-card";
interface ExpandedUpcomingSessionsSection {
  remainingSessions: EventApiData[];
  isExpanded: boolean;
  client: ClientOverviewApiData | null;
}

export const ExpandedUpcomingSessionsSection = ({
  remainingSessions,
  isExpanded,
  client,
}: ExpandedUpcomingSessionsSection) => {
  return (
    <>
      {remainingSessions.length > 0 ? (
        <Stack
          gap={16}
          css={{
            y: -200,
          }}
        >
          {isExpanded ? (
            <>
              {remainingSessions?.map((event, index) => (
                <SessionCard
                  key={event.eventId}
                  sessionCadence={SessionCadence.Biweekly}
                  index={index}
                  isEditable={true}
                  event={event}
                  client={client}
                />
              ))}
            </>
          ) : null}
        </Stack>
      ) : null}
    </>
  );
};
