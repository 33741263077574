import * as React from "react";
import { ModalType } from "../types";

export const ModalContext = React.createContext({} as ModalProviderStore | SessionModalProviderStore);

export type OpenModalFunction = (
  originPosition: DOMRect,
  content: React.ReactChild,
) => void;

interface ModalContextStateBase {
  modalRef: React.RefObject<HTMLDivElement>;
  modalIsOpen: boolean;
  modalOriginPosition: DOMRect | undefined;
}

export interface ModalContextState extends ModalContextStateBase {
  modalType: Exclude<ModalType, 'session'>;
  modalDataId: string;
}
export interface SessionModalContextState extends ModalContextStateBase {
  modalType: Extract<ModalType, 'session'>;
  modalDataIds: string[];
}
interface BaseModalProviderStore {
 showModal: (
    modalOriginPosition: DOMRect,
    type: ModalType,
    modalDataIds: string[] | string,
  ) => void;
  hideModal: () => void;
}

export interface SessionModalProviderStore extends BaseModalProviderStore {
  state: SessionModalContextState;
}

export interface ModalProviderStore extends BaseModalProviderStore {
  state: ModalContextState;
}

export class ModalProvider extends React.Component<{}, ModalContextState | SessionModalContextState> {
  public hideModal = () => {
    this.setState({
      modalIsOpen: false,
      modalType: undefined,
      modalOriginPosition: undefined,
    });
  };

  // tslint:disable-next-line: member-access
  // tslint:disable-next-line: member-ordering
  public readonly state = {
    modalRef: React.createRef<HTMLDivElement>(),
    modalType: undefined,
    modalIsOpen: false,
    modalOriginPosition: undefined,
    modalDataId: "",
    modalDataIds: [],
  };

  public render() {
    const store: ModalProviderStore = {
      state: this.state,
      showModal: this.showModal,
      hideModal: this.hideModal,
    };
    return (
      <ModalContext.Provider value={store}>
        {this.props.children}
      </ModalContext.Provider>
    );
  }

  private showModal = (
    modalOriginPosition: DOMRect,
    type: ModalType,
    modalDataId: string | string[],
  ) => {
    if (isSessionModal(type)) {
      this.setState({
        modalRef: React.createRef(),
        modalIsOpen: true,
        modalType: type,
        modalOriginPosition,
        modalDataIds: modalDataId,
      } as SessionModalContextState);
    } else {
      this.setState({
        modalRef: React.createRef(),
        modalIsOpen: true,
        modalType: type,
        modalOriginPosition,
        modalDataId,
      } as ModalContextState);
    }
  };
}

export function isSessionModal(type: ModalType): type is Extract<ModalType, 'session'> {
  return type === 'session';
}

export const ModalConsumer = ModalContext.Consumer;
