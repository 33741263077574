import { getFirstPrecedingMondayFromDateThreeMonthsAgoOrDateTime } from "@/app/_helpers/datetime";
import { FreshPaint } from "@/app/_shared/FreshPaint";
import { TimePickerDateRange } from "@/app/_shared/TimePicker";
import { FriendlySpin } from "@/app/_shared/spin";
import { NoDataMessage } from "@/app/dashboard/_components/no-data-message";
import { ViewTrendsLink } from "@/app/dashboard/_components/view-trends-link";
import { transformDataToAverages } from "@/app/dashboard/billable-hours/AveragesView";
import { useClinicalUtilization } from "@/app/dashboard/billable-hours/use-get-utilization";
import { PrimaryMetricCard } from "@/app/dashboard/dashboard";
import { Flex, RadixTooltip, Title4, Title6 } from "@/app/design-system";
import { UnstyledButton } from "@/app/design-system/button/button";
import { CheckIconFilled } from "@/app/design-system/icons";
import { FilledBackgroundCircle } from "@/app/design-system/icons/_helpers/filled_background_circle";
import { FlagIcon } from "@/app/design-system/icons/components/flag-icon";
import { GetClinicalUtilizationQuery } from "@/graphql/generated";
import { generateEmptyISOWeekMapLuxon } from "@/state/models/dashboard";
import { DateTime } from "luxon";
import React from "react";

export const BILLABLE_UTILIZATION_THRESHOLD = 62.5;

export const getAvgBillableUtilization = ({
  clinicianId,
  timePickerDateRange,
}: {
  clinicianId: number;
  timePickerDateRange: TimePickerDateRange;
}): {
  isLoading: boolean;
  avgBillableUtilization: number;
  billableHoursMap: any;
} => {
  const startTimeParam = timePickerDateRange.start
    .startOf("week")
    .minus({ weeks: -1 });

  const { data, isLoading, isError } = useClinicalUtilization({
    clinicianId,
    start: startTimeParam,
    end: timePickerDateRange.end,
    enabled: true,
  });

  const billableHoursMap = generateEmptyISOWeekMapLuxon(
    startTimeParam,
    timePickerDateRange.end,
  );
  (data as GetClinicalUtilizationQuery)?.mv_clinical_utilization?.forEach(
    (week) => {
      const { isoweek, isoyear } = week;
      billableHoursMap[`${isoyear}-W${isoweek}`] = week;
    },
  );

  const avgBillableUtilization = React.useMemo(
    () => Math.round(transformDataToAverages(billableHoursMap) * 100),
    [billableHoursMap, timePickerDateRange],
  );

  return { isLoading, avgBillableUtilization, billableHoursMap };
};

export const UtilizationTile = ({
  clinicianId,
  timePickerDateRange,
}: {
  clinicianId: number;
  timePickerDateRange: {
    start: DateTime;
    end: DateTime;
  };
}) => {
  const { avgBillableUtilization, isLoading } = getAvgBillableUtilization({
    clinicianId: clinicianId ? Number(clinicianId) : -1,
    timePickerDateRange,
  });

  const getTtipTitle = (score: number) => {
    if (!score) return "";
    if (score >= BILLABLE_UTILIZATION_THRESHOLD) {
      return "At or above goal";
    } else {
      return "Below goal";
    }
  };

  return (
    <PrimaryMetricCard>
      <Flex gap={"8"} justifyContent="space-between">
        <Flex gap={"8"} flexDirection={"column"} justifyContent="space-between">
          <Title6 fontWeight={"bold"} as="h3">
            Utilization
          </Title6>
          {isLoading ? null : (
            <>
              {avgBillableUtilization ? (
                <FreshPaint eventName="Dashboard v2: Clicked utilization view trends">
                  <ViewTrendsLink
                    link={`${clinicianId}/utilization`}
                    linkDescription="Utilization"
                  />
                </FreshPaint>
              ) : (
                <NoDataMessage />
              )}
            </>
          )}
        </Flex>
        {isLoading ? (
          <Flex alignItems={"center"}>
            <FriendlySpin size="large" spinning={true} />
          </Flex>
        ) : (
          <>
            {avgBillableUtilization ? (
              <Flex flexDirection={"column"} alignItems="flex-end" gap="8">
                <RadixTooltip content={getTtipTitle(avgBillableUtilization)}>
                  <UnstyledButton discreet>
                    <Flex gap={8}>
                      {avgBillableUtilization >
                      BILLABLE_UTILIZATION_THRESHOLD ? (
                        <CheckIconFilled title="Meets goal"></CheckIconFilled>
                      ) : (
                        <FilledBackgroundCircle color="violet" size="medium">
                          <FlagIcon
                            stroke="white"
                            height={"12px"}
                            width={"12px"}
                            title="Warning: Below utilization goal"
                          />
                        </FilledBackgroundCircle>
                      )}
                      <Title4 fontWeight={"bold"} as="span">
                        {avgBillableUtilization}%
                      </Title4>
                    </Flex>
                  </UnstyledButton>
                </RadixTooltip>
                {avgBillableUtilization > BILLABLE_UTILIZATION_THRESHOLD && (
                  <div>Caseload full</div>
                )}
              </Flex>
            ) : null}{" "}
          </>
        )}
      </Flex>
    </PrimaryMetricCard>
  );
};
