import { Button, Select as DeprecatedAntdSelect } from "antd";
import { RadioChangeEvent } from "antd/es/radio";
import RadioGroup from "antd/es/radio/group";
import RadioButton from "antd/es/radio/radioButton";
import { SelectValue } from "antd/es/select";
import Tooltip from "antd/es/tooltip";
import { AxiosResponse } from "axios";
import moment from "moment";
import * as React from "react";
import * as ReactRedux from "react-redux";
import styled from "styled-components";

import { CurrentUser, PanelClientMeta, PanelSession } from "../../../api/types";
import Mangle from "../../../app/_shared/Mangle";
import { $antdBorder, $greyText } from "../../../assets/colors";
import { AppState, Dispatcher } from "../../../state/models";
import * as panelOperations from "../../../state/models/panel-management/operations";
import { UserHasAllPermissions } from "../../_helpers/permissions";
import { sessionBgColor } from "../_shared";
import {
  CadenceEnum,
  ClientAppointmentStatusCountMap,
  ClientCadence,
  ClientMetaMap,
  ClinicianWeeksMap,
  PanelSessionsMap,
} from "../types";
import {
  DebugView,
  ModalExternalLink,
  ModalContent,
  ModalSection,
  ModalTitle,
} from "./_shared";
import { NoteTextArea } from "./ModalTextArea";
import { API_URL } from "../../../api/constants";
import { DateTime } from "luxon";
import { useTimezoneProvider } from "@/app/_shared/TimezoneContext";

const SquareSize = 20;

function createSessionsGrid(
  clientId: number,
  weeks: ClinicianWeeksMap,
  sessionsMap: PanelSessionsMap,
) {
  const weeksObjects = Object.values(weeks);

  if (!weeksObjects || weeksObjects.length === 0) {
    return null;
  }
  const grid = [];

  let weekIndex = 1;
  let latestMonth = "0";
  for (const week of weeksObjects) {
    const currentMonth = moment().year(week.year).week(week.week_number);
    if (currentMonth.format("M") !== latestMonth) {
      grid.push(
        <WeekLabel key={week.id} offset={weekIndex}>
          {currentMonth.format("MMM")}
        </WeekLabel>,
      );
      latestMonth = currentMonth.format("M");
    }

    let clientSessions = week.sessions.map((session) => sessionsMap[session]);
    clientSessions = clientSessions
      .filter((session) => session.appointment[0].client.id === clientId)
      .sort((a, b) => (moment(a.start_time).isAfter(b.start_time) ? 1 : -1));

    const elements = [];
    for (const session of clientSessions) {
      elements.push(<SessionSquare key={session.id} session={session} />);
    }

    if (elements.length === 0) {
      elements.push(
        <Tooltip
          title={`No appointments week ${week.week_number} of ${week.year}`}
          key={week.id}
        >
          <PlaceholderSquare />
        </Tooltip>,
      );
    }

    grid.push(
      <SessionsWeekContainer key={`${week.year}${week.week_number}`}>
        {elements}
      </SessionsWeekContainer>,
    );
    weekIndex++;
  }

  return <SessionsGridContainer>{grid}</SessionsGridContainer>;
}

function SessionSquare(props: { session: PanelSession }) {
  const timezone = useTimezoneProvider();
  const time = DateTime.fromISO(props.session.start_time).setZone(timezone);
  return (
    <Tooltip
      key={props.session.id}
      title={time.toLocaleString(DateTime.DATETIME_FULL)}
    >
      <SessionSquareElement
        state={props.session.appointment[0].appointment_status}
      />
    </Tooltip>
  );
}

interface ClientModalStateProps {
  clientMetaMap: ClientMetaMap;
  weeksMap: ClinicianWeeksMap;
  sessionsMap: PanelSessionsMap;
  currentUser: CurrentUser | null;
}

interface ClientModalOwnProps {
  dataId: string;
}

interface ClientModalDispatchProps {
  setClientNote: (id: number, noteContent: string) => Promise<AxiosResponse>;
  setClientCadence: (
    id: number,
    cadence: ClientCadence,
  ) => Promise<AxiosResponse>;
  toggleClientTermination: (id: number) => void;
}

function _ClientModal(
  props: ClientModalStateProps & ClientModalOwnProps & ClientModalDispatchProps,
) {
  const clientMeta: PanelClientMeta = props.clientMetaMap[props.dataId];

  const [client, setClient] = React.useState(clientMeta.client);
  const noteContent = clientMeta.note_content;

  const [cadenceState, setCadenceState] = React.useState<ClientCadence>(
    clientMeta.cadence_override,
  );

  // Use client to determine whether to reset modalState
  if (clientMeta.client !== client) {
    setCadenceState(clientMeta.cadence_override);
    setClient(clientMeta.client);
  }

  const onCadenceChange = (value: SelectValue) => {
    if (typeof value === "string") {
      props.setClientCadence(client.id, value as ClientCadence);
      setCadenceState(value as ClientCadence);
    } else {
      throw new Error("Expected string value");
    }
  };

  const name = `
    ${clientMeta.client.first_name}
    ${client.preferred_name ? `(${client.preferred_name})` : ""}
    ${client.last_name}`;

  const sessionsGrid = createSessionsGrid(
    client.id,
    props.weeksMap,
    props.sessionsMap,
  );

  const cadenceOptions = Object.entries(CadenceEnum).map((cadence) => (
    <DeprecatedAntdSelect.Option key={cadence[0]} value={cadence[0]}>
      {cadence[1]}
    </DeprecatedAntdSelect.Option>
  ));

  const contactInfo = [];
  if (client.phone_number) {
    contactInfo.push(
      <span style={{ marginRight: "0.75em" }}>Tel: {client.phone_number}</span>,
    );
  }

  if (client.email) {
    contactInfo.push(<span>Email: {client.email}</span>);
  }

  const overviewSection: JSX.Element = (
    <div style={{ display: "flex" }}>
      <div style={{ maxWidth: "50%" }}>
        <h4>Overview</h4>
        {sessionsGrid}
      </div>
    </div>
  );

  return (
    <ModalContent key={`${client.id}`}>
      <ModalTitle>
        <h3>
          <Mangle>{name}</Mangle>
        </h3>
        {contactInfo && (
          <h4>
            <Mangle>{contactInfo}</Mangle>
          </h4>
        )}
        {client.healthie_id && (
          <ModalExternalLink
            href={`https://twochairs.gethealthie.com/users/${client.healthie_id}`}
          >
            Healthie Chart
          </ModalExternalLink>
        )}
        <DebugView>
          <ModalExternalLink href={`${API_URL}/admin/ehr/client/${client.id}/`}>
            Chronicler
          </ModalExternalLink>
        </DebugView>
      </ModalTitle>
      <ModalSection>
        {overviewSection}
        <SessionsData meta={clientMeta} />
      </ModalSection>
      <ModalSection>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h4>Client Cadence</h4>
          <DeprecatedAntdSelect
            size="middle"
            onChange={onCadenceChange}
            defaultValue={cadenceState}
            dropdownClassName="clickable-cell"
          >
            {cadenceOptions}
          </DeprecatedAntdSelect>
        </div>
        {UserHasAllPermissions(props.currentUser, ["IsSuperUser"]) &&
          (clientMeta.status.state === "terminated" ||
            clientMeta.status.state === "inactive") && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "1em",
              }}
            >
              <h4>Client Status</h4>
              <Button
                danger
                onClick={() => props.toggleClientTermination(client.id)}
              >
                {clientMeta.status.state === "inactive" ? "Mark" : "Unmark"}
                &nbsp;as Terminated
              </Button>
            </div>
          )}
      </ModalSection>
      <NoteTextArea
        initialText={noteContent}
        updateFn={(note) => props.setClientNote(client.id, note)}
      />
    </ModalContent>
  );
}

function SessionsData(props: { meta: PanelClientMeta }) {
  const [view, setView] = React.useState<"alltime" | "inrange">("alltime");
  let statusCounts: ClientAppointmentStatusCountMap;
  const inRange = props.meta.sessionCountInRange;
  const allTime = props.meta.status;
  if (view === "inrange") {
    statusCounts = {
      attended: inRange.attended,
      canceled: inRange.canceled,
      noshow: inRange.noshow,
      scheduled: inRange.scheduled,
    };
  } else {
    statusCounts = {
      attended: allTime.attended_appts,
      canceled: allTime.canceled_appts,
      noshow: allTime.noshow_appts,
      scheduled: allTime.future_appts,
    };
  }

  return (
    <SessionsDataSuperContainer>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4>Sessions Data</h4>
        <RadioGroup
          defaultValue={view}
          size="small"
          onChange={(e: RadioChangeEvent) => setView(e.target.value)}
        >
          <RadioButton value="inrange">In Range</RadioButton>
          <RadioButton value="alltime">All Time</RadioButton>
        </RadioGroup>
      </div>
      <SessionsDataContainer>
        <div>
          {statusCounts.attended}
          <span>Attended</span>
        </div>
        <div>
          {statusCounts.noshow}
          <span>No Shows</span>
        </div>
        <div>
          {statusCounts.scheduled}
          <span>Scheduled</span>
        </div>
      </SessionsDataContainer>
    </SessionsDataSuperContainer>
  );
}

// override antd styles with a lot of importants
const SessionsDataSuperContainer = styled.div`
  * {
    border: 0 !important;
  }
  .ant-radio-group {
    font-size: 12px;
  }
  .ant-radio-button-wrapper {
    color: rgba(0, 0, 0, 0.3) !important;
  }
  .ant-radio-button-wrapper-checked {
    box-shadow: none !important;
    color: #000 !important;
  }
  .ant-radio-button-wrapper:not(:first-child)::before {
    display: none !important;
  }
`;

const SessionsDataContainer = styled.div`
  display: flex;
  justify-content: space-around;
  > div {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    font-size: 1.2em;
    > span {
      font-size: 14px;
    }
  }
`;

const SessionsGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, ${SquareSize}px);
  grid-gap: 4px;
  padding-bottom: 16px;
  overflow-x: scroll;
`;

const SessionsWeekContainer = styled.div`
  grid-row-start: 2;
  display: flex;
  flex-direction: column;
`;

interface SessionSquareProps {
  state: "attended" | "canceled" | "noshow" | null | string;
}

const SessionSquareElement = styled.div<SessionSquareProps>`
  background-color: #aaa;
  width: ${SquareSize}px;
  height: ${SquareSize}px;
  margin-bottom: 4px;
  background: ${(p) => sessionBgColor(p.state, 5)};
`;

const PlaceholderSquare = styled.div`
  width: ${SquareSize}px;
  height: ${SquareSize}px;
  border: 1px solid ${$antdBorder};
`;

const WeekLabel = styled.span<{ offset: number }>`
  font-size: 10pt;
  color: ${$greyText};
  grid-column-start: ${(p) => p.offset};
`;

const mapDispatchToProps: ReactRedux.MapDispatchToProps<
  ClientModalDispatchProps,
  ClientModalOwnProps
> = (dispatch: Dispatcher, ownProps) => ({
  setClientNote: (id: number, noteContent: string) =>
    dispatch(panelOperations.setClientNote(id, noteContent)),
  setClientCadence: (id: number, cadence: ClientCadence) =>
    dispatch(panelOperations.setClientCadence(id, cadence)),
  toggleClientTermination: (id: number) =>
    dispatch(panelOperations.toggleClientTermination(id)),
});

const clientModalMapStateToProps: ReactRedux.MapStateToProps<
  ClientModalStateProps,
  ClientModalOwnProps,
  AppState
> = (state) => {
  return {
    clientMetaMap: state.panel.clientMetaMap,
    sessionsMap: state.panel.sessionsMap,
    weeksMap: state.panel.weeksMap,
    currentUser: state.auth.currentUser,
  };
};

const ClientModal = ReactRedux.connect(
  clientModalMapStateToProps,
  mapDispatchToProps,
)(_ClientModal);

export default ClientModal;
