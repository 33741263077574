import isNil from "lodash/isNil";

const requiredFields = [
  "consult_modality",
  "self_injurious_behavior",
  "endorsed_current_ipv",
  "criterion_trauma_endorsed",
  "prelim_dx_code_1",
  "goals_text",
  "readiness_to_engage",
  "client_cadence",
  "functional_impairment",
  "initial_treatment_plan",
];

export const checkMissingRequiredFields = (consultNote: any) => {
  return requiredFields.filter((rf) => {
    if (rf === "endorsed_current_ipv") {
      // This is saying that we allow them to submit if they did respond to endorsed_prior_ipv
      return (
        (!(rf in consultNote) || isNil(consultNote[rf])) &&
        (!("endorsed_prior_ipv" in consultNote) ||
          isNil(consultNote["endorsed_prior_ipv"]))
      );
    } else if (rf === "initial_treatment_plan") {
      // isNil doesn't catch empty string values "".
      // Adding this check to the block below causes Criterion A validation to fail
      // for some reason.
      return consultNote[rf] === "";
    } else {
      return !(rf in consultNote) || isNil(consultNote[rf]);
    }
  });
};
