import { AxiosResponse } from "axios";

import api, { wrapApiCall } from "../../../api";
import {
  APIClinicianWeek,
  AppointmentClient,
  EventMbcStatus,
  IdMap,
  MBCClientAdherence,
  PanelClient,
  PanelClientMeta,
  PanelClinicianWeek,
  PanelMeta,
  PanelSession,
} from "../../../api/types";
import {
  CadenceEnum,
  ClientAppointmentStatusCountMap,
  ClientCadence,
  ClientFilterFn,
  ClientFilters,
  ClientMetaMap,
  ClientRowIndices,
  ClientsMap,
  ClientSort,
  ClientStatusEnum,
  DateRange,
  EventMBCStatusesMap,
  StatifiedActiveClientFilters,
} from "../../../app/panel-management/types";
import { AsyncActionCreator, AsyncActionWithResultCreator } from "../types";
import { getInitialFilters, panelActions } from "./";

export const getClientsMeta: AsyncActionWithResultCreator<AxiosResponse> = (
  ids: number[],
) => async (dispatch, getState) => {
  const { panel } = getState();
  const route = `/ehr/panels/${panel.clinicianId}/meta/clients/${ids.join(
    ",",
  )}`;

  const res: AxiosResponse<PanelClientMeta[]> = await wrapApiCall(
    api.get(route),
    dispatch,
  );
  const panelClientMetaMap: ClientMetaMap = {};
  res.data.forEach(v => (panelClientMetaMap[v.client.id] = v));
  dispatch(panelActions.processClientMeta(panelClientMetaMap));
  return res;
};

export const toggleClientTermination: AsyncActionCreator = (id: number) => (
  dispatch,
  getState,
) => {
  const { panel } = getState();
  const route = `/ehr/panels/${panel.clinicianId}/meta/clients/${id}/toggle_termination`;
  return wrapApiCall(api.post(route), dispatch).then(() => {
    dispatch(getClientsMeta([id]));
  });
};

export const setSessionOrWeekNote: AsyncActionWithResultCreator<AxiosResponse> = (
  type: "week" | "sessions",
  id: string,
  noteContent: string,
) => async (dispatch, getState) => {
  const { panel } = getState();
  const res = await wrapApiCall(
    api.patch(
      `/ehr/panels/${panel.clinicianId}/${
        type === "week" ? "week" : "meta/sessions"
      }/${id}`,
      { note_content: noteContent },
    ),
    dispatch,
  );
  if (type === "week") {
    const data = res.data as APIClinicianWeek;
    const processedData: PanelClinicianWeek = {
      ...data,
      sessions: data.sessions.map(s => s.id),
    };
    dispatch(panelActions.updateWeekMeta(processedData));
  } else {
    dispatch(
      panelActions.updateSessionMeta({ id, meta: res.data as PanelMeta }),
    );
  }
  return res;
};

export const setClientNote: AsyncActionWithResultCreator<AxiosResponse> = (
  id: number,
  noteContent: string,
) => async (dispatch, getState) => {
  const { panel } = getState();
  const res = await wrapApiCall(
    api.patch(`/ehr/panels/${panel.clinicianId}/meta/clients/${id}`, {
      note_content: noteContent,
    }),
    dispatch,
  );
  dispatch(panelActions.processClientMeta({ [res.data.client.id]: res.data }));
  return res;
};

export const setClientCadence: AsyncActionWithResultCreator<AxiosResponse> = (
  id: number,
  cadence: ClientCadence,
) => async (dispatch, getState) => {
  const { panel } = getState();
  const res = await wrapApiCall(
    api.patch(`/ehr/panels/${panel.clinicianId}/meta/clients/${id}`, {
      cadence_override: cadence,
    }),
    dispatch,
  );
  dispatch(panelActions.processClientMeta({ [res.data.client.id]: res.data }));
  return res;
};

export const getClinicianWeekSessions: AsyncActionCreator = (
  id: number,
  dateRange?: DateRange,
) => async dispatch => {
  dispatch(panelActions.startLoadingPanel());
  let route = `/ehr/panels/${id}`;
  if (dateRange) {
    // adds 10 hours to maintain the same day when converted to UTC on the backend.
    // dateRange.start is localized to the 0:00 of that day, which can be bumped to the previous day in UTC
    // 10 hours is an adequate buffer for a couple hours in each direction
    const startTime = `start_year=${dateRange.start.isoWeekYear()}&start_week=${dateRange.start
      .add(10, "hours")
      .isoWeek()}`;
    const endTime = `end_year=${dateRange.end.isoWeekYear()}&end_week=${dateRange.end.isoWeek()}`;
    route = `${route}?${startTime}&${endTime}`;
  }
  const res: AxiosResponse<APIClinicianWeek[]> = await wrapApiCall(
    api.get(route),
    dispatch,
  );
  const data = res.data;
  dispatch(buildClientsFromWeeks(data));
  const processedData: PanelClinicianWeek[] = [];
  const sessions: PanelSession[] = [];
  data.sort((a, b) => {
    return a.year - b.year || a.week_number - b.week_number;
  });
  data.forEach(week => {
    const weekConsults: number[] = [];
    const weekSessions = week.sessions as PanelSession[];
    const weekSessionsIds: number[] = [];
    weekSessions.forEach(session => {
      if (!isValidPanelSession(session)) {
        return;
      }

      sessions.push(session);
      if (session.procedure !== 2) {
        weekSessionsIds.push(session.id);
      } else {
        const consultStatus = session.appointment[0].appointment_status;
        if (!["canceled", "noshow", null].includes(consultStatus)) {
          weekConsults.push(session.id);
        }
      }
    });

    const processedWeek = {
      ...week,
      sessions: weekSessionsIds,
      consults: weekConsults,
    };
    processedData.push(processedWeek);
  });
  dispatch(
    panelActions.processClinicianWeeks({ weeks: processedData, sessions }),
  );
};

function isValidPanelSession(session: PanelSession) {
  // no consults
  if (session.procedure === 2) {
    return false;
  }

  // no buddy zero-appointment maljoins
  if (session.appointment.length === 0) {
    return false;
  }

  const appointment = session.appointment[0];

  // no sessions w/o client
  if (!appointment.client) {
    return false;
  }

  return true;
}

const buildClientsFromWeeks: AsyncActionCreator = (
  weeks: APIClinicianWeek[],
) => (dispatch, getState) => {
  const { panel } = getState();
  const clients: PanelClient[] = [];
  const clientIds: number[] = [];
  const clientsMap: ClientsMap = {};
  const clientToSessionsMap: { [id: number]: number[] } = {};
  const clientSessionsCount: {
    [id: string]: { sessionCountInRange: ClientAppointmentStatusCountMap };
  } = {};
  const eventIds: string[] = [];

  for (const week of weeks) {
    for (const session of week.sessions) {
      if (!isValidPanelSession(session)) {
        continue;
      }
      const sessionObj = session;
      eventIds.push(session.event_id);
      const client = sessionObj.appointment[0].client;
      if (!clientIds.includes(client.id)) {
        clients.push(client);
        clientsMap[client.id] = client;
        clientIds.push(client.id);
        clientToSessionsMap[client.id] = [];
        clientSessionsCount[client.id] = {
          sessionCountInRange: {
            attended: 0,
            scheduled: 0,
            canceled: 0,
            noshow: 0,
          },
        };
      }
      const sessionStatus = session.appointment[0].appointment_status;

      clientSessionsCount[client.id].sessionCountInRange[
        sessionStatus ? sessionStatus : "scheduled"
      ] += 1;
      clientToSessionsMap[client.id].push(session.id);
    }
  }

  const clientRowIndices = generateIndicesForClients(clients);
  const processAllClientData = () =>
    new Promise<void>(resolve => {
      // xform client sessions count into the PartialPanelClientMeta model
      dispatch(panelActions.processClientMeta(clientSessionsCount));
      dispatch(panelActions.setClientToSessionsMap(clientToSessionsMap));
      dispatch(
        panelActions.processClients({
          clients,
          filteredClientIds: clientIds,
          clientRowIndices,
          clientsMap,
        }),
      );
      resolve();
    });

  processAllClientData().then(async () => {
    dispatch(getMbcEventStatusByEvent(eventIds));
    await dispatch(getClientsMeta(clientIds, clientToSessionsMap));
    dispatch(filterClients(panel.clientFilters));
  });
};

export const sortClients: AsyncActionCreator = (clientSort: ClientSort) => (
  dispatch,
  getState,
) => {
  dispatch(panelActions.startLoadingPanel());
  dispatch(panelActions.setSort(clientSort));
  localStorage.setItem("defaultSort", clientSort);
  const { panel } = getState();
  const clientsWithMeta = panel.filteredClientIds.map(id => ({
    ...panel.clientMetaMap[id],
    ...panel.clientsMap[id],
  }));
  type ClientWithMeta = typeof clientsWithMeta[0];

  let sortedClients = [];
  let sortFn: (a: ClientWithMeta, b: ClientWithMeta) => number = (a, b) => 1;

  switch (clientSort) {
    case "byLastName":
      sortFn = (a, b) => a.last_name.localeCompare(b.last_name);
      break;
    case "byFirstName":
      sortFn = (a, b) => a.first_name.localeCompare(b.first_name);
      break;
    case "byNumSessions":
      sortFn = (a, b) =>
        panel.clientToSessionMap[b.id].length -
        panel.clientToSessionMap[a.id].length;
      break;
    case "byStatus": {
      const statuses = Object.keys(ClientStatusEnum);
      sortFn = (a, b) =>
        statuses.indexOf(a.status.state) - statuses.indexOf(b.status.state);
      break;
    }
    case "byCadence": {
      const cadences = Object.keys(CadenceEnum);
      sortFn = (a, b) =>
        cadences.indexOf(a.cadence_override) -
        cadences.indexOf(b.cadence_override);
      break;
    }
    case "byMBCPreSessionAdherence": {
      sortFn = (a, b) =>
        (b.mbcAdherenceScores?.preSession || 0) -
        (a.mbcAdherenceScores?.preSession || 0);
      break;
    }
  }

  sortedClients = clientsWithMeta.sort(sortFn);
  const sortedClientIds = sortedClients.map(c => c.id);
  const clientRowIndices = generateIndicesForClients(sortedClients);
  dispatch(
    panelActions.setFilteredClients({
      filteredClientIds: sortedClientIds,
      clientRowIndices,
    }),
  );
  setTimeout(() => dispatch(panelActions.finishLoadingPanel()), 100);
};

export const filterClients: AsyncActionCreator = (
  clientFilters: StatifiedActiveClientFilters,
) => (dispatch, getState) => {
  localStorage.setItem("defaultFilter", JSON.stringify(clientFilters));
  const clientFilterFns: ClientFilterFn[] = [];
  if (clientFilters.byCadence) {
    clientFilterFns.push(ClientFilters.byCadence(clientFilters.byCadence));
  }
  if (clientFilters.byStatus) {
    clientFilterFns.push(ClientFilters.byStatus(clientFilters.byStatus));
  }

  dispatch(panelActions.startLoadingPanel());
  const { panel } = getState();
  const clientsWithMeta = Object.values(panel.clientsMap).map(c => ({
    ...c,
    meta: { ...panel.clientMetaMap[c.id] },
  }));
  if (clientsWithMeta.length === 0) {
    return;
  }
  try {
    const filteredClients =
      clientFilterFns.length !== 0
        ? clientFilterFns.reduce(
            (clients, filter) => clients.filter(filter),
            clientsWithMeta,
          )
        : clientsWithMeta;

    const filteredClientIds = filteredClients.map(c => c.id);
    const clientRowIndices = generateIndicesForClients(filteredClients);
    dispatch(
      panelActions.setFilteredClients({ filteredClientIds, clientRowIndices }),
    );
    dispatch(sortClients(panel.sort));
    setTimeout(() => dispatch(panelActions.finishLoadingPanel()), 100);
  } catch (e) {
    console.error("Invalid client filters. Resetting...");
    dispatch(resetPanelOptions());
  }
};

export const getMbcEventStatusByEvent: AsyncActionCreator = (
  eventIds: number[],
) => async dispatch => {
  await wrapApiCall(
    api.put(`/api/event-mbc-status/byEventIds/?hide_is_skipped=true`, {
      event_ids: eventIds,
    }),
    dispatch,
  ).then((res: AxiosResponse<EventMbcStatus[]>) => {
    const { data } = res;

    const statusMap: EventMBCStatusesMap = {};
    data.forEach(s => {
      const eventId = s.event;
      const type = s.assessment_type;

      if (!["pre-session"].includes(type)) {
        return;
      }

      if (!(eventId in statusMap)) {
        statusMap[eventId] = [];
      }
      statusMap[eventId].push(s);
    });
    dispatch(panelActions.setMBCEventStatuses(statusMap));
  });
};

function generateIndicesForClients(
  clients: AppointmentClient[],
): ClientRowIndices {
  const clientToIndexMap: { [id: number]: number } = {};
  clients.forEach((client, index) => (clientToIndexMap[client.id] = index + 1));
  return clientToIndexMap;
}

export const resetPanelOptions: AsyncActionCreator = () => dispatch => {
  dispatch(panelActions.startLoadingPanel());
  dispatch(panelActions.setClientFilters(getInitialFilters(true)));
  dispatch(panelActions.finishLoadingPanel());
};
