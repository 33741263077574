import { IconProps } from "../icon.interface";

export const PenIcon = ({ title }: IconProps) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden={!title}
    >
      {title ? <title>{title}</title> : null}
      <path
        d="M20.1614 3.97846C20.4678 3.67204 20.8316 3.42898 21.2319 3.26315C21.6323 3.09732 22.0614 3.01196 22.4947 3.01196C22.928 3.01196 23.3571 3.09732 23.7575 3.26315C24.1579 3.42898 24.5216 3.67204 24.828 3.97846C25.1345 4.28488 25.3775 4.64865 25.5434 5.049C25.7092 5.44936 25.7945 5.87845 25.7945 6.31179C25.7945 6.74513 25.7092 7.17423 25.5434 7.57459C25.3775 7.97494 25.1345 8.33871 24.828 8.64513L9.07804 24.3951L2.66138 26.1451L4.41138 19.7285L20.1614 3.97846Z"
        stroke="#171717"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
