import { Divider } from "antd";
import * as React from "react";
import { useShallowEqualSelector } from "../../../_helpers/redux";
import Mangle from "../../../_shared/Mangle";

export default () => {
  const [client] = useShallowEqualSelector((state) => [
    state.consultnote?.openConsult?.client,
  ]);

  return (
    <div>
      <div>
        <b>Client:</b>
        <div style={{ paddingLeft: "15px" }}>
          <Mangle>
            Name: {client?.first_name} {client?.last_name} ({client?.email})
            <br />
            Payer Name: {client?.latest_referral_for_client.payer_name}
            <br />
            State: {client?.latest_referral_for_client.provider_state}
            <br />
            Referring Diagnosis: {client?.latest_referral_for_client.diagnosis}
            <br />
            Reason for Care: {client?.latest_referral_for_client.reason_for_care}
            <br />
            Referring Provider: {client?.latest_referral_for_client.referring_provider}
          </Mangle>
        </div>
      </div>
      <Divider />
    </div>
  );
};
