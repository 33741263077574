import React, { useContext } from "react";

interface MBCContext {
  alertCount: number;
  setCount: (v: number) => void;
}
const MbcContext = React.createContext<MBCContext>({
  alertCount: 0,
  setCount: _v => {},
});

export const MbcProvider = ({ children }: { children: React.ReactNode }) => {
  const [count, setCount] = React.useState(0);
  return (
    <MbcContext.Provider value={{ alertCount: count, setCount }}>
      {children}
    </MbcContext.Provider>
  );
};
export const useMbcCount = () => {
  const { alertCount, setCount } = useContext(MbcContext);
  return { alertCount, setCount };
};
