import { Flex, Text, RadixTooltip } from "@/app/design-system";
import { BarGraphIcon, CircleCheckMarkIcon } from "@/app/design-system/icons";
import { NotesIcon } from "@/app/design-system/icons/components/notes-icon";
import { DateTime } from "luxon";
import { GroupEventApiData } from "../../_api/group-therapy.interface";
import { CurrentUserContext } from "@/app/app.utils";
import { useContext } from "react";
import { TIMEZONE } from "@/app/my-clients/utils";

interface ClientsSessionsMBCProps {
  mbcCompleted: number | undefined;
  nextSession: GroupEventApiData | null | undefined;
  totalSessions: number;
  numClients: number;
  groupStartTime: string;
}

const NumClientsText = ({ numClients }: { numClients: number }) => {
  return (
    <Flex
      gap={8}
      alignItems={"center"}
      justifyContent={"flex-start"}
      css={{ minWidth: 120 }}
    >
      <NotesIcon />
      <Text css={{ fontWeight: 600 }}>{numClients} </Text>
      <Text> clients</Text>
    </Flex>
  );
};

const getSessionNumber = (
  timezone: string,
  nextSession?: GroupEventApiData | null,
) => {
  if (nextSession) {
    const now = DateTime.now();
    const nextSessionDateTime = DateTime.fromISO(nextSession.eventStartTime, {
      zone: timezone,
    });
    const isNextSessionInThePast = nextSessionDateTime < now;
    if (isNextSessionInThePast) {
      return nextSession.sessionNumber;
    }
    return nextSession.sessionNumber - 1;
  }
  return 0;
};

const NumSessionText = ({
  nextSession,
  groupStartTime,
  totalSessions,
  sessionNumber,
}: {
  nextSession: GroupEventApiData | null | undefined;
  groupStartTime: string;
  totalSessions: number;
  sessionNumber: number;
}) => {
  const isStarting = groupStartTime === nextSession?.eventStartTime ?? false;
  const circleCheckMarkIconColor = isStarting ? "#adb2ba" : "#46a758";
  return (
    <Flex gap={8} alignItems={"center"} css={{ minWidth: 165 }}>
      <CircleCheckMarkIcon fill={circleCheckMarkIconColor} />
      <Text css={{ fontWeight: 600, rightPadding: 4 }}>
        {sessionNumber} / {totalSessions}
      </Text>
      <Text> sessions</Text>
    </Flex>
  );
};

const MBCCompleted = ({
  mbcCompleted,
  sessionNumber,
}: {
  mbcCompleted: number | undefined;
  sessionNumber: number;
}) => {
  const isMBC = mbcCompleted === -1 ? false : true;
  const completedMBCPercent = mbcCompleted
    ? Math.round(mbcCompleted * 100)
    : null;
  const sessionsAverageText =
    sessionNumber < 6
      ? "session 1"
      : sessionNumber < 12
      ? "sessions 1 and 6"
      : "sessions 1, 6, 12";
  return mbcCompleted && isMBC ? (
    <Flex
      justifyContent={"flex-end"}
      gap={12}
      css={{
        "@breakpoint1200": {
          justifyContent: "space-between",
        },
      }}
    >
      <RadixTooltip
        width={300}
        content={`On avg, for ${sessionsAverageText}, ${completedMBCPercent}% of the wellness surveys in this group have been completed`}
      >
        <Flex gap={4}>
          <BarGraphIcon />
          <Text css={{ fontWeight: 600 }}>{completedMBCPercent}%</Text>
          <Text> completed</Text>
        </Flex>
      </RadixTooltip>
    </Flex>
  ) : (
    <Flex
      justifyContent={"flex-end"}
      gap={12}
      css={{
        "@breakpoint1200": {
          justifyContent: "space-between",
        },
      }}
    ></Flex>
  );
};

export const ClientsSessionsMBC = ({
  mbcCompleted,
  nextSession,
  totalSessions,
  numClients,
  groupStartTime,
}: ClientsSessionsMBCProps) => {
  const cuser = useContext(CurrentUserContext);
  const timezone = TIMEZONE(cuser);
  const sessionNumber = getSessionNumber(timezone, nextSession);

  return (
    <Flex css={{ flexGrow: 10, justifyContent: "space-between" }}>
      {/* Clients */}
      <NumClientsText numClients={numClients} />
      {/* Sessions */}
      <NumSessionText
        groupStartTime={groupStartTime}
        totalSessions={totalSessions}
        nextSession={nextSession}
        sessionNumber={sessionNumber}
      />
      {/* MBC */}
      <MBCCompleted mbcCompleted={mbcCompleted} sessionNumber={sessionNumber} />
    </Flex>
  );
};
