import { forwardRef } from "react";
import { EventApiData } from "../../../../../../../../../api/use-my-clients-overview/my-clients-data.interface";
import { DownChevronIcon } from "../../../../../../../../../design-system/icons";
import {
  Flex,
  styledStitches,
  Text,
} from "../../../../../../../../../design-system/index";
import { SessionCancellationInfo } from "./components/session-cancellation-type";
import { SessionClinician } from "./components/session-clinician";
import { SessionStatus } from "./components/session-status";
import { SessionMetaData } from "./session-meta-data";
import { SessionPaneContainer } from "./session-pane.styled";

export const StyledChevron = styledStitches(DownChevronIcon, {
  transition: "transform 300ms cubic-bezier(0.87, 0, 0.13, 1)",
  "[data-state=open] &": { transform: "rotate(180deg)" },
});

interface SessionPaneProps
  extends Pick<
    EventApiData,
    | "startTime"
    | "recurrenceData"
    | "appointmentStatus"
    | "clinicianId"
    | "cancellationType"
    | "cancellationReason"
    | "serviceType"
    | "clinician"
    | "meta"
  > {
  isSessionAppintmentStatusNoShowOrCanceled: boolean;
  shouldShowViewNoteButton?: boolean;
}

export const SessionPane = forwardRef<HTMLDivElement, SessionPaneProps>(
  ({ children, ...props }, forwardedRef) => {
    const {
      appointmentStatus,
      recurrenceData,
      cancellationType,
      cancellationReason,
      serviceType,
      startTime,
      clinician,
      shouldShowViewNoteButton,
    } = props;

    return (
      <SessionPaneContainer ref={forwardedRef}>
        <SessionMetaData
          appointmentStatus={appointmentStatus}
          recurrenceData={recurrenceData}
          startTime={startTime}
        />

        <Flex gap={12} css={{ flexGrow: 1 }}>
          <SessionClinician
            serviceType={serviceType}
            clinicianName={clinician?.fullName}
          />
          {/**
           * if no cancellation type, we default to the old model of cancellations types
           **/}

          <Flex
            justifyContent={"space-between"}
            gap={16}
            css={{ flexGrow: 1, minWidth: "fit-content" }}
          >
            {(appointmentStatus === "noshow" && cancellationReason) ||
            cancellationType ? (
              <SessionCancellationInfo
                cancellationType={cancellationType}
                cancellationReason={cancellationReason}
                appointmentStatus={appointmentStatus}
              />
            ) : (
              <SessionStatus appointmentStatus={appointmentStatus} />
            )}

            <Flex alignItems={"center"} gap={4} css={{ width: 105 }}>
              {shouldShowViewNoteButton ? (
                <>
                  <Text color={"$neutral11"}>View note</Text>
                  <StyledChevron aria-hidden />
                </>
              ) : null}
            </Flex>
          </Flex>
        </Flex>
      </SessionPaneContainer>
    );
  },
);
