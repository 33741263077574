import { Flex, Spinner, H2, Text } from "@/app/design-system";
import { QuarterCircle } from "@/app/design-system";
import useTitle from "@/app/hooks/useTitle";
import { GroupTherapyContainer } from "./_components/groups.styled";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useGroupTherapyRedirect } from "./_hooks/use-group-therapy-redirect";
import { useGroupTherapyUser } from "./_hooks/use-group-therapy-user";
import { ClinicianPickerV2 } from "@/app/_components/clinician-picker-v2/clinician-picker-v2";
import { useMyClientsSearchStore } from "@/app/my-clients/routes/my-clients-page/my-clients-model/my-clients.model";
import { useDispatch } from "react-redux";
import { loadMDBWithClinicianId } from "@/state/models/dashboard/operations";

import { useGroupTherapyPage } from "./_api/use-group-therapy-page";
import { GroupNavigationTabs } from "./_components/group-navigation-tabs";
import { useEffect } from "react";
import { store } from "@/index";
import { MainContentHeader } from "@/app/_shared/MainContentHeader";

export const GroupTherapyPage = () => {
  const cuser = store.getState()?.auth?.currentUser;

  useTitle("Group Therapy", false);

  const { clinicianId, isClinicalLeaderOrSuperuser } = useGroupTherapyUser();

  useGroupTherapyRedirect();

  const { setCurrentlySelectedClinician, currentlySelectedClinician } =
    useMyClientsSearchStore();

  const clinicianIdParam = clinicianId
    ? `clinician_id=${clinicianId}`
    : currentlySelectedClinician
    ? `clinician_id=${currentlySelectedClinician}`
    : "";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (
      !Number(searchParams.get("clinician_id")) &&
      currentlySelectedClinician
    ) {
      navigate(`?${clinicianIdParam}`);
    }
  }, [pathname]);

  const handleClinicianPickerSelect = (clinicianId: null | number) => {
    setCurrentlySelectedClinician(clinicianId);
    dispatch(loadMDBWithClinicianId(clinicianId));
    navigate(`?clinician_id=${clinicianId}`);
  };
  const { groupTherapyOverviewData, isLoading } = useGroupTherapyPage();

  return (
    <GroupTherapyContainer flexDirection={"column"}>
      <MainContentHeader
        css={{
          "@breakpoint1200": {
            flexDirection: "column",
            alignItems: "flex-start",
          },
          flexWrap: "wrap",
          gap: 8,
        }}
      >
        <Flex gap={8} alignItems={"center"}>
          <H2>Group Therapy</H2>

          {isLoading ? (
            <Flex css={{ marginLeft: 8 }}>
              <Spinner>
                <QuarterCircle dimension={"1.5em"} />
              </Spinner>
            </Flex>
          ) : null}
        </Flex>

        <Flex
          css={{
            "@breakpoint640": {
              flexDirection: "column-reverse",
            },
            gap: 8,
          }}
        >
          <Text color={"$neutral11"} fontSize="14">
            Times displayed in&nbsp;
            <strong>
              {/* https://stackoverflow.com/questions/1091372/getting-the-clients-time-zone-and-offset-in-javascript */}
              {cuser?.clinician?.primary_timezone ||
                Intl.DateTimeFormat().resolvedOptions().timeZone}
            </strong>
          </Text>

          {isClinicalLeaderOrSuperuser ? (
            <ClinicianPickerV2 callback={handleClinicianPickerSelect} />
          ) : null}
        </Flex>
      </MainContentHeader>

      <GroupNavigationTabs
        groupTherapyOverviewData={groupTherapyOverviewData ?? []}
      />
    </GroupTherapyContainer>
  );
};
