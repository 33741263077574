import { styledStitches } from "@/app/design-system/styles/stitches.config";
import { ClientOverviewApiData } from "../../../../../api/use-my-clients-overview/my-clients-data.interface";
import { DxCodeMap } from "../../../../../client/types";
import { Stack } from "../../../../../design-system/layout/stack";
import { MatchingInfoByClientIdApiData } from "../../../../api/use-matching-data-by-clients/select-data-for-matching-data-by-client.util";
import { ClientProfileNavContactInfo } from "./client-profile-nav-contact-info";
import { ClientProfileNavTherapyInfo } from "./client-profile-nav-therapy-info";

import { UseGetMBC } from "@/app/client/use-mbc/use-get-mbc";
import { useGetEpisodesOfCare } from "@/app/client/use-mbc/use-get-mbc-episodes-of-care";
import { useGetDiagnosisCodes } from "../../../components/use-get-diagnosis-code";
import { CurrentUserContext, CurrentConfigContext } from "@/app/app.utils";
import { userCanAccessHealthie } from "@/app/_helpers/permissions";
import React from "react";

import {
  getEpisodeNumberToClinicianDataMap,
  getTotalSessionCountFromLatestEoc,
  mergeMBCData,
} from "@/app/eoc-utils";
import { LoadingOutlined } from "@ant-design/icons";
import { EOCEpisodeData } from "../../../../../types";
import { SessionHistoryModal } from "./session-history-modal";

export const ClientProfileNavInfoContainer = styledStitches(Stack, {
  width: 300,
  fontFamily: "Inter, arial",
});

export interface ClientProfileNavInfoProps {
  client: ClientOverviewApiData | null;
  clientMatchingData: MatchingInfoByClientIdApiData | null;
  dxCodeMap: DxCodeMap;
}

export const ClientProfileNavInfo = ({
  client,
  clientMatchingData,
  dxCodeMap,
}: ClientProfileNavInfoProps) => {
  let mergedEocData;
  let isLoading, mbcQueryError: boolean | undefined;

  const mbcResponse = UseGetMBC({ clientId: client?.clientId });
  const episodesOfCareResponse = useGetEpisodesOfCare({
    clientIds: client?.clientId ? [client?.clientId] : [],
    enabled: true,
    select: (data) => {
      return data.dw_export_episode_of_care;
    },
  });

  isLoading = episodesOfCareResponse.isLoading || mbcResponse.isLoading;
  let eocs: Map<number, EOCEpisodeData>,
    sortedEocs: EOCEpisodeData[] = [];

  if (client && !isLoading) {
    mergedEocData = mergeMBCData(
      mbcResponse.data,
      episodesOfCareResponse?.data ? episodesOfCareResponse.data : [],
    );
    mbcQueryError = episodesOfCareResponse.isError;

    eocs = getEpisodeNumberToClinicianDataMap(mergedEocData);
    sortedEocs = [...eocs.values()].sort(
      (map1: EOCEpisodeData, map2: EOCEpisodeData) => {
        return map1.episode - map2.episode;
      },
    );
  }
  const eocTotalSessionCount = mergedEocData
    ? getTotalSessionCountFromLatestEoc(mergedEocData)
    : 0;

  const currentUser = React.useContext(CurrentUserContext);

  const config = React.useContext(CurrentConfigContext);

  const hasHealthieAccess = userCanAccessHealthie(currentUser, config);

  const { data: diagnosisCodes, isLoading: isDxCodeLoading } =
    useGetDiagnosisCodes(
      client?.healthieId ? [client?.healthieId as string] : [],
      hasHealthieAccess && client?.healthieId ? true : false,
    );

  if (
    !isDxCodeLoading &&
    diagnosisCodes &&
    client &&
    client.healthieId &&
    diagnosisCodes[client.healthieId]
  ) {
    client.primaryDiagnosis = diagnosisCodes[client.healthieId][0] ?? null;
  }

  return (
    <ClientProfileNavInfoContainer gap={24}>
      <ClientProfileNavContactInfo
        firstName={client?.firstName ?? ""}
        lastName={client?.lastName ?? ""}
        preferredName={client?.preferredName ?? ""}
        email={client?.email ?? ""}
        phoneNumber={client?.phoneNumber ?? ""}
        clientMatchingData={clientMatchingData}
        isHighAcuity={client?.isHighAcuity ?? false}
      />
      <div>
        <ClientProfileNavTherapyInfo
          clientPayerServiceRecord={client?.clientPayerServiceRecord ?? null}
          baselineSeverity={client?.severity ?? ""}
          totalSessionsAttendedWithCurrentClinician={client?.totalSessionsAttendedWithCurrentClinician ?? null}
          totalSessionsAttended={client?.totalSessionsAttended ?? null}
          goalNumberOfSessionsRange={client?.suggestedRange ?? ""}
          diagnosis={
            client?.primaryDiagnosis && !isDxCodeLoading
              ? client?.primaryDiagnosis
              : null
          }
          isDxCodeLoading={isDxCodeLoading}
          dxCodeMap={dxCodeMap}
          manualSlotReservation={client?.manualSlotReservation ?? null}
          welkinLink={client?.welkinLink ?? null}
          healthieLink={client?.healthieLink ?? null}
          suggestedRange={client?.suggestedRange ?? null}
          careLocation={client?.careLocation ?? null}
          isLoading={isLoading}
          client={client}
        />
        {isLoading ? <LoadingOutlined /> : null}
        {client &&
        !isLoading &&
        mergedEocData &&
        sortedEocs &&
        sortedEocs.length > 0 ? (
          <SessionHistoryModal
            sortedEocs={sortedEocs}
            mbcQueryError={mbcQueryError}
          />
        ) : null}
      </div>
    </ClientProfileNavInfoContainer>
  );
};
