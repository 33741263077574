import React from "react";
import { Notification } from "@/app/design-system";
import { MainContentHeader } from "@/app/_shared/MainContentHeader";
import useTitle from "@/app/hooks/useTitle";
import { BackLink } from "@/app/_shared/BackLink";
import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DxCodeMap } from "../../../client/types";
import { Flex, styledStitches, Text } from "../../../design-system";
import { useFetchDxCodes } from "../../api/use-fetch-dxcodes/use-fetch-dxcodes";
import { getDiagnosisCodeMap } from "../../utils";
import { MyClientsContainer } from "../my-clients-page/my-clients.styled";
import { ClientProfileNavigation } from "./client-profile-nav/client-profile-nav";
import { useClientProfilePage } from "./use-client-profile-page";
import { CurrentUserContext, CurrentConfigContext } from "@/app/app.utils";

export const ClientProfilePage = () => {

  useTitle("My Clients", false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { clinicianId, client, clientMatchingData } = useClientProfilePage();
  const { data: dxCodeData } = useFetchDxCodes();
  const diagnosisCodeMap: DxCodeMap = getDiagnosisCodeMap(dxCodeData);

  Sentry.setContext("clientProfile", {
    client,
    clinicianId,
  });

  const clinicianIdQueryParam = clinicianId
    ? `?clinician_id=${clinicianId}`
    : "";

  let target;
  if (window.history.length > 1) {
    target = -1;
  } else {
    target = `/my-clients${clinicianIdQueryParam}`;
  }

  const cuser = React.useContext(CurrentUserContext);

  return (
    <MyClientsContainer flexDirection={"column"}>
      <MainContentHeader>
        <Flex alignItems={"center"}>
          <BackLink target={target}>Go Back</BackLink>
        </Flex>
        <Flex alignItems={"center"}>
          <Text color={"$neutral11"} fontSize="14">
            Times displayed in&nbsp;
            <strong>
              {/* https://stackoverflow.com/questions/1091372/getting-the-clients-time-zone-and-offset-in-javascript */}
              {cuser?.clinician?.primary_timezone ||
                Intl.DateTimeFormat().resolvedOptions().timeZone}
            </strong>
          </Text>
        </Flex>
      </MainContentHeader>

      <ClientProfileNavigation
        client={client ?? null}
        clientMatchingData={clientMatchingData}
        dxCodeMap={diagnosisCodeMap}
      />
      <Notification />
    </MyClientsContainer>
  );
};
