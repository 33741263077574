import { Flex, styledStitches } from "@/app/design-system";
import { UnstyledButton } from "@/app/design-system/button/button";
import { XIcon } from "@/app/design-system/icons";

export const NavInfoContainer = styledStitches(Flex, {
  borderTop: "1px solid",
  borderColor: "$neutral7",
  justifyContent: "space-between",
  py: 14,
  gap: "16px",

  "&:nth-last-of-type(1)": {
    borderBottom: "1px solid",
    borderColor: "$neutral7",
  },

  ":last-child": {
    textAlign: "end",
  },
});

export const PreferredNameContainer = styledStitches(Flex, {
  backgroundColor: "#323D54",
  borderRadius: "8px",
  padding: "12px 8px",
  color: "white",
});

export const StyledXIcon = styledStitches(XIcon, {
  color: "white",
  cursor: "pointer",
});

export const SuperUnstyledButton = styledStitches(UnstyledButton, {
  "&:hover, &:focus": {
    backgroundColor: "rgba(255, 255, 255, 0)",
  },
});
