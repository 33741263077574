export const questions = [
  {
    questionNumber: 1,
    title: "Client demographics info",
    subtitle: "",
    model: "consult_note",
    slug: "demographics_info",
  },
  {
    questionNumber: 2,
    title: "Consult Modality",
    subtitle: "",
    model: "consult_note",
    slug: "consult_modality",
    isRequired: true,
    defaultValue: "teletherapy",
  },
  {
    questionNumber: 3,
    title: "Consult Training",
    subtitle:
      ": Please indicate if this consult was conducted as part of consult training.",
    model: "consult_note",
    slug: "consult_training",
    defaultValue: "none",
  },
  {
    questionNumber: 4,
    title: "Teletherapy Checklist",
    subtitle: "",
    model: "consult_note",
    slug: "multi - teletherapy consent",
    isRequired: true,
  },
  {
    questionNumber: 5,
    title: "Physical Location",
    subtitle:
      ": Confirmed client’s physical location at the time of the video Consult Appointment",
    model: "consult_note",
    slug: "multi",
    isRequired: true,
  },
  {
    questionNumber: 6,
    title: "Presenting Problem(s)",
    subtitle: ": Identify presenting problems here.",
    model: "consultnote_presentingproblems",
    slug: "multi",
    isRequired: true,
  },
  {
    questionNumber: 7,
    title: "Suicidal Ideation / Homicidal Ideation / Self-Injurious Behavior",
    subtitle: "",
    model: "consultnote_selfinjuriousbehavior",
    slug: "multi",
    isRequired: true,
  },
  {
    questionNumber: 7.5,
    title: "HIGH RISK CLIENTS ONLY",
    subtitle:
      ": If client endorsed current active SI or HI, record a safety plan and risk management actions below.",
    model: "consultnote",
    slug: "high_risk_plan",
    isRequired: true,
  },
  {
    questionNumber: 8,
    title: "Cognitive Dementia",
    subtitle: "",
    model: "consultnote",
    slug: "multi",
  },
  {
    questionNumber: 9,
    title: "Interpersonal Violence (IPV)",
    subtitle: "",
    model: "consultnote",
    slug: "multi",
    isRequired: true,
  },
  {
    questionNumber: 10,
    title: "Substance Use:",
    subtitle: "",
    model: "consultnote_substanceuse",
    slug: "multi",
    isRequired: true,
  },
  {
    questionNumber: 11,
    title: "Criterion A Trauma",
    subtitle:
      ": (exposure (direct, witnessing, learning about, or indirect) to actual or threatened death, injury, or sexual violence)",
    model: "consultnote_substanceuse",
    slug: "multi",
    isRequired: true,
  },
  {
    questionNumber: 12,
    title: "Other Trauma / Socio-Emotional / Relational History (if needed)",
    subtitle: "",
    model: "consult_note",
    slug: "other_emotional_history_text",
  },

  {
    questionNumber: 13,
    title: "Functional Impairment",
    subtitle: ": Please indicate the extent to which:",
    model: "consult_note",
    slug: "multi - functional impairment",
    isRequired: true,
  },

  {
    questionNumber: 14,
    title: "Preliminary Diagnosis Code - 1",
    subtitle: "",
    model: "consult_note",
    slug: "prelim_dx_code_1",
    isRequired: true,
  },
  {
    questionNumber: 15,
    title: "Preliminary Diagnosis Code - 2",
    subtitle: "",
    model: "consult_note",
    slug: "prelim_dx_code_2",
  },

  {
    questionNumber: 16,
    title: "Preliminary Diagnosis Code - Other",
    subtitle: " (google ICD-10 code)",
    model: "consult_note",
    slug: "prelim_dx_code_other_text",
  },
  {
    questionNumber: 17,
    title: "Goals",
    subtitle:
      ": Please describe client's goals / hopes / expectations for therapy.",
    model: "consult_note",
    slug: "goals_text",
    isRequired: true,
  },
  {
    questionNumber: 18,
    title: "Initial Treatment Plan",
    subtitle: ": Please describe your initial treatment plan recommendation.",
    model: "consult_note",
    slug: "initial_treatment_plan",
    isRequired: true,
  },
  {
    questionNumber: 19,
    title: "Prior Therapy",
    subtitle: ": If needed, add additional information (please use sparingly).",
    model: "consult_note",
    slug: "prior_therapy_text",
  },
  {
    questionNumber: 20,
    title: "Readiness to Engage",
    subtitle: " (clinician rating): ",
    model: "consult_note",
    slug: "readiness_to_engage",
    isRequired: true,
  },

  {
    questionNumber: 21,
    title: "Did you discuss group therapy with the client?",
    subtitle: "",
    model: "consult_note",
    slug: "group_discussed",
    isRequired: false,
  },
  {
    questionNumber: 21.1,
    title: "Why not?",
    subtitle: "",
    model: "consult_note",
    slug: "group_discussed_no_reason",
    isRequired: false,
    hideNumber: true,
  },
  {
    questionNumber: 21.2,
    title: "Please describe",
    subtitle: "",
    model: "consult_note",
    slug: "group_discussed_other_description",
    isRequired: false,
    hideNumber: true,
  }, 

  {
    questionNumber: 22,
    title: "Was the client clinically appropriate for groups?",
    subtitle: "",
    model: "consult_note",
    slug: "group_clinically_appropriate",
    isRequired: false,
  },
  {
    questionNumber: 22.1,
    title: "Why not?",
    subtitle: " (Select all that apply): ",
    model: "consult_note",
    slug: "group_clinically_appropriate_no_reason",
    isRequired: false,
    hideNumber: true,
  },
  {
    questionNumber: 22.2,
    title: "Please describe",
    subtitle: "",
    model: "consult_note",
    slug: "group_clinically_appropriate_other_description",
    isRequired: false,
    hideNumber: true,
  },

  {
    questionNumber: 23,
    title: "Was the client appropriate for Two Chairs groups?",
    subtitle: "",
    model: "consult_note",
    slug: "group_two_chairs_appropriate",
    isRequired: false,
  },
  {
    questionNumber: 23.1,
    title: "Why not?",
    subtitle: "",
    model: "consult_note",
    slug: "group_two_chairs_appropriate_no_reason",
    isRequired: false,
    hideNumber: true,
  },
  {
    questionNumber: 23.2,
    title: "Please describe",
    subtitle: "",
    model: "consult_note",
    slug: "group_two_chairs_appropriate_other_description",
    isRequired: false,
    hideNumber: true,
  },
  {
    questionNumber: 23.3,
    title: "Will the client enroll in Two Chairs groups?",
    subtitle: "",
    model: "consult_note",
    slug: "group_two_chairs_appropriate_yes_intention",
    isRequired: false,
    hideNumber: true,
  },
  {
    questionNumber: 23.4,
    title: "Why not?",
    subtitle: "",
    model: "consult_note",
    slug: "group_two_chairs_appropriate_yes_intention_no_reason",
    isRequired: false,
    hideNumber: true,
  },
  {
    questionNumber: 23.5,
    title: "Please describe",
    subtitle: "",
    model: "consult_note",
    slug: "group_two_chairs_appropriate_yes_intention_other_description",
    isRequired: false,
    hideNumber: true,
  },

  {
    questionNumber: 24,
    title: "Referred Out",
    subtitle:
      ": Does this client need additional or alternative care outside of Two Chairs?",
    model: "consult_note",
    slug: "referred_out_text",
  },
  {
    questionNumber: 24.1,
    title: "What is the reason for referring?",
    subtitle: " (Select all that apply): ",
    model: "consult_note",
    slug: "referred_out_reason",
    hideNumber: true,
  },
  {
    questionNumber: 24.2,
    title: "Please explain",
    subtitle: "",
    model: "consult_note",
    slug: "referred_out_other_description",
    hideNumber: true,
  },
  {
    questionNumber: 24.3,
    title: "What type of care does this client need?",
    subtitle: "",
    model: "consult_note",
    slug: "referred_out_required_care",
    hideNumber: true,
  },
  {
    questionNumber: 24.4,
    title: "Please explain",
    subtitle: "",
    model: "consult_note",
    slug: "referred_out_required_care_other_description",
    hideNumber: true,
  },
  {
    questionNumber: 24.5,
    title: "Is this client continuing care at Two Chairs?",
    subtitle: "",
    model: "consult_note",
    slug: "referred_out_continuing_care",
    hideNumber: true,
  },
  {
    questionNumber: 24.6,
    title: "Please explain",
    subtitle: "",
    model: "consult_note",
    slug: "referred_out_continuing_care_other_description",
    hideNumber: true,
  },
  {
    questionNumber: 24.7,
    title: "Have you communicated this referral recommendation to the client?",
    subtitle: "",
    model: "consult_note",
    slug: "referred_out_client_comms",
    hideNumber: true,
  },
  {
    questionNumber: 24.8,
    title: "Is there a specific facility or clinician this client should be referred to?",
    subtitle: "",
    model: "consult_note",
    slug: "referred_out_specificity",
    hideNumber: true,
  },
  {
    questionNumber: 24.9,
    title: "Does the client have a gender preference for the provider they are referred to?",
    subtitle: "",
    model: "consult_note",
    slug: "referred_out_gender_preference",
    hideNumber: true,
  },
  // 24.10 is resolved as 24.1. Since we are hiding the numbers it doesn't really matter
  {
    questionNumber: 24.11,
    title: "Is there any additional context important for getting this client connected to the appropriate care provider?",
    subtitle: "",
    model: "consult_note",
    slug: "referred_out_additional_context",
    hideNumber: true,
  },

  {
    questionNumber: 25,
    title: "Past / Present Focus",
    subtitle: "",
    model: "consult_note",
    slug: "multi",
    isRequired: true,
    instructionText:
      "Would you like your therapy to focus on exploring your childhood and past and how that impacts you today OR would you prefer to focus on what’s going on with you now and the future, and not focus on the past?",
  },
  {
    questionNumber: 26,
    title: "Therapist Structure",
    subtitle: "",
    model: "consult_note",
    slug: "multi",
    isRequired: true,
    instructionText:
      "When thinking about the structure of a therapy session, would you prefer to work in a modality where the provider is leading and structuring the content and focus of the session, OR would you prefer to lead and explore in session?",
  },
  {
    questionNumber: 27,
    title: "Therapist Directiveness",
    subtitle: "",
    model: "therapy_preferences",
    slug: "multi",
    isRequired: true,
    instructionText:
      "Would you prefer to work with someone who redirects you to keep you focused on mutually established goals and might offer specific suggestions and direction OR would you prefer to work with someone who is less directive and non-prescriptive in their approach?",
  },
  {
    questionNumber: 28,
    title: "Use of Homework",
    subtitle: "",
    model: "therapy_preferences",
    slug: "multi",
    isRequired: true,
    instructionText:
      "Some therapies incorporate out of session practice assignments - things like tracking your mood/sleep, or practicing a coping skill for anxiety mgmt. Do you have a strong preference to incorporate out of session assignments as part of your therapy, are open to homework but don't have a strong preference either way, OR would you prefer not to incorporate out of session assignments?",
  },
  {
    questionNumber: 29,
    title: "Therapist Expressiveness",
    subtitle: "",
    model: "therapy_preferences",
    slug: "multi",
    isRequired: true,
    instructionText:
      "Would you like to work with a provider who is openly expressive and may occasionally share something personal about themselves if appropriate OR would you prefer a provider who is more emotionally reserved?",
  },
  {
    questionNumber: 30,
    title: "Demographic Preferences",
    subtitle:
      ": Copy any demographic preferences from the Client Profile, and adjust as needed based on the Consult Appt.",
    model: "consult_note",
    slug: "multi",
  },

  {
    questionNumber: 31,
    title: "Client Cadence",
    subtitle:
      ": Suggested initial cadence for matching (based on client preference and clinician assessment of appropriateness)",
    model: "consult_note",
    slug: "client_cadence",
    isRequired: true,
  },

  {
    questionNumber: 32,
    title: "General Observations",
    subtitle: "",
    model: "mental_status",
    slug: "multi",
  },
  {
    questionNumber: 33,
    title: "Thought Process",
    subtitle: "",
    model: "mental_status",
    slug: "multi",
  },
  {
    questionNumber: 34,
    title: "Attitude",
    subtitle: "",
    model: "mental_status",
    slug: "multi",
  },

  {
    questionNumber: 35,
    title: "Other",
    subtitle:
      ": If necessary, please elaborate on the Mental Status indicated above (please use sparingly)",
    model: "consult_note",
    slug: "other_text",
  },
];
