import { CurrentUser } from "@/api/types";
import { TIMEZONE } from "@/app/my-clients/utils";
import { GraphDataPointData } from "@/app/_components/graph/graph.types";
import { DateTime } from "luxon";
import { ParsedClientEventMbcScoresAndSeverities } from "./mbc.util";

type MBCDataType = 'GAD' | 'PHQ' | 'cohesion';

export const GroupMBCStatus = {
  remission: "Remission",
  improvement: "Improvement",
  stagnation: "Stagnation",
  escalate: "Escalate",
  noChange: "No Change",
} as const;

export const getStatusColorFromStatus = (status?: string) => {
  if (status === GroupMBCStatus.improvement) {
    return "#18794E";
  }
  if (status === GroupMBCStatus.escalate) {
    return "#CD2B31";
  }
  if (status === GroupMBCStatus.stagnation) {
    return "#F7CE00";
  }
  if (status === GroupMBCStatus.remission) {
    return "#006ADC";
  }
  if (status === GroupMBCStatus.noChange) {
    return "#fff";
  }
  return undefined;
};

export const getGraphDataPointFromParsedMBCScore = (
  parsedMBCScore: ParsedClientEventMbcScoresAndSeverities,
  dataTypeMBC: MBCDataType,
  cuser: CurrentUser | null,
): GraphDataPointData => {

  const date = DateTime.fromISO(parsedMBCScore.eventDate)
    .setZone(TIMEZONE(cuser));

  const value = dataTypeMBC !== 'cohesion' ? parsedMBCScore[dataTypeMBC].score : parsedMBCScore.cohesion;
  const label = date.toLocaleString(DateTime.DATE_SHORT);
  const tooltipLabel = `Session ${parsedMBCScore.sessionNumber} - ${date.toFormat('LLL d')}`;
  const status = dataTypeMBC !== 'cohesion' ? parsedMBCScore[dataTypeMBC].status : undefined;
  const statusColor = getStatusColorFromStatus(status);
  const endorsedSI = parsedMBCScore.endorsedSI ?? undefined;

  const graphDataPoint = {
    value,
    label,
    tooltipLabel,
    status,
    statusColor,
    endorsedSI,
  };

  return graphDataPoint;
};
