import { styledStitches } from "@/app/design-system/styles/stitches.config";

export const CollapseSidebarButton = styledStitches("button", {
  border: "none",
  cursor: "pointer",
  backgroundColor: "$neutral3",
  display: "flex",
  py: 14,
  height: 40,
  alignItems: "center",
  alignContent: "center",
  textAlign: "center",
  justifyContent: "center",

  borderRight: "1px solid $neutral4",
});
