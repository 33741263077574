import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import {
  Control,
  useForm,
  UseFormReturn,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import * as yup from "yup";
import { RecurringSessionsOptions } from "../../session-actions.constants";
import {
  billableCancellationTypeOptions,
  CancellationType,
  clientCancellationReasons,
  clinicianCancellationReasons,
  lateCancelByClientReasons,
  nonBillableCancellationTypeOptions,
  noShowCancellationReasons,
} from "./cancel-session-form.constants";

export interface CancelSessionFormFields {
  cancellationType: string;
  cancellationReason: string;
  attendanceNote: string;
  sessionsToUpdate: string;
}

export enum CancelSessionFieldNames {
  CANCELLATION_TYPE = "cancellationType",
  CANCELLATION_REASON = "cancellationReason",
  ATTENDANCE_NOTE = "attendanceNote",
  SESSIONS_TO_UPDATE = "sessionsToUpdate",
}

export const useCancelSessionForm = () => {
  const initialCancellationFormFields: CancelSessionFormFields = {
    cancellationType: "Select",
    cancellationReason: "Select",
    attendanceNote: "",
    sessionsToUpdate: RecurringSessionsOptions.THIS_SESSION_ONLY,
  };

  const schema = yup.object({
    [CancelSessionFieldNames.CANCELLATION_TYPE]: yup
      .string()
      .not(["Select"])
      .required(),
    [CancelSessionFieldNames.CANCELLATION_REASON]: yup
      .string()
      .not(["Select"])
      .required(),
    [CancelSessionFieldNames.ATTENDANCE_NOTE]: yup.string(),
    [CancelSessionFieldNames.ATTENDANCE_NOTE]: yup.string(),
  });

  const methods = useForm<CancelSessionFormFields>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      ...initialCancellationFormFields,
    },
  });

  const {
    control,
    formState: { isValid },
    getValues,
    setValue,
    handleSubmit,
    trigger,
    reset,
  } = methods;

  return {
    methods,
    isValid,
    setValue,
    control,
    handleSubmit,
    trigger,
    reset,
    getValues,
  };
};

const mapIdsToOptions = (options: { value: string; label: string }[]) => {
  return options.map(({ label, value }, index) => ({
    id: index.toString(),
    label,
    value,
  }));
};

export const useCancellationReasons = (
  control: Control<CancelSessionFormFields>,
  setValue: UseFormSetValue<CancelSessionFormFields>,
  formMethods: UseFormReturn<CancelSessionFormFields, any>,
) => {
  const selectedCancellationType = useWatch<CancelSessionFormFields>({
    name: CancelSessionFieldNames.CANCELLATION_TYPE,
    control,
  });

  const [conditionalCancellationReasons, setConditionalCancellationReasons] =
    useState(mapIdsToOptions(lateCancelByClientReasons));

  const selectedSesssionsToCancel = useWatch<CancelSessionFormFields>({
    name: CancelSessionFieldNames.SESSIONS_TO_UPDATE,
    control,
  });

  const [conditionalCancellationType, setConditionalCancellationType] =
    useState(mapIdsToOptions(billableCancellationTypeOptions));

  useEffect(() => {
    if (selectedCancellationType === CancellationType.CANCELED_BY_CLIENT) {
      setConditionalCancellationReasons(clientCancellationReasons);
      setValue("cancellationReason", "Select");
    } else if (
      selectedCancellationType === CancellationType.CANCELED_BY_CLINICIAN
    ) {
      setConditionalCancellationReasons(clinicianCancellationReasons);
      setValue("cancellationReason", "Select");
    } else if (
      selectedCancellationType === CancellationType.LATE_CANCEL_BY_CLIENT
    ) {
      setConditionalCancellationReasons(lateCancelByClientReasons);
      setValue("cancellationReason", "Select");
    } else if (selectedCancellationType === CancellationType.NO_SHOW) {
      setConditionalCancellationReasons(noShowCancellationReasons);
      setValue("cancellationReason", "No show (missed session)");
    }
  }, [selectedCancellationType]);

  useEffect(() => {
    if (
      selectedSesssionsToCancel ===
      RecurringSessionsOptions.ALL_FUTURE_RECURRING
    ) {
      setConditionalCancellationType(nonBillableCancellationTypeOptions);

      if (
        selectedCancellationType === CancellationType.NO_SHOW ||
        selectedCancellationType === CancellationType.LATE_CANCEL_BY_CLIENT
      ) {
        formMethods.resetField("cancellationType");
        formMethods.resetField("cancellationReason");
      }
    } else {
      setConditionalCancellationType([
        ...nonBillableCancellationTypeOptions,
        ...billableCancellationTypeOptions,
      ]);
    }
  }, [selectedSesssionsToCancel]);

  return [conditionalCancellationReasons, conditionalCancellationType];
};
