export const substanceOptions = [
  { key: "OPTION__none", value: "none", title: "None" },
  { key: "OPTION__alcohol", value: "alcohol", title: "Alcohol" },
  { key: "OPTION__benzodiazepines", value: "benzodiazepines", title: "Benzodiazepines" },
  { key: "OPTION__hallucinogens_entheogens", value: "hallucinogens_entheogens", title: "Hallucinogens/Entheogens" },
  { key: "OPTION__cannabis", value: "cannabis", title: "Cannabis" },
  { key: "OPTION__nicotine", value: "nicotine", title: "Nicotine" },
  { key: "OPTION__opiates", value: "opiates", title: "Opiates" },
  { key: "OPTION__stimulants", value: "stimulants", title: "Stimulants" },
  { key: "OPTION__other", value: "other", title: "Other" },
];

export const frequencyOptions = [
  { key: "OPTION__none", value: "none", title: "None" },
  { key: "OPTION__daily", value: "daily", title: "Daily" },
  { key: "OPTION__four_to_six_week", value: "four_to_six_week", title: "4-6x per week" },
  { key: "OPTION__two_to_four_week", value: "two_to_four_week", title: "2-4x per week" },
  { key: "OPTION__one_to_two_week", value: "one_to_two_week", title: "1-2x per week" },
  { key: "OPTION__one_to_three_month", value: "one_to_three_month", title: "1-3x per month" },
  { key: "OPTION__one_to_ten_year", value: "one_to_ten_year", title: "1-10x per year" },
];

export const intensityOptions = [
  { key: "OPTION__none", value: "none", title: "None" },
  { key: "OPTION__low", value: "low", title: "Low" },
  { key: "OPTION__moderate", value: "moderate", title: "Moderate" },
  { key: "OPTION__high", value: "high", title: "High" },
];
