import * as React from "react";
import moment from "moment";
import { Pad, Title } from "../../../../app/_layout/display";
import Mangle from "../../../_shared/Mangle";
import { useShallowEqualSelector } from "../../../_helpers/redux";
import { Row, Flex } from "../../../_layout/Flex";
import ClinicianItem from "../../../_shared/ClinicianItem";
import { Consult } from "../../../../api/types";
import { $kpBlue } from "../../../../assets/colors";
import { matchmakerUtils } from "../../../../state/models/matchmaker";

export default () => {
  const [consult, match, rematch, clientProfileData] = useShallowEqualSelector(
    (state) => [
      state.matchmaker.initData.consult,
      state.matchmaker.initData.match,
      state.matchmaker.initData.rematch,
      state.matchmaker.clientProfileData,
    ],
  );
  const client = useShallowEqualSelector(matchmakerUtils.clientSelector);

  const consultAppointment = React.useMemo(() => {
    if (consult) {
      return consult;
    }
    if (match?.consult) {
      return match.consult;
    }
    if (typeof rematch?.consult === "object") {
      return rematch.consult as Consult;
    }
    return null;
  }, [match, consult, rematch]);

  if (!client) return null;

  let consultClinicianId = null;
  if (consultAppointment)
    consultClinicianId =
      typeof consultAppointment.clinician === "number"
        ? consultAppointment.clinician
        : consultAppointment.clinician.id;

  const cpCoi =
    clientProfileData && clientProfileData.conflictOfInterest
      ? clientProfileData.conflictOfInterest
      : match?.conflict_of_interest;

  return (
    <Pad>
      <Row layout="start start">
        <Flex>
          <strong>Client:</strong>
          <div style={{ paddingLeft: "10px" }}>
            <Mangle>{`${client.first_name} ${client.last_name} (${client.email})`}</Mangle>
          </div>
          {client.payers.map((p) => (
            <Title size="xs" key={p.id} style={{ margin: 0, color: $kpBlue }}>
              {p.payer.display_name} Client
            </Title>
          ))}
          {client.is_medicare && (
            <Title size="xs" style={{ margin: 0, color: $kpBlue }}>
              MediCare Client
            </Title>
          )}
          {client.is_medical && (
            <Title size="xs" style={{ margin: 0, color: $kpBlue }}>
              MediCal Client
            </Title>
          )}
          <strong>Conflict of Interest (from Client Profile):</strong>
          <div style={{ paddingLeft: "10px" }}>
            {cpCoi ? cpCoi : <i>Question not answered.</i>}
          </div>
        </Flex>
        <Flex>
          <strong>Consult:</strong>
          <div style={{ paddingLeft: "10px" }}>
            {consultClinicianId && (
              <ClinicianItem
                clinicianId={consultClinicianId}
                hideClinic={true}
              />
            )}
            {consultAppointment && (
              <div>
                <i>{moment(consultAppointment.start_time).format("ll @ LT")}</i>
              </div>
            )}
            {!consultAppointment && (
              <div>
                <i>
                  This Match was created using the "Match by Email" feature.
                </i>
              </div>
            )}
          </div>
        </Flex>
      </Row>
    </Pad>
  );
};
