import { filterTagNameToTagStateMap } from "../../components/filter-tags/filter-tag.util";
import {
  getIsClientMildToModerateAndExceeding17Sessions,
  getIsClientModerateToSevereAndExceeding25Sessions,
  getIsClientSubclinicalAndExceeding10Sessions,
} from "../../components/search-and-filter/filters/filter-checkboxes/mbc-metrics.util";
import {
  BaselineSeverityFilterState,
  CadenceFilterState,
  ClientStatusFilterState,
  MBCMetricsFilterState,
  MBCStatusFilterState,
  MyClientsClient,
  PayerFilterState,
  TotalSessionsFilterState,
  TreatmentTrackFiltersState,
} from "../../my-clients-model";

export const getFilterOnClientStatus = (
  client: MyClientsClient,
  clientStatusFilterState: ClientStatusFilterState,
) => {
  const isAnyClientStatusFilterChecked = Object.values(
    clientStatusFilterState,
  ).includes(true);

  if (isAnyClientStatusFilterChecked) {
    if (!client.matchingInfo?.matchingStatus) {
      return false;
    }

    // Prospective , Pending, New, Active, Inactive, Graduated
    const filter =
      filterTagNameToTagStateMap[client.matchingInfo?.matchingStatus];

    return clientStatusFilterState[filter];
  } else {
    return true;
  }
};

export const getFilterOnMBCStatus = (
  client: MyClientsClient,
  mbcStatusFilterState: MBCStatusFilterState,
) => {
  // if any filters are selected, return if a client matches any of the checked filters
  const isAnyMbcStatusFilterChecked =
    Object.values(mbcStatusFilterState).includes(true);

  if (isAnyMbcStatusFilterChecked) {
    if (!client.mbcTrackStatus) {
      return false;
    }

    // Prospective , Pending, New, Active, Inactive

    let status = client.mbcTrackStatus;
    if (status === "Reliable Change") {
      status = "Graduate";
    }
    // for filtering purposes, reliable change is the same thing as graduate
    const filter = filterTagNameToTagStateMap[status];

    return mbcStatusFilterState[filter];
  } else {
    return true;
  }
};

export const getFilterOnCadence = (
  client: MyClientsClient,
  cadenceFilterState: CadenceFilterState,
) => {
  const filterKeys = {
    weekly: "Weekly",
    biweekly: "Biweekly",
  };

  const isAnyCadenceFilterChecked =
    Object.values(cadenceFilterState).includes(true);

  if (isAnyCadenceFilterChecked) {
    if (
      !(
        client.manualSlotReservation &&
        client.manualSlotReservation.length > 0 &&
        client.manualSlotReservation[0].recurrence
      )
    ) {
      const filter = filterTagNameToTagStateMap["No cadence"];

      return cadenceFilterState[filter];
    }

    const tagState = filterKeys[client?.manualSlotReservation[0]?.recurrence];

    const filter = filterTagNameToTagStateMap[tagState];

    return cadenceFilterState[filter];
  } else {
    return true;
  }
};

export const getFilterOnTreatmentTrack = (
  client: MyClientsClient,
  treatmentTrackFilterState: TreatmentTrackFiltersState,
) => {
  const filterKeys = {
    GAD: "GAD7 - Anxiety",
    PHQ: "PHQ9 - Depression",
  };

  // if any filters are selected, return if a client matches any of the checked filters
  const isAnyTreatmentTrackFilterChecked = Object.values(
    treatmentTrackFilterState,
  ).includes(true);

  if (isAnyTreatmentTrackFilterChecked) {
    if (client.primaryAssessment === null) {
      const filter = filterTagNameToTagStateMap["No track"];

      return treatmentTrackFilterState[filter];
    }

    const tagState = filterKeys[client.primaryAssessment];

    const filter = filterTagNameToTagStateMap[tagState];

    return treatmentTrackFilterState[filter];
  } else {
    return true;
  }
};

export const getFilterOnTotalSessions = (
  client: MyClientsClient,
  totalSessionsFilterState: TotalSessionsFilterState,
): any => {
  // if any are selected, return if a client matches any
  const isAnyTotalSessionsFilterChecked = Object.values(
    totalSessionsFilterState,
  ).includes(true);
  if (isAnyTotalSessionsFilterChecked) {
    if (
      client.totalSessionsAttendedWithCurrentClinician === undefined ||
      client.totalSessionsAttendedWithCurrentClinician === null
    ) {
      return false;
    }

    if (
      client?.totalSessionsAttendedWithCurrentClinician >= 0 &&
      client?.totalSessionsAttendedWithCurrentClinician <= 9
    ) {
      return totalSessionsFilterState["isFilteringOn0to9Sessions"];
    }

    if (
      client?.totalSessionsAttendedWithCurrentClinician > 10 &&
      client?.totalSessionsAttendedWithCurrentClinician <= 16
    ) {
      return totalSessionsFilterState["isFilteringOn10to16Sessions"];
    }

    if (
      client?.totalSessionsAttendedWithCurrentClinician > 17 &&
      client?.totalSessionsAttendedWithCurrentClinician <= 25
    ) {
      return totalSessionsFilterState["isFilteringOn17to25Sessions"];
    }

    if (client?.totalSessionsAttendedWithCurrentClinician > 25) {
      return totalSessionsFilterState["isFilteringOn25OrMoreSessions"];
    }

    return false;
  } else {
    return true;
  }
};

export const getFilterOnBaselineSeverity = (
  client: MyClientsClient,
  baselineSeverityFilterState: BaselineSeverityFilterState,
) => {
  const filterKeys = {
    subclinical: "Subclinical",
    "mild - moderate": "Mild - moderate",
    "moderate - severe": "Moderate - severe",
  };

  // if any are selected, return if a client matches any
  const isAnyBaselineSeverityFilterChecked = Object.values(
    baselineSeverityFilterState,
  ).includes(true);

  if (isAnyBaselineSeverityFilterChecked) {
    if (!client.combinedSeverity) {
      return false;
    }

    const tagState = filterKeys[client.combinedSeverity];

    const filter = filterTagNameToTagStateMap[tagState];

    return baselineSeverityFilterState[filter];
  } else {
    return true;
  }
};

export const getFilterOnPayer = (
  client: MyClientsClient,
  payerFilterState: PayerFilterState,
) => {
  // if any are selected, return if a client matches any
  const isAnyPayerFilterChecked =
    Object.values(payerFilterState).includes(true);

  if (isAnyPayerFilterChecked) {
    if (client.clientPayerServiceRecord === null) {
      const filter = filterTagNameToTagStateMap["DTC"];

      return payerFilterState[filter];
    }

    // Kaiser Permanente Norcal, Kaiser Permanente Washington
    const filter =
      filterTagNameToTagStateMap[
        client.clientPayerServiceRecord.payer.displayName
      ];

    return payerFilterState[filter];
  } else {
    return true;
  }
};

export const getFilterOnMBCMetrics = (
  client: MyClientsClient,
  mbcMetricsFilterState: MBCMetricsFilterState,
) => {
  // if any are selected, return if a client matches any
  const isAnyMBCMetricFilterChecked = Object.values(
    mbcMetricsFilterState,
  ).includes(true);

  if (isAnyMBCMetricFilterChecked) {
    if (!client.combinedSeverity) {
      return false;
    }

    if (getIsClientSubclinicalAndExceeding10Sessions(client)) {
      return mbcMetricsFilterState[
        "isFilteringOnSubclinicalClientsExceeding10Sessions"
      ];
    } else if (getIsClientMildToModerateAndExceeding17Sessions(client)) {
      return mbcMetricsFilterState[
        "isFilteringOnMildToModerateClientsExceeding17Sessions"
      ];
    } else if (getIsClientModerateToSevereAndExceeding25Sessions(client)) {
      return mbcMetricsFilterState[
        "isFilteringOnModerateToSevereClientsExceeding25Sessions"
      ];
    }

    return false;
  } else {
    return true;
  }
};
