import { GroupTherapyNotesEventQuery } from "@/graphql/generated";
import { DateTime } from "luxon";

interface GroupTherapyNotesClientGroupEvent {
  appointmentStatus?: string | null;
  cancellationType?: string | null;
  cancellationReason?: string | null;
  client: GroupTherapyNotesClientInfo;
  clientGroupEventId: string;
  clientId: number;
  eventId?: string;
  meta?: GroupTherapyNotesClientGroupEventMeta | null;
}

interface GroupTherapyNotesClientInfo {
  clientId: number;
  firstName?: string | null;
  lastName?: string | null;
  isTestClient?: boolean;
  welkin8Id?: string | null;
  healthieId?: number | null | string;
  clientGroup?: ClientGroupInfo[] | null;
}

interface ClientGroupInfo {
  cgGroupId: string;
  clientUnenrolledDate?: string | null | undefined;
}

interface GroupTherapyNotesClientGroupEventMeta {
  attendanceNote?: string | null;
}

export const selectGroupTherapyNotesEventAttendance = (
  groupTherapyNotesData: GroupTherapyNotesEventQuery,
  timezone: string,
): ParsedGroupTherapyNotesEventAttendance => {
  const event = groupTherapyNotesData.event[0];
  const groupId = event.groupId;

  const rawClientGroupEvents = event.clientGroupEvents.filter(
    ({ client }) => client?.isTestClient === false,
  );

  const clientGroupEvents = rawClientGroupEvents
    .map((clientGroupEvent) =>
      getParsedClientGroupEvent(clientGroupEvent, groupId, timezone),
    )
    .filter(
      (
        parsedClientGroupEvent,
      ): parsedClientGroupEvent is ParsedClientGroupEvent =>
        parsedClientGroupEvent !== null,
    );

  const sortedClientGroupEvents = sortedClients(clientGroupEvents);

  const data = {
    clientGroupEvents: sortedClientGroupEvents,
    eventStartTime: groupTherapyNotesData.event[0].eventStartTime,
    eventId: event.eventId,
  };

  return data;
};

export interface ParsedGroupTherapyNotesEventAttendance {
  clientGroupEvents: ParsedClientGroupEvent[];
  eventStartTime: string | undefined;
  eventId: string;
}

export interface ParsedClientGroupEvent {
  clientId?: number;
  appointmentStatus?: string | null;
  attendanceNote?: string | null;
  cancellationType?: string | null;
  cancellationReason?: string | null;
  clientGroupEventId: string;
  firstName?: string | null;
  lastName?: string | null;
  welkin8Id?: string | null;
  healthieId?: number | null | string;
  clientUnenrolledDate: string | null;
}

const getParsedClientGroupEvent = (
  clientGroupEvent: GroupTherapyNotesClientGroupEvent,
  groupId: string | undefined,
  timezone: string,
): ParsedClientGroupEvent | null => {
  const {
    appointmentStatus,
    cancellationType,
    cancellationReason,
    client,
    clientGroupEventId,
    meta,
  } = clientGroupEvent;

  if (!client || client?.isTestClient === true) {
    return null;
  }

  const unenrolledDate = getParsedUnenrolledDate(
    clientGroupEvent,
    groupId,
    timezone,
  );

  return {
    clientId: client?.clientId,
    appointmentStatus,
    attendanceNote: meta?.attendanceNote || "",
    cancellationType,
    cancellationReason,
    clientGroupEventId,
    firstName: client?.firstName || "",
    lastName: client?.lastName || "",
    welkin8Id: client?.welkin8Id || "",
    healthieId: client?.healthieId || null,
    clientUnenrolledDate: unenrolledDate,
  };
};

const getParsedUnenrolledDate = (
  clientGroupEvent: GroupTherapyNotesClientGroupEvent,
  groupId: string | undefined,
  timezone: string,
): string | null => {
  const matchingClientGroup = clientGroupEvent.client.clientGroup?.find(
    (cg) => cg.cgGroupId === groupId,
  );

  if (
    !groupId ||
    !matchingClientGroup ||
    !matchingClientGroup.clientUnenrolledDate
  ) {
    return null;
  }

  return DateTime.fromISO(matchingClientGroup.clientUnenrolledDate, {
    zone: timezone,
  }).toFormat("M/d/yy");
};

const sortedClients = (
  clientGroupEvents: ParsedClientGroupEvent[] | undefined,
) => {
  return clientGroupEvents
    ? clientGroupEvents.sort((a, b) => {
        const bUnenrolled = b.clientUnenrolledDate ? true : false;
        const aUnenrolled = a.clientUnenrolledDate ? true : false;
        return Number(aUnenrolled) - Number(bUnenrolled);
      })
    : [];
};
