import {
  CheckboxUnchecked,
  CheckboxChecked,
} from "../../../../../../../../../../design-system/icons";
import {
  Flex,
  Spacer,
  styledStitches,
} from "../../../../../../../../../../design-system/index";
import { MyClientsSessionsAppointmentStatusFilterState } from "../../../../my-clients-schedule-model";

import { Checkbox } from "../../../../../../../../../../design-system";

export const StyledCheckbox = styledStitches(Checkbox.Root, {
  backgroundColor: "$neutral3",
  br: 8,
  py: 12,
  px: 16,
  fontSize: 16,

  "&:active": {
    transform: "scale(0.99)",
  },
});

interface FilterButtonProps {
  children: React.ReactNode;
  isChecked: boolean;
  setFilter: (
    filter: keyof MyClientsSessionsAppointmentStatusFilterState,
    isSelected: boolean,
  ) => void;
  filterName: keyof MyClientsSessionsAppointmentStatusFilterState;
}

export const SessionHistoryFilterCheckbox = ({
  children,
  isChecked,
  setFilter,
  filterName,
}: FilterButtonProps) => {
  return (
    <StyledCheckbox
      checked={isChecked}
      onCheckedChange={() => {
        setFilter(filterName, !isChecked);
      }}
    >
      <Flex alignItems={"center"}>
        {children}
        <Spacer x={16} />

        {isChecked ? <CheckboxChecked /> : <CheckboxUnchecked />}
      </Flex>
    </StyledCheckbox>
  );
};
