import {
  GroupTherapyNavInfoQuery,
  GroupTherapyNavInfoEventFragment,
  GroupTherapyNavInfoClientGroupFragment,
} from "@/graphql/generated";
import { DateTime } from "luxon";

const getUnenrolledDate = (
  clientId: number,
  clientGroups: GroupTherapyNavInfoClientGroupFragment[],
) => {
  const unenrollDate = clientGroups.find(
    (clientGroup) => clientGroup.clientId === clientId,
  )?.unenrolledDate;
  return DateTime.fromISO(unenrollDate);
};

const getAttendanceAverage = (
  events: GroupTherapyNavInfoEventFragment[],
  clientGroups: GroupTherapyNavInfoClientGroupFragment[],
) => {
  let totalClientGroupEvents = 0;
  let totalAttendedTotalClientGroupEvents = 0;

  const clientIdsOfEnrolledClientGroups = clientGroups
    .filter((clientGroup) => !clientGroup.unenrolledDate)
    .map((clientGroup) => clientGroup.clientId);

  for (const event of events) {
    if (event.appointmentStatus === "attended") {
      for (const clientGroupEvent of event.clientGroupEvents) {
        // if client is enrolled, count their attendance
        if (
          clientIdsOfEnrolledClientGroups.includes(clientGroupEvent.clientId)
        ) {
          totalClientGroupEvents++;
          if (clientGroupEvent.appointmentStatus === "attended") {
            totalAttendedTotalClientGroupEvents++;
          }
        }
        // if client is unenrolled, only count their attendance if the event was before their unenroll day
        // we only have unenroll day, not time.  If it's the same day as an event, the client's attendance is not counted
        if (
          !clientIdsOfEnrolledClientGroups.includes(clientGroupEvent.clientId)
        ) {
          const eventStartTime = DateTime.fromISO(event.startTime);
          const clientUnenrolledDate = getUnenrolledDate(
            clientGroupEvent.clientId,
            clientGroups,
          );
          if (
            eventStartTime < clientUnenrolledDate &&
            !eventStartTime.invalidReason &&
            !clientUnenrolledDate.invalidReason
          ) {
            totalClientGroupEvents++;
            if (clientGroupEvent.appointmentStatus === "attended") {
              totalAttendedTotalClientGroupEvents++;
            }
          }
        }
      }
    }
  }

  const attendanceAverage = Math.floor(
    (totalAttendedTotalClientGroupEvents / totalClientGroupEvents) * 100,
  );

  return isNaN(attendanceAverage) ? null : attendanceAverage;
};

export const selectDataForGroupDetailsNav = (
  data: GroupTherapyNavInfoQuery,
  timezone: string,
) => {
  const group = data?.group[0];
  const events = group?.events;
  const clientGroups = group?.clientGroups;

  const sessionsAttended = events.filter((event) => {
    const now = DateTime.now();
    return (
      now > DateTime.fromISO(event.startTime) &&
      event.appointmentStatus !== "canceled"
    );
  }).length;

  const firstSessionDate = DateTime.fromISO(events[0].startTime)
    .setZone(timezone)
    .toFormat("MMM d");

  const endSessionDate = DateTime.fromISO(events[events.length - 1].startTime)
    .setZone(timezone)
    .toFormat("MMM d yyyy");

  const groupTitle = `${group.grouptype.curriculum} for ${group.grouptype.affinity}`;

  const groupDayAndTime = DateTime.fromISO(group?.startTime)
    .setZone(timezone)
    .toFormat("ccc ha");

  const numClients = clientGroups.filter((clientGroup) => {
    return clientGroup.unenrolledDate === null;
  }).length;

  const selectData = {
    sessionsAttended: group?.events ? sessionsAttended : null,
    groupDayAndTime,
    firstSessionDate,
    endSessionDate,
    averageAttendance: getAttendanceAverage(events, group.clientGroups),
    groupTitle,
    totalSessions: group.grouptype.groupSessions,
    numClients,
  };

  return selectData;
};
