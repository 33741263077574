import { MBCStatus, mbcTrackStatusConfig } from "../../utils";
import { DateTime } from "luxon";
import useCareEpisodesFlag from "@/app/hooks/useCareEpisodesFlag";
import { TIMEZONE } from "@/app/my-clients/utils";
import { CurrentUserContext } from "@/app/app.utils";
import React from "react";

export const MBCGraphTooltip = ({
  active,
  payload,
}: {
  active: boolean;
  payload: any[];
}) => {
  const isEpisodesOfCareBetaUser = useCareEpisodesFlag();
  const cuser = React.useContext(CurrentUserContext);

  const getTooltipStatus = (status: string) => {
    if (status === MBCStatus.reliableChange) {
      return "Graduate (RCI - Reliable Change Indicator)";
    } else if (status === MBCStatus.graduate) {
      return "Graduate (Remission)";
    } else {
      return status;
    }
  };
  if (active && payload && payload.length) {
    const scorePayload = payload[0].payload;
    let label;
    if (scorePayload.service_type === "consult") {
      label = `Consult on ${DateTime.fromISO(scorePayload.start_time)
        .setZone(TIMEZONE(cuser), { keepLocalTime: true })
        .toFormat("LLL d")}`;
    } else {
      let session_number =
        scorePayload?.calculated_session_number ||
        scorePayload?.clinician_session_number;
      label = `Session ${session_number || ""} - ${DateTime.fromISO(
        scorePayload.start_time,
      )
        .setZone(TIMEZONE(cuser), { keepLocalTime: true })
        .toFormat("LLL d")}`;
    }
    return (
      <div
        style={{
          backgroundColor: "#fff",
          borderRadius: 8,
          padding: 8,
          display: "flex",
          flexDirection: "column",
          boxShadow: "rgba(0, 0, 0, 0.3) 0 2px 10px",
          maxWidth: 400,
        }}
      >
        <p style={{ fontWeight: "bold" }}>{label}</p>
        {isEpisodesOfCareBetaUser && scorePayload?.clinician_last_name && <p style={{ fontWeight: "bold" }}>Clinician: {scorePayload?.clinician_last_name}</p>}
        <p>Score: {scorePayload.value}</p>
        {scorePayload.status && scorePayload.status !== "-" && (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  height: 18,
                  width: 18,
                  marginRight: 12,
                  marginLeft: 4,
                  borderRadius: 4,
                  backgroundColor: mbcTrackStatusConfig(scorePayload.status)
                    .color,
                }}
              />
              {getTooltipStatus(scorePayload.status)}
            </div>
            <span style={{ color: "#6a6a6a", fontSize: 13, marginTop: 8 }}>
              {
                mbcTrackStatusConfig(scorePayload.status, scorePayload.track)
                  .tooltipDescription
              }
            </span>
          </div>
        )}
      </div>
    );
  }
  return null;
};
