import { useUserHasAnyPermissions } from "@/app/_helpers/permissions";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useHasGroupTherapyPermissions = () => {
  return useUserHasAnyPermissions(["IsGroupTherapyUser", "IsSuperUser"]);
};

export const useGroupTherapyRedirect = () => {
  const hasGroupTherapyPermissions = useHasGroupTherapyPermissions();
  const navigate = useNavigate();

  useEffect(() => {
    if (!hasGroupTherapyPermissions) {
      navigate('/dashboard');
    }
  }, [hasGroupTherapyPermissions]);

  return;
};
