import { CSS_COLORS } from "../..";
import { IconProps } from "../icon.interface";

export const XCircleIcon = ({
  stroke = `${CSS_COLORS.red9}`,
  height = 24,
  width = 24,
  title,
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      aria-hidden={!title}
    >
      {title ? <title>{title}</title> : null}
      <circle cx="12" cy="12" r="10"></circle>
      <line x1="15" y1="9" x2="9" y2="15"></line>
      <line x1="9" y1="9" x2="15" y2="15"></line>
    </svg>
  );
};
