import { actionFactory, simpleAction, ActionUnion } from "reductser";
import { produce } from "immer";
import { AsyncOperations } from "../types";

const actionMap = {
  toggleMangle: simpleAction(),
};

export const optionsActions = actionFactory(actionMap, "OPTIONS");
export type OptionsAction = ActionUnion<typeof optionsActions>;

export interface OptionsState {
  doMangle: boolean;
}

function getInitialState(): OptionsState {
  return {
    doMangle: !!(localStorage.getItem("doMangle") === "true"),
  };
}

const optionsReducer = (
  state = getInitialState(),
  action: OptionsAction,
) =>
  produce(state, draft => {
    if (action.reducer === "OPTIONS") {
      switch (action.type) {
        case "toggleMangle":
          draft.doMangle = !state.doMangle;
          return;
        // default:
        //   assertUnreachable(action);
        //   return;
      }
    }
  });

export default optionsReducer;

export const optionsOperations: AsyncOperations = {
  toggleMangle: () => dispatch => {
    dispatch(optionsActions.toggleMangle());
    localStorage.setItem(
      "doMangle",
      (!(localStorage.getItem("doMangle") === "true")).toString(),
    );
  },
};
