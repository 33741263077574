import * as React from "react";

export default () => {
  return (
    <ol style={{ fontSize: "14px" }}>
      <li>
        Not at all = Dismissive of therapy, does not think it would be helpful
        or useful. Is here bc someone else suggested it.
      </li>
      <li>
        As above, BUT expresses some tentative interest in the possibility of Tx
        being beneficial. However, not very open to discussing pros / cons
      </li>
      <li>As above, BUT, open to discussing pros / cons</li>
      <li>
        Interested in therapy, but highly ambivalent about starting. Is
        currently unsure if they want to engage, weighing pros / cons
      </li>
      <li>
        Tentatively excited and motivated OR states eagerness to get started but
        also displays ambivalence. OR Endorses 1-3 barriers to starting and
        expresses lack of certainty that they will be able to overcome these
        barriers
      </li>
      <li>
        Appears excited and motivated and states eagerness to get started.
        Displays no or mild ambivalence. May endorse 1-2 barriers to starting
        but expresses being able to overcome them
      </li>
      <li>
        Ready to engage = Observed to be excited and motivated and states
        eagerness to get started. Displays no ambivalence. Denies any barriers
        to starting
      </li>
    </ol>
  );
};
