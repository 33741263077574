import { Flex, H2, Stack, styledStitches, Text } from "@/app/design-system";
import { BarGraphIcon } from "@/app/design-system/icons";
import { EmptyClipboardIcon } from "@/app/design-system/icons/components/empty-clipboard-icon";
import {
  TabsContent,
  TabsList,
  TabsRoot,
  TabsTrigger,
} from "@/app/_components/secondary-nav/secondary-nav.styled";
import { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { GroupClientDetailsNavInfo } from "./group-client-details-nav-info";
import { useGroupTherapyClientNavInfo } from "../../_api/use-group-therapy-client-nav-info/use-group-therapy-client-nav-info.api";
import { HeartIcon } from "@/app/design-system/icons/components/heart-icon";
import { selectDataForGroupClientDetailsNav } from "../../_api/use-group-therapy-client-nav-info/use-group-therapy-client-nav-info.util";
import { MBCAssessmentResults } from "../../clients/client-id/mbc/mbc";
import { GroupClientProfile } from "../../clients/client-id/profile/profile";
import { useMyClientsSearchStore } from "@/app/my-clients/routes/my-clients-page/my-clients-model/my-clients.model";
import { useGroupTherapyUser } from "@/app/groups/_hooks/use-group-therapy-user";
import { ClientAttendanceNotes } from "../../clients/client-id/notes/notes";
import Mangle from "@/app/_shared/Mangle";

const ATTENDANCE_TAB = "notes";
const MBC_TAB = "mbc";
const CLIENTS_TAB = "profile";

const StyledLink = styledStitches(Link, {
  display: "flex",
  alignItems: "stretch",
});
export const GroupClientDetailsNavigation = () => {
  const { clinicianId } = useGroupTherapyUser();
  const { currentlySelectedClinician } = useMyClientsSearchStore();
  const clinicianIdToQueryBy = currentlySelectedClinician
    ? currentlySelectedClinician
    : clinicianId;

  const clinicianIdParam = clinicianIdToQueryBy
    ? `clinician_id=${clinicianIdToQueryBy}`
    : "";

  const { pathname } = useLocation();
  const { groupId, clientId } =
    useParams<{ groupId: string; clientId: string }>();

  const parts = pathname.split("/");
  const tabName = parts.pop();
  const [activeTabName, setActiveTabName] = useState(tabName);

  const { data, isLoading, isError } = useGroupTherapyClientNavInfo({
    groupId,
    clientStringId: clientId,
    select: selectDataForGroupClientDetailsNav,
    enabled: !!groupId && !!clientId,
  });

  useEffect(() => {
    setActiveTabName(tabName);
  }, [pathname]);
  return (
    <TabsRoot
      orientation="vertical"
      defaultValue={activeTabName}
      value={activeTabName}
    >
      <TabsList
        css={{
          "@breakpoint1024": {
            display: "none",
          },
          width: 300,
          gap: 32,
        }}
      >
        <Stack gap={10} css={{ p: 15, pb: 0 }}>
          <Mangle>
            <H2
              css={{
                fontWeight: 700,
              }}
            >
              {data?.clientName}
            </H2>
          </Mangle>
          <GroupClientDetailsNavInfo
            sessionsAttended={
              data?.sessionsAttended ? data?.sessionsAttended : 0
            }
            totalSessions={data?.totalSessions}
            groupTitle={data?.groupTitle}
            payer={data?.payer}
            phoneNumber={data?.phoneNumber}
            email={data?.email}
            address={data?.address}
            groupId={data?.groupId}
            isError={isError}
            isLoading={isLoading}
          />
        </Stack>
        <Stack css={{ pr: 25, pl: 25 }}>
          <StyledLink
            to={`/groups/${groupId}/clients/${clientId}/mbc?${clinicianIdParam}`}
          >
            <TabsTrigger value={MBC_TAB} css={{ flexGrow: 1 }}>
              <Flex gap={16}>
                <BarGraphIcon /> <Text fontSize={18}>Weekly Check-ins</Text>
              </Flex>
            </TabsTrigger>
          </StyledLink>

          <StyledLink
            to={`/groups/${groupId}/clients/${clientId}/profile?${clinicianIdParam}`}
          >
            <TabsTrigger value={CLIENTS_TAB} css={{ flexGrow: 1 }}>
              <Flex gap={16}>
                <HeartIcon /> <Text fontSize={18}>Client Profile</Text>
              </Flex>
            </TabsTrigger>
          </StyledLink>

          <StyledLink
            to={`/groups/${groupId}/clients/${clientId}/notes?${clinicianIdParam}`}
          >
            <TabsTrigger
              value={ATTENDANCE_TAB}
              css={{ flexGrow: 1, cursor: "auto" }}
            >
              <Flex gap={16} alignItems={"center"}>
                <EmptyClipboardIcon />
                <Text fontSize={18}>Notes &amp; Attendance</Text>
              </Flex>
            </TabsTrigger>
          </StyledLink>
        </Stack>
      </TabsList>
      <Flex
        css={{
          flex: 1,
        }}
      >
        <TabsContent value={MBC_TAB}>
          <MBCAssessmentResults />
        </TabsContent>
        <TabsContent value={ATTENDANCE_TAB}>
          <ClientAttendanceNotes />
        </TabsContent>
        <TabsContent value={CLIENTS_TAB}>
          <GroupClientProfile />
        </TabsContent>
      </Flex>
    </TabsRoot>
  );
};
