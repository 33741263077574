import * as React from "react";
import { LinkOutlined } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Button, DatePicker, Input, message, Select as DeprecatedAntdSelect } from "antd";
import moment from "moment";
import { FormComponentProps } from "@ant-design/compatible/lib/form";

import { useUserHasAnyPermissions } from "../../_helpers/permissions";
import { Row, Column } from "../../_layout/Flex";
import { clientsPanel, sendToClient } from "../utils";
import { useDispatchPromise } from "../../_helpers/redux";
import { ClientPanel, Resource, SendToClientPayload } from "../types";
import { ResourceTitle } from "../styles";
import styled from "styled-components";

const StyledSelectWithPrettyLittleRoundedCorners = styled.div`
  .ant-select > div {
    border-radius: 4px;
  }
`;

export const StyledDatePickerWithPrettyLittleRoundedCorners = styled.div`
  .ant-calendar-picker > div > input {
    border-radius: 4px;
  }
`;

interface SendToClientFormProps extends FormComponentProps {
  resource: Resource;
  handleSetDetailsView: (isDetailsView: boolean) => void;
}

const SendToClient = ({
  resource,
  handleSetDetailsView,
  form,
}: SendToClientFormProps) => {
  const [clients, setClients] = React.useState<ClientPanel[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const [selectedClient, setSelectedClient] = React.useState<string>();
  const [dueDate, setDueDate] = React.useState<number>();
  const [note, setNote] = React.useState<string>();
  const isClinician = useUserHasAnyPermissions(["IsClinician"]);

  const dispatch = useDispatchPromise();

  React.useEffect(() => {
    const fetchMyClients = async () => {
      setIsLoading(true);
      const res = await dispatch(clientsPanel());
      if (res.data) {
        setClients(res.data);
      } else {
        message.error("Unable to get your clients");
      }
      setIsLoading(false);
    };
    if (isClinician) {
      fetchMyClients();
    }
  }, [dispatch, isClinician]);

  const parseIdAndServiceType = (key: string): string[] => {
    const idAndServiceType = key.split("-");

    return idAndServiceType;
  };

  const handleSettingSelectedClient = (clientIdKey: string) => {
    setSelectedClient(clientIdKey);
  };

  const handleSubmitForm = async (e: React.FormEvent) => {
    try {
      e.preventDefault();
      form.validateFields(async (err, values) => {
        if (!selectedClient || !note) return;

        const [id, service_type] = parseIdAndServiceType(selectedClient);

        const clientFromList = clients.find(
          (c) => c.id === parseInt(id, 10) && c.service_type === service_type,
        );

        const payload: SendToClientPayload = {
          title: resource.title,
          link: resource.url,
          service_type: service_type,
          client_id: parseInt(id, 10),
          note: note,
          resource_item_ids: [resource.id.toString()],
          expiration: moment(dueDate).format(),
          event_id: clientFromList?.upcoming_event_id || "",
        };

        try {
          setIsLoading(true);
          const res = await dispatch(sendToClient(payload));
          if (res) {
            message.success("Resource sent to client");
            setSelectedClient(undefined);
            setDueDate(undefined);
            setNote(undefined);
          }
          handleSetDetailsView(true);
        } catch (e: any) {
          message.error("Unable to send resource to client");
        } finally {
          setIsLoading(false);
        }
      });
    } catch (e: any) {
      message.error(e.message);
    }
  };

  if (!isClinician) {
    return <div>You must be a clinician to perform this action.</div>;
  }

  return (
    <div
      style={{
        marginTop: 20,
        flex: 1,
        width: "100%",
      }}
      onSubmit={handleSubmitForm}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "8px",
        }}
      >
        <Column layout="flex-start flex-start">
          <ResourceTitle>{resource.title}</ResourceTitle>
        </Column>
        <div>
          <LinkOutlined style={{ marginRight: "8px" }} />

          <a
            href={resource.url}
            rel="noopener noreferrer"
            target="_blank"
            style={{
              wordBreak: "break-word",
              fontSize: "14px",
              color: "#8C8C8C",
            }}
          >
            {resource.url}
          </a>
        </div>
      </div>

      <Column>
        <Column
          layout="flex-start flex-start"
          style={{ marginBottom: 24, flex: 1, width: "100%" }}
        >
          <Form
            style={{ width: "100%" }}
            onSubmit={handleSubmitForm}
            hideRequiredMark // Antd defaults to showing the asterisk on the left side, which throws off text alignment
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "16px",
              }}
            >
              <Form.Item
                colon={false}
                label={
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: 500,
                      color: "black",
                    }}
                  >
                    Recipient <span style={{ color: "red" }}> *</span>
                  </span>
                }
              >
                <StyledSelectWithPrettyLittleRoundedCorners>
                  {form.getFieldDecorator("recipient", {
                    initialValue: selectedClient,
                    rules: [
                      {
                        required: true,
                        message: "Selecting a client is required.",
                      },
                    ],
                  })(
                    <DeprecatedAntdSelect
                      showSearch
                      style={{
                        flex: 1,
                        width: "262px",
                      }}
                      placeholder="Select client"
                      optionFilterProp="first_name"
                      dropdownStyle={{ borderRadius: "4px" }}
                      onSelect={handleSettingSelectedClient}
                      filterOption={(input, option) => {
                        const childrenStr = String(
                          option?.props.children,
                        ).toLowerCase();
                        return childrenStr.indexOf(input.toLowerCase()) >= 0;
                      }}
                    >
                      {clients.map((c) => (
                        <DeprecatedAntdSelect.Option
                          key={`${c.id}-${c.service_type}`}
                          value={`${c.id}-${c.service_type}`}
                          children={`${c.first_name} ${c.last_name} (${c.service_type})`}
                        />
                      ))}
                    </DeprecatedAntdSelect>,
                  )}
                </StyledSelectWithPrettyLittleRoundedCorners>
              </Form.Item>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "8px",
                }}
              >
                <span
                  style={{ fontSize: "12px", fontWeight: 500, color: "black" }}
                >
                  Due Date
                </span>
                <span>
                  Add an optional due date to create a task for the recipient
                  client.
                </span>
              </div>

              <Form.Item>
                <StyledDatePickerWithPrettyLittleRoundedCorners>
                  <DatePicker
                    style={{ width: "262px" }}
                    showTime
                    placeholder="Select a date"
                    format={"YYYY-MM-DD"}
                    value={
                      dueDate
                        ? moment(new Date(dueDate ? dueDate : ""))
                        : undefined
                    }
                    onChange={(e) => setDueDate(e?.valueOf())}
                  />
                </StyledDatePickerWithPrettyLittleRoundedCorners>
              </Form.Item>
            </div>

            <Form.Item
              label={
                <span
                  style={{ fontSize: "12px", fontWeight: 500, color: "black" }}
                >
                  Message to client<span style={{ color: "red" }}> *</span>
                </span>
              }
              colon={false}
              style={{ width: "100%" }}
            >
              {form.getFieldDecorator("lol", {
                initialValue: note,
                rules: [
                  {
                    required: true,
                    message: "A message is required.",
                  },
                  {
                    max: 200,
                    message: "Message is limited to 200 characters.",
                  },
                ],
              })(
                <Input.TextArea
                  rows={4}
                  maxLength={200}
                  placeholder="Add a personal note to help client understand how they can best leverage this resource..."
                  onChange={(e) => setNote(e.target.value)}
                  style={{ borderRadius: "4px", fontSize: "14px" }}
                />,
              )}
              <span style={{ position: "absolute", right: 0, bottom: -44 }}>
                {note?.toString().length || 0}/200
              </span>
            </Form.Item>
          </Form>
        </Column>

        <Row
          layout="space-between"
          style={{
            paddingRight: "16px",
            paddingLeft: "16px",
            position: "absolute",
            bottom: "20px",
            left: 0,
            width: "100%",
          }}
        >
          <Button
            style={{
              width: "100px",
              height: "40px",
              borderRadius: "4px",
            }}
            onClick={() => handleSetDetailsView(true)}
          >
            Cancel
          </Button>

          <Button
            type="primary"
            style={{ height: "40px", borderRadius: "4px" }}
            disabled={isLoading}
            onClick={(e) => {
              handleSetDetailsView(false);
              handleSubmitForm(e);
            }}
          >
            Send to client
          </Button>
        </Row>
      </Column>
    </div>
  );
};

export const SendToClientForm = Form.create<SendToClientFormProps>({
  name: "send_resource",
})(SendToClient);
