import { graphqlClient } from "@/api/graphql";
import { FIVE_MINUTES } from "@/app/_helpers/constants";
import {
  GetAllLiveGroupsAfterDateQuery,
  useGetAllLiveGroupsAfterDateQuery,
} from "@/graphql/generated";

interface UseGetAllLiveGroupsAfterDateParams<T> {
  date: string; // ex: '2020-01-01'
  select: (data: GetAllLiveGroupsAfterDateQuery) => T;
  enabled?: boolean;
}

export const useGetAllLiveGroupsAfterDate = <T extends {}>({
  date,
  select,
  enabled,
}: UseGetAllLiveGroupsAfterDateParams<T>) => {
  return useGetAllLiveGroupsAfterDateQuery(
    graphqlClient,
    { date },
    {
      staleTime: FIVE_MINUTES,
      select,
      enabled,
    },
  );
};
