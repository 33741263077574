import { graphqlClient } from "../../../api/graphql";
import {
  GetEpisodesOfCareQuery,
  GetLatestClinicianEpisodeOfCareQuery,
  useGetEpisodesOfCareQuery,
  useGetLatestClinicianEpisodeOfCareQuery,
} from "../../../graphql/generated";
import { FIVE_MINUTES } from "../../_helpers/constants";
import { Dw_Export_Episode_Of_Care } from "@/graphql/generated";

interface UseGetEpisodesOfCareParams<T extends {}> {
  clientIds?: number[];
  clinicianId?: number;
  startTime?: string;
  select?: (data: GetEpisodesOfCareQuery) => T;
  enabled: boolean;
}

interface UseGetLatestClinicianEpisodesOfCareParams<T extends {}> {
  clientIds?: number[];
  clinicianId?: number;
  startTime?: string;
  select?: (data: GetLatestClinicianEpisodeOfCareQuery) => T;
  enabled: boolean;
}

export const useGetEpisodesOfCare = <T extends {}>({
  clientIds,
  clinicianId,
  startTime,
  select,
  enabled,
}: UseGetEpisodesOfCareParams<T>) => {
  const absClientIds = clientIds?.map((id) => Math.abs(id));
  const absClinicianId = Math.abs(clinicianId || 0);
  if (!startTime) {
    const currentDate = new Date();
    currentDate.setHours(currentDate.getHours() - 1, 0, 0, 0);
    startTime = currentDate.toISOString();
  }
  return useGetEpisodesOfCareQuery(
    graphqlClient,
    {
      client_ids: absClientIds,
      clinician_id: absClinicianId,
      start_time: startTime,
    },
    {
      staleTime: FIVE_MINUTES,
      select: (data) => {
        const formatted = convertTypes(data);
        if (select) {
          return select(formatted) as T;
        } else {
          return formatted as T;
        }
      },
      enabled: false,
    },
  );
};

export const useGetLatestClinicianEpisodesOfCare = <T extends {}>({
  clientIds,
  clinicianId,
  select,
  enabled,
}: UseGetLatestClinicianEpisodesOfCareParams<T>) => {
  const absClientIds = clientIds?.map((id) => Math.abs(id));
  const absClinicianId = Math.abs(clinicianId || 0);
  return useGetLatestClinicianEpisodeOfCareQuery(
    graphqlClient,
    { client_ids: absClientIds, clinician_id: absClinicianId },
    {
      staleTime: FIVE_MINUTES,
      select: (data) => {
        const formatted = convertTypes(data);
        if (select) {
          return select(formatted) as T;
        } else {
          return formatted as T;
        }
      },
      enabled: false,
    },
  );
};

//@ts-ignore
const convertTypes = (data) => {
  const int64Cols = [
    "episode",
    "client_id",
    "client_session_number",
    "clinician_session_number",
    "clinician_id",
    "is_latest",
    "is_baseline",
    "same_clinician_over_60_days_last_session",
    "is_latest_clinician_in_episode",
  ];

  const float64Cols = ["gad", "phq", "alliance"];

  data.dw_export_episode_of_care.forEach((row: Dw_Export_Episode_Of_Care) => {
    int64Cols.forEach((col) => {
      if (row[col]) {
        row[col] = parseInt(row[col]);
      }
    });
    float64Cols.forEach((col) => {
      if (row[col]) {
        row[col] = parseFloat(row[col]);
      }
    });
  });
  return data;
};
