import { IconProps } from "../icon.interface";

export const MBCTrackTrendIcon = ({
  fill,
  height = 18,
  width = 18,
  title,
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden={!title}
    >
      {title ? <title>{title}</title> : null}
      <rect width={width} height={height} rx="4" fill={fill} />
    </svg>
  );
};
