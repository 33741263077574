import { CSS_COLORS } from "../..";
import { IconProps } from "../icon.interface";

export const ArrowDownIcon = ({
  height = 24,
  width = 24,
  stroke = CSS_COLORS.neutral12,
  title,
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden={!title}
    >
      {title ? <title>{title}</title> : null}
      <line x1="12" y1="19" x2="12" y2="5"></line>
      <polyline points="19 12 12 19 5 12"></polyline>
    </svg>
  );
};
