import { graphqlClient } from "@/api/graphql";

import {
  GroupTherapyMbcEventsQuery,
  useGroupTherapyMbcEventsQuery,
} from "@/graphql/generated";
import { FIVE_MINUTES } from "@/app/_helpers/constants";

interface UseGroupTherapyMbcEventsParams<T extends object> {
  groupId: string | undefined;
  select?: (data: GroupTherapyMbcEventsQuery) => T;
  enabled: boolean;
}

export const useGroupTherapyMbcEvent = <T extends {}>({
  groupId,
  select,
  enabled,
}: UseGroupTherapyMbcEventsParams<T>) => {
  return useGroupTherapyMbcEventsQuery(
    graphqlClient,
    { groupId },
    { staleTime: FIVE_MINUTES, select, enabled },
  );
};
