import { graphqlClient } from '@/api/graphql';
import { useGetConfigQuery } from '@/graphql/generated'; 
import { ONE_WEEK } from "@/app/_helpers/constants";


export const useGetConfig = (enabled:boolean)=> {
  const { data, isLoading } = useGetConfigQuery(
    graphqlClient, 
    {},
    {
      staleTime: ONE_WEEK,
      enabled: enabled
    },
    
  );

  return { data, isLoading };
};
