import { AxiosResponse } from "axios";
import { message } from "antd";

import {
  Resource,
  Category,
  Tag,
  SearchResults,
  SearchOptions,
  Paginated,
  ClientPanel,
  SendToClientPayload,
} from "./types";
import api, { wrapApiCall } from "../../api";
import { AsyncActionWithResultCreator } from "../../state/models/types";
import { EventApiData } from "../api/use-my-clients-overview";

export const FAVORITES_CATEGORY_ID = "my_favorites";
export const ADDED_BY_ME_CATEGORY_ID = "added_by_me";

export const searchResults: AsyncActionWithResultCreator<SearchResults> =
  (options: SearchOptions) => async (dispatch) => {
    try {
      const promises: Promise<AxiosResponse>[] = [
        dispatch(fetchResources(options)),
      ];
      if (options.search_term) {
        promises.push(dispatch(fetchCategories(options.search_term)));
        // wrapApiCall(
        //   api.get(`/api/resource-tags/v1/?search_term=${options.search_term}`),
        //   dispatch,
        // ) as Promise<AxiosResponse<Paginated<Tag>>>,
        // wrapApiCall(
        //   api.get(
        //     `/api/resource-items/v1/favorites/?search_term=${options.search_term}`,
        //   ),
        //   dispatch,
        // ) as Promise<AxiosResponse<Paginated<Resource>>>,
      }
      const res = await Promise.all(promises);
      return {
        resources: res[0].data.results,
        categories: res[1] ? res[1].data.results : [],
        tags: [],
        favorites: [],
      };
    } catch (e) {
      message.error((e as Error).message);
      throw new Error((e as Error).message);
    }
  };

export const toggleResourceIsFavorite: AsyncActionWithResultCreator<Resource> =
  (resource: Resource) => async (dispatch) => {
    try {
      const path = resource.is_favorite ? "unfavorite" : "favorite";
      const res: AxiosResponse<string> = await wrapApiCall(
        api.put(`/api/resource-items/v1/${resource.id}/${path}/`),
        dispatch,
      );
      if (!res) {
        message.error(
          `Unable to ${resource.is_favorite ? "un" : ""}favorite resource`,
        );
      }
      const { data, status } = res;
      if (data === "" && (status === 200 || status === 201)) {
        message.success(
          `Resource has been ${resource.is_favorite ? "un" : ""}favorited`,
        );
        return {
          ...resource,
          is_favorite: !resource.is_favorite,
        };
      }
      throw new Error(res.statusText);
    } catch (e) {
      message.error((e as Error).message);
      throw new Error((e as Error).message);
    }
  };

export const fetchFavorites: AsyncActionWithResultCreator<
  AxiosResponse<Paginated<Resource>>
> = () => async (dispatch) => {
  const res: AxiosResponse<Paginated<Resource>> = await wrapApiCall(
    api.get(`/api/resource-items/v1/favorites/`),
    dispatch,
  );
  return res;
};

export const fetchAddedByMe: AsyncActionWithResultCreator<
  AxiosResponse<Paginated<Resource>>
> = () => async (dispatch) => {
  const res: AxiosResponse<Paginated<Resource>> = await wrapApiCall(
    api.get(`/api/resource-items/v1/added_by_me/`),
    dispatch,
  );
  return res;
};

export const fetchCategories: AsyncActionWithResultCreator<
  AxiosResponse<Paginated<Category>>
> =
  (search: string = "", page: number = 1, limit: number = 10) =>
  async (dispatch) => {
    try {
      let url = `/api/resource-categories/v1/?page=${page}&page_size=${limit}`;
      if (search) {
        url += `&search_term=${search}`;
      }
      return await wrapApiCall(api.get(url), dispatch);
    } catch (e) {
      message.error((e as Error).message);
      throw new Error((e as Error).message);
    }
  };

export const createCategory: AsyncActionWithResultCreator<
  AxiosResponse<Category>
> = (category: Category) => async (dispatch) => {
  try {
    return await wrapApiCall(
      api.post(`/api/resource-categories/v1/`, {
        name: category.name,
        is_published: true,
      }),
      dispatch,
    );
  } catch (e) {
    message.error((e as Error).message);
    throw new Error((e as Error).message);
  }
};

export const updateCategory: AsyncActionWithResultCreator<
  AxiosResponse<Category>
> = (id: string, payload: Category) => async (dispatch) => {
  try {
    return await wrapApiCall(
      api.patch(`/api/resource-categories/v1/${id}/`, payload),
      dispatch,
    );
  } catch (e) {
    message.error((e as Error).message);
    throw new Error((e as Error).message);
  }
};

export const createResource: AsyncActionWithResultCreator<
  AxiosResponse<Resource>
> = (resource: Resource) => async (dispatch) => {
  try {
    return await wrapApiCall(
      api.post(`/api/resource-items/v1/`, resource),
      dispatch,
    );
  } catch (e) {
    message.error((e as Error).message);
    throw new Error((e as Error).message);
  }
};

export const updateResource: AsyncActionWithResultCreator<
  AxiosResponse<Resource>
> = (id: string, payload: Resource) => async (dispatch) => {
  try {
    return await wrapApiCall(
      api.patch(`/api/resource-items/v1/${id}/`, payload),
      dispatch,
    );
  } catch (e) {
    message.error((e as Error).message);
    throw new Error((e as Error).message);
  }
};

export const deleteResource: AsyncActionWithResultCreator<
  AxiosResponse<Resource>
> = (id: string) => async (dispatch) => {
  try {
    return await wrapApiCall(
      api.delete(`/api/resource-items/v1/${id}/`),
      dispatch,
    );
  } catch (e) {
    message.error((e as Error).message);
    throw new Error((e as Error).message);
  }
};

export const createTag: AsyncActionWithResultCreator<AxiosResponse<Tag>> =
  (tag: Tag) => async (dispatch) => {
    try {
      return await wrapApiCall(
        api.post(`/api/resource-tags/v1/`, tag),
        dispatch,
      );
    } catch (e) {
      message.error((e as Error).message);
      throw new Error((e as Error).message);
    }
  };

export const updateTag: AsyncActionWithResultCreator<AxiosResponse<Tag>> =
  (id: string, payload: Tag) => async (dispatch) => {
    try {
      return await wrapApiCall(
        api.patch(`/api/resource-tags/v1/${id}/`, payload),
        dispatch,
      );
    } catch (e) {
      message.error((e as Error).message);
      throw new Error((e as Error).message);
    }
  };

export const fetchTags: AsyncActionWithResultCreator<
  AxiosResponse<Paginated<Tag>>
> = (search?: string) => async (dispatch) => {
  try {
    let url = "/api/resource-tags/v1/";
    if (search) {
      url += `?search_term=${search}`;
    }
    return await wrapApiCall(api.get(url), dispatch);
  } catch (e) {
    message.error((e as Error).message);
    throw new Error((e as Error).message);
  }
};

const fetchResources: AsyncActionWithResultCreator<
  AxiosResponse<Paginated<Resource>>
> = (options: SearchOptions) => async (dispatch) => {
  let baseUrl = "/api/resource-items/v1/";
  const { categoryId, tags, search_term } = options;
  const params = new URLSearchParams();
  if (categoryId) {
    if (categoryId === FAVORITES_CATEGORY_ID) {
      baseUrl += "favorites/";
    } else if (categoryId === ADDED_BY_ME_CATEGORY_ID) {
      baseUrl += "added_by_me/";
    } else {
      params.set("category_ids", categoryId);
    }
  }
  if (tags) {
    params.set("tag_ids", tags);
  }
  if (search_term) {
    params.set("search_term", search_term);
  }
  return await wrapApiCall(
    api.get(`${baseUrl}?${params.toString()}`),
    dispatch,
  );
};

export const fetchResourceById: AsyncActionWithResultCreator<
  AxiosResponse<Resource>
> = (resourceId: string) => async (dispatch) => {
  return await wrapApiCall(
    api.get(`/api/resource-items/v1/${resourceId}/`),
    dispatch,
  );
};

export const clientsPanel: AsyncActionWithResultCreator<
  AxiosResponse<ClientPanel[]>
> = () => async (dispatch) => {
  return await wrapApiCall(api.get(`/api/query/cuser_client_panel/`), dispatch);
};

export const sendToClient: AsyncActionWithResultCreator<AxiosResponse<any>> =
  (payload: SendToClientPayload) => async (dispatch) => {
    try {
      return await wrapApiCall(
        api.post(`/api/resource-items/v1/send_to_client/`, payload),
        dispatch,
      );
    } catch (e) {
      throw new Error((e as Error).message);
    }
  };

export const getCurrentClinicianAttendedSessions = (
  events: Array<EventApiData>,
  clinicianId?: number,
) => {
  return events?.filter(
    (event: any) =>
      event.appointmentStatus === "attended" &&
      event.clinicianId === clinicianId,
  ).length;
};
