import React from "react";
import ExpandableRowItem from "../../_shared/ExpandableRowItem";
import * as colors from "../../../assets/colors";
import Mangle from "../../_shared/Mangle";
import MatchQueueItem from "../_components/MyMatchesTableItem/MatchQueueItem";
import { MatchIcons, StaticMatchIcons } from "../_components/MatchIcons";
import { BasicMatch, CouplesMatch, IdMap } from "../../../api/types";
import { Column, Row } from "../../_layout/Flex";
import { useShallowEqualSelector } from "../../_helpers/redux";
import MatchBadge from "../_components/MyMatchesTableItem/MatchBadge";
import { FlagFilled } from "@ant-design/icons";
import { matchActions } from "../../../state/models/matches";
import { useDispatch } from "react-redux";
import { Tag, Typography, Popover } from "antd";
import { MatchRequeueLog } from "./_api/use-get-match-requeue-log/use-get-match-requeue-log";
import { DateTime } from "luxon";

interface Props {
  match: BasicMatch;
  queueIdx: number;
  fitFilter: IdMap<{ couples: boolean; individual: boolean }> | undefined;
  matchRequeueLogs?: MatchRequeueLog[];
}

const ExpandableQueueItem = ({
  match,
  queueIdx,
  fitFilter,
  matchRequeueLogs,
}: Props) => {
  const dispatch = useDispatch();
  const [isFlagged, matchInCart, suggestedMatch] = useShallowEqualSelector(
    (state) => [
      state.matches.flaggedMatches[match.id] || false,
      state.matches.matchesInCart[match.id],
      state.matches.matchSuggestions[match.id],
    ],
  );

  const isCouplesMatch = "couple" in match && match.couple !== null;

  const showInitialsCouple = (match: CouplesMatch | BasicMatch) => {
    return `${match.couple!.clientA.initials} & ${
      match.couple!.clientB.initials
    }`;
  };

  return (
    <ExpandableRowItem
      style={{
        border: `1px solid ${colors.$border}`,
        marginBottom: "10px",
        backgroundColor: "white",
      }}
      hideDivider={true}
      headerStyle={{
        display: "flex",
        alignItems: "center",
        height: "48px",
        borderBottom: `1px solid ${colors.$border}`,
      }}
      header={
        <Row style={{ flex: 1 }} layout="space-between center">
          <Row
            layout="start center"
            style={{ flex: 2, paddingLeft: "16px" }}
          >
            <Column
              style={{ flex: "1 2 320px" }}
              layout="flex-start flex-start"
            >
              <span
                style={{
                  flex: "1",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {queueIdx}
                {". "}
                <Mangle>
                  {isCouplesMatch
                    ? showInitialsCouple(match)
                    : `${match!.client.first_name} ${match!.client.last_name}`}
                </Mangle>
                &nbsp;
              </span>
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography.Text
                  style={{ fontSize: "10px", color: colors.$greyText }}
                >
                  Match ID: {match.id} | Client ID: {match.client.id}
                </Typography.Text>
                <StaticMatchIcons basicMatch={match} />
              </span>
            </Column>

            <span style={{ flex: "1 0 120px" }}>
              <MatchIcons basicMatch={match} />
            </span>
          </Row>
          <Row style={{ flex: "1 0 180px"}} layout={"flex-end center"}>
            {/* badges */}
            {matchRequeueLogs && matchRequeueLogs.length > 0 ? (
              <RequeueInfo matchRequeueLogs={matchRequeueLogs} />
            ) : null}
          </Row>
          <span
            style={{
              textAlign: "right",
              paddingRight: "20px",
              flex: "0.1 1 120px",
            }}
          >
            <MatchBadge
              match={match}
              matchInCart={matchInCart}
              suggestedMatch={suggestedMatch}
            />
          </span>
          <FlagFilled
            onClick={(e) => {
              e.stopPropagation();
              dispatch(matchActions.toggleMatchIsFlagged(match.id));
            }}
            style={isFlagged ? { color: "red" } : { color: "gray" }}
          />
        </Row>
      }
      content={<MatchQueueItem fitFilter={fitFilter} matchId={match.id} />}
    />
  );
};

export default ExpandableQueueItem;

const RequeueInfo = ({
  matchRequeueLogs,
}: {
  matchRequeueLogs: MatchRequeueLog[];
}) => {
  const PopoverContent = (
    <table>
      <tbody>
        {matchRequeueLogs
          .sort((a, b) =>
            b.requeue_timestamp.localeCompare(a.requeue_timestamp),
          )
          .map((r) => {
            const requeuedAt = DateTime.fromISO(r.requeue_timestamp);

            let diffFromMatching: number | null;
            try {
              diffFromMatching = Math.floor(
                requeuedAt
                  .diff(DateTime.fromISO(r.match_resolved_at_before_requeue))
                  .as("hours"),
              );
            } catch {
              diffFromMatching = null;
            }
            return (
              <tr>
                <tr>
                  <td>
                    Requeued on{" "}
                    {requeuedAt.toLocaleString(DateTime.DATETIME_SHORT)}{" "}
                    {diffFromMatching !== null
                      ? diffFromMatching < 0
                        ? "(<1 hours after matching)"
                        : `(${diffFromMatching} hours after matching)`
                      : ""}{" "}
                  </td>
                </tr>
                <tr>
                  <td>Reason: {r.requeue_reason}</td>
                </tr>
              </tr>
            );
          })}
      </tbody>
    </table>
  );

  // get the latest appointment offered (aka the match_email_sent)
  const requeuesAfterAppointmentOffered = matchRequeueLogs
    .filter((l) => !!l.match_email_sent_before_requeue)
    .sort((a, b) =>
      b.match_email_sent_before_requeue.localeCompare(
        a.match_email_sent_before_requeue,
      ),
    );

  return (
    <Popover content={PopoverContent}>
      <Tag>Requeued {matchRequeueLogs.length} time(s)</Tag>
      {requeuesAfterAppointmentOffered.length > 0 ? (
        <Tag color="success">
          Appt offered{" "}
          {DateTime.fromISO(
            requeuesAfterAppointmentOffered[0].match_email_sent_before_requeue,
          ).toLocaleString(DateTime.DATETIME_SHORT)}
        </Tag>
      ) : null}
    </Popover>
  );
};
