import { ClientGroupsAttendanceDataFragment, GetClientGroupsAttendanceQuery } from "@/graphql/generated";

const getGroupDescription = (group: ClientGroupsAttendanceDataFragment) => {
  const groupStartDateTitleAndClinicianName
    = `Starts ${group.groupStartDate} - ${group.groupType.title} - ${group.clinician.fullName}`;
  return groupStartDateTitleAndClinicianName;
}

export const getGroupDescriptionFromAttendanceData = (clientGroupsAttendanceData: GetClientGroupsAttendanceQuery) => {
  if (clientGroupsAttendanceData.client?.[0].clientGroups?.length) {
    return getGroupDescription(clientGroupsAttendanceData.client?.[0].clientGroups[0].group);
  }
  return '';
}
