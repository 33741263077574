import * as DialogPrimitive from "@radix-ui/react-dialog";
import { styled } from "@stitches/react";
import React from "react";
import { Flex } from "../";
import { H2 } from "../headings/heading";
import { XIcon } from "../icons";
import { TRANSLATEY_0_TO_100 } from "../styles/config/css-keyframe-constants";
import { styledStitches } from "@/app/design-system/styles/stitches.config";
import { OVERLAY_ZINDEX } from "../styles/config/css-constants";

const StyledDialogOverlay = styledStitches(DialogPrimitive.DialogOverlay, {
  backgroundColor: `$neutral12`,
  opacity: "40%",
  position: "fixed",
  inset: 0,
  z: OVERLAY_ZINDEX,
});

const StyledDialogTitle = styledStitches(DialogPrimitive.Title, {
  borderBottom: "1px solid $neutral4",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: 60,
  textAlign: "center",
  padding: "16px",
  margin: 0,
});

const StyledDialogContent = styledStitches(DialogPrimitive.Content, {
  backgroundColor: "white",
  borderRadius: 6,
  boxShadow:
    "hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px",
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  maxWidth: "450px",
  maxHeight: "85vh",

  "@media (prefers-reduced-motion: no-preference)": {
    animation: `${TRANSLATEY_0_TO_100} 250ms cubic-bezier(0.16, 1, 0.3, 1) forwards`,
  },
  "&:focus": { outline: "none" },
  z: OVERLAY_ZINDEX,
});

export const StyledCloseButton = styledStitches("button", {
  all: "unset",
  borderRadius: "50%",
  cursor: "pointer",
});

const StyledDialogFooter = styledStitches(Flex, {
  p: "12px 24px 12px",
  borderTop: "1px solid $neutral4",
});

export const ModalIconButton = styled("button", {
  all: "unset",
  fontFamily: "inherit",
  borderRadius: "100%",
  height: 25,
  width: 25,
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  top: 20,
  right: 20,
});

interface ModalDialogHeaderProps extends React.ComponentPropsWithoutRef<"div"> {
  title?: string;
}

export const Header = React.forwardRef<
  HTMLHeadingElement,
  ModalDialogHeaderProps
>(({ children, title, ...props }, forwardedRef) => (
  <DialogPrimitive.Title {...props} ref={forwardedRef}>
    {children ? (
      children
    ) : (
      <Flex
        justifyContent={"space-between"}
        css={{
          flexGrow: 1,
          p: "12px 24px",
          borderBottom: "1px solid $neutral4",
        }}
      >
        <H2 fontWeight={700} css={{ fontSize: 20 }} color={"$neutral12"}>
          {title}
        </H2>
        <DialogPrimitive.Close asChild>
          <StyledCloseButton>
            <XIcon />
          </StyledCloseButton>
        </DialogPrimitive.Close>
      </Flex>
    )}
  </DialogPrimitive.Title>
));

export const Root = DialogPrimitive.Root;
export const Close = DialogPrimitive.Close;
export const CloseButton = StyledCloseButton;
export const Trigger = DialogPrimitive.DialogTrigger;
export const Portal = DialogPrimitive.Portal;
export const Overlay = StyledDialogOverlay;
export const Title = StyledDialogTitle;
export const Content = StyledDialogContent;
export const Footer = StyledDialogFooter;
