import { cancellationReasonToFrontendValueMap } from "@/app/my-clients/api/use-upsert-session/upsert-session.constants";
import { Flex, Text } from "../../../../../../../../../../design-system/index";

export const cancellationTypeToFrontendValueMap = {
  late_by_client: "Late cancel by client",
  cancel_by_system: "Canceled by system",
  by_clinician: "Canceled by clinician",
  by_client: "Canceled by client",
  by_twochairs: "Canceled by Two Chairs",
};

interface SessionCancellationInfoProps {
  cancellationType: string;
  cancellationReason: string;
  appointmentStatus: string | null;
}

export const SessionCancellationInfo = ({
  cancellationType,
  cancellationReason,
  appointmentStatus,
}: SessionCancellationInfoProps) => {
  if (cancellationType !== "noshow") {
    return (
      <Flex gap={4}>
        <Text
          color={
            cancellationType === "late_by_client" ? "$red11" : "$neutral12"
          }
          fontWeight={700}
        >
          {cancellationTypeToFrontendValueMap[cancellationType]}
        </Text>
        <Text>-</Text>
        <Text lowercase>
          {cancellationReasonToFrontendValueMap[cancellationReason]}
        </Text>
      </Flex>
    );
  }

  if (appointmentStatus === "noshow") {
    <Text color={"$red11"} fontWeight={700}>
      No show (missed session)
    </Text>;
  }

  return null;
};
