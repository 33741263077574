import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Modal, Steps } from "antd";
import { $border } from "../../../assets/colors";
import { AppState } from "../../../state/models";
import { matchmakerActions } from "../../../state/models/matchmaker";

import MatchmakerFooter from "./Footer";

import ClientStep from "./1-ClientStep";
import ConsultStep from "./2-ConsultStep";
import FitStep from "./3-FitStep";
import MatchStep from "./4-MatchStep";
import { useGetTags } from "@/app/api/use-get-tags/use-get-tags";
import { toMap } from "@/app/_helpers";
import { matchActions } from "@/state/models/matches";
import { Tag } from "@/api/types";
import { Spinner } from "@/app/design-system";
import { QuarterCircle } from "@/app/design-system";
import { getConsultNote } from "@/state/models/consultnote/operations";
import { useAutoOpenMatchmaker } from "../MatchingV2/use-auto-open-matchmaker";

const modalWidth = Math.min(window.innerWidth - 80, 960);
const styles: { [key: string]: React.CSSProperties } = {
  steps: {
    padding: "15px",
    minHeight: "60px",
    borderBottom: "1px solid " + $border,
  },
  modalContainer: { top: "25px", margin: "0 auto" },
  modalBody: {
    maxHeight: "calc(100vh - 160px)",
    backgroundColor: "white",
    padding: "0px",
    display: "flex",
    flexDirection: "column",
  },
  footer: {
    backgroundColor: "white",
  },
};

const MatchMaker = () => {
  const [
    stepIdx,
    matchmakerIsOpen,
    removedTags,
    consultId,
    isGroupsAppropriateMatch,
  ] = useSelector((state: AppState) => [
    state.matchmaker.stepIdx,
    state.matchmaker.isOpen,
    state.matchmaker.removedTags,
    state.matchmaker.initData.consult?.id,
    state.matchmaker.groupsMatchData.isGroupsAppropriateMatch,
  ]);
  useAutoOpenMatchmaker();

  const dispatch = useDispatch();
  const { data: tagsData, isLoading: isLoadingTagsData } = useGetTags();

  const [isLoadingConsultNote, setIsLoadingConsultNote] = useState(false);

  const autoPopulateForGroupsAndSkipToStep4 = () => {
    dispatch(
      matchmakerActions.patchMatchDraft({
        // set required page 1 fields
        is_biweekly_fit: false,
        cadence_pref_strength: "open",
        delivery_preference: "remote",
        hybrid_preference: "teletherapy_ok",
        clinician_approves_ot: true,
        // we treat groups clients as "referred out"
        is_referral: true,
      }),
    );
    stepTo(3);
  };

  const handleGroupsAppropriateClient = async (consultId: string) => {
    setIsLoadingConsultNote(true);
    const res = await getConsultNote(consultId);
    if (
      res?.consult_note_exists &&
      res.data?.group_two_chairs_appropriate_yes_intention === "Yes"
    ) {
      dispatch(matchmakerActions.setIsGroupsAppropriateMatch(true));
      autoPopulateForGroupsAndSkipToStep4();
    } else {
      if (isGroupsAppropriateMatch) {
        dispatch(matchmakerActions.setIsGroupsAppropriateMatch(false));
        dispatch(matchmakerActions.clearGroupsEnrollData);
      }
    }
    setIsLoadingConsultNote(false);
  };

  const handleForceTriageToGroupsClick = (consultId?: string) => {
    if (!!consultId) {
      dispatch(matchmakerActions.setIsGroupsAppropriateMatch(true));
      autoPopulateForGroupsAndSkipToStep4();
    }
  };

  useEffect(() => {
    if (tagsData) {
      const tags = tagsData?.ehr_tag.map((tag) => {
        return {
          ...tag,
          status: tag.status ?? null,
        } as Tag;
      });
      setTags(tags);
    }
  }, [tagsData]);

  useEffect(() => {
    // we only want handleGroupsAppropriateClient to run if we're creating a new match (createNewMatchWithMatchmaker)
    // and the consult note has been filled out
    const wasMatchmakerOpenedWithConsult = !!consultId;
    if (wasMatchmakerOpenedWithConsult) {
      handleGroupsAppropriateClient(consultId);
    }
  }, [consultId]);

  const stepTo = (idx: number) => dispatch(matchmakerActions.stepTo(idx));
  const closeMatchmaker = () => dispatch(matchmakerActions.closeMatchmaker());
  const setTags = (tags: Tag[]) => dispatch(matchActions.loadTags(tags));
  if (isLoadingTagsData || isLoadingConsultNote)
    return (
      <div>
        <Spinner>
          <QuarterCircle dimension={"3em"} />
        </Spinner>
      </div>
    );
  return (
    <Modal
      title="Matchmaker"
      open={matchmakerIsOpen}
      maskClosable={false}
      width={modalWidth}
      style={styles.modalContainer}
      bodyStyle={styles.modalBody}
      footer={
        <MatchmakerFooter
          showForceTriageButton={!!consultId}
          handleForceTriageToGroupsClick={() =>
            handleForceTriageToGroupsClick(consultId)
          }
        />
      }
      onCancel={closeMatchmaker}
    >
      <Steps size="small" current={stepIdx} style={styles.steps}>
        <Steps.Step
          title="Client"
          style={{ cursor: "pointer" }}
          onClick={() => stepTo(0)}
        />
        <Steps.Step
          title="Consult"
          style={{ cursor: "pointer" }}
          status={removedTags.length > 0 ? "error" : undefined}
          onClick={() => stepTo(1)}
        />
        <Steps.Step
          title="Fit"
          style={{ cursor: "pointer" }}
          onClick={() => stepTo(2)}
        />
        <Steps.Step
          title="Match"
          style={{ cursor: "pointer" }}
          onClick={() => stepTo(3)}
        />
      </Steps>
      <>
        {stepIdx === 0 && <ClientStep />}
        {stepIdx === 1 && <ConsultStep />}
        {stepIdx === 2 && <FitStep />}
        {stepIdx === 3 && <MatchStep />}
      </>
    </Modal>
  );
};
export default MatchMaker;
