import * as Sentry from "@sentry/react";
import { CheckOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Drawer, Input, Table, Tag as AntdTag, Typography, Select } from "antd";
import { ColumnProps } from "antd/es/table";
import moment from "moment";
import { differenceInDays } from "date-fns";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import {
  ShoppingCartItem,
  Clinician,
  ClinicianSlot,
  Recurrence,
  MatchFit,
  Day,
  Time,
  Fit,
  ClientPayerServiceRecord,
  MatchCountRowItem,
  MatchSlotPreference,
  ScheduleItem,
  CsvDataItem,
  ClientExtended,
  BasicMatch,
} from "../../api/types";
import { matchActions, matchOperations } from "../../state/models/matches";
import { alphabetizeBy, downloadAsCSV } from "../_helpers";
import { useShallowEqualSelector } from "../_helpers/redux";
import { Pad, Title } from "../_layout/display";
import { Row } from "../_layout/Flex";
import ClinicianItem from "../_shared/ClinicianItem";
import Mangle from "../_shared/Mangle";
import useDebounce from "../hooks/useDebounce";
import MatchCountTable from "./_components/MatchCountTable";
import { useUserHasAnyPermissions } from "@/app/_helpers/permissions";
import { getClinicianCapacities } from "../_shared/ClinicianStatus";
import * as colors from "../../assets/colors";
import {
  IANA_TO_USER_FRIENDLY_TIMEZONE,
  Timezones,
} from "../_helpers/time.constants";
import { StaticMatchIcons } from "./_components/MatchIcons";

interface RowItem {
  client: ClientExtended;
  consultClinician: number | null;
  dayOfWeek?: Day;
  fitId: number;
  match: BasicMatch;
  matchedClinician: number;
  queueTime: string;
  recurrence: Recurrence;
  startDate?: string;
  startTime?: Time;
  workFromHome: boolean;
  modality: "hybrid" | "remote" | undefined | null;
  timezone?: string;
  is_manual_match: boolean;
}

type ClinicianFitMap = {
  [key: string]: string[];
};

const DESCRIPTIONS = [
  {value: "all", label: "All"},
  {value: "high_acuity", label: "High acuity"},
  {value: "direct_match", label: "Direct match - return to care"},
  {value: "network_ramping", label: "Network ramping"},
  {value: "network_matches_capped", label: "Network matches capped"},
  {value: "network_matches_uncapped", label: "Network matches uncapped"},
  {value: "other", label: "Other - open text description required"},
]

export type MatchingSessionDescription = typeof DESCRIPTIONS[number]["value"] | null;

interface MatchingSessionState {
  description: MatchingSessionDescription,
  description_other : string,
  description_error: boolean,
  description_other_error: boolean,
}

const getMatchingSessionInitialState = (): MatchingSessionState => ({
  description: null,
  description_other: '',
  description_error: false,
  description_other_error: false,
})


function ShoppingCart() {
  // const [sortedInfo, setSortedInfo] = useState<null | SorterResult<RowItem>>(
  //   null,
  // );
  // const handleChange = (
  //   _pagination: PaginationConfig,
  //   _filters: Partial<Record<ColumnKeys, string[]>>,
  //   sorter: SorterResult<RowItem>,
  // ) => {
  //   setSortedInfo(sorter);
  // };

  const [
    clinicianMap,
    matchesInCart,
    matchFitsMap,
    matchSuggestions,
    myMatchesMap,
    shoppingCartIsOpen,
    getClinicianCapacity,
    scheduleMap,
    matchSlotPreferenceMap,
    clinicMap,
    queue_condition_id,
  ] = useShallowEqualSelector((state) => [
    state.clinicians.clinicianMap,
    state.matches.matchesInCart,
    state.matches.matchFitsMap,
    state.matches.matchSuggestions,
    state.matches.myMatchesMap,
    state.matches.shoppingCartIsOpen,
    getClinicianCapacities(state),
    state.clinicians.scheduleMap,
    state.matches.matchSlotPreferenceMap,
    state.clinics.clinicMap,
    state.matches.queue_condition_id,
  ]);

  const dispatch = useDispatch();

  const closeShoppingCart = () => {
    dispatch(matchActions.setShoppingCartIsOpen(false));
  };
  const emptyShoppingCart = () => {
    dispatch(matchActions.emptyShoppingCart());
  };
  const removeMatchFromCart = (matchId: number) => {
    dispatch(matchActions.removeMatchFromCart(matchId));
  };
  const saveMatchFits = (matchFits: MatchFit[], description: MatchingSessionDescription, description_other: string, onFinish?: () => unknown) => {
    dispatch(
      matchOperations.saveMatchFits(matchFits, queue_condition_id, description, description_other, onFinish),
    );
  };

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [matchingSessionState, setMatchingSessionState] = React.useState<MatchingSessionState>(getMatchingSessionInitialState());

  const convertSuggestionToCartItem = (matchId: string) => {
    const suggestion = matchSuggestions[matchId];
    const match = myMatchesMap[matchId];
    const matchFits = matchFitsMap[matchId] || matchFitsMap[parseInt(matchId)];

    // 2023-01-11 we're seeing an edge case where a matchId from the AQM
    // suggestions list isn't in the matchFitsMap, which causes an exception
    // and breaks the Auto Complete functionality. I'm not sure what causes
    // the issue, but for now we'll handle the error silently by excluding
    // the match so the matching team is unblocked. We'll capture the
    // exception in Sentry to help figure out the root cause.
    let fit = null;
    try {
      fit = matchFits.find((fit: Fit) => fit.id === suggestion.fit_id);
    } catch (e) {
      // If matchFits is undefined, fit will remain null and this match
      // won't be added to the cart since it checks `if (item.fit) {...}`
      Sentry.captureException(
        new Error("Suggested matchId missing from matchFitsMap"),
        {
          extra: {
            matchId: matchId,
            suggestion: suggestion,
          },
        },
      );
    }

    const slot: ClinicianSlot = {
      clinician_id: suggestion.clinician_id,
      subregion_id: suggestion.subregion_id,
      day_of_week: suggestion.day_of_week,
      note: suggestion.note,
      recurrence: suggestion.recurrence,
      start_date: suggestion.start_date,
      start_time: suggestion.start_time,
      work_from_home: suggestion.work_from_home,
      modality: suggestion.modality,
      timezone: suggestion.timezone,
    };
    return {
      fit: fit,
      match: match,
      slot: slot,
      is_manual_match: false,
    };
  };

  const addSuggestionToCart = () => {
    const bestMatch = Object.values(matchSuggestions)
      .reduce((prev, current) => {
        return prev.aqm_score > current.aqm_score ? prev : current;
      })
      .match_id.toString();
    const cartItem = convertSuggestionToCartItem(bestMatch);
    dispatch(matchActions.addMatchesToCart([cartItem]));
  };

  const addSuggestionsToCart = () => {
    const cartItems = Object.keys(matchSuggestions).map(
      convertSuggestionToCartItem,
    );
    dispatch(matchActions.addMatchesToCart(cartItems));
  };

  const matchList = Object.values(matchesInCart);

  const tableData = matchList.map((cartItem: ShoppingCartItem): RowItem => {
    const { fit, match, slot, is_manual_match } = cartItem;
    return {
      client: match.client,
      consultClinician: match.created_by_clinician,
      fitId: fit.id,
      matchedClinician: fit.clinician,
      match,
      queueTime: match.consult ? match.consult.start_time : match.created_at,
      // if a slotless match is made, it is safer to assume it is weekly, to avoid overmatching to a clinician
      recurrence: slot?.recurrence ?? "weekly",
      dayOfWeek: slot?.day_of_week,
      startDate: slot?.start_date,
      startTime: slot?.start_time,
      workFromHome: slot?.work_from_home ?? true,
      modality: slot?.modality,
      timezone: slot?.timezone,
      is_manual_match: is_manual_match,
    };
  });

  const columns: ColumnProps<RowItem>[] = [
    {
      title: "Client",
      dataIndex: "client",
      key: "client",
      render: (client: ClientExtended, record) => (
        <>
          <Mangle>{client.initials}</Mangle>{" "}
          <Typography.Text
            style={{ fontSize: "10px", color: colors.$greyText }}
          >
            {client.id}
          </Typography.Text>
          <StaticMatchIcons basicMatch={record.match} />
        </>
      ),
      sorter: (a: RowItem, b: RowItem) => {
        return alphabetizeBy(a, b, "client");
      },
    },
    {
      title: "Consult Clinician",
      dataIndex: "consultClinician",
      key: "consultClinician",
      render: (consultClinician: number) => (
        <ClinicianItem clinicianId={consultClinician} hideClinic={true} />
      ),
      sorter: (a: RowItem, b: RowItem) => {
        if (!a.consultClinician) return -1;
        if (!b.consultClinician) return 1;
        const firstClinician = clinicianMap[a.consultClinician];
        const secondClinician = clinicianMap[b.consultClinician];
        if (!firstClinician) return -1;
        if (!secondClinician) return 1;
        return alphabetizeBy(firstClinician, secondClinician, "first_name");
      },
    },
    {
      title: "Queued",
      dataIndex: "queueTime",
      key: "queueTime",
      render: (queueTime: string) => moment(queueTime).fromNow(),
      sorter: (a: RowItem, b: RowItem) => {
        return alphabetizeBy(a, b, "queueTime");
      },
    },
    {
      title: "Matched Clinician",
      dataIndex: "matchedClinician",
      key: "matchedClinician",
      render: (matchedClinician: number) => (
        <ClinicianItem clinicianId={matchedClinician} hideClinic={true} />
      ),
      sorter: (a: RowItem, b: RowItem) => {
        const firstClinician = clinicianMap[a.matchedClinician];
        const secondClinician = clinicianMap[b.matchedClinician];
        if (!firstClinician) return -1;
        if (!secondClinician) return 1;
        return alphabetizeBy(firstClinician, secondClinician, "first_name");
      },
    },
    {
      title: "Slot",
      dataIndex: "slot",
      key: "slot",
      render: (_: string, record: RowItem) => {
        if (record.dayOfWeek && record.startDate && record.startTime) {
          const startTime = moment(record.startTime, "HH:mm:ss").format("ha");
          let startDate = moment(record.startDate);
          const matchedClinician = clinicianMap[record.matchedClinician];
          return (
            <span style={{ textTransform: "capitalize" }}>
              {record.recurrence} {record.dayOfWeek} {startTime}{" "}
              {record.timezone
                ? IANA_TO_USER_FRIENDLY_TIMEZONE[record.timezone]
                : "Unknown Timezone"}
              <br />
              Starting {startDate.format("MMM Do")}
              {record.modality && (
                <Modality value={record.modality}>{record.modality}</Modality>
              )}
              {!matchedClinician.is_match_to_timeslot && (
                <AntdTag color="red">Not MTAT</AntdTag>
              )}
            </span>
          );
        }
        return "";
      },
      sorter: (a: RowItem, b: RowItem) => {
        if (a.startDate === b.startDate) {
          return alphabetizeBy(a, b, "startTime");
        }
        return alphabetizeBy(a, b, "startDate");
      },

      width: "150px",
    },
    {
      title: "",
      dataIndex: "match",
      key: "delete",
      render: (match: BasicMatch) => (
        <Button
          icon={<DeleteOutlined />}
          onClick={() => removeMatchFromCart(match.id)}
        />
      ),
      width: "20px",
    },
  ];

  const getMatches = () => {
    const rows: CsvDataItem[] = [];
    const headers = [
      "Client ID",
      "Client Initials",
      "Consult Clinician",
      "Client State",
      "Client Payer",
      "Match ID",
      "Days on Queue",
      "Matched Clinician",
      "Recurrence",
      "Day of Week",
      "Start Date",
      "Start Time",
      "Work From Home",
      "Modality",
      "Clinician Timeslot Modality"
    ];

    tableData.forEach((record) => {
      const client = matchesInCart[record.match.id].match.client;
      const consultClinician = record.consultClinician
        ? clinicianMap[record.consultClinician]
        : undefined;
      const matchedClinician = clinicianMap[record.matchedClinician];

      const clientID = client.id;
      const clientInitials = client.initials;
      const consultClinicianName =
        consultClinician?.first_name + " " + consultClinician?.last_name;
      const clientState = client.service_state;
      const clientPayer = getPayerString(client.payers);
      const matchID = record.match.id;
      const daysOnQueue = differenceInDays(
        new Date(),
        new Date(record.queueTime),
      );
      const matchedClinicianName =
        matchedClinician?.first_name + " " + matchedClinician?.last_name;
      const recurrence = record.recurrence;
      const dayOfWeek = record.dayOfWeek;
      const startDate = moment(record.startDate).format("M/D/YY");
      const startTime = moment(record.startTime, "HH:mm:ss").format("ha");
      const workFromHome = record.workFromHome;
      const modality = record.modality;

      const values: string[] = [
        clientID,
        clientInitials,
        consultClinicianName,
        clientState,
        clientPayer,
        matchID,
        daysOnQueue,
        matchedClinicianName,
        recurrence,
        dayOfWeek,
        startDate,
        startTime,
        workFromHome,
        modality,
      ].map((i) => String(i));
      rows.push(values);
    });

    return { headers, rows };
  };

  const matchCount: { [clinicianId: number]: number } = matchList.reduce(
    (accumulator, match) => {
      const increment = match.slot?.recurrence === "biweekly" ? 0.5 : 1;
      accumulator[match.fit.clinician] = accumulator[match.fit.clinician]
        ? accumulator[match.fit.clinician] + increment
        : increment;
      return accumulator;
    },
    {},
  );

  const matchCountTableData = Object.entries(matchCount).map(
    ([clinicianId, count]): MatchCountRowItem => {
      const capacity = getClinicianCapacity(parseInt(clinicianId));
      return {
        matchedClinician: parseInt(clinicianId),
        matchesSuggested: count,
        matchesRequested: capacity.capacity ?? -1,
      };
    },
  );

  const getStatePayerMatchCounts = () => {
    /**
     * Return an object containing the number of matches on the queue
     * for each state/payer, further broken down by medicare/medical or
     * neither. This is used to get a the number of clients a given
     * clinician is eligible to match with based on state/payer.
     *
     * The object is initialized with keys for CA, WA, and FL DTC matches. As
     * we come across new payers in the myMatchesMap loop, we add its
     * ID as a new key. A finished object would look something like:
     * {
     *   CA_DTC: { is_medicare: 2, is_medical: 6, other: 45 },
     *   WA_DTC: { is_medicare: 1, is_medical: 0, other: 12 },
     *   FL_DTC: { is_medicare: 0, is_medical: 1, other: 5 },
     *   <some_payer_id_1234>: { is_medicare: 0, is_medical: 0, other: 60 },
     *   <some_payer_id_5678>: { is_medicare: 0, is_medical: 4, other: 110 },
     * };
     */
    const statePayerEligibleMatchCounts = {
      CA_DTC: { is_medicare: 0, is_medical: 0, other: 0 },
      WA_DTC: { is_medicare: 0, is_medical: 0, other: 0 },
      FL_DTC: { is_medicare: 0, is_medical: 0, other: 0 },
    };
    Object.values(myMatchesMap).forEach((m) => {
      if (m.client.payers.length === 0) {
        // Client is DTC if payers is empty, so increment the relevant
        // state DTC counter (eg. CA_DTC)
        const key = `${m.client.service_state}_DTC`;
        if (m.client.is_medical) {
          statePayerEligibleMatchCounts[key].is_medical += 1;
        } else if (m.client.is_medicare) {
          statePayerEligibleMatchCounts[key].is_medicare += 1;
        } else {
          statePayerEligibleMatchCounts[key].other += 1;
        }
      } else {
        // The client has payers.length > 0 here, so we know they're not DTC.
        // Use a set to get a unique list of payers for this client, then
        // add one to the appropriate counter for each payer
        const clientPayers: Set<string> = new Set();
        m.client.payers
          .filter((p) => p.service_type === "individual")
          .forEach((p) => clientPayers.add(p.payer.id));
        clientPayers.forEach((p: string) => {
          // If we haven't seen a payer yet, add the id as a key
          // and empty counts as the value to our counts object
          if (statePayerEligibleMatchCounts[p] === undefined) {
            statePayerEligibleMatchCounts[p] = {
              is_medicare: 0,
              is_medical: 0,
              other: 0,
            };
          }
          if (m.client.is_medical) {
            statePayerEligibleMatchCounts[p].is_medical += 1;
          } else if (m.client.is_medicare) {
            statePayerEligibleMatchCounts[p].is_medicare += 1;
          } else {
            statePayerEligibleMatchCounts[p].other += 1;
          }
        });
      }
    });

    return statePayerEligibleMatchCounts;
  };

  const getEligibleMatchCount = (clinician: Clinician) => {
    const statePayerMatchCounts = getStatePayerMatchCounts();
    let eligibleMatchCount = 0;
    clinician.service_states
      .filter((state: string) => ["CA", "WA", "FL"].includes(state))
      .forEach((state: string) => {
        const counts = statePayerMatchCounts[`${state}_DTC`];
        eligibleMatchCount += counts.other;
        if (clinician.is_medicaid_approved) {
          eligibleMatchCount += counts.is_medical;
        }
      });
    clinician.current_payer_service_records
      // @ts-ignore
      .filter((p) => p.service_type === "individual" || p.service_type === "all")
      .forEach((p) => {
        // @ts-ignore
        const counts = statePayerMatchCounts[p.payer.id];
        if (counts === undefined) return;
        eligibleMatchCount += counts.other;
        if (clinician.is_medicaid_approved) {
          eligibleMatchCount += counts.is_medical;
        }
      });

    return eligibleMatchCount;
  };

  const buildClinicianFitMap = () => {
    /** Creates and returns a mapping from clinician id to a list of match
     * ids for which they are a fit. eg:
     * {
     *   34: [1,43,34,2],
     *   894: [1, 234, 89],
     *   ...
     * }
     *
     * ...which helps us determine how many fits a clinician has an overlapping
     * timeslot with.
     */
    const clinicianFits = {};
    Object.keys(matchCount).forEach((clinicianId) => {
      // Loop through match fits and if any of them are related to this clinician,
      // add the match id
      const fitsForThisClinician = [];
      for (const [matchId, fitsArray] of Object.entries(matchFitsMap)) {
        const isAFit = fitsArray.some((fit) => {
          if (Number(fit.clinician) === Number(clinicianId)) {
            return true;
          }
          return false;
        });
        if (isAFit) {
          fitsForThisClinician.push(matchId);
        }
      }
      clinicianFits[clinicianId] = Array.from(fitsForThisClinician);
    });

    return clinicianFits;
  };

  const getFitsWithOverlappingTimeslotCount = (
    clinician: Clinician,
    clinicianFits: ClinicianFitMap,
  ) => {
    /** Return the number of fits for this clinician that have an overlapping
     * timeslot.
     */
    const clinicianSchedule: ScheduleItem[] = scheduleMap[clinician.id] || [];

    let fitsWithOverlappingTimeslot = 0;
    clinicianFits[clinician.id].forEach((matchId) => {
      const matchSlotPreferences: MatchSlotPreference[] =
        matchSlotPreferenceMap[matchId];
      const hasOverlap = clinicianSchedule.some((clinicianSlot) => {
        return matchSlotPreferences.some((clientSlot) => {
          const daysOverlap =
            clinicianSlot.day_of_week.substring(0, 2) ===
            clientSlot.day_of_week;
          if (!daysOverlap) return false;

          // clinician slots come in batches, eg. start_time 8:00:00 end_time 14:00:00
          // client slots are always one hour long, eg. start_time 13:00:00 end_time 14:00:00
          // For each combination of slots, see if the client slot starts at the same time or
          // after the start time of the clinician slot AND ends before or at the same time as
          // the clinician slot
          const clxnSlotStartTime = Number(
            clinicianSlot.start_time.split(":")[0],
          );
          const clxnSlotEndTime = Number(clinicianSlot.end_time.split(":")[0]);
          const clientSlotStartTime = Number(
            clientSlot.start_time.split(":")[0],
          );
          const clientSlotEndTime = Number(clientSlot.end_time.split(":")[0]);
          const timesOverlap =
            clientSlotStartTime >= clxnSlotStartTime &&
            clientSlotEndTime <= clxnSlotEndTime;

          if (!timesOverlap) return false;

          const clientSlotIsTeletherapy = clientSlot.subregion === null;
          const subregionsMatch =
            clinicMap[clinicianSlot.clinic_id].subregion ===
            clientSlot.subregion;
          const clinicianInClinic = clinicianSlot.work_from_home === false;
          const subregionIsAFit =
            (subregionsMatch && clinicianInClinic) || clientSlotIsTeletherapy;

          if (!subregionIsAFit) return false;

          return true;
        });
      });
      if (hasOverlap) {
        fitsWithOverlappingTimeslot += 1;
      }
    });

    return fitsWithOverlappingTimeslot;
  };

  const getClinicianMatchSummary = () => {
    const rows: CsvDataItem[] = [];
    const headers = [
      "Matched Clinician",
      "Degree",
      "Clinician State",
      "Clinician Payer",
      "Matches Suggested",
      "Matches Requested",
      "State-Payer Eligible Matches",
      "Fits on Queue",
      "Fits with Mutual Timeslot",
      "Clinician Employment Type",
    ];

    const clinicianFits = buildClinicianFitMap();

    matchCountTableData.forEach((row) => {
      const clinician = clinicianMap[row.matchedClinician];
      const clinicianName = clinician.first_name + " " + clinician.last_name;
      const degree = clinician.degree;
      const state = clinician.service_states.join("; ");
      const payer = clinician.current_payer_service_records
        // @ts-ignore
        .filter((p) => p.service_type === "individual")
        // @ts-ignore
        .map((p) => p.payer.display_name)
        .join("; ");
      const suggested = row.matchesSuggested;
      const requested = row.matchesRequested;
      const employmentType = clinician.configuration?.classification?.employment_type;

      const values = [
        clinicianName,
        degree,
        state,
        payer,
        suggested,
        requested,
        getEligibleMatchCount(clinician),
        clinicianFits[clinician.id].length,
        getFitsWithOverlappingTimeslotCount(clinician, clinicianFits),
        employmentType,
      ];
      rows.push(values);
    });
    return { headers, rows };
  };

  const downloadMatches = () => {
    const data = getMatches();
    downloadAsCSV(data, "matches");
  };

  const downloadClinicianMatchSummary = () => {
    const data = getClinicianMatchSummary();
    downloadAsCSV(data, "clinician_match_summary");
  };

  const downloadCart = () => {
    downloadMatches();
    downloadClinicianMatchSummary();
  };

  const validate = () => {
    if (!matchingSessionState.description) {
      setMatchingSessionState(prevState => ({
        ...prevState,
        description_error: true
      }));
      throw new Error("This field is required");
    }
    if (matchingSessionState.description === "other" && !matchingSessionState.description_other) {
      setMatchingSessionState(prevState => ({
        ...prevState,
        description_other_error: true
      }));
      throw new Error("This field is required");
    }
  }

  return (
    <Drawer
      title={
        <Row layout="space-between center">
          <span>Shopping Cart</span>
          <span>Total Matches: {matchList.length}</span>
          <Button
            onClick={closeShoppingCart}
            type="primary"
            icon={<CheckOutlined />}
          />
        </Row>
      }
      closable={false}
      onClose={closeShoppingCart}
      open={shoppingCartIsOpen}
      width={950}
      bodyStyle={{ padding: 0 }}
    >
      <Table
        columns={columns}
        dataSource={tableData}
        locale={{ emptyText: "Shopping cart is empty." }}
        pagination={false}
        rowKey={(item: RowItem) => "row-" + item.match.id.toString()}
        expandedRowRender={(rowItem: RowItem) => (
          <MatchNoteInput matchId={rowItem.match.id} />
        )}
        footer={() => (
          <>
            <Row style={{ gap: '24px', margin: '4px 10px 0px' }}>
              <Select
                style={{ width: 300, flex: 'none' }}
                value={matchingSessionState.description}
                onChange={(value: string) => {
                  setMatchingSessionState({
                    ...getMatchingSessionInitialState(),
                    description: value
                  })
                }}
                options={DESCRIPTIONS}
                placeholder="Matching-session description"
                {...(matchingSessionState.description_error ? { status: 'error' } : {})}
              />
              <Input
                style={{ minWidth: 360, flex: 'auto' }}
                value={matchingSessionState.description_other}
                onChange={e => {
                  setMatchingSessionState(prevState => ({
                    ...prevState,
                    description_other: e.target.value,
                    description_other_error: false,
                  }))
                }}
                placeholder="Other description"
                disabled={matchingSessionState.description !== "other"}
                {...(matchingSessionState.description_other_error ? { status: 'error' } : {})}
              />
            </Row>
            <Row style={{ gap: '24px', margin: '20px 10px 0px' }}>
              <Button style={{ flex: 'auto' }} onClick={emptyShoppingCart}>Clear Cart</Button>
              <Button style={{ flex: 'auto' }} onClick={addSuggestionToCart}>Add Suggestion</Button>
              <Button style={{ flex: 'auto' }} onClick={addSuggestionsToCart}>Auto-Complete</Button>
              <Button style={{ flex: 'auto' }} onClick={downloadCart}>Download</Button>
              <Button
                style={{ flex: 'auto' }}
                disabled={isSubmitting}
                onClick={async () => {
                  try {
                    validate();
                  } catch (e) {
                    // no need to bubble up the error since we expect this one and are displaying
                    // a message in the UI
                    return;
                  }
                  setIsSubmitting(true);
                  const matchFits: MatchFit[] = tableData.map((row) => {
                    return {
                      dayOfWeek: row.dayOfWeek,
                      fitId: row.fitId,
                      matchId: row.match.id,
                      matchNote: row.match.match_note,
                      recurrence: row.recurrence,
                      startDate: row.startDate,
                      startTime: row.startTime,
                      workFromHome: row.workFromHome,
                      resolved_modality: row.modality,
                      is_manual_match: row.is_manual_match,
                      timezone: row.timezone as Timezones,
                    };
                  });
                  saveMatchFits(
                    matchFits,
                    matchingSessionState.description,
                    matchingSessionState.description_other,
                    () => {
                      setMatchingSessionState(getMatchingSessionInitialState())
                      setIsSubmitting(false)
                    }
                  );
                }}
                type="primary"
              >
                Checkout
              </Button>
            </Row>
            <div style={{ height: '16px', margin: '10px' }}>
              {(matchingSessionState.description_error || matchingSessionState.description_other_error) && (
                <ErrorText>Please complete the required fields</ErrorText>
              )}
            </div>
          </>
        )}
      />
      <MatchCountTable matchCountTableData={matchCountTableData} />
    </Drawer>
  );
}

export const MatchNoteInput = ({ matchId }: { matchId: number }) => {
  const dispatch = useDispatch();
  const [matchesInCart] = useShallowEqualSelector((state) => [
    state.matches.matchesInCart,
  ]);

  const noteInState = matchesInCart[matchId]?.match?.match_note;
  const [matchNote, setMatchNote] = useState(noteInState);

  const debouncedNote = useDebounce(matchNote, 500);
  const dispatchMatchNote = React.useCallback(() => {
    if (matchesInCart[matchId]) {
      dispatch(
        matchActions.updateMatchInCart({
          ...matchesInCart[matchId].match,
          match_note: debouncedNote,
        }),
      );
    }

    // do NOT include matchesInCart in this deps, because we are mutating it in this action
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, matchId, debouncedNote]);

  useEffect(() => {
    dispatchMatchNote();
  }, [dispatchMatchNote, debouncedNote]);

  useEffect(() => {
    if (noteInState && noteInState !== matchNote) {
      setMatchNote(noteInState);
    }
    // only want to change the form value when the value in state changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noteInState]);

  if (!matchesInCart[matchId]) {
    return null;
  }

  return (
    <Pad>
      <Title size="small">Match Note:</Title>
      <Input.TextArea
        value={matchNote}
        onChange={(e) => setMatchNote(e.target.value)}
        onBlur={() => setMatchNote(matchNote)}
        placeholder="Provide context for the consult clinician should they need it when sending the match email."
      />
    </Pad>
  );
};

export default ShoppingCart;

const Modality = styled.p<{ value: string | undefined }>`
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: var(
    --colors-${(props) => (props.value === "hybrid" ? "blue" : "green")}11
  );
  margin-top: 3px;
  margin-bottom: 0;
`;

const ErrorText = styled.p`
  color: red;
`

function getPayerString(payers: ClientPayerServiceRecord[]): string {
  // use a set to remove duplicates
  const payerSet = new Set();
  payers
    .filter((p) => p.service_type === "individual")
    .forEach((p) => payerSet.add(p.payer.display_name));
  let payerString = Array.from(payerSet).join("; ");

  if (payerString === "") {
    payerString = "DTC";
  }

  return payerString;
}
