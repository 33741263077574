import { getMostRecentClientPayerServiceRecord } from "@/app/api/utils/get-most-recent-client-payer-service-record.util";
import { getCurrentClinicianAttendedSessions } from "@/app/library/utils";
import { DateTime } from "luxon";
import {
  ActiveClientFragment,
  MyClientsOverviewQuery,
  MyClientsOverviewReliableChangeQuery,
} from "../../../../graphql/generated";
import {
  ClientOverviewApiData,
  ClientPayerServiceRecord,
  EventApiData,
  ManualSlotReservationApiData,
} from "../../../api/use-my-clients-overview/my-clients-data.interface";

/**
 * @function getMostRecentManualSlotReservation
 * @description TODO
 *
 *
 * @param { ManualSlotReservationApiData[] } manualSlotReservation
 * @returns { ManualSlotReservationApiData }
 */

export const sortAndFilterManualSlotReservations = (
  manualSlotReservation: ManualSlotReservationApiData[],
) => {
  const sortedManualSlotReservations = manualSlotReservation
    ?.sort(
      (a, b) =>
        DateTime.fromISO(a.startDate).toMillis() -
        DateTime.fromISO(b.startDate).toMillis(),
    )
    .filter((msr) => msr.status !== "ended");

  return sortedManualSlotReservations ?? null;
};

/**
 * @function parseSessions
 * @description returns a client's next session, upcoming sessions in ascending order,
 * and session history sessions in descending order.
 *
 * upcoming sessions are events in the future that have appointment status = null
 * session history sessions includes upcoming events that may have been cancelled
 * @param { EventApiData[] } events
 * @returns { [EventApiData, EventApiData[], EventApiData[]] }
 */
export const parseSessions = (
  events: EventApiData[] | undefined,
  clinicianId?: number,
) => {
  const sortedSessions = events?.sort(
    (a, b) =>
      DateTime.fromISO(b.startTime).toMillis() -
      DateTime.fromISO(a.startTime).toMillis(),
  );

  const now = DateTime.now().toMillis();

  const upcomingSessions = sortedSessions
    ?.filter(
      (event: EventApiData) =>
        DateTime.fromISO(event.startTime).plus({ minutes: 50 }).toMillis() >
          now && event.appointmentStatus === null,
    )
    .reverse();

  const nextSession =
    upcomingSessions &&
    upcomingSessions.length > 0 &&
    upcomingSessions[0].clinicianId === clinicianId
      ? upcomingSessions[0]
      : undefined;

  const sessionHistorySessions = sortedSessions?.filter(
    (event) =>
      event.appointmentStatus !== null ||
      DateTime.fromISO(event.startTime).plus({ minutes: 50 }).toMillis() < now,
  );

  return { nextSession, upcomingSessions, sessionHistorySessions };
};

export const getCombinedSeverity = (severity: string | null | undefined) => {
  if (severity === "subclinical") {
    return "subclinical";
  } else if (severity === "mild" || severity === "moderate") {
    return "mild - moderate";
  } else if (severity === "moderately-severe" || severity === "severe") {
    return "moderate - severe";
  }

  return null;
};

/**
 * @function parseClientOverview
 * @description "flattens" the response from useMyClientsOverviewQueryWrapper.
 *
 * @param { CWAClientInfoApiData } clientOverviewApiData
 * @returns { ClientOverviewApiData[] }
 */

const parseMyClientsClientOverview = (
  clinicianId: number,
  activeClientApiData: ActiveClientFragment,
): ClientOverviewApiData => {
  const { client } = activeClientApiData;

  const {
    clientId,
    email,
    firstName,
    lastName,
    preferredName,
    events,
    phoneNumber,
    clientOverview,
    diagnosisRecords,
    clientPayerServiceRecords,
    manualSlotReservations,
    careLocation,
    matches
  } = client ?? {};

  const {
    primaryAssessment,
    mbcTrackStatus,
    mbcTrackStatusBeta,
    endorsedSi,
    suggestedRange,
    severity,
    welkinLink,
    healthieLink,
    healthieId,
  } = clientOverview ?? {};

  const { nextSession, upcomingSessions, sessionHistorySessions } =
    parseSessions(events as EventApiData[], clinicianId);

  const sortedActiveManualSlotReservations =
    sortAndFilterManualSlotReservations(
      manualSlotReservations as ManualSlotReservationApiData[],
    );

  const combinedSeverity = getCombinedSeverity(severity);

  const currentClientPayerServiceRecord = getMostRecentClientPayerServiceRecord(
    clientPayerServiceRecords as ClientPayerServiceRecord[],
  );
  const totalSessionsAttendedWithCurrentClinician =
    getCurrentClinicianAttendedSessions(events as EventApiData[], clinicianId);
  return {
    clientId,
    firstName: firstName ?? "",
    lastName: lastName ?? "",
    preferredName: preferredName ?? null,
    email,
    // parse "most recent cpsr"
    clientPayerServiceRecord:
      clientPayerServiceRecords && clientPayerServiceRecords.length > 0
        ? currentClientPayerServiceRecord
        : null,
    primaryDiagnosis:
      diagnosisRecords && diagnosisRecords.length > 0
        ? diagnosisRecords[0].primaryDiagnosisCode
        : null,
    severity: severity ?? null,
    combinedSeverity,
    // totalSessions: events?.length,
    totalSessionsAttended: events?.filter(
      (event) =>
        event.appointmentStatus === "attended" &&
        event.serviceType !== "consult",
    ).length,
    totalSessionsAttendedWithCurrentClinician,
    suggestedRange: suggestedRange ?? null,
    primaryAssessment: primaryAssessment ?? null,
    mbcTrackStatus: mbcTrackStatus ?? null,
    mbcTrackStatusBeta: mbcTrackStatusBeta ?? null,
    endorsedSi: endorsedSi ?? false,
    phoneNumber: phoneNumber ?? null,
    nextSession: nextSession ?? null,
    upcomingSessions: upcomingSessions ?? [],
    sessionHistorySessions: sessionHistorySessions ?? [],
    welkinLink: welkinLink ?? null,
    healthieLink: healthieLink ?? null,
    healthieId: healthieId ?? null,
    manualSlotReservation: sortedActiveManualSlotReservations,
    careLocation: careLocation ?? null,
    isHighAcuity: matches?.[0]?.priority === "high_acuity"
  };
};

/**
 * @function selectDataForMyClients
 * @description takes in data of a MyClientsOverviewQuery type, which is
 * automatically generated by the graphql codegen cli. returns a LIST of
 * ClientOverviewApiData
 * @param { MyClientsOverviewQuery | MyClientsOverviewReliableChangeQuery } data
 * @returns { ClientOverviewApiData[] }
 */
export const selectDataForMyClients = (
  data: MyClientsOverviewQuery,
): ClientOverviewApiData[] => {
  const { cwaActiveClientsByClinician } = data;

  // TODO: https://twochairs.atlassian.net/browse/TC-4501
  // since we’re not supporting consults initially, we're filtering out consults for now
  const clientOverviews = cwaActiveClientsByClinician
    .filter((cwaActiveClientByClinician) => {
      const onlyHasHadConsultWithClinician =
        cwaActiveClientByClinician.sources?.includes("has_consult") &&
        cwaActiveClientByClinician.sources.length === 1;

      return !onlyHasHadConsultWithClinician;
    })
    .map((cwaActiveClientByClinician) => {
      return parseMyClientsClientOverview(
        cwaActiveClientByClinician.clinicianId as number,
        cwaActiveClientByClinician as ActiveClientFragment,
      );
    });

  return clientOverviews;
};

/**
 * @function selectDataForMyClientsSchedule
 * @description takes in data of a MyClientsOverviewQuery type, which is
 * automatically generated by the graphql codegen cli. returns a MAP of
 * ClientOverviewApiData so that's it's easy to grab the client's overview
 * using their client id from URL params.
 * @param { MyClientsOverviewQuery } data
 * @returns { Record<number, ClientOverviewApiData> }
 */
export const selectDataForMyClientsSchedule = (
  data: MyClientsOverviewQuery,
): Record<string, ClientOverviewApiData> => {
  const clientOverviewMap = {};

  data.cwaActiveClientsByClinician.forEach((cwaActiveClientByClinician) => {
    const clientOverview = cwaActiveClientByClinician;

    if (clientOverview?.client?.clientId) {
      clientOverviewMap[clientOverview?.client?.clientId] =
        parseMyClientsClientOverview(
          clientOverview?.clinicianId as number,
          clientOverview as ActiveClientFragment,
        );
    }
  });
  return clientOverviewMap;
};
