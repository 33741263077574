const group_clinically_appropriate_no_reason_checkboxes = [
  "Financial concerns/Insurance coverage",
  "Already Engaged in Two Chairs Individual Therapy",
  "Active suicidality or recent attempt",
  "Emotion dysregulation",
  "Acute psychotic symptoms",
  "Cognitive impairment",
  "Severe, unmanaged personality disorder",
  "Managing acute crisis",
  "Low motivation or treatment expectancy",
  "Other (Please describe)",
];

const referred_out_reason_checkboxes = [
  "Client cannot be safely/effectively served via teletherapy (even in the short-term)",
  "Client wants in-person therapy when available, but does not live near a Two Chairs clinic",
  "Client cannot be safely/effectively served with psychotherapy 1x/week",
  "Referring for additional treatment (in parallel)",
  "Referring for a higher level of care",
  "Other",
];

export {
  group_clinically_appropriate_no_reason_checkboxes,
  referred_out_reason_checkboxes,
};
