import React from "react";
import { createSelector } from "reselect";

import { AppState } from "../../state/models";
import {
  ClinicianManualCapacityV2DataMap,
  ClinicianVolumeData,
  ClinicianVolumeDataMap,
  volumeDataIsRamp,
} from "./types";

export interface WeeklyVolumeData {
  volume: number;
  maxNewClients: number;
  matchesRequested: number;
  numAvailableSlots: number;
  targetPanelSize: number;
  manualCapacity: number;
  manualCapacitySetAt: string;
  rawData: ClinicianVolumeData;
  source: string;
  hasPTONextWeek: boolean;
  hasActiveHold: boolean;
}

function computeVolumeForClinician(
  clinicianVolumeDataMap: ClinicianVolumeDataMap,
  clinicianId: number,
  clinicianManualCapacityDataMap: ClinicianManualCapacityV2DataMap,
  isNetwork: boolean,
) {
  const thisVolumeData = clinicianVolumeDataMap[clinicianId];
  const thisManualCapacity = clinicianManualCapacityDataMap[clinicianId];

  const volume = thisVolumeData.weekly_volume_score_with_pending_matches;
  const remainingVolume = thisVolumeData.remaining_volume;

  const targetPanelSize = thisVolumeData.target_client_hours;
  const maxNewClients = Math.max(0, remainingVolume);

  const numAvailableSlots =
    thisVolumeData.slots_data?.open_slots?.filter(
      (slot) => slot.recurrence === "weekly",
    ).length || 0;

  // AKA auto capacity:
  let matchesRequested = isNetwork
    ? Math.min(maxNewClients, numAvailableSlots)
    : maxNewClients;

  const manualCapacity = thisManualCapacity?.capacity;
  const manualCapacitySetAt = thisManualCapacity?._date_start;
  return {
    volume,
    maxNewClients,
    matchesRequested,
    numAvailableSlots,
    targetPanelSize,
    manualCapacity,
    manualCapacitySetAt,
    rawData: thisVolumeData,
    source: thisVolumeData.source,
    hasPTONextWeek: volumeDataIsRamp(thisVolumeData)
      ? thisVolumeData.ramp_data?.has_pto_next_week ?? false
      : false,
  };
}

export const volumeDataMapSelector = createSelector(
  (state: AppState) => state.slottool.clinicianVolumeDataMap,
  (state: AppState) => state.slottool.clinicianManualCapacityDataMap,
  (state: AppState) => state.clinicians.clinicianMap,
  (clinicianVolumeDataMap, clinicianManualCapacityDataMap, clinicianMap) => {
    const volumeData: Record<number, WeeklyVolumeData> = {};

    for (const clinicianId of Object.keys(clinicianVolumeDataMap)) {
      const isNetwork =
        clinicianId in clinicianMap
          ? clinicianMap[clinicianId].is_network
          : false;

      const {
        volume,
        maxNewClients,
        matchesRequested,
        numAvailableSlots,
        targetPanelSize,
        manualCapacity,
        manualCapacitySetAt,
        rawData,
        source,
        hasPTONextWeek,
      } = computeVolumeForClinician(
        clinicianVolumeDataMap,
        parseInt(clinicianId),
        clinicianManualCapacityDataMap,
        isNetwork,
      );

      volumeData[clinicianId] = {
        volume,
        maxNewClients,
        matchesRequested,
        numAvailableSlots,
        targetPanelSize,
        manualCapacity,
        manualCapacitySetAt,
        rawData,
        source,
        hasPTONextWeek,
      };
    }
    return volumeData;
  },
);
