import { Timezone } from "@/app/_shared/TimezoneContext";
import { GetAllLiveGroupsAfterDateGroupDataFragment, GetAllLiveGroupsAfterDateQuery } from "@/graphql/generated";
import { DateTime } from "luxon";

export interface GroupDescriptionForClientEnroll {
  id: string;
  description: string;
}

const getGroupDescriptionFromGroup = (group: GetAllLiveGroupsAfterDateGroupDataFragment, userTimezone: Timezone) => {
  const groupStartDateTitleAndClinicianName
    = `${group.groupStartDate} - ${group.groupType.title} - ${group.clinician.fullName}`;
  const groupStartDateTime = DateTime.fromISO(group.groupStartTime, { zone: userTimezone });
  const dayOfWeek = groupStartDateTime.toFormat('EEEE');
  const hourOfDay = groupStartDateTime.toFormat('ha ZZZZ');
  const description = `${dayOfWeek}s at ${hourOfDay}, starts ${groupStartDateTitleAndClinicianName}`;
  return {
    id: group.id,
    description,
  }
}

export const getGroupDescriptionsForClientEnroll = (data: GetAllLiveGroupsAfterDateQuery, userTimezone: Timezone) => {
  if (data.groups.length) {
    return data.groups
      .filter(group => group.clientGroups.length < group.capacity)
      .map(group => getGroupDescriptionFromGroup(group, userTimezone));
  }
  return [];
}
