import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { matchOperations } from "../../../state/models/matches";
import { DownOutlined } from "@ant-design/icons";
import { Button, Popover, AutoComplete, Divider } from "antd";
import { useShallowEqualSelector } from "../../_helpers/redux";
import { SelectValue } from "antd/es/select";
import { BasicUser } from "../../../api/types";

const BulkMatchReassignment = () => {
  const [assignableUsers] = useShallowEqualSelector((state) => [
    state.matches.assignableUsers,
  ]);

  const [searchTermFrom, updateSearchTermFrom] = React.useState("");
  const [searchTermTo, updateSearchTermTo] = React.useState("");

  const [fromAssignee, updateFromAssignee] = React.useState("");
  const [toAssignee, updateToAssignee] = React.useState("");

  const dispatch = useDispatch();
  useEffect(() => {
    if (assignableUsers.length < 1) {
      dispatch(matchOperations.getAssignableUsers());
    }
    // eslint-disable-next-line
  }, []);

  const filteredSearchTermFromUsers = assignableUsers
    .filter((user) =>
      `${user.first_name} ${user.last_name}`
        .toLowerCase()
        .includes(searchTermFrom.toLowerCase()),
    )
    .map((user) => ({
      value: `${user.first_name} ${user.last_name}`,
    }));

  const filteredSearchTermToUsers = assignableUsers
    .filter((user) =>
      `${user.first_name} ${user.last_name}`
        .toLowerCase()
        .includes(searchTermTo.toLowerCase()),
    )
    .map((user) => ({
      value: `${user.first_name} ${user.last_name}`,
    }));

  return (
    <div>
      <Popover
        title="Reassign Matches"
        content={
          <div>
            <AutoComplete
              style={{ width: "100%" }}
              onSelect={(value: string) => {
                updateFromAssignee(value);
              }}
              onSearch={updateSearchTermFrom}
              placeholder="From..."
            >
              {filteredSearchTermFromUsers.map((option) => {
                return (
                  <AutoComplete.Option key={option.value}>
                    {option.value}
                  </AutoComplete.Option>
                );
              })}
            </AutoComplete>

            <Divider
              orientation="center"
              style={{ width: "100px", margin: "5px 0" }}
            >
              <DownOutlined />
            </Divider>

            <AutoComplete
              style={{ width: "100%", marginBottom: "5px" }}
              onSelect={(value: string) => {
                updateToAssignee(value);
              }}
              onSearch={updateSearchTermTo}
              placeholder="To..."
            >
              {filteredSearchTermToUsers.map((option) => {
                return (
                  <AutoComplete.Option key={option.value}>
                    {option.value}
                  </AutoComplete.Option>
                );
              })}
            </AutoComplete>

            <Button
              type="primary"
              style={{ width: "100%" }}
              disabled={fromAssignee === null || toAssignee === null}
              onClick={() => {
                const fromUser = assignableUsers.find(
                  (user) =>
                    `${user.first_name} ${user.last_name}` === fromAssignee,
                );

                const toUser = assignableUsers.find(
                  (user) =>
                    `${user.first_name} ${user.last_name}` === toAssignee,
                );

                dispatch(
                  matchOperations.bulkMatchReassignment(
                    fromUser?.id,
                    toUser?.id,
                  ),
                );
              }}
            >
              Submit
            </Button>
          </div>
        }
        placement="bottom"
        trigger="click"
      >
        <Button style={{ marginRight: "10px" }}>
          Bulk Reassign <DownOutlined />
        </Button>
      </Popover>
    </div>
  );
};

export default BulkMatchReassignment;
