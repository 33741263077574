import { CommonAbbr, UNSTYLED_ABBR_STYLE } from "@/app/_shared/CommonAbbr";
import { styledStitches } from "@/app/design-system/styles/stitches.config";
const sharedTagStyles = {
  br: "$8",
  px: 12,
  py: 4,
  fontSize: 16,
  lineHeight: "24px",
  alignContent: "center",
  backgroundColor: "$neutral3",
  color: "$neutral11",
  minWidth: "fit-content",

  variants: {
    size: {
      small: {
        fontSize: 14,
        lineHeight: "21px",
      },
    },
    px: {
      12: {
        px: 12,
      },
    },
    color: {
      neutral: {
        backgroundColor: "$neutral3",
        color: "$neutral11",
      },
      blue: {
        backgroundColor: "$blue3",
        color: "$blue11",
      },
      green: {
        backgroundColor: "$green3",
        color: "$green11",
      },
      yellow: {
        backgroundColor: "$yellow3",
        color: "$yellow11",
      },
      red: {
        backgroundColor: "$red3",
        color: "$red11",
      },

      violet: {
        backgroundColor: "$violet3",
        color: "$violet11",
      },

      // No source in Figma for orange, but it is being used
      orange: {
        backgroundColor: "$orange3",
        color: "$orange11",
      },
      // No source in Figma for alertRed, but it is being used
      alertRed: {
        backgroundColor: "$red9",
        color: "$neutral0",
      },
    },
  },
};
export const Tag = styledStitches("div", { ...sharedTagStyles });

export const CommonAbbrTag = styledStitches("div", {
  ...UNSTYLED_ABBR_STYLE,
  ...sharedTagStyles,
});
