import ReactHtmlParser from "react-html-parser";
import React from "react";

import {
  ClientExtended,
  CoupleForMatch,
  CouplesJointIntakeSurveyQuestion,
  CouplesJointIntakeSurveyQuestionResponse,
  JointIntakeFormResponse,
  Tag,
  TagResponse,
} from "../../../../api/types";
import Mangle from "../../../_shared/Mangle";

const questionOrder = [
  "living_in_ca",
  "relationship_status",
  "relationship_length",
  "living_situation",
  "couples_demographics",
  "individual_mental_health",
  "individual_mental_health_treatment",
  "focus_areas",
  "focus_areas_ranked",
  "what_brings_you_in",
  "frequency_of_concerns",
  "distress",
  "acuity",
  "prior_counseling",
  "prior_counseling_length",
  "prior_counseling_helpful",
  "clinician_demographics_ethnicity",
  "clinician_demographics_sexuality",
  "scheduling",
  "conflict_of_interest",
  "certify",
];

const renderCoupleMember = (client: ClientExtended) => {
  return (
    <div key={client.first_name}>
      <div>
        First name: <Mangle>{client.first_name}</Mangle>
      </div>
      <div>
      Last name: <Mangle>{client.last_name}</Mangle>
      </div>
      <div>
      Email: <Mangle>{client.email}</Mangle>
      </div>
      <div>
      Phone: <Mangle>{client.phone_number || 'Not provided'}</Mangle>
      </div>
      <div>
      DOB: <Mangle>{client.dob}</Mangle>
      </div>
    </div>
  );
};

export const renderCouple = (couple: CoupleForMatch) => {
  let elementResponse = [];

  elementResponse.push(
    <h3 key={0}>Client A Info</h3>,
    renderCoupleMember(couple.clientA),
    <h3 key={1} style={{ marginTop: "32px" }}>Client B Info</h3>,
    renderCoupleMember(couple.clientB),
  );
  return elementResponse;
};

export const renderQuestionAndAnswer = (
  formResponse: JointIntakeFormResponse,
  tagMap: { [id: number]: Tag },
) => {
  return questionOrder.map(slug => {
    const question = formResponse.form_questions.find(
      q => q.slug === slug,
    ) as CouplesJointIntakeSurveyQuestion;
    const response = formResponse.form_responses.find(
      fr => fr.general_survey_question === question.id,
    ) as CouplesJointIntakeSurveyQuestionResponse;

    let responseText = "Question not answered.";
    let elementResponse = [];

    if (response) {
      if (
        typeof response.value === "string" ||
        typeof response.value === "number"
      ) {
        responseText = response.value;
      } else if (typeof response.value === "boolean") {
        responseText = response.value ? "Yes" : "No";
      } else if (Array.isArray(response.value)) {
        responseText = response.value.join(", ");
      } else if (response.value.structured_response) {
        // Sometimes typescript is v stupid
        const o = (response.value as unknown) as TagResponse;
        const arr = o.structured_response as number[];

        const str = arr
          .map((sr: number) => {
            return tagMap[sr].name;
          })
          .join(", ");
        elementResponse.push(<div key={str}>{str}</div>);
      }
      if (response.value.unstructured_response) {
        const str = `
            \
            Additional details:
            ${response.value.unstructured_response}
            `;
        elementResponse.push(
          <div key={str} style={{ color: "red" }}>
            {str}
          </div>,
        );
      }
    }

    return (
      <div style={{ margin: "8px 0" }} key={slug}>
        <div>{ReactHtmlParser(question.question_text)}</div>
        <div style={{ paddingLeft: "16px" }}>
          {elementResponse.length > 0 ? elementResponse : responseText}
        </div>
      </div>
    );
  });
};
