import { Button, Flex, Stack, styledStitches, Text } from "@/app/design-system";
import {
  DropdownButton,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuTrigger,
} from "@/app/design-system/dropdown/dropdown.styled";
import React from "react";
import { DownChevronIcon } from "../../../../../../design-system/icons";
import { sorts } from "./client-card-sort.util";
import { ClientCardSortRadioItem } from "./client-pane-sort-radio-item";
import { useClientPaneSortDropdown } from "./use-client-pane-sort-dropdown";

export const ClientPaneSortDropdown = () => {
  const { isDropdownOpen, setIsDropdownOpen, currentlySelectedSort } =
    useClientPaneSortDropdown();

  return (
    <DropdownMenu open={isDropdownOpen}>
      <DropdownMenuTrigger
        asChild
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        <StyledSortDropdownButton
          aria-label="client card sort dropdown button"
          css={{ display: "flex", height: 34 }}
        >
          <Text>Sort by {currentlySelectedSort.toLowerCase()}</Text>
          <DownChevronIcon className="caret-icon" />
        </StyledSortDropdownButton>
      </DropdownMenuTrigger>

      <DropdownMenuContent
        sideOffset={5}
        align={"end"}
        onInteractOutside={() => setIsDropdownOpen(false)}
      >
        <Stack gap={16}>
          <DropdownMenuRadioGroup value={currentlySelectedSort}>
            {/* radio item - only one can be selected at a time. 
            when selected, can toggle between ascending or descending */}

            {sorts.map((sort) => {
              return (
                <ClientCardSortRadioItem
                  key={sort}
                  value={sort}
                  currentlySelectedSort={currentlySelectedSort}
                  callback={() => setIsDropdownOpen(false)}
                />
              );
            })}
          </DropdownMenuRadioGroup>
        </Stack>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export const StyledSortDropdownButton = styledStitches(DropdownButton, {
  backgroundColor: "transparent",
  border: "none",
  pl: 4,
  py: 4,
  pr: 30,
  "&:focus": {
    outline: "none",
    transform: "none",
  },

  ".caret-icon": {
    position: "absolute",
    right: 4,
    top: 4,
  },
});
