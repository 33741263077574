import { Text } from "recharts";

interface Payload {
  value: number;
}

interface GraphAxisTickProps {
  x: number;
  y: number;
  payload: Payload;
  tickFormatter: (value: number) => string;
}

export const GraphAxisTick = (props: GraphAxisTickProps) => {
  const { x, y, payload, tickFormatter } = props;
  const label = tickFormatter(payload.value);
  let dy = -50;
  let dx = -30;
  let transform = "rotate(0)";

  const [labelToShow, range] = label.split("/");
  const rangeToShow = range || '';

  return (
    <g transform={`translate(${x + dx},${y + dy})`}>
      <Text
        x={0}
        y={0}
        width={100}
        textAnchor="middle"
        fill="#000"
        fontSize={14}
        transform={transform}
      >
        {labelToShow}
      </Text>
      {rangeToShow && (
        <Text
          x={0}
          y={18}
          width={100}
          fontSize={12}
          textAnchor="middle"
          fill="#666"
          transform={transform}
        >
          {rangeToShow}
        </Text>
      )}
    </g>
  );
};
