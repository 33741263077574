export const copy = {
  gad: `
<div>
  <div><b>Potential symptom list (Generalized Anxiety):</b></div>
  <div><i>Clt endorsed excessive worry, difficulty controlling worry, feeling restless/on edge, feeling easily fatigued, having difficulty concentrating/focusing, heightened irritability, muscle tension, and sleep disturbance.</i></div>
</div>
`,
  mdd: `
<div>
  <div><b>Potential symptom list (Major Depressive Disorder):</b></div>
  <div><i>Clt endorsed feeling down/depressed mood, loss of pleasure in things they once found interesting, significant weight loss/gain, sleep disturbance, psychomotor agitation or retardation, feeling worthless or excessive/inappropriate guilt, having difficulty concentrating/focusing, feeling fatigued/loss of energy, having thoughts of death or suicidal ideation. Symptoms present for at least 2 weeks.</i></div>
  </div>`,
  adj: `
<div>
  </div><b>Potential symptom list (Adjustment Disorder):</b></div>
  <i><div>Clt endorsed marked distress in excess given nature of stress, significant impairment in social and/or occupational functioning within 3 months of specific stressor in client’s life. Symptoms include low mood, feeling sad or hopeless, crying or withdrawing from others, feeling isolated, feeling worried and/or tense, difficulty concentrating, sleep disturbance, angry or disruptive behavior, loss of self-esteem, exhibiting defiant or impulsive behavior.</i></div>
</div>`,
};
