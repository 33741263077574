import { useState } from "react";
import { useMyClientsSortStore } from "../../../my-clients-model";

export const useClientPaneSortDropdown = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const { setMyClientsSort, currentlySelectedSort } = useMyClientsSortStore(
    (state) => ({
      setMyClientsSort: state.setMyClientsSort,
      currentlySelectedSort: state.currentlySelectedSort,
    }),
  );

  return {
    isDropdownOpen,
    setIsDropdownOpen,
    setMyClientsSort,
    currentlySelectedSort,
  };
};
