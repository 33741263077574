import * as React from "react";
import { AbstractSeriesProps } from "react-vis";
import styled from "styled-components";

import { $greyText } from "../../assets/colors";
import { styledStitches } from "../design-system";
import { UnstyledButton } from "../design-system/button/button";

// 3 is an educated guess because cmp will/should usually be in another component with a title, which will be in a section that probably has a title
const DEFAULT_HEADING_LEVEL = 3;

export const CommonSeriesProps: Partial<AbstractSeriesProps<any>> = {};

export const TooltipContainer = styled.div`
  padding: 8px;
  background-color: #fff;
  border-radius: 8px;
  min-width: 140px;
  box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05),
    0 15px 40px rgba(166, 173, 201, 0.2);
  margin-x: 0.65em;
  h4 {
    font-weight: 500 !important;
    white-space: nowrap;
    margin-bottom: 0.2em;
  }
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 10pt !important;
`;

export const TooltipValues = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: min-content 1fr;
  color: ${$greyText};

  h5 {
    grid-column: 1 / -1;
    margin: 2px 0 2px 0;
    text-align: center;
    font-size: 10pt;
  }

  span {
    display: inline-block;
    white-space: nowrap;

    &:nth-of-type(2n) {
      color: #3e3e3e;
      text-align: center;
    }
  }
`;

export const TooltipDot = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: ${(props) => props.color || "#fff"};
  display: inline-block;
  margin-right: 0.65em;
`;

/**
 * @name AverageValue
 * @param { string } label
 * @param { number | string } value
 * @param { JSX.Element } children
 * @param { number } headingLevel // used for orienting users who use rotors on the page. Defaults to 3 within the component, which is an educated guess because cmp will/should usually be in another component with a title, which will be in a section that probably has a title
 */
export const AverageValue = (props: {
  label: string;
  value?: number | string;
  children?: JSX.Element;
  headingLevel?: number;
}) => (
  <div style={{ textAlign: "center", width: "100%" }}>
    <h5 aria-level={props.headingLevel ?? DEFAULT_HEADING_LEVEL}>
      {props.label}
    </h5>
    {props.value !== undefined && !isNaN(Number(props.value)) && (
      <span style={{ fontSize: "16pt" }}>
        {(Number(props.value) * 100).toFixed(1)}%
      </span>
    )}
    {props.value !== undefined && isNaN(Number(props.value)) && (
      <span style={{ fontSize: "16pt" }}>{props.value}</span>
    )}
    {props.children}
  </div>
);

export const AveragesContainer = styled.div`
  width: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  font-size: 12pt;
  padding: 2.5% 0;
  margin-left: -16px;
  margin-top: -16px;

  h5 {
    margin: 1em 0 0.5em;
  }
`;

export const DashboardComponentTitle = styledStitches("h3", {
  position: "relative",
  fontWeight: 400,
  margin: "12px 0 -16px",
  variants: {
    mr: {
      "0": {
        marginRight: "0",
      },
      "4": {
        marginRight: "4px",
      },
    },
    mb: {
      "0": {
        margin: "12px 0",
      },
      "4": {
        margin: "12px 0 -16px",
      },
    },
    m: {
      "0": {
        margin: "0",
      },
    },
  },
});

export const ShowUtilizationButton = styledStitches(UnstyledButton, {
  padding: "4px 15px",
  background: "#FFFFFF",
  border: "1px solid #D9D9D9",
  boxShadow: "0px 2px 0px rgba(0, 0, 0, 0.016)",
  borderRadius: 4,
  color: "rgba(0, 0, 0, 0.85)",
  fontSize: 16,
  fontWeight: 400,
  lineHeight: "24px",
  // These are some of the antd default button styles (to minimize visual changes)
  position: "relative",
  display: "inline-block",
  whiteSpace: "nowrap",
  textAlign: "center",
  transition: "all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)",
  userSelect: "none",
  touchAction: "manipulation",
  height: "32px",
  borderColor: "#d9d9d9",
});
