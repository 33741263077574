import React from "react";
import { IfPermitted } from "../../_helpers/permissions";
import { OpenReservationTimeslot, OpenTimeslot } from "../EditTimeslot";
import { Recurrence, ReservationType } from "../types";
import { SpecialTimeSlotCellProps } from "./types";
import { ActionsContainer, CellContainer, CellLabel } from "./_common";

export type BlankTimeSlotCellProps = Omit<
  SpecialTimeSlotCellProps,
  "eventIds" | "eventMap" | "typeId"
>;

export const BlankTimeSlotCell = (props: BlankTimeSlotCellProps) => {
  const { day, hour, rowIndex, displaySide, clinicianId } = props;
  const eventType = "blank";

  return (
    <CellContainer
      displaySide={displaySide}
      eventType={eventType}
      rowIndex={rowIndex}
      style={{ gridRow: "1 / -1" }} // forces a Slot to occupy the entire cell
    >
      <CellLabel></CellLabel>
      <ActionsContainer>
        <IfPermitted
          permissions={["IsConsultTimeslotEditor"]}
          requireAll={false}
        >
          <OpenReservationTimeslot
            clinicianId={clinicianId}
            day={day}
            hour={hour}
            recurrence={
              (displaySide === "both" ? "weekly" : "biweekly") as Recurrence
            }
            even_odd_week={
              displaySide === "left"
                ? "even"
                : displaySide === "right"
                ? "odd"
                : undefined
            }
            reservation_type={"consult" as ReservationType}
          />
        </IfPermitted>
        <IfPermitted
          permissions={["IsATSlotEditor", "IsClinicalLeader"]}
          requireAll={false}
        >
          <OpenTimeslot
            clinicianId={clinicianId}
            day={day}
            hour={hour}
            recurrence={
              (displaySide === "both" ? "weekly" : "biweekly") as Recurrence
            }
            even_odd_week={
              displaySide === "left"
                ? "even"
                : displaySide === "right"
                ? "odd"
                : undefined
            }
          />
        </IfPermitted>
      </ActionsContainer>
    </CellContainer>
  );
};
