import { UserHasAnyPermissions } from "@/app/_helpers/permissions";
import React from "react";
import { useDispatch } from "react-redux";
import * as dashboardOperations from "../../../state/models/dashboard/operations";
import { useShallowEqualSelector } from "../../_helpers/redux";
import { Dashboard2MainContentHeader } from "../../dashboard/dashboard";
import { TimeZoneAndClinicianPicker } from "../../dashboard2.0/_components/timezone-and-clinician-picker";
import { Flex, Text, styledStitches, Notification } from "../../design-system";
import useTitle from "../../hooks/useTitle";
import { useMyClientsSearchStore } from "../../my-clients/routes/my-clients-page/my-clients-model/my-clients.model";
import { MyConsultsV2NavigationTabs } from "./matching-v2-navigation-tabs";
import { Clinician, CurrentUser } from "@/api/types";
import { useLocation, useNavigate } from "react-router-dom";

interface HeaderProps {
  clinicianMap: {
    [id: number]: Clinician;
  };
  handleSelectClinician: (clinicianid: number) => void;
  clinicianId: string | number | undefined;
  myTeamClinicianIds: number[];
  clinicianManagers: Clinician[];
  currentUser: CurrentUser | null;
  loggedInUserClinicianId?: number;
}

interface SubHeaderProps {
  clinicianId: string | number | undefined;
  loggedInUserClinicianId?: number;
}

const Header = ({
  clinicianMap,
  handleSelectClinician,
  clinicianId,
  myTeamClinicianIds,
  clinicianManagers,
  currentUser,
  loggedInUserClinicianId,
}: HeaderProps) => {
  return (
    <Dashboard2MainContentHeader className="ASDFASDF">
      <Text as="section" fontWeight={700} fontSize={20}>
        My Consults
      </Text>
      <TimeZoneAndClinicianPicker
        clinicianMap={clinicianMap}
        handleSelectClinician={handleSelectClinician}
        clinicianId={clinicianId}
        myTeamClinicianIds={myTeamClinicianIds}
        permissions={["IsSuperUser", "IsClinicalLeader", "IsCareCoordinator"]}
        clinicianManagers={clinicianManagers}
        currentUser={currentUser}
        loggedInUserClinicianId={loggedInUserClinicianId}
      />
    </Dashboard2MainContentHeader>
  );
};

const MainContentHeader = styledStitches(Flex, {
  backgroundColor: "$neutral0",
  color: "black",
  borderBottom: "1px solid",
  borderColor: "$neutral5",
  justifyContent: "space-between",
  alignItems: "start",
  minHeight: 65,
  flexWrap: "wrap",
});

const Subheader = ({
  clinicianId,
  loggedInUserClinicianId,
}: SubHeaderProps) => {
  return (
    <MainContentHeader style={{ justifyContent: "center" }}>
      <MyConsultsV2NavigationTabs
        clinicianId={
          clinicianId ? Number(clinicianId) : loggedInUserClinicianId
        }
      />
    </MainContentHeader>
  );
};

export const MyConsultsAndMatchesV2 = () => {
  useTitle("My Consults");
  const {
    clinicianId,
    clinicianMap,
    currentUser,
    clinicianManagers,
    myTeamClinicianIds,
  } = useShallowEqualSelector((state) => ({
    clinicianId: state.dashboard.clinicianId,
    currentUser: state.auth.currentUser,
    clinicianMap: state.clinicians.clinicianMap,
    clinicianManagers: state.dashboard.clinicianManagers,
    myTeamClinicianIds: state.auth.myTeamClinicianIds,
  }));
  const navigate = useNavigate();

  const location = useLocation();
  const [derivedClinicianId, setDerivedClinicianId] =
    React.useState(clinicianId);

  const { setCurrentlySelectedClinician } = useMyClientsSearchStore();
  const dispatch = useDispatch();
  const changeMDBClinician = (id: number | string) =>
    dispatch(dashboardOperations.loadMDBWithClinicianId(id));
  const handleSelectClinician = (clinicianid: number) => {
    changeMDBClinician(clinicianid);
    setCurrentlySelectedClinician(clinicianid);
    navigate(`?clinicianId=${clinicianid?.toString()}`);
  };
  const loggedInUserClinicianId = currentUser?.clinician?.id;

  const canSelectClinician = UserHasAnyPermissions(currentUser, [
    "IsSuperUser",
    "IsCareCoordinator",
    "IsClinicalLeader",
  ]);
  React.useEffect(() => {
    if (canSelectClinician) {
      const searchParams = new URLSearchParams(window.location.search);
      const queryParamCID = Number(searchParams.get("clinicianId"));
      setDerivedClinicianId(queryParamCID);
    }
  }, [location]);

  return (
    <div style={{ paddingBottom: "30px", overflowX: "hidden" }}>
      <Header
        clinicianMap={clinicianMap}
        handleSelectClinician={handleSelectClinician}
        clinicianId={derivedClinicianId}
        myTeamClinicianIds={myTeamClinicianIds}
        clinicianManagers={clinicianManagers}
        currentUser={currentUser}
        loggedInUserClinicianId={loggedInUserClinicianId}
      />
      <Subheader
        clinicianId={derivedClinicianId}
        loggedInUserClinicianId={loggedInUserClinicianId}
      />
      <Notification />
    </div>
  );
};
