import * as React from "react";
import { Drawer } from "antd";

import { useDispatchPromise } from "../../_helpers/redux";
import { Resource } from "../types";
import { fetchResourceById, toggleResourceIsFavorite } from "../utils";

import { EditResourceForm } from "./EditResourceForm";

import { useUserHasAnyPermissions } from "../../_helpers/permissions";
import { ResourceDetails } from "./ResourceDetails";
import { ResourceDrawerActions } from "./ResourceDrawerActions";

interface ResourceDrawerProps {
  resourceId: string | null;
  onUpdateResource: (nextResource: Resource) => void;
  onClose: () => void;
  handleDeleteResource: () => void;
  setParams: (key: string, value: string) => void;
}

export const ResourceDrawer = ({
  resourceId,
  onUpdateResource,
  onClose,
  handleDeleteResource,
  setParams,
}: ResourceDrawerProps) => {
  const [resource, setResource] = React.useState<Resource>({
    id: "",
    title: "",
    description: "",
    url: "",
    resource_type: "link",
    categories: [],
    tags: [],
    is_favorite: false,
    is_personal: false,
    is_published: true,
  });
  const [isEditMode, setIsEditMode] = React.useState<boolean>(!resourceId);
  const [error, setError] = React.useState<string>("");
  const [isDetailsView, setIsDetailsView] = React.useState(true);
  const dispatch = useDispatchPromise();

  const handleSetDetailsView = (isDetailsView: boolean) => {
    setIsDetailsView(isDetailsView);
  };

  // fetch resource item by id
  React.useEffect(() => {
    const init = async (resourceId: string) => {
      const res = await dispatch(fetchResourceById(resourceId));
      setResource(res.data);
    };
    if (resourceId) {
      init(resourceId);
    }
  }, [dispatch, resourceId]);

  const handleFavoriteIconClick = async () => {
    try {
      if (resource) {
        const nextResource = await dispatch(toggleResourceIsFavorite(resource));
        setResource(nextResource);
        onUpdateResource(nextResource);
      }
    } catch (e) {
      setError((e as Error).message);
    }
  };

  const onSetIsEditMode = (): void => {
    setIsEditMode(!isEditMode);
  };

  const isCurator = useUserHasAnyPermissions(["IsResourceLibCurator"]);
  const isClinician = useUserHasAnyPermissions(["IsClinician"]);
  const canEditResource = isCurator || (isClinician && resource.is_personal);

  const showResourceDetails = !isEditMode && resource;

  const resourceDrawerTitle = isEditMode
    ? resourceId
      ? "Edit resource"
      : "Create resource"
    : isDetailsView
    ? "Resource Details"
    : "Back to resource";

  return (
    <Drawer
      title={
        <ResourceDrawerActions
          title={resourceDrawerTitle}
          handleFavoriteIconClick={handleFavoriteIconClick}
          resource={resource}
          isCurator={isCurator}
          isClinician={isClinician}
          canEditResource={canEditResource}
          onSetIsEditMode={onSetIsEditMode}
          isDetailsView={isDetailsView}
          handleSetDetailsView={handleSetDetailsView}
          isEditMode={isEditMode}
          handleDeleteResource={handleDeleteResource}
        />
      }
      placement="right"
      closable
      maskClosable
      visible
      width={600}
      onClose={onClose}
      bodyStyle={{ padding: "0px" }}
    >
      {error && <div style={{ padding: 10 }}>{error}</div>}

      {isEditMode && resource && (
        <EditResourceForm
          setParams={setParams}
          initialResource={resource}
          onUpdateResource={next => {
            setResource(next);
            onUpdateResource(next);
            setIsEditMode(false);
          }}
          onCancel={() => {
            if (resource.id) {
              setIsEditMode(false);
            } else {
              onClose();
            }
          }}
        />
      )}

      {showResourceDetails && (
        <ResourceDetails
          resource={resource}
          isDetailsView={isDetailsView}
          handleSetDetailsView={handleSetDetailsView}
          isClinician={isClinician}
        />
      )}
    </Drawer>
  );
};
