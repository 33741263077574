import { CurrentUserContext } from "@/app/app.utils";
import { TIMEZONE } from "@/app/my-clients/utils";
import React from "react";
import { Flex, Text } from "../../../../../../../../design-system";

import { formattedSessionAppointmentDateTime } from "../../schedule-page.utils";

interface SessionTimeProps {
  appointmentDatetime: string;
  color: "$neutral12" | "$neutral11";
}

export const SessionTime = ({
  appointmentDatetime,
  color,
}: SessionTimeProps) => {
  const cuser = React.useContext(CurrentUserContext);
  const { date, day, time, year } =
    formattedSessionAppointmentDateTime(appointmentDatetime, TIMEZONE(cuser));

  return (
    <Flex gap={4}>
      <Text fontWeight={700} color={color}>
        {date}, {year}
      </Text>
      <Text color={color}>- {day}</Text>
      <Text color={color}>at</Text>
      <Text fontWeight={700} color={color}>
        {time}
      </Text>
    </Flex>
  );
};
