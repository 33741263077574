import { DropdownMenuTriggerItem } from "../../../../../../../../design-system/dropdown/dropdown.styled";
import React from "react";

import { Flex, Text } from "../../../../../../../../design-system";
import { RightChevronIcon } from "../../../../../../../../design-system/icons/components/right-chevron-icon";

interface DropdownSubMenuTriggerProps {
  numberOfSelectedFilters: number;
  children: React.ReactNode;
}

export const DropdownSubMenuTriggerItem = ({
  numberOfSelectedFilters,
  children,
}: DropdownSubMenuTriggerProps) => {
  return (
    <DropdownMenuTriggerItem
      css={{
        position: "relative",
        "&:focus": {
          ".chevron-right": {
            display: "inline",
            transition: "transform ease-in-out",
            transform: "translateX(2px)",
          },
        },

        ".number-of-selected-filters-indicator": {
          position: "absolute",
          left: 145,
        },
      }}
    >
      <Text fontSize={14}>{children}</Text>

      <>
        {numberOfSelectedFilters > 0 ? (
          <Flex
            css={{
              br: "50%",
              width: 20,
              height: 20,
              backgroundColor: "$neutral5",
              justifyContent: "center",
            }}
            className="number-of-selected-filters-indicator"
          >
            <Text fontSize={"12"}>{numberOfSelectedFilters}</Text>
          </Flex>
        ) : null}

        <RightChevronIcon className="chevron-right" height={18} width={18} />
      </>
    </DropdownMenuTriggerItem>
  );
};
