import { Select as DeprecatedAntdSelect } from "antd";
import { omit, pick } from "lodash-es";
import * as React from "react";
import { Clinician } from "../../api/types";
import { alphabetizeBy } from "../_helpers";

/**
 * @name DeprecatedClinicianPicker
 * @description We do not want to use this. This has been deprecated in favor of ClinicianPickerV2 as of 2022
 * @todo Ticket to remove [here](https://twochairs.atlassian.net/browse/TC-7276)
 */
function DeprecatedClinicianPicker<IdType extends string | number>(props: {
  clinicianMap: { [id: number]: Clinician };
  setClinicianFn: (id: IdType) => void;
  disabled?: boolean;
  value?: number | string | null;
  additionalOptions?: { [key: string]: string };
  sortFirstValues?: number[];
  style?: React.CSSProperties;
}) {
  const { clinicianMap, setClinicianFn, additionalOptions, sortFirstValues } =
    props;

  const onChange = (id: IdType) => setClinicianFn(id);
  return (
    // @ts-ignore
    <DeprecatedAntdSelect<IdType>
      {...{
        // This allows the clinician picker to maintain its state when navigating to and from a tab
        ...(props.value === undefined
          ? {}
          : {
              value: props.value || undefined,
            }),
        disabled: props.disabled,
        style: { width: 200, ...props.style },
        showSearch: true,
        optionFilterProp: "title",
        placeholder: "Select a Clinician",
        "aria-label": "Select a Clinician",
        onChange,
      }}
    >
      {additionalOptions &&
        Object.entries(additionalOptions).map(([key, value]) => (
          <DeprecatedAntdSelect.Option key={key} value={key} title={value}>
            {value as string}
          </DeprecatedAntdSelect.Option>
        ))}
      {makePickerOptions(clinicianMap, sortFirstValues)}
    </DeprecatedAntdSelect>
  );
}

function makePickerOptions(
  clinicianMap: { [id: number]: Clinician },
  sortedFirst?: number[],
): JSX.Element[] {
  const clinicianSortFn = (a: Clinician, b: Clinician) =>
    alphabetizeBy(a, b, "first_name");

  if (sortedFirst) {
    const firstClinicians = Object.values(pick(clinicianMap, sortedFirst)).sort(
      clinicianSortFn,
    );
    const remainingClinicians = Object.values<Clinician>(
      omit(clinicianMap, sortedFirst),
    ).sort(clinicianSortFn);
    return [
      <DeprecatedAntdSelect.OptGroup label="My Team" key={1}>
        {firstClinicians.map((c) => clinicianOption(c))}
      </DeprecatedAntdSelect.OptGroup>,
      <DeprecatedAntdSelect.OptGroup label="All Clinicians" key={2}>
        {remainingClinicians.map((c) => clinicianOption(c))}
      </DeprecatedAntdSelect.OptGroup>,
    ];
  } else {
    return Object.values(clinicianMap)
      .sort((a, b) => alphabetizeBy(a, b, "first_name"))
      .map((c) => clinicianOption(c));
  }
}

function clinicianOption(clinician: Clinician) {
  return (
    <DeprecatedAntdSelect.Option
      key={clinician.id}
      title={`${clinician.first_name} ${clinician.last_name}`}
      value={clinician.id}
    >
      {`${clinician.first_name} ${clinician.last_name}`}
    </DeprecatedAntdSelect.Option>
  );
}

export default DeprecatedClinicianPicker;
