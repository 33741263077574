import { capitalize } from "lodash-es";
import moment from "moment";
import { useEffect, useState } from "react";

import { AQMTuningParamsBase, IdMap } from "../../../../../../../api/types";
import { useShallowEqualSelector } from "../../../../../../_helpers/redux";

export interface UseAQMScoreTableParams {
  matchId: number;
}

export const parseScore = (score: number) => {
  return Number(score.toFixed(1));
};

export function useAQMScoreTableData({
  matchId,
  clinicianBestSlots,
}: {
  matchId: number;
  clinicianBestSlots: IdMap<string>;
}) {
  const [aqmScores, clinicianMap] = useShallowEqualSelector((state) => [
    state.matches.aqmScores[matchId],
    state.clinicians.clinicianMap,
  ]);

  const [mergedBreakdownComponents, setMergedBreakdownComponents] = useState<
    Partial<AQMTuningParamsBase<number>>
  >({});

  const [tableData, setTableData] = useState<any>(undefined);

  useEffect(() => {
    if (aqmScores) {
      const data = Object.entries(aqmScores).map(([slotId, breakdown]) => {
        const [clinicianId, day, time] = slotId.split("_");
        const clinician = clinicianMap[clinicianId];

        setMergedBreakdownComponents({
          ...mergedBreakdownComponents,
          ...breakdown.components,
        });

        return {
          key: slotId,
          clinician: `${clinician.first_name} ${clinician.last_name}`,
          bestSlot: clinicianBestSlots[clinicianId] === slotId ? "Yes" : "No",
          timeSlot: `${day}-${moment(time, "HH:mm:ss").format("ha")}`,
          score: parseScore(breakdown.score),
          modality: breakdown.modality ? capitalize(breakdown.modality) : "",
          work_from_home: breakdown.work_from_home === true ? "Yes" : "No",
          ...breakdown.components,
        };
      });

      setTableData(data);
    }
  }, [aqmScores]);

  return { tableData, mergedBreakdownComponents };
}
