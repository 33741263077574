const prelim_dx_code_options = [
  {
    key: "OPTION__none",
    value: "none",
    title: "None",
  },
  {
    key: "OPTION__other_(please_look_up_correct_code_and_enter_in_question_19)",
    value: "other_(please_look_up_correct_code_and_enter_in_question_19)",
    title: "Other (please look up correct code and enter in question 16)",
  },
  {
    key: "OPTION__acute_stress_reaction___f43_0",
    value: "acute_stress_reaction___f43_0",
    title: "Acute stress reaction - F43.0",
  },
  {
    key: "OPTION__adjustment_disorder_with_anxiety___f43_22",
    value: "adjustment_disorder_with_anxiety___f43_22",
    title: "Adjustment disorder with anxiety - F43.22",
  },
  {
    key: "OPTION__adjustment_disorder_with_depressed_mood___f43_21",
    value: "adjustment_disorder_with_depressed_mood___f43_21",
    title: "Adjustment disorder with depressed mood - F43.21",
  },
  {
    key: "OPTION__adjustment_disorder_with_mixed_anxiety_and_depressed_mood___f43_23",
    value: "adjustment_disorder_with_mixed_anxiety_and_depressed_mood___f43_23",
    title: "Adjustment disorder with mixed anxiety and depressed mood - F43.23",
  },
  {
    key: "OPTION__adjustment_disorder_with_mixed_disturbance_of_emotions_and_conduct___f43_25",
    value:
      "adjustment_disorder_with_mixed_disturbance_of_emotions_and_conduct___f43_25",
    title:
      "Adjustment disorder with mixed disturbance of emotions and conduct - F43.25",
  },
  {
    key: "OPTION__adjustment_disorder,_unspecified___f43_20",
    value: "adjustment_disorder,_unspecified___f43_20",
    title: "Adjustment disorder, unspecified - F43.20",
  },
  {
    key: "OPTION__alcohol_abuse___f10_1",
    value: "alcohol_abuse___f10_1",
    title: "Alcohol abuse - F10.1",
  },
  {
    key: "OPTION__alcohol_dependence___f10_2",
    value: "alcohol_dependence___f10_2",
    title: "Alcohol dependence - F10.2",
  },
  {
    key: "OPTION__alcohol_use,_unspecified___f10_9",
    value: "alcohol_use,_unspecified___f10_9",
    title: "Alcohol use, unspecified - F10.9",
  },
  {
    key: "OPTION__anxiety_disorder,_unspecified___f41_9",
    value: "anxiety_disorder,_unspecified___f41_9",
    title: "Anxiety disorder, unspecified - F41.9",
  },
  {
    key: "OPTION__attention_deficit_hyperactivity_disorder,_unspecified_type___f90_0",
    value: "attention_deficit_hyperactivity_disorder,_unspecified_type___f90_0",
    title: "Attention-deficit hyperactivity disorder, unspecified type - F90.0",
  },
  {
    key: "OPTION__bipolar_disorder,_current_episode_hypomanic___f31_0",
    value: "bipolar_disorder,_current_episode_hypomanic___f31_0",
    title: "Bipolar disorder, current episode hypomanic - F31.0",
  },
  {
    key: "OPTION__bipolar_disorder,_current_episode_manic_without_psychotic_features___f31_1",
    value:
      "bipolar_disorder,_current_episode_manic_without_psychotic_features___f31_1",
    title:
      "Bipolar disorder, current episode manic without psychotic features - F31.1",
  },
  {
    key: "OPTION__bipolar_disorder,_currently_in_remission___f31_7",
    value: "bipolar_disorder,_currently_in_remission___f31_7",
    title: "Bipolar disorder, currently in remission - F31.7",
  },
  {
    key: "OPTION__bipolar_ii_disorder__ f31_81",
    value: "bipolar_ii_disorder__ f31_81",
    title: "Bipolar II disorder - F31.81",
  },
  {
    key: "OPTION__cannabis_dependence,_uncomplicated___f12_20",
    value: "cannabis_dependence,_uncomplicated___f12_20",
    title: "Cannabis dependence, uncomplicated - F12.20",
  },
  {
    key: "OPTION__disruption_of_family_due_to_death___z63_4",
    value: "disruption_of_family_due_to_death___z63_4",
    title: "Disruption of family due to death - Z63.4",
  },
  {
    key: "OPTION__dysthymic_disorder___f34_1",
    value: "dysthymic_disorder___f34_1",
    title: "Dysthymic disorder - F34.1",
  },
  {
    key: "OPTION__eating_disorder,_unspecified___f50_9",
    value: "eating_disorder,_unspecified___f50_9",
    title: "Eating disorder, unspecified - F50.9",
  },
  {
    key: "OPTION__generalized_anxiety_disorder___f41_1",
    value: "generalized_anxiety_disorder___f41_1",
    title: "Generalized anxiety disorder - F41.1",
  },
  {
    key: "OPTION__insomnia,_unspecified___g47_00",
    value: "insomnia,_unspecified___g47_00",
    title: "Insomnia, unspecified - G47.00",
  },
  {
    key: "OPTION__major_depressive_disorder,_recurrent_severe_without_psychotic_features___f33_2",
    value:
      "major_depressive_disorder,_recurrent_severe_without_psychotic_features___f33_2",
    title:
      "Major depressive disorder, recurrent severe without psychotic features - F33.2",
  },
  {
    key: "OPTION__major_depressive_disorder,_recurrent,_mild___f33_0",
    value: "major_depressive_disorder,_recurrent,_mild___f33_0",
    title: "Major depressive disorder, recurrent, mild - F33.0",
  },
  {
    key: "OPTION__major_depressive_disorder,_recurrent,_moderate___f33_1",
    value: "major_depressive_disorder,_recurrent,_moderate___f33_1",
    title: "Major depressive disorder, recurrent, moderate - F33.1",
  },
  {
    key: "OPTION__major_depressive_disorder,_single_episode,_moderate___f32_1",
    value: "major_depressive_disorder,_single_episode,_moderate___f32_1",
    title: "Major depressive disorder, single episode, moderate - F32.1",
  },
  {
    key: "OPTION__major_depressive_disorder,_single_episode,_severe_w/o_psych_features___f32_2",
    value:
      "major_depressive_disorder,_single_episode,_severe_w/o_psych_features___f32_2",
    title:
      "Major depressive disorder, single episode, severe w/o psych features - F32.2",
  },
  {
    key: "OPTION__major_depressive_disorder,_single_episode,_unspecified___f32_9",
    value: "major_depressive_disorder,_single_episode,_unspecified___f32_9",
    title: "Major depressive disorder, single episode, unspecified - F32.9",
  },
  {
    key: "OPTION__obsessive_compulsive_disorder___f42",
    value: "obsessive_compulsive_disorder___f42",
    title: "Obsessive-compulsive disorder - F42",
  },
  {
    key: "OPTION__other_specified_problems_related_to_psychosocial_circumstances___z65_8",
    value:
      "other_specified_problems_related_to_psychosocial_circumstances___z65_8",
    title:
      "Other specified problems related to psychosocial circumstances - Z65.8",
  },
  {
    key: "OPTION__panic_disorder_with_agoraphobia___f40_01",
    value: "panic_disorder_with_agoraphobia___f40_01",
    title: "Panic disorder with agoraphobia - F40.01",
  },
  {
    key: "OPTION__panic_disorder_without_agoraphobia___f41_0",
    value: "panic_disorder_without_agoraphobia___f41_0",
    title: "Panic disorder without agoraphobia - F41.0",
  },
  {
    key: "OPTION__post_traumatic_stress_disorder,_unspecified___f43_10",
    value: "post_traumatic_stress_disorder,_unspecified___f43_10",
    title: "Post-traumatic stress disorder, unspecified - F43.10",
  },
  {
    key: "OPTION__problems_in_relationship_with_spouse_or_partner___z63_0",
    value: "problems_in_relationship_with_spouse_or_partner___z63_0",
    title: "Problems in relationship with spouse or partner - Z63.0",
  },
  {
    key: "OPTION__problems_of_adjustment_to_life_cycle_transitions___z60_0",
    value: "problems_of_adjustment_to_life_cycle_transitions___z60_0",
    title: "Problems of adjustment to life-cycle transitions - Z60.0",
  },
  {
    key: "OPTION__schizoaffective_depressive_disorder___f25_9",
    value: "schizoaffective_depressive_disorder___f25_9",
    title: "Schizoaffective depressive disorder - F25.9",
  },
  {
    key: "OPTION__sleep_disorder,_unspecified___g47_9",
    value: "sleep_disorder,_unspecified___g47_9",
    title: "Sleep disorder, unspecified - G47.9",
  },
  {
    key: "OPTION__social_phobia,_unspecified___f40_10",
    value: "social_phobia,_unspecified___f40_10",
    title: "Social phobia, unspecified - F40.10",
  },
];

const client_cadence_options = [
  {
    key: "OPTION__weekly_only",
    value: "weekly_only",
    title: "Weekly only",
  },
  {
    key: "OPTION__prefers_weekly_but_could_start_biweekly_if_absolutely_needed",
    value: "prefers_weekly_but_could_start_biweekly_if_absolutely_needed",
    title: "Prefers weekly but could start biweekly if absolutely needed",
  },
  {
    key: "OPTION__prefers_biweekly_but_could_start_weekly_if_absolutely_needed",
    value: "prefers_biweekly_but_could_start_weekly_if_absolutely_needed",
    title: "Prefers biweekly but could start weekly if absolutely needed",
  },
  {
    key: "OPTION__biweekly_only",
    value: "biweekly_only",
    title: "Biweekly only",
  },
];

const consult_training_options = [
  {
    key: "OPTION__none",
    value: "none",
    title: "None",
  },
  {
    key: "OPTION__supervised_consult",
    value: "supervised_consult",
    title: "Supervised consult - in training",
  },
  {
    key: "OPTION__client_profile_incomplete",
    value: "client_profile_incomplete",
    title: "Client Profile incomplete - in pilot",
  },
];

const consult_modality_options = [
  {
    key: "OPTION__teletherapy",
    value: "teletherapy",
    title: "Teletherapy (secure video session)",
  },
  {
    key: "OPTION__in_person",
    value: "in_person",
    title: "In-person",
  },
];

const group_discussed_options = [
  {
    key: "OPTION__yes",
    value: "Yes",
    title: "Yes",
  },
  {
    key: "OPTION__no",
    value: "No",
    title: "No",
  },
];

const group_discussed_no_reason_options = [
  {
    key: "OPTION__not_appropriate",
    value: "Not appropriate for group therapy",
    title: "Not appropriate for group therapy",
  },
  {
    key: "OPTION__concern_not_a_fit",
    value: "Presenting concern isn't a fit for Two Chairs group",
    title: "Presenting concern isn't a fit for Two Chairs group",
  },
  {
    key: "OPTION__seeking_different_care",
    value: "Seeking a different level of care",
    title: "Seeking a different level of care",
  },
  {
    key: "OPTION__other",
    value: "Other",
    title: "Other (Please describe)",
  },
];

const group_clinically_appropriate_options = [
  {
    key: "OPTION__yes",
    value: "Yes",
    title: "Yes",
  },
  {
    key: "OPTION__no",
    value: "No",
    title: "No",
  },
];

const group_two_chairs_appropriate_options = [
  {
    key: "OPTION__yes",
    value: "Yes",
    title: "Yes",
  },
  {
    key: "OPTION__no",
    value: "No",
    title: "No",
  },
];

const group_two_chairs_appropriate_no_reason_options = [
  {
    key: "OPTION__focus_not_covered",
    value: "Wants to focus on something not covered in the curriculum",
    title: "Wants to focus on something not covered in the curriculum",
  },
  {
    key: "OPTION__scheduling_conflict",
    value: "Scheduling conflict",
    title: "Scheduling conflict",
  },
  {
    key: "OPTION__other",
    value: "Other",
    title: "Other (Please describe)",
  },
];

const group_two_chairs_appropriate_yes_intention_options = [
  {
    key: "OPTION__yes",
    value: "Yes",
    title: "Yes",
  },
  {
    key: "OPTION__no",
    value: "No",
    title: "No",
  },
];

const group_two_chairs_appropriate_yes_intention_no_reason_options = [
  {
    key: "OPTION__individual_therapy_better_fit",
    value: "Thinks individual therapy is a better fit",
    title: "Thinks individual therapy is a better fit",
  },
  {
    key: "OPTION__other",
    value: "Other",
    title: "Other (Please describe)",
  },
];

const referred_out_text_options = [
  {
    key: "OPTION__yes",
    value: "Yes",
    title: "Yes",
  },
  {
    key: "OPTION__no",
    value: "No",
    title: "No",
  },
];

const referred_out_required_care_options = [
  {
    key: "OPTION__primary_care_provider",
    value: "Primary Care Provider",
    title: "Primary Care Provider",
  },
  {
    key: "OPTION__in_person_therapy_near_residence",
    value: "In-person therapy near client's residence",
    title: "In-person therapy near client's residence",
  },
  {
    key: "OPTION__peer_support_program",
    value: "Peer Support Program/Group",
    title: "Peer Support Program/Group",
  },
  {
    key: "OPTION__psychiatry",
    value: "Psychiatry",
    title: "Psychiatry",
  },
  {
    key: "OPTION_iop",
    value: "IOP (Intensive Outpatient Program)",
    title: "IOP (Intensive Outpatient Program)",
  },
  {
    key: "OPTION_php",
    value: "PHP (Partial Hospitalization Program)",
    title: "PHP (Partial Hospitalization Program)",
  },
  {
    key: "OPTION_residential",
    value: "Residential (or Inpatient) Treatment Program",
    title: "Residential (or Inpatient) Treatment Program",
  },
  {
    key: "OPTION__other",
    value: "Other (short response)",
    title: "Other (short response)",
  },
];

const referred_out_continuing_care_options = [
  {
    key: "OPTION__yes",
    value: "Yes, the client will continue therapy treatment at Two Chairs ",
    title: "Yes, the client will continue therapy treatment at Two Chairs ",
  },
  {
    key: "OPTION__no_continuing",
    value:
      "No, however I will continue delivering care to this client until they have a new care provider",
    title:
      "No, however I will continue delivering care to this client until they have a new care provider",
  },
  {
    key: "OPTION__no_terminated",
    value: "No, this client's care will be terminated immediately",
    title: "No, this client's care will be terminated immediately",
  },
  {
    key: "OPTION__no_not_started",
    value:
      "No, this client has not started treatment and will not be matched with a Two Chairs clinician",
    title:
      "No, this client has not started treatment and will not be matched with a Two Chairs clinician",
  },
  {
    key: "OPTION__other",
    value: "Other (short response)",
    title: "Other (short response)",
  },
];

const referred_out_urgent_options = [
  {
    key: "OPTION__not_urgent",
    value: "Not Urgent",
    title: "Not Urgent",
  },
  {
    key: "OPTION__urgent",
    value: "Urgent",
    title: "Urgent",
  },
];

const referred_out_client_comms_options = [
  {
    key: "OPTION__yes",
    value: "Yes",
    title: "Yes",
  },
  {
    key: "OPTION_no",
    value:
      "No, however I understand that the client will be contacted by the Care Navigator before I have a chance to communicate the referral to them",
    title:
      "No, however I understand that the client will be contacted by the Care Navigator before I have a chance to communicate the referral to them",
  },
];

export default {
  prelim_dx_code_options,
  client_cadence_options,
  consult_training_options,
  consult_modality_options,
  group_discussed_options,
  group_discussed_no_reason_options,
  group_clinically_appropriate_options,
  group_two_chairs_appropriate_options,
  group_two_chairs_appropriate_no_reason_options,
  group_two_chairs_appropriate_yes_intention_options,
  group_two_chairs_appropriate_yes_intention_no_reason_options,
  referred_out_text_options,
  referred_out_required_care_options,
  referred_out_continuing_care_options,
  referred_out_urgent_options,
  referred_out_client_comms_options,
};
