import * as clinicianOperations from "./operations";
import reducer from "./reducer";
import { ClinicianState } from "./reducer";
import { createSelector } from "reselect";
import { ClinicianHold } from "../../../api/types";

export * from "./reducer";

const clinicianIdSelector = (state: ClinicianState) => state.ids;
const clinicianMapSelector = (state: ClinicianState) => state.clinicianMap;

export const clinicianListSelector = createSelector(
  clinicianIdSelector,
  clinicianMapSelector,
  (ids, map) => ids.map(id => map[id]),
);

export enum HoldStatus {
  None = "NONE",
  Pending = "PENDING",
  Current = "CURRENT",
}

const activeHoldSelector = (state: ClinicianState) => state.activeHolds;

export interface HoldStatusMap {
  [clinicianId: number]: ClinicianHold;
}

export const holdStatusSelector = createSelector(
  activeHoldSelector,
  (holds = []) => {
    const holdStatus: HoldStatusMap = {};
    for (const hold of holds) {
      holdStatus[hold.clinician] = hold;
    }
    return holdStatus;
  },
);

export { clinicianOperations };
export default reducer;
