import { Label, Stack } from "@/app/design-system";
import {
  DeprecatedComposedReachUISelect,
  Option,
} from "@/app/design-system/select-reachui/composed-select-reachui";
import React from "react";
import { useController, useFormContext } from "react-hook-form";

interface CancellationFormItemProps {
  name: string;
  options: Option[];
  id?: string;
}

export const CancellationTypeFormItem = ({
  name,
  options,
  id,
}: CancellationFormItemProps) => {
  const { control } = useFormContext();

  // register input
  const {
    field: { value, onChange, onBlur, ref },
    fieldState: { invalid, isDirty },
    formState: { errors },
  } = useController({
    name,
    control,
  });

  return (
    <Stack gap={10}>
      <Label required>Attendance status</Label>
      <DeprecatedComposedReachUISelect
        options={options}
        value={value}
        onChange={(value) => {
          onChange(value);
        }}
        id={id}
        selectRootName={"Attendance status"}
      />
    </Stack>
  );
};
