import { forwardRef } from "react";
import { CSS_COLORS } from "..";
import { IconProps } from "../icons/icon.interface";

export const TwoChairsHeaderImageSmall = forwardRef<SVGSVGElement, IconProps>(
  ({ children, title, ...props }, forwardedRef) => {
    const { fill = CSS_COLORS.neutral12, width = 32, height = 32 } = props;

    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={forwardedRef}
        aria-hidden={!title}
      >
        <path d="M0 32H31.9648V0.00326157H0V32Z" fill="white" />
        <title>{title}</title>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.4355 11.3878H9.6284V19.267C9.6284 21.1443 10.624 21.9692 12.0177 21.9692C12.9047 21.9692 13.3539 21.7503 13.6806 21.5911C13.8673 21.5002 14.014 21.4287 14.1795 21.4287C14.3786 21.4287 14.5209 21.5141 14.5209 21.7701C14.5209 23.0785 12.16 24.6145 9.51463 24.6145C7.12529 24.6145 5.30484 23.5336 5.30484 20.7461C5.30484 19.5442 5.34803 17.7594 5.39095 15.9853C5.43336 14.2325 5.47551 12.4901 5.47551 11.3309H3.91106C3.51284 11.3309 3.19995 11.1318 3.19995 10.7336C3.19995 10.3723 3.54137 10.1532 4.14754 9.76422C4.59116 9.47956 5.17657 9.10392 5.87373 8.51494C6.30881 8.14892 6.67515 7.82817 6.99024 7.55229C7.97295 6.69186 8.45726 6.26782 8.97418 6.26782C9.42929 6.26782 9.6284 6.55227 9.6284 7.09271V9.88027C10.4948 9.88027 14.5563 9.81195 17.9208 9.75535C19.5662 9.72767 21.045 9.70279 21.9019 9.69008C22.4929 9.57068 23.1132 9.51059 23.7558 9.51059C25.6332 9.51059 28.5061 10.1364 28.5061 12.6679C28.5061 14.2324 27.1692 15.0288 25.4056 15.0288C25.3203 13.2084 24.8367 11.075 22.9309 11.075C20.9114 11.075 19.8589 13.2937 19.8589 16.3657C19.8589 19.9782 21.5087 21.6279 24.0118 21.6279C25.8419 21.6279 26.6708 20.9397 27.2291 20.4762C27.5125 20.2409 27.7262 20.0635 27.9656 20.0635C28.2216 20.0635 28.4207 20.2342 28.4207 20.5186C28.4207 22.3106 25.9745 24.5862 22.4758 24.5862C18.5505 24.5862 15.9052 22.0831 15.9052 17.9586C15.9052 15.0057 16.8645 12.8026 18.4355 11.3878Z"
          fill={fill}
        />
      </svg>
    );
  },
);
