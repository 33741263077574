import * as Redux from "redux";
import thunkMiddleware from "redux-thunk";
import { AppState, rootReducer } from "./models";
import * as auth from "./models/auth";

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || Redux.compose;

export const configureStore = (initialStateOverride: Redux.DeepPartial<AppState> = {}): Redux.Store<AppState> => {
  let initialState = {};
  if (localStorage.getItem("isLoggedIn")) {
    initialState = {
      auth: {
        ...auth.getInitialState(),
        isAuthenticated: true,
        ...initialStateOverride,
      },
    };
  }

  return Redux.createStore(
    rootReducer,
    initialState,
    composeEnhancers(Redux.applyMiddleware(thunkMiddleware)),
  );
};
