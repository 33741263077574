import { Flex } from '@/app/design-system';
import { SolutionOutlined } from '@ant-design/icons';

import { NavigationMenuItem } from '../../components.utility/navigation-menu-item';

interface MyConsultsMenuItemProps {
  isCollapsed: boolean;
}
export function MyConsultsMenuItem({ isCollapsed }: MyConsultsMenuItemProps) {
  return (
    <NavigationMenuItem
      to="/matching"
      menuItemTooltipContent="My Consults"
      isCollapsed={isCollapsed}
    >
      <Flex
        justifyContent={"space-between"}
        css={{ flexGrow: isCollapsed ? 0 : 1, position: "relative" }}
      >
        <Flex gap={12}>
          <SolutionOutlined
            style={{ fontSize: 20 }}
            aria-hidden={!isCollapsed}
            title={!isCollapsed ? "" : "My Consults"}
          />{" "}
          {!isCollapsed ? "My Consults" : null}
        </Flex>
      </Flex>
    </NavigationMenuItem>
  );
}
