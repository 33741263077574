import React, { useState } from "react";
import { Select as DeprecatedAntdSelect, Divider, Button } from "antd";
import { SelectValue } from "antd/es/select";
import { useSelector } from "react-redux";
import { AppState } from "../../../../state/models";
import { Row } from "../../../_layout/Flex";
import api from "../../../../api";
import { AxiosResponse } from "axios";
import { Title } from "../../../_layout/display";
import { alphabetizeBy } from "../../../_helpers";

interface ParseClinicianQuestionnaireResponse {
  command: "parse_clinician_questionnaire";
  data:
    | string
    | {
        clinician_id: number;
        dryrun: boolean;
        errors: string[];
        logs: string[];
        tags_added: string[];
        tags_removed: string[];
      };
  result: "Success" | "Failure";
}

export default () => {
  const [clinicians] = useSelector((state: AppState) => [
    Object.values(state.clinicians.clinicianMap),
  ]);

  const [loading, setLoading] = useState<boolean>(false);

  const [selectedClinicianId, selectClinicianId] = useState<number>();
  const [dryrunResult, setDryrunResult] = useState<
    ParseClinicianQuestionnaireResponse | undefined
  >();
  const [runResult, setRunResult] = useState<
    ParseClinicianQuestionnaireResponse | undefined
  >();

  const url = `/ehr/manage/parse_clinician_questionnaire?clinician_id=${selectedClinicianId}`;

  const doDryrun = () => {
    if (loading) return;
    setLoading(true);
    api
      .get(url)
      .then((response: AxiosResponse<ParseClinicianQuestionnaireResponse>) => {
        setDryrunResult(response.data);
        setLoading(false);
      })
      .catch((err: any) => {
        console.error(err);
        setLoading(false);
      });
  };

  const doRun = () => {
    if (loading) return;
    setLoading(true);
    api
      .get(url + "&no-dryrun")
      .then((response: AxiosResponse<ParseClinicianQuestionnaireResponse>) => {
        setRunResult(response.data);
        setLoading(false);
      })
      .catch((err: any) => {
        console.error(err);
        setLoading(false);
      });
  };

  return (
    <>
      <Title>Import Clinician Questionnaire</Title>
      <p>
        This will reset a clinician's tags to match what they put in their
        questionnaire while onboarding.
        <br />
        <i>
          **This job will fail with a Traceback error if the clinician filled
          out a past version of the questionnaire.
        </i>
      </p>
      <Row layout={"start center"}>
        <DeprecatedAntdSelect
          disabled={loading}
          onChange={(value: SelectValue) => {
            selectClinicianId(value as number);
            setDryrunResult(undefined);
            setRunResult(undefined);
          }}
          style={{ width: 400 }}
          placeholder="Select a clinician to import a questionnaire..."
        >
          {clinicians
            .sort((a, b) => alphabetizeBy(a, b, "first_name"))
            .map((clinician) => (
              <DeprecatedAntdSelect.Option
                key={clinician.id}
                value={clinician.id}
              >
                {clinician.first_name} {clinician.last_name}
              </DeprecatedAntdSelect.Option>
            ))}
        </DeprecatedAntdSelect>
        <div style={{ flex: 1 }} />
        <Button
          type="default"
          loading={loading}
          disabled={!selectedClinicianId}
          onClick={() => doDryrun()}
        >
          Dryrun
        </Button>
        <Button
          loading={loading}
          disabled={!dryrunResult}
          type="primary"
          onClick={doRun}
        >
          Run
        </Button>
      </Row>
      {runResult === undefined && dryrunResult !== undefined && (
        <Results results={dryrunResult} />
      )}
      {runResult !== undefined && <Results results={runResult} />}
    </>
  );
};

const Results = ({
  results,
}: {
  results: ParseClinicianQuestionnaireResponse;
}) => (
  <>
    <Divider />
    <div>
      <strong>Result: </strong> {results.result}
    </div>
    <div style={{ overflowY: "auto" }}>
      {typeof results.data === "string" ? (
        <div>{results.data}</div>
      ) : (
        <>
          <div>
            <strong>Errors:</strong>
          </div>
          <p>
            <i>
              You may have to add tags manually based on these errors. These
              errors also may indicate a need for additional tags in the system.
            </i>
          </p>
          <ul>
            {results.data.errors.map((error: string, idx) => (
              <li key={"errors" + idx}>{error}</li>
            ))}
          </ul>
          <p>
            <strong>Logs:</strong>
          </p>
          <ul>
            {results.data.logs.map((log: string, idx) => (
              <li key={"logs" + idx}>{log}</li>
            ))}
          </ul>
        </>
      )}
    </div>
  </>
);
