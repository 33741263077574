import * as React from "react";
import { useDispatch } from "react-redux";
import { useShallowEqualSelector } from "../../../_helpers/redux";
import { consultNoteActions } from "../../../../state/models/consultnote";
import { Input, Radio } from "antd";
import QuestionTitle from "./shared/QuestionTitle";
import { RadioGroup } from "../../Matchmaker/1-ClientStep/CarePreferences/styled";
import useDebounce from "../../../hooks/useDebounce";
import { ConsultNoteQuestion } from "../../../../api/types";
import { getCurrentConsultNoteInfo } from "../../_hooks";

interface Props {
  question: ConsultNoteQuestion;
  handleEdit: (hasBeenEdited: boolean) => void;
}

export default ({ question, handleEdit }: Props) => {
  const { readOnlyConsultNote } = getCurrentConsultNoteInfo();

  const [cognitive_dementia_endorsed, cognitive_dementia_additional_details] =
    useShallowEqualSelector((state) => [
      state.consultnote?.openConsult?.consult_note?.cognitive_dementia_endorsed,
      state.consultnote?.openConsult?.consult_note
        ?.cognitive_dementia_additional_details
        ? state.consultnote?.openConsult?.consult_note
            ?.cognitive_dementia_additional_details
        : "",
    ]);

  const handleChange = (key: string, value: boolean | string) => {
    handleEdit(true);
    dispatch(
      consultNoteActions.setConsultNoteValue({
        key,
        value,
      }),
    );
  };

  const [additionalDetails, setAdditionalDetails] = React.useState(
    cognitive_dementia_additional_details,
  );

  const handleSetAdditionalDetails = (value: string) => {
    setAdditionalDetails(value);
    handleEdit(true);
  };

  const dispatch = useDispatch();

  const debouncedValue = useDebounce(additionalDetails, 500);

  React.useEffect(() => {
    setAdditionalDetails(cognitive_dementia_additional_details);
  }, [cognitive_dementia_additional_details]);

  React.useEffect(() => {
    dispatch(
      consultNoteActions.setConsultNoteValue({
        key: "cognitive_dementia_additional_details",
        value: debouncedValue,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return (
    <div style={{ paddingTop: "15px", paddingBottom: "15px" }}>
      <QuestionTitle
        questionNumber={question.questionNumber}
        titleText={question.title}
        subtitleText={question.subtitle}
        isRequired={question.isRequired}
      />
      <div>
        <RadioGroup
          disabled={readOnlyConsultNote}
          onChange={(e) => {
            handleChange("cognitive_dementia_endorsed", e.target.value);
          }}
          value={cognitive_dementia_endorsed}
        >
          <Radio.Button value={false}>Denied</Radio.Button>
          <Radio.Button value={true}>Endorsed</Radio.Button>
        </RadioGroup>
        {cognitive_dementia_endorsed && (
          <div>
            <div
              style={{
                fontSize: "14px",
                fontStyle: "italic",
                paddingTop: "8px",
                color: "rgba(0, 0, 0, 0.85)",
              }}
            >
              If checked, consider assessing:
              <ol>
                <li>When diagnosed with ____ and by whom </li>
                <li>
                  Current treatment (ie. medication, supportive therapies)
                </li>
                <li>
                  Daily activities currently able to perform on own vs.
                  need/receive support in completing (ie. preparing meals,
                  paying bills)
                </li>
              </ol>
            </div>
            Additional details (if needed):
            <Input.TextArea
              disabled={readOnlyConsultNote}
              style={{ height: "32px" }}
              value={additionalDetails}
              onChange={(e) => {
                handleSetAdditionalDetails(e.target.value);
              }}
            ></Input.TextArea>
          </div>
        )}
      </div>
    </div>
  );
};
