import { Flex } from "@/app/design-system";

import { HomeIcon } from "@/app/design-system/icons/components/home-icon";

import { useMainNavigationUtils } from "../../../hooks/use-main-navigation-utils";
import { NavigationMenuItem } from "../../components.utility/navigation-menu-item";

interface MyDashboardMenuItemProps {
  isCollapsed: boolean;
}

export function MyDashboardMenuItem({ isCollapsed }: MyDashboardMenuItemProps) {
  const { alertCount } = useMainNavigationUtils();

  return (
    <NavigationMenuItem
      to="/dashboard"
      menuItemTooltipContent="My Dashboard"
      isCollapsed={isCollapsed}
    >
      <Flex
        justifyContent={"space-between"}
        css={{ flexGrow: isCollapsed ? 0 : 1, position: "relative" }}
      >
        <Flex gap={12} css={{ flexGrow: 1 }}>
          <HomeIcon
            aria-hidden={!isCollapsed}
            title={!isCollapsed ? "" : "My Dashboard"}
          />{" "}
          {!isCollapsed ? "My Dashboard" : null}
        </Flex>
      </Flex>
    </NavigationMenuItem>
  );
}
