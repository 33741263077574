import React from "react";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { Form, Separator } from "../../../../../../../../../../design-system";
import { recurringSessionsOptions } from "../../session-actions.constants";
import { SessionsToSelectFormItem } from "../../upsert-session-action/upsert-session-form/upsert-session-form-items/sessions-to-select-form-item";
import { CancellationAttendanceNoteFormItem } from "./cancel-session-form-tems/cancellation-attendance-note-form-item";
import { CancellationReasonFormItem } from "./cancel-session-form-tems/cancellation-reason-form-item";
import { CancellationTypeFormItem } from "./cancel-session-form-tems/cancellation-type-form-item";
import {
  CancelSessionFieldNames,
  CancelSessionFormFields,
  useCancellationReasons,
} from "./use-cancel-session-form";

type CancelSessionFormProps = {
  formMethods: UseFormReturn<CancelSessionFormFields, any>;
  isRecurringEvent: boolean;
  isException: boolean;
};

export const CancelSessionForm = ({
  formMethods,
  isRecurringEvent,
  isException,
}: CancelSessionFormProps) => {
  const { control, setValue, trigger } = formMethods;

  const [cancellationReasons, cancellationTypeOptions] = useCancellationReasons(
    control,
    setValue,
    formMethods,
  );

  const shouldShowSessionsToSelect = isRecurringEvent && !isException;

  return (
    <FormProvider {...formMethods}>
      <Form>
        {shouldShowSessionsToSelect ? (
          <>
            <SessionsToSelectFormItem
              name={CancelSessionFieldNames.SESSIONS_TO_UPDATE}
              options={recurringSessionsOptions}
              mode={"cancel"}
            />
            <Separator />
          </>
        ) : null}
        <CancellationTypeFormItem
          name={CancelSessionFieldNames.CANCELLATION_TYPE}
          options={cancellationTypeOptions}
          callback={() => trigger()}
        />

        <CancellationReasonFormItem
          name={CancelSessionFieldNames.CANCELLATION_REASON}
          options={cancellationReasons}
          id={"cancellation-reason-form-item"}
          callback={() => trigger()}
        />

        <CancellationAttendanceNoteFormItem
          name={CancelSessionFieldNames.ATTENDANCE_NOTE}
          callback={() => trigger()}
        />
      </Form>
    </FormProvider>
  );
};
