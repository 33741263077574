import React from "react";
import { ExtendedMatch } from "../../../../api/types";
import { Button } from "antd";
import { Title } from "../../../_layout/display";
import { copyToClipboard } from "../../../_helpers";
import { Row } from "../../../_layout/Flex";
import Footer from "./Footer";
import QueueNote from "../QueueNote";
import { $kpBlue } from "../../../../assets/colors";

export default function ReferredMatchItem({
  extendedMatch,
}: {
  extendedMatch: ExtendedMatch;
}) {
  return (
    <>
      <QueueNote queueNote={extendedMatch.queue_note} />
      <div style={{ padding: "10px" }}>
        <Row layout="start center" style={{ marginBottom: "10px" }}>
          <Title size="xs" margin="0px">
            Referral Note:&nbsp;
          </Title>
          <div style={{ flex: 1 }} />
          <Button
            size="small"
            onClick={() => copyToClipboard(extendedMatch.client.email)}
          >
            Copy Client Email
          </Button>
        </Row>
        {extendedMatch.client.payers.map((p) => (
          <Title size="xs" key={p.id} style={{ margin: 0, color: $kpBlue }}>
            {p.payer.display_name} Client
          </Title>
        ))}
        <p>{extendedMatch.referral_note}</p>
        <Footer extendedMatch={extendedMatch} actions={["requeue"]} />
      </div>
    </>
  );
}
