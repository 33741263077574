import api from "@/api";
import { useQuery } from "react-query";
import { GroupsSIEnabledByGroupData } from "./group-therapy.interface";
import { FIVE_MINUTES } from "@/app/_helpers/constants";

const getGroupSIDataByClinician = async (
  clinicianId: number,
): Promise<GroupsSIEnabledByGroupData> => {
  try {
    const res = await api.get(
      `api/groups/v1/get_groups_SI_data/?clinician_id=${clinicianId}`,
    );

    return res.data;
  } catch (e) {
    throw new Error((e as Error).message);
  }
};

interface UseGroupSIDataByClinicianParams {
  clinicianId: number;
  enabled: boolean;
}

export const useGroupSIDataByClinician = ({
  clinicianId,
  enabled,
}: UseGroupSIDataByClinicianParams) => {
  return useQuery(
    ["groups-SI-data-by-clinician", clinicianId],
    () => getGroupSIDataByClinician(clinicianId),
    {
      select: (data) => data,
      enabled,
      staleTime: FIVE_MINUTES,
    },
  );
};
