import { RadioGroupProps } from "@radix-ui/react-radio-group";
import React from "react";
import { RadioGroup } from "../";
import { Label } from "@/app/design-system";
import { Flex } from "../layout/flex";
interface RadioGroupOption {
  id: string;
  value: string;
  label: string;
  shouldRenderTextInput?: boolean;
}

/**
 * @component RadioGroupV2
 * @description Radio Group where no more than radio can be selected at a time.
 * @prop {RadioGroupOption[]} options a list of options to render as the RadioGroupRadio.
 * @prop {React.ReactNode} name
 * @prop { string }
 */

interface CustomRadioGroupProps extends RadioGroupProps {
  errorMessage?: string;
  options: RadioGroupOption[];
}

export const ComposedRadioGroup = React.forwardRef<
  HTMLDivElement,
  CustomRadioGroupProps
>(({ children, title, ...props }, forwardedRef) => (
  <RadioGroup.Root
    ref={forwardedRef}
    defaultValue={props.defaultValue}
    onValueChange={props.onValueChange}
    aria-label={props["aria-label"]}
  >
    {props.options.map((option) => {
      const radioGroupId = `${option.id}-${option.label}`;

      return (
        <Flex key={option.value} alignItems={"center"} gap={8}>
          <RadioGroup.Radio value={option.value} id={radioGroupId}>
            <RadioGroup.Indicator />
          </RadioGroup.Radio>
          <Label htmlFor={radioGroupId} className="radio-label">
            {option.label}
          </Label>
        </Flex>
      );
    })}
  </RadioGroup.Root>
));
