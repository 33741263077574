import React, { useEffect, useState } from "react";

// import { Card } from '../../design-system/cards/card';
// import { Flex } from '../../design-system/layout/flex';
// import { QuestionIcon } from '../styled/icons/QuestionIcon';
import { Flex } from "@/app/design-system";
import { QuestionIcon } from "./QuestionIcon";

// import { logFreshpaintEvent, SymptomTrackingEvent } from '../_dynamic/analytics/Freshpaint';
// import { useGetClientInfo } from './api/use-get-client-info';
// import { styledStitches } from "@/app/design-system/styles/stitches.config";
import { styledStitches } from "@/app/design-system/styles/stitches.config";
import * as HoverCard from "@radix-ui/react-hover-card";
import { XIcon } from "@/app/design-system/icons";
// import { XIcon } from '../../design-system/icons';
import * as ModalDialog from "@/app/design-system/modal-dialog/modal-dialog.styled";
// import { StyledCloseButton as MobileStyledCloseButton } from '../../design-system/modal-dialog/modal-dialog.styled';
// import * as ModalDialog from '../../design-system/modal-dialog/modal-dialog.styled';

// images positive
import pos_gad7_1 from "./responses-summary/positive/gad7_1.svg";
import pos_gad7_2 from "./responses-summary/positive/gad7_2.svg";
import pos_gad7_3 from "./responses-summary/positive/gad7_3.svg";
import pos_gad7_4 from "./responses-summary/positive/gad7_4.svg";
import pos_gad7_5 from "./responses-summary/positive/gad7_5.svg";
import pos_gad7_6 from "./responses-summary/positive/gad7_6.svg";
import pos_gad7_7 from "./responses-summary/positive/gad7_7.svg";
import pos_phq9_1 from "./responses-summary/positive/phq9_1.svg";
import pos_phq9_2 from "./responses-summary/positive/phq9_2.svg";
import pos_phq9_3 from "./responses-summary/positive/phq9_3.svg";
import pos_phq9_4 from "./responses-summary/positive/phq9_4.svg";
import pos_phq9_5 from "./responses-summary/positive/phq9_5.svg";
import pos_phq9_6 from "./responses-summary/positive/phq9_6.svg";
import pos_phq9_7 from "./responses-summary/positive/phq9_7.svg";
import pos_phq9_8 from "./responses-summary/positive/phq9_8.svg";

// images negative
import neg_gad7_1 from "./responses-summary/negative/gad7_1.svg";
import neg_gad7_2 from "./responses-summary/negative/gad7_2.svg";
import neg_gad7_3 from "./responses-summary/negative/gad7_3.svg";
import neg_gad7_4 from "./responses-summary/negative/gad7_4.svg";
import neg_gad7_5 from "./responses-summary/negative/gad7_5.svg";
import neg_gad7_6 from "./responses-summary/negative/gad7_6.svg";
import neg_gad7_7 from "./responses-summary/negative/gad7_7.svg";
import neg_phq9_1 from "./responses-summary/negative/phq9_1.svg";
import neg_phq9_2 from "./responses-summary/negative/phq9_2.svg";
import neg_phq9_3 from "./responses-summary/negative/phq9_3.svg";
import neg_phq9_4 from "./responses-summary/negative/phq9_4.svg";
import neg_phq9_5 from "./responses-summary/negative/phq9_5.svg";
import neg_phq9_6 from "./responses-summary/negative/phq9_6.svg";
import neg_phq9_7 from "./responses-summary/negative/phq9_7.svg";
import neg_phq9_8 from "./responses-summary/negative/phq9_8.svg";

const images_map = {
  pos_gad7_1: pos_gad7_1,
  pos_gad7_2: pos_gad7_2,
  pos_gad7_3: pos_gad7_3,
  pos_gad7_4: pos_gad7_4,
  pos_gad7_5: pos_gad7_5,
  pos_gad7_6: pos_gad7_6,
  pos_gad7_7: pos_gad7_7,
  pos_phq9_1: pos_phq9_1,
  pos_phq9_2: pos_phq9_2,
  pos_phq9_3: pos_phq9_3,
  pos_phq9_4: pos_phq9_4,
  pos_phq9_5: pos_phq9_5,
  pos_phq9_6: pos_phq9_6,
  pos_phq9_7: pos_phq9_7,
  pos_phq9_8: pos_phq9_8,
  neg_gad7_1: neg_gad7_1,
  neg_gad7_2: neg_gad7_2,
  neg_gad7_3: neg_gad7_3,
  neg_gad7_4: neg_gad7_4,
  neg_gad7_5: neg_gad7_5,
  neg_gad7_6: neg_gad7_6,
  neg_gad7_7: neg_gad7_7,
  neg_phq9_1: neg_phq9_1,
  neg_phq9_2: neg_phq9_2,
  neg_phq9_3: neg_phq9_3,
  neg_phq9_4: neg_phq9_4,
  neg_phq9_5: neg_phq9_5,
  neg_phq9_6: neg_phq9_6,
  neg_phq9_7: neg_phq9_7,
  neg_phq9_8: neg_phq9_8,
};

interface SurveyCardProps {
  imageUrl: string;
  title: string;
  description: string;
  activeCard: string;
  id: string;
  isPositive: boolean;
}

export const Card = styledStitches("div", {
  br: 8,
  backgroundColor: "$neutral0",
  fontSize: 14,
  fontWeight: "bold",
  display: "inline-block",
  padding: 8,
});

export const MobileStyledCloseButton = styledStitches("button", {
  all: "unset",
  borderRadius: "50%",
  cursor: "pointer",

  variants: {
    upperRightCorner: {
      true: {
        position: "absolute",
        top: "5px",
        right: "15px",
      },
    },
  },
});

export const MobileSurveyCard = ({
  imageUrl,
  title,
  description,
  id,
  activeCard,
  isPositive,
}: SurveyCardProps) => {
  // const { data: clientInfoData, isLoading: isClientInfoLoading } = useGetClientInfo();
  // if (isClientInfoLoading) {
  //   return null;
  // }
  return (
    <StyledCard>
      <ModalDialog.Root
        open={id === activeCard}
        // onOpenChange={(open: boolean) => {
        //   if (open) {
        //     logFreshpaintEvent(SymptomTrackingEvent.CLICKED_CATEGORY_CARD, {
        //       client_id: clientInfoData?.ehr_client[0].id,
        //     });
        //   }
        // }}
      >
        <ModalDialog.Trigger
          style={{ all: "unset", height: "100%", width: "100%" }}
        >
          <CardContainer
            flexDirection="column"
            justifyContent="center"
            alignItems="stretch"
          >
            <Flex flexDirection="row" justifyContent="flex-end">
              <Flex>
                <QuestionContainer />
              </Flex>
            </Flex>
            <ContentContainer
              flexDirection="column"
              alignItems="center"
              gap="8"
              justifyContent="center"
            >
              <img
                src={images_map[`${isPositive ? "pos" : "neg"}_${id}`]}
                alt={`${title}`}
              />
              {title}
            </ContentContainer>
          </CardContainer>
        </ModalDialog.Trigger>

        <ModalDialog.Portal>
          <ModalDialog.Overlay />
          <ModalDialog.Content style={{ zIndex: 1, maxWidth: "260px" }}>
            <HoverCardContainer>
              <HoverCardContentContainer
                flexDirection="column"
                justifyContent="flex-end"
              >
                <Flex justifyContent="flex-end">
                  <MobileStyledCloseButton
                    upperRightCorner
                    style={{ right: "10px" }}
                  >
                    <StyledXIcon />
                  </MobileStyledCloseButton>
                </Flex>
                <HoverCardTitle>
                  Based on your response to the statement
                </HoverCardTitle>
                <HoverCardDescription>{description}</HoverCardDescription>
              </HoverCardContentContainer>
            </HoverCardContainer>
          </ModalDialog.Content>
        </ModalDialog.Portal>
      </ModalDialog.Root>
    </StyledCard>
  );
};

export const DesktopSurveyCard = ({
  imageUrl,
  title,
  description,
  id,
  activeCard,
  isPositive,
}: SurveyCardProps) => {
  // const { data: clientInfoData, isLoading: isClientInfoLoading } = useGetClientInfo();
  // if (isClientInfoLoading) {
  //   return null;
  // }
  return (
    <StyledCard>
      <HoverCard.Root
        openDelay={0}
        open={id === activeCard}
        // onOpenChange={(open: boolean) => {
        //   if (open) {
        //     logFreshpaintEvent(SymptomTrackingEvent.CLICKED_CATEGORY_CARD, {
        //       client_id: clientInfoData?.ehr_client[0].id,
        //     });
        //   }
        // }}
      >
        <HoverCard.Trigger>
          <CardContainer
            flexDirection="column"
            justifyContent="center"
            alignItems="stretch"
          >
            <Flex flexDirection="row" justifyContent="flex-end">
              <Flex>
                <QuestionContainer />
              </Flex>
            </Flex>
            {getHoverCardContent(description)}
            <ContentContainer
              flexDirection="column"
              alignItems="center"
              gap="8"
              justifyContent="center"
            >
              <img
                src={images_map[`${isPositive ? "pos" : "neg"}_${id}`]}
                alt={`${title}`}
              />
              {title}
            </ContentContainer>
          </CardContainer>
        </HoverCard.Trigger>
      </HoverCard.Root>
    </StyledCard>
  );
};

const SurveyCard = (props: SurveyCardProps) => {
  const [isDesktopMediaQuery, setIsDesktopMediaQuery] = useState<{
    matches: boolean;
  }>({
    matches: window.innerWidth < 768 ? false : true,
  });

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 768px)");

    // https://stackoverflow.com/questions/56466261
    // Use of deprecated addListener in catch block to support older (safari) versions
    try {
      mediaQuery.addEventListener("change", setIsDesktopMediaQuery);
      // this is the cleanup function to remove the listener
      return () =>
        mediaQuery.removeEventListener("change", setIsDesktopMediaQuery);
    } catch (_) {
      try {
        mediaQuery.addListener(setIsDesktopMediaQuery);
        // this is the cleanup function to remove the listener
        return () => mediaQuery.removeListener(setIsDesktopMediaQuery);
      } catch (e) {
        console.error(
          "SurveyCard.tsx: Unable to add listeners for screen width media query",
          e,
        );
        return;
      }
    }
  }, []);

  if (isDesktopMediaQuery && !isDesktopMediaQuery?.matches) {
    return <MobileSurveyCard {...props} />;
  } else {
    return <DesktopSurveyCard {...props} />;
  }
};

const getHoverCardContent = (description: string) => {
  return (
    <HoverCard.Content side="bottom" sideOffset={10}>
      <HoverCardContainer>
        <HoverCardContentContainer
          flexDirection="column"
          justifyContent="flex-end"
        >
          <Flex justifyContent="flex-end">
            <StyledCloseButton>
              <StyledXIcon />
            </StyledCloseButton>
          </Flex>
          <HoverCardTitle>
            Based on your response to the statement
          </HoverCardTitle>
          <HoverCardDescription>{description}</HoverCardDescription>
        </HoverCardContentContainer>
      </HoverCardContainer>
    </HoverCard.Content>
  );
};

const ContentContainer = styledStitches(Flex, {
  marginTop: -16,
  height: "100%",
  textAlign: "center",
  alignItems: "center",
  fontWeight: 400,
});

const StyledCloseButton = styledStitches("button", {
  all: "unset",
  cursor: "pointer",
  paddingBottom: 10,
});

const StyledXIcon = styledStitches(XIcon, {
  height: 10,
});

const QuestionContainer = styledStitches(QuestionIcon, { opacity: 0 });

const CardContainer = styledStitches(Flex, {
  height: "100%",
  zIndex: 10,
});

const StyledCard = styledStitches(Card, {
  // height: 150,
  height: "132px",
  width: "165px",
  border: "1px solid #E2E2E2",
});

const HoverCardContainer = styledStitches("div", {
  backgroundColor: "#F3FAF7",
  fontWeight: "normal",
  borderRadius: 8,
});

const HoverCardContentContainer = styledStitches(Flex, {
  padding: 24,
  maxWidth: 260,
  textAlign: "center",
});

const HoverCardTitle = styledStitches("div", {
  color: "#171717D1",
  fontSize: 11,
  textTransform: "capitalize",
});

const HoverCardDescription = styledStitches("div", {
  color: "#171717D1",
  fontStyle: "italic",
  paddingTop: 24,
});
export default SurveyCard;
