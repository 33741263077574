import api from "@/api";
import { useMutation } from "react-query";
import { transformDateAndTimeString } from "../../routes/client-profile-page/client-profile-nav/routes/schedule-page/components/session-actions/upsert-session-action/upsert-session-form/upcoming-conflicts/use-future-conflicts.api";
import {
  SessionFormatOption,
  SessionRecurrenceOption,
} from "../../routes/client-profile-page/client-profile-nav/routes/schedule-page/components/session-actions/upsert-session-action/upsert-session-form/upsert-session-form.constants";
import { UpsertSessionFormFields } from "../../routes/client-profile-page/client-profile-nav/routes/schedule-page/components/session-actions/upsert-session-action/upsert-session-form/use-upsert-session-form";

interface AddEventArgs {
  clinicianId: number;
  clientId: number;
  data: UpsertSessionFormFields;
  timezone: string;
}

export function addEvent({ clinicianId, clientId, data, timezone }: AddEventArgs) {
  try {
    const { sessionDate, sessionTime, sessionFormat, recurrence, clinician } =
      data;

    const combinedDateTime = transformDateAndTimeString(
      sessionDate,
      sessionTime,
      timezone
    );

    const startISODateTime = combinedDateTime?.toISO();
    const endISODateTime = combinedDateTime?.plus({ minute: 50 }).toISO();

    // const delivery =
    //   sessionFormat === SessionFormatOption.VIRTUAL
    //     ? "teletherapy"
    //     : "in_person";

    const createRecurrenceValue =
      recurrence === SessionRecurrenceOption.DOES_NOT_REPEAT
        ? null
        : recurrence;

    const createRecurrenceQueryParam = createRecurrenceValue
      ? `?create_recurrence=${createRecurrenceValue}`
      : "";

    const addEventPostBody = {
      start_time: startISODateTime,
      end_time: endISODateTime,
      client: clientId,
      clinician: clinician ? clinician : clinicianId,
      service_type: "individual",
    };

    console.log(
      `[addEvent] POST created new event\n[addEvent] Request body:`,
      addEventPostBody,
      `\n[addEvent] Params:`,
      createRecurrenceQueryParam ? createRecurrenceQueryParam : "none",
    );

    return api.post(
      `/api/care_platform/events/v1/${createRecurrenceQueryParam}`,
      addEventPostBody,
    );
  } catch (e) {
    console.error(`[addEvent] Unable to create event: ${e}`);
    throw new Error(`[addEvent] Unable to create event: ${e}`);
  }
}

export function useAddSession() {
  return useMutation(addEvent);
}
