import * as React from "react";
import * as colors from "../../assets/colors";

import { SafetyCertificateFilled } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import Column from "../_layout/Flex/Column";
import { DivProps } from "../../api/types";

interface Props extends DivProps {
  normalizedScore: number;
  totalStars?: number;
  filledStars?: number;
}

export default (props: Props) => {
  return (
    <Column {...props} layout="center center">
      <div>
        <SafetyCertificateFilled style={{ color: colors.$green }} />
        <SafetyCertificateFilled style={{ color: colors.$green }} />
        <SafetyCertificateFilled style={{ color: colors.$green }} />
        <LegacyIcon
          type="safety-certificate"
          theme={props.normalizedScore >= 0.6 ? "filled" : "outlined"}
          style={{
            color:
              props.normalizedScore >= 0.6 ? colors.$green : colors.$greyBorder,
          }}
        />
        <LegacyIcon
          type="safety-certificate"
          theme={props.normalizedScore >= 0.9 ? "filled" : "outlined"}
          style={{
            color:
              props.normalizedScore >= 0.9 ? colors.$green : colors.$greyBorder,
          }}
        />
      </div>
      {props.totalStars !== undefined && props.filledStars !== undefined && (
        <div style={{ marginTop: "-4px" }}>
          {props.totalStars > 0 && (
            <LegacyIcon
              type="star"
              theme={props.filledStars > 0 ? "filled" : "outlined"}
              style={{
                color:
                  props.filledStars > 0
                    ? colors.$secondary
                    : colors.$greyBorder,
              }}
            />
          )}
          {props.totalStars > 1 && (
            <LegacyIcon
              type="star"
              theme={props.filledStars > 1 ? "filled" : "outlined"}
              style={{
                color:
                  props.filledStars > 1
                    ? colors.$secondary
                    : colors.$greyBorder,
              }}
            />
          )}
          {props.totalStars > 2 && (
            <LegacyIcon
              type="star"
              theme={props.filledStars > 2 ? "filled" : "outlined"}
              style={{
                color:
                  props.filledStars > 2
                    ? colors.$secondary
                    : colors.$greyBorder,
              }}
            />
          )}
        </div>
      )}
    </Column>
  );
};
