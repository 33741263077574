import { Flex, Spinner, Text, styledStitches } from "@/app/design-system";
import {
  BarGraphIcon,
  CalendarIcon,
  CircleCheckMarkIcon,
} from "@/app/design-system/icons";
import { NotesIcon } from "@/app/design-system/icons/components/notes-icon";
import { QuarterCircle } from "@/app/design-system";

interface GroupDetailsNavInfoProps {
  sessionsAttended: number;
  averageAttendance: number | undefined | null;
  averageMBC: string | undefined | null;
  numClients: number | undefined;
  firstSessionDate: string | undefined;
  endSessionDate: string | undefined;
  totalSessions: number | undefined;
  isError: boolean;
  isLoading: boolean;
}

export const GroupDetailsNavInfo = ({
  sessionsAttended,
  averageAttendance,
  firstSessionDate,
  endSessionDate,
  numClients,
  averageMBC,
  totalSessions,
  isError,
  isLoading,
}: GroupDetailsNavInfoProps) => {
  const isAvgAttendance =
    averageAttendance && averageAttendance !== -1 ? true : false;

  const isMBC =
    averageMBC && averageMBC !== "-100" && averageMBC !== "NaN" ? true : false;

  return (
    <Flex>
      {isError ? (
        <Flex
          css={{
            backgroundColor: "$red3",
            flexGrow: 1,
            br: 6,
            border: "1px solid $red11",
          }}
          gap={8}
          alignItems="center"
          justifyContent={"center"}
        >
          <Text color={"$red11"} fontWeight={600}>
            Error loading group info.
          </Text>
        </Flex>
      ) : null}

      {isLoading ? (
        <Spinner>
          <QuarterCircle dimension={"2em"}></QuarterCircle>
        </Spinner>
      ) : null}

      {!isLoading && !isError ? (
        <>
          <Flex>
            <NavInfoContainer>
              <IconContainer>
                <CalendarIcon fill={"#6F6F6F"} />
              </IconContainer>
              <Text color={"$neutral9"} css={{ fontWeight: 700 }}>
                {firstSessionDate} - {endSessionDate}
              </Text>
            </NavInfoContainer>

            <NavInfoContainer>
              <IconContainer>
                <NotesIcon width={25} stroke={"#6F6F6F"} />
              </IconContainer>
              <HeaderTextContent color={"$neutral9"}>
                {numClients} clients enrolled
              </HeaderTextContent>
            </NavInfoContainer>

            <NavInfoContainer>
              <IconContainer>
                <CircleCheckMarkIcon fill={"#6F6F6F"} />
              </IconContainer>
              <HeaderTextContent color={"$neutral9"} css={{ fontWeight: 700 }}>
                {sessionsAttended}/{totalSessions}
              </HeaderTextContent>
              <HeaderTextContent color={"$neutral9"}>
                &nbsp; sessions completed | &nbsp;
              </HeaderTextContent>

              {isAvgAttendance ? (
                <>
                  <HeaderTextContent
                    color={"$neutral9"}
                    css={{ fontWeight: 700 }}
                  >
                    {averageAttendance}%
                  </HeaderTextContent>
                  <HeaderTextContent color={"$neutral9"}>
                    &nbsp;avg attendance
                  </HeaderTextContent>
                </>
              ) : (
                <HeaderTextContent color={"$neutral9"}>
                  No attendance percentage yet
                </HeaderTextContent>
              )}
            </NavInfoContainer>

            {isMBC ? (
              <NavInfoContainer>
                <IconContainer>
                  <BarGraphIcon fill={"#6F6F6F"} />
                </IconContainer>
                <HeaderTextContent
                  color={"$neutral9"}
                  css={{ fontWeight: 700 }}
                >
                  {averageMBC}%
                </HeaderTextContent>
                <HeaderTextContent color={"$neutral9"} css={{ pr: 26 }}>
                  &nbsp;avg check-in completion
                </HeaderTextContent>
              </NavInfoContainer>
            ) : null}
          </Flex>
        </>
      ) : null}
    </Flex>
  );
};

export const NavInfoContainer = styledStitches(Flex, {
  justifyContent: "space-between",
  pt: 32,
  pb: 32,
  pr: 25,
});

export const IconContainer = styledStitches(Flex, {
  justifyContent: "space-between",
  pr: 15,
});

export const HeaderTextContent = styledStitches(Text, {
  color: "$neutral9",
  fontSize: 16,
});
