import { ActionCreator } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";

import { AppState, Action } from ".";

export type AsyncAction = ThunkAction<void, AppState, any, Action>;
export type AsyncActionCreator = ActionCreator<AsyncAction>;
export type AsyncActionWithResult<R> = ThunkAction<Promise<R>, AppState, any, Action>;
export type AsyncActionWithResultCreator<R> = ActionCreator<AsyncActionWithResult<R>>
export type PromiseDispatch = ThunkDispatch<AppState, never, Action>;
export interface AsyncOperations {
  [key: string]: ActionCreator<AsyncAction>;
}

export function assertUnreachable(never: never): never {
  throw new Error("Unreachable code reached.");
}
