import { styledStitches } from "@/app/design-system/styles/stitches.config";
import { FOCUS_STYLES } from "../styles/config/css-utils";

export const UnstyledButton = styledStitches("button", {
  ...FOCUS_STYLES,
  background: "none",
  color: "inherit",
  border: "none",
  padding: 0,
  font: "inherit",
  cursor: "pointer",
  outline: "inherit",
  "&:hover": { backgroundColor: "$neutral2" },
  variants: {
    discreet: {
      true: {
        cursor: "default",
      },
    },
  },
});

export const Button = styledStitches("button", {
  ...FOCUS_STYLES,
  all: "unset",
  br: 8,
  cursor: "pointer",
  width: "fit-content",
  alignItems: "center",
  display: "flex",
  justifyContent: "center",

  "&:active": {
    backgroundColor: "$neutral5",
    transform: "scale(0.985)",
    boxShadow: "none",
  },

  "&:disabled": {
    cursor: "not-allowed",
    backgroundColor: "$neutral4",

    "&:hover, &:focus": {
      backgroundColor: "$neutral4",
    },
  },

  /**
   * defaulting svgs to be flex for ease of positioning.
   * the "right" way to center inline elements is a little more involved
   * requires system wide refactoring to account for inline elements:
   * https://every-layout.dev/layouts/icon/
   */
  svg: {
    display: "flex",
  },

  variants: {
    size: {
      small: {
        fontSize: 12,
        padding: "8px 12px",
        height: 24,
      },
      medium: {
        fontSize: 14,
        padding: "10px 16px",
      },
      large: {
        fontSize: 16,
        padding: "14px 16px",
      },
    },
    variant: {
      // Useful when we have UI element that looks like a link but does not actually change the user context/location
      link: {
        color: "$blue11",
        backgroundColor: "none,",
        "&:hover, &:focus, &:active": {
          textDecoration: "underline",
          backgroundColor: "none,",
        },
      },
      antdLink: {
        color: "$blue11",
        fontSize: 14,
        padding: 0,
        backgroundColor: "none,",
        "&:hover, &:focus, &:active": {
          color: "#0d0d0d",
          backgroundColor: "none,",
        },
      },
      primary: {
        color: "$neutral0",
        backgroundColor: "$neutral12",

        "&:hover, &:focus": {
          backgroundColor: "$neutral11",
          transition: "background-color 150ms",
        },
      },

      secondary: {
        backgroundColor: "$neutral0",
        border: "2px solid $neutral12",
        fontWeight: 700,
        transition: "background-color 150ms",
        "&:disabled": {
          border: "2px solid $neutral6",
        },

        "&:hover": {
          backgroundColor: "$neutral2",
        },
      },

      tertiary: {
        border: "none",
        color: "$neutral11",
        "&:hover": {
          color: "$neutral9",
          backgroundColor: "$neutral2",
          transition: "background-color 150ms",
        },

        "&:disabled": {
          color: "$neutral6",
        },
      },
    },
    color: {
      $blue11: {
        color: "$blue11",
      },
      $neutral11: {
        color: "$neutral11",
      },
      $neutral0: {
        color: "$blue11",
      },
    },

    backgroundColor: {
      $red11: {
        backgroundColor: "$red11",
      },

      $neutral12: {
        backgroundColor: "$neutral12",
      },
      transparent: {
        backgroundColor: "rgba(0,0,0,0)",
      },
    },
    gap: {
      4: {
        gap: "$space4",
      },
      8: {
        gap: "$space8",
      },
    },
  },

  /**
   * using the css property to override the tertiary button's
   * color defaults.
   *
   * if we didn't use compoundVariants, and we set
   *   <Button variant="tertiary" color="$blue11" />
   * the color would still be $neutral11.
   */
  compoundVariants: [
    {
      variant: "tertiary",
      color: "$blue11",
      css: {
        color: "$blue11",
        "&:hover": {
          backgroundColor: "$blue9",
        },
      },
    },

    {
      variant: "secondary",
      size: "small",
      css: {
        py: 6,
      },
    },

    {
      variant: "primary",
      backgroundColor: "$red11",
      css: {
        backgroundColor: "$red11",
        "&:hover": {
          backgroundColor: "$red9",
        },
      },
    },

    /**
     * @todo
     * add disabled compound variants
     */
  ],

  defaultVariants: {
    size: "large",
    variant: "primary",
    color: "$neutral0",
  },
});
