import * as React from "react";
import styled from "styled-components";
import { DivProps } from "../../../api/types";

const Row = styled.div`
  display: flex;
`;
interface Props extends DivProps {
  layout?: string;
}

export default (props: Props) => (
  <Row
    {...{
      ...props,
      style: {
        ...(props.style ? props.style : {}),
        justifyContent: props.layout?.split(" ")[0] || "start",
        alignItems: props.layout?.split(" ")[1] || "center",
      },
    }}
  >
    {props.children}
  </Row>
);
