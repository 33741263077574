import React from "react";
import { Button, Flex, H2, Text } from "..";
import { XIcon } from "../icons";
import * as Drawer from "./drawer.styled";

/**
 * @component Drawer
 * @description A drawer (modal) component broken down into 5 composable components:
 * Pseudo composition: <>Root, <>Trigger</>, Content (Header and Footer))</
 *
 * Trigger is by default a button. Use asChild to merge it into its child component.
 *
 * @summary This abstraction is still a work in progress - there's stil
 */

/**
 *
 */

export const Root = Drawer.Root;
export const Trigger = Drawer.Trigger;

/**
 * truly have a love/hate relationship with typescript
 * https://stackoverflow.com/questions/54654303/using-a-forwardref-component-with-children-in-typescript
 */
type DrawerHeaderProps<T extends React.ElementType> =
  React.ComponentPropsWithoutRef<T> & {
    title?: string;
  };

export const Header = React.forwardRef<
  HTMLHeadingElement,
  DrawerHeaderProps<"div">
>(({ children, title, ...props }, forwardedRef) => (
  <Drawer.Title {...props} ref={forwardedRef}>
    {children ? (
      children
    ) : (
      <Flex justifyContent={"space-between"} css={{ flexGrow: 1 }}>
        <H2 fontWeight={700} css={{ fontSize: 20 }} color={"$neutral12"}>
          {title}
        </H2>
        <Drawer.Close asChild>
          <Drawer.CloseButton>
            <XIcon />
          </Drawer.CloseButton>
        </Drawer.Close>
      </Flex>
    )}
  </Drawer.Title>
));

interface DrawerContentProps extends React.ComponentPropsWithoutRef<"div"> {}

export const Content = React.forwardRef<HTMLDivElement, DrawerContentProps>(
  ({ children, ...props }, forwardedRef) => (
    <Drawer.Portal>
      <Drawer.Overlay />
      <Drawer.Content ref={forwardedRef} {...props}>
        {children}
      </Drawer.Content>
    </Drawer.Portal>
  ),
);

interface DrawerFooterProps extends React.ComponentPropsWithoutRef<"div"> {
  secondaryButtonText?: string;
  primaryButtonText?: string;
}

export const Footer = React.forwardRef<HTMLDivElement, DrawerFooterProps>(
  (
    { children, secondaryButtonText, primaryButtonText, ...props },
    forwardedRef,
  ) => (
    <Drawer.Footer
      gap={12}
      alignItems="flex-end"
      justifyContent="flex-end"
      {...props}
      ref={forwardedRef}
    >
      {children ? (
        children
      ) : (
        <>
          <Drawer.Close asChild>
            <Button variant={"secondary"} size={"small"}>
              <Text fontWeight={700}>{secondaryButtonText}</Text>
            </Button>
          </Drawer.Close>

          <Button size={"small"} css={{ minWidth: 100 }}>
            <Text fontWeight={700} color={"$neutral0"}>
              {primaryButtonText}
            </Text>
          </Button>
        </>
      )}
    </Drawer.Footer>
  ),
);

export const Close = Drawer.Close;
