import * as React from "react";
import { useDispatch } from "react-redux";
import { useShallowEqualSelector } from "../../../_helpers/redux";
import { consultNoteActions } from "../../../../state/models/consultnote";
import { Checkbox, Input } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import QuestionTitle from "./shared/QuestionTitle";
import useDebounce from "../../../hooks/useDebounce";
import { ConsultNoteQuestion } from "../../../../api/types";
import { getCurrentConsultNoteInfo } from "../../_hooks";

interface Props {
  question: ConsultNoteQuestion;
  handleEdit: (hasBeenEdited: boolean) => void;
}

const _ = ({ question, handleEdit }: Props) => {
  const { readOnlyConsultNote } = getCurrentConsultNoteInfo();

  const [endorsed_prior_ipv, endorsed_current_ipv, ipv_additional_details] =
    useShallowEqualSelector((state) => [
      state.consultnote?.openConsult?.consult_note?.endorsed_prior_ipv,
      state.consultnote?.openConsult?.consult_note?.endorsed_current_ipv,
      state.consultnote?.openConsult?.consult_note?.ipv_additional_details
        ? state.consultnote?.openConsult?.consult_note?.ipv_additional_details
        : "",
    ]);

  const dispatch = useDispatch();

  const handleChange = (key: string, value: boolean | string) => {
    handleEdit(true);
    dispatch(
      consultNoteActions.setConsultNoteValue({
        key,
        value,
      }),
    );
  };

  const [additionalDetails, setAdditionalDetails] = React.useState(
    ipv_additional_details,
  );

  const handleSetAdditionalDetails = (value: string) => {
    setAdditionalDetails(value);
    handleEdit(true);
  };

  const debouncedValue = useDebounce(additionalDetails, 500);

  React.useEffect(() => {
    dispatch(
      consultNoteActions.setConsultNoteValue({
        key: "ipv_additional_details",
        value: additionalDetails,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return (
    <div style={{ paddingTop: "15px", paddingBottom: "15px" }}>
      <QuestionTitle
        questionNumber={question.questionNumber}
        titleText={question.title}
        subtitleText={question.subtitle}
        isRequired={question.isRequired}
      />
      <Checkbox
        disabled={readOnlyConsultNote}
        checked={endorsed_current_ipv === false}
        style={{ paddingRight: "10px" }}
        onChange={(e: CheckboxChangeEvent) =>
          handleChange("endorsed_current_ipv", !e.target.checked)
        }
      >
        Denied current IPV
      </Checkbox>

      <Checkbox
        disabled={readOnlyConsultNote}
        checked={endorsed_prior_ipv}
        style={{ paddingRight: "10px" }}
        onChange={(e: CheckboxChangeEvent) =>
          handleChange("endorsed_prior_ipv", e.target.checked)
        }
      >
        Endorsed prior IPV
      </Checkbox>
      <Checkbox
        disabled={readOnlyConsultNote}
        checked={endorsed_current_ipv}
        style={{ paddingRight: "10px" }}
        onChange={(e: CheckboxChangeEvent) =>
          handleChange("endorsed_current_ipv", e.target.checked)
        }
      >
        Endorsed current IPV
      </Checkbox>
      {endorsed_current_ipv && (
        <div>
          <div>Additional details (if needed)</div>
          <Input.TextArea
            disabled={readOnlyConsultNote}
            style={{ height: "32px" }}
            value={ipv_additional_details}
            onChange={(e) => handleSetAdditionalDetails(e.target.value)}
          />
        </div>
      )}
    </div>
  );
};

export default _;
