import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";

import { useDispatchPromise } from "@/app/_helpers/redux";
import { volumeDataMapSelector } from "@/app/slot-tool/WeeklyVolume";
import { volumeDataIsRamp, volumeDataIsTenured } from "@/app/slot-tool/types";

import styled from "styled-components";
import { Table } from "@/app/design-system/tanstack-table/tanstack-table.styled";
import { styledStitches } from "@/app/design-system/styles/stitches.config";
import {
  HighAcuityClinicianFitList,
  useGetHighAcuityFitList,
} from "../../api/use-get-high-acuity-fit-list/use-get-high-acuity-fit-list";
import { $green } from "@/assets/colors";
type ClinicianSlotMap = {
  number: {
    start_date: string;
    start_time: string;
  };
};

export const HighAcuityFitList = ({
  matchId,
  clinicianSlots,
}: {
  matchId: number;
  clinicianSlots: ClinicianSlotMap | {};
}) => {
  const dispatch = useDispatchPromise();
  const volumeData = useSelector(volumeDataMapSelector);

  const clinicianIds = Object.keys(clinicianSlots).map((i) => parseInt(i));
  const { data, isLoading } = useGetHighAcuityFitList({
    matchId,
    clinicianIds,
    enabled: clinicianIds.length > 0,
  });

  if (!data || isLoading) {
    return null;
  }

  // sort first by clinicians that meet all HA criteria; then by earliest slots
  const tableBody = data
    .sort(
      (a, b) =>
        Number(b.is_ha_eligible) - Number(a.is_ha_eligible) ||
        DateTime.fromISO(
          `${clinicianSlots[a.id].start_date}T${
            clinicianSlots[a.id].start_time
          }`,
        ).diff(
          DateTime.fromISO(
            `${clinicianSlots[b.id].start_date}T${
              clinicianSlots[b.id].start_time
            }`,
          ),
        ).milliseconds,
    )
    .map((clinician: HighAcuityClinicianFitList, idx: number) => {
      if (!volumeData[clinician.id]) {
        return null;
      }

      const {
        matchesRequested,
        targetPanelSize,
        manualCapacity,
        rawData,
        source,
      } = volumeData[clinician.id];
      const nextMatchRequest =
        manualCapacity !== undefined && manualCapacity !== null
          ? manualCapacity
          : matchesRequested;

      const tenure = volumeDataIsTenured(rawData)
        ? `${Math.floor(rawData.mrmr_data.weeks_tenure / 4.33)} months`
        : clinician.tenure;

      const employmentType = clinician.is_network
        ? "Network"
        : targetPanelSize === 25
        ? "Full time"
        : "Part time";

      const avg_scheduled_sessions = volumeDataIsRamp(rawData)
        ? rawData.ramp_data.avg_4_weeks
        : volumeDataIsTenured(rawData)
        ? rawData.mrmr_data.avg_4_weeks
        : "N/A";

      const { start_date, start_time } = clinicianSlots[clinician.id];
      const firstAvailable = DateTime.fromISO(
        `${start_date}T${start_time}`,
      ).toFormat("ccc L'/'d 'at' ha");

      return (
        <tr key={`tbody-tr-${idx + 1}`}>
          <td key="tbody-td-1">{clinician.full_name}</td>
          <td
            key="tbody-td-2"
            className={clinician.ha_eligibility_dict.tenure ? "qualifies" : ""}
          >
            {tenure}
          </td>
          <td key="tbody-td-3">{employmentType}</td>
          <td key="tbody-td-4">{firstAvailable}</td>
          <td key="tbody-td-5">{nextMatchRequest}</td>
          <td
            key="tbody-td-6"
            className={
              clinician.ha_eligibility_dict.n_high_acuity ? "qualifies" : ""
            }
          >
            {clinician.high_acuity_clients}
          </td>
          <td
            key="tbody-td-7"
            className={
              clinician.ha_eligibility_dict.capacity ? "qualifies" : ""
            }
          >
            {avg_scheduled_sessions}
          </td>
          <td key="tbody-td-8">{clinician.manager_name}</td>
        </tr>
      );
    });

  return (
    <Container>
      <Header>High-acuity fit list</Header>
      <TableContainer>
        <thead>
          <tr key="thead-tr-1">
            <th key="thead-th-1">Clinician</th>
            <th key="thead-th-2">Tenure</th>
            <th key="thead-th-3">Type</th>
            <th key="thead-th-4">First available</th>
            <th key="thead-th-5">Matches req.</th>
            <th key="thead-th-6">High acuity</th>
            <th key="thead-th-7">4-wk avg.</th>
            <th key="thead-th-8">Manager</th>
          </tr>
        </thead>
        <tbody>{tableBody}</tbody>
      </TableContainer>
    </Container>
  );
};

const Container = styled.div`
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  padding: 1.75rem 2rem;
  margin: 2rem 0;
`;

const Header = styled.h4`
  font-size: 16px;
`;

const TableContainer = styledStitches(Table, {
  width: "unset",
  "thead > tr > th": {
    fontSize: "14px",
    fontWeight: "500",
    padding: "1rem 1.5rem",

    "&:first-child": {
      paddingLeft: "0",
    },
  },
  "tbody > tr > td": {
    fontWeight: "400",
    fontSize: "14px",
    padding: "1rem 1.5rem",

    "&:first-child": {
      paddingLeft: "0",
    },
    "&.qualifies": {
      backgroundColor: $green,
    },
  },
});
