import { useNavigate } from "react-router-dom";
import { styledStitches } from "@/app/design-system";
import { LeftCaretIcon } from "@/app/design-system/icons";

interface BackLinkProps {
  target?: string | number;
  children: React.ReactNode;
}

const StyledBackLink = styledStitches("a", {
  color: "$neutral11",
  fontSize: 16,
  display: "flex",
  gap: 8,
  alignItems: "center",
});

/**
 * @name BackLink
 * @param children
 * @description A component that returns a link back to the previous page using react-router-dom
 * @returns a jsx component that is a link that goes back to the previous page
 */
export const BackLink = ({ target, children }: BackLinkProps) => {
  const navigate = useNavigate();
  return (
    <StyledBackLink
      onClick={() => {
        //@ts-ignore
        target ? navigate(target) : navigate(-1);
      }}
    >
      <LeftCaretIcon />
      {children}
    </StyledBackLink>
  );
};
