import { CloseOutlined, PlusOutlined, TeamOutlined } from "@ant-design/icons";
import {
  Button,
  notification,
  Popover,
  Radio,
  Select as DeprecatedAntdSelect,
} from "antd";
import { DateTime, Info, Interval } from "luxon";
import moment from "moment";
import React, { useCallback } from "react";
import styled from "styled-components";

import { Time } from "../../api/types";
import { DATE_FORMAT } from "../../state/models/slot-tool";
import * as slotToolOperations from "../../state/models/slot-tool/operations";
import { timeIsOnOddWeek } from "../_helpers/datetime";
import { IfPermitted } from "../_helpers/permissions";
import { useDispatchPromise, useShallowEqualSelector } from "../_helpers/redux";
import { CurrentUserContext } from "../app.utils";
import {
  DeprecatedComposedReachUISelect,
  Option,
} from "../design-system/select-reachui/composed-select-reachui";
import { TIMEZONE } from "../my-clients/utils";
import {
  DayOfWeek,
  ExcludedSlot,
  ManualSlotReservation,
  OpenSlot,
  Recurrence,
  ReservationType,
  SlotClosedSlot,
  SlotOpenSlot,
  SlotReservationSlot,
} from "./types";

export enum OPEN_TIMESLOT_REASONS {
  TERMINATION = "Client is terminating",
  IRRELEVANT = "Calendar block is irrelevant",
  CHANGE_TIMESLOT = "Client is changing timeslots",
  OTHER = "Other",
}

export enum CLOSE_TIMESLOT_REASONS {
  ERROR = "This open timeslot is a system error",
  RETURNING_CLIENT = "A client is returning to care in this timeslot",
  EXISTING_CLIENT = "A current client is going to take this timeslot",
  OTHER = "Other",
}

interface TimeslotProps {
  clinicianId: number;
  day: DayOfWeek;
  hour: Time;
  recurrence: Recurrence;
}

export interface OpenTimeslotProps extends TimeslotProps {
  even_odd_week?: "even" | "odd";
}

export interface CloseTimeslotProps extends TimeslotProps {
  even_odd_week?: "even" | "odd";
}

export interface OpenReservationTimeslotProps extends OpenTimeslotProps {
  reservation_type: ReservationType;
}
export interface CloseReservationTimeslotProps extends CloseTimeslotProps {}

export function openNotification(
  title: string,
  message: string,
  level?: "normal" | "error" | "success",
) {
  const content = {
    message: title,
    description: message,
  };
  if (level === "error") {
    notification.error(content);
  } else if (level === "success") {
    notification.success(content);
  } else {
    notification.open(content);
  }
}

export function getValidStartDateForSlot(
  day: DayOfWeek,
  hour: string,
  biweekly?: boolean,
  biweeklyEvenOrOdd?: "even" | "odd",
) {
  let dayOfWeek = Info.weekdays("long").indexOf(day) + 1;
  let startDate = DateTime.local().set({ weekday: dayOfWeek }).startOf("day");

  if (startDate < DateTime.local().startOf("day")) {
    startDate = startDate.plus({ weeks: 1 });
  } else if (startDate === DateTime.local().startOf("day") && Number(hour) < DateTime.local().hour) {
    // if they choose today AND the start hour is less than the current hour
    // eg. if it's Wednesday at 11am and they want to open Wednesday at 10am, then
    // open the reservation starting next week (in this case, next Wednesday at 10am)
    startDate = startDate.plus({ weeks: 1 });
  }

  if (biweekly && biweeklyEvenOrOdd) {
    const thisWeekIsOdd = timeIsOnOddWeek(startDate);

    if (
      (thisWeekIsOdd && biweeklyEvenOrOdd === "even") ||
      (!thisWeekIsOdd && biweeklyEvenOrOdd === "odd")
    ) {
      startDate = startDate.plus({ weeks: 1 });
    }
  }

  return startDate.toFormat("yyyy-MM-dd");
}

export const OpenReservationTimeslot = (
  props: OpenReservationTimeslotProps,
) => {
  const cuser = React.useContext(CurrentUserContext);
  const {
    clinicianId,
    day,
    hour,
    recurrence,
    even_odd_week,
    reservation_type,
  } = props;
  const dispatch = useDispatchPromise();

  const { addReservationSlot } = React.useMemo(
    () => ({
      addReservationSlot: (clinicianId: number, slot: ManualSlotReservation) =>
        dispatch(
          slotToolOperations.addReservationSlot(
            clinicianId,
            slot,
            TIMEZONE(cuser),
          ),
        ),
    }),
    [dispatch],
  );

  const [reservationTypeValue, setReservationTypeValue] =
    React.useState<ReservationType>(reservation_type);
  const [recurrenceRadioValue, setRecurrenceRadioValue] =
    React.useState<Recurrence>(recurrence);
  const [recurrenceEvenOddValue, setRecurrenceEvenOddValue] = React.useState<
    "even" | "odd" | undefined
  >(even_odd_week);

  const [endDate, setEndDate] = React.useState<string>("null");

  const startDate = getValidStartDateForSlot(
    day,
    hour,
    recurrence === "biweekly",
    even_odd_week,
  );

  const confirm = async () => {
    if (!reservationTypeValue) {
      openNotification(
        "Error opening slot.",
        "Please selection a reservation type",
        "error",
      );
    }

    const slot: ManualSlotReservation = {
      clinician: clinicianId,
      slot_time_of_day: hour,
      start_date: startDate,
      slot_day_of_week: day,
      slot_recurrence: recurrenceRadioValue,
      slot_type: reservationTypeValue,
      end_date: endDate === "null" ? undefined : endDate,
    };

    const addReservationSlotSuccess = await addReservationSlot(
      clinicianId,
      slot,
    );

    if (addReservationSlotSuccess) {
      openNotification("Successfully opened slot.", "");
    } else {
      openNotification(
        "Error opening slot.",
        "Please send details to techsupport@twochairs.com.",
        "error",
      );
    }
  };

  const Title = (
    <PopoverTitleContainer>
      <h5>Reserve Timeslot</h5>
      <span>
        {props.day} at {moment(props.hour, "HH:mm:ss").format("ha")} (
        <span style={{ textTransform: "capitalize" }}>
          {recurrence}
          {recurrence === "biweekly" && `, ${even_odd_week} weeks`}
        </span>
        )
      </span>
    </PopoverTitleContainer>
  );

  const Content = (
    <PopoverContentContainer>
      <p>This weekly timeslot will be reserved for:</p>
      <IfPermitted permissions={["IsClinicalLeader"]} requireAll={false}>
        <div>
          <Radio.Group
            defaultValue={reservationTypeValue}
            style={{ margin: "8px 0 8px 0" }}
            onChange={(e) =>
              setReservationTypeValue(e.target.value as ReservationType)
            }
          >
            <Radio.Button value="consult">Consults</Radio.Button>
            <Radio.Button value="couples_therapy">Couples</Radio.Button>
          </Radio.Group>
        </div>
      </IfPermitted>
      <IfPermitted permissions={["IsClinicalLeader"]} requireAll={false}>
        <div>
          <Radio.Group
            defaultValue={recurrence}
            style={{ margin: "8px 0 8px 0" }}
            onChange={(e) =>
              setRecurrenceRadioValue(e.target.value as Recurrence)
            }
          >
            <Radio.Button value="weekly">Weekly</Radio.Button>
            <Radio.Button value="biweekly">Biweekly</Radio.Button>
          </Radio.Group>
        </div>
        <div>
          <Radio.Group
            defaultValue={recurrenceEvenOddValue}
            style={{ margin: "8px 0 8px 0" }}
            onChange={(e) =>
              setRecurrenceEvenOddValue(e.target.value as "even" | "odd")
            }
            disabled={recurrenceRadioValue !== "biweekly"}
          >
            <Radio.Button value="even">Even</Radio.Button>
            <Radio.Button value="odd">Odd</Radio.Button>
          </Radio.Group>
        </div>
        <IfPermitted
          permissions={["IsAvailabilityToolEndDateUser"]}
          requireAll={false}
        >
          <div>
            Optionally, pick an end date for this reservation:
            <ConsultReservationEndDatePicker
              slot_start_date={startDate}
              onChange={setEndDate}
              value={endDate}
              recurrence={recurrenceRadioValue}
            />
          </div>
        </IfPermitted>
      </IfPermitted>
      <div>
        <Button type="primary" onClick={confirm}>
          Confirm
        </Button>
      </div>
    </PopoverContentContainer>
  );

  return (
    <Popover title={Title} content={Content} trigger="click" placement="right">
      <Button icon={<TeamOutlined />} size="small" shape="circle" />
    </Popover>
  );
};

export const OpenTimeslot = (props: OpenTimeslotProps) => {
  const { clinicianId, day, hour, recurrence, even_odd_week } = props;
  const dispatch = useDispatchPromise();

  const { addManualSlot } = React.useMemo(
    () => ({
      addManualSlot: (clinicianId: number, slot: OpenSlot) =>
        dispatch(slotToolOperations.addManualSlot(clinicianId, slot)),
    }),
    [dispatch],
  );

  const [clinicianSchedule, clinicMap] = useShallowEqualSelector((state) => [
    state.clinicians.scheduleMap[clinicianId] || [],
    state.clinics.clinicMap,
  ]);

  const getSubregionId = useCallback(
    (day: DayOfWeek, time: Time): number => {
      const scheduleItem = clinicianSchedule.find(
        (sched) =>
          sched.day_of_week === day &&
          sched.start_time <= time &&
          sched.end_time > time,
      );

      if (!scheduleItem) {
        return 0;
      } else {
        return clinicMap[scheduleItem.clinic_id].subregion;
      }
    },
    [clinicMap, clinicianSchedule],
  );

  const [reason, setReason] = React.useState<string>();
  const [recurrenceRadioValue, setRecurrenceRadioValue] =
    React.useState<Recurrence>(recurrence);
  const [recurrenceEvenOddValue, setRecurrenceEvenOddValue] = React.useState<
    "even" | "odd" | undefined
  >(even_odd_week);

  const confirm = async () => {
    if (!reason) {
      openNotification(
        "Error opening slot.",
        "Please select a reason.",
        "error",
      );
      return;
    }

    const subregionId = getSubregionId(day, hour);
    if (!subregionId) {
      openNotification(
        "Error opening slot.",
        "Clinician is not working at this time.",
        "error",
      );
      return;
    }

    let startDate = getValidStartDateForSlot(
      day,
      hour,
      recurrenceRadioValue === "biweekly",
      recurrenceEvenOddValue,
    );

    const slot: OpenSlot = {
      clinician_id: clinicianId,
      start_time: hour,
      start_date: startDate,
      day_of_week: day,
      recurrence: recurrenceRadioValue,
      note: reason,
      subregion_id: subregionId,
    };

    const addManualSlotSuccess = await addManualSlot(clinicianId, slot);

    if (addManualSlotSuccess === 200) {
      openNotification("Successfully opened slot.", "");
    } else if (addManualSlotSuccess === 409) {
      openNotification(
        "Slot already exists",
        "This manually opened slot was recorded but may be unavailable for matching due to holidays or PTO.",
        "error",
      );
    } else {
      openNotification(
        "Error opening slot.",
        "Please send details to techsupport@twochairs.com.",
        "error",
      );
    }
  };

  const Title = (
    <PopoverTitleContainer>
      <h5>Open Weekly Timeslot</h5>
      <span>
        {props.day} at {moment(props.hour, "HH:mm:ss").format("ha")} (
        <span style={{ textTransform: "capitalize" }}>
          {recurrence}
          {recurrence === "biweekly" && `, ${even_odd_week} weeks`}
        </span>
        )
      </span>
    </PopoverTitleContainer>
  );

  const Content = (
    <PopoverContentContainer>
      <p>This weekly timeslot will be made available to a new match.</p>
      <DeprecatedAntdSelect
        placeholder="Select a reason"
        style={{ width: "80%" }}
        onChange={(v) => setReason(OPEN_TIMESLOT_REASONS[v as number])}
      >
        {Object.keys(OPEN_TIMESLOT_REASONS).map((k) => (
          <DeprecatedAntdSelect.Option value={k} key={k}>
            {OPEN_TIMESLOT_REASONS[k]}
          </DeprecatedAntdSelect.Option>
        ))}
      </DeprecatedAntdSelect>
      <IfPermitted permissions={["IsClinicalLeader"]} requireAll={false}>
        <Radio.Group
          defaultValue={recurrence}
          style={{ margin: "8px 0 8px 0" }}
          onChange={(e) =>
            setRecurrenceRadioValue(e.target.value as Recurrence)
          }
        >
          <Radio.Button value="weekly">Weekly</Radio.Button>
          <Radio.Button value="biweekly">Biweekly</Radio.Button>
        </Radio.Group>
        {recurrenceRadioValue === "biweekly" && (
          <Radio.Group
            defaultValue={recurrenceEvenOddValue}
            style={{ margin: "8px 0 8px 8px" }}
            onChange={(e) =>
              setRecurrenceEvenOddValue(e.target.value as "even" | "odd")
            }
          >
            <Radio.Button value="even">Even</Radio.Button>
            <Radio.Button value="odd">Odd</Radio.Button>
          </Radio.Group>
        )}
      </IfPermitted>
      <div>
        <Button type="primary" onClick={confirm}>
          Confirm
        </Button>
      </div>
    </PopoverContentContainer>
  );

  return (
    <Popover title={Title} content={Content} trigger="click" placement="right">
      <Button icon={<PlusOutlined />} size="small" shape="circle" />
    </Popover>
  );
};

export const CloseTimeslot = (props: CloseTimeslotProps) => {
  const { clinicianId, day, hour, recurrence, even_odd_week } = props;
  const dispatch = useDispatchPromise();

  const { addExclusionSlot } = React.useMemo(
    () => ({
      addExclusionSlot: (clinicianId: number, slot: ExcludedSlot) =>
        dispatch(slotToolOperations.addExclusionSlot(clinicianId, slot)),
    }),
    [dispatch],
  );

  const [reason, setReason] = React.useState<string>();

  const confirm = async () => {
    if (!reason) {
      openNotification(
        "Error opening slot.",
        "Please select a reason.",
        "error",
      );
      return;
    }

    const thisWeekIsEven = !timeIsOnOddWeek(moment());
    const startDate = moment().day(day);

    if (even_odd_week) {
      if (even_odd_week === "odd" && thisWeekIsEven) {
        startDate.add(1, "week");
      } else if (even_odd_week === "even" && !thisWeekIsEven) {
        startDate.add(1, "week");
      }
    }

    const excludedSlot: ExcludedSlot = {
      clinician_id: clinicianId,
      start_time: hour,
      start_date: startDate.format(DATE_FORMAT),
      day_of_week: day,
      recurrence: recurrence,
      expires_at: moment().add(1, "week").format(),
      note: reason,
    };

    const slotExclusionSuccess = await addExclusionSlot(
      clinicianId,
      excludedSlot,
    );

    if (slotExclusionSuccess) {
      openNotification("Successfully closed slot.", "");
    } else {
      openNotification(
        "Error closing slot.",
        "Please send details to techsupport@twochairs.com.",
        "error",
      );
    }
  };

  const Title = (
    <PopoverTitleContainer>
      <h5>Close Timeslot</h5>
      <span>
        {recurrence === "biweekly" ? (
          <span style={{ textTransform: "capitalize" }}>
            {even_odd_week} Biweekly{" "}
          </span>
        ) : (
          ""
        )}
        {props.day} at {moment(props.hour, "HH:mm:ss").format("ha")}
      </span>
    </PopoverTitleContainer>
  );

  const Content = (
    <PopoverContentContainer>
      <p>This timeslot will be made unavailable to matches for 1 week.</p>
      <DeprecatedAntdSelect
        placeholder="Select a reason"
        style={{ width: "80%" }}
        onChange={(v) => setReason(CLOSE_TIMESLOT_REASONS[v as number])}
      >
        {Object.keys(CLOSE_TIMESLOT_REASONS).map((k) => (
          <DeprecatedAntdSelect.Option value={k} key={k}>
            {CLOSE_TIMESLOT_REASONS[k]}
          </DeprecatedAntdSelect.Option>
        ))}
      </DeprecatedAntdSelect>

      <div style={{ marginTop: "8px" }}>
        <Button type="primary" onClick={confirm}>
          Confirm
        </Button>
      </div>
    </PopoverContentContainer>
  );

  return (
    <Popover title={Title} content={Content} trigger="click" placement="right">
      <Button icon={<CloseOutlined />} size="small" shape="circle" />
    </Popover>
  );
};

export type RemoveOverrideProps =
  | {
      type: "open";
      slot: SlotOpenSlot;
    }
  | {
      type: "closed";
      slot: SlotClosedSlot;
    }
  | {
      type: "reservation";
      slot: SlotReservationSlot;
    };

export const RemoveOverride = (props: RemoveOverrideProps) => {
  const { type, slot } = props;
  const dispatch = useDispatchPromise();

  const { expireExcludedSlot, expireOpenedSlot, expireReservationSlot } =
    React.useMemo(
      () => ({
        expireExcludedSlot: (slotId: string) =>
          dispatch(slotToolOperations.expireExcludedSlot(slotId)),
        expireOpenedSlot: (slotId: string) =>
          dispatch(slotToolOperations.expireManualSlot(slotId)),
        expireReservationSlot: (slotId: string, endDate: string) =>
          dispatch(slotToolOperations.expireReservationSlot(slotId, endDate)),
      }),
      [dispatch],
    );

  const [endDate, setEndDate] = React.useState<string>(
    DateTime.max(
      DateTime.local(),
      DateTime.fromISO(slot.end_date ? slot.end_date : slot.start_date),
    ).toISODate(),
  );

  const confirm = async () => {
    if (type === "closed") {
      await expireExcludedSlot(slot.eventId);
    } else if (type === "open") {
      await expireOpenedSlot(slot.eventId);
    } else if (type === "reservation") {
      await expireReservationSlot(slot.eventId, endDate);
    }
  };

  const Title = (() => {
    switch (type) {
      case "closed":
        return (
          <PopoverTitleContainer>
            <h5>Closed Timeslot</h5>
            <span>
              Expires{" "}
              {moment((slot as SlotClosedSlot).expires_at).format("M/D")}
            </span>
          </PopoverTitleContainer>
        );
      case "open":
        return (
          <PopoverTitleContainer>
            <h5>Open Timeslot</h5>
            <span></span>
          </PopoverTitleContainer>
        );
      case "reservation":
        return (
          <PopoverTitleContainer>
            <h5>Reserved Timeslot</h5>
            <span></span>
          </PopoverTitleContainer>
        );
      default:
        return null;
    }
  })();

  const Content = (
    <PopoverContentContainer>
      <p>
        {(() => {
          switch (type) {
            case "closed":
              return `This slot was manually closed and will be reopened for matches after ${moment(
                (slot as SlotClosedSlot).expires_at,
              ).format("M/D")}.`;
            case "open":
              return `This slot was manually opened and is available for matches.`;
            case "reservation":
              return `This slot is manually reserved for ${slot.slot_type}s.`;
          }
        })()}
      </p>

      {type === "reservation" ? (
        <IfPermitted
          permissions={["IsAvailabilityToolEndDateUser"]}
          requireAll={false}
        >
          <span>Pick a date to end this reservation after:</span>
          <ConsultReservationEndDatePicker
            slot_start_date={slot.start_date}
            onChange={setEndDate}
            value={endDate}
            recurrence={slot.recurrence}
          />
        </IfPermitted>
      ) : null}

      <div style={{ marginTop: "8px" }}>
        <Button type="primary" onClick={confirm}>
          Confirm {type === "reservation" ? "Reservation" : "Override"}
        </Button>
      </div>
    </PopoverContentContainer>
  );

  return (
    <Popover title={Title} content={Content} trigger="click" placement="right">
      <Button icon={<CloseOutlined />} size="small" shape="circle" />
    </Popover>
  );
};

const ConsultReservationEndDatePicker = (props: {
  slot_start_date: string;
  onChange: (value: string) => unknown;
  value: string;
  recurrence?: Recurrence;
}) => {
  const { slot_start_date, onChange, value, recurrence } = props;

  const startDate = DateTime.fromISO(slot_start_date);

  const validEndDates = getValidReservationEndDates(startDate, recurrence);

  const endDateOptions = [
    {
      label: "Do not end",
      value: "null",
      id: "Do not end",
    },
    {
      label: "Immediately",
      value: DateTime.max(DateTime.local(), startDate).toISODate(),
      id: "Immediately",
    } as Option,
  ].concat(
    validEndDates.map((e) => {
      const weeksAway = Math.ceil(e.diffNow("weeks").weeks);

      return {
        label: `${e.toFormat("LLL dd")} (after ${weeksAway} week${
          weeksAway === 1 ? "" : "s"
        })`,
        value: e.plus({ days: 1 }).toISODate(),
        id: e.toISODate(),
      } as Option;
    }),
  );

  return (
    <>
      <DeprecatedComposedReachUISelect
        options={endDateOptions}
        value={value}
        onChange={onChange}
      />
    </>
  );
};

const PopoverTitleContainer = styled.div``;

const PopoverContentContainer = styled.div``;

export function getValidReservationEndDates(
  slot_start_date: DateTime,
  recurrence: Recurrence = Recurrence.Weekly,
) {
  return Interval.fromDateTimes(
    DateTime.max(slot_start_date),
    DateTime.max(slot_start_date, DateTime.local().startOf("day")).plus({
      weeks: 9,
    }),
  )
    .splitBy({ weeks: 1 })
    .filter((i) => i.start >= DateTime.local())
    .filter(
      (i) =>
        recurrence === Recurrence.Weekly ||
        timeIsOnOddWeek(slot_start_date) === timeIsOnOddWeek(i.start),
    )
    .map((i) => i.start);
}
