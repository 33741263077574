import api from "@/api";
import { useQuery } from "react-query";

export const getDiagnosisCodes = async (userIds?: string[]): Promise<any> => {
  const url = `/ehr/fetch_diagnoses`;
  if (!userIds || !userIds.length) {
    throw new Error("userId is required");
  }

  try {
    const { data } = await api.get(url, {
      params: { userIds: userIds.join(",") },
    });
    return data;
  } catch (e) {
    throw e;
  }
};

/**
 * @function useGetDiagnosisCodes
 * @description
 */
export const useGetDiagnosisCodes = (userIds: string[], enabled?: boolean) => {
  return useQuery({
    // like in useEffect, we pass in query keys as an array to react-query
    // and treat query keys as a dependency array. if the keys change, react-query
    // will automatically call the query function we pass to it.
    queryKey: ["diagnosisCodes"],
    // a query function can be any function that returns a promise.
    // the promise that is returned should either resolve the data or throw an error.
    queryFn: () => getDiagnosisCodes(userIds),
    enabled: enabled,
  });
};
